/* eslint-disable react/no-array-index-key */
import classes from '../index.module.scss';
import { Table } from 'antd';
import { getColumns } from '../lib/getColumns';
import { getDataSource } from '../lib/getDataSource';
import { ROW_KEY } from '../lib/utils';
import { getRowClassName } from '../lib/getRowClassName';
import { useWindowResize } from '../../../utils/hooks/useWindowResize';
import styled from 'styled-components';
import { Fragment, useRef } from 'react';

export function ComparisonTable(props) {
  const { windowWidth } = useWindowResize();
  const dataSource = getDataSource(props?.tableList, props.applyVariable);

  const footnoteRef = useRef([]);

  return (
    <div className={classes.wrapper}>
      {dataSource.map((table, idx) => {
        const columns = getColumns(props?.tableList, props?.fonts, props?.colors, windowWidth, idx, footnoteRef);

        return (
          <Fragment key={idx}>
            <Table
              rowKey={ROW_KEY}
              className={classes.table}
              columns={columns}
              dataSource={table}
              pagination={false}
              scroll={{ x: 'max-content' }}
              selections={false}
              rowClassName={getRowClassName}
            />
            {props?.tableList[idx].footnotes && (
              <FootnotesDescription
                ref={el => {
                  footnoteRef.current[idx] = el;
                }}
              >
                {props?.tableList[idx].footnotes}
              </FootnotesDescription>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

const FootnotesDescription = styled('div')({
  backgroundColor: '#F2F2F3',
  color: '#858899',
  whiteSpace: 'pre-wrap',
  padding: '8px 24px',
  lineHeight: '24px',
  borderRadius: '8px',
});
