import { Navigate, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { lazily } from 'react-lazily';
import { ROUTER_PATHS } from '../../../../lib/util/router/router-paths';
import { NOT_FOUND_ROUTE_PATH } from '../../../../lib/util/router/router-consts';
import { WithFinderSubHeader } from '../management/components/WithFinderSubHeader';
import { SettingsPage } from '../management/pages/SettingsPage';
import { QuestionnairePage } from '../management/pages/QuestionnairePage/QuestionnairePage';
import { WithoutSideBarLayout } from '../management/components/WithoutSideBarLayout';
import { YourFindersPage } from './pages/YourFindersPage';
import { PreviewPage } from 'src/processes/CMS/processes/management/pages/PreviewPage/index';
import { WithPreviewHeader } from 'src/processes/CMS/processes/management/components/WithPreviewHeader/index';
import { Header } from '../../modules/Header';
import { ProductsPage } from 'src/processes/CMS/processes/management/pages/ProductsPage/index';

const { ProfilePage } = lazily(() => import('src/processes/CMS/pages/ProfilePage'));
const { NotFoundPage } = lazily(() => import('src/processes/CMS/processes/management/pages/NotFoundPage'));

export function UserRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Header />}>
        <Route path={NOT_FOUND_ROUTE_PATH} element={<Navigate to={ROUTER_PATHS.yourFinders} />} />
        <Route element={<WithFinderSubHeader />}>
          <Route path={ROUTER_PATHS.settings(':id')} element={<SettingsPage />} />
          <Route path={ROUTER_PATHS.questionnaire(':id')} element={<QuestionnairePage />} />
          <Route path={ROUTER_PATHS.products(':id')} element={<ProductsPage />} />
        </Route>
        <Route element={<WithoutSideBarLayout />}>
          <Route path={ROUTER_PATHS.yourFinders} element={<YourFindersPage />} />
          <Route path={ROUTER_PATHS.profile} element={<ProfilePage />} />
          <Route path={ROUTER_PATHS.notFound} element={<NotFoundPage />} />
        </Route>
        <Route element={<WithPreviewHeader />}>
          <Route path={ROUTER_PATHS.preview(':id')} element={<PreviewPage />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}
