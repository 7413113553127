import { AppTitle } from '../../../../../ui/text/AppTitle';
import { AppBox } from '../../../../../ui/boxes/AppBox';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';

export function FilterTitle() {
  const { arranges } = useLocalization().translations.shared;

  // const filterTypeOptions = [
  //   {
  //     value: FilterType.INCLUDE,
  //     label: handleTranslation(arranges.filter.types.include),
  //   },
  //   {
  //     value: FilterType.EXCLUDE,
  //     label: handleTranslation(arranges.filter.types.exclude),
  //   },
  // ];

  return (
    <AppBox style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '13px' }}>
      <AppTitle level={3} textValue={arranges.filter.title} type='secondary' style={{ fontSize: '12px', margin: 0 }} />

      {/* <AppSegmented block size='small' value={filterPagination.filterType} onChange={changeFilterType} options={filterTypeOptions} /> */}
    </AppBox>
  );
}
