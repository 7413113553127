export const User = {
  EMAIL: 'email',
  PERSON_NAME: 'fullName',
  USERNAME: 'login',
  TENANTS: 'tenants',
  STATUS: 'status',
  ROLES: 'authority',
  CREATED_DATE: 'createdDate',
  LAST_MODIFIED_DATE: 'lastModifiedDate',
};

export const UserStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  DEACTIVATED: 'DEACTIVATED',
};
