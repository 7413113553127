import { AppParagraph } from '../../../../../../../../ui/text/AppParagraph';
import { AppText } from '../../../../../../../../ui/text/AppText';

/**
 * @desc Component for displaying information about deleting a rule.
 * @param {String} itemName
 * @param {Number} usedInElements
 * @param {Object} subTitle
 * @returns {JSX.Element}
 */
export function DeleteRuleInfo({ itemName, usedInElements, subTitle }) {
  return (
    <>
      <AppParagraph style={{ marginBottom: 0 }}>
        <AppText textValue={subTitle.firstPartOpen} />
        <AppText className='bold' textValue={itemName} />
        <AppText textValue={subTitle.firstPartClose} />
      </AppParagraph>
      <AppParagraph style={{ marginBottom: 0 }}>
        <AppText textValue={subTitle.secondPartOpen} />
        <AppText className='bold' textValue={subTitle.secondPartClose({ amount: usedInElements })} />
      </AppParagraph>
      <AppParagraph textValue={subTitle.thirdPart} />
      <AppParagraph textValue={subTitle.fourthPart} style={{ marginBottom: '24px' }} />
    </>
  );
}
