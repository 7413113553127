import { TableTabs } from '../../../features/TableTabs';

export function TopPanel({ addButtons, tabOptions, activeTab, setActiveTab, tabButtonStyle }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <TableTabs options={tabOptions} selected={activeTab} setActiveTab={setActiveTab} tabButtonStyle={tabButtonStyle} />
      {addButtons[activeTab]}
    </div>
  );
}
