import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadLocaleAsync } from '../../../../lib/localization/i18n-util.async';
import TypesafeI18n from '../../../../lib/localization/i18n-react';
import { initLocale } from '../../../../lib/store/slices/localizationSlice';

export function LocalizationProvider({ children }) {
  const { locale } = useSelector(({ localization }) => localization);
  const dispatch = useDispatch();
  const [localesLoaded, setLocalesLoaded] = useState(false);

  useEffect(() => {
    async function setLocalization() {
      dispatch(initLocale());
      await loadLocaleAsync(locale);
      setLocalesLoaded(true);
    }

    setLocalization();
  }, [dispatch, locale]);

  if (!localesLoaded) {
    return null;
  }

  return <TypesafeI18n locale={locale}>{children}</TypesafeI18n>;
}
