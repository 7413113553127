export function RightReturnArrow() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2977_1771)'>
        <g clipPath='url(#clip1_2977_1771)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.5 1.5C1.36739 1.5 1.24021 1.55268 1.14645 1.64645C1.05268 1.74021 1 1.86739 1 2V6.8C1 7.1283 1.06466 7.45339 1.1903 7.75671C1.31594 8.06002 1.50009 8.33562 1.73223 8.56777C2.20107 9.03661 2.83696 9.3 3.5 9.3H13.293L9.946 12.646C9.85211 12.7399 9.79937 12.8672 9.79937 13C9.79937 13.1328 9.85211 13.2601 9.946 13.354C10.0399 13.4479 10.1672 13.5006 10.3 13.5006C10.4328 13.5006 10.5601 13.4479 10.654 13.354L14.854 9.154C14.9006 9.10755 14.9375 9.05238 14.9627 8.99163C14.9879 8.93089 15.0009 8.86577 15.0009 8.8C15.0009 8.73423 14.9879 8.66911 14.9627 8.60837C14.9375 8.54762 14.9006 8.49245 14.854 8.446L10.854 4.446C10.7601 4.35211 10.6328 4.29937 10.5 4.29937C10.3672 4.29937 10.2399 4.35211 10.146 4.446C10.0521 4.53989 9.99937 4.66722 9.99937 4.8C9.99937 4.93278 10.0521 5.06011 10.146 5.154L13.293 8.3H3.5C3.10218 8.3 2.72064 8.14196 2.43934 7.86066C2.15804 7.57936 2 7.19782 2 6.8V2C2 1.86739 1.94732 1.74021 1.85355 1.64645C1.75979 1.55268 1.63261 1.5 1.5 1.5Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2977_1771'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
        <clipPath id='clip1_2977_1771'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
