/* eslint-disable sonarjs/cognitive-complexity,no-magic-numbers */
import { elementSubTypes, types } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { isLength } from '../../../../../../lib/util/helpers';
import { getProdPropertyResult, getAnswerPropertyResult } from './property_result_helper';
import { getAnswers, getProductCards } from './get_elements_helper';

export const getIfArithmetic = (blocks, pagesFlow, allElements, currentElements) => {
  const formattedForCalculation = blocks.map((block, index, array) => {
    if (Array.isArray(block)) {
      return getIfArithmetic(block, pagesFlow, allElements, currentElements);
    }

    if (
      block.type === types.element &&
      (array[index - 1]?.type === types.arithmeticOperator || array[index + 1]?.type === types.arithmeticOperator)
    ) {
      if (!isLength(block.value.elements)) {
        return null;
      }
      if (block.value.elementType === elementSubTypes.Product) {
        const productCards = getProductCards(block.value.elements, allElements);
        const prodPropertyResult = getProdPropertyResult(productCards, block.value.property, pagesFlow, currentElements);
        return prodPropertyResult ? { value: { amount: prodPropertyResult[0], valueType: 'digit' }, type: types.preCalculation } : null;
      }
      if (block.value.elementType === elementSubTypes.Answer) {
        const answers = getAnswers(block.value.elements, allElements);
        const answerPropertyResult = getAnswerPropertyResult(answers, block.value.property, block.value.composerItem, pagesFlow);
        return answerPropertyResult ? { value: { amount: answerPropertyResult[0], valueType: 'digit' }, type: types.preCalculation } : null;
      }
    }

    if (
      block.type === types.variable &&
      (array[index - 1]?.type === types.arithmeticOperator || array[index + 1]?.type === types.arithmeticOperator)
    ) {
      const variables = pagesFlow?.finder?.customVariables || [];
      const selectedVariable = variables.find(variable => variable.id === block.value);
      const selectedValue = selectedVariable?.value || 0;
      return { ...block, value: { amount: selectedValue, valueType: 'Variable' }, type: types.preCalculation };
    }

    if (block.type === types.value && (array[index - 1]?.type === types.arithmeticOperator || array[index + 1]?.type === types.arithmeticOperator)) {
      return { ...block, type: types.preCalculation };
    }

    return block;
  });

  return calculateIfBlocks(formattedForCalculation);
};

const calculateIfBlocks = blocks => {
  return blocks.reduce((accumulator, block, index, array) => {
    if (Array.isArray(block)) {
      accumulator.push(calculateIfBlocks(block));
    }
    if (![types.arithmeticOperator, types.preCalculation].includes(block.type) && !Array.isArray(block)) {
      accumulator.push(block);
    }
    if (block?.type === types.preCalculation && array[index - 1]?.type !== types.arithmeticOperator) {
      accumulator.push({ value: [block.value], type: types.calculation });
    }
    if (block?.type === types.preCalculation && array[index - 1]?.type === types.arithmeticOperator) {
      accumulator.at(-1).value.push(block.value);
    }
    if (block?.type === types.arithmeticOperator) {
      accumulator.at(-1).value.push(block.value);
    }

    return accumulator;
  }, []);
};
