/* eslint-disable max-lines,max-lines-per-function */
import { Typography } from 'antd';
import { CompletedChapterElement } from '../../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ChapterLayouts/components/CompletedChapterElement';
import { ButtonOrLinkComponent } from '../../ButtonOrLinkComponent';
import { InfoIconComponent } from '../../InfoIconComponent';
import { isLength } from '../../../../../../../../../lib/util/helpers';
import { ProductLayoutCardBulletPoints } from '../../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ProductLayouts/components/ProductLayoutCardBulletPoints';
import { ChapterState } from '../../../../../../../../../lib/models/TemplateTypes';
import { getDisplayedProduct } from './utils';
import { getChapterImageUrl } from '../../../../../utils/rules/chapters_rules_engine_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import styles from '../styles.module.scss';

const FIRST_CHAPTER_INDEX = 0;
const CHAPTER_INDEX = 1;

export function FirstChapter({
  colors,
  fonts,
  pagesFlow,
  setPagesFlow,
  style,
  media,
  chapterOnClick,
  bulletIcons,
  setPageHistory,
  imageSelectionFlow,
  currentElements,
}) {
  const { applyVariable } = useVariables(pagesFlow);
  const { allChapters } = pagesFlow;
  const allChaptersWithoutCompletedChapters = allChapters.filter(item => !item.content.completedChaptersButtons);
  const firstChapter = isLength(allChaptersWithoutCompletedChapters) ? allChaptersWithoutCompletedChapters[FIRST_CHAPTER_INDEX] : {};
  const {
    content,
    content: {
      headline,
      subline,
      chapterButton,
      selectBackgroundImageInactive,
      selectBackgroundImageActive,
      showProductsAfterComplete,
      isShortProductName,
    } = {},
    flow,
    state,
    chapterInnerPagesIds,
    id: firstChapterId,
    resultSelectedProducts,
    rules,
  } = firstChapter;

  const { customIcons, defaultIcons } = bulletIcons;
  const allBulletIcons = [...customIcons, ...defaultIcons];

  const {
    firstChapterStyle = {},
    firstChapterHeadlineStyle = {},
    firstChapterSublineStyle = {},
    firstChapterButtonStyle = {},
    chapterCompletedCheckStyle = {},
  } = style;

  const backgroundImageUrl = getChapterImageUrl(
    firstChapterId,
    state,
    imageSelectionFlow,
    selectBackgroundImageActive,
    selectBackgroundImageInactive,
    media
  );

  const displayedProduct = getDisplayedProduct(resultSelectedProducts, content?.productsPriority);
  const bulletIcon = allBulletIcons?.find(item => item.id === displayedProduct?.bulletPointIcon?.value);

  return (
    Object.keys(firstChapter).length > 0 && (
      <div
        className={styles.firstChapter}
        data-testid='first_chapter'
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`,
          border: `3px solid ${state === ChapterState.COMPLETED ? colors.primaryColor : 'transparent'}`,
          cursor: [ChapterState.COMPLETED, ChapterState.OPENED].includes(state) ? 'pointer' : 'default',
          position: 'relative',
        }}
        role='presentation'
        onClick={() => chapterOnClick(firstChapterId, flow, rules, state, chapterInnerPagesIds[0], CHAPTER_INDEX)}
      >
        {state === ChapterState.COMPLETED && <CompletedChapterElement colors={colors} chapterCompletedCheckStyle={chapterCompletedCheckStyle} />}
        <div
          className={styles.firstChapterInfo}
          style={{
            background: colors.backgroundColor,
            ...firstChapterStyle,
          }}
        >
          {state === ChapterState.COMPLETED && showProductsAfterComplete && displayedProduct ? (
            <div>
              <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                style={{
                  marginBottom: 10,
                  fontSize: fonts.headlineFirst.fontSize,
                  fontFamily: fonts.headlineFirst.font,
                  fontWeight: fonts.headlineFirst.fontWeight,
                  color: colors[fonts.headlineFirst.colorVariant],
                  ...firstChapterHeadlineStyle,
                  maxWidth: '90%',
                }}
              >
                {isShortProductName && displayedProduct?.productNameSection?.shortProduct?.title
                  ? displayedProduct?.productNameSection?.shortProduct?.title
                  : displayedProduct?.productNameSection?.product?.title}
              </Typography.Paragraph>
              <ProductLayoutCardBulletPoints
                applyVariable={applyVariable}
                bulletPointsSection={
                  displayedProduct?.bulletPointsForChapterSection?.show
                    ? displayedProduct?.bulletPointsForChapterSection
                    : displayedProduct?.bulletPointsSection
                }
                bulletPointIconSvg={displayedProduct?.bulletPointIcon?.svgContent}
                bulletPointIconColor={displayedProduct?.bulletPointIconColor}
                bulletPointIconUrl={bulletIcon ? bulletIcon?.s3Url : allBulletIcons?.[0]?.s3Url}
                border={false}
                fonts={fonts}
              />
            </div>
          ) : (
            <>
              {headline && (
                <div style={{ display: 'flex' }}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      marginBottom: 10,
                      fontSize: fonts.headlineFirst.fontSize,
                      fontFamily: fonts.headlineFirst.font,
                      fontWeight: fonts.headlineFirst.fontWeight,
                      color: colors[fonts.headlineFirst.colorVariant],
                      ...firstChapterHeadlineStyle,
                      maxWidth: '90%',
                    }}
                  >
                    {applyVariable(headline.value)}
                  </Typography.Paragraph>
                  <InfoIconComponent applyVariable={applyVariable} type={headline} styles={{ top: 12 }} />
                </div>
              )}
              {subline && (
                <div style={{ display: 'flex' }}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      lineHeight: '24px',
                      marginBottom: 24,
                      fontSize: fonts.body.fontSize,
                      fontFamily: fonts.body.font,
                      fontWeight: fonts.body.fontWeight,
                      color: colors[fonts.body.colorVariant],
                      ...firstChapterSublineStyle,
                    }}
                  >
                    {applyVariable(subline.value)}
                  </Typography.Paragraph>
                  <InfoIconComponent applyVariable={applyVariable} type={subline} styles={{ top: 5 }} />
                </div>
              )}
              {chapterButton && (
                <ButtonOrLinkComponent
                  analyticsClass='analytics-button'
                  contentObject={chapterButton}
                  style={{
                    width: 300,
                    minHeight: 48,
                    background: colors.primaryColor,
                    marginBottom: 10,
                    border: 'none',
                    borderRadius: 24,
                    color: '#FFFFFF',
                    ...firstChapterButtonStyle,
                    position: 'relative',
                  }}
                  setPagesFlow={setPagesFlow}
                  pagesFlow={pagesFlow}
                  textStyle={{
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                  }}
                  setPageHistory={setPageHistory}
                  currentElements={currentElements}
                >
                  <InfoIconComponent applyVariable={applyVariable} type={chapterButton} styles={{ top: -7, right: 5 }} />
                </ButtonOrLinkComponent>
              )}
            </>
          )}
        </div>
        <div style={{ position: 'absolute', right: 30, top: 0 }}>
          <InfoIconComponent applyVariable={applyVariable} type={selectBackgroundImageActive} />
        </div>
      </div>
    )
  );
}
