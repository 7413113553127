import { ElementLabel } from '../ElementLabel';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { LabelTexts } from '../../../../../../../../lib/models/TemplateTypes';
import './style.scss';

export function ElementNameWithLabel({ templateType, label, elementIdByType, style = {}, minWidthElementLabel, minWidthAppText }) {
  return (
    <div className='align_items_center' style={style}>
      <ElementLabel templateType={templateType} minWidth={minWidthElementLabel} />
      <AppText style={{ minWidth: minWidthAppText }}>{`${LabelTexts[templateType]}.${elementIdByType + 1}`}</AppText>
      <AppText ellipsis className='element_label_name'>
        {label}
      </AppText>
    </div>
  );
}
