import { Product } from '../../../../lib/Product';

export function formatPriceStrToNum(stringPrice) {
  return stringPrice ? Number.parseFloat(stringPrice?.replaceAll(',', '')) : null;
}

function getExceptionalFormValues(formValues, additionalFields) {
  const {
    [Product.CATEGORY]: category,
    [Product.PRODUCT_PAGE_LINK]: productPageLink,
    [Product.CURRENCY]: currency,
    [Product.PRICE_PER_MONTH]: pricePerMonth,
    [Product.PRICE_PER_YEAR]: pricePerYear,
    [Product.PRICE_PRE_REQUEST]: pricePerRequest,
    ...restFormValues
  } = formValues;

  return { ...restFormValues, ...additionalFields };
}

function getNormalFormValues(formValues, additionalFields) {
  const category = formValues[Product.CATEGORY];

  return {
    ...formValues,
    [Product.CATEGORY]: { id: category?.value },
    [Product.CURRENCY]: formValues[Product.CURRENCY]?.value || formValues[Product.CURRENCY],
    [Product.PRICE_PER_MONTH]: formatPriceStrToNum(formValues[Product.PRICE_PER_MONTH]),
    [Product.PRICE_PER_YEAR]: formatPriceStrToNum(formValues[Product.PRICE_PER_YEAR]),
    ...additionalFields,
  };
}

export function prepareProductForm(formValues, additionalFields = {}) {
  const isExceptional = formValues[Product.IS_EXCEPTIONAL];

  return isExceptional ? getExceptionalFormValues(formValues, additionalFields) : getNormalFormValues(formValues, additionalFields);
}
