/* eslint-disable no-param-reassign */
import { BulletPointIconFormItem } from '../../../../../../../../../../features/form-items/BulletPointIconFormItem';
import { FontColorFormItem } from '../../../../../../../../../../features/form-items/FontColorFormItem';
import { useSelector } from 'react-redux';
import { checkDisableColor } from '../../../../../../../../../../../../../../lib/util/helpers';
import styled from 'styled-components';
import { useFinderColors } from '../../../../../../../../hooks/useFinderColors';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';

export function ProductBulletPointSelects({ structureItemIndex, bulletPointIcon, bulletPointIconColor, productIndex }) {
  const {
    isViewMode,
    finder: {
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);
  const icons = [...defaultIcons, ...customIcons];
  const colors = useFinderColors();

  const { updateStructure } = useUpdateStructure();

  const changeBulletPointIcon = data => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].bulletPointsSection.bulletPointIcon = data;
    });
  };

  const changeBulletPointIconColor = data => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].bulletPointsSection.bulletPointIconColor = data;
    });
  };

  return (
    <>
      <Styled
        as={BulletPointIconFormItem}
        size='small'
        color={colors[bulletPointIconColor]}
        icons={icons}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '160px', minHeight: 24, marginBottom: 0 },
        }}
        selectProps={{
          size: 'small',
          value: bulletPointIcon,
          onChange: (selected, _action) => changeBulletPointIcon({ value: selected.value, svgContent: selected.svgContent }),
        }}
      />
      <Styled
        as={FontColorFormItem}
        size='small'
        value={bulletPointIconColor}
        colors={colors}
        onChange={changeBulletPointIconColor}
        disabled={checkDisableColor(icons, bulletPointIcon.value) || isViewMode}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '160px', minHeight: 24, marginBottom: 0 },
        }}
        selectProps={{
          size: 'small',
        }}
      />
    </>
  );
}

const Styled = styled('div')({
  marginBottom: 0,
  '.react-select__value-container': {
    padding: '4px 5px 4px',
    overflowX: 'hidden !important',
  },
});
