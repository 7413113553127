import { Draggable } from 'react-beautiful-dnd';
import { ResultType, withActionContactFormFields } from '../../../../../../../../../../../lib/models/Questionnaire';
import classNames from 'classnames';
import { QuestionnaireVerticalGripHandle } from '../../../../../features/QuestionnaireVerticalGripHandle';
import { QuestionnaireBasicButton } from '../../../../../features/QuestionnaireBasicButton';
import { SINGLE_ITEM_DELETING_INDEXES_AMOUNT } from '../../../../../../../../../../../lib/util/constants';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { CrossIcon } from '../../../../../../../../../../../ui/icons/CrossIcon';
import { ContactFormRowFieldOptionTitle } from './ContactFormRowFieldOptionTitle';
import { ContactFormRowFieldOptionWithAction } from './ContactFormRowFieldOptionWithAction';

const gap8Px = { gap: '8px' };
const gap24Px = { gap: '24px' };

export function ContactFormRowFieldOption(props) {
  const { rowIndex, fieldIndex, optionIndex, structureItemIndex, field, id } = props;
  const { updateStructure } = useUpdateStructure();

  const deleteOption = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.formRowList[rowIndex].fieldList[fieldIndex].optionList.splice(
        optionIndex,
        SINGLE_ITEM_DELETING_INDEXES_AMOUNT
      );
    });
  };

  return (
    <Draggable
      draggableId={JSON.stringify({
        type: ResultType.CONTACT_FORM_ROW_FIELD_OPTION,
        rowIndex,
        fieldIndex,
        optionIndex,
        structureItemIndex,
        id,
      })}
      index={optionIndex}
    >
      {providedDraggable => (
        <li
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          style={{ ...providedDraggable.draggableProps.style, ...gap24Px }}
          className={classNames(providedDraggable.draggableProps.className, 'flex flex-y-c flex-sb')}
        >
          <div className='flex flex-y-c w-100 flex-sb' style={gap24Px}>
            <div className='flex flex-y-c w-100' style={gap8Px}>
              <QuestionnaireVerticalGripHandle dragHandleProps={providedDraggable.dragHandleProps} />
              {withActionContactFormFields.includes(field?.type) ? (
                <ContactFormRowFieldOptionWithAction {...props} field={field} />
              ) : (
                <ContactFormRowFieldOptionTitle {...props} field={field} />
              )}
            </div>

            <QuestionnaireBasicButton onClick={deleteOption}>
              <CrossIcon />
            </QuestionnaireBasicButton>
          </div>
        </li>
      )}
    </Draggable>
  );
}
