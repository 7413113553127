import React from 'react';
import { TemplateFormSection } from '../../../../../../../features/TemplateFormSection';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';

const MAX_NUMBER_BUTTONS = 20;

export function Buttons({ item, isViewMode, onChange }) {
  const { buttonsBlock } = useLocalization().translations.unique.pages.result;

  const {
    content: { buttons },
  } = item;

  const handleChange = (field, fieldId) => value => {
    const result = buttons.map(button => (button.id === fieldId ? { ...button, [field]: { value } } : button));
    onChange(result);
  };

  const handleDeleteButton = fieldId => {
    const result = buttons.filter(button => button.id !== fieldId);
    onChange(result);
  };

  const handleAddButton = () => {
    if (buttons.length < MAX_NUMBER_BUTTONS) {
      const result = [
        ...buttons,
        {
          rules: [],
          id: uuidv4(),
          buttonText: { value: '' },
          flow: {
            goTo: null,
            href: null,
            targetBlank: true,
          },
        },
      ];
      onChange(result);
    }
  };

  return (
    <div className='template_card_content_block'>
      <AppText className='template_card_headline' textValue={buttonsBlock.title} />
      <StyledButtons>
        {buttons?.map(button => (
          <StyledButton key={button.id}>
            <StyledButtonWrapper>
              <TemplateFormSection
                valueObject={button?.buttonText}
                label={buttonsBlock.buttons.enterButtonText}
                onChange={handleChange('buttonText', button.id)}
              />
            </StyledButtonWrapper>
            <AppBasicButton onClick={() => handleDeleteButton(button.id)} disabled={isViewMode}>
              <DeleteIcon />
            </AppBasicButton>
          </StyledButton>
        ))}
        <AppBasicButton onClick={handleAddButton} disabled={isViewMode || buttons.length === MAX_NUMBER_BUTTONS}>
          <AppText className='primary_color bold' textValue={buttonsBlock.buttons.addButton} />
        </AppBasicButton>
      </StyledButtons>
    </div>
  );
}

const StyledButtons = styled('div')({
  marginTop: 32,
});

const StyledButtonWrapper = styled('div')({
  width: '95%',
  marginRight: 15,
});

const StyledButton = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
});
