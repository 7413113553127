import { AppFormSection } from '../ui/AppFormSection';
import { useLocalization } from '../lib/hooks/useLocalization';
import { AppNewSelect } from '../ui/AppNewSelect';
import { CurrencyCode } from '../lib/models/Finance';

const CURRENCIES = [
  { value: CurrencyCode.EUR, title: 'EUR' },
  { value: CurrencyCode.USD, title: 'USD' },
  { value: CurrencyCode.CAD, title: 'CAD' },
  { value: CurrencyCode.CHF, title: 'CHF' },
  { value: CurrencyCode.SGD, title: 'SGD' },
];

export function CurrencyFormItem(props) {
  const { formItemProps: { style = {}, ...restFormItemProps } = {}, textFieldProps } = props;
  const { label } = useLocalization().translations.unique.formItems.currency;

  return (
    <AppFormSection
      formItemProps={{
        style: { width: 140, ...style },
        ...restFormItemProps,
      }}
    >
      <AppNewSelect label={label} options={CURRENCIES} {...textFieldProps} />
    </AppFormSection>
  );
}
