import styled from 'styled-components';
import { DeleteButton } from './DeleteButton';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { humanizeIndex } from '../../../../../../../../../../../../../../../../lib/util/helpers';
import { useFinderColors } from '../../../../../../../../../../hooks/useFinderColors';
import { TextFieldWithInfoIcon } from '../../../../../../../../../../features/TextFieldWithInfoIcon';
import { QuestionnaireSelect } from '../../../../../../../../../../features/QuestionnaireSelect';
import { QuestionnaireVerticalGripHandle } from '../../../../../../../../../../features/QuestionnaireVerticalGripHandle';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../../../../../../lib/models/Questionnaire';

export function BulletPointRow({
  providedDraggable,
  productIndex,
  structureItemIndex,
  item,
  index,
  dataPath,
  rightColumnType,
  bulletPointIcon,
  bulletPointIconColor,
  disabled,
  ...rest
}) {
  const {
    finder: {
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);

  const { label } = useLocalization().translations.unique.formItems.bulletPoint;
  const itemPath = `${dataPath}[${index}]`;
  const areRightBulletPointColumnIcons = rightColumnType === ProductRightBulletPointColumnType.ICON;
  const genericFieldsProps = { style: { width: '100%' } };
  const bulletPointNumber = humanizeIndex(index);
  const colors = useFinderColors();
  const fill = colors[bulletPointIconColor];

  const createSvg = () => {
    return { __html: bulletPointIcon?.svgContent };
  };

  const bulletIcon = [...customIcons, ...defaultIcons].find(bulletPointIconItem => bulletPointIconItem.id === bulletPointIcon?.value);

  return (
    <Root {...rest}>
      <Left>
        <Handle disabled={disabled} dragHandleProps={providedDraggable.dragHandleProps} />
        <TextFieldWithInfoIcon
          disabled={disabled}
          label={label({ index: bulletPointNumber })}
          data={item.left}
          dataPath={`${itemPath}.left`}
          {...genericFieldsProps}
        />
      </Left>
      <Right>
        {areRightBulletPointColumnIcons && bulletPointIcon?.svgContent ? (
          <QuestionnaireSelect
            selectProps={{
              disabled: true,
              components: { DropdownIndicator: () => null },
              value: {
                title:
                  bulletPointIcon?.svgContent && !bulletPointIcon?.svgContent.includes('fill') ? (
                    // eslint-disable-next-line react/no-danger
                    <div className='bullet_select_icon_svg' dangerouslySetInnerHTML={createSvg()} style={{ fill: fill || '#000000' }} />
                  ) : (
                    <img style={{ width: 24, height: 24 }} src={bulletIcon.s3Url} alt='bullet icon' />
                  ),
              },
            }}
          />
        ) : (
          <TextFieldWithInfoIcon
            disabled={disabled}
            label={label({ index: bulletPointNumber })}
            data={item.rightText}
            dataPath={`${itemPath}.rightText`}
            {...genericFieldsProps}
          />
        )}

        <DeleteButton bulletPointIndex={index} dataPath={dataPath} disabled={disabled} />
      </Right>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '18px',

  '& > *:first-child': { flex: 6.5, display: 'flex' },
  '& > *:last-child': { flex: 3.5, display: 'flex', justifyContent: 'flex-end' },
});

const Left = styled('div')({
  position: 'relative',
});

const Handle = styled(QuestionnaireVerticalGripHandle)({
  position: 'absolute',
  top: '11px',
  left: '-20px',
  cursor: 'pointer',
});

const Right = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '16px',
});
