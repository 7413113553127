/* eslint-disable max-lines */
import styles from '../Chapter.module.scss';
import { QuestionnaireSwitch } from '../../../../../features/QuestionnaireSwitch';
import { AppSegmented } from '../../../../../../../../../../../ui/AppSegmented';
import { ProductsPriority } from '../ProductsPriority';
import { DroppableFieldWrapper } from '../../DroppableItems/DroppableFieldWrapper';
import { Droppable } from 'react-beautiful-dnd';
import { ImageMultiSelect } from 'src/processes/CMS/processes/management/features/ImageMultiSelect';
import { DoubleConnectionLine } from '../../../../../../../../../../../ui/icons/DoubleConnectionLine';
import { InfoIconTextarea } from '../../../../../../../features/form-items/InfoIconTextarea';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { SelectImages } from '../../../../../../../../../../../lib/models/SelectImages';
import { defaultChapterBackgroundImage } from '../../../../../../../../../lib/models/DefaultStylesFontsAndColors';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { handleTranslation } from '../../../../../../../../../../../lib/util/helpers';
import { useDispatch, useSelector } from 'react-redux';

const ShortProductName = {
  DEFAULT: false,
  CUSTOM: true,
};

const questionnaireSwitchStyle = { marginRight: 20 };

/**
 * @desc StandardChapter - composer element
 * @param {Object} item
 * @param {Number} itemIndex
 * @param {Object} firstChapter
 * @returns {JSX.Element}
 */

// eslint-disable-next-line max-lines-per-function
export function StandardChapter({ item = {}, itemIndex, firstChapter }) {
  const dispatch = useDispatch();

  const {
    questionnaireStructure,
    finder: {
      finderSettings: { media },
    },
  } = useSelector(({ finders }) => finders);

  const { templateHeadline, templateSubline, selectImage, templateInfo, firstChapterButton, templateChapterShowProducts } =
    useLocalization().translations.unique.formItems;
  const { fullName, shortName } = useLocalization().translations.unique.pages.questionnaire.content;

  const inActiveMediaOptions = [
    {
      id: SelectImages.DEFAULT,
      s3Url: defaultChapterBackgroundImage,
      targetType: 'FINDER',
      targetId: null,
      originalFileName: 'default.jpg',
      svgContent: null,
    },
    {
      id: SelectImages.LOW_SATURATION,
      s3Url: 'https://kontofinder.s3.eu-central-1.amazonaws.com/images/finder/5753/11000/5757421191014663139Rectangle%204898.jpg',
      targetType: 'FINDER',
      targetId: null,
      originalFileName: selectImage.useActive(),
      svgContent: null,
    },
    ...media,
  ];

  const handleChangeFieldValue = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, [fieldName]: { ...el.content[fieldName], value } } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeFieldInfoIconValue = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? { ...el, content: { ...el.content, [fieldName]: { ...el.content[fieldName], infoIcon: { ...el.content[fieldName].infoIcon, value } } } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleDeleteInfoIcon = fieldName => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, [fieldName]: { ...el.content[fieldName], infoIcon: '' } } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeActiveBgField = activeBgField => {
    const structure = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: {
              ...el.content,
              selectBackgroundImageActive: activeBgField,
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure }));
  };

  const handleChangeInactiveBgField = inactiveBgField => {
    const structure = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: {
              ...el.content,
              selectBackgroundImageInactive: inactiveBgField,
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure }));
  };

  const toggleShowProductsAfterComplete = value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, showProductsAfterComplete: value } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const options = [
    { value: ShortProductName.DEFAULT, label: fullName },
    { value: ShortProductName.CUSTOM, label: shortName },
  ].map(el => ({ ...el, label: handleTranslation(el.label) }));

  const handleChangeTypeName = (value, fieldName) => {
    const result = questionnaireStructure.map(el => {
      if (el.id === item.id) {
        const { isShortProductName, ...rest } = el.content;
        return { ...el, content: !value ? { ...el.content, [fieldName]: true } : { ...rest } };
      }
      return el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <>
      {Object.prototype.hasOwnProperty.call(item.content, 'showProductsAfterComplete') && (
        <StyledSwitchesWrapper>
          <QuestionnaireSwitch
            style={questionnaireSwitchStyle}
            labelProps={{ textValue: templateChapterShowProducts.label }}
            isOn={item.content.showProductsAfterComplete}
            onChange={toggleShowProductsAfterComplete}
          />

          {item.content.showProductsAfterComplete && (
            <AppSegmented
              options={options}
              onChange={() => handleChangeTypeName(item.content?.isShortProductName, 'isShortProductName')}
              value={Boolean(item.content?.isShortProductName)}
            />
          )}
        </StyledSwitchesWrapper>
      )}
      <ProductsPriority structureItemIndex={itemIndex} structureItem={item} disabled={!item.content.showProductsAfterComplete} />
      <DroppableFieldWrapper
        itemId={item.id}
        labelField={templateHeadline.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.headline.infoIcon && 'INFO_ICON'}
        valueObject={item.content.headline}
        onChangeField={handleChangeFieldValue}
        onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
        onDeleteInfoIcon={handleDeleteInfoIcon}
        fieldName='headline'
        infoIcon={item.content.headline.infoIcon}
      />
      <DroppableFieldWrapper
        itemId={item.id}
        labelField={templateSubline.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.subline.infoIcon && 'INFO_ICON'}
        valueObject={item.content.subline}
        onChangeField={handleChangeFieldValue}
        onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
        onDeleteInfoIcon={handleDeleteInfoIcon}
        fieldName='subline'
        infoIcon={item.content.subline.infoIcon}
      />
      {firstChapter.id === item.id && (
        <DroppableFieldWrapper
          itemId={item.id}
          labelField={firstChapterButton.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.chapterButton.infoIcon && 'INFO_ICON'}
          valueObject={item.content.chapterButton}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
          onDeleteInfoIcon={handleDeleteInfoIcon}
          fieldName='chapterButton'
          infoIcon={item.content.chapterButton.infoIcon}
        />
      )}
      <Droppable droppableId={`selectBackgroundImageActive_${item.id}`} type={!item.content.selectBackgroundImageActive?.infoIcon && 'INFO_ICON'}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={styles.wrapper_image}>
            <ImageMultiSelect
              valueObject={item.content.selectBackgroundImageActive}
              label={selectImage.activeImageLabel}
              mediaOptions={media}
              onChange={handleChangeActiveBgField}
            />
            <ImageMultiSelect
              valueObject={item.content.selectBackgroundImageInactive}
              label={selectImage.inactiveImageLabel}
              mediaOptions={inActiveMediaOptions}
              onChange={handleChangeInactiveBgField}
              selectBackgroundImageActive={item.content.selectBackgroundImageActive}
            />
            {(item.content.selectBackgroundImageActive.infoIcon || snapshot.isDraggingOver) && (
              <div className={styles.info_icon_line}>
                <DoubleConnectionLine stroke={item.content.selectBackgroundImageActive?.infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
              </div>
            )}
            {(item.content.selectBackgroundImageActive?.infoIcon || snapshot.isDraggingOver) && (
              <InfoIconTextarea
                valueObject={item.content.selectBackgroundImageActive.infoIcon || { value: '' }}
                label={templateInfo.label}
                onChange={handleChangeFieldInfoIconValue('selectBackgroundImageActive')}
                onClickSuffix={() => handleDeleteInfoIcon('selectBackgroundImageActive')}
              />
            )}
          </div>
        )}
      </Droppable>
    </>
  );
}

const StyledSwitchesWrapper = styled('div')({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
});
