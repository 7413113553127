import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../ui/text/AppText';
import { DeleteIcon } from '../../../../../../ui/icons/DeleteIcon';
import { PreloaderIcon } from '../../../../../../ui/icons/PreloaderIcon';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import './styles.scss';

export function UploadedMediaBox({ media, deleteOnClick }) {
  const { generic } = useLocalization().translations.shared;

  const deleteClick = event => {
    event.stopPropagation();
    deleteOnClick();
  };

  return media.s3Url ? (
    <div className='media_wrap' style={{ backgroundImage: `url('${media.s3Url}')` }}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className='media_hidden_box' onClick={event => event.stopPropagation()}>
        <AppBasicButton style={{ display: 'flex', alignItems: 'center' }} onClick={deleteClick}>
          <DeleteIcon className='primary_color media_hidden_box_icon' />
          <AppText className='bold primary_color' textValue={generic.delete} />
        </AppBasicButton>
      </div>
    </div>
  ) : (
    <div className='media_wrap media_wrap__loading'>
      <PreloaderIcon className='file_uploader' style={{ width: 24, height: 24 }} />
    </div>
  );
}
