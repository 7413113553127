/* eslint-disable no-magic-numbers */
import { AppTextArea } from '../../../../../../ui/AppTextArea';
import styled from 'styled-components';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { DndInfoIcon } from '../../../../../../ui/icons/DndInfoIcon';
import { useEffect, useState } from 'react';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { DeleteIcon } from '../../../../../../ui/icons/DeleteIcon';
import { useSelector } from 'react-redux';

const DELAY = 100;
let timer = null;

const MAX_TEXTAREA_HEIGHT = 110;

const getTextareaHeight = value => {
  if (value === 0 || value === 1) {
    return 40;
  }
  if (value === 2) {
    return 35 * value;
  }
  if (value === 3) {
    return 30 * value;
  }
  if (value === 4) {
    return 25 * value;
  }
  if (value === 5) {
    return 22 * value;
  }
  return MAX_TEXTAREA_HEIGHT;
};

export function InfoIconTextarea({ valueObject, label, onChange, onClickSuffix, ...rest }) {
  const { textFieldProps: { disabled, ...restTextFieldProps } = {}, ...restProps } = rest;
  const { isViewMode } = useSelector(({ finders }) => finders);
  const [value, setValue] = useState('');

  const numberOfParagraphs = valueObject.value.split('\n').length;

  useEffect(() => {
    setValue(valueObject.value);
  }, [valueObject.value]);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(value);
    }, DELAY);
  };

  return (
    <Wrapper>
      <InfoIconTextareaStyled
        numberOfParagraphs={numberOfParagraphs}
        formItemProps={{
          rules: [{ required: false }, { max: 1800 }],
        }}
        textFieldProps={{
          onChange: event => handleChange(event),
          value,
          label,
          as: AppTextArea,
          bordered: false,
          style: {
            marginBottom: 28,
            height: getTextareaHeight(numberOfParagraphs),
            resize: 'none',
            paddingTop: '0px !important',
            overflow: numberOfParagraphs > 5 ? 'auto' : 'hidden',
          },
          disabled,
          ...restTextFieldProps,
        }}
        labelLeft='40px'
        disabled
        {...restProps}
      />
      <DndInfoIcon style={{ marginRight: 5, width: 18, height: 18, position: 'absolute', left: 14, top: 12 }} />
      <AppBasicButton
        onClick={onClickSuffix}
        disabled={disabled || isViewMode}
        style={{ marginRight: 5, width: 18, height: 18, position: 'absolute', right: 10, top: 12, cursor: 'pointer !important', zIndex: 10 }}
      >
        <DeleteIcon />
      </AppBasicButton>
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  position: 'relative',
});

const InfoIconTextareaStyled = styled(AppFormSection)(props => ({
  '.AppInputInner': {
    height: 'unset',
  },
  textarea: {
    paddingTop: '10px !important',
    paddingLeft: 40,
    paddingRight: 40,
    overflow: props.numberOfParagraphs > 5 ? 'auto' : 'hidden',
    height: getTextareaHeight(props.numberOfParagraphs),
  },
}));
