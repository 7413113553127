import { Checkbox } from 'antd';
import { TranslatableBox } from './boxes/TranslatableBox';
import classNames from 'classnames';
import './AppCheckbox.sass';

export function AppCheckbox({ as, style, className, ...rest }) {
  return (
    <TranslatableBox
      className={classNames('AppCheckbox flex flex-y-c', className, {
        disabled: rest?.disabled,
      })}
      style={{ lineHeight: 1, ...style }}
      {...rest}
      as={Checkbox}
    />
  );
}
