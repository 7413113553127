import { all, call, debounce, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addFinderError,
  addFinderRequest,
  addFinderSuccess,
  applyFinderArrangesError,
  applyFinderArrangesRequest,
  checkSMTPError,
  checkSMTPRequest,
  checkSMTPSuccess,
  closeFinderModal,
  deleteFinderError,
  deleteFinderRequest,
  deleteFinderSuccess,
  duplicateFinderError,
  duplicateFinderRequest,
  duplicateFinderSuccess,
  editFinderDesignSettingsError,
  editFinderDesignSettingsRequest,
  editFinderDesignSettingsSuccess,
  editFinderError,
  editFinderGeneralSettingsError,
  editFinderGeneralSettingsRequest,
  editFinderGeneralSettingsSuccess,
  editFinderRequest,
  editFinderSuccess,
  getFinderError,
  getFinderRequest,
  getFinderSuccess,
  getFinderRulesError,
  getFinderRulesRequest,
  getFinderRulesSuccess,
  getFinderTemplatesError,
  getFinderTemplatesRequest,
  getFinderTemplatesSuccess,
  getTemplateLayoutsError,
  getTemplateLayoutsRequest,
  getTemplateLayoutsSuccess,
  getTenantDomainsSuccess,
  getTenantError,
  getTenantProductsError,
  getTenantProductsRequest,
  getTenantProductsSuccess,
  getTenantRequest,
  getTenantsDomainsRequest,
  getTenantSuccess,
  getTenantThemesError,
  getTenantThemesRequest,
  getTenantThemesSuccess,
  loadFinderListError,
  loadFinderListRequest,
  loadFinderListSuccess,
  publishFinderError,
  publishFinderRequest,
  publishFinderSuccess,
  redoHistory,
  removeFinderFiltersError,
  removeFinderFiltersRequest,
  saveQuestionnaireStructureError,
  setCurrentUserId,
  setFinderStatusError,
  setFinderStatusRequest,
  setFinderStatusSuccess,
  setQuestionnaireActivityRequest,
  submitCanChangePublished,
  undoHistory,
  updateQuestionnaireStructure,
  updateQuestionnaireStructureSuccess,
  uploadMediaError,
  uploadMediaRequest,
  uploadMediaSuccess,
} from '../slices/findersSlice';
import { setTitle } from '../slices/titleSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getFindersFromApi, getTenantByIdApiRequest, withXTenantHeader, getRulesApiRequest } from '../../api';
import { ROUTER_PATHS } from '../../util/router/router-paths';
import { checkIsTryingEditingPublishedWhenDraftIsDifferent } from '../selectors/findersSelectors';
import { getTenantDomainsError } from '../slices/tenantsSlice';
import { setPreviewStructure } from '../slices/previewSlice';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    finders: {
      sorting,
      filtering,
      pagination,
      tenant: { id: tenantId },
    },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });
  const response = yield call(getFindersFromApi, tenantId, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(loadFinderListSuccess({ finders: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* loadFinders({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(loadFinderListError({ error }));
  }
}

function* getTenantById({ payload: tenantId }) {
  try {
    const res = yield call(getTenantByIdApiRequest, tenantId);
    if (res?.data?.name) {
      yield put(getTenantSuccess(res.data));
      const {
        headerTitle: {
          headerTitle: { title },
        },
      } = yield select();
      yield put(setTitle({ title, subTitle: res.data.name }));
    }
  } catch (error) {
    yield put(getTenantError(error));
  }
}

function* setTenantDomainsList({ payload: tenantId }) {
  try {
    const res = yield call(axiosInstance.get, `/tenant/database-config/${tenantId}`, withXTenantHeader(tenantId));
    if (res?.data) {
      yield put(getTenantDomainsSuccess(res.data.domains));
    }
  } catch (error) {
    yield put(getTenantDomainsError(error));
  }
}

function* setFinderStatus({ payload: { finderId, status } }) {
  const {
    finders: {
      tenant: { id: tenantId },
    },
  } = yield select();
  try {
    yield call(axiosInstance.patch, `finder/${finderId}/set-active?active=${status}`, {}, withXTenantHeader(tenantId));
    yield put(setFinderStatusSuccess({ success: { messageType: setFinderStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setFinderStatusError({ error: { ...error, url: `${ROUTER_PATHS.settings(finderId)}?X-TENANT=${tenantId}` } }));
  }
}

function* addFinder({ payload }) {
  try {
    yield call(axiosInstance.post, '/finder', payload.finder, withXTenantHeader(payload.tenantId));
    yield put(addFinderSuccess({ success: { messageType: addFinderSuccess.type } }));
    yield put(closeFinderModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addFinderError({ error }));
  }
}

function* editFinder({ payload: { finder, tenantId } }) {
  try {
    yield call(axiosInstance.put, `/finder/${finder.id}`, finder, withXTenantHeader(tenantId));
    yield put(editFinderSuccess({ success: { messageType: editFinderSuccess.type } }));
    yield put(closeFinderModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(editFinderError({ error }));
  }
}

function* editFinderGeneralSettings({ payload: { finder, tenantId } }) {
  try {
    const res = yield call(axiosInstance.put, `/finder/${finder.id}`, finder, withXTenantHeader(tenantId));
    yield put(editFinderGeneralSettingsSuccess({ finder: res.data, success: { messageType: editFinderGeneralSettingsSuccess.type } }));
  } catch (error) {
    yield put(editFinderGeneralSettingsError({ error }));
  }
}

function* editFinderDesignSettings({ payload: { finder, tenantId } }) {
  try {
    const res = yield call(axiosInstance.patch, `/finder/update-finder-settings/${finder.id}`, finder, withXTenantHeader(tenantId));
    yield put(editFinderDesignSettingsSuccess({ finder: res.data, success: { messageType: editFinderDesignSettingsSuccess.type } }));
  } catch (error) {
    yield put(editFinderDesignSettingsError({ error }));
  }
}
function* deleteFinder({ payload }) {
  try {
    yield call(axiosInstance.delete, `/finder/${payload.finderId}/delete`, withXTenantHeader(payload.tenantId));
    yield put(deleteFinderSuccess({ success: { messageType: deleteFinderSuccess.type } }));
    yield put(closeFinderModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteFinderError({ error }));
  }
}

function* duplicateFinder({ payload }) {
  try {
    yield call(axiosInstance.post, '/finder/duplicate', payload.finder, withXTenantHeader(payload.destinationTenantId));
    yield put(duplicateFinderSuccess({ success: { messageType: duplicateFinderSuccess.type } }));
    yield put(closeFinderModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(duplicateFinderError({ error }));
  }
}

function* getFinderById({ payload: { id: finderId, tenantId } }) {
  try {
    const res = yield call(axiosInstance.get, `/finder/${finderId}`, withXTenantHeader(tenantId));
    if (res?.data?.name) {
      const {
        auth: {
          user: { id: userId },
        },
        finders: { selectedQuestionnaireVersion },
      } = yield select();

      yield put(setCurrentUserId(userId));
      yield put(getFinderSuccess({ finder: res.data, questionnaireStructure: JSON.parse(res.data.questionnaireDraft), tenant: { id: tenantId } }));
      yield put(setTitle({ subTitle: res.data.name }));

      const previewStructureData = {
        brand: res?.data.brand,
        finderSettings: res?.data.finderSettings,
        questionnaireStructure: res?.data.questionnaire,
        questionnaireStructureDraft: res?.data.questionnaireDraft,
        selectedQuestionnaireVersion,
        finderId: res?.data.id,
        tenantId,
        rules: res?.data.rules,
      };

      yield put(setPreviewStructure(previewStructureData));
    }
  } catch (error) {
    yield put(getFinderError(error));
  }
}

function* fetchTenantThemes({ payload: { queryParams, requestBody } }) {
  try {
    const res = yield call(axiosInstance.post, '/brand/get-brands', requestBody, { params: queryParams });
    if (res?.data) {
      yield put(getTenantThemesSuccess(res.data.content));
    }
  } catch (error) {
    yield put(getTenantThemesError({ error }));
  }
}

function* uploadMedia({ payload: { formData, afterUploadAction, tenantId, setUploading } }) {
  try {
    const res = yield call(axiosInstance.post, 'media', formData, withXTenantHeader(tenantId));
    yield put(uploadMediaSuccess());
    const {
      finders: { questionnaireStructure },
    } = yield select();
    yield afterUploadAction(res.data, questionnaireStructure);
  } catch (error) {
    yield put(uploadMediaError({ error }));
  } finally {
    if (setUploading) {
      yield setUploading(false);
    }
  }
}

function* checkSMTP({ payload: { settingsSMTP, tenantId } }) {
  try {
    yield call(axiosInstance.post, 'lib/check-smtp-server', settingsSMTP, withXTenantHeader(tenantId));
    yield put(checkSMTPSuccess({ success: { messageType: checkSMTPSuccess.type } }));
  } catch (error) {
    yield put(checkSMTPError({ error }));
  }
}

function* publishFinder({ payload: { finderId, tenantId } }) {
  try {
    const res = yield call(axiosInstance.patch, `finder/${finderId}/publish`, {}, withXTenantHeader(tenantId));

    if (res?.data?.name) {
      yield put(
        publishFinderSuccess({
          success: { messageType: publishFinderSuccess.type },
          finder: res.data,
          questionnaireStructure: JSON.parse(res.data.questionnaireDraft),
        })
      );
    }
  } catch (error) {
    yield put(publishFinderError({ error: { ...error, url: `${ROUTER_PATHS.settings(finderId)}?X-TENANT=${tenantId}` } }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyFinderArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeFinderFiltersError({ error }));
  }
}

function* getTemplates({ payload: { queryParams, requestBody } }) {
  try {
    const res = yield call(axiosInstance.post, '/template/get-templates', requestBody, { params: queryParams });
    if (res?.data) {
      yield put(getFinderTemplatesSuccess(res.data.content));
    }
  } catch (error) {
    yield put(getFinderTemplatesError({ error }));
  }
}

function* getTenantProducts({ payload: { queryParams, tenantId } }) {
  try {
    const res = yield call(axiosInstance.post, '/product/get-products', {}, { params: queryParams, ...withXTenantHeader(tenantId) });
    if (res?.data) {
      yield put(getTenantProductsSuccess(res.data.content));
    }
  } catch (error) {
    yield put(getTenantProductsError({ error }));
  }
}

function* getTemplateLayouts({ payload: { queryParams, requestBody } }) {
  try {
    const res = yield call(axiosInstance.post, '/layout/get-layouts', requestBody, { params: queryParams });
    if (res?.data) {
      yield put(getTemplateLayoutsSuccess(res.data.content));
    }
  } catch (error) {
    yield put(getTemplateLayoutsError({ error }));
  }
}

function* saveQuestionnaire() {
  const {
    finders,
    finders: {
      finder,
      questionnaireStructure,
      tenant: { id: tenantId },
      isViewMode,
      selectedQuestionnaireVersion,
      tenant,
    },
  } = yield select();

  if (isViewMode || checkIsTryingEditingPublishedWhenDraftIsDifferent(finders)) {
    return;
  }

  try {
    const res = yield call(
      axiosInstance.patch,
      `finder/update-questionnaire-draft/${finder.id}/`,
      {
        ...finder,
        questionnaireDraft: JSON.stringify(questionnaireStructure),
        editing: true,
      },
      withXTenantHeader(tenantId)
    );

    if (res?.data) {
      yield put(updateQuestionnaireStructureSuccess(res?.data));

      const previewStructureData = {
        brand: res?.data.brand,
        finderSettings: res?.data.finderSettings,
        questionnaireStructure: res?.data.questionnaire,
        questionnaireStructureDraft: res?.data.questionnaireDraft,
        selectedQuestionnaireVersion,
        tenantId: tenant.id,
        finderId: res?.data.id,
        rules: res?.data.rules,
      };

      yield put(setPreviewStructure(previewStructureData));
    }
  } catch (error) {
    yield put(saveQuestionnaireStructureError({ error }));
  }
}
function* setQuestionnaireActivity({ payload: { tenantId, finderId } } = {}) {
  try {
    yield call(
      axiosInstance.patch,
      `finder/update-questionnaire-draft/${finderId}/`,
      { id: finderId, lastEditedDraftTimestamp: new Date().toISOString(), editing: true },
      withXTenantHeader(tenantId)
    );
  } catch {
    //
  }
}

function* fetchFinderRules({ payload: { tenantId, config } }) {
  try {
    const res = yield call(getRulesApiRequest, tenantId, config);
    if (res?.data) {
      yield put(getFinderRulesSuccess(res.data.content));
    }
  } catch (error) {
    yield put(getFinderRulesError({ error }));
  }
}

const DEBOUNCE_TIME = 1000;

/**
 * @desc function generator - observer for all finder's sagas functions
 */
export function* watchFindersManagement() {
  yield all([
    takeLatest(loadFinderListRequest.type, loadFinders),
    takeLatest(applyFinderArrangesRequest.type, applyArranges),
    takeLatest(removeFinderFiltersRequest.type, removeFilters),
    takeLatest(getTenantRequest.type, getTenantById),
    takeLatest(getFinderRequest.type, getFinderById),
    takeLatest(setFinderStatusRequest.type, setFinderStatus),
    takeLatest(addFinderRequest.type, addFinder),
    takeLatest(editFinderRequest.type, editFinder),
    takeLatest(editFinderDesignSettingsRequest.type, editFinderDesignSettings),
    takeLatest(editFinderGeneralSettingsRequest.type, editFinderGeneralSettings),
    takeLatest(deleteFinderRequest.type, deleteFinder),
    takeLatest(duplicateFinderRequest.type, duplicateFinder),
    takeLatest(getTenantThemesRequest.type, fetchTenantThemes),
    takeEvery(uploadMediaRequest.type, uploadMedia),
    takeLatest(checkSMTPRequest.type, checkSMTP),
    takeLatest(publishFinderRequest.type, publishFinder),
    takeLatest(getFinderTemplatesRequest.type, getTemplates),
    takeLatest(getTenantProductsRequest.type, getTenantProducts),
    takeLatest(getTemplateLayoutsRequest.type, getTemplateLayouts),
    takeLatest(setQuestionnaireActivityRequest.type, setQuestionnaireActivity),
    takeLatest(getTenantsDomainsRequest.type, setTenantDomainsList),
    takeLatest(getFinderRulesRequest.type, fetchFinderRules),
    debounce(DEBOUNCE_TIME, updateQuestionnaireStructure.type, saveQuestionnaire),
    debounce(DEBOUNCE_TIME, submitCanChangePublished.type, saveQuestionnaire),
    debounce(DEBOUNCE_TIME, undoHistory.type, saveQuestionnaire),
    debounce(DEBOUNCE_TIME, redoHistory.type, saveQuestionnaire),
  ]);
}
