import { changeNumberValue } from './change_number_value';

export const getAnswerScoreValue = (answerScoreBlocks, allSimpleAnswers) => {
  return answerScoreBlocks.reduce((accumulator, answerScoreBlock) => {
    const { conditionValue, scoreAnswer, resultOperator, value } = answerScoreBlock;
    const { elements, product } = scoreAnswer;
    const blockAnswers = elements.map(el => allSimpleAnswers.find(answer => answer.id === el.answerId));
    const blockAnswersIds = new Set(blockAnswers.map(answer => answer.id));

    const oldAnswers = accumulator.filter(answer => blockAnswersIds.has(answer.id));
    const filteredAnswers = accumulator.filter(answer => !blockAnswersIds.has(answer.id));

    if (conditionValue) {
      const updatedAnswers = blockAnswers.map(answer => {
        const originalScoreProduct = answer.products.find(prod => prod.id === product.value);
        const originalScore = originalScoreProduct ? originalScoreProduct.score : 0;
        const ruleScoreValue = changeNumberValue(value, resultOperator, scoreAnswer, { value: originalScore });

        const updatedProduct = originalScoreProduct
          ? { ...originalScoreProduct, score: ruleScoreValue, originalScore: originalScoreProduct.score, ruleScore: true }
          : { id: product.value, score: ruleScoreValue, originalScore: 0, ruleScore: true };

        const oldAnswer = oldAnswers.find(answ => answ.id === answer.id);
        const oldProducts = oldAnswer ? oldAnswer.products : answer.products;
        const products = oldProducts.filter(prod => prod.id !== updatedProduct.id).concat([updatedProduct]);

        return { ...answer, products };
      });

      const newAnswers = updateAnswersProperties(updatedAnswers, oldAnswers, 'products');
      return [...filteredAnswers, ...newAnswers];
    }

    if (!conditionValue) {
      const updatedAnswers = blockAnswers.map(answer => {
        const oldAnswer = oldAnswers.find(answ => answ.id === answer.id) || answer;
        const oldProducts = oldAnswer.products;
        const oldProduct = oldProducts.find(prod => prod.id === product.value);
        const updatedProduct = oldProduct || { id: product.value, score: 0, ruleScore: false };
        const products = oldProducts.filter(prod => prod.id !== updatedProduct.id).concat([updatedProduct]);

        return { ...answer, products };
      });

      const newAnswers = updateAnswersProperties(updatedAnswers, oldAnswers, 'products');
      return [...filteredAnswers, ...newAnswers];
    }

    return accumulator;
  }, []);
};

export const updateAnswersProperties = (updatedAnswers, oldAnswers, fieldName) => {
  return updatedAnswers.map(answer => {
    const oldAnswer = oldAnswers.find(answ => answ.id === answer.id);
    const updatedFields = oldAnswer ? updateAnswerArrayValues(answer[fieldName], oldAnswer[fieldName]) : answer[fieldName];
    return { ...answer, [fieldName]: updatedFields };
  });
};

const updateAnswerArrayValues = (newArr, oldArr) => {
  const newArrIds = new Set(newArr.map(el => el.id));
  const filteredOldProducts = oldArr.filter(el => !newArrIds.has(el.id));
  return [...filteredOldProducts, ...newArr];
};
