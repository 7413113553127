import classNames from 'classnames';
import { AppBox } from './boxes/AppBox';
import { AppText } from './text/AppText';
import { Switch } from 'antd';

export function AppSwitch({ switchProps, labelProps = {}, disabled, style, className, ...rest }) {
  return (
    <AppBox disabled={disabled} className={classNames('align_items_center', className)} style={{ gap: '8px', ...style }} {...rest}>
      <Switch size='small' {...switchProps} disabled={disabled} />
      <AppText {...labelProps} />
    </AppBox>
  );
}
