import { AppBox } from '../../../../../../../../../../../../../ui/boxes/AppBox';
import { ProductsIcon } from '../../../../../../../../../../../../../ui/icons/ProductsIcon';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { CardSectionHeader } from '../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireBasicButton } from '../../../../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../../../ui/icons/DeleteIcon';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { AppSegmented } from '../../../../../../../../../../../../../ui/AppSegmented';
import { handleTranslation } from '../../../../../../../../../../../../../lib/util/helpers';

const DELETE_PRODUCTS_AMOUNT = 1;

const ShortProductName = {
  DEFAULT: false,
  CUSTOM: true,
};

export function ProductNameSectionHeader({ data, productIndex, structureItemIndex }) {
  const { productName, fullName, shortName, showPicture: showPictureText } = useLocalization().translations.unique.pages.questionnaire.content;
  const { updateStructure } = useUpdateStructure();

  const toggleShowPicture = () => {
    updateStructure(draft => {
      const { productNameSection } = draft[structureItemIndex].content.products[productIndex];
      productNameSection.showPicture = !productNameSection.showPicture;
    });
  };

  const deleteProduct = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products.splice(productIndex, DELETE_PRODUCTS_AMOUNT);
    });
  };

  const handleChangeTypeName = value => {
    updateStructure(draft => {
      // eslint-disable-next-line no-param-reassign
      draft[structureItemIndex].content.products[productIndex].productNameSection.isShortProductName = value;
    });
  };

  const options = [
    { value: ShortProductName.DEFAULT, label: fullName },
    { value: ShortProductName.CUSTOM, label: shortName },
  ].map(item => ({ ...item, label: handleTranslation(item.label) }));

  return (
    <CardSectionHeader>
      <AppBox $flexY style={{ gap: '8px' }}>
        <ProductsIcon fill='#5C5C6E' />
        <AppText strong textValue={productName} />
      </AppBox>
      <AppSegmented options={options} onChange={() => handleChangeTypeName(!data.isShortProductName)} value={data.isShortProductName} />
      <AppBox $flexY style={{ gap: '15px' }}>
        <QuestionnaireSwitch labelProps={{ textValue: showPictureText }} isOn={data.showPicture} onChange={toggleShowPicture} />
        <QuestionnaireBasicButton onClick={deleteProduct}>
          <DeleteIcon />
        </QuestionnaireBasicButton>
      </AppBox>
    </CardSectionHeader>
  );
}
