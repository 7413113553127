import { StatusHeaderCell } from './StatusHeaderCell';

export function useStatusColumn(props) {
  const { columnProps, arrangedId, ...rest } = props;

  return {
    dataIndex: arrangedId,
    title: <StatusHeaderCell {...rest} arrangedId={arrangedId} />,
    ...columnProps,
  };
}
