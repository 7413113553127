import styled from 'styled-components';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../icons/ChevronUpIcon';

export function DropdownIndicator({ cx, ...rest }) {
  return <Root {...rest}>{rest.selectProps.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Root>;
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '11px',
  'svg, svg  *': {
    fill: '#5C5C6E',
  },
});
