import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { Finder } from '../../lib/Finder';
import { useFinderNumberColumn } from '../../components/sort';

export function useFinderNumberOfQuestionsColumn() {
  const { questions: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderNumberColumn({
    title,
    arrangedId: Finder.COUNT_QUESTIONS,
    columnProps: {
      width: 140,
    },
  });
}
