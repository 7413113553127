import { AddContactFormFieldButton } from './AddContactFormFieldButton';
import { QuestionnaireCheckbox } from '../../../../../features/QuestionnaireCheckbox';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { ContactFormField } from '../../../../../../../../../../../lib/models/Questionnaire';

const ALLOWED_TYPES = new Set([
  ContactFormField.TEXT,
  ContactFormField.EMAIL,
  ContactFormField.PHONE_NUMBER,
  ContactFormField.DATE,
  ContactFormField.TIME,
  ContactFormField.DROPDOWN,
  ContactFormField.DROPDOWN_WITH_ACTION, // Client said this one should be here as well, but it is not logical...
]);

function canShowFullWidthCheckbox(templateContent, rowIndex) {
  const fieldType = templateContent?.formRowList?.[rowIndex]?.fieldList?.[0]?.type;
  return templateContent?.formRowList?.[rowIndex]?.fieldList?.length === 1 && ALLOWED_TYPES.has(fieldType);
}

const MAX_FIELDS_IN_ROW = 2;

function canShowAddButton(templateContent, rowIndex) {
  const firstFieldType = templateContent?.formRowList?.[rowIndex]?.fieldList[0]?.type;
  if ([ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION, ContactFormField.CHECKBOX].includes(firstFieldType)) {
    return false;
  }
  return templateContent?.formRowList?.[rowIndex]?.fieldList?.length < MAX_FIELDS_IN_ROW;
}

const styles = { marginTop: '16px' };

export function ContactFormRowContentBottom(props) {
  const { templateContent, rowIndex, structureItemIndex } = props;
  const { updateStructure } = useUpdateStructure();
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const isFullWidth = templateContent?.formRowList?.[rowIndex]?.fieldList?.[0]?.isFullWidth;

  const toggleCheckbox = () => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[0].isFullWidth`, !isFullWidth);
    });
  };

  return (
    <div className='flex flex-y flex-sb' style={styles}>
      {canShowAddButton(templateContent, rowIndex) && <AddContactFormFieldButton {...props} isFullWidth={isFullWidth} />}
      {canShowFullWidthCheckbox(templateContent, rowIndex) && (
        <QuestionnaireCheckbox textValue={contentText.fullWidth} checked={isFullWidth} onChange={toggleCheckbox} />
      )}
    </div>
  );
}
