import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getVariablesRequest,
  getVariablesError,
  getVariablesSuccess,
  addVariableRequest,
  addVariableError,
  addVariableSuccess,
  editVariableRequest,
  editVariableError,
  editVariableSuccess,
  deleteVariableRequest,
  deleteVariableSuccess,
  deleteVariableError,
} from '../slices/variablesSlice';
import { deleteVariablesApiRequest, addVariablesApiRequest, getVariablesApiRequest, updateVariableApiRequest } from '../../api';

function* fetchVariables() {
  try {
    const {
      finders: {
        tenant: { id: tenantId },
        finder: { id: finderId },
      },
    } = yield select();

    const response = yield call(getVariablesApiRequest, tenantId, finderId);

    if (response?.data) {
      yield put(getVariablesSuccess(response.data.content));
    }
  } catch (error) {
    yield put(getVariablesError({ error }));
  }
}

function* addVariable({ payload }) {
  try {
    const {
      finders: {
        tenant: { id: tenantId },
        finder: { id: finderId },
      },
    } = yield select();

    const formattedPayload = {
      ...payload,
      finderId,
      value: payload.defaultValue,
    };

    const response = yield call(addVariablesApiRequest, tenantId, formattedPayload);

    if (response?.data) {
      yield put(addVariableSuccess(response.data.content));
      yield payload.closeModal();
    }

    yield call(fetchVariables);
  } catch (error) {
    yield put(addVariableError({ error }));
  }
}

function* deleteVariable(payload) {
  try {
    const {
      finders: {
        tenant: { id: tenantId },
      },
    } = yield select();

    const response = yield call(deleteVariablesApiRequest, tenantId, payload);

    if (response?.data) {
      yield put(deleteVariableSuccess(response.data.content));
    }

    yield call(fetchVariables);
  } catch (error) {
    yield put(deleteVariableError({ error }));
  }
}

function* updateVariable(payload) {
  try {
    const {
      finders: {
        tenant: { id: tenantId },
      },
    } = yield select();

    const response = yield call(updateVariableApiRequest, tenantId, payload);

    if (response?.data) {
      yield put(editVariableSuccess(response.data.content));
      yield payload.payload.closeModal();
    }

    yield call(fetchVariables);
  } catch (error) {
    yield put(editVariableError({ error }));
  }
}

/**
 * @desc function generator - observer for all rules sagas functions
 */
export function* watchVariablesManagement() {
  yield all([takeLatest(getVariablesRequest.type, fetchVariables)]);
  yield all([takeLatest(addVariableRequest.type, addVariable)]);
  yield all([takeLatest(deleteVariableRequest.type, deleteVariable)]);
  yield all([takeLatest(editVariableRequest.type, updateVariable)]);
}
