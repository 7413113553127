import { Spin } from 'antd';
import { AppBox } from './boxes/AppBox';

export function AppPreloader({ size = 'large', style, spinning = true, ...rest }) {
  return (
    <AppBox $isFullWidth $flexCenterXY style={style} {...rest}>
      <Spin size={size} spinning={spinning} />
    </AppBox>
  );
}
