import styled from 'styled-components';
import { useState } from 'react';
import { AppSider } from '../../../../../../../../ui/AppSider';
import { AppTabs } from '../../../../../../../../ui/AppTabs';
import { LayoutContent } from './LayoutContent';
import { ScoreContent } from './ScoreContent';
import { FlowContent } from './FlowContent';
import { SavingsContent } from './SavingsContent';
import { AdvancedSettingsContent } from './AdvancedSettingsContent';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../../../lib/util/helpers';

const Tab = {
  LAYOUT: 'layout',
  SCORE: 'score',
  FLOW: 'flow',
  SAVINGS: 'savings',
  ADVANCED_SETTINGS: 'advanced_settings',
};

export function QuestionnaireRightSideBar() {
  const [activeTab, setActiveTab] = useState(Tab.LAYOUT);
  const { tabs: tabsText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar;

  const tabs = [
    { key: Tab.LAYOUT, label: handleTranslation(tabsText.layout) },
    { key: Tab.SCORE, label: handleTranslation(tabsText.score) },
    { key: Tab.FLOW, label: handleTranslation(tabsText.flow) },
    { key: Tab.SAVINGS, label: handleTranslation(tabsText.savings) },
    { key: Tab.ADVANCED_SETTINGS, label: handleTranslation(tabsText.advanced) },
  ];

  const onTabClick = tab => setActiveTab(tab);

  return (
    <Root width={490} theme='light'>
      <StyledTabs onTabClick={onTabClick} items={tabs} activeKey={activeTab} tabBarGutter={0} />

      {activeTab === Tab.LAYOUT && <LayoutContent />}
      {activeTab === Tab.SCORE && <ScoreContent />}
      {activeTab === Tab.FLOW && <FlowContent />}
      {activeTab === Tab.SAVINGS && <SavingsContent />}
      {activeTab === Tab.ADVANCED_SETTINGS && <AdvancedSettingsContent />}
    </Root>
  );
}

const Root = styled(AppSider)({
  '&.ant-layout-sider': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    position: 'sticky',
    top: 0,
  },
  '.ant-layout-sider-children': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const StyledTabs = styled(AppTabs)({
  '&.ant-tabs >.ant-tabs-nav': {
    '&:before': {
      borderBottomColor: '#CFCFD4',
    },

    '.ant-tabs-nav-list': {
      width: 'unset',
    },
  },
  '&.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, &.ant-tabs .ant-tabs-tab:hover': {
    color: '#191919',
  },
  '.ant-tabs-tab': {
    padding: '16px',
  },
});
