import { DestinationVariants } from '../../../../../../../../lib/models/TemplateTypes';
import { FlowOperation } from '../../../../../../../../lib/models/Questionnaire';
import { Typography } from 'antd';
import { setNextFlow } from '../../hooks/setNextFlow';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { generatePdfRequest } from 'src/lib/api';
import { useEffect } from 'react';
import { formatHref } from 'src/processes/CMS/processes/management/utils/helpers';
import { useHvmIntegration } from 'src/processes/CMS/lib/hooks/useHvmIntegration';

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
export function ButtonOrLinkComponent({
  contentObject,
  style,
  setPagesFlow,
  pagesFlow = {},
  textStyle = {},
  disabled,
  analyticsClass,
  children,
  setPageHistory,
  printedResultProps,
  currentElements,
}) {
  const {
    flow: { goTo, href, targetBlank, showElements, operation },
    value,
    id: buttonId,
    rules,
  } = contentObject;
  const { currentPageId, allPages, allChapters, allShownButtonsIds = [], clickedButtonsIds = [] } = pagesFlow;

  const { applyVariable } = useVariables(pagesFlow);

  const { sendHvmData } = useHvmIntegration(pagesFlow);

  const formattedHref = applyVariable(formatHref(href));

  const textValue = applyVariable(value);

  useEffect(() => {
    const filteredClickedButtonsIds = clickedButtonsIds.filter(elem => elem !== buttonId);
    if (setPagesFlow) {
      setPagesFlow(prev => ({
        ...prev,
        clickedButtonsIds: filteredClickedButtonsIds,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printPdf = async () => {
    const settings = {
      showDate: true,
      showLink: true,
      showPrices: true,
      showProductNames: true,
      showProductPageLink: true,
      showProductPictures: true,
      showTotalPriceBlock: true,
    };

    const pdfBlob = await generatePdfRequest({
      payload: {
        ...printedResultProps,
        settings,
      },
      type: 'RESULT',
    });

    const url = URL.createObjectURL(pdfBlob);
    window.open(url);
  };

  const onClick = event => {
    event.stopPropagation();

    if (disabled) {
      return;
    }

    if (operation === 'HVM') {
      sendHvmData();
    }

    if (operation === 'PRINT' && printedResultProps) {
      printPdf();
      setPagesFlow(prev => ({
        ...prev,
        clickedButtonsIds: buttonId ? [...prev.clickedButtonsIds, buttonId] : prev.clickedButtonsIds,
      }));
      return;
    }
    const currentPageIndex = allPages?.findIndex(page => page.id === currentPageId);
    const nextPage =
      goTo === DestinationVariants.NEXT ? allPages[currentPageIndex + 1] : allPages?.find(page => page.elements.find(el => el.id === goTo));
    const currentChapter = allChapters?.find(chapter => chapter?.chapterInnerPagesIds?.find(page => page === nextPage?.id));
    setNextFlow({ goTo, showElements }, rules, nextPage, currentChapter?.id, pagesFlow, setPagesFlow, setPageHistory, currentElements, buttonId);
  };

  if (buttonId && !allShownButtonsIds.includes(buttonId)) {
    return null;
  }

  return goTo === DestinationVariants.LINK || operation === FlowOperation.LINK ? (
    <a
      href={formattedHref}
      style={style}
      className={classNames(styles.button_link, analyticsClass)}
      target={targetBlank ? '_blank' : '_self'}
      rel='noreferrer'
      onClick={event => event.stopPropagation()}
    >
      <Typography.Paragraph
        className={analyticsClass}
        ellipsis={{ rows: 2 }}
        style={{
          margin: '0 auto',
          maxWidth: '80%',
          marginBottom: 0,
          color: 'inherit',
          ...textStyle,
        }}
      >
        {textValue}
      </Typography.Paragraph>
      {children}
    </a>
  ) : (
    <span role='presentation' className={classNames(styles.button_link, analyticsClass)} style={style} onClick={onClick}>
      <Typography.Paragraph
        className={analyticsClass}
        ellipsis={{ rows: 2 }}
        style={{
          margin: '0 auto',
          maxWidth: '80%',
          marginBottom: 0,
          color: 'inherit',
          ...textStyle,
        }}
      >
        {textValue}
      </Typography.Paragraph>
      {children}
    </span>
  );
}
