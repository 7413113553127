import { CardSectionHeader } from '../../../../../../../ui/CardSectionHeader';
import { CardSection } from '../../../../../../../ui/CardSection';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductBulletPoints } from './ProductBulletPoints';
import { ProductBulletPointsSubHeaderRow } from './ProductBulletPointsSubHeaderRow';
import { isLength } from '../../../../../../../../../../../../../lib/util/helpers';
import { ProductAddBulletPointButton } from './ProductAddBulletPointButton';
import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import lodashSet from 'lodash.set';

export function ProductBulletPointsForChapterSection({ data, productIndex, structureItemIndex, bulletPointIcon, bulletPointIconColor, ...rest }) {
  const { updateStructure } = useUpdateStructure();
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].bulletPointsForChapterSection`;
  const disabled = !data.show;

  const toggle = () => {
    updateStructure(draft => {
      lodashSet(draft, `${dataPath}.show`, !data.show);
    });
  };

  return (
    <CardSection withPaddingBottom {...rest}>
      <CardSectionHeader>
        <QuestionnaireSwitch
          labelProps={{
            strong: true,
            textValue: contentText.bulletPointsForChapter,
          }}
          isOn={data.show}
          onChange={toggle}
        />
      </CardSectionHeader>

      <div>
        {isLength(data.bulletPoints) && (
          <>
            <ProductBulletPointsSubHeaderRow
              dataPath={dataPath}
              value={data.rightColumnType}
              structureItemIndex={structureItemIndex}
              disabled={disabled}
            />

            <ProductBulletPoints
              dataPath={`${dataPath}.bulletPoints`}
              bulletPointIcon={bulletPointIcon}
              bulletPointIconColor={bulletPointIconColor}
              bulletPointsSection={data}
              productIndex={productIndex}
              structureItemIndex={structureItemIndex}
              disabled={disabled}
            />
          </>
        )}

        <ProductAddBulletPointButton
          disabled={disabled}
          data={data}
          dataPath={`${dataPath}.bulletPoints`}
          productIndex={productIndex}
          structureItemIndex={structureItemIndex}
        />
      </div>
    </CardSection>
  );
}
