import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { ChapterState } from '../../../../../../lib/models/TemplateTypes';

export function ChapterStateFormItem({ value, onChange, disabled }) {
  const { opened, notOpened, completed } = useLocalization().translations.unique.formItems.chapterState.options;
  const chartStateOptions = [
    { value: ChapterState.OPENED, title: opened() },
    { value: ChapterState.NOT_OPENED, title: notOpened() },
    { value: ChapterState.COMPLETED, title: completed() },
  ];

  return (
    <AppNewSelect
      options={chartStateOptions}
      value={value}
      onChange={selected => onChange(selected.map(item => item.value))}
      withoutLimit
      isMultiSelect
      disabled={disabled}
    />
  );
}
