import { RowType } from '../lib/utils';
import { Product } from './Product';
import { Icon } from './Icon';
import { Picture } from './Picture';
import { Text } from './Text';
import { TableName } from './TableName';
import styled from 'styled-components';

export function CellContent({ item, fonts, colors, rowIndex, footnoteRef, idx }) {
  const onFootnoteClick = () => {
    if (footnoteRef && footnoteRef.current[idx]) {
      const yOffset = -100;
      const y = footnoteRef.current[idx].getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  let Component = null;
  switch (true) {
    case item?.type === 'picture':
      Component = item?.image ? <Picture cell={item} /> : null;
      break;
    case item?.type === 'icon':
      Component = item?.image ? <Icon cell={item} /> : null;
      break;
    case item?.type === 'product':
      Component = item?.image ? <Product cell={item} body={fonts?.body} colors={colors} rowIndex={rowIndex} /> : null;
      break;
    case item?.type === RowType.TABLE_NAME:
      Component = <TableName item={item} fonts={fonts} colors={colors} />;
      break;
    default:
      Component = <Text item={item} fonts={fonts} colors={colors} />;
  }

  return (
    <Container>
      {item?.footnote?.enabled && (
        <FootNote
          onClick={onFootnoteClick}
          style={{
            right: item?.type === 'icon' ? '-30px' : '-24px',
            fontFamily: fonts?.body.font,
            color: colors.secondarySecond,
          }}
        >
          {item.footnote.note}
        </FootNote>
      )}
      {Component}
    </Container>
  );
}

const Container = styled('span')({
  position: 'relative',
});

const FootNote = styled('div')({
  position: 'absolute',
  cursor: 'pointer',
  width: '25px',
  right: '-20px',
  fontSize: '12px',
  top: '-10px',
});
