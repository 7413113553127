import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { addColumn, COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT, generateEmptyRow, getCellsAmount } from '../comparison-table-utils';
import { isLength } from '../../../../../../../../../../lib/util/helpers';
import { QuestionnaireTextButton } from '../../../../features/QuestionnaireTextButton';
import { useContentTable } from '../hooks/useContentTable';

export function AddColumn() {
  const { contentTableState } = useContentTable();
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;
  const disabled = getCellsAmount(contentTableState.tableRows) >= COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT;

  const handleAddColumn = () => {
    contentTableState.updateTable(({ tableRows }) => {
      const areNoRows = !isLength(tableRows);
      if (areNoRows) {
        tableRows.push(generateEmptyRow(1));
      } else {
        addColumn(tableRows);
      }
    });
  };

  return <QuestionnaireTextButton onClick={handleAddColumn} disabled={disabled} textValue={contentTableText.addColumn} />;
}
