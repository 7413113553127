/* eslint-disable max-lines-per-function,sonarjs/cognitive-complexity,max-lines */
import { AppTooltip } from 'src/ui/AppTooltip';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppImage } from '../../../../../../../../../ui/AppImage';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { CheckProduct } from '../../../../../../../../../ui/icons/CheckProduct';
import { ProductCardContent } from '../ProductCardContent';
import styles from './styles.module.scss';
import { sendDataToAnalytics } from '../../../../../utils/sendDataToAnalytics';
import { LayoutDesign } from '../../../../../../../../../lib/models/Questionnaire';
import { useOnSelectCard } from '../../../hooks/useOnSelectCard';
import { getSpecificProducts } from '../../../models/Hardcode';

function PreviewProductCardPresentationalCenterMode(props) {
  const { data, dataIndex } = props || {};
  const {
    isRecommended,
    pagesFlow,
    card,
    fonts,
    colors,
    bulletPointIconColor,
    bulletPointIcon,
    customIcons,
    defaultIcons,
    selectable,
    showSavings,
    activeSlide,
    layoutDesign,
    setDragging,
    carouselRef,
    windowWidth,
    setPagesFlow,
    structureItemId,
    setSelectionModalOpen,
    productObject,
    scoringResult,
  } = data[dataIndex]?.extraData || {};

  const { translations } = useLocalization();
  const { templates } = translations.unique.pages.questionnaire.rightSideBar.layout || {};
  const largeMobileView = 800;
  // IMPORTANT: it should be exactly like this. Do not pass `onSelectCard` directly to the `onClick` (because `onSelectCard` accepts `customValue` argument. Therefore, it should not accept `event` from the `onClick`.)
  const handleSelectCard = () => {
    onSelectCard();
    const { categoryName, product } = data[dataIndex]?.productNameSection || {};
    const { title } = product;
    sendDataToAnalytics(
      'Product',
      `Category: ${categoryName || 'no category'}, Title: ${title}, Selected: ${data[dataIndex]?.selected ? 'no' : 'yes'}`
    );
  };

  const { P2 } = getSpecificProducts(pagesFlow.allPages);
  const conditions = {};

  const { onSelectCard } = useOnSelectCard({
    conditions,
    P2,
    cardId: data[dataIndex].id,
    products: data,
    card: data[dataIndex],
    selectable,
    setPagesFlow,
    structureItemId,
    setDragging,
    carouselRef,
    windowWidth,
    setSelectionModalOpen,
    productObject,
    pagesFlow,
    scoringResult,
  });

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleSelectCard} className={styles.cardInner}>
          {!isRecommended && data[dataIndex]?.productNameSection?.customSign?.isEnabled && (
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '34px',
                padding: '8px 16px',
                borderRadius: '0 0 8px 8px',
                background: colors.primaryColor,
                maxWidth: '40%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxHeight: '40px',
                color: '#fff',
              }}
            >
              <AppTooltip text={data[dataIndex]?.productNameSection?.customSign?.sign}>
                <AppText
                  textValue={data[dataIndex]?.productNameSection?.customSign?.sign}
                  style={{ color: '#fff', fontWeight: 700, fontSize: fonts.headlineFourth.fontSize, fontFamily: fonts.headlineFourth.font }}
                />
              </AppTooltip>
            </div>
          )}
          {selectable && (
            <div
              style={{
                border: data[dataIndex]?.selected ? '#3EAF00' : 'none',
                width: '35px',
                height: '35px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0px',
                left: '0px',
                background: data[dataIndex]?.selected ? colors.primaryColor : 'transparent',
                borderRadius: data[dataIndex]?.selected ? '15px 5px 5px 5px' : '50%',
              }}
            >
              {data[dataIndex]?.selected && <CheckProduct />}
            </div>
          )}

          {isRecommended && (
            <div style={{ position: 'absolute', top: '0px', right: '34px', borderRadius: '0 0 8px 8px', background: '#3EAF00' }}>
              <AppText
                textValue={templates.recommended}
                style={{ color: '#fff', fontWeight: 700, fontSize: fonts.headlineFourth.fontSize, fontFamily: fonts.headlineFourth.font }}
              />
            </div>
          )}
          <div style={{ width: '100%' }}>
            {data[dataIndex]?.image && data[dataIndex]?.productNameSection.showPicture ? (
              <div>
                <div
                  style={{
                    width: '100%',
                    height: 300,
                    userSelect: 'none',
                    borderRadius: 15,
                    border: data[dataIndex]?.selected ? `2px solid ${colors.primaryColor}` : '2px solid transparent',
                  }}
                >
                  <AppImage
                    style={{
                      objectFit: windowWidth <= largeMobileView ? 'contain' : 'cover',
                      width: '100%',
                      height: '100%',
                      borderRadius: 15,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    src={data[dataIndex]?.image}
                    alt='image'
                  />
                </div>
                {!layoutDesign ||
                  (layoutDesign?.title !== LayoutDesign.NORMAL_MODE_CAROUSEL && layoutDesign?.title !== LayoutDesign.CENTER_MODE_CAROUSEL && (
                    <ProductCardContent
                      pagesFlow={pagesFlow}
                      card={card}
                      fonts={fonts}
                      colors={colors}
                      bulletPointIconColor={bulletPointIconColor}
                      bulletPointIcon={bulletPointIcon}
                      customIcons={customIcons}
                      defaultIcons={defaultIcons}
                      showSavings={showSavings}
                    />
                  ))}
              </div>
            ) : (
              <ProductCardContent
                pagesFlow={pagesFlow}
                card={card}
                fonts={fonts}
                colors={colors}
                bulletPointIconColor={bulletPointIconColor}
                bulletPointIcon={bulletPointIcon}
                customIcons={customIcons}
                defaultIcons={defaultIcons}
                showSavings={showSavings}
              />
            )}

            {data[dataIndex]?.productNameSection.product.title && dataIndex === activeSlide && (
              <AppTitle
                style={{
                  fontSize: fonts.headlineThird.fontSize,
                  fontFamily: fonts.headlineThird.font,
                  fontWeight: fonts.headlineThird.fontWeight,
                  color:
                    layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL || layoutDesign?.title === LayoutDesign.CENTER_MODE_CAROUSEL
                      ? layoutDesign?.appTitle?.color
                      : colors[fonts.headlineThird.colorVariant],
                  textAlign:
                    layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL || layoutDesign?.title === LayoutDesign.CENTER_MODE_CAROUSEL
                      ? layoutDesign?.appTitle?.textAlign
                      : 'initial',
                  marginTop: 10,
                }}
                level={3}
                textValue={
                  data[dataIndex]?.productNameSection?.isShortProductName
                    ? data[dataIndex]?.productNameSection?.shortProduct?.title
                    : data[dataIndex]?.productNameSection?.product?.title
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { PreviewProductCardPresentationalCenterMode };
