import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../../../FindersAndProductsPage/lib/Product';
import { handleTranslation, mapObject } from '../../../../../../../../../../../lib/util/helpers';

export function useFieldLabels() {
  const { shared, unique } = useLocalization().translations;
  const { columns } = shared;

  // REFACTOR: move translations to other places (because these are not columns, and even more this is not the "products" page)
  const { category, headline, subline, link, yearPrice, monthPrice, image } = unique.pages.products.table.columns;
  const { name: nameLabel, currency } = columns;

  const fields = {
    [Product.NAME]: nameLabel,
    [Product.CATEGORY]: category,
    [Product.HEADLINE]: headline,
    [Product.SUBLINE]: subline,
    [Product.PRODUCT_PAGE_LINK]: link,
    [Product.PRICE_PER_YEAR]: yearPrice,
    [Product.PRICE_PER_MONTH]: monthPrice,
    [Product.CURRENCY]: currency,
    [Product.IMAGE_LINK]: image,
  };

  return mapObject({ source: fields, valueCb: handleTranslation });
}
