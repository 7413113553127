import { AppText } from '../../../../../../../ui/text/AppText';
import { getTextStyles } from '../../../../../../../lib/util/helpers';
import { InfoIconComponent } from '../../../pages/PreviewPage/components/InfoIconComponent';

export function Text({ item, fonts, colors }) {
  return (
    <div>
      <AppText textValue={item?.value} style={{ ...getTextStyles(fonts?.body, colors), fontWeight: item?.isBold && 'bold', color: item?.color }} />
      {item?.infoIcon && <InfoIconComponent type={item} />}
    </div>
  );
}
