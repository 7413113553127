import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { ArrangeFormItem } from '../../utils/model/Arrange';
import { CheckboxAllFormSection } from '../../../../../../ui/CheckboxAllFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppSpinner } from '../../../../../../ui/AppSpinner';
import { handleTranslation, isLength } from '../../../../../../lib/util/helpers';
import { ArrangeListType, MINIMAL_SEARCH_LENGTH } from '../../utils/consts';
import './SearchFilter.sass';

export function SearchFilter({ filtering, minSearchLength = MINIMAL_SEARCH_LENGTH }) {
  const { options, onChangeSearchField, isLoading, type } = filtering;
  const { inputPlaceholdersByTypes, errors: errorsText } = useLocalization().translations.shared.arranges.filter;

  const suffix = <AppSpinner show={isLoading} />;
  const rules =
    type === ArrangeListType.STRING && minSearchLength ? [{ min: minSearchLength, message: handleTranslation(errorsText.minLength) }] : [];

  const uniqueOptions = options.filter((item, index, array) => {
    return array.findIndex(el => el.label === item.label) === index;
  });

  return (
    <>
      <AppFormSection
        size='small'
        className='searchField'
        rootProps={{ style: { margin: 0 } }} // TODO: set up logic for different AppFormSection types/sizes
        formItemProps={{
          name: ArrangeFormItem.SEARCH,
          style: { minHeight: isLength(uniqueOptions) ? 'unset' : '43px' },
          hasFeedback: false,
          rules,
        }}
        textFieldProps={{
          label: inputPlaceholdersByTypes.list,
          onChange: onChangeSearchField,
          suffix,
        }}
      />

      <CheckboxAllFormSection
        minSearchLength={minSearchLength}
        isLoading={isLoading}
        rootProps={{ style: { maxHeight: '208px' } }}
        formSectionProps={{
          formItemProps: { name: ArrangeFormItem.SELECTED_ITEMS },
        }}
        checkboxGroupProps={{
          rootProps: { style: { maxHeight: '150px', height: '100%', overflowY: 'auto' } },
          groupProps: { options: uniqueOptions },
        }}
      />
    </>
  );
}
