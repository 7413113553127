import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { GripVerticalIcon } from '../../../../../../../../../../ui/icons/GripVerticalIcon';
import { BodyCell } from './BodyCell';
import classes from '../ComparisonTable.module.sass';

export function BodyDragRowCell({ providedDraggable }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <BodyCell
      rootProps={{
        ...providedDraggable.dragHandleProps,
        className: classNames(providedDraggable.dragHandleProps?.className, classes.bodyDragRowCell),
      }}
      innerProps={{
        disabled: isViewMode,
      }}
    >
      <GripVerticalIcon />
    </BodyCell>
  );
}
