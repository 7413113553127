import React, { useEffect, useState } from 'react';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../../../../features/QuestionnaireSelect';
import { useGetFieldOptions } from './useGetFieldOptions';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { withActionContactFormFields, ContactFormField } from '../../../../../../../../../../../lib/models/Questionnaire';
import { ChangeFieldTypeModal } from './ChangeFieldTypeModal';
import lodashGet from 'lodash.get';
import { QuestionnaireFormSectionWithDelay } from '../../../../../features/QuestionnaireFormSectionWithDelay';

const formSectionStyle = {
  marginBottom: 0,
};

const formItemProps = {
  style: { minHeight: 'unset', width: '100%' },
};

// It's a limit for max fields in a row where among which there is a field with an action.
const LIMIT = 1;

// eslint-disable-next-line max-lines-per-function
export function ContactFormRowFieldParts(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [canChangeFieldType, setCanChangeFieldType] = useState(false);
  const [fieldTypeValue, setFieldTypeValue] = useState();

  const { updateStructure } = useUpdateStructure();
  const { rowIndex, fieldIndex, structureItemIndex, templateContent } = props;
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const field = templateContent?.formRowList[rowIndex]?.fieldList?.[fieldIndex];

  const onChangeName = value => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].name`, value);
    });
  };

  const changeFieldType = value => {
    updateStructure(draft => {
      const fullWidth = [ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION, ContactFormField.CHECKBOX].includes(value);
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].type`, value);
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].optionList`, []);
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].isFullWidth`, fullWidth);
    });
  };

  const deleteOtherFieldsAndChangeType = value => {
    updateStructure(draft => {
      const item = lodashGet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}]`);
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList`, [{ ...item, options: [], type: value }]);
    });
  };

  const onChangeType = ({ value }) => {
    if (withActionContactFormFields.includes(value) && templateContent?.formRowList[rowIndex]?.fieldList?.length > LIMIT) {
      setIsOpen(true);
      setFieldTypeValue(value);
      return;
    }

    changeFieldType(value);
  };

  const closeModal = () => setIsOpen(false);
  const onSubmit = () => setCanChangeFieldType(true);

  const options = useGetFieldOptions();
  const fieldListLength = templateContent?.formRowList[rowIndex]?.fieldList?.length;
  const filteredOptions =
    fieldListLength > 1
      ? options.filter(option => ![ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION, ContactFormField.CHECKBOX].includes(option.value))
      : options;

  useEffect(() => {
    if (canChangeFieldType) {
      setCanChangeFieldType(false);

      deleteOtherFieldsAndChangeType(fieldTypeValue);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canChangeFieldType, fieldTypeValue]);

  return (
    <>
      <ChangeFieldTypeModal isOpen={isOpen} close={closeModal} submit={onSubmit} />

      <div className='flex flex-y-c w-100' style={{ gap: '8px' }}>
        <QuestionnaireFormSectionWithDelay
          style={{ ...formSectionStyle, flex: 1 }}
          formItemProps={formItemProps}
          textFieldProps={{
            value: field?.name,
            label: contentText.inputName,
            onChange: onChangeName,
          }}
        />
        <QuestionnaireSelect
          style={{ ...formSectionStyle, flex: 1 }}
          formItemProps={formItemProps}
          selectProps={{
            value: { value: field?.type },
            onChange: onChangeType,
            options: filteredOptions,
            label: contentText.inputType,
          }}
        />
      </div>
    </>
  );
}
