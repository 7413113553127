import { useDispatch } from 'react-redux';
import { applyCategoryArrangesRequest, removeCategoryFiltersRequest } from '../../../../../../../../../../lib/store/slices/categoriesSlice';
import { ArrangeFormItem } from '../../../../../../utils/model/Arrange';
import { getParsedSelectedItems, LIST_VALUE_PROP_NAME, normalizeFilters } from '../../../../../../utils/arranges-helpers';

export function useCategoryArrangeRequests({
  arrangedId,
  sortFieldName,
  clearFilters,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  withSorting = true,
}) {
  const dispatch = useDispatch();

  const getSortOrder = () => form.getFieldValue(ArrangeFormItem.SORT_ORDER);
  const getSortField = () => ({ sortOrder: getSortOrder(), withSorting: true, sortFieldName });

  const getSorting = () => {
    const baseFields = { withSorting: false };
    return withSorting ? Object.assign(baseFields, getSortField()) : baseFields;
  };

  const applyArranges = async () => {
    await dispatch(
      applyCategoryArrangesRequest({
        sorting: getSorting(),
        filtering: normalizeFilters(getFilters()),
        activeArrangedId: arrangedId,
      })
    );

    setFilterLabel(getFilterLabel);
  };

  const removeFilter = async () => {
    clearFilters();
    await dispatch(removeCategoryFiltersRequest());
    setFilterLabel(null);
  };

  const removeFilterValue = async value => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(item => item[LIST_VALUE_PROP_NAME] !== value);
    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    await dispatch(
      applyCategoryArrangesRequest({
        sorting: getSorting(),
        filtering: normalizeFilters(getFilters()),
        activeArrangedId: arrangedId,
      })
    );

    const filterLabel = getFilterLabel();
    setFilterLabel(filterLabel);

    if (!filterLabel) {
      clearFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
}
