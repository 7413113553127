import { produce } from 'immer';
import { updateQuestionnaireStructure } from '../../../../../../../lib/store/slices/findersSlice';
import { useDispatch, useSelector } from 'react-redux';

export function useUpdateStructure() {
  const dispatch = useDispatch();
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const updateStructure = callback => {
    const structure = produce(questionnaireStructure, callback);
    dispatch(updateQuestionnaireStructure({ structure }));
  };

  return { updateStructure };
}
