import ProgressBar from '@ramonak/react-progress-bar';
import styled from 'styled-components';

export function PreviewProgressBar({ strokeColor, progressPercentage }) {
  return (
    <ProgressBarContainer>
      <ProgressBar
        isLabelVisible={false}
        bgColor={strokeColor}
        borderRadius='0px'
        height='8px'
        completed={progressPercentage}
        baseBgColor='#ffffff'
      />
    </ProgressBarContainer>
  );
}

const ProgressBarContainer = styled('div')({
  width: '100%',
  height: '8px',
  position: 'fixed',
  bottom: '0',
  left: '0',
});
