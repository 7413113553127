import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../lib/models/Form';
import { getTenantThemesRequest } from '../../../../../../lib/store/slices/findersSlice';
import { DefaultColors, DefaultFontStyles } from '../../../../lib/models/DefaultStylesFontsAndColors';
import { loadFonts } from '../../pages/ThemePage/lib/util/loadFonts';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../lib/consts';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { usePermissions } from 'src/lib/hooks/usePermissions';

export function ThemeSelectFormItem({ designSettings, setDesignSettings }) {
  const { isRestricted } = usePermissions();
  const { themeName } = useLocalization().translations.unique.pages.themes.table.columns;
  const {
    tenantThemes,
    finder,
    tenant: { id: tenantId },
  } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();
  const { themeConfig } = designSettings;
  const themesOptions = tenantThemes.map(theme => ({ value: theme.id, title: theme.name }));

  useEffect(() => {
    if (!isRestricted()) {
      dispatch(
        getTenantThemesRequest({
          queryParams: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
          requestBody: { activated: true, equalByTenantId: [tenantId] },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finder?.brand?.configuration) {
      const parsedConfig = JSON.parse(finder.brand.configuration);
      loadFonts(parsedConfig.fontsForLoading);
    }
  }, [finder]);

  const changeThemeConfig = selected => {
    const id = selected?.value;
    if (id) {
      const themeObject = tenantThemes.find(theme => theme.id === id);
      const parsedThemeConfig = JSON.parse(themeObject?.configuration);
      loadFonts(parsedThemeConfig.fontsForLoading);
      setDesignSettings({ ...designSettings, themeConfig: { ...themeConfig, colors: parsedThemeConfig.colors, fonts: parsedThemeConfig.fonts } });
    } else {
      setDesignSettings({ ...designSettings, themeConfig: { colors: DefaultColors, fonts: DefaultFontStyles } });
    }
  };

  return (
    <AppFormSection
      style={{ width: 280 }}
      formItemProps={{
        name: FormItemName.THEME_ID,
        rules: [{ required: false }],
        style: { width: 280, minHeight: 40 },
      }}
    >
      <AppNewSelect label={themeName} options={themesOptions} onChange={changeThemeConfig} unselectable disabled={isRestricted()} />
    </AppFormSection>
  );
}
