import { useCallback } from 'react';

const DELAY = 100; // ms

export function useScrollToCardOnNonMobile(setDragging, carouselRef) {
  return useCallback(
    slideIndex => {
      setDragging(false);

      if (carouselRef?.current?.slickGoTo) {
        carouselRef.current.slickGoTo(slideIndex);

        setTimeout(() => {
          setDragging(false);
        }, DELAY);
      }
    },
    [carouselRef, setDragging]
  );
}
