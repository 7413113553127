import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';

/**
 * @desc Composer children: all the elements for building a questionnaire (Intro, Chapter, Content, Question, Product, Result, Contact form)
 * @param {Object} composerItem
 * @param {Number} composerIndex
 * @returns {JSX.Element}
 */
export function ComposerChildren({ composerItem, composerIndex }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);

  // Only one intro allowed
  const isIntroDragDisabled = questionnaireStructure?.some(item => item.templateType === TemplateTypes.INTRO);

  // Only one result table allowed
  const isResultTableDragDisabled = questionnaireStructure?.some(item => item.templateType === TemplateTypes.RESULT && item.label === 'Table');

  return (
    <Droppable droppableId='COMPOSER_CHILDREN' type='COMPOSER' isDropDisabled>
      {provided => (
        <ChildrenItems ref={provided.innerRef}>
          {composerItem?.children?.map((composerChildrenItem, composerChildrenIndex) => (
            <Draggable
              key={composerChildrenItem.id}
              draggableId={composerChildrenItem.id}
              index={Number(String(String(composerIndex) + String(composerChildrenIndex)))}
              isDragDisabled={
                (isIntroDragDisabled && composerChildrenItem.templateType === TemplateTypes.INTRO) ||
                isViewMode ||
                (isResultTableDragDisabled && composerChildrenItem.templateType === TemplateTypes.RESULT && composerChildrenItem.label === 'Table')
              }
            >
              {(providedItem, snapshotItem) => (
                <>
                  <AppBox
                    as={ChildrenItem}
                    disabled={isViewMode}
                    ref={providedItem.innerRef}
                    {...providedItem.draggableProps}
                    {...providedItem.dragHandleProps}
                    isDragDisabled={isIntroDragDisabled || isResultTableDragDisabled}
                    style={{
                      ...providedItem.draggableProps.style,
                      transform: snapshotItem.isDragging ? providedItem.draggableProps.style?.transform : 'translate(0px, 0px)',
                    }}
                  >
                    {composerChildrenItem.label}
                  </AppBox>
                  {snapshotItem.isDragging && <ChildrenItem>{composerChildrenItem.label}</ChildrenItem>}
                </>
              )}
            </Draggable>
          ))}
        </ChildrenItems>
      )}
    </Droppable>
  );
}

const ChildrenItems = styled('div')({
  listStyle: 'none',
  padding: 0,
});

const ChildrenItem = styled('div')(props => ({
  userSelect: 'none',
  background: '#404055',
  color: '#fff',
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 400,
  paddingLeft: 45,
  paddingRight: 0,
  paddingTop: 8,
  paddingBottom: 8,
  '&:hover': {
    background: 'rgba(25, 25, 25, 0.06);',
  },
  cursor: props.isDragDisabled && 'not-allowed',
}));
