export const ClientFormField = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  COMPANY_OFFICE: 'companyOffice',
  INDUSTRY: 'industry',
  LEGAL_FORM: 'legalForm',
  LAST_YEAR_REVENUE: 'lastYearRevenue',
  LOAN_AMOUNT: 'loanAmount',
  ARE_YOU_A_CLIENT: 'areYouAClient',
};

export function prepareContactForm(formValues) {
  return {
    ...formValues,
    [ClientFormField.LAST_YEAR_REVENUE]: formValues[ClientFormField.LAST_YEAR_REVENUE]?.value,
    [ClientFormField.LOAN_AMOUNT]: formValues[ClientFormField.LOAN_AMOUNT]?.value,
  };
}
