/* eslint-disable no-magic-numbers */
import { getVars } from './getVars';
import { FK_Q3_A2_ID, FK_RESULT_ID, PK_Q1_A2_ID, PK_Q3_A3_ID, PK_RESULT_ID } from './constants';

// eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
export function hardCodeClick({ button, openPreviewForm, onButtonClick, R1, pagesFlow }) {
  const {
    FK_LINK_1_VAR_1,
    FK_LINK_1_VAR_2,
    FK_LINK_3_VAR_1,
    FK_LINK_3_VAR_2,
    FK_LINK_5_VAR_1,
    FK_LINK_5_VAR_2,
    PK_LINK_1_VAR_1,
    PK_LINK_1_VAR_2,
    PK_LINK_2_VAR_1,
    PK_LINK_2_VAR_2,
    PK_LINK_5_VAR_1,
    PK_LINK_5_VAR_2,
    PK_LINK_7_VAR_1,
    PK_LINK_7_VAR_2,
    P1,
    P2,
    P3,
    P4,
    Q1,
    Q3,
  } = getVars({ pagesFlow });

  if (R1?.id === FK_RESULT_ID) {
    if (FK_LINK_1_VAR_1 || FK_LINK_1_VAR_2) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk-mg.landingpage.html';
      openPreviewForm(link, button);
      return;
    }

    if (
      P1?.content?.products[0]?.selected &&
      !P1?.content?.products[1]?.selected &&
      !P1?.content?.products[2]?.selected &&
      Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
      P2?.content?.products[1]?.selected
    ) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk.landingpage.html';
      openPreviewForm(link, button);
      return;
    }

    if (FK_LINK_3_VAR_1 || FK_LINK_3_VAR_2) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk-plus-mg.landingpage.html';
      openPreviewForm(link, button);
      return;
    }

    if (
      P1?.content?.products[0]?.selected &&
      P1?.content?.products[1]?.selected &&
      Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
      P2?.content?.products[1]?.selected
    ) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk-plus.landingpage.html';
      openPreviewForm(link, button);
      return;
    }

    if (FK_LINK_5_VAR_1 || FK_LINK_5_VAR_2) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk-premium-mg.landingpage.html';
      openPreviewForm(link, button);
      return;
    }

    if (
      P1?.content?.products[0]?.selected &&
      P1?.content?.products[2]?.selected &&
      Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
      P2?.content?.products[1]?.selected
    ) {
      const link =
        'https://www.hamburger-volksbank.de/firmenkunden/zahlungsverkehr/geschaeftskonto/grosse-freiheit-businesskonto/kontoeroeffnung-gfbk-premium.landingpage.html';
      openPreviewForm(link, button);
      return;
    }
    onButtonClick(button);
  }

  if (R1?.id === PK_RESULT_ID) {
    let link = button?.flow?.href;
    if (PK_LINK_2_VAR_1 || PK_LINK_2_VAR_2) {
      link =
        'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pr-mg-holz.landingpage.html';
    } else if (PK_LINK_1_VAR_1 || PK_LINK_1_VAR_2) {
      link =
        'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pr-mg-giro.landingpage.html';
    } else if (
      Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
      P1?.content?.products[1]?.selected &&
      P3?.content?.products[0]?.selected &&
      P4?.content?.products[0]?.selected &&
      Q3?.selectedAnswers?.includes(PK_Q3_A3_ID)
    ) {
      link =
        'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pr-holz.landingpage.html';
    } else if (
      Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
      P1?.content?.products[1]?.selected &&
      P3?.content?.products[0]?.selected &&
      P4?.content?.products[0]?.selected
    ) {
      link =
        'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pr-giro.landingpage.html';
    } else if (PK_LINK_5_VAR_1 || PK_LINK_5_VAR_2) {
      link =
        'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pl-mg.landingpage.html';
    } else if (
      Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
      P1?.content?.products[1]?.selected &&
      P3?.content?.products[0]?.selected &&
      P4?.content?.products[1]?.selected
    ) {
      link = 'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-pl.landingpage.html';
    } else if (PK_LINK_7_VAR_1 || PK_LINK_7_VAR_2) {
      link = 'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk-mg.landingpage.html';
    } else if (
      Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
      P1?.content?.products[1]?.selected &&
      P3?.content?.products[1]?.selected &&
      P4?.content?.products[1]?.selected
    ) {
      link = 'https://www.hamburger-volksbank.de/privatkunden/girokonto-kreditkarten/girokonto/grosse-freiheit-privatkonto/gfpk.landingpage.html';
    }

    const buttonWithLink = {
      ...button,
      flow: {
        ...button?.flow,
        href: link,
      },
    };
    onButtonClick(buttonWithLink);
  }
}
