import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

import { AppPreloader } from '../../../../../ui/AppPreloader';
import { AppLayout } from '../../../../../ui/AppLayout';

export function WithoutSideBarLayout() {
  return (
    <AppLayout>
      <Suspense fallback={<AppPreloader style={{ minHeight: 200 }} />}>
        <Outlet />
      </Suspense>
    </AppLayout>
  );
}
