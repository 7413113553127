import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { CounterAnswersRangeBlock } from './CounterAnswersRangeBlock';
import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { FlowSingleButtonSection } from '../FlowSingleButtonSection';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';
import '../../../style.scss';

/**
 * @desc Renders the flow for a question with counter answers.
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.elements - The elements in the questionnaire structure.
 * @param {Object} props.element - The specific element for which the flow is being rendered.
 * @param {Function} props.handleChangeFlowFieldValue - Function to handle changes in flow field values.
 * @param {Function} props.handleChangeFlowFieldsValue - Function to handle changes in flow fields.
 * @param {boolean} props.disabled - Indicates whether the component is disabled.
 * @returns {JSX.Element} The JSX element displaying the flow for the question with counter answers.
 */
export function QuestionCounterAnswersFlow({ elements, element, handleChangeFlowFieldValue, handleChangeFlowFieldsValue, disabled }) {
  const dispatch = useDispatch();
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { elseGoTo } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { additionalButton } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;
  const { addAnswer } = useLocalization().translations.shared.generic;

  const elementIdByType = getIndexByTemplateType(elements, element);

  const { flow, content, templateType, label, id: elementId, rules } = element;
  const { counterAnswers, composerButton } = content;

  const addCounterAnswerOnClick = () => {
    const result = questionnaireStructure?.map(el => {
      return el.id === elementId
        ? { ...el, flow: { ...el.flow, counterAnswersFlowArr: [...flow.counterAnswersFlowArr, { id: uuidv4(), counterAnswersRanges: [] }] } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <>
      <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ padding: '24px 15px 1px 15px' }} />
      <div style={{ padding: '0 15px' }}>
        <FlowRulesList elementRules={rules} />
      </div>

      {flow.counterAnswersFlowArr?.map((counterAnswersFlowItem, index) => (
        <CounterAnswersRangeBlock
          key={counterAnswersFlowItem.id}
          elementId={elementId}
          index={index}
          counterAnswersFlowItem={counterAnswersFlowItem}
          counterAnswersFlowArr={flow.counterAnswersFlowArr}
          counterAnswers={counterAnswers}
          handleChangeFieldValue={handleChangeFlowFieldValue('counterAnswersFlowArr')}
          disabled={disabled}
        />
      ))}
      <div className='flow_process_box'>
        <AppBasicButton
          onClick={addCounterAnswerOnClick}
          style={{ display: 'inline-flex', margin: '10px 0 16px 0' }}
          disabled={disabled || flow.counterAnswersFlowArr.length === counterAnswers.length}
        >
          <AppText className='primary_color bold' textValue={addAnswer} />
        </AppBasicButton>

        <AppText textValue={elseGoTo} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={flow}
          options={questionnaireStructure}
          onChange={handleChangeFlowFieldsValue}
          disabled={disabled}
          goToFieldName='counterAnswersElseGoTo'
          showElementsFieldName='counterAnswersElseShowElements'
        />
      </div>
      <div className='flow_process_box'>
        {composerButton && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={composerButton}
            buttonTextValue={composerButton.value}
            buttonLabelText={additionalButton}
            buttonName='composerButton'
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}
