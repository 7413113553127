import { isLength } from '../../../../../../../../../../../lib/util/helpers';
import { insertNewRowsAndFillSelectedFields } from './insertNewRowsAndFillSelectedFields';
import { fillSelectedFieldsInExistedRows } from './fillSelectedFieldsInExistedRows';
import { Product } from '../../../../../../FindersAndProductsPage/lib/Product';

const getProductFieldValue = (selectedProduct, field) =>
  field.value === Product.CATEGORY ? selectedProduct[Product.CATEGORY].name : selectedProduct[field.value];

function getSelectedFields(selected, fieldLabels, selectedProduct) {
  return selected.map(field => ({
    productFieldName: field.value,
    label: fieldLabels[field.value],
    value: getProductFieldValue(selectedProduct, field),
  }));
}

export function onChangeFieldsSelect(tableRows, newSelected, fieldLabels, selectedProduct, rowIndex, columnIndex) {
  const selectedFields = getSelectedFields(newSelected, fieldLabels, selectedProduct);

  fillSelectedFieldsInExistedRows(tableRows, selectedFields, rowIndex, columnIndex, selectedProduct);

  if (isLength(selectedFields)) {
    insertNewRowsAndFillSelectedFields(tableRows, selectedFields, columnIndex, selectedProduct);
  }
}
