import { useDispatch, useSelector } from 'react-redux';
import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { deleteProductRequest } from '../../../../../../../../../../../lib/store/slices/productsSlice';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';

export function DeleteProductModal({ close, isOpen, productData, isModalLoading }) {
  const dispatch = useDispatch();
  const { tenant } = useSelector(({ finders }) => finders);
  const { title, subTitle } = useLocalization().translations.unique.pages.products.deleteModal;
  const { generic } = useLocalization().translations.shared;

  const submit = () => dispatch(deleteProductRequest({ productId: productData.id, tenantId: tenant.id }));

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title({ name: productData.name }) }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, loading: isModalLoading, textValue: generic.delete }}
    >
      {isOpen && <AppParagraph textValue={subTitle} style={{ marginBottom: '24px' }} />}
    </AppModal>
  );
}
