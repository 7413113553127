import { ProductLayoutCardWithCounter } from './ProductLayoutCardWithCounter';
import { ProductLayoutProductListWrapper } from '../../components/ProductLayoutProductListWrapper';
import { LayoutDesign } from '../../../../../../../../../../../../../lib/models/Questionnaire';
import { CenterModeCarousel } from '../../../../../../../../PreviewPage/components/PreviewProductCarousel/components/centerModeCarousel';
import React from 'react';

export function ProductListMultipleCounters({
  list,
  allData,
  bulletPointIconSvg,
  bulletPointIconColor,
  bulletPointIconUrl,
  colors,
  layoutDesign,
  ...rest
}) {
  const productsList = allData?.list?.map(item => {
    return { id: item.id, ...item.data };
  });

  return (
    <>
      {layoutDesign.title === LayoutDesign.CENTER_MODE_CAROUSEL && (
        <CenterModeCarousel genericProps={{ products: productsList, colors }} layoutDesign={layoutDesign} preview={false} />
      )}

      {layoutDesign.title !== LayoutDesign.CENTER_MODE_CAROUSEL && (
        <ProductLayoutProductListWrapper {...rest}>
          {list.map(item => (
            <ProductLayoutCardWithCounter
              key={item.id}
              data={item.data}
              allData={allData}
              bulletPointIconSvg={bulletPointIconSvg}
              bulletPointIconUrl={bulletPointIconUrl}
              bulletPointIconColor={bulletPointIconColor}
              layoutDesign={layoutDesign}
            />
          ))}
        </ProductLayoutProductListWrapper>
      )}
    </>
  );
}
