import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { useSelector } from 'react-redux';

export function SelectProductFormItem({ value: id, rangeProductsArr, onChange, width = 300, disabled }) {
  const { tenantProducts } = useSelector(({ finders }) => finders);

  const productOptions = tenantProducts
    ?.filter(item => !rangeProductsArr.some(prod => prod.prodId === item?.id) || item?.id === id)
    ?.map(prod => ({ value: prod.id, title: prod.name }));

  return (
    <div style={{ width }}>
      <AppNewSelect options={productOptions} value={id} onChange={selected => onChange(selected.value)} disabled={disabled} />
    </div>
  );
}
