import { useState, useRef } from 'react';
import { SketchPicker } from 'react-color';

import { AppInputLabel } from 'src/ui/AppInputLabel';
import { ChevronDownIcon } from 'src/ui/icons/ChevronDownIcon';

import { useOutsideClick } from 'src/lib/hooks/useOutsideClick';

import styles from './AppColorPicker.module.scss';

export function AppColorPicker({ color = '#000000', onChange = () => {} }) {
  const containerRef = useRef(null);

  const [isActive, setIsActive] = useState(false);

  const handleChange = ({ hex }) => {
    onChange(hex);
  };

  useOutsideClick(containerRef, () => {
    setIsActive(false);
  });

  return (
    <div
      className={`${styles.appColorPicker} ${isActive ? styles.active : ''}`}
      aria-hidden='true'
      onClick={() => setIsActive(true)}
      ref={containerRef}
    >
      <div className={styles.mainContainer}>
        <AppInputLabel className='asLabel' textValue='Color' />

        <div className={styles.colorBlockContainer}>
          <div className={styles.colorIcon} style={{ backgroundColor: color }} />
          <span className={styles.colorText}>{color}</span>
        </div>

        <ChevronDownIcon className={styles.chevronIcon} fill='#5C5C6E' />
      </div>

      <div className={styles.colorPicker}>
        <SketchPicker color={color} onChange={handleChange} disabledAlpha presetColors={[]} />
      </div>
    </div>
  );
}
