import { v4 as uuidv4 } from 'uuid';
import { updateQuestionnaireStructure } from 'src/lib/store/slices/findersSlice';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getButtons(questionnaireElement) {
  const buttons = [];

  if (questionnaireElement.content?.composerButton) {
    buttons.push({
      ...questionnaireElement.content.composerButton,
      buttonName: questionnaireElement.content.composerButton.value,
      destination: 'composerButton',
    });
  }

  if (questionnaireElement?.content?.buttons?.length > 0) {
    buttons.push(
      ...questionnaireElement.content.buttons.map(resultButton => ({
        ...resultButton,
        buttonName: resultButton.buttonText.value,
        destination: 'buttons',
      }))
    );
  }

  if (questionnaireElement.content?.primaryButton) {
    buttons.push({
      ...questionnaireElement.content.primaryButton,
      buttonName: questionnaireElement.content.primaryButton.value,
      destination: 'primaryButton',
    });
  }

  if (questionnaireElement.content?.secondaryButton) {
    buttons.push({
      ...questionnaireElement.content.secondaryButton,
      buttonName: questionnaireElement.content.secondaryButton.value,
      destination: 'secondaryButton',
    });
  }

  if (questionnaireElement.content?.chapterButton && questionnaireElement.content.chapterButton.value) {
    buttons.push({
      ...questionnaireElement.content.chapterButton,
      buttonName: questionnaireElement.content.chapterButton.value,
      id: questionnaireElement.content.chapterButton.id || uuidv4(),
      destination: 'chapterButton',
    });
  }

  if (questionnaireElement.content?.completedChaptersButtons) {
    for (const chapterButton of questionnaireElement.content.completedChaptersButtons) {
      buttons.push({
        ...chapterButton,
        buttonName: chapterButton.buttonText.value,
        destination: 'completedChaptersButtons',
      });
    }
  }

  if (questionnaireElement.content?.products) {
    for (const depotProduct of questionnaireElement.content.products) {
      if (!depotProduct.buttonsSection?.buttons) {
        break;
      }

      for (const depotButton of depotProduct.buttonsSection.buttons) {
        buttons.push({
          ...depotButton,
          buttonName: depotButton.buttonText.value,
          destination: 'depotButtons',
        });
      }
    }
  }

  return buttons;
}

const updateContentButton = (button, rules, elementId, questionnaireStructure) => {
  const { id, destination } = button;
  return questionnaireStructure.map(structureItem => {
    return elementId === structureItem.id
      ? {
          ...structureItem,
          content: {
            ...structureItem.content,
            [destination]: {
              ...structureItem.content[destination],
              id,
              rules,
            },
          },
        }
      : structureItem;
  });
};

const updateButtonsArray = (button, rules, elementId, questionnaireStructure) => {
  const { id, destination } = button;
  return questionnaireStructure.map(structureItem => {
    return elementId === structureItem.id
      ? {
          ...structureItem,
          content: {
            ...structureItem.content,
            [destination]: structureItem.content[destination].map(buttonItem => {
              return buttonItem.id === id
                ? {
                    ...buttonItem,
                    rules,
                  }
                : buttonItem;
            }),
          },
        }
      : structureItem;
  });
};

const updateDepotButtons = (button, rules, questionnaireElement, questionnaireStructure) => {
  const { id } = button;

  const updatedProducts = questionnaireElement.content.products.map(product => {
    return {
      ...product,
      buttonsSection: {
        ...product.buttonsSection,
        buttons: product.buttonsSection.buttons.map(productButton => {
          return productButton.id === id
            ? {
                ...productButton,
                rules,
              }
            : productButton;
        }),
      },
    };
  });

  return questionnaireStructure.map(structureItem => {
    return questionnaireElement.id === structureItem.id
      ? {
          ...structureItem,
          content: {
            ...structureItem.content,
            products: updatedProducts,
          },
        }
      : structureItem;
  });
};

export function useUpdateButtonRule() {
  const dispatch = useDispatch();

  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const updateButton = (button, rules, questionnaireElement) => {
    const { destination } = button;
    const { id: elementId } = questionnaireElement;

    let updatedStructure = null;
    switch (destination) {
      case 'chapterButton':
      case 'composerButton':
      case 'primaryButton':
      case 'secondaryButton': {
        updatedStructure = updateContentButton(button, rules, elementId, questionnaireStructure);
        break;
      }
      case 'completedChaptersButtons':
      case 'buttons': {
        updatedStructure = updateButtonsArray(button, rules, elementId, questionnaireStructure);
        break;
      }
      case 'depotButtons': {
        updatedStructure = updateDepotButtons(button, rules, questionnaireElement, questionnaireStructure);
        break;
      }
      default: {
        updatedStructure = questionnaireStructure;
      }
    }

    dispatch(updateQuestionnaireStructure({ structure: updatedStructure }));
  };

  return { updateButton };
}
