import { AppTitle } from '../../../../../../ui/text/AppTitle';
import { InfoIconComponent } from '../../pages/PreviewPage/components/InfoIconComponent';
import classes from './index.module.scss';
import { getTextStyles, isLength } from '../../../../../../lib/util/helpers';
import { ComparisonTable } from './components/ComparisonTable';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import classNames from 'classnames';
import { getBgColor } from './lib/utils';

export function ComparisonTablePreview(props) {
  const { fonts, colors, dataObj, className, pagesFlow = {}, style, ...rest } = props;

  const { applyVariable } = useVariables(pagesFlow);

  const headline = dataObj?.content?.headerHeadline;
  const background = getBgColor(colors);

  return (
    <div className={classNames(classes.root, className)} style={{ background, ...style }} {...rest}>
      <div className={classes.content}>
        {headline?.value && (
          <div className={classes.headlineWrapper}>
            <div className={classes.headline}>
              <AppTitle level={2} textValue={applyVariable(headline.value)} style={getTextStyles(fonts?.headlineSecond, colors)} />
              <InfoIconComponent applyVariable={applyVariable} type={headline} className={classes.headlineInfoIcon} />
            </div>
          </div>
        )}

        {isLength(dataObj?.content?.tables) && (
          <ComparisonTable applyVariable={applyVariable} tableList={dataObj.content.tables} fonts={fonts} colors={colors} />
        )}
      </div>
    </div>
  );
}
