import { Form } from 'antd';
import { useResetArrange } from '../../utils/hooks/useResetArrange';
import { SortBlock } from '../SortBlock';
import { ArrangePopupWrapper } from '../ArrangePopupWrapper';

export function SortPopup({ form, onFinish, initialValues = {}, isActiveArrange }) {
  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangePopupWrapper sort={<SortBlock />} />
    </Form>
  );
}
