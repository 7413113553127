/* eslint-disable no-magic-numbers,consistent-return */

export function getDotValue(amountValue, answer) {
  const rangeSum = answer.maxValue - answer.minValue;
  const percentage = (amountValue - answer.minValue) / rangeSum;
  if (percentage === 0 || amountValue <= answer.minValue) {
    return 0;
  }
  // 5%
  if (percentage > 0 && percentage <= 0.05) {
    return (88 * percentage) / 0.05;
  }
  // 10%
  if (percentage > 0.05 && percentage <= 0.1) {
    return ((166 - 88) * (percentage - 0.05)) / (0.1 - 0.05) + 88;
  }
  // 25%
  if (percentage > 0.1 && percentage <= 0.25) {
    return ((260 - 166) * (percentage - 0.1)) / (0.25 - 0.1) + 166;
  }
  // 50%
  if (percentage > 0.25 && percentage <= 0.5) {
    return ((394 - 260) * (percentage - 0.25)) / (0.5 - 0.25) + 260;
  }
  // 75%
  if (percentage > 0.5 && percentage <= 0.75) {
    return ((520 - 394) * (percentage - 0.5)) / (0.75 - 0.5) + 394;
  }
  // 100%
  if (percentage > 0.75) {
    return ((616 - 520) * (percentage - 0.75)) / (1 - 0.75) + 520;
  }
}

export function getAmount(dotValue, answer) {
  const rangeSum = answer.maxValue - answer.minValue;
  if (dotValue === 0) {
    return answer.minValue;
  }
  // 5%
  if (dotValue > 0 && dotValue <= 88) {
    return (rangeSum * 0.05 * dotValue) / 88 + answer.minValue;
  }
  // 10%
  if (dotValue > 88 && dotValue <= 166) {
    return ((rangeSum * 0.1 - rangeSum * 0.05) * (dotValue - 88)) / (166 - 88) + rangeSum * 0.05 + answer.minValue;
  }
  // 25%
  if (dotValue > 166 && dotValue <= 260) {
    return ((rangeSum * 0.25 - rangeSum * 0.1) * (dotValue - 166)) / (260 - 166) + rangeSum * 0.1 + answer.minValue;
  }
  // 50%
  if (dotValue > 260 && dotValue <= 394) {
    return ((rangeSum * 0.5 - rangeSum * 0.25) * (dotValue - 260)) / (394 - 260) + rangeSum * 0.25 + answer.minValue;
  }
  // 75%
  if (dotValue > 394 && dotValue <= 520) {
    return ((rangeSum * 0.75 - rangeSum * 0.5) * (dotValue - 394)) / (520 - 394) + rangeSum * 0.5 + answer.minValue;
  }
  // 100%
  if (dotValue > 520) {
    return ((rangeSum - rangeSum * 0.75) * (dotValue - 520)) / (616 - 520) + rangeSum * 0.75 + answer.minValue;
  }
}
