/* eslint-disable react/no-array-index-key */
import { AppText } from './text/AppText';

export function TextWithHighlighting({ textArray, textProps = {}, highlightedProps = {}, ...rest }) {
  return (
    <div {...rest}>
      {Array.from(textArray).map((item, index) => {
        const highlightedText = item?.textValue;

        return <AppText key={index} {...textProps} {...(highlightedText && highlightedProps)} textValue={highlightedText || item} />;
      })}
    </div>
  );
}
