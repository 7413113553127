import styled from 'styled-components';
import { AppList } from '../../../../../../../../../../../../ui/AppList';

export function ProductLayoutProductListWrapper({ list, children, ...rest }) {
  return (
    <Root {...rest}>
      <AppList>{children}</AppList>
    </Root>
  );
}

const Root = styled('div')({
  overflowX: 'auto',
  height: '100%',

  '::-webkit-scrollbar': {
    display: 'none !important',
  },

  '& > ul': {
    display: 'flex',
    alignItems: 'stretch',
    gap: '32px',
    padding: '18px 233px 0',
  },
});
