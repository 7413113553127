import styled from 'styled-components';
import { AppSpace } from '../../../../../../../../../ui/AppSpace';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { DesertIllustration } from '../../../../../../../../../ui/illustrations/DesertIllustration';

export function Explanation({ descriptionText }) {
  return (
    <div style={styles.root}>
      <Space size='middle'>
        <Card>
          <span style={styles.illustration}>
            <DesertIllustration />
          </span>
          <AppText textValue={descriptionText} style={styles.description} />
        </Card>
      </Space>
    </div>
  );
}

const styles = {
  root: {
    padding: '16px 24px',
  },
  illustration: {
    display: 'flex',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginRight: '24px',
  },
  description: {
    fontWeight: 700,
  },
};

const Space = styled(AppSpace)({
  width: '100%',
  flexDirection: 'column',
  '& > .ant-space-item': {
    width: '100%',
  },
});

const Card = styled.div({
  display: 'flex',
  background: '#F2F7F9',
  borderRadius: '8px',
  padding: '24px',
});
