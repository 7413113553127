import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addThemeError,
  addThemeRequest,
  addThemeSuccess,
  applyThemeArrangesError,
  applyThemeArrangesRequest,
  closeThemeModal,
  deleteThemeError,
  deleteThemeRequest,
  deleteThemeSuccess,
  duplicateThemeError,
  duplicateThemeRequest,
  duplicateThemeSuccess,
  editThemeError,
  editThemeRequest,
  editThemeSuccess,
  getFontsError,
  getFontsRequest,
  getFontsSuccess,
  getThemesError,
  getThemesRequest,
  getThemesSuccess,
  removeThemeFiltersError,
  removeThemeFiltersRequest,
  setThemeStatusError,
  setThemeStatusRequest,
  setThemeStatusSuccess,
  uploadFontError,
  uploadFontRequest,
  uploadFontSuccess,
} from '../slices/themesSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getThemesApiRequest } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    themes: { sorting, filtering, pagination },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });
  const response = yield call(getThemesApiRequest, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getThemesSuccess({ themes: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchThemes({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getThemesError({ error }));
  }
}

function* addTheme({ payload: themeForm }) {
  try {
    const res = yield call(axiosInstance.post, '/brand/', themeForm);
    if (res?.data) {
      yield put(addThemeSuccess({ success: { messageType: addThemeSuccess.type }, selectedTheme: res.data }));
    }
  } catch (error) {
    yield put(addThemeError({ error }));
  }
}

function* editTheme({ payload }) {
  try {
    yield call(axiosInstance.put, '/brand/update', payload);
    yield put(editThemeSuccess({ success: { messageType: editThemeSuccess.type } }));
  } catch (error) {
    yield put(editThemeError({ error }));
  }
}

function* deleteTheme({ payload: themeId }) {
  try {
    yield call(axiosInstance.delete, `/brand/${themeId}/delete`);
    yield put(deleteThemeSuccess({ success: { messageType: deleteThemeSuccess.type } }));
    yield put(closeThemeModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteThemeError({ error }));
  }
}

function* duplicateTheme({ payload: theme }) {
  try {
    yield call(axiosInstance.post, '/brand/duplicate', theme);
    yield put(duplicateThemeSuccess({ success: { messageType: duplicateThemeSuccess.type } }));
    yield put(closeThemeModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(duplicateThemeError({ error }));
  }
}

function* setThemeStatus({ payload: { themeId, status } }) {
  try {
    yield call(axiosInstance.patch, `/brand/${themeId}/set-active?active=${status}`);
    yield put(setThemeStatusSuccess({ success: { messageType: setThemeStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setThemeStatusError({ error }));
  }
}

function* fetchFonts() {
  try {
    const { data } = yield call(axiosInstance.get, '/font/listFonts');
    yield put(getFontsSuccess(data));
  } catch (error) {
    yield put(getFontsError({ error }));
  }
}

function* uploadFont({ payload: formData }) {
  try {
    yield call(axiosInstance.post, 'font/', formData);
    yield put(uploadFontSuccess());
    yield fork(fetchFonts);
  } catch (error) {
    yield put(uploadFontError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyThemeArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeThemeFiltersError({ error }));
  }
}

/**
 * @desc function generator - observer for all themes sagas functions
 */
export function* watchThemesManagement() {
  yield all([
    takeLatest(getThemesRequest.type, fetchThemes),
    takeLatest(setThemeStatusRequest.type, setThemeStatus),
    takeLatest(addThemeRequest.type, addTheme),
    takeLatest(deleteThemeRequest.type, deleteTheme),
    takeLatest(editThemeRequest.type, editTheme),
    takeLatest(duplicateThemeRequest.type, duplicateTheme),
    takeLatest(uploadFontRequest.type, uploadFont),
    takeLatest(getFontsRequest.type, fetchFonts),
    takeLatest(applyThemeArrangesRequest.type, applyArranges),
    takeLatest(removeThemeFiltersRequest.type, removeFilters),
  ]);
}
