import { useCallback, useEffect, useState } from 'react';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';

export function PreviewAudioPlayer({ audioPlayer, pagesFlow, isAudioGuidePlaying, pauseAudio, setPagesFlow }) {
  const { currentPageId, currentChapterId, allPages, allChapters, completed } = pagesFlow;
  const [audioUrl, setAudioUrl] = useState(null);

  const getAudioUrl = useCallback(async () => {
    const isPageWithAllChapters = allPages.find(page => page.id === currentPageId && page.templateType === TemplateTypes.CHAPTER);
    const currentChapterIndex = allChapters.findIndex(chapter => chapter.id === currentChapterId);
    const currentChapter = allChapters[currentChapterIndex];

    const isIntroPage = allPages.find(page => page.id === currentPageId);

    if (completed) {
      // set isPaused if we finished all chapters
      await pauseAudio();

      setPagesFlow(prev => ({
        ...prev,
        completed: false,
      }));
      return;
    }

    if (isPageWithAllChapters) {
      const currentAudio = currentChapter?.chapterPages.find(chapterPage =>
        chapterPage.elements.find(item => item.templateType === TemplateTypes.CHAPTER)
      );

      if (currentAudio?.audio?.s3Url) {
        setAudioUrl(currentAudio.audio.s3Url);
      }
    } else if (!isPageWithAllChapters) {
      if (isIntroPage?.audio) {
        setAudioUrl(isIntroPage.audio.s3Url);

        return;
      }

      if (currentChapter?.chapterPages) {
        const currentAudioUrl = currentChapter.chapterPages.find(chapterPage => chapterPage.id === currentPageId)?.audio?.s3Url;

        setAudioUrl(currentAudioUrl);

        return;
      }

      setAudioUrl(null);
      await audioPlayer?.current?.pause();
    }
  }, [allChapters, allPages, audioPlayer, completed, currentChapterId, currentPageId, pauseAudio, setPagesFlow]);

  useEffect(() => {
    if (isAudioGuidePlaying) {
      getAudioUrl();
    }
  }, [getAudioUrl, isAudioGuidePlaying]);

  useEffect(() => {
    if (audioUrl && isAudioGuidePlaying && audioPlayer?.current) {
      audioPlayer.current?.play();
    }
  }, [audioPlayer, audioUrl, isAudioGuidePlaying]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <audio ref={audioPlayer} autoPlay={isAudioGuidePlaying} src={audioUrl} />;
}
