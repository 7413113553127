import { useNormalUpdateProductsSelection } from './useNormalUpdateProductsSelection';
import { useCallback } from 'react';

export function useNormalOnSelectCard({
  products,
  productId,
  categoryName,
  updateProductsStructure,
  customUnselectedProductIds,
  productObject,
  pagesFlow,
  scoringResult,
}) {
  const updatedDependenceProducts = products.map(prod => {
    const ruleCard = pagesFlow.rulesProductsProperties.find(ruleProd => ruleProd.id === prod.id);
    const { ruleChildFor, ruleParentFor } = ruleCard || {};
    return { ...prod, ruleChildFor, ruleParentFor };
  });

  const updateProductSelection = useNormalUpdateProductsSelection({
    products: updatedDependenceProducts,
    productId,
    categoryName,
    customUnselectedProductIds,
    scoringResult,
  });

  return useCallback(
    customValue => {
      const updatedProds = updateProductSelection(customValue);
      const updatedProductsSelection = updatedProds.map(item => {
        const updatedItem = { ...item };

        if (updatedItem.counterSection && updatedItem.selected) {
          updatedItem.counterSection.value =
            updatedItem.counterSection.value || updatedItem.counterSection.defaultValue || updatedItem.counterSection.min;
        }

        return updatedItem;
      });

      const selectedProducts = updatedProductsSelection.filter(item => item.selected);
      const unselectedProductIds = products.map(cardItem => cardItem?.id).filter(Boolean);

      updateProductsStructure(updatedProductsSelection, selectedProducts, true, productObject, unselectedProductIds);
    },
    [updateProductSelection, products, updateProductsStructure, productObject]
  );
}
