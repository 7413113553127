import { PreviewResultProductTableRow } from './PreviewResultProductTableRow';
import { AppList } from '../../../../../../../../../ui/AppList';
import { canShowProductInResult } from '../../../utils';

export function Products({ products, fonts, colors, productInformation, pagesFlow }) {
  return (
    <AppList>
      {products?.map(
        product =>
          canShowProductInResult(product) && (
            <PreviewResultProductTableRow
              key={product.id}
              product={product}
              productInformation={productInformation}
              colors={colors}
              fonts={fonts}
              pagesFlow={pagesFlow}
            />
          )
      )}
    </AppList>
  );
}
