import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { FormItemName } from '../../../../../../lib/models/Form';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function TenantListFormItem({ formItemProps, textFieldProps, title }) {
  return (
    <AppFormSection
      title={title}
      formItemProps={{
        name: FormItemName.TENANT_LIST,
        rules: [{ required: true }],
        ...formItemProps,
      }}
    >
      <AppNewSelect {...textFieldProps} />
    </AppFormSection>
  );
}
