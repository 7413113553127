import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../pages/QuestionnairePage/features/QuestionnaireSelect';

export function CurrencyFormItem({ currencies, value, onChange, color, size, ...rest }) {
  const { selectCurrencyLabel } = useLocalization().translations.unique.formItems;
  const currencyOptions = Object.values(currencies).map(currency => ({
    value: currency,
    title: currency,
  }));
  const { formItemProps = {}, selectProps, ...restRootProps } = rest;
  const { style, ...restFormItemProps } = formItemProps;

  return (
    <QuestionnaireSelect
      formItemProps={{
        style: { width: 226, minHeight: 40, ...style },
        ...restFormItemProps,
      }}
      {...restRootProps}
      selectProps={{
        value: { value },
        onChange: selected => onChange(selected?.value),
        options: currencyOptions,
        label: selectCurrencyLabel,
        ...selectProps,
      }}
    />
  );
}
