import { changeNumberValue } from './change_number_value';

export const getProductScoreValue = (cardScoreBlocks, originalScore) => {
  return cardScoreBlocks.reduce(
    (accumulator, cardScoreBlock) => {
      const { scoreProduct, resultOperator, value } = cardScoreBlock;

      if (scoreProduct) {
        const ruleScoreValue = changeNumberValue(value, resultOperator, scoreProduct, { value: originalScore });
        return { ruleScore: ruleScoreValue, originalScore };
      }

      return accumulator;
    },
    { ruleScore: undefined, originalScore }
  );
};
