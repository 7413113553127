// eslint-disable-next-line unicorn/no-unsafe-regex
export const LINK_PATTERN = /^(https?:\/\/[\w%+-]+(\.[\w%+-]+)*(:\d{1,5})?(\/[\w#%&()+.=?~-]*)*)?$/;

// eslint-disable-next-line unicorn/no-unsafe-regex
export const PHONE_NUMBER_PATTERN = /\+?\d{1,4}?[\s.-]?\(?\d{1,3}?\)?(?:[\s.-]?\d{1,4}){2}[\s.-]?\d{1,9}/;

/* Why such regexp?
 * https://habr.com/ru/post/175375/
 * https://habr.com/ru/post/320572/
 */

export const EMAIL_SIMPLE_PATTERN = /@/;
