import { QuestionnaireFormSection } from './QuestionnaireFormSection';
import { useEffect, useState } from 'react';

const DELAY = 100;
let timer = null;

export function QuestionnaireFormSectionWithDelay({ textFieldProps, ...restProps }) {
  const { onChange, value, ...restTextFieldProps } = textFieldProps;

  const [valueState, setValueState] = useState('');

  useEffect(() => {
    setValueState(value);
  }, [value]);
  const handleChange = ({ target: { value } }) => {
    setValueState(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(value);
    }, DELAY);
  };

  return (
    <QuestionnaireFormSection
      textFieldProps={{
        value: valueState,
        onChange: handleChange,
        ...restTextFieldProps,
      }}
      {...restProps}
    />
  );
}
