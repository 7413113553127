import { useDispatch, useSelector } from 'react-redux';
import { CategoryModal } from '../../../../../../../lib/models/Modal';
import { AddCategoryModal } from './components/AddCategoryModal';
import { closeCategoryModal } from '../../../../../../../../../lib/store/slices/categoriesSlice';
import { EditCategoryModal } from './components/EditCategoryModal';
import { DeleteCategoryModal } from './components/DeleteCategoryModal';

export function CategoryModals() {
  const { isModalOpen, modalType: currentModalType, selectedCategory, isModalLoading } = useSelector(({ categories }) => categories);
  const { tenant } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const close = () => dispatch(closeCategoryModal());
  const checkIsOpen = modalType => isModalOpen && modalType === currentModalType;

  const genericProps = { categoryData: selectedCategory, close, isModalLoading, tenantId: tenant.id };

  return (
    <>
      <AddCategoryModal {...genericProps} isOpen={checkIsOpen(CategoryModal.ADD)} />
      <EditCategoryModal {...genericProps} isOpen={checkIsOpen(CategoryModal.EDIT)} />
      <DeleteCategoryModal {...genericProps} isOpen={checkIsOpen(CategoryModal.DELETE)} />
    </>
  );
}
