// REFACTOR: move consts outside

async function loadFont(fontName, src) {
  const LAST_INDEX = -1;

  const formats = {
    ttf: 'truetype',
    otf: 'opentype',
    eot: 'embedded-opentype',
    woff: 'woff',
    woff2: 'woff2',
  };

  try {
    const fileExtension = src.split('.').at([LAST_INDEX]);
    const format = formats[fileExtension?.toLowerCase()];
    const formattedUrl = fileExtension === 'eot' ? `${src}?#iefix` : src;
    const font = new FontFace(fontName, `local('${fontName}'), url('${formattedUrl}') format('${format}')`);
    await font.load();
    document.fonts.add(font);
  } catch {
    // appLogger.info(error, fontName);
  }
}

export function loadFonts(fonts) {
  for (const fontObj of fonts) {
    loadFont(fontObj.fontName, fontObj.fontUrl);
  }
}
