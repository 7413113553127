export function LaptopIcon() {
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_8106_90704)'>
        <path
          d='M1.16667 13.8333C0.97778 13.8333 0.819336 13.7693 0.691336 13.6413C0.563336 13.5133 0.499558 13.3551 0.500002 13.1667C0.500002 12.9778 0.564002 12.8193 0.692002 12.6913C0.820002 12.5633 0.978225 12.4996 1.16667 12.5H1.83334V3.83333C1.83334 3.46667 1.964 3.15267 2.22534 2.89133C2.48667 2.63 2.80045 2.49956 3.16667 2.5H13.8333C14.2 2.5 14.514 2.63067 14.7753 2.892C15.0367 3.15333 15.1671 3.46711 15.1667 3.83333V12.5H15.8333C16.0222 12.5 16.1807 12.564 16.3087 12.692C16.4367 12.82 16.5004 12.9782 16.5 13.1667C16.5 13.3556 16.436 13.514 16.308 13.642C16.18 13.77 16.0218 13.8338 15.8333 13.8333H1.16667ZM7.5 12.5H9.5C9.58889 12.5 9.66667 12.4667 9.73334 12.4C9.8 12.3333 9.83334 12.2556 9.83334 12.1667C9.83334 12.0778 9.8 12 9.73334 11.9333C9.66667 11.8667 9.58889 11.8333 9.5 11.8333H7.5C7.41111 11.8333 7.33334 11.8667 7.26667 11.9333C7.2 12 7.16667 12.0778 7.16667 12.1667C7.16667 12.2556 7.2 12.3333 7.26667 12.4C7.33334 12.4667 7.41111 12.5 7.5 12.5ZM3.16667 10.5H13.8333V3.83333H3.16667V10.5Z'
          fill='url(#paint0_linear_8106_90704)'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_8106_90704' x1='0.5' y1='2.5' x2='18.0589' y2='10.3434' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F8F5FF' />
          <stop offset='0.515625' stopColor='white' />
          <stop offset='1' stopColor='#F6F4FF' />
        </linearGradient>
        <clipPath id='clip0_8106_90704'>
          <rect width='16' height='16' fill='white' transform='translate(0.5 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
