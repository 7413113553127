/* eslint-disable no-template-curly-in-string,id-denylist */
const typeTemplate = "'${name}' ist kein gültiger ${type}";

export const de_validateMessages = {
  default: "Validierungsfehler im Feld '${name}'",
  required: '${name} sollte angegeben werden',
  enum: "'${name}' muss einer von [${enum}] sein",
  whitespace: "'${name}' darf nicht leer sein",
  date: {
    format: "Das Format von '${name}' ist ungültig",
    parse: "'${name}' konnte nicht als Datum geparst werden",
    invalid: "'${name}' ist kein gültiges Datum",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${name}' muss genau ${len} Zeichen lang sein",
    min: "'${name}' muss mindestens ${min} Zeichen lang sein",
    max: "'${name}' darf nicht länger als ${max} Zeichen sein",
    range: "Die Länge von '${name}' muss zwischen ${min} und ${max} Zeichen liegen",
  },
  number: {
    len: "'${name}' muss gleich ${len} sein",
    min: "'${name}' darf nicht kleiner als ${min} sein",
    max: "'${name}' darf nicht größer als ${max} sein",
    range: "'${name}' muss zwischen ${min} und ${max} liegen",
  },
  array: {
    len: "Die Länge von '${name}' muss genau ${len} sein",
    min: "Die Länge von '${name}' darf nicht kleiner als ${min} sein",
    max: "Die Länge von '${name}' darf nicht größer als ${max} sein",
    range: "Die Länge von '${name}' muss zwischen ${min} und ${max} liegen",
  },
  pattern: {
    mismatch: "'${name}' ist ungültig. Bitte geben Sie einen gültigen Wert ein",
  },
};

export const end_user_de_validateMessages = {
  default: 'Validierungsfehler im Feld',
  required: 'Feld sollte angegeben werden',
  date: {
    format: 'Das Format ist ungültig',
    parse: 'konnte nicht als Datum geparst werden',
    invalid: 'ist kein gültiges Datum',
  },
  pattern: {
    mismatch: 'Die Daten sind ungültig.',
  },
};
