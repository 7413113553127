import { components } from 'react-select';
import { ColorBlock } from './ColorBlock';

export function ColorOption(props) {
  const { data } = props;

  return (
    <components.Option {...props}>
      <ColorBlock {...props} color={data.value} label={data.label} />
    </components.Option>
  );
}
