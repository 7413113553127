/* eslint-disable no-magic-numbers */
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { InfoIconComponent } from '../../InfoIconComponent';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';
import { HtmlNodeId } from '../../../../../../../../../lib/util/HtmlNodeId';

export function TotalPrice({
  fieldNameProps = {},
  priceProps = {},
  productInformation,
  totalPrice = {},
  fonts = {},
  disableInfoIcon = false,
  disableAdaptive = false,
  applyVariable,
  ...rest
}) {
  const { totalPriceOfMostRepeatedCurrency, totalPriceListOfRestCurrencies } = totalPrice;
  const { windowWidth } = useWindowResize();
  const { style: fieldNameStyle, ...restFieldNameProps } = fieldNameProps;
  const { style: priceStyle, ...restPriceProps } = priceProps;
  const infoIconObj = { infoIcon: { value: totalPriceListOfRestCurrencies } };

  return (
    <Root {...rest} id={HtmlNodeId.PDF_RESULT_TOTAL_PRICE}>
      <TotalPriceName>
        <Typography.Paragraph
          style={{
            maxWidth: 250,
            fontSize: windowWidth > AppBreakpoint.M || disableAdaptive ? fonts?.body?.fontSize : fonts?.subText?.fontSize,
            fontFamily: fonts?.body?.font,
            fontWeight: 700,
            color: '#FFFFFF',
            marginLeft: windowWidth > AppBreakpoint.M || disableAdaptive ? 118 : 10,
            marginBottom: 0,
            ...fieldNameStyle,
          }}
          ellipsis={{ rows: 1 }}
          {...restFieldNameProps}
        >
          {applyVariable(productInformation?.priceName?.value)}
        </Typography.Paragraph>
        {!disableInfoIcon && <InfoIconComponent type={productInformation?.priceName} applyVariable={applyVariable} styles={{ top: 1 }} />}
      </TotalPriceName>
      <p
        style={{
          marginBottom: 0,
          fontSize: windowWidth > AppBreakpoint.M || disableAdaptive ? fonts?.body?.fontSize : fonts?.subText?.fontSize,
          fontFamily: fonts?.body?.font,
          fontWeight: 700,
          color: '#FFFFFF',
          position: 'relative',
          ...priceStyle,
        }}
        {...restPriceProps}
      >
        {totalPriceOfMostRepeatedCurrency}
        {!disableInfoIcon && totalPriceListOfRestCurrencies && <InfoIconComponent type={infoIconObj} {...totalPriceValueInfoIconProps} />}
      </p>
    </Root>
  );
}

const styles = {
  overlayStyle: {
    maxWidth: 'unset',
    width: 'unset',
  },
  overlayInnerStyle: {
    whiteSpace: 'pre',
    lineHeight: 1.7,
  },
};

const tooltipProps = { overlayStyle: styles.overlayStyle, overlayInnerStyle: styles.overlayInnerStyle };

const totalPriceValueInfoIconProps = {
  tooltipProps,
  styles: { position: 'static' },
};

const Root = styled('div')({
  backgroundColor: 'rgba(25, 25, 25, 0.7)',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: '24px',
  paddingRight: '24px',
});

const TotalPriceName = styled('div')({
  display: 'flex',
});
