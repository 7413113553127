import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { LabelTexts, TemplateTypes } from '../../../../../../lib/models/TemplateTypes';

export function ChapterSelectFormItem({ value, onChange, options, disabled }) {
  const chapterOptions = options.map((chapter, index) => ({
    value: chapter.id,
    title: `${LabelTexts[TemplateTypes.CHAPTER]}.${index + 1}.${chapter.label}`,
  }));

  return (
    <AppNewSelect
      options={chapterOptions}
      value={value}
      onChange={selected => onChange(selected.map(item => item.value))}
      withoutLimit
      isMultiSelect
      disabled={disabled}
    />
  );
}
