import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ProductRangeSavings } from './ProductRangeSavings';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import '../style.scss';

/**
 * @desc Question Range Savings component.
 * @param {Object} questionElement - The question element object.
 * @param {Object} selectedQuestion - The selected question object.
 * @returns {JSX.Element} - JSX component representing the Question Range Savings.
 */
export function QuestionRangeSavings({ questionElement, selectedQuestion }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, tenantProducts, isViewMode } = useSelector(({ finders }) => finders);

  const { clearAll, addProduct: addProductText } = useLocalization().translations.shared.generic;
  const { selectAnswer: selectAnswerText, calculateSavings } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.savings;

  const savingsProductsRanges = questionElement?.content?.savingsProductsRanges || [];

  const clearAllAnswerScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              savingsProductsRanges: savingsProductsRanges.map(prodRangeObj => ({
                ...prodRangeObj,
                savingsRanges: prodRangeObj.savingsRanges.map(range => ({ ...range, minValue: 0, maxValue: 0, savingsValue: 0 })),
              })),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const addProductSavings = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              savingsProductsRanges: [...savingsProductsRanges, { id: uuidv4(), productId: null, savingsRanges: [] }],
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return questionElement?.content?.counterAnswers?.some(answer => answer.id) ? (
    <>
      <div className='products_savings_clear_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={clearAllAnswerScore} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={clearAll} />
        </AppBasicButton>
      </div>
      <div className='products_savings_block'>
        <AppText className='products_savings_text' textValue={calculateSavings} />
        {savingsProductsRanges?.map(savingProdObj => (
          <ProductRangeSavings key={savingProdObj.id} savingObj={savingProdObj} selectedQuestion={selectedQuestion} savings={savingsProductsRanges} />
        ))}
        <div className='products_savings_add_btn'>
          <AppBasicButton
            style={{ display: 'inline-flex' }}
            onClick={addProductSavings}
            disabled={isViewMode || tenantProducts.length <= savingsProductsRanges.length}
          >
            <AppText className='primary_color bold' textValue={addProductText} />
          </AppBasicButton>
        </div>
      </div>
    </>
  ) : (
    <AppParagraph className='products_score_description' textValue={selectAnswerText} />
  );
}
