import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  categories: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  error: null,
  selectedCategory: {},
  isModalLoading: false,
  modalType: '',
  isModalOpen: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getCategoriesRequest: state => {
      state.error = null;
      state.loading = true;
      state.categories = [];
    },
    getCategoriesSuccess: (state, action) => {
      state.categories = action.payload.categories;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getCategoriesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openCategoryModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedCategory = action.payload.selectedCategory || {};
    },
    closeCategoryModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedCategory = {};
    },
    addCategoryRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    addCategorySuccess: state => {
      state.isModalLoading = false;
    },
    addCategoryError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editCategoryRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    editCategorySuccess: state => {
      state.isModalLoading = false;
    },
    editCategoryError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    deleteCategoryRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteCategorySuccess: state => {
      state.isModalLoading = false;
    },
    deleteCategoryError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    /* arranges */
    // apply arranges
    applyCategoryArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyCategoryArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeCategoryFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeCategoryFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeCategoryArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesError,
  openCategoryModal,
  closeCategoryModal,
  addCategoryRequest,
  addCategorySuccess,
  addCategoryError,
  editCategoryRequest,
  editCategorySuccess,
  editCategoryError,
  deleteCategoryRequest,
  deleteCategorySuccess,
  deleteCategoryError,
  applyCategoryArrangesRequest,
  applyCategoryArrangesError,
  removeCategoryFiltersRequest,
  removeCategoryFiltersError,
  removeCategoryArranges,
} = categoriesSlice.actions;
export const categories = categoriesSlice.reducer;
