/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { useContentTable } from '../hooks/useContentTable';
import { InfoIconTextarea } from '../../../../../../features/form-items/InfoIconTextarea';
import { updateInfoIconsIndexes } from '../../../../utils/helpers';

function deleteInfoIcon(tableRows, rowIndex, columnIndex) {
  tableRows[rowIndex].cells[columnIndex].infoIcon = null;
}

export function InfoIconInputs() {
  const { contentTableState } = useContentTable();
  const { templateInfo } = useLocalization().translations.unique.formItems;

  const handleChangeInfoIcon = (rowIndex, columnIndex, value) => {
    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].cells[columnIndex].infoIcon.value = value;
    });
  };

  const onDeleteInfoIcon = (rowIndex, columnIndex) => {
    contentTableState.updateTable(({ tableRows }) => {
      deleteInfoIcon(tableRows, rowIndex, columnIndex);
      updateInfoIconsIndexes(tableRows);
    });
  };

  return (
    <StyledInfoIconInputs>
      {contentTableState.tableRows?.map((row, rowIndex) => {
        return row?.cells?.map((cell, columnIndex) => (
          <Fragment key={cell?.id}>
            {cell?.infoIcon ? (
              <InfoIconTextarea
                valueObject={cell.infoIcon || { value: '' }}
                label={templateInfo.label({ number: cell.infoIcon.infoIconIndex })}
                onChange={value => handleChangeInfoIcon(rowIndex, columnIndex, value)}
                onClickSuffix={() => onDeleteInfoIcon(rowIndex, columnIndex)}
              />
            ) : null}
          </Fragment>
        ));
      })}
    </StyledInfoIconInputs>
  );
}

const StyledInfoIconInputs = styled('div')({
  marginLeft: 25,
  marginTop: 20,
});
