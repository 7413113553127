import { CardSection } from '../../../../../../../ui/CardSection';
import { ProductPageLinkSectionHeader } from './ProductPageLinkSectionHeader';
import { ProductPageLink } from './ProductPageLink';

export function ProductPageLinkSection({ structureItemIndex, productIndex, value }) {
  return (
    <CardSection withBorderBottom={false}>
      <ProductPageLinkSectionHeader />
      <ProductPageLink productIndex={productIndex} value={value} structureItemIndex={structureItemIndex} />
    </CardSection>
  );
}
