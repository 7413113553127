import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getFinderRequest } from '../store/slices/findersSlice';

export function useGetFinder() {
  const dispatch = useDispatch();
  let { id: finderId } = useParams();
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));

  finderId = Number(finderId);

  const getFinder = useCallback(() => {
    dispatch(getFinderRequest({ id: finderId, tenantId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finderId, tenantId]);

  return { getFinder, finderId, tenantId };
}
