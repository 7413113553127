import { AppFormSection } from '../ui/AppFormSection';
import { useLocalization } from '../lib/hooks/useLocalization';
import { CategoryFormItem } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/modules/categories/CategoryModals/lib/Category';

export function CategoryNameFormItem(props) {
  const { label } = useLocalization().translations.unique.formItems.categoryName;
  const { formItemProps, textFieldProps } = props;

  return (
    <AppFormSection
      formItemProps={{
        name: CategoryFormItem.CATEGORY_NAME,
        rules: [{ required: true }],
        style: { minHeight: '63px' },
        ...formItemProps,
      }}
      textFieldProps={{
        label,
        ...textFieldProps,
      }}
    />
  );
}
