/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { Fragment, useEffect, useState } from 'react';
import { PreviewChapterLayout } from '../PreviewChapterLayout';
import { PreviewIntroLayout } from '../PreviewIntroLayout';
import { PreviewQuestion } from '../PreviewQuestion';
import { PreviewResultLayout } from '../PreviewResultLayout';
import { PreviewProduct } from '../PreviewProduct';
import { PreviewContentLayout } from '../PreviewContentLayout';
import { PreviewDynamicContactForm } from '../PreviewDynamicContactForm';
import { PreviewResultDepotLayout } from '../PreviewResultDepotLayout';
import { ComparisonTablePreview } from '../../../../modules/ComparisonTableLayout';
import { ChapterState, TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { PreviewForm } from '../PreviewForm';
import { PreviewContextProvider } from '../../PreviewContext';
import { Form } from 'antd';
import { ResultButton } from '../Buttons/ResultButton';
import { NextPrevButtons } from '../Buttons/NextPrevButtons';
import { elementRulesEngine } from '../../../../utils/rules/element_rules_engine_helper';
import { chaptersRulesEngine } from '../../../../utils/rules/chapters_rules_engine_helper';
import { productSelectionRulesEngine } from '../../../../utils/rules/products_selection_rules_engine_helper';
import { rulesEngine } from 'src/processes/CMS/processes/management/utils/rules/rules_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { answerSetRulesEngine } from '../../../../utils/rules/answer_set_rules_engine_helper';
import { answerSelectionRulesEngine } from '../../../../utils/rules/answer_selection_rules_engine_helper';
import styles from './styles.module.scss';

export function PreviewLayoutTemplates({
  media,
  pagesFlow,
  setPagesFlow,
  fonts,
  colors,
  bulletIcons,
  fontsForLoading,
  showChapterIds,
  setShowChapterIds,
  isTesting,
}) {
  const { processVariables } = useVariables(pagesFlow, setPagesFlow);
  const [form] = Form.useForm();
  const finderUrl = pagesFlow?.finder?.url;
  const finderType = pagesFlow?.finder?.finderType;

  const [previewState, setPreviewState] = useState({
    form,
    finderUrl,
    isFormSubmitting: false,
    finderType,
  });

  const [pageHistory, setPageHistory] = useState([]);
  const [imageSelectionFlow, setImageSelectionFlow] = useState([]);
  const [allowRulesProductSelection, setAllowRulesProductSelection] = useState([]);
  const [allowRulesAnswerSelection, setAllowRulesAnswerSelection] = useState([]);
  const [rulesSetAnswerValues, setRulesSetAnswerValues] = useState([]);
  const [showCompletedChapters, setShowCompletedChapters] = useState(false);

  const { allPages, currentPageId, currentElementsIds, showContactForm, allChapters, resultTableContent, clickedButtonsIds } = pagesFlow;

  const currentPage = allPages.find(page => page.id === currentPageId);
  const currentElements = currentPage?.elements ? currentPage?.elements?.filter(el => currentElementsIds.includes(el.id)) : [];
  const isDisabled = currentElements.some(el => el.content.mandatory && !isLength(el.selectedAnswers));
  const genericProps = {
    colors,
    fonts,
    pagesFlow,
    setPagesFlow,
    allPages,
    setPreviewState,
    fontsForLoading,
    setPageHistory,
    pageHistory,
    currentElements,
    showChapterIds,
  };

  const allCurrentElementsRules = currentElements.filter(el => el.templateType !== TemplateTypes.CHAPTER).flatMap(el => el.rules);
  const allChaptersRules = allChapters.flatMap(el => el.rules);

  useEffect(() => {
    if (finderUrl || finderType) {
      setPreviewState(prev => ({ ...prev, finderUrl, finderType }));
    }
  }, [finderUrl, finderType]);

  useEffect(() => {
    if (isLength(allCurrentElementsRules)) {
      elementRulesEngine(allCurrentElementsRules, pagesFlow, setPagesFlow, currentElements);
      const allRules = allPages.flatMap(page => page.elements.filter(el => el.rules.length)).flatMap(singlePage => singlePage.rules);
      const { resultVariables } = rulesEngine({ rules: allRules, pagesFlow, currentElements, rulesFilter: ['resultVariables'] });
      processVariables(resultVariables);
    }
    if (isLength(allChaptersRules) && isLength(showChapterIds)) {
      chaptersRulesEngine(allChaptersRules, pagesFlow, showChapterIds, setShowChapterIds, currentElements, setImageSelectionFlow, setPagesFlow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, currentPageId, isLength(allCurrentElementsRules), clickedButtonsIds]);

  useEffect(() => {
    if (isLength(allCurrentElementsRules)) {
      productSelectionRulesEngine(
        allCurrentElementsRules,
        pagesFlow,
        setPagesFlow,
        currentElements,
        allowRulesProductSelection,
        setAllowRulesProductSelection
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, allCurrentElementsRules, currentPageId, clickedButtonsIds]);

  useEffect(() => {
    if (isLength(allCurrentElementsRules)) {
      answerSetRulesEngine(allCurrentElementsRules, pagesFlow, setPagesFlow, currentElements, rulesSetAnswerValues, setRulesSetAnswerValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, currentPageId, clickedButtonsIds]);

  useEffect(() => {
    if (isLength(allCurrentElementsRules)) {
      answerSelectionRulesEngine(
        allCurrentElementsRules,
        pagesFlow,
        setPagesFlow,
        currentElements,
        allowRulesAnswerSelection,
        setAllowRulesAnswerSelection
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, allCurrentElementsRules, currentPageId, clickedButtonsIds]);

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      event => {
        if (event.detail > 1) {
          event.preventDefault();
        }
      },
      false
    );
  }, []);

  const getResultLayout = el => {
    if (el.content.products) {
      return <PreviewResultDepotLayout {...genericProps} resultObject={el} style={JSON.parse(el.layoutStyle)} />;
    }
    return <PreviewResultLayout {...genericProps} resultObject={el} style={JSON.parse(el.layoutStyle)} />;
  };

  const noPaddingTypes = new Set(['INTRO', 'CHAPTER']);
  const hasPadding = currentElements?.some(el => !noPaddingTypes.has(el.templateType));

  return (
    <PreviewContextProvider value={{ previewState, setPreviewState }}>
      <section className={styles.container} style={{ paddingBottom: hasPadding ? '88px' : 0 }}>
        {currentPage?.templateType === TemplateTypes.CHAPTER ? (
          <PreviewChapterLayout
            media={media}
            bulletIcons={bulletIcons}
            pagesFlow={pagesFlow}
            setPagesFlow={setPagesFlow}
            colors={colors}
            fonts={fonts}
            style={JSON.parse(currentPage.elements[0].layoutStyle)}
            setPageHistory={setPageHistory}
            currentElements={currentElements}
            imageSelectionFlow={imageSelectionFlow}
            showChapterIds={showChapterIds}
            showCompletedChapters={showCompletedChapters}
            setShowCompletedChapters={setShowCompletedChapters}
          />
        ) : (
          currentElements?.map(el => {
            return (
              <Fragment key={el.id}>
                {el.templateType === TemplateTypes.INTRO && (
                  <PreviewIntroLayout
                    media={media}
                    introObject={el}
                    colors={colors}
                    fonts={fonts}
                    style={JSON.parse(el.layoutStyle)}
                    pagesFlow={pagesFlow}
                    setPagesFlow={setPagesFlow}
                    setPageHistory={setPageHistory}
                    currentElements={currentElements}
                  />
                )}
                {el.templateType === TemplateTypes.QUESTION && (
                  <PreviewQuestion
                    key={el.id}
                    questionObject={el}
                    colors={colors}
                    fonts={fonts}
                    style={JSON.parse(el.layoutStyle)}
                    pagesFlow={pagesFlow}
                    setPagesFlow={setPagesFlow}
                    currentPageElements={currentPage?.elements}
                    setPageHistory={setPageHistory}
                    currentElements={currentElements}
                  />
                )}
                {el.templateType === TemplateTypes.PRODUCT && (
                  <PreviewProduct
                    productObject={el}
                    colors={colors}
                    fonts={fonts}
                    bulletIcons={bulletIcons}
                    style={JSON.parse(el.layoutStyle)}
                    pagesFlow={pagesFlow}
                    setPagesFlow={setPagesFlow}
                    currentElements={currentElements}
                    currentPageElements={currentPage?.elements}
                    setPageHistory={setPageHistory}
                    isTesting={isTesting}
                  />
                )}
                {el.templateType === TemplateTypes.RESULT &&
                  (showContactForm ? (
                    <PreviewForm {...genericProps} resultObject={el} style={JSON.parse(el.layoutStyle)} fonts={fonts} />
                  ) : (
                    getResultLayout(el)
                  ))}
                {el.templateType === TemplateTypes.CONTENT &&
                  (isLength(el?.content?.tables) ? (
                    <ComparisonTablePreview pagesFlow={pagesFlow} dataObj={el} colors={colors} fonts={fonts} />
                  ) : (
                    <PreviewContentLayout
                      contentObject={el}
                      colors={colors}
                      fonts={fonts}
                      media={media}
                      bulletIcons={bulletIcons}
                      style={JSON.parse(el.layoutStyle)}
                      pagesFlow={pagesFlow}
                      setPagesFlow={setPagesFlow}
                      setPageHistory={setPageHistory}
                      currentElements={currentElements}
                    />
                  ))}
                {el.templateType === TemplateTypes.CONTACT_FORM && (
                  <PreviewDynamicContactForm
                    {...genericProps}
                    colors={colors}
                    contactFormObject={el}
                    resultTableContent={resultTableContent}
                    style={JSON.parse(el.layoutStyle)}
                    form={form}
                    finderUrl={previewState.finderUrl}
                    currentElements={currentElements}
                  />
                )}
              </Fragment>
            );
          })
        )}
      </section>
      {currentPage?.templateType !== TemplateTypes.CHAPTER && currentElements[0]?.templateType !== TemplateTypes.INTRO && (
        <NextPrevButtons
          setPagesFlow={setPagesFlow}
          pagesFlow={pagesFlow}
          colors={colors}
          disabled={isDisabled}
          fonts={fonts}
          setPageHistory={setPageHistory}
          pageHistory={pageHistory}
          currentElements={currentElements}
        />
      )}
      {currentPage?.templateType === TemplateTypes.CHAPTER && allChapters.every(chapter => chapter?.state === ChapterState.COMPLETED) && (
        <ResultButton setPagesFlow={setPagesFlow} pagesFlow={pagesFlow} colors={colors} fonts={fonts} />
      )}
    </PreviewContextProvider>
  );
}
