import { BulletIconTag } from '../BulletIconTag';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../pages/QuestionnairePage/features/QuestionnaireSelect';

export function BulletPointIconFormItem({ icons, value, onChange, color, size, ...rest }) {
  const { label } = useLocalization().translations.unique.formItems.bulletIcon;
  const iconOptions = icons.map(icon => ({
    value: icon.id,
    title: <BulletIconTag size={size} color={color} icon={icon} />,
    svgContent: icon.svgContent,
  }));
  const { formItemProps = {}, selectProps, ...restRootProps } = rest;
  const { style, ...restFormItemProps } = formItemProps;

  return (
    <QuestionnaireSelect
      formItemProps={{
        style: { width: 226, minHeight: 40, ...style },
        ...restFormItemProps,
      }}
      {...restRootProps}
      selectProps={{
        value: { value },
        onChange: selected => onChange(selected?.value),
        options: iconOptions,
        label,
        ...selectProps,
      }}
    />
  );
}
