import classNames from 'classnames';
import styles from './styles.module.scss';
import { AppText } from '../../../../../../ui/text/AppText';

const MIN_ITEM_WIDTH = 50;
const FIRST_INDEX = 0;
const SECOND_INDEX = 1;

export function CustomSwitch({ items, status, itemWidth, onClick, disabled }) {
  const handleClick = () => (disabled ? null : onClick());

  return (
    <div className={classNames(styles.switch, disabled && styles.switch_disabled)}>
      <div className={styles.items}>
        {items.map((item, index) => (
          <div key={item()} className={styles.item} role='presentation' style={{ width: itemWidth || MIN_ITEM_WIDTH }} onClick={handleClick}>
            <AppText
              className={classNames(
                styles.item_text,
                status && index === FIRST_INDEX && styles.item_text_active,
                !status && index === SECOND_INDEX && styles.item_text_active
              )}
              textValue={item}
            />
          </div>
        ))}
        <div className={classNames(styles.slicer, status && styles.slicer_active)} />
      </div>
    </div>
  );
}
