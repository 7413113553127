import { Space } from 'antd';
import { SelectProductFormItem } from '../../../../../../../../features/form-items/SelectProductFormItem';
import { IconButton } from '../../../../../../../../features/IconButton';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import './style.scss';

/**
 * @desc Product Savings Row component.
 * Renders a row for managing product savings.
 * @param {Object} savingObj - The saving object containing information about the product savings.
 * @param {Array} savingsArr - An array containing all the savings options.
 * @param {Function} onChange - Function to handle changes in the product selection.
 * @param {Function} deleteRow - Function to delete the savings row.
 * @param {Boolean} disabled - Indicates whether the row is disabled or not.
 * @returns {JSX.Element} - JSX element representing the Product Savings Row component.
 */
export function ProductSavingsRow({ savingObj, savingsArr, onChange, deleteRow, disabled }) {
  return (
    <Space size='middle' className='product_savings_row_box'>
      <SelectProductFormItem
        value={savingObj.productId}
        rangeProductsArr={savingsArr.map(savObj => ({ ...savObj, prodId: savObj.productId }))}
        onChange={onChange}
        disabled={disabled}
      />
      <IconButton onClick={() => deleteRow(savingObj.id)} disabled={disabled} icon={<DeleteIcon className='secondary_color' />} />
    </Space>
  );
}
