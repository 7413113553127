export function SortDefaultIcon({ active }) {
  const fill = active ? '#DA1B5E' : '#5C5C6E';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <rect y='7' width='16' height='2' rx='1' fill={fill} />
      <rect y='2' width='12' height='2' rx='1' fill={fill} />
      <rect y='12' width='8' height='2' rx='1' fill={fill} />
    </svg>
  );
}
