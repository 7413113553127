import { prepareContactForm } from '../../ClientForm';
import { publicAxiosInstance } from '../../../../../../../../../../lib/util/http/axios/axios-instance';
import { appLogger } from '../../../../../../../../../../lib/util/logger';

export async function onSubmitForm({ newData, setPreviewState, setPagesFlow, printedResultProps, previewState, pdfBlob }) {
  try {
    const selectedProductNameList = printedResultProps?.productList?.map(item => item?.name)?.join(',') || '';

    const formData = new FormData();
    if (pdfBlob) {
      formData.append('pdfBlob', pdfBlob);
    }
    formData.append('products', selectedProductNameList);
    formData.append('finderUrl', encodeURIComponent(previewState.finderUrl));

    const formValues = prepareContactForm(newData);

    for (const key of Object.keys(formValues)) {
      formData.append(key, formValues[key]);
    }

    await publicAxiosInstance.post('global/public/save-contact', formData);

    setPagesFlow(prev => {
      if (prev?.afterContactFormLink) {
        window.open(prev?.afterContactFormLink?.link, prev?.afterContactFormLink?.openInNewWindow ? '_blank' : '_self');
      }
      return { ...prev, showSuccessScreen: true, afterContactFormLink: null };
    });
  } catch (error) {
    appLogger.error(error);
    // eslint-disable-next-line i18n-text/no-en,no-alert
    alert(error || 'Something went wrong. Please contact us.');
  } finally {
    setPreviewState(prev => ({ ...prev, isFormSubmitting: false }));
  }
}
