import { QuestionnaireSelect } from 'src/processes/CMS/processes/management/pages/QuestionnairePage/features/QuestionnaireSelect';
import { BulletPointIconFormItem } from 'src/processes/CMS/processes/management/features/form-items/BulletPointIconFormItem';
import { FontColorFormItem } from 'src/processes/CMS/processes/management/features/form-items/FontColorFormItem';

import { useLocalization } from 'src/lib/hooks/useLocalization';

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '16px',
};

const answerStyle = {
  height: '40px',
  minHeight: '40px',
  flex: 1,
};

const OtherSelectStyle = {
  width: 'auto',
  flex: 1,
};

export function OptionRow({ index, comparisonOption, answerOptions, colors, icons, additionalColors, comparisonOptionsChange }) {
  const { answer } = useLocalization().translations.unique.pages.questionnaire.content;

  const handleSelectChange = (field, value) => {
    comparisonOptionsChange(index, field, value);
  };

  return (
    <div style={rowStyle}>
      <QuestionnaireSelect
        formItemProps={{
          style: answerStyle,
        }}
        selectProps={{
          value: comparisonOption.value,
          options: answerOptions,
          disabled: true,
          label: answer,
        }}
      />
      {comparisonOption.icon && (
        <BulletPointIconFormItem
          icons={icons}
          value={comparisonOption.icon}
          onChange={value => handleSelectChange('icon', value)}
          formItemProps={{
            style: OtherSelectStyle,
          }}
        />
      )}
      <FontColorFormItem
        colors={colors}
        value={comparisonOption.color}
        additionalColors={additionalColors}
        onChange={value => handleSelectChange('color', value)}
        rootProps={{
          style: OtherSelectStyle,
        }}
      />
    </div>
  );
}
