import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import { useDispatch } from 'react-redux';
import { AppModal } from '../../../../../../../ui/AppModal';
import styled from 'styled-components';
import { AppText } from '../../../../../../../ui/text/AppText';
import { closeFinderModal, submitCanChangePublished } from '../../../../../../../lib/store/slices/findersSlice';

export function AreYouSureChangeQuestionnaireModal({ isModalLoading, close, isOpen }) {
  const { title, description } = useLocalization().translations.unique.pages.questionnaire.areYouSureModal;
  const dispatch = useDispatch();
  const { generic } = useLocalization().translations.shared;

  const submit = () => {
    dispatch(submitCanChangePublished(true));
    dispatch(closeFinderModal());
  };

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, loading: isModalLoading, textValue: generic.change }}
    >
      {isOpen && (
        <div style={{ marginBottom: '28px' }}>
          {Array.from(description).map((item, index) => {
            const boldText = item?.textValue;

            // eslint-disable-next-line react/no-array-index-key
            return <Text key={index} textValue={boldText || item} style={boldText ? { fontWeight: 'bold' } : undefined} />;
          })}
        </div>
      )}
    </AppModal>
  );
}

const Text = styled(AppText)({
  color: '#5C5C6E',
  lineHeight: 1.5,
});
