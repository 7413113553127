import { useDispatch } from 'react-redux';
import { applyRuleArrangesRequest, removeRuleFiltersRequest } from '../../../../../../../../lib/store/slices/rulesSlice';
import { ArrangeFormItem } from '../../../../utils/model/Arrange';
import { getParsedSelectedItems, LIST_VALUE_PROP_NAME, normalizeFilters } from '../../../../utils/arranges-helpers';

/**
 * @desc Custom hook for managing rule arrangement requests.
 * @param {string} arrangedId - The ID of the arranged item.
 * @param {string} sortFieldName - The name of the field used for sorting.
 * @param {Function} clearFilters - A function to clear filters.
 * @param {Function} setFilterLabel - A function to set filter label.
 * @param {Function} getFilters - A function to get filters.
 * @param {Function} getFilterLabel - A function to get filter label.
 * @param {Object} form - The form object.
 * @param {boolean} [withSorting=true] - Flag indicating whether sorting is enabled.
 * @returns {Object} - An object containing functions to apply and remove rule arrangements.
 */
export function useRuleArrangeRequests({
  arrangedId,
  sortFieldName,
  clearFilters,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  withSorting = true,
}) {
  const dispatch = useDispatch();

  const getSortOrder = () => form.getFieldValue(ArrangeFormItem.SORT_ORDER);
  const getSortField = () => ({ sortOrder: getSortOrder(), withSorting: true, sortFieldName });

  const getSorting = () => {
    const baseFields = { withSorting: false };
    return withSorting ? Object.assign(baseFields, getSortField()) : baseFields;
  };

  const applyArranges = async () => {
    await dispatch(
      applyRuleArrangesRequest({
        sorting: getSorting(),
        filtering: normalizeFilters(getFilters()),
        activeArrangedId: arrangedId,
      })
    );

    setFilterLabel(getFilterLabel);
  };

  const removeFilter = async () => {
    clearFilters();
    await dispatch(removeRuleFiltersRequest());
    setFilterLabel(null);
  };

  const removeFilterValue = async value => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(item => item[LIST_VALUE_PROP_NAME] !== value);
    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    await dispatch(
      applyRuleArrangesRequest({
        sorting: getSorting(),
        filtering: normalizeFilters(getFilters()),
        activeArrangedId: arrangedId,
      })
    );

    const filterLabel = getFilterLabel();
    setFilterLabel(filterLabel);

    if (!filterLabel) {
      clearFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
}
