import { useState } from 'react';
import { ActionsComponent } from './ActionsComponent/ActionsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { hasValueField, modifiedTemplates } from '../../../../../../utils/questionnaire-helpers';
import { getDefaultFlowObject } from '../../../../../../utils/dnd-helpers';
import { updateQuestionnaireStructure } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmModal } from './ConfirmModal/ConfirmModal';
import styled from 'styled-components';

function findClosestChapter(questionnaireStructureClone, itemIndex) {
  let closestChapter = null;
  let minDistance = Number.POSITIVE_INFINITY;

  for (let i = 0; i <= questionnaireStructureClone.length; i += 1) {
    const elem = questionnaireStructureClone[i];
    if (elem?.templateType === TemplateTypes.CHAPTER && i !== itemIndex) {
      const distance = Math.abs(i - itemIndex);
      if (distance < minDistance) {
        minDistance = distance;
        closestChapter = elem;
      }
    }
  }

  return closestChapter;
}

const NUMBER_DELETED_ELEMENTS = 0;

function deepReplaceId(obj) {
  if (typeof obj === 'object') {
    if (obj?.id !== undefined) {
      // eslint-disable-next-line no-param-reassign
      obj.id = uuidv4();
    }

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        deepReplaceId(obj[key]);
      }
    }
  }

  return obj;
}

export function ActionsWrapper({ item, itemIndex }) {
  const dispatch = useDispatch();

  const { questionnaireStructure, templates } = useSelector(({ finders }) => finders);

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const handleAddTemplate = () => {
    const newElement = modifiedTemplates(templates).find(el => {
      return el.templateId === item.templateId;
    });
    const flowObject = getDefaultFlowObject(newElement);
    const questionnaireStructureClone = [...questionnaireStructure];

    const closestChapter = findClosestChapter(questionnaireStructureClone, itemIndex);

    questionnaireStructureClone.splice(itemIndex + 1, NUMBER_DELETED_ELEMENTS, {
      ...newElement,
      isOpen: closestChapter ? closestChapter?.isOpen : true,
      flow: flowObject,
    });

    dispatch(updateQuestionnaireStructure({ structure: questionnaireStructureClone }));
  };

  const handleCopyTemplate = () => {
    const copyDataWithReplacedIds = deepReplaceId(JSON.parse(JSON.stringify(item)));

    const questionnaireStructureClone = [...questionnaireStructure];
    questionnaireStructureClone.splice(itemIndex + 1, NUMBER_DELETED_ELEMENTS, copyDataWithReplacedIds);
    dispatch(updateQuestionnaireStructure({ structure: questionnaireStructureClone }));
  };

  const handleDeleteCard = () => {
    const result = questionnaireStructure.filter(el => el.id !== item.id);
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleOpenConfirmModal = () => {
    if (hasValueField(item.content)) {
      setIsOpenConfirmModal(true);
    } else {
      handleDeleteCard();
    }
  };

  const handleCloseConfirmModal = () => setIsOpenConfirmModal(false);
  return (
    <Root>
      <ActionsComponent
        onAddTemplate={handleAddTemplate}
        onCopyTemplate={handleCopyTemplate}
        onDeleteCard={handleOpenConfirmModal}
        templateType={item.templateType}
      />
      <ConfirmModal isOpen={isOpenConfirmModal} close={handleCloseConfirmModal} submit={handleDeleteCard} />
    </Root>
  );
}

const Root = styled('div')({
  top: 0,
  right: 0,
  position: 'absolute',
});
