import { Header } from './Header';
import { DetailTable } from './DetailTable';

export function ComparisonDetailTable({ applyVariable, colors, fonts, data, icons }) {
  const { productRow, comparisonOptions, detailTables, productOptions } = data;
  return (
    <div style={{ position: 'relative', marginTop: '90px', zIndex: 1, minWidth: '900px' }}>
      <Header productOptions={productOptions} color={fonts.headlineFourth.colorVariant} productRow={productRow} />
      {detailTables &&
        detailTables.map((detailTable, index) => (
          <DetailTable
            applyVariable={applyVariable}
            key={detailTable.id}
            detailTable={detailTable}
            fonts={fonts}
            colors={colors}
            icons={icons}
            comparisonOptions={comparisonOptions}
            isFirst={index === 0}
          />
        ))}
    </div>
  );
}
