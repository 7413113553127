import { useSelector } from 'react-redux';
import { AddProductButton } from './AddProductButton';
import { generateGenericProductItemStructure } from './utils';
import { ProductList } from './ProductList';

export function DepotProducts({ products, structureItemIndex }) {
  const { tenantProducts } = useSelector(({ finders }) => finders);

  return (
    <>
      <ProductList products={products} structureItemIndex={structureItemIndex} />
      <AddProductButton
        structureItemIndex={structureItemIndex}
        allProducts={tenantProducts}
        generateProductItem={generateGenericProductItemStructure}
      />
    </>
  );
}
