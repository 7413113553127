import { Finder } from '../../lib/Finder';
import { useFinderDateColumn } from '../../components/useFinderDateColumn';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function usePublishedDateColumn() {
  const { published: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderDateColumn({
    arrangedId: Finder.PUBLISHED_DATE,
    title,
    filterFromName: 'publishingDateFrom',
    filterToName: 'publishingDateTo',
  });
}
