import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { DestinationVariants, LabelTexts, TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType, isLength } from '../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { getMultiselectFlow, getFlowPages, getPagesWithChapters } from '../../pages/PreviewPage/hooks/getMultiselectFlow';

const MAX_FULL_LABEL_LIMIT = 2;

export function SelectMultiGoToFormItem({
  flow,
  onChange,
  options = [],
  elementId,
  disabled,
  goToFieldName = 'goTo',
  showElementsFieldName = 'showElements',
}) {
  const { next } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { page } = useLocalization().translations.unique.pages.questionnaire.leftSideBar.composer;
  const pages = getFlowPages(options);
  const elementOptions = pages?.map((pageItem, index) => {
    const pageOptions = pageItem?.elements?.map(element => {
      const elementIdByType = getIndexByTemplateType(options, element);
      return {
        value: element.id,
        title:
          flow?.[showElementsFieldName]?.length > MAX_FULL_LABEL_LIMIT
            ? `${LabelTexts[element.templateType]}.${elementIdByType + 1}`
            : `${LabelTexts[element.templateType]}.${elementIdByType + 1} ${element.label}`,
      };
    });

    const pagesStructure = getPagesWithChapters(options);
    const pageStructureIndex = pagesStructure.findIndex(pageObj => pageObj.elements[0]?.id === pageItem.elements[0]?.id);
    const pageIndexText = pageItem.elements.map(el => el.templateType).includes(TemplateTypes.CHAPTER) ? index : pageStructureIndex;

    return {
      label: `${page()}.${pageIndexText + 1}`,
      options: pageOptions
        .filter(option => option.value !== elementId)
        .filter(option => {
          return isLength(flow?.[showElementsFieldName]) ? pageItem.elements.some(el => flow?.[showElementsFieldName]?.includes(el.id)) : option;
        })
        .filter(option => {
          return flow?.[goToFieldName] && flow?.[goToFieldName] !== DestinationVariants.NEXT
            ? pageItem.elements.some(el => flow?.[goToFieldName]?.includes(el.id))
            : option;
        }),
      pageType: pageItem.templateType,
    };
  });

  const additionalOptions = [
    { label: 'Next page', options: [{ value: DestinationVariants.NEXT, title: next() }], pageType: DestinationVariants.NEXT },
  ];

  const updatedOptions = [...additionalOptions, ...elementOptions];

  const handleChangeFlow = values => {
    const updatedFlowFields = getMultiselectFlow(values, goToFieldName, showElementsFieldName);
    onChange(updatedFlowFields);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getValues = (selectedData, allOptions) => {
    const onlyOptions = allOptions.flatMap(opt => opt.options);
    return selectedData?.map(dataItem => onlyOptions.find(option => option.value === dataItem.value));
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <AppNewSelect
        options={updatedOptions}
        value={
          isLength(flow?.[showElementsFieldName]) ? flow?.[showElementsFieldName]?.map(el => ({ value: el })) : [{ value: flow?.[goToFieldName] }]
        }
        onChange={handleChangeFlow}
        isMultiSelect
        selectAll={false}
        withoutLimit
        getCustomValue={getValues}
        disabled={disabled}
      />
    </div>
  );
}
