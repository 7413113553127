/* eslint-disable max-lines,max-lines-per-function */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSearchParams } from 'react-router-dom';
import {
  copyComposerButton,
  copyInfoIcon,
  copyInfoIconProductTotalPrice,
  copyInfoIconQuestionAnswer,
  copyInfoIconResultPriceNameField,
  copyPage,
  copyTemplate,
  dndTableColumns,
  dndTableRow,
  reorder,
  reorderBulletPoints,
  reorderTopics,
  reorderFn,
  reorderQuestionAnswers,
  copyInfoIconQuestionChartAnswers,
} from '../../utils/dnd-helpers';
import {
  getFinderTemplatesRequest,
  getTemplateLayoutsRequest,
  getTenantProductsRequest,
  getFinderRulesRequest,
  redoHistory,
  setFutureQuestionnaireStructure,
  setPastQuestionnaireStructure,
  undoHistory,
  updateQuestionnaireStructure,
} from '../../../../../../lib/store/slices/findersSlice';
import lodashSet from 'lodash.set';
import { handleTranslation, safeJsonParse, secToMs } from '../../../../../../lib/util/helpers';
import { modifiedTemplates } from '../../utils/questionnaire-helpers';
import { useInterval } from '../../../../../../lib/hooks/useInterrval';
import { useUpdateStructure } from './hooks/useUpdateStructure';
import { useGetFinder } from '../../../../../../lib/hooks/useGetFinder';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { DroppableIdField, DroppableIdKind, DroppableIdType, ResultType } from '../../../../../../lib/models/Questionnaire';
import { ComparisonTableDroppable } from './components/QuestionnaireContent/ContentTableSection/comparison-table-utils';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../lib/consts';
import { QuestionnaireRightSideBar } from './components/QuestionnaireRightSideBar';
import { QuestionnaireContent } from './components/QuestionnaireContent/QuestionnaireContent';
import { QuestionnaireLeftSideBar } from './components/QuestionnaireLeftSideBar/QuestionnaireLeftSideBar';
import { AppLayout } from '../../../../../../ui/AppLayout';
import lodashGet from 'lodash.get';
import { QuestionnaireModals } from './components/QuestionnaireModals';
import { updateInfoIconsIndexes } from './utils/helpers';
import { appLogger } from '../../../../../../lib/util/logger';

// REFACTOR: make file smaller

const ResultDroppableId = {
  COMPOSER: 'COMPOSER',
  COMPOSER_CHILDREN: 'COMPOSER_CHILDREN',
};

const FieldName = {
  QUESTION_ANSWER: 'questionAnswer',
  QUESTION_CHART_ANSWERS: 'chartAnswers',
  PRODUCT_TOTAL_PRICE: 'productTotalPrice',
  RESULT_PRICE_NAME_FIELD: 'resultPriceNameField',
};

/**
 * @desc Questionnaire page: the user will be able to set composer elements (questions, product cards, content) that will be viewed and selected by end-users by exact flow.
 * @returns {JSX.Element}
 */
export function QuestionnairePage() {
  const dispatch = useDispatch();
  const { composer } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));

  const {
    questionnaireStructure,
    templates,
    isTemplatesLoading,
    templateLayouts,
    isTemplateLayoutsLoading,
    isViewMode,
    pastQuestionnaireStructure,
    futureQuestionnaireStructure,
    finder,
    pastQSSS,
    futureQSSS,
  } = useSelector(({ finders }) => finders);
  const { getFinder, finderId } = useGetFinder();
  const { updateStructure } = useUpdateStructure();

  useEffect(() => {
    dispatch(getFinderTemplatesRequest({ queryParams: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE }, requestBody: { activated: true } }));
    dispatch(getTenantProductsRequest({ queryParams: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE }, tenantId }));
    dispatch(getTemplateLayoutsRequest({ queryParams: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE }, requestBody: { activated: true } }));
    dispatch(getFinderRulesRequest({ tenantId, config: { params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE }, data: { finderId } } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval({ callback: getFinder, intervalInMs: secToMs({ sec: 25 }), isEnabled: isViewMode });

  const composerItems = [
    {
      key: 'page',
      label: handleTranslation(composer.page),
      id: uuidv4(),
      isOpen: false,
    },
    {
      key: 'infoIcon',
      label: handleTranslation(composer.infoIcon),
      id: uuidv4(),
      isOpen: false,
    },
    {
      key: 'composerButton',
      label: handleTranslation(composer.button),
      id: uuidv4(),
      isOpen: false,
    },
    {
      key: 'intro',
      label: handleTranslation(composer.intro.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.INTRO),
    },
    {
      key: 'chapter',
      label: handleTranslation(composer.chapter.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)
        ?.filter(el => el.templateType === TemplateTypes.CHAPTER)
        .reverse(),
    },
    {
      key: 'content',
      label: handleTranslation(composer.content.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.CONTENT),
    },
    {
      key: 'question',
      label: handleTranslation(composer.question.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.QUESTION),
    },
    {
      key: 'product',
      label: handleTranslation(composer.product.label),
      id: uuidv4(),
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.PRODUCT),
    },
    {
      key: 'result',
      label: handleTranslation(composer.result.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.RESULT),
    },
    {
      key: 'contactForm',
      label: handleTranslation(composer.contactForm.label),
      id: uuidv4(),
      isOpen: false,
      children: modifiedTemplates(templates, templateLayouts)?.filter(el => el.templateType === TemplateTypes.CONTACT_FORM),
    },
  ];

  const handleUndo = useCallback(() => {
    if (pastQuestionnaireStructure?.length > 0) {
      dispatch(undoHistory());
    }
  }, [dispatch, pastQuestionnaireStructure?.length]);

  const handleRedo = useCallback(() => {
    if (futureQuestionnaireStructure.length > 0) {
      dispatch(redoHistory());
    }
  }, [dispatch, futureQuestionnaireStructure?.length]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'z') {
          event.preventDefault();
          handleUndo();
        }
        if (event.key === 'y') {
          event.preventDefault();
          handleRedo();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleRedo, handleUndo]);

  // REFACTOR: split to small funcs
  /**
   * @desc As soon as the user releases the element, the onDragEnd callback function fires.
   * This is where you update the application state to reflect the new order of the Draggable elements.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    appLogger.info('onDragEnd:', { result });

    if (source?.index === destination?.index && source?.droppableId === destination?.droppableId) {
      return;
    }

    if (!destination || (destination.index === 0 && result.type === ResultType.COMPOSER && draggableId !== 'COMPOSER_CHILDREN')) return;

    const introIndex = questionnaireStructure.findIndex(item => item.templateType === TemplateTypes.INTRO);
    const isIntro = questionnaireStructure.some(item => item.templateType === TemplateTypes.INTRO);

    if (isIntro && (introIndex === destination.index || introIndex === destination.index - 1)) {
      return;
    }

    // Drag-n-drop page from composer to content
    if (source.droppableId === ResultDroppableId.COMPOSER) {
      dispatch(updateQuestionnaireStructure({ structure: copyPage(questionnaireStructure, source, destination) }));
    }

    // Reorder items (content block)
    if (result.type === ResultType.COMPOSER && source.droppableId === destination.droppableId) {
      dispatch(updateQuestionnaireStructure({ structure: reorder(questionnaireStructure, source.index, destination.index) }));
    }

    // Copy (drag-n-drop) children elements from composer to content
    if (source.droppableId === ResultDroppableId.COMPOSER_CHILDREN) {
      dispatch(updateQuestionnaireStructure({ structure: copyTemplate(questionnaireStructure, source, destination, composerItems) }));
    }

    // Copy (drag-n-drop) button from composer to templates
    if (result.type === ResultType.COMPOSER_BUTTON) {
      dispatch(updateQuestionnaireStructure({ structure: copyComposerButton(destination, questionnaireStructure) }));
    }

    // Reorder items (navigator)
    if (result.type === ResultType.NAVIGATOR && source.droppableId === destination.droppableId) {
      dispatch(updateQuestionnaireStructure({ structure: reorder(questionnaireStructure, source.index, destination.index) }));
    }

    if (result.type === ComparisonTableDroppable.COLUMN) {
      dndTableColumns(updateStructure, result);
    } else if (result.type === ComparisonTableDroppable.ROW) {
      dndTableRow(updateStructure, result);
    }

    // / Reorder bullet points
    if (result.type === ResultType.BULLET_POINT) {
      const parsedDroppableId = safeJsonParse(destination.droppableId);

      if (parsedDroppableId?.[DroppableIdField.KIND] === DroppableIdKind.WITH_PATH) {
        updateStructure(draft => {
          const list = lodashGet(draft, parsedDroppableId[DroppableIdField.PATH]);
          lodashSet(draft, parsedDroppableId[DroppableIdField.PATH], reorderFn(list, source, destination));
        });
      } else {
        dispatch(updateQuestionnaireStructure({ structure: reorderBulletPoints(questionnaireStructure, source, destination) }));
      }
    }

    // / Reorder answers
    if (result.type === ResultType.QUESTION_ANSWERS) {
      const parsedDroppableId = safeJsonParse(destination.droppableId);
      dispatch(
        updateQuestionnaireStructure({
          structure: reorderQuestionAnswers(questionnaireStructure, source, destination, parsedDroppableId?.itemId, parsedDroppableId?.fieldName),
        })
      );
    }

    if (result.type === ResultType.CONTACT_FORM_ROW) {
      const parsedDraggableId = JSON.parse(draggableId);
      updateStructure(draft => {
        const { formRowList } = draft[parsedDraggableId.structureItemIndex].content;

        const [removed] = formRowList.splice(source.index, 1);
        formRowList.splice(destination.index, 0, removed);
      });

      return;
    }

    if (result.type === ResultType.CONTACT_FORM_ROW_FIELD) {
      const parsedDraggableId = JSON.parse(draggableId);
      updateStructure(draft => {
        const { fieldList } = draft[parsedDraggableId.structureItemIndex].content.formRowList[parsedDraggableId.rowIndex];

        const [removed] = fieldList.splice(source.index, 1);
        fieldList.splice(destination.index, 0, removed);
      });

      return;
    }

    if (result.type === ResultType.CONTACT_FORM_ROW_FIELD_OPTION) {
      const parsedDraggableId = JSON.parse(draggableId);
      updateStructure(draft => {
        const { optionList } =
          draft[parsedDraggableId.structureItemIndex].content.formRowList[parsedDraggableId.rowIndex].fieldList[parsedDraggableId.fieldIndex];

        const [removed] = optionList.splice(source.index, 1);
        optionList.splice(destination.index, 0, removed);
      });

      return;
    }

    if (result.type === ResultType.COMPARISON_DETAIL_TABLE_TOPIC) {
      const parsedDraggableId = safeJsonParse(destination.droppableId);

      dispatch(updateQuestionnaireStructure({ structure: reorderTopics(questionnaireStructure, parsedDraggableId, source, destination) }));
    }

    // Copy (drag-n-drop) info icon
    if (result.type === ResultType.INFO_ICON) {
      const parsedDroppableId = safeJsonParse(destination.droppableId);
      const isQuestionAnswer = destination?.droppableId.split('_')[0] === FieldName.QUESTION_ANSWER;
      const isProductTotalPrice = destination?.droppableId.split('_')[0] === FieldName.PRODUCT_TOTAL_PRICE;
      const isResultPriceNameField = destination?.droppableId.split('_')[0] === FieldName.RESULT_PRICE_NAME_FIELD;

      const isQuestionChartAnswers = parsedDroppableId?.fieldName === FieldName.QUESTION_CHART_ANSWERS;

      switch (true) {
        case Boolean(isQuestionAnswer):
          dispatch(updateQuestionnaireStructure({ structure: copyInfoIconQuestionAnswer(destination, questionnaireStructure) }));
          break;
        case Boolean(isQuestionChartAnswers):
          dispatch(updateQuestionnaireStructure({ structure: copyInfoIconQuestionChartAnswers(destination, questionnaireStructure) }));
          break;
        case Boolean(isProductTotalPrice):
          dispatch(updateQuestionnaireStructure({ structure: copyInfoIconProductTotalPrice(destination, questionnaireStructure) }));
          break;
        case Boolean(isResultPriceNameField):
          dispatch(updateQuestionnaireStructure({ structure: copyInfoIconResultPriceNameField(destination, questionnaireStructure) }));
          break;
        case Boolean(parsedDroppableId?.[DroppableIdField.KIND] === DroppableIdKind.WITH_PATH):
          updateStructure(draft => {
            if (parsedDroppableId?.[DroppableIdField.TYPE] === DroppableIdType.COMPARISON_TABLE_CELL) {
              const path = `${parsedDroppableId?.tablePath}.tableRows`;
              const tableRows = lodashGet(draft, path);
              lodashSet(draft, parsedDroppableId[DroppableIdField.PATH], { value: '', infoIconIndex: 0 });
              lodashSet(draft, path, updateInfoIconsIndexes(tableRows));
            } else {
              lodashSet(draft, parsedDroppableId[DroppableIdField.PATH], { value: '' });
            }
          });
          break;
        default:
          dispatch(updateQuestionnaireStructure({ structure: copyInfoIcon(destination, questionnaireStructure) }));
          break;
      }
    }
  };

  useEffect(() => {
    if (pastQSSS) {
      dispatch(setPastQuestionnaireStructure(pastQSSS[finder.id]));
    }
    if (futureQSSS) {
      dispatch(setFutureQuestionnaireStructure(futureQSSS[finder.id]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finder.id]);

  return (
    <>
      <QuestionnaireModals />

      <AppLayout hasSider style={{ overflowY: 'auto', height: '0vh' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <QuestionnaireLeftSideBar composerItems={composerItems} isTemplatesLoading={isTemplatesLoading || isTemplateLayoutsLoading} />
          <QuestionnaireContent questionnaireStructure={questionnaireStructure} isTemplatesLoading={isTemplatesLoading} />
        </DragDropContext>
        <QuestionnaireRightSideBar />
      </AppLayout>
    </>
  );
}
