export function formatEditedProductWithCounters(productTemplate, customIcons, defaultIcons) {
  const bulletIcon = [...customIcons, ...defaultIcons].find(item => item.id === productTemplate?.bulletPointIcon?.value);

  return {
    productSelection: productTemplate?.productSelection,
    showSavings: productTemplate?.showSavings,
    headline: productTemplate?.headline?.value,
    headlineInfoIcon: productTemplate?.headline?.infoIcon,
    bulletPointIconSvg: productTemplate?.bulletPointIcon?.svgContent,
    bulletPointIconUrl: bulletIcon ? bulletIcon?.s3Url : defaultIcons?.[0]?.s3Url,
    bulletPointIconColor: productTemplate?.bulletPointIconColor,
    list: productTemplate?.products?.map(item => ({
      id: item.id,
      data: {
        headline: item.productNameSection.product.title,
        subline: item.productNameSection.subline.value,
        sublineInfoIcon: item.productNameSection.subline.infoIcon,
        bulletPointsSection: {
          rightColumnType: item.bulletPointsSection.rightColumnType,
          bulletPoints: item.bulletPointsSection.bulletPoints.map(bulletPoint => ({
            ...bulletPoint,
            left: bulletPoint.left.value,
            infoIconLeft: {
              infoIcon: bulletPoint.left.infoIcon,
            },
            rightText: bulletPoint.rightText.value,
            infoIconRight: {
              infoIcon: bulletPoint.rightText.infoIcon,
            },
          })),
        },
        price: {
          amount: item.priceSection.priceValue,
          currency: item.priceSection.currency,
          show: item?.priceSection?.showTotalPrice,
          infoIconPrice: item.priceSection.infoIcon,
        },
        image: item.image,
        showPicture: item.productNameSection.showPicture,
        counter: {
          headline: item.counterSection.headline.value,
          showCounter: item.counterSection.showCounter,
          value: 0,
        },
        customSign: item.productNameSection.customSign,
      },
    })),
    composerButton: productTemplate?.composerButton,
  };
}
