import tableStyles from '../../../../../../../../utils/styles/table.module.sass';
import { Finder } from '../../lib/Finder';
import { useFinderDateColumn } from '../../components/useFinderDateColumn';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function useCreationDateColumn() {
  const { creationDate: title } = useLocalization().translations.shared.columns;

  return useFinderDateColumn({
    arrangedId: Finder.CREATION_DATE,
    title,
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
    headerCellProps: {
      rootProps: { className: tableStyles.hiddenHeaderCell },
    },
  });
}
