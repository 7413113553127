import styled from 'styled-components';

export function CardSection(props) {
  return <Root {...props} />;
}

const Root = styled('div')({
  padding: ({ withPaddingBottom = false }) => (withPaddingBottom ? '20px 25px 20px' : '20px 25px 0'),
  borderBottom: ({ withBorderBottom = true }) => (withBorderBottom ? '1px solid #E3E3E6' : 'none'),
  '&:last-child': {
    borderBottom: 'none',
  },
});
