/* eslint-disable no-param-reassign */
import { ColorSelect } from './ColorSelect';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { DefaultRowColor } from '../comparison-table-utils';
import classes from '../ComparisonTable.module.sass';
import { useContentTable } from '../hooks/useContentTable';

export function RowColorSelect({ rowColorList, rowIndex }) {
  const { contentTable } = useLocalization().translations.unique.pages.questionnaire;
  const { contentTableState } = useContentTable();
  const { bgColor } = contentTableState.tableRows[rowIndex];
  const value = { label: bgColor?.label || null, value: bgColor?.value || DefaultRowColor.NONE };

  const onChange = newSelected => {
    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].bgColor = newSelected;
    });
  };

  return (
    <ColorSelect
      options={rowColorList}
      value={value}
      label={contentTable.rowColor}
      onChange={onChange}
      classNames={{
        control: ({ menuIsOpen }) => menuIsOpen && classes.rowColorActive,
      }}
    />
  );
}
