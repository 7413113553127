import { Form } from 'antd';
import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { AddCategoryForm } from './AddCategoryForm';
import { useResetFormOnCloseModal } from '../../../../../../../../../lib/hooks/useResetFormOnCloseModal';

export function AddCategoryModal({ close, isOpen, isModalLoading, tenantId }) {
  const { title } = useLocalization().translations.unique.pages.category.addModal;
  const [form] = Form.useForm();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: form.submit, loading: isModalLoading }}
    >
      {isOpen && <AddCategoryForm form={form} tenantId={tenantId} />}
    </AppModal>
  );
}
