import { QuestionnaireFormSection } from '../QuestionnaireFormSection';
import styled from 'styled-components';
import { MAX_COUNTER_VALUE } from '../../../../../../../../lib/models/Questionnaire';

const MIN = 0;

export function RangeField({ textFieldProps, ...rest }) {
  const { onChange, ...restTextFieldProps } = textFieldProps;

  const formattedOnChange = val => {
    let value = val;

    if (value > MAX_COUNTER_VALUE) {
      value = MAX_COUNTER_VALUE;
    }

    if (value < MIN || !value) {
      value = MIN;
    }

    onChange(value);
  };

  return (
    <Root
      formItemProps={{ style: styles.field, hasFeedback: false }}
      textFieldProps={{
        isNumber: true,
        style: styles.inputWrapper,
        onChange: formattedOnChange,
        ...restTextFieldProps,
      }}
      {...rest}
    />
  );
}

const Root = styled(QuestionnaireFormSection)({
  '.ant-input-affix-wrapper input': {
    padding: '0 16px',
    textAlign: 'left',
  },
});

const styles = {
  field: {
    width: '110px',
  },
  inputWrapper: {
    textAlign: 'left',
  },
};
