export function LeftAlignIcon() {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 7C0 6.86739 0.0526785 6.74021 0.146447 6.64645C0.240215 6.55268 0.367392 6.5 0.5 6.5H3.5C3.63261 6.5 3.75979 6.55268 3.85355 6.64645C3.94732 6.74021 4 6.86739 4 7C4 7.13261 3.94732 7.25979 3.85355 7.35355C3.75979 7.44732 3.63261 7.5 3.5 7.5H0.5C0.367392 7.5 0.240215 7.44732 0.146447 7.35355C0.0526785 7.25979 0 7.13261 0 7ZM0 4C0 3.86739 0.0526785 3.74021 0.146447 3.64645C0.240215 3.55268 0.367392 3.5 0.5 3.5H7.5C7.63261 3.5 7.75979 3.55268 7.85355 3.64645C7.94732 3.74021 8 3.86739 8 4C8 4.13261 7.94732 4.25979 7.85355 4.35355C7.75979 4.44732 7.63261 4.5 7.5 4.5H0.5C0.367392 4.5 0.240215 4.44732 0.146447 4.35355C0.0526785 4.25979 0 4.13261 0 4ZM0 1C0 0.867392 0.0526785 0.740215 0.146447 0.646447C0.240215 0.552679 0.367392 0.5 0.5 0.5H11.5C11.6326 0.5 11.7598 0.552679 11.8536 0.646447C11.9473 0.740215 12 0.867392 12 1C12 1.13261 11.9473 1.25979 11.8536 1.35355C11.7598 1.44732 11.6326 1.5 11.5 1.5H0.5C0.367392 1.5 0.240215 1.44732 0.146447 1.35355C0.0526785 1.25979 0 1.13261 0 1Z'
        fill='white'
      />
    </svg>
  );
}
