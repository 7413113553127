import { Switch } from 'antd';
import { AppInput } from '../../../../../../../../../../../ui/AppInput';
import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { DestinationVariants } from '../../../../../../../../../../../lib/models/TemplateTypes';
import { FlowOperation } from '../../../../../../../../../../../lib/models/Questionnaire';
import { LinkIcon } from '../../../../../../../../../../../ui/icons/LinkIcon';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { QuestionnaireSelect } from '../../../../../features/QuestionnaireSelect';
import '../../../style.scss';

/**
 * @desc Renders the flow for a button within a result depot product.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.buttonObject - The button object containing flow information.
 * @param {string} props.structureItemId - The ID of the structure item containing the button.
 * @param {boolean} props.disabled - Indicates whether the component is disabled.
 * @param {number} props.productIndex - The index of the product containing the button.
 * @param {number} props.buttonIndex - The index of the button within the product.
 * @returns {JSX.Element} The JSX element displaying the flow for the result depot button.
 */
export function ResultDepotButtonFlow({ buttonObject, structureItemId, disabled, productIndex, buttonIndex }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { goTo, link, openNewTab } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { alternativeLabel } = useLocalization().translations.unique.formItems.linkUrl;

  const baseOptions = [
    { value: FlowOperation.LINK, title: link() },
    { value: FlowOperation.GO_TO_ELEMENT, title: goTo() },
  ];
  const { flow = {}, buttonText } = buttonObject;

  const { updateStructure } = useUpdateStructure();

  const handleUpdateStructure = updatedValue => {
    updateStructure(draft => {
      const structureItem = draft?.find(iterated => iterated.id === structureItemId);
      if (structureItem) {
        structureItem.content.products[productIndex].buttonsSection.buttons[buttonIndex] = updatedValue;
      }
    });
  };

  const handleChangeButtonFlow = values => {
    const updatedValue = { ...buttonObject, flow: { ...flow, ...values } };
    handleUpdateStructure(updatedValue);
  };

  return (
    <div className='flow_process_button_box'>
      <div className='flow_process_button_box'>
        <AppInput value={buttonText.value} disabled />
      </div>
      <div className='flow_process_button_box flow_process_button_box_result_depot'>
        <AppText textValue={goTo} className='flow_process_box_select_title' />
        <QuestionnaireSelect
          style={{ marginBottom: '12px' }}
          formItemProps={{ style: { minHeight: 'unset' } }}
          selectProps={{
            options: baseOptions,
            onChange: selected => handleChangeButtonFlow({ operation: selected?.value }),
            value: flow?.operation,
          }}
        />
        {flow?.operation === FlowOperation.GO_TO_ELEMENT && (
          <SelectMultiGoToFormItem
            elementId={structureItemId}
            flow={flow}
            options={questionnaireStructure}
            onChange={handleChangeButtonFlow}
            disabled={disabled}
          />
        )}
        {(flow.goTo === DestinationVariants.LINK || flow?.operation === FlowOperation.LINK) && (
          <>
            <div className='flow_process_button_box_link'>
              <AppInput
                label={alternativeLabel}
                prefix={<LinkIcon />}
                value={flow.href}
                disabled={disabled}
                onChange={event => handleChangeButtonFlow({ href: event.target.value })}
              />
            </div>
            <div className='align_items_center'>
              <Switch checked={flow.targetBlank} onChange={checked => handleChangeButtonFlow({ targetBlank: checked })} size='small' />
              <AppText className='flow_process_button_box_text' textValue={openNewTab} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
