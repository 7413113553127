/* eslint-disable sonarjs/cognitive-complexity */
import { changeNumberValue } from './change_number_value';
import { updateAnswersProperties } from './answer_score_rules_helper';

export const getAnswerSavingsValue = (answerSavingsBlocks, allSimpleAnswers) => {
  return answerSavingsBlocks.reduce((accumulator, answerSavingsBlock) => {
    const { conditionValue, savingsAnswer, resultOperator, value } = answerSavingsBlock;
    const { elements, product } = savingsAnswer;
    const blockAnswers = elements.map(el => allSimpleAnswers.find(answer => answer.id === el.answerId));
    const blockAnswersIds = new Set(blockAnswers.map(answer => answer.id));

    const oldAnswers = accumulator.filter(answer => blockAnswersIds.has(answer.id));
    const filteredAnswers = accumulator.filter(answer => !blockAnswersIds.has(answer.id));

    if (conditionValue) {
      const updatedAnswers = blockAnswers.map(answer => {
        const originalSavingsProduct = answer.savings.find(prod => prod.productId === product.value);
        const originalSavings = originalSavingsProduct ? originalSavingsProduct.savingsValue : 0;
        const calculateRuleSavings = changeNumberValue(value, resultOperator, savingsAnswer, { value: originalSavings });
        const ruleSavingsValue = calculateRuleSavings >= 0 ? calculateRuleSavings : 0;

        const updatedSavings = originalSavingsProduct
          ? { ...originalSavingsProduct, savingsValue: ruleSavingsValue, originalSavings: originalSavingsProduct.savingsValue, ruleSavings: true }
          : { productId: product.value, savingsValue: ruleSavingsValue, originalSavings: 0, ruleSavings: true };

        const oldAnswer = oldAnswers.find(answ => answ.id === answer.id);
        const oldSavings = oldAnswer ? oldAnswer.savings : answer.savings;
        const savings = oldSavings.filter(prod => prod.id !== updatedSavings.id).concat([updatedSavings]);

        return { ...answer, savings };
      });

      const newAnswers = updateAnswersProperties(updatedAnswers, oldAnswers, 'savings');
      return [...filteredAnswers, ...newAnswers];
    }

    if (!conditionValue) {
      const updatedAnswers = blockAnswers.map(answer => {
        const oldAnswer = oldAnswers.find(answ => answ.id === answer.id) || answer;
        const oldSavings = oldAnswer.savings;
        const oldSavingItem = oldSavings.find(prod => prod.productId === product.value);
        const updatedSavingItem = oldSavingItem || { productId: product.value, savingsValue: 0, ruleSavings: false };
        const savings = oldSavings.filter(prod => prod.id !== updatedSavingItem.id).concat([updatedSavingItem]);

        return { ...answer, savings };
      });

      const newAnswers = updateAnswersProperties(updatedAnswers, oldAnswers, 'savings');
      return [...filteredAnswers, ...newAnswers];
    }

    return accumulator;
  }, []);
};
