export function ArrowClockwiseIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg className={className} {...rest} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00093 3C6.92029 3.00042 5.86886 3.35093 5.00414 3.99904C4.13942 4.64715 3.50794 5.55797 3.2043 6.59508C2.90065 7.63219 2.94119 8.73976 3.31982 9.7519C3.69846 10.764 4.39482 11.6263 5.3046 12.2094C6.21438 12.7926 7.28861 13.0653 8.36639 12.9868C9.44418 12.9082 10.4675 12.4826 11.2831 11.7736C12.0987 11.0647 12.6626 10.1105 12.8905 9.0542C13.1183 7.99784 12.9978 6.89611 12.5469 5.914C12.4979 5.7946 12.4969 5.66084 12.5442 5.54072C12.5915 5.42061 12.6834 5.32344 12.8007 5.26957C12.918 5.21569 13.0517 5.20927 13.1736 5.25167C13.2955 5.29406 13.3963 5.38197 13.4549 5.497C13.9959 6.67559 14.1405 7.99773 13.867 9.26538C13.5935 10.533 12.9167 11.678 11.9379 12.5286C10.9591 13.3793 9.73097 13.89 8.43758 13.9842C7.14418 14.0783 5.85509 13.7509 4.7634 13.0509C3.67171 12.3509 2.83619 11.3161 2.38199 10.1014C1.9278 8.88676 1.87937 7.55762 2.24397 6.31311C2.60857 5.0686 3.36656 3.97571 4.40442 3.19815C5.44227 2.42059 6.70411 2.00022 8.00093 2V3Z'
        fill='white'
      />
      <path
        d='M8 4.46316V0.531156C8.00002 0.483653 8.01357 0.437138 8.03907 0.397058C8.06457 0.356977 8.10096 0.324992 8.14398 0.304846C8.187 0.2847 8.23487 0.277227 8.28198 0.283304C8.32909 0.28938 8.3735 0.308754 8.41 0.339156L10.77 2.30516C10.89 2.40516 10.89 2.58916 10.77 2.68916L8.41 4.65516C8.3735 4.68556 8.32909 4.70493 8.28198 4.71101C8.23487 4.71709 8.187 4.70961 8.14398 4.68947C8.10096 4.66932 8.06457 4.63734 8.03907 4.59726C8.01357 4.55718 8.00002 4.51066 8 4.46316Z'
        fill='white'
      />
    </svg>
  );
}
