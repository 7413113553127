import { useSelector } from 'react-redux';
import { AddProductButton } from '../components/AddProductButton';
import { generateGenericProductItemStructure } from '../utils';
import { ProductTopSectionWithCounter } from './components/ProductTopSectionWithCounter';
import { ProductList } from '../components/ProductList';
import { ComposerButton } from '../../ComposerButton';

export function ProductOneCounterForm({ structureItem, structureItemIndex, onDeleteComposerButton, isDraggingOver, onChangeFieldValue }) {
  const { tenantProducts } = useSelector(({ finders }) => finders);

  return (
    <>
      <ProductTopSectionWithCounter structureItem={structureItem} structureItemIndex={structureItemIndex} />
      <ProductList structureItem={structureItem} structureItemIndex={structureItemIndex} allProducts={tenantProducts} isOneCounter />
      <AddProductButton
        structureItemIndex={structureItemIndex}
        allProducts={tenantProducts}
        generateProductItem={generateGenericProductItemStructure}
      />
      {(structureItem.content?.composerButton?.name || isDraggingOver) && (
        <div className='template_card_bottom_block'>
          <ComposerButton
            item={structureItem}
            isDraggingOver={isDraggingOver}
            onChangeFieldValue={onChangeFieldValue}
            onDelete={onDeleteComposerButton}
          />
        </div>
      )}
    </>
  );
}
