import { getFilteredScoringResult, getRulesScoringResult } from 'src/processes/CMS/processes/management/utils/end-user-flow-helpers';

export function useHvmIntegration(pagesFlow) {
  // scoring
  const { answersScoring, rulesProductsProperties, allPages } = pagesFlow;

  const sendHvmData = () => {
    // eslint-disable-next-line no-console
    console.log(productsInfo);
    window.parent.postMessage(
      {
        messageType: 'hvm',
        products: productsInfo,
      },
      '*'
    );
  };

  // we're on admin constructor page
  if (!allPages) {
    return { sendHvmData };
  }

  const allProductsPages = allPages
    .flatMap(item => {
      return item.elements;
    })
    .filter(el => el.templateType === 'PRODUCT');

  const products = allProductsPages.flatMap(productPage => productPage.content.products);

  const scoringResult = getFilteredScoringResult(answersScoring, pagesFlow, false, products);
  const scoringRulesResult = getRulesScoringResult(products, scoringResult, rulesProductsProperties, false);

  const finalScoring =
    scoringRulesResult
      .map(scoringItem => ({
        hvmProductId: scoringItem.hvmProductId,
        score: (scoringItem.score || 0) + (scoringItem.ruleScore || 0),
      }))
      .filter(scoringItem => scoringItem.hvmProductId) || [];

  const productsWithHvmId = products.filter(product => product.hvmProductId !== null);

  const hvmRecommended = JSON.parse(localStorage.getItem('hvmRecommended')) || [];

  const productsInfo = productsWithHvmId.map(product => ({
    score: finalScoring.find(scoringItem => scoringItem.hvmProductId === product.hvmProductId)?.score,
    selected: product.selected,
    hvmProductId: product.hvmProductId,
    recommended: hvmRecommended.includes(product.hvmProductId),
    counter: product.counterSection?.value,
    showCounter: product.counterSection?.showCounter,
  }));

  return { sendHvmData };
}
