import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { AnswersConditionBlock } from './AnswersConditionBlock';
import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { FlowSingleButtonSection } from '../FlowSingleButtonSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { ConditionTypes, DestinationVariants } from '../../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';
import '../../../style.scss';

/**
 * @desc Question Answers Flow
 * @param {Array} elements
 * @param {Object} element
 * @param {Function} handleChangeFlowFieldValue
 * @param {Function} handleChangeFlowFieldsValue
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function QuestionAnswersFlow({ elements, element, handleChangeFlowFieldValue, handleChangeFlowFieldsValue, disabled }) {
  const dispatch = useDispatch();
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { elseGoTo } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { additionalButton } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;
  const { addCondition } = useLocalization().translations.shared.generic;

  const elementIdByType = getIndexByTemplateType(elements, element);

  const { flow, content, templateType, label, id: elementId, rules } = element;
  const { multipleChoice, answers, composerButton } = content;

  const addConditionOnClick = () => {
    const result = questionnaireStructure?.map(el => {
      return el.id === elementId
        ? {
            ...el,
            flow: {
              ...el.flow,
              conditions: [
                ...flow.conditions,
                {
                  condition: ConditionTypes.AND,
                  ifAnswers: [],
                  ifGoTo: DestinationVariants.NEXT,
                  ifShowElements: [],
                  elseIfAnswers: [],
                  elseIfGoTo: DestinationVariants.NEXT,
                  elseIfShowElements: [],
                },
              ],
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <>
      <div className='flow_process_box'>
        <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ marginBottom: 16 }} />
        <FlowRulesList elementRules={rules} />

        {flow.conditions?.map((conditionItem, index) => (
          <AnswersConditionBlock
            key={uuidv4()}
            index={index}
            conditionItem={conditionItem}
            conditions={flow.conditions}
            multipleChoice={multipleChoice}
            answers={answers}
            handleChangeFieldValue={handleChangeFlowFieldValue('conditions')}
            elementId={elementId}
            disabled={disabled}
          />
        ))}

        <AppBasicButton onClick={addConditionOnClick} style={{ display: 'inline-flex', margin: '10px 0 16px 0' }} disabled={disabled}>
          <AppText className='primary_color bold' textValue={addCondition} />
        </AppBasicButton>

        <AppText textValue={elseGoTo} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={flow}
          options={elements}
          onChange={handleChangeFlowFieldsValue}
          disabled={disabled}
          goToFieldName='elseGoTo'
          showElementsFieldName='elseShowElements'
        />
      </div>
      <div className='flow_process_box'>
        {composerButton && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={composerButton}
            buttonTextValue={composerButton.value}
            buttonLabelText={additionalButton}
            buttonName='composerButton'
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}
