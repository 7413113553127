import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { clearDateFilters } from '../../../../../../../utils/arranges-helpers';
import { useDateColumn } from '../../../../../../../components/columns/date';
import { useDateArrange } from '../../../../../../../utils/hooks/arranges-by-type/useDateArrange';
import { useFinderArrangeRequests } from '../lib/useFinderArrangeRequests';

export function useFinderDateColumn({ title, arrangedId, filterFromName, filterToName, headerCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearDateFilters(form),
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
}
