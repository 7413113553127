import { useSelector } from 'react-redux';
import { AppCheckbox } from '../../../../../../../ui/AppCheckbox';
import React from 'react';

export function QuestionnaireCheckbox({ disabled, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const isDisabled = isViewMode || disabled;

  return <AppCheckbox disabled={isDisabled} {...rest} />;
}
