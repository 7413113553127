import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { DeleteIcon } from '../../../../../../../../../../../../../ui/icons/DeleteIcon';
import { ProductSelectFormItem } from '../../../../../../../../../features/form-items/ProductSelectFormItem';
import { AppFormSection } from '../../../../../../../../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { checkValue, handleTranslation } from '../../../../../../../../../../../../../lib/util/helpers';
import { AppBasicButton } from '../../../../../../../../../../../../../ui/buttons/AppBasicButton';
import './style.scss';
import { AppSegmented } from '../../../../../../../../../../../../../ui/AppSegmented';

const ShortProductName = {
  DEFAULT: false,
  CUSTOM: true,
};

// eslint-disable-next-line max-lines-per-function
export function ProductValueItem({ productValue, productValues, products, index, handleChangeField, handleChangeContentFields, scoring }) {
  const { product } = useLocalization().translations.shared.generic;
  const { label } = useLocalization().translations.unique.formItems.templatePriceForAnswer;
  const { fullName, shortName } = useLocalization().translations.unique.pages.questionnaire.content;
  const { isViewMode } = useSelector(({ finders }) => finders);

  const handleChangeProductValues = selected => {
    const updatedProductValues = productValues.map(prodVal => {
      return prodVal.id === productValue.id
        ? { ...prodVal, value: selected.value, price: selected.price, productName: selected.title, productShortName: selected.shortName }
        : prodVal;
    });
    handleChangeField(updatedProductValues);
  };

  const handleChangePrices = (value, priceId) => {
    if (Number(value) >= 0) {
      const updatedProductValues = productValues.map(prodVal => {
        return prodVal.id === productValue.id
          ? { ...prodVal, prices: prodVal.prices.map(priceObj => (priceObj.priceId === priceId ? { ...priceObj, value: Number(value) } : priceObj)) }
          : prodVal;
      });
      handleChangeField(updatedProductValues);
    }
  };

  const deleteProductValue = () => {
    const updatedProductValues = productValues.filter(productVal => productVal.id !== productValue.id);
    const updatedScoring = scoring.filter(scoreObj => scoreObj.id !== productValue.id);
    handleChangeContentFields({ productValues: updatedProductValues, scoring: updatedScoring });
  };

  const filteredProducts = products.filter(
    prodObj =>
      !productValues
        .filter(prodVal => prodVal.value !== productValue.value)
        .map(prodVal => prodVal.value)
        .includes(prodObj.id)
  );

  const handleChangeTypeName = value => {
    const updatedProductValues = productValues.map(prodVal => {
      return prodVal.id === productValue.id ? { ...prodVal, isShortProductName: value } : prodVal;
    });
    handleChangeField(updatedProductValues);
  };

  const options = [
    { value: ShortProductName.DEFAULT, label: fullName },
    { value: ShortProductName.CUSTOM, label: shortName },
  ].map(item => ({ ...item, label: handleTranslation(item.label) }));

  return (
    <>
      <div className='align_items_center product_value_heading_block'>
        <div className='product_value_heading_left'>
          <AppText className='product_value_heading' textValue={product({ amount: index + 1 })} />
          <AppBasicButton onClick={deleteProductValue} disabled={isViewMode}>
            <DeleteIcon className='product_value_delete_icon' />
          </AppBasicButton>
        </div>
        <AppSegmented
          options={options}
          onChange={() => handleChangeTypeName(!productValue.isShortProductName)}
          value={productValue.isShortProductName}
        />
      </div>
      <ProductSelectFormItem value={checkValue(products, productValue.value)} products={filteredProducts} onChange={handleChangeProductValues} />
      {productValue.prices.map((price, answerIndex) => (
        <AppFormSection
          key={price.priceId}
          textFieldProps={{
            value: price.value,
            onChange: event => handleChangePrices(event.target.value, price.priceId),
            label: label({ index: answerIndex + 1 }),
            type: 'number',
            disabled: isViewMode,
          }}
        />
      ))}
    </>
  );
}
