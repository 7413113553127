import { AppText } from '../../../../../../ui/text/AppText';
import { memo } from 'react';
import styles from './ImageOption.module.scss';

export const ImageOption = memo(({ s3Url, id, fileName, onSelect, value, isOpacity, isMultiSelection, isSelected }) => {
  return (
    <div role='presentation' className={`align_items_center ${styles.image_select_option_wrap}`} onClick={() => onSelect(id)}>
      {isMultiSelection && <input className={styles.checkbox} type='checkbox' checked={isSelected} onChange={() => onSelect(id)} />}
      <div className={styles.image_select_option_picture} style={{ backgroundImage: `url('${s3Url}')`, opacity: isOpacity ? '0.6' : '1' }} />
      <AppText ellipsis className={`${styles.image_select_option_text} ${value === id ? 'primary_color' : ''}`}>
        {fileName}
      </AppText>
    </div>
  );
});
