export const theme = {
  token: {
    borderRadius: 8,
    borderRadiusLG: 8,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.2)',
    boxShadowSecondary: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    fontSizeHeading3: 24,
    // error
    // colorError: 'FF4949',
    // colorErrorText: 'FF4949',
    // colorErrorBorder: 'FF4949',
    // colorErrorBorderHover: '#FF4949',
    // // success
    // colorSuccess: '#00A887',
    // colorSuccessText: '#00A887',
    // colorSuccessBorder: '#00A887',
    // colorSuccessBorderHover: '#00A887',
    // // warning
    // colorWarning: '#F6AB2F',
    // colorWarningText: '#F6AB2F',
    // colorWarningBorder: '#F6AB2F',
    // colorWarningBorderHover: '#F6AB2F',
    // primary
    colorPrimary: '#DA1B5E',
    // text
    colorText: '#191919',
    colorTextBase: '#191919',
    colorTextSecondary: '#5C5C6E',
    colorTextDisabled: '#9696A2',
    // colorTextTertiary: '#FFFFFF',
    controlHeight: 40,
    fontFamily: 'SimonKucher',
    fontSize: 16,
    lineHeight: 1.2,
  },
};
