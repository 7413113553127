/* eslint-disable i18next/no-literal-string,max-lines-per-function,react/no-danger,max-lines */
import { ConfigProvider, Typography } from 'antd';
import deDE from 'antd/locale/de_DE';
import { DynamicContactFormRow } from './components/DynamicContactFormRow';
import { SuccessScreen } from '../PreviewForm/SuccessScreen';
import { end_user_de_validateMessages } from '../../../../../../../../lib/localization/DE/de_validation';
import { StyledFormWrapper } from '../PreviewForm/components/PreviewFormWrapper/StyledFormWrapper';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { onSubmitDynamicForm } from './helpers/onSubmitDynamicContactForm';
import styles from './styles.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import { showErrorNotification } from '../../../../../../../../lib/store/slices/notificationsSlice';
import { useDispatch } from 'react-redux';
import { verifyReCaptchaToken } from './helpers/verifyReCaptchaToken';
import { useResultProps } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useResultProps';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { generatePdfRequest } from 'src/lib/api';
import { InfoIconComponent } from '../InfoIconComponent';

export function PreviewDynamicContactForm({
  allPages,
  contactFormObject,
  resultTableContent,
  colors,
  fonts,
  fontsForLoading,
  style,
  pagesFlow,
  setPagesFlow,
  pageHistory,
  form,
  finderUrl,
}) {
  const { applyVariable, applyVariablesToObject } = useVariables(pagesFlow);
  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, bodyStyle = {}, formBlockStyle = {}, additionalInfoBlockStyle = {} } = style || {};
  const {
    content: {
      header: { headline, subline, body, showReCaptcha },
      formRowList,
      additionalText,
      mail = {},
      emailHeaderText,
      successButton,
    },
  } = contactFormObject;

  const { body: emailBody, subject, success } = mail;

  const { printedResultProps = {} } = useResultProps({
    allPages,
    resultObject: contactFormObject,
    fonts,
    colors,
    headlineStyle,
    sublineStyle,
    pagesFlow,
    fontsForLoading,
    pageHistory,
  });

  const { currentFlowElement, tenant } = pagesFlow;

  const { windowWidth } = useWindowResize();

  const dispatch = useDispatch();

  const recaptcha = useRef();

  const createSideText = () => {
    return { __html: applyVariable(additionalText.sideText) };
  };

  const getPdfBlob = async () => {
    let pdfBlob = null;
    let pdfType = null;

    for (const page of allPages) {
      for (const element of page.elements) {
        if (element.templateType === 'RESULT') {
          pdfType = element.content?.kind || 'RESULT';
        }
      }
    }

    if (pdfType) {
      const settings = {
        showDate: true,
        showLink: true,
        showPrices: true,
        showProductNames: true,
        showProductPageLink: true,
        showProductPictures: true,
        showTotalPriceBlock: true,
      };

      const payload = pagesFlow.printedResultProps ||
        resultTableContent || {
          ...printedResultProps,
          settings,
        };

      pdfBlob = await generatePdfRequest({
        payload,
        type: pdfType,
      });
    }

    return pdfBlob;
  };

  const onFinish = async data => {
    const captchaToken = recaptcha?.current?.getValue();

    if (showReCaptcha && tenant?.reCaptchaKey && tenant?.reCaptchaSecret) {
      if (captchaToken) {
        const response = await verifyReCaptchaToken(captchaToken, finderUrl);
        if (response.success) {
          setPagesFlow(prev => ({ ...prev, isDynamicFormSubmitting: true }));
          await onSubmitDynamicForm({
            emailBody,
            formValues: data,
            formRowList,
            subject,
            applyVariablesToObject,
            applyVariable,
            setPagesFlow,
            bankName: tenant?.bankName,
            logo: tenant.logo?.s3Url,
            finderUrl,
            pdfBlob: await getPdfBlob(),
            emailHeaderText: applyVariable(emailHeaderText),
          });
        } else {
          dispatch(showErrorNotification({ error: { message: 'reCaptcha token invalid' } }));
        }
      } else {
        dispatch(showErrorNotification({ error: { message: 'You must confirm you are not a robot' } }));
      }
    } else {
      setPagesFlow(prev => ({ ...prev, isDynamicFormSubmitting: true }));
      await onSubmitDynamicForm({
        emailBody,
        formValues: data,
        formRowList,
        subject,
        applyVariablesToObject,
        applyVariable,
        setPagesFlow,
        bankName: tenant?.bankName,
        logo: tenant.logo?.s3Url,
        finderUrl,
        pdfBlob: await getPdfBlob(),
        emailHeaderText: applyVariable(emailHeaderText),
      });
      form.resetFields();
    }
  };

  return (
    <section className={styles.section} style={{ width: '100%' }}>
      <div className={styles.container}>
        {currentFlowElement.templateType === TemplateTypes.CONTACT_FORM && currentFlowElement.submitted ? (
          <SuccessScreen
            bankName={tenant?.bankName}
            pagesFlow={pagesFlow}
            successCustomText={applyVariable(success)}
            successButton={successButton}
            colors={colors}
            fonts={fonts}
            applyVariable={applyVariable}
          />
        ) : (
          <div style={{ width: '100%', ...rootStyle }}>
            <div className={styles.form_block}>
              {headline && (
                <Typography.Paragraph
                  style={{
                    margin: '0 0 10px 0',
                    fontSize: fonts.headlineSecond.fontSize,
                    fontFamily: fonts.headlineSecond.font,
                    fontWeight: fonts.headlineSecond.fontWeight,
                    color: colors[fonts.headlineSecond.colorVariant],
                    ...headlineStyle,
                  }}
                >
                  {applyVariable(headline.value)}
                  <InfoIconComponent type={headline} styles={{ top: 4 }} applyVariable={applyVariable} />
                </Typography.Paragraph>
              )}
              {subline && (
                <Typography.Paragraph
                  style={{
                    marginBottom: 24,
                    lineHeight: '24px',
                    fontSize: fonts.headlineThird.fontSize,
                    fontFamily: fonts.headlineThird.font,
                    fontWeight: fonts.headlineThird.fontWeight,
                    color: colors[fonts.headlineThird.colorVariant],
                    ...sublineStyle,
                  }}
                >
                  {applyVariable(subline.value)}
                  <InfoIconComponent type={subline} styles={{ top: 4 }} applyVariable={applyVariable} />
                </Typography.Paragraph>
              )}
              {body && (
                <Typography.Paragraph
                  style={{
                    marginBottom: 24,
                    lineHeight: '19px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                    ...bodyStyle,
                  }}
                >
                  {applyVariable(body.value)}
                  <InfoIconComponent type={body} styles={{ top: 4 }} applyVariable={applyVariable} />
                </Typography.Paragraph>
              )}
            </div>
            <div className={styles.form_container}>
              <div className={styles.form_block} style={formBlockStyle}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: colors.primaryColor,
                      borderRadius: 4,
                      fontFamily: fonts.body.font,
                      colorBgContainerDisabled: colors.primaryColor,
                    },
                  }}
                  form={{ validateMessages: end_user_de_validateMessages }}
                  locale={deDE}
                >
                  <StyledFormWrapper form={form} onFinish={onFinish} windowWidth={windowWidth} colors={colors}>
                    {formRowList.map((rowObj, index) => (
                      <DynamicContactFormRow
                        key={rowObj.id}
                        form={form}
                        rowObject={rowObj}
                        rowIndex={index}
                        fonts={fonts}
                        colors={colors}
                        formList={formRowList}
                        isEndUserForm
                        applyVariable={applyVariable}
                      />
                    ))}
                    {showReCaptcha && tenant?.reCaptchaKey && tenant?.reCaptchaSecret && (
                      <div style={{ margin: '10px 0' }}>
                        <ReCAPTCHA ref={recaptcha} sitekey={tenant?.reCaptchaKey} />
                      </div>
                    )}
                  </StyledFormWrapper>
                </ConfigProvider>
                <Typography.Text
                  style={{
                    margin: '32px 0 10px 0',
                    fontSize: fonts.subText.fontSize,
                    fontFamily: fonts.subText.font,
                    fontWeight: fonts.subText.fontWeight,
                    color: colors[fonts.subText.colorVariant],
                  }}
                >
                  *) Pflichtangabe
                </Typography.Text>
              </div>
              <div className={styles.aside_block} style={additionalInfoBlockStyle}>
                {additionalText.headline && (
                  <Typography.Paragraph
                    style={{
                      marginBottom: 24,
                      lineHeight: '24px',
                      fontSize: fonts.headlineThird.fontSize,
                      fontFamily: fonts.headlineThird.font,
                      fontWeight: fonts.headlineThird.fontWeight,
                      color: colors[fonts.headlineThird.colorVariant],
                      ...sublineStyle,
                    }}
                  >
                    {applyVariable(additionalText.headline.value)}
                    <InfoIconComponent type={additionalText.headline} styles={{ top: 4 }} applyVariable={applyVariable} />
                  </Typography.Paragraph>
                )}
                <div
                  style={{
                    lineHeight: '20px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                  }}
                  dangerouslySetInnerHTML={createSideText()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
