import classNames from 'classnames';

import { Table } from './Table';
import { InfoIcon } from 'src/ui/icons/InfoIcon';
import { VariablePopup } from './VariablePopup';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { CrossIcon } from 'src/ui/icons/CrossIcon';
import { AppIconButton } from 'src/ui/AppIconButton';

import styles from './VariableTable.module.scss';
import { useState } from 'react';

/**
 * @desc Variables Table
 * @param {Boolean} isOpen
 * @param {Function} setIsOpen
 * @returns {JSX.Element}
 */
export function VariablesTable({ isOpen, setIsOpen }) {
  const { variables } = useLocalization().translations.unique.pages;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    isOpen && (
      <div className={styles.variablesTable}>
        <div className={styles.variablesInfo}>
          <div className={classNames(styles.header, styles.section)}>
            <div>
              {variables.variables()} <InfoIcon />
            </div>
            <div>
              <AppIconButton onClick={() => setIsOpen(false)}>
                <CrossIcon />
              </AppIconButton>
            </div>
          </div>
          <div className={classNames(styles.addVariable, styles.section)}>
            <button type='button' className={styles.action} onClick={() => setIsModalOpen(true)}>
              {variables.plusAddVariable()}
            </button>
          </div>
        </div>
        <div className={styles.section}>
          <Table setIsModalOpen={setIsModalOpen} />
        </div>

        <VariablePopup isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>
    )
  );
}
