import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppRadioButton } from '../../../../../../../../../ui/AppRadioButton';
import { TemplateTypes } from '../../../../../../../../../lib/models/TemplateTypes';

/**
 * @desc Layout Radio Button
 * @param {Object} layout
 * @param {String} templateType
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function LayoutRadioButton({ layout, templateType, disabled, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <Root {...rest} $templateType={templateType} disabled={isViewMode || disabled}>
      <span className='LayoutRadioButtonCircle' />
      <LayoutWrapper>{layout}</LayoutWrapper>
    </Root>
  );
}

const Root = styled(AppRadioButton)(({ disabled, $templateType }) => ({
  width: '100%',
  height: $templateType === TemplateTypes.PRODUCT ? '208px' : '191px',
  position: 'relative',
  borderWidth: '2px !important',
  borderStyle: 'solid',
  borderColor: '#CFCFD4',
  borderRadius: '8px',
  overflow: 'hidden',

  '.LayoutRadioButtonCircle': {
    position: 'absolute',
    top: '16px',
    left: '12px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#191919',
    transition: 'all 0.15s',
    zIndex: 1,
    '&:after': {
      content: "''",
      display: 'none',
      borderRadius: '50%',
      transition: 'all 0.15s',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: '4.5px',
      background: disabled ? '#858899' : '#0092A3',
    },
  },

  '&.ant-radio-button-wrapper-checked': {
    '&, *': {
      color: 'unset',
      '&:hover': {
        color: 'unset',
      },
    },

    borderColor: disabled ? '#858899' : '#0092A3 !important',

    '.LayoutRadioButtonCircle': {
      borderColor: disabled ? '#858899' : '#0092A3',

      '&:after': {
        display: 'block',
      },
    },
  },
}));

const LayoutWrapper = styled('div')({
  position: 'absolute',
  inset: 0,
});
