import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import { TemplateFormSection } from '../../../features/TemplateFormSection';
import { ConnectionLineShort } from '../../../../../../../ui/icons/ConnectionLineShort';
import { DroppableIdField, DroppableIdKind, ResultType } from '../../../../../../../lib/models/Questionnaire';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../hooks/useUpdateStructure';
import { InfoIconTextarea } from '../../../features/form-items/InfoIconTextarea';

export function TextFieldWithInfoIcon({ disabled, label, data = {}, dataPath, mainFieldProps = {}, infoIconFieldProps = {}, ...rest }) {
  const { templateInfo } = useLocalization().translations.unique.formItems;
  const { textFieldProps: { disabled: isMainFieldDisabled, ...restMainTextFieldProps } = {}, ...restMainFieldProps } = mainFieldProps || {};
  const { textFieldProps: { disabled: isInfoIconFieldDisabled, ...restInfoIconTextFieldProps } = {}, ...restInfoIconFieldProps } =
    infoIconFieldProps || {};
  const { updateStructure } = useUpdateStructure();
  const infoIconPath = `${dataPath}.infoIcon`;

  const { infoIcon } = data;

  const changeFieldText = value => {
    updateStructure(draft => {
      lodashSet(draft, `${dataPath}.value`, value);
    });
  };

  const changeInfoIconFieldText = value => {
    updateStructure(draft => {
      lodashSet(draft, infoIconPath, { value });
    });
  };

  const deleteInfoIcon = () => {
    updateStructure(draft => {
      lodashSet(draft, infoIconPath, null);
    });
  };

  return (
    <Droppable
      droppableId={JSON.stringify({
        [DroppableIdField.PATH]: infoIconPath,
        [DroppableIdField.KIND]: DroppableIdKind.WITH_PATH,
      })}
      type={ResultType.INFO_ICON}
      isDropDisabled={disabled || Boolean(infoIcon)}
    >
      {(provided, snapshot) => (
        <Root ref={provided.innerRef} {...rest}>
          <TemplateFormSection
            textFieldProps={{
              disabled: disabled || isMainFieldDisabled,
              ...restMainTextFieldProps,
            }}
            valueObject={data}
            label={label}
            onChange={changeFieldText}
            {...restMainFieldProps}
          />
          {(infoIcon || snapshot.isDraggingOver) && (
            <>
              <InfoIconLine>
                <ConnectionLineShort stroke={infoIcon?.infoIconName || snapshot.isDraggingOver ? '#33BDCD' : '#5C5C6E'} />
              </InfoIconLine>
              <InfoIconTextarea
                valueObject={infoIcon || { value: '' }}
                label={templateInfo.label}
                onChange={changeInfoIconFieldText}
                onClickSuffix={deleteInfoIcon}
                textFieldProps={{
                  disabled: disabled || isInfoIconFieldDisabled,
                  ...restInfoIconTextFieldProps,
                }}
                {...restInfoIconFieldProps}
              />
            </>
          )}
        </Root>
      )}
    </Droppable>
  );
}

const Root = styled('div')({
  position: 'relative',
  zIndex: 0,
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 19,
  right: -9,
});
