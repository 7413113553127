import { BulletPointIconFormItem } from '../../../../../../../../../features/form-items/BulletPointIconFormItem';
import { FontColorFormItem } from '../../../../../../../../../features/form-items/FontColorFormItem';
import { useSelector } from 'react-redux';
import { checkDisableColor } from '../../../../../../../../../../../../../lib/util/helpers';
import { useChangeCardContentField } from '../../../../../../../hooks/useChangeCardContentField';
import styled from 'styled-components';
import { useFinderColors } from '../../../../../../../hooks/useFinderColors';

export function ProductBulletPointSelects({ structureItemIndex, bulletPointIcon, bulletPointIconColor }) {
  const { changeField } = useChangeCardContentField(structureItemIndex);
  const {
    isViewMode,
    finder: {
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);
  const icons = [...defaultIcons, ...customIcons];
  const colors = useFinderColors();

  return (
    <>
      <Styled
        as={BulletPointIconFormItem}
        size='small'
        color={colors[bulletPointIconColor]}
        icons={icons}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '120px' },
        }}
        selectProps={{
          size: 'small',
          value: bulletPointIcon,
          onChange: (selected, _action) => changeField('bulletPointIcon')({ value: selected.value, svgContent: selected.svgContent }),
        }}
      />
      <Styled
        as={FontColorFormItem}
        size='small'
        value={bulletPointIconColor}
        colors={colors}
        onChange={changeField('bulletPointIconColor')}
        disabled={checkDisableColor(icons, bulletPointIcon.value) || isViewMode}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '120px' },
        }}
        selectProps={{
          size: 'small',
        }}
      />
    </>
  );
}

const Styled = styled('div')({
  '.react-select__value-container': {
    padding: '4px 5px 4px',
    overflowX: 'hidden !important',
  },
});
