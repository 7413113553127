import { Space, Tooltip } from 'antd';
import { AppFormSection } from '../../../../../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { IconButton } from '../../../../../../features/IconButton';
import { CircleXIcon } from '../../../../../../../../../../ui/icons/CircleXIcon';
import { LinkIcon } from '../../../../../../../../../../ui/icons/LinkIcon';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { FormItemName } from '../../../../../../../../../../lib/models/Form';
import './styles.scss';

export function ButtonLinkRow({ linkObject, remove, isMaxLength }) {
  const { linkLabel, linkUrl } = useLocalization().translations.unique.formItems;
  const { deleteBtn } = useLocalization().translations.shared.generic;

  const unlimited = 999;
  const maxLength = 15;

  return (
    <Space size='middle' style={{ alignItems: 'flex-start' }}>
      <AppFormSection
        formItemProps={{
          name: [linkObject.name, FormItemName.LINK_NAME],
          style: { width: 387 },
          rules: [
            { required: true, message: handleTranslation(linkLabel.errors.required) },
            { max: isMaxLength ? maxLength : unlimited, message: 'Maximum length of the input must be 15' },
          ],
        }}
        textFieldProps={{ label: linkLabel.label }}
      />
      <AppFormSection
        className='button_link_url'
        formItemProps={{
          name: [linkObject.name, FormItemName.LINK_URL],
          style: { width: 387 },
          rules: [
            { required: true, message: handleTranslation(linkUrl.errors.required) },
            { type: 'url', message: handleTranslation(linkUrl.errors.invalid) },
          ],
        }}
        textFieldProps={{ label: linkUrl.label, prefix: <LinkIcon /> }}
      />
      <Tooltip placement='top' title={deleteBtn()}>
        <div className='button_link_delete_btn'>
          <IconButton onClick={() => remove(linkObject.name)} icon={<CircleXIcon className='button_link_delete_icon' />} />
        </div>
      </Tooltip>
    </Space>
  );
}
