import { Space } from 'antd';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppButton } from '../../../../../../ui/buttons/AppButton';
import './styles.scss';

export function TableTabs({ options, selected, setActiveTab, tabButtonStyle = true }) {
  return (
    <Space size='middle'>
      {options.map(({ tabName, value }, index) => {
        return tabButtonStyle ? (
          <div
            className={`tab_wrap ${selected === value ? 'tab_wrap__selected' : ''} `}
            key={value}
            role='tab'
            tabIndex={index}
            onClick={() => setActiveTab(value)}
            onKeyDown={() => setActiveTab(value)}
            aria-selected={selected === value}
          >
            <AppText className={selected === value ? 'primary_color bold' : ''} textValue={tabName} />
          </div>
        ) : (
          <AppButton
            className='tab_variant_btn'
            key={value}
            onClick={() => setActiveTab(value)}
            type={selected === value ? 'primary' : 'default'}
            textValue={tabName}
          />
        );
      })}
    </Space>
  );
}
