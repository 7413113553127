import { FIRST_INDEX } from '../../../lib/util/constants';

// Check if multi selected options have already [{value, title}] structure (not just array of IDs, like [id1, id2])
function haveMultiSelectedOptionListCompleteStructure(data) {
  return Boolean(data?.[FIRST_INDEX]?.title);
}

function isSelectedOption(iterated, selected) {
  return iterated?.value === selected?.value || iterated?.value?.includes?.(selected?.value) || iterated?.value === selected;
}

function getMultiSelectedOptionList(allOptionList, selectedOptionList) {
  return haveMultiSelectedOptionListCompleteStructure(selectedOptionList)
    ? selectedOptionList
    : selectedOptionList.map(selected => allOptionList.find(item => isSelectedOption(item, selected)));
}

function getSingleSelectedOption(allOptionList, selected) {
  return allOptionList.find(item => isSelectedOption(item, selected));
}

export function formatAppNewSelectData({ options, selected }) {
  if (selected) {
    return Array.isArray(selected) ? getMultiSelectedOptionList(options, selected) : getSingleSelectedOption(options, selected);
  }

  return undefined;
}
