import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { Radio } from 'antd';
import { AppRadio } from '../../../../../../../../../../../../../ui/AppRadio';
import { useSelector } from 'react-redux';
import { ProductsPriority } from './ProductsPriority';
import { ResultSortProductsByType } from '../../../../../../../../../utils/consts';

export function SortProductsBy({ sortProductsBy, structureItemIndex, onChange }) {
  const {
    title,
    sortProductsByElements: { defaultText, priority },
  } = useLocalization().translations.unique.pages.result.sortProductsBy;

  const { isViewMode } = useSelector(({ finders }) => finders);

  const { selected: selectedValue, productsPriority } = sortProductsBy || {};

  const handleChange = event => {
    onChange({ selected: event.target.value, productsPriority: productsPriority || [] });
  };

  return (
    <div className='template_card_content_block'>
      <AppText className='template_card_headline' textValue={title} />
      <Radio.Group
        value={selectedValue || ResultSortProductsByType.DEFAULT}
        onChange={event => handleChange(event)}
        disabled={isViewMode}
        style={{ marginTop: 16 }}
      >
        <AppRadio value={ResultSortProductsByType.DEFAULT} textValue={defaultText()} style={{ marginBottom: 10 }} />;
        <AppRadio value={ResultSortProductsByType.PRIORITY} textValue={priority()} style={{ marginBottom: 10 }} />;
      </Radio.Group>
      <ProductsPriority
        data={productsPriority}
        structureItemIndex={structureItemIndex}
        disabled={selectedValue !== ResultSortProductsByType.PRIORITY || isViewMode}
      />
    </div>
  );
}
