import { AppNewSelect } from 'src/ui/AppNewSelect';

import { useConditionElement } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionElement';
import { useElements } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useElements';

import { elementOptions } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionTypes';

import {
  productProperties,
  answerProperties,
  noProductAnswerProperties,
  elementSubTypes,
  mapSelectOptions,
  questionProperties,
  buttonProperties,
} from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import styles from './ConditionItem.module.scss';

const mappedElementOptions = mapSelectOptions(elementOptions);

const mappedProductPropertyOptions = mapSelectOptions(Object.values(productProperties));

const mappedButtonPropertyOptions = mapSelectOptions(Object.values(buttonProperties));

const mappedQuestionPropertyOptions = mapSelectOptions(Object.values(questionProperties));

const selectItemLabelEnum = {
  [elementSubTypes.Answer]: 'Select answer',
  [elementSubTypes.Product]: 'Select product',
  [elementSubTypes.Question]: 'Select question',
  [elementSubTypes.Buttons]: 'Select button',
};

const selectComposerPropertyLabelEnum = {
  Score: 'Score for',
  Savings: 'Savings for',
};

// TODO: ADD I18N

/**
 * @desc Conditional Element
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ConditionalElement({ value, onChange }) {
  const { elementType, elements, property, composerItem } = value;
  const { handleElementTypeChange, handleElementsChange, handlePropertyChange, handleComposerItemChange } = useConditionElement({
    value,
    onChange,
  });

  const { questionOptions, answerOptions, products, tenantProducts, buttons } = useElements();

  const isAnswerComposerItemShown =
    elementType === elementSubTypes.Answer && (property === productProperties.score || property === productProperties.savings);

  const isSelectedAnswerWithoutProducts = elements?.some(elementItem => elementItem.answerType === 'without product');

  const mappedPAnswerPropertyOptions = mapSelectOptions(
    Object.values(isSelectedAnswerWithoutProducts ? noProductAnswerProperties : answerProperties)
  );

  const elementProperties = {
    [elementSubTypes.Product]: mappedProductPropertyOptions,
    [elementSubTypes.Answer]: mappedPAnswerPropertyOptions,
    [elementSubTypes.Buttons]: mappedButtonPropertyOptions,
    [elementSubTypes.Question]: mappedQuestionPropertyOptions,
  };

  const selectedElementOptions = {
    [elementSubTypes.Product]: products,
    [elementSubTypes.Question]: questionOptions,
    [elementSubTypes.Answer]: answerOptions,
    [elementSubTypes.Buttons]: buttons,
  };

  const elementsWithoutTitle = (elements || []).map(elem => ({
    ...elem,
    title: '',
  }));

  return (
    <div className={styles.elementContainer} data-testid='conditionTestItem'>
      <AppNewSelect
        titleSearch
        className={`${styles.largeSelect} ${styles.selectItem}`}
        onChange={({ value }) => {
          handleElementTypeChange(value);
        }}
        value={elementType}
        label='Element'
        options={mappedElementOptions}
      />
      {elementType && (
        <AppNewSelect
          titleSearch
          label={selectItemLabelEnum[elementType]}
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={elementsWithoutTitle}
          isMultiSelect
          onChange={selection => {
            handleElementsChange(selection);
          }}
          options={selectedElementOptions[elementType]}
        />
      )}
      {elements && (
        <AppNewSelect
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={property}
          onChange={({ value }) => {
            handlePropertyChange(value);
          }}
          label='Property'
          options={elementProperties[elementType]}
        />
      )}
      {isAnswerComposerItemShown && (
        <AppNewSelect
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={composerItem}
          onChange={({ value }) => {
            handleComposerItemChange(value);
          }}
          label={selectComposerPropertyLabelEnum[property]}
          options={tenantProducts}
        />
      )}
    </div>
  );
}
