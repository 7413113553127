import { AppText } from '../../../../../../ui/text/AppText';
import { ChevronDownIcon } from '../../../../../../ui/icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../../../ui/icons/ChevronUpIcon';
import { isLength } from '../../../../../../lib/util/helpers';
import './style.scss';

export function QuestionDropBox({ question, index, selectedQuestion, selectQuestion }) {
  const selectFirstAnswer = () => {
    selectQuestion({
      elementId: question.id,
      answerId: isLength(question.content.answers) ? question.content.answers[0].id : null,
    });
  };

  const selectAnswerId = (event, id) => {
    event.stopPropagation();
    selectQuestion({ elementId: question.id, answerId: id });
  };

  const checkChartOrCounter = Array.isArray(question.content.chartAnswers) || Array.isArray(question.content.counterAnswers);

  return (
    <div className='question_drop_box'>
      <div role='presentation' className='question_drop_box_header' onClick={() => selectFirstAnswer()}>
        {selectedQuestion.elementId === question.id && Array.isArray(question.content.answers) && (
          <ChevronUpIcon className='question_drop_box_header_icon' fill='#5C5C6E' />
        )}
        {selectedQuestion.elementId !== question.id && Array.isArray(question.content.answers) && (
          <ChevronDownIcon className='question_drop_box_header_icon' fill='#5C5C6E' />
        )}
        {Array.isArray(question.content.chartAnswers) && <span className='question_drop_box_header_icon' />}
        {Array.isArray(question.content.counterAnswers) && <span className='question_drop_box_header_icon' />}
        <AppText
          style={{ color: checkChartOrCounter && selectedQuestion.elementId === question.id ? '#0092A3' : '#191919' }}
          className={selectedQuestion.elementId === question.id ? 'bold' : ''}
        >{`Q.${index + 1}`}</AppText>
      </div>

      {selectedQuestion.elementId === question.id &&
        Array.isArray(question.content.answers) &&
        question.content.answers.map((answer, answerIndex) => (
          <div key={answer.id} role='presentation' className='question_drop_box_item' onClick={event => selectAnswerId(event, answer.id)}>
            <AppText className={selectedQuestion.answerId === answer.id ? 'question_drop_box_item_selected' : ''}>{`A.${answerIndex + 1}`}</AppText>
          </div>
        ))}
    </div>
  );
}
