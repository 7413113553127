import { useParams, useSearchParams } from 'react-router-dom';
import { isTruthy } from '../../../../lib/util/helpers';

// REFACTOR
export function useTenantIdFromUri() {
  const [searchParams] = useSearchParams();
  const queryTenantId = searchParams.get('X-TENANT') ? Number(searchParams.get('X-TENANT')) : undefined;
  const { id: paramTenantId } = useParams();
  return isTruthy(queryTenantId) ? queryTenantId : paramTenantId;
}
