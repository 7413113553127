import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { openCategoryModal } from '../../../../../../../../../../../lib/store/slices/categoriesSlice';
import { CategoryModal } from '../../../../../../../../../lib/models/Modal';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import { IconButton } from '../../../../../../../features/IconButton';
import { EditIcon } from '../../../../../../../../../../../ui/icons/EditIcon';

export function CategoryActionsBodyCell({ row }) {
  const dispatch = useDispatch();

  return (
    <Space size='middle'>
      <IconButton icon={<EditIcon />} onClick={() => dispatch(openCategoryModal({ modalType: CategoryModal.EDIT, selectedCategory: row }))} />
      <IconButton
        icon={<DeleteIcon className='secondary_color' />}
        onClick={() => dispatch(openCategoryModal({ modalType: CategoryModal.DELETE, selectedCategory: row }))}
      />
    </Space>
  );
}
