import { AppInput } from 'src/ui/AppInput';
import { AppSwitch } from 'src/ui/AppSwitch';
import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { CardSection } from '../../../../../ui/CardSection';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import lodashSet from 'lodash.set';

import styled from 'styled-components';

export function ContactFormSuccessButton({ templateContent, structureItemIndex }) {
  const { updateStructure } = useUpdateStructure();

  const { name: buttonName, url, isNewTab } = templateContent.successButton || { name: '', url: '', isNewTab: false };

  const updateSuccessButton = (value, valueName) => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.successButton.${valueName}`, value);
    });
  };

  return (
    <CardSection withPaddingBottom withBorderBottom={false}>
      <CardSectionHeader>
        <AppText strong textValue='Success Button' />
      </CardSectionHeader>
      <AppInput
        label='Button name'
        value={buttonName}
        onChange={event => {
          updateSuccessButton(event.target.value, 'name');
        }}
      />
      <MarginContainer>
        <AppInput
          label='Button link'
          value={url}
          onChange={event => {
            updateSuccessButton(event.target.value, 'url');
          }}
        />
      </MarginContainer>
      <MarginContainer>
        <AppSwitch
          switchProps={{
            onChange: state => {
              updateSuccessButton(state, 'isNewTab');
            },
            checked: isNewTab,
          }}
          labelProps={{ textValue: 'Open in new tab' }}
        />
      </MarginContainer>
    </CardSection>
  );
}

const MarginContainer = styled('div')({
  marginTop: '24px',
});
