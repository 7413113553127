import { useDispatch, useSelector } from 'react-redux';
import { Form, Space } from 'antd';
import { FormCard } from '../../../../../features/FormCard';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { FormCardButton } from '../../../../../features/FormCardButton';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { EmailFormItem } from '../../../../../features/form-items/EmailFormItem';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { checkSMTPRequest } from '../../../../../../../../../lib/store/slices/findersSlice';
import { MailServerFormSection } from '../../../../../features/form-items/MailServerFormSection';

export function MailServerCard({ form }) {
  const dispatch = useDispatch();
  const { finder, tenant } = useSelector(({ finders }) => finders);
  const { general } = useLocalization().translations.unique.pages.settings;
  const { testConnect } = useLocalization().translations.shared.generic;
  const { mailServerContact, mailServerSender, mailServerHost, mailServerPort, mailServerUserName, mailServerPassword } =
    useLocalization().translations.unique.formItems;

  const switchServerChecked = Form.useWatch(FormItemName.MAIL_SERVER, form);
  const passwordValue = Form.useWatch(FormItemName.MAIL_SERVER_PASSWORD, form);

  const settingsSMTP = {
    [FormItemName.MAIL_SERVER_HOST]: Form.useWatch(FormItemName.MAIL_SERVER_HOST, form),
    [FormItemName.MAIL_SERVER_PORT]: Form.useWatch(FormItemName.MAIL_SERVER_PORT, form),
    [FormItemName.MAIL_SERVER_USER_NAME]: Form.useWatch(FormItemName.MAIL_SERVER_USER_NAME, form),
    [FormItemName.MAIL_SERVER_PASSWORD]: passwordValue === '********' ? null : passwordValue,
    finderId: finder.id,
  };

  const testConnection = () => {
    dispatch(checkSMTPRequest({ settingsSMTP, tenantId: tenant.id }));
  };

  const onChangeSMTPPort = event => {
    let { value } = event.target;
    value = value?.toString?.() || '';
    const result = value.replace(/\D/g, '');
    form.setFieldValue(FormItemName.MAIL_SERVER_PORT, result);
  };

  const mailServerHostValue = Form.useWatch(FormItemName.MAIL_SERVER_HOST, form);
  const mailServerPortValue = Form.useWatch(FormItemName.MAIL_SERVER_PORT, form);
  const mailServerUserNameValue = Form.useWatch(FormItemName.MAIL_SERVER_USER_NAME, form);
  const mailServerPasswordValue = Form.useWatch(FormItemName.MAIL_SERVER_PASSWORD, form);

  const checkDisabled = Boolean(mailServerHostValue && mailServerPortValue && mailServerUserNameValue && mailServerPasswordValue);

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className='bold' textValue={general.mailServer} />
        <FormCardButton textValue={testConnect} onClick={testConnection} disabled={!checkDisabled} />
      </AppBox>

      <AppBox>
        <EmailFormItem textFieldProps={{ label: mailServerContact.label }} formItemName={FormItemName.MAIL_SERVER_CONTACT} required />
      </AppBox>

      <MailServerFormSection />

      <Space size='middle' className='equal_space'>
        <EmailFormItem
          textFieldProps={{ label: mailServerSender.label, disabled: switchServerChecked }}
          formItemName={FormItemName.MAIL_SERVER_SENDER}
          required={false}
        />
      </Space>
      <Space size='middle' className='equal_space'>
        <AppFormSection
          formItemProps={{ name: FormItemName.MAIL_SERVER_HOST }}
          textFieldProps={{ label: mailServerHost.label, disabled: switchServerChecked }}
        />
        <AppFormSection
          formItemProps={{ name: FormItemName.MAIL_SERVER_PORT }}
          textFieldProps={{ label: mailServerPort.label, disabled: switchServerChecked, onChange: onChangeSMTPPort }}
        />
      </Space>
      <Space size='middle' className='equal_space'>
        <AppFormSection
          formItemProps={{ name: FormItemName.MAIL_SERVER_USER_NAME }}
          textFieldProps={{ label: mailServerUserName.label, disabled: switchServerChecked }}
        />
        <AppFormSection
          formItemProps={{ name: FormItemName.MAIL_SERVER_PASSWORD }}
          textFieldProps={{ label: mailServerPassword.label, disabled: switchServerChecked }}
        />
      </Space>
    </FormCard>
  );
}
