export const ALWAYS_SHOW = 'ALWAYS_SHOW';

export const OptionType = {
  FLAT: 'FLAT',
  GROUP: 'GROUP',
};

export const alwaysShowBaseItem = {
  value: ALWAYS_SHOW,
  optionType: OptionType.FLAT,
};

const WARNING_COLOR = '#C58926';

export const styles = {
  modal: {
    titleWrapper: { gap: '18px', margin: 0 },
    title: { margin: 0, fontSize: '16px', color: WARNING_COLOR },
    description: { display: 'flex', margin: '16px 0 24px' },
    footer: { justifyContent: 'flex-end', gap: '8px' },
    button: {
      fontSize: '12px',
      width: 'unset',
      padding: '5px 9.5px',
      height: 'unset !important',
    },
    actionButton: { backgroundColor: WARNING_COLOR, color: '#fff' },
  },
};
