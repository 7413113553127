import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openFinderModal, setQuestionnaireActivityRequest } from '../../../../../../../../lib/store/slices/findersSlice';
import { minsToMs, secToMs } from '../../../../../../../../lib/util/helpers';
import { useGetFinder } from '../../../../../../../../lib/hooks/useGetFinder';
import { FinderModal } from '../../../../../../lib/models/Modal';
import lodashThrottle from 'lodash.throttle';

const USER_ACTIONS = [
  'mousemove',
  'click',
  'keydown',
  'scroll',
  'focus',
  'input',
  'mouseover',
  'change',
  'dblclick',
  'contextmenu',
  'submit',
  'drag',
];

let timeoutId = null;
let isLaunch = false;
const TRACK_USER_ACTIVITY_DELAY = secToMs({ sec: 40 });
const USER_INACTIVITY_TIME = minsToMs({ mins: 5 });

export function useSetActivity() {
  const dispatch = useDispatch();
  const controller = new AbortController();

  const showModal = () => {
    dispatch(openFinderModal({ modalType: FinderModal.SESSION_EXPIRED }));
  };

  // REFACTOR: get `tenantId` & `finderId` not from `useGetFinder`
  const { finderId, tenantId } = useGetFinder();

  useEffect(() => {
    isLaunch = true;

    const resetInactivityTimer = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        showModal();
        isLaunch = false;
      }, USER_INACTIVITY_TIME);
    };

    const handleUserAction = lodashThrottle(() => {
      dispatch(setQuestionnaireActivityRequest({ finderId, tenantId }));
      if (isLaunch) {
        resetInactivityTimer();
      }
    }, TRACK_USER_ACTIVITY_DELAY);

    for (const action of USER_ACTIONS) {
      document.addEventListener(action, handleUserAction, { signal: controller.signal });
    }

    if (isLaunch) {
      resetInactivityTimer();
    }

    return () => {
      clearTimeout(timeoutId);
      controller.abort();
      isLaunch = false;
      handleUserAction.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
