import styles from './ProductRange.module.scss';
import { CrossIcon } from 'src/ui/icons/CrossIcon';

import { useLocalization } from 'src/lib/hooks/useLocalization';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';

/**
 * @desc Product Range
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
export function ProductRange({ value, onChange }) {
  const { from, to } = useLocalization().translations.shared.generic;

  const { rangeFrom, rangeTo } = value;

  const { handleValueChange } = useConditionResult({
    value,
    onChange,
  });

  const handleRangeValueChange = (input, value) => {
    const validatedValue = value ? Math.abs(value).toString() : value;
    handleValueChange(input, validatedValue);
  };

  return (
    <div className={styles.productRange}>
      {from()}
      <div className={styles.inputContainer}>
        <input value={rangeFrom} type='number' onChange={event => handleRangeValueChange('rangeFrom', event.target.value)} />
        <button type='button' className={styles.clearInput} onClick={() => handleRangeValueChange('rangeFrom', '0')}>
          <CrossIcon fill='#5C5C6E' width='10' height='10' />
        </button>
      </div>
      {to()}
      <div className={styles.inputContainer}>
        <input value={rangeTo} type='number' onChange={event => handleRangeValueChange('rangeTo', event.target.value)} />
        <button type='button' className={styles.clearInput} onClick={() => handleRangeValueChange('rangeTo', '0')}>
          <CrossIcon fill='#5C5C6E' width='10' height='10' />
        </button>
      </div>
    </div>
  );
}
