import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { CircleXIcon } from '../../../../../../ui/icons/CircleXIcon';
import './styles.scss';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { Droppable } from 'react-beautiful-dnd';
import { ConnectionLineShort } from '../../../../../../ui/icons/ConnectionLineShort';
import styled from 'styled-components';
import { InfoIconTextarea } from '../form-items/InfoIconTextarea';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

// eslint-disable-next-line max-lines-per-function
export function DraggableFormSectionQuestion({ valueObject, valueArray, index, onChange, label, item }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const { templateInfo } = useLocalization().translations.unique.formItems;
  const { groupBy } = useLocalization().translations.unique.pages.questionnaire.content;

  const handleValueChange = value => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, value };
    onChange(newArr);
  };

  const handleChangeValueInfoIcon = value => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, infoIcon: { infoIconName: 'infoIconName', value } };
    onChange(newArr);
  };

  const deleteValue = event => {
    event.stopPropagation();
    if (valueArray.length > 1) {
      const newArr = valueArray.filter(el => el.id !== valueObject.id);
      onChange(newArr);
    }
  };

  const handleDeleteInfoIcon = () => {
    const newArr = valueArray.map(el => (el.id === valueObject.id ? { ...el, infoIcon: null } : el));
    onChange(newArr);
  };

  const suffix = (
    <AppBasicButton disabled={isViewMode} onClick={event => deleteValue(event)}>
      <CircleXIcon className='draggable_input_suffix_icon' />
    </AppBasicButton>
  );

  const { infoIcon } = item.content.answers[index];

  const handleChange = value => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, exception: value };
    onChange(newArr);
  };

  return (
    <Root>
      <Droppable droppableId={`questionAnswer_${item.id}_${valueObject.id}`} type={!infoIcon?.infoIconName && 'INFO_ICON'}>
        {(provided, snapshot) => (
          <StyledWrapperInput ref={provided.innerRef}>
            <AppFormSection
              textFieldProps={{
                value: valueObject.value,
                onChange: event => handleValueChange(event.target.value),
                label: label({ index: index + 1 }),
                suffix,
                disabled: isViewMode,
              }}
            />
            {(infoIcon?.infoIconName || snapshot.isDraggingOver) && (
              <InfoIconLine>
                <ConnectionLineShort stroke={infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
              </InfoIconLine>
            )}
            {(infoIcon?.infoIconName || snapshot.isDraggingOver) && (
              <InfoIconTextarea
                valueObject={item?.content?.answers?.[index].infoIcon || { value: '' }}
                label={templateInfo.label}
                onChange={handleChangeValueInfoIcon}
                onClickSuffix={handleDeleteInfoIcon}
              />
            )}
          </StyledWrapperInput>
        )}
      </Droppable>
      {item.content?.multipleChoice && (
        <div style={{ flex: '1 1 30%' }}>
          <AppNewSelect
            options={valueObject?.exceptionOptions}
            label={groupBy}
            value={valueObject.exception}
            onChange={selected => handleChange(selected)}
            styles={{
              menuPortal: provided => ({ ...provided, zIndex: 9999 }),
              menu: provided => ({ ...provided, zIndex: 9999, padding: '0 10px' }),
              maxHeight: 40,
            }}
            menuPortalTarget={document.body}
            menuPosition='fixed'
          />
        </div>
      )}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 30,
});

const StyledWrapperInput = styled('div')({
  position: 'relative',
  flex: '1 1 85%',
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 19,
  right: -9,
});
