/* eslint-disable no-param-reassign */
import { addColumn, ComparisonTableCellKind, generateEmptyRow, getCellsAmount } from '../../comparison-table-utils';
import { NO_FOUND_INDEX } from '../../../../../../../../../../../lib/util/constants';
import { isIndexFound } from '../../../../../../../../../../../lib/util/helpers';
import { DEFAULT_NAME_CELL_INDEX, findCustomNameIndexFromRow } from './ProductFieldsSelect-helpers';

function insertEmptyRow(tableRows) {
  const cellsAmount = getCellsAmount(tableRows);
  const emptyRow = generateEmptyRow(cellsAmount);
  tableRows.push(emptyRow);
}

function findCustomNameIndexFromTable(tableRows) {
  let customNameCellIndex = NO_FOUND_INDEX;

  for (const row of tableRows) {
    if (isIndexFound(customNameCellIndex)) {
      break;
    }
    customNameCellIndex = findCustomNameIndexFromRow(row);
  }

  return customNameCellIndex;
}

function getNameCellIndexFromTable(tableRows, columnIndex) {
  const customNameCellIndex = findCustomNameIndexFromTable(tableRows);
  const tempIndex = isIndexFound(customNameCellIndex) ? customNameCellIndex : DEFAULT_NAME_CELL_INDEX;
  const nextIndex = tempIndex + 1;
  return tempIndex === columnIndex ? nextIndex : tempIndex;
}

function fillNameCell(tableRows, lastRowIndex, iteratedField, columnIndex, nameCellIndex) {
  nameCellIndex = isIndexFound(nameCellIndex) ? nameCellIndex : getNameCellIndexFromTable(tableRows, columnIndex);
  const nameCell = tableRows[lastRowIndex].cells[nameCellIndex];

  tableRows[lastRowIndex].cells[nameCellIndex] = {
    ...nameCell,
    value: iteratedField.label,
    kind: ComparisonTableCellKind.NAME,
    productFieldName: iteratedField.productFieldName,
  };
}

function fillValueCell(tableRows, lastRowIndex, columnIndex, iteratedField, selectedProduct) {
  const valueCell = tableRows[lastRowIndex].cells[columnIndex];

  tableRows[lastRowIndex].cells[columnIndex] = {
    ...valueCell,
    value: iteratedField.value,
    productId: selectedProduct.id,
    kind: ComparisonTableCellKind.VALUE,
    productFieldName: iteratedField.productFieldName,
  };
}

export function insertNewRowsAndFillSelectedFields(tableRows, selectedFields, columnIndex, selectedProduct) {
  for (const iteratedField of selectedFields) {
    insertEmptyRow(tableRows);

    let nameCellIndex = NO_FOUND_INDEX;
    const lastRowIndex = tableRows.length - 1;
    const isOnlyOneColumn = getCellsAmount(tableRows) === 1;

    if (isOnlyOneColumn) {
      addColumn(tableRows, 'left');
      nameCellIndex = 0;
      columnIndex = 1;
    }

    fillNameCell(tableRows, lastRowIndex, iteratedField, columnIndex, nameCellIndex);
    fillValueCell(tableRows, lastRowIndex, columnIndex, iteratedField, selectedProduct);
  }
}
