import { message } from 'antd';
import { useLocalization } from 'src/lib/hooks/useLocalization';

const BITES_NUMBER = 1024;

export function useBeforeUploadError(maxFileSize, errorText, excludedTypes = []) {
  const [messageApi, contextHolder] = message.useMessage();
  const { fileFormat } = useLocalization().translations.unique.validation;

  const beforeUpload = file => {
    const checkFileSize = file.size < BITES_NUMBER * BITES_NUMBER * maxFileSize;
    if (!checkFileSize) {
      messageApi.open({
        type: 'error',
        content: errorText,
      });
    }

    if (excludedTypes.includes(file.type)) {
      messageApi.open({
        type: 'error',
        content: fileFormat.svgError(),
      });
      return false;
    }

    return checkFileSize;
  };

  return { beforeUpload, contextHolder };
}
