/* eslint-disable no-param-reassign */
import styled from 'styled-components';
import { PriceTypes } from './PriceTypes';
import { PriceValue } from './PriceValue';
import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ConnectionLineShort } from '../../../../../../../../../../../../../ui/icons/ConnectionLineShort';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { InfoIconTextarea } from '../../../../../../../../../features/form-items/InfoIconTextarea';

export function PriceFields({ structureItemIndex, productIndex, data, allProducts, productId, structureItem }) {
  const disabled = !data.showTotalPrice;
  const { updateStructure } = useUpdateStructure();

  const { templateInfo } = useLocalization().translations.unique.formItems;

  const handleChangeFieldInfoIconValue = value => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].priceSection.infoIcon.value = value;
    });
  };
  const handleDeleteInfoIcon = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].priceSection.infoIcon = null;
    });
  };

  return (
    <Droppable droppableId={`productTotalPrice_${structureItem.id}_${productIndex}`} type='INFO_ICON'>
      {(provided, snapshot) => (
        <StyledWrapper>
          <div ref={provided.innerRef}>
            <Root>
              <PriceTypes
                structureItemIndex={structureItemIndex}
                productIndex={productIndex}
                data={data}
                allProducts={allProducts}
                disabled={disabled}
              />
              <PriceValue
                structureItemIndex={structureItemIndex}
                productIndex={productIndex}
                data={data}
                allProducts={allProducts}
                productId={productId}
                disabled={disabled}
                structureItem={structureItem}
              />
            </Root>
          </div>
          {(data.infoIcon?.infoIconName || snapshot.isDraggingOver) && (
            <InfoIconLine>
              <ConnectionLineShort stroke={data.infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
            </InfoIconLine>
          )}
          {(data.infoIcon || snapshot.isDraggingOver) && (
            <InfoIconTextarea
              valueObject={data.infoIcon || { value: '' }}
              label={templateInfo.label}
              onChange={handleChangeFieldInfoIconValue}
              onClickSuffix={handleDeleteInfoIcon}
            />
          )}
        </StyledWrapper>
      )}
    </Droppable>
  );
}

const Root = styled('div')({
  gap: '16px',
  display: 'flex',
  alignItems: 'center',

  '& > *:first-child': { flex: 477 },
  '& > *:last-child': { flex: 256 },
});

const StyledWrapper = styled('div')({
  position: 'relative',
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 19,
  right: -9,
});
