import { AppText } from 'src/ui/text/AppText';
import { OptionRow } from './OptionRow';

export function ProductOptions({ productOptions, productRow, comparisonProductOptionChange }) {
  const { productOne, productTwo } = productOptions;

  const handleOptionChange = (field, value) => {
    comparisonProductOptionChange(field, value);
  };

  return (
    <div>
      <AppText className='template_card_headline' textValue='Configuring products' />

      <OptionRow
        product={productOne}
        productName={productRow?.value?.productOne?.title}
        onOptionChange={value => {
          handleOptionChange('productOne', value);
        }}
      />
      <OptionRow
        product={productTwo}
        productName={productRow?.value?.productTwo?.title}
        onOptionChange={value => {
          handleOptionChange('productTwo', value);
        }}
      />

      <div style={{ backgroundColor: '#ECECEE', height: '1px', margin: '16px 0' }} />
    </div>
  );
}
