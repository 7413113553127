import { AppModal } from '../../../../../../../../../../../ui/AppModal';

export function ConfirmModal({ close, isOpen, submit }) {
  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: 'Are you sure?' }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, textValue: 'Delete' }}
    />
  );
}
