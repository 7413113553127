import { addRuleRequest, editRuleRequest, getRulesRequest } from 'src/lib/store/slices/rulesSlice';
import { getProductsRequest } from 'src/lib/store/slices/productsSlice';

import { types } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import { getVariablesRequest } from 'src/lib/store/slices/variablesSlice';
import { AppButton } from 'src/ui/buttons/AppButton';
import { AppInput } from 'src/ui/AppInput';
import { RuleBuilderIcon } from 'src/ui/icons/RuleBuilderIcon';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { AppText } from 'src/ui/text/AppText';
import { useCondition } from './hooks/useCondition';
import { VariablesTable } from './VariableTable';
import { Condition } from './Condition';

import styles from './BuilderPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_usePrompt } from 'react-router-dom';

const onTabClose = ev => {
  ev.preventDefault();
};

const removeUnloadEvent = () => {
  window.removeEventListener('beforeunload', onTabClose);
};

/**
 * @desc Builder
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function Builder() {
  const { selectedRule, addCondition, updateRuleName } = useCondition();
  const { id, name: ruleName, conditions, isChanged } = selectedRule;

  unstable_usePrompt({
    message: 'Are you sure you want to log out of the Rulebuilder page?\nIf you exit the Rulebuilder, all your changes will not be saved.',
    when: () => isChanged,
  });

  useEffect(() => {
    if (isChanged) {
      window.addEventListener('beforeunload', onTabClose);
    } else {
      removeUnloadEvent();
    }

    return () => {
      removeUnloadEvent();
    };
  }, [isChanged]);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const {
    tenant: { id: tenantId },
  } = useSelector(({ finders }) => finders);

  useEffect(() => {
    dispatch(getProductsRequest({ tenantId, params: { size: 99999999 } }));
    dispatch(getVariablesRequest());
    dispatch(getRulesRequest());
  }, [tenantId, dispatch]);

  // eslint-disable-next-line no-console
  console.log(selectedRule);

  const { translations } = useLocalization();
  const builderTranslations = translations.unique.pages.builder;

  const availableTypes = new Set([types.element, types.resultType, types.value, types.variable]);
  const lastItem = -1;

  const isSaveDisabled = !ruleName || conditions.some(condition => !condition.name || !availableTypes.has(condition.body.at(lastItem).type));

  const saveRule = () => {
    if (id) {
      dispatch(editRuleRequest(selectedRule));
    } else {
      dispatch(addRuleRequest(selectedRule));
    }
  };

  return (
    <div className={styles.builderPage}>
      <div className={styles.header}>
        <AppText className={styles.title} textValue={id ? builderTranslations.editRule : builderTranslations.newRule} />
        <div className={styles.buttonRow}>
          <AppButton className={styles.button} textValue={builderTranslations.addCondition} onClick={() => addCondition()} />
          <AppButton className={styles.button} textValue={builderTranslations.variablesTable} onClick={() => setIsOpen(true)} />
          <AppButton
            type='primary'
            disabled={isSaveDisabled}
            className={`${styles.button} ${styles.primary}`}
            textValue={translations.shared.generic.save}
            onClick={saveRule}
          />
        </div>
        <VariablesTable isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className={styles.container}>
        <div className={styles.ruleName}>
          <AppInput
            hasError={!ruleName}
            placeholder={builderTranslations.enterNameOfTheRule}
            onChange={event => updateRuleName(event.target.value)}
            value={ruleName}
          />
        </div>

        <div className={styles.conditions}>
          {(!conditions || conditions.length === 0) && (
            <div className={styles.empty}>
              <div className={styles.emptyIcon}>
                <RuleBuilderIcon width='48px' height='48px' />
              </div>

              <span className={styles.text}>{builderTranslations.pleaseAddNewCondition()}</span>
            </div>
          )}

          {conditions?.map((condition, index) => (
            <Condition key={condition.id} isLastItem={index === conditions.length - 1} condition={condition} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
