import { useEffect, useState } from 'react';
import lodashDebounce from 'lodash.debounce';
import { useOutletContext } from 'react-router-dom';

const DEBOUNCED_DELAY = 100; // ms

export function useWindowResize() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const outletContext = useOutletContext();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', lodashDebounce(handleWindowResize, DEBOUNCED_DELAY));

    return () => {
      window.removeEventListener('resize', lodashDebounce(handleWindowResize, DEBOUNCED_DELAY));
    };
  });

  const previewContainerWidth = outletContext?.currentResolution?.width;

  return { windowWidth: previewContainerWidth || windowWidth };
}
