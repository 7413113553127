import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { TemplateFormSection } from '../../../../../../features/TemplateFormSection';
import { ConnectionLineShort } from '../../../../../../../../../../ui/icons/ConnectionLineShort';
import { InfoIconTextarea } from '../../../../../../features/form-items/InfoIconTextarea';

export function DroppableFieldWrapper({
  itemId,
  labelField,
  labelTemplateInfo,
  type,
  valueObject,
  onChangeField,
  onChangeFieldInfoIconValue,
  onDeleteInfoIcon,
  fieldName,
  infoIcon,
  contentBlocks,
  contentBlockIndex,
}) {
  return (
    <Droppable droppableId={`${fieldName}_${itemId}_${contentBlocks}_${contentBlockIndex}`} type={type}>
      {(provided, snapshot) => (
        <Item ref={provided.innerRef}>
          <TemplateFormSection valueObject={valueObject} label={labelField} onChange={onChangeField(fieldName)} />
          {(infoIcon || snapshot.isDraggingOver) && (
            <InfoIconLine>
              <ConnectionLineShort stroke={infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
            </InfoIconLine>
          )}
          {(infoIcon || snapshot.isDraggingOver) && (
            <InfoIconTextarea
              valueObject={infoIcon || { value: '' }}
              label={labelTemplateInfo}
              onChange={onChangeFieldInfoIconValue(fieldName)}
              onClickSuffix={() => onDeleteInfoIcon(fieldName)}
            />
          )}
        </Item>
      )}
    </Droppable>
  );
}

const Item = styled('div')({
  position: 'relative',
  zIndex: 0,
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 19,
  right: -9,
});
