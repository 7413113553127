import styled from 'styled-components';
import { TitleAndButton } from './TitleAndButton';
import { FilterLabel } from './FilterLabel';

export function TableHeaderCell({
  rootProps,
  left = null,
  right = null,
  title,
  removeFilter,
  filterLabel,
  sortOrder,
  isActiveArrange,
  form,
  children,
  withArrange = true,
  getParsedItems,
  removeFilterValue,
}) {
  // const { appliedFilterPlaceholder } = useLocalization().translations.shared.arranges;
  const showFilterLabel = isActiveArrange && filterLabel;

  return (
    <Root {...rootProps}>
      {/* for collapsed button */}
      {left}

      <Content>
        <TitleAndButton withArrange={withArrange} title={title} form={form} isActiveArrange={isActiveArrange} sortOrder={sortOrder}>
          {children}
        </TitleAndButton>
        {showFilterLabel && (
          <FilterLabel filterLabel={filterLabel} removeFilter={removeFilter} getParsedItems={getParsedItems} removeFilterValue={removeFilterValue} />
        )}
      </Content>

      {right}
    </Root>
  );
}

const Root = styled('div')({
  minHeight: '78px',
  display: 'flex',
  padding: '16px 24px',
});

const Content = styled('div')({
  verticalAlign: 'top',
  display: 'flex',
  flex: '1',
  alignItems: 'flex-start',
  flexDirection: 'column',
});
