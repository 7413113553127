import { AppText } from '../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { RangeField } from './RangeField';
import { isTruthy } from '../../../../../../../../lib/util/helpers';

export function MinMax({ minTextFieldProps, maxTextFieldProps }) {
  const { shared, unique } = useLocalization().translations;
  const { templateAnswerMin, templateAnswerMax } = unique.formItems;
  const { to } = shared.generic;
  const { value: minValue, ...restMinTextFieldProps } = minTextFieldProps;
  const { value: maxValue, ...restMaxTextFieldProps } = maxTextFieldProps;

  return (
    <Root>
      <RangeField
        textFieldProps={{
          label: templateAnswerMin.label,
          value: minValue,
          ...(isTruthy(maxValue) && { max: maxValue - 1 }),
          ...restMinTextFieldProps,
        }}
      />

      <AppText strong textValue={to} style={styles.to} />

      <RangeField
        textFieldProps={{
          label: templateAnswerMax.label,
          value: maxValue,
          ...(isTruthy(minValue) && { min: minValue + 1 }),
          ...restMaxTextFieldProps,
        }}
      />
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const styles = {
  to: {
    height: '40px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignSelf: 'flex-start',
    paddingTop: '11px',
  },
};
