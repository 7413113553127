export function RightAlignIcon() {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 7C12 6.86739 11.9473 6.74021 11.8536 6.64645C11.7598 6.55268 11.6326 6.5 11.5 6.5H8.5C8.36739 6.5 8.24021 6.55268 8.14645 6.64645C8.05268 6.74021 8 6.86739 8 7C8 7.13261 8.05268 7.25979 8.14645 7.35355C8.24021 7.44732 8.36739 7.5 8.5 7.5H11.5C11.6326 7.5 11.7598 7.44732 11.8536 7.35355C11.9473 7.25979 12 7.13261 12 7ZM12 4C12 3.86739 11.9473 3.74021 11.8536 3.64645C11.7598 3.55268 11.6326 3.5 11.5 3.5H4.5C4.36739 3.5 4.24021 3.55268 4.14645 3.64645C4.05268 3.74021 4 3.86739 4 4C4 4.13261 4.05268 4.25979 4.14645 4.35355C4.24021 4.44732 4.36739 4.5 4.5 4.5H11.5C11.6326 4.5 11.7598 4.44732 11.8536 4.35355C11.9473 4.25979 12 4.13261 12 4ZM12 1C12 0.867392 11.9473 0.740215 11.8536 0.646447C11.7598 0.552679 11.6326 0.5 11.5 0.5H0.5C0.367392 0.5 0.240215 0.552679 0.146447 0.646447C0.0526785 0.740215 0 0.867392 0 1C0 1.13261 0.0526785 1.25979 0.146447 1.35355C0.240215 1.44732 0.367392 1.5 0.5 1.5H11.5C11.6326 1.5 11.7598 1.44732 11.8536 1.35355C11.9473 1.25979 12 1.13261 12 1Z'
        fill='white'
      />
    </svg>
  );
}
