import { AppFormSection } from '../../../../../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { ClientFormField } from '../../ClientForm';
import { EMAIL_SIMPLE_PATTERN, PHONE_NUMBER_PATTERN } from '../../../../../../../../../../lib/util/regexp';
import styles from './styles.module.scss';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';

export function PreviewFormGenericTopFields({ isHardcodeForHamburgerVbKontofinderFk }) {
  const { fields } = useLocalization().translations.unique.components.clientForm.form;

  return (
    <div>
      <div className={styles.row}>
        <AppFormSection
          formItemProps={{
            name: ClientFormField.FIRST_NAME,
            rules: isHardcodeForHamburgerVbKontofinderFk
              ? [{ required: true, message: handleTranslation(fields.requiredField) }]
              : [{ required: true }],
          }}
          textFieldProps={{ label: fields.firstName }}
        />
        <AppFormSection
          formItemProps={{
            name: ClientFormField.LAST_NAME,
            rules: isHardcodeForHamburgerVbKontofinderFk
              ? [{ required: true, message: handleTranslation(fields.requiredField) }]
              : [{ required: true }],
          }}
          textFieldProps={{ label: fields.lastName }}
        />
      </div>
      <div className={styles.row}>
        <AppFormSection
          formItemProps={{
            name: ClientFormField.EMAIL,
            rules: isHardcodeForHamburgerVbKontofinderFk
              ? [{ required: true, message: handleTranslation(fields.requiredField) }, { pattern: EMAIL_SIMPLE_PATTERN }]
              : [{ required: true }, { pattern: EMAIL_SIMPLE_PATTERN }],
          }}
          textFieldProps={{ label: fields.email }}
        />
        <AppFormSection
          formItemProps={{
            name: ClientFormField.PHONE_NUMBER,
            rules: [
              { required: isHardcodeForHamburgerVbKontofinderFk, message: handleTranslation(fields.requiredField) },
              { pattern: PHONE_NUMBER_PATTERN },
            ],
          }}
          textFieldProps={{ label: isHardcodeForHamburgerVbKontofinderFk ? fields.phoneNumberRequired : fields.phoneNumber }}
        />
      </div>
    </div>
  );
}
