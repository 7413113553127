/* eslint-disable no-param-reassign */
import { Product } from '../../../../../../../../FindersAndProductsPage/lib/Product';
import { isTruthy } from '../../../../../../../../../../../../../lib/util/helpers';
import { CategorySelection } from '../../../../../../../../FindersAndProductsPage/modules/categories/CategoriesTable/lib/Category';

export function setProductFields({ draft, productId, productName, structureItemIndex, productIndex, allProducts }) {
  const productItem = draft[structureItemIndex].content.products[productIndex];
  const selectedProduct = allProducts.find(item => item.id === productId);
  const isExceptional = selectedProduct[Product.IS_EXCEPTIONAL];

  setGenericFields(productItem, productName, productId, isExceptional, selectedProduct);

  if (isExceptional) {
    setExceptionalFields(productItem, productId, productName);
  } else {
    setNormalFields(productItem, selectedProduct);
  }
}

function setGenericFields(productItem, productName, productId, isExceptional, selectedProduct) {
  // set product
  productItem.productNameSection.product.value = productId;
  productItem.productNameSection.product.title = productName;

  // set isExceptional
  productItem[Product.IS_EXCEPTIONAL] = isExceptional;

  // set category
  productItem.productNameSection.categoryName = isExceptional ? undefined : selectedProduct.category?.name;

  // set price
  const { priceType } = productItem.priceSection;
  productItem.priceSection.priceValue = selectedProduct[priceType];
  productItem.priceSection.pricePerMonth = selectedProduct.pricePerMonth;
  productItem.priceSection.pricePerYear = selectedProduct.pricePerYear;
  productItem.priceSection.pricePerRequest = selectedProduct.pricePerRequest;

  // set image
  productItem.image = selectedProduct[Product.IMAGE_LINK];

  // set short name
  if (!productItem.productNameSection.shortProduct) {
    productItem.productNameSection.shortProduct = {};
  }

  productItem.productNameSection.shortProduct.value = selectedProduct.id;
  productItem.productNameSection.shortProduct.title = selectedProduct.shortName;
  productItem.hvmProductId = selectedProduct.hvmProductId;
}

function setExceptionalFields(productItem, productId, productName) {
  // set product
  productItem.productNameSection.product.value = productId;
  productItem.productNameSection.product.title = productName;

  // set category
  productItem.productNameSection.categoryName = undefined;

  // set price
  productItem.priceSection.priceValue = undefined;
  productItem.priceSection.pricePerMonth = undefined;
  productItem.priceSection.pricePerYear = undefined;
  productItem.priceSection.pricePerRequest = undefined;

  productItem.productNameSection.multiProduct = false;

  // set currency
  productItem.priceSection.currency = false;

  // set link
  productItem.productPageLink = false;
}

function setNormalFields(productItem, selectedProduct) {
  productItem.productNameSection.multiProduct = isTruthy(selectedProduct.category?.multiProduct)
    ? selectedProduct.category?.multiProduct
    : CategorySelection.MULTIPLE_SELECTION;

  // set currency
  productItem.priceSection.currency = selectedProduct[Product.CURRENCY];

  // set link
  productItem.productPageLink = selectedProduct[Product.PRODUCT_PAGE_LINK];
}
