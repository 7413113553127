import { useCallback } from 'react';
import { rewriteChapterSelectedProducts } from '../../../../../../utils/end-user-flow-helpers';

// eslint-disable-next-line i18n-text/no-en
const NO_CARD = 'Keine girocard';

export function useOnUpdateCountersAllChapters(setPagesFlow) {
  return useCallback(
    cards => {
      const resultProds = cards;
      const unselectedIdsArr = cards.map(card => card.id);

      setPagesFlow(prev => ({
        ...prev,
        allChapters: rewriteChapterSelectedProducts(prev?.allChapters, prev?.currentChapterId, resultProds, unselectedIdsArr),
      }));
    },
    [setPagesFlow]
  );
}

export function useOnUpdateCounter(setPagesFlow, structureItemId, productObject) {
  return useCallback(
    (value, card) => {
      if (card?.productNameSection?.product?.title === NO_CARD) {
        return;
      }

      const resultProds = value === 0 ? [] : [{ ...card, counterSection: { ...card.counterSection, value } }];
      const unselectedIdsArr = [card?.id];

      setPagesFlow(prev => ({
        ...prev,
        allChapters: rewriteChapterSelectedProducts(prev?.allChapters, prev?.currentChapterId, resultProds, unselectedIdsArr),
        allPages: getUpdatedAllPages(prev, structureItemId, value, card),
        currentFlowElement: productObject,
      }));
    },
    [productObject, setPagesFlow, structureItemId]
  );
}

function getUpdatedAllPages(prevPagesFlow, structureItemId, value, card) {
  return prevPagesFlow.allPages.map(page =>
    page.id === prevPagesFlow.currentPageId
      ? {
          ...page,
          elements: page.elements.map(el =>
            el.id === structureItemId
              ? {
                  ...el,
                  content: {
                    ...el.content,
                    products: el.content.products.map(product =>
                      product?.id === card?.id
                        ? {
                            ...product,
                            counterSection: {
                              ...product.counterSection,
                              value,
                            },
                          }
                        : product
                    ),
                  },
                }
              : el
          ),
        }
      : page
  );
}
