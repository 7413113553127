import React from 'react';
import { QuestionnaireCheckbox } from '../../../../../../features/QuestionnaireCheckbox';
import { useUpdateStructure } from '../../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import lodashSet from 'lodash.set';
import lodashGet from 'lodash.get';
import styled from 'styled-components';

export function SettingsFields({ data, structureItemIndex }) {
  const { printPdfSettings } = useLocalization().translations.unique.pages.questionnaire.content;
  const { updateStructure } = useUpdateStructure();
  const contentPath = `[${structureItemIndex}].content`;

  const toggleCheckbox = (event, fieldName) => {
    updateStructure(draft => {
      const content = lodashGet(draft, contentPath);
      lodashSet(draft, `${contentPath}.pdfSettings`, { ...content?.pdfSettings, [fieldName]: !data?.[fieldName] });
    });
  };

  const onToggleShowDate = event => toggleCheckbox(event, 'showDate');
  const onToggleShowLink = event => toggleCheckbox(event, 'showLink');

  return (
    <Root>
      <QuestionnaireCheckbox onChange={onToggleShowDate} checked={data?.showDate} textValue={printPdfSettings.showDate} />
      <QuestionnaireCheckbox onChange={onToggleShowLink} checked={data?.showLink} textValue={printPdfSettings.showLink} />
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
});
