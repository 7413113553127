import styled from 'styled-components';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../../../../../../../../../ui/icons/ChevronDownIcon';

export function CellPopupDropdownIcon({ cx, ...rest }) {
  return <Root {...rest}>{rest.selectProps.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Root>;
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  'svg, svg  *': {
    fill: '#fff',
  },
});
