import { Button } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function FlowButton({ onClick, children, analyticsClass, ...props }) {
  return (
    <Button type='primary' {...props} onClick={onClick} className={classNames(styles.button, analyticsClass)}>
      <span className={analyticsClass}>{children}</span>
    </Button>
  );
}
