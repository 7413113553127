import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { Products } from './components/Products';
import { Buttons } from './components/Buttons';
import { TotalPrice } from './components/TotalPrice';
import { InfoIcon } from '../../../../../../../../../../../../ui/icons/InfoIcon';

const MAX_TENANT_PRODUCTS_FOR_LAYOUT = 5;

/**
 * @desc Layout for Result template
 * @param {Object} selectedElement
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ResultLayout({ selectedElement, style, stylesConfiguration }) {
  const { questionnaireStructure, tenantProducts } = useSelector(({ finders }) => finders);

  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, buttonsStyle = {} } = style;

  const element = selectedElement ? questionnaireStructure.find(elem => elem.id === selectedElement.id) : {};

  const {
    content: { headline, subline, productInformation, buttons },
  } = element;

  const { fonts, colors } = stylesConfiguration;

  const { body, heading2 } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const totalPrice = tenantProducts.reduce((acc, curr) => acc + Number(curr.pricePerMonth), 0);

  const tenantProductsForLayout = tenantProducts.slice(0, MAX_TENANT_PRODUCTS_FOR_LAYOUT);

  return (
    <section
      style={{
        width: 1920,
        height: 813,
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 452px',
          ...rootStyle,
        }}
      >
        {headline && (
          <h2
            style={{
              ...fonts.headlineSecond,
              marginBottom: 15,
              marginTop: 32,
              overflow: 'hidden',
              wordBreak: 'break-all',
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.headlineSecond.fontSize,
              fontFamily: fonts.headlineSecond.font,
              fontWeight: fonts.headlineSecond.fontWeight,
              color: colors[fonts.headlineSecond.colorVariant],
              ...headlineStyle,
            }}
          >
            {headline.value || heading2()}
            {headline?.infoIcon?.value && (
              <span style={{ marginLeft: 5 }}>
                <InfoIcon />
              </span>
            )}
          </h2>
        )}
        {subline && (
          <p
            style={{
              marginBottom: 35,
              overflow: 'hidden',
              wordBreak: 'break-all',
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: 700,
              color: colors[fonts.body.colorVariant],
              ...sublineStyle,
            }}
          >
            {subline.value || body()}
            {subline?.infoIcon?.value && (
              <span style={{ position: 'relative' }}>
                <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 1 }} />
              </span>
            )}
          </p>
        )}
        <Products tenantProducts={tenantProductsForLayout} fonts={fonts} colors={colors} productInformation={productInformation} />
        <TotalPrice productInformation={productInformation} totalPrice={totalPrice} fonts={fonts} colors={colors} />
        <Buttons buttons={buttons} buttonsStyle={buttonsStyle} colors={colors} />
      </div>
    </section>
  );
}
