export function SquareIcon(props) {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect y='0.5' width='16' height='16' rx='4' fill='white' />
      <g clipPath='url(#clip0_10098_232468)'>
        <path
          d='M14 1.5C14.2652 1.5 14.5196 1.60536 14.7071 1.79289C14.8946 1.98043 15 2.23478 15 2.5V14.5C15 14.7652 14.8946 15.0196 14.7071 15.2071C14.5196 15.3946 14.2652 15.5 14 15.5H2C1.73478 15.5 1.48043 15.3946 1.29289 15.2071C1.10536 15.0196 1 14.7652 1 14.5V2.5C1 2.23478 1.10536 1.98043 1.29289 1.79289C1.48043 1.60536 1.73478 1.5 2 1.5H14ZM2 0.5C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5L0 14.5C0 15.0304 0.210714 15.5391 0.585786 15.9142C0.960859 16.2893 1.46957 16.5 2 16.5H14C14.5304 16.5 15.0391 16.2893 15.4142 15.9142C15.7893 15.5391 16 15.0304 16 14.5V2.5C16 1.96957 15.7893 1.46086 15.4142 1.08579C15.0391 0.710714 14.5304 0.5 14 0.5H2Z'
          fill='#191919'
        />
      </g>
      <defs>
        <clipPath id='clip0_10098_232468'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
