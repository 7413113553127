import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../../../../../../../../../lib/util/router/router-paths';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import styles from '../../../../../../../../utils/styles/table.module.sass';
import { useFinderSearchDataColumn } from '../../components/search-data';
import { Finder } from '../../lib/Finder';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function useFinderNameColumn() {
  const { tenant } = useSelector(({ finders }) => finders);
  const { finderName: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderSearchDataColumn({
    title,
    arrangedId: Finder.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    headerCellProps: {
      rootProps: { className: styles.borderRight },
    },
    columnProps: {
      width: 189,
      className: styles.borderRight,
      render: (text, row) => {
        return (
          <Link to={`${ROUTER_PATHS.settings(row.id)}?X-TENANT=${tenant.id}`}>
            <AppText className='primary_color'>{text}</AppText>
          </Link>
        );
      },
    },
  });
}
