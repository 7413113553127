import { FinderSearchDataHeaderCell } from './FinderSearchDataHeaderCell';

export function useFinderSearchDataColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <FinderSearchDataHeaderCell {...rest} />,
  };
}
