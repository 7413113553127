export function Score({ product, color }) {
  const numberOfPoints = 3;
  return (
    <div style={{ flex: 3, display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
      {Array.from({ length: numberOfPoints }, (value, index) => 1 + index).map(point => (
        <div
          key={point}
          style={{
            width: '16px',
            height: '16px',
            borderRadius: '100%',
            backgroundColor: point <= product ? color : 'transparent',
            border: `1px solid ${color}`,
          }}
        />
      ))}
    </div>
  );
}
