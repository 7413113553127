import { v4 as uuidv4 } from 'uuid';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../lib/models/Questionnaire';

export function generateGenericProductItemStructure() {
  return {
    id: uuidv4(),
    productNameSection: {
      showPicture: true,
      product: {
        value: null, // id
        title: null, // name
      },
      shortProduct: {
        value: null, // id
        title: null, // name
      },
      categoryName: null,
      multiProduct: null,
      subline: {
        value: '',
        infoIcon: null,
      },
      isShortProductName: false,
    },
    bulletPointsSection: {
      rightColumnType: ProductRightBulletPointColumnType.TEXT,
      bulletPoints: [],
    },
    bulletPointsForChapterSection: {
      show: false,
      rightColumnType: ProductRightBulletPointColumnType.TEXT,
      bulletPoints: [],
    },
    priceSection: {
      showTotalPrice: true,
      priceType: 'pricePerMonth',
      priceValue: null,
      currency: null,
    },
    productPageLink: '',
    image: null,
  };
}
