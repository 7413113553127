import { CardSection } from '../../../../../../../ui/CardSection';
import { ProductTopSectionBasicHeader } from '../../components/ProductTopSection/ProductTopSectionBasicHeader';
import { TextFieldWithInfoIcon } from '../../../../../../../features/TextFieldWithInfoIcon';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';

export function ProductTopSection({ structureItem, structureItemIndex }) {
  const { templateHeadline } = useLocalization().translations.unique.formItems;

  return (
    <CardSection withBorderBottom={false}>
      <ProductTopSectionBasicHeader structureItem={structureItem} structureItemIndex={structureItemIndex} />
      <TextFieldWithInfoIcon
        label={templateHeadline.label}
        data={structureItem.content.headline}
        dataPath={`[${structureItemIndex}].content.headline`}
      />
    </CardSection>
  );
}
