/* eslint-disable react/no-danger,max-lines-per-function,i18next/no-literal-string */
import { ConfigProvider, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';
import { DynamicContactFormRow } from '../../../../../../PreviewPage/components/PreviewDynamicContactForm/components/DynamicContactFormRow';
import { end_user_de_validateMessages } from '../../../../../../../../../../../lib/localization/DE/de_validation';
import { StyledFormWrapper } from '../../../../../../PreviewPage/components/PreviewForm/components/PreviewFormWrapper/StyledFormWrapper';
import { useWindowResize } from '../../../../../../../utils/hooks/useWindowResize';

/**
 * @desc Layout for Contact Form template
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @param {Object} selectedElement
 * @returns {JSX.Element}
 */
export function ContactFormLayout({ style, stylesConfiguration, selectedElement }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, bodyStyle = {}, formBlockStyle = {}, additionalInfoBlockStyle = {} } = style;
  const element = selectedElement ? questionnaireStructure.find(elem => elem.id === selectedElement.id) : {};

  const {
    content: {
      header: { headline, subline, body },
      formRowList,
      additionalText,
    },
  } = element;
  const { heading1, heading2, bodyRegular } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const { windowWidth } = useWindowResize();
  const { fonts, colors } = stylesConfiguration;

  const createSideText = () => {
    return { __html: additionalText.sideText };
  };

  return (
    <section
      style={{
        width: 1920,
        height: 813,
        display: 'flex',
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
    >
      <div style={{ margin: '0 453px', width: '100%', ...rootStyle }}>
        <div style={{ maxWidth: 586, width: '100%' }}>
          {headline && (
            <Typography.Paragraph
              style={{
                margin: '32px 0 10px 0',
                fontSize: fonts.headlineSecond.fontSize,
                fontFamily: fonts.headlineSecond.font,
                fontWeight: fonts.headlineSecond.fontWeight,
                color: colors[fonts.headlineSecond.colorVariant],
                ...headlineStyle,
              }}
            >
              {headline.value || heading1()}
              {headline?.infoIcon?.value && (
                <span style={{ marginLeft: 5 }}>
                  <InfoIcon />
                </span>
              )}
            </Typography.Paragraph>
          )}
          {subline && (
            <Typography.Paragraph
              style={{
                marginBottom: 24,
                lineHeight: '24px',
                fontSize: fonts.headlineThird.fontSize,
                fontFamily: fonts.headlineThird.font,
                fontWeight: fonts.headlineThird.fontWeight,
                color: colors[fonts.headlineThird.colorVariant],
                ...sublineStyle,
              }}
            >
              {subline.value || heading2()}
              {subline?.infoIcon?.value && (
                <span style={{ position: 'relative' }}>
                  <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 5 }} />
                </span>
              )}
            </Typography.Paragraph>
          )}
          {body && (
            <Typography.Paragraph
              style={{
                marginBottom: 24,
                lineHeight: '19px',
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
                ...bodyStyle,
              }}
            >
              {body.value || bodyRegular()}
              {body?.infoIcon?.value && (
                <span style={{ position: 'relative' }}>
                  <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 1 }} />
                </span>
              )}
            </Typography.Paragraph>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: 586, ...formBlockStyle }}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: colors.primaryColor,
                  borderRadius: 4,
                  fontFamily: fonts.body.font,
                },
              }}
              form={{ validateMessages: end_user_de_validateMessages }}
            >
              <StyledFormWrapper windowWidth={windowWidth} colors={colors}>
                {formRowList.map((rowObj, index) => (
                  <DynamicContactFormRow
                    key={rowObj.id}
                    rowObject={rowObj}
                    rowIndex={index}
                    fonts={fonts}
                    colors={colors}
                    formList={formRowList}
                    disabled
                  />
                ))}
              </StyledFormWrapper>
            </ConfigProvider>
            <Typography.Text
              style={{
                margin: '32px 0 10px 0',
                fontSize: fonts.subText.fontSize,
                fontFamily: fonts.subText.font,
                fontWeight: fonts.subText.fontWeight,
                color: colors[fonts.subText.colorVariant],
              }}
            >
              *) Pflichtangabe
            </Typography.Text>
          </div>
          <div
            style={{
              width: 328,
              minHeight: 420,
              backgroundColor: '#FFFFFF',
              padding: '24px 24px 35px',
              height: 'fit-content',
              ...additionalInfoBlockStyle,
            }}
          >
            {additionalText.headline && (
              <Typography.Paragraph
                style={{
                  marginBottom: 24,
                  lineHeight: '24px',
                  fontSize: fonts.headlineThird.fontSize,
                  fontFamily: fonts.headlineThird.font,
                  fontWeight: fonts.headlineThird.fontWeight,
                  color: colors[fonts.headlineThird.colorVariant],
                  ...sublineStyle,
                }}
              >
                {additionalText.headline.value || heading2()}
                {subline?.infoIcon?.value && (
                  <span style={{ position: 'relative' }}>
                    <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 5 }} />
                  </span>
                )}
              </Typography.Paragraph>
            )}
            <div
              style={{
                lineHeight: '20px',
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
              }}
              dangerouslySetInnerHTML={createSideText()}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
