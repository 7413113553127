import { useProductSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';
import { AppText } from '../../../../../../../../ui/text/AppText';

export function useProductSublineColumn() {
  const { subline: title } = useLocalization().translations.unique.pages.products.table.columns;

  return useProductSearchDataColumn({
    title,
    arrangedId: Product.SUBLINE,
    searchFieldName: 'searchBySubLine',
    filterFieldName: 'equalBySubLine',
    columnsProps: {
      width: 200,
      render: data => <AppText>{data || '-'}</AppText>,
    },
  });
}
