export function QuestionMark({ fill = '#5C5C6E', width = '17', height = '16' }) {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.02734 15C6.17083 15 4.39035 14.2625 3.0776 12.9497C1.76484 11.637 1.02734 9.85652 1.02734 8C1.02734 6.14348 1.76484 4.36301 3.0776 3.05025C4.39035 1.7375 6.17083 1 8.02734 1C9.88386 1 11.6643 1.7375 12.9771 3.05025C14.2898 4.36301 15.0273 6.14348 15.0273 8C15.0273 9.85652 14.2898 11.637 12.9771 12.9497C11.6643 14.2625 9.88386 15 8.02734 15ZM8.02734 16C10.1491 16 12.1839 15.1571 13.6842 13.6569C15.1845 12.1566 16.0273 10.1217 16.0273 8C16.0273 5.87827 15.1845 3.84344 13.6842 2.34315C12.1839 0.842855 10.1491 0 8.02734 0C5.90561 0 3.87078 0.842855 2.37049 2.34315C0.870199 3.84344 0.0273438 5.87827 0.0273438 8C0.0273438 10.1217 0.870199 12.1566 2.37049 13.6569C3.87078 15.1571 5.90561 16 8.02734 16Z'
        fill={fill}
      />
      <path
        d='M5.28146 5.786C5.28009 5.81829 5.28535 5.85053 5.29689 5.88072C5.30844 5.91091 5.32604 5.93841 5.34862 5.96155C5.37119 5.98468 5.39826 6.00296 5.42815 6.01524C5.45805 6.02753 5.49014 6.03357 5.52246 6.033H6.34746C6.48546 6.033 6.59546 5.92 6.61346 5.783C6.70346 5.127 7.15346 4.649 7.95546 4.649C8.64146 4.649 9.26946 4.992 9.26946 5.817C9.26946 6.452 8.89546 6.744 8.30446 7.188C7.63146 7.677 7.09846 8.248 7.13646 9.175L7.13946 9.392C7.14051 9.45761 7.16731 9.52017 7.21409 9.5662C7.26086 9.61222 7.32385 9.63801 7.38946 9.638H8.20046C8.26677 9.638 8.33036 9.61166 8.37724 9.56478C8.42412 9.51789 8.45046 9.4543 8.45046 9.388V9.283C8.45046 8.565 8.72346 8.356 9.46046 7.797C10.0695 7.334 10.7045 6.82 10.7045 5.741C10.7045 4.23 9.42846 3.5 8.03146 3.5C6.76446 3.5 5.37646 4.09 5.28146 5.786ZM6.83846 11.549C6.83846 12.082 7.26346 12.476 7.84846 12.476C8.45746 12.476 8.87646 12.082 8.87646 11.549C8.87646 10.997 8.45646 10.609 7.84746 10.609C7.26346 10.609 6.83846 10.997 6.83846 11.549Z'
        fill={fill}
      />
    </svg>
  );
}
