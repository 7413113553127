import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../lib/models/Form';
import { handleTranslation } from '../../../../../../lib/util/helpers';

export function SubdomainFormItem() {
  const { label, errors } = useLocalization().translations.unique.formItems.subdomain;

  return (
    <AppFormSection
      formItemProps={{
        name: FormItemName.SUBDOMAIN,
        rules: [
          { required: true },
          {
            pattern: /^[\w-]+$/,
            message: handleTranslation(errors.invalid),
          },
          { max: 250 },
        ],
      }}
      textFieldProps={{
        label,
      }}
    />
  );
}
