// @ts-check

/**
 * @typedef { import('typesafe-i18n').FormattersInitializer<Locales, Formatters> } FormattersInitializer
 * @typedef { import('./i18n-types').Locales } Locales
 * @typedef { import('./i18n-types').Formatters } Formatters
 */

/**
 * @param { Locales } _locale
 * @return { Formatters }
 */

export const initFormatters = _locale => {
  /** @type { Formatters } */
  return {
    // add your formatter functions here
  };
};
