import styled from 'styled-components';
import { Layout } from 'antd';
import { AppBox } from './boxes/AppBox';

const { Content } = Layout;

const StyledContent = styled(Content)({
  padding: '24px 32px',
});

export function AppContent({ as, ...rest }) {
  return <AppBox {...rest} as={StyledContent} />;
}
