import { put, take } from 'redux-saga/effects';
import { showSuccessNotification, showErrorNotification } from '../slices/notificationsSlice';

/**
 * @desc function generator - observer for all notifications
 */
export function* watchErrorHanding() {
  while (true) {
    const action = yield take(['*']);
    const { payload } = action;
    if (payload?.success) {
      yield put(showSuccessNotification(payload.success));
    }
    if (payload?.error) {
      yield put(showErrorNotification(payload.error));
    }
  }
}
