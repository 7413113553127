import { useSelector } from 'react-redux';
import { ContentTop } from '../ContentTop';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Explanation } from '../Explanation';
import { FlowProcess } from './FlowProcess';

/**
 * @desc Renders content related to the flow of a questionnaire. Displays either the flow process or an explanation based on the availability of questionnaire structure.
 * @returns {JSX.Element} The rendered FlowContent component.
 */
export function FlowContent() {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { title, tooltipText, explanation } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <ContentTop title={title} tooltipText={tooltipText} />
      {questionnaireStructure ? <FlowProcess /> : <Explanation descriptionText={explanation.description} />}
    </div>
  );
}
