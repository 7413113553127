import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedRule } from 'src/lib/store/slices/rulesSlice';

/**
 * @desc Custom hook for managing actions and updating the selected rule.
 * @returns {Object} - An object containing functions to add elements to conditions.
 */
export const useAction = () => {
  const dispatch = useDispatch();

  const { selectedRule } = useSelector(({ rules }) => rules);

  const addElement = (id, element) => {
    const updatedRule = {
      ...selectedRule,
      conditions: selectedRule.conditions.map(condition => {
        if (condition.id === id) {
          return {
            ...condition,
            body: [
              ...condition.body,
              {
                ...element,
                id: uuidv4(),
              },
            ],
          };
        }

        return condition;
      }),
    };

    dispatch(updateSelectedRule(updatedRule));
  };

  return { addElement };
};
