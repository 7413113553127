/* eslint-disable max-lines */
import { Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { isLength, getBackgroundUrl } from '../../../../../../../../../../../lib/util/helpers';
import { TemplateTypes } from '../../../../../../../../../../../lib/models/TemplateTypes';
import { NotFirstChapter } from './components/NotFirstChapter';
import { CompletedChapterElement } from './components/CompletedChapterElement';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';
import { CompletedChapters } from './components/CompletedChapters';

/**
 * @desc Layout for Chapter template
 * @param {Object} style
 * @param {Object} selectedElement
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ChapterLayout({ style, stylesConfiguration, selectedElement }) {
  const {
    finder: {
      finderSettings: { media: mediaOptions },
    },
    questionnaireStructure,
  } = useSelector(({ finders }) => finders);

  const [completed, setCompleted] = useState(false);

  const allChapters = questionnaireStructure.filter(el => el.templateType === TemplateTypes.CHAPTER && !el.content.completedChaptersButtons);
  const firstChapter = isLength(allChapters) && allChapters[0] ? allChapters[0] : {};
  const otherChapters = isLength(allChapters) ? allChapters.slice(1) : [];

  const completedChapters = questionnaireStructure.filter(el => el.templateType === TemplateTypes.CHAPTER && el.content.completedChaptersButtons);

  const { content: { headline, subline, chapterButton, selectBackgroundImageActive } = {} } = firstChapter;

  const {
    rootStyle = {},
    firstChapterStyle = {},
    firstChapterHeadlineStyle = {},
    firstChapterSublineStyle = {},
    firstChapterButtonStyle = {},
    chapterCompletedCheckStyle = {},
  } = style;
  const { fonts, colors } = stylesConfiguration;
  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImageActive, mediaOptions);

  const {
    heading1,
    bodyRegular,
    button: buttonDefaultText,
  } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const showCompletedChapters = completedChapters.some(el => el.id === selectedElement.id);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <section
      style={{
        width: 1920,
        height: 813,
        display: 'flex',
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
      onMouseEnter={() => setCompleted(true)}
      onMouseOut={() => setCompleted(false)}
    >
      <div style={{ margin: '0 453px', width: '100%', ...rootStyle }}>
        {Object.keys(firstChapter).length > 0 && (
          <div
            style={{
              width: '100%',
              minHeight: 353,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url('${backgroundImageUrl}')`,
              margin: '50px 0 32px 0',
              display: 'flex',
              padding: '20px',
              border: `3px solid ${completed ? colors.primaryColor : 'transparent'}`,
              position: 'relative',
            }}
          >
            {completed && <CompletedChapterElement colors={colors} chapterCompletedCheckStyle={chapterCompletedCheckStyle} />}
            <div
              style={{
                width: 496,
                height: 'fit-content',
                background: colors.backgroundColor,
                padding: 24,
                ...firstChapterStyle,
              }}
            >
              {headline && (
                <div style={{ display: 'flex' }}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      marginBottom: 10,
                      fontSize: fonts.headlineFirst.fontSize,
                      fontFamily: fonts.headlineFirst.font,
                      fontWeight: fonts.headlineFirst.fontWeight,
                      color: colors[fonts.headlineFirst.colorVariant],
                      ...firstChapterHeadlineStyle,
                      maxWidth: '95%',
                    }}
                  >
                    {headline.value || heading1()}
                  </Typography.Paragraph>
                  {headline?.infoIcon?.value && (
                    <span style={{ position: 'relative' }}>
                      <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 8 }} />
                    </span>
                  )}
                </div>
              )}
              {subline && (
                <div style={{ display: 'flex' }}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      marginBottom: 24,
                      lineHeight: '24px',
                      fontSize: fonts.body.fontSize,
                      fontFamily: fonts.body.font,
                      fontWeight: fonts.body.fontWeight,
                      color: colors[fonts.body.colorVariant],
                      ...firstChapterSublineStyle,
                      maxWidth: '95%',
                    }}
                  >
                    {subline.value || bodyRegular()}
                  </Typography.Paragraph>
                  {subline?.infoIcon?.value && (
                    <span style={{ position: 'relative' }}>
                      <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 3 }} />
                    </span>
                  )}
                </div>
              )}
              {chapterButton && (
                <button
                  type='button'
                  style={{
                    width: 300,
                    height: 48,
                    background: colors.primaryColor,
                    marginBottom: 10,
                    border: 'none',
                    borderRadius: 24,
                    color: '#FFFFFF',
                    cursor: 'pointer',
                    ...firstChapterButtonStyle,
                    position: 'relative',
                  }}
                >
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      margin: '0 auto',
                      maxWidth: '80%',
                      marginBottom: 0,
                      color: 'inherit',
                    }}
                  >
                    {chapterButton.value || buttonDefaultText()}
                  </Typography.Paragraph>
                  {chapterButton?.infoIcon?.value && (
                    <span style={{ position: 'absolute', right: 15, top: 8 }}>
                      <InfoIcon />
                    </span>
                  )}
                </button>
              )}
            </div>
            {selectBackgroundImageActive?.infoIcon?.value && (
              <span style={{ position: 'absolute', right: 25, top: 20 }}>
                <InfoIcon />
              </span>
            )}
          </div>
        )}
        <div
          style={{
            margin: '0 0 32px 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {otherChapters.map(chapter => (
            <NotFirstChapter
              key={chapter.id}
              chapter={chapter}
              chaptersLength={otherChapters.length}
              style={style}
              stylesConfiguration={stylesConfiguration}
              completed={completed}
            />
          ))}
        </div>
        {showCompletedChapters && <CompletedChapters completedChapters={completedChapters} colors={colors} />}
      </div>
    </section>
  );
}
