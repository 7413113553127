export const FormItemName = {
  CONTRACT_DATES: 'contractDates',
  ADMIN_CONTACT: 'contact',
  COMMENTS: 'comment',
  FINDER_NAME: 'name',
  DOMAINS: 'domains',
  DOMAIN: 'domain',
  SUBDOMAIN: 'subdomain',
  TENANT_NAME: 'tenant',
  USERS: 'users',
  FULL_NAME: 'fullName',
  USERNAME: 'username',
  EMAIL: 'email',
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',
  RIGHTS: 'rights',
  LANGUAGE: 'language',
  TENANT_LIST: 'tenants',
  INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS: 'allAdminTenants',
  TENANT: 'tenant',
  LINK: 'link',
  CURRENCY: 'link',
  THEME_NAME: 'name',
  THEME_STATUS: 'activated',
  CREATION_DATE: 'createdDate',
  META_TITLE: 'title',
  META_DESCRIPTION: 'description',
  MAIL_SERVER: 'usedDefaultServer',
  MAIL_SERVER_CONTACT: 'contactEmailForForms',
  MAIL_SERVER_SENDER: 'senderEmail',
  MAIL_SERVER_HOST: 'host',
  MAIL_SERVER_PORT: 'port',
  MAIL_SERVER_USER_NAME: 'userName',
  MAIL_SERVER_PASSWORD: 'password',
  DATA_PROTECTION: 'notificationEnabled',
  DATA_PROTECTION_HEAD: 'headline',
  DATA_PROTECTION_SUB: 'subLine',
  DATA_PROTECTION_CONTENT: 'content',
  THEME_ID: 'themeId',
  HEADER_HEAD: 'headerHeadline',
  HEADER_SUB: 'headerSubLine',
  PRIMARY_BUTTON: 'primaryButton',
  SECONDARY_BUTTON: 'secondaryButton',
  BUTTON_LINKS: 'buttonLinks',
  LINK_NAME: 'linkName',
  LINK_URL: 'linkUrl',
  BANK_NAME: 'bankName',
  BANK_ADDRESS: 'bankAddress',
  ADDRESS_LINE_TWO: 'addressLine2',
  PLZ: 'plz',
  BANK_CITY: 'bankCity',
  BANK_EMAIL: 'bankEmail',
  BANK_PHONE: 'bankPhone',
  FAVICON_TITLE_TITLE: 'title',
  TRACKING: 'usedTracking',
  TRACKING_SOFTWARE: 'trackingSoftware',
  TRACKER_URL: 'trackerUrl',
  TRACKING_TRACKER_SIDE_ID: 'trackerSiteId',
  RECAPTCHA_KEY: 'reCaptchaKey',
  RECAPTCHA_SECRET: 'reCaptchaSecret',
  FINDERS_NAMES: 'findersNames',
};
