export const ViewMode = {
  ENABLED: true,
  DISABLED: false,
  NOT_DEFINED: null,
};

export const UpdateStructureType = {
  NAVIGATOR_TOGGLE: 'navigatorToggle',
};

export const DroppableIdField = {
  KIND: 'kind',
  PATH: 'path',
  TYPE: 'type',
};

export const DroppableIdKind = {
  WITH_PATH: 'withPath',
};

export const DroppableIdType = {
  COMPARISON_TABLE_CELL: 'COMPARISON_TABLE_CELL',
};

export const ResultType = {
  COMPOSER: 'COMPOSER',
  NAVIGATOR: 'NAVIGATOR',
  INFO_ICON: 'INFO_ICON',
  BULLET_POINT: 'BULLET_POINT',
  QUESTION_ANSWERS: 'QUESTION_ANSWERS',
  COMPOSER_BUTTON: 'COMPOSER_BUTTON',
  CONTACT_FORM_ROW: 'CONTACT_FORM_ROW',
  CONTACT_FORM_ROW_FIELD: 'CONTACT_FORM_ROW_FIELD',
  CONTACT_FORM_ROW_FIELD_OPTION: 'CONTACT_FORM_ROW_FIELD_OPTION',
  COMPARISON_DETAIL_TABLE_TOPIC: 'COMPARISON_DETAIL_TABLE_TOPIC',
};

export const ProductRightBulletPointColumnType = {
  ICON: 'icon',
  TEXT: 'text',
};

export const QuestionnaireVersion = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const ProductKind = {
  ONE_COUNTER: 'ONE_COUNTER',
  MULTIPLE_COUNTERS: 'MULTIPLE_COUNTERS',
};

export const LayoutDesign = {
  CENTER_MODE_CAROUSEL: 'CENTER_MODE_CAROUSEL',
  NORMAL_MODE_CAROUSEL: 'NORMAL_MODE_CAROUSEL',
};

const DEFAULT_SHOW_SAVINGS_VALUE = false;

export const MAX_COUNTER_VALUE = 100000000;
export const DEFAULT_MAX_COUNTER_VALUE = 1000;

export const PRODUCT_MULTIPLE_COUNTERS_TEMPLATE_DEFAULT_CONTENT = {
  kind: ProductKind.MULTIPLE_COUNTERS,
  headline: {
    value: '',
    infoIcon: null,
  },
  productSelection: true,
  showSavings: DEFAULT_SHOW_SAVINGS_VALUE,
  bulletPointIcon: {
    value: null,
    svgContent: '',
  },
  bulletPointIconColor: null,
  products: [],
};

export const PRODUCT_ONE_COUNTER_TEMPLATE_DEFAULT_CONTENT = {
  kind: ProductKind.ONE_COUNTER,
  headline: {
    value: '',
    infoIcon: null,
  },
  bulletPointIcon: {
    value: null,
    svgContent: '',
  },
  showSavings: DEFAULT_SHOW_SAVINGS_VALUE,
  bulletPointIconColor: null,
  products: [],
  counter: {
    headline: {
      value: '',
      infoIcon: null,
    },
    min: 1,
    max: MAX_COUNTER_VALUE,
  },
};

export const CONTACT_FORM_DEFAULT_CONTENT = {
  header: {
    headline: {
      value: '',
      infoIcon: null,
    },
    subline: {
      value: '',
      infoIcon: null,
    },
    body: {
      value: '',
      infoIcon: null,
    },
  },
  formRowList: [],
  additionalText: {
    headline: {
      value: '',
      infoIcon: null,
    },
    sideText: '',
  },
};

export const FlowOperation = {
  GO_TO_FORM: 'GO_TO_FORM',
  PRINT: 'PRINT',
  LINK: 'LINK',
  NEXT: 'NEXT',
  FORM_THEN_LINK: 'FORM_THEN_LINK',
  GO_TO_ELEMENT: 'GO_TO_ELEMENT',
  HVM: 'HVM',
};

export const ContactFormField = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  DATE: 'DATE',
  TIME: 'TIME',
  RADIO: 'RADIO',
  RADIO_WITH_ACTION: 'RADIO_WITH_ACTION',
  CHECKBOX: 'CHECKBOX',
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_WITH_ACTION: 'DROPDOWN_WITH_ACTION',
};

export const ContactFormAction = {
  CREATE_NEW_ROWS: 'CREATE_NEW_ROWS',
  NONE: 'NONE',
};

export const withOptionsContactFormFields = [
  ContactFormField.CHECKBOX,
  ContactFormField.RADIO,
  ContactFormField.DROPDOWN,
  ContactFormField.RADIO_WITH_ACTION,
  ContactFormField.DROPDOWN_WITH_ACTION,
];
export const withActionContactFormFields = [ContactFormField.RADIO_WITH_ACTION, ContactFormField.DROPDOWN_WITH_ACTION];

export const ResultKind = {
  DEPOT: 'DEPOT',
};

export const PDF_TYPE = {
  DEPOT: 'DEPOT',
  RESULT: 'RESULT',
};
