import styles from './Actions.module.scss';

import { useAction } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useAction';

/**
 * @desc Action
 * @param {Object} item
 * @param {String} type
 * @param {String | Number} conditionId
 * @returns {JSX.Element}
 */
export function Action({ item, type, conditionId }) {
  const { addElement } = useAction();

  const { name: actionName, disabled, element } = item;
  let actionClassName = null;
  switch (type) {
    case 'default':
      actionClassName = styles.action;
      break;
    case 'dropdown':
      actionClassName = styles.dropdownItem;
      break;
    default:
      actionClassName = styles.action;
  }

  const addConditionItem = () => {
    if (disabled || !element) return;

    addElement(conditionId, element);
  };

  return (
    <button data-testid='actionItem' type='button' onClick={addConditionItem} className={`${actionClassName} ${disabled ? styles.disabled : ''}`}>
      {actionName}
    </button>
  );
}
