/* eslint-disable react/no-array-index-key,no-param-reassign */
import { HeadCell } from './HeadCell';
import { DeleteButton } from './DeleteButton';
import classes from '../ComparisonTable.module.sass';
import { FIRST_INDEX } from '../../../../../../../../../../lib/util/constants';
import { useContentTable } from '../hooks/useContentTable';
import styled from 'styled-components';
import { isTruthy } from '../../../../../../../../../../lib/util/helpers';
import { updateInfoIconsIndexes } from '../../../../utils/helpers';

export function HeaderDeleteColumnsCells() {
  const { contentTableState } = useContentTable();

  const deleteColumn = index => {
    contentTableState.updateTable(table => {
      const isLastColumn = table.tableRows[FIRST_INDEX].cells.length === 1;

      if (isLastColumn) {
        table.tableRows = [];
        return;
      }

      for (const row of table.tableRows) {
        row.cells.splice(index, 1);
      }

      updateInfoIconsIndexes(table.tableRows);
    });
  };

  return contentTableState.tableRows?.[0]?.cells.map((_item, columnIndex) => {
    const columnWidth = contentTableState.table?.columnsWidth?.[columnIndex];
    const style = isTruthy(columnWidth) && { width: `${columnWidth}px` };

    return (
      <Root key={columnIndex} className={classes.headerDeleteCell} style={style?.width && style}>
        <div>
          <DeleteButton onClick={() => deleteColumn(columnIndex)} />
        </div>

        <Resizer onMouseDown={event => contentTableState.resizeColumn(columnIndex, event)} />
      </Root>
    );
  });
}

const Root = styled(HeadCell)({
  position: 'relative',
});

const Resizer = styled('span')({
  position: 'absolute',
  top: 0,
  display: 'flex',
  width: '10px',
  height: '100%',
  cursor: 'col-resize',
  right: '0px',
});
