export function ChevronUpIcon({ fill = '#FFFFFF', ...rest }) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.64565 4.64592C7.6921 4.59935 7.74728 4.56241 7.80802 4.5372C7.86877 4.512 7.93389 4.49902 7.99965 4.49902C8.06542 4.49902 8.13054 4.512 8.19129 4.5372C8.25203 4.56241 8.30721 4.59935 8.35365 4.64592L14.3537 10.6459C14.4475 10.7398 14.5003 10.8671 14.5003 10.9999C14.5003 11.1327 14.4475 11.26 14.3537 11.3539C14.2598 11.4478 14.1324 11.5005 13.9997 11.5005C13.8669 11.5005 13.7395 11.4478 13.6457 11.3539L7.99965 5.70692L2.35366 11.3539C2.25977 11.4478 2.13243 11.5005 1.99966 11.5005C1.86688 11.5005 1.73954 11.4478 1.64566 11.3539C1.55177 11.26 1.49902 11.1327 1.49902 10.9999C1.49902 10.8671 1.55177 10.7398 1.64566 10.6459L7.64565 4.64592Z'
      />
    </svg>
  );
}
