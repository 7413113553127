import { checkIsHamburgerBusinessFkP1, ProductCardId } from '../models/Hardcode';
import { useOnSelectHamburgerPkP4P2 } from './useCardLogic/useHardcodedOnSelectCard/useOnSelectHamburgerPkP4P2';
import { useOnSelectHamburgerPkP4P1 } from './useCardLogic/useHardcodedOnSelectCard/useOnSelectHamburgerPkP4P1';
import { useOnSelectHamburgerBusinessFkP1 } from './useCardLogic/useHardcodedOnSelectCard/useOnSelectHamburgerBusinessFkP1';
import { useNormalOnSelectCard } from './useCardLogic/useNormalOnSelectCard';
import { useUpdateProductsStructure } from './useCardLogic/generic/useUpdateProductsStructure';
import { useHardcodedUpdateProductSelect } from './useCardLogic/useHardcodedOnSelectCard/useHardcodedUpdateProductSelect';
import { useScrollToCard } from '../components/PreviewProductCarousel/lib/hooks/useScrollToCard';
import { voidFunc } from '../../../../../../../lib/util/helpers';

// eslint-disable-next-line max-lines-per-function
export function useOnSelectCard(props) {
  const {
    conditions,
    P2,
    cardId,
    products,
    card,
    selectable,
    setPagesFlow,
    structureItemId,
    customUnselectedProductIds = [],
    selectedCardIndex,
    setDragging,
    carouselRef,
    windowWidth,
    setSelectionModalOpen,
    productObject,
    pagesFlow,
    scoringResult,
  } = props;

  const scrollToCard = useScrollToCard({
    setDragging,
    carouselRef,
    windowWidth,
    cardIndex: selectedCardIndex,
  });

  const categoryName = card?.productNameSection?.categoryName;
  const productId = card?.productNameSection?.product?.value;
  const updateProductsStructure = useUpdateProductsStructure(setPagesFlow, structureItemId);
  const updateProductHardcodedSelect = useHardcodedUpdateProductSelect({ products, productId, categoryName, customUnselectedProductIds });

  const onSelectHamburgerPkP4P1 = useOnSelectHamburgerPkP4P1({
    products,
    card,
    conditions,
    setSelectionModalOpen,
    updateProductsStructure,
    updateProductSelect: updateProductHardcodedSelect,
    P2,
    productObject,
  });

  const onSelectHamburgerPkP4P2 = useOnSelectHamburgerPkP4P2({
    products,
    card,
    updateProductsStructure,
    updateProductSelect: updateProductHardcodedSelect,
    productObject,
  });

  const onSelectHamburgerBusinessFkP1 = useOnSelectHamburgerBusinessFkP1({
    updateProductsStructure,
    products,
    card,
    customUnselectedProductIds,
    productObject,
  });

  const onSelectNormal = useNormalOnSelectCard({
    setPagesFlow,
    products,
    structureItemId,
    productId,
    categoryName,
    updateProductsStructure,
    selectedCardIndex,
    customUnselectedProductIds,
    productObject,
    pagesFlow,
    scoringResult,
  });

  let selectHandler = voidFunc;
  if (selectable) {
    selectHandler = getSelectHandler({
      cardId,
      onSelectHamburgerPkP4P1,
      onSelectHamburgerPkP4P2,
      onSelectHamburgerBusinessFkP1,
      products,
      onSelectNormal,
      structureItemId,
    });
  }

  const onSelectCard = customValue => {
    scrollToCard();

    if (selectable) {
      selectHandler(customValue);
    }
  };

  return { onSelectCard, setSelectionModalOpen, scrollToCard, updateProductsStructure };
}

function getSelectHandler({
  cardId,
  onSelectHamburgerPkP4P1,
  onSelectHamburgerPkP4P2,
  onSelectHamburgerBusinessFkP1,
  products,
  onSelectNormal,
  structureItemId,
}) {
  switch (true) {
    case cardId === ProductCardId.HAMBURGER_PK_P4_1:
      return onSelectHamburgerPkP4P1;
    case cardId === ProductCardId.HAMBURGER_PK_P4_2:
      return onSelectHamburgerPkP4P2;
    case checkIsHamburgerBusinessFkP1(products, structureItemId):
      return onSelectHamburgerBusinessFkP1;
    default:
      return onSelectNormal;
  }
}
