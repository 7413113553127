import styled from 'styled-components';

const FIRST_SCORE_VALUE = '0/3';

export function Score({ score, colors }) {
  const filteredScore = score.scoreList.filter(el => el.value !== FIRST_SCORE_VALUE);
  return (
    <StyledScore>
      {filteredScore.map((item, index) => {
        const scoreValue = Number(score.selectedScore.split('/')[0]);
        return (
          <StyledScoreItem key={item.id} primaryColor={colors.primaryColor} backgroundColor={scoreValue > index ? colors.primaryColor : '#fff'} />
        );
      })}
    </StyledScore>
  );
}

const StyledScore = styled('div')({
  marginTop: 16,
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'center',
  gap: 8,
});

const StyledScoreItem = styled('div')(props => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  border: `1px solid ${props.primaryColor}`,
  backgroundColor: props.backgroundColor,
}));
