import { Typography } from 'antd';
import { ButtonOrLinkComponent } from '../ButtonOrLinkComponent';
import { getBackgroundUrl } from '../../../../../../../../lib/util/helpers';
import styles from './styles.module.scss';
import { InfoIconComponent } from '../InfoIconComponent';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

// eslint-disable-next-line max-lines-per-function
export function PreviewIntroLayout({ media, introObject, colors, fonts, style, setPagesFlow, pagesFlow, setPageHistory, currentElements }) {
  const { applyVariable } = useVariables(pagesFlow);

  const { content: { headline, primaryButton, secondaryButton, selectBackgroundImage, subline } = {} } = introObject || {};

  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, primaryButtonStyle = {}, secondaryButtonStyle = {} } = style || {};

  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImage, media);

  return (
    <section
      className={styles.section}
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url('${backgroundImageUrl}')`,
        width: '100%',
        height: 'calc(100vh - 94px)',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', right: 80, top: 10 }}>
        <InfoIconComponent applyVariable={applyVariable} type={selectBackgroundImage} />
      </div>
      <div className={styles.container}>
        <div
          className={styles.card}
          style={{
            minHeight: 269,
            background: colors.backgroundColor,
            padding: 24,
            ...rootStyle,
          }}
        >
          {subline && (
            <div style={{ display: 'flex' }}>
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                style={{
                  marginBottom: 16,
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  minHeight: fonts.body.fontSize,
                  ...sublineStyle,
                  maxWidth: '95%',
                }}
              >
                {applyVariable(subline.value)}
              </Typography.Paragraph>
              <InfoIconComponent applyVariable={applyVariable} type={subline} styles={{ top: 0 }} />
            </div>
          )}
          {headline && (
            <div style={{ display: 'flex' }}>
              <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                style={{
                  ...fonts.headlineSecond,
                  marginBottom: 32,
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                  fontSize: fonts.headlineSecond.fontSize,
                  fontFamily: fonts.headlineSecond.font,
                  fontWeight: fonts.headlineSecond.fontWeight,
                  color: colors[fonts.headlineSecond.colorVariant],
                  minHeight: fonts.headlineSecond.fontSize,
                  ...headlineStyle,
                  maxWidth: '95%',
                }}
              >
                {applyVariable(headline.value)}
              </Typography.Paragraph>
              <InfoIconComponent applyVariable={applyVariable} type={headline} styles={{ top: 5 }} />
            </div>
          )}
          {primaryButton && (
            <div style={{ position: 'relative' }}>
              <ButtonOrLinkComponent
                contentObject={primaryButton}
                style={{
                  width: 300,
                  minHeight: 48,
                  background: colors.primaryColor,
                  marginBottom: 10,
                  border: 'none',
                  borderRadius: 24,
                  color: '#FFFFFF',
                  ...primaryButtonStyle,
                  position: 'relative',
                }}
                setPagesFlow={setPagesFlow}
                pagesFlow={pagesFlow}
                textStyle={{
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                }}
                setPageHistory={setPageHistory}
                currentElements={currentElements}
              >
                <InfoIconComponent applyVariable={applyVariable} type={primaryButton} styles={{ top: -7, right: 0 }} />
              </ButtonOrLinkComponent>
            </div>
          )}
          {secondaryButton && (
            <ButtonOrLinkComponent
              contentObject={secondaryButton}
              style={{
                width: 300,
                minHeight: 48,
                background: '#FFFFFF',
                marginBottom: 10,
                border: '1px solid #FFFFFF',
                borderRadius: 24,
                color: '#191919',
                ...secondaryButtonStyle,
                position: 'relative',
              }}
              setPagesFlow={setPagesFlow}
              pagesFlow={pagesFlow}
              textStyle={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
              }}
              setPageHistory={setPageHistory}
              currentElements={currentElements}
            >
              <InfoIconComponent applyVariable={applyVariable} type={secondaryButton} styles={{ top: -7, right: 0 }} />
            </ButtonOrLinkComponent>
          )}
        </div>
      </div>
    </section>
  );
}
