import styled from 'styled-components';
import { CompletedChapter } from './CompletedChapter';

export function CompletedChapters({ completedChapters, pagesFlow, setPagesFlow, colors, fonts, setPageHistory, currentElements }) {
  return (
    <Root>
      {completedChapters.map(el => {
        return (
          <CompletedChapter
            key={el.id}
            completedChapter={el}
            pagesFlow={pagesFlow}
            setPagesFlow={setPagesFlow}
            colors={colors}
            fonts={fonts}
            setPageHistory={setPageHistory}
            currentElements={currentElements}
          />
        );
      })}
    </Root>
  );
}

const Root = styled('div')({
  maxWidth: 800,
  margin: '10px auto',
});
