import { useNavigate } from 'react-router-dom';
import { AppModal } from '../../../../../../../../ui/AppModal';
import { SessionExpiredInfo } from './SessionExpiredInfo';
import { ROUTER_PATHS } from '../../../../../../../../lib/util/router/router-paths';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { useSetActivity } from './useSetActivity';
import { closeFinderModal } from '../../../../../../../../lib/store/slices/findersSlice';
import { useDispatch } from 'react-redux';
import { useGetFinder } from '../../../../../../../../lib/hooks/useGetFinder';

export function SessionExpiredModal({ isOpen }) {
  const { title, actionButton } = useLocalization().translations.unique.pages.questionnaire.sessionExpiredModal;
  const navigate = useNavigate();
  const { tenantId } = useGetFinder();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(closeFinderModal());
  };

  const submit = () => {
    closeModal();
    navigate(ROUTER_PATHS.tenant(tenantId));
  };

  useSetActivity();

  return (
    <AppModal
      open={isOpen}
      width={528}
      titleProps={{ textValue: title }}
      cancelButtonProps={{ style: { display: 'none' } }}
      actionButtonProps={{ onClick: submit, textValue: actionButton }}
      closable={false}
    >
      {isOpen && <SessionExpiredInfo />}
    </AppModal>
  );
}
