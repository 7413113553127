import { useUpdateStructure } from '../../../../../../hooks/useUpdateStructure';

export function useToggleDefaultCounter(structureItemIndex, productIndex) {
  const { updateStructure } = useUpdateStructure();

  return value => {
    updateStructure(draft => {
      const product = draft[structureItemIndex].content.products[productIndex];

      if (!product?.counterSection) {
        product.counterSection = {};
      }

      if (value) {
        product.counterSection.value = 0;
        product.counterSection.defaultValue = 0;
      }

      product.counterSection.showDefaultCounter = !value;
    });
  };
}
