export const baseBorderStyles = {
  borderWidth: '1px !important',
  borderStyle: 'solid',
  borderColor: '#5C5C6E !important',
  borderRadius: '8px',
};

const colorBorderStyles = {
  borderWidth: '2px !important',
  borderStyle: 'solid',
  borderColor: '#33BDCD !important',
  borderRadius: '8px',
};

export const equalRightBorderStyles = {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
};

export const equalLeftBorderStyles = {
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
};

export const resetFieldStyles = {
  border: 'none !important',
  borderRadius: '7.5px',
  boxShadow: 'none !important',
  position: 'relative',
};

export const initialFieldBeforeStyles = {
  transition: 'all 0.1s',
  position: 'absolute',
  content: '""',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  visibility: 'visible',
  width: '100%',
  zIndex: 0,
  height: '100%',
  ...baseBorderStyles,
};

export const secondFieldBeforeStyles = {
  transition: 'all 0.1s',
  position: 'absolute',
  content: '""',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  visibility: 'visible',
  width: '100%',
  zIndex: 0,
  height: '100%',
  ...colorBorderStyles,
};

export const hoverFieldBeforeStyles = {
  transition: 'all 0.1s',
  borderColor: '#33BDCD !important',
  borderWidth: '2px !important',
};

export const focusedFieldBeforeStyles = {
  borderColor: '#0092A3 !important',
};

export const errorFieldBeforeStyles = {
  borderColor: '#FF4949 !important',
};

export const successFieldBeforeStyles = {
  borderColor: '#00A887 !important',
};

export const disabledFieldStyles = {
  cursor: 'not-allowed',
  '&:hover:before': baseBorderStyles,
};
