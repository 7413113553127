import { rulesEngine } from './rules_helper';
import { getProductScoreValue } from './product_score_rules_helper';
import { getProductSavingsValue } from './product_savings_rules_helper';
import { getDependenceValue } from './product_dependence_rules_helper';

export const getRulesProductProperties = (productCard, pagesFlow, currentElements, { originalScore, originalSavings }) => {
  const { rules, id: cardId } = productCard;
  const { scoreProduct, savingsProduct, dependenceProduct } = rulesEngine({
    rules,
    pagesFlow,
    currentElements,
    rulesFilter: ['scoreProduct', 'savingsProduct', 'dependenceProduct'],
  });

  const cardScoreBlocks = scoreProduct.filter(block => block?.scoreProduct?.elements?.map(el => el.productId)?.includes(cardId));
  const cardSavingsBlocks = savingsProduct.filter(block => block?.savingsProduct?.elements?.map(el => el.productId)?.includes(cardId));
  const dependenceProductParentBlocks = dependenceProduct.filter(block =>
    block?.dependenceProduct?.elements?.map(el => el.productId)?.includes(cardId)
  );
  const dependenceProductChildBlocks = dependenceProduct.filter(block => block?.dependenceProduct?.product?.map(el => el.id)?.includes(cardId));

  const scoreRulesObject = getProductScoreValue(cardScoreBlocks, originalScore);
  const savingsRulesObject = getProductSavingsValue(cardSavingsBlocks, originalSavings);
  const dependenceRulesObject = getDependenceValue(dependenceProductParentBlocks, dependenceProductChildBlocks);

  return { ...scoreRulesObject, ...savingsRulesObject, ...dependenceRulesObject };
};

export const updateRulesProperties = (rulesProperties, updatedRulesValues) => {
  const updatedRulesProperties = updatedRulesValues.map(el => {
    const oldRuleObject = rulesProperties.find(ruleObj => el.id === ruleObj.id);
    return oldRuleObject ? { ...oldRuleObject, ...el } : el;
  });
  const filteredProperties = rulesProperties.filter(ruleObj => !updatedRulesValues.some(el => el.id === ruleObj.id));
  return [...filteredProperties, ...updatedRulesProperties];
};
