import { AppInput } from 'src/ui/AppInput';
import { AppText } from 'src/ui/text/AppText';
import { AppBasicButton } from 'src/ui/buttons/AppBasicButton';
import { DeleteIcon } from 'src/ui/icons/DeleteIcon';
import { GripVerticalIcon } from 'src/ui/icons/GripVerticalIcon';
import { ComparisonRadioGroup } from './ComparisonRadioGroup';

import { useLocalization } from 'src/lib/hooks/useLocalization';

export function ComparisonTopic({ topic, structureItemIndex, tableIndex, topicIndex, tableId, deleteTopic, updateTopic, dragHandleProps }) {
  const { id, topicName, productOne, productTwo } = topic;
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;
  const { product } = useLocalization().translations.shared.generic;

  const onTopicChange = (field, value) => {
    updateTopic(tableId, id, field, value);
  };

  return (
    <>
      <div className='comparisonTopicItem'>
        <div className='mb-24 comparisonTopicHeader'>
          <AppBasicButton {...dragHandleProps}>
            <GripVerticalIcon />
          </AppBasicButton>

          <AppInput
            value={topicName}
            label={comparisonDetailTable.enterComparisonTopic}
            onChange={event => onTopicChange('topicName', event.target.value)}
          />

          <AppBasicButton
            onClick={() => {
              deleteTopic(tableId, id);
            }}
          >
            <DeleteIcon />
          </AppBasicButton>
        </div>

        <div className='mb-24'>
          <AppText style={{ fontWeight: 700, fontSize: '14px', marginBottom: '24px' }} textValue={`${product()} 1`} />
        </div>

        <ComparisonRadioGroup
          tableIndex={tableIndex}
          topicIndex={topicIndex}
          structureItemIndex={structureItemIndex}
          tableId={tableId}
          product={productOne}
          fieldName='productOne'
          onTopicChange={onTopicChange}
        />

        <AppText style={{ fontWeight: 700, fontSize: '14px', marginBottom: '24px' }} textValue={`${product()} 2`} />

        <ComparisonRadioGroup
          tableIndex={tableIndex}
          topicIndex={topicIndex}
          structureItemIndex={structureItemIndex}
          product={productTwo}
          fieldName='productTwo'
          onTopicChange={onTopicChange}
        />
      </div>
      <div className='comparisonSeparator topicSeparator' />
    </>
  );
}
