import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { CircleXIcon } from '../../../../../../ui/icons/CircleXIcon';
import './styles.scss';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { Droppable } from 'react-beautiful-dnd';
import { ConnectionLineShort } from '../../../../../../ui/icons/ConnectionLineShort';
import styled from 'styled-components';
import { InfoIconTextarea } from '../form-items/InfoIconTextarea';
import React from 'react';

export function DraggableFormSectionContent({ valueObject, valueArray, index, onChange, label, headingLabel, item, contentBlockIndex }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const { templateInfo } = useLocalization().translations.unique.formItems;

  const handleValueChange = (fieldName, value) => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, [fieldName]: value };
    onChange(newArr);
  };

  const handleChangeValueInfoIcon = value => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, infoIcon: { infoIconName: 'infoIconName', value } };
    onChange(newArr);
  };

  const deleteValue = event => {
    event.stopPropagation();
    if (valueArray.length > 1) {
      const newArr = valueArray.filter(el => el.id !== valueObject.id);
      onChange(newArr);
    }
  };

  const handleDeleteInfoIcon = () => {
    const newArr = valueArray.map(el => (el.id === valueObject.id ? { ...el, infoIcon: null } : el));
    onChange(newArr);
  };

  const suffix = (
    <AppBasicButton disabled={isViewMode} onClick={event => deleteValue(event)}>
      <CircleXIcon className='draggable_input_suffix_icon' />
    </AppBasicButton>
  );

  const { infoIcon } = item.content.contentBlocks[contentBlockIndex].bulletPoints[index];

  return (
    <Droppable droppableId={`bulletPoints_${item.id}_contentBlocks_${contentBlockIndex}_${index}`} type={!infoIcon?.infoIconName && 'INFO_ICON'}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef}>
          <AppFormSection
            textFieldProps={{
              value: valueObject.headline,
              onChange: event => handleValueChange('headline', event.target.value),
              label: headingLabel({ index: index + 1 }),
              suffix,
              disabled: isViewMode,
            }}
          />
          <Line>
            <ConnectionLineShort stroke='#5C5C6E' />
          </Line>
          <AppFormSection
            textFieldProps={{
              value: valueObject.textValue,
              onChange: event => handleValueChange('textValue', event.target.value),
              label: label({ index: index + 1 }),
              disabled: isViewMode,
            }}
          />
          {(infoIcon?.infoIconName || snapshot.isDraggingOver) && (
            <InfoIconLine>
              <ConnectionLineShort stroke={infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
            </InfoIconLine>
          )}
          {(infoIcon?.infoIconName || snapshot.isDraggingOver) && (
            <InfoIconTextarea
              valueObject={item.content.contentBlocks[contentBlockIndex].bulletPoints[index].infoIcon || { value: '' }}
              label={templateInfo.label}
              onChange={handleChangeValueInfoIcon}
              onClickSuffix={handleDeleteInfoIcon}
            />
          )}
        </div>
      )}
    </Droppable>
  );
}

const Line = styled('div')({
  position: 'absolute',
  top: 20,
  right: -9,
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 92,
  right: -9,
});
