import React from 'react';
import { CrossIcon } from '../../../../../../../../../../ui/icons/CrossIcon';
import { QuestionnaireBasicButton } from '../../../../features/QuestionnaireBasicButton';

export function DeleteButton({ onClick }) {
  return (
    <QuestionnaireBasicButton onClick={onClick} style={{ display: 'flex' }}>
      <CrossIcon />
    </QuestionnaireBasicButton>
  );
}
