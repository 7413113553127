import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { Finder } from '../../lib/Finder';
import styles from '../../../../../../../../utils/styles/table.module.sass';
import { useFinderNumberColumn } from '../../components/sort';

export function useFinderViewsColumn() {
  const { views: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderNumberColumn({
    title,
    arrangedId: Finder.VIEWS,
    columnProps: {
      width: 140,
    },
    headerCellProps: {
      rootProps: { className: styles.hiddenHeaderCell },
    },
  });
}
