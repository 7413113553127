import { DateHeaderCell } from './DateHeaderCell';
import { AppText } from '../../../../../../../ui/text/AppText';
import { formatDateForTable } from '../../../utils/helpers';

export function useDateColumn(props) {
  const { arrangedId } = props;

  return {
    dataIndex: arrangedId,
    title: <DateHeaderCell {...props} />,
    render: date => <AppText>{date ? formatDateForTable(date) : '-'}</AppText>,
  };
}
