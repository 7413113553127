import { HamburgerPKP4P1Modal } from '../../../PreviewModals/HamburgerPKP4P1Modal';
import { PreviewProductCardPresentational } from '../../PreviewProductCardPresentational';
import { makeConfirmSelection } from '../../../PreviewProductCarousel/lib/utils';
import { useUpdateHamburgerPkP4FlowStructure } from '../../../../hooks/useCardLogic/useHardcodedOnSelectCard/useUpdateHamburgerPkP4FlowStructure';

export function HamburgerPKP4P1ProductCard(props) {
  const {
    fonts,
    colors,
    card,
    structureItemId,
    products,
    setPagesFlow,
    pagesFlow,
    scoringResult,
    selectable,
    isRecommended,
    windowWidth,
    onSelectCard,
    conditions,
    P2,
    P3,
    isSelectionModalOpen,
    setSelectionModalOpen,
    updateProductsStructure,
    scrollToCard,
    ...rest
  } = props;

  const updateHardcodeFlowStructure = useUpdateHamburgerPkP4FlowStructure({ setPagesFlow, P2, P3, structureItemId });
  const confirmSelection = makeConfirmSelection({ products, scrollToCard, updateHardcodeFlowStructure, cardId: card?.id });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 100%' }}>
      <PreviewProductCardPresentational
        fonts={fonts}
        colors={colors}
        pagesFlow={pagesFlow}
        card={card}
        selectable={selectable}
        isRecommended={isRecommended}
        onSelectCard={onSelectCard}
        {...rest}
      />
      <HamburgerPKP4P1Modal
        confirmSelection={confirmSelection}
        setSelectionModalOpen={setSelectionModalOpen}
        isOpen={isSelectionModalOpen}
        headline='Premium-Paket'
        subLine='Wir bieten unser Premium-Paket nur für das Große Freiheit PrivatKonto inkl. Karten-Paket an. Entscheiden Sie sich für das Premium-Paket und damit automatisch für die Buchung des Karten-Pakets?”'
        primaryButton='Ja'
        secondaryButton='Nein'
        fonts={fonts}
        colors={colors}
      />
    </div>
  );
}
