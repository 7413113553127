import { useDispatch } from 'react-redux';
import { AppModal } from '../../../../../../../../ui/AppModal';
import { DeleteRuleInfo } from './DeleteRuleInfo';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { deleteRuleRequest } from '../../../../../../../../lib/store/slices/rulesSlice';

/**
 * @desc Component for displaying a modal to delete a rule.
 * @param {Object} props - Component props.
 * @param {Function} props.close - Function to close the modal.
 * @param {boolean} props.isOpen - Boolean indicating whether the modal is open.
 * @param {Object} props.ruleData - Data of the rule to be deleted.
 * @param {boolean} props.isModalLoading - Boolean indicating whether the modal is in loading state.
 * @returns {JSX.Element} The JSX for the delete rule modal component.
 */
export function DeleteRuleModal({ close, isOpen, ruleData, isModalLoading }) {
  const { title, subTitle } = useLocalization().translations.unique.pages.rules.deleteModal;
  const dispatch = useDispatch();
  const { generic } = useLocalization().translations.shared;

  const submit = () => dispatch(deleteRuleRequest(ruleData.id));

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, loading: isModalLoading, textValue: generic.delete }}
    >
      {isOpen && <DeleteRuleInfo itemName={ruleData.name} usedInElements={ruleData.usedInElements} subTitle={subTitle} />}
    </AppModal>
  );
}
