import { ProductKind } from '../../../../../../../../../../../lib/models/Questionnaire';
import { ProductMultipleCountersLayout } from './multiple-counters';
import { ProductOneCounterLayout } from './one-counter';

/**
 * @desc Layout for Product template
 * @param {Object} props
 * @returns {JSX.Element}
 */
export function ProductLayout(props) {
  const { kind, style, layoutDesign = {}, stylesConfiguration, ...rest } = props;

  const genericStyles = {
    pointerEvents: 'none',
    width: '1920px',
    height: '863px',
    display: 'flex',
    transform: 'scale(0.237) translate(-3096px, -1391px)',
  };

  const genericProps = { style: { ...genericStyles, ...style }, stylesConfiguration, layoutDesign, ...rest };

  return kind === ProductKind.ONE_COUNTER ? <ProductOneCounterLayout {...genericProps} /> : <ProductMultipleCountersLayout {...genericProps} />;
}
