import { configureStore } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { rootReducer } from './slices/rootReducer';
import { logoutSuccess } from './slices/authSlice';
// eslint-disable-next-line import/no-cycle
import { rootSaga } from './sagas';

import {
  changePreviewStructureVersion,
  cleanPreviewStructure,
  selectPreviewQuestionnaireElement,
  setPreviewStructure,
  writeNewStructure,
} from './slices/previewSlice';

import { publishFinderSuccess, setQuestionnaireBasedOnVersion, setSelectedQuestionnaireVersion } from './slices/findersSlice';
import { AppLocalStorage } from '../util/helpers';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../util/constants';

const actionIds = [
  setPreviewStructure,
  cleanPreviewStructure,
  changePreviewStructureVersion,
  writeNewStructure,
  selectPreviewQuestionnaireElement,
  setQuestionnaireBasedOnVersion,
  publishFinderSuccess,
  setSelectedQuestionnaireVersion,
].map(item => item.type);

const reduxStateSyncConfig = {
  whitelist: [...actionIds],
};

const sagaMiddleware = createSagaMiddleware();
const stateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig);
const middleware = [sagaMiddleware, stateSyncMiddleware];

const combinedReducer = (state, action) => {
  if (action.type === logoutSuccess.type) {
    AppLocalStorage.removeItem('persist:auth');
    AppLocalStorage.removeItem(ACCESS_TOKEN_NAME);
    AppLocalStorage.removeItem(REFRESH_TOKEN_NAME);
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: combinedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['payload'],
        ignoredPaths: ['payload'],
      },
    }).concat(middleware),
});

initMessageListener(store);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
