import { ProductNameSection } from './ProductNameSection';
import { PriceSection } from './PriceSection';
import { CounterSection } from '../multiple-counters/components/CounterSection';
import { ProductPageLinkSection } from './ProductPageLinkSection';
import { useSelector } from 'react-redux';
import { ProductBulletPointsForProductSection } from './ProductBulletPointsSection/ProductBulletPointsForProductSection';
import { ListWrapper } from '../../../../ListWrapper';
import { ProductBulletPointsForChapterSection } from './ProductBulletPointsSection/ProductBulletPointsForChapterSection';

export function ProductList({ structureItem, structureItemIndex, allProducts, showCounterSection, isOneCounter }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const addedProducts = questionnaireStructure[structureItemIndex].content.products;
  const availableProducts = allProducts.filter(existedProduct =>
    addedProducts.every(addedProduct => addedProduct?.productNameSection?.product?.value !== existedProduct.id)
  );

  return (
    <ListWrapper>
      {structureItem.content?.products?.map((item, index) => (
        <li key={item.id}>
          <ProductNameSection
            productIndex={index}
            isExceptional={item.isExceptional}
            productNameSection={item.productNameSection}
            product={item}
            structureItem={structureItem}
            structureItemIndex={structureItemIndex}
            allProducts={allProducts}
            availableProducts={availableProducts}
            isOneCounter={isOneCounter}
          />
          <ProductBulletPointsForProductSection
            productIndex={index}
            data={item.bulletPointsSection}
            structureItemIndex={structureItemIndex}
            bulletPointIcon={structureItem.content.bulletPointIcon}
            bulletPointIconColor={structureItem.content.bulletPointIconColor}
          />
          {item.bulletPointsForChapterSection && (
            <ProductBulletPointsForChapterSection
              productIndex={index}
              data={item.bulletPointsForChapterSection}
              structureItemIndex={structureItemIndex}
              bulletPointIcon={structureItem.content.bulletPointIcon}
              bulletPointIconColor={structureItem.content.bulletPointIconColor}
            />
          )}
          {!item.isExceptional && (
            <PriceSection
              productIndex={index}
              isExceptional={item.isExceptional}
              data={item.priceSection}
              structureItemIndex={structureItemIndex}
              allProducts={allProducts}
              product={item.productNameSection.product}
              structureItem={structureItem}
            />
          )}
          {showCounterSection && !item.isExceptional && (
            <CounterSection productIndex={index} data={item.counterSection} structureItemIndex={structureItemIndex} />
          )}
          {!item.isExceptional && (
            <ProductPageLinkSection productIndex={index} value={item.productPageLink} structureItemIndex={structureItemIndex} />
          )}
        </li>
      ))}
    </ListWrapper>
  );
}
