/* eslint-disable no-magic-numbers,max-lines,max-lines-per-function */
import { Typography } from 'antd';
import { Button } from '../../../../../../components/Button';
import { PDF_TYPE, FlowOperation, ResultKind } from '../../../../../../../../../../lib/models/Questionnaire';
import { useEffect, useState } from 'react';
// import { useState } from 'react';
import styles from './styles.module.scss';
// import { DestinationVariants } from '../../../../../../../../../../lib/models/TemplateTypes';
import { DestinationVariants, TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { StructureItemId } from '../../../../Hardcode';
import { hardCodeClick } from './hardCodeClick';
import { FK_RESULT_ID, PK_RESULT_ID } from './constants';
import { setNextFlow } from '../../../../hooks/setNextFlow';

import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { generatePdfRequest } from 'src/lib/api';
import { formatHref } from 'src/processes/CMS/processes/management/utils/helpers';
import { useHvmIntegration } from 'src/processes/CMS/lib/hooks/useHvmIntegration';

export function ResultButtons({
  list,
  buttonsStyle,
  setPagesFlow,
  pagesFlow,
  printedResultProps,
  colors,
  fonts,
  resultObject,
  setPageHistory,
  currentElements,
  ...rest
}) {
  const { applyVariable } = useVariables(pagesFlow);
  const { sendHvmData } = useHvmIntegration(pagesFlow);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const pdfType = resultObject.content?.kind === ResultKind.DEPOT ? PDF_TYPE.DEPOT : PDF_TYPE.RESULT;

  const printPdf = async () => {
    setIsPdfGenerating(true);
    const pdfBlob = await generatePdfRequest({
      payload: printedResultProps,
      type: pdfType,
    });

    const url = URL.createObjectURL(pdfBlob);
    window.open(url);
    setIsPdfGenerating(false);
  };

  const allResults = pagesFlow.allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.RESULT));
  const R1 = allResults[0];

  const handleFlowGoTo = button => {
    const currentPageIndex = pagesFlow.allPages?.findIndex(page => page.id === pagesFlow.currentPageId);
    const nextPage =
      button.flow?.goTo === DestinationVariants.NEXT
        ? pagesFlow.allPages[currentPageIndex + 1]
        : pagesFlow.allPages?.find(page => page.elements.find(el => el.id === button.flow?.goTo));
    const currentChapter = pagesFlow.allChapters?.find(chapter => chapter?.chapterInnerPagesIds?.find(page => page === nextPage?.id));

    setNextFlow(
      button?.flow,
      button?.rules,
      nextPage,
      currentChapter?.id,
      pagesFlow,
      setPagesFlow,
      setPageHistory,
      currentElements,
      button?.id,
      printedResultProps
    );
  };

  // const handleFormGoTo = button => {
  //   const goToForm = button?.flow?.goToForm;
  //   const nextPage = pagesFlow.allPages?.find(page => page.elements.find(el => el.id === goToForm));
  //   const currentChapter = pagesFlow.allChapters?.find(chapter => chapter?.chapterInnerPagesIds?.find(page => page === nextPage?.id));

  //   if (goToForm && nextPage) {
  //     setPagesFlow(prev => ({
  //       ...prev,
  //       currentChapterId: currentChapter?.id,
  //       currentPageId: nextPage.id,
  //       prevPageIds: prev?.currentPageId === nextPage?.id ? prev?.prevPageIds : [...prev.prevPageIds, prev?.currentPageId],
  //       currentFlowElement: isLength(nextPage?.elements) ? nextPage?.elements.find(el => el.id === goToForm) : null,
  //       currentElementsIds: [nextPage?.elements.find(el => el.id === goToForm).id],
  //       clickedButtonsIds: button?.id ? [...prev.clickedButtonsIds, button?.id] : prev.clickedButtonsIds,
  //       printedResultProps,
  //       afterContactFormLink: {
  //         link: applyVariable(button?.flow?.href),
  //         openInNewWindow: button?.flow?.targetBlank || true,
  //       },
  //     }));
  //   }

  //   if (nextPage && nextPage.id !== pagesFlow.currentPageId) {
  //     setHistoryPages(pagesFlow.allPages, pagesFlow.currentPageId, pagesFlow.currentElementsIds, setPageHistory, pagesFlow.currentFlowElement);
  //   }
  // };

  const onButtonClick = async button => {
    switch (button?.flow?.operation) {
      case FlowOperation.GO_TO_FORM:
        setPagesFlow(prev => ({
          ...prev,
          showContactForm: true,
          clickedButtonsIds: button?.id ? [...prev.clickedButtonsIds, button?.id] : prev.clickedButtonsIds,
        }));
        break;
      // case FlowOperation.FORM_THEN_LINK:
      //   if (button?.flow?.goToForm === DestinationVariants.FORM) {
      //     setPagesFlow(prev => ({
      //       ...prev,
      //       clickedButtonsIds: button?.id ? [...prev.clickedButtonsIds, button?.id] : prev.clickedButtonsIds,
      //       showContactForm: true,
      //       afterContactFormLink: {
      //         link: formatHref(applyVariable(button?.flow?.href)),
      //         openInNewWindow: button?.flow?.targetBlank || true,
      //       },
      //     }));
      //   }
      //   if (button?.flow?.goToForm && button?.flow?.goToForm !== DestinationVariants.FORM) {
      //     handleFormGoTo({
      //       ...button,
      //       flow: {
      //         ...button.flow,
      //         href: formatHref(button.flow.href),
      //       },
      //     });
      //   }
      //   break;
      case FlowOperation.PRINT:
        setPagesFlow(prev => ({
          ...prev,
          clickedButtonsIds: button?.id ? [...prev.clickedButtonsIds, button?.id] : prev.clickedButtonsIds,
        }));
        await printPdf();
        break;
      case FlowOperation.LINK:
        setPagesFlow(prev => ({
          ...prev,
          clickedButtonsIds: button?.id ? [...prev.clickedButtonsIds, button?.id] : prev.clickedButtonsIds,
        }));
        window.open(formatHref(applyVariable(button.flow.href)), button.flow.targetBlank ? '_blank' : '_self');
        break;
      case FlowOperation.GO_TO_ELEMENT:
        handleFlowGoTo(button);
        break;
      case FlowOperation.HVM:
        sendHvmData();
        break;
      default:
        break;
    }
  };

  const openPreviewForm = (link, button) => {
    setPagesFlow(prev => ({
      ...prev,
      showContactForm: true,
      afterContactFormLink: {
        link,
        openInNewWindow: button?.flow?.targetBlank || true,
      },
    }));
  };

  const buttonClick = async (button, index) => {
    if (index === 2 && R1?.id === StructureItemId.HAMBURGER_FK_RESULT) {
      setPagesFlow(prev => ({ ...prev, showContactForm: true, isFormHardcoded: true }));
    }

    await ([FK_RESULT_ID, PK_RESULT_ID].includes(R1?.id) && index === 2
      ? hardCodeClick({ button, openPreviewForm, onButtonClick, R1, pagesFlow })
      : onButtonClick(button));
  };

  // const buttonClick = async button => {
  //   await onButtonClick(button);
  // };

  useEffect(() => {
    setPagesFlow(prev => ({ ...prev, isFormHardcoded: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.buttons} {...rest}>
      {list?.map((button, index) => (
        // {list?.map(button => (
        <Button
          key={button.id}
          style={{ ...buttonsStyle, backgroundColor: colors.primaryColor }}
          onClick={() => buttonClick(button, index)}
          // onClick={() => buttonClick(button)}
          loading={button?.flow?.operation === FlowOperation.PRINT && isPdfGenerating}
          analyticsClass='analytics-button'
        >
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{
              marginBottom: 0,
              color: 'inherit',
              fontWeight: 700,
              fontSize: fonts.headlineFourth.fontSize,
              fontFamily: fonts.headlineFourth.font,
            }}
            className='analytics-button'
          >
            {applyVariable(button.buttonText.value)}
          </Typography.Paragraph>
        </Button>
      ))}
    </div>
  );
}
