import { useDispatch, useSelector } from 'react-redux';
import { ProductAnswerScoring } from './ProductAnswerScoring';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import '../style.scss';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';

/**
 * @desc Renders the question answer scoring component.
 * @param {Object} questionElement - The question element containing scoring information.
 * @param {Object} selectedQuestion - The selected question.
 * @returns {JSX.Element} The rendered question answer scoring component.
 */
export function QuestionAnswerScoring({ questionElement, selectedQuestion }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, tenantProducts, isViewMode } = useSelector(({ finders }) => finders);

  const { clearAll } = useLocalization().translations.shared.generic;
  const { selectAnswer: selectAnswerText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  const clearAllAnswerScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              answers: el.content.answers.map(answer => {
                return answer.id === selectedQuestion.answerId
                  ? {
                      ...answer,
                      products: tenantProducts.map(product => ({
                        id: product.id,
                        name: product.name,
                        score: 0,
                        price: Number(product.pricePerMonth),
                      })),
                    }
                  : answer;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return questionElement?.content?.answers?.some(answer => answer.id === selectedQuestion.answerId) ? (
    <>
      <div className='products_score_clear_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={clearAllAnswerScore} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={clearAll} />
        </AppBasicButton>
      </div>
      <div className='products_score_block'>
        {tenantProducts.map(product => (
          <ProductAnswerScoring key={product.id} product={product} selectedQuestion={selectedQuestion} answers={questionElement.content.answers} />
        ))}
      </div>
    </>
  ) : (
    <AppParagraph className='products_score_description' textValue={selectAnswerText} />
  );
}
