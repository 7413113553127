import { Navigate, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { lazily } from 'react-lazily';
import { WithSideBarLayout } from './components/WithSideBarLayout';
import { WithFinderSubHeader } from './components/WithFinderSubHeader';
import { WithoutSideBarLayout } from './components/WithoutSideBarLayout';
import { ROUTER_PATHS } from '../../../../lib/util/router/router-paths';
import { NOT_FOUND_ROUTE_PATH } from '../../../../lib/util/router/router-consts';
import { QuestionnairePage } from './pages/QuestionnairePage/QuestionnairePage';
import { SettingsPage } from './pages/SettingsPage';
import { MainLeftSideBar } from './modules/MainLeftSideBar';
import { LayoutsPage } from './pages/LayoutsPage';
import { PreviewPage } from './pages/PreviewPage';
import { ProductsPage } from './pages/ProductsPage';
import { Header } from '../../modules/Header';
import { WithPreviewHeader } from './components/WithPreviewHeader';
import { Builder } from './pages/BuilderPage';
import { RulesPage } from './pages/RulesPage';

const { ProfilePage } = lazily(() => import('src/processes/CMS/pages/ProfilePage'));
const { TemplatesPage } = lazily(() => import('src/processes/CMS/processes/management/pages/TemplatesPage'));
const { ThemesPage } = lazily(() => import('src/processes/CMS/processes/management/pages/ThemesPage'));
const { ThemePage } = lazily(() => import('src/processes/CMS/processes/management/pages/ThemePage'));
const { TenantsPage } = lazily(() => import('src/processes/CMS/processes/management/pages/TenantsPage'));
const { FindersAndProductsPage } = lazily(() => import('src/processes/CMS/processes/management/pages/FindersAndProductsPage'));
const { UsersPage } = lazily(() => import('src/processes/CMS/processes/management/pages/UsersPage'));
const { NotFoundPage } = lazily(() => import('src/processes/CMS/processes/management/pages/NotFoundPage'));

export function ManagerRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<Header />}>
          <Route element={<WithSideBarLayout sidebar={<MainLeftSideBar />} />}>
            <Route path={NOT_FOUND_ROUTE_PATH} element={<Navigate to={ROUTER_PATHS.tenants} />} />
            <Route path={ROUTER_PATHS.tenants} element={<TenantsPage />} />
            <Route path={ROUTER_PATHS.tenant(':id')} element={<FindersAndProductsPage />} />
            <Route path={ROUTER_PATHS.users} element={<UsersPage />} />
            <Route path={ROUTER_PATHS.templates} element={<TemplatesPage />} />
            <Route path={ROUTER_PATHS.template(':id')} element={<LayoutsPage />} />
            <Route path={ROUTER_PATHS.themes} element={<ThemesPage />} />
            <Route path={ROUTER_PATHS.theme} element={<ThemePage />} />
            <Route path={ROUTER_PATHS.notFound} element={<NotFoundPage />} />
          </Route>
          <Route element={<WithFinderSubHeader />}>
            <Route path={ROUTER_PATHS.settings(':id')} element={<SettingsPage />} />
            <Route path={ROUTER_PATHS.questionnaire(':id')} element={<QuestionnairePage />} />
            <Route path={ROUTER_PATHS.products(':id')} element={<ProductsPage />} />
            <Route path={ROUTER_PATHS.rules(':id')} element={<RulesPage />} />
            <Route path={ROUTER_PATHS.builder(':id')} element={<Builder />} />
          </Route>
          <Route element={<WithoutSideBarLayout />}>
            <Route path={ROUTER_PATHS.profile} element={<ProfilePage />} />
          </Route>
        </Route>
        <Route element={<WithPreviewHeader />}>
          <Route path={ROUTER_PATHS.preview(':id')} element={<PreviewPage />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}
