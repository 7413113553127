/* eslint-disable sonarjs/cognitive-complexity */
import { productProperties, answerProperties, buttonProperties, questionProperties } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { isLength } from '../../../../../../lib/util/helpers';
import { getFilteredScoringResult } from '../end-user-flow-helpers';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { calculateProductSavings } from '../savings-helpers';

export const getProdPropertyResult = (prodCards, prodProperty, pagesFlow, currentElements, originalValue = true) => {
  const { allPages, answersScoring, rulesProductsProperties, rulesAnswersSavings } = pagesFlow;
  if (prodProperty === productProperties?.selected) {
    return prodCards.every(prodCard => prodCard?.selected === true);
  }
  if (prodProperty === productProperties.notSelected) {
    return prodCards.every(prodCard => !prodCard?.selected === true);
  }

  if (prodProperty === productProperties.counter) {
    return prodCards.map(prodCard => {
      const counterValue = prodCard?.counterSection?.value;
      if (typeof prodCard?.counterSection?.showCounter !== 'undefined') {
        if (prodCard?.counterSection?.showCounter) {
          return counterValue;
        }
        return 0;
      }
      return counterValue;
    });
  }
  if (prodProperty === productProperties.score) {
    const isChart = isLength(currentElements.filter(el => el.templateType === TemplateTypes.QUESTION && Array.isArray(el?.content?.chartAnswers)));
    return prodCards.map(prodCard => {
      const scoreArr = getFilteredScoringResult(answersScoring, pagesFlow, isChart, [prodCard]);
      const ruleProd = rulesProductsProperties.find(prod => prod.id === prodCard.id);
      const originalScore = scoreArr[0] ? scoreArr[0].score : 0;
      const ruleScore = ruleProd?.ruleScore ?? originalScore;
      return originalValue ? originalScore : ruleScore;
    });
  }
  if (prodProperty === productProperties.savings) {
    return prodCards.map(prodCard => {
      const originalSavings = calculateProductSavings(allPages, rulesAnswersSavings, prodCard?.productNameSection?.product?.value);
      const ruleProd = rulesProductsProperties.find(prod => prod.id === prodCard.id);
      const ruleSavings = ruleProd?.ruleSavings ?? originalSavings;
      return originalValue ? originalSavings : ruleSavings;
    });
  }
  if (prodProperty === productProperties.price) {
    return prodCards.map(prodCard => Number(prodCard?.priceSection?.priceValue));
  }
  return null;
};

export const getAnswerPropertyResult = (answers, answerProperty, composerItem, pagesFlow) => {
  const { rulesAnswersScore } = pagesFlow;
  const updatedAnswers = answers.map(answObj => {
    if (Array.isArray(answObj.question?.content?.answers)) {
      const selected = answObj.question.selectedAnswers.includes(answObj.answer.id);
      const counter = 0;
      const ruleAnswer = rulesAnswersScore.find(answ => answObj.answer.id === answ.id);
      const scoreObject =
        ruleAnswer?.products?.find(prod => prod.id === composerItem) || answObj.answer.products.find(scoreItem => scoreItem.id === composerItem);
      const score = composerItem && scoreObject ? scoreObject.score : 0;
      const savingsObject = answObj.answer.savings.find(savingsItem => savingsItem.productId === composerItem);
      const savings = composerItem && savingsObject ? savingsObject.savingsValue : 0;
      return { ...answObj, selected, counter, score, savings };
    }
    if (Array.isArray(answObj.question?.content?.chartAnswers) || Array.isArray(answObj.question?.content?.counterAnswers)) {
      const selected = answObj.answer?.amount > 0;
      const counter = answObj.answer?.amount;
      return { ...answObj, selected, counter };
    }
    return answObj;
  });

  if (answerProperty === answerProperties?.selected) {
    return updatedAnswers.every(answ => answ?.selected === true);
  }
  if (answerProperty === answerProperties.notSelected) {
    return updatedAnswers.every(answ => !answ?.selected === true);
  }
  if (answerProperty === answerProperties.counter) {
    return updatedAnswers.map(answ => answ.counter);
  }
  if (answerProperty === answerProperties.score) {
    return updatedAnswers.map(answ => answ.score);
  }
  if (answerProperty === answerProperties.savings) {
    return updatedAnswers.map(answ => answ.savings);
  }
  return null;
};

export const getButtonsPropertyResult = (buttonsIds, buttonsProperty, pagesFlow) => {
  const { clickedButtonsIds } = pagesFlow;

  if (buttonsProperty === buttonProperties?.selected) {
    return buttonsIds.every(buttonId => clickedButtonsIds.includes(buttonId));
  }
  if (buttonsProperty === buttonProperties.notSelected) {
    return buttonsIds.every(buttonId => !clickedButtonsIds.includes(buttonId));
  }
  return null;
};

export const getQuestionsPropertyResult = (questions, questionsProperty) => {
  const updatedQuestions = questions.map(questionObj => {
    if (Array.isArray(questionObj.question?.content?.answers) && Array.isArray(questionObj.question?.selectedAnswers)) {
      const selected = questionObj.question.selectedAnswers.length > 0;
      return { ...questionObj, selected };
    }
    if (Array.isArray(questionObj.question?.content?.chartAnswers)) {
      const selected = questionObj.question?.content?.chartAnswers.some(answer => answer?.amount > 0);
      return { ...questionObj, selected };
    }
    if (Array.isArray(questionObj.question?.content?.counterAnswers)) {
      const selected = questionObj.question?.content?.counterAnswers.some(answer => answer?.amount > 0);
      return { ...questionObj, selected };
    }
    return questionObj;
  });

  if (questionsProperty === questionProperties?.selected) {
    return updatedQuestions.every(question => question?.selected);
  }
  if (questionsProperty === questionProperties.notSelected) {
    return updatedQuestions.every(question => !question?.selected);
  }
  return null;
};
