import styled from 'styled-components';
import { TextColorSelect } from './TextColorSelect';
import { AlignSection } from './AlignSection';
import { CellPopupDivider } from './CellPopupDivider';
import { BoldButton } from './BoldButton';
import { RowColorSelect } from './RowColorSelect';
import { CellTypeSelect } from './CellTypeSelect';
import { ComparisonTableCellType, isImageLikeType } from '../comparison-table-utils';
import { ProductSelect } from './ProductSelect';
import { ProductFieldsSelect } from './ProductFieldsSelect';
import { isFalsy } from '../../../../../../../../../../lib/util/helpers';
import { TableImageSelect } from './TableImageSelect';
import { useContentTable } from '../hooks/useContentTable';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { CellPopupFootnotes } from './CellPopupFootnotes';

export function CellPopupContent({
  fieldLabels,
  productFieldList,
  iconList,
  pictureList,
  productList,
  rowColorList,
  textColorList,
  rowIndex,
  columnIndex,
}) {
  const { contentTableState } = useContentTable();
  const cell = contentTableState.tableRows[rowIndex].cells[columnIndex];
  const isProductCell = cell?.type === ComparisonTableCellType.PRODUCT;
  const isPictureCell = cell?.type === ComparisonTableCellType.PICTURE;
  const isIconCell = cell?.type === ComparisonTableCellType.ICON;
  const isCellValue = !isFalsy(cell.value);
  const isImageLikeCell = isImageLikeType(cell.type);

  return (
    <Root>
      <AppBox disabled={isImageLikeCell} style={{ display: 'flex' }}>
        <BoldButton rowIndex={rowIndex} columnIndex={columnIndex} />
        <CellPopupDivider color='#292929' />
      </AppBox>

      <CellTypeSelect rowIndex={rowIndex} columnIndex={columnIndex} />

      {isProductCell && (
        <>
          <CellPopupDivider color='#222222' />
          <ProductSelect options={productList} rowIndex={rowIndex} columnIndex={columnIndex} />

          {isCellValue && (
            <>
              <CellPopupDivider color='#222222' />
              <ProductFieldsSelect fieldLabels={fieldLabels} options={productFieldList} rowIndex={rowIndex} columnIndex={columnIndex} />
            </>
          )}
        </>
      )}

      {isPictureCell && (
        <>
          <CellPopupDivider color='#222222' />
          <TableImageSelect options={pictureList} rowIndex={rowIndex} columnIndex={columnIndex} />
        </>
      )}

      {isIconCell && (
        <>
          <CellPopupDivider color='#222222' />
          <TableImageSelect options={iconList} rowIndex={rowIndex} columnIndex={columnIndex} />
        </>
      )}

      <CellPopupDivider />
      <AlignSection rowIndex={rowIndex} columnIndex={columnIndex} />

      {!isImageLikeCell && (
        <>
          <CellPopupDivider />
          <TextColorSelect textColorList={textColorList} rowIndex={rowIndex} columnIndex={columnIndex} />
        </>
      )}

      <CellPopupDivider />
      <RowColorSelect rowColorList={rowColorList} rowIndex={rowIndex} columnIndex={columnIndex} />

      <CellPopupDivider />
      <CellPopupFootnotes rowIndex={rowIndex} columnIndex={columnIndex} />
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  height: '40px',
});
