import { useSelector } from 'react-redux';
import { AppBasicButton } from '../../../../../../../ui/buttons/AppBasicButton';

export function QuestionnaireBasicButton({ children, disabled, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <AppBasicButton disabled={disabled || isViewMode} {...rest}>
      {children}
    </AppBasicButton>
  );
}
