import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';

export function ElementNameWithLabel({ questionIndex, answerIndex, label }) {
  return (
    <div className='align_items_center'>
      <AppText style={{ minWidth: 50 }}>{`A.${questionIndex + 1}.${answerIndex + 1}`}</AppText>
      <AppText ellipsis className='element_label_name'>
        {label}
      </AppText>
    </div>
  );
}
