import { useSelector } from 'react-redux';
import '../../style.scss';
import { ProductList } from '../components/ProductList';
import { ProductTopSection } from './components/ProductTopSectionBasic';
import { AddProductButton } from '../components/AddProductButton';
import { generateProductWithCounterItem } from './generateProductWithCounterItem';
import { ComposerButton } from '../../ComposerButton';

export function ProductMultipleCountersForm({ structureItem, structureItemIndex, isDraggingOver, onDeleteComposerButton, onChangeFieldValue }) {
  const { tenantProducts } = useSelector(({ finders }) => finders);

  return (
    <>
      <ProductTopSection structureItem={structureItem} structureItemIndex={structureItemIndex} />
      <ProductList structureItem={structureItem} structureItemIndex={structureItemIndex} allProducts={tenantProducts} showCounterSection />
      <AddProductButton structureItemIndex={structureItemIndex} allProducts={tenantProducts} generateProductItem={generateProductWithCounterItem} />
      {(structureItem.content?.composerButton?.name || isDraggingOver) && (
        <div className='template_card_bottom_block'>
          <ComposerButton
            item={structureItem}
            isDraggingOver={isDraggingOver}
            onChangeFieldValue={onChangeFieldValue}
            onDelete={onDeleteComposerButton}
          />
        </div>
      )}
    </>
  );
}
