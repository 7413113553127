import { AppFormSection } from '../../../../../../../../../../ui/AppFormSection';
import { Product } from '../../../../lib/Product';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { AppSwitch } from '../../../../../../../../../../ui/AppSwitch';
import { Form } from 'antd';

const NAME = Product.IS_EXCEPTIONAL;

export function AddExceptionalOption() {
  const { label } = useLocalization().translations.unique.formItems.isExceptional;
  const form = Form.useFormInstance();
  const value = Form.useWatch(NAME, form);

  const onChange = value => {
    form.setFieldValue(NAME, value);
  };

  return (
    <AppFormSection
      formItemProps={{
        name: NAME,
        style: { minHeight: 'unset', marginBottom: '22px' },
      }}
    >
      <AppSwitch labelProps={{ textValue: label }} switchProps={{ onChange, checked: value }} />
    </AppFormSection>
  );
}
