import { useSelector } from 'react-redux';
import { Role } from 'src/lib/models/Role';

function usePermissions() {
  const { role } = useSelector(({ auth }) => auth);

  const isRestricted = () => {
    return role === Role.POWER_USER;
  };

  return { isRestricted };
}

export { usePermissions };
