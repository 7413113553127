import { components } from 'react-select';

import styles from '../ConditionItem.module.scss';

/**
 * @desc Flow Image Selection Option
 * @param {Object} data
 * @param {Boolean} isSelected
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowImageSelectionOption({ data, isSelected, ...rest }) {
  const { imageUrl, title } = data;
  return (
    <components.Option {...rest}>
      <div className={styles.flowImageOption}>
        <img src={imageUrl} className={styles.flowImage} alt='active' />
        <span className={styles.text}>{title}</span>
      </div>
    </components.Option>
  );
}
