import { Form } from 'antd';
import styled from 'styled-components';
import { AppButton } from '../../../../../ui/buttons/AppButton';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';

export function ArrangePopupWrapper({ sort, filter }) {
  const { arranges } = useLocalization().translations.shared;
  const form = Form.useFormInstance();

  return (
    <Root>
      {sort}
      {filter}
      <AppButton $isFullWidth textValue={arranges.actionButton} type='primary' style={{ marginTop: '16px' }} onClick={form.submit} />
    </Root>
  );
}

const Root = styled('div')({
  '.AppFormSection:last-child .AppFormItem': {
    minHeight: 'unset',
  },
});
