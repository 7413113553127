import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerTitle: { title: '', subTitle: '' },
};

const titleSlice = createSlice({
  name: 'headerTitle',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.headerTitle = { ...state.headerTitle, ...action.payload };
    },
  },
});

export const { setTitle } = titleSlice.actions;
export const headerTitle = titleSlice.reducer;
