import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { defaultBackgroundImage } from '../../../../../../../../../../../lib/models/DefaultStylesFontsAndColors';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../../../lib/models/Questionnaire';
import { generateBulletPoints, price, SVG } from '../../utils';

const getCounter = bodyRegular => ({ headline: bodyRegular, value: 0, showCounter: true });

function getProducts(templatesText) {
  const counter = getCounter(templatesText.bodyRegular);
  const bulletPoints = generateBulletPoints(templatesText.bodyRegular);
  const genericDataFields = {
    headline: templatesText.heading3,
    subline: templatesText.subline,
    counter,
  };

  return [
    {
      id: 1,
      data: {
        ...genericDataFields,
        bulletPointsSection: {
          bulletPoints,
          rightColumnType: ProductRightBulletPointColumnType.ICON,
        },
        price,
      },
    },
    {
      id: 2,
      data: {
        ...genericDataFields,
        bulletPointsSection: {
          bulletPoints,
          rightColumnType: ProductRightBulletPointColumnType.TEXT,
        },
        price,
      },
    },
    {
      id: 3,
      data: {
        ...genericDataFields,
        bulletPointsSection: {
          bulletPoints,
          rightColumnType: ProductRightBulletPointColumnType.TEXT,
        },
      },
    },
    {
      id: 4,
      data: {
        ...genericDataFields,
        bulletPointsSection: {
          bulletPoints,
          rightColumnType: ProductRightBulletPointColumnType.TEXT,
        },
        image: defaultBackgroundImage,
      },
    },
  ];
}

export function useProductMultipleCountersExample() {
  const { templates } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout;

  return {
    list: getProducts(templates),
    headline: templates.bodyRegular,
    bulletPointIconSvg: SVG,
    bulletPointIconColor: '#DA1B5E',
    productSelection: true,
    showSavings: true,
  };
}
