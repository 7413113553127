import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppTitle } from '../../../../../../../../../../../ui/text/AppTitle';
import { WarningIcon } from '../../../../../../../../../../../ui/icons/WarningIcon';
import { styles } from './consts';

export function DeleteFieldModal({ isOpen, submit, close }) {
  const { translations } = useLocalization();
  const { deleteFieldModalDescription } = translations.unique.pages.questionnaire.content;
  const { generic } = translations.shared;

  const title = (
    <div className='flex flex-y-c' style={styles.modal.titleWrapper}>
      <WarningIcon />
      <AppTitle level={3} textValue={generic.warning} style={styles.modal.titleWrapper} />
    </div>
  );

  return (
    <AppModal
      open={isOpen}
      title={title}
      footerProps={{ style: styles.modal.footer }}
      cancelButtonProps={{ onClick: close, style: { ...styles.modal.button } }}
      actionButtonProps={{
        onClick: submit,
        textValue: generic.delete,
        style: { ...styles.modal.button, ...styles.modal.actionButton },
      }}
    >
      {isOpen && <AppText textValue={deleteFieldModalDescription} style={styles.modal.description} />}
    </AppModal>
  );
}
