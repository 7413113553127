import { AppText } from '../../../../../../ui/text/AppText';
import './styles.scss';
import classNames from 'classnames';

export function FontColorTag({ text, color, disabled, size }) {
  return (
    <div className='font_color_wrap'>
      <div>
        <div
          className={classNames('font_color_square', {
            small: size === 'small',
          })}
          style={{ backgroundColor: color }}
        />
      </div>
      <AppText ellipsis style={{ width: 125 }} className={disabled ? 'primary_text_color_disabled' : ''} textValue={text} />
    </div>
  );
}
