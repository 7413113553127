import { Form } from 'antd';
import { getDatesRange } from '../../helpers';
import { ArrangeFormItem } from '../../model/Arrange';

export function useDateArrange(form) {
  const value = Form.useWatch(ArrangeFormItem.DATES, form);
  const fromDate = value?.[0];
  const toDate = value?.[1];

  const areValidDates = fromDate && toDate;

  const formattedFromDate = fromDate || undefined;
  const formattedToDate = toDate || undefined;

  const getFilterLabel = () => areValidDates && getDatesRange(fromDate, toDate);

  return {
    getFilterLabel,
    formattedFromDate,
    formattedToDate,
  };
}
