/* eslint-disable no-magic-numbers */
import { arithmeticTypes } from '../../pages/BuilderPage/Condition/Actions/actionConstants';

export const getNumbersCalculation = valuesArr => {
  const formattedCalculation = getFormattedCalculation(valuesArr);
  const calculationArray = formattedCalculation.map(block => {
    if (Array.isArray(block)) {
      return getCalculation(block);
    }
    return block;
  });
  return getCalculation(calculationArray);
};

const getFormattedCalculation = valuesArr => {
  return valuesArr.reduce((accumulator, block, index, array) => {
    if (array.length === 1) {
      return valuesArr;
    }

    if (
      ['digit', 'percent'].includes(block?.valueType) &&
      ![arithmeticTypes.divide, arithmeticTypes.multiply].includes(array[index - 1]) &&
      ![arithmeticTypes.divide, arithmeticTypes.multiply].includes(array[index + 1])
    ) {
      accumulator.push(block);
    }

    if (block === arithmeticTypes.plus || block === arithmeticTypes.minus) {
      accumulator.push(block);
    }

    if (
      ['digit', 'percent'].includes(block?.valueType) &&
      ![arithmeticTypes.divide, arithmeticTypes.multiply].includes(array[index - 1]) &&
      [arithmeticTypes.divide, arithmeticTypes.multiply].includes(array[index + 1])
    ) {
      accumulator.push([block]);
    }

    if (['digit', 'percent'].includes(block?.valueType) && [arithmeticTypes.divide, arithmeticTypes.multiply].includes(array[index - 1])) {
      accumulator.at(-1)?.push(block);
    }

    if (block === arithmeticTypes.divide || block === arithmeticTypes.multiply) {
      accumulator.at(-1)?.push(block);
    }

    return accumulator;
  }, []);
};

const getCalculation = data => {
  return data.reduce(
    (accumulator, block) => {
      if (block?.valueType === 'percent') {
        return { amount: Number(block.amount), valueType: 'percent' };
      }
      if (block?.valueType === 'digit' && accumulator.amount === null) {
        return { ...accumulator, amount: block.amount };
      }
      if ([arithmeticTypes.divide, arithmeticTypes.multiply, arithmeticTypes.plus, arithmeticTypes.minus].includes(block)) {
        return { ...accumulator, operator: block };
      }
      if (block?.valueType === 'digit' && accumulator.operator === arithmeticTypes.multiply) {
        return { ...accumulator, amount: accumulator.amount * block.amount };
      }
      if (block?.valueType === 'digit' && accumulator.operator === arithmeticTypes.divide) {
        return { ...accumulator, amount: accumulator.amount / block.amount };
      }
      if (block?.valueType === 'digit' && accumulator.operator === arithmeticTypes.plus) {
        return { ...accumulator, amount: Number(accumulator.amount) + Number(block.amount) };
      }
      if (block?.valueType === 'digit' && accumulator.operator === arithmeticTypes.minus) {
        return { ...accumulator, amount: Number(accumulator.amount) - Number(block.amount) };
      }

      if (block?.valueType === 'string') {
        return { ...accumulator, amount: block.amount };
      }

      return accumulator;
    },
    { amount: null, valueType: 'digit' }
  );
};
