import { QuestionnaireTextButton } from '../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { generateContactFormFieldStructure } from './utils';
import { withActionContactFormFields } from '../../../../../../../../../../../lib/models/Questionnaire';

export function AddContactFormFieldButton({ structureItemIndex, rowIndex, isFullWidth, templateContent }) {
  const fieldList = templateContent?.formRowList?.[rowIndex]?.fieldList;
  const { updateStructure } = useUpdateStructure();
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;

  const onClick = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.formRowList[rowIndex].fieldList.push(generateContactFormFieldStructure());
    });
  };

  return (
    <QuestionnaireTextButton
      textValue={contentText.addInput}
      onClick={onClick}
      disabled={isFullWidth || fieldList?.some(item => withActionContactFormFields.includes(item?.type))}
    />
  );
}
