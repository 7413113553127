import { Form, Space, Switch } from 'antd';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormCard } from '../../../../../features/FormCard';
import { FormCardButton } from '../../../../../features/FormCardButton';
import { FontColorFormItem } from '../../../../../features/form-items/FontColorFormItem';
import { ButtonLinkRow } from './ButtonLinkRow';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';
import '../styles.scss';
import { ProgressBarVariantFormItem } from '../../../../../features/form-items/ProgressBarVariantFormItem';
import { ProgressBarType } from '../../../../../utils/consts';
import { AppColorPicker } from '../../../../../../../../../ui/AppColorPicker';

const marginBottom32 = { marginBottom: 32 };
const marginBottom24 = { marginBottom: 24 };
const maxWidth226 = { maxWidth: 226 };

// eslint-disable-next-line max-lines-per-function
export function FooterCard({ form, designSettings, setDesignSettings }) {
  const { design } = useLocalization().translations.unique.pages.settings;
  const { addLink } = useLocalization().translations.shared.generic;
  const {
    themeConfig: { colors },
    footerConfig,
  } = designSettings;

  const fieldHandleChange = (fieldName, value) => setDesignSettings({ ...designSettings, footerConfig: { ...footerConfig, [fieldName]: value } });

  const selectFooterBackgroundColor = value => {
    if (value) {
      fieldHandleChange('backgroundColor', value);
    }
  };

  const selectFooterProgressBarBgColor = value => {
    if (value) {
      fieldHandleChange('progressbarBackgroundColor', value);
    }
  };

  const selectProgressBarVariant = value => {
    if (value) {
      fieldHandleChange('progressbarVariant', value);
    }
  };

  const handleChangeOppositeColorForProgressBar = value => {
    if (value) {
      fieldHandleChange('oppositeColorForProgressBar', value);
    }
  };

  const resetFields = () => {
    setDesignSettings({ ...designSettings, footerConfig: { backgroundColor: ColorVariants.SECONDARY_SECOND, isBackgroundShadow: true } });
    form.setFieldValue(FormItemName.BUTTON_LINKS, []);
  };

  const nonValidatingIndex = 4;

  return (
    <FormCard>
      <AppBox className='space_between' style={marginBottom24}>
        <AppTitle level={4} textValue={design.footer} />
        <FormCardButton onClick={resetFields} />
      </AppBox>
      <AppBox>
        <AppText className='settings_card_label' textValue={design.background} />
        <Space size='large' className='align_items_center' style={marginBottom32}>
          <FontColorFormItem value={footerConfig.backgroundColor} onChange={selectFooterBackgroundColor} colors={colors} />
          <AppBox className='align_items_center'>
            <Switch checked={footerConfig.isBackgroundShadow} onChange={checked => fieldHandleChange('isBackgroundShadow', checked)} size='small' />
            <AppText className='settings_card_switch' textValue={design.shadow} />
          </AppBox>
        </Space>
      </AppBox>
      <Space size='large' className='align_items_center' style={marginBottom32}>
        <div>
          <AppText className='settings_card_label' textValue={design.progressBar.title} />
          <Space size='large'>
            <ProgressBarVariantFormItem value={footerConfig.progressbarVariant || ProgressBarType.ORIGINAL} onChange={selectProgressBarVariant} />
            <FontColorFormItem value={footerConfig.progressbarBackgroundColor} onChange={selectFooterProgressBarBgColor} colors={colors} />
          </Space>
        </div>
      </Space>
      <AppBox style={marginBottom32}>
        <AppText className='settings_card_label' textValue='Opposite color for Progress bar' />
        <AppBox style={maxWidth226}>
          <AppColorPicker color={footerConfig.oppositeColorForProgressBar} onChange={handleChangeOppositeColorForProgressBar} />
        </AppBox>
      </AppBox>
      <Form.List name={FormItemName.BUTTON_LINKS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((linkObject, index) => (
              <ButtonLinkRow key={linkObject.key} linkObject={linkObject} remove={remove} isMaxLength={index < nonValidatingIndex} />
            ))}
            <FormCardButton onClick={() => add()} textValue={addLink} />
          </>
        )}
      </Form.List>
    </FormCard>
  );
}
