import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RangeProductsScoring } from './RangeProductsScoring';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import '../style.scss';

/**
 * @desc Renders the question counter scoring component.
 * @param {Object} questionElement - The question element containing scoring information.
 * @param {Object} selectedQuestion - The selected question.
 * @returns {JSX.Element} The rendered question counter scoring component.
 */
export function QuestionCounterScoring({ questionElement, selectedQuestion }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);

  const { clearAll, addRange } = useLocalization().translations.shared.generic;
  const { addAnswer: addAnswerText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  const clearAllCounterRangeScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.map(scoreObject => ({
                ...scoreObject,
                minValue: 0,
                maxValue: 0,
                products: scoreObject.products.map(prodObj => ({ ...prodObj, score: 0 })),
              })),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const addRangeScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: { ...el.content, scoringRanges: [...el.content.scoringRanges, { id: uuidv4(), minValue: 0, maxValue: 0, products: [] }] },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return questionElement?.content?.counterAnswers?.some(answer => answer.id) ? (
    <>
      <div className='products_score_clear_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={clearAllCounterRangeScore} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={clearAll} />
        </AppBasicButton>
      </div>
      <div className='products_score_block'>
        {questionElement?.content?.scoringRanges.map((rangeObject, rangeIndex) => (
          <RangeProductsScoring key={rangeObject.id} rangeObject={rangeObject} rangeIndex={rangeIndex} selectedQuestion={selectedQuestion} />
        ))}
        <div className='products_score_add_btn'>
          <AppBasicButton style={{ display: 'inline-flex' }} onClick={addRangeScore} disabled={isViewMode}>
            <AppText className='primary_color bold' textValue={addRange} />
          </AppBasicButton>
        </div>
      </div>
    </>
  ) : (
    <AppParagraph className='products_score_description' textValue={addAnswerText} />
  );
}
