/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable i18n-text/no-en */
import { AppImage } from '../../../../../../../../ui/AppImage';
import { AppButton } from 'src/ui/buttons/AppButton';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { sendDataToAnalytics } from '../../../../utils/sendDataToAnalytics';
import { formatHref } from 'src/processes/CMS/processes/management/utils/helpers';
import { HamburgerMenu } from './HamburgerMenu';
import { useWindowResize } from 'src/processes/CMS/processes/management/utils/hooks/useWindowResize';

const onClickButton = url => {
  window.open(formatHref(url), '_blank');
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function PreviewHeader({ header = {}, backgroundColor, isAudioGuidePlaying, playAudio, pauseAudio, footer = {}, textLinkStyles }) {
  const { windowWidth } = useWindowResize();

  const { logo, autoGuide, isAutoGuide, showHeader } = header;

  const togglePlay = () => {
    if (isAudioGuidePlaying) {
      pauseAudio();
      sendDataToAnalytics('Audio guide', 'stop audio');
    } else {
      playAudio();
      sendDataToAnalytics('Audio guide', 'play audio');
    }
  };

  const preparedButtonsLinks = footer.buttonLinks?.map(link => ({
    ...link,
    id: uuidv4(),
  }));

  /* eslint-disable */
  const numberOfHeaderLinks = windowWidth >= 1100 ? 4 :
                              windowWidth >= 860 ? 3 :
                              windowWidth >= 650 ? 2 :
                              windowWidth >= 500 ? 1 :
                                                   0;
  /* eslint-enable */

  const menuLinks = preparedButtonsLinks?.splice(0, numberOfHeaderLinks);

  return (
    showHeader !== false && (
      <div style={{ backgroundColor }} className={styles.header}>
        <div className={styles.container}>
          {logo?.s3Url ? <AppImage className={styles.logo} alt='Logo' src={logo?.s3Url} /> : <div />}
          <div className={styles.headerLinks}>
            {menuLinks?.map(link => (
              <AppButton
                className={styles.navButtons}
                onClick={() => {
                  onClickButton(link.linkUrl);
                }}
                style={{
                  ...textLinkStyles,
                  fontFamily: textLinkStyles.font,
                  fontWeight: 700,
                  fontSize: '14px',
                  color: '#5C5C6E',
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}
                key={link.id}
              >
                {link.linkName}
              </AppButton>
            ))}
            {preparedButtonsLinks && preparedButtonsLinks.length > 0 && <HamburgerMenu links={preparedButtonsLinks} />}

            {isAutoGuide && (
              <AppImage
                onClick={togglePlay}
                style={{
                  marginRight: '10px',
                  transform: isAudioGuidePlaying ? 'scale(1.2)' : '',
                  marginLeft: '20px',
                }}
                className={classNames(styles.avatar, 'analytics-audio-guide')}
                alt='Avatar'
                src={autoGuide?.s3Url || '/images/avatar.png'}
              />
            )}
          </div>
        </div>
      </div>
    )
  );
}
