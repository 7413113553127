import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line max-lines-per-function
export function useComparisonDetailTable(handleChangeField, handleChangeFieldValue, contentBlockIndex, contentBlock, contentBlocks, item) {
  const comparisonOptionsChange = (index, field, value) => {
    const updatedContentBlocks = [...contentBlocks];

    const updatedOptions = [...updatedContentBlocks[contentBlockIndex].comparisonOptions];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [field]: value,
    };

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      comparisonOptions: updatedOptions,
    };

    handleChangeField(updatedContentBlocks);
  };

  const comparisonProductOptionChange = (field, value) => {
    const updatedContentBlocks = [...contentBlocks];

    const updatedProductOptions = { ...updatedContentBlocks[contentBlockIndex].productOptions, [field]: value };

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      productOptions: updatedProductOptions,
    };

    handleChangeField(updatedContentBlocks);
  };

  const addComparisonDetailTable = () => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: [
        ...updatedContentBlocks[contentBlockIndex].detailTables,
        {
          ...item.content.detailTablesDefault,
          id: uuidv4(),
        },
      ],
    };
    handleChangeField(updatedContentBlocks);
  };

  const deleteComparisonDetailTable = id => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: contentBlock.detailTables.filter(detailTable => detailTable.id !== id),
    };
    handleChangeField(updatedContentBlocks);
  };

  const updateComparisonDetailTable = (id, field, value) => {
    const updatedTables = contentBlock.detailTables.map(detailTable => {
      const updatedTableItem = { ...detailTable };

      if (updatedTableItem.id === id) {
        updatedTableItem[field] = value;
      }

      return updatedTableItem;
    });

    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: updatedTables,
    };

    handleChangeField(updatedContentBlocks);
  };

  const addTopic = tableId => {
    const updatedContentBlocks = [...contentBlocks];

    const updatedDetailTables = updatedContentBlocks[contentBlockIndex].detailTables.map(detailTable => {
      const updatedDetailTable = { ...detailTable };
      if (updatedDetailTable.id === tableId) {
        updatedDetailTable.topics = [
          ...updatedDetailTable.topics,
          {
            ...item.content.topicsDefault,
            id: uuidv4(),
          },
        ];
      }

      return updatedDetailTable;
    });

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: updatedDetailTables,
    };

    handleChangeField(updatedContentBlocks);
  };

  const deleteTopic = (tableId, topicId) => {
    const updatedContentBlocks = [...contentBlocks];

    const updatedDetailTables = updatedContentBlocks[contentBlockIndex].detailTables.map(detailTable => {
      const updatedDetailTable = { ...detailTable };
      if (updatedDetailTable.id === tableId) {
        updatedDetailTable.topics = updatedDetailTable.topics.filter(topic => topic.id !== topicId);
      }

      return updatedDetailTable;
    });

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: updatedDetailTables,
    };

    handleChangeField(updatedContentBlocks);
  };

  const updateTopic = (tableId, topicId, field, value) => {
    const updatedContentBlocks = [...contentBlocks];

    const updatedDetailTables = updatedContentBlocks[contentBlockIndex].detailTables.map(detailTable => {
      const updatedDetailTable = { ...detailTable };

      if (updatedDetailTable.id === tableId) {
        const updatedTopics = updatedDetailTable.topics.map(topic => {
          const updatedTopic = { ...topic };

          if (topic.id === topicId) {
            updatedTopic[field] = value;
          }
          return updatedTopic;
        });

        updatedDetailTable.topics = updatedTopics;
      }

      return updatedDetailTable;
    });

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: updatedDetailTables,
    };

    handleChangeField(updatedContentBlocks);
  };

  const changeTopicOrder = (tableId, currentIndex, targetIndex) => {
    if (currentIndex === targetIndex) return;

    const updatedContentBlocks = [...contentBlocks];

    const updatedDetailTables = updatedContentBlocks[contentBlockIndex].detailTables.map(detailTable => {
      const updatedDetailTable = { ...detailTable };

      if (updatedDetailTable.id === tableId) {
        const temp = { ...updatedDetailTable.topics[currentIndex] };
        const updatedTopics = [...updatedDetailTable.topics];
        updatedTopics.splice(currentIndex, 1);

        updatedTopics.splice(targetIndex, 0, temp);
        updatedDetailTable.topics = updatedTopics;
      }

      return updatedDetailTable;
    });

    updatedContentBlocks[contentBlockIndex] = {
      ...contentBlock,
      detailTables: updatedDetailTables,
    };

    handleChangeField(updatedContentBlocks);
  };

  return {
    comparisonOptionsChange,
    comparisonProductOptionChange,
    addComparisonDetailTable,
    deleteComparisonDetailTable,
    updateComparisonDetailTable,
    addTopic,
    deleteTopic,
    updateTopic,
    changeTopicOrder,
  };
}
