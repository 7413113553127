import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../ui/text/AppText';

export function SessionExpiredInfo() {
  const { description } = useLocalization().translations.unique.pages.questionnaire.sessionExpiredModal;

  return (
    <div style={{ marginBottom: '28px' }}>
      {Array.from(description).map((item, index) => {
        const boldText = item?.textValue;

        // eslint-disable-next-line react/no-array-index-key
        return <Text key={index} textValue={boldText || item} style={boldText ? { fontWeight: 'bold' } : undefined} />;
      })}
    </div>
  );
}

const Text = styled(AppText)({
  color: '#5C5C6E',
  lineHeight: 1.5,
});
