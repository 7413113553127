import { ContactFormHeader } from './ContactFormHeader';
import { ContactFormBuilder } from './ContactFormBuilder';
// import { ContactFormEmailHeader } from './ContactFormEmailHeader';
import { ContactFormAdditionalText } from './ContactFormAdditionalText';
import { ContactFormBodyText } from './ContactFormBodyText';
import { ContactFormSuccessText } from './ContactFormSuccessText';
import { ContactFormSuccessButton } from './ContactFormSuccessButton';

import styled from 'styled-components';

/**
 * @desc Contact form
 * @returns {JSX.Element}
 */

export function ContactForm(props) {
  const templateContent = props.structureItem.content;

  return (
    <Root>
      <ContactFormHeader {...props} templateContent={templateContent} />
      <ContactFormSuccessText {...props} templateContent={templateContent} />
      <ContactFormSuccessButton {...props} templateContent={templateContent} />
      <ContactFormBodyText {...props} templateContent={templateContent} />
      {/* <ContactFormEmailHeader {...props} templateContent={templateContent} /> */}
      <ContactFormBuilder {...props} templateContent={templateContent} />
      <ContactFormAdditionalText {...props} templateContent={templateContent} />
    </Root>
  );
}

const Root = styled('div')({
  paddingBottom: '24px',
});
