import { useOutletContext } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useEffect, useState } from 'react';
import { getFilteredScoringResult, getRulesScoringResult } from '../../../../utils/end-user-flow-helpers';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { PreviewSingleCounter } from '../PreviewSingleCounter';
import { useOnUpdateCounter } from './lib/hooks/useOnUpdateCounter';
import { MobileCardList } from './components/MobileCardList';
import { checkIsMobile } from '../../../../../../../../lib/util/Adaptive';
import { NonMobileCarouser } from './components/NonMobileCarouser';
import { useHandleSelectionOnMount } from './lib/hooks/useHandleSelectionOnMount';
import { getRecommendedIndexList } from '../../utils';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { ProductKind } from '../../../../../../../../lib/models/Questionnaire';
import { getRulesProductProperties, updateRulesProperties } from '../../../../utils/rules/product_rules_helper';
import { calculateProductSavings } from '../../../../utils/savings-helpers';
import './styles.scss';

const DEFAULT_CARD_INDEX = 0;

// REFACTOR: use Context API instead of props drilling
// eslint-disable-next-line max-lines-per-function
export function PreviewProductCarouselAndCounter({
  counterType,
  carouselRef,
  products,
  bulletPointIconColor,
  bulletPointIcon,
  defaultIcons,
  colors,
  fonts,
  pagesFlow,
  setPagesFlow,
  customIcons,
  selectable,
  structureItemId,
  counter,
  windowWidth,
  showSavings,
  currentElements,
  productObject,
  layoutDesign,
  isTesting,
}) {
  const [dragging, setDragging] = useState(false);
  const outletContext = useOutletContext();
  const [isSelectionModalOpen, setSelectionModalOpen] = useState(false);
  const { allPages, answersScoring, rulesProductsProperties, rulesAnswersSavings } = pagesFlow;
  const isChart = isLength(currentElements.filter(el => el.templateType === TemplateTypes.QUESTION && Array.isArray(el?.content?.chartAnswers)));
  const scoringResult = getFilteredScoringResult(answersScoring, pagesFlow, isChart, products);
  const scoringRulesResult = getRulesScoringResult(products, scoringResult, rulesProductsProperties, isChart);
  // eslint-disable-next-line no-console
  console.log(scoringRulesResult, 'scoringRulesResult');
  const isOneCounter = counterType === ProductKind.ONE_COUNTER;
  const recommendedIndexList = getRecommendedIndexList(products, scoringRulesResult, structureItemId, isChart);
  const preSelectedCardIndex = isLength(recommendedIndexList) ? recommendedIndexList[0] : DEFAULT_CARD_INDEX;
  const onUpdateCounter = useOnUpdateCounter(setPagesFlow, structureItemId, productObject);

  useEffect(() => {
    const updatedRulesValues = products.map(prod => {
      const scoreObject = scoringResult.find(score => prod.productNameSection.product.value === score.id);
      const { ruleScore, ruleSavings, ruleParentFor, ruleChildFor } = getRulesProductProperties(prod, pagesFlow, currentElements, {
        originalScore: scoreObject?.score ?? 0,
        originalSavings: calculateProductSavings(allPages, rulesAnswersSavings, prod?.productNameSection?.product?.value),
      });
      return { ...prod, ruleScore, ruleSavings, ruleParentFor, ruleChildFor };
    });
    setPagesFlow(prev => {
      const newRulesProductProperties = updateRulesProperties(prev.rulesProductsProperties, updatedRulesValues);
      return { ...prev, rulesProductsProperties: newRulesProductProperties };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages]);

  const genericProps = {
    setDragging,
    products,
    windowWidth,
    colors,
    carouselRef,
    isSelectionModalOpen,
    setSelectionModalOpen,
  };

  const cardProps = {
    fonts,
    productObject,
    defaultIcons,
    bulletPointIconColor,
    bulletPointIcon,
    onUpdateCounter,
    pagesFlow,
    setPagesFlow,
    structureItemId,
    customIcons,
    selectable,
    showSavings,
    scoringResult: scoringRulesResult,
    hideCounter: isOneCounter,
    recommendedIndexList,
    layoutDesign,
    currentElements,
  };

  const mobileProps = {
    ...genericProps,
    ...cardProps,
    layoutDesign,
  };

  const carouselProps = {
    onLeftScrollButtonClick: () => carouselRef?.current?.slickPrev?.(),
    onRightScrollButtonClick: () => carouselRef?.current?.slickNext?.(),
    dragging,
    outletContext,
    preSelectedCardIndex,
  };

  const nonMobileProps = {
    genericProps,
    carouselProps,
    cardProps,
    layoutDesign,
  };

  const isMobile = checkIsMobile(windowWidth);
  const Cards = isMobile || isTesting ? <MobileCardList {...mobileProps} /> : <NonMobileCarouser {...nonMobileProps} />;
  const showCounter = isOneCounter && counter;

  useHandleSelectionOnMount({
    selectable,
    recommendedIndexList,
    products,
    setDragging,
    carouselRef,
    windowWidth,
    setPagesFlow,
    structureItemId,
    allPages,
    setSelectionModalOpen,
    preSelectedCardIndex,
    pagesFlow,
    currentElements,
    scoringResult: scoringRulesResult,
  });

  return (
    <>
      {Cards}

      {showCounter && (
        <PreviewSingleCounter
          counter={counter}
          products={products}
          onUpdateCounter={onUpdateCounter}
          fonts={fonts}
          colors={colors}
          allPages={allPages}
          productObject={productObject}
          selectable={selectable}
          setPagesFlow={setPagesFlow}
          structureItemId={structureItemId}
          pagesFlow={pagesFlow}
          setDragging={setDragging}
          carouselRef={carouselRef}
          windowWidth={windowWidth}
          preSelectedCardIndex={preSelectedCardIndex}
          isSelectionModalOpen={isSelectionModalOpen}
          setSelectionModalOpen={setSelectionModalOpen}
          recommendedIndexList={recommendedIndexList}
          currentElements={currentElements}
          scoringResult={scoringRulesResult}
        />
      )}
    </>
  );
}
