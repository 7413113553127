import { useLayoutNumberColumn } from '../../components/sort';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { Layout } from '../../lib/Layout';

export function useLayoutUsedInPublishedFindersColumn() {
  const { publishUsed: title } = useLocalization().translations.shared.columns;

  return useLayoutNumberColumn({
    title,
    arrangedId: Layout.USED_IN_PUBLISHED_FINDERS,
    columnProps: {
      width: 184,
    },
  });
}
