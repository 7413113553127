/* eslint-disable unicorn/consistent-destructuring */
import { DisplayedProductCardWithWrapper } from './DisplayedProductCardWithWrapper';
import { HtmlNodeId } from '../../../../../../../../../lib/util/HtmlNodeId';
import { LayoutDesign } from '../../../../../../../../../lib/models/Questionnaire';
import { CenterModeCarousel } from './centerModeCarousel';

export function MobileCardList(props) {
  const {
    layoutDesign,
    fonts,
    productObject,
    defaultIcons,
    bulletPointIconColor,
    bulletPointIcon,
    onUpdateCounter,
    pagesFlow,
    setPagesFlow,
    structureItemId,
    customIcons,
    selectable,
    showSavings,
    scoringResult,
    hideCounter: isOneCounter,
    recommendedIndexList,
    setDragging,
    products,
    windowWidth,
    colors,
    carouselRef,
    isSelectionModalOpen,
    setSelectionModalOpen,
  } = props;
  const genericProps = {
    setDragging,
    products,
    windowWidth,
    colors,
    carouselRef,
    isSelectionModalOpen,
    setSelectionModalOpen,
    setPagesFlow,
  };
  const cardProps = {
    fonts,
    productObject,
    defaultIcons,
    bulletPointIconColor,
    bulletPointIcon,
    onUpdateCounter,
    pagesFlow,
    setPagesFlow,
    structureItemId,
    customIcons,
    selectable,
    showSavings,
    scoringResult,
    hideCounter: isOneCounter,
    recommendedIndexList,
  };
  return (
    <div className='slider-container' id={HtmlNodeId.PRODUCTS_CAROUSEL}>
      {!layoutDesign ||
        (layoutDesign?.title !== LayoutDesign.CENTER_MODE_CAROUSEL && (
          <>
            {props.products.map((item, index) => (
              <DisplayedProductCardWithWrapper key={item.id} card={item} cardIndex={index} {...props} />
            ))}
          </>
        ))}

      {layoutDesign?.title === LayoutDesign.CENTER_MODE_CAROUSEL && (
        <CenterModeCarousel genericProps={genericProps} cardProps={cardProps} layoutDesign={layoutDesign} />
      )}
    </div>
  );
}
