import { ContactFormRowFieldOptionTitle } from './ContactFormRowFieldOptionTitle';
import { ContactFormRowFieldOptionAction } from './ContactFormRowFieldOptionAction';

const styles = { gap: '8px', flex: 1 };

export function ContactFormRowFieldOptionWithAction(props) {
  return (
    <div className='flex flex-y-c' style={styles}>
      <ContactFormRowFieldOptionTitle {...props} />
      <ContactFormRowFieldOptionAction {...props} />
    </div>
  );
}
