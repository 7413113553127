export function MobileIcon() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.3359 1.16406H5.0026C4.56058 1.16406 4.13665 1.33966 3.82409 1.65222C3.51153 1.96478 3.33594 2.3887 3.33594 2.83073V14.1641C3.33594 14.6061 3.51153 15.03 3.82409 15.3426C4.13665 15.6551 4.56058 15.8307 5.0026 15.8307H10.3359C10.778 15.8307 11.2019 15.6551 11.5144 15.3426C11.827 15.03 12.0026 14.6061 12.0026 14.1641V2.83073C12.0026 2.3887 11.827 1.96478 11.5144 1.65222C11.2019 1.33966 10.778 1.16406 10.3359 1.16406ZM7.66927 15.1641C7.11594 15.1641 6.66927 14.7174 6.66927 14.1641C6.66927 13.6107 7.11594 13.1641 7.66927 13.1641C8.2226 13.1641 8.66927 13.6107 8.66927 14.1641C8.66927 14.7174 8.2226 15.1641 7.66927 15.1641ZM10.6693 12.4974H4.66927V3.16406H10.6693V12.4974Z'
        fill='url(#paint0_linear_8106_90716)'
      />
      <defs>
        <linearGradient id='paint0_linear_8106_90716' x1='3.33594' y1='1.16406' x2='14.3594' y2='3.2251' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F8F5FF' />
          <stop offset='0.515625' stopColor='white' />
          <stop offset='1' stopColor='#F6F4FF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
