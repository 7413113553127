/* eslint-disable unicorn/no-unsafe-regex */
import { InputNumber } from 'antd';
import styled from 'styled-components';
import { pressOnlyRegEx } from '../../lib/util/helpers';

export function AppCounterInput({ value, onChange, regEx = /\d/, style, className, disabled, ...rest }) {
  return (
    <StyledInputNumber
      className={className}
      style={style}
      controls={false}
      value={value}
      onChange={onChange}
      formatter={value => value.replace('.', ',').replace(/\B(?=(?:\d{3})+(?!\d))/g, '.')}
      parser={value => value.replace(/\.*/g, '').replace(',', '.')}
      onKeyPress={event => pressOnlyRegEx(event, regEx)}
      disabled={disabled}
      {...rest}
    />
  );
}

const StyledInputNumber = styled(InputNumber)(({ style, size }) => ({
  width: '100%',
  height: size === 'small' ? '24px' : '40px',
  ...style,
  boxSizing: 'border-box',
  border: '1px solid #5c5c6e',
  '&:focus': {
    borderColor: '#0092A3 !important',
    boxShadow: 'none',
  },

  '&:hover': {
    borderColor: '#0092A3 !important',
  },
  '&.ant-input-number-focused': {
    boxShadow: 'none',
  },

  '.ant-input-number-input': {
    padding: '0 8px',
    height: '38px',
    textAlign: 'right',
  },
  '&.ant-input-number-sm input.ant-input-number-input': {
    padding: '0 8px',
    height: '22px',
  },
}));
