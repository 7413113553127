import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppText } from '../../text/AppText';
import { AppBasicButton } from '../../buttons/AppBasicButton';
import { PrevIcon } from './PrevIcon';
import { NextIcon } from './NextIcon';

export function DatePickerHeader(props) {
  const title = dayjs(props.date).format('MMMM YYYY');

  return (
    <Root>
      <AppBasicButton onClick={props.decreaseMonth}>
        <PrevIcon type='month' />
      </AppBasicButton>

      <AppText style={styles.title}>{title}</AppText>

      <AppBasicButton onClick={props.increaseMonth}>
        <NextIcon type='month' />
      </AppBasicButton>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '12.5px',

  button: {
    padding: '5px',
  },
});

const styles = {
  title: {
    fontWeight: 700,
  },
};
