import { CardSectionHeader } from '../../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function Header() {
  const { title } = useLocalization().translations.unique.pages.questionnaire.content.printPdfSettings;

  return (
    <CardSectionHeader>
      <AppText strong textValue={title} />
    </CardSectionHeader>
  );
}
