import styled from 'styled-components';
import { InfoIconComponent } from '../../InfoIconComponent';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';

export function BulletPoints({ product, colors, fonts, applyVariable }) {
  const { windowWidth } = useWindowResize();
  const {
    bulletPointsSection: { bulletPoints, bulletPointIcon, bulletPointIconColor },
  } = product;
  const createSvg = () => {
    return { __html: bulletPointIcon.svgContent };
  };
  const fill = colors[bulletPointIconColor];
  return (
    <Root>
      {bulletPoints.map(item => {
        return (
          <StyledBulletPoint key={item.id} windowWidth={windowWidth}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={createSvg()} style={{ fill: fill || '#000000', marginRight: 16 }} />
            <p
              style={{
                marginBottom: 0,
                marginTop: -2,
                fontSize: fonts.body.fontSize,
                lineHeight: '19px',
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
              }}
            >
              {applyVariable(item.value)}
              <InfoIconComponent applyVariable={applyVariable} type={item} />
            </p>
          </StyledBulletPoint>
        );
      })}
    </Root>
  );
}

const Root = styled('div')({
  marginTop: 15,
});

const StyledBulletPoint = styled('div')(props => ({
  display: 'flex',
  marginBottom: 16,
  '& svg': {
    width: props.windowWidth < AppBreakpoint.M && '12px',
    height: props.windowWidth < AppBreakpoint.M && '12px',
  },
}));
