import { TableHeaderCell } from '../../TableHeaderCell';
import { ArrangeListPopup } from '../../popups/ArrangeListPopup';
import { useArrangeSubmit } from '../../../utils/hooks/useArrangeSubmit';
import { getArrangesInitialValues } from '../../../utils/arranges-helpers';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';

export function StatusHeaderCell({
  getParsedItems,
  removeFilterValue,
  removeFilter,
  filterLabel,
  sortOrder,
  isActiveArrange,
  form,
  applyArranges,
  filtering,
}) {
  const { onFinish } = useArrangeSubmit(applyArranges);
  const { status: title } = useLocalization().translations.shared.columns;

  return (
    <TableHeaderCell
      title={title}
      removeFilter={removeFilter}
      filterLabel={filterLabel}
      sortOrder={sortOrder}
      isActiveArrange={isActiveArrange}
      form={form}
      getParsedItems={getParsedItems}
      removeFilterValue={removeFilterValue}
    >
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
