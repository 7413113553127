import ReactSelect from 'react-select';
import { CellPopupDropdownIcon } from './CellPopupDropdownIcon';

export function PopupSelect({ components, styles: additionalStyles, ...rest }) {
  const customComponents = {
    DropdownIndicator: CellPopupDropdownIcon,
    IndicatorSeparator: () => null,
    Input: () => null,
    ...components,
  };

  const customStyles = { ...styles, ...additionalStyles };

  return (
    <ReactSelect
      isClearable={false}
      classNamePrefix='react-select'
      isSearchable={false}
      closeMenuOnSelect={false}
      components={customComponents}
      styles={customStyles}
      {...rest}
    />
  );
}

const styles = {
  control: (baseStyles, props) => ({
    ...baseStyles,
    background: props?.menuIsOpen ? '#5C5C6E' : 'transparent',
    transition: 'all .02s ease-out',
    cursor: 'pointer',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'unset !important',
    height: '100%',
    padding: '9px 16px',
    gap: '12px',
    flexWrap: 'nowrap',
  }),
  singleValue: baseStyles => ({ ...baseStyles, color: '#fff', margin: '0px' }),
  placeholder: baseStyles => ({ ...baseStyles, color: '#fff', margin: '0px' }),
  menuPortal: baseStyles => ({ ...baseStyles, zIndex: 2000 }),
  menuList: baseStyles => ({ ...baseStyles, padding: '0px' }),
  valueContainer: baseStyles => ({ ...baseStyles, display: 'flex', padding: '0px', height: '100%', flexWrap: 'nowrap' }),
  option: (baseStyles, props) => ({
    ...baseStyles,
    cursor: 'pointer',
    display: 'flex',
    transition: 'all .1s ease-out',
    '&, *': {
      color: props?.isSelected ? '#fe7eab !important' : '#fff',
    },
    background: 'unset',
    padding: '8px 16px',
    '&:hover': {
      background: '#5C5C6E',
    },
  }),
  menu: baseStyles => ({
    ...baseStyles,
    background: 'linear-gradient(287.86deg, #282828 0%, #3F3F53 48.28%, #141414 98.62%)',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.29)',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
};
