import { useContext } from 'react';
import { ContentTableContext } from '../context/ContentTableContext';

export function useContentTable() {
  const context = useContext(ContentTableContext);

  if (!context) {
    throw new Error('"useContentTable" must be used within its provider');
  }

  return context;
}
