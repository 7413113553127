import { Typography } from 'antd';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { CompletedChapterElement } from '../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ChapterLayouts/components/CompletedChapterElement';
import { ChapterState } from '../../../../../../../../lib/models/TemplateTypes';
import { SelectImages } from '../../../../../../../../lib/models/SelectImages';
import { defaultChapterBackgroundImage } from '../../../../../../lib/models/DefaultStylesFontsAndColors';
import { getBestProducts } from '../../../../utils/end-user-flow-helpers';
import { RightArrow } from '../../../../../../../../ui/icons/RightArrow';
import { getChapterImageUrl } from '../../../../utils/rules/chapters_rules_engine_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import cn from 'classnames';
import styles from './styles.module.scss';

const DISABLED_OPACITY = 0.4;
const NextText = 'Weiter';

// eslint-disable-next-line max-lines-per-function
export function PreviewNotFirstChapter({
  chapter,
  style,
  colors,
  fonts,
  mediaOptions,
  chapterOnClick,
  pagesFlow,
  chaptersLength = 1,
  chapterIndex,
  imageSelectionFlow,
}) {
  const { applyVariable } = useVariables(pagesFlow);
  const { otherChaptersStyle = {}, chapterCompletedCheckStyle = {}, nextButtonStyle = {} } = style;
  const {
    content: { headline, selectBackgroundImageInactive, selectBackgroundImageActive, showProductsAfterComplete, isShortProductName } = {},
    state,
    flow,
    chapterInnerPagesIds,
    id: chapterId,
    resultSelectedProducts,
    rules,
  } = chapter;
  const { answersScoring } = pagesFlow;

  const inActiveMediaOptions = [
    {
      id: SelectImages.DEFAULT,
      s3Url: defaultChapterBackgroundImage,
      targetType: 'FINDER',
    },
    {
      id: SelectImages.LOW_SATURATION,
      s3Url: 'https://kontofinder.s3.eu-central-1.amazonaws.com/images/finder/5753/11000/5757421191014663139Rectangle%204898.jpg',
      targetType: 'FINDER',
    },
    ...mediaOptions,
  ];

  const backgroundImageUrl = getChapterImageUrl(
    chapterId,
    state,
    imageSelectionFlow,
    selectBackgroundImageActive,
    selectBackgroundImageInactive,
    mediaOptions,
    inActiveMediaOptions
  );

  const bestProducts = getBestProducts(resultSelectedProducts, pagesFlow, answersScoring);

  const bestResultProductsText = bestProducts
    .map(prod => {
      const prodName =
        isShortProductName && prod.productNameSection?.shortProduct?.title
          ? prod.productNameSection.shortProduct?.title
          : prod.productNameSection.product?.title;

      return prod.counterSection?.value > 1 ? `${prod.counterSection.value} x ${prodName}` : prodName;
    })
    .join(', ');

  return (
    <div
      style={{
        border: `3px solid ${state === ChapterState.COMPLETED ? colors.primaryColor : 'transparent'}`,
        opacity: state === ChapterState.NOT_OPENED && selectBackgroundImageInactive.value === SelectImages.LOW_SATURATION && DISABLED_OPACITY,
        pointerEvents: state === ChapterState.NOT_OPENED && 'none',
        cursor: [ChapterState.COMPLETED, ChapterState.OPENED].includes(state) ? 'pointer' : 'default',
        ...otherChaptersStyle,
      }}
      className={styles.card}
      role='presentation'
      onClick={() => chapterOnClick(chapterId, flow, rules, state, chapterInnerPagesIds[0], chapterIndex)}
    >
      {state === ChapterState.COMPLETED && <CompletedChapterElement colors={colors} chapterCompletedCheckStyle={chapterCompletedCheckStyle} />}
      <div className={styles.text_block}>
        {state === ChapterState.COMPLETED && showProductsAfterComplete && isLength(bestProducts) ? (
          <Typography.Title
            ellipsis
            style={{
              margin: '6px 0 6px 0',
              padding: '0 6px',
              textAlign: 'center',
              fontSize: fonts.headlineThird.fontSize,
              fontFamily: fonts.headlineThird.font,
              fontWeight: fonts.headlineThird.fontWeight,
              color: colors[fonts.headlineThird.colorVariant],
              lineHeight: '22px',
            }}
          >
            {bestResultProductsText}
          </Typography.Title>
        ) : (
          headline && (
            <Typography.Title
              style={{
                margin: '6px 0 6px 0',
                padding: '0 6px',
                textAlign: 'center',
                fontSize: fonts.headlineThird.fontSize,
                fontFamily: fonts.headlineThird.font,
                fontWeight: fonts.headlineThird.fontWeight,
                color: colors[fonts.headlineThird.colorVariant],
                lineHeight: '22px',
              }}
            >
              {applyVariable(headline.value)}
            </Typography.Title>
          )
        )}
      </div>
      <div
        className={cn(styles.background, styles[`background-${chaptersLength}`])}
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`,
          position: 'relative',
        }}
      >
        {state === ChapterState.OPENED && (
          <div
            className={styles.text_block}
            style={{ height: 36, position: 'absolute', background: colors.primaryColor, bottom: 0, ...nextButtonStyle }}
          >
            <Typography.Text
              ellipsis
              style={{
                fontSize: fonts.headlineFourth.fontSize,
                fontFamily: fonts.headlineFourth.font,
                fontWeight: 700,
                color: '#FFFFFF',
                lineHeight: '22px',
                marginRight: 10,
              }}
            >
              {NextText}
            </Typography.Text>
            <RightArrow fill='#FFFFFF' className={`analytics-chapter-${chapterIndex}`} />
          </div>
        )}
      </div>
    </div>
  );
}
