import { Form } from 'antd';
import { ArrangeSortAndFilterPopupWrapper } from '../ArrangeSortAndFilterPopupWrapper';
import { SearchFilter } from '../SearchFilter';
import { useResetArrange } from '../../utils/hooks/useResetArrange';

export function ArrangeListPopup({ filtering, form, onFinish, initialValues = {}, isActiveArrange, minSearchLength }) {
  useResetArrange({ isActiveArrange, form, clearOptions: filtering.clearOptions });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeSortAndFilterPopupWrapper>
        <SearchFilter filtering={filtering} minSearchLength={minSearchLength} />
      </ArrangeSortAndFilterPopupWrapper>
    </Form>
  );
}
