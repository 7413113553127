import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useProductArrangeRequests } from '../../lib/useProductArrangeRequests';
import { useArrangeSubmit } from '../../../../utils/hooks/useArrangeSubmit';
import { getArrangesInitialValues } from '../../../../utils/arranges-helpers';
import { SortPopup } from '../../../../components/popups/SortPopup';
import { TableHeaderCell } from '../../../../components/TableHeaderCell';

export function ProductPriceHeaderCell({ title, arrangedId, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ products }) => products);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();

  const { applyArranges } = useProductArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    form,
  });

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <SortPopup initialValues={getArrangesInitialValues(form)} form={form} onFinish={onFinish} isActiveArrange={isActiveArrange} />
    </TableHeaderCell>
  );
}
