/* eslint-disable no-magic-numbers */
import { TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';

export const ProductCardId = {
  HAMBURGER_PK_P4_1: 'bf937d83-2a46-4715-9c51-067ca3ac8cb1',
  HAMBURGER_PK_P4_2: '44511d1b-d96f-4558-b55f-68b4c1972999',
  HAMBURGER_FK_P1_BUSINESS: 'b71f56ae-e41f-4e91-8d64-45d70c66adab',
};

export const StructureItemId = {
  HAMBURGER_FK_P1: '9229900e-ff19-41f9-abe8-2c04e1b42a68',
};

export const ProductId = {
  HAMBURGER_BUSINESS: 11003,
};

export const QuestionElementId = {
  KONFOFINDER_PK_QUESTION_6: '05597e4b-e93f-41d9-bdf4-7d2f1cfc2d7a',
  HAMBURGER_VB_KONTOFINDER_PK_QUESTION_6: '0a28bcef-fbcb-4d4f-baeb-4590cf1eca9b',
};

export const checkIsHamburgerBusiness = products =>
  Boolean(products.some(prod => prod?.productNameSection?.product?.value === ProductId.HAMBURGER_BUSINESS));

export const checkIsHamburgerFkP1 = structureItemId => structureItemId === StructureItemId.HAMBURGER_FK_P1;

export const checkIsHamburgerBusinessFkP1 = (products, structureItemId) =>
  Boolean(checkIsHamburgerBusiness(products) && checkIsHamburgerFkP1(structureItemId));

const getAllProducts = allPages => allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT));

export const getSpecificProducts = allPages => {
  const allProducts = getAllProducts(allPages);
  const P1 = allProducts?.[0];
  const P2 = allProducts?.[1];
  const P3 = allProducts?.[2];

  return { P1, P2, P3 };
};

export function getConditions({ P1, P2, P3 }) {
  return {
    P4_1: P1?.content?.products?.[0]?.selected && P2?.content?.products?.[1]?.selected,
    P4_2: P1?.content?.products?.[1]?.selected && P3?.content?.products?.[1]?.selected,
  };
}
