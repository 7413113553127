import { AppNewSelect } from 'src/ui/AppNewSelect';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';

import { resultSubTypes, types, mapSelectOptions } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import { Flow } from './Flow';
import { Answer } from './Answer';
import { Product } from './Product';

import { useSelector } from 'react-redux';

import styles from '../ConditionItem.module.scss';

const mappedResultOptions = mapSelectOptions(Object.values(resultSubTypes));

/**
 * @desc Conditional Result Type
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
export function ConditionalResultType({ value, onChange }) {
  const { handleElementTypeChange } = useConditionResult({
    value,
    onChange,
  });

  const { variables: finderVariables } = useSelector(({ variables }) => variables);

  const { elementType, element } = value;

  return (
    <div className={styles.elementContainer} data-testid='conditionTestItem'>
      <AppNewSelect
        className={`${styles.largeSelect} ${styles.selectItem}`}
        titleSearch
        onChange={({ value }) => {
          handleElementTypeChange(value);
        }}
        value={elementType}
        label={types.resultType}
        options={mappedResultOptions}
      />

      {elementType === resultSubTypes.Flow && <Flow value={value} onChange={onChange} />}

      {elementType === resultSubTypes.Answer && <Answer value={value} onChange={onChange} />}

      {elementType === resultSubTypes.Product && <Product value={value} onChange={onChange} />}

      {elementType === resultSubTypes.SetVariable && (
        <AppNewSelect
          className={`${styles.largeSelect} ${styles.selectItem}`}
          titleSearch
          fullWidth
          onChange={({ value }) => {
            onChange({
              elementType,
              element: value,
            });
          }}
          value={element}
          label='Select variable'
          options={finderVariables.map(variable => ({
            title: variable.name,
            value: variable.id,
          }))}
        />
      )}
    </div>
  );
}
