import { RangeMinMaxRow } from '../../../../../../../../../features/RangeMinMaxRow';
import { AppCounterInput } from '../../../../../../../../../../../../../ui/AppCounterInput';
import { updateRangeValue } from '../../../../../../../utils/helpers';

/**
 * @desc Product Range Savings Item component.
 * Represents an individual range item within the Product Range Savings component.
 * @param {number} rangeIndex - The index of the range item.
 * @param {Object} rangeObject - The range object containing range information.
 * @param {Function} updateRangeObject - Function to update the range object.
 * @param {Function} deleteRange - Function to delete the range item.
 * @param {Boolean} disabled - Indicates if the component is disabled.
 * @param {Array} savingsRanges - Array of savings range objects.
 * @returns {JSX.Element} - JSX component representing the Product Range Savings Item.
 */
export function ProductRangeSavingsItem({ rangeIndex, rangeObject, updateRangeObject, deleteRange, disabled, savingsRanges }) {
  const savingsHandleChange = value => {
    if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
      updateRangeObject(rangeObject.id, 'savingsValue', Number(value));
    }
  };

  const rangeHandleChange = (value, fieldName, minNumber, maxValue) => {
    const targetValue = Number(value);
    const previousObject = savingsRanges[rangeIndex - 1];
    const nextObject = savingsRanges[rangeIndex + 1];

    updateRangeValue(targetValue, fieldName, rangeObject.id, minNumber, maxValue, previousObject, nextObject, updateRangeObject);
  };

  return (
    <div>
      <RangeMinMaxRow rangeIndex={rangeIndex} rangeObject={rangeObject} deleteRange={deleteRange} onChange={rangeHandleChange} disabled={disabled} />
      <div className='product_range_saving_input_wrap'>
        <AppCounterInput
          style={{ width: '100%', padding: '0 8px' }}
          value={rangeObject.savingsValue}
          onChange={savingsHandleChange}
          disabled={disabled}
          regEx={/[\d,]/}
        />
      </div>
    </div>
  );
}
