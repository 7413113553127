export const TemplateTypes = {
  INTRO: 'INTRO',
  CHAPTER: 'CHAPTER',
  CONTENT: 'CONTENT',
  QUESTION: 'QUESTION',
  PRODUCT: 'PRODUCT',
  RESULT: 'RESULT',
  PAGE: 'PAGE',
  CONTACT_FORM: 'CONTACT_FORM',
};

export const DestinationVariants = {
  LINK: 'LINK',
  NEXT: 'NEXT',
  FORM: 'FORM',
};

export const LabelTexts = {
  [TemplateTypes.CHAPTER]: 'CH',
  [TemplateTypes.INTRO]: 'I',
  [TemplateTypes.CONTENT]: 'C',
  [TemplateTypes.PRODUCT]: 'P',
  [TemplateTypes.QUESTION]: 'Q',
  [TemplateTypes.RESULT]: 'R',
  [TemplateTypes.CONTACT_FORM]: 'C',
};

export const ChapterState = {
  OPENED: 'OPENED',
  NOT_OPENED: 'NOT_OPENED',
  COMPLETED: 'COMPLETED',
};

export const ConditionTypes = {
  AND: 'AND',
  OR: 'OR',
};
