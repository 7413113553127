import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { TemplateFormSection } from '../../../../../../../../../features/TemplateFormSection';
import { ConnectionLineShort } from '../../../../../../../../../../../../../ui/icons/ConnectionLineShort';
import { InfoIconTextarea } from '../../../../../../../../../features/form-items/InfoIconTextarea';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';

export function TotalPrice({ productInformation, id, onChange }) {
  const { productInfo } = useLocalization().translations.unique.pages.result;
  const { templateInfo } = useLocalization().translations.unique.formItems;

  const handleChangePriceName = value => {
    onChange({
      ...productInformation,
      priceName: { ...productInformation.priceName, value },
    });
  };

  const handleChangeFieldInfoIconValue = value => {
    onChange({
      ...productInformation,
      priceName: { ...productInformation.priceName, infoIcon: { ...productInformation.priceName.infoIcon, value } },
    });
  };

  const handleDeleteInfoIcon = () => {
    onChange({
      ...productInformation,
      priceName: { ...productInformation.priceName, infoIcon: null },
    });
  };

  return (
    productInformation?.showTotalPrice && (
      <Droppable droppableId={`resultPriceNameField_${id}`} type='INFO_ICON' isDropDisabled={productInformation.priceName.infoIcon}>
        {(provided, snapshot) => (
          <StyledInputWrapper ref={provided.innerRef}>
            <TemplateFormSection
              valueObject={productInformation.priceName}
              label={productInfo.priceNameLabel}
              onChange={value => handleChangePriceName(value)}
            />
            {(productInformation.priceName.infoIcon?.infoIconName || snapshot.isDraggingOver) && (
              <InfoIconLine>
                <ConnectionLineShort stroke={productInformation.priceName.infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
              </InfoIconLine>
            )}
            {(productInformation.priceName.infoIcon || snapshot.isDraggingOver) && (
              <InfoIconTextarea
                valueObject={productInformation.priceName.infoIcon || { value: '' }}
                label={templateInfo.label}
                onChange={handleChangeFieldInfoIconValue}
                onClickSuffix={handleDeleteInfoIcon}
              />
            )}
          </StyledInputWrapper>
        )}
      </Droppable>
    )
  );
}

const StyledInputWrapper = styled('div')({
  zIndex: 0,
  position: 'relative',
  marginTop: 24,
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 19,
  right: -9,
});
