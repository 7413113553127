import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { CardSection } from '../../../../../ui/CardSection';
import ReactQuill from 'react-quill';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { AppInput } from 'src/ui/AppInput';

export function ContactFormBodyText({ structureItemIndex, templateContent }) {
  const { updateStructure } = useUpdateStructure();

  const changeBodyText = value => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.mail.body`, value);
    });
  };

  const changeSubjectText = event => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.mail.subject`, event.target.value);
    });
  };

  return (
    <CardSection withPaddingBottom withBorderBottom={false}>
      <CardSectionHeader>
        <AppText strong textValue='Mail' />
      </CardSectionHeader>
      <AppInput label='Subject' value={templateContent?.mail?.subject} onChange={changeSubjectText} />
      <ReactQuill style={{ marginTop: '24px' }} theme='snow' value={templateContent?.mail?.body} onChange={changeBodyText} />
    </CardSection>
  );
}
