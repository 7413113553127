import { BulletPoints } from './BulletPoints';
import { BulletPointsSubHeaderRow } from './BulletPointsSubHeaderRow';

import { AddBulletPointButton } from './AddBulletPointButton';
import { useLocalization } from '../../../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { CardSectionHeader } from '../../../../../../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../../../../../../ui/text/AppText';
import { isLength } from '../../../../../../../../../../../../../../../../lib/util/helpers';
import styled from 'styled-components';
import { BulletPointSelects } from './BulletPointSelects';

export function BulletPointsBlock({ data, productIndex, structureItemIndex, detailTableIndex }) {
  const { design } = useLocalization().translations.unique.pages.settings;
  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].detailTableSection.detailTables[${detailTableIndex}].bulletPointsSection`;

  return (
    <Root>
      <CardSectionHeader>
        <AppText strong textValue={design.bulletPoints} />
        <ProductBulletPointSelectsWrapper>
          <BulletPointSelects
            structureItemIndex={structureItemIndex}
            bulletPointIcon={data.bulletPointIcon}
            bulletPointIconColor={data.bulletPointIconColor}
            productIndex={productIndex}
            detailTableIndex={detailTableIndex}
            rightColumnType={data.rightColumnType}
          />
        </ProductBulletPointSelectsWrapper>
      </CardSectionHeader>
      <div>
        {isLength(data.bulletPoints) && (
          <>
            <BulletPointsSubHeaderRow dataPath={dataPath} value={data.rightColumnType} structureItemIndex={structureItemIndex} />
            <BulletPoints
              dataPath={`${dataPath}.bulletPoints`}
              bulletPointIcon={data.bulletPointIcon}
              bulletPointIconColor={data.bulletPointIconColor}
              bulletPointsSection={data}
              productIndex={productIndex}
              structureItemIndex={structureItemIndex}
            />
          </>
        )}
        <AddBulletPointButton data={data} dataPath={`${dataPath}.bulletPoints`} productIndex={productIndex} structureItemIndex={structureItemIndex} />
      </div>
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 24px',
  borderBottom: '1px solid #ECECEE',
});
const ProductBulletPointSelectsWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  marginRight: 34,
});
