import { useDispatch, useSelector } from 'react-redux';
import { CMSRoutes } from '../../processes/CMS';
import { AuthRoutes } from '../../processes/auth';
import { MainLayout } from './MainLayout';
import { ClientRoutes } from '../../processes/client';
import { useEffect } from 'react';
import { getDomainsRequest } from '../../lib/store/slices/endUserFinderSlice';
import { useLocalStorageItem } from '../../lib/hooks/useLocalStorageItem';
import { logoutSuccess } from '../../lib/store/slices/authSlice';
import { ACCESS_TOKEN_NAME } from '../../lib/util/constants';
import { AppPreloader } from '../../ui/AppPreloader';

const isBuild = process.env.NODE_ENV === 'production';

const APP_TITLE = 'PRODUCT FINDER';

export function AppRouter() {
  const [accessToken] = useLocalStorageItem(ACCESS_TOKEN_NAME);
  const { domains, endUserFinder, domainsLoading, endUserFinderLoading } = useSelector(state => state.endUserFinder);
  const { hostname } = window.location;
  const dispatch = useDispatch();

  const isSubdomain = domains?.length === 0 ? false : domains?.every(item => item.name !== hostname);

  useEffect(() => {
    if (!accessToken) {
      dispatch(logoutSuccess());
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (isBuild) {
      dispatch(getDomainsRequest());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].append(link);
    }
    if (!isBuild) {
      link.href = '/favicon.ico?v=2';
      document.title = APP_TITLE;
    }
    if (isBuild) {
      if (!domainsLoading && !isSubdomain) {
        link.href = '/favicon.ico?v=2';
        document.title = APP_TITLE;
      }
      if (!domainsLoading && isSubdomain && !endUserFinderLoading) {
        if (endUserFinder?.finderSettings?.faviconTitle?.favicon?.s3Url) {
          link.href = `${endUserFinder?.finderSettings?.faviconTitle?.favicon?.s3Url}?v=2`;
        }
        if (endUserFinder?.finderSettings?.faviconTitle?.title) {
          document.title = endUserFinder?.finderSettings?.faviconTitle?.title;
        }
      }
    }
  }, [
    domainsLoading,
    endUserFinderLoading,
    endUserFinder?.finderSettings?.faviconTitle?.favicon?.s3Url,
    endUserFinder?.finderSettings?.faviconTitle?.title,
    isSubdomain,
  ]);

  if (isBuild && isSubdomain && domainsLoading) {
    return <AppPreloader />;
  }

  if (isBuild && isSubdomain && !domainsLoading) {
    return <ClientRoutes />;
  }

  return <MainLayout>{accessToken ? <CMSRoutes /> : <AuthRoutes />}</MainLayout>;
}
