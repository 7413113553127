import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { CardSection } from '../../../../../ui/CardSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { ContactFormRowList } from './ContactFormRowList';
import { isLength } from '../../../../../../../../../../../lib/util/helpers';
import { AddContactFormRowButton } from './AddContactFormRowButton';

export function ContactFormBuilder({ structureItemIndex, templateContent }) {
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;

  return (
    <CardSection withPaddingBottom>
      <CardSectionHeader>
        <AppText strong textValue={contentText.contactFormBuilder} />
      </CardSectionHeader>

      {isLength(templateContent?.formRowList) && <ContactFormRowList templateContent={templateContent} structureItemIndex={structureItemIndex} />}

      <AddContactFormRowButton structureItemIndex={structureItemIndex} formRowList={templateContent?.formRowList} />
    </CardSection>
  );
}
