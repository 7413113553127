import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormItemName } from '../../../../../../../../../lib/models/Form';

export function TextEditor({ form }) {
  const handleChangeText = code => form.setFieldValue(FormItemName.DATA_PROTECTION_CONTENT, code);

  return <ReactQuill theme='snow' value={Form.useWatch(FormItemName.DATA_PROTECTION_CONTENT, form)} onChange={handleChangeText} />;
}
