import { useState } from 'react';
import { ArrangeListType } from '../../consts';

export function useEnumArrange(allOptions = []) {
  const [options, setOptions] = useState(allOptions);

  const onChangeSearchField = event => {
    const { value } = event.target;

    if (value) {
      const regex = new RegExp(value, 'i');
      const filteredOptions = allOptions.filter(item => item.label.match(regex));
      setOptions(filteredOptions);
    } else {
      setOptions(allOptions);
    }
  };

  return {
    filtering: { options, setOptions, onChangeSearchField, type: ArrangeListType.ENUM },
  };
}
