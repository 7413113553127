import { PopupSelect } from './PopupSelect';
import { ProductOption } from './ProductOption';
import { ProductSingleValue } from './ProductSingleValue';
import { fillProduct, getSimpleValue } from '../comparison-table-utils';
import { useContentTable } from '../hooks/useContentTable';

export function ProductSelect({ options, rowIndex, columnIndex }) {
  const { contentTableState } = useContentTable();
  const value = getSimpleValue(contentTableState.tableRows, options, rowIndex, columnIndex);

  const onChange = newSelected => {
    contentTableState.updateTable(({ tableRows }) => {
      fillProduct(tableRows, newSelected, rowIndex, columnIndex);
    });
  };

  return (
    <PopupSelect
      options={options}
      value={value}
      onChange={onChange}
      components={{
        Option: ProductOption,
        SingleValue: ProductSingleValue,
      }}
    />
  );
}
