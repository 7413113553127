import { TextFieldWithInfoIcon } from '../../../../../../../../../features/TextFieldWithInfoIcon';
import { useLocalization } from '../../../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { AppList } from '../../../../../../../../../../../../../../../ui/AppList';

export function HeadlineSublineBlock({ detailTable, structureItemIndex, productIndex, detailTableIndex }) {
  const { tableHeadline, tableSubline } = useLocalization().translations.unique.pages.result.productsDepot.detailTable;
  return (
    <Root>
      <TextFieldWithInfoIcon
        label={tableHeadline.label}
        data={detailTable.headline}
        dataPath={`[${structureItemIndex}].content.products[${productIndex}].detailTableSection.detailTables[${detailTableIndex}].headline`}
      />
      <TextFieldWithInfoIcon
        label={tableSubline.label}
        data={detailTable.subline}
        dataPath={`[${structureItemIndex}].content.products[${productIndex}].detailTableSection.detailTables[${detailTableIndex}].subline`}
      />
    </Root>
  );
}

const Root = styled(AppList)({
  padding: '16px 24px 0 24px',
  borderBottom: '1px solid #ECECEE',
});
