export const DefaultColors = {
  primaryColor: '#DA1B5E',
  secondaryFirst: '#191919',
  secondarySecond: '#FFFFFF',
  backgroundColor: '#F2F2F3',
};

export const ColorVariants = {
  PRIMARY_COLOR: 'primaryColor',
  SECONDARY_FIRST: 'secondaryFirst',
  SECONDARY_SECOND: 'secondarySecond',
  BACKGROUND_COLOR: 'backgroundColor',
};

export const DefaultFontStyles = {
  headlineFirst: { font: 'SimonKucher', fontWeight: 700, fontSize: 32, colorVariant: ColorVariants.SECONDARY_FIRST },
  headlineSecond: { font: 'SimonKucher', fontWeight: 700, fontSize: 24, colorVariant: ColorVariants.SECONDARY_FIRST },
  headlineThird: { font: 'SimonKucher', fontWeight: 700, fontSize: 18, colorVariant: ColorVariants.SECONDARY_FIRST },
  headlineFourth: { font: 'SimonKucher', fontWeight: 700, fontSize: 16, colorVariant: ColorVariants.SECONDARY_FIRST },
  body: { font: 'SimonKucher', fontWeight: 400, fontSize: 16, colorVariant: ColorVariants.SECONDARY_FIRST },
  subText: { font: 'SimonKucher', fontWeight: 400, fontSize: 12, colorVariant: ColorVariants.SECONDARY_FIRST },
  textLink: { font: 'SimonKucher', fontWeight: 700, fontSize: 14, colorVariant: ColorVariants.PRIMARY_COLOR },
};

export const defaultBackgroundImage = 'https://kontofinder.s3.eu-central-1.amazonaws.com/images/content+(1)+1.jpg';
export const defaultChapterBackgroundImage =
  'https://kontofinder.s3.eu-central-1.amazonaws.com/images/finder/5551/1111/1434254864298516587default.jpg';
