import { DestinationVariants, TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';
import { setHistoryPages } from './getHistoryPage';
import { isLength } from '../../../../../../../lib/util/helpers';
import { getCurrentPageNextElementId, getQuestionConditionsFlow, getQuestionCounterRangeFlow } from '../../../utils/end-user-flow-helpers';
import { rulesEngine } from '../../../utils/rules/rules_helper';

export function setCurrentElementsIds(nextPage, goTo, showElements) {
  if (isLength(showElements)) {
    return showElements;
  }
  if (goTo === DestinationVariants.NEXT && nextPage && isLength(nextPage?.elements)) {
    return [nextPage?.elements[0]?.id];
  }
  if (goTo && goTo !== DestinationVariants.NEXT && nextPage && isLength(nextPage?.elements)) {
    return [nextPage?.elements.find(el => el.id === goTo).id];
  }
  return [];
}

export function setAllElementsButtonsIds(elements) {
  const buttonsIds = [];
  // eslint-disable-next-line unicorn/no-array-for-each
  elements.forEach((el, index, array) => {
    const firstChapter = array.filter(element => element.templateType === TemplateTypes.CHAPTER).find(item => !item.content.completedChaptersButtons);

    if (el.templateType === TemplateTypes.INTRO) {
      buttonsIds.push(el.content.primaryButton.id, el.content.secondaryButton.id);
    }
    if (el.templateType === TemplateTypes.CHAPTER && firstChapter?.id === el.id && el?.content?.chapterButton) {
      buttonsIds.push(el.content.chapterButton.id);
    }
    if (el.templateType === TemplateTypes.CHAPTER && el?.content?.completedChaptersButtons) {
      buttonsIds.push(...el.content.completedChaptersButtons.map(button => button.id));
    }
    if (el.content?.composerButton?.id) {
      buttonsIds.push(el.content?.composerButton?.id);
    }
    if (el?.content?.buttons) {
      buttonsIds.push(...el.content.buttons.map(button => button.id));
    }
    if (el.templateType === TemplateTypes.RESULT && el?.content?.products) {
      const productsButtons = el.content.products.flatMap(prod => prod.buttonsSection.buttons);
      buttonsIds.push(...productsButtons.map(button => button.id));
    }
  });
  return buttonsIds.filter(Boolean);
}

// eslint-disable-next-line sonarjs/cognitive-complexity,max-params
export function setNextFlow(
  flow,
  rules,
  nextPage,
  currentChapterId,
  pagesFlow,
  setPagesFlow,
  setPageHistory,
  currentElements,
  buttonId = null,
  resultTableContent = null
) {
  const { goTo, showElements } = flow;
  const { allPages, currentPageId, prevPageIds, currentElementsIds, currentFlowElement } = pagesFlow;
  const { goToFlow } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['goToFlow'] });

  const updatedGoTo = goToFlow ? goToFlow.goTo : goTo;
  const updatedNextPage = goToFlow ? allPages?.find(page => page.elements.find(el => el.id === goToFlow.goTo)) : nextPage;
  const updatedShowElements = goToFlow ? [] : showElements;

  const nextPageCurrentElementsIds = setCurrentElementsIds(updatedNextPage, updatedGoTo, updatedShowElements);

  if (updatedGoTo === DestinationVariants.NEXT && updatedNextPage) {
    setPagesFlow(prev => ({
      ...prev,
      currentChapterId,
      currentPageId: updatedNextPage.id,
      prevPageIds: currentPageId === updatedNextPage?.id ? prevPageIds : [...prevPageIds, currentPageId],
      currentFlowElement: isLength(nextPage?.elements) ? updatedNextPage?.elements[0] : null,
      currentElementsIds: nextPageCurrentElementsIds,
      resultTableContent,
      clickedButtonsIds: buttonId ? [...prev.clickedButtonsIds, buttonId] : prev.clickedButtonsIds,
    }));
  }

  if (updatedGoTo && updatedGoTo !== DestinationVariants.NEXT && updatedNextPage) {
    setPagesFlow(prev => ({
      ...prev,
      currentChapterId,
      currentPageId: updatedNextPage.id,
      prevPageIds: currentPageId === updatedNextPage?.id ? prevPageIds : [...prevPageIds, currentPageId],
      currentFlowElement: isLength(updatedNextPage?.elements) ? updatedNextPage?.elements.find(el => el.id === updatedGoTo) : null,
      currentElementsIds: nextPageCurrentElementsIds,
      resultTableContent,
      clickedButtonsIds: buttonId ? [...prev.clickedButtonsIds, buttonId] : prev.clickedButtonsIds,
    }));
  }

  if (updatedNextPage && updatedNextPage.id !== currentPageId) {
    setHistoryPages(allPages, currentPageId, currentElementsIds, setPageHistory, currentFlowElement);
  }
}

export function getNextElementsIdsOnPage(flow, currentPageElements) {
  const initialNextElementId = getCurrentPageNextElementId(flow?.goTo, currentPageElements);
  const initialNextElementIdArr = initialNextElementId ? [initialNextElementId] : [];
  return {
    flowOnCurrentPage: Boolean(initialNextElementId),
    nextElementIds: isLength(flow?.showElements) && initialNextElementId ? flow?.showElements : initialNextElementIdArr,
  };
}

export function setNextElementsAndFlowOnPage(goTo, setPagesFlow, nextElementIds, currentPageElements, currentElement) {
  setPagesFlow(prev => {
    const { currentElementsIds } = prev;
    const updatedCurrentElementsIds = [...currentElementsIds, ...nextElementIds];
    const nextElement = currentPageElements.find(el => el.id === goTo);

    return {
      ...prev,
      currentElementsIds: updatedCurrentElementsIds,
      currentFlowElement: nextElement || currentElement,
    };
  });
}

export function getConditionsAndRulesCurrentFlow(pagesFlow, currentElements) {
  const { allPages, currentFlowElement } = pagesFlow;
  const currentElement = allPages
    .find(page => page?.elements.find(el => el.id === currentFlowElement?.id))
    ?.elements.find(el => el.id === currentFlowElement?.id);

  const { goToFlow } = rulesEngine({ rules: currentFlowElement?.rules, pagesFlow, currentElements, rulesFilter: ['goToFlow'] });
  if (goToFlow) {
    return { goTo: goToFlow.goTo };
  }

  if (currentFlowElement?.flow?.elseGoTo) {
    return getQuestionConditionsFlow(currentFlowElement.flow, currentFlowElement?.content.multipleChoice, currentElement.selectedAnswers);
  }

  if (currentFlowElement?.flow?.counterAnswersElseGoTo) {
    return getQuestionCounterRangeFlow(currentFlowElement.flow, currentElement?.content.counterAnswers);
  }

  return currentFlowElement?.flow;
}
