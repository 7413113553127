import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { CardSection } from '../../../../../ui/CardSection';
import ReactQuill from 'react-quill';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';

export function ContactFormSuccessText({ structureItemIndex, templateContent }) {
  const { updateStructure } = useUpdateStructure();

  const changeSuccessText = value => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.mail.success`, value);
    });
  };

  return (
    <CardSection withPaddingBottom withBorderBottom={false}>
      <CardSectionHeader>
        <AppText strong textValue='Success state' />
      </CardSectionHeader>
      <ReactQuill style={{ marginTop: '24px' }} theme='snow' value={templateContent?.mail?.success} onChange={changeSuccessText} />
    </CardSection>
  );
}
