import { InfoIcon } from '../../../../../../../../../../../../ui/icons/InfoIcon';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { DepotProducts } from '../../../../../../../PreviewPage/components/PreviewResultDepotLayout/DepotProducts';
import { Buttons } from '../ResultLayout/components/Buttons';

/**
 * @desc Layout for Result Depot template
 * @param {Object} selectedElement
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
export function ResultDepotLayout({ selectedElement, style, stylesConfiguration }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const { rootStyle = {}, headlineStyle = {}, buttonsStyle = {} } = style;

  const element = selectedElement ? questionnaireStructure.find(elem => elem.id === selectedElement.id) : {};

  const {
    content: { headline, buttons, products },
  } = element;

  const { fonts, colors } = stylesConfiguration;

  const { heading2 } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  return (
    <section
      style={{
        width: 1920,
        height: 813,
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 452px',
          ...rootStyle,
        }}
      >
        {headline && (
          <h2
            style={{
              ...fonts.headlineSecond,
              marginBottom: 15,
              marginTop: 32,
              overflow: 'hidden',
              wordBreak: 'break-all',
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.headlineSecond.fontSize,
              fontFamily: fonts.headlineSecond.font,
              fontWeight: fonts.headlineSecond.fontWeight,
              color: colors[fonts.headlineSecond.colorVariant],
              ...headlineStyle,
            }}
          >
            {headline.value || heading2()}
            {headline?.infoIcon?.value && (
              <span style={{ marginLeft: 5 }}>
                <InfoIcon />
              </span>
            )}
          </h2>
        )}
        <DepotProducts products={products} fonts={fonts} colors={colors} buttonsStyle={buttonsStyle} disabled />
        <Buttons buttons={buttons} colors={colors} buttonsStyle={buttonsStyle} />
      </div>
    </section>
  );
}
