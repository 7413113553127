import { HttpStatusCode } from './http-status-code';

export function checkIsHttpServerError(code) {
  return code >= HttpStatusCode.INTERNAL_SERVER_ERROR;
}

export function checkIsHttpError(code) {
  return code >= HttpStatusCode.BAD_REQUEST;
}

// export function checkIsValidHttpCode(code) {
//   return code >= HttpStatusCode.CONTINUE && code <= HttpStatusCode.HTTP_VERSION_NOT_SUPPORTED;
// }

/**
 * @desc not BE (API), not user, but exactly FE error
 */
export function checkIsHttpFrontEndError(axiosErrorData) {
  return (
    HttpStatusCode.NOT_FOUND === axiosErrorData?.status || (HttpStatusCode.BAD_REQUEST === axiosErrorData?.status && axiosErrorData?.fieldErrors)
  );
}
