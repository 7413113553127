/* eslint-disable react/no-array-index-key */
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { HeadCell } from './HeadCell';
import { GripVerticalIcon } from '../../../../../../../../../../ui/icons/GripVerticalIcon';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { useContentTable } from '../hooks/useContentTable';
import { ComparisonTableDraggableId } from '../comparison-table-utils';

export function HeaderDragColumnsCells() {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { contentTableState } = useContentTable();

  return contentTableState?.tableRows?.[0]?.cells.map((_item, columnIndex) => (
    <Draggable
      key={columnIndex}
      draggableId={JSON.stringify({
        type: 'column',
        [ComparisonTableDraggableId.STRUCTURE_INDEX]: contentTableState.structureItemIndex,
        [ComparisonTableDraggableId.TABLE_INDEX]: contentTableState.tableIndex,
        columnIndex,
      })}
      index={columnIndex}
      isDragDisabled={isViewMode}
    >
      {providedDraggable => (
        <HeadCell ref={providedDraggable.innerRef} rootProps={providedDraggable.draggableProps} innerProps={providedDraggable.dragHandleProps}>
          <AppBox disabled={isViewMode} style={{ display: 'flex', justifyContent: 'center' }}>
            <GripVerticalIcon style={{ transform: 'rotate(90deg)' }} />
          </AppBox>
        </HeadCell>
      )}
    </Draggable>
  ));
}
