import { v4 as uuidv4 } from 'uuid';

export function generateBulletPointItem() {
  return {
    id: uuidv4(),
    left: {
      value: '',
      infoIcon: null,
    },
    rightText: {
      value: '',
      infoIcon: null,
    },
  };
}
