import { useSelector } from 'react-redux';
import { PopupSelect } from '../PopupSelect';
import { components, getValue } from './ProductFieldsSelect-helpers';
import { onChangeFieldsSelect } from './ProductFieldsSelect-onChange';
import { useContentTable } from '../../hooks/useContentTable';

export function ProductFieldsSelect({ fieldLabels, options, rowIndex, columnIndex }) {
  const { tenantProducts } = useSelector(({ finders }) => finders);
  const { contentTableState } = useContentTable();
  const currentProductId = contentTableState.tableRows[rowIndex].cells[columnIndex]?.value;
  const selectedProduct = tenantProducts.find(item => item.id === currentProductId);
  const value = getValue(options, contentTableState.tableRows, selectedProduct, columnIndex);

  const onChange = newSelected => {
    contentTableState.updateTable(({ tableRows }) => {
      onChangeFieldsSelect(tableRows, newSelected, fieldLabels, selectedProduct, rowIndex, columnIndex);
    });
  };

  return <PopupSelect isMulti hideSelectedOptions={false} options={options} value={value} onChange={onChange} components={components} />;
}
