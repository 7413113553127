import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { AppParagraph } from '../../../../../../../../../ui/text/AppParagraph';
import styled from 'styled-components';
import { StructureItemId } from '../../../Hardcode';
import { getFontStyles, getTextStyles } from '../../../../../../../../../lib/util/helpers';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';

export function PreviewFormHeader({ resultObject, pagesFlow, colors, fonts }) {
  const { title, subTitle, subTitleFK, description, descriptionFK } = useLocalization().translations.unique.components.clientForm;
  const { id: resultObjectId } = resultObject;

  const subTitleText = pagesFlow?.isFormHardcoded && resultObjectId === StructureItemId.HAMBURGER_FK_RESULT ? subTitleFK : subTitle;
  const descriptionText = pagesFlow?.isFormHardcoded && resultObjectId === StructureItemId.HAMBURGER_FK_RESULT ? descriptionFK : description;

  return (
    <Root>
      <AppTitle level={2} textValue={title} style={{ ...getFontStyles(fonts?.headlineFirst), color: colors?.[ColorVariants.PRIMARY_COLOR] }} />
      <AppTitle level={3} textValue={subTitleText} style={{ ...getTextStyles(fonts?.headlineSecond, colors) }} />
      <AppParagraph as='p' textValue={descriptionText} style={{ ...getTextStyles(fonts?.body, colors), lineHeight: 1.43 }} />
    </Root>
  );
}

const Root = styled('div')({
  maxWidth: '586px',
  width: '100%',
});
