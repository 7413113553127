/* eslint-disable no-magic-numbers */
import { useCalculateTotalPrice } from './useCalculateTotalPrice';
import { getResultProducts } from '../utils';
import { TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';
import {
  checkCanShowProductAmount,
  getAddressAndCommunication,
  getMultipleProductAmount,
  getResultProductName,
} from '../components/PreviewResultLayout/helpers';
import { useProductRowPrice } from './useProductRowPrice';
import { ResultKind } from '../../../../../../../lib/models/Questionnaire';
import { getDepotPdfProps } from './getDepotPdfProps';
import { getFontObj } from './getFontObj';
import { getRulesProductPrice } from '../../../utils/rules/product_price_rules_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

// eslint-disable-next-line max-lines-per-function
export function useResultProps({
  allPages,
  resultObject = {},
  fonts,
  colors,
  headlineStyle,
  sublineStyle,
  pagesFlow,
  fontsForLoading,
  pageHistory,
  showChapterIds,
}) {
  const { applyVariablesToObject } = useVariables(pagesFlow);
  const allQuestions = allPages?.flatMap?.(page => page?.elements?.filter(el => el?.templateType === TemplateTypes.QUESTION));
  const Q6 = allQuestions?.[5];
  const {
    content: { headline, subline, productInformation, buttons, pdfSettings, additionalInformation = [], kind = '', products, sortProductsBy } = {},
  } = resultObject;

  const resultProducts = getResultProducts(pagesFlow, Q6, productInformation, sortProductsBy, showChapterIds);
  const totalPrice = useCalculateTotalPrice(resultProducts, pagesFlow, productInformation);
  const genericProps = { fonts, colors };
  const tenant = pagesFlow?.tenant;

  const headlineProps = {
    ...genericProps,
    headline,
    headlineStyle,
  };

  const sublineProps = {
    ...genericProps,
    sublineStyle,
    subline,
  };

  const resultPdfProps = {
    tenantLogo: tenant?.logo?.cdnUrl,
    finderName: pagesFlow.finder?.name,
    finderUrl: pagesFlow.finder?.url,
    fonts: {
      headlineSecond: {
        family: fonts?.headlineSecond?.font,
        link: fontsForLoading?.find(item => item?.fontName === fonts?.headlineSecond?.font)?.fontUrl,
        size: fonts?.headlineSecond?.fontSize,
        color: colors?.[fonts?.headlineSecond?.colorVariant],
        weight: fonts?.headlineSecond?.fontWeight,
      },
      body: {
        family: fonts?.body?.font,
        link: fontsForLoading?.find(item => item?.fontName === fonts?.body?.font)?.fontUrl,
        size: fonts?.body?.fontSize,
        color: colors?.[fonts?.body?.colorVariant],
        weight: fonts?.body?.fontWeight,
      },
    },
    settings: {
      showPrices: productInformation?.priceType !== 'No price',
      showProductNames: productInformation?.name,
      showProductPageLink: productInformation?.externalProductPageLink,
      showProductPictures: productInformation?.picture,
      showTotalPriceBlock: productInformation?.showTotalPrice,
      showDate: pdfSettings?.showDate,
      showLink: pdfSettings?.showLink,
    },
    total: {
      word: productInformation?.priceName?.value,
      price: totalPrice?.totalPriceOfMostRepeatedCurrency,
    },
    productList: resultProducts.map(item => {
      const multipleProductAmount = getMultipleProductAmount(item);

      const { showPrice, rulePrice, rulePriceType } = getRulesProductPrice(item, pagesFlow, [], false);

      return {
        name: getResultProductName(productInformation, item),
        // eslint-disable-next-line react-hooks/rules-of-hooks
        price: useProductRowPrice(item, productInformation, showPrice, rulePrice, rulePriceType),
        productPageLink: item?.productPageLink,
        canShowProductAmount: checkCanShowProductAmount(multipleProductAmount),
        multipleProductAmount,
        image: item?.image,
      };
    }),
    contacts: {
      bankName: tenant?.bankName,
      addressAndCommunication: getAddressAndCommunication(tenant),
    },
    bodyFontSize: `${fonts?.body?.fontSize}px`,
    bodyColor: colors?.[fonts?.body?.colorVariant],
    title: headline?.value,
    subTitle: subline?.value,
    tenantName: tenant?.name,
  };

  const getFont = fontType => getFontObj(fontsForLoading, fonts, colors, fontType);

  const printedResultProps =
    kind === ResultKind.DEPOT
      ? getDepotPdfProps({
          tenant,
          pagesFlow,
          getFont,
          headline,
          allQuestions,
          additionalInformation,
          pdfSettings,
          products,
          colors,
          pageHistory,
        })
      : resultPdfProps;

  const resultProps = {
    printedResultProps,
    sublineProps,
    headlineProps,
    headline,
    subline,
    buttons,
    productInformation,
    totalPrice,
    resultProducts,
  };

  return applyVariablesToObject(resultProps);
}
