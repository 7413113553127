import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { QuestionnaireBasicButton } from '../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../ui/icons/DeleteIcon';
import { ColumnsRowsFields } from './ColumnsRowsFields';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useContentTable } from '../hooks/useContentTable';
import { humanizeIndex } from '../../../../../../../../../../lib/util/helpers';

const MINIMUM_DISPLAYED_TABLES = 1;

export function TitleSection() {
  const { contentTableState } = useContentTable();
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;
  const showDeleteButton = humanizeIndex(contentTableState.tableIndex) > MINIMUM_DISPLAYED_TABLES;

  const deleteTable = () => {
    contentTableState.updateTableList(tableList => {
      tableList.splice(contentTableState.tableIndex, 1);
    });
  };

  return (
    <div style={styles.root}>
      <div style={styles.left}>
        <AppText className='template_card_headline' textValue={contentTableText.title} style={styles.title} />
        {showDeleteButton && (
          <QuestionnaireBasicButton onClick={deleteTable}>
            <DeleteIcon />
          </QuestionnaireBasicButton>
        )}
      </div>

      <ColumnsRowsFields />
    </div>
  );
}

const styles = {
  root: {
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    margin: '0px',
  },
  left: {
    gap: '13px',
    display: 'flex',
    alignItems: 'center',
  },
};
