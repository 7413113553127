import styled from 'styled-components';
import { BulletPoints } from './BulletPoints';
import { DepotProductButtons } from './DepotProductButtons';
import { DetailTable } from './DetailTable';
import { Fragment } from 'react';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';
import { ProductHeader } from './ProductHeader';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

export function DepotProducts({ products, fonts, colors, setPagesFlow, pagesFlow, setPageHistory, currentElements, disabled }) {
  const { windowWidth } = useWindowResize();
  const { applyVariable } = useVariables(pagesFlow);

  const getStyledImage = product => (
    <StyledImageWrapper windowWidth={windowWidth}>
      {product.productNameSection.showPicture && product.image && <StyledImage src={product.image} alt='img' />}
    </StyledImageWrapper>
  );

  return (
    <Root windowWidth={windowWidth}>
      {products.map((product, index) => {
        const LAST_INDEX = products.length - 1;
        const isLastIndex = LAST_INDEX === index;
        return (
          <Fragment key={product.id}>
            <StyledCard windowWidth={windowWidth}>
              {windowWidth > AppBreakpoint.TABLET_LANDSCAPE && getStyledImage(product)}
              <StyledMain>
                <ProductHeader product={product} colors={colors} fonts={fonts} applyVariable={applyVariable} />
                <BulletPoints product={product} colors={colors} fonts={fonts} applyVariable={applyVariable} />
                {windowWidth < AppBreakpoint.TABLET_LANDSCAPE && getStyledImage(product)}
                <DepotProductButtons
                  buttons={
                    pagesFlow
                      ? product.buttonsSection.buttons.filter(button => pagesFlow.allShownButtonsIds.includes(button.id))
                      : product.buttonsSection.buttons
                  }
                  colors={colors}
                  setPagesFlow={setPagesFlow}
                  pagesFlow={pagesFlow}
                  fonts={fonts}
                  setPageHistory={setPageHistory}
                  currentElements={currentElements}
                  disabled={disabled}
                />
              </StyledMain>
            </StyledCard>
            {product.detailTableSection.showDetailTable && (
              <DetailTable detailTables={product.detailTableSection.detailTables} colors={colors} fonts={fonts} applyVariable={applyVariable} />
            )}
            {!isLastIndex && <Divider />}
          </Fragment>
        );
      })}
    </Root>
  );
}

const Root = styled('div')(props => ({
  marginBottom: props.windowWidth > AppBreakpoint.M ? '32px' : '16px',
}));

const StyledCard = styled('div')(props => ({
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: ' 0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
  padding: 24,
  display: 'flex',
  flexDirection: props.windowWidth > AppBreakpoint.TABLET_LANDSCAPE ? 'row' : 'column',
}));

const StyledMain = styled('div')({
  flex: '1 1 100%',
});

const StyledImageWrapper = styled('div')(props => ({
  order: props.windowWidth > AppBreakpoint.TABLET_LANDSCAPE ? 0 : 1,
  textAlign: props.windowWidth > AppBreakpoint.M ? 'left' : 'center',
}));

const StyledImage = styled('img')({
  width: 200,
  height: 200,
  marginRight: 24,
});

const Divider = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ccc',
  margin: '32px 0',
});
