import { components } from 'react-select';
import styled from 'styled-components';
import { CrossIcon } from '../../icons/CrossIcon';

export function MultiValueRemove(props) {
  return props.data.isDisabled ? null : (
    <Root {...props}>
      <CrossIcon style={{ padding: '2px', height: '100%', display: 'flex', alignItems: 'center' }} />
    </Root>
  );
}

const Root = styled(components.MultiValueRemove)({
  zIndex: 500,
});
