import { useCategorySearchDataColumn } from '../../components/search-data';
import { Category } from '../../lib/Category';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';

export function useCategoryNameColumn() {
  const { name: title } = useLocalization().translations.shared.columns;

  return useCategorySearchDataColumn({
    title,
    arrangedId: Category.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnsProps: {
      width: 310,
    },
  });
}
