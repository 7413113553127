import { TableHeaderCell } from '../../TableHeaderCell';
import { getArrangesInitialValues } from '../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../utils/hooks/useArrangeSubmit';
import { ArrangeDatePopup } from '../../popups/ArrangeDatePopup';

export function DateHeaderCell({ title, removeFilter, sortOrder, isActiveArrange, form, applyArranges, rootProps, filterLabel }) {
  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell
      title={title}
      filterLabel={filterLabel}
      removeFilter={removeFilter}
      sortOrder={sortOrder}
      isActiveArrange={isActiveArrange}
      form={form}
      rootProps={rootProps}
    >
      <ArrangeDatePopup initialValues={getArrangesInitialValues(form)} form={form} onFinish={onFinish} isActiveArrange={isActiveArrange} />
    </TableHeaderCell>
  );
}
