import { ProductPageLink } from './ProductPageLinks';
import { ProductPageLinkSectionHeader } from './ProductPageLinkSectionHeader';
import styled from 'styled-components';

export function ProductPageLinkSection({ structureItemIndex, productIndex, value }) {
  return (
    <Root>
      <ProductPageLinkSectionHeader />
      <ProductPageLink productIndex={productIndex} value={value} structureItemIndex={structureItemIndex} />
    </Root>
  );
}

const Root = styled('div')({
  borderBottom: '1px solid #ECECEE',
  padding: '32px 24px 0 24px',
});
