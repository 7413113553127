import { useSelector } from 'react-redux';
import { isLength } from '../../../../../../../../../lib/util/helpers';
import { FIRST_INDEX } from '../../../../../../../../../lib/util/constants';
import { getOptions } from './components/ProductFieldsSelect/ProductFieldsSelect-helpers';

export function useProductFieldList(fieldLabels) {
  const { tenantProducts } = useSelector(({ finders }) => finders);
  const productKeys = isLength(tenantProducts) ? Object.keys(tenantProducts[FIRST_INDEX]) : [];
  return getOptions(productKeys, fieldLabels);
}
