import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { IconButton } from '../../../../../../../../features/IconButton';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import { ChapterStateFormItem } from '../../../../../../../../features/form-items/ChapterStateFormItem';
import { ChapterSelectFormItem } from '../../../../../../../../features/form-items/ChapterSelectFormItem';
import { CustomSwitch } from '../../../../../../../../features/CustomSwitch';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { ConditionTypes, TemplateTypes } from '../../../../../../../../../../../../lib/models/TemplateTypes';
import '../../../../style.scss';

/**
 * @desc Chapter Condition Block
 * @param {Number} index
 * @param {Object} conditionItem
 * @param {Array} conditions
 * @param {Function} handleChangeFieldValue
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function ChapterConditionBlock({ index, conditionItem, conditions, handleChangeFieldValue, disabled }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { if: ifText, is, and, or } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  const chapters = questionnaireStructure.filter(el => el.templateType === TemplateTypes.CHAPTER);

  const toggleCondition = () => {
    const updateCondition = conditionItem.condition === ConditionTypes.AND ? ConditionTypes.OR : ConditionTypes.AND;
    const updatedConditionsArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, condition: updateCondition } : condItem;
    });
    handleChangeFieldValue(updatedConditionsArr);
  };

  const selectChapters = valuesArr => {
    const updatedConditionsArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, chapters: valuesArr } : condItem;
    });
    handleChangeFieldValue(updatedConditionsArr);
  };

  const selectStates = valuesArr => {
    const updatedStatesArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, states: valuesArr } : condItem;
    });
    handleChangeFieldValue(updatedStatesArr);
  };

  const deleteCondition = () => {
    const updatedStatesArr = conditions.filter((condItem, condIndex) => condIndex !== index);
    handleChangeFieldValue(updatedStatesArr);
  };

  return (
    <div>
      {index !== 0 && (
        <div className='align_items_center space_between' style={{ marginBottom: 16 }}>
          <div style={{ width: 108 }}>
            <CustomSwitch
              items={[and, or]}
              status={conditionItem.condition === ConditionTypes.AND}
              onClick={() => toggleCondition()}
              itemWidth={50}
              disabled={disabled}
            />
          </div>
          <IconButton onClick={deleteCondition} disabled={disabled} icon={<DeleteIcon className='flow_process_box_delete_icon' />} />
        </div>
      )}
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={ifText} className='flow_process_box_select_title' />
        <ChapterSelectFormItem value={conditionItem.chapters} onChange={selectChapters} disabled={disabled} options={chapters} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={is} className='flow_process_box_select_title' />
        <ChapterStateFormItem value={conditionItem.states} onChange={selectStates} disabled={disabled} options={chapters} />
      </div>
    </div>
  );
}
