import styled from 'styled-components';
import { AppTitle } from '../../../../../../../../../../../../ui/text/AppTitle';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductLayoutCardBulletPoints } from './ProductLayoutCardBulletPoints';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { AppImage } from '../../../../../../../../../../../../ui/AppImage';
import { isLength, isTruthy } from '../../../../../../../../../../../../lib/util/helpers';
import { InfoIcon } from '../../../../../../../../../../../../ui/icons/InfoIcon';
import { formatPriceForUi, transformCurrencyCodeToSymbol } from '../../../../../../../../../../../../lib/models/Finance';
import { Typography } from 'antd';
import { InfoIconComponent } from '../../../../../../../PreviewPage/components/InfoIconComponent';
import { LayoutDesign } from '../../../../../../../../../../../../lib/models/Questionnaire';

// eslint-disable-next-line max-lines-per-function
export function ProductLayoutCard(props) {
  const { unique, shared } = useLocalization().translations;
  const { templates } = unique.pages.questionnaire.rightSideBar.layout;
  const { data, bulletPointIconSvg, bulletPointIconColor, allData, bulletPointIconUrl, children, layoutDesign = {}, ...rest } = props;

  const selectable = allData?.productSelection;

  const Subline = (
    <Typography.Paragraph
      ellipsis={{ rows: 3 }}
      style={{
        whiteSpace: 'break-spaces',
        lineHeight: 1.2,
      }}
    >
      {data.subline || templates.subline}
      <InfoIconComponent type={data?.sublineInfoIcon?.value} styles={{ top: 3 }} />
    </Typography.Paragraph>
  );

  const BulletPoints = (
    <ProductLayoutCardBulletPoints
      bulletPointsSection={data.bulletPointsSection}
      bulletPointIconSvg={bulletPointIconSvg}
      bulletPointIconColor={bulletPointIconColor}
      bulletPointIconUrl={bulletPointIconUrl}
    />
  );

  const priceAndCurrency = `${formatPriceForUi(data?.price?.amount)} ${transformCurrencyCodeToSymbol(data?.price?.currency)}`;

  const Content = (
    <div style={{ width: '100%' }}>
      {Subline}
      {isLength(data.bulletPointsSection.bulletPoints) && BulletPoints}
      {data?.price?.show && (
        <div style={{ textAlign: 'right' }}>
          <AppText textValue={isTruthy(data?.price?.amount) ? priceAndCurrency : '‎'} />
          {data.price?.infoIconPrice?.value && (
            <span style={{ position: 'relative' }}>
              <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 0 }} />
            </span>
          )}
        </div>
      )}
      {allData?.showSavings && (
        <div style={{ marginLeft: 'auto', marginTop: 8, display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
            <AppText textValue='20 USD' />
            <AppText textValue={templates.savings} style={{ fontSize: '12px' }} />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Root {...rest}>
      <Card recommended={data.recommended}>
        {selectable && <SelectCircleButton />}

        {data.customSign?.isEnabled && (
          <RecommendedLabel
            style={{
              maxWidth: '40%',
              overflow: 'hidden',
              padding: '0 16px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxHeight: '40px',
              color: '#fff',
            }}
          >
            <AppText strong textValue={data.customSign?.sign} style={{ color: '#fff' }} />
          </RecommendedLabel>
        )}

        <div style={{ width: '100%' }}>
          <AppTitle
            level={3}
            textValue={data.headline || templates.heading3}
            style={{ textAlign: layoutDesign.title === LayoutDesign.NORMAL_MODE_CAROUSEL ? 'center' : 'initial' }}
          />

          {data.image && data.showPicture ? (
            <Columns>
              {layoutDesign.title === LayoutDesign.NORMAL_MODE_CAROUSEL ? (
                <FullImageWrapper>
                  <AppImage src={data.image} alt={shared.generic.productImageAlt} />
                </FullImageWrapper>
              ) : (
                <>
                  <DefaultImageWrapper>
                    <AppImage src={data.image} alt={shared.generic.productImageAlt} />
                  </DefaultImageWrapper>
                  {Content}
                </>
              )}
            </Columns>
          ) : (
            Content
          )}
        </div>
      </Card>

      {children}
    </Root>
  );
}

const Root = styled('li')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  maxHeight: '570px',
  position: 'relative',
});

const SelectCircleButton = styled('div')({
  border: '2px solid #5C5C6E',
  width: '35px',
  height: '35px',
  position: 'absolute',
  top: '-17.5px',
  left: '-17.5px',
  background: '#fff',
  borderRadius: '50%',
});

const Columns = styled('div')({
  gap: '16px',
  display: 'flex',
});

const DefaultImageWrapper = styled('div')({
  width: '70px',
  height: '44px',
  borderRadius: '4px',
  overflow: 'hidden',

  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

const FullImageWrapper = styled('div')({
  width: '100%',
  height: 'auto',
  borderRadius: '4px',
  overflow: 'hidden',

  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

const Card = styled('div')(({ recommended }) => ({
  padding: '32px 40px 40px !important',
  border: recommended ? '2px solid #DA1B5E' : '2px solid #5C5C6E',
  borderRadius: '4px',
  position: 'relative',
  width: '460px',
  maxHeight: '482px',
  display: 'flex',
  flex: 1,
}));

const RecommendedLabel = styled('div')({
  position: 'absolute',
  top: '0px',
  right: '34px',

  padding: '8px 16px',
  borderRadius: '0 0 8px 8px',
  background: '#DA1B5E',
});
