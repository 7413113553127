import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectRule } from '../../../../../../../../../lib/store/slices/rulesSlice';
import { ROUTER_PATHS } from '../../../../../../../../../lib/util/router/router-paths';
import { ActionsDropdown } from '../../../../../features/ActionsDropdown';
import { EditIcon } from '../../../../../../../../../ui/icons/EditIcon';
import { IconButton } from '../../../../../features/IconButton';
import { AppTooltip } from '../../../../../../../../../ui/AppTooltip';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';

/**
 * @desc Component for rendering actions in the body cell of the rule table.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.row - The data object representing the row.
 * @returns {JSX.Element} - The rendered component.
 */
export function RuleActionsBodyCell({ row }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenant, finder } = useSelector(({ finders }) => finders);

  const { rules } = useLocalization().translations.unique.pages;

  const editRule = () => {
    dispatch(selectRule(row));
    navigate(`${ROUTER_PATHS.builder(finder.id)}?X-TENANT=${tenant.id}`);
  };

  return (
    <Space size='middle'>
      <AppTooltip placement='leftBottom' text={rules.tooltip.edit} overlayInnerStyle={{ padding: '8px 16px' }}>
        <span>
          <IconButton icon={<EditIcon styles='secondary_color' />} onClick={() => editRule()} />
        </span>
      </AppTooltip>
      <ActionsDropdown table='rules' row={row} />
    </Space>
  );
}
