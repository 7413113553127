import { useMemo } from 'react';
import { Product } from '../../../FindersAndProductsPage/lib/Product';
import { QuestionElementId } from '../../models/Hardcode';

import { getRulePriceArray } from '../../utils';

export function useTotalPriceStringCounter({ Q6, P9, P10, selectedElement, counterQ6, counterValue, ruleCounter, pagesFlow, price, priceType }) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    if (selectedElement && selectedElement?.counterSection?.value) {
      const { counterSection, priceSection, id } = selectedElement;

      // TODO hardcode value for P9 and P10
      if (
        [QuestionElementId.HAMBURGER_VB_KONTOFINDER_PK_QUESTION_6, QuestionElementId.KONFOFINDER_PK_QUESTION_6].includes(Q6?.id) &&
        [P9?.id, P10?.id].includes(id)
      ) {
        if (counterQ6 === counterValue) {
          return 0;
        }
        if (counterQ6 > counterValue) {
          return 0;
        }
        if (P9?.id === id) {
          // eslint-disable-next-line no-magic-numbers
          return (counterSection.value - counterQ6) * 12;
        }
        if (P10?.id === id) {
          // eslint-disable-next-line no-magic-numbers
          return (counterSection.value - counterQ6) * 24;
        }
      }

      if (priceType === Product.PRICE_PRE_REQUEST) {
        return price;
      }

      if (!ruleCounter) {
        const { rulePriceArray, priceArray } = getRulePriceArray(selectedElement, pagesFlow, priceType);
        return [...rulePriceArray, ...priceArray].reduce((sum, curr) => {
          return sum + Number(curr.rulePrice);
        }, 0);
      }

      if (ruleCounter) {
        return counterSection.value * Number(priceSection.priceValue);
      }
    }

    return 0;
  }, [selectedElement, price, ruleCounter, Q6?.id, P9?.id, P10?.id, priceType, counterQ6, counterValue, pagesFlow]);
}
