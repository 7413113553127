import { FlowButtonInfo } from '../components/FlowButtonInfo';
import { FlowOperation } from '../../../../../../../../../../../lib/models/Questionnaire';
import { FlowButtonLink } from '../components/FlowButtonLink';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { FlowButtonOperationWrapper } from '../components/FlowButtonOperationWrapper';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import classNames from 'classnames';
import classes from '../Flow.module.sass';

/**
 * @desc Flow Button Section
 * @param {String} buttonTitle
 * @param {String} buttonText
 * @param {String} buttonsField
 * @param {Number} buttonIndex
 * @param {String} structureItemId
 * @param {Object} buttonObject
 * @param {Array} options
 * @param {Array} elements
 * @param {Boolean} disabled
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowButtonSection({
  buttonTitle,
  buttonText,
  buttonsField,
  buttonIndex,
  structureItemId,
  buttonObject,
  options,
  elements,
  disabled,
  ...rest
}) {
  const flow = buttonObject?.flow;
  const { className, ...restProps } = rest;
  const { operation: title } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { operation, href, targetBlank } = flow;
  const sharedProps = { buttonIndex, structureItemId, buttonsField };

  const { updateStructure } = useUpdateStructure();

  const handleChangeButtonFlow = values => {
    updateStructure(draft => {
      const structureItem = draft?.find(iterated => iterated.id === structureItemId);
      if (structureItem) {
        structureItem.content[buttonsField][buttonIndex].flow = { ...structureItem.content[buttonsField][buttonIndex].flow, ...values };
      }
    });
  };

  const OperationAdditionalComponent = {
    [FlowOperation.LINK]: <FlowButtonLink href={href} targetBlank={targetBlank} {...sharedProps} />,
    // [FlowOperation.FORM_THEN_LINK]: (
    //   <>
    //     <SelectFormGoToFormItem
    //       elementId={structureItemId}
    //       value={goToForm}
    //       options={elements}
    //       onChange={handleChangeButtonFlow}
    //       disabled={disabled}
    //     />
    //     <FlowButtonLink href={href} targetBlank={targetBlank} {...sharedProps} />,
    //   </>
    // ),
    [FlowOperation.GO_TO_ELEMENT]: (
      <SelectMultiGoToFormItem elementId={structureItemId} flow={flow} options={elements} onChange={handleChangeButtonFlow} disabled={disabled} />
    ),
  };

  return (
    <div className={classNames(className, classes.buttonSection)} {...restProps}>
      <FlowButtonInfo buttonTitle={buttonTitle} buttonText={buttonText} />

      <FlowButtonOperationWrapper
        title={title}
        structureItemId={structureItemId}
        buttonIndex={buttonIndex}
        buttonsField={buttonsField}
        selectProps={{ options, value: operation }}
      >
        {OperationAdditionalComponent?.[operation]}
      </FlowButtonOperationWrapper>
    </div>
  );
}
