export const Finder = {
  COMMENT: 'comment',
  NAME: 'name',
  CREATOR: 'createdBy',
  URL: 'url',
  CREATION_DATE: 'createdDate',
  PUBLISHED_DATE: 'publishingDate',
  LAST_EDITED_DATE: 'lastModifiedDate',
  LAST_EDITED_BY: 'lastModifiedBy',
  VIEWS: 'countViews',
  COUNT_QUESTIONS: 'countQuestions',
  FINDER_TYPE: 'finderType',
  STATUS: 'status',
  ACTIVATED: 'activated',
  EDITING: 'editing',
};

export const FinderTableColumn = {
  STATUS: 'status',
};

export const FinderStatus = {
  PUBLISHED: 'PUBLISHED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  DEACTIVATED: false,
};

export const finderPublishingStatuses = [FinderStatus.PUBLISHED, FinderStatus.NOT_PUBLISHED];

export const FinderEditMode = {
  EDITING: true,
  BLANK: false,
};

export const FinderType = {
  PRIVATE: 'private',
  COMPANY: 'company',
};
