import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { LayoutStatusBodyCell } from './LayoutStatusBodyCell';
import { Layout, LayoutStatus } from '../../lib/Layout';
import { useLayoutArrangeRequests } from '../../lib/useLayoutArrangeRequests';
import {
  clearListFilters,
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../../utils/arranges-helpers';
import { useStatusColumn } from '../../../../../../components/columns/status';
import { useEnumArrange } from '../../../../../../utils/hooks/arranges-by-type/useEnumArrange';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

const arrangedId = Layout.IS_ACTIVE;

const pseudoAllFilters = {};

export function useLayoutStatusColumn() {
  const { status } = useLocalization().translations.shared;
  const { activeArrangedId, sorting } = useSelector(({ layouts }) => layouts);
  const [form] = Form.useForm();
  const isActiveArrange = activeArrangedId === arrangedId;
  const getItemsValues = () => getSelectedItemsValues(form);
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = handleTranslation(status.activated);
  const deactivatedLabel = handleTranslation(status.deactivated);

  const allOptions = [createOptionItem(activatedLabel, LayoutStatus.ACTIVATED), createOptionItem(deactivatedLabel, LayoutStatus.DEACTIVATED)];

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const areAllFiltersSelected = allOptions.length === itemsValues.length;
    return areAllFiltersSelected ? pseudoAllFilters : { activated: itemsValues?.[0] };
  };

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useLayoutArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearListFilters(form),
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      render: status => <LayoutStatusBodyCell status={status} />,
    },
  });
}
