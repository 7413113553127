import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 45;

const fontSizeOptions = Array.from({ length: MAX_FONT_SIZE - MIN_FONT_SIZE + 1 }, (_value, index) => ({
  value: MIN_FONT_SIZE + index,
  title: `${MIN_FONT_SIZE + index} pt`,
}));

export function FontSizeFormItem({ value, onChange, fieldName, field, disabled }) {
  return (
    <AppFormSection
      formItemProps={{
        style: { width: 92 },
      }}
    >
      <AppNewSelect
        disabled={disabled}
        onChange={selected => onChange(selected?.value, fieldName, field)}
        value={{ value }}
        options={fontSizeOptions}
      />
    </AppFormSection>
  );
}
