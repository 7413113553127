import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { LinearFlowIcon } from '../../../../../../../../../../ui/icons/LinearFlowIcon';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { NonLinearFlowIcon } from '../../../../../../../../../../ui/icons/NonLinearFlowIcon';
import { AppTooltip } from '../../../../../../../../../../ui/AppTooltip';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { LabelTexts, TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { getIndexByTemplateType } from '../../../../../../../../../../lib/util/helpers';
import { Typography } from 'antd';

/**
 * @desc Other item (Intro, Content, Question, Product, Result, Contact Form)
 * @param {Object} providedItem
 * @param {Object} snapshotItem
 * @param {Object} item
 * @param {Function} onOpenContextMenu
 * @param {Function} onClickScroll
 * @param {Array} items
 * @param {ReactNode} children
 * @return {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function OtherItem({ providedItem, snapshotItem, item, onOpenContextMenu, onClickScroll, items, children }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const { navigatorTab } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;

  const getFlowTextAndIcon = () => {
    if (item.templateType === TemplateTypes.QUESTION) {
      if (item.flow?.goTo === 'NEXT') {
        return { text: navigatorTab.linearFlow, icon: <LinearFlowIcon /> };
      }
      if (
        item.flow?.elseGoTo === 'NEXT' &&
        item.flow?.conditions?.every(condition => condition.elseIfGoTo === 'NEXT' && condition.ifGoTo === 'NEXT')
      ) {
        return { text: navigatorTab.linearFlow, icon: <LinearFlowIcon /> };
      }
      return { text: navigatorTab.nonLinearFlow, icon: <NonLinearFlowIcon /> };
    }
    return item.flow?.goTo === 'NEXT'
      ? { text: navigatorTab.linearFlow, icon: <LinearFlowIcon /> }
      : { text: navigatorTab.nonLinearFlow, icon: <NonLinearFlowIcon /> };
  };

  const { text: flowTooltipText, icon: flowIcon } = getFlowTextAndIcon();

  const itemIdByType = getIndexByTemplateType(items, item);

  const getTitle = () => {
    switch (item.templateType) {
      case TemplateTypes.CONTENT:
        return item.content?.headerHeadline?.value;
      case TemplateTypes.QUESTION:
        return item.content?.question?.value;
      case TemplateTypes.PRODUCT:
        return item.content?.headline?.value;
      case TemplateTypes.RESULT:
        return item.content?.headline?.value;
      default:
        return '';
    }
  };

  return (
    <AppBox
      as={Item}
      disabled={isViewMode}
      ref={providedItem.innerRef}
      {...providedItem.draggableProps}
      {...providedItem.dragHandleProps}
      style={{
        ...providedItem.draggableProps.style,
      }}
      isDragging={snapshotItem.isDragging}
      onContextMenu={event => onOpenContextMenu(event, item?.id)}
      isOpen={item.isOpen}
      onClick={() => onClickScroll(item.id)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AppText className='white_color' style={{ fontSize: 12, marginRight: 5, minWidth: 30 }}>
          {`${LabelTexts[item.templateType]}.${itemIdByType + 1}`}
        </AppText>
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{
            color: 'inherit',
            marginBottom: 0,
            maxWidth: 130,
            fontSize: 12,
          }}
        >
          {getTitle() || item.label}
        </Typography.Paragraph>
      </div>
      <AppTooltip text={flowTooltipText}>
        <span>{flowIcon}</span>
      </AppTooltip>
      <VerticalLine isDragging={snapshotItem.isDragging} />
      {children}
    </AppBox>
  );
}
const Item = styled('div')(props => ({
  display: props.isOpen ? 'flex' : 'none',
  justifyContent: 'space-between',
  userSelect: 'none',
  background: '#404055',
  color: '#fff',
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 400,
  padding: '10px 10px 10px 45px',
  borderBottom: props.isDragging && '1px solid #da1b5e',
  '&:hover': {
    background: '#5C5C6E',
  },
  position: 'relative',
  cursor: 'pointer',
}));

const VerticalLine = styled('div')(props => ({
  display: props.isDragging && 'none',
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  height: '100%',
  width: 1,
  top: 0,
  left: 18,
}));
