import { AppTitle } from '../../../../../../ui/text/AppTitle';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { Timeline } from 'antd';
import { Version } from './Version';
import styled from 'styled-components';

export function VersionsMenuInner(props) {
  const { selectProps } = props;
  const { questionnaireVersions } = useLocalization().translations.unique.components;
  const items = selectProps.options.map((item, index) => ({ children: <Version {...item} index={index} reactSelectProps={props} /> }));

  return (
    <Root>
      <Header level={5} textValue={questionnaireVersions.versions} />
      <VersionList items={items} mode='left' />
    </Root>
  );
}

const Root = styled('div')({
  padding: '16px 0 16px 16px',
});

const Header = styled(AppTitle)({
  margin: '0 0 12px',
  color: '#5C5C6E !important',
  fontSize: '12px !important',
});

const VersionList = styled(Timeline)({
  '& > li.ant-timeline-item': {
    paddingBottom: '0 !important',
    minHeight: 'unset',
    marginBottom: '6px',

    '.ant-timeline-item-tail': {
      top: '15px',
      background: '#CFCFD4',
      height: 'calc(100% + 2px)',
      left: '1.5px',
    },

    '.ant-timeline-item-head': {
      top: '10px',
      width: '5px',
      height: '5px',
      border: '1px solid #191919',
    },

    '.ant-timeline-item-content': {
      minHeight: 'unset',
    },

    '&:last-child': {
      margin: '0',
    },
  },
});
