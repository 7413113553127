import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { IconButton } from '../../../../../../../../features/IconButton';
import { EditIcon } from '../../../../../../../../../../../../ui/icons/EditIcon';
import { openFinderModal } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import { FinderModal } from '../../../../../../../../../../lib/models/Modal';
import { ActionsDropdown } from '../../../../../../../../features/ActionsDropdown';
import { usePermissions } from 'src/lib/hooks/usePermissions';

export function FinderActionsBodyCell({ row }) {
  const { isRestricted } = usePermissions();
  const dispatch = useDispatch();

  return (
    <Space size='middle'>
      {!isRestricted() ? (
        <>
          <IconButton icon={<EditIcon />} onClick={() => dispatch(openFinderModal({ modalType: FinderModal.EDIT, selectedFinder: row }))} />
          <ActionsDropdown table='finders' row={row} />
        </>
      ) : null}
    </Space>
  );
}
