import { Link, useParams } from 'react-router-dom';
import { LeftArrow } from '../../../../ui/icons/LeftArrow';
import { AppText } from '../../../../ui/text/AppText';

import styles from './styles.module.scss';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import { AppButton } from '../../../../ui/buttons/AppButton';

import cn from 'classnames';
import { EyeIcon } from '../../../../ui/icons/EyeIcon';
import { QuestionnaireVersions } from '../FinderSubHeader/QuestionnaireVersions';
import { publishFinderRequest } from '../../../../lib/store/slices/findersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTER_PATHS } from '../../../../lib/util/router/router-paths';
import { useEffect } from 'react';
import { cleanPreviewStructure, writeNewStructure } from '../../../../lib/store/slices/previewSlice';

export function PreviewMainHeader({ onSelectResolution, currentResolution, SCREEN_RESOLUTIONS }) {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { finderId, tenantId, selectedQuestionnaireVersion } = useSelector(({ preview }) => preview);

  const { previewMainHeader } = useLocalization().translations.unique.components;

  const publishFinder = () => {
    dispatch(publishFinderRequest({ finderId, tenantId }));
  };

  useEffect(() => {
    if (selectedQuestionnaireVersion) {
      dispatch(writeNewStructure({ finderId }));
    }
  }, [dispatch, selectedQuestionnaireVersion, finderId]);

  useEffect(() => {
    return () => {
      dispatch(cleanPreviewStructure({ finderId }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <header className={styles.header}>
      <div className={styles.wrap}>
        <Link className={styles.backLink} to={`${ROUTER_PATHS.questionnaire(id)}?X-TENANT=${tenantId}`}>
          <LeftArrow styles={styles.icon} />
          <AppText className={styles.backLinkText} textValue={previewMainHeader.backLink} />
        </Link>

        <div className={styles.resolutionsContainer}>
          {SCREEN_RESOLUTIONS.map(item => (
            <button
              className={cn(styles.resolutionButton, currentResolution.name === item.name && styles.resolutionButtonActive)}
              onClick={() => onSelectResolution(item)}
              key={item.name}
              type='button'
            >
              {item.icon}
              <AppText
                className={cn(styles.resolutionText, currentResolution.name === item.name && styles.resolutionTextActive)}
                textValue={item.name}
              />
            </button>
          ))}
        </div>

        <div className={styles.actions}>
          <AppButton className={styles.previewButton} type='button'>
            <EyeIcon color='#E1497E' />
            <AppText className={styles.previewButtonText} textValue={previewMainHeader.preview} />
          </AppButton>

          <QuestionnaireVersions />

          <AppButton type='primary' className={styles.publishButton} textValue={previewMainHeader.publish} loading={false} onClick={publishFinder} />
        </div>
      </div>
    </header>
  );
}
