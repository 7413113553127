import { components } from 'react-select';

export function Group(props) {
  const groupStyles = {
    borderRadius: '5px',
  };

  return (
    <div style={groupStyles}>
      <components.Group {...props} />
    </div>
  );
}
