import { FlowButtonInfo } from '../components/FlowButtonInfo';
import { FlowOperation } from '../../../../../../../../../../../lib/models/Questionnaire';
import { FlowButtonLink } from '../components/FlowButtonLink';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { FlowButtonOperationWrapper } from '../components/FlowButtonOperationWrapper';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import classNames from 'classnames';
import classes from '../Flow.module.sass';

/**
 * @desc Flow Single Button Section
 * @param {String} buttonTextValue
 * @param {String} buttonName
 * @param {String} structureItemId
 * @param {Object} buttonObject
 * @param {Array} options
 * @param {Array} elements
 * @param {Boolean} disabled
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowSingleButtonSection({
  buttonLabelText,
  buttonTextValue,
  buttonName,
  structureItemId,
  buttonObject,
  options = [],
  elements,
  disabled,
  ...rest
}) {
  const flow = buttonObject?.flow;
  const { className, ...restProps } = rest;
  const { operation: title } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const operation = flow?.operation || FlowOperation.GO_TO_ELEMENT;
  const sharedProps = { buttonName, structureItemId };

  const { updateStructure } = useUpdateStructure();

  const handleChangeButtonFlow = values => {
    updateStructure(draft => {
      const structureItem = draft?.find(iterated => iterated.id === structureItemId);
      if (structureItem) {
        structureItem.content[buttonName].flow = { ...structureItem.content[buttonName].flow, ...values };
      }
    });
  };

  const OperationAdditionalComponent = {
    [FlowOperation.LINK]: <FlowButtonLink href={flow?.href} targetBlank={flow?.targetBlank} {...sharedProps} />,
    [FlowOperation.GO_TO_ELEMENT]: (
      <SelectMultiGoToFormItem elementId={structureItemId} flow={flow} options={elements} onChange={handleChangeButtonFlow} disabled={disabled} />
    ),
  };

  return (
    <div className={classNames(className, classes.buttonSection)} {...restProps}>
      <FlowButtonInfo buttonTitle={buttonLabelText} buttonText={buttonTextValue} />

      <FlowButtonOperationWrapper title={title} structureItemId={structureItemId} buttonName={buttonName} selectProps={{ options, value: operation }}>
        {OperationAdditionalComponent?.[operation]}
      </FlowButtonOperationWrapper>
    </div>
  );
}
