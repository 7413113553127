/* eslint-disable react/no-multi-comp */
import styles from '../styles.module.scss';
import { Typography } from 'antd';
import { InfoIconComponent } from '../../../InfoIconComponent';
import { useWindowResize } from 'src/processes/CMS/processes/management/utils/hooks/useWindowResize';

export function BulletPointsBlock({ isVerticalContentBlock, isHorizontal, bulletPoints, iconComponent, applyVariable, colors, fonts }) {
  const { windowWidth } = useWindowResize();

  const maxVerticalWidth = 860;

  const isMobileView = windowWidth < maxVerticalWidth;

  return (
    <div
      className={`${styles.bulletPointContainer} ${isHorizontal ? styles.horizontal : styles.vertical}`}
      style={{ paddingTop: isMobileView ? '8px' : 0 }}
    >
      {!isVerticalContentBlock || isHorizontal === true || isMobileView ? (
        <HorizontalBulletPoints
          bulletPoints={bulletPoints}
          iconComponent={iconComponent}
          fonts={fonts}
          applyVariable={applyVariable}
          colors={colors}
        />
      ) : (
        <VerticalBulletPoints bulletPoints={bulletPoints} iconComponent={iconComponent} fonts={fonts} applyVariable={applyVariable} colors={colors} />
      )}
    </div>
  );
}

function VerticalBulletPoints({ bulletPoints, iconComponent, fonts, applyVariable, colors }) {
  return (
    <>
      <div className={styles.bulletPointHeader}>
        {bulletPoints &&
          bulletPoints.map(bulletPoint => (
            <div key={bulletPoint.id}>
              <div style={{ margin: 8 }}>{iconComponent()}</div>
              <Typography.Paragraph
                style={{
                  marginBottom: 16,
                  textAlign: 'center',
                  lineHeight: '20px',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: 700,
                  color: colors[fonts.body.colorVariant],
                }}
              >
                {applyVariable(bulletPoint.headline)}
              </Typography.Paragraph>
            </div>
          ))}
      </div>

      <div className={styles.bulletPointContent}>
        {bulletPoints &&
          bulletPoints.map(bulletPoint => (
            <Typography.Paragraph
              key={bulletPoint.id}
              style={{
                marginBottom: 6,
                textAlign: 'center',
                lineHeight: '20px',
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
              }}
            >
              <span className={styles.contentItemText}>
                {applyVariable(bulletPoint.textValue)}

                <span className={styles.contentInfoIcon}>
                  <InfoIconComponent applyVariable={applyVariable} type={bulletPoint} />
                </span>
              </span>
            </Typography.Paragraph>
          ))}
      </div>
    </>
  );
}

function HorizontalBulletPoints({ bulletPoints, iconComponent, fonts, applyVariable, colors }) {
  return bulletPoints.map(bulletPoint => (
    <div key={bulletPoint.id} className={styles.bulletItem}>
      <div style={{ marginRight: '16px', marginTop: '4px' }}>{iconComponent()}</div>
      <div className={styles.bulletContent}>
        <Typography.Paragraph
          style={{
            marginBottom: 16,
            lineHeight: '20px',
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: 700,
            color: colors[fonts.body.colorVariant],
          }}
        >
          {applyVariable(bulletPoint.headline)}
        </Typography.Paragraph>

        <Typography.Paragraph
          key={bulletPoint.id}
          style={{
            marginBottom: 6,
            lineHeight: '20px',
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: fonts.body.fontWeight,
            color: colors[fonts.body.colorVariant],
          }}
        >
          <span className={styles.contentItemText}>
            {applyVariable(bulletPoint.textValue)}

            <span className={styles.contentInfoIcon}>
              <InfoIconComponent applyVariable={applyVariable} type={bulletPoint} />
            </span>
          </span>
        </Typography.Paragraph>
      </div>
    </div>
  ));
}
