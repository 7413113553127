import styled from 'styled-components';
import { AppList } from '../../../../../../../../../../../../ui/AppList';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../../lib/models/Questionnaire';
import { useFinderColors } from '../../../../../../hooks/useFinderColors';
import { Typography } from 'antd';
import { handleTranslation, isObj } from '../../../../../../../../../../../../lib/util/helpers';
import { InfoIconComponent } from '../../../../../../../PreviewPage/components/InfoIconComponent';
import { useWindowResize } from '../../../../../../../../utils/hooks/useWindowResize';

const getLeftValue = item => {
  return handleTranslation(item?.left?.value || (!isObj(item?.left) && item?.left) || '');
};

const getRightValue = item => {
  return handleTranslation(item?.rightText?.value || (!isObj(item?.rightText) && item?.rightText) || '');
};

export function ProductLayoutCardBulletPoints({
  bulletPointsSection,
  bulletPointIconSvg,
  bulletPointIconColor,
  bulletPointIconUrl,
  applyVariable = () => {},
  border = true,
  fonts,
  ...rest
}) {
  const colors = useFinderColors();
  const fill = colors?.[bulletPointIconColor];

  const { windowWidth } = useWindowResize();

  const createSvg = () => {
    return { __html: bulletPointIconSvg };
  };

  const textStyle = {
    fontSize: fonts?.body?.fontSize,
    fontFamily: fonts?.body?.font,
    fontWeight: fonts?.body?.fontWeight,
    color: colors?.[fonts?.body?.colorVariant],
  };

  return (
    <Root border={border} {...rest} windowWidth={windowWidth}>
      {bulletPointsSection.bulletPoints.map(item => (
        <li key={item.id}>
          <div>
            <Typography.Text style={textStyle}>{applyVariable(getLeftValue(item))}</Typography.Text>
            <InfoIconComponent
              applyVariable={applyVariable}
              type={item?.left}
              styles={{ top: 2 }}
              style={{ display: item?.left?.value || (!isObj(item?.left) && item?.left) ? 'inline' : 'block', minHeight: 16 }}
            />
            <InfoIconComponent applyVariable={applyVariable} type={item.infoIconLeft} styles={{ top: 2 }} />
          </div>
          {bulletPointsSection.rightColumnType === ProductRightBulletPointColumnType.ICON ? (
            <div style={{ minWidth: 'fit-content' }}>
              {bulletPointIconSvg && !bulletPointIconSvg.includes('fill') ? (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={createSvg()} style={{ fill: fill || '#000000' }} />
              ) : (
                <img style={{ width: 24, height: 24 }} src={bulletPointIconUrl} alt='bullet icon' />
              )}
            </div>
          ) : (
            <Typography.Text align='right' style={{ ...textStyle, minHeight: 16, minWidth: 'fit-content' }}>
              {applyVariable(getRightValue(item))}
              <InfoIconComponent applyVariable={applyVariable} type={item?.rightText} styles={{ top: 2 }} />
              <InfoIconComponent applyVariable={applyVariable} type={item.infoIconRight} styles={{ top: 2 }} />
            </Typography.Text>
          )}
        </li>
      ))}
    </Root>
  );
}

const Root = styled(AppList)({
  '&': {
    marginBottom: props => props.windowWidth && '15px',
  },
  '& > li': {
    padding: '8px 0',
    borderBottom: props => (props.border ? '1px solid #E3E3E6' : 'none'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: 1,
  },
});
