import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ProductAnswerSavings } from './ProductAnswerSavings';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import '../style.scss';

/**
 * @desc Question Answer Savings component.
 * Renders the savings options for a selected question answer.
 * @param {Object} questionElement - The question element object.
 * @param {Object} selectedQuestion - The selected question object.
 * @returns {JSX.Element} - JSX element representing the Question Answer Savings component.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function QuestionAnswerSavings({ questionElement, selectedQuestion }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, tenantProducts, isViewMode } = useSelector(({ finders }) => finders);

  const { clearAll, addProduct: addProductText } = useLocalization().translations.shared.generic;
  const { selectAnswer: selectAnswerText, calculateSavings } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.savings;
  const { if: ifText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  const clearAllAnswerScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              answers: el.content.answers.map(answer => {
                return answer.id === selectedQuestion.answerId
                  ? { ...answer, savings: answer.savings.map(savingItem => ({ ...savingItem, savingsValue: 0 })) }
                  : answer;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const addProductSavings = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              answers: el.content.answers.map(answer => {
                return answer.id === selectedQuestion.answerId
                  ? { ...answer, savings: [...answer.savings, { id: uuidv4(), productId: null, savingsValue: 0 }] }
                  : answer;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const answerObject = questionElement?.content?.answers?.find(answer => answer.id === selectedQuestion.answerId);
  const answerIndex = questionElement?.content?.answers?.findIndex(answer => answer.id === selectedQuestion.answerId);

  return questionElement?.content?.answers?.some(answer => answer.id === selectedQuestion.answerId) ? (
    <>
      <div className='products_savings_clear_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={clearAllAnswerScore} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={clearAll} />
        </AppBasicButton>
      </div>
      <div className='products_savings_block'>
        <AppText className='products_savings_text' textValue={ifText} />
        <div className='products_savings_title_wrap'>
          <AppText className='products_savings_title' ellipsis>{`A.${answerIndex + 1} ${answerObject.value}`}</AppText>
        </div>
        <AppText className='products_savings_text' textValue={calculateSavings} />
        {answerObject.savings.map(savingObj => (
          <ProductAnswerSavings key={savingObj.id} savingObj={savingObj} selectedQuestion={selectedQuestion} savings={answerObject.savings} />
        ))}
        <div className='products_savings_add_btn'>
          <AppBasicButton
            style={{ display: 'inline-flex' }}
            onClick={addProductSavings}
            disabled={isViewMode || tenantProducts.length <= answerObject.savings.length}
          >
            <AppText className='primary_color bold' textValue={addProductText} />
          </AppBasicButton>
        </div>
      </div>
    </>
  ) : (
    <AppParagraph className='products_score_description' textValue={selectAnswerText} />
  );
}
