import { useSelector } from 'react-redux';
import { checkObjectWithValue } from '../../../../../../lib/util/helpers';
import { DeleteIcon } from '../../../../../../ui/icons/DeleteIcon';
import { DndInfoIcon } from '../../../../../../ui/icons/DndInfoIcon';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { QuestionnaireFormSection } from '../../pages/QuestionnairePage/features/QuestionnaireFormSection';
import { useEffect, useState } from 'react';

const DELAY = 100;
let timer = null;
const dndInfoIconStyle = { marginRight: 5 };

export function TemplateFormSection({ valueObject, onChange, label, isPrefix, isSuffix, onClickSuffix, labelLeft, borderColor, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { textFieldProps: { disabled, ...restTextFieldProps } = {}, ...restProps } = rest;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueObject.value);
  }, [valueObject.value]);

  const suffix = (
    <AppBasicButton onClick={onClickSuffix} disabled={disabled || isViewMode}>
      <DeleteIcon />
    </AppBasicButton>
  );

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(value);
    }, DELAY);
  };

  return (
    checkObjectWithValue(valueObject) && (
      <QuestionnaireFormSection
        textFieldProps={{
          value,
          onChange: handleChange,
          label,
          prefix: isPrefix && <DndInfoIcon style={dndInfoIconStyle} />,
          suffix: isSuffix && suffix,
          disabled,
          ...restTextFieldProps,
        }}
        labelLeft={labelLeft}
        borderColor={borderColor}
        {...restProps}
      />
    )
  );
}
