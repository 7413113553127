import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { getArrangesInitialValues } from '../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../utils/hooks/useArrangeSubmit';
import { SortPopup } from '../../../../../../components/popups/SortPopup';
import { TableHeaderCell } from '../../../../../../components/TableHeaderCell';
import { useLayoutArrangeRequests } from '../../lib/useLayoutArrangeRequests';

export function LayoutNumberHeaderCell({ title, arrangedId, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ layouts }) => layouts);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();

  const { applyArranges } = useLayoutArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    form,
  });

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <SortPopup initialValues={getArrangesInitialValues(form)} form={form} onFinish={onFinish} isActiveArrange={isActiveArrange} />
    </TableHeaderCell>
  );
}
