export function HomeIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.354 1.64689C8.30756 1.60033 8.25238 1.56339 8.19164 1.53818C8.13089 1.51297 8.06577 1.5 8 1.5C7.93423 1.5 7.86911 1.51297 7.80837 1.53818C7.74762 1.56339 7.69245 1.60033 7.646 1.64689L1.646 7.64689C1.5996 7.69341 1.56282 7.74862 1.53777 7.80936C1.51272 7.8701 1.49988 7.93519 1.5 8.00089V15.0009C1.5 15.1335 1.55268 15.2607 1.64645 15.3544C1.74022 15.4482 1.86739 15.5009 2 15.5009H6.5C6.63261 15.5009 6.75979 15.4482 6.85355 15.3544C6.94732 15.2607 7 15.1335 7 15.0009V11.0009H9V15.0009C9 15.1335 9.05268 15.2607 9.14645 15.3544C9.24022 15.4482 9.36739 15.5009 9.5 15.5009H14C14.1326 15.5009 14.2598 15.4482 14.3536 15.3544C14.4473 15.2607 14.5 15.1335 14.5 15.0009V8.00089C14.5001 7.93519 14.4873 7.8701 14.4622 7.80936C14.4372 7.74862 14.4004 7.69341 14.354 7.64689L13 6.29389V3.00089C13 2.86829 12.9473 2.74111 12.8536 2.64734C12.7598 2.55357 12.6326 2.50089 12.5 2.50089H11.5C11.3674 2.50089 11.2402 2.55357 11.1464 2.64734C11.0527 2.74111 11 2.86829 11 3.00089V4.29389L8.354 1.64689ZM2.5 14.5009V8.20789L8 2.70789L13.5 8.20789V14.5009H10V10.5009C10 10.3683 9.94732 10.2411 9.85356 10.1473C9.75979 10.0536 9.63261 10.0009 9.5 10.0009H6.5C6.36739 10.0009 6.24022 10.0536 6.14645 10.1473C6.05268 10.2411 6 10.3683 6 10.5009V14.5009H2.5Z'
        fill='#5C5C6E'
      />
    </svg>
  );
}
