import { select, take } from 'redux-saga/effects';
import { appLogger } from '../../util/logger';

export function* watchAndLog() {
  while (true) {
    const action = yield take('*');
    const state = yield select();
    const { type, payload } = action;

    appLogger.groupCollapsed(type);
    appLogger.info('Payload:', payload);
    appLogger.info('action', action);
    appLogger.info('state after action', state);
    appLogger.groupEnd();
  }
}
