import { rulesEngine } from './rules_helper';
import { changeNumberValue } from './change_number_value';

export const getRulesProductCounter = (productCard, pagesFlow, currentElements, logger = true) => {
  if (!pagesFlow || !productCard) {
    return {};
  }

  const { rules, id: cardId, counterSection } = productCard;
  const { counterProduct } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['counterProduct'], logger });
  const cardCounterBlocks = counterProduct.filter(block => block?.counterProduct?.elements?.map(el => el.productId)?.includes(cardId));
  const counterValue = counterSection?.value || 0;
  const defaultValue = counterSection?.defaultValue || 0;
  const newCounterValue = defaultValue > 0 ? counterValue - defaultValue : counterValue;
  return getCounterValue(cardCounterBlocks, newCounterValue);
};

export const getCounterValue = (cardCounterBlocks, counterValue) => {
  return cardCounterBlocks.reduce(
    (accumulator, cardCounterBlock) => {
      const { counterProduct, resultOperator, value } = cardCounterBlock;

      if (counterProduct) {
        const ruleCounterValue = changeNumberValue(value, resultOperator, counterProduct, { value: counterValue });
        return {
          ruleCounter: ruleCounterValue < 0 ? 0 : ruleCounterValue,
          originalCounter: counterValue,
          resultOperator,
        };
      }

      return accumulator;
    },
    { ruleCounter: undefined, originalCounter: counterValue, resultOperator: null }
  );
};
