// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { AppSwitch } from 'src/ui/AppSwitch';
import { AppInput } from 'src/ui/AppInput';
import { AppText } from 'src/ui/text/AppText';
import { AppRadioGroup } from 'src/ui/AppRadioGroup';
import { AppRadio } from 'src/ui/AppRadio';
import { AppBasicButton } from 'src/ui/buttons/AppBasicButton';
import { ComparisonTopics } from './ComparisonTopics';

import { useLocalization } from 'src/lib/hooks/useLocalization';

import { DeleteIcon } from 'src/ui/icons/DeleteIcon';
import './detailTable.scss';

/* eslint-disable-next-line max-lines-per-function */
export function DetailTable({
  detailTable,
  tableIndex,
  structureItemIndex,
  deleteComparisonDetailTable,
  updateComparisonDetailTable,
  addTopic,
  deleteTopic,
  updateTopic,
  changeTopicOrder,
}) {
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;
  const { id, headline, subline, score, topics } = detailTable;

  const onFieldChange = (field, value) => {
    updateComparisonDetailTable(id, field, value);
  };

  const onScoreChange = (field, value) => {
    const updatedScoreItem = {
      isEnabled: score.isEnabled,
      productOne: score.productOne,
      productTwo: score.productTwo,
      [field]: value,
    };

    updateComparisonDetailTable(id, 'score', updatedScoreItem);
  };

  return (
    <div className='detailTableComparison'>
      <div className='mb-24 detailTableHeader'>
        <AppText style={{ fontWeight: 700 }} textValue={comparisonDetailTable.comparisonDetailTable} />
        <AppBasicButton onClick={() => deleteComparisonDetailTable(id)}>
          <DeleteIcon />
        </AppBasicButton>
      </div>
      <div className='mb-24'>
        <AppInput
          label={comparisonDetailTable.enterTableHeadline}
          value={headline}
          onChange={event => onFieldChange('headline', event.target.value)}
        />
      </div>
      <div className='mb-24'>
        <AppInput label={comparisonDetailTable.enterTableSubline} value={subline} onChange={event => onFieldChange('subline', event.target.value)} />
      </div>

      <div className='comparisonSeparator' />

      <div style={{ fontWeight: 'bold' }}>
        <AppSwitch
          switchProps={{
            checked: score.isEnabled,
            onChange: value => {
              onScoreChange('isEnabled', value);
            },
          }}
          labelProps={{
            textValue: comparisonDetailTable.score,
          }}
        />
      </div>

      {score.isEnabled && (
        <div className='scoreOption'>
          <div>
            <AppText
              style={{ fontWeight: 700, fontSize: '14px', marginBottom: '24px' }}
              textValue={comparisonDetailTable.productScore({ index: 1 })}
            />
            <AppRadioGroup
              value={score.productOne}
              className='detailTableRadioGroup'
              onChange={event => onScoreChange('productOne', event.target.value)}
            >
              <AppRadio value={0} textValue='0/3' />
              <AppRadio value={1} textValue='1/3' />
              <AppRadio value={2} textValue='2/3' />
              <AppRadio value={3} textValue='3/3' />
            </AppRadioGroup>
          </div>

          <div>
            <AppText style={{ fontWeight: 700, fontSize: '14px' }} textValue={comparisonDetailTable.productScore({ index: 2 })} />
            <AppRadioGroup
              value={score.productTwo}
              className='detailTableRadioGroup'
              onChange={event => onScoreChange('productTwo', event.target.value)}
            >
              <AppRadio value={0} textValue='0/3' />
              <AppRadio value={1} textValue='1/3' />
              <AppRadio value={2} textValue='2/3' />
              <AppRadio value={3} textValue='3/3' />
            </AppRadioGroup>
          </div>
        </div>
      )}

      <div className='comparisonSeparator' />

      <div className='mb-24'>
        <AppText style={{ fontWeight: 700 }} textValue={comparisonDetailTable.comparisonTopics} />
      </div>

      <ComparisonTopics
        tableIndex={tableIndex}
        structureItemIndex={structureItemIndex}
        topics={topics}
        addTopic={addTopic}
        tableId={id}
        deleteTopic={deleteTopic}
        updateTopic={updateTopic}
        changeTopicOrder={changeTopicOrder}
      />

      <div className='comparisonSeparator' />
    </div>
  );
}
