import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { ClientFormField } from '../ClientForm';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { AppNewSelect } from '../../../../../../../../../ui/AppNewSelect';
import { handleTranslation } from '../../../../../../../../../lib/util/helpers';
import styles from './styles.module.scss';
import { HAMBURGER_VB_ID, HAMBURGER_VB_KONTOFINDER_FK_ID } from '../components/PreviewFormWrapper';

// eslint-disable-next-line max-lines-per-function
export function CompanyFormFields({ pagesFlow }) {
  const isHardcodeForHamburgerVbKontofinderFk = pagesFlow?.finder?.id === HAMBURGER_VB_KONTOFINDER_FK_ID && pagesFlow?.tenant?.id === HAMBURGER_VB_ID;

  const { fields } = useLocalization().translations.unique.components.clientForm.form;

  const lastRevenueOptions = [
    { title: fields.lastYearRevenue.options.companyFormation },
    { title: fields.lastYearRevenue.options.from0To500K },
    { title: fields.lastYearRevenue.options.from500KTo6M },
    { title: fields.lastYearRevenue.options.above6M },
  ].map(item => ({ value: handleTranslation(item.title), title: handleTranslation(item.title) }));

  const loanAmountOptions = [
    { title: fields.loanAmount.options.from0To100K },
    { title: fields.loanAmount.options.from100KTo1M },
    { title: fields.loanAmount.options.above1M },
    { title: fields.loanAmount.options.no },
  ].map(item => ({ value: handleTranslation(item.title), title: handleTranslation(item.title) }));

  return (
    <div>
      <div className={styles.row}>
        <AppFormSection
          formItemProps={{
            name: ClientFormField.COMPANY_OFFICE,
            rules: [
              {
                required: isHardcodeForHamburgerVbKontofinderFk,
                message: handleTranslation(fields.requiredField),
              },
            ],
          }}
          textFieldProps={{ label: isHardcodeForHamburgerVbKontofinderFk ? fields.companyOfficeRequired : fields.companyOffice }}
        />
        <AppFormSection
          formItemProps={{
            name: ClientFormField.INDUSTRY,

            rules: [
              {
                required: isHardcodeForHamburgerVbKontofinderFk,
                message: handleTranslation(fields.requiredField),
              },
            ],
          }}
          textFieldProps={{ label: isHardcodeForHamburgerVbKontofinderFk ? fields.industryRequired : fields.industry }}
        />
      </div>
      <div className={styles.row}>
        <AppFormSection
          formItemProps={{
            name: ClientFormField.LEGAL_FORM,
            rules: [
              {
                required: isHardcodeForHamburgerVbKontofinderFk,
                message: handleTranslation(fields.requiredField),
              },
            ],
          }}
          textFieldProps={{ label: isHardcodeForHamburgerVbKontofinderFk ? fields.legalFormRequired : fields.legalForm }}
        />
        <AppFormSection
          formItemProps={{
            name: ClientFormField.LAST_YEAR_REVENUE,
            rules: isHardcodeForHamburgerVbKontofinderFk
              ? [{ required: true, message: handleTranslation(fields.requiredField) }]
              : [{ required: true }],
          }}
        >
          <AppNewSelect label={fields.lastYearRevenue.title} options={lastRevenueOptions} />
        </AppFormSection>
      </div>
      <AppFormSection
        formItemProps={{
          name: ClientFormField.LOAN_AMOUNT,
          rules: isHardcodeForHamburgerVbKontofinderFk
            ? [{ required: true, message: handleTranslation(fields.requiredField) }]
            : [{ required: true }],
        }}
      >
        <AppNewSelect label={fields.loanAmount.title} options={loanAmountOptions} />
      </AppFormSection>
    </div>
  );
}
