import { EMPTY_OBJ } from '../../../../../../../lib/util/constants';
import { FIXED_COLUMN_INDEX, ROW_INDEX } from './utils';
import { Cell } from '../components/Cell';
import { isDesktop } from '../../../../../../../lib/util/Adaptive';

const DEFAULT_VAL = [EMPTY_OBJ];
const maybeWithColumnWidth = (columnsWidth, columnIndex) => (columnsWidth?.[columnIndex] ? { width: columnsWidth?.[columnIndex] } : EMPTY_OBJ);

const getColumnsProps = (columnsWidth, columnIndex, fonts, colors, footnoteRef, idx) => ({
  dataIndex: columnIndex,
  render: (cell, row, rowIndex) => (
    <Cell item={cell} row={row} rowIndex={rowIndex} fonts={fonts} colors={colors} columnIndex={columnIndex} footnoteRef={footnoteRef} idx={idx} />
  ),
  ...maybeWithColumnWidth(columnsWidth, columnIndex),
});

const modifyCells = (cells, idx, columnsWidth, fonts, colors, footnoteRef) =>
  cells?.reduce?.((prev, curr, columnIndex) => [...prev, getColumnsProps(columnsWidth, columnIndex, fonts, colors, footnoteRef, idx)], []) ||
  DEFAULT_VAL;

export function getColumns(tableList, fonts, colors, windowWidth, idx, footnoteRef) {
  const table = tableList?.[idx];
  const { columnsWidth } = table || {};
  const cells = table?.tableRows?.[ROW_INDEX]?.cells;

  const result = modifyCells(cells, idx, columnsWidth, fonts, colors, footnoteRef);

  if (isDesktop(windowWidth)) {
    // Make first (informational) column as fixed (pinned) to the left side
    result[FIXED_COLUMN_INDEX].fixed = 'left';
  }

  return result;
}
