import React from 'react';
import styled from 'styled-components';
import { InfoIconComponent } from '../../../../InfoIconComponent';
import { useWindowResize } from '../../../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../../../lib/util/Adaptive';

export function DetailTableBulletPoints({ bulletPointsSection, colors, fonts, applyVariable }) {
  const { bulletPoints, bulletPointIcon, bulletPointIconColor, rightColumnType } = bulletPointsSection;

  const { windowWidth } = useWindowResize();

  const createSvg = () => {
    return { __html: bulletPointIcon.svgContent };
  };
  const fill = colors[bulletPointIconColor];

  return (
    <>
      {bulletPoints.map(item => {
        return (
          <StyledBulletPoint windowWidth={windowWidth} key={item.id}>
            <p
              style={{
                marginBottom: 0,
                textAlign: 'left',
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
              }}
            >
              {applyVariable(item.left.value)}
              <InfoIconComponent applyVariable={applyVariable} type={item.left} />
            </p>
            {rightColumnType === 'icon' ? (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={createSvg()} style={{ fill: fill || '#000000' }} />
            ) : (
              <p
                style={{
                  marginBottom: 0,
                  textAlign: 'right',
                  minWidth: '100px',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                }}
              >
                {applyVariable(item.rightText.value)}
                <InfoIconComponent applyVariable={applyVariable} type={item.rightText} />
              </p>
            )}
          </StyledBulletPoint>
        );
      })}
    </>
  );
}

const StyledBulletPoint = styled('div')(props => ({
  borderBottom: '1px solid #CCCCCC',
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: props.windowWidth > AppBreakpoint.M ? '32px' : '24px',
  '&:last-child': {
    borderBottom: 'none',
  },
}));
