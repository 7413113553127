import { priceActions, productRangeOptions } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { rulesEngine } from './rules_helper';
import { changeNumberValue } from './change_number_value';

export const getRulesProductPrice = (productCard, pagesFlow, currentElements, logger = true) => {
  const { rules, id: cardId, priceSection, counterSection } = productCard;
  const { priceProduct } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['priceProduct'], logger });
  const cardPriceBlocks = priceProduct.filter(block => block?.priceProduct?.elements?.map(el => el.productId)?.includes(cardId));

  return getPriceWithCounterValue(cardPriceBlocks, counterSection?.value, priceSection);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getPriceWithCounterValue = (cardPriceBlocks, counterValue, priceSection) => {
  const { ruleShowPriceType, ruleShowPrice, showPrice, ruleChangePriceType, ruleChangePrice } = cardPriceBlocks.reduce(
    (accumulator, cardPriceBlock) => {
      const {
        priceProduct: { action, priceType, range, rangeFrom, rangeTo },
        priceProduct,
        resultOperator,
        value,
      } = cardPriceBlock;

      if (action === priceActions.selectPriceToShow) {
        return { ...accumulator, ruleShowPriceType: priceType, ruleShowPrice: priceSection[priceType], showPrice: true };
      }

      if (action === priceActions.changePrice) {
        const rulePriceValue = changeNumberValue(value, resultOperator, priceProduct, priceSection);

        if (
          range === productRangeOptions.all ||
          (range === productRangeOptions.fromTo && counterValue >= Number(rangeFrom) && counterValue <= Number(rangeTo))
        ) {
          return { ...accumulator, ruleChangePriceType: priceType, ruleChangePrice: rulePriceValue < 0 ? 0 : rulePriceValue };
        }
      }

      return accumulator;
    },
    {}
  );

  const rulePriceType = showPrice ? ruleShowPriceType : ruleChangePriceType;
  const rulePrice = ruleShowPriceType === ruleChangePriceType ? ruleChangePrice : ruleShowPrice || ruleChangePrice;

  return { showPrice, rulePrice, rulePriceType };
};
