/* eslint-disable max-lines */
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import styled from 'styled-components';
import { Input } from 'antd';
import { getRulesProductCounter } from '../../../../../../../../../utils/rules/product_counter_rules_helper';
import { useEffect } from 'react';
import { rewriteChapterSelectedProducts } from '../../../../../../../../../utils/end-user-flow-helpers';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { arithmeticTypes } from '../../../../../../../../BuilderPage/Condition/Actions/actionConstants';

const KONTOFINDER_PK_PRODUCT_9_1_ID = 'e344a746-d246-451e-872b-8aea09aeb317';
const KONTOFINDER_PK_PRODUCT_10_1_ID = '252469e3-3cde-4fb2-ba90-d464a444b4ca';

// eslint-disable-next-line max-lines-per-function
export function ProductLayoutCounter({
  headline,
  maxValue = 100,
  minValue = 0,
  value = 0,
  disabled,
  colors = {},
  fonts = {},
  // REFACTOR: naming of `onChangeCounter` & `onUpdateCounter`
  onChangeCounter,
  onUpdateCounter,
  productObject,
  card,
  currentElements,
  pagesFlow,
  setPagesFlow,
  ...rest
}) {
  const { applyVariable } = useVariables(pagesFlow);

  const { ruleCounter, resultOperator } = getRulesProductCounter(card, pagesFlow, currentElements);

  const counterDefaultValue = card?.counterSection?.defaultValue || 0;

  const getCounterValue = () => {
    // Todo hardcode P9.1, P10.1
    if (card?.id === KONTOFINDER_PK_PRODUCT_9_1_ID || card?.id === KONTOFINDER_PK_PRODUCT_10_1_ID) {
      return value;
    }
    return ruleCounter ?? value;
  };

  const counterValue = getCounterValue();

  useEffect(() => {
    if (ruleCounter >= 0 && value <= 0) {
      const resultProds = ruleCounter === 0 ? [] : [{ ...card, counterSection: { ...card.counterSection, value, ruleCounter } }];
      const unselectedIdsArr = [card.id];

      setPagesFlow(prev => ({
        ...prev,
        allChapters: rewriteChapterSelectedProducts(prev?.allChapters, prev?.currentChapterId, resultProds, unselectedIdsArr),
      }));
    }
    if (ruleCounter === undefined && value < 0) {
      onUpdateCounter(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card?.id, ruleCounter]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const increment = () => {
    if (onChangeCounter) {
      onChangeCounter?.();
    }

    if (onUpdateCounter && !disabled && ruleCounter < maxValue) {
      if (resultOperator === arithmeticTypes.equal) {
        return;
      }
      if (ruleCounter === 0) {
        onUpdateCounter(Number(minValue) === 0 ? Number(minValue) + 1 : minValue, ruleCounter + 1);
        return;
      }
      onUpdateCounter(Number(value) + 1, ruleCounter + 1);
      return;
    }

    if (onUpdateCounter && !disabled && value < maxValue && ruleCounter === undefined) {
      if (value === 0) {
        onUpdateCounter(Number(minValue) === 0 ? Number(minValue) + 1 : minValue);
        return;
      }
      onUpdateCounter(Number(value) + 1);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const decrement = () => {
    if (onChangeCounter) {
      onChangeCounter?.();
    }
    if (onUpdateCounter && !disabled && ruleCounter >= minValue) {
      if (resultOperator === arithmeticTypes.equal || (resultOperator === arithmeticTypes.plusEquals && value === 0)) {
        return;
      }
      const getValueFn = () => {
        if (Number(ruleCounter) === Number(minValue)) {
          return value - ruleCounter;
        }
        return Number(value) - 1;
      };
      const getRuleValueFn = () => {
        if (Number(ruleCounter) === Number(minValue)) {
          return 0;
        }
        return ruleCounter - 1;
      };
      onUpdateCounter(getValueFn(), getRuleValueFn());
      return;
    }
    if (onUpdateCounter && !disabled && value >= minValue) {
      onUpdateCounter(Number(value) === Number(minValue) ? 0 : Number(value) - 1);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeCounter = ({ target: { value: inputValue } }) => {
    if (onChangeCounter) {
      onChangeCounter?.();
    }

    const currentValue = inputValue.split(' ')[0];
    const ruleDifference = ruleCounter - value;
    const updatedValue = ruleCounter === undefined ? currentValue : currentValue - ruleDifference;

    if (onUpdateCounter) {
      const pattern = /^\d+$/;

      if (currentValue === '') {
        onUpdateCounter(null);
      }

      if (pattern.test(currentValue)) {
        if (ruleCounter === undefined) {
          if (Number(currentValue) > Number(maxValue)) {
            onUpdateCounter(maxValue);
            return;
          }

          if (Number(currentValue) < Number(minValue)) {
            onUpdateCounter(minValue);
            return;
          }
        }

        if (ruleCounter !== undefined) {
          if (Number(currentValue) > Number(maxValue)) {
            onUpdateCounter(maxValue - ruleDifference);
            return;
          }

          if (Number(currentValue) < Number(minValue)) {
            onUpdateCounter(minValue + counterDefaultValue);
            return;
          }
        }
        onUpdateCounter(updatedValue);
      }
    }
  };

  const getValue = value => {
    if (productObject?.content?.showCurrencySymbol && productObject?.content?.currencySymbol) {
      if (value) {
        return `${value} ${productObject.content.currencySymbol}`;
      }
      return `0 ${productObject.content.currencySymbol}`;
    }
    return value || 0;
  };

  return (
    <Root {...rest}>
      {headline && (
        <AppText
          textValue={applyVariable(headline) || '‎'}
          style={{
            marginBottom: '24px',
            fontSize: fonts.body?.fontSize,
            fontFamily: fonts.body?.font,
            fontWeight: fonts.body?.fontWeight,
            color: colors[fonts.body?.colorVariant],
          }}
        />
      )}

      <Counter>
        <ModifierButton onClick={decrement}>
          <ModifierText strong textValue='–' />
        </ModifierButton>
        <Input
          disabled={disabled}
          onChange={onUpdateCounter ? handleChangeCounter : undefined}
          bordered={false}
          value={getValue(counterValue)}
          style={{
            padding: '0 24px',
            maxWidth: 120,
            height: 40,
            fontSize: fonts.body?.fontSize,
            fontFamily: fonts.body?.font,
            fontWeight: fonts.body?.fontWeight,
            color: colors[fonts.body?.colorVariant],
            textAlign: 'center',
          }}
        />
        <ModifierButton onClick={increment}>
          <ModifierText strong textValue='+' />
        </ModifierButton>
      </Counter>
    </Root>
  );
}

const ModifierText = styled(AppText)({
  fontSize: '18px',
  color: '#fff !important',
});

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ModifierButton = styled('div')({
  width: '64px',
  display: 'flex',
  alignItems: 'center',
  background: '#5C5C6E',
  justifyContent: 'center',
  height: '100%',
  cursor: 'pointer',

  '& > *': {
    color: '#fff',
  },
});

const Counter = styled('div')({
  borderRadius: '40px',
  border: '2px solid #5C5C6E',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
});
