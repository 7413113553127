import { TemplateFormSection } from '../../../../../../../features/TemplateFormSection';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import { QuestionnaireTextButton } from '../../../../../features/QuestionnaireTextButton';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash.get';
import { v4 as uuidv4 } from 'uuid';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';

const MAX_NUMBER_BUTTONS = 6;
const DELETE_AMOUNT = 1;

/**
 * @desc CompletedChapters - composer element
 * @param {Array} buttons
 * @param {Number} itemIndex
 * @returns {JSX.Element}
 */

export function CompletedChapters({ buttons, itemIndex }) {
  const { updateStructure } = useUpdateStructure();
  const { enterButtonText, addButton } = useLocalization().translations.unique.pages.result.productsDepot.buttons;

  const { isViewMode } = useSelector(({ finders }) => finders);

  const dataPath = `[${itemIndex}].content.completedChaptersButtons`;

  const handleAddButton = () => {
    updateStructure(draft => {
      const completedChaptersButtons = lodashGet(draft, dataPath);
      completedChaptersButtons.push({
        id: uuidv4(),
        buttonText: { value: '' },
        flow: {
          goTo: null,
          href: null,
          targetBlank: true,
        },
      });
    });
  };

  const handleChangeButtonText = (value, buttonIndex) => {
    updateStructure(draft => {
      lodashSet(draft, `${dataPath}[${buttonIndex}].buttonText.value`, value);
    });
  };

  const handleDeleteButton = buttonIndex => {
    updateStructure(draft => {
      const completedChaptersButtons = lodashGet(draft, dataPath);
      completedChaptersButtons.splice(buttonIndex, DELETE_AMOUNT);
    });
  };

  return (
    <Root>
      {buttons.map((button, idx) => {
        return (
          <StyledButton key={button.id}>
            <StyledButtonWrapper>
              <TemplateFormSection valueObject={button?.buttonText} label={enterButtonText} onChange={value => handleChangeButtonText(value, idx)} />
            </StyledButtonWrapper>
            <AppBasicButton onClick={() => handleDeleteButton(idx)} disabled={isViewMode}>
              <DeleteIcon />
            </AppBasicButton>
          </StyledButton>
        );
      })}
      <QuestionnaireTextButton textValue={addButton} disabled={isViewMode || buttons.length >= MAX_NUMBER_BUTTONS} onClick={handleAddButton} />
    </Root>
  );
}

const Root = styled('div')({
  marginTop: 10,
});

const StyledButtonWrapper = styled('div')({
  width: '95%',
  marginRight: 15,
});

const StyledButton = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
});
