import { useCallback } from 'react';
import { rewriteHamburgPKAllPages, rewriteHamburgPKChapterSelectedProducts } from '../../../../../utils/end-user-flow-helpers';

export function useUpdateHamburgerPkP4FlowStructure({ setPagesFlow, P2, P3, structureItemId }) {
  return useCallback(
    (updatedProducts, newSelectedProducts, unselectedProductIds = []) => {
      setPagesFlow(prev => ({
        ...prev,
        allChapters: rewriteHamburgPKChapterSelectedProducts({
          allPages: prev?.allPages,
          P2,
          P3,
          allChapters: prev?.allChapters,
          currentChapterId: prev?.currentChapterId,
          newSelectedProducts,
          unselectedProductIds,
        }),
        allPages: rewriteHamburgPKAllPages(prev?.allPages, P2, P3)?.map(page =>
          page.id === prev?.currentPageId
            ? {
                ...page,
                elements: page.elements.map(el =>
                  el.id === structureItemId
                    ? {
                        ...el,
                        content: {
                          ...el.content,
                          products: updatedProducts,
                        },
                      }
                    : el
                ),
              }
            : page
        ),
      }));
    },
    [P2, P3, structureItemId, setPagesFlow]
  );
}
