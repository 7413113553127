import { FilterValuesPopup } from './FilterValuesPopup';
import { MainLabel } from './MainLabel';

export function FilterLabel({ getParsedItems, filterLabel, removeFilter, removeFilterValue }) {
  if (getParsedItems) {
    return (
      <FilterValuesPopup
        filterLabel={filterLabel}
        getParsedItems={getParsedItems}
        removeFilter={removeFilter}
        removeFilterValue={removeFilterValue}
      />
    );
  }

  return <MainLabel removeFilter={removeFilter} textValue={filterLabel} />;
}
