import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { TextFieldWithInfoIcon } from '../../../../../features/TextFieldWithInfoIcon';
import { CardSection } from '../../../../../ui/CardSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { AppCheckbox } from '../../../../../../../../../../../ui/AppCheckbox';
import { useSelector } from 'react-redux';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { Tooltip } from 'antd';

export function ContactFormHeader({ structureItemIndex, templateContent }) {
  const { unique } = useLocalization().translations;
  const { content: contentText } = unique.pages.questionnaire;
  const { templateHeadline, templateSubline, templateBody } = unique.formItems;

  const { isViewMode, finder } = useSelector(({ finders }) => finders);

  const { updateStructure } = useUpdateStructure();

  const handleChange = value => {
    updateStructure(draft => {
      const dataPath = `[${structureItemIndex}].content.header.showReCaptcha`;
      lodashSet(draft, dataPath, value);
    });
  };

  return (
    <CardSection withBorderBottom={false}>
      <CardSectionHeader>
        <AppText strong textValue={contentText.header} />
        <Tooltip placement='topLeft' title={!finder.reCaptchaKey && contentText.reCaptcha.tooltip}>
          <AppCheckbox
            style={{ flexDirection: 'row-reverse' }}
            onChange={event => handleChange(event.target.checked)}
            checked={finder.reCaptchaKey && templateContent?.header?.showReCaptcha}
            textValue={contentText.reCaptcha.title}
            disabled={isViewMode || !finder.reCaptchaKey}
          />
        </Tooltip>
      </CardSectionHeader>
      <TextFieldWithInfoIcon
        label={templateHeadline.label}
        data={templateContent?.header?.headline}
        dataPath={`[${structureItemIndex}].content.header.headline`}
      />
      <TextFieldWithInfoIcon
        label={templateSubline.label}
        data={templateContent?.header?.subline}
        dataPath={`[${structureItemIndex}].content.header.subline`}
      />
      <TextFieldWithInfoIcon
        label={templateBody.label}
        data={templateContent?.header?.body}
        dataPath={`[${structureItemIndex}].content.header.body`}
      />
    </CardSection>
  );
}
