import { AppText } from '../../../../../../../../ui/text/AppText';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import './style.scss';

const labelTexts = {
  [TemplateTypes.CHAPTER]: 'CH',
  [TemplateTypes.INTRO]: 'I',
  [TemplateTypes.CONTENT]: 'C',
  [TemplateTypes.PRODUCT]: 'P',
  [TemplateTypes.QUESTION]: 'Q',
  [TemplateTypes.RESULT]: 'R',
  [TemplateTypes.CONTACT_FORM]: 'C',
};

const labelBackgroundColors = {
  [TemplateTypes.CHAPTER]: '#404055',
  [TemplateTypes.INTRO]: '#BC82BD',
  [TemplateTypes.CONTENT]: '#C3A314',
  [TemplateTypes.PRODUCT]: '#005660',
  [TemplateTypes.QUESTION]: '#0092A3',
  [TemplateTypes.RESULT]: '#54529F',
  [TemplateTypes.CONTACT_FORM]: '#54529F',
};

export function ElementLabel({ templateType, minWidth }) {
  return (
    <div className='element_label' style={{ backgroundColor: labelBackgroundColors[templateType], minWidth }}>
      <AppText className='element_label_text' textValue={labelTexts[templateType]} />
    </div>
  );
}
