import styled from 'styled-components';
import { forwardRef } from 'react';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { CrossIcon } from '../../../../../../ui/icons/CrossIcon';

export const MainLabel = forwardRef(({ textValue, removeFilter, ...rest }, ref) => {
  return (
    <Root ref={ref} {...rest}>
      <AppText textValue={textValue} />

      <AppBasicButton onClick={removeFilter}>
        <CrossIcon />
      </AppBasicButton>
    </Root>
  );
});

const Root = styled('div')({
  display: 'flex',
  marginTop: '8px',
  alignItems: 'center',

  '& > button': {
    minWidth: '16px',
    minHeight: '16px',
    opacity: 0,
    border: '1.5px solid currentColor',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    svg: {
      padding: '1.4px',
    },
  },

  '& > span': {
    fontSize: '16px',
    marginRight: '16px',
    wordBreak: 'normal',
    fontWeight: 400,
  },

  '& > *': {
    transition: 'all 0.75s',
  },

  '&:hover': {
    button: {
      opacity: 1,
    },
    '&, span': {
      color: '#BA1750',
    },
  },
});
