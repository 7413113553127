import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { FlowSingleButtonSection } from '../FlowSingleButtonSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';
import '../../../style.scss';

/**
 * @desc Element Flow
 * @param {Array} elements
 * @param {Object} element
 * @param {Function} handleChangeFlowFieldsValue
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function ElementFlow({ elements, element, handleChangeFlowFieldsValue, disabled }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { goTo } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const {
    primaryButton: primaryButtonText,
    secondaryButton: secondaryButtonText,
    additionalButton,
  } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const elementIdByType = getIndexByTemplateType(elements, element);

  const { flow, templateType, label, id: elementId, content, rules } = element;
  const { primaryButton, secondaryButton, composerButton } = content;

  return (
    <>
      <div className='flow_process_box'>
        <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ marginBottom: 16 }} />
        <FlowRulesList elementRules={rules} />

        <AppText textValue={goTo} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={flow}
          options={questionnaireStructure}
          onChange={handleChangeFlowFieldsValue}
          disabled={disabled}
        />
      </div>
      <div className='flow_process_box'>
        {primaryButton && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={primaryButton}
            buttonTextValue={primaryButton.value}
            buttonLabelText={primaryButtonText}
            buttonName='primaryButton'
            disabled={disabled}
          />
        )}
        {secondaryButton && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={secondaryButton}
            buttonTextValue={secondaryButton.value}
            buttonLabelText={secondaryButtonText}
            buttonName='secondaryButton'
            disabled={disabled}
          />
        )}
        {composerButton && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={composerButton}
            buttonTextValue={composerButton.value}
            buttonLabelText={additionalButton}
            buttonName='composerButton'
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}
