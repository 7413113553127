import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedRule } from 'src/lib/store/slices/rulesSlice';

const getDefaultRuleObject = () => ({
  ruleId: uuidv4(),
  name: '',
  activated: true,
  conditions: [],
});

/**
 * @desc Custom hook for managing conditions and updating the selected rule.
 * @returns {Object} - An object containing functions to manage conditions and update the selected rule.
 */
export const useCondition = () => {
  const dispatch = useDispatch();

  const { selectedRule } = useSelector(({ rules }) => rules);

  const ruleObject = selectedRule.ruleId ? selectedRule : getDefaultRuleObject();

  const updateRule = (field, value) => {
    const updatedRule = {
      ...ruleObject,
      [field]: value,
    };
    dispatch(updateSelectedRule(updatedRule));
  };

  const addCondition = (condition = null) => {
    const newConditionObject = condition || {
      id: uuidv4(),
      name: '',
      isActive: true,
      body: [
        {
          id: uuidv4(),
          type: 'Logical operator',
          value: 'IF',
        },
      ],
    };

    updateRule('conditions', [...ruleObject.conditions, newConditionObject]);
  };

  const moveCondition = (index, destination) => {
    const updatedConditions = [...ruleObject.conditions];

    const swappedIndex = destination === 'up' ? index - 1 : index + 1;

    [updatedConditions[index], updatedConditions[swappedIndex]] = [updatedConditions[swappedIndex], updatedConditions[index]];

    updateRule('conditions', updatedConditions);
  };

  const updateCondition = (id, property, value) => {
    const updatedRule = {
      ...ruleObject,
      conditions: ruleObject.conditions.map(condition => {
        if (condition.id === id) {
          return {
            ...condition,
            [property]: value,
          };
        }

        return condition;
      }),
    };

    dispatch(updateSelectedRule(updatedRule));
  };

  const duplicateCondition = id => {
    const selectedCondition = ruleObject.conditions.find(item => item.id === id);

    addCondition({ ...selectedCondition, id: uuidv4() });
  };

  const deleteCondition = id => {
    updateRule(
      'conditions',
      ruleObject.conditions.filter(condition => condition.id !== id)
    );
  };

  const updateRuleName = ruleName => {
    updateRule('name', ruleName);
  };

  return { selectedRule, addCondition, updateCondition, moveCondition, duplicateCondition, updateRuleName, deleteCondition };
};
