import { getRulesApiRequest } from '../../../../../../../../../lib/api';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../../lib/consts';

/**
 * @desc Retrieves rule data from the API based on search criteria.
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} finderId - The ID of the finder.
 * @param {string} searchFieldName - The name of the field to search.
 * @param {string} searchValue - The value to search for.
 * @returns {Promise} - A Promise that resolves with the rule data.
 */
export const getRuleData = (tenantId, finderId, searchFieldName, searchValue) =>
  getRulesApiRequest(tenantId, {
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: { [searchFieldName]: searchValue, finderId },
  });
