import { AppFormSection } from '../ui/AppFormSection';
import { Product } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/lib/Product';

export function ProductHvmIdFormItem(props) {
  const { formItemProps, textFieldProps } = props;

  return (
    <AppFormSection
      formItemProps={{
        name: Product.HVM_PRODUCT_ID,
        ...formItemProps,
      }}
      textFieldProps={{
        label: 'Add SK Loyalty-ID*',
        ...textFieldProps,
      }}
    />
  );
}
