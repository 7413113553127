import { CategoryAssignedUsersHeaderCell } from './CategoryAssignedUsersHeaderCell';
import { ASSIGNED_PRODUCTS_ID } from './assigned-products-utils';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';

export const categoryAssignedProductsColumn = {
  dataIndex: ASSIGNED_PRODUCTS_ID,
  title: <CategoryAssignedUsersHeaderCell />,
  width: 600,
  render: list => list && <AppText>{list.map(item => item.name).join(', ')}</AppText>,
};
