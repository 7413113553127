import 'antd/dist/reset.css';
import './lib/styles/index.scss';

import { reportWebVitals } from './lib/reportWebVitals';
import { axiosInstance, publicAxiosInstance } from '../lib/util/http/axios/axios-instance';
import { applyInterceptors } from '../lib/util/http/axios/interceptors';
import { AppProvider } from './components/AppProvider';
import { AppRouter } from './components/AppRouter';
import { AppNotification } from './components/AppNotification';
import { consoleErrorHandler } from './lib/consoleErrorHandler';
import { applyPublicInterceptors } from '../lib/util/http/axios/public-instance/applyPublicInterceptors';

applyInterceptors(axiosInstance); // we use it here for avoid cycle imports (on handling store actions)
applyPublicInterceptors(publicAxiosInstance);

consoleErrorHandler();

export function App() {
  return (
    <AppProvider>
      <AppNotification />

      <AppRouter />
    </AppProvider>
  );
}

reportWebVitals();
