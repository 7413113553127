import { TemplateFormSection } from '../../../../../../features/TemplateFormSection';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function ComposerButton({ item, isDraggingOver, onChangeFieldValue, onDelete }) {
  const { composerButton } = useLocalization().translations.unique.formItems;
  return (
    <TemplateFormSection
      valueObject={item.content.composerButton || { value: '' }}
      label={composerButton.label}
      onChange={onChangeFieldValue('composerButton')}
      isSuffix
      onClickSuffix={() => onDelete(item.id)}
      borderColor={isDraggingOver}
    />
  );
}
