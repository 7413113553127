import { parseNumberType, formatVariableText } from 'src/lib/util/helpers';

const isVariablesSame = (oldVariables, newVariables) => {
  if (oldVariables.length !== newVariables.length) {
    return false;
  }

  for (const oldVariable of oldVariables) {
    let found = false;
    for (const [j, newVariable] of newVariables.entries()) {
      if (oldVariable.id === newVariable.id && oldVariable.value === newVariables[j].value) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line max-lines-per-function, default-param-last
export const useVariables = (pagesFlow = {}, setPagesFlow) => {
  const { finder = {} } = pagesFlow;

  const { customVariables = [] } = finder;

  const variables = customVariables.reduce((variablesObject, currentItem) => {
    const newVariablesObject = { ...variablesObject };
    newVariablesObject[currentItem.name] = currentItem;

    return newVariablesObject;
  }, {});

  const applyVariable = valueToReplace => {
    if (valueToReplace === undefined) {
      return valueToReplace;
    }

    const stringToReplace = String(valueToReplace);
    if (!stringToReplace) {
      return stringToReplace;
    }

    const regex = /\$\w+\$/g;

    return stringToReplace.replace(regex, match => {
      const keyWithoutSign = match.replaceAll('$', '');
      const matchedVariable = variables[keyWithoutSign];

      const variableValue = matchedVariable?.value ?? matchedVariable?.defaultValue ?? '';
      return formatVariableText(variableValue);
    });
  };

  const applyVariablesToObject = dataObject => {
    return Object.keys(dataObject).reduce((acc, key) => {
      if (dataObject[key] && dataObject[key].getTime) {
        acc[key] = dataObject[key];
      } else if (typeof dataObject[key] === 'string') {
        acc[key] = applyVariable(dataObject[key]);
      } else if (Array.isArray(dataObject[key])) {
        acc[key] = dataObject[key].map(item => (typeof item === 'string' ? applyVariable(item) : applyVariablesToObject(item)));
      } else if (typeof dataObject[key] === 'object' && dataObject[key] !== null) {
        acc[key] = applyVariablesToObject(dataObject[key]);
      } else {
        acc[key] = dataObject[key];
      }
      return acc;
    }, {});
  };

  const setVariable = (id, value) => {
    const updatedVariables = customVariables.map(variable => {
      return {
        ...variable,
        value: variable.id === id ? value : variable.value,
      };
    });

    setPagesFlow(prevPagesFlow => ({
      ...prevPagesFlow,
      finder: {
        ...prevPagesFlow.finder,
        customVariables: updatedVariables,
      },
    }));
  };

  const getVariable = id => {
    return customVariables.find(variable => variable.id === id);
  };

  const getDefaultVariables = () => {
    return customVariables.map(variable => {
      return {
        ...variable,
        value: variable.defaultValue,
      };
    });
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const processVariables = resultVariables => {
    if (!resultVariables) {
      return;
    }

    const defaultVariables = getDefaultVariables();

    if (!defaultVariables || defaultVariables.length === 0) {
      return;
    }

    let updatedVariables = [...defaultVariables];

    for (const resultBlock of resultVariables) {
      const {
        conditionValue,
        value,
        resultOperator,
        resultVariables: { element },
      } = resultBlock;
      const currentVariable = updatedVariables.find(variableObj => variableObj.id === element);

      if (conditionValue && currentVariable) {
        const isNumber = currentVariable.type === 'Number';

        let currentValue = isNumber ? parseNumberType(currentVariable.value) : currentVariable.value;
        const addedAmount = isNumber ? parseNumberType(value.amount) : value.amount;

        switch (resultOperator) {
          case '=': {
            currentValue = addedAmount;
            break;
          }
          case '+=': {
            currentValue += addedAmount;
            break;
          }
          case '-=': {
            if (isNumber) {
              currentValue -= addedAmount;
            }
            break;
          }
          default:
          // do nothing
        }

        updatedVariables = updatedVariables.map(variableObj => {
          return {
            ...variableObj,
            value: variableObj.id === currentVariable.id ? currentValue : variableObj.value,
          };
        });
      }
    }

    if (!isVariablesSame(customVariables, updatedVariables)) {
      setPagesFlow(prevPagesFlow => ({
        ...prevPagesFlow,
        finder: {
          ...prevPagesFlow.finder,
          customVariables: updatedVariables,
        },
      }));
    }
  };

  return { applyVariable, applyVariablesToObject, getVariable, setVariable, processVariables };
};
