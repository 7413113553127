import { AddColumn } from './AddColumn';
import { AddRow } from './AddRow';

export function AddRowColumnSection() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
      <AddColumn />
      <AddRow />
    </div>
  );
}
