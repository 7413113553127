import { useDispatch, useSelector } from 'react-redux';
import { ProductSavingsRow } from '../../components/ProductSavingsRow';
import { AppBasicButton } from '../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { ProductRangeSavingsItem } from './ProductRangeSavingsItem';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import './style.scss';
import { fillUniqueRangesAndAddNew } from '../../../../../../utils/helpers';

/**
 * @desc Product Range Savings component.
 * Manages the display and functionality of product range savings within a questionnaire.
 * @param {Object} savingObj - The saving object containing product information.
 * @param {Object} selectedQuestion - The selected question object.
 * @param {Array} savings - The array of savings objects.
 * @returns {JSX.Element} - JSX component representing the Product Range Savings.
 */
// eslint-disable-next-line max-lines-per-function
export function ProductRangeSavings({ savingObj, selectedQuestion, savings }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const { addRange: addRangeText } = useLocalization().translations.shared.generic;

  const updateSavingsArr = (savingsArr, fieldName, value) => {
    return savingsArr.map(savingItem => (savingItem.id === savingObj.id ? { ...savingItem, [fieldName]: value } : savingItem));
  };

  const handleFieldChange = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? { ...el, content: { ...el.content, savingsProductsRanges: updateSavingsArr(savings, fieldName, value) } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const deleteSavingsObject = deleteId => {
    const filteredSavings = savings.filter(savingItem => savingItem.id !== deleteId);
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId ? { ...el, content: { ...el.content, savingsProductsRanges: filteredSavings } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const addRange = () => {
    const updatedSavingRanges = fillUniqueRangesAndAddNew(savingObj.savingsRanges, 'savingsValue', 0);
    const updatedSavingsProductsRanges = updateSavingsArr(savings, 'savingsRanges', updatedSavingRanges);
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId ? { ...el, content: { ...el.content, savingsProductsRanges: updatedSavingsProductsRanges } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const deleteRange = deleteId => {
    const updatedSavingRanges = savingObj.savingsRanges.filter(rangeItem => rangeItem.id !== deleteId);
    const updatedSavingsProductsRanges = updateSavingsArr(savings, 'savingsRanges', updatedSavingRanges);
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId ? { ...el, content: { ...el.content, savingsProductsRanges: updatedSavingsProductsRanges } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const updateRangeObject = (objectId, fieldName, value) => {
    const updatedSavingRanges = savingObj.savingsRanges.map(rangeItem =>
      rangeItem.id === objectId ? { ...rangeItem, [fieldName]: value } : rangeItem
    );
    const updatedSavingsProductsRanges = updateSavingsArr(savings, 'savingsRanges', updatedSavingRanges);
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId ? { ...el, content: { ...el.content, savingsProductsRanges: updatedSavingsProductsRanges } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <div className='product_range_saving_wrap'>
      <ProductSavingsRow
        savingObj={savingObj}
        savingsArr={savings}
        onChange={handleFieldChange('productId')}
        deleteRow={deleteSavingsObject}
        disabled={isViewMode}
      />
      {savingObj.savingsRanges.map((rangeObj, index) => (
        <ProductRangeSavingsItem
          key={rangeObj.id}
          rangeIndex={index}
          rangeObject={rangeObj}
          updateRangeObject={updateRangeObject}
          deleteRange={deleteRange}
          disabled={isViewMode}
          savingsRanges={savingObj.savingsRanges}
        />
      ))}

      <div className='product_range_saving_add_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={addRange} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={addRangeText} />
        </AppBasicButton>
      </div>
    </div>
  );
}
