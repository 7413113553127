/* eslint-disable no-magic-numbers */
// https://mui.com/material-ui/guides/composition/#inlining-amp-caveat

import { forwardRef, useMemo } from 'react';

export function useCombinedComponents({ components = [], props = {} }) {
  const First = components[0];
  const Second = components[1];
  const Third = components[2];

  const SecondMemo = useMemo(() => forwardRef((forwardedProps, ref) => <Second {...forwardedProps} ref={ref} as={Third} />), [Third]);

  return <First {...props} as={SecondMemo} />;
}
