import { ProductBlock } from './ProductBlock';
import { ProductOptions } from './ProductOptions';
import { ComparisonOptions } from './ComparisonOptions';
import { DetailTables } from './DetailTables';

import { useComparisonDetailTable } from './useComparisonDetailTable';

export function ComparisonDetailTable({
  productRow,
  productOptions,
  comparisonOptions,
  detailTables,
  contentBlockIndex,
  contentBlock,
  contentBlocks,
  handleChangeField,
  handleChangeFieldValue,
  item,
  bulletIcons,
  configuration,
  structureItemIndex,
}) {
  const {
    comparisonOptionsChange,
    comparisonProductOptionChange,
    addComparisonDetailTable,
    deleteComparisonDetailTable,
    updateComparisonDetailTable,
    addTopic,
    deleteTopic,
    updateTopic,
    changeTopicOrder,
  } = useComparisonDetailTable(handleChangeField, handleChangeFieldValue, contentBlockIndex, contentBlock, contentBlocks, item);

  return (
    <>
      {productRow && <ProductBlock productRow={productRow} handleChangeField={handleChangeFieldValue('productRow')} />}
      {productOptions && (
        <ProductOptions productOptions={productOptions} productRow={productRow} comparisonProductOptionChange={comparisonProductOptionChange} />
      )}
      {comparisonOptions && (
        <ComparisonOptions
          comparisonOptions={comparisonOptions}
          configuration={configuration}
          bulletIcons={bulletIcons}
          comparisonOptionsChange={comparisonOptionsChange}
        />
      )}
      {detailTables && (
        <DetailTables
          detailTables={detailTables}
          structureItemIndex={structureItemIndex}
          addComparisonDetailTable={addComparisonDetailTable}
          deleteComparisonDetailTable={deleteComparisonDetailTable}
          updateComparisonDetailTable={updateComparisonDetailTable}
          addTopic={addTopic}
          deleteTopic={deleteTopic}
          updateTopic={updateTopic}
          changeTopicOrder={changeTopicOrder}
        />
      )}
    </>
  );
}
