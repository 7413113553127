import {
  convertMoney,
  DEFAULT_CURRENCY_CODE,
  formatPriceForUi,
  normalizeCurrencyCode,
  transformCurrencyCodeToSymbol,
} from '../../../../../../../lib/models/Finance';
import { findMostRepeatedItem, getUniqueArray, handleTranslation } from '../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../FindersAndProductsPage/lib/Product';
import { getRulesProductPrice } from '../../../utils/rules/product_price_rules_helper';
import { ResultPricePeriod } from '../../../utils/consts';

const CONVERTED_TOTAL_PRICE_CURRENCY_CODE = 'USD'; // Here could be any currency, that is described in our "Finance.js"
const INITIAL_TOTAL_PRICE_VALUE = 0;

const getTotalPriceText = (totalPrice, currencySymbol, priceTypeText) => `${formatPriceForUi(totalPrice)} ${currencySymbol} ${priceTypeText}`;
const getTotalPricePerMonthText = (totalPrice, currencySymbol, priceTypesTextObj) =>
  getTotalPriceText(totalPrice, currencySymbol, handleTranslation(priceTypesTextObj.pricePerMonthShort));
const getTotalPricePerYearText = (totalPrice, currencySymbol, priceTypesTextObj) =>
  getTotalPriceText(totalPrice, currencySymbol, handleTranslation(priceTypesTextObj.pricePerYearShort));

const getPriceValue = priceSection => Number(priceSection?.priceValue) || INITIAL_TOTAL_PRICE_VALUE;

const getPriceValuePerYear = (priceSection, rulePriceType) => {
  if (rulePriceType) {
    if (rulePriceType === Product.PRICE_PER_YEAR) {
      return getPriceValue(priceSection);
    }
    return INITIAL_TOTAL_PRICE_VALUE;
  }

  return priceSection?.priceType === Product?.PRICE_PER_YEAR ? getPriceValue(priceSection) : INITIAL_TOTAL_PRICE_VALUE;
};

const getPriceValuePerMonth = (priceSection, rulePriceType) => {
  if (rulePriceType) {
    if (rulePriceType === Product.PRICE_PER_MONTH) {
      return getPriceValue(priceSection);
    }
    return INITIAL_TOTAL_PRICE_VALUE;
  }
  return priceSection?.priceType === Product?.PRICE_PER_MONTH ? getPriceValue(priceSection) : INITIAL_TOTAL_PRICE_VALUE;
};

function formatTotalPriceOfCurrency(totalPricePerYear, totalPricePerMonth, currencyCode, priceTypesTextObj) {
  const currencySymbol = transformCurrencyCodeToSymbol(currencyCode);
  const totalPricePerYearInCurrency = convertMoney(totalPricePerYear, CONVERTED_TOTAL_PRICE_CURRENCY_CODE, currencyCode);
  const totalPricePerMonthInCurrency = convertMoney(totalPricePerMonth, CONVERTED_TOTAL_PRICE_CURRENCY_CODE, currencyCode);
  const perYear = getTotalPricePerYearText(totalPricePerYearInCurrency, currencySymbol, priceTypesTextObj);
  const perMonth = getTotalPricePerMonthText(totalPricePerMonthInCurrency, currencySymbol, priceTypesTextObj);

  // REFACTOR: move hardcoded word to translations
  return `${perYear} und ${perMonth}`;
}

const getTotalPriceOfMostRepeatedCurrencyDependingOnPeriod = (prodInfo, totalPrice) => {
  if (prodInfo?.pricePeriod === ResultPricePeriod.PRICE_PER_YEAR) {
    return totalPrice.split('und')[0];
  }
  if (prodInfo?.pricePeriod === ResultPricePeriod.PRICE_PER_MONTH) {
    return totalPrice.split('und')[1];
  }
  return totalPrice;
};

export function useCalculateTotalPrice(products, pagesFlow, productInformation) {
  const { dePriceTypes: priceTypesTextObj } = useLocalization().translations.unique.pages.questionnaire.content; // REFACTOR: place of the translation
  let totalPricePerYear = INITIAL_TOTAL_PRICE_VALUE;
  let totalPricePerMonth = INITIAL_TOTAL_PRICE_VALUE;

  for (const product of products) {
    const { rulePriceType } = getRulesProductPrice(product || {}, pagesFlow, [], false);
    const priceSection = product?.priceSection;
    const currency = normalizeCurrencyCode(priceSection?.currency || DEFAULT_CURRENCY_CODE);
    const pricePerYear = getPriceValuePerYear(priceSection, rulePriceType);
    const pricePerMonth = getPriceValuePerMonth(priceSection, rulePriceType);
    const convertedPerYear = convertMoney(pricePerYear, currency, CONVERTED_TOTAL_PRICE_CURRENCY_CODE);
    const convertedPerMonth = convertMoney(pricePerMonth, currency, CONVERTED_TOTAL_PRICE_CURRENCY_CODE);

    totalPricePerYear += convertedPerYear;
    totalPricePerMonth += convertedPerMonth;
  }

  const allCurrencyList = products.map(item => item?.priceSection?.currency || DEFAULT_CURRENCY_CODE);
  const mostRepeatedCurrencyCode = findMostRepeatedItem(allCurrencyList, DEFAULT_CURRENCY_CODE);
  const restUniqueCurrencyList = getUniqueArray(allCurrencyList)?.filter(item => item !== mostRepeatedCurrencyCode);

  const totalPriceOfMostRepeatedCurrency = formatTotalPriceOfCurrency(
    totalPricePerYear,
    totalPricePerMonth,
    mostRepeatedCurrencyCode,
    priceTypesTextObj
  );

  const totalPriceOfMostRepeatedCurrencyDependingOnPeriod = getTotalPriceOfMostRepeatedCurrencyDependingOnPeriod(
    productInformation,
    totalPriceOfMostRepeatedCurrency
  );

  const totalPriceListOfRestCurrencies = restUniqueCurrencyList
    ?.map(currencyCode => formatTotalPriceOfCurrency(totalPricePerYear, totalPricePerMonth, currencyCode, priceTypesTextObj))
    .join('\n');

  return {
    totalPriceOfMostRepeatedCurrency: totalPriceOfMostRepeatedCurrencyDependingOnPeriod,
    totalPriceListOfRestCurrencies,
  };
}
