import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppBox } from '../../../../../../../../../../../ui/boxes/AppBox';
import { ChooseImageFormItem } from '../../../../../../../features/ChooseImageFormItem';
import { ProductPrices } from '../../../../../../../features/ProductPrices';
import { ProductLinkFormItem } from '../../../../../../../../../../../features/ProductLinkFormItem';
import { ProductSublineFormItem } from '../../../../../../../../../../../features/ProductSublineFormItem';
import { ProductHeadlineFormItem } from '../../../../../../../../../../../features/ProductHeadlineFormItem';
import { ProductNameFormItem } from '../../../../../../../../../../../features/ProductNameFormItem';
import { ProductHvmIdFormItem } from '../../../../../../../../../../../features/ProductHvmIdFormItem';
import { ProductCategoryFormItem } from '../../../../../../../../../../../features/ProductCategoryFormItem';
import { isTruthy, trimForm } from '../../../../../../../../../../../lib/util/helpers';
import { prepareProductForm } from '../../lib/utils';
import { editProductRequest } from '../../../../../../../../../../../lib/store/slices/productsSlice';
import { axiosInstance } from '../../../../../../../../../../../lib/util/http/axios/axios-instance';
import { withXTenantHeader } from '../../../../../../../../../../../lib/api';
import { Product } from '../../../../../lib/Product';
import { ProductShortNameFormItem } from '../../../../../../../../../../../features/ProductShortNameFormItem';
import { PricePerRequest } from '../PricePerRequest';
import { AddExceptionalOption } from '../AddExceptionalOption';

function getFieldsData(data) {
  const productId = data?.[Product.CATEGORY]?.id;

  return [
    ...Object.entries(data).map(([key, value]) => ({ name: key, value })),
    isTruthy(productId)
      ? {
          name: Product.CATEGORY,
          value: { value: productId, title: data?.[Product.CATEGORY]?.name },
        }
      : {},
  ];
}

const getProductById = (productId, tenantId) => axiosInstance.get(`product/${productId}`, withXTenantHeader(tenantId));

export function EditProductForm({ form, productData }) {
  const dispatch = useDispatch();
  const { id: tenantId } = useParams();
  const { tenantId: tenantIdForUser } = useSelector(({ auth }) => auth);
  const actualTenantId = tenantId || tenantIdForUser;
  const productId = productData?.id;

  const onFinish = values => {
    if (typeof values.imageUrl === 'string') {
      const trimmedForm = trimForm(values);
      const preparedForm = prepareProductForm(trimmedForm, { id: productId });
      dispatch(editProductRequest({ product: preparedForm, tenantId: actualTenantId }));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return,promise/always-return
    getProductById(productId, actualTenantId).then(({ data }) => {
      form.setFields(getFieldsData(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, actualTenantId]);

  return (
    <Form form={form} onFinish={onFinish}>
      <AppBox style={{ marginBottom: 40, marginTop: 20 }}>
        <AddExceptionalOption />
        <ProductCategoryFormItem />
        <ProductNameFormItem />
        <ProductHvmIdFormItem />
        <ProductShortNameFormItem />
        <ProductHeadlineFormItem />
        <ProductSublineFormItem />
        <ProductLinkFormItem />
        <ProductPrices />
        <PricePerRequest />
        <ChooseImageFormItem />
      </AppBox>
    </Form>
  );
}
