import styled from 'styled-components';
import { ProductLayoutCounter } from '../../multiple-counters/components/ProductLayoutCounter';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../../../ui/text/AppParagraph';

export function ProductOneCounterSection({ counter, ...rest }) {
  return (
    <Root {...rest}>
      <Inner>
        <AppParagraph textValue={counter.headline} style={{ textAlign: 'center' }} />
        <Card>
          <ProductLayoutCounter value={counter.value} />

          <div style={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AppText style={{ fontSize: 24 }} textValue='350 USD' />
            <AppText textValue='Body regular' />
          </div>
        </Card>
      </Inner>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginBottom: '20px',
});

const Inner = styled('div')({
  maxWidth: '1016px',
  width: '100%',
});

const Card = styled('div')({
  background: '#FFFFFF',
  padding: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  gap: '120px',
});
