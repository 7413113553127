import styled from 'styled-components';
import { isTruthy } from '../../../../../../../../../../../../../lib/util/helpers';
import { transformCurrencyCodeToSymbol } from '../../../../../../../../../../../../../lib/models/Finance';
import { ResultPriceType } from '../../../../../../../../../utils/consts';

/**
 * @desc Products
 * @param {Array} tenantProducts
 * @param {Object} fonts
 * @param {Object} colors
 * @param {Object} productInformation
 * @returns {JSX.Element}
 */
export function Products({ tenantProducts, fonts, colors, productInformation }) {
  return tenantProducts?.map(product => {
    const price = product.pricePerMonth || product.pricePerYear;
    const maybePrice =
      productInformation?.priceType !== ResultPriceType.NO_PRICE && isTruthy(price)
        ? `${price} ${transformCurrencyCodeToSymbol(product.currency)}`
        : '';

    return (
      <StyledProduct key={product.id}>
        <StyledProductLeft>
          {productInformation?.picture && <StyledImage src={product.imageUrl} alt='img' />}
          <div>
            <p
              style={{
                overflow: 'hidden',
                marginBottom: 0,
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: 700,
                color: colors[fonts.body.colorVariant],
              }}
            >
              {productInformation?.name && product.headline}
            </p>
            <p
              style={{
                fontSize: fonts.textLink.fontSize,
                fontFamily: fonts.textLink.font,
                fontWeight: 400,
                color: colors[fonts.textLink.colorVariant],
                overflow: 'hidden',
                marginBottom: 0,
              }}
            >
              {productInformation?.externalProductPageLink && product.productPageUrl}
            </p>
          </div>
        </StyledProductLeft>
        <p
          style={{
            marginRight: 20,
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: 700,
            color: colors[fonts.body.colorVariant],
          }}
        >
          {maybePrice}
        </p>
      </StyledProduct>
    );
  });
}

const StyledProduct = styled('div')({
  backgroundColor: '#fff',
  padding: 5,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 4,
});

const StyledProductLeft = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledImage = styled('img')({
  width: 80,
  height: 80,
  borderRadius: 4,
  marginRight: 33,
});
