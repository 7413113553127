import { DefaultColors } from '../../../../../lib/models/DefaultStylesFontsAndColors';
import { useSelector } from 'react-redux';

export function useFinderColors() {
  const previewConfiguration = useSelector(({ finders }) => finders.finder?.brand?.configuration);
  const endUserConfiguration = useSelector(({ endUserFinder }) => endUserFinder.endUserFinder?.brand?.configuration);

  const configuration = previewConfiguration || endUserConfiguration;

  return configuration ? JSON.parse(configuration).colors : DefaultColors;
}
