import { PreviewFormGenericBottomFields } from '../PreviewFormGenericBottomFields';
import { ConfigProvider } from 'antd';
import { de_validateMessages } from '../../../../../../../../../../lib/localization/DE/de_validation';
import { useResultProps } from '../../../../hooks/useResultProps';
import { usePreviewContext } from '../../../../hooks/usePreviewContext';
import classes from '../styles.module.scss';
import { useWindowResize } from '../../../../../../utils/hooks/useWindowResize';
import { PreviewFormGenericTopFields } from '../PreviewFormGenericTopFields';
import { PreviewFormAside } from '../PreviewFormAside';
import { StyledFormWrapper } from './StyledFormWrapper';
import { useRef } from 'react';
import { PDF_TYPE, ResultKind } from '../../../../../../../../../../lib/models/Questionnaire';
import { onSubmitForm } from './onSubmitForm';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { showErrorNotification } from '../../../../../../../../../../lib/store/slices/notificationsSlice';
import { verifyReCaptchaToken } from '../../../PreviewDynamicContactForm/helpers/verifyReCaptchaToken';
import { generatePdfRequest } from 'src/lib/api';

export const HAMBURGER_VB_KONTOFINDER_FK_ID = 11000;
export const HAMBURGER_VB_ID = 11048;

export function PreviewFormWrapper({ children, ...rest }) {
  const { previewState, setPreviewState } = usePreviewContext();
  const { printedResultProps } = useResultProps(rest);
  const { setPagesFlow, pagesFlow, resultObject, colors } = rest;
  const { windowWidth } = useWindowResize();
  const pdfType = resultObject.content?.kind === ResultKind.DEPOT ? PDF_TYPE.DEPOT : PDF_TYPE.RESULT;

  const dispatch = useDispatch();

  const recaptcha = useRef();

  const isHardcodeForHamburgerVbKontofinderFk = pagesFlow?.finder?.id === HAMBURGER_VB_KONTOFINDER_FK_ID && pagesFlow?.tenant?.id === HAMBURGER_VB_ID;

  const sendForm = async data => {
    const pdfBlob = await generatePdfRequest({
      payload: printedResultProps,
      type: pdfType,
    });

    const newData = isHardcodeForHamburgerVbKontofinderFk ? { ...data, specificHeader: 'Neue Finder-Anfrage für Online-Abschluss' } : data;

    onSubmitForm({
      newData,
      pagesFlow,
      setPreviewState,
      setPagesFlow,
      printedResultProps,
      previewState,
      pdfBlob,
    });
  };

  const onFinish = async data => {
    if (pagesFlow.tenant?.reCaptchaKey && pagesFlow.tenant?.reCaptchaSecret) {
      const captchaToken = recaptcha?.current?.getValue();
      if (captchaToken) {
        const response = await verifyReCaptchaToken(captchaToken, previewState.finderUrl);
        if (response.success) {
          sendForm(data);
        } else {
          dispatch(showErrorNotification({ error: { message: 'reCaptcha token invalid' } }));
        }
      } else {
        dispatch(showErrorNotification({ error: { message: 'You must confirm you are not a robot' } }));
      }
    } else {
      sendForm(data);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <ConfigProvider form={{ validateMessages: de_validateMessages }}>
        <StyledFormWrapper form={previewState.form} windowWidth={windowWidth} onFinish={onFinish} colors={colors}>
          <PreviewFormGenericTopFields isHardcodeForHamburgerVbKontofinderFk={isHardcodeForHamburgerVbKontofinderFk} />
          <div>{children}</div>
          <PreviewFormGenericBottomFields
            bankName={pagesFlow.tenant?.bankName}
            isHardcodeForHamburgerVbKontofinderFk={isHardcodeForHamburgerVbKontofinderFk}
          />
          {pagesFlow.tenant?.reCaptchaKey && pagesFlow.tenant?.reCaptchaSecret && (
            <div style={{ marginTop: 10 }}>
              <ReCAPTCHA ref={recaptcha} sitekey={pagesFlow.tenant.reCaptchaKey} />
            </div>
          )}
        </StyledFormWrapper>
      </ConfigProvider>

      <PreviewFormAside resultId={resultObject?.id} />
    </div>
  );
}
