import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';

/**
 * @desc Renders the label and rules associated with a result flow.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.structureItem - The structure item representing the result flow.
 * @param {Array} props.elements - The array of elements containing the result flow.
 * @returns {JSX.Element} The JSX element displaying the label and rules.
 */
export function ResultFlowLabel({ structureItem, elements }) {
  const { templateType, label, rules } = structureItem;
  const elementIdByType = getIndexByTemplateType(elements, structureItem);

  return (
    <div style={{ padding: '24px 15px 0' }}>
      <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ marginBottom: 16 }} />
      <FlowRulesList elementRules={rules} />
    </div>
  );
}
