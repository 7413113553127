import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addUserError,
  addUserRequest,
  addUserSuccess,
  applyUserArrangesError,
  applyUserArrangesRequest,
  closeUserModal,
  deleteUserError,
  deleteUserRequest,
  deleteUserSuccess,
  editUserError,
  editUserRequest,
  editUserSuccess,
  getUsersError,
  getUsersRequest,
  getUsersSuccess,
  removeUserFiltersError,
  removeUserFiltersRequest,
  setUserStatusError,
  setUserStatusRequest,
  setUserStatusSuccess,
} from '../slices/usersSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getUsersFromApi } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    users: { sorting, filtering, pagination },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });

  const res = yield call(getUsersFromApi, config);

  if (res?.data) {
    if (isEmptyPage(res?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = res.data;
    yield put(getUsersSuccess({ users: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* getUsers({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getUsersError({ error }));
  }
}

function* addUser({ payload }) {
  try {
    yield call(axiosInstance.post, 'admin/users', payload);
    yield put(addUserSuccess({ success: { messageType: addUserSuccess.type } }));
    yield put(closeUserModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addUserError({ error }));
  }
}

function* editUser({ payload }) {
  try {
    yield call(axiosInstance.put, 'admin/users', payload);
    yield put(editUserSuccess({ success: { messageType: editUserSuccess.type } }));
    yield put(closeUserModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(editUserError({ error }));
  }
}

function* deleteUser({ payload: userId }) {
  try {
    yield call(axiosInstance.delete, `admin/users/${userId}`);
    yield put(deleteUserSuccess({ success: { messageType: deleteUserSuccess.type } }));
    yield put(closeUserModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteUserError({ error }));
  }
}

function* setUserStatus({ payload: { userId, status } }) {
  try {
    yield call(axiosInstance.patch, `admin/users/${userId}/set-active?active=${status}`);
    yield put(setUserStatusSuccess({ success: { messageType: setUserStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setUserStatusError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyUserArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeUserFiltersError({ error }));
  }
}

/**
 * @desc function generator - observer for all user's sagas functions
 */
export function* watchUsersManagement() {
  yield all([
    takeLatest(getUsersRequest.type, getUsers),
    takeLatest(setUserStatusRequest.type, setUserStatus),
    takeLatest(addUserRequest.type, addUser),
    takeLatest(editUserRequest.type, editUser),
    takeLatest(deleteUserRequest.type, deleteUser),
    takeLatest(applyUserArrangesRequest.type, applyArranges),
    takeLatest(removeUserFiltersRequest.type, removeFilters),
  ]);
}
