import { AppFormSection } from '../../ui/AppFormSection';
import { FormItemName } from '../../lib/models/Form';
import { LinkIcon } from '../../ui/icons/LinkIcon';
import { useLocalization } from '../../lib/hooks/useLocalization';
import { handleTranslation } from '../../lib/util/helpers';
import styles from './LinkFormItem.module.sass';
import { LINK_PATTERN } from '../../lib/util/regexp';

export function LinkFormItem(props) {
  const { errors } = useLocalization().translations.unique.formItems.link;
  const { formItemProps: { rules = [], ...restFormItemProps } = {}, textFieldProps } = props;

  return (
    <AppFormSection
      className={styles.root}
      formItemProps={{
        name: FormItemName.LINK,
        rules: [{ pattern: LINK_PATTERN, message: handleTranslation(errors.invalid) }].concat(rules),
        ...restFormItemProps,
      }}
      textFieldProps={{
        prefix: <LinkIcon />,
        ...textFieldProps,
      }}
    />
  );
}
