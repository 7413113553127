import { Products } from './components/Products';
import { TotalPrice } from './components/TotalPrice';
import { ResultButtons } from './components/ResultButtons';
import styles from './styles.module.scss';
import { ResultHeadline } from './components/ResultHeadline';
import { ResultSubline } from './components/ResultSubline';
import { useResultProps } from '../../hooks/useResultProps';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

export function PreviewResultLayout({
  resultObject,
  colors,
  fonts,
  style,
  allPages,
  setPagesFlow,
  pagesFlow,
  fontsForLoading,
  pageHistory,
  currentElements,
  setPageHistory,
  showChapterIds,
}) {
  const { applyVariable } = useVariables(pagesFlow);
  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, buttonsStyle = {} } = style;

  const {
    content: { buttons },
  } = resultObject;

  const {
    headline,
    subline,
    sublineProps = {},
    headlineProps = {},
    printedResultProps = {},
    productInformation = {},
    totalPrice,
    resultProducts,
  } = useResultProps({
    allPages,
    resultObject,
    fonts,
    colors,
    headlineStyle,
    sublineStyle,
    pagesFlow,
    fontsForLoading,
    pageHistory,
    showChapterIds,
  });

  return (
    <section
      className={styles.section}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#F2F2F3',
      }}
    >
      <div className={styles.container} style={rootStyle}>
        {headline && <ResultHeadline applyVariable={applyVariable} showInfoIcon {...headlineProps} />}
        {subline && <ResultSubline applyVariable={applyVariable} showInfoIcon {...sublineProps} />}
        <Products products={resultProducts} fonts={fonts} colors={colors} productInformation={productInformation} pagesFlow={pagesFlow} />
        {productInformation?.showTotalPrice && (
          <TotalPrice productInformation={productInformation} totalPrice={totalPrice} fonts={fonts} colors={colors} applyVariable={applyVariable} />
        )}
        <ResultButtons
          list={buttons.filter(button => pagesFlow.allShownButtonsIds.includes(button.id))}
          buttonsStyle={buttonsStyle}
          setPagesFlow={setPagesFlow}
          pagesFlow={pagesFlow}
          printedResultProps={printedResultProps}
          colors={colors}
          fonts={fonts}
          resultObject={resultObject}
          currentElements={currentElements}
          setPageHistory={setPageHistory}
        />
      </div>
    </section>
  );
}
