import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { LinearFlowIcon } from '../../../../../../../../../../ui/icons/LinearFlowIcon';
import { NonLinearFlowIcon } from '../../../../../../../../../../ui/icons/NonLinearFlowIcon';
import { AppTooltip } from '../../../../../../../../../../ui/AppTooltip';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { LabelTexts } from '../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../lib/util/helpers';
import { Typography } from 'antd';

/**
 * @desc Chapter
 * @param {Object} providedItem
 * @param {Object} item
 * @param {Function} onToggleVisible
 * @param {Object} snapshotItem
 * @param {Function} onOpenContextMenu
 * @param {Function} onClickScroll
 * @param {Array} items
 * @param {ReactNode} children
 * @return {JSX.Element}
 */
export function Chapter({ providedItem, item, onToggleVisible, snapshotItem, onOpenContextMenu, onClickScroll, items, children }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const { navigatorTab } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;

  const handleClick = itemId => {
    onToggleVisible(itemId, item.isOpen);
    onClickScroll(itemId);
  };

  const itemIdByType = getIndexByTemplateType(items, item);

  return (
    <Item
      $isViewMode={isViewMode}
      ref={providedItem.innerRef}
      {...providedItem.draggableProps}
      {...providedItem.dragHandleProps}
      onClick={() => handleClick(item.id)}
      isDragging={snapshotItem.isDragging}
      onContextMenu={event => onOpenContextMenu(event, item?.id)}
    >
      <ItemLeft>
        {item.isCollapsed && <ChevronUpIcon style={{ marginRight: 10, transform: !item.isOpen && 'scaleY(-1)', transition: '0.2s transform' }} />}
        <AppText className='white_color' style={{ fontSize: 12, marginRight: 5 }}>
          {`${LabelTexts[item.templateType]}.${itemIdByType + 1}`}
        </AppText>
        <Title>
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{
              color: 'inherit',
              marginBottom: 0,
              maxWidth: 130,
              fontSize: 12,
            }}
          >
            {item?.content?.headline?.value || item.label}
          </Typography.Paragraph>
        </Title>
      </ItemLeft>
      <AppTooltip text={item.flow.goTo === 'NEXT' ? navigatorTab.linearFlow : navigatorTab.nonLinearFlow}>
        <span>{item.flow.goTo === 'NEXT' ? <LinearFlowIcon /> : <NonLinearFlowIcon />}</span>
      </AppTooltip>
      {children}
    </Item>
  );
}

const Item = styled('div')(({ $isViewMode }) => ({
  color: '#fff',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 10,
  '&:hover': {
    background: 'rgba(25, 25, 25, 0.06);',
  },
  ...($isViewMode && { cursor: 'pointer' }),
}));

const ItemLeft = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Title = styled('div')({
  fontSize: 12,
  lineHeight: '14px',
});
