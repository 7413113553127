/* eslint-disable no-param-reassign */
import { QuestionnaireSelect } from '../../../../features/QuestionnaireSelect';
import { useUpdateStructure } from '../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useSelector } from 'react-redux';
import { formatOptions } from '../../../../utils/helpers';

export function ProductsPriority({ structureItemIndex, structureItem, disabled }) {
  const { updateStructure } = useUpdateStructure();
  const { tenantProducts } = useSelector(({ finders }) => finders);
  const { label } = useLocalization().translations.unique.pages.questionnaire.content.formItems.productsPriority;

  const onChange = selected => {
    updateStructure(draft => {
      const productsPriority = draft?.[structureItemIndex]?.content?.productsPriority;

      const newIds = selected?.map(item => item.value);
      draft[structureItemIndex].content.productsPriority = Array.isArray(productsPriority) ? newIds : [];
    });
  };

  return (
    <QuestionnaireSelect
      formItemProps={{ style: { minHeight: 'unset', marginBottom: '34px' } }}
      selectProps={{
        isMultiSelect: true,
        withoutLimit: true,
        value: structureItem?.content?.productsPriority || [],
        label,
        onChange,
        options: Array.isArray(tenantProducts) ? formatOptions(tenantProducts) : [],
        disabled,
      }}
    />
  );
}
