import styled from 'styled-components';
import { CardSectionHeader } from '../../../../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { TemplateFormSection } from '../../../../../../../../../../features/TemplateFormSection';
import { AppBasicButton } from '../../../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../../../../ui/icons/DeleteIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import lodashGet from 'lodash.get';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';
import lodashSet from 'lodash.set';

const MAX_NUMBER_BUTTONS = 2;
const DELETE_AMOUNT = 1;

export function ProductButtons({ data, productIndex, structureItemIndex }) {
  const { productsDepot } = useLocalization().translations.unique.pages.result;
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { updateStructure } = useUpdateStructure();
  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].buttonsSection.buttons`;

  const handleChange = (value, buttonIndex) => {
    const dataPathForChangeButtonText = `${dataPath}[${buttonIndex}]`;
    updateStructure(draft => {
      lodashSet(draft, `${dataPathForChangeButtonText}.buttonText.value`, value);
    });
  };

  const handleDeleteButton = buttonIndex => {
    updateStructure(draft => {
      const buttons = lodashGet(draft, dataPath);
      buttons.splice(buttonIndex, DELETE_AMOUNT);
    });
  };

  const handleAddButton = () => {
    updateStructure(draft => {
      const buttons = lodashGet(draft, dataPath);
      buttons.push({
        id: uuidv4(),
        rules: [],
        buttonText: { value: '' },
        flow: {
          goTo: null,
          href: null,
          targetBlank: true,
        },
      });
    });
  };

  return (
    <Root>
      <CardSectionHeader>
        <AppText strong textValue={productsDepot.buttons.title} />
      </CardSectionHeader>
      <StyledButtons>
        {data?.map((button, idx) => (
          <StyledButton key={button.id}>
            <StyledButtonWrapper>
              <TemplateFormSection
                valueObject={button?.buttonText}
                label={productsDepot.buttons.enterButtonText}
                onChange={value => handleChange(value, idx)}
              />
            </StyledButtonWrapper>
            <AppBasicButton onClick={() => handleDeleteButton(idx)} disabled={isViewMode}>
              <DeleteIcon />
            </AppBasicButton>
          </StyledButton>
        ))}
        <AppBasicButton onClick={handleAddButton} disabled={isViewMode || data?.length === MAX_NUMBER_BUTTONS}>
          <AppText className='primary_color bold' textValue={productsDepot.buttons.addButton} />
        </AppBasicButton>
      </StyledButtons>
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 24px',
  borderBottom: '1px solid #ECECEE',
});

const StyledButtons = styled('div')({
  marginTop: 32,
});

const StyledButtonWrapper = styled('div')({
  width: '95%',
  marginRight: 15,
});

const StyledButton = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
});
