import classNames from 'classnames';
import classes from '../ComparisonTable.module.sass';
import { AppImage } from '../../../../../../../../../../ui/AppImage';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function ProductBlock({ image, label }) {
  const { contentTable } = useLocalization().translations.unique.pages.questionnaire;

  return (
    <AppBox $flexCenterXY>
      <div className={classes.circle}>
        <AppImage className={classNames(classes.circleInner, classes.circleImage)} src={image} alt={contentTable.productImageAlt} />
      </div>
      <AppText textValue={label} ellipsis style={{ color: '#fff' }} />
    </AppBox>
  );
}
