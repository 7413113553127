import { AppTitle } from '../../../../../../../ui/text/AppTitle';
import classes from '../index.module.scss';
import { getTextStyles } from '../../../../../../../lib/util/helpers';
import { InfoIconComponent } from '../../../pages/PreviewPage/components/InfoIconComponent';

export function TableName({ item, fonts, colors }) {
  return (
    <div>
      <AppTitle level={3} textValue={item?.value} className={classes.tableName} style={getTextStyles(fonts?.headlineThird, colors)} />
      {item?.infoIcon && <InfoIconComponent type={item} />}
    </div>
  );
}
