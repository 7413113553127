import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { AppSpace } from '../../../../../../../../../../ui/AppSpace';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { FormCardButton } from '../../../../../../features/FormCardButton';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { FontFamilyFormItem } from '../../../../../../features/form-items/FontFamilyFormItem';
import { FontWeightFormItem } from '../../../../../../features/form-items/FontWeightFormItem';
import { FontSizeFormItem } from '../../../../../../features/form-items/FontSizeFormItem';
import { FontColorFormItem } from '../../../../../../features/form-items/FontColorFormItem';

export function FontsCard({ themeConfiguration, setThemeConfiguration, defaultFontStyles, disabled = false }) {
  const { theme } = useLocalization().translations.unique.pages;
  const { fonts } = useSelector(({ themes }) => themes);

  const onChangeField = (value, fieldName, field) => {
    if (value) {
      setThemeConfiguration({
        ...themeConfiguration,
        fonts: { ...themeConfiguration.fonts, [fieldName]: { ...themeConfiguration.fonts[fieldName], [field]: value } },
      });
    }
  };

  const resetFields = () => {
    setThemeConfiguration({ ...themeConfiguration, fonts: defaultFontStyles });
  };

  const fontForms = ['headlineFirst', 'headlineSecond', 'headlineThird', 'headlineFourth', 'body', 'subText', 'textLink'].map(fieldName => {
    return {
      label: theme[fieldName](),
      fieldName,
      font: themeConfiguration.fonts[fieldName].font,
      fontWeight: themeConfiguration.fonts[fieldName].fontWeight,
      fontSize: themeConfiguration.fonts[fieldName].fontSize,
      colorVariant: themeConfiguration.fonts[fieldName].colorVariant,
    };
  });

  return (
    <>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className={`bold ${disabled ? 'primary_text_color_disabled' : ''}`} textValue={theme.fonts} />
        {!disabled && <FormCardButton onClick={resetFields} />}
      </AppBox>
      {fontForms.map(({ label, fieldName, font, fontWeight, fontSize, colorVariant }) => (
        <Form.Item key={label} label={label} style={{ marginBottom: 0, fontWeight: 400 }}>
          <AppSpace size='middle' className='stretch_first_child'>
            <FontFamilyFormItem value={font} onChange={onChangeField} fieldName={fieldName} field='font' disabled={disabled} />
            <FontWeightFormItem
              value={fonts.map(fontObj => fontObj.fontName).includes(font) ? undefined : fontWeight}
              onChange={onChangeField}
              fieldName={fieldName}
              field='fontWeight'
              disabled={disabled || fonts.map(fontObj => fontObj.fontName).includes(font)}
              placeholder={fonts.map(fontObj => fontObj.fontName).includes(font) ? theme.noOption() : ''}
            />
            <FontSizeFormItem value={fontSize} onChange={onChangeField} fieldName={fieldName} field='fontSize' disabled={disabled} />
            <FontColorFormItem
              value={colorVariant}
              onChange={onChangeField}
              fieldName={fieldName}
              field='colorVariant'
              colors={themeConfiguration.colors}
              disabled={disabled}
            />
          </AppSpace>
        </Form.Item>
      ))}
    </>
  );
}
