import classes from '../index.module.scss';
import { AppImage } from '../../../../../../../ui/AppImage';
import { InfoIconComponent } from '../../../pages/PreviewPage/components/InfoIconComponent';

export function Icon({ cell }) {
  return (
    <div className={classes.icon}>
      <AppImage src={cell?.image} alt='Yes' />
      {cell?.infoIcon && <InfoIconComponent type={cell} />}
    </div>
  );
}
