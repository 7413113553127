import { useContext } from 'react';
import { PreviewContext } from '../PreviewContext';

export function usePreviewContext() {
  const context = useContext(PreviewContext);

  if (!context) {
    throw new Error('"usePreviewContext" must be used within its provider');
  }

  return context;
}
