/* eslint-disable max-lines */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { AppContent } from '../../../../../../ui/AppContent';
import { AppButton } from '../../../../../../ui/buttons/AppButton';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { GeneralSettingsForm } from './components/GeneralSettingsForm';
import { DesignSettingsForm } from './components/DesignSettingsForm';
import { TableTabs } from '../../features/TableTabs';
import { checkIsUser } from '../../../../../../lib/models/Role';
import { getDomainAndSubDomain, trimForm } from '../../../../../../lib/util/helpers';
import { editFinderDesignSettingsRequest, editFinderGeneralSettingsRequest } from '../../../../../../lib/store/slices/findersSlice';
import { getTenantDomainsRequest } from '../../../../../../lib/store/slices/tenantsSlice';
import { FormItemName } from '../../../../../../lib/models/Form';
import { ColorVariants, DefaultColors, DefaultFontStyles } from '../../../../lib/models/DefaultStylesFontsAndColors';
import { dateFormatter } from '../../../../lib/helpers';
import { useGetFinder } from '../../../../../../lib/hooks/useGetFinder';
import { useFinderSubdomain } from '../FindersAndProductsPage/modules/finders/lib/useFinderSubdomain';
import { selectPreviewQuestionnaireElement } from '../../../../../../lib/store/slices/previewSlice';

const Tab = {
  GENERAL_SETTINGS: 'GENERAL_SETTINGS',
  DESIGN_SETTINGS: 'DESIGN_SETTINGS',
};

function getInitialDomainAndSubdomain(link) {
  try {
    return getDomainAndSubDomain(link);
  } catch {
    return link;
  }
}

function getFields(finder) {
  const { brand = {}, createdDate, finderSettings = {}, generalSettings = {}, url } = finder;
  const { header = {}, footer = {}, faviconTitle = {} } = finderSettings;
  const { meta = {}, mailServer = {}, dataProtection = {}, tracking = {} } = generalSettings;

  const domainAndSubdomain = getInitialDomainAndSubdomain(url);

  return [
    ...Object.entries(finder).map(([key, value]) => ({ name: key, value })),
    { name: FormItemName.CREATION_DATE, value: dateFormatter(createdDate) },
    { name: FormItemName.DOMAIN, value: { value: domainAndSubdomain?.[1] } },
    { name: FormItemName.SUBDOMAIN, value: domainAndSubdomain?.[0] },
    { name: FormItemName.META_TITLE, value: meta.title ? meta.title : '' },
    { name: FormItemName.META_DESCRIPTION, value: meta.description ? meta.description : '' },
    { name: FormItemName.MAIL_SERVER, value: mailServer.usedDefaultServer },
    { name: FormItemName.MAIL_SERVER_CONTACT, value: mailServer.contactEmailForForms },
    { name: FormItemName.MAIL_SERVER_SENDER, value: mailServer.senderEmail },
    { name: FormItemName.MAIL_SERVER_HOST, value: mailServer.host },
    { name: FormItemName.MAIL_SERVER_PORT, value: mailServer.port },
    { name: FormItemName.MAIL_SERVER_USER_NAME, value: mailServer.userName },
    { name: FormItemName.MAIL_SERVER_PASSWORD, value: mailServer.password },
    { name: FormItemName.TRACKING, value: tracking?.usedTracking },
    { name: FormItemName.TRACKER_URL, value: tracking?.trackerUrl },
    { name: FormItemName.TRACKING_SOFTWARE, value: tracking?.trackingSoftware },
    { name: FormItemName.DATA_PROTECTION, value: dataProtection.notificationEnabled },
    { name: FormItemName.DATA_PROTECTION_HEAD, value: dataProtection.headline },
    { name: FormItemName.DATA_PROTECTION_SUB, value: dataProtection.subLine },
    { name: FormItemName.DATA_PROTECTION_CONTENT, value: dataProtection.content },
    { name: FormItemName.THEME_ID, value: { value: brand.id } },
    { name: FormItemName.HEADER_HEAD, value: header.headline },
    { name: FormItemName.HEADER_SUB, value: header.subLine },
    { name: FormItemName.PRIMARY_BUTTON, value: header.primaryButton },
    { name: FormItemName.SECONDARY_BUTTON, value: header.secondaryButton },
    { name: FormItemName.BUTTON_LINKS, value: footer.buttonLinks },
    { name: FormItemName.FAVICON_TITLE_TITLE, value: faviconTitle.title },
  ];
}

function getDesignSettings(finder) {
  return {
    themeConfig: finder?.brand?.configuration ? JSON.parse(finder.brand.configuration) : { colors: DefaultColors, fonts: DefaultFontStyles },
    mediaFiles: finder?.finderSettings?.media,
    bulletIcons: {
      defaultIcons: finder?.finderSettings?.bulletIcons?.defaultIcons,
      customIcons: finder?.finderSettings?.bulletIcons?.customIcons,
    },
    headerConfig: {
      autoGuide: finder?.finderSettings?.header?.autoGuide,
      backgroundColor: finder?.finderSettings?.header?.backgroundColor || ColorVariants.SECONDARY_SECOND,
      isAutoGuide: finder?.finderSettings?.header?.isAutoGuide,
      isBackgroundShadow: finder?.finderSettings?.header?.isBackgroundShadow,
      logo: finder?.finderSettings?.header?.logo,
      showHeader: finder?.finderSettings?.header?.showHeader,
    },
    footerConfig: {
      backgroundColor: finder?.finderSettings?.footer?.backgroundColor || ColorVariants.SECONDARY_SECOND,
      isBackgroundShadow: finder?.finderSettings?.footer?.isBackgroundShadow,
      progressbarBackgroundColor: finder?.finderSettings?.footer?.progressbarBackgroundColor || ColorVariants.PRIMARY_COLOR,
      progressbarVariant: finder?.finderSettings?.footer?.progressbarVariant,
      oppositeColorForProgressBar: finder?.finderSettings?.footer?.oppositeColorForProgressBar,
    },
    faviconTitleConfig: {
      title: finder?.finderSettings?.faviconTitle?.title,
      favicon: finder?.finderSettings?.faviconTitle?.favicon,
    },
  };
}

// eslint-disable-next-line max-lines-per-function
export function SettingsPage() {
  const { translations } = useLocalization();
  const { settings } = translations.unique.pages;
  const { role } = useSelector(({ auth }) => auth);
  const { loading, finder, tenant, isUploading } = useSelector(({ finders }) => finders);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { tenantId } = useGetFinder();
  const { finderURL } = useFinderSubdomain(form);

  const [activeTab, setActiveTab] = useState(checkIsUser(role) ? Tab.DESIGN_SETTINGS : Tab.GENERAL_SETTINGS);

  const [designSettings, setDesignSettings] = useState(getDesignSettings(finder));

  const onFinish = values => {
    const trimmedForm = trimForm(values);

    const generalSettings = {
      meta: {
        [FormItemName.META_TITLE]: trimmedForm[FormItemName.META_TITLE],
        [FormItemName.META_DESCRIPTION]: trimmedForm[FormItemName.META_DESCRIPTION],
      },
      mailServer: {
        [FormItemName.MAIL_SERVER]: trimmedForm[FormItemName.MAIL_SERVER],
        [FormItemName.MAIL_SERVER_CONTACT]: trimmedForm[FormItemName.MAIL_SERVER_CONTACT],
        [FormItemName.MAIL_SERVER_SENDER]: trimmedForm[FormItemName.MAIL_SERVER_SENDER],
        [FormItemName.MAIL_SERVER_HOST]: trimmedForm[FormItemName.MAIL_SERVER_HOST],
        [FormItemName.MAIL_SERVER_PORT]: trimmedForm[FormItemName.MAIL_SERVER_PORT],
        [FormItemName.MAIL_SERVER_USER_NAME]: trimmedForm[FormItemName.MAIL_SERVER_USER_NAME],
        [FormItemName.MAIL_SERVER_PASSWORD]: trimmedForm[FormItemName.MAIL_SERVER_PASSWORD],
      },
      tracking: {
        [FormItemName.TRACKING]: trimmedForm[FormItemName.TRACKING],
        [FormItemName.TRACKER_URL]: trimmedForm[FormItemName.TRACKER_URL],
        [FormItemName.TRACKING_SOFTWARE]: trimmedForm[FormItemName.TRACKING_SOFTWARE],
      },
      dataProtection: {
        [FormItemName.DATA_PROTECTION]: trimmedForm[FormItemName.DATA_PROTECTION],
        [FormItemName.DATA_PROTECTION_HEAD]: trimmedForm[FormItemName.DATA_PROTECTION_HEAD],
        [FormItemName.DATA_PROTECTION_SUB]: trimmedForm[FormItemName.DATA_PROTECTION_SUB],
        [FormItemName.DATA_PROTECTION_CONTENT]: trimmedForm[FormItemName.DATA_PROTECTION_CONTENT],
      },
    };

    const generalSettingsAndBasicInfo = { generalSettings, [FormItemName.FINDER_NAME]: trimmedForm[FormItemName.FINDER_NAME], url: finderURL };
    const finderSettings = {
      media: designSettings.mediaFiles,
      bulletIcons: designSettings.bulletIcons,
      header: {
        ...designSettings.headerConfig,
        headline: trimmedForm[FormItemName.HEADER_HEAD],
        subLine: trimmedForm[FormItemName.HEADER_SUB],
        primaryButton: trimmedForm[FormItemName.PRIMARY_BUTTON],
        secondaryButton: trimmedForm[FormItemName.SECONDARY_BUTTON],
      },
      footer: { ...designSettings.footerConfig, buttonLinks: trimmedForm[FormItemName.BUTTON_LINKS] },
      faviconTitle: { ...designSettings.faviconTitleConfig, title: trimmedForm[FormItemName.FAVICON_TITLE_TITLE] },
    };

    const finderSettingsAndTheme = {
      finderSettings,
      brand: { id: trimmedForm[FormItemName.THEME_ID] ? trimmedForm[FormItemName.THEME_ID].value : null },
    };

    if (activeTab === Tab.GENERAL_SETTINGS && !isUploading) {
      dispatch(editFinderGeneralSettingsRequest({ finder: { ...finder, ...generalSettingsAndBasicInfo }, tenantId: tenant.id }));
    }
    if (activeTab === Tab.DESIGN_SETTINGS && !isUploading) {
      dispatch(editFinderDesignSettingsRequest({ finder: { ...finder, ...finderSettingsAndTheme }, tenantId: tenant.id }));
    }
  };

  const handleFormSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const forms = {
    GENERAL_SETTINGS: <GeneralSettingsForm form={form} />,
    DESIGN_SETTINGS: <DesignSettingsForm form={form} designSettings={designSettings} setDesignSettings={setDesignSettings} />,
  };

  const tabOptions = [
    { tabName: settings.general.tab, value: Tab.GENERAL_SETTINGS },
    { tabName: settings.design.tab, value: Tab.DESIGN_SETTINGS },
  ];

  const filteredOptions = checkIsUser(role) ? [{ tabName: settings.design.tab, value: Tab.DESIGN_SETTINGS }] : tabOptions;

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        handleFormSubmit();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleFormSubmit]);

  useEffect(() => {
    dispatch(getTenantDomainsRequest(tenantId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFields(getFields(finder));
    setDesignSettings(getDesignSettings(finder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finder]);

  useEffect(() => {
    dispatch(selectPreviewQuestionnaireElement({ selectedQuestionnaireElement: null, finderId: finder.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <AppContent>
      <div style={{ width: 872, marginLeft: 368 }}>
        <Form
          form={form}
          id='settings'
          style={{ margin: '32px auto', maxWidth: 872 }}
          layout='vertical'
          onFinish={onFinish}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
            <TableTabs options={filteredOptions} selected={activeTab} setActiveTab={setActiveTab} />
            <AppButton
              htmlType='submit'
              disabled={isUploading}
              loading={loading || isUploading}
              type='primary'
              textValue={settings.saveButton}
              style={{ width: 200 }}
            />
          </div>
          {forms[activeTab]}
        </Form>
      </div>
    </AppContent>
  );
}
