import { PopupSelect } from './PopupSelect';
import { ColorSingleValue } from './ColorSingleValue';
import { ColorOption } from './ColorOption';

export function ColorSelect({ components, label, ...rest }) {
  return (
    <PopupSelect
      label={label}
      components={{
        SingleValue: ColorSingleValue,
        Option: ColorOption,
        ...components,
      }}
      {...rest}
    />
  );
}
