import { ResultType } from '../../../../../../../../../../../lib/models/Questionnaire';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ContactFormRowHeader } from './ContactFormRowHeader';
import { ContactFormRowContent } from './ContactFormRowContent';

const styles = { display: 'flex', flexDirection: 'column' };

export function ContactFormRow({ rowIndex, structureItemIndex, templateContent, id }) {
  return (
    <Draggable
      draggableId={JSON.stringify({
        type: ResultType.CONTACT_FORM_ROW,
        rowIndex,
        structureItemIndex,
        id,
      })}
      index={rowIndex}
    >
      {providedDraggable => (
        <li ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} style={{ ...providedDraggable.draggableProps.style, ...styles }}>
          <ContactFormRowHeader
            rowIndex={rowIndex}
            providedDraggable={providedDraggable}
            structureItemIndex={structureItemIndex}
            templateContent={templateContent}
          />
          <ContactFormRowContent rowIndex={rowIndex} structureItemIndex={structureItemIndex} templateContent={templateContent} />
        </li>
      )}
    </Draggable>
  );
}
