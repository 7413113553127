/* eslint-disable unicorn/no-array-for-each,no-magic-numbers,sonarjs/cognitive-complexity,no-unsafe-optional-chaining,no-unused-expressions,max-lines-per-function,no-prototype-builtins,max-lines */
import { isLength } from '../../../../../../lib/util/helpers';
import { getRuleConditionResult } from './then_condition_helper';
import { getIfConditions } from './if_conditions_helper';

export const rulesEngine = ({ rules, pagesFlow, currentElements, rulesFilter, logger = true }) => {
  const rulesResults = [];

  rules?.forEach(rule => {
    const ruleConditions = JSON.parse(rule?.conditions);
    const activeConditions = ruleConditions.filter(condition => condition.isActive);
    activeConditions.forEach(activeCond => {
      const thenValueIndex = activeCond.body.map(item => item.value).indexOf('THEN');
      const ifBlocks = activeCond.body
        .filter((bodyItem, index) => index < thenValueIndex && bodyItem.value !== 'IF')
        .reduce((accumulator, block) => {
          if (block.value === '(') {
            accumulator.push([]);
            return accumulator;
          }
          if (block.value === ')') {
            accumulator.push(null);
            return accumulator;
          }
          if (accumulator.length > 0 && Array.isArray(accumulator.at(-1))) {
            accumulator.at(-1).push(block);
            return accumulator;
          }
          if (!Array.isArray(accumulator.at(-1))) {
            accumulator.push(block);
            return accumulator;
          }
          return accumulator;
        }, [])
        .filter(Boolean);
      const resultBlocks = activeCond.body.filter((bodyItem, index) => index > thenValueIndex);
      const { value } = getIfConditions(ifBlocks, pagesFlow, currentElements);
      const actionedResults = getRuleConditionResult(resultBlocks, rule, activeCond, pagesFlow, currentElements);
      const resultsWithConditionValue = actionedResults.map(resultBlock => ({ ...resultBlock, conditionValue: value }));

      rulesResults.push(resultsWithConditionValue);
    });
  });

  const positiveResults = rulesFilter ? rulesResults.flat().filter(res => rulesFilter.some(prop => res?.hasOwnProperty(prop))) : rulesResults.flat();

  const allRulesOutput = positiveResults.reduce(
    (result, resBlock) => {
      if (resBlock.goToFlow && resBlock.conditionValue) {
        return { ...result, ...resBlock };
      }
      if (resBlock.showOrHideFlow && resBlock.conditionValue) {
        return { ...result, showOrHideFlow: [...result?.showOrHideFlow, resBlock.showOrHideFlow] };
      }
      if (resBlock.imageSelectionFlow && resBlock.conditionValue) {
        return { ...result, imageSelectionFlow: [...result?.imageSelectionFlow, resBlock.imageSelectionFlow] };
      }
      if (resBlock.priceProduct && resBlock.conditionValue) {
        return { ...result, priceProduct: [...result?.priceProduct, resBlock] };
      }
      if (resBlock.counterProduct && resBlock.conditionValue) {
        return { ...result, counterProduct: [...result?.counterProduct, resBlock] };
      }
      if (resBlock.scoreProduct && resBlock.conditionValue) {
        return { ...result, scoreProduct: [...result?.scoreProduct, resBlock] };
      }
      if (resBlock.savingsProduct && resBlock.conditionValue) {
        return { ...result, savingsProduct: [...result?.savingsProduct, resBlock] };
      }
      if (resBlock.resultProduct) {
        return { ...result, resultProduct: [...result?.resultProduct, resBlock] };
      }
      if (resBlock.selectProduct) {
        return { ...result, selectProduct: [...result?.selectProduct, resBlock] };
      }
      if (resBlock.dependenceProduct && resBlock.conditionValue) {
        return { ...result, dependenceProduct: [...result?.dependenceProduct, resBlock] };
      }
      if (resBlock.resultVariables) {
        return { ...result, resultVariables: [...result?.resultVariables, resBlock] };
      }
      if (resBlock.scoreAnswer) {
        return { ...result, scoreAnswer: [...result?.scoreAnswer, resBlock] };
      }
      if (resBlock.savingsAnswer) {
        return { ...result, savingsAnswer: [...result?.savingsAnswer, resBlock] };
      }
      if (resBlock.setAnswer && resBlock.conditionValue) {
        return { ...result, setAnswer: [...result?.setAnswer, resBlock] };
      }
      if (resBlock.selectAnswer) {
        return { ...result, selectAnswer: [...result?.selectAnswer, resBlock] };
      }
      return result;
    },
    {
      goToFlow: null,
      showOrHideFlow: [],
      imageSelectionFlow: [],
      priceProduct: [],
      counterProduct: [],
      scoreProduct: [],
      savingsProduct: [],
      selectProduct: [],
      resultProduct: [],
      dependenceProduct: [],
      resultVariables: [],
      scoreAnswer: [],
      savingsAnswer: [],
      setAnswer: [],
      selectAnswer: [],
    }
  );
  const {
    goToFlow,
    showOrHideFlow,
    imageSelectionFlow,
    priceProduct,
    counterProduct,
    scoreProduct,
    savingsProduct,
    resultProduct,
    selectProduct,
    resultVariables,
    dependenceProduct,
    scoreAnswer,
    savingsAnswer,
    setAnswer,
    selectAnswer,
  } = allRulesOutput;

  // eslint-disable-next-line no-console,i18n-text/no-en
  goToFlow && logger && console.log(goToFlow && `Result Flow GoTo of condition ${goToFlow?.condition?.name} of rule ${goToFlow?.rule?.name} done`);

  isLength(showOrHideFlow) &&
    logger &&
    // eslint-disable-next-line no-console
    showOrHideFlow.forEach(block => console.log(`Result Flow Show or Hide of condition ${block?.condition?.name} of rule ${block?.rule?.name} done`));

  isLength(imageSelectionFlow) &&
    logger &&
    imageSelectionFlow.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Flow Image selection of condition ${block?.condition?.name} of rule ${block?.rule?.name} done`)
    );

  isLength(priceProduct) &&
    logger &&
    priceProduct.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Product Price of condition ${block?.priceProduct?.condition?.name} of rule ${block?.priceProduct?.rule?.name} done`)
    );

  isLength(counterProduct) &&
    logger &&
    counterProduct.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Product Counter of condition ${block?.counterProduct?.condition?.name} of rule ${block?.counterProduct?.rule?.name} done`)
    );

  isLength(scoreProduct) &&
    logger &&
    scoreProduct.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Product Score of condition ${block?.scoreProduct?.condition?.name} of rule ${block?.scoreProduct?.rule?.name} done`)
    );

  isLength(savingsProduct) &&
    logger &&
    savingsProduct.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Product Savings of condition ${block?.savingsProduct?.condition?.name} of rule ${block?.savingsProduct?.rule?.name} done`)
    );

  isLength(resultProduct) &&
    logger &&
    resultProduct.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(
          `Result Product Selection Add to Result of condition ${block?.resultProduct?.condition?.name} of rule ${block?.resultProduct?.rule?.name} done`
        )
    );

  isLength(selectProduct) &&
    logger &&
    selectProduct.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(
          `Result Product Selection Select/Unselect of condition ${block?.selectProduct?.condition?.name} of rule ${block?.selectProduct?.rule?.name} done`
        )
    );

  if (isLength(resultVariables) && logger) {
    resultVariables.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(`Result Set variable of condition ${block?.resultVariables?.condition?.name} of rule ${block?.resultVariables?.rule?.name} done`)
    );
  }

  isLength(dependenceProduct) &&
    logger &&
    dependenceProduct.forEach(block =>
      // eslint-disable-next-line no-console
      console.log(
        `Result Product Dependence of condition ${block?.dependenceProduct?.condition?.name} of rule ${block?.dependenceProduct?.rule?.name} done`
      )
    );

  isLength(scoreAnswer) &&
    logger &&
    scoreAnswer.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(`Result Answer Score of condition ${block?.scoreAnswer?.condition?.name} of rule ${block?.scoreAnswer?.rule?.name} done`)
    );

  isLength(savingsAnswer) &&
    logger &&
    savingsAnswer.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(`Result Answer Savings of condition ${block?.savingsAnswer?.condition?.name} of rule ${block?.savingsAnswer?.rule?.name} done`)
    );

  isLength(setAnswer) &&
    logger &&
    setAnswer.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(`Result Answer Set of condition ${block?.setAnswer?.condition?.name} of rule ${block?.setAnswer?.rule?.name} done`)
    );

  isLength(selectAnswer) &&
    logger &&
    selectAnswer.forEach(
      block =>
        block.conditionValue &&
        // eslint-disable-next-line no-console
        console.log(
          `Result Answer Selection Select/Unselect of condition ${block?.selectAnswer?.condition?.name} of rule ${block?.selectAnswer?.rule?.name} done`
        )
    );

  return allRulesOutput;
};
