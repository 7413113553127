import { createJwtForPublicApi } from '../../../createJwtForPublicApi';

function handleRequest(request) {
  if (request?.headers) {
    request.headers['X-API-TOKEN'] = createJwtForPublicApi();
  }
  return request;
}

export function applyPublicInterceptors(instance) {
  instance.interceptors.request.use(handleRequest);
}
