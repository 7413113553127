import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { FontColorFormItem } from '../../../../../../../../../features/form-items/FontColorFormItem';
import { BulletPointIconFormItem } from '../../../../../../../../../features/form-items/BulletPointIconFormItem';
import { DraggableFormListContent } from '../../../../../../../../../features/DraggableFormListContent';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { DefaultColors } from '../../../../../../../../../../../lib/models/DefaultStylesFontsAndColors';
import { checkDisableColor, checkValue } from '../../../../../../../../../../../../../lib/util/helpers';
import '../../../style.scss';

export function BulletPointsBlock({ contentBlockIndex, contentBlock, contentBlocks, bulletPoints, bulletPointIcon, handleChangeField, item }) {
  const { bulletPoints: bulletPointsText } = useLocalization().translations.unique.pages.settings.design;
  const { addBulletPoint: addBulletPointText } = useLocalization().translations.shared.generic;
  const { label, headingLabel } = useLocalization().translations.unique.formItems.bulletPoint;
  const {
    isViewMode,
    finder: {
      brand: { configuration },
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);

  const icons = [...defaultIcons, ...customIcons];
  const colors = configuration ? JSON.parse(configuration).colors : DefaultColors;

  const handleChangeBulletPointIcon = fieldName => value => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[contentBlockIndex] = { ...contentBlock, bulletPointIcon: { ...contentBlock.bulletPointIcon, [fieldName]: value } };
    handleChangeField(updatedContentBlocks);
  };

  const handleChangeBulletPoints = updatedBulletPoints => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[contentBlockIndex] = { ...contentBlock, bulletPoints: updatedBulletPoints };
    handleChangeField(updatedContentBlocks);
  };

  return (
    <>
      <AppText className='template_card_headline' textValue={bulletPointsText} />
      <Space size='middle' wrap>
        <BulletPointIconFormItem
          value={checkValue(icons, bulletPointIcon.value)}
          color={colors[bulletPointIcon.color]}
          icons={icons}
          onChange={handleChangeBulletPointIcon('value')}
        />
        <FontColorFormItem
          value={bulletPointIcon.color}
          colors={colors}
          onChange={handleChangeBulletPointIcon('color')}
          disabled={checkDisableColor(icons, bulletPointIcon.value) || isViewMode}
        />
      </Space>
      <DraggableFormListContent
        valueArray={bulletPoints}
        onChange={handleChangeBulletPoints}
        label={label}
        headingLabel={headingLabel}
        addButtonText={addBulletPointText}
        item={item}
        contentBlockIndex={contentBlockIndex}
      />
    </>
  );
}
