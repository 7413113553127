import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { FinderFileLoader } from '../../../../features/FinderFileLoader';
import { HeadphoneIcon } from '../../../../../../../../ui/icons/HeadphoneIcon';
import { PreloaderIcon } from '../../../../../../../../ui/icons/PreloaderIcon';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../lib/store/slices/findersSlice';
import './style.scss';
import { AppBox } from '../../../../../../../../ui/boxes/AppBox';
import { usePermissions } from 'src/lib/hooks/usePermissions';

const MAX_AUDIO_FILE_SIZE = 20;

export function AudioLoader({ index }) {
  const { isRestricted } = usePermissions();
  const [uploading, setUploading] = useState(false);
  const { content } = useLocalization().translations.unique.pages.questionnaire;
  const dispatch = useDispatch();
  const { isViewMode } = useSelector(({ finders }) => finders);

  const uploadAudioFile = (audioObject, items) => {
    const updatedItem = { ...items[index], audio: audioObject };
    const newQuestionnaireStructure = [...items];
    newQuestionnaireStructure[index] = updatedItem;
    dispatch(updateQuestionnaireStructure({ structure: newQuestionnaireStructure }));
  };

  return (
    <FinderFileLoader
      accept='audio/mp3'
      afterUploadAction={uploadAudioFile}
      errorText={content.beforeUploadAudioError()}
      maxFileSize={MAX_AUDIO_FILE_SIZE}
      targetType='FINDER_AUDIO'
      disabled={isRestricted() || uploading}
      setUploading={setUploading}
      uploadLabel={
        <AppBox className='audio_upload_label align_items_center' style={{ cursor: isRestricted() ? 'not-allowed' : '' }} disabled={isViewMode}>
          {uploading ? <PreloaderIcon className='file_uploader' /> : <HeadphoneIcon className='primary_color' />}
          <AppText className='audio_upload_label_text primary_color bold' textValue={content.selectAudioFile} />
        </AppBox>
      }
    />
  );
}
