import { Form } from 'antd';
import { ArrangeFilterPopupWrapper } from '../ArrangeFilterPopupWrapper';
import { SearchFilter } from '../SearchFilter';
import { useResetArrange } from '../../utils/hooks/useResetArrange';

export function FilterListPopup({ filtering, form, onFinish, initialValues = {}, isActiveArrange }) {
  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeFilterPopupWrapper>
        <SearchFilter filtering={filtering} />
      </ArrangeFilterPopupWrapper>
    </Form>
  );
}
