import styled from 'styled-components';
import { Typography } from 'antd';
import { InfoIcon } from '../../../../../../../../../../../../../ui/icons/InfoIcon';
import { isTruthy } from '../../../../../../../../../../../../../lib/util/helpers';
import { DEFAULT_CURRENCY_SYMBOL } from '../../../../../../../../../../../../../lib/models/Finance';

/**
 * @desc Total Price
 * @param {Object} productInformation
 * @param {Number} totalPrice
 * @param {Object} fonts
 * @param {Object} colors
 * @returns {JSX.Element}
 */
export function TotalPrice({ productInformation, totalPrice, fonts, colors }) {
  const maybeTotalPrice = isTruthy(totalPrice) ? `${totalPrice} ${DEFAULT_CURRENCY_SYMBOL}` : '';

  return (
    productInformation?.showTotalPrice && (
      <StyledTotalPrice>
        <StyledTotalPriceText>
          <Typography.Paragraph
            style={{
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: 700,
              color: colors[fonts.body.colorVariant],
              marginLeft: 118,
              marginBottom: 0,
              maxWidth: 500,
            }}
            ellipsis={{ rows: 1 }}
          >
            {productInformation.priceName.value}
          </Typography.Paragraph>
          {productInformation.priceName?.infoIcon?.value && <InfoIcon style={{ marginLeft: 10 }} />}
        </StyledTotalPriceText>
        <p
          style={{
            marginRight: 24,
            marginBottom: 0,
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: 700,
            color: colors[fonts.body.colorVariant],
          }}
        >
          {maybeTotalPrice}
        </p>
      </StyledTotalPrice>
    )
  );
}

const StyledTotalPrice = styled('div')({
  backgroundColor: 'rgba(25, 25, 25, 0.7)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 45,
});

const StyledTotalPriceText = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
