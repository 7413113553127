import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';
import { formatOptions } from '../../../../../../../utils/helpers';
import { setProductFields } from './utils';

export function ProductSelect({ structureItemIndex, productIndex, allProducts, availableProducts, value }) {
  const { updateStructure } = useUpdateStructure();
  const { label } = useLocalization().translations.unique.formItems.templateSelectProduct;

  const onChangeProduct = ({ value: productId, title: productName }) => {
    updateStructure(draft => {
      setProductFields({
        draft,
        productId,
        productName,
        structureItemIndex,
        productIndex,
        allProducts,
      });
    });
  };

  return (
    <QuestionnaireSelect
      selectProps={{
        value,
        label,
        onChange: onChangeProduct,
        options: formatOptions(availableProducts),
        allOptions: formatOptions(allProducts),
      }}
      style={{ height: 40, marginBottom: 0 }}
    />
  );
}
