import { ProductPriceHeaderCell } from './ProductPriceHeaderCell';
import styles from '../../../../utils/styles/table.module.sass';
import { AppText } from '../../../../../../../../ui/text/AppText';

export function useProductPriceColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    dataIndex: props.arrangedId,
    className: styles.numberBodyColumn,
    title: <ProductPriceHeaderCell {...rest} />,
    render: (amount, data) => <AppText>{amount ? `${amount} ${data.currency}` : '-'}</AppText>,
    ...columnProps,
  };
}
