import { useRuleDateColumn } from '../../components/useRuleDateColumn';
import { Rule } from '../../lib/Rule';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';

export function useRuleLastEditedDateColumn() {
  const { lastEdit: title } = useLocalization().translations.shared.columns;

  return useRuleDateColumn({
    arrangedId: Rule.LAST_EDITED_DATE,
    title,
    filterFromName: 'lastModifiedDateFrom',
    filterToName: 'lastModifiedDateTo',
  });
}
