import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

import { AppPreloader } from '../../../../../ui/AppPreloader';
import { AppLayout } from '../../../../../ui/AppLayout';

export function WithSideBarLayout({ sidebar }) {
  return (
    <AppLayout hasSider style={{ overflow: 'auto' }}>
      {sidebar}

      <Suspense fallback={<AppPreloader />}>
        <Outlet />
      </Suspense>
    </AppLayout>
  );
}
