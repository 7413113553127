import { rulesEngine } from './rules_helper';
import { updatePagesRulesInputAnswers } from '../end-user-flow-helpers';
import { isLength } from '../../../../../../lib/util/helpers';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { changeNumberValue } from './change_number_value';

export const answerSetRulesEngine = (rules, pagesFlow, setPagesFlow, currentElements, rulesSetAnswerValues, setRulesSetAnswerValues) => {
  const { setAnswer } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['setAnswer'] });

  const newConditionsValues = setAnswer.map(el => ({ condition: el.setAnswer.condition.id, value: Number(el.value.amount) }));
  const oldConditionsValues = rulesSetAnswerValues.filter(valueObj => newConditionsValues.map(obj => obj.condition).includes(valueObj.condition));
  const isEqualRulesValues =
    isLength(oldConditionsValues) && oldConditionsValues.every((valueObj, index) => valueObj.value === newConditionsValues[index]?.value);

  if (isLength(setAnswer) && !isEqualRulesValues) {
    setPagesFlow(prev => {
      const rulesAnswers = updateAnswerAmount(setAnswer, pagesFlow.allPages);
      const updatedAllPages = updatePagesRulesInputAnswers(prev.allPages, rulesAnswers);

      return {
        ...prev,
        allPages: updatedAllPages,
      };
    });
    setRulesSetAnswerValues(prev => {
      const filteredOldValues = prev.filter(obj => !newConditionsValues.map(newObj => newObj.condition).includes(obj.condition));
      return [...filteredOldValues, ...newConditionsValues];
    });
  }
};

const updateAnswerAmount = (answerResultsBlocks, pages) => {
  const allQuestions = pages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));

  return answerResultsBlocks.reduce((accumulator, block) => {
    const { conditionValue, setAnswer, resultOperator, value } = block;
    if (conditionValue) {
      const updatedAnswers = setAnswer.elements
        .map(answ => {
          const originalQuestion = allQuestions.find(question => question.id === answ.questionId);
          const originalPage = pages.find(page => page.elements.map(el => el.id).includes(answ.questionId));
          const originalAnswer =
            originalQuestion?.content?.counterAnswers?.find(answItem => answItem.id === answ.answerId) ||
            originalQuestion?.content?.chartAnswers?.find(answItem => answItem.id === answ.answerId);
          const originalAmount = originalAnswer?.originalAmount ?? originalAnswer?.amount;
          const calculateRuleAnswerAmount = changeNumberValue(value, resultOperator, {}, { value: originalAmount });
          const ruleAnswerAmount = calculateRuleAnswerAmount >= 0 ? calculateRuleAnswerAmount : 0;
          const updatedAnswer = {
            ...originalAnswer,
            amount: ruleAnswerAmount,
            pageId: originalPage.id,
            questionId: originalQuestion?.id,
            originalAmount,
          };

          return ruleAnswerAmount === originalAmount ? null : updatedAnswer;
        })
        .filter(Boolean);

      const filteredOldAnswers = accumulator.filter(el => !updatedAnswers.map(answ => answ.id).includes(el.id));

      return [...filteredOldAnswers, ...updatedAnswers];
    }

    return accumulator;
  }, []);
};
