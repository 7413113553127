import { AppPopup } from '../../../../../../../../../../../ui/AppPopup';
import './CellPopup.sass';
import { CellPopupContent } from '../CellPopupContent';

export function CellPopup({
  children,
  fieldLabels,
  productFieldList,
  iconList,
  productList,
  rowColorList,
  textColorList,
  pictureList,
  rowIndex,
  columnIndex,
}) {
  return (
    <AppPopup
      content={
        <CellPopupContent
          productList={productList}
          rowColorList={rowColorList}
          pictureList={pictureList}
          textColorList={textColorList}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          fieldLabels={fieldLabels}
          productFieldList={productFieldList}
          iconList={iconList}
        />
      }
      overlayClassName='CellPopup'
      trigger='click'
    >
      {children}
    </AppPopup>
  );
}
