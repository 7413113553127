/* eslint-disable unicorn/consistent-destructuring, no-magic-numbers,react-hooks/exhaustive-deps, unicorn/no-unused-properties, max-lines-per-function, new-cap, unicorn/consistent-function-scoping */
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import { PreviewProductCardPresentationalCenterMode } from '../../PreviewProductCard/PreviewProductCardPresentationalCenterMode';
import { CarouselButtons } from './CarouselButtons';
import { ProductListMultipleCarouselCenterMode } from '../../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ProductLayouts/multiple-counters/components/ProductListMultipleCarouselCenterMode'; // <-- Eslint: 'Dependency cycle detected.eslint(import/no-cycle)'

function CenterModeCarousel({ genericProps, cardProps, layoutDesign, preview = true }) {
  const { colors, products } = genericProps;
  const [activeSlide, setActiveSlide] = useState(0);
  const [centerSliderData, setCenterSliderData] = useState([]);
  const ref = React.useRef();
  const carouselSettingObject = {
    largeDesktopView: 1440,
    smallDesktopView: 1080,
    largeMobileView: 800,
    paddingSpace: 40,
    slideWidthInMobileView: 750,
    numberOfSlideInLargeScreen: 5,
  };

  useEffect(() => {
    setCenterSliderData(
      products?.map(item => {
        return { ...item, extraData: { key: item.id, layoutDesign, ...cardProps, ...genericProps, activeSlide } };
      })
    );
  }, [products]);

  function CalcMaxVisibleSlide(listLength) {
    const result = listLength >= 1 && listLength < 3 ? 1 : listLength >= 3 && listLength < 5 ? 3 : 5;
    return Math.abs(result);
  }

  const carouselMaxVisibleSlide1 = (
    <ResponsiveContainer
      carouselRef={ref}
      render={(parentWidth, carouselRef) => {
        return (
          <StackedCarousel
            ref={carouselRef}
            slideComponent={preview ? PreviewProductCardPresentationalCenterMode : ProductListMultipleCarouselCenterMode}
            slideWidth={
              parentWidth < carouselSettingObject.largeMobileView
                ? parentWidth - carouselSettingObject.paddingSpace
                : carouselSettingObject.slideWidthInMobileView
            }
            carouselWidth={parentWidth}
            data={centerSliderData}
            fadeDistance={0.2}
            maxVisibleSlide={1}
            onActiveSlideChange={eve => {
              setActiveSlide(eve);
            }}
            useGrabCursor
            disableSwipe
          />
        );
      }}
    />
  );

  const carouselMaxVisibleSlide3 = (
    <ResponsiveContainer
      carouselRef={ref}
      render={(parentWidth, carouselRef) => {
        return (
          <StackedCarousel
            ref={carouselRef}
            slideComponent={preview ? PreviewProductCardPresentationalCenterMode : ProductListMultipleCarouselCenterMode}
            slideWidth={
              parentWidth < carouselSettingObject.largeMobileView
                ? parentWidth - carouselSettingObject.paddingSpace
                : carouselSettingObject.slideWidthInMobileView
            }
            carouselWidth={parentWidth}
            data={centerSliderData}
            fadeDistance={0.2}
            maxVisibleSlide={3}
            onActiveSlideChange={eve => {
              setActiveSlide(eve);
            }}
            useGrabCursor
            disableSwipe
          />
        );
      }}
    />
  );

  const carouselMaxVisibleSlide5 = (
    <ResponsiveContainer
      carouselRef={ref}
      render={(parentWidth, carouselRef) => {
        return (
          <StackedCarousel
            ref={carouselRef}
            slideComponent={preview ? PreviewProductCardPresentationalCenterMode : ProductListMultipleCarouselCenterMode}
            slideWidth={
              parentWidth < carouselSettingObject.largeMobileView
                ? parentWidth - carouselSettingObject.paddingSpace
                : carouselSettingObject.slideWidthInMobileView
            }
            carouselWidth={parentWidth}
            data={centerSliderData}
            fadeDistance={0.2}
            maxVisibleSlide={5}
            onActiveSlideChange={eve => {
              setActiveSlide(eve);
            }}
            useGrabCursor
            disableSwipe
          />
        );
      }}
    />
  );

  return (
    <>
      {centerSliderData?.length && (
        <div
          style={{
            width: '97%',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: genericProps.windowWidth <= carouselSettingObject.largeMobileView ? '0' : '15%',
          }}
        >
          {CalcMaxVisibleSlide(centerSliderData?.length) >= 1 && CalcMaxVisibleSlide(centerSliderData?.length) < 3 && carouselMaxVisibleSlide1}
          {CalcMaxVisibleSlide(centerSliderData?.length) >= 3 && CalcMaxVisibleSlide(centerSliderData?.length) < 5 && carouselMaxVisibleSlide3}
          {CalcMaxVisibleSlide(centerSliderData?.length) >= 5 && carouselMaxVisibleSlide5}
        </div>
      )}

      {preview && (
        <CarouselButtons
          onLeftClick={() => {
            ref.current?.goBack();
          }}
          onRightClick={() => {
            ref.current?.goNext();
          }}
          colors={colors}
        />
      )}
    </>
  );
}

export { CenterModeCarousel };
