import breakpoints from '../styles/breakpoints.scss';
import { appParseInt } from './helpers';

export const AppBreakpoint = {
  PHONE: appParseInt(breakpoints.phone),
  TABLET_PORTRAIT: appParseInt(breakpoints.tabletPortrait),
  TABLET_LANDSCAPE: appParseInt(breakpoints.tabletLandscape),
  DESKTOP: appParseInt(breakpoints.desktop),
  M: 555,
};

export const isDesktop = containerWidth => containerWidth > AppBreakpoint.DESKTOP;

export const checkIsMobile = containerWidth => containerWidth < AppBreakpoint.PHONE;
