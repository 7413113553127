import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../utils/hooks/arranges-by-type/useStringArrange';
import { ArrangeListPopup } from '../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../components/TableHeaderCell';
import { getProductsFromApi } from '../../../../../../../../lib/api';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../lib/consts';
import { useProductArrangeRequests } from '../../lib/useProductArrangeRequests';
import { useTenantIdFromUri } from '../../../../../../lib/hooks/useTenantIdFromUri';

export function ProductSearchDataHeaderCell({
  arrangedId,
  title,
  searchFieldName,
  filterFieldName,
  headerCellProps: additionalHeaderCellProps,
  minSearchLength,
}) {
  const { activeArrangedId, sorting } = useSelector(({ products }) => products);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');
  const tenantId = useTenantIdFromUri();

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredProductsFromApi(searchFieldName, value, tenantId), // REFACTOR: use the func from api.js
    formatResponse: response => formatArrangedData(response, arrangedId),
    minSearchLength,
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useProductArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
        minSearchLength={minSearchLength}
      />
    </TableHeaderCell>
  );
}

const getFilteredProductsFromApi = (searchFieldName, searchValue, tenantId) =>
  getProductsFromApi(tenantId, {
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: { [searchFieldName]: searchValue },
  });
