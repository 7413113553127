import { CardSection } from '../../../../../../../ui/CardSection';
import { TextFieldWithInfoIcon } from '../../../../../../../features/TextFieldWithInfoIcon';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductTopSectionCounter } from './ProductTopSectionCounter';
import { ProductTopSectionHeader } from './ProductTopSectionHeader';

export function ProductTopSectionWithCounter({ structureItem, structureItemIndex }) {
  const { templateHeadline } = useLocalization().translations.unique.formItems;

  return (
    <CardSection withBorderBottom={false}>
      <ProductTopSectionHeader structureItem={structureItem} structureItemIndex={structureItemIndex} />
      <TextFieldWithInfoIcon
        label={templateHeadline.label}
        data={structureItem.content.headline}
        dataPath={`[${structureItemIndex}].content.headline`}
      />
      <ProductTopSectionCounter structureItem={structureItem} structureItemIndex={structureItemIndex} />
    </CardSection>
  );
}
