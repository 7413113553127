import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ImageSelect } from '../../../../../../features/ImageSelect';
import { ConnectionLineLong } from '../../../../../../../../../../ui/icons/ConnectionLineLong';
import { InfoIconTextarea } from '../../../../../../features/form-items/InfoIconTextarea';

export function DroppableImageWrapper({
  itemId,
  labelField,
  labelTemplateInfo,
  type,
  valueObject,
  onChangeField,
  onChangeFieldInfoIconValue,
  onDeleteInfoIcon,
  fieldName,
  infoIcon,
  media,
  contentBlocks,
  contentBlockIndex,
}) {
  return (
    <Droppable droppableId={`${fieldName}_${itemId}_${contentBlocks}_${contentBlockIndex}`} type={type}>
      {(provided, snapshot) => (
        <ImageWrapper ref={provided.innerRef}>
          <ImageSelect valueObject={valueObject} label={labelField} mediaOptions={media} onChange={onChangeField(fieldName)} />
          {(infoIcon || snapshot.isDraggingOver) && (
            <InfoIconTextarea
              valueObject={infoIcon || { value: '' }}
              label={labelTemplateInfo}
              onChange={onChangeFieldInfoIconValue(fieldName)}
              onClickSuffix={() => onDeleteInfoIcon(fieldName)}
            />
          )}
          {(infoIcon || snapshot.isDraggingOver) && (
            <InfoIconLine>
              <ConnectionLineLong stroke={infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
            </InfoIconLine>
          )}
        </ImageWrapper>
      )}
    </Droppable>
  );
}

const ImageWrapper = styled('div')({
  position: 'relative',
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 56,
  right: -9,
});
