import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PreviewMainHeader } from '../../../../modules/PreviewMainHeader';
import { DesktopIcon } from '../../../../../../ui/icons/DesktopIcon';
import { LaptopIcon } from '../../../../../../ui/icons/LaptopIcon';
import { TabletIcon } from '../../../../../../ui/icons/TabletIcon';
import { MobileIcon } from '../../../../../../ui/icons/MobileIcon';

import styles from './styles.module.scss';
import { HtmlNodeId } from '../../../../../../lib/util/HtmlNodeId';

const SCREEN_RESOLUTIONS = [
  {
    name: 'Desktop',
    icon: <DesktopIcon />,
    width: 1920,
  },
  {
    name: 'Laptop',
    icon: <LaptopIcon />,
    width: 1366,
  },
  {
    name: 'Tablet',
    icon: <TabletIcon />,
    width: 850,
  },
  {
    name: 'Mobile',
    icon: <MobileIcon />,
    width: 400,
  },
];

const borderRadius = 8;

export function WithPreviewHeader() {
  const [currentResolution, setCurrentResolution] = useState(SCREEN_RESOLUTIONS[0]);

  const onSelectResolution = item => {
    setCurrentResolution(item);
  };

  return (
    <>
      <PreviewMainHeader currentResolution={currentResolution} onSelectResolution={onSelectResolution} SCREEN_RESOLUTIONS={SCREEN_RESOLUTIONS} />

      <div style={{ padding: currentResolution.name !== 'Desktop' ? '40px 0' : 0 }} className={styles.container}>
        <div
          id={HtmlNodeId.PREVIEW_CONTAINER}
          className={styles.pageWrapper}
          style={{
            width: currentResolution.width,
            borderRadius: currentResolution.name === 'Desktop' ? 0 : borderRadius,
          }}
        >
          <Outlet context={{ currentResolution }} />
        </div>
      </div>
    </>
  );
}
