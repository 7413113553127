import { ProductSearchDataHeaderCell } from './ProductSearchDataHeaderCell';

export function useProductSearchDataColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <ProductSearchDataHeaderCell {...rest} />,
  };
}
