import { AppButton } from '../../../../../ui/buttons/AppButton';
import { PlusIcon } from '../../../../../ui/icons/PlusIcon';
import './table.scss';

export function AddTableItemButton({ text, onClick, styles = '', ...rest }) {
  return (
    <AppButton
      className={`table_btn ${styles}`}
      textValue={text}
      onClick={onClick}
      type='primary'
      icon={<PlusIcon className='inner_btn_icon' fill='#FFFFFF' />}
      {...rest}
    />
  );
}
