/* eslint-disable no-magic-numbers */
import { FK_Q3_A1_ID, FK_Q3_A2_ID, PK_Q1_A1_ID, PK_Q1_A2_ID, PK_Q3_A3_ID } from './constants';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';

// eslint-disable-next-line max-lines-per-function
export function getVars({ pagesFlow }) {
  const allProducts = pagesFlow.allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT));
  const P1 = allProducts[0];
  const P2 = allProducts[1];
  const P3 = allProducts[2];
  const P4 = allProducts[3];

  const allQuestions = pagesFlow.allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));
  const Q1 = allQuestions[0];
  const Q3 = allQuestions[2];

  const FK_LINK_1_VAR_1 =
    P1?.content?.products[0]?.selected &&
    !P1?.content?.products[1]?.selected &&
    !P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A1_ID);
  const FK_LINK_1_VAR_2 =
    P1?.content?.products[0]?.selected &&
    !P1?.content?.products[1]?.selected &&
    !P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
    P2?.content?.products[0]?.selected;
  const FK_LINK_3_VAR_1 =
    P1?.content?.products[0]?.selected &&
    P1?.content?.products[1]?.selected &&
    !P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A1_ID);
  const FK_LINK_3_VAR_2 =
    P1?.content?.products[0]?.selected &&
    P1?.content?.products[1]?.selected &&
    !P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
    P2?.content?.products[0]?.selected;
  const FK_LINK_5_VAR_1 =
    P1?.content?.products[0]?.selected &&
    !P1?.content?.products[1]?.selected &&
    P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A1_ID);
  const FK_LINK_5_VAR_2 =
    P1?.content?.products[0]?.selected &&
    !P1?.content?.products[1]?.selected &&
    P1?.content?.products[2]?.selected &&
    Q3?.selectedAnswers?.includes(FK_Q3_A2_ID) &&
    P2?.content?.products[0]?.selected;

  const PK_LINK_1_VAR_1 = Q1?.selectedAnswers?.includes(PK_Q1_A1_ID) && P2?.content?.products[0]?.selected && P4?.content?.products[0]?.selected;
  const PK_LINK_1_VAR_2 =
    Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
    P1?.content?.products[0]?.selected &&
    P2?.content?.products[0]?.selected &&
    P4?.content?.products[0]?.selected;
  const PK_LINK_2_VAR_1 =
    Q1?.selectedAnswers?.includes(PK_Q1_A1_ID) &&
    P2?.content?.products[0]?.selected &&
    P4?.content?.products[0]?.selected &&
    Q3?.selectedAnswers?.includes(PK_Q3_A3_ID);
  const PK_LINK_2_VAR_2 =
    Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
    P1?.content?.products[0]?.selected &&
    P2?.content?.products[0]?.selected &&
    P4?.content?.products[0]?.selected &&
    Q3?.selectedAnswers?.includes(PK_Q3_A3_ID);
  const PK_LINK_5_VAR_1 = Q1?.selectedAnswers?.includes(PK_Q1_A1_ID) && P2?.content?.products[0]?.selected && P4?.content?.products[1]?.selected;
  const PK_LINK_5_VAR_2 =
    Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
    P1?.content?.products[0]?.selected &&
    P2?.content?.products[0]?.selected &&
    P4?.content?.products[1]?.selected;
  const PK_LINK_7_VAR_1 = Q1?.selectedAnswers?.includes(PK_Q1_A1_ID) && P2?.content?.products[1]?.selected && P4?.content?.products[1]?.selected;
  const PK_LINK_7_VAR_2 =
    Q1?.selectedAnswers?.includes(PK_Q1_A2_ID) &&
    P1?.content?.products[0]?.selected &&
    P2?.content?.products[1]?.selected &&
    P4?.content?.products[1]?.selected;

  return {
    FK_LINK_1_VAR_1,
    FK_LINK_1_VAR_2,
    FK_LINK_3_VAR_1,
    FK_LINK_3_VAR_2,
    FK_LINK_5_VAR_1,
    FK_LINK_5_VAR_2,
    PK_LINK_1_VAR_1,
    PK_LINK_1_VAR_2,
    PK_LINK_2_VAR_1,
    PK_LINK_2_VAR_2,
    PK_LINK_5_VAR_1,
    PK_LINK_5_VAR_2,
    PK_LINK_7_VAR_1,
    PK_LINK_7_VAR_2,
    P1,
    P2,
    P3,
    P4,
    Q1,
    Q3,
  };
}
