/* eslint-disable no-console */
const consoleError = console.error.bind(console);

export function consoleErrorHandler() {
  console.error = (errObj, ...args) => {
    if (typeof errObj === 'string' && args.includes('findDOMNode')) {
      return;
    }
    consoleError(errObj, ...args);
  };
}
