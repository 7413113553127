import styled from 'styled-components';
import { RightArrow } from '../../../../../../../ui/icons/RightArrow';
import { LeftArrow } from '../../../../../../../ui/icons/LeftArrow';
import { AppIconButton } from '../../../../../../../ui/AppIconButton';

export function ToggleExpandButton({ areColumnsExpanded, ...rest }) {
  return (
    <Root {...rest} $areColumnsExpanded={areColumnsExpanded}>
      {areColumnsExpanded ? <LeftArrow /> : <RightArrow />}
    </Root>
  );
}

const Root = styled(AppIconButton)(({ $areColumnsExpanded }) => ({
  height: '100%',
  width: '24px',
  display: 'flex',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  background: '#cce1e9',
  borderRadius: $areColumnsExpanded ? 'unset' : '0 8px 8px 0',
  svg: {
    width: '100%',
  },
}));
