import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import { ProductScoringRow } from '../../components/ProductScoringRow';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import './style.scss';

const TWO = 2;

/**
 * @desc Renders the scoring box component.
 * @param {Object} scoreObj - The score object containing scoring information.
 * @param {Object} selectedQuestion - The selected question.
 * @param {Number} index - The index of the score object.
 * @returns {JSX.Element} The rendered scoring box component.
 */
export function ScoringBox({ scoreObj, selectedQuestion, index }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const { highestEconomy, secondEconomy, thirdEconomy, nEconomy } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  const updateScoring = (scoreArr, value) => {
    return scoreArr.map(score => {
      return score.id === scoreObj.id ? { ...score, value } : score;
    });
  };

  const prodNameText = ind => {
    if (ind === 0) {
      return highestEconomy();
    }
    if (ind === 1) {
      return secondEconomy();
    }
    if (ind === TWO) {
      return thirdEconomy();
    }
    return nEconomy({ index: ind + 1 });
  };

  const scoreHandleChange = value => {
    if (Number.isInteger(Number(value))) {
      const result = questionnaireStructure.map(el => {
        return el.id === selectedQuestion.elementId
          ? { ...el, content: { ...el.content, scoring: updateScoring(el.content.scoring, Number(value)) } }
          : el;
      });
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  return <ProductScoringRow productName={prodNameText(index)} value={scoreObj.value} onChange={scoreHandleChange} disabled={isViewMode} />;
}
