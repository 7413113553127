import { TableColumn } from '../../../../../models/Table';
import { TableHeaderCell } from '../../TableHeaderCell';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';

export function useActionsColumn(props) {
  const { actions: title } = useLocalization().translations.shared.columns;

  return {
    title: <TableHeaderCell withArrange={false} title={title} />,
    dataIndex: TableColumn.ACTIONS,
    width: 110,
    ...props,
  };
}
