/* eslint-disable no-param-reassign */
import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { CardSectionHeader } from '../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';

export function PriceSectionHeader({ structureItemIndex, productIndex, data }) {
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;
  const { updateStructure } = useUpdateStructure();

  const toggleShowTotalPrice = () => {
    updateStructure(draft => {
      const prevValue = draft[structureItemIndex].content.products[productIndex].priceSection.showTotalPrice;
      draft[structureItemIndex].content.products[productIndex].priceSection.showTotalPrice = !prevValue;
    });
  };

  return (
    <CardSectionHeader>
      <QuestionnaireSwitch
        labelProps={{
          strong: true,
          textValue: contentText.showTotalPrice,
        }}
        isOn={data.showTotalPrice}
        onChange={toggleShowTotalPrice}
      />
    </CardSectionHeader>
  );
}
