import { CAROUSEL_MAIN_SETTINGS, getCenterPadding } from '../utils';

export function useGetCarouselSettings({ products, windowWidth, preSelectedCardIndex, sliderDraggable }) {
  return {
    ...CAROUSEL_MAIN_SETTINGS,
    draggable: sliderDraggable,
    centerPadding: getCenterPadding(products, windowWidth),
    initialSlide: preSelectedCardIndex,
  };
}
