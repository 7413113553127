import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { useFinderArrangeRequests } from '../../lib/useFinderArrangeRequests';
import { Finder, FinderEditMode } from '../../lib/Finder';
import {
  clearListFilters,
  createOptionItem,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../../../../utils/arranges-helpers';
import { useEnumArrange } from '../../../../../../../../utils/hooks/arranges-by-type/useEnumArrange';
import { handleTranslation } from '../../../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { ArrangeListPopup } from '../../../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../../../components/TableHeaderCell';
import { useArrangeSubmit } from '../../../../../../../../utils/hooks/useArrangeSubmit';

const arrangedId = Finder.EDITING;
const pseudoAllFilters = {};

export function FinderEditModeHeaderCell() {
  const { table } = useLocalization().translations.unique.pages.finders;
  const { editMode } = table.filters;
  const { editMode: title } = table.columns;
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const [form] = Form.useForm();
  const isActiveArrange = activeArrangedId === arrangedId;
  const getItemsValues = () => getSelectedItemsValues(form);
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const editingLabel = handleTranslation(editMode.editing);
  const blankLabel = handleTranslation(editMode.blank);

  const allOptions = [createOptionItem(editingLabel, FinderEditMode.EDITING), createOptionItem(blankLabel, FinderEditMode.BLANK)];

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const areAllFiltersSelected = allOptions.length === itemsValues.length;
    return areAllFiltersSelected ? pseudoAllFilters : { editing: itemsValues?.[0] };
  };

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearListFilters(form),
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    filterLabel,
    removeFilter,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
