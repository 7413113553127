export function ActionsIcon({ isOpen }) {
  const fill = isOpen ? '#DA1B5E' : '#5C5C6E';
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.082 13C10.082 13.3978 9.924 13.7794 9.64269 14.0607C9.36139 14.342 8.97986 14.5 8.58203 14.5C8.18421 14.5 7.80268 14.342 7.52137 14.0607C7.24007 13.7794 7.08203 13.3978 7.08203 13C7.08203 12.6022 7.24007 12.2206 7.52137 11.9393C7.80268 11.658 8.18421 11.5 8.58203 11.5C8.97986 11.5 9.36139 11.658 9.64269 11.9393C9.924 12.2206 10.082 12.6022 10.082 13ZM10.082 8C10.082 8.39782 9.924 8.77936 9.64269 9.06066C9.36139 9.34196 8.97986 9.5 8.58203 9.5C8.18421 9.5 7.80268 9.34196 7.52137 9.06066C7.24007 8.77936 7.08203 8.39782 7.08203 8C7.08203 7.60218 7.24007 7.22064 7.52137 6.93934C7.80268 6.65804 8.18421 6.5 8.58203 6.5C8.97986 6.5 9.36139 6.65804 9.64269 6.93934C9.924 7.22064 10.082 7.60218 10.082 8ZM10.082 3C10.082 3.39782 9.924 3.77936 9.64269 4.06066C9.36139 4.34196 8.97986 4.5 8.58203 4.5C8.18421 4.5 7.80268 4.34196 7.52137 4.06066C7.24007 3.77936 7.08203 3.39782 7.08203 3C7.08203 2.60218 7.24007 2.22064 7.52137 1.93934C7.80268 1.65804 8.18421 1.5 8.58203 1.5C8.97986 1.5 9.36139 1.65804 9.64269 1.93934C9.924 2.22064 10.082 2.60218 10.082 3Z'
        fill={fill}
      />
    </svg>
  );
}
