import { AppInput } from 'src/ui/AppInput/';
import { AppSwitch } from 'src/ui/AppSwitch';
import { AppButton } from 'src/ui/buttons/AppButton';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { useCondition } from '../hooks/useCondition';

import styles from '../BuilderPage.module.scss';

/**
 * @desc Condition Header
 * @param {Object} condition
 * @param {Number} index
 * @returns {JSX.Element}
 */
export function ConditionHeader({ condition, index }) {
  const { updateCondition, duplicateCondition, deleteCondition } = useCondition();
  const { translations } = useLocalization();
  const builderTranslations = translations.unique.pages.builder;

  const updateConditionName = value => {
    updateCondition(condition.id, 'name', value);
  };

  const toggleConditionActivity = value => {
    updateCondition(condition.id, 'isActive', value);
  };

  return (
    <div className={styles.conditionHeader}>
      <div className={styles.generalInfo}>
        <span className={styles.title}>{index + 1}</span>
        <div className={styles.conditionName}>
          <AppInput hasError={!condition.name} value={condition.name} onChange={event => updateConditionName(event.target.value)} />
        </div>
        <AppSwitch
          switchProps={{ onChange: toggleConditionActivity, checked: condition.isActive }}
          labelProps={{ textValue: builderTranslations.activeCondition }}
        />
      </div>
      <div className={styles.buttonRow}>
        <AppButton
          onClick={() => {
            duplicateCondition(condition.id);
          }}
          className={styles.button}
          textValue={translations.unique.components.actions.duplicate}
        />
        <AppButton
          onClick={() => {
            deleteCondition(condition.id);
          }}
          className={styles.button}
          textValue={builderTranslations.deleteCondition}
        />
      </div>
    </div>
  );
}
