import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  variables: [],
  loading: false,
  error: null,
  selectedVariable: {},
};

const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    addVariableRequest: state => {
      state.error = null;
      state.loading = true;
    },
    addVariableSuccess: state => {
      state.error = false;
      state.loading = false;
    },
    addVariableError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    editVariableRequest: state => {
      state.error = null;
      state.loading = true;
    },
    editVariableSuccess: state => {
      state.loading = false;
    },
    editVariableError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getVariablesRequest: state => {
      state.error = null;
      state.loading = true;
    },
    getVariablesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getVariablesSuccess: (state, { payload }) => {
      state.variables = payload;
      state.loading = false;
    },
    selectVariable: (state, { payload }) => {
      state.selectedVariable = payload;
    },
    updateSelectedVariable: (state, { payload }) => {
      state.selectedVariable = payload;
    },
    deleteVariableRequest: state => {
      state.error = null;
      state.loading = true;
    },
    deleteVariableSuccess: state => {
      state.error = false;
      state.loading = false;
    },
    deleteVariableError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  addVariableRequest,
  addVariableSuccess,
  addVariableError,
  editVariableRequest,
  editVariableSuccess,
  editVariableError,
  getVariablesRequest,
  getVariablesSuccess,
  getVariablesError,
  selectVariable,
  updateSelectedVariable,
  deleteVariableRequest,
  deleteVariableSuccess,
  deleteVariableError,
} = variablesSlice.actions;
export const variables = variablesSlice.reducer;
