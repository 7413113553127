import { Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { useBeforeUploadError } from '../utils/hooks/useBeforeUploadError';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { createFormData } from '../../../../../lib/util/helpers';

const MAX_JSON_FILE_SIZE = 1;

export function FileLoader({ uploadLabel, uploadAction, accept = '.json', disabled = false, item = {} }) {
  const dispatch = useDispatch();
  const { beforeUploadJsonError } = useLocalization().translations.unique.pages.templates;

  const { beforeUpload, contextHolder } = useBeforeUploadError(MAX_JSON_FILE_SIZE, beforeUploadJsonError());

  const customUploadRequest = ({ file }) => {
    const data = item.id ? { file, id: item.id } : { file };
    const formData = createFormData(data);
    dispatch(uploadAction(formData));
  };

  return (
    <>
      {contextHolder}
      <Upload
        accept={accept}
        beforeUpload={beforeUpload}
        customRequest={options => customUploadRequest(options)}
        showUploadList={false}
        disabled={disabled}
      >
        {uploadLabel}
      </Upload>
    </>
  );
}
