import { FormCard } from '../../../../../features/FormCard';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Form, Space, Switch } from 'antd';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';

// Todo temporary
const hideTrackingFields = { visibility: 'hidden', opacity: 0, height: 0 };

export function TrackingCard({ form }) {
  const { general } = useLocalization().translations.unique.pages.settings;

  const { trackingSoftware, trackerUrl, trackerSiteId } = useLocalization().translations.unique.formItems;

  const toggleDataProtection = checked => {
    form.setFieldValue(FormItemName.TRACKING, checked);
  };

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className='bold' textValue={general.tracking} />
      </AppBox>
      <Form.Item name={FormItemName.TRACKING} noStyle>
        <Space size='small' style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
          <Switch checked={Form.useWatch(FormItemName.TRACKING, form)} onChange={toggleDataProtection} />
          <AppText textValue={general.trackingDescr} />
        </Space>
      </Form.Item>
      <div style={hideTrackingFields}>
        <AppFormSection formItemProps={{ name: FormItemName.TRACKING_SOFTWARE }} textFieldProps={{ label: trackingSoftware.label }} />
        <Space size='middle' className='equal_space'>
          <AppFormSection formItemProps={{ name: FormItemName.TRACKER_URL }} textFieldProps={{ label: trackerUrl.label }} />
          <AppFormSection formItemProps={{ name: FormItemName.TRACKING_TRACKER_SIDE_ID }} textFieldProps={{ label: trackerSiteId.label }} />
        </Space>
      </div>
    </FormCard>
  );
}
