import { getEnv, secToMs } from './helpers';
import encodeJwt from 'jwt-encode';
import { v4 as uuidv4 } from 'uuid';

const LIFE_TIME = secToMs({ sec: 60 });

export function createJwtForPublicApi() {
  const data = {
    exp: Date.now() + LIFE_TIME,
    id: uuidv4(),
  };

  return encodeJwt(data, getEnv('REACT_APP_GLOBAL_SECRET_PHRASE'));
}
