import { useEffect, useState } from 'react';
import { AppLocalStorage, handleLocalStorageValue } from '../util/helpers';

const isValue = value => ![null, undefined].includes(value);

function getValue(key, initialValue) {
  const storedValue = AppLocalStorage.getItem({ key });
  return isValue(storedValue) ? storedValue : initialValue;
}

export function useLocalStorageItem(key, initialValue) {
  const [value, setValue] = useState(() => getValue(key, initialValue));

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === key) {
        setValue(isValue(event.newValue) ? handleLocalStorageValue({ value: event.newValue }) : initialValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  const setLocalStorageItem = value => {
    setValue(value);
    AppLocalStorage.setItem(key, value);
  };

  return [value, setLocalStorageItem];
}
