import styled from 'styled-components';
import { AppBasicButton } from '../../../ui/buttons/AppBasicButton';

const Root = styled(AppBasicButton)(({ disabled, danger }) => ({
  appearance: 'button',
  padding: '8px 16px',
  lineHeight: '19px',
  background: '#FFFFFF',
  border: 'none',
  letterSpacing: '-0.015em',
  textAlign: 'left',
  ...(disabled ? { color: '#858899', cursor: 'not-allowed' } : {}),
  ...(danger ? { color: '#da1b5e' } : {}),
}));

export function DropdownMenuButton(props) {
  return <Root {...props} />;
}
