import styled from 'styled-components';
import { AppBox } from '../../../../../../../../../../../../../ui/boxes/AppBox';
import { ProductsIcon } from '../../../../../../../../../../../../../ui/icons/ProductsIcon';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { ProductSelect } from './ProductSelect';
import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { QuestionnaireBasicButton } from '../../../../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../../../ui/icons/DeleteIcon';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { TextFieldWithInfoIcon } from '../../../../../../../features/TextFieldWithInfoIcon';

const DELETE_PRODUCTS_AMOUNT = 1;

export function ProductNameSection({ allProducts, availableProducts, productIndex, structureItemIndex, data }) {
  const { updateStructure } = useUpdateStructure();

  const { showPicture: showPictureText, productName } = useLocalization().translations.unique.pages.questionnaire.content;
  const { templateSubline } = useLocalization().translations.unique.formItems;
  const toggleShowPicture = () => {
    updateStructure(draft => {
      const { productNameSection } = draft[structureItemIndex].content.products[productIndex];
      productNameSection.showPicture = !productNameSection.showPicture;
    });
  };

  const deleteProduct = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products.splice(productIndex, DELETE_PRODUCTS_AMOUNT);
    });
  };

  return (
    <Root>
      <AppBox $flexY style={{ gap: '8px', marginBottom: 22 }}>
        <ProductsIcon fill='#5C5C6E' />
        <AppText strong textValue={productName} />
      </AppBox>
      <StyledMain>
        <StyledSelectWrapper>
          <ProductSelect
            allProducts={allProducts}
            availableProducts={availableProducts}
            structureItemIndex={structureItemIndex}
            productIndex={productIndex}
            value={data.product}
          />
        </StyledSelectWrapper>
        <AppBox $flexY style={{ gap: '15px', width: 250, display: 'flex', justifyContent: 'flex-end' }}>
          <QuestionnaireSwitch labelProps={{ textValue: showPictureText }} isOn={data.showPicture} onChange={toggleShowPicture} />
          <QuestionnaireBasicButton onClick={deleteProduct}>
            <DeleteIcon />
          </QuestionnaireBasicButton>
        </AppBox>
      </StyledMain>
      <TextFieldWithInfoIcon
        label={templateSubline.label}
        data={data.subline}
        dataPath={`[${structureItemIndex}].content.products[${productIndex}].productNameSection.subline`}
      />
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 24px 0 24px',
  borderBottom: '1px solid #ECECEE',
});

const StyledMain = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 16,
});
const StyledSelectWrapper = styled('div')({
  flex: '1 1 100%',
  marginRight: 24,
});
