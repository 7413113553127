import { AppText } from '../../../../../../../ui/text/AppText';
import { QuestionnaireBasicButton } from './QuestionnaireBasicButton';

export function QuestionnaireTextButton({ textValue, ...rest }) {
  return (
    <QuestionnaireBasicButton {...rest}>
      <AppText strong className='primary_color' textValue={textValue} />
    </QuestionnaireBasicButton>
  );
}
