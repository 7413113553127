import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { FormItemName } from '../../../../../../../../lib/models/Form';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../lib/consts';
import { getFindersFromApi } from '../../../../../../../../lib/api';

/**
 * @desc Custom hook for managing the list of finders based on the selected tenant.
 * @param {object} form - Ant Design Form instance.
 * @returns {object} - An object containing the list of finders.
 */
export function useFindersList(form) {
  const [findersList, setFindersList] = useState([]);

  const selectedTenantId = Form.useWatch(FormItemName.TENANT, form)?.value;

  useEffect(() => {
    if (selectedTenantId) {
      setFindersList([]);
      getFindersFromApi(selectedTenantId, {
        params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
        data: {},
      })
        .then(res => {
          // eslint-disable-next-line promise/always-return
          if (Array.isArray(res.data?.content)) {
            setFindersList(res.data.content);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [selectedTenantId]);

  const findersNames = isLength(findersList) ? findersList.map(item => ({ value: item.id, title: item.name })) : [];

  return { findersNames };
}
