import { TitleSection } from './TitleSection';
import { AddRowColumnSection } from './AddRowColumnSection';
import { TextFieldWithInfoIcon } from '../../../../features/TextFieldWithInfoIcon';
import { useContentTable } from '../hooks/useContentTable';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function TopTableSection() {
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;
  const { contentTableState } = useContentTable();

  return (
    <div style={styles.root}>
      <TitleSection />
      <TextFieldWithInfoIcon data={contentTableState.tableName} dataPath={contentTableState.tableNamePath} label={contentTableText.tableNameLabel} />
      <AddRowColumnSection />
    </div>
  );
}

const styles = {
  root: {
    paddingLeft: '24px',
    marginBottom: '20px',
  },
};
