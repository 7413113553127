/* eslint-disable unicorn/no-unsafe-regex */
import { InputNumber } from 'antd';
import styled from 'styled-components';
import { pressOnlyRegEx } from '../../../../../../../../../../../../lib/util/helpers';
import styles from './CounterInput.module.scss';
import classNames from 'classnames';

/**
 * @desc Counter Input
 * @param {Object} answer
 * @param {Function} handleChange
 * @param {Boolean} showCurrencySymbol
 * @param {String} currencySymbol
 * @param {Object} textStyle
 * @param {String} className
 * @param {RegExp} regEx
 * @param {Boolean} disabled
 * @param {String} primaryColor
 * @returns {JSX.Element}
 */
export function CounterInput({
  answer,
  handleChange,
  showCurrencySymbol,
  currencySymbol,
  textStyle,
  className,
  regEx = /\d/,
  disabled,
  primaryColor,
}) {
  const counterInputClassList = classNames(
    styles.counterInputContainer,
    showCurrencySymbol ? styles.hasCurrency : '',
    currencySymbol?.length > 1 ? styles.longCurrency : ''
  );

  const primarycolor = primaryColor;

  return (
    <div className={counterInputClassList}>
      <div className={styles.currencyContainer}>{currencySymbol}</div>
      <StyledInputNumber
        primarycolor={primarycolor}
        className={className}
        style={textStyle}
        controls={false}
        value={answer.amount}
        onChange={handleChange}
        formatter={value => {
          const formattedValue = String(value).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
          return `${answer.maxValueText && answer.amount === answer.maxValue ? '> ' : ''}${formattedValue}`;
        }}
        parser={value => value.replace('> ', '').replace(` ${currencySymbol}`, '').replace(/\.*/g, '')}
        onKeyPress={event => pressOnlyRegEx(event, regEx)}
        min={answer.minValue}
        max={answer.maxValue}
        disabled={disabled}
      />
    </div>
  );
}

const StyledInputNumber = styled(InputNumber)(({ primarycolor }) => ({
  ':focus-within, :hover': {
    borderColor: `${primarycolor} !important`,
    boxShadow: `0 0 0 2px ${primarycolor}20`,
  },

  '.ant-input-number-input': {
    boxSizing: 'border-box',
    margin: 0,
    padding: '0 8px',
    color: 'inherit',
    fontSize: 'inherit',
    lineHeight: 1.2,
    listStyle: 'none',
    fontFamily: 'inherit',
    width: '100%',
    height: '38px',
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: '8px',
    outline: 0,
    transition: 'all 0.2s linear',
  },
}));
