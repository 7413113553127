/* eslint-disable max-lines,no-magic-numbers,max-lines-per-function */
import { useEffect } from 'react';
import { LayoutNumberInputSection } from '../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/features/LayoutNumberInputSection';
import { Space, Typography } from 'antd';
import {
  getCurrentPageNextElementId,
  getQuestionConditionsFlow,
  getQuestionCounterRangeFlow,
  rewriteHamburgPKHolzPages,
  updateElementsAfterAnswering,
  rewriteChapterSelectedProducts,
} from '../../../../utils/end-user-flow-helpers';
import { ButtonOrLinkComponent } from '../ButtonOrLinkComponent';
import { InfoIconComponent } from '../InfoIconComponent';
import { Chart } from './components/Chart';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { getNextElementsIdsOnPage, setNextElementsAndFlowOnPage } from '../../hooks/setNextFlow';
import { rulesEngine } from '../../../../utils/rules/rules_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import styles from './styles.module.scss';

const HAM_VB_PK_Q5_ID = '6351eaa2-4552-4466-a5dc-0b5f339ec771';
const HAM_VB_PK_Q7_ID = '47e4553e-d272-4800-a79f-bf5a2e5e3762';
const HAM_VB_PK_Q8_ID = 'b33f7265-2de3-4f0e-a6ff-bc859fd5f4e1';

const HAM_VB_PK_Q3_ID = '6956ada4-c5b2-4f07-835f-be6684f4ee0f';
const HAM_VB_PK_Q3_A3_ID = '27bc0001-d931-4f32-8e60-58c7b9b84d52';

export function PreviewQuestion({
  questionObject,
  colors,
  fonts,
  style,
  currentPageElements,
  pagesFlow,
  setPagesFlow,
  setPageHistory,
  currentElements,
}) {
  const { applyVariable } = useVariables(pagesFlow);
  const { rootStyle = {}, answersBlockStyle = {}, questionStyle = {}, questionSublineStyle = {} } = style || {};
  const {
    content: { answers, chartAnswers, counterAnswers, productValues = [], question, questionSubline, xAxis, yAxis, multipleChoice, composerButton },
    selectedAnswers = [],
    id: questionElementId,
    flow,
    rules,
  } = questionObject;

  const { allPages, allChapters, currentPageId, currentElementsIds, currentFlowElement, answersScoring } = pagesFlow;

  const allQuestions = allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));
  const Q3 = allQuestions[2];
  const Q5 = allQuestions[4];

  const allProducts = allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT));
  const P2 = allProducts[1];
  const P3 = allProducts[2];
  const P4 = allProducts[3];
  const P7 = allProducts[6];
  const P8 = allProducts[7];
  const P10 = allProducts[9];
  const P12 = allProducts[11];

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getQuestionCurrentFlow = answersSelected => {
    if (HAM_VB_PK_Q5_ID === questionElementId && Array.isArray(answers) && flow.elseGoTo) {
      if (
        P2 &&
        P2?.content?.products[0]?.selected &&
        !P2?.content?.products[1]?.selected &&
        Q5 &&
        P7 &&
        !P3?.content?.products[0]?.selected &&
        !P3?.content?.products[1]?.selected
      ) {
        return { goTo: P7?.id };
      }
      if (
        P2 &&
        !P2?.content?.products[0]?.selected &&
        P2?.content?.products[1]?.selected &&
        Q5 &&
        P8 &&
        !P3?.content?.products[0]?.selected &&
        !P3?.content?.products[1]?.selected
      ) {
        return { goTo: P8?.id };
      }
      if (P3 && P3?.content?.products[0]?.selected && !P3?.content?.products[1]?.selected && Q5 && P7) {
        return { goTo: P7?.id };
      }
      if (P3 && !P3?.content?.products[0]?.selected && P3?.content?.products[1]?.selected && Q5 && P8) {
        return { goTo: P8?.id };
      }
    }

    if (
      HAM_VB_PK_Q7_ID === questionElementId &&
      Array.isArray(answers) &&
      flow.elseGoTo &&
      Q3?.selectedAnswers?.includes(HAM_VB_PK_Q3_A3_ID) &&
      P4?.content?.products[0]?.selected
    ) {
      return { goTo: P10?.id };
    }

    if (
      HAM_VB_PK_Q8_ID === questionElementId &&
      Array.isArray(answers) &&
      flow.elseGoTo &&
      Q3?.selectedAnswers?.includes(HAM_VB_PK_Q3_A3_ID) &&
      P4?.content?.products[0]?.selected
    ) {
      return { goTo: P12?.id };
    }

    const updatedPages = allPages.map(page =>
      page.id === currentPageId
        ? { ...page, elements: page.elements.map(el => (el.id === questionElementId ? { ...el, selectedAnswers: answersSelected } : el)) }
        : page
    );

    const { goToFlow } = rulesEngine({ rules, pagesFlow: { ...pagesFlow, allPages: updatedPages }, currentElements, rulesFilter: ['goToFlow'] });

    if (goToFlow) {
      return goToFlow;
    }

    if (Array.isArray(answers) && flow.elseGoTo) {
      return getQuestionConditionsFlow(flow, multipleChoice, answersSelected);
    }
    if (Array.isArray(counterAnswers) && flow.counterAnswersElseGoTo) {
      return getQuestionCounterRangeFlow(flow, counterAnswers);
    }

    return flow;
  };

  useEffect(() => {
    const currentFlow = getQuestionCurrentFlow(selectedAnswers);
    const { nextElementIds } = getNextElementsIdsOnPage(currentFlow, currentPageElements);

    if (isLength(nextElementIds) && currentFlowElement.id === questionElementId) {
      setNextElementsAndFlowOnPage(currentFlow?.goTo, setPagesFlow, nextElementIds, currentPageElements, questionObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlowElement]);

  // hardcode cleaning holz product cards counters
  useEffect(() => {
    if (HAM_VB_PK_Q3_ID === questionElementId && (!selectedAnswers.includes(HAM_VB_PK_Q3_A3_ID) || !P4?.content?.products[0]?.selected)) {
      const { updatedPages, updatedChapters } = rewriteHamburgPKHolzPages(allPages, allChapters, P10, P12);
      setPagesFlow(prev => ({ ...prev, allChapters: updatedChapters, allPages: updatedPages }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswers, P4]);

  const selectAnswers = answer => {
    const newAnswers = new Set(answers.filter(answ => answ?.exception?.value === answer.exception?.value).map(el => el.id));
    const newSelectedAnswers = selectedAnswers.filter(answerId => newAnswers.has(answerId));
    const updatedSelectedAnswers = multipleChoice ? [...newSelectedAnswers, answer.id] : [answer.id];
    const updatedUnselectedAnswers = selectedAnswers.filter(answerId => answerId !== answer.id);
    const resultAnswers = selectedAnswers.includes(answer.id) ? updatedUnselectedAnswers : updatedSelectedAnswers;

    // const updatedQuestionFlow = getQuestionCurrentFlow(resultAnswers);
    const updatedQuestionFlow = [HAM_VB_PK_Q5_ID, HAM_VB_PK_Q7_ID, HAM_VB_PK_Q8_ID].includes(questionElementId)
      ? getQuestionCurrentFlow(resultAnswers)
      : getQuestionConditionsFlow(flow, multipleChoice, resultAnswers);

    const nextElementId = getCurrentPageNextElementId(updatedQuestionFlow?.goTo, currentPageElements);
    const currentElementIndex = currentElementsIds.indexOf(questionElementId);
    const { nextElementIds, flowOnCurrentPage } = getNextElementsIdsOnPage(updatedQuestionFlow, currentPageElements);
    const filteredCurrentElementsIds = flowOnCurrentPage
      ? currentElementsIds.filter((id, index) => index <= currentElementIndex)
      : currentElementsIds;
    const updatedCurrentElementsIds = isLength(nextElementIds) ? [...filteredCurrentElementsIds, ...nextElementIds] : [...filteredCurrentElementsIds];

    const initialScoreArr = answersScoring[questionElementId] ? answersScoring[questionElementId].selectedAnswers : [];
    const filteredInitialScoreArr = multipleChoice
      ? initialScoreArr.filter(answ => answ?.exception?.value === answer.exception?.value)
      : initialScoreArr;

    const selectedAnswersScoring = multipleChoice ? [...filteredInitialScoreArr, answer] : [answer];
    const unselectedAnswersScoring = filteredInitialScoreArr.filter(answerObj => answerObj.id !== answer.id);
    const resultScoring = selectedAnswers.includes(answer.id) ? unselectedAnswersScoring : selectedAnswersScoring;

    const nonFlowProductsElements = currentPageElements.filter(
      el => el.templateType === TemplateTypes.PRODUCT && !updatedCurrentElementsIds.includes(el.id)
    );

    const updatedAllPages = allPages.map(page =>
      page.id === currentPageId
        ? { ...page, elements: updateElementsAfterAnswering(page.elements, questionElementId, resultAnswers, nonFlowProductsElements) }
        : page
    );
    const unselectedIdsArr = nonFlowProductsElements.flatMap(el => el?.content?.products).map(prod => prod.id);

    setPagesFlow(prev => ({
      ...prev,
      allChapters: rewriteChapterSelectedProducts(prev?.allChapters, prev?.currentChapterId, [], unselectedIdsArr),
      allPages: updatedAllPages,
      currentElementsIds: updatedCurrentElementsIds,
      currentFlowElement: nextElementId ? currentPageElements.find(el => el.id === updatedQuestionFlow?.goTo) : questionObject,
      answersScoring: { ...answersScoring, [questionElementId]: { selectedAnswers: resultScoring } },
    }));
  };

  return (
    <section className={styles.section} style={{ width: '100%' }}>
      <div className={styles.container}>
        <div style={{ width: '100%', ...rootStyle }}>
          {question.value && (
            <Typography.Paragraph
              style={{
                marginBottom: 10,
                width: '100%',
                textAlign: 'center',
                fontSize: fonts.headlineSecond.fontSize,
                fontFamily: fonts.headlineSecond.font,
                fontWeight: fonts.headlineSecond.fontWeight,
                color: colors[fonts.headlineSecond.colorVariant],
                ...questionStyle,
              }}
            >
              {applyVariable(question.value)}
              <InfoIconComponent applyVariable={applyVariable} type={question} styles={{ marginLeft: 5, top: 1 }} />
            </Typography.Paragraph>
          )}
          {questionSubline.value && (
            <Typography.Paragraph
              style={{
                marginBottom: 24,
                width: '100%',
                textAlign: 'center',
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: 700,
                color: colors[fonts.body.colorVariant],
                ...questionSublineStyle,
              }}
            >
              {applyVariable(questionSubline.value)}
              <InfoIconComponent applyVariable={applyVariable} type={questionSubline} styles={{ marginLeft: 5, top: 2 }} />
            </Typography.Paragraph>
          )}
          <div style={{ width: '100%', ...answersBlockStyle, marginBottom: '40px' }}>
            {Array.isArray(chartAnswers) && Array.isArray(productValues) && xAxis && yAxis && (
              <div className={styles.chart_container}>
                <div className={styles.counters}>
                  {chartAnswers.map(answer => (
                    <LayoutNumberInputSection
                      key={answer.id}
                      answers={chartAnswers}
                      answerFieldName='chartAnswers'
                      primaryColor={colors.primaryColor}
                      textStyle={{
                        fontSize: fonts.body.fontSize,
                        fontFamily: fonts.body.font,
                        fontWeight: 400,
                        color: colors[fonts.body.colorVariant],
                      }}
                      answer={answer}
                      element={questionObject}
                      setPagesFlow={setPagesFlow}
                      pagesFlow={pagesFlow}
                      currentPageElements={currentPageElements}
                      flow={flow}
                    />
                  ))}
                </div>
                <div className={styles.chart}>
                  <Chart
                    pagesFlow={pagesFlow}
                    productValues={productValues}
                    chartAnswers={chartAnswers}
                    fonts={fonts}
                    colors={colors}
                    xAxis={xAxis}
                    yAxis={yAxis}
                  />
                </div>
              </div>
            )}
            {Array.isArray(answers) && (
              <Space size='middle' wrap style={{ width: '100%', padding: '0 24px 0 24px', display: 'flex', justifyContent: 'center' }}>
                {answers.map(answer => (
                  <div
                    key={answer.id}
                    style={{
                      width: 230,
                      border: selectedAnswers?.includes(answer.id) ? 'none' : '2px solid #5C5C6E',
                      backgroundColor: selectedAnswers?.includes(answer.id) ? colors.primaryColor : 'transparent',
                      borderRadius: 4,
                      height: 48,
                      padding: '10px 5px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    role='presentation'
                    onClick={() => selectAnswers(answer)}
                    className='analytics-answer'
                  >
                    <Typography.Paragraph
                      ellipsis={{ rows: 2 }}
                      data-testid={selectedAnswers?.includes(answer.id) ? 'selected_answer' : 'unselected_answer'}
                      style={{
                        fontSize: fonts.body.fontSize,
                        fontFamily: fonts.body.font,
                        fontWeight: selectedAnswers?.includes(answer.id) ? 700 : 400,
                        color: selectedAnswers?.includes(answer.id) ? '#FFFFFF' : colors[fonts.body.colorVariant],
                        overflow: 'hidden',
                        marginBottom: 0,
                        textAlign: 'center',
                        maxWidth: 170,
                      }}
                      className='analytics-answer'
                    >
                      {applyVariable(answer.value)}
                      <span style={{ position: 'absolute', top: 0, right: 25 }}>
                        <InfoIconComponent applyVariable={applyVariable} type={answer} />
                      </span>
                    </Typography.Paragraph>
                  </div>
                ))}
              </Space>
            )}
            {Array.isArray(counterAnswers) && (
              <div className={rootStyle.slider ? styles.slider_counters : styles.counters}>
                {counterAnswers.map(answer => (
                  <LayoutNumberInputSection
                    key={answer.id}
                    answers={counterAnswers}
                    answerFieldName='counterAnswers'
                    primaryColor={colors.primaryColor}
                    textStyle={{
                      fontSize: fonts.body.fontSize,
                      fontFamily: fonts.body.font,
                      fontWeight: 400,
                      color: colors[fonts.body.colorVariant],
                    }}
                    answer={answer}
                    element={questionObject}
                    setPagesFlow={setPagesFlow}
                    pagesFlow={pagesFlow}
                    slider={rootStyle.slider}
                    currentPageElements={currentPageElements}
                    flow={flow}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {composerButton && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 10, marginBottom: '24px' }}>
            <ButtonOrLinkComponent
              contentObject={composerButton}
              style={{
                backgroundColor: colors.primaryColor,
                height: 48,
                minWidth: 200,
                borderRadius: 24,
                padding: '5px 8px',
                color: '#FFFFFF',
              }}
              setPagesFlow={setPagesFlow}
              pagesFlow={pagesFlow}
              textStyle={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
              }}
              analyticsClass='analytics-button'
              setPageHistory={setPageHistory}
              currentElements={currentElements}
            />
          </div>
        )}
      </div>
    </section>
  );
}
