/* eslint-disable react/no-array-index-key */
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { ContentBlock } from './ContentBlock';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import '../style.scss';
import { DroppableFieldWrapper } from '../../DroppableItems/DroppableFieldWrapper';
import { ContentTableList } from '../../../ContentTableSection';
import { ComposerButton } from '../ComposerButton';
import { isLength } from '../../../../../../../../../../../lib/util/helpers';

/**
 * @desc Content is an informative composer element, usually, there are no elements that the user can interact with within the content.
 * @param {Number} itemIndex
 * @param {Function} handleChangeFieldValue
 * @param {Function} handleChangeField
 * @param {Function} onChangeFieldInfoIconValue
 * @param {Function} onDeleteInfoIcon
 * @param {Function} onDeleteInfoIconContentBlocks
 * @param {Function} onDeleteComposerButton
 * @param {Boolean} isDraggingOver
 * @returns {JSX.Element}
 */

export function ContentForm({
  item,
  itemIndex,
  handleChangeFieldValue,
  handleChangeField,
  onChangeFieldInfoIconValue,
  onDeleteInfoIcon,
  onDeleteInfoIconContentBlocks,
  onDeleteComposerButton,
  isDraggingOver,
}) {
  const { content = {}, id } = item;
  const { headerHeadline, contentBlocks, tables } = content;
  const { templateHeadline, templateInfo } = useLocalization().translations.unique.formItems;
  const { header } = useLocalization().translations.unique.pages.settings.design;

  return (
    <>
      {headerHeadline && (
        <div className='template_card_header_block'>
          <AppText className='template_card_headline' textValue={header} />
          <DroppableFieldWrapper
            itemId={id}
            labelField={templateHeadline.label}
            labelTemplateInfo={templateInfo.label}
            type={!item.content.headerHeadline?.infoIcon && 'INFO_ICON'}
            valueObject={headerHeadline}
            onChangeField={handleChangeFieldValue}
            onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
            onDeleteInfoIcon={onDeleteInfoIcon}
            fieldName='headerHeadline'
            infoIcon={item.content.headerHeadline?.infoIcon}
          />
        </div>
      )}
      {Array.isArray(contentBlocks) &&
        contentBlocks.map((contentBlock, index) => (
          <ContentBlock
            key={index}
            index={index}
            contentBlocks={contentBlocks}
            contentBlock={contentBlock}
            handleChangeField={handleChangeField('contentBlocks')}
            item={item}
            structureItemIndex={itemIndex}
            onDeleteInfoIcon={onDeleteInfoIconContentBlocks('contentBlocks')}
          />
        ))}
      {isLength(tables) && <ContentTableList structureItemIndex={itemIndex} tables={tables} structureItemId={id} />}
      {(item.content?.composerButton?.name || isDraggingOver) && (
        <div className='template_card_bottom_block'>
          <ComposerButton item={item} isDraggingOver={isDraggingOver} onChangeFieldValue={handleChangeFieldValue} onDelete={onDeleteComposerButton} />
        </div>
      )}
    </>
  );
}
