/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { AppImage } from '../../../../../../../../../../ui/AppImage';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { Droppable } from 'react-beautiful-dnd';
import { useContentTable } from '../hooks/useContentTable';
import { DroppableIdField, DroppableIdKind, DroppableIdType, ResultType } from '../../../../../../../../../../lib/models/Questionnaire';
import { message } from 'antd';
import { FIRST_INDEX } from '../../../../../../../../../../lib/util/constants';

const MAX_CELL_TEXT_LENGTH = 200;

function getCutCellText(value) {
  return value?.toString().slice(FIRST_INDEX, MAX_CELL_TEXT_LENGTH);
}

export function ValueCellInner({ columnIndex, rowIndex, isFocused, bgColorValue, item = {}, isImage, ...rest }) {
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;
  const ref = useRef(null);
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { contentTableState } = useContentTable();
  const [messageApi, contextHolder] = message.useMessage();

  const changeInput = ({ target }) => {
    const { value } = target;

    if (value?.length > MAX_CELL_TEXT_LENGTH) {
      messageApi.error({
        content: handleTranslation(contentTableText.maxCellTextLengthError({ limit: MAX_CELL_TEXT_LENGTH })),
        duration: 4, // seconds
      });
    }

    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].cells[columnIndex].value = getCutCellText(value);
    });
  };

  return (
    <>
      {contextHolder}

      <Root item={item} bgColor={bgColorValue} isFocused={isFocused} {...rest}>
        <Droppable
          isDropDisabled={Boolean(item?.infoIcon)}
          droppableId={JSON.stringify({
            [DroppableIdField.PATH]: `${contentTableState.tablePath}.tableRows[${rowIndex}].cells[${columnIndex}].infoIcon`,
            [DroppableIdField.KIND]: DroppableIdKind.WITH_PATH,
            [DroppableIdField.TYPE]: DroppableIdType.COMPARISON_TABLE_CELL,
            tablePath: contentTableState.tablePath,
          })}
          type={ResultType.INFO_ICON}
        >
          {provided => (
            <div ref={provided.innerRef}>
              {isImage ? (
                item?.image ? (
                  <>
                    <StyledImage src={item.image} alt={item?.label} />
                    {item.footnote && item.footnote.enabled && <FootNote>{item.footnote.note}</FootNote>}
                  </>
                ) : item?.label ? (
                  <>
                    <AppText textValue={item.label} style={styles.imageLabel} />
                    {item.footnote && item.footnote.enabled && <FootNote>{item.footnote.note}</FootNote>}
                  </>
                ) : (
                  <>
                    <AppText textValue={contentTableText.selectProductPlaceholder} style={styles.selectProduct} />
                    {item.footnote && item.footnote.enabled && <FootNote>{item.footnote.note}</FootNote>}
                  </>
                )
              ) : (
                <>
                  <StyledInput
                    ref={ref}
                    item={item}
                    type='text'
                    value={item?.value || ''}
                    onChange={changeInput}
                    placeholder={item?.withPlaceholder === false ? undefined : handleTranslation(contentTableText.textCellPlaceholder)}
                    disabled={isViewMode}
                  />
                  {item.footnote && item.footnote.enabled && <FootNote>{item.footnote.note}</FootNote>}
                </>
              )}
              {item?.infoIcon && <StyledInputInfoIconCounter>{item.infoIcon?.infoIconIndex}</StyledInputInfoIconCounter>}
            </div>
          )}
        </Droppable>
      </Root>
    </>
  );
}

const cellTextPadding = '10px 0';
const placeholderColor = '#9696A2';

const styles = {
  selectProduct: {
    padding: cellTextPadding,
    color: placeholderColor,
  },
  imageLabel: {
    padding: cellTextPadding,
  },
};

const Root = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  margin: 0,
  background: ({ bgColor }) => bgColor || 'unset',
  textAlign: ({ item }) => item?.align || 'initial',
  justifyContent: ({ item }) => item?.align || 'center',
  outline: ({ isFocused }) => (isFocused ? '1px solid #0092A3' : 'unset'),
});

const StyledImage = styled(AppImage)({
  height: '40px',
});

const StyledInput = styled('input')({
  width: '100%',
  minWidth: 'unset',
  height: '100%',
  fontWeight: ({ item }) => (item?.isBold ? 'bold' : 'unset'),
  color: ({ item }) => item?.color || 'initial',
  textAlign: ({ item }) => item?.align || 'initial',
  cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'text'),
  padding: cellTextPadding,
  border: 'none',
  outline: 'unset',
  background: 'unset',
  '&::placeholder': {
    color: placeholderColor,
  },
});

const StyledInputInfoIconCounter = styled('div')({
  position: 'absolute',
  top: 3,
  right: 4,
  width: 16,
  height: 16,
  backgroundColor: '#858899',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '12px',
});

const FootNote = styled('div')({
  position: 'absolute',
  backgroundColor: '#858899',
  borderRadius: '100%',
  fontSize: '8px',
  right: '4px',
  top: '4px',
  width: '16px',
  height: '16px',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
