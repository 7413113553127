import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { ContentBlockItem } from './components/ContentBlockItem';
import { ButtonOrLinkComponent } from '../../../../../../PreviewPage/components/ButtonOrLinkComponent';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { getBackgroundImageStyle, getBackgroundUrl } from '../../../../../../../../../../../lib/util/helpers';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';

/**
 * @desc Layout Content Blocks
 * @param {Object} selectedElement
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ContentLayoutContentBlocks({ selectedElement, style, stylesConfiguration }) {
  const {
    finder: {
      finderSettings: { media: mediaOptions },
    },
    questionnaireStructure,
  } = useSelector(({ finders }) => finders);

  const { rootStyle = {}, headerHeadlineStyle = {} } = style;

  const element = selectedElement ? questionnaireStructure.find(elem => elem.id === selectedElement.id) : {};

  const {
    content: { headerHeadline, contentBlocks, composerButton },
  } = element;

  const { fonts, colors } = stylesConfiguration;

  const { heading2 } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const backgroundImageUrl = getBackgroundUrl(contentBlocks[0].selectBackgroundImage, mediaOptions);

  return (
    <section
      style={{
        width: 1920,
        height: 813,
        display: 'flex',
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
    >
      <div
        style={{
          ...getBackgroundImageStyle(rootStyle.backGround, backgroundImageUrl),
          margin: '40px 453px',
          width: '100%',
          ...rootStyle,
        }}
      >
        {headerHeadline && (
          <Typography.Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              textAlign: 'center',
              margin: '32px 0px 32px 0px',
              fontSize: fonts.headlineSecond.fontSize,
              fontFamily: fonts.headlineSecond.font,
              fontWeight: fonts.headlineSecond.fontWeight,
              color: colors[fonts.headlineSecond.colorVariant],
              ...headerHeadlineStyle,
            }}
          >
            {headerHeadline.value || heading2()}
            {headerHeadline?.infoIcon?.value && (
              <span style={{ position: 'relative' }}>
                <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 5 }} />
              </span>
            )}
          </Typography.Paragraph>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          {contentBlocks.map(block => (
            <div
              key={uuidv4()}
              style={{
                width: `calc((100% - 16px*(${contentBlocks?.length} - 1))/${contentBlocks?.length}`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ContentBlockItem contentBlock={block} blocksLength={contentBlocks?.length} style={style} stylesConfiguration={stylesConfiguration} />
            </div>
          ))}
        </div>
        {composerButton && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
            <ButtonOrLinkComponent
              contentObject={composerButton}
              disabled
              style={{
                backgroundColor: colors.primaryColor,
                height: 48,
                minWidth: 200,
                borderRadius: 24,
                padding: '5px 8px',
                color: '#FFFFFF',
              }}
              textStyle={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
}
