import { useDispatch, useSelector } from 'react-redux';
import { ProductScoringRow } from '../../components/ProductScoringRow';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import './style.scss';
import { normalizeNumber } from '../../../../../../../../../../../../lib/util/helpers';
import { MAX_COUNTER_VALUE } from '../../../../../../../../../../../../lib/models/Questionnaire';

/**
 * @desc Renders the product answer scoring component.
 * @param {Object} product - The product for which the scoring is being displayed.
 * @param {Object} selectedQuestion - The selected question.
 * @param {Array} answers - The array of answers for the selected question.
 * @returns {JSX.Element} The rendered product answer scoring component.
 */
export function ProductAnswerScoring({ product, selectedQuestion, answers }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const answerProducts = answers.find(answer => answer.id === selectedQuestion.answerId).products;

  const value = answerProducts.some(prod => prod.id === product.id) ? answerProducts.find(prod => prod.id === product.id).score : 0;

  const updateProductsArr = (productsArr, value) => {
    return productsArr.some(item => item.id === product.id)
      ? productsArr.map(prodItem => (prodItem.id === product.id ? { ...prodItem, score: value, price: Number(product.pricePerMonth) } : prodItem))
      : [...productsArr, { id: product.id, name: product.name, score: value, price: Number(product.pricePerMonth) }];
  };

  const updateAnswers = (answerArr, value) => {
    return answerArr.map(answer => {
      return answer.id === selectedQuestion.answerId ? { ...answer, products: updateProductsArr(answer.products, value) } : answer;
    });
  };

  const scoreHandleChange = value => {
    const resultValue = normalizeNumber(value, undefined, MAX_COUNTER_VALUE);

    const structure = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? { ...el, content: { ...el.content, answers: updateAnswers(el.content.answers, resultValue) } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure }));
  };

  return <ProductScoringRow productName={`P.${product.name}`} value={value} onChange={scoreHandleChange} disabled={isViewMode} />;
}
