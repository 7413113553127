import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { ElementLabel } from '../../../../../features/ElementLabel';
import './style.scss';

export function CollapseHeader({ templateType, headerText, ...rest }) {
  return (
    <div {...rest} className='align_items_center'>
      <ElementLabel templateType={templateType} />
      <AppText className='card_header_text' textValue={headerText} />
    </div>
  );
}
