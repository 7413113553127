import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppBox } from '../../../../../../../../../../../ui/boxes/AppBox';
import { ChooseImageFormItem } from '../../../../../../../features/ChooseImageFormItem';
import { ProductPrices } from '../../../../../../../features/ProductPrices';
import { ProductLinkFormItem } from '../../../../../../../../../../../features/ProductLinkFormItem';
import { ProductSublineFormItem } from '../../../../../../../../../../../features/ProductSublineFormItem';
import { ProductHeadlineFormItem } from '../../../../../../../../../../../features/ProductHeadlineFormItem';
import { ProductHvmIdFormItem } from '../../../../../../../../../../../features/ProductHvmIdFormItem';
import { ProductNameFormItem } from '../../../../../../../../../../../features/ProductNameFormItem';
import { ProductCategoryFormItem } from '../../../../../../../../../../../features/ProductCategoryFormItem';
import { prepareProductForm } from '../../lib/utils';
import { addProductRequest } from '../../../../../../../../../../../lib/store/slices/productsSlice';
import { trimForm } from '../../../../../../../../../../../lib/util/helpers';
import { ProductShortNameFormItem } from '../../../../../../../../../../../features/ProductShortNameFormItem';
import { PricePerRequest } from '../PricePerRequest';
import { AddExceptionalOption } from '../AddExceptionalOption';

export function AddProductForm({ form }) {
  const dispatch = useDispatch();
  const { id: tenantId } = useParams();
  const { tenantId: tenantIdForUser } = useSelector(({ auth }) => auth);
  const actualTenantId = tenantId || tenantIdForUser;

  const onFinish = values => {
    if (typeof values.imageUrl === 'string') {
      const trimmedForm = trimForm(values);
      const preparedForm = prepareProductForm(trimmedForm);
      dispatch(addProductRequest({ product: preparedForm, tenantId: actualTenantId }));
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <AppBox style={{ marginBottom: 40, marginTop: 20 }}>
        <AddExceptionalOption />
        <ProductCategoryFormItem />
        <ProductNameFormItem />
        <ProductHvmIdFormItem />
        <ProductShortNameFormItem />
        <ProductHeadlineFormItem />
        <ProductSublineFormItem />
        <ProductLinkFormItem />
        <ProductPrices />
        <PricePerRequest />
        <ChooseImageFormItem />
      </AppBox>
    </Form>
  );
}
