import { axiosInstance } from './axios-instance';
import { AppLocalStorage, isLength } from '../../helpers';
import { updateAccessTokenApiRequest } from '../../../api';

import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../constants';

let areTokensUpdating = false;
let failedRequestsQueue = [];

function addFailedRequestToQueue(originalRequest, resolve) {
  failedRequestsQueue.push(() => {
    return resolve(axiosInstance(originalRequest));
  });
}

function retryAllRequests() {
  if (!isLength(failedRequestsQueue)) return;

  for (const retryRequest of failedRequestsQueue) retryRequest();
  failedRequestsQueue = [];
}

async function updateAndSaveAccessToken() {
  const refreshToken = AppLocalStorage.getItem({ key: REFRESH_TOKEN_NAME });
  const { data } = await updateAccessTokenApiRequest(refreshToken);

  const { accessToken } = data;
  AppLocalStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
}

async function updateAccessTokenAndRetryRequests() {
  areTokensUpdating = true;

  // `try/catch` and `return` are here because if the refresh token expires, the user will be automatically logged out. Therefore, all subsequent logic is redundant.
  try {
    await updateAndSaveAccessToken();
  } catch {
    return;
  } finally {
    areTokensUpdating = false;
  }

  retryAllRequests();
}

export function handleUpdateAccessTokenAndRetryRequests(errorResponse) {
  const originalRequest = errorResponse.config;

  return new Promise((resolve, _reject) => {
    addFailedRequestToQueue(originalRequest, resolve);

    if (!areTokensUpdating) {
      void updateAccessTokenAndRetryRequests();
    }
  });
}
