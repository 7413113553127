import styles from './styles.module.scss';
import { PreviewProgressBar } from '../PreviewProgressBar';
import { useEffect, useMemo } from 'react';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { ProgressBarType } from '../../../../utils/consts';

export function PreviewFooter({ footer = {}, colors, pagesFlow, setPagesFlow }) {
  const { primaryColor } = colors;
  const prevPages = useMemo(() => [], []);

  const { currentPageId, progressPercentage, allPages } = pagesFlow;

  const pageWithChapters = allPages.find(page => page.templateType === TemplateTypes.CHAPTER);
  const pageWithIntro = allPages.find(page => page.elements.find(element => element.templateType === TemplateTypes.INTRO));
  const currentPageLength = allPages.length;

  const getPageLength = () => {
    let value = 1;
    if (pageWithIntro) {
      // eslint-disable-next-line no-magic-numbers
      value = 2;
    }

    return value;
  };

  // eslint-disable-next-line no-magic-numbers
  const pagePercentage = 100 / (currentPageLength - getPageLength());

  useEffect(() => {
    if (!prevPages.includes(currentPageId) && currentPageId !== pageWithChapters?.id && currentPageId !== pageWithIntro?.id) {
      prevPages.push(currentPageId);
      setPagesFlow(prevState => ({ ...prevState, progressPercentage: prevState.progressPercentage + pagePercentage }));
    }
  }, [currentPageId, pagePercentage, pageWithChapters?.id, pageWithIntro?.id, prevPages, setPagesFlow]);

  return (
    <footer className={styles.footer}>
      {(footer.progressbarVariant === ProgressBarType.ORIGINAL || !footer.progressbarVariant) && (
        <PreviewProgressBar progressPercentage={progressPercentage || 0} strokeColor={colors[footer.progressbarBackgroundColor] || primaryColor} />
      )}
    </footer>
  );
}
