import { FinderType } from '../../../FindersAndProductsPage/modules/finders/components/FindersTable/lib/Finder';
import { CompanyClientForm } from './CompanyClientForm';
import { PrivateClientForm } from './PrivateClientForm';
import styled from 'styled-components';
import { usePreviewContext } from '../../hooks/usePreviewContext';
import { SuccessScreen } from './SuccessScreen';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';

const TABLET_WIDTH = 900;

export function PreviewForm(props) {
  const { previewState } = usePreviewContext();
  const { windowWidth } = useWindowResize();

  const {
    pagesFlow,
    pagesFlow: { showSuccessScreen, tenant },
    resultObject,
    fonts,
  } = props;

  return (
    <Root windowWidth={windowWidth}>
      {showSuccessScreen ? (
        <SuccessScreen bankName={tenant?.bankName} resultObject={resultObject} pagesFlow={pagesFlow} fonts={fonts} />
      ) : (
        <div>{previewState?.finderType === FinderType.PRIVATE ? <PrivateClientForm {...props} /> : <CompanyClientForm {...props} />}</div>
      )}
    </Root>
  );
}

const Root = styled('div')(props => ({
  'h1, h2, h3, h4, h5, h6, p, a, span, button': {
    fontFamily: 'Arial !important',
    fontWeight: 400,
    color: '#444444',
  },
  display: 'flex',
  justifyContent: 'center',

  '& > *': {
    width: '1121px',
    padding: props.windowWidth > TABLET_WIDTH ? '32px 50px 42px' : '32px 20px',
  },
}));
