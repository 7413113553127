/* eslint-disable i18next/no-literal-string */
/* eslint-disable i18n-text/no-en */
import { createPortal } from 'react-dom';
import styles from './VariablePopup.module.scss';
import { AppButton } from 'src/ui/buttons/AppButton';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import classNames from 'classnames';

export function DeleteConfirmationPopup({ deletePopupInfo, onClose, onDelete }) {
  const { variableUsage, isOpen, variable } = deletePopupInfo;
  const { translations } = useLocalization();
  const genericLocalization = translations.shared.generic;

  let deletionText = null;
  const isButtonDisabled = variableUsage?.rules?.size > 0;

  if (variableUsage?.rules?.size > 0) {
    const ruleNames = [...variableUsage.rules].map(ruleUsage => ruleUsage).join(', ');
    const conditionNames = [...variableUsage.conditions].map(conditionUsage => conditionUsage).join(', ');

    deletionText = (
      <span>
        <b>{variable.name}</b> is used in
        <b>{` ${variableUsage.rules.size} rules (${ruleNames}) `}</b>
        in
        <b>{` ${variableUsage.conditions.size} conditions (${conditionNames})`}</b>, so it cannot be deleted Remove it from the conditions to delete
        the variable.
      </span>
    );
  } else {
    deletionText = 'Do you really want to delete the variable?';
  }

  return (
    isOpen &&
    createPortal(
      <div className={styles.variablePopup}>
        <div className={classNames(styles.body, styles.deletePopup)}>
          <div className={styles.header}>Delete variable {variableUsage?.rules?.size > 0 ? '' : variable.name}</div>
          <div className={styles.deleteBody}>{deletionText}</div>
          <div className={styles.buttonsRow}>
            <AppButton textValue={genericLocalization.cancel} onClick={onClose} />
            <AppButton textValue={genericLocalization.delete} type='primary' disabled={isButtonDisabled} onClick={() => onDelete(variable.id)} />
          </div>
        </div>
      </div>,
      document.getElementById('root')
    )
  );
}
