import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { ProductsIcon } from '../../../../../../../../../../../../ui/icons/ProductsIcon';
import { AppCounterInput } from '../../../../../../../../../../../../ui/AppCounterInput';
import { AppTooltip } from '../../../../../../../../../../../../ui/AppTooltip';
import './style.scss';

/**
 * @desc Represents a row in the product scoring section.
 * @param {String} productName - The name of the product.
 * @param {Number} value - The score value of the product.
 * @param {Function} onChange - A function to handle changes in the score value.
 * @param {Boolean} disabled - Indicates whether the input should be disabled.
 * @returns {JSX.Element} The rendered product scoring row component.
 */
export function ProductScoringRow({ productName, value, onChange, disabled }) {
  return (
    <div className='product_scoring_box'>
      <ProductsIcon className='product_scoring_icon' fill='#5C5C6E' />
      <AppTooltip text={productName}>
        <AppText ellipsis className='product_scoring_name'>
          {productName}
        </AppText>
      </AppTooltip>
      <AppCounterInput style={{ width: 72 }} value={value} onChange={onChange} disabled={disabled} regEx={/[\d-]/} />
    </div>
  );
}
