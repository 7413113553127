import classes from '../Flow.module.sass';
import styles from './styles.module.scss';
import { ResultDepotButtonFlow } from './ResultDepotButtonFlow';

/**
 * @desc Renders the flow for result depot products.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.structureItem - The structure item representing the result depot flow.
 * @param {boolean} props.disabled - Indicates whether the component is disabled.
 * @returns {JSX.Element|null} The JSX element displaying the flow for result depot products, or null if no products are selected.
 */
export function ResultDepotFlow({ structureItem, disabled }) {
  const {
    id: structureItemId,
    content: { products },
  } = structureItem;

  const isSelectedAtLeastOneProduct = products?.some(el => el.productNameSection.product.value);

  return (
    isSelectedAtLeastOneProduct && (
      <div className={classes.root_product}>
        {products.map((product, productIndex) => {
          return (
            <div className={styles.product} key={product.id}>
              <p className={styles.product_title}>{product.productNameSection.product.title}</p>
              {product.buttonsSection.buttons.map((button, buttonIndex) => {
                return (
                  <ResultDepotButtonFlow
                    key={button.id}
                    buttonObject={button}
                    structureItemId={structureItemId}
                    disabled={disabled}
                    productIndex={productIndex}
                    buttonIndex={buttonIndex}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    )
  );
}
