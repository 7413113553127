import { useSelector } from 'react-redux';

import { AppNewSelect } from 'src/ui/AppNewSelect';
import { ValueTypeInput } from 'src/ui/ValueTypeInput/';
import { ConditionalElement } from './ConditionalElement';
import { ConditionalResultType } from './ConditionalResultType';

import { comparisonOptions, arithmeticOptions } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionTypes';

import { types, arithmeticTypes, logicalTypes } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import styles from './ConditionItem.module.scss';

/**
 * @desc Condition Item
 * @param {String} type
 * @param {String | Object} value
 * @param {Function} onChange
 * @param {Boolean} isThenOperatorPlaced
 * @param {Array} body
 * @param {Number} index
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ConditionItem({ type, value, onChange, isThenOperatorPlaced, body, index }) {
  const thenIndex = body?.findIndex(conditionItem => conditionItem.value === logicalTypes.Then);
  const notFoundIndex = -1;
  const isPlacedAfterThen = thenIndex === notFoundIndex ? false : index > thenIndex;
  const { variables: finderVariables } = useSelector(({ variables }) => variables);
  switch (type) {
    case types.comparisonOperator:
    case types.arithmeticOperator: {
      const typeOptions = {
        [types.comparisonOperator]: comparisonOptions,
        [types.arithmeticOperator]: arithmeticOptions,
      };

      const mappedOptions = typeOptions[type].map(typeOption => ({
        title: typeOption,
        value: typeOption,
        isDisabled: typeOption === arithmeticTypes.equal ? !isThenOperatorPlaced : false,
      }));

      return (
        <div className={styles.conditionalItem} data-testid='conditionTestItem'>
          <AppNewSelect
            onChange={selection => onChange(selection.value)}
            className={type !== types.categoricalOperator ? styles.smallSelect : styles.conditionalSelect}
            isMultiSelect={false}
            value={value}
            options={mappedOptions}
            label={type === types.categoricalOperator ? type : ''}
          />
        </div>
      );
    }
    case types.variable: {
      return (
        <div className={styles.conditionalItem} data-testid='conditionTestItem'>
          <AppNewSelect
            onChange={selection => onChange(selection.value)}
            fullWidth
            className={styles.conditionalSelect}
            isMultiSelect={false}
            value={value}
            options={finderVariables.map(variable => ({
              title: variable.name,
              value: variable.id,
            }))}
            label={type}
          />
        </div>
      );
    }
    case types.logicalOperator:
      return (
        <div className={styles.conditionalItem} data-testid='conditionTestItem'>
          <div className={styles.default}>{value}</div>
        </div>
      );
    case types.value: {
      const mappedValue = {
        inputValue: value.amount,
        selectValue: value.valueType,
      };
      return (
        <div data-testid='conditionTestItem'>
          <ValueTypeInput
            value={mappedValue}
            allowString={isPlacedAfterThen}
            onChange={val =>
              onChange({
                amount: val.inputValue,
                valueType: val.selectValue,
              })
            }
          />
        </div>
      );
    }
    case types.element:
      return <ConditionalElement value={value} onChange={onChange} />;
    case types.resultType:
      return <ConditionalResultType value={value} onChange={onChange} />;
    default:
      return <div>{value}</div>;
  }
}
