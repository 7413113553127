/* eslint-disable max-lines */
import { useSelector } from 'react-redux';

export const formatOptions = (elements = []) => {
  return elements.map(element => formatOptionObj(element));
};

/**
 * @desc Formats an element object based on its template type.
 * @param {Object} element - The element object to be formatted.
 * @returns {Object} - The formatted object with additional 'value' field.
 */
const formatOptionObj = element => {
  let elementObject = null;

  switch (element.templateType) {
    case 'PRODUCT_ITEM':
      elementObject = {
        ...element,
        type: 'P',
      };
      break;
    case 'PRODUCT':
      elementObject = {
        title: element.headline,
        type: 'P',
      };
      break;
    case 'QUESTION':
      elementObject = {
        title: `Question ${element.index}`,
        products: element.content?.products,
        questionId: element.id,
        type: 'Q',
      };
      break;
    case 'ANSWER':
      elementObject = {
        title: `Answer ${element.questionIndex}.${element.answerIndex}`,
        questionId: element.questionId,
        answerId: element.answerId,
        products: element.products,
        answerType: element.answerType,
        type: 'A',
      };
      break;
    case 'CHAPTER': {
      const activeImages = [...(element.content?.selectBackgroundImageActive?.selectedImages || [])];

      // to handle old chapter images
      if (element.content.selectBackgroundImageActive?.value) {
        activeImages.push(element.content.selectBackgroundImageActive.value);
      }

      const inactiveImages = [...(element.content?.selectBackgroundImageInactive?.selectedImages || [])];

      // to handle old chapter images
      if (element.content.selectBackgroundImageInactive?.value) {
        inactiveImages.push(element.content.selectBackgroundImageInactive.value);
      }

      elementObject = {
        activeImages: [...new Set(activeImages)],
        inactiveImages: [...new Set(inactiveImages)],
        title: `Chapter ${element.index}`,
        type: 'CH',
      };
      break;
    }
    case 'CONTENT':
      elementObject = {
        title: element.content?.headerHeadline?.value,
        type: 'C',
      };
      break;
    default:
      elementObject = {};
  }

  return {
    ...elementObject,
    value: element.id,
  };
};

// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
export const useElements = () => {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const rawAnswers = [];
  const rawProducts = [];
  const rawQuestions = [];
  const rawChapters = [];
  const buttons = [];

  let productCounter = 1;
  let questionsCounter = 1;
  let chapterCounter = 1;

  for (const structureElement of questionnaireStructure) {
    if (structureElement.content?.primaryButton?.id && structureElement.content.secondaryButton?.id) {
      buttons.push(
        {
          title: `B. ${structureElement.content.primaryButton.value}`,
          value: structureElement.content.primaryButton.id,
        },
        {
          title: `B. ${structureElement.content.secondaryButton.value}`,
          value: structureElement.content.secondaryButton.id,
        }
      );
    }

    if (structureElement.content?.composerButton && structureElement.content.composerButton.id) {
      buttons.push({
        title: `B. ${structureElement.content.composerButton.value}`,
        value: structureElement.content.composerButton.id,
      });
    }

    switch (structureElement.templateType) {
      case 'QUESTION': {
        rawQuestions.push({
          ...structureElement,
          index: questionsCounter,
        });

        if (structureElement.content.answers) {
          for (const [idx, answer] of structureElement.content.answers.entries()) {
            rawAnswers.push({
              ...answer,
              questionIndex: questionsCounter,
              products: answer.products,
              answerIndex: idx + 1,
              questionId: structureElement.id,
              answerId: answer.id,
              templateType: 'ANSWER',
            });
          }
        }

        if (structureElement.content?.counterAnswers) {
          for (const [idx, answer] of structureElement.content.counterAnswers.entries()) {
            rawAnswers.push({
              ...answer,
              questionIndex: questionsCounter,
              answerIndex: idx + 1,
              questionId: structureElement.id,
              answerId: answer.id,
              answerType: 'without product',
              templateType: 'ANSWER',
            });
          }
        }

        if (structureElement.content?.chartAnswers) {
          for (const [idx, answer] of structureElement.content.chartAnswers.entries()) {
            rawAnswers.push({
              ...answer,
              questionIndex: questionsCounter,
              answerIndex: idx + 1,
              questionId: structureElement.id,
              answerId: answer.id,
              answerType: 'without product',
              templateType: 'ANSWER',
            });
          }
        }

        questionsCounter += 1;
        break;
      }
      case 'CHAPTER': {
        rawChapters.push({
          ...structureElement,
          index: chapterCounter,
        });

        if (structureElement.content?.chapterButton && structureElement.content.chapterButton.value && structureElement.content.chapterButton.id) {
          buttons.push({
            title: `B. ${structureElement.content.chapterButton.value}`,
            value: structureElement.content.chapterButton.id,
          });
        }

        if (structureElement.content?.completedChaptersButtons) {
          for (const chapterButton of structureElement.content.completedChaptersButtons) {
            if (chapterButton.id) {
              buttons.push({
                title: `B. ${chapterButton.buttonText.value}`,
                value: chapterButton.id,
              });
            }
          }
        }

        chapterCounter += 1;
        break;
      }
      case 'PRODUCT': {
        for (const [idx, product] of [...structureElement.content.products].entries()) {
          rawProducts.push({
            productId: product.id,
            // eslint-disable-next-line no-loop-func
            products: structureElement.content.products.map((templateProduct, productIdx) => ({
              id: templateProduct.id,
              value: templateProduct.id,
              title: `Product ${productCounter}.${productIdx + 1}`,
            })),
            title: `Product ${productCounter}.${idx + 1}`,
            productTemplateId: structureElement.id,
            templateType: 'PRODUCT_ITEM',
            id: product.id,
          });
        }

        productCounter += 1;
        break;
      }
      case 'RESULT': {
        if (structureElement.content?.buttons?.length > 0) {
          for (const button of structureElement.content.buttons) {
            if (button.id) {
              buttons.push({
                title: `B. ${button.buttonText.value}`,
                value: button.id,
              });
            }
          }
        }

        if (structureElement.content?.products) {
          for (const depotProduct of structureElement.content.products) {
            if (!depotProduct.buttonsSection?.buttons) {
              break;
            }

            for (const depotButton of depotProduct.buttonsSection.buttons) {
              buttons.push({
                title: `B. ${depotButton.buttonText.value}`,
                value: depotButton.id,
              });
            }
          }
        }
        break;
      }
      default:
      // unknown templateType, please add a new case
    }
  }

  const { products: TenProducts } = useSelector(({ products: tenantProd }) => tenantProd);
  const tenantProducts = formatOptions(
    TenProducts?.map(productItem => ({
      ...productItem,
      templateType: 'PRODUCT',
    }))
  );

  const products = formatOptions(rawProducts);

  const questionOptions = formatOptions(rawQuestions);

  const answerOptions = formatOptions(rawAnswers);

  const chapterOptions = formatOptions(rawChapters);

  return { products, tenantProducts, questionOptions, answerOptions, chapterOptions, buttons };
};
