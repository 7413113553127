/* eslint-disable no-param-reassign */
import { LinkIcon } from '../../../../../../../../../../../../../../ui/icons/LinkIcon';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { LINK_PATTERN } from '../../../../../../../../../../../../../../lib/util/regexp';
import { handleTranslation, isTruthy } from '../../../../../../../../../../../../../../lib/util/helpers';
import { QuestionnaireFormSectionWithDelay } from '../../../../../../../../features/QuestionnaireFormSectionWithDelay';

export function ProductPageLink({ structureItemIndex, productIndex, value }) {
  const { updateStructure } = useUpdateStructure();
  const { errors } = useLocalization().translations.unique.formItems.link;

  const onChange = value => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].productPageLink = value;
    });
  };

  return (
    <QuestionnaireFormSectionWithDelay
      formItemProps={{
        help: isTruthy(value) && !LINK_PATTERN.test(value) && handleTranslation(errors.invalid),
      }}
      textFieldProps={{
        value,
        prefix: <LinkIcon />,
        onChange,
      }}
    />
  );
}
