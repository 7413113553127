import { HomeIcon } from '../../../../../../../../../../../ui/icons/HomeIcon';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppSpace } from '../../../../../../../../../../../ui/AppSpace';

export function FinderURL({ url }) {
  return (
    <AppSpace style={{ marginBottom: 40 }}>
      <HomeIcon />
      <AppText style={{ color: '#5C5C6E' }} textValue={url} />
    </AppSpace>
  );
}
