export const Rule = {
  CREATION_DATE: 'createdDate',
  LAST_EDITED_DATE: 'lastModifiedDate',
  ACTIVATED: 'activated',
  NAME: 'name',
  CREATOR: 'createdBy',
};

export const RuleStatus = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
};
