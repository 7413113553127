import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  users: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  isModalLoading: false,
  error: null,
  selectedUser: {},
  modalType: '',
  isModalOpen: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersRequest: state => {
      state.error = null;
      state.loading = true;
      state.users = [];
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload.users;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getUsersError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setUserStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setUserStatusSuccess: state => {
      state.loading = false;
    },
    setUserStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openUserModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedUser = action.payload.selectedUser || {};
    },
    closeUserModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedUser = {};
    },
    addUserRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    addUserSuccess: state => {
      state.isModalLoading = false;
    },
    addUserError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editUserRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    editUserSuccess: state => {
      state.isModalLoading = false;
    },
    editUserError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    deleteUserRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteUserSuccess: state => {
      state.isModalLoading = false;
    },
    deleteUserError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    /* arranges */
    // apply arranges
    applyUserArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyUserArrangesSuccess: state => {
      state.loading = false;
    },
    applyUserArrangesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // remove filters
    removeUserFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeUserFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeUserArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  setUserStatusRequest,
  setUserStatusSuccess,
  setUserStatusError,
  openUserModal,
  closeUserModal,
  addUserRequest,
  addUserSuccess,
  addUserError,
  editUserRequest,
  editUserSuccess,
  editUserError,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserError,
  applyUserArrangesRequest,
  applyUserArrangesError,
  removeUserArranges,
  removeUserFiltersRequest,
  removeUserFiltersError,
} = usersSlice.actions;

export const users = usersSlice.reducer;
