import { forwardRef } from 'react';
import ReactSelect from 'react-select';
import { DropdownIndicator } from './components/DropdownIndicator';
import { MultiSelectOption } from './components/MultiSelectOption';
import { AppNewSelectMenuList } from './components/AppNewSelectMenuList';
import { MenuListWithoutSelectAll } from './components/MenuListWithoutSelectAll';
import { MultiValue } from './components/MultiValue';
import { MultiValueWithoutLimit } from './components/MultiValue/MultiValueWithoutLimit';
import { MultiValueRemove } from './components/MultiValueRemove';
import { Control } from './components/Control';
import { Group } from './components/Group';
import { SingleSelectOption } from './components/SingleSelectOption';
import { handleTranslation, isLength } from '../../lib/util/helpers';
import { createStyles } from './lib/AppNewSelect-create-styles';
import { formatAppNewSelectData } from './lib/AppNewSelect-utils';
import './AppNewSelect.scss';

function getData({ getCustomValue, data, options, isValueFromOptions }) {
  return getCustomValue ? getCustomValue(data, options) : isValueFromOptions ? options && formatAppNewSelectData({ options, selected: data }) : data;
}

const searchByTitle = (option, searchQuery = '') => {
  return option.data.title.toLowerCase().includes(searchQuery.toLowerCase());
};

export const AppNewSelect = forwardRef((props, ref) => {
  const {
    isSearchable,
    titleSearch,
    isMultiSelect,
    placeholder,
    styles: additionalStyles,
    value: selected,
    defaultValue: defaultSelected,
    options,
    allOptions,
    'aria-invalid': ariaInvalid,
    unselectable = false,
    disabled,
    components,
    withoutLimit = false,
    selectAll = true,
    isValueFromOptions = true,
    getCustomValue,
    fullWidth,
    smallWidth,
    ...rest
  } = props;

  const isError = ariaInvalid === 'true';
  const mainStyles = createStyles({ isError });
  const $options = isLength(allOptions) ? allOptions : options;

  const $value = getData({ getCustomValue, data: selected, options: $options, isValueFromOptions });
  const $defaultValue = getData({ getCustomValue, data: defaultSelected, options: $options, isValueFromOptions });

  return (
    <ReactSelect
      value={$value || selected}
      defaultValue={$defaultValue || defaultSelected}
      //
      ref={ref}
      isMulti={isMultiSelect}
      fullWidth={fullWidth}
      smallWidth={smallWidth}
      isSearchable={isSearchable}
      {...(titleSearch && { filterOption: searchByTitle })}
      hideSelectedOptions={false}
      isClearable={false}
      options={options}
      classNamePrefix='react-select'
      isDisabled={disabled}
      unselectable={unselectable}
      closeMenuOnSelect={!isMultiSelect}
      placeholder={placeholder ? handleTranslation(placeholder) : null}
      getOptionLabel={option => option?.title} // REFACTOR: replace "title" with const
      components={{
        Group,
        Option: isMultiSelect ? MultiSelectOption : SingleSelectOption,
        MenuList: selectAll ? AppNewSelectMenuList : MenuListWithoutSelectAll,
        DropdownIndicator,
        IndicatorSeparator: () => null,
        MultiValue: withoutLimit ? MultiValueWithoutLimit : MultiValue,
        MultiValueRemove,
        Control,
        ...components,
      }}
      className={isMultiSelect && 'react-select-multiselect'}
      styles={{ ...mainStyles, ...additionalStyles }}
      {...rest}
    />
  );
});
