/* eslint-disable no-magic-numbers */
import { Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { PreviewProductCarouselAndCounter } from '../PreviewProductCarousel';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { InfoIconComponent } from '../InfoIconComponent';
import { ProductKind } from '../../../../../../../../lib/models/Questionnaire';
import { ButtonOrLinkComponent } from '../ButtonOrLinkComponent';
import { getCurrentPageNextElementId } from '../../../../utils/end-user-flow-helpers';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { getNextElementsIdsOnPage, setNextElementsAndFlowOnPage } from '../../hooks/setNextFlow';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import styles from './styles.module.scss';

const HAMBURGER_PK_P1_ID = 'dc6e91ce-b989-4e08-a9bc-fdd916625ccf';

// eslint-disable-next-line max-lines-per-function
export function PreviewProduct(props) {
  const { windowWidth } = useWindowResize();

  const {
    productObject,
    style,
    colors,
    fonts,
    pagesFlow,
    setPagesFlow,
    bulletIcons,
    currentElements,
    currentPageElements,
    setPageHistory,
    isTesting,
  } = props;
  const { applyVariable } = useVariables(pagesFlow);

  const { root: rootStyle = {}, layoutDesign = {} } = style;

  const { customIcons, defaultIcons } = bulletIcons;

  const {
    content: { bulletPointIcon, bulletPointIconColor, headline, productSelection, products, kind: counterType, counter, showSavings, composerButton },
    id,
    flow,
  } = productObject;

  const carouselRef = useRef(null);

  const { allPages, currentFlowElement } = pagesFlow;
  // const { currentFlowElement } = pagesFlow;

  const allContents = allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.CONTENT));
  const C4 = allContents[3];
  const C5 = allContents[4];

  const getHardcodeCurrentFlow = () => {
    if (products[0]?.selected && !products[1]?.selected) {
      return C4.id;
    }
    if (products[1]?.selected && !products[0]?.selected) {
      return C5.id;
    }

    return flow.goTo;
  };

  useEffect(() => {
    const initialNextElementId = getCurrentPageNextElementId(flow?.goTo, currentPageElements);
    const nextElement = currentPageElements.find(el => el.id === initialNextElementId);
    const { nextElementIds } = getNextElementsIdsOnPage(flow, currentPageElements);
    if (nextElement?.templateType === TemplateTypes.CONTENT && isLength(nextElementIds) && currentFlowElement.id === id) {
      setNextElementsAndFlowOnPage(flow?.goTo, setPagesFlow, nextElementIds, currentPageElements, productObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlowElement]);

  // Only for Hamburger PK P1 hardcode
  useEffect(() => {
    if (HAMBURGER_PK_P1_ID === id) {
      const currentFlow = getHardcodeCurrentFlow();
      setPagesFlow(prev => ({
        ...prev,
        currentFlowElement: { ...currentFlowElement, flow: { goTo: currentFlow } },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <section className={styles.container} style={{ ...rootStyle }}>
      {headline?.value && (
        <div className={styles.headline}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Title
              className={styles.title}
              style={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
                color: colors[fonts.body.colorVariant],
                width: 'auto',
              }}
            >
              {applyVariable(headline?.value && headline.value)}
            </Typography.Title>
            <InfoIconComponent applyVariable={applyVariable} type={headline} styles={{ marginLeft: 10, top: -10 }} />
          </div>
        </div>
      )}
      <PreviewProductCarouselAndCounter
        counterType={counterType}
        carouselRef={carouselRef}
        products={products}
        showSavings={showSavings}
        bulletPointIconColor={bulletPointIconColor}
        bulletPointIcon={bulletPointIcon}
        defaultIcons={defaultIcons}
        colors={colors}
        fonts={fonts}
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        structureItemId={id}
        customIcons={customIcons}
        selectable={productSelection || counterType === ProductKind.ONE_COUNTER}
        counter={counter}
        windowWidth={windowWidth}
        productObject={productObject}
        currentElements={currentElements}
        layoutDesign={layoutDesign}
        isTesting={isTesting}
      />
      {composerButton && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
          <ButtonOrLinkComponent
            contentObject={composerButton}
            style={{
              backgroundColor: colors.primaryColor,
              height: 48,
              minWidth: 200,
              borderRadius: 24,
              padding: '5px 8px',
              color: '#FFFFFF',
            }}
            setPagesFlow={setPagesFlow}
            pagesFlow={pagesFlow}
            textStyle={{
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: fonts.body.fontWeight,
            }}
            analyticsClass='analytics-button'
            setPageHistory={setPageHistory}
            currentElements={currentElements}
          />
        </div>
      )}
    </section>
  );
}
