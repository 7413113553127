import { rulesEngine } from './rules_helper';
import { resultTypeAnswerProperties } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { updatePagesRulesSimpleAnswers } from '../end-user-flow-helpers';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../lib/util/helpers';

export const answerSelectionRulesEngine = (
  rules,
  pagesFlow,
  setPagesFlow,
  currentElements,
  allowRulesAnswerSelection,
  setAllowRulesAnswerSelection
) => {
  const { selectAnswer } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['selectAnswer'] });

  if (isLength(selectAnswer)) {
    const positiveSelectAnswerConditions = selectAnswer.filter(cond => {
      const ruleCondition = allowRulesAnswerSelection.find(ruleAnswerItem => ruleAnswerItem.conditionId === cond.selectAnswer.condition.id);
      const isConditionAllow = !ruleCondition || !ruleCondition?.completed;
      return cond.conditionValue && isConditionAllow;
    });

    if (isLength(positiveSelectAnswerConditions)) {
      setPagesFlow(prev => {
        const { selectedAnswers, unselectedAnswers } = updateAnswerSelection(positiveSelectAnswerConditions, prev.allPages);
        const updatedAllPages = updatePagesRulesSimpleAnswers(
          prev.allPages,
          [...selectedAnswers, ...unselectedAnswers],
          selectedAnswers,
          unselectedAnswers
        );

        return {
          ...prev,
          allPages: updatedAllPages,
          answersScoring: updateAnswerScoring(prev.answersScoring, selectedAnswers, unselectedAnswers),
        };
      });
    }

    const newConditionsArray = selectAnswer.map(ruleItem => {
      return { conditionId: ruleItem.selectAnswer.condition.id, completed: ruleItem.conditionValue };
    });
    const newConditionsIds = new Set(newConditionsArray.map(condItem => condItem.conditionId));
    const oldConditions = allowRulesAnswerSelection.filter(condObject => newConditionsIds.has(condObject.conditionId));
    const filteredConditions = allowRulesAnswerSelection.filter(condObject => !newConditionsIds.has(condObject.conditionId));

    const isNotUpdateAnswerAllowSelection = newConditionsArray.every(newCondItem => {
      const oldCondition = oldConditions.find(oldItem => oldItem.conditionId === newCondItem.conditionId);
      return newCondItem.completed === oldCondition?.completed;
    });

    if (!isNotUpdateAnswerAllowSelection) {
      setAllowRulesAnswerSelection([...filteredConditions, ...newConditionsArray]);
    }
  }
};

const updateAnswerSelection = (selectAnswerBlocks, pages) => {
  const allQuestions = pages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));

  return selectAnswerBlocks.reduce(
    (accumulator, block) => {
      const { conditionValue, selectAnswer } = block;
      if (conditionValue && selectAnswer.property === resultTypeAnswerProperties.select) {
        const selectedAnswers = getAnswersArray(selectAnswer.elements, allQuestions, pages);
        const selectedAnswersIds = new Set(selectedAnswers.map(answer => answer.id));

        return {
          selectedAnswers: [...accumulator.selectedAnswers, ...selectedAnswers],
          unselectedAnswers: accumulator.unselectedAnswers.filter(answer => !selectedAnswersIds.has(answer.id)),
        };
      }

      if (conditionValue && selectAnswer.property === resultTypeAnswerProperties.unselect) {
        const unselectedAnswers = getAnswersArray(selectAnswer.elements, allQuestions, pages);
        const unselectedAnswersIds = new Set(unselectedAnswers.map(answer => answer.id));

        return {
          selectedAnswers: accumulator.selectedAnswers.filter(answer => !unselectedAnswersIds.has(answer.id)),
          unselectedAnswers: [...accumulator.unselectedAnswers, ...unselectedAnswers],
        };
      }

      return accumulator;
    },
    { selectedAnswers: [], unselectedAnswers: [] }
  );
};

const getAnswersArray = (elements, allQuestions, allPages) => {
  return elements
    .map(elAnswer => {
      const originalQuestion = allQuestions.find(question => question.id === elAnswer.questionId);
      const originalPage = allPages.find(page => page.elements.map(el => el.id).includes(elAnswer.questionId));
      const originalAnswer = originalQuestion?.content?.answers?.find(answItem => answItem.id === elAnswer.answerId);

      return originalAnswer ? { ...originalAnswer, pageId: originalPage.id, questionId: originalQuestion?.id } : null;
    })
    .filter(Boolean);
};

const updateAnswerScoring = (answersScoring, selectedAnswers, unselectedAnswers) => {
  const selectedAnsweringScoring = selectedAnswers.reduce((accumulator, answer) => {
    const { questionId } = answer;
    const resultSelectAnswers = accumulator[questionId] ? [...accumulator[questionId].selectedAnswers, answer] : [answer];
    return { ...accumulator, [questionId]: { selectedAnswers: resultSelectAnswers } };
  }, answersScoring);

  return unselectedAnswers.reduce((accumulator, answer) => {
    const { questionId, id } = answer;
    const resultUnselectAnswers = accumulator[questionId]
      ? accumulator[questionId].selectedAnswers.filter(answerObject => answerObject.id !== id)
      : [];
    return { ...accumulator, [questionId]: { selectedAnswers: resultUnselectAnswers } };
  }, selectedAnsweringScoring);
};
