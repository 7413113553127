import { v4 as uuidv4 } from 'uuid';

export function generateAdditionalInfo() {
  return {
    id: uuidv4(),
    fieldText: {
      value: '',
      infoIcon: '',
    },
    question: {
      value: '',
    },
    answer: {
      value: '',
    },
  };
}
