import { useDispatch } from 'react-redux';
import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { deleteCategoryRequest } from '../../../../../../../../../../../lib/store/slices/categoriesSlice';

export function DeleteCategoryModal({ close, isOpen, categoryData, isModalLoading, tenantId }) {
  const dispatch = useDispatch();
  const { title, subTitle } = useLocalization().translations.unique.pages.category.deleteModal;
  const { generic } = useLocalization().translations.shared;

  const submit = () => dispatch(deleteCategoryRequest({ categoryId: categoryData.id, tenantId }));

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title({ categoryName: categoryData.name }) }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, loading: isModalLoading, textValue: generic.delete }}
    >
      {isOpen && <AppParagraph textValue={subTitle} style={{ marginBottom: '24px' }} />}
    </AppModal>
  );
}
