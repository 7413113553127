/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { Product } from '../../../../../../../../FindersAndProductsPage/lib/Product';

export function generateGenericProductItemStructure() {
  return {
    id: uuidv4(),
    productNameSection: {
      showPicture: true,
      product: {
        value: null, // id
        title: null, // name
      },
      subline: {
        value: '',
        infoIcon: null,
      },
    },
    bulletPointsSection: {
      bulletPoints: [],
      bulletPointIcon: { value: '', svgContent: '' },
      bulletPointIconColor: null,
    },
    productPageLink: '',
    image: null,
    buttonsSection: {
      buttons: [],
    },
    detailTableSection: {
      showDetailTable: false,
      detailTables: [],
    },
  };
}

export function setProductFields({ draft, productId, productName, structureItemIndex, productIndex, allProducts }) {
  const productItem = draft[structureItemIndex].content.products[productIndex];
  const selectedProduct = allProducts.find(item => item.id === productId);

  setGenericFields(productItem, productName, productId, selectedProduct);
  setNormalFields(productItem, selectedProduct);
}

function setGenericFields(productItem, productName, productId, selectedProduct) {
  // set product
  productItem.productNameSection.product.value = productId;
  productItem.productNameSection.product.title = productName;

  // set image
  productItem.image = selectedProduct[Product.IMAGE_LINK];
}

function setNormalFields(productItem, selectedProduct) {
  // set link
  productItem.productPageLink = selectedProduct[Product.PRODUCT_PAGE_LINK];
}
