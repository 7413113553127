import styled from 'styled-components';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getFirstCapitalized } from '../lib/util/helpers';

const COEFFICIENT = 6;
const getOptimizedPadding = size => size / COEFFICIENT;

export function AppAvatar({ bgColor = '#c3a314', icon = <UserOutlined />, stringStyle, children, size = 16, ...rest }) {
  const isStr = typeof children === 'string';
  const isSizeNumber = typeof size === 'number';

  return (
    <Inner size={size} $bgColor={bgColor} $stringStyle={stringStyle} shape='circle' {...rest} icon={children ? undefined : icon}>
      {isStr ? <Str style={isSizeNumber && { padding: getOptimizedPadding(size) }}>{getFirstCapitalized(children)}</Str> : children || undefined}
    </Inner>
  );
}

const Inner = styled(Avatar)(({ $bgColor, $stringStyle }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `${$bgColor} !important`,

  '.ant-avatar-string': $stringStyle,
}));

const Str = styled('span')({
  lineHeight: 1,
});
