import classNames from 'classnames';
import classes from './Flow.module.sass';
import { FlowButtonSection } from './FlowButtonSection';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { FlowOperation } from '../../../../../../../../../../lib/models/Questionnaire';

// eslint-disable-next-line unicorn/prefer-set-has
const UNIQUE_OPERATION_LIST = [FlowOperation.GO_TO_FORM, FlowOperation.PRINT];

/**
 * @desc Checks if the given flow button has the specified operation.
 * @param {Object} flowButton - The flow button object to check.
 * @param {string} operation - The operation to compare against.
 * @returns {boolean} - True if the operation of the flow button matches the specified operation, otherwise false.
 */
const isAlreadySelected = (flowButton, operation) => flowButton?.flow?.operation === operation;

/**
 * @desc Checks if the specified operation is not already selected in the given list of flow buttons.
 * @param {Array<Object>} buttonList - The list of flow buttons to check.
 * @param {string} operation - The operation to check for.
 * @returns {boolean} - True if the operation is not already selected in the list, otherwise false.
 */
const isNotSelected = (buttonList, operation) => !buttonList.some(button => isAlreadySelected(button, operation));

/**
 * @desc Determines whether an option can be added based on the current operations and the list of flow buttons.
 * @param {Array<Object>} buttonList - The list of flow buttons to consider.
 * @param {string} iteratedOperation - The operation being iterated over to check.
 * @param {string} currentOperation - The current operation being processed.
 * @returns {boolean} - True if the option can be added, otherwise false.
 */
const canAddOption = (buttonList, iteratedOperation, currentOperation) =>
  isNotSelected(buttonList, iteratedOperation) || currentOperation === iteratedOperation;

/**
 * @desc Filters the list of available flow operation options based on the current flow buttons and their operations.
 * @param {Array} optionsList - An array containing objects representing available flow operation options.
 * @param {Array} buttonList - An array containing objects representing current flow buttons.
 * @param {string} currentOperation - The operation of the current flow button being processed.
 * @returns {Array} - An array containing filtered options.
 */
function filterOptions(optionsList, buttonList, currentOperation) {
  return optionsList.filter(option => {
    if (UNIQUE_OPERATION_LIST.includes(option?.value)) {
      return canAddOption(buttonList, option?.value, currentOperation);
    }

    return true;
  });
}

/**
 * @desc Renders the result flow section for a questionnaire template. Display buttons and options for flow operations such as 'Go to Form' and 'Print'.
 * @param {string} [className] - Additional classes to be applied to the root element.
 * @param {Object} structureItem - The structure item containing information about the flow buttons.
 * @param {Array} elements - List of elements for the flow section.
 * @param {boolean} disabled - Indicates whether the flow section is disabled.
 * @param {Object} rest - Additional props to be spread to the root element.
 * @returns {JSX.Element} The rendered ResultFlow component.
 */
export function ResultFlow({ className, structureItem, elements, disabled, ...rest }) {
  const { buttonIndex: buttonIndexText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;
  const { flowButtonAction } = useLocalization().translations.unique.formItems;
  const buttonList = structureItem?.content?.buttons;
  const optionsList = [
    { value: FlowOperation.GO_TO_FORM, title: flowButtonAction.options.goToForm },
    { value: FlowOperation.PRINT, title: flowButtonAction.options.print },
    // { value: FlowOperation.FORM_THEN_LINK, title: flowButtonAction.options.formThenLink },
  ];

  const { id: structureItemId } = structureItem;

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      {buttonList?.map((button, index) => {
        const currentOperation = button?.flow?.operation;
        const filteredOptions = filterOptions(optionsList, buttonList, currentOperation);

        return (
          <FlowButtonSection
            key={button.id}
            buttonIndex={index}
            structureItemId={structureItemId}
            buttonObject={button}
            buttonTitle={buttonIndexText({ index: index + 1 })}
            buttonText={button.buttonText.value}
            options={filteredOptions}
            elements={elements}
            disabled={disabled}
            buttonsField='buttons'
          />
        );
      })}
    </div>
  );
}
