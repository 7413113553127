/* eslint-disable no-magic-numbers */
import { DestinationVariants, TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../lib/util/helpers';
import { createChapterPage } from '../../../utils/end-user-flow-helpers';

export function getMultiselectFlow(values, goToFieldName, showElementsFieldName) {
  const onlyValues = values.map(el => el.value);
  if (!isLength(values) || onlyValues.at(-1) === DestinationVariants.NEXT) {
    return { [goToFieldName]: DestinationVariants.NEXT, [showElementsFieldName]: [] };
  }
  if (isLength(values) && onlyValues.at(-1) !== DestinationVariants.NEXT) {
    return { [goToFieldName]: onlyValues.at(-1), [showElementsFieldName]: onlyValues.filter(el => el !== DestinationVariants.NEXT) };
  }
  return { [goToFieldName]: onlyValues.at(-1), [showElementsFieldName]: onlyValues };
}

export function getPagesWithChapters(structure) {
  return structure.reduce((accumulator, item) => {
    if (item.templateType === TemplateTypes.PAGE) {
      accumulator.push({ ...item, elements: [] });
    } else if (accumulator.length > 0) {
      accumulator.at(-1).elements.push(item);
    }
    return accumulator;
  }, []);
}

export function getFlowPages(structure) {
  const pages = getPagesWithChapters(structure);

  const chapters = structure.filter(el => el.templateType === TemplateTypes.CHAPTER);
  return createChapterPage(pages, chapters);
}
