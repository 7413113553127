import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { ASSIGNED_PRODUCTS_ID } from './assigned-products-utils';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../../../utils/arranges-helpers';
import { useStringArrange } from '../../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { useCategoryArrangeRequests } from '../../lib/useCategoryArrangeRequests';
import { getFilteredProductsFromApi } from '../../../../../../../utils/api';
import { useArrangeSubmit } from '../../../../../../../utils/hooks/useArrangeSubmit';
import { FilterListPopup } from '../../../../../../../components/popups/FilterListPopup';
import { TableHeaderCell } from '../../../../../../../components/TableHeaderCell';
import { Product } from '../../../../../lib/Product';

export function CategoryAssignedUsersHeaderCell() {
  const { assignedProducts: title } = useLocalization().translations.unique.pages.category.table.columns;
  const { activeArrangedId } = useSelector(({ categories }) => categories);
  const isActiveArrange = activeArrangedId === ASSIGNED_PRODUCTS_ID;
  const [form] = Form.useForm();
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);
  const [filterLabel, setFilterLabel] = useState('');
  const { id: tenantId } = useParams();

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredProductsFromApi(tenantId, { searchByName: value }),
    formatResponse: response => formatArrangedData(response, Product.NAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useCategoryArrangeRequests({
    arrangedId: ASSIGNED_PRODUCTS_ID,
    clearFilters,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ includeProducts: getItemsValues() }),
    form,
    setFilterLabel,
    withSorting: false,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <FilterListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
