import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { ProductValueItem } from './ProductValueItem';
import { AppPreloader } from '../../../../../../../../../../../../ui/AppPreloader';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function ProductValuesBlock({ productValues, handleChangeField, chartAnswers, scoring, handleChangeContentFields }) {
  const { addProduct } = useLocalization().translations.shared.generic;
  const { tenantProducts, isProductsLoading, isViewMode } = useSelector(({ finders }) => finders);

  const addNewProductValue = () => {
    const id = uuidv4();
    const updatedProdValues = [
      ...productValues,
      {
        id,
        value: null,
        price: null,
        productName: '',
        productShortName: '',
        prices: chartAnswers.map(answer => ({ priceId: answer.id, value: 0 })),
        isShortProductName: false,
      },
    ];
    const updatedScoring = [...scoring, { id, value: 0 }];
    handleChangeContentFields({ productValues: updatedProdValues, scoring: updatedScoring });
  };

  if (isProductsLoading) {
    return <AppPreloader />;
  }

  return (
    <>
      {productValues.map((productValue, index) => (
        <ProductValueItem
          key={productValue.id}
          productValue={productValue}
          productValues={productValues}
          index={index}
          handleChangeField={handleChangeField}
          handleChangeContentFields={handleChangeContentFields}
          products={tenantProducts}
          scoring={scoring}
        />
      ))}
      {productValues.length < tenantProducts.length && (
        <AppBasicButton onClick={addNewProductValue} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={addProduct} />
        </AppBasicButton>
      )}
    </>
  );
}
