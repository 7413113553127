import { useState } from 'react';
import styled from 'styled-components';
import { AppLeftSidebar } from '../../../../../../../../ui/AppLeftSidebar';
import { AppTabs } from '../../../../../../../../ui/AppTabs';
import { ComposerContent } from './ComposerContent/ComposerContent';
import { NavigatorContent } from './NavigatorContent/NavigatorContent';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../../../lib/util/helpers';

const Tab = {
  COMPOSER: 'composer',
  NAVIGATOR: 'navigator',
};

/**
 * @desc Questionnaire left sidebar contains composer and navigator
 * @param {Array} composerItems
 * @param {Boolean} isTemplatesLoading
 * @returns {JSX.Element}
 */

export function QuestionnaireLeftSideBar({ composerItems, isTemplatesLoading }) {
  const [activeTab, setActiveTab] = useState(Tab.COMPOSER);
  const { tabs: tabsText } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;

  const tabs = [
    { key: Tab.COMPOSER, label: handleTranslation(tabsText.composer) },
    { key: Tab.NAVIGATOR, label: handleTranslation(tabsText.navigator) },
  ];

  const onTabClick = tab => setActiveTab(tab);

  return (
    <AppLeftSidebar>
      <AppTabs onTabClick={onTabClick} items={tabs} activeKey={activeTab} tabBarGutter={0} className='QuestionnaireLeftSideBarTabs' />
      {activeTab === Tab.COMPOSER &&
        (isTemplatesLoading ? <LoadingContent>Loading...</LoadingContent> : <ComposerContent composerItems={composerItems} />)}
      {activeTab === Tab.NAVIGATOR && <NavigatorContent />}
    </AppLeftSidebar>
  );
}

const LoadingContent = styled('div')({
  padding: 20,
  color: '#fff',
});
