import styled from 'styled-components';
import { Button } from 'antd';
import { useCombinedComponents } from '../../lib/hooks/useCombinedComponents';
import { TranslatableBox } from '../boxes/TranslatableBox';

const StyledButton = styled(Button)(({ type, hovercolor }) => ({
  ...(type === 'primary' ? { fontWeight: 700 } : {}),
  ...(hovercolor
    ? {
        ':hover': {
          color: `${hovercolor} !important`,
          borderColor: `${hovercolor} !important`,
        },
      }
    : {}),
}));

export function AppButton({ as, ...rest }) {
  return useCombinedComponents({
    components: [TranslatableBox, StyledButton, as],
    props: rest,
  });
}
