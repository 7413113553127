import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { FormCard } from '../../../../../features/FormCard';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { FinderDomainFormSection } from '../../../../../features/form-items/FinderDomainFormSection';
import { SubdomainFormItem } from '../../../../../features/form-items/SubdomainFormItem';
import { setTitle } from '../../../../../../../../../lib/store/slices/titleSlice';
import { useFinderSubdomain } from '../../../../FindersAndProductsPage/modules/finders/lib/useFinderSubdomain';
import { FinderURL } from '../../../../FindersAndProductsPage/modules/finders/components/FinderModals/components/FinderURL';

export function BasicDataCard({ form }) {
  const { tenantDomains } = useSelector(({ tenants }) => tenants);

  const { general } = useLocalization().translations.unique.pages.settings;
  const { finderName, metaTitle, metaDescription } = useLocalization().translations.unique.formItems;
  const { creationDate } = useLocalization().translations.shared.columns;
  const dispatch = useDispatch();

  const { finderURL } = useFinderSubdomain(form);

  const onFinderNameChange = value => {
    dispatch(setTitle({ subTitle: value.target.value }));
  };

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className='bold' textValue={general.basicInfo} />
      </AppBox>

      <Space size='middle' className='equal_space'>
        <AppFormSection
          formItemProps={{ name: FormItemName.FINDER_NAME, rules: [{ required: true }, { max: 100 }] }}
          textFieldProps={{ label: finderName.alternativeLabel, onChange: onFinderNameChange }}
        />
        <AppFormSection formItemProps={{ name: FormItemName.CREATION_DATE }} textFieldProps={{ label: creationDate, disabled: true }} />
      </Space>
      <Space size='middle' className='equal_space'>
        <FinderDomainFormSection domains={tenantDomains} />
        <SubdomainFormItem />
      </Space>
      <FinderURL url={finderURL} />
      <Space size='middle' className='equal_space'>
        <AppFormSection formItemProps={{ name: FormItemName.META_TITLE }} textFieldProps={{ label: metaTitle.label }} />
        <AppFormSection formItemProps={{ name: FormItemName.META_DESCRIPTION }} textFieldProps={{ label: metaDescription.label }} />
      </Space>
    </FormCard>
  );
}
