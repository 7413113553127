import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../lib/models/Form';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function FinderDomainFormSection({ domains = [], disabled }) {
  const { label } = useLocalization().translations.unique.formItems.domain;

  const formattedDomains = domains.map(domain => ({ value: domain.name, title: domain.name }));

  return (
    <AppFormSection
      formItemProps={{
        name: FormItemName.DOMAIN,
        rules: [{ required: true }],
      }}
    >
      <AppNewSelect disabled={disabled} options={formattedDomains} label={label} />
    </AppFormSection>
  );
}
