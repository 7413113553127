/* eslint-disable react/no-array-index-key */
import { ValueCell } from './ValueCell';
import classes from '../ComparisonTable.module.sass';

export function BodyInputCells({
  row,
  fieldLabels,
  productFieldList,
  pictureList,
  rowIndex,
  productList,
  textColorList,
  rowColorList,
  iconList,
  focusedId,
  setFocusedId,
}) {
  return row.cells.map((item, columnIndex) => (
    <ValueCell
      key={`${rowIndex}_${columnIndex}`}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      item={item}
      bgColor={row.bgColor}
      textColorList={textColorList}
      iconList={iconList}
      rowColorList={rowColorList}
      productList={productList}
      className={classes.bodyInputCell}
      setFocusedId={setFocusedId}
      focusedId={focusedId}
      fieldLabels={fieldLabels}
      productFieldList={productFieldList}
      pictureList={pictureList}
    />
  ));
}
