import styled from 'styled-components';
import { forwardRef } from 'react';

export const AppList = forwardRef((props, ref) => {
  return <Root {...props} ref={ref} />;
});

const Root = styled('ul')({
  listStyle: 'none',

  '&, & > li': {
    margin: '0px',
    padding: '0px',
  },
});
