import { useState } from 'react';
import { Space } from 'antd';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormCard } from '../../../../../features/FormCard';
import { FormCardButton } from '../../../../../features/FormCardButton';
import { UploadedMediaBox } from '../../../../../features/UploadedMediaBox';
import { UploadLabel } from '../../../../../features/UploadLabel';
import { FinderFileLoader } from '../../../../../features/FinderFileLoader';

const GAP_SIZE = 16;
const MAX_MEDIA_FILE_SIZE = 10;

export function MediaCard({ designSettings, setDesignSettings }) {
  const [uploading, setUploading] = useState(false);
  const { design } = useLocalization().translations.unique.pages.settings;
  const { mediaFiles } = designSettings;

  const updateMediaFiles = file => {
    setDesignSettings(prevDesignSettings => ({ ...prevDesignSettings, mediaFiles: [...mediaFiles, file] }));
  };

  const deleteMediaFile = id => {
    if (!uploading) {
      const filteredMedia = mediaFiles.filter(file => file.id !== id);
      setDesignSettings(prevDesignSettings => ({ ...prevDesignSettings, mediaFiles: [...filteredMedia] }));
    }
  };

  const resetFields = () => {
    if (!uploading) {
      setDesignSettings({ ...designSettings, mediaFiles: [] });
    }
  };

  const mediaOptions = uploading ? [...mediaFiles, { id: 'uploading' }] : mediaFiles;

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className='bold' textValue={design.media} />
        <FormCardButton onClick={resetFields} />
      </AppBox>
      <Space size={[GAP_SIZE, GAP_SIZE]} wrap style={{ marginBottom: 16 }}>
        {mediaOptions?.map(media => (
          <UploadedMediaBox key={media.id} media={media} deleteOnClick={() => deleteMediaFile(media.id)} />
        ))}
      </Space>
      <AppBox>
        <FinderFileLoader
          accept='image/png, image/jpeg'
          afterUploadAction={updateMediaFiles}
          errorText={design.beforeUploadMediaError()}
          maxFileSize={MAX_MEDIA_FILE_SIZE}
          disabled={uploading}
          setUploading={setUploading}
          uploadLabel={<UploadLabel uploading={uploading} text={design.uploadMediaButton} />}
        />
      </AppBox>
    </FormCard>
  );
}
