import { useCallback } from 'react';
import { isIndexFound, isTruthy, isLength } from '../../../../../../../../../lib/util/helpers';
import { priceScoringSort } from '../../../../../utils/end-user-flow-helpers';

export function useNormalUpdateProductsSelection({ products, productId, categoryName, customUnselectedProductIds, scoringResult }) {
  return useCallback(
    customValue => {
      const normalUpdatedProductSelection = products?.map(card => {
        const productNameSection = card?.productNameSection;
        const iteratedProductId = productNameSection?.product?.value;
        const isCurrentProduct = iteratedProductId === productId;
        const unselectedProductIndex = customUnselectedProductIds.indexOf(card.id);
        const shouldBeUnselected = isIndexFound(unselectedProductIndex);
        const selected = getSelected({ card, customValue, isCurrentProduct, shouldBeUnselected, productNameSection, categoryName });
        return { ...card, selected };
      });

      const ruleProductSelectionProds = getDependenceRuleProducts(normalUpdatedProductSelection);

      return normalUpdatedProductSelection.map(card => {
        const ruleProdCard = ruleProductSelectionProds.find(prod => prod.id === card.id);
        const { isParent, isChild } = ruleProdCard;
        const productNameSection = card?.productNameSection;
        const iteratedProductId = productNameSection?.product?.value;
        const isCurrentProduct = iteratedProductId === productId;
        const parents = ruleProductSelectionProds.filter(prod => prod.isParent);
        const children = ruleProductSelectionProds.filter(prod => prod.isChild);
        const parentsValueIds = new Set(parents.map(prod => prod?.productNameSection?.product?.value));
        const parentsScore = scoringResult.filter(scoreObj => parentsValueIds.has(scoreObj.id));
        const sortedParentsScore = parentsScore[0]?.price
          ? parentsScore.sort((cur, next) => priceScoringSort(cur, next))
          : parentsScore.sort((cur, next) => next.score - cur.score);

        const isChildSelected = children.some(prod => prod.selected);
        const isParentSelected = parents.some(prod => prod.selected);
        const isParentClick = parentsValueIds.has(productId);
        const isBestScoreParent = sortedParentsScore[0]?.id === iteratedProductId;

        const dependenceSelected = getProductDependenceSelected({
          card,
          isParentSelected,
          isChildSelected,
          isParent,
          isChild,
          isCurrentProduct,
          isBestScoreParent,
          isParentClick,
        });
        const selected = isParent || isChild ? dependenceSelected : card?.selected;
        return { ...card, selected };
      });
    },

    [products, productId, customUnselectedProductIds, categoryName, scoringResult]
  );
}

function getSelected({ card, customValue, isCurrentProduct, shouldBeUnselected, productNameSection, categoryName }) {
  let selected = card?.selected;

  switch (true) {
    case shouldBeUnselected:
      selected = false;
      break;
    case isCurrentProduct && isTruthy(customValue):
      selected = customValue;
      break;
    case productNameSection?.categoryName === categoryName: {
      if (productNameSection?.multiProduct) {
        selected = isCurrentProduct ? !card?.selected : card?.selected;
      } else {
        selected = isCurrentProduct && card?.selected ? false : isCurrentProduct;
      }
      break;
    }
    default:
      break;
  }

  return selected;
}

function getProductDependenceSelected({
  card,
  isParentSelected,
  isChildSelected,
  isParent,
  isChild,
  isCurrentProduct,
  isBestScoreParent,
  isParentClick,
}) {
  if (isParent && isChildSelected && !card?.selected && !isCurrentProduct && !isParentSelected && isBestScoreParent && !isParentClick) {
    return true;
  }

  if (isChild && !isParentSelected && card?.selected && !isCurrentProduct) {
    return false;
  }

  return card?.selected;
}

export const getDependenceRuleProducts = prods =>
  prods.map(card => {
    const { ruleChildFor, ruleParentFor } = card;
    const childCards = prods.filter(prod => ruleChildFor?.includes(prod.id));
    const parentCards = prods.filter(prod => ruleParentFor?.includes(prod.id));
    const isParent = isLength(parentCards);
    const isChild = isLength(childCards);

    return { ...card, isChild, isParent };
  });
