import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadFinderListRequest, removeFinderArranges } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { insertAtIndex, isOdd, normalizePageForRequest, secToMs } from '../../../../../../../../../../lib/util/helpers';
import { checkIsManager, checkIsUser } from '../../../../../../../../../../lib/models/Role';
import { useFinderActionsColumn } from './columns/actions';
import { useFinderCommentColumn } from './columns/comment';
import { useFinderNameColumn } from './columns/name';
import { AppTable } from '../../../../../../../../../../ui/AppTable';
import { useFinderCreatorColumn } from './columns/creator';
import { useFinderUrlColumn } from './columns/url';
import { useCreationDateColumn } from './columns/creation-date';
import { usePublishedDateColumn } from './columns/published-date';
import { useLastEditedDateColumn } from './columns/last-edited-date';
import { useFinderLastEditedBy } from './columns/last-edited-by';
import { useExpandColumn } from '../../../../../../components/columns/expand';
import { TableColumn } from '../../../../../../../../models/Table';
import { useFinderViewsColumn } from './columns/views';
import { useFinderNumberOfQuestionsColumn } from './columns/number-of-questions';
import { useFinderStatusColumn } from './columns/status';
import { finderEditModeColumn } from './columns/edit-mode';
import { useInterval } from '../../../../../../../../../../lib/hooks/useInterrval';
import { usePermissions } from 'src/lib/hooks/usePermissions';

const COLUMN_INSERT_INDEX = 1;

export function FindersTable() {
  const dispatch = useDispatch();
  const { isRestricted } = usePermissions();
  const { role, tenantId } = useSelector(({ auth }) => auth);
  const { finders: dataSource, loading, pagination, tenant } = useSelector(({ finders }) => finders);
  const { id } = useParams();
  const [areColumnsExpanded, setAreColumnsExpanded] = useState(false);

  const checkTenantIdForAdmins = tenant.id && tenant.id === Number(id);
  const checkTenantIdForUser = checkIsUser(role) && tenantId;

  const loadFinderList = () => dispatch(loadFinderListRequest());

  useEffect(() => {
    dispatch(removeFinderArranges());

    if (checkTenantIdForAdmins || checkTenantIdForUser) {
      loadFinderList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval({ callback: loadFinderList, intervalInMs: secToMs({ sec: 30 }) });

  const actions = useFinderActionsColumn();

  const visibleColumns = [
    useFinderNameColumn(),
    useExpandColumn({ areColumnsExpanded, setAreColumnsExpanded }),
    useFinderNumberOfQuestionsColumn(),
    finderEditModeColumn,
    useLastEditedDateColumn(),
    useFinderLastEditedBy(),
    useFinderStatusColumn(),
    usePublishedDateColumn(),
    useFinderCommentColumn(),
    useFinderUrlColumn(),
  ];

  if (!isRestricted()) {
    visibleColumns.push(actions);
  }

  const hiddenColumns = [useCreationDateColumn(), useFinderCreatorColumn(), useFinderViewsColumn()];
  const allColumns = insertAtIndex(visibleColumns, hiddenColumns, COLUMN_INSERT_INDEX);
  const displayedColumns = areColumnsExpanded ? allColumns : visibleColumns;
  const filteredColumns = checkIsManager(role) ? displayedColumns : displayedColumns.filter(column => column.dataIndex !== TableColumn.ACTIONS);

  return (
    <AppTable
      columns={filteredColumns}
      rowKey='id'
      dataSource={dataSource}
      pagination={pagination}
      loading={loading}
      scroll={{ x: 'max-content' }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      onChange={tablePagination => dispatch(loadFinderListRequest({ params: { page: normalizePageForRequest(tablePagination.current) } }))}
    />
  );
}
