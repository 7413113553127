import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  themes: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  error: null,
  selectedTheme: {},
  isModalLoading: false,
  modalType: '',
  isModalOpen: false,
  fonts: [],
  fontUploading: false,
};

const themesSlice = createSlice({
  name: 'themes',
  initialState,
  reducers: {
    getThemesRequest: state => {
      state.error = null;
      state.loading = true;
      state.themes = [];
    },
    getThemesSuccess: (state, action) => {
      state.themes = action.payload.themes;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getThemesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setThemeStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setThemeStatusSuccess: state => {
      state.loading = false;
    },
    setThemeStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openThemeModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedTheme = action.payload.selectedTheme || {};
    },
    closeThemeModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedTheme = {};
    },
    addThemeRequest: state => {
      state.error = null;
      state.loading = true;
    },
    addThemeSuccess: (state, action) => {
      state.selectedTheme = action.payload.selectedTheme;
      state.loading = false;
    },
    addThemeError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    editThemeRequest: state => {
      state.error = null;
      state.loading = true;
    },
    editThemeSuccess: state => {
      state.loading = false;
    },
    editThemeError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteThemeRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteThemeSuccess: state => {
      state.isModalLoading = false;
    },
    deleteThemeError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    duplicateThemeRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    duplicateThemeSuccess: state => {
      state.isModalLoading = false;
    },
    duplicateThemeError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    selectTheme: (state, action) => {
      state.selectedTheme = action.payload;
    },
    cleanTheme: state => {
      state.selectedTheme = {};
    },
    getFontsRequest: state => {
      state.error = null;
      state.loading = true;
    },
    getFontsSuccess: (state, action) => {
      state.fonts = action.payload;
      state.loading = false;
    },
    getFontsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    uploadFontRequest: state => {
      state.error = null;
      state.fontUploading = true;
    },
    uploadFontSuccess: state => {
      state.fontUploading = false;
    },
    uploadFontError: (state, action) => {
      state.error = action.payload;
      state.fontUploading = false;
    },

    /* arranges */
    // apply arranges
    applyThemeArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyThemeArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeThemeFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeThemeFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeThemeArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getThemesRequest,
  getThemesSuccess,
  getThemesError,
  setThemeStatusRequest,
  setThemeStatusSuccess,
  setThemeStatusError,
  openThemeModal,
  closeThemeModal,
  addThemeRequest,
  addThemeSuccess,
  addThemeError,
  editThemeRequest,
  editThemeSuccess,
  editThemeError,
  deleteThemeRequest,
  deleteThemeSuccess,
  deleteThemeError,
  duplicateThemeRequest,
  duplicateThemeSuccess,
  duplicateThemeError,
  selectTheme,
  cleanTheme,
  uploadFontRequest,
  uploadFontSuccess,
  uploadFontError,
  getFontsRequest,
  getFontsSuccess,
  getFontsError,
  applyThemeArrangesRequest,
  applyThemeArrangesError,
  removeThemeFiltersRequest,
  removeThemeFiltersError,
  removeThemeArranges,
} = themesSlice.actions;
export const themes = themesSlice.reducer;
