import { AppDivider } from '../../../../../ui/AppDivider';
import { FilterBlockWrapper } from './FilterBlockWrapper';
import { SortBlock } from './SortBlock';
import { ArrangePopupWrapper } from './ArrangePopupWrapper';

export function ArrangeSortAndFilterPopupWrapper({ children }) {
  return (
    <ArrangePopupWrapper
      sort={<SortBlock />}
      filter={
        <>
          <AppDivider style={{ margin: '14px 0 16px' }} />
          <FilterBlockWrapper>{children}</FilterBlockWrapper>
        </>
      }
    />
  );
}
