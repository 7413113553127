export function LeftReturnArrow() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2977_1781)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.4999 1.5C14.6325 1.5 14.7597 1.55268 14.8535 1.64645C14.9472 1.74021 14.9999 1.86739 14.9999 2V6.8C14.9999 7.1283 14.9353 7.45339 14.8096 7.75671C14.684 8.06002 14.4998 8.33562 14.2677 8.56777C14.0355 8.79991 13.7599 8.98406 13.4566 9.1097C13.1533 9.23534 12.8282 9.3 12.4999 9.3H2.70692L6.05392 12.646C6.1478 12.7399 6.20055 12.8672 6.20055 13C6.20055 13.1328 6.1478 13.2601 6.05392 13.354C5.96003 13.4479 5.83269 13.5006 5.69992 13.5006C5.56714 13.5006 5.4398 13.4479 5.34592 13.354L1.14592 9.154C1.09935 9.10755 1.06241 9.05238 1.0372 8.99163C1.012 8.93089 0.999023 8.86577 0.999023 8.8C0.999023 8.73423 1.012 8.66911 1.0372 8.60837C1.06241 8.54762 1.09935 8.49245 1.14592 8.446L5.14592 4.446C5.2398 4.35211 5.36714 4.29937 5.49992 4.29937C5.63269 4.29937 5.76003 4.35211 5.85392 4.446C5.9478 4.53989 6.00055 4.66722 6.00055 4.8C6.00055 4.93278 5.9478 5.06011 5.85392 5.154L2.70692 8.3H12.4999C12.8977 8.3 13.2793 8.14196 13.5606 7.86066C13.8419 7.57936 13.9999 7.19782 13.9999 6.8V2C13.9999 1.86739 14.0526 1.74021 14.1464 1.64645C14.2401 1.55268 14.3673 1.5 14.4999 1.5Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2977_1781'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
