import { AppTitle } from '../../../../../../../../../../ui/text/AppTitle';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { TextWithHighlighting } from '../../../../../../../../../../ui/TextWithHighlighting';
import styles from './styles.module.scss';

const HamburgerVBKontofinderFKRESULTID = '6fc545b9-69ae-4ef8-8960-f8d05c35e14e';
const HamburgerVBKontofinderPKRESULTID = 'f1c00fb7-36ed-4375-993d-db1e6e00666e';
const RheingauerVBKontofinderFKRESULTID = '91793bf9-0029-4406-a7c3-c35537e80d61';

export function PreviewFormAside({ resultId }) {
  const { aside } = useLocalization().translations.unique.components.clientForm;

  // TODO ------------  temporary code --------------------
  const getLink = () => {
    if (resultId === HamburgerVBKontofinderFKRESULTID || resultId === HamburgerVBKontofinderPKRESULTID) {
      return 'https://www.hamburger-volksbank.de/service/rechtliche-hinweise/datenschutzhinweis-zur-website.html';
    }
    if (resultId === RheingauerVBKontofinderFKRESULTID) {
      return 'https://www.rheingauer-volksbank.de/service/rechtliche-hinweise/datenschutzhinweis-zur-website.html';
    }
    return null;
  };
  //----------------------------------------------------------------

  return (
    <div className={styles.wrapper}>
      <AppTitle
        level={3}
        textValue={aside.title}
        style={{
          color: '#002D67',
          fontSize: '20px',
        }}
      />

      <TextWithHighlighting
        textArray={aside.description}
        style={{ marginTop: '16px' }}
        textProps={{ style: { color: '#444444', lineHeight: 1.43 } }}
        highlightedProps={{
          style: { color: '#FF6600' },
          as: 'a',
          target: '_blank',
          href: getLink(),
        }}
      />
    </div>
  );
}
