import { useCallback } from 'react';
import { ProductCardId, ProductId } from '../../../models/Hardcode';
import { isIndexFound, isTruthy } from '../../../../../../../../../lib/util/helpers';

export function useOnSelectHamburgerBusinessFkP1({ updateProductsStructure, products, card, customUnselectedProductIds = [], productObject }) {
  return useCallback(
    customValue => {
      const updatedProducts = updateProductsSelection({ products, card, customUnselectedProductIds, customValue });
      const selectedProducts = updatedProducts.filter(prod => prod.selected);
      const idsForUnselect = card.id === ProductCardId.HAMBURGER_FK_P1_BUSINESS ? products.map(prod => prod.id) : [card.id];
      const unselectedProductsIds = card.selected ? idsForUnselect : [];
      updateProductsStructure(updatedProducts, selectedProducts, true, productObject, unselectedProductsIds);
    },
    [products, card, customUnselectedProductIds, updateProductsStructure, productObject]
  );
}

function updateProductsSelection({ products, card, customUnselectedProductIds, customValue }) {
  const activeProductValue = card?.productNameSection?.product?.value;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return products.map(iteratedCard => {
    const iteratedProductId = iteratedCard?.productNameSection?.product?.value;
    const unselectedProductIndex = customUnselectedProductIds.indexOf(card?.id);
    const shouldBeUnselected = isIndexFound(unselectedProductIndex);

    if (iteratedProductId === activeProductValue && isTruthy(customValue)) {
      return { ...iteratedCard, selected: customValue };
    }

    if (shouldBeUnselected || (activeProductValue === ProductId.HAMBURGER_BUSINESS && card.selected)) {
      return { ...iteratedCard, selected: false };
    }

    if (activeProductValue === ProductId.HAMBURGER_BUSINESS && !card.selected) {
      return ProductId.HAMBURGER_BUSINESS === iteratedCard.productNameSection?.product?.value ? { ...iteratedCard, selected: true } : iteratedCard;
    }

    if (activeProductValue !== ProductId.HAMBURGER_BUSINESS && card.selected) {
      return activeProductValue === iteratedCard?.productNameSection?.product?.value ? { ...iteratedCard, selected: false } : iteratedCard;
    }

    if (activeProductValue !== ProductId.HAMBURGER_BUSINESS && !card.selected) {
      return [ProductId.HAMBURGER_BUSINESS, activeProductValue].includes(iteratedCard?.productNameSection?.product?.value)
        ? { ...iteratedCard, selected: true }
        : { ...iteratedCard, selected: false };
    }

    return iteratedCard;
  });
}
