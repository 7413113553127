export function UploadIcon({ styles = '' }) {
  return (
    <svg className={styles} width='16' height='16' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 9.89844C0.632608 9.89844 0.759785 9.95112 0.853553 10.0449C0.947322 10.1387 1 10.2658 1 10.3984V12.8984C1 13.1637 1.10536 13.418 1.29289 13.6055C1.48043 13.7931 1.73478 13.8984 2 13.8984H14C14.2652 13.8984 14.5196 13.7931 14.7071 13.6055C14.8946 13.418 15 13.1637 15 12.8984V10.3984C15 10.2658 15.0527 10.1387 15.1464 10.0449C15.2402 9.95112 15.3674 9.89844 15.5 9.89844C15.6326 9.89844 15.7598 9.95112 15.8536 10.0449C15.9473 10.1387 16 10.2658 16 10.3984V12.8984C16 13.4289 15.7893 13.9376 15.4142 14.3127C15.0391 14.6877 14.5304 14.8984 14 14.8984H2C1.46957 14.8984 0.960859 14.6877 0.585786 14.3127C0.210714 13.9376 0 13.4289 0 12.8984V10.3984C0 10.2658 0.0526784 10.1387 0.146447 10.0449C0.240215 9.95112 0.367392 9.89844 0.5 9.89844Z'
        fill='currentColor'
      />
      <path
        d='M7.64663 1.14689C7.69308 1.10033 7.74825 1.06339 7.809 1.03818C7.86974 1.01297 7.93486 1 8.00063 1C8.0664 1 8.13152 1.01297 8.19226 1.03818C8.25301 1.06339 8.30819 1.10033 8.35463 1.14689L11.3546 4.14689C11.4485 4.24078 11.5013 4.36812 11.5013 4.50089C11.5013 4.63367 11.4485 4.76101 11.3546 4.85489C11.2607 4.94878 11.1334 5.00153 11.0006 5.00153C10.8679 5.00153 10.7405 4.94878 10.6466 4.85489L8.50063 2.70789V11.5009C8.50063 11.6335 8.44795 11.7607 8.35419 11.8544C8.26042 11.9482 8.13324 12.0009 8.00063 12.0009C7.86802 12.0009 7.74085 11.9482 7.64708 11.8544C7.55331 11.7607 7.50063 11.6335 7.50063 11.5009V2.70789L5.35463 4.85489C5.30814 4.90138 5.25295 4.93826 5.19222 4.96342C5.13148 4.98858 5.06638 5.00153 5.00063 5.00153C4.93489 5.00153 4.86979 4.98858 4.80905 4.96342C4.74831 4.93826 4.69312 4.90138 4.64663 4.85489C4.60014 4.80841 4.56327 4.75322 4.53811 4.69248C4.51295 4.63174 4.5 4.56664 4.5 4.50089C4.5 4.43515 4.51295 4.37005 4.53811 4.30931C4.56327 4.24857 4.60014 4.19338 4.64663 4.14689L7.64663 1.14689Z'
        fill='currentColor'
      />
    </svg>
  );
}
