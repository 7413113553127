import { useDispatch, useSelector } from 'react-redux';
import { DeleteLayoutModal } from './DeleteLayoutModal';
import { LayoutModal } from '../../../../../../lib/models/Modal';
import { closeLayoutModal } from '../../../../../../../../lib/store/slices/layoutsSlice';

/**
 * @desc Layouts page
 * @returns {JSX.Element}
 */
export function LayoutModals() {
  const { isModalOpen, modalType: currentModalType, currentItem, isModalLoading } = useSelector(({ layouts }) => layouts);
  const dispatch = useDispatch();

  const close = () => dispatch(closeLayoutModal());
  const checkIsOpen = modalType => isModalOpen && modalType === currentModalType;

  const genericProps = { currentItem, close, isModalLoading };

  return <DeleteLayoutModal {...genericProps} isOpen={checkIsOpen(LayoutModal.DELETE)} />;
}
