import { ProductLayoutProductListWrapper } from '../../components/ProductLayoutProductListWrapper';
import { ProductLayoutCard } from '../../components/ProductLayoutCard';
import styled from 'styled-components';

export function ProductListWithoutCounter({ list, allData, bulletPointIconSvg, bulletPointIconColor, bulletPointIconUrl, ...rest }) {
  return (
    <Root {...rest}>
      {list.map(item => (
        <ProductLayoutCard
          key={item.id}
          data={item.data}
          allData={allData}
          bulletPointIconSvg={bulletPointIconSvg}
          bulletPointIconUrl={bulletPointIconUrl}
          bulletPointIconColor={bulletPointIconColor}
        />
      ))}
    </Root>
  );
}

const Root = styled(ProductLayoutProductListWrapper)({
  height: 'unset',
});
