import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { PlusIcon } from '../../../../../../../../../../../../ui/icons/PlusIcon';
import { MinusIcon } from '../../../../../../../../../../../../ui/icons/MinusIcon';
import { InputSlider } from '../InputSlider';
import { CounterInput } from '../CounterInput';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { getCurrentPageNextElementId, getQuestionCounterRangeFlow } from '../../../../../../../../utils/end-user-flow-helpers';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { isLength } from '../../../../../../../../../../../../lib/util/helpers';
import { getNextElementsIdsOnPage } from '../../../../../../../PreviewPage/hooks/setNextFlow';
import { InfoIconComponent } from '../../../../../../../PreviewPage/components/InfoIconComponent';
import './style.scss';

/**
 * @desc Layout Number Input Section
 * @param {Object} answer
 * @param {Array} answers
 * @param {String} answerFieldName
 * @param {Object} textStyle
 * @param {String} primaryColor
 * @param {Object} element
 * @param {Function} setPagesFlow
 * @param {Object} pagesFlow
 * @param {Boolean} disabled
 * @param {Boolean} slider
 * @param {Object} flow
 * @param {Array} currentPageElements
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function LayoutNumberInputSection({
  answer,
  answers,
  answerFieldName,
  textStyle,
  primaryColor,
  element,
  setPagesFlow,
  pagesFlow,
  disabled,
  slider,
  flow,
  currentPageElements,
}) {
  const { applyVariable } = useVariables(pagesFlow);

  const { body } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const updatedAnswers = (answerArr, amountValue) =>
    answerArr.map(answ => {
      return answ.id === answer.id ? { ...answ, amount: amountValue, lastModified: true } : { ...answ, lastModified: false };
    });

  const updatedElements = (elementsArr, elementId, value) =>
    elementsArr.map(el => {
      return el.id === elementId ? { ...el, content: { ...el.content, [answerFieldName]: updatedAnswers(answers, value) } } : el;
    });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChange = value => {
    let numbered = Math.round(Number(value));

    if (numbered < answer.minValue) {
      numbered = answer.minValue;
    } else if (numbered > answer.maxValue) {
      numbered = answer.maxValue;
    }

    const setFlowAndValue = (setFlow, flowObject, nextElementId, nextElementIds, flowOnCurrentPage) => {
      setFlow(prev => {
        const updatedPages = prev?.allPages?.map(page => {
          return page.id === prev?.currentPageId ? { ...page, elements: updatedElements(page.elements, element.id, numbered) } : page;
        });
        const currentElementsIds = prev?.currentElementsIds;
        const currentElementIndex = currentElementsIds.indexOf(element.id);
        const filteredCurrentElementsIds = flowOnCurrentPage
          ? currentElementsIds.filter((id, index) => index <= currentElementIndex)
          : currentElementsIds;
        const updatedCurrentElementsIds = isLength(nextElementIds)
          ? [...filteredCurrentElementsIds, ...nextElementIds]
          : [...filteredCurrentElementsIds];

        return {
          ...prev,
          allPages: updatedPages,
          currentElementsIds: updatedCurrentElementsIds,
          currentFlowElement: nextElementId ? currentPageElements.find(el => el.id === flowObject?.goTo) : element,
        };
      });
    };

    if (numbered >= answer.minValue && numbered <= answer.maxValue) {
      if (answerFieldName === 'counterAnswers') {
        const newAnswers = updatedAnswers(answers, numbered);
        const rangeFlow = getQuestionCounterRangeFlow(flow, newAnswers, answer.id);
        const updatedQuestionFlow = rangeFlow?.goTo ? rangeFlow : flow;
        const nextElementId = getCurrentPageNextElementId(updatedQuestionFlow?.goTo, currentPageElements);
        const { nextElementIds, flowOnCurrentPage } = getNextElementsIdsOnPage(updatedQuestionFlow, currentPageElements);

        setFlowAndValue(setPagesFlow, updatedQuestionFlow, nextElementId, nextElementIds, flowOnCurrentPage);
      }

      if (answerFieldName === 'chartAnswers') {
        const nextElementId = getCurrentPageNextElementId(flow.goTo, currentPageElements);
        const { nextElementIds, flowOnCurrentPage } = getNextElementsIdsOnPage(flow, currentPageElements);

        setFlowAndValue(setPagesFlow, flow, nextElementId, nextElementIds, flowOnCurrentPage);
      }
    }
  };

  const addNumber = () => {
    if (!disabled) {
      handleChange(answer.amount + element.content.step);
    }
  };

  const subtractNumber = () => {
    if (!disabled) {
      handleChange(answer.amount - element.content.step);
    }
  };

  const getFormattedValue = (value, specialMaxValueText = '>') => {
    const formattedValue = answer.maxValueText && value === answer.maxValue ? `${specialMaxValueText} ${value}` : ` ${value}`;

    if (element?.content?.showCurrencySymbol && element?.content?.currencySymbol) {
      return `${formattedValue} ${element.content.currencySymbol}`;
    }
    return formattedValue;
  };

  return (
    <div className='layout_number_wrap'>
      <AppText style={textStyle} className='layout_number_answer'>
        {applyVariable(disabled ? answer.value || body() : answer.value)}
        {answer.value && <InfoIconComponent applyVariable={applyVariable} type={answer} />}
      </AppText>

      <div className='layout_number_input_block'>
        {!slider && <MinusIcon className='layout_number_icon' fill={primaryColor} onClick={subtractNumber} />}
        <CounterInput
          primaryColor={primaryColor}
          textStyle={textStyle}
          className='layout_number_input'
          answer={answer}
          handleChange={handleChange}
          showCurrencySymbol={element?.content?.showCurrencySymbol}
          currencySymbol={element?.content?.currencySymbol}
          disabled={disabled}
        />
        {!slider && <PlusIcon className='layout_number_icon' fill={primaryColor} onClick={addNumber} />}
      </div>
      {slider && (
        <InputSlider
          textStyle={textStyle}
          answer={answer}
          onChange={handleChange}
          getFormattedValue={getFormattedValue}
          primaryColor={primaryColor}
          disabled={disabled}
        />
      )}
    </div>
  );
}
