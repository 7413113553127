import { ConfigProvider } from 'antd';
import { theme } from '../../../lib/styles/theme';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import { en_validateMessages } from '../../../../lib/localization/EN/validation';

/**
 * @typedef { import('../../../../lib/localization/i18n-types').Locales } Locales
 */

/** @param { Locales } locale */
function getValidateMessages(locale) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (locale) {
    case 'EN':
      return en_validateMessages;
    default:
      throw new Error('Invalid locale');
  }
}

export function AppAntdConfigProvider({ children }) {
  const { locale } = useLocalization();

  return (
    <ConfigProvider theme={theme} form={{ validateMessages: getValidateMessages(locale) }}>
      {children}
    </ConfigProvider>
  );
}
