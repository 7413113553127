import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../utils/hooks/useArrangeSubmit';
import { useLayoutArrangeRequests } from '../../lib/useLayoutArrangeRequests';
import { useStringArrange } from '../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { ArrangeListPopup } from '../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../components/TableHeaderCell';
import { LAYOUT_CREATOR_ID } from './layout-creator-utils';
import { User } from '../../../../../UsersPage/components/UsersTable/lib/User';
import { getUsersByLoginFromApi } from '../../../../../../utils/api';

export function LayoutCreatorHeaderCell() {
  const { creator: title } = useLocalization().translations.shared.columns;
  const { activeArrangedId, sorting } = useSelector(({ layouts }) => layouts);
  const isActiveArrange = activeArrangedId === LAYOUT_CREATOR_ID;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const { filtering } = useStringArrange({
    form,
    request: getUsersByLoginFromApi,
    formatResponse: data => formatArrangedData(data, User.USERNAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useLayoutArrangeRequests({
    arrangedId: LAYOUT_CREATOR_ID,
    sortFieldName: LAYOUT_CREATOR_ID,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ includeCreatedBy: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
