import { useSelector } from 'react-redux';
import { RangeMinMaxRow } from '../../../../../../../../../features/RangeMinMaxRow';
import { SelectMultiGoToFormItem } from '../../../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { updateRange } from '../../../../../../../utils/helpers';

export function CounterFlowRangeItem({
  rangeIndex,
  elementId,
  rangeObject,
  updateRangeObject,
  updateRangeObjectFields,
  deleteRange,
  disabled,
  counterAnswersRanges,
}) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const rangeHandleChange = (value, fieldName) => {
    const targetValue = Number(value);

    updateRange(targetValue, fieldName, rangeObject.id, counterAnswersRanges, updateRangeObject);
  };

  const flowDestinationChange = values => {
    updateRangeObjectFields(rangeObject.id, values);
  };

  return (
    <div>
      <RangeMinMaxRow rangeIndex={rangeIndex} rangeObject={rangeObject} deleteRange={deleteRange} onChange={rangeHandleChange} disabled={disabled} />
      <div className='product_range_saving_input_wrap'>
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={rangeObject}
          options={questionnaireStructure}
          onChange={flowDestinationChange}
          disabled={disabled}
          goToFieldName='thenGoTo'
          showElementsFieldName='thenGoToShowElements'
        />
      </div>
    </div>
  );
}
