import { useProductSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';

export function useProductHeadlineColumn() {
  const { headline: title } = useLocalization().translations.unique.pages.products.table.columns;

  return useProductSearchDataColumn({
    title,
    arrangedId: Product.HEADLINE,
    searchFieldName: 'searchByHeadline',
    filterFieldName: 'equalByHeadline',
    columnsProps: {
      width: 270,
    },
  });
}
