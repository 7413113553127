import { Navigate, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { ROUTER_PATHS } from '../../lib/util/router/router-paths';
import { NOT_FOUND_ROUTE_PATH } from '../../lib/util/router/router-consts';
import { EndUserFinder } from './EndUserFinder';

export function ClientRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={ROUTER_PATHS.home} element={<Navigate to={ROUTER_PATHS.finder} />} />
        <Route path={ROUTER_PATHS.finder} element={<EndUserFinder />} />
        <Route path={NOT_FOUND_ROUTE_PATH} element={<Navigate to={ROUTER_PATHS.finder} />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
}
