export const Category = {
  NAME: 'name',
  ID: 'id',
  PRODUCTS: 'products',
  IS_MULTI_PRODUCT: 'multiProduct',
};

export const CategorySelection = {
  SINGE_SELECTION: false,
  MULTIPLE_SELECTION: true,
};
