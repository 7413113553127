import { rulesEngine } from './rules_helper';
import { updateProductSelection } from './product_selection_rules_helper';
import { updatePagesRulesProducts } from '../end-user-flow-helpers';
import { isLength } from '../../../../../../lib/util/helpers';

export const productSelectionRulesEngine = (
  rules,
  pagesFlow,
  setPagesFlow,
  currentElements,
  allowRulesProductSelection,
  setAllowRulesProductSelection
) => {
  const { selectProduct } = rulesEngine({ rules, pagesFlow, currentElements, rulesFilter: ['selectProduct'] });

  if (isLength(selectProduct)) {
    const positiveSelectProduct = selectProduct.filter(cond => {
      const ruleCondition = allowRulesProductSelection.find(ruleProdItem => ruleProdItem.conditionId === cond.selectProduct.condition.id);
      const isConditionAllow = !ruleCondition || !ruleCondition?.completed;
      return cond.conditionValue && isConditionAllow;
    });

    if (isLength(positiveSelectProduct)) {
      setPagesFlow(prev => {
        const { rulesChapters, rulesSelectedProducts } = updateProductSelection(positiveSelectProduct, prev.allChapters, pagesFlow.allPages);
        const updatedAllPages = updatePagesRulesProducts(prev.allPages, rulesSelectedProducts, false);

        return {
          ...prev,
          allChapters: rulesChapters,
          allPages: updatedAllPages,
        };
      });
    }

    const newConditionsArray = selectProduct.map(ruleItem => {
      return { conditionId: ruleItem.selectProduct.condition.id, completed: ruleItem.conditionValue };
    });
    const newConditionsIds = new Set(newConditionsArray.map(condItem => condItem.conditionId));

    const oldConditions = allowRulesProductSelection.filter(condObject => newConditionsIds.has(condObject.conditionId));
    const filteredConditions = allowRulesProductSelection.filter(condObject => !newConditionsIds.has(condObject.conditionId));

    const isNotUpdateProductAllowSelection = newConditionsArray.every(newCondItem => {
      const oldCondition = oldConditions.find(oldItem => oldItem.conditionId === newCondItem.conditionId);
      return newCondItem.completed === oldCondition?.completed;
    });

    if (!isNotUpdateProductAllowSelection) {
      setAllowRulesProductSelection([...filteredConditions, ...newConditionsArray]);
    }
  }
};
