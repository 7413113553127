export const FIRST_INDEX = 0;
export const EMPTY_LENGTH = 0;

export const NEXT = +1;
export const PREV = -1;

export const NO_FOUND_INDEX = -1;

export const DEFAULT_PAGINATION = {
  current: undefined,
  pageSize: undefined,
  total: undefined,
};

export const FIRST_REQUEST_PAGE = 0;

export const EMPTY_OBJ = {};

export const ACCESS_TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';

export const SINGLE_ITEM_DELETING_INDEXES_AMOUNT = 1;
