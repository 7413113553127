import { ColorVariants } from '../../../../../lib/models/DefaultStylesFontsAndColors';
import { Align } from '../../../pages/QuestionnairePage/components/QuestionnaireContent/ContentTableSection/comparison-table-utils';

export const ROW_KEY = 'id';

export const RowType = {
  TABLE_NAME: 'tableName',
  PRODUCTS: 'products',
  FIRST_PLAIN_NOT_FIRST_TABLE: 'firstPlainNotFirstTable',
  FIRST_PLAIN: 'firstPlain',
  LAST_PLAIN: 'lastPlain',
  TABLE_NAME_NOT_FIRST: 'tableNameNotFirst',
};

/*
 * `TABLE_INDEX` is zero because tables amount could be exact zero,
 * or because all tables have the same amount of columns. So the easiest way is use "0".
 */
export const TABLE_INDEX = 0;
export const ROW_INDEX = 0;
export const FIXED_COLUMN_INDEX = 0;
export const TABLE_TITLE_COLUMN_INDEX = 0;

// Based on designs
const PRODUCT_TRANSPARENT_ROW_INDEX = 0;

export const isTransparentRow = (rowTypes, rowIndex) =>
  rowTypes?.includes?.(RowType.TABLE_NAME) || (rowTypes?.includes?.(RowType.PRODUCTS) && rowIndex === PRODUCT_TRANSPARENT_ROW_INDEX);

const DEFAULT_BG_ODD = '#F2F2F3';
export const getBgColor = colors => colors?.[ColorVariants.BACKGROUND_COLOR] || DEFAULT_BG_ODD;

export const getAlign = align => ([Align.LEFT, Align.RIGHT].includes(align) ? align : Align.CENTER);
