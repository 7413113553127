export function QuestionnaireIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11H11.586C12.1164 11.0001 12.625 11.2109 13 11.586L15 13.586V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H2ZM14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.960859 16 1.46957 16 2V14.793C16 14.8919 15.9706 14.9886 15.9156 15.0709C15.8606 15.1531 15.7824 15.2172 15.691 15.255C15.5996 15.2928 15.499 15.3026 15.402 15.2832C15.3049 15.2638 15.2159 15.216 15.146 15.146L12.293 12.293C12.1055 12.1055 11.8512 12.0001 11.586 12H2C1.46957 12 0.960859 11.7893 0.585786 11.4142C0.210714 11.0391 0 10.5304 0 10V2C0 1.46957 0.210714 0.960859 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0L14 0Z'
        fill='white'
      />
    </svg>
  );
}
