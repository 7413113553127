import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCategoryArrangeRequests } from '../../lib/useCategoryArrangeRequests';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { ArrangeListPopup } from '../../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../../components/TableHeaderCell';
import { getFilteredCategoriesFromApi } from '../../../../../../../utils/api';

export function CategorySearchDataHeaderCell({
  arrangedId,
  title,
  searchFieldName,
  filterFieldName,
  headerCellProps: additionalHeaderCellProps,
  minSearchLength,
}) {
  const { activeArrangedId, sorting } = useSelector(({ categories }) => categories);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');
  const { id: tenantId } = useParams();

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredCategoriesFromApi(tenantId, { [searchFieldName]: value }),
    formatResponse: response => formatArrangedData(response, arrangedId),
    minSearchLength,
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useCategoryArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
        minSearchLength={minSearchLength}
      />
    </TableHeaderCell>
  );
}
