import styles from './ImageMultiSelect.module.scss';

export function ImagesTitle({ imageList }) {
  const formatText = () => {
    const maxTextLength = 45;
    let checkedText = '';
    let finalText = '';
    let overflowedCount = 0;

    for (let i = 0; i < imageList.length; i += 1) {
      checkedText += `${imageList[i]}, `;

      if (checkedText.length > maxTextLength) {
        overflowedCount = imageList.length - i;

        if (overflowedCount === imageList.length) {
          finalText = imageList[i];
          overflowedCount = imageList.length - 1;
        }

        break;
      } else {
        finalText = checkedText;
      }
    }

    return (
      <>
        {finalText} {overflowedCount > 0 && <span className={styles.overflowedTitlesCount}>+{overflowedCount}</span>}
      </>
    );
  };

  return <span>{imageList.length === 1 ? imageList[0] : formatText()}</span>;
}
