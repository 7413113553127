import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation, humanizeIndex } from '../../../../../../../../../../../lib/util/helpers';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { SquareIcon } from '../../../../../../../../../../../ui/icons/SquareIcon';
import { CircleIcon } from '../../../../../../../../../../../ui/icons/CircleIcon';
import './styles.scss';
import { QuestionnaireFormSectionWithDelay } from '../../../../../features/QuestionnaireFormSectionWithDelay';

export function ContactFormRowFieldOptionTitle(props) {
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const { rowIndex, fieldIndex, optionIndex, structureItemIndex, field } = props;
  const option = field?.optionList?.[optionIndex];
  const { updateStructure } = useUpdateStructure();

  const onChange = value => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].optionList[${optionIndex}].title`, value);
    });
  };

  const prefixVariants = {
    RADIO_WITH_ACTION: <CircleIcon className='option_input_prefix_icon' />,
    RADIO: <CircleIcon className='option_input_prefix_icon' />,
    CHECKBOX: <SquareIcon className='option_input_prefix_icon' />,
  };

  return (
    <QuestionnaireFormSectionWithDelay
      className={prefixVariants[field.type] ? 'option_section_label' : 'option_section'}
      formItemProps={{ style: { minHeight: 'unset', width: '100%' } }}
      textFieldProps={{
        prefix: prefixVariants[field.type],
        value: option?.title,
        label: `${handleTranslation(contentText.option)} ${humanizeIndex(optionIndex)}`,
        onChange,
      }}
    />
  );
}
