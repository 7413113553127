import { QuestionnaireTextButton } from '../../../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { generateProductBulletPointItem } from './generateProductBulletPointItem';
import { isLength } from '../../../../../../../../../../../../../lib/util/helpers';
import lodashGet from 'lodash.get';

const MAX_BULLET_POINTS_AMOUNT = 8;

export function ProductAddBulletPointButton({ structureItemIndex, productIndex, data, dataPath, disabled, ...rest }) {
  const areBulletPointsReachedLimit = isLength(data.bulletPoints) && data.bulletPoints?.length >= MAX_BULLET_POINTS_AMOUNT;
  const { updateStructure } = useUpdateStructure();
  const { generic } = useLocalization().translations.shared;

  const add = () => {
    updateStructure(draft => {
      const bulletPoints = lodashGet(draft, dataPath);
      bulletPoints.push(generateProductBulletPointItem());
    });
  };

  return (
    <div {...rest}>
      <QuestionnaireTextButton textValue={generic.addBulletPoint} disabled={disabled || areBulletPointsReachedLimit} onClick={add} />
    </div>
  );
}
