import styled from 'styled-components';
import { components } from 'react-select';
import { CrossIcon } from '../../icons/CrossIcon';

export function SingleSelectOption(props) {
  const { isSelected, label, selectProps, data } = props;
  const showCross = selectProps.unselectable && isSelected;

  const onClick = () => {
    if (selectProps.unselectable && isSelected) {
      props.clearValue();
    } else {
      props.selectOption(data);
    }
  };

  return (
    <Root {...props} innerProps={{ onClick }}>
      <span className='SingleSelectOptionLabel'>{label}</span>
      {showCross && (
        <span className='SingleSelectOptionCross'>
          <CrossIcon />
        </span>
      )}
    </Root>
  );
}

const Root = styled(components.Option)(({ isSelected, isDisabled }) => ({
  cursor: isDisabled ? 'not-allowed !important' : 'pointer !important',
  display: 'flex !important',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '9.5px 16px',
  background: 'none !important',
  transition: 'all .2s ease-out',
  color: 'unset !important',

  '.SingleSelectOptionLabel': {
    color: isDisabled ? '#BDBABAFF' : isSelected ? '#DA1B5E' : 'initial',
  },

  '.SingleSelectOptionCross': {
    visibility: 'hidden',
  },

  '&:hover': {
    background: isDisabled || !isSelected ? 'initial' : '#fbe8ef !important',

    'svg, svg *': {
      fill: isDisabled ? '#BDBABAFF' : '#BA1750',
    },

    '.SingleSelectOptionLabel': {
      color: isDisabled ? '#BDBABAFF' : '#BA1750',
    },

    '.SingleSelectOptionCross': {
      visibility: 'visible',
    },
  },
}));
