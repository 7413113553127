import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { getLayoutsApiRequest } from '../../api';
import {
  addLayoutError,
  addLayoutRequest,
  addLayoutSuccess,
  applyLayoutsArrangesError,
  applyLayoutsArrangesRequest,
  closeLayoutModal,
  deleteLayoutError,
  deleteLayoutRequest,
  deleteLayoutSuccess,
  getLayoutListError,
  getLayoutListRequest,
  getLayoutListSuccess,
  getLayoutParentError,
  getLayoutParentRequest,
  getLayoutParentSuccess,
  removeLayoutsFiltersError,
  removeLayoutsFiltersRequest,
  reUploadLayoutError,
  reUploadLayoutRequest,
  reUploadLayoutSuccess,
  setCurrentLayout,
  setLayoutStatusError,
  setLayoutStatusRequest,
  setLayoutStatusSuccess,
} from '../slices/layoutsSlice';
import { setTitle } from '../slices/titleSlice';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, templateId, recursionLimit = 1 } = args;

  const {
    layouts: { sorting, filtering, parentItem, pagination },
  } = yield select();

  const data = { ...filtering, includeTemplateIds: [templateId || parentItem?.id] };
  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data });
  const response = yield call(getLayoutsApiRequest, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getLayoutListSuccess({ list: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* loadData({ payload }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getLayoutListError({ error }));
  }
}

function* remove({ payload: id }) {
  try {
    yield call(axiosInstance.delete, `layout/${id}/delete`);
    yield put(deleteLayoutSuccess({ success: { messageType: deleteLayoutSuccess.type } }));
    yield put(closeLayoutModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteLayoutError({ error }));
  }
}

function* setStatus({ payload: { id, status } }) {
  try {
    yield call(axiosInstance.patch, `layout/${id}/set-active?active=${status}`);
    yield put(setLayoutStatusSuccess({ success: { messageType: setLayoutStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setLayoutStatusError({ error }));
  }
}

function* reUpload({ payload: templateForm }) {
  try {
    const {
      layouts: { parentItem },
    } = yield select();

    yield call(axiosInstance.put, 'layout/', templateForm, {
      headers: { 'Content-Type': 'multipart/form-data; ' },
      params: { templateId: parentItem.id },
    });
    yield put(reUploadLayoutSuccess({ success: { messageType: reUploadLayoutSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(reUploadLayoutError({ error }));
  }
}

function* add({ payload }) {
  try {
    const {
      layouts: { parentItem },
    } = yield select();

    yield call(axiosInstance.post, 'layout/', payload, {
      headers: { 'Content-Type': 'multipart/form-data; ' },
      params: { templateId: parentItem.id },
    });
    yield put(addLayoutSuccess({ success: { messageType: addLayoutSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addLayoutError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyLayoutsArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeLayoutsFiltersError({ error }));
  }
}

function* getParentById({ payload: id }) {
  try {
    const res = yield call(axiosInstance.get, `/template/${id}`);

    if (res?.data?.name) {
      yield put(getLayoutParentSuccess(res.data));

      const {
        headerTitle: {
          headerTitle: { title },
        },
      } = yield select();

      yield put(setTitle({ title, subTitle: res.data.name }));
      yield put(setCurrentLayout({ name: res.data.name, id: res.data.id }));
    }
  } catch (error) {
    yield put(getLayoutParentError(error));
  }
}

export function* watchLayoutsManagement() {
  yield all([
    takeLatest(getLayoutListRequest.type, loadData),
    takeLatest(setLayoutStatusRequest.type, setStatus),
    takeLatest(deleteLayoutRequest.type, remove),
    takeLatest(reUploadLayoutRequest.type, reUpload),
    takeLatest(addLayoutRequest.type, add),
    takeLatest(applyLayoutsArrangesRequest.type, applyArranges),
    takeLatest(removeLayoutsFiltersRequest.type, removeFilters),
    takeLatest(getLayoutParentRequest.type, getParentById),
  ]);
}
