import {
  equalRightBorderStyles,
  errorFieldBeforeStyles,
  focusedFieldBeforeStyles,
  hoverFieldBeforeStyles,
  initialFieldBeforeStyles,
  resetFieldStyles,
} from '../../../lib/styles/common';
import { multiValueChipLabelStyles, multiValueChipStyles, multiValueChipWithoutRemoveStyles } from './AppNewSelect-styles';
import { isLength } from '../../../lib/util/helpers';

// eslint-disable-next-line max-lines-per-function
export const createStyles = ({ isError }) => ({
  menu: (baseStyles, props) => ({
    ...baseStyles,
    zIndex: 100,
    padding: props.isMulti ? '0 0 0 16px' : '0',
    border: '1px solid #5C5C6E',
    boxShadow: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',

    ...(props.selectProps.size === 'small' && {
      '&, *': {
        fontSize: '12px',
      },
    }),
  }),
  menuList: (baseStyles, props) => ({
    ...baseStyles,
    padding: props.isMulti ? '16px 16px 16px 0' : '8px 0',
    maxHeight: '250px',
  }),
  control: (baseStyles, props) => ({
    ...baseStyles,
    ...resetFieldStyles,
    pointerEvents: props.isDisabled ? 'none' : 'initial',
    cursor: 'text',
    background: props.isDisabled ? '#F2F2F3' : 'initial',
    minHeight: props.selectProps.size === 'small' ? '24px' : '40px',
    height: '100%',
    ...(props.selectProps.size === 'small' && {
      '&, *': {
        fontSize: '12px',
      },
    }),
    '&:before': {
      ...initialFieldBeforeStyles,
      ...(props.isFocused && focusedFieldBeforeStyles),
      ...(isError && errorFieldBeforeStyles),
      ...(props.selectProps.equalRightBorder && equalRightBorderStyles),
    },
    '&:hover:before': hoverFieldBeforeStyles,
  }),
  multiValue: (baseStyles, props) => ({
    ...multiValueChipStyles,
    ...(props.data.isDisabled && multiValueChipWithoutRemoveStyles),
  }),
  multiValueLabel: () => multiValueChipLabelStyles,
  multiValueRemove: () => ({
    cursor: 'pointer',
    display: 'flex',
    padding: '0px 10px 0px 7px',
    zIndex: 10,
  }),
  valueContainer: (baseStyles, props) => {
    const singleSelectWidth = props.selectProps.fullWidth ? '100%' : '85%';
    const isSmall = props.selectProps.size === 'small';
    return {
      ...baseStyles,
      margin: 0,
      maxWidth: props.isMulti ? '100%' : singleSelectWidth,
      // eslint-disable-next-line no-nested-ternary
      padding: props.isMulti ? (isSmall ? '0' : '6px 0 6px 6px') : isSmall ? '0 0 0 8px' : '6px 0 6px 16px',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: props.selectProps.smallWidth ? 'hidden' : 'visible',
      gap: '8px',
      '& > *:not(:last-child)': {
        display: !props.isMulti && isLength(props.selectProps.inputValue) ? 'none' : 'flex', // hiding all except input on search
      },
    };
  },
  input: (baseStyles, props) => ({
    paddingLeft: props.isMulti ? '10px' : 'initial',
    position: !props.isMulti && 'absolute',
    input: { cursor: props.isDisabled ? 'not-allowed' : 'text' },
    width: props.value ? '85%' : '0px',
  }),
  placeholder: (baseStyles, props) => ({ ...baseStyles, margin: 0, paddingLeft: props?.isMulti ? '10px' : 'unset' }),
  indicatorsContainer: baseStyles => ({ ...baseStyles, cursor: 'pointer', zIndex: 30 }),
  clearIndicator: baseStyles => ({ ...baseStyles, padding: 0, height: '100%', display: 'flex', alignItems: 'center' }),
  container: (baseStyles, props) => ({ ...baseStyles, pointerEvents: 'unset', cursor: props.isDisabled ? 'not-allowed' : 'initial' }),
  singleValue: baseStyles => ({
    ...baseStyles,
    marginLeft: 0,
    '& > *': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
});
