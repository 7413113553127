import { createSlice } from '@reduxjs/toolkit';
import { QuestionnaireVersion } from '../../models/Questionnaire';

const initialState = {
  questionnaireStructure: null,
  questionnaireStructureDraft: null,
  currentQuestionnaireStructure: null,
  brand: {},
  finderSettings: {},
  selectedQuestionnaireVersion: null,
  selectedQuestionnaireElement: null,
  tenantId: null,
  finderId: null,
  rules: [],
};

const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setPreviewStructure: (state, action) => {
      const { pathname } = window.location;
      const { questionnaireStructure, brand, finderSettings, selectedQuestionnaireVersion, questionnaireStructureDraft, tenantId, finderId, rules } =
        action.payload;

      if (pathname.includes(finderId)) {
        state.questionnaireStructure = questionnaireStructure;
        state.questionnaireStructureDraft = questionnaireStructureDraft;
        state.currentQuestionnaireStructure =
          selectedQuestionnaireVersion === QuestionnaireVersion.PUBLISHED ? questionnaireStructure : questionnaireStructureDraft;
        state.brand = brand;
        state.finderSettings = finderSettings;
        state.selectedQuestionnaireVersion = selectedQuestionnaireVersion;
        state.tenantId = tenantId;
        state.finderId = finderId;
        state.rules = rules;
      }
    },
    changePreviewStructureVersion: (state, action) => {
      const { pathname } = window.location;
      const { version, finderId } = action.payload;
      if (pathname.includes(finderId)) {
        state.selectedQuestionnaireVersion = version;
      }
    },
    writeNewStructure: (state, action) => {
      const { pathname } = window.location;
      const { finderId } = action.payload;
      if (pathname.includes(finderId)) {
        state.currentQuestionnaireStructure =
          state.selectedQuestionnaireVersion === QuestionnaireVersion.PUBLISHED ? state.questionnaireStructure : state.questionnaireStructureDraft;
      }
    },
    cleanPreviewStructure: (state, action) => {
      const { pathname } = window.location;
      const { finderId } = action.payload;
      if (pathname.includes(finderId)) {
        state.questionnaireStructure = null;
        state.questionnaireStructureDraft = null;
        state.currentQuestionnaireStructure = null;
        state.brand = {};
        state.finderSettings = {};
        state.selectedQuestionnaireVersion = null;
        state.selectedQuestionnaireElement = null;
        state.tenantId = null;
        state.finderId = null;
        state.rules = [];
      }
    },
    selectPreviewQuestionnaireElement: (state, action) => {
      const { pathname } = window.location;
      const { selectedQuestionnaireElement, finderId } = action.payload;
      if (pathname.includes(finderId)) {
        state.selectedQuestionnaireElement = selectedQuestionnaireElement;
      }
    },
  },
});

export const { setPreviewStructure, cleanPreviewStructure, changePreviewStructureVersion, writeNewStructure, selectPreviewQuestionnaireElement } =
  previewSlice.actions;
export const preview = previewSlice.reducer;
