import { getAdditionalInformationData } from '../utils';
import { isLength, maybe } from '../../../../../../../lib/util/helpers';
import { ProductRightBulletPointColumnType } from '../../../../../../../lib/models/Questionnaire';
import { getTimezoneOffsetInHours } from '../../../../../../../lib/models/Time';

const DEFAULT_BULLET_POINT_ICON_COLOR = '#000';
const CURRENT_SCORE_INDEX = 0;
const MAX_SCORE_INDEX = 1;

const getColor = (colorsObj, color) => colorsObj?.[color] || DEFAULT_BULLET_POINT_ICON_COLOR;

function formatScore(score) {
  return {
    current: Number(score?.selectedScore?.split('/')?.[CURRENT_SCORE_INDEX]),
    max: Number(score?.selectedScore?.split('/')?.[MAX_SCORE_INDEX]),
  };
}

function formatTableList(bulletPoints, isIcon) {
  return bulletPoints?.map(item => ({
    left: item?.left?.value,
    ...maybe(!isIcon, { right: item?.rightText?.value }),
  }));
}

function formatDetailTableBulletPoints(bulletPointsSection, colors) {
  const isIcon = bulletPointsSection?.rightColumnType === ProductRightBulletPointColumnType.ICON;

  return {
    rightColumnType: bulletPointsSection?.rightColumnType,
    iconColor: getColor(colors, bulletPointsSection?.bulletPointIconColor),
    list: formatTableList(bulletPointsSection?.bulletPoints, isIcon),
    ...maybe(isIcon, { icon: bulletPointsSection?.bulletPointIcon?.svgContent }),
  };
}

function formatDetailTableList(detailTables, colors) {
  return detailTables?.map(item => ({
    title: item?.headline?.value,
    subTitle: item?.subline?.value,
    ...maybe(item?.score?.showScore, { score: formatScore(item?.score) }),
    ...maybe(isLength(item?.bulletPointsSection?.bulletPoints), { bulletPoints: formatDetailTableBulletPoints(item?.bulletPointsSection, colors) }),
  }));
}

function formatProductCardBulletPoints(bulletPointsSection, colors) {
  return {
    list: bulletPointsSection?.bulletPoints?.map(item => item?.value),
    icon: bulletPointsSection?.bulletPointIcon?.svgContent,
    iconColor: getColor(colors, bulletPointsSection?.bulletPointIconColor),
  };
}

const areDetailTableList = item => item?.detailTableSection?.showDetailTable && isLength(item?.detailTableSection?.detailTables);

function formatProductList(products, colors) {
  return products.map(item => ({
    showPicture: item?.productNameSection?.showPicture,
    name: item?.productNameSection?.product?.title,
    subTitle: item?.productNameSection?.subline?.value,
    image: item?.image,
    bulletPoints: formatProductCardBulletPoints(item?.bulletPointsSection, colors),
    ...maybe(areDetailTableList(item), { detailTableList: formatDetailTableList(item?.detailTableSection?.detailTables, colors) }),
  }));
}

function formatFonts(getFont) {
  return {
    headlineFirst: getFont('headlineFirst'),
    headlineSecond: getFont('headlineSecond'),
    headlineThird: getFont('headlineThird'),
    headlineFourth: getFont('headlineFourth'),
    body: getFont('body'),
    link: getFont('textLink'),
  };
}

export function getDepotPdfProps({
  tenant,
  pagesFlow,
  getFont,
  headline,
  allQuestions,
  additionalInformation,
  pdfSettings,
  products,
  colors,
  pageHistory,
}) {
  return {
    tenantLogo: tenant?.logo?.cdnUrl,
    finderName: pagesFlow.finder?.name,
    finderUrl: pagesFlow.finder?.url,
    title: headline?.value,
    timezoneOffsetInHours: getTimezoneOffsetInHours(),
    additionalInfoList: getAdditionalInformationData(allQuestions, additionalInformation, pageHistory),
    fonts: formatFonts(getFont),
    colors,
    productList: formatProductList(products, colors),
    settings: {
      showDate: pdfSettings?.showDate,
      showLink: pdfSettings?.showLink,
    },
  };
}
