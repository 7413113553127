import { Category, CategorySelection } from '../../CategoriesTable/lib/Category';
import { AppSegmented } from '../../../../../../../../../../ui/AppSegmented';
import { AppFormSection } from '../../../../../../../../../../ui/AppFormSection';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function CategorySelectionFormSection() {
  const { categorySelection } = useLocalization().translations.unique.formItems;

  const options = [
    { value: CategorySelection.SINGE_SELECTION, label: categorySelection.singleSelection },
    { value: CategorySelection.MULTIPLE_SELECTION, label: categorySelection.multipleSelection },
  ].map(item => ({ ...item, label: handleTranslation(item.label) }));

  return (
    <AppFormSection formItemProps={{ rules: [{ required: true }], name: Category.IS_MULTI_PRODUCT, style: { minHeight: '46px' } }}>
      <AppSegmented options={options} />
    </AppFormSection>
  );
}
