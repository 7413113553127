import { AppInput } from 'src/ui/AppInput';
import { AppNewSelect } from 'src/ui/AppNewSelect';
import { getButtons, useUpdateButtonRule } from '../ruleButtonHelper';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { useSelector } from 'react-redux';

import '../../../../style.scss';

export function ButtonRules({ questionnaireElement }) {
  const buttons = getButtons(questionnaireElement);
  const { updateButton } = useUpdateButtonRule();

  const { buttons: buttonsText } = useLocalization().translations.shared.generic;

  const { finderRules } = useSelector(({ finders }) => finders);

  const ruleOptions = finderRules?.map(rule => ({ value: rule.ruleId, title: rule.name }));

  return (
    <div className='buttonRules'>
      {buttons.length > 0 && (
        <>
          <div className='separator' />

          <div className='title'>{buttonsText()}</div>

          {buttons.map((button, index) => (
            <div className='buttonRow' key={button.id}>
              <AppInput value={`B.${index + 1} ${button.buttonName}`} disabled />
              <div className='buttonRuleSelector'>
                <AppNewSelect
                  placeholder='select rule'
                  isClearable
                  isMultiSelect
                  value={
                    button.rules
                      ? button.rules.map(({ ruleId }) => ({
                          value: ruleId,
                        }))
                      : null
                  }
                  options={ruleOptions}
                  onChange={selection => {
                    updateButton(button, selection ? selection.map(({ value }) => ({ ruleId: value })) : selection, questionnaireElement);
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
