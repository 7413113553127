import styled from 'styled-components';
import { RowsField } from './RowsField';
import { ColumnsField } from './ColumnsField';

export function ColumnsRowsFields() {
  return (
    <Root>
      <ColumnsField />
      <RowsField />
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',

  '& > *': {
    width: '88px',
  },
});
