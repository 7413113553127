import { Tabs } from 'antd';
import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

export function AppTabs(props) {
  return <Root as={Tabs} {...props} />;
}

const Root = styled(AppBox)(({ theme }) => ({
  '.ant-tabs-nav': {
    margin: 0,
    '&:before': {
      borderBottomWidth: '1.5px',
      borderBottomColor: '#32323a',
    },
  },
  '&.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar': {
    background: theme.token.colorPrimary,
    height: '2px',
    borderRadius: '4px',
  },
  '.ant-tabs-nav-list': {
    width: '100%',
  },
  '.ant-tabs-tab': {
    flex: 1,
    justifyContent: 'center',
    // width: '96px',
  },
  '.ant-tabs-tab-active .ant-tabs-tab-btn': {
    fontWeight: 700,
  },
}));
