import { AppTooltip } from '../../../../../../../ui/AppTooltip';
import { InfoIcon } from '../../../../../../../ui/icons/InfoIcon';
import styled from 'styled-components';

const LIMIT = 500; // symbols

export function InfoIconComponent({
  type,
  applyVariable = () => {
    return '!!missing!!';
  },
  styles,
  tooltipProps = {},
  ...rest
}) {
  if (!type?.infoIcon?.value) {
    return null;
  }

  const { overlayInnerStyle, ...restTooltipProps } = tooltipProps;
  const { value } = type.infoIcon;
  const text = value.length > LIMIT ? `${value.slice(0, LIMIT)}...` : value;

  return (
    <StyledWrapper {...rest}>
      <StyledInfoIconComponent style={styles} onClick={event => event.stopPropagation()}>
        <AppTooltip text={applyVariable(text)} overlayInnerStyle={{ ...overlayInnerStyle, whiteSpace: 'pre-wrap' }} {...restTooltipProps}>
          <InfoIcon />
        </AppTooltip>
      </StyledInfoIconComponent>
    </StyledWrapper>
  );
}

const StyledWrapper = styled('span')({
  position: 'relative',
});

const StyledInfoIconComponent = styled('span')({
  position: 'absolute',
  cursor: 'pointer',
  marginLeft: 5,
  '& svg: hover': {
    fill: '#DA1B5E',
  },
});
