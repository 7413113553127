import { v4 as uuidv4 } from 'uuid';
import { ContactFormAction, ContactFormField, withActionContactFormFields } from '../../../../../../../../../../../lib/models/Questionnaire';
import { alwaysShowBaseItem, OptionType } from './consts';
import { handleTranslation, humanizeIndex, isLength, maybe } from '../../../../../../../../../../../lib/util/helpers';

export function generateContactFormRowStructure({ condition = alwaysShowBaseItem } = {}) {
  return {
    id: uuidv4(),
    condition,
    fieldList: [],
  };
}

export function generateContactFormFieldStructure() {
  return {
    id: uuidv4(),
    name: '',
    type: ContactFormField.TEXT,
    isFullWidth: false,
    required: true,
    optionList: [],
  };
}

export function generateContactFormOptionStructure() {
  return {
    id: uuidv4(),
    title: '',
    action: null,
  };
}

export function getConditionOptions(formRowList, conditionText, currentRowIndex) {
  return formRowList.reduce((prev, row, rowIndex) => {
    if (!isLength(row?.fieldList)) return prev;

    for (const field of row.fieldList) {
      if (!withActionContactFormFields.includes(field?.type) || !isLength(field?.optionList)) continue;

      for (const [optionIndex, option] of field.optionList.entries()) {
        if (option?.action !== ContactFormAction.CREATE_NEW_ROWS) continue;

        if (!prev[rowIndex]) {
          // eslint-disable-next-line no-param-reassign
          prev[rowIndex] = {
            rowIndex,
            label: `${handleTranslation(conditionText?.row)} ${humanizeIndex(rowIndex)}`,
            options: [],
          };
        }

        prev[rowIndex].options.push({
          value: {
            rowId: row.id,
            optionId: option.id,
            optionType: OptionType.GROUP,
          },
          title: `${handleTranslation(conditionText?.option)} ${humanizeIndex(optionIndex)}`,
          ...maybe(rowIndex === currentRowIndex, { isDisabled: true }),
        });
      }
    }

    return prev;
  }, {});
}

export function getOptionLabel(rowIndex, optionIndex, conditionText) {
  return `${handleTranslation(conditionText?.row)} ${humanizeIndex(rowIndex)}, ${handleTranslation(conditionText?.option)} ${humanizeIndex(
    optionIndex
  )} ${handleTranslation(conditionText?.selected)}`;
}

export function findConditionIndexes(options, fromRowId, fromOptionId) {
  if (!isLength(options)) return;

  for (const [optionIndex, option] of options.entries()) {
    if (!isLength(option?.options)) continue;

    for (const [groupOptionIndex, groupOption] of option.options.entries()) {
      if (groupOption?.value?.rowId === fromRowId && groupOption?.value?.optionId === fromOptionId) {
        // eslint-disable-next-line consistent-return
        return {
          rowIndex: option.rowIndex,
          optionIndex,
          groupOptionIndex,
        };
      }
    }
  }
}
