import { useCallback } from 'react';

export function useOnSelectHamburgerPkP4P1({
  card,
  conditions,
  setSelectionModalOpen,
  updateProductsStructure,
  updateProductSelect,
  P2,
  productObject,
}) {
  return useCallback(
    customValue => {
      if (!card?.selected && (P2?.content?.products?.[1]?.selected || conditions.P4_1 || conditions.P4_2)) {
        setSelectionModalOpen(true);
        return;
      }

      const updatedSelectedProducts = updateProductSelect(customValue);
      const selectedProducts = updatedSelectedProducts.filter(prod => prod.selected);
      const unselectedProductIds = card?.selected ? [card?.id] : [];
      updateProductsStructure(updatedSelectedProducts, selectedProducts, true, productObject, unselectedProductIds);
    },
    [
      card?.selected,
      card?.id,
      P2?.content?.products,
      conditions.P4_1,
      conditions.P4_2,
      updateProductSelect,
      updateProductsStructure,
      productObject,
      setSelectionModalOpen,
    ]
  );
}
