import { resultTypeFlowProperties } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { rulesEngine } from './rules_helper';
import { updateProductResult, findProductAndChapter } from './product_selection_rules_helper';
import { nonFlowProductsUpdate, updatePagesRulesProducts } from '../end-user-flow-helpers';
import { getRulesAnswerProperties } from './answer_rules_helper';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../lib/util/helpers';

export const hideOrShowElements = (currentElementsIds, showOrHideFlow, allProducts = [], allPages = []) => {
  return showOrHideFlow.reduce(
    (accumulator, block) => {
      const filteredElementsIds = [...accumulator.elementsIds].filter(elId => !block.composerElements.includes(elId));
      if (block.operation === resultTypeFlowProperties.show) {
        return { ...accumulator, elementsIds: [...filteredElementsIds, ...block.composerElements] };
      }
      if (block.operation === resultTypeFlowProperties.hide) {
        const prodsToHide = allProducts
          .filter(prodEl => block.composerElements.includes(prodEl.id))
          .flatMap(el =>
            el.content.products.map(prodItem => ({
              ...prodItem,
              productTemplateId: el.id,
              kind: el.content?.kind,
              productSelection: el.content?.productSelection,
              pageId: allPages.find(page => page.elements.find(element => element.id === el.id))?.id,
            }))
          );
        return { ...accumulator, elementsIds: filteredElementsIds, hideProducts: [...accumulator.hideProducts, ...prodsToHide] };
      }
      return accumulator;
    },
    { elementsIds: [...currentElementsIds], hideProducts: [] }
  );
};

export const elementRulesEngine = (rules, pagesFlow, setPagesFlow, currentElements) => {
  const { showOrHideFlow, resultProduct, scoreAnswer, savingsAnswer } = rulesEngine({
    rules,
    pagesFlow,
    currentElements,
    rulesFilter: ['showOrHideFlow', 'resultProduct', 'scoreAnswer', 'savingsAnswer', 'resultVariables'],
  });

  setPagesFlow(prev => {
    const allProducts = prev.allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT));
    const allQuestions = prev.allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));
    const { elementsIds: currentElementsIds, hideProducts } = hideOrShowElements(prev.currentElementsIds, showOrHideFlow, allProducts, prev.allPages);
    const { elementsIds: allShownButtonsIds } = hideOrShowElements(prev.allShownButtonsIds, showOrHideFlow);
    const unselectProdsWithChapters = findProductAndChapter(hideProducts, prev.allChapters, allProducts);
    const { rulesChapters } = updateProductResult(resultProduct, prev.allChapters, prev.allPages, unselectProdsWithChapters);
    const unselectedHideProds = nonFlowProductsUpdate(hideProducts);

    const updatedAllPages = updatePagesRulesProducts(prev.allPages, unselectedHideProds, true);
    const prevHideProductsIds = prev.hideProducts.map(prod => prod.id);
    const newHideProductsIds = hideProducts.map(prod => prod.id);

    const { rulesAnswersScore, rulesAnswersSavings } = getRulesAnswerProperties(
      prev.rulesAnswersScore,
      prev.rulesAnswersSavings,
      allQuestions,
      scoreAnswer,
      savingsAnswer
    );

    if (
      (isLength(hideProducts) && isLength(prevHideProductsIds) && newHideProductsIds.every((id, index) => prevHideProductsIds[index] === id)) ||
      !isLength(hideProducts)
    ) {
      return {
        ...prev,
        currentElementsIds,
        allShownButtonsIds,
        allChapters: rulesChapters,
        hideProducts: [],
        rulesAnswersScore,
        rulesAnswersSavings,
      };
    }

    return {
      ...prev,
      currentElementsIds,
      allShownButtonsIds,
      allChapters: rulesChapters,
      hideProducts,
      allPages: updatedAllPages,
      rulesAnswersScore,
      rulesAnswersSavings,
    };
  });
};
