import styled from 'styled-components';
import { AppCard } from '../../../../../ui/AppCard';

const Wrapper = styled(AppCard)({
  width: '100%',
  boxShadow: '0 4px 20px rgb(0 0 0 / 10%)',
  marginBottom: 16,
});

export function FormCard(props) {
  return <Wrapper {...props} />;
}
