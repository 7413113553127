import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { getUserDataError, getUserDataRequest, getUserDataSuccess, loginError, loginRequest, loginSuccess, logoutSuccess } from '../slices/authSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance'; // eslint-disable-next-line import/no-cycle
import { persistor } from '..';
import { checkIsUser } from '../../models/Role';
import { isLength } from '../../util/helpers';
import { AuthURI } from '../../api';
import jwtDecode from 'jwt-decode';

/**
 * @desc function generator for auth log in
 */
function* fetchUserLogIn({ payload }) {
  try {
    const { data } = yield call(axiosInstance.post, AuthURI.LOG_IN, payload);
    const { roles, username, accessToken, refreshToken } = data;
    const decodedAccessToken = jwtDecode(accessToken);
    const permissions = decodedAccessToken?.permissions;
    const mainRole = roles?.[0];
    const tenantId = checkIsUser(mainRole) && isLength(permissions) ? permissions?.[0]?.databaseConfigId : null;
    yield put(loginSuccess({ accessToken, role: mainRole, username, tenantId, refreshToken }));
    yield put(getUserDataRequest());
  } catch (error) {
    yield put(loginError(error));
  }
}

/**
 * @desc function generator - observer for success log out action
 */
function* watchLogOutFlow() {
  while (true) {
    yield take(logoutSuccess.type);
    yield persistor.persist();
  }
}

/**
 * @desc request for user data
 */
function* fetchUserData() {
  try {
    const { data } = yield call(axiosInstance.get, 'account');
    yield put(getUserDataSuccess(data));
  } catch (error) {
    yield put(getUserDataError({ error }));
  }
}

/**
 * @desc function generator - observer for all auth saga functions
 */
export function* watchUserAuth() {
  yield all([takeLatest(loginRequest.type, fetchUserLogIn), takeLatest(getUserDataRequest.type, fetchUserData), fork(watchLogOutFlow)]);
}
