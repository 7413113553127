import { Segmented } from 'antd';
import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

export function AppSegmented({ as, sameWidth = true, ...rest }) {
  return <Root {...rest} as={Segmented} $sameWidth={sameWidth} />;
}

const Root = styled(AppBox)(({ $sameWidth }) => ({
  '&.ant-segmented': {
    borderRadius: '8px',
    border: '1px solid #191919',
    padding: '2px',
    background: 'transparent',

    '.ant-segmented-thumb, .ant-segmented-item-selected': {
      background: '#da1b5e',
    },

    '.ant-segmented-item-selected .ant-segmented-item-label': {
      color: '#fff !important',
    },

    '.ant-segmented-thumb, .ant-segmented-item, .ant-segmented-item-selected': {
      borderRadius: '6px !important',
    },

    '.ant-segmented-item-label': {
      lineHeight: 'unset',
      height: 'unset',
      minHeight: 'unset',
      color: '#191919',
      fontWeight: 700,
      fontSize: '12px',
      padding: '3px 10px',
      transition: ' 0.1s all',
    },

    '.ant-segmented-item-selected: after': {
      background: 'unset !important',
    },

    '.ant-segmented-item, .ant-segmented-item-selected': {
      overflow: 'hidden',
      minWidth: 'unset',
    },
  },

  '.ant-segmented-group': $sameWidth && {
    display: 'grid',
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoFlow: 'column',
    justifyItems: 'unset',
  },
}));
