import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AppBasicButton } from '../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { RangeMinMaxRow } from '../../../../../../../../features/RangeMinMaxRow';
import { ProductRangeScoringRow } from '../../components/ProductRangeScoringRow';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import './style.scss';
import { normalizeNumber } from '../../../../../../../../../../../../lib/util/helpers';

/**
 * @desc Renders the range products scoring component.
 * @param {Object} rangeObject - The range object containing scoring information.
 * @param {Number} rangeIndex - The index of the range object.
 * @param {Object} selectedQuestion - The selected question.
 * @returns {JSX.Element} The rendered range products scoring component.
 */
// eslint-disable-next-line max-lines-per-function
export function RangeProductsScoring({ rangeObject, rangeIndex, selectedQuestion }) {
  const { questionnaireStructure, tenantProducts, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const { addProduct: addProductText } = useLocalization().translations.shared.generic;

  const scoreHandleChange = (value, fieldName, minNumber, maxNumber) => {
    const resultValue = normalizeNumber(value, minNumber, maxNumber);

    const structure = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.map(scoreRangeObj => {
                return scoreRangeObj.id === rangeObject.id ? { ...scoreRangeObj, [fieldName]: resultValue } : scoreRangeObj;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure }));
  };

  const deleteRange = deleteId => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.filter(scoreRangeObj => scoreRangeObj.id !== deleteId),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const addProduct = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.map(scoreRangeObj => {
                return scoreRangeObj.id === rangeObject.id
                  ? { ...scoreRangeObj, products: [...scoreRangeObj.products, { id: uuidv4(), prodId: null, score: 0 }] }
                  : scoreRangeObj;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <div className='range_product_scoring_box'>
      <RangeMinMaxRow
        rangeIndex={rangeIndex}
        rangeObject={rangeObject}
        deleteRange={deleteRange}
        onChange={scoreHandleChange}
        disabled={isViewMode}
      />
      {rangeObject.products.map(prodObj => (
        <ProductRangeScoringRow key={prodObj.id} rangeObject={rangeObject} prodObj={prodObj} selectedQuestion={selectedQuestion} />
      ))}
      <div className='products_score_add_btn'>
        <AppBasicButton
          style={{ display: 'inline-flex' }}
          onClick={addProduct}
          disabled={isViewMode || tenantProducts.length <= rangeObject.products.length}
        >
          <AppText className='primary_color bold' textValue={addProductText} />
        </AppBasicButton>
      </div>
    </div>
  );
}
