import { useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionDropBox } from '../../../../../../features/QuestionDropBox';
import { QuestionAnswerSavings } from './QuestionAnswerSavings';
import { QuestionRangeSavings } from './QuestionRangeSavings';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../../../lib/util/helpers';
import './style.scss';

/**
 * @desc Filters the provided items based on the template type.
 * @param {Array} items - An array of items to filter.
 * @returns {Array} - Filtered array containing only items with the specified template type.
 */
const filterQuestions = items => items?.filter(item => item.templateType === TemplateTypes.QUESTION);

/**
 * @desc Represents the savings process component.
 * @returns {JSX.Element} - The rendered savings process component.
 */
export function SavingsProcess() {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const [selectedQuestion, selectQuestion] = useState({
    elementId: filterQuestions(questionnaireStructure)[0].id,
    answerId: isLength(filterQuestions(questionnaireStructure)[0].content.answers)
      ? filterQuestions(questionnaireStructure)[0].content.answers[0].id
      : null,
  });

  const questionElement = filterQuestions(questionnaireStructure).find(element => element.id === selectedQuestion.elementId);

  return (
    <div className='questions_savings'>
      <div className='questions_savings_drops_wrap'>
        {filterQuestions(questionnaireStructure).map((element, index) => (
          <QuestionDropBox key={element.id} question={element} index={index} selectedQuestion={selectedQuestion} selectQuestion={selectQuestion} />
        ))}
      </div>
      <div className='products_savings_wrap'>
        {questionElement?.content?.answers && <QuestionAnswerSavings questionElement={questionElement} selectedQuestion={selectedQuestion} />}
        {questionElement?.content?.counterAnswers && <QuestionRangeSavings questionElement={questionElement} selectedQuestion={selectedQuestion} />}
      </div>
    </div>
  );
}
