/* eslint-disable unicorn/no-useless-switch-case */
import { ProductKind } from '../../../../../../../../../../../lib/models/Questionnaire';
import { ProductOneCounterForm } from './one-counter';
import { ProductMultipleCountersForm } from './multiple-counters';

/**
 * @desc Product form
 * @returns {JSX.Element}
 */

export function ProductForm(props) {
  switch (true) {
    case props.structureItem.content.kind === ProductKind.ONE_COUNTER:
      return <ProductOneCounterForm {...props} />;
    case props.structureItem.content.kind === ProductKind.MULTIPLE_COUNTERS:
    default: // TODO: remove all existed product templates from all finders and then remove this "default" case
      return <ProductMultipleCountersForm {...props} />;
  }
}
