/* eslint-disable react-hooks/exhaustive-deps */
import { CardSection } from '../../../../../../../ui/CardSection';
import { ProductNameSectionHeader } from './ProductNameSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductSelect } from './ProductSelect';
import { CustomSign } from './CustomSign';
import { QuestionnaireFormSection } from '../../../../../../../features/QuestionnaireFormSection';
import { TextFieldWithInfoIcon } from '../../../../../../../features/TextFieldWithInfoIcon';
import { DefaultCounter } from '../../../../../../../../../features/DefaultCounter';
import { useEffect } from 'react';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { useToggleDefaultCounter } from '../../hooks/useToggleDefaultCounter';
import { useFirstMountState } from '../../../../../../../../../../../../../lib/hooks/useFirstMountState';

export function ProductNameSection({
  productNameSection,
  productIndex,
  structureItemIndex,
  allProducts,
  availableProducts,
  product,
  structureItem,
  isOneCounter,
  isExceptional,
}) {
  const { category, templateSubline } = useLocalization().translations.unique.formItems;
  const { updateStructure } = useUpdateStructure();
  const handleToggleDefaultCounter = useToggleDefaultCounter(structureItemIndex, productIndex);

  const isFirstMountState = useFirstMountState();

  const handleChangeDefaultCounterValue = value => {
    // eslint-disable-next-line init-declarations
    let result;
    if (Number(value) > Number(structureItem?.content?.counter?.max)) {
      result = Number(structureItem?.content?.counter?.max);
    } else if (Number(value) < Number(structureItem?.content?.counter?.min)) {
      result = Number(structureItem?.content?.counter?.min);
    } else {
      result = Number(value);
    }
    updateStructure(draft => {
      const { products } = draft[structureItemIndex].content;
      products[productIndex] = { ...products[productIndex], counterSection: { ...products[productIndex].counterSection, value: result } };
    });
  };

  useEffect(() => {
    if (isFirstMountState) {
      return;
    }

    if (product?.counterSection?.showDefaultCounter) {
      handleChangeDefaultCounterValue(product?.counterSection?.value);
    }
  }, [structureItem?.content?.counter?.max, structureItem?.content?.counter?.min]);

  return (
    <CardSection>
      <ProductNameSectionHeader data={productNameSection} productIndex={productIndex} structureItemIndex={structureItemIndex} />
      {isOneCounter && !isExceptional && (
        <DefaultCounter
          checked={product?.counterSection?.showDefaultCounter}
          value={product?.counterSection?.value}
          onToggleDefaultCounter={handleToggleDefaultCounter}
          onChangeDefaultCounterValue={handleChangeDefaultCounterValue}
        />
      )}
      <ProductSelect
        value={productNameSection.product}
        allProducts={allProducts}
        availableProducts={availableProducts}
        productIndex={productIndex}
        structureItemIndex={structureItemIndex}
      />
      {!isExceptional && (
        <QuestionnaireFormSection textFieldProps={{ label: category.label, value: productNameSection.categoryName, disabled: true }} />
      )}
      <TextFieldWithInfoIcon
        label={templateSubline.label}
        data={productNameSection.subline}
        dataPath={`[${structureItemIndex}].content.products[${productIndex}].productNameSection.subline`}
      />
      {!isOneCounter && <CustomSign value={productNameSection.customSign} structureItemIndex={structureItemIndex} productIndex={productIndex} />}
    </CardSection>
  );
}
