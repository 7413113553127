import styled from 'styled-components';

import { AppNewSelect } from 'src/ui/AppNewSelect';
import { AppColorPicker } from 'src/ui/AppColorPicker';

import { useLocalization } from 'src/lib/hooks/useLocalization';

const transparencyStep = 5;
const percentNumber = 0.01;
const digitsAfterDecimal = 2;
const transparencyOptions = Array.from(Array.from({ length: 21 }).keys()).map(number => ({
  title: `${number * transparencyStep} %`,
  value: (1 - number * transparencyStep * percentNumber).toFixed(digitsAfterDecimal),
}));

export function OptionRow({ product, productName, onOptionChange }) {
  const { color, transparency } = product;
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;

  const handleChange = (field, value) => {
    onOptionChange({
      color,
      transparency,
      [field]: value,
    });
  };

  return (
    <ProductRow>
      <ProductTitle>{productName || comparisonDetailTable.productName()}</ProductTitle>
      <ColorPickerContainer>
        <AppColorPicker color={color} onChange={value => handleChange('color', value)} />
      </ColorPickerContainer>

      <TransparencyContainer>
        <AppNewSelect
          value={transparency}
          options={transparencyOptions}
          label='Transparency'
          onChange={optionObj => {
            handleChange('transparency', optionObj.value);
          }}
        />
      </TransparencyContainer>
    </ProductRow>
  );
}

const ProductRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '16px',
});

const ProductTitle = styled('span')({
  flex: 3,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const ColorPickerContainer = styled('div')({
  flex: 4,
});

const TransparencyContainer = styled('div')({
  flex: 4,
});
