import { isLength, removeProductsDuplicates } from '../../../../../../../../../lib/util/helpers';

function getPrioritizedProduct(resultSelectedProducts = [], productsPriority = []) {
  const productMap = new Map();
  for (const product of resultSelectedProducts) productMap.set(product?.productNameSection?.product?.value, product);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < productsPriority?.length; i++) {
    const priorityId = productsPriority[i];
    if (productMap.has(priorityId)) {
      return productMap.get(priorityId);
    }
  }

  return null;
}

const getLastSelectedProduct = resultSelectedProducts => (isLength(resultSelectedProducts) ? [...resultSelectedProducts].pop() : null);

export function getDisplayedProduct(resultSelectedProducts, productsPriority) {
  if (!isLength(resultSelectedProducts)) return null;

  const filteredProducts = removeProductsDuplicates(resultSelectedProducts);

  return getPrioritizedProduct(filteredProducts, productsPriority) || getLastSelectedProduct(filteredProducts);
}
