import { isTransparentRow, RowType } from './utils';
import classNames from 'classnames';
import classes from '../index.module.scss';

export function getRowClassName(row, rowIndex) {
  const isFirstPlain = row?.rowTypes?.includes?.(RowType.FIRST_PLAIN);
  const isLastPlain = row?.rowTypes?.includes?.(RowType.LAST_PLAIN);

  return classNames(row?.className, {
    [classes.transparentRow]: isTransparentRow(row?.rowTypes, rowIndex),
    [classes.firstPlainRow]: isFirstPlain,
    [classes.lastPlainRow]: isLastPlain,
    [classes.lastPlainOddRow]: row?.isLastOdd,
    [classes.productsRow]: row?.rowTypes?.includes(RowType.PRODUCTS),
  });
}
