import { Navigate, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { lazily } from 'react-lazily';
import { NOT_FOUND_ROUTE_PATH } from '../../lib/util/router/router-consts';
import { ROUTER_PATHS } from '../../lib/util/router/router-paths';

const { LogInPage } = lazily(() => import('src/processes/auth/pages/LogInPage'));
const { SetUpPasswordPage } = lazily(() => import('src/processes/auth/pages/SetUpPasswordPage'));

export function AuthRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={NOT_FOUND_ROUTE_PATH} element={<Navigate to={ROUTER_PATHS.logIn} />} />
        <Route path={ROUTER_PATHS.logIn} element={<LogInPage />} />
        <Route path={ROUTER_PATHS.setUpPassword} element={<SetUpPasswordPage />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
}
