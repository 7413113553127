import { useState } from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { ChevronDownIcon } from 'src/ui/icons/ChevronDownIcon';

import { useLocalization } from 'src/lib/hooks/useLocalization';

import { Score } from './Score';
import { Topic } from './Topic';

export function DetailTable({ detailTable, applyVariable = () => {}, fonts, colors, icons, comparisonOptions }) {
  const [openedIndex, setOpenedIndex] = useState(1);
  const { details } = useLocalization().translations.unique.pages.finders.detailTable;

  const toggleCollapse = () => {
    if (!hasTopics) return;

    setOpenedIndex(openedIndex === 0 ? 1 : 0);
  };

  const hasTopics = detailTable.topics?.length > 0;

  return (
    <StyledCollapse
      expandIconPosition='end'
      color={colors[fonts.headlineFourth.colorVariant]}
      fontWeight={fonts.headlineFourth.fontWeight}
      activeKey={openedIndex}
    >
      <StyledHeader bgColor={colors.primaryColor} fontWeight={fonts.headlineFourth.fontWeight} onClick={toggleCollapse}>
        <div>{applyVariable(detailTable.headline)}</div>
        {hasTopics && (
          <StyledDetails>
            {details()}
            <ChevronDownIcon
              fill={colors[fonts.headlineFourth.colorVariant]}
              style={{
                transform: `rotate(${openedIndex === 1 ? '0' : '180deg'})`,
              }}
            />
          </StyledDetails>
        )}
      </StyledHeader>
      {(detailTable.score.isEnabled || detailTable.subline) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            borderBottomLeftRadius: hasTopics ? 0 : '8px',
            borderBottomRightRadius: hasTopics ? 0 : '8px',
            backgroundColor: 'white',
            color: colors[fonts.body.colorVariant],
          }}
        >
          <div style={{ flex: 4, fontWeight: 600 }}>
            <div style={{ padding: '16px 24px' }}>{applyVariable(detailTable.subline)}</div>
          </div>
          {detailTable.score.isEnabled && (
            <>
              <Score color={colors.primaryColor} product={detailTable.score.productOne} />
              <Score color={colors.primaryColor} product={detailTable.score.productTwo} />
            </>
          )}
        </div>
      )}
      <Collapse.Panel key={1}>
        {detailTable.topics.map(topic => (
          <Topic
            applyVariable={applyVariable}
            topic={topic}
            key={topic.id}
            colors={colors}
            fonts={fonts}
            icons={icons}
            comparisonOptions={comparisonOptions}
          />
        ))}
      </Collapse.Panel>
    </StyledCollapse>
  );
}

const StyledCollapse = styled(Collapse)({
  border: 'none !important',
  marginBottom: '16px',
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
  overflow: 'hidden',

  '.ant-collapse-header': {
    display: 'none !important',
  },
});

const StyledHeader = styled('div')(props => ({
  cursor: 'pointer',
  backgroundColor: props.bgColor,
  padding: '16px 24px',
  color: props.color,
  fontWeight: props.fontWeight,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledDetails = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});
