/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { AppText } from 'src/ui/text/AppText';
import { AppFormSection } from 'src/ui/AppFormSection';
import { ImagesIcon } from 'src/ui/icons/ImagesIcon';
import { ChevronDownIcon } from 'src/ui/icons/ChevronDownIcon';
import { ChevronUpIcon } from 'src/ui/icons/ChevronUpIcon';
import { checkObjectWithValue } from 'src/lib/util/helpers';
import { SelectImages } from 'src/lib/models/SelectImages';

import { ImagesTitle } from './ImagesTitle';
import { SliderControls } from './SliderControls';

import { ImageOption } from '../ImageOption';

import styles from './ImageMultiSelect.module.scss';

// eslint-disable-next-line max-lines-per-function
export function ImageMultiSelect({ valueObject, onChange, label, mediaOptions, selectBackgroundImageActive = {} }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const [visible, setVisible] = useState(false);
  const [selectWidth, setSelectWidth] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState(mediaOptions);
  const selectRef = useRef();

  const checkMedia = value => value && mediaOptions.some(media => media.id === value);

  const existingSelectedImages = valueObject.selectedImages?.filter(image => checkMedia(image));
  const existingActiveImage = checkMedia(valueObject.value) ? valueObject.value : null;

  /* to handle entered value from previous version of component */
  const selectedImagesVal = existingSelectedImages || (existingActiveImage ? [existingActiveImage] : []);
  const [selectedImages, setSelectedImages] = useState(selectedImagesVal);
  const [activeImage, setActiveImage] = useState(existingActiveImage);

  const handleSearch = event => {
    if (event.target.value) {
      const filteredOptions = mediaOptions.filter(mediaObj => mediaObj.originalFileName && mediaObj.originalFileName.includes(event.target.value));
      setOptions(filteredOptions);
    } else {
      setOptions(mediaOptions);
    }
    setSearchValue(event.target.value);
  };

  const { inputPlaceholdersByTypes } = useLocalization().translations.shared.arranges.filter;

  useEffect(() => {
    if (selectRef?.current) {
      const { width } = selectRef.current.getBoundingClientRect();
      setSelectWidth(width);
    }
  }, [visible]);

  const handleVisibleChange = flag => setVisible(flag);

  if (!checkObjectWithValue(valueObject)) {
    return null;
  }

  const checkLowSaturationBackgroundUrl = () => checkMedia(selectBackgroundImageActive.value) && activeImage === SelectImages.LOW_SATURATION;
  const usualBackgroundUrl = checkMedia(activeImage) ? mediaOptions.find(media => media.id === activeImage).s3Url : null;

  const lowSaturationBackgroundUrl = checkLowSaturationBackgroundUrl()
    ? mediaOptions.find(media => media.id === selectBackgroundImageActive.value).s3Url
    : null;

  const isMedia = checkMedia(activeImage);

  const onSlideArrowClick = (event, position) => {
    event.stopPropagation();
    const currentIndex = selectedImages.indexOf(activeImage);
    let activeImageIndex = position === 'prev' ? currentIndex - 1 : currentIndex + 1;
    const loopSliderValue = -1;

    if (activeImageIndex === selectedImages.length) {
      activeImageIndex = 0;
    }

    if (activeImageIndex === loopSliderValue) {
      activeImageIndex = selectedImages.length - 1;
    }

    const newActiveImage = selectedImages[activeImageIndex];
    setActiveImage(newActiveImage);
    onChange({
      selectedImages,
      value: newActiveImage,
    });
  };

  const onImageSelect = imageId => {
    const newSelectedImages = selectedImages.includes(imageId) ? selectedImages.filter(image => image !== imageId) : [...selectedImages, imageId];
    const notFound = -1;
    setSelectedImages(newSelectedImages);

    let selectedValue = activeImage;

    if (newSelectedImages.length === 0) {
      setActiveImage(null);
      selectedValue = null;
    }

    if (newSelectedImages.indexOf(activeImage) === notFound) {
      setActiveImage(newSelectedImages[0]);
      [selectedValue] = newSelectedImages;
    }

    onChange({
      selectedImages: newSelectedImages,
      value: selectedValue || null,
    });
  };

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement='bottom'
      getPopupContainer={trigger => trigger.parentNode}
      onOpenChange={handleVisibleChange}
      disabled={isViewMode}
      dropdownRender={() => (
        <div className={styles.dropdownContent} style={{ width: selectWidth }}>
          <AppFormSection
            size='small'
            rootProps={{ style: { margin: 0 } }}
            formItemProps={{ hasFeedback: false, style: { minHeight: 'unset' } }}
            textFieldProps={{ label: inputPlaceholdersByTypes.list, onChange: handleSearch, value: searchValue }}
          />
          <div className='image_select_options_wrap'>
            {options.map(imageObj => (
              <ImageOption
                key={imageObj.id}
                s3Url={
                  checkMedia(selectBackgroundImageActive.value) && imageObj.id === SelectImages.LOW_SATURATION
                    ? mediaOptions.find(media => media.id === selectBackgroundImageActive.value).s3Url
                    : imageObj.s3Url
                }
                id={imageObj.id}
                fileName={imageObj.originalFileName}
                value={activeImage}
                onSelect={onImageSelect}
                isOpacity={imageObj.id === SelectImages.LOW_SATURATION}
                isMultiSelection='true'
                isSelected={selectedImages.includes(imageObj.id)}
              />
            ))}
          </div>
        </div>
      )}
    >
      <div
        ref={selectRef}
        className={classNames('image_select_wrap', 'align_items_center', {
          disabled: isViewMode,
          image_select_wrap__open: visible,
          image_select_wrap__closed: !visible,
        })}
      >
        <div
          className={classNames('image_select_picture', { image_select_picture__not_selected: !isMedia, disabled: isViewMode })}
          style={
            isMedia
              ? {
                  backgroundImage: `${
                    activeImage === SelectImages.LOW_SATURATION ? 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), ' : ''
                  } url('${checkLowSaturationBackgroundUrl() ? lowSaturationBackgroundUrl : usualBackgroundUrl}')`,
                }
              : {}
          }
        >
          {activeImage && (
            <SliderControls
              onSlideArrowClick={onSlideArrowClick}
              currentSlide={selectedImages.indexOf(activeImage) + 1}
              maxSlides={selectedImages.length}
            />
          )}
          {!isMedia && <ImagesIcon />}
        </div>
        {isMedia && (
          <AppText ellipsis className='image_select_text' disabled={isViewMode}>
            <ImagesTitle imageList={selectedImages.map(image => mediaOptions.find(mediaObj => mediaObj.id === image).originalFileName)} />
          </AppText>
        )}
        <AppText ellipsis={!isMedia} className={isMedia ? 'image_select_text_label__selected' : 'image_select_text_label'} textValue={label} />
        {visible ? <ChevronUpIcon fill='#5C5C6E' /> : <ChevronDownIcon fill='#5C5C6E' />}
      </div>
    </Dropdown>
  );
}
