import { useDispatch } from 'react-redux';
import { AppModal } from '../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { deleteLayoutRequest } from '../../../../../../../../../lib/store/slices/layoutsSlice';
import { AppParagraph } from '../../../../../../../../../ui/text/AppParagraph';

/**
 * @desc Delete Layout Modal
 * @param {Function} close
 * @param {Boolean} isOpen
 * @param {Object} currentItem
 * @param {Boolean} isModalLoading
 * @returns {JSX.Element}
 */
export function DeleteLayoutModal({ close, isOpen, currentItem, isModalLoading }) {
  const { title, subTitle } = useLocalization().translations.unique.pages.layouts.deleteModal;
  const dispatch = useDispatch();
  const { generic } = useLocalization().translations.shared;

  const submit = () => dispatch(deleteLayoutRequest(currentItem.id));

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title({ name: currentItem.name }) }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: submit, loading: isModalLoading, textValue: generic.delete }}
    >
      {isOpen && <AppParagraph textValue={subTitle} style={{ marginBottom: '24px' }} />}
    </AppModal>
  );
}
