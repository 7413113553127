import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedRule } from 'src/lib/store/slices/rulesSlice';

/**
 * @desc Custom hook for managing condition items and performing updates and deletions.
 * @param {string} conditionId - The ID of the condition associated with the items.
 * @returns {Object} - An object containing functions to update and delete condition items.
 */
export const useConditionItem = conditionId => {
  const dispatch = useDispatch();

  const { selectedRule } = useSelector(({ rules }) => rules);

  const updateConditionItem = (itemId, value) => {
    const updatedRule = {
      ...selectedRule,
      conditions: selectedRule.conditions.map(condition => {
        if (condition.id === conditionId) {
          return {
            ...condition,
            body: condition.body.map(item => {
              if (item.id === itemId) {
                return {
                  ...item,
                  value,
                };
              }

              return item;
            }),
          };
        }

        return condition;
      }),
    };

    dispatch(updateSelectedRule(updatedRule));
  };

  const deleteConditionItem = () => {
    const updatedRule = {
      ...selectedRule,
      conditions: selectedRule.conditions.map(condition => {
        if (condition.id === conditionId) {
          const lastItem = -1;
          return {
            ...condition,
            body: condition.body.slice(0, lastItem),
          };
        }

        return condition;
      }),
    };

    dispatch(updateSelectedRule(updatedRule));
  };

  return { updateConditionItem, deleteConditionItem };
};
