import { TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';

export function setHistoryPages(allPages, currentPageId, currentElementsIds, setPageHistory, lastCurrentFlowElement) {
  const currentPage = allPages.find(page => page.id === currentPageId);
  const currentFlowPage =
    currentPage?.templateType === TemplateTypes.PAGE
      ? { ...currentPage, elements: currentPage?.elements.filter(el => currentElementsIds.includes(el.id)) }
      : currentPage;

  setPageHistory(prevHistory => {
    const filteredHistory = prevHistory.filter(page => page.id !== currentPageId);
    return [...filteredHistory, { ...currentFlowPage, lastCurrentFlowElement }];
  });
}
