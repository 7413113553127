export function CrossIcon({ styles = '', ...rest }) {
  return (
    <svg className={styles} fill='currentColor' xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' {...rest}>
      <path
        d='M0.97034 0.97034C1.04001 0.900495 1.12277 0.845081 1.21389 0.807271C1.30501 0.769462 1.40269 0.75 1.50134 0.75C1.59999 0.75 1.69767 0.769462 1.78879 0.807271C1.87991 0.845081 1.96267 0.900495 2.03234 0.97034L6.00134 4.94084L9.97034 0.97034C10.0401 0.900608 10.1229 0.845294 10.214 0.807555C10.3051 0.769817 10.4027 0.750393 10.5013 0.750393C10.6 0.750393 10.6976 0.769817 10.7887 0.807555C10.8798 0.845294 10.9626 0.900608 11.0323 0.97034C11.1021 1.04007 11.1574 1.12286 11.1951 1.21396C11.2329 1.30507 11.2523 1.40272 11.2523 1.50134C11.2523 1.59996 11.2329 1.69761 11.1951 1.78872C11.1574 1.87982 11.1021 1.96261 11.0323 2.03234L7.06184 6.00134L11.0323 9.97034C11.1021 10.0401 11.1574 10.1229 11.1951 10.214C11.2329 10.3051 11.2523 10.4027 11.2523 10.5013C11.2523 10.6 11.2329 10.6976 11.1951 10.7887C11.1574 10.8798 11.1021 10.9626 11.0323 11.0323C10.9626 11.1021 10.8798 11.1574 10.7887 11.1951C10.6976 11.2329 10.6 11.2523 10.5013 11.2523C10.4027 11.2523 10.3051 11.2329 10.214 11.1951C10.1229 11.1574 10.0401 11.1021 9.97034 11.0323L6.00134 7.06184L2.03234 11.0323C1.96261 11.1021 1.87982 11.1574 1.78872 11.1951C1.69761 11.2329 1.59996 11.2523 1.50134 11.2523C1.40272 11.2523 1.30507 11.2329 1.21396 11.1951C1.12286 11.1574 1.04007 11.1021 0.97034 11.0323C0.900608 10.9626 0.845294 10.8798 0.807555 10.7887C0.769817 10.6976 0.750393 10.6 0.750393 10.5013C0.750393 10.4027 0.769817 10.3051 0.807555 10.214C0.845294 10.1229 0.900608 10.0401 0.97034 9.97034L4.94084 6.00134L0.97034 2.03234C0.900495 1.96267 0.845081 1.87991 0.807271 1.78879C0.769462 1.69767 0.75 1.59999 0.75 1.50134C0.75 1.40269 0.769462 1.30501 0.807271 1.21389C0.845081 1.12277 0.900495 1.04001 0.97034 0.97034Z'
        fill='currentColor'
      />
    </svg>
  );
}
