export function ImagesIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.75391 13.9375C7.35064 13.9375 7.92294 13.7004 8.3449 13.2785C8.76685 12.8565 9.00391 12.2842 9.00391 11.6875C9.00391 11.0908 8.76685 10.5185 8.3449 10.0965C7.92294 9.67455 7.35064 9.4375 6.75391 9.4375C6.15717 9.4375 5.58487 9.67455 5.16292 10.0965C4.74096 10.5185 4.50391 11.0908 4.50391 11.6875C4.50391 12.2842 4.74096 12.8565 5.16292 13.2785C5.58487 13.7004 6.15717 13.9375 6.75391 13.9375Z'
        fill='#CFCFD4'
      />
      <path
        d='M21.0039 19.9375C21.0039 20.7331 20.6878 21.4962 20.1252 22.0588C19.5626 22.6214 18.7996 22.9375 18.0039 22.9375H3.00391C2.20826 22.9375 1.4452 22.6214 0.882586 22.0588C0.319977 21.4962 0.00390625 20.7331 0.00390625 19.9375V7.9375C0.00390585 7.14237 0.319565 6.37976 0.881525 5.81724C1.44349 5.25472 2.20578 4.9383 3.00091 4.9375C3.00091 4.14185 3.31698 3.37879 3.87959 2.81618C4.44219 2.25357 5.20526 1.9375 6.00091 1.9375H21.0009C21.7966 1.9375 22.5596 2.25357 23.1222 2.81618C23.6848 3.37879 24.0009 4.14185 24.0009 4.9375V16.9375C24.0009 17.7326 23.6852 18.4952 23.1233 19.0578C22.5613 19.6203 21.799 19.9367 21.0039 19.9375ZM21.0009 3.4375H6.00091C5.60308 3.4375 5.22155 3.59554 4.94025 3.87684C4.65894 4.15814 4.50091 4.53968 4.50091 4.9375H18.0039C18.7996 4.9375 19.5626 5.25357 20.1252 5.81618C20.6878 6.37879 21.0039 7.14185 21.0039 7.9375V18.4375C21.4012 18.4367 21.782 18.2783 22.0626 17.9971C22.3433 17.7159 22.5009 17.3348 22.5009 16.9375V4.9375C22.5009 4.53968 22.3429 4.15814 22.0616 3.87684C21.7803 3.59554 21.3987 3.4375 21.0009 3.4375ZM3.00391 6.4375C2.60608 6.4375 2.22455 6.59554 1.94325 6.87684C1.66194 7.15814 1.50391 7.53968 1.50391 7.9375V19.9375L5.47291 16.4065C5.59551 16.2843 5.75658 16.2084 5.92881 16.1914C6.10105 16.1745 6.27385 16.2176 6.41791 16.3135L10.4079 18.973L15.9729 13.408C16.0841 13.2967 16.2276 13.2231 16.3829 13.1978C16.5382 13.1725 16.6976 13.1967 16.8384 13.267L19.5039 16.1875V7.9375C19.5039 7.53968 19.3459 7.15814 19.0646 6.87684C18.7833 6.59554 18.4017 6.4375 18.0039 6.4375H3.00391Z'
        fill='#CFCFD4'
      />
    </svg>
  );
}
