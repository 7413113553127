import { AppBasicButton } from '../../../../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../../../../../ui/icons/DeleteIcon';
import styled from 'styled-components';
import lodashGet from 'lodash.get';
import { useUpdateStructure } from '../../../../../../../../../hooks/useUpdateStructure';
import { useSelector } from 'react-redux';

const DELETE_AMOUNT = 1;

export function DeleteDetailTableButton({ structureItemIndex, productIndex, detailTableIndex }) {
  const { updateStructure } = useUpdateStructure();

  const { isViewMode } = useSelector(({ finders }) => finders);

  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].detailTableSection.detailTables`;
  const handleDeleteDetailTable = () => {
    updateStructure(draft => {
      const detailTables = lodashGet(draft, dataPath);
      detailTables.splice(detailTableIndex, DELETE_AMOUNT);
    });
  };

  return (
    <Root>
      <AppBasicButton onClick={handleDeleteDetailTable} disabled={isViewMode}>
        <DeleteIcon />
      </AppBasicButton>
    </Root>
  );
}

const Root = styled('div')({
  padding: '16px 24px 0 24px',
  display: 'flex',
  justifyContent: 'flex-end',
});
