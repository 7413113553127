import lodashGet from 'lodash.get';
import { useUpdateStructure } from '../../../../../../../../../../hooks/useUpdateStructure';
import { QuestionnaireBasicButton } from '../../../../../../../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../../../../../../ui/icons/DeleteIcon';

const DELETE_AMOUNT = 1;

export function DeleteButton({ bulletPointIndex, dataPath, disabled }) {
  const { updateStructure } = useUpdateStructure();

  const deleteBulletPoint = () => {
    updateStructure(draft => {
      const bulletPoints = lodashGet(draft, dataPath);
      bulletPoints.splice(bulletPointIndex, DELETE_AMOUNT);
    });
  };

  return (
    <QuestionnaireBasicButton disabled={disabled} onClick={deleteBulletPoint} style={{ marginBottom: '24px' }}>
      <DeleteIcon />
    </QuestionnaireBasicButton>
  );
}
