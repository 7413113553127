import { Icon } from 'src/features/Icon';
import styles from './styles.module.scss';
import { useRef, useState } from 'react';
import { useOutsideClick } from 'src/lib/hooks/useOutsideClick';

function HamburgerMenu({ links }) {
  const [active, setActive] = useState(false);
  const hamburgerRef = useRef(null);

  useOutsideClick(hamburgerRef, () => {
    setActive(false);
  });

  return (
    <button className={`${styles.hamburgerMenu} ${active ? styles.active : ''}`} ref={hamburgerRef} onClick={() => setActive(!active)} type='button'>
      <Icon icon='hamburger' width={18} height={16} fill={active ? '#0066B3' : '#5C5C6E'} />

      {active && (
        <div className={styles.menu}>
          {links.map(link => (
            <a href={link.linkUrl} key={link.id} target='blank'>
              {link.linkName}
            </a>
          ))}
        </div>
      )}
    </button>
  );
}

export { HamburgerMenu };
