/* eslint-disable max-lines-per-function */
import { useSelector } from 'react-redux';
import { AppTreeSelect } from 'src/ui/AppTreeSelect';
import { LabelTexts } from 'src/lib/models/TemplateTypes';
import { resultTypeFlowProperties } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

/**
 * @desc Conditional Item Select
 * @param {String} label
 * @param {Function} onChange
 * @param {Array | Object} value
 * @param {isMultiSelect} isMultiSelect
 * @returns {JSX.Element}
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function ConditionalItemSelect({ label, onChange, value, isMultiSelect }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const excludedTypes = new Set(['PAGE']);

  const typeCounter = {};
  const selectOptions = [];

  const handleTreeSelectChange = selected => {
    let updatedValue = null;

    updatedValue = isMultiSelect ? selected.map(selectedItem => selectedItem.value) : selected;

    onChange(updatedValue);
  };

  for (const structureItem of questionnaireStructure) {
    if (excludedTypes.has(structureItem.templateType)) continue;

    const childButtons = [];

    typeCounter[structureItem.templateType] = (typeCounter[structureItem.templateType] || 0) + 1;

    const structureItemOption = {
      title: `${LabelTexts[structureItem.templateType]}.${typeCounter[structureItem.templateType]} ${structureItem.label}`,
      value: structureItem.id,
    };

    if (structureItem.content?.composerButton && structureItem.content.composerButton.id) {
      childButtons.push({
        title: `B. ${structureItem.content.composerButton.value}`,
        value: structureItem.content.composerButton.id,
      });
    }

    if (structureItem?.content?.buttons?.length > 0) {
      childButtons.push(
        ...structureItem.content.buttons.map(button => ({
          title: `B. ${button.buttonText.value}`,
          value: button.id,
        }))
      );
    }

    if (structureItem.content?.primaryButton?.id && structureItem.content.secondaryButton?.id) {
      childButtons.push(
        {
          title: `B. ${structureItem.content.primaryButton.value}`,
          value: structureItem.content.primaryButton.id,
        },
        {
          title: `B. ${structureItem.content.secondaryButton.value}`,
          value: structureItem.content.secondaryButton.id,
        }
      );
    }

    if (structureItem.content?.chapterButton && structureItem.content.chapterButton.value && structureItem.content.chapterButton.id) {
      childButtons.push({
        title: `B. ${structureItem.content.chapterButton.value}`,
        value: structureItem.content.chapterButton.id,
      });
    }

    if (structureItem.content?.completedChaptersButtons) {
      for (const chapterButton of structureItem.content.completedChaptersButtons) {
        if (chapterButton.id) {
          childButtons.push({
            title: `B. ${chapterButton.buttonText.value}`,
            value: chapterButton.id,
          });
        }
      }
    }

    if (structureItem.content?.products) {
      for (const depotProduct of structureItem.content.products) {
        if (!depotProduct.buttonsSection?.buttons) {
          break;
        }

        for (const depotButton of depotProduct.buttonsSection.buttons) {
          childButtons.push({
            title: `B. ${depotButton.buttonText.value}`,
            value: depotButton.id,
          });
        }
      }
    }

    if (childButtons.length > 0 && label !== resultTypeFlowProperties.goTo) {
      structureItemOption.children = childButtons;
    }

    selectOptions.push(structureItemOption);
  }

  const mappedValue = isMultiSelect ? (value ? value.map(val => ({ value: val })) : undefined) : { value };

  return (
    <AppTreeSelect
      label={label}
      options={selectOptions}
      isMultiSelect={isMultiSelect}
      value={mappedValue}
      onChange={handleTreeSelectChange}
      placeholder='Select Composer element'
    />
  );
}
