import { useSelector } from 'react-redux';
import { ContentTop } from '../ContentTop';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Explanation } from '../Explanation';
import { SavingsProcess } from './SavingsProcess';
import { TemplateTypes } from '../../../../../../../../../lib/models/TemplateTypes';

/**
 * @desc Checks if any element in the provided array has a specific template type.
 * @param {Array} items - An array of items to check.
 * @returns {boolean} - True if any item has the specified template type, otherwise false.
 */
const checkQuestionElement = items => items.some(item => item.templateType === TemplateTypes.QUESTION);

/**
 * @desc Represents the savings content component.
 * @returns {JSX.Element} - The rendered savings content component.
 */
export function SavingsContent() {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { title, tooltipText, explanation } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.savings;

  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <ContentTop title={title} tooltipText={tooltipText} />
      {checkQuestionElement(questionnaireStructure) ? <SavingsProcess /> : <Explanation descriptionText={explanation.description} />}
    </div>
  );
}
