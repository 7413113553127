/* eslint-disable no-param-reassign */
import styled from 'styled-components';
import { Align } from '../comparison-table-utils';
import { LeftAlignIcon } from '../../../../../../../../../../ui/icons/LeftAlignIcon';
import { CenterAlignIcon } from '../../../../../../../../../../ui/icons/CenterAlignIcon';
import { RightAlignIcon } from '../../../../../../../../../../ui/icons/RightAlignIcon';
import { AlignButton } from './AlignButton';
import { useContentTable } from '../hooks/useContentTable';

export function AlignSection({ rowIndex, columnIndex }) {
  const { contentTableState } = useContentTable();
  const { align } = contentTableState.tableRows[rowIndex].cells[columnIndex];

  const setAlign = alignType => {
    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].cells[columnIndex].align = alignType;
    });
  };

  const isActiveLeft = align === Align.LEFT;
  const isActiveRight = align === Align.RIGHT;
  const isActiveCenter = !isActiveLeft && !isActiveRight;

  const setLeftAlign = () => setAlign(Align.LEFT);
  const setCenterAlign = () => setAlign(Align.CENTER);
  const setRightAlign = () => setAlign(Align.RIGHT);

  return (
    <Root>
      <AlignButton isActive={isActiveLeft} onClick={setLeftAlign}>
        <LeftAlignIcon />
      </AlignButton>
      <AlignButton isActive={isActiveCenter} onClick={setCenterAlign}>
        <CenterAlignIcon />
      </AlignButton>
      <AlignButton isActive={isActiveRight} onClick={setRightAlign}>
        <RightAlignIcon />
      </AlignButton>
    </Root>
  );
}
const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 10px',
});
