import { v4 as uuidv4 } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DraggableFormRow } from '../DraggableFormRow';
import { GripVerticalIcon } from '../../../../../../ui/icons/GripVerticalIcon';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../ui/text/AppText';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppBox } from '../../../../../../ui/boxes/AppBox';
import { DEFAULT_MAX_COUNTER_VALUE, ResultType } from '../../../../../../lib/models/Questionnaire';
import { ConnectionLineLong } from '../../../../../../ui/icons/ConnectionLineLong';
import { InfoIconTextarea } from '../form-items/InfoIconTextarea';
import React from 'react';
import styled from 'styled-components';
import { updateQuestionnaireStructure } from '../../../../../../lib/store/slices/findersSlice';

// eslint-disable-next-line max-lines-per-function
export function DraggableCounterAnswerList({
  answers,
  answersFieldName,
  productValues,
  onChange,
  label,
  addButtonText,
  handleChangeContentFields,
  item,
}) {
  const dispatch = useDispatch();
  const { isViewMode, questionnaireStructure } = useSelector(({ finders }) => finders);

  const addValue = () => {
    const uniqueId = uuidv4();
    const updatedAnswers = [...answers, { id: uniqueId, value: '', amount: 0, minValue: 0, maxValue: DEFAULT_MAX_COUNTER_VALUE, maxValueText: '' }];
    const contentUpdate = Array.isArray(productValues)
      ? {
          [answersFieldName]: updatedAnswers,
          productValues: productValues.map(prodVal => ({ ...prodVal, prices: [...prodVal.prices, { value: 0, priceId: uniqueId }] })),
        }
      : { [answersFieldName]: updatedAnswers };
    handleChangeContentFields(contentUpdate);
  };

  const deleteValue = id => {
    if (answers.length > 1) {
      const updatedAnswers = answers.filter(answer => answer.id !== id);
      const contentUpdate = Array.isArray(productValues)
        ? {
            [answersFieldName]: updatedAnswers,
            productValues: productValues.map(prodVal => ({ ...prodVal, prices: prodVal.prices.filter(price => price.priceId !== id) })),
          }
        : { [answersFieldName]: updatedAnswers };
      handleChangeContentFields(contentUpdate);
      handleChangeContentFields(contentUpdate);
    }
  };

  const handleChangeFieldInfoIconValue = (fieldName, answerId) => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: {
              ...el.content,
              [fieldName]: el.content[fieldName].map(elem => {
                if (elem.id === answerId) {
                  return {
                    ...elem,
                    infoIcon: { ...elem.infoIcon, value },
                  };
                }
                return elem;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleDeleteInfoIcon = (fieldName, answerId) => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: {
              ...el.content,
              [fieldName]: el.content[fieldName].map(elem => {
                if (elem.id === answerId) {
                  return {
                    ...elem,
                    infoIcon: '',
                  };
                }
                return elem;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <>
      <div className='draggable_counter_zone_block'>
        <Droppable
          droppableId={JSON.stringify({
            itemId: item.id,
            fieldName: answersFieldName,
          })}
          type={ResultType.QUESTION_ANSWERS}
        >
          {provided => (
            <div ref={provided.innerRef}>
              {answers.map((valueObj, index) => (
                <Draggable key={valueObj.id} draggableId={valueObj.id} index={index} isDragDisabled={isViewMode}>
                  {prov => (
                    <div ref={prov.innerRef} {...prov.draggableProps}>
                      <Droppable
                        droppableId={JSON.stringify({
                          itemId: item.id,
                          answerId: valueObj.id,
                          fieldName: answersFieldName,
                        })}
                        type={answersFieldName === 'chartAnswers' && !valueObj.infoIcon && ResultType.INFO_ICON}
                      >
                        {(providedItem, snapshotItem) => (
                          <Item ref={providedItem.innerRef}>
                            <div className='draggable_counter_input_wrap'>
                              <DraggableFormRow
                                index={index}
                                valueObject={valueObj}
                                onChange={onChange}
                                valueArray={answers}
                                label={label}
                                deleteValue={deleteValue}
                                answersFieldName={answersFieldName}
                                item={item}
                              />
                              <AppBox {...prov.dragHandleProps} className='draggable_counter_input_drag_icon' disabled={isViewMode}>
                                <GripVerticalIcon />
                              </AppBox>
                            </div>
                            {(valueObj?.infoIcon || snapshotItem.isDraggingOver) && (
                              <InfoIconLine>
                                <ConnectionLineLong stroke={valueObj?.infoIcon?.infoIconName ? '#5C5C6E' : '#33BDCD'} />
                              </InfoIconLine>
                            )}
                            {(valueObj?.infoIcon || snapshotItem.isDraggingOver) && (
                              <InfoIconTextarea
                                valueObject={valueObj?.infoIcon || { value: '' }}
                                label='Enter info text'
                                onChange={handleChangeFieldInfoIconValue('chartAnswers', valueObj.id)}
                                onClickSuffix={() => handleDeleteInfoIcon('chartAnswers', valueObj.id)}
                              />
                            )}
                          </Item>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <AppBasicButton onClick={addValue} disabled={isViewMode}>
        <AppText className='primary_color bold' textValue={addButtonText} />
      </AppBasicButton>
    </>
  );
}

const Item = styled('div')({
  position: 'relative',
  zIndex: 0,
});

const InfoIconLine = styled('div')({
  position: 'absolute',
  top: 68,
  right: -9,
});
