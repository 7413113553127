/* eslint-disable no-magic-numbers,max-lines-per-function,max-lines */
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef, useState } from 'react';
import { AppContent } from '../../../../../../../../ui/AppContent';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { AudioLoader } from '../AudioLoader';
import { AudioPlayerDropdown } from '../AudioPlayerDropdown';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { selectQuestionnaireElement } from '../../../../../../../../lib/store/slices/findersSlice';
import { Chapter } from './cards/Chapter/Chapter';
import { CardCollapse } from './cards/CardCollapse';
import { EmptyCard } from './EmptyCard/EmptyCard';
import { useModifiedStructure } from '../../../../utils/hooks/useModifiedStructure';
import { AppBox } from '../../../../../../../../ui/boxes/AppBox';
import { useOutsideClick } from '../../../../../../../../lib/hooks/useOutsideClick';
import { deleteItem } from '../../../../utils/questionnaire-helpers';
import { selectPreviewQuestionnaireElement } from '../../../../../../../../lib/store/slices/previewSlice';
import { AppPreloader } from '../../../../../../../../ui/AppPreloader';

const contextMenuTextStyle = {
  color: '#000',
  fontSize: 16,
  lineHeight: '19px',
  cursor: 'pointer',
};

/**
 * @desc The user will be able to set composer elements (questions, product cards, content) that will be viewed and selected by end-users by exact flow.
 * @param {Array} questionnaireStructure
 * @param {Boolean} isTemplatesLoading
 * @returns {JSX.Element}
 */

export function QuestionnaireContent({ questionnaireStructure, isTemplatesLoading }) {
  const dispatch = useDispatch();
  const { content } = useLocalization().translations.unique.pages.questionnaire;
  const { navigatorTab } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;
  const [isOpenContextMenu, setIsOpenContextMenu] = useState({ isOpen: false, id: null });
  const contextMenuRef = useRef(null);

  const { isViewMode, finder } = useSelector(({ finders }) => finders);

  const modifiedStructure = useModifiedStructure(questionnaireStructure);

  const firstChapter = useMemo(() => modifiedStructure?.find(item => item.templateType === TemplateTypes.CHAPTER), [modifiedStructure]);
  const isEmptyCard = useMemo(() => modifiedStructure?.every(item => item.templateType === TemplateTypes.PAGE), [modifiedStructure]);
  const isIntro = useMemo(() => modifiedStructure?.some(item => item.templateType === TemplateTypes.INTRO), [modifiedStructure]);

  const getTextValue = item => {
    const contentPage = content.page({ number: item.templateTypeIndex });
    const chapterName = item?.pageName ? `| ${item?.pageName}` : '';
    const chapterIndex = item?.chapterIndex;
    return `${contentPage} ${chapterName} ${chapterIndex || ''}`;
  };

  const handleOpenContextMenu = (event, pageId, pageIndex) => {
    if (questionnaireStructure.length > 1 && pageIndex !== 0) {
      event.preventDefault();
      setIsOpenContextMenu({ isOpen: !isOpenContextMenu.isOpen, id: pageId });
    }
  };

  useOutsideClick(contextMenuRef, () => {
    setIsOpenContextMenu({ isOpen: false, id: null });
  });

  const showContextMenu = item => isOpenContextMenu.isOpen && isOpenContextMenu.id === item.id && !isViewMode;

  if (isTemplatesLoading) {
    return (
      <StyledLoaderWrapper>
        <AppPreloader />
      </StyledLoaderWrapper>
    );
  }

  return (
    <AppContent>
      <Droppable droppableId='ITEMS' type='COMPOSER'>
        {(provided, snapshot) => (
          <Items ref={provided.innerRef}>
            {modifiedStructure?.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={index === 0 || isViewMode || (isIntro && index === 1) || (isIntro && index === 2)}
                >
                  {(providedItem, snapshotItem) =>
                    item.templateType === TemplateTypes.PAGE ? (
                      <AppBox
                        as={Page}
                        disabled={isViewMode}
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                        isDragging={snapshotItem.isDragging}
                        style={providedItem.draggableProps.style}
                        isEmptyCard={isEmptyCard}
                      >
                        <PageTop>
                          <AppText
                            {...providedItem.dragHandleProps}
                            textValue={getTextValue(item)}
                            type='secondary'
                            style={{ fontSize: '12px' }}
                            onContextMenu={event => handleOpenContextMenu(event, item?.id, index)}
                          />
                          {item?.audio ? (
                            <AudioPlayerDropdown
                              itemIndex={index}
                              item={item}
                              questionnaireStructure={questionnaireStructure}
                              audioObject={item.audio}
                            />
                          ) : (
                            <AudioLoader index={index} />
                          )}
                          {showContextMenu(item) && (
                            <StyledContextMenu ref={contextMenuRef}>
                              <AppText
                                onClick={event => deleteItem(event, item.id, questionnaireStructure, setIsOpenContextMenu, dispatch)}
                                {...providedItem.dragHandleProps}
                                textValue={navigatorTab.delete}
                                type='secondary'
                                style={contextMenuTextStyle}
                              />
                            </StyledContextMenu>
                          )}
                        </PageTop>
                        {isEmptyCard && <EmptyCard isDraggingOver={snapshot.isDraggingOver} />}
                      </AppBox>
                    ) : (
                      <Item
                        id={item.id}
                        onClick={() => {
                          dispatch(selectQuestionnaireElement({ item, index }));
                          dispatch(selectPreviewQuestionnaireElement({ selectedQuestionnaireElement: item, finderId: finder.id }));
                        }}
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                        isDragging={snapshotItem.isDragging}
                        style={providedItem.draggableProps.style}
                      >
                        <Container>
                          {item.templateType === TemplateTypes.CHAPTER ? (
                            <Chapter item={item} providedItem={providedItem} itemIndex={index} firstChapter={firstChapter} />
                          ) : (
                            <CardCollapse item={item} providedItem={providedItem} itemIndex={index} />
                          )}
                        </Container>
                      </Item>
                    )
                  }
                </Draggable>
              );
            })}
          </Items>
        )}
      </Droppable>
    </AppContent>
  );
}

const StyledLoaderWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Items = styled('div')({
  listStyle: 'none',
  paddingBottom: 1000,
});

const Page = styled('div')(props => ({
  paddingTop: '8px',
  backgroundImage: `linear-gradient(to right, ${props.isDragging ? '#da1b5e' : '#B3B3BB'} 45%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: 'top',
  backgroundSize: '4px 1px',
  backgroundRepeat: 'repeat-x',
  paddingBottom: !props.isEmptyCard && '20px',
}));

const PageTop = styled('div')({
  height: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
});

const Container = styled('div')({
  margin: '0 auto',
  width: '75%',
});

const Item = styled('div')({
  marginBottom: 32,
  scrollMarginTop: 20,
});

const StyledContextMenu = styled('div')({
  color: '#000',
  zIndex: 1,
  position: 'absolute',
  minWidth: 100,
  backgroundColor: 'rgb(242, 242, 243)',
  borderRadius: 8,
  top: '50%',
  left: 50,
  transform: 'translateY(-50%)',
  padding: '8px 16px',
  textAlign: 'center',
});
