/* eslint-disable no-magic-numbers,i18n-text/no-en */
import { useMemo, useEffect } from 'react';
import { FirstChapter } from './FirstChapter';
import { PreviewNotFirstChapter } from '../PreviewNotFirstChapter';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { ChapterState, DestinationVariants } from '../../../../../../../../lib/models/TemplateTypes';
import { CompletedChapters } from './CompletedChapters';
import { sendDataToAnalytics } from '../../../../utils/sendDataToAnalytics';
import { setNextFlow } from '../../hooks/setNextFlow';
import styles from './styles.module.scss';

// eslint-disable-next-line max-lines-per-function
export function PreviewChapterLayout({
  colors,
  fonts,
  pagesFlow,
  setPagesFlow,
  style,
  media,
  bulletIcons,
  setPageHistory,
  showChapterIds,
  imageSelectionFlow,
  currentElements,
  showCompletedChapters,
  setShowCompletedChapters,
}) {
  const { allPages, allChapters, currentChapterId } = pagesFlow;
  const allChaptersWithoutCompletedChapters = allChapters.filter(item => !item.content.completedChaptersButtons);
  const completedChapters = allChapters.filter(item => item.content.completedChaptersButtons);
  const otherChapters = useMemo(
    () => (isLength(allChaptersWithoutCompletedChapters) ? allChaptersWithoutCompletedChapters.slice(1) : []),
    [allChaptersWithoutCompletedChapters]
  );
  const { windowWidth } = useWindowResize();

  const { rootStyle = {} } = style;

  const showOtherChapters = otherChapters.filter(chap => showChapterIds.includes(chap.id));

  const isOddChapters = useMemo(() => otherChapters.length % 2 !== 0 && windowWidth < 1200, [otherChapters, windowWidth]);
  const isCompletedChapters = useMemo(() => completedChapters.some(el => el.id === currentChapterId), [completedChapters, currentChapterId]);

  useEffect(() => {
    if (isCompletedChapters) {
      setShowCompletedChapters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletedChapters]);

  const chapterOnClick = (chapterId, flowObj, rules, stateObj, pageUid, chapterIndex) => {
    sendDataToAnalytics('Open chapter', chapterIndex);
    if ([ChapterState.COMPLETED, ChapterState.OPENED].includes(stateObj)) {
      const nextPage =
        flowObj?.goTo === DestinationVariants.NEXT
          ? allPages.find(page => page.id === pageUid)
          : allPages.find(page => page.elements.find(el => el.id === flowObj?.goTo));

      setNextFlow(flowObj, rules, nextPage, chapterId, pagesFlow, setPagesFlow, setPageHistory, currentElements);
    }
  };

  return (
    <section
      style={{
        display: 'flex',
        flexShrink: 0,
        flexGrow: 1,
        backgroundColor: colors.backgroundColor,
        width: '100%',
      }}
    >
      <div className={styles.chapterContainer} style={{ ...rootStyle }}>
        <FirstChapter
          colors={colors}
          fonts={fonts}
          pagesFlow={pagesFlow}
          setPagesFlow={setPagesFlow}
          style={style}
          media={media}
          bulletIcons={bulletIcons}
          chapterOnClick={chapterOnClick}
          setPageHistory={setPageHistory}
          imageSelectionFlow={imageSelectionFlow}
          currentElements={currentElements}
        />

        <div className={styles.notFirstChaptersContainer}>
          {showOtherChapters.map((chapter, index) => (
            <PreviewNotFirstChapter
              key={chapter.id}
              chapter={chapter}
              chaptersLength={showOtherChapters.length}
              pagesFlow={pagesFlow}
              style={style}
              colors={colors}
              fonts={fonts}
              mediaOptions={media}
              chapterOnClick={chapterOnClick}
              chapterIndex={index + 2}
              imageSelectionFlow={imageSelectionFlow}
            />
          ))}
          {isOddChapters && <div style={{ flex: '1 1 33.33%' }} />}
        </div>
        {(isCompletedChapters || showCompletedChapters) && (
          <CompletedChapters
            completedChapters={completedChapters}
            pagesFlow={pagesFlow}
            setPagesFlow={setPagesFlow}
            colors={colors}
            fonts={fonts}
            setPageHistory={setPageHistory}
            currentElements={currentElements}
          />
        )}
      </div>
    </section>
  );
}
