import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../../ui/AppFormSection';

export function QuestionnaireFormSection({ textFieldProps, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { disabled, ...restTextFieldProps } = textFieldProps;

  return (
    <AppFormSection
      {...rest}
      textFieldProps={{
        disabled: isViewMode || disabled,
        ...restTextFieldProps,
      }}
    />
  );
}
