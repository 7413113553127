export function RuleBuilderIcon({ width = 16, height = 16, ...rest }) {
  return (
    <svg width={width} height={height} {...rest} viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M45.5707 25.4808C41.9909 25.4808 39.2855 27.5369 37.8995 29.0529C36.0615 25.714 32.602 25.4808 31.5159 25.4808H31.5142C30.6081 25.4808 29.8744 26.2153 29.8744 27.1214C29.8744 28.0275 30.6099 28.762 31.5159 28.762C34.7581 28.762 35.9058 31.3704 35.3869 33.7636C35.3844 33.7739 35.382 33.7842 35.3797 33.7946H35.3796C34.6875 36.9085 31.4865 39.5391 28.3896 39.5391C27.4836 39.5391 26.749 40.2736 26.749 41.1797C26.749 42.0858 27.4836 42.8203 28.3896 42.8203C31.9707 42.8203 34.6766 40.7633 36.0631 39.2467C37.9012 42.5866 41.3611 42.8203 42.4481 42.8203C43.3541 42.8203 44.0887 42.0858 44.0887 41.1797C44.0887 40.2736 43.3541 39.5391 42.4481 39.5391C39.1667 39.5391 38.0679 36.885 38.5753 34.54C38.5781 34.5282 38.581 34.5162 38.5836 34.5043C39.2748 31.3917 42.4744 28.762 45.5707 28.762H45.5725C46.4785 28.762 47.2122 28.0275 47.2122 27.1214C47.2122 26.2153 46.4769 25.4808 45.5707 25.4808Z'
        fill='white'
      />
      <path
        d='M27.8831 27.1214C27.8831 26.2153 27.1486 25.4808 26.2425 25.4808H23.016C23.0787 25.1988 23.1413 24.9175 23.2034 24.6376L24.9156 16.9332C25.3418 15.0131 27.0387 12.9464 29.757 12.9464C31.6217 12.9464 32.6741 14.3395 32.256 16.222C32.0596 17.1065 32.6174 17.9828 33.5019 18.1793C34.3868 18.3756 35.2629 17.8179 35.4592 16.9333C36.3184 13.0651 33.7863 9.66516 29.757 9.66516C25.2299 9.66516 22.4133 13.0641 21.7123 16.2218L20.0002 23.9258C19.8858 24.4407 19.7704 24.9599 19.6546 25.4809H15.6986C14.7926 25.4809 14.058 26.2155 14.058 27.1215C14.058 28.0276 14.7926 28.7621 15.6986 28.7621H18.9253C18.3918 31.1629 17.872 33.5018 17.4167 35.5516C16.755 38.5253 13.692 41.2964 10.4268 41.2964C9.5207 41.2964 8.78613 42.031 8.78613 42.937C8.78613 43.8431 9.5207 44.5777 10.4268 44.5777C15.0919 44.5777 19.5907 40.888 20.6197 36.2636C21.1201 34.0114 21.698 31.4107 22.2866 28.762H26.2424C27.1486 28.762 27.8831 28.0275 27.8831 27.1214Z'
        fill='white'
      />
      <path
        d='M45.5711 25.4808C41.9912 25.4808 39.2859 27.5369 37.8999 29.0529C36.0619 25.714 32.6024 25.4808 31.5163 25.4808H31.5145C30.6085 25.4808 29.8748 26.2153 29.8748 27.1214C29.8748 28.0275 30.6102 28.762 31.5163 28.762C34.7585 28.762 35.9062 31.3703 35.3873 33.7636C35.3848 33.7739 35.3824 33.7842 35.3801 33.7946H35.38C34.6878 36.9085 31.4869 39.5391 28.39 39.5391C28.2554 39.5391 28.1252 39.5571 28 39.5876V42.7716C28.1252 42.8023 28.2554 42.8202 28.39 42.8202C31.9711 42.8202 34.677 40.7632 36.0635 39.2466C37.9016 42.5865 41.3615 42.8202 42.4484 42.8202C43.3545 42.8202 44.0891 42.0856 44.0891 41.1796C44.0891 40.2735 43.3545 39.539 42.4484 39.539C39.1671 39.539 38.0683 36.8849 38.5757 34.5399C38.5785 34.5281 38.5814 34.5161 38.584 34.5042C39.2751 31.3916 42.4748 28.7619 45.5711 28.7619H45.5728C46.4789 28.7619 47.2126 28.0274 47.2126 27.1213C47.2126 26.2152 46.4773 25.4808 45.5711 25.4808Z'
        fill='white'
      />
      <path
        d='M29.7573 9.66504C29.1389 9.66504 28.5535 9.73045 28 9.84802V13.2665C28.5264 13.0636 29.1128 12.9464 29.7573 12.9464C31.622 12.9464 32.6744 14.3395 32.2563 16.222C32.0599 17.1065 32.6177 17.9828 33.5022 18.1793C34.3871 18.3756 35.2632 17.8179 35.4595 16.9333C36.3187 13.065 33.7867 9.66504 29.7573 9.66504Z'
        fill='white'
      />
    </svg>
  );
}
