import { SORT_ORDERS } from '../../../lib/consts';
import { appDayJs } from '../../../../../lib/models/Time';

export function formatDateForTable(date) {
  return date ? appDayJs(date).utc().format('MMM DD YYYY') : undefined;
}

export function getDatesRange(fromDate, toDate) {
  return `${formatDateForTable(fromDate)} / ${formatDateForTable(toDate)}`;
}

/**
 * @param { string[] } sortFieldNames
 * @param { SortOrder } sortOrder
 */
export function formatSortQuery(sortFieldNames, sortOrder) {
  const source = Array.isArray(sortFieldNames) ? sortFieldNames : [sortFieldNames];
  const joinedFieldNames = source.join(',');
  const withSortOrder = `,${sortOrder}`;

  return SORT_ORDERS.includes(sortOrder) ? `${joinedFieldNames}${withSortOrder}` : joinedFieldNames;
}

/**
 * @desc Converts a hexadecimal color value to its RGBA equivalent.
 * @param {string} hex - The hexadecimal color value (e.g., "#RRGGBB").
 * @param {number} [alpha=1] - The alpha value for the RGBA color (optional, defaults to 1).
 * @returns {string} - The RGBA color value (e.g., "rgba(255,255,255,1)").
 */
export const hex2rgba = (hex, alpha = 1) => {
  const [red, green, blue] = hex.match(/\w\w/g).map(x => Number.parseInt(x, 16));
  return `rgba(${red},${green},${blue},${alpha})`;
};

export const formatHref = href => {
  if (!href) return href;
  const stringHref = String(href);

  const isHrefAbsolute = stringHref?.includes('http://') || stringHref?.includes('https://');
  return isHrefAbsolute ? stringHref : `http://${stringHref}`;
};
