import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../../processes/management/pages/QuestionnairePage/features/QuestionnaireSelect';
import { handleTranslation, isString } from '../../../../../lib/util/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { QuestionnaireVersion } from '../../../../../lib/models/Questionnaire';
import { setQuestionnaireBasedOnVersion, setSelectedQuestionnaireVersion } from '../../../../../lib/store/slices/findersSlice';
import { VersionsMenuInner } from './components/VersionsMenuInner';
import './QuestionnaireVersions.sass';
import { changePreviewStructureVersion } from '../../../../../lib/store/slices/previewSlice';

export function QuestionnaireVersions() {
  const dispatch = useDispatch();
  const { questionnaireVersions } = useLocalization().translations.unique.components;
  const { finder, selectedQuestionnaireVersion } = useSelector(({ finders }) => finders);
  const publishedQuestionnaire = finder.questionnaire;
  const hasQuestionnaireNotBeenPublished = publishedQuestionnaire === null;
  const areVersionsFilled = isString(publishedQuestionnaire) && isString(finder.questionnaireDraft);
  const areVersionEqual = publishedQuestionnaire === finder.questionnaireDraft;
  const disabled = hasQuestionnaireNotBeenPublished || areVersionEqual;

  const options = [
    {
      value: QuestionnaireVersion.DRAFT,
      title: handleTranslation(questionnaireVersions.draft),
      personName: finder.lastModifiedBy,
      modifyingTime: finder.lastModifiedDate,
    },
    {
      value: QuestionnaireVersion.PUBLISHED,
      title: handleTranslation(questionnaireVersions.published),
      personName: finder.lastPublishedByUser?.login,
      modifyingTime: finder.publishingDate,
    },
  ];

  const onChange = selected => {
    dispatch(setQuestionnaireBasedOnVersion(selected.value));
    dispatch(changePreviewStructureVersion({ version: selected.value, finderId: finder.id }));
  };

  useEffect(() => {
    if (areVersionsFilled) {
      dispatch(setSelectedQuestionnaireVersion(areVersionEqual ? QuestionnaireVersion.PUBLISHED : QuestionnaireVersion.DRAFT));
      dispatch(changePreviewStructureVersion({ version: QuestionnaireVersion.DRAFT, finderId: finder.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areVersionsFilled, areVersionEqual]);

  useEffect(() => {
    if (hasQuestionnaireNotBeenPublished) {
      dispatch(setSelectedQuestionnaireVersion(QuestionnaireVersion.DRAFT));
      dispatch(changePreviewStructureVersion({ version: QuestionnaireVersion.DRAFT, finderId: finder.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQuestionnaireNotBeenPublished]);

  return (
    <QuestionnaireSelect
      size='small'
      style={{ marginBottom: 0 }}
      formItemProps={{ style: { minHeight: 'unset' } }}
      selectProps={{
        className: 'QuestionnaireVersions',
        fullWidth: true,
        components: {
          MenuList: VersionsMenuInner,
          ...(disabled && { DropdownIndicator: () => null }),
        },
        options,
        onChange,
        disabled,
        value: { value: selectedQuestionnaireVersion },
      }}
    />
  );
}
