import { ArrangeFormItem } from './model/Arrange';
import { TableSortOrder } from '../../../lib/consts';
import { isLength } from '../../../../../lib/util/helpers';

export function clearListFilters(form) {
  form.setFieldValue(ArrangeFormItem.SEARCH, '');
  form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, []);
}

export function clearDateFilters(form) {
  form.setFieldValue(ArrangeFormItem.DATES, []);
}

export const LIST_LABEL_PROP_NAME = 'label';
export const LIST_VALUE_PROP_NAME = 'value';

const getSelectedItems = form => form.getFieldValue(ArrangeFormItem.SELECTED_ITEMS);

export function getParsedSelectedItems(form) {
  return (getSelectedItems(form) || []).map(JSON.parse);
}

export function getSelectedItemsLabels(form) {
  return getParsedSelectedItems(form).map(item => item[LIST_LABEL_PROP_NAME]);
}

export function getSelectedItemsValues(form) {
  return getParsedSelectedItems(form).map(item => item[LIST_VALUE_PROP_NAME]);
}

export function getArrangesInitialValues(form) {
  return {
    [ArrangeFormItem.SORT_ORDER]: form.getFieldValue(ArrangeFormItem.SORT_ORDER) || TableSortOrder.NONE,
  };
}

export function normalizeFilters(filters) {
  return Object.fromEntries(Object.entries(filters).filter(([_key, value]) => (Array.isArray(value) ? isLength(value) : value !== undefined)));
}

export function createOptionItem(label, value) {
  return { value: JSON.stringify({ [LIST_VALUE_PROP_NAME]: value, [LIST_LABEL_PROP_NAME]: label }), label };
}

export function formatArrangedData(data, apiFieldName) {
  return data.content.map(item => ({
    value: JSON.stringify({ [LIST_LABEL_PROP_NAME]: item[apiFieldName], [LIST_VALUE_PROP_NAME]: item.id }),
    label: item[apiFieldName],
  }));
}
