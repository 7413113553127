import { memo } from 'react';

import { ConditionHeader } from './ConditionHeader';
import { ConditionBody } from './ConditionBody';

import styles from '../BuilderPage.module.scss';

/**
 * @desc Condition
 * @param {Object} condition
 * @param {Number} index
 * @param {Boolean} isLastItem
 * @returns {JSX.Element}
 */
const Condition = memo(({ condition, index, isLastItem }) => {
  return (
    <div className={styles.condition}>
      <ConditionHeader condition={condition} index={index} />
      <ConditionBody condition={condition} isLastItem={isLastItem} index={index} />
    </div>
  );
});

export { Condition };
