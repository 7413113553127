import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';
import styled from 'styled-components';
import { ExternalLinkIcon } from '../../../../../../../../../ui/icons/ExternalLinkIcon';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';
import { useProductRowPrice } from '../../../hooks/useProductRowPrice';
import { ResultProductTitle } from './ResultProductTitle';
import { getRulesProductPrice } from '../../../../../utils/rules/product_price_rules_helper';

export function PreviewResultProductTableRow({ product, productInformation, fonts, colors, pagesFlow, ...rest }) {
  const { windowWidth } = useWindowResize();
  const { showPrice, rulePrice, rulePriceType } = getRulesProductPrice(product, pagesFlow, [], false);
  const price = useProductRowPrice(product, productInformation, showPrice, rulePrice, rulePriceType);

  return (
    <Root {...rest} windowWidth={windowWidth}>
      <StyledProductLeft>
        {/* REFACTOR: "alt" tag */}
        {productInformation?.picture && <StyledImage src={product?.image} alt='img' windowWidth={windowWidth} />}
        <StyledProductLeftMain>
          <ResultProductTitle productInformation={productInformation} product={product} colors={colors} fonts={fonts} />
          {productInformation.externalProductPageLink && (
            <a target='_blank' rel='noopener noreferrer' href={product?.productPageLink} style={{ display: 'flex' }}>
              <IconComponent $color={colors?.[ColorVariants.SECONDARY_SECOND]} />
            </a>
          )}
        </StyledProductLeftMain>
      </StyledProductLeft>
      <StyledPrice
        fontSize={windowWidth > AppBreakpoint.M ? fonts?.body?.fontSize : fonts?.subText?.fontSize}
        fontFamily={fonts?.body?.font}
        color={colors?.[fonts?.body?.colorVariant]}
        windowWidth={windowWidth}
      >
        {price}
      </StyledPrice>
    </Root>
  );
}

const Root = styled('li')({
  backgroundColor: '#fff',
  padding: '5px !important',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '4px !important',
  gap: '10px',
});

const StyledProductLeft = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledProductLeftMain = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const StyledImage = styled('img')(props => ({
  width: props.windowWidth > AppBreakpoint.M ? '80px' : '40px',
  height: props.windowWidth > AppBreakpoint.M ? '80px' : '40px',
  borderRadius: '4px',
  marginRight: props.windowWidth > AppBreakpoint.M ? '33px' : '10px',
}));

const IconComponent = styled(ExternalLinkIcon)({
  '&, *': {
    fill: '#DA1B5E',
  },
});

const StyledPrice = styled('div')(props => ({
  marginRight: props.windowWidth > AppBreakpoint.M && '20px',
  fontSize: props.fontSize,
  fontFamily: props.fontFamily,
  fontWeight: 700,
  color: props.color,
  marginBottom: 0,
  textAlign: 'right',
  flex: '1 1 40%',
}));
