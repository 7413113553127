import { useEffect } from 'react';
import { Form } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppBox } from '../../../../../../../../ui/boxes/AppBox';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../../../lib/models/Form';
import { TenantListFormItem } from '../../../../features/form-items/TenantListFormItem';
import { FindersListFormItem } from '../../../../features/form-items/FindersListFormItem';
import { useTenantNames } from '../../../UsersPage/lib/hooks/useTenantNames';
import { useFindersList } from '../hooks/useFindersList';
import { trimForm } from '../../../../../../../../lib/util/helpers';
import { duplicateRuleRequest } from '../../../../../../../../lib/store/slices/rulesSlice';

/**
 * @des Component for duplicating a rule with form inputs.
 * @param {Object} props - Component props.
 * @param {Object} props.form - Ant Design form instance.
 * @param {Object} props.ruleData - Data of the rule to be duplicated.
 * @returns {JSX.Element} The JSX for the duplicate rule form component.
 */
export function DuplicateRuleForm({ form, ruleData }) {
  const dispatch = useDispatch();
  const { tenant: tenantText, findersList } = useLocalization().translations.unique.formItems;
  const { tenantNames } = useTenantNames();
  const { findersNames } = useFindersList(form);
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));

  const onFinish = values => {
    const ruleObj = {
      name: ruleData.name,
      ruleId: ruleData.id,
      targetFinderIds: values.findersNames.map(finder => finder.value),
      sourceTenantId: tenantId,
    };

    const trimmedForm = trimForm(ruleObj);
    dispatch(duplicateRuleRequest({ rule: trimmedForm, tenantId: values.tenant.value }));
  };

  const selectedTenant = Form.useWatch(FormItemName.TENANT, form);

  useEffect(() => {
    form.setFieldValue(FormItemName.FINDERS_NAMES, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  return (
    <Form form={form} onFinish={onFinish}>
      <AppBox style={{ marginBottom: 40 }}>
        <TenantListFormItem formItemProps={{ name: FormItemName.TENANT }} textFieldProps={{ options: tenantNames, label: tenantText.label }} />
        <FindersListFormItem errorMessage={findersList.errors.required()} textFieldProps={{ options: findersNames, label: findersList.label }} />
      </AppBox>
    </Form>
  );
}
