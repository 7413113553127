import { InfoIconComponent } from '../../InfoIconComponent';

export function ResultHeadline({ fonts = {}, colors = {}, headline = {}, headlineStyle, showInfoIcon = false, applyVariable }) {
  return (
    headline?.value && (
      <h2
        style={{
          ...fonts?.headlineSecond,
          marginBottom: 15,
          overflow: 'hidden',
          width: '100%',
          textAlign: 'center',
          fontSize: fonts?.headlineSecond?.fontSize,
          fontFamily: fonts?.headlineSecond.font,
          fontWeight: fonts?.headlineSecond?.fontWeight,
          color: colors?.[fonts?.headlineSecond?.colorVariant],
          ...headlineStyle,
        }}
      >
        {applyVariable(headline?.value)}
        {showInfoIcon && <InfoIconComponent applyVariable={applyVariable} type={headline} />}
      </h2>
    )
  );
}
