import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../../../../ui/AppNewSelect';
import { ContactFormField } from '../../../../../../../../../lib/models/Questionnaire';
import { PHONE_NUMBER_PATTERN, EMAIL_SIMPLE_PATTERN } from '../../../../../../../../../lib/util/regexp';
import styles from './styles.module.scss';

export function DynamicContactFormInputOrDropdown({ fieldObject, fonts, colors, disabled, applyVariable }) {
  const dropdownOptions = fieldObject?.optionList.map(option => ({ title: applyVariable(option.title), value: option.id }));

  return (
    <AppFormSection
      className={fieldObject.isFullWidth ? styles.input_full_width : styles.input_half_width}
      style={{
        fontSize: fonts.body.fontSize,
        fontFamily: fonts.body.font,
        fontWeight: fonts.body.fontWeight,
        color: colors[fonts.body.colorVariant],
      }}
      formItemProps={{
        name: fieldObject.id,
        rules:
          fieldObject.type === ContactFormField.PHONE_NUMBER
            ? [{ required: fieldObject?.required }, { pattern: PHONE_NUMBER_PATTERN }]
            : fieldObject.type === ContactFormField.EMAIL
            ? [{ required: fieldObject?.required }, { pattern: EMAIL_SIMPLE_PATTERN }]
            : [{ required: fieldObject?.required }],
      }}
      textFieldProps={{
        label: applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name),
        disabled,
        style: {
          fontSize: fonts.body.fontSize,
          fontFamily: fonts.body.font,
          fontWeight: fonts.body.fontWeight,
          color: colors[fonts.body.colorVariant],
        },
      }}
    >
      {[ContactFormField.DROPDOWN, ContactFormField.DROPDOWN_WITH_ACTION].includes(fieldObject.type) && (
        <AppNewSelect
          label={applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name)}
          disabled={disabled}
          options={dropdownOptions}
        />
      )}
    </AppFormSection>
  );
}
