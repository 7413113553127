import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { FormItemName } from '../../../../../../lib/models/Form';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function FindersListFormItem({ formItemProps = {}, textFieldProps = {}, errorMessage }) {
  return (
    <AppFormSection
      formItemProps={{
        name: FormItemName.FINDERS_NAMES,
        rules: [{ required: true, message: errorMessage }],
        ...formItemProps,
      }}
    >
      <AppNewSelect isMultiSelect {...textFieldProps} />
    </AppFormSection>
  );
}
