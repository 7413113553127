import { AppNewSelect } from 'src/ui/AppNewSelect';
import { FlowImageSelectionOption } from './FlowImageSelectionOption';

import { useElements } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useElements';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';
import { pictureTypes, mapSelectOptions } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import styles from '../ConditionItem.module.scss';
import { useSelector } from 'react-redux';

const pictureTypeOptions = mapSelectOptions(Object.values(pictureTypes));

/**
 * @desc Flow Image Selection
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function FlowImageSelection({ value, onChange }) {
  const { chapterOptions } = useElements();
  const { handleValueChange } = useConditionResult({
    value,
    onChange,
  });
  const {
    finder: {
      finderSettings: { media },
    },
  } = useSelector(({ finders }) => finders);

  const { chapter, pictureType, picture, property, elementType } = value;
  const selectedChapter = chapterOptions.find(chapterOption => chapterOption.value === chapter?.value);
  const picturesArray = (pictureType && pictureType === pictureTypes.active ? selectedChapter?.activeImages : selectedChapter?.inactiveImages) || [];
  const imageOptions = [];
  const filteredImages = picturesArray.filter(imageId => imageId !== 'lowSaturation' && imageId !== '');

  for (const imageId of filteredImages) {
    const imageData = media.find(mediaItem => mediaItem.id === imageId);
    const { s3Url, originalFileName } = imageData || {};

    if (s3Url) {
      imageOptions.push({
        title: originalFileName,
        value: imageId,
        imageUrl: s3Url,
      });
    }
  }

  return (
    <>
      <AppNewSelect
        label='Select chapter'
        className={`${styles.largeSelect} ${styles.selectItem}`}
        value={chapter}
        titleSearch
        onChange={value => {
          onChange({
            property,
            elementType,
            pictureType: null,
            chapter: value,
            picture: null,
          });
        }}
        options={chapterOptions}
      />
      {chapter && (
        <AppNewSelect
          label='Active/Inactive picture'
          titleSearch
          className={`${styles.elementSelect} ${styles.selectItem}`}
          value={pictureType}
          onChange={({ value }) => {
            onChange({
              property,
              elementType,
              chapter,
              pictureType: value,
              picture: null,
            });
          }}
          options={pictureTypeOptions}
        />
      )}
      {pictureType && (
        <AppNewSelect
          label='Set picture'
          titleSearch
          fullWidth
          className={`${styles.elementSelect} ${styles.selectItem}`}
          value={picture}
          onChange={value => {
            handleValueChange('picture', value);
          }}
          options={imageOptions}
          components={{ Option: FlowImageSelectionOption }}
        />
      )}
    </>
  );
}
