import './styles.scss';

export function BulletIconComponent({ icon, color }) {
  const createSvg = () => {
    return { __html: icon.svgContent };
  };

  return icon.svgContent && !icon.svgContent.includes('fill') ? (
    // eslint-disable-next-line react/no-danger
    <div className='bullet_component_icon_svg' dangerouslySetInnerHTML={createSvg()} style={{ fill: color || '#000000' }} />
  ) : (
    <img className='bullet_component_icon_image' src={icon.s3Url} alt='bullet icon' />
  );
}
