import { QuestionnaireVerticalGripHandle } from '../../../../../../../../features/QuestionnaireVerticalGripHandle';
import { TextFieldWithInfoIcon } from '../../../../../../../../features/TextFieldWithInfoIcon';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { humanizeIndex } from '../../../../../../../../../../../../../../lib/util/helpers';
import { DeleteProductBulletPointButton } from './DeleteProductBulletPointButton';

export function ProductBulletPointRow({
  providedDraggable,
  productIndex,
  structureItemIndex,
  item,
  index,
  dataPath,
  rightColumnType,
  bulletPointIcon,
  bulletPointIconColor,
  disabled,
  ...rest
}) {
  const { label } = useLocalization().translations.unique.formItems.bulletPoint;
  const itemPath = `${dataPath}[${index}]`;
  const genericFieldsProps = { style: { width: '100%' } };
  const bulletPointNumber = humanizeIndex(index);

  return (
    <Root {...rest}>
      <Handle disabled={disabled} dragHandleProps={providedDraggable.dragHandleProps} />
      <TextFieldWithInfoIcon
        disabled={disabled}
        label={label({ index: bulletPointNumber })}
        data={item}
        dataPath={String(itemPath)}
        {...genericFieldsProps}
      />
      <DeleteProductBulletPointButton bulletPointIndex={index} dataPath={dataPath} disabled={disabled} />
    </Root>
  );
}

const Root = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  columnGap: '18px',
  marginLeft: 20,

  '& > *:first-child': { flex: 6.5, display: 'flex' },
  '& > *:last-child': { flex: 3.5, display: 'flex', justifyContent: 'flex-end' },
});

const Handle = styled(QuestionnaireVerticalGripHandle)({
  position: 'absolute',
  top: '11px',
  left: '-20px',
  cursor: 'pointer',
});
