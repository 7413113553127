import { components } from 'react-select';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { isLength } from '../../../../../../../../../../lib/util/helpers';

export function ProductFieldsValueContainer({ children, ...props }) {
  const { contentTable } = useLocalization().translations.unique.pages.questionnaire;
  const selectedFields = props.selectProps.value;

  return (
    <components.ValueContainer {...props}>
      {isLength(selectedFields) && <AppText textValue={contentTable.fieldsSelected({ amount: selectedFields.length })} style={{ color: '#fff' }} />}
      {children}
    </components.ValueContainer>
  );
}
