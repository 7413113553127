/* eslint-disable max-lines */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { hideNotification } from '../../lib/store/slices/notificationsSlice';
import { AppText } from '../../ui/text/AppText';
import { AppParagraph } from '../../ui/text/AppParagraph';
import { ErrorIcon } from '../../ui/icons/ErrorIcon';
import { SuccessIcon } from '../../ui/icons/SuccessIcon';
import { CrossIcon } from '../../ui/icons/CrossIcon';
import {
  addThemeSuccess,
  deleteThemeSuccess,
  duplicateThemeSuccess,
  editThemeSuccess,
  setThemeStatusSuccess,
} from '../../lib/store/slices/themesSlice';
import { addTemplateSuccess, deleteTemplateSuccess, reUploadTemplateSuccess, setTemplateStatusSuccess } from '../../lib/store/slices/templatesSlice';
import { addUserSuccess, deleteUserSuccess, editUserSuccess, setUserStatusSuccess } from '../../lib/store/slices/usersSlice';
import { addTenantSuccess, deleteTenantSuccess, editTenantSuccess, setTenantStatusSuccess } from '../../lib/store/slices/tenantsSlice';
import { addProductSuccess, deleteProductSuccess, editProductSuccess } from '../../lib/store/slices/productsSlice';
import { addCategorySuccess, deleteCategorySuccess, editCategorySuccess } from '../../lib/store/slices/categoriesSlice';
import {
  addFinderSuccess,
  checkSMTPSuccess,
  deleteFinderSuccess,
  duplicateFinderSuccess,
  editFinderDesignSettingsSuccess,
  editFinderGeneralSettingsSuccess,
  editFinderSuccess,
  publishFinderSuccess,
  setFinderStatusSuccess,
} from '../../lib/store/slices/findersSlice';
import { useLocalization } from '../../lib/hooks/useLocalization';
import { handleTranslation } from '../../lib/util/helpers';
import { checkIsHttpFrontEndError } from '../../lib/util/http/http-helpers';
import { addLayoutSuccess, deleteLayoutSuccess, reUploadLayoutSuccess, setLayoutStatusSuccess } from '../../lib/store/slices/layoutsSlice';
import { deleteRuleSuccess, setRuleStatusSuccess, addRuleSuccess, editRuleSuccess, duplicateRuleSuccess } from '../../lib/store/slices/rulesSlice';

const appSuccessNotificationConfig = {
  icon: <SuccessIcon />,
  closeIcon: <CrossIcon styles='white_color' />,
  duration: 5,
};

const appErrorNotificationConfig = {
  icon: <ErrorIcon />,
  closeIcon: <CrossIcon styles='white_color' />,
  duration: 5,
};

const tenantDeleteErrorDescription = (firstParagraph, secondParagraph, errorMessage) => (
  <>
    <AppParagraph className='white_color' textValue={firstParagraph} />
    <AppParagraph className='white_color' style={{ fontSize: 12, marginBottom: 2 }} textValue={secondParagraph} />
    <AppText className='white_color bold' style={{ fontSize: 12 }} textValue={errorMessage} />
  </>
);

const publishFinderWarningDescription = (firstParagraph, secondParagraph, url) => (
  <>
    <AppParagraph className='white_color' textValue={firstParagraph} />
    <AppParagraph style={{ marginBottom: 2 }} textValue={secondParagraph}>
      <AppText className='white_color' style={{ fontSize: 12 }} textValue={secondParagraph.begin} />
      <a href={url}>
        <AppText className='white_color bold' style={{ fontSize: 12, textDecoration: 'underline' }} textValue={secondParagraph.settings} />
      </a>
      <AppText className='white_color' style={{ fontSize: 12 }} textValue={secondParagraph.end} />
    </AppParagraph>
  </>
);

function handleErrorText(error, httpErrorsText) {
  let message = '';
  let description = '';

  if (checkIsHttpFrontEndError(error)) {
    message = handleTranslation(httpErrorsText.frontEnd.title);
    description = handleTranslation(httpErrorsText.frontEnd.description);
  } else {
    message = error?.title || error?.message;
    description = error?.detail;
  }

  return { message, description };
}

// eslint-disable-next-line max-lines-per-function
export function AppNotification() {
  const { success, error, isShow } = useSelector(({ notifications }) => notifications);
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { translations } = useLocalization();
  const { httpErrors: httpErrorsText, notifications: notificationsText } = translations.shared;
  const { themes, templates, layouts, users, tenants, products, finders, categories, rules } = notificationsText.success;

  const successMessages = {
    [addThemeSuccess.type]: themes.add,
    [editThemeSuccess.type]: themes.edit,
    [deleteThemeSuccess.type]: themes.delete,
    [duplicateThemeSuccess.type]: themes.duplicate,
    [setThemeStatusSuccess.type]: themes.status,
    [addTemplateSuccess.type]: templates.add,
    [reUploadTemplateSuccess.type]: templates.reUpload,
    [deleteTemplateSuccess.type]: templates.delete,
    [setTemplateStatusSuccess.type]: templates.status,
    [addLayoutSuccess.type]: layouts.add,
    [reUploadLayoutSuccess.type]: layouts.reUpload,
    [deleteLayoutSuccess.type]: layouts.delete,
    [setLayoutStatusSuccess.type]: layouts.status,
    [addUserSuccess.type]: users.add,
    [editUserSuccess.type]: users.edit,
    [deleteUserSuccess.type]: users.delete,
    [setUserStatusSuccess.type]: users.status,
    [addTenantSuccess.type]: tenants.add,
    [editTenantSuccess.type]: tenants.edit,
    [deleteTenantSuccess.type]: tenants.delete,
    [setTenantStatusSuccess.type]: tenants.status,
    [addProductSuccess.type]: products.add,
    [editProductSuccess.type]: products.edit,
    [deleteProductSuccess.type]: products.delete,
    [addCategorySuccess.type]: categories.add,
    [editCategorySuccess.type]: categories.edit,
    [deleteCategorySuccess.type]: categories.delete,
    [addFinderSuccess.type]: finders.add,
    [editFinderSuccess.type]: finders.edit,
    [deleteFinderSuccess.type]: finders.delete,
    [duplicateFinderSuccess.type]: finders.duplicate,
    [setFinderStatusSuccess.type]: finders.status,
    [editFinderDesignSettingsSuccess.type]: finders.editDesignSettings,
    [editFinderGeneralSettingsSuccess.type]: finders.editGeneralSettings,
    [checkSMTPSuccess.type]: finders.checkSMTP,
    [publishFinderSuccess.type]: finders.publish,
    [setRuleStatusSuccess.type]: rules.status,
    [deleteRuleSuccess.type]: rules.delete,
    [addRuleSuccess.type]: rules.add,
    [editRuleSuccess.type]: rules.edit,
    [duplicateRuleSuccess.type]: rules.duplicate,
  };

  useEffect(() => {
    if (success && isShow) {
      api.open({
        ...appSuccessNotificationConfig,
        message: <AppText className='white_color bold' textValue={successMessages[success.messageType]} />,
        onClose: () => dispatch(hideNotification()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, isShow]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (error && isShow) {
      const errorText = handleErrorText(error, httpErrorsText);

      const messageTextValue = () => {
        if (error.exceptionCode === 'ERROR_025') {
          return notificationsText.errors.titles.delete;
        }
        if (error.exceptionCode === 'ERROR_026') {
          return notificationsText.errors.titles.publish;
        }
        if (error.exceptionCode === 'ERROR_308') {
          return notificationsText.errors.titles.deactivate;
        }
        if (error.exceptionCode === 'ERROR_320') {
          return notificationsText.errors.titles.bankLogo;
        }
        if (error.exceptionCode === 'ERROR_018') {
          return error?.fieldErrors.map(err => err.message).join(' ');
        }
        return errorText.message;
      };

      const descriptionComponent = () => {
        if (error.exceptionCode === 'ERROR_025') {
          return tenantDeleteErrorDescription(
            notificationsText.errors.descriptions.tenant.delete,
            notificationsText.errors.descriptions.currentlyAssigned,
            error.message
          );
        }
        if (error.exceptionCode === 'ERROR_026') {
          return publishFinderWarningDescription(
            notificationsText.errors.descriptions.finder.missingTheme,
            {
              begin: notificationsText.errors.descriptions.finder.goTo,
              settings: notificationsText.errors.descriptions.finder.settings,
              end: notificationsText.errors.descriptions.finder.selectBrand,
            },
            error.url
          );
        }
        if (error.exceptionCode === 'ERROR_308') {
          return tenantDeleteErrorDescription(
            notificationsText.errors.descriptions.tenant.deactivate,
            notificationsText.errors.descriptions.currentlyAssigned,
            error.message
          );
        }
        if (error.exceptionCode === 'ERROR_320') {
          return <AppText className='white_color' textValue={translations.unique.pages.tenants.errors.bankLogo} />;
        }
        if (error.exceptionCode === 'ERROR_018') {
          return '';
        }
        return <AppText className='white_color' textValue={errorText.description} />;
      };

      api.open({
        ...appErrorNotificationConfig,
        message: <AppText className='white_color bold' textValue={messageTextValue()} />,
        description: descriptionComponent(),
        onClose: () => dispatch(hideNotification()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isShow]);

  return contextHolder;
}
