import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { ProductModal } from '../../../../../../lib/models/Modal';
import { DeleteIcon } from '../../../../../../../../ui/icons/DeleteIcon';
import { IconButton } from '../../../../features/IconButton';
import { EditIcon } from '../../../../../../../../ui/icons/EditIcon';
import { openProductModal } from '../../../../../../../../lib/store/slices/productsSlice';

export function ProductActionsBodyCell({ row }) {
  const dispatch = useDispatch();

  return (
    <Space size='middle'>
      <IconButton icon={<EditIcon />} onClick={() => dispatch(openProductModal({ modalType: ProductModal.EDIT, selectedProduct: row }))} />
      <IconButton
        icon={<DeleteIcon className='secondary_color' />}
        onClick={() => dispatch(openProductModal({ modalType: ProductModal.DELETE, selectedProduct: row }))}
      />
    </Space>
  );
}
