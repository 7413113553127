import { QuestionnaireCheckbox } from '../../../../../features/QuestionnaireCheckbox';
import { QuestionnaireBasicButton } from '../../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import React, { useEffect, useState } from 'react';
import lodashSet from 'lodash.set';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import lodashGet from 'lodash.get';
import { SINGLE_ITEM_DELETING_INDEXES_AMOUNT } from '../../../../../../../../../../../lib/util/constants';
import { DeleteFieldModal } from './DeleteFieldModal';
import { isLastElement } from '../../../../../../../../../../../lib/util/helpers';

const getPath = (rowIndex, fieldIndex) => `formRowList[${rowIndex}].fieldList[${fieldIndex}].required`;

const gap24Px = { gap: '24px' };

export function ContactFormRowFieldActions(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldDeleteField, setShouldDeleteField] = useState(false);

  const { templateContent, rowIndex, fieldIndex, structureItemIndex } = props;
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const { updateStructure } = useUpdateStructure();
  const value = lodashGet(templateContent, getPath(rowIndex, fieldIndex));

  const toggleCheckbox = () => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.${getPath(rowIndex, fieldIndex)}`, !value);
    });
  };

  const closeModal = () => setIsOpen(false);

  const deleteField = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.formRowList[rowIndex].fieldList.splice(fieldIndex, SINGLE_ITEM_DELETING_INDEXES_AMOUNT);
    });
  };

  const deleteRow = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.formRowList.splice(rowIndex, SINGLE_ITEM_DELETING_INDEXES_AMOUNT);
    });
  };

  const onDeleteField = () => {
    if (isLastElement(templateContent?.formRowList?.[rowIndex]?.fieldList)) {
      setIsOpen(true);
      return;
    }

    deleteField();
  };

  const onSubmit = () => setShouldDeleteField(true);

  useEffect(() => {
    if (shouldDeleteField) {
      closeModal();
      setShouldDeleteField(false);

      deleteField();
      deleteRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDeleteField]);

  return (
    <>
      <DeleteFieldModal isOpen={isOpen} close={closeModal} submit={onSubmit} />

      <div style={gap24Px} className='flex flex-y-c'>
        <QuestionnaireCheckbox
          textValue={contentText.required}
          checked={value}
          onChange={toggleCheckbox}
          style={{ flexDirection: 'row-reverse' }}
          className='flex'
        />
        <QuestionnaireBasicButton onClick={onDeleteField}>
          <DeleteIcon />
        </QuestionnaireBasicButton>
      </div>
    </>
  );
}
