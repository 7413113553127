import { DisplayedProductCard } from './DisplayedProductCard';
import { useOnSelectCard } from '../../../hooks/useOnSelectCard';
import { getSpecificProducts } from '../../../models/Hardcode';

// REFACTOR: use Context API instead of props drilling
// eslint-disable-next-line max-lines-per-function
export function DisplayedProductCardWithWrapper(props) {
  const {
    defaultIcons,
    preSelectedCardIndex,
    products,
    setDragging,
    pagesFlow,
    carouselRef,
    windowWidth,
    card,
    setPagesFlow,
    structureItemId,
    selectable,
    isSelectionModalOpen,
    setSelectionModalOpen,
    recommendedIndexList,
    cardIndex,
    productObject,
    layoutDesign,
    currentElements,
    scoringResult,
    onSliderDraggable = () => {},
    hasShowCounter,
    ...rest
  } = props;

  const cardId = card?.id;
  const { P1, P2, P3 } = getSpecificProducts(pagesFlow.allPages);
  const conditions = {
    P4_1: P1?.content?.products?.[0]?.selected && P2?.content?.products?.[1]?.selected,
    P4_2: P1?.content?.products?.[1]?.selected && P3?.content?.products?.[1]?.selected,
  };

  const isRecommended = recommendedIndexList.includes(cardIndex);

  const { onSelectCard, scrollToCard, updateProductsStructure } = useOnSelectCard({
    conditions,
    P2,
    cardId,
    products,
    card,
    selectable,
    setPagesFlow,
    structureItemId,
    setDragging,
    carouselRef,
    windowWidth,
    setSelectionModalOpen,
    productObject,
    pagesFlow,
    scoringResult,
  });

  const providedProps = {
    ...rest,
    products,
    windowWidth,
    defaultIcons,
    onSelectCard,
    pagesFlow,
    card,
    setPagesFlow,
    selectable,
    setSelectionModalOpen,
    isSelectionModalOpen,
    P3,
    P2,
    P1,
    conditions,
    structureItemId,
    updateProductsStructure,
    isRecommended,
    scrollToCard,
    layoutDesign,
    currentElements,
    onSliderDraggable,
    hasShowCounter,
    // defaultIcon: defaultIcons[0].s3Url,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <DisplayedProductCard {...providedProps} />
    </div>
  );
}
