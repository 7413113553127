import styled from 'styled-components';
import { AppBasicButton } from '../../../../../../../../../../ui/buttons/AppBasicButton';

export function AlignButton({ isActive, onClick, children }) {
  return (
    <Root isActive={isActive} onClick={onClick}>
      {children}
    </Root>
  );
}

const Root = styled(AppBasicButton)(({ isActive }) => ({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  ...(isActive && {
    background: '#5C5C6E',
  }),
}));
