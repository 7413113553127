/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers,sonarjs/cognitive-complexity,max-lines-per-function,max-depth */
import {
  elementSubTypes,
  priceActions,
  productRangeOptions,
  resultSubTypes,
  resultTypeFlowProperties,
  resultTypeProductProperties,
  productResultTypeActions,
  arithmeticTypes,
  types,
  resultTypeAnswerProperties,
} from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { isLength } from '../../../../../../lib/util/helpers';
import { Product } from '../../pages/FindersAndProductsPage/lib/Product';
import { getAnswerPropertyResult, getProdPropertyResult } from './property_result_helper';
import { getProductCards, getAnswers } from './get_elements_helper';
import { getNumbersCalculation } from './get_numbers_calcaulation';

export const getRuleConditionResult = (resultBlocks, rule, condition, pagesFlow, currentElements) => {
  const { allPages, finder } = pagesFlow;
  const onlyResults = resultBlocks.filter(block =>
    [types.resultType, types.arithmeticOperator, types.value, types.element, types.variable].includes(block?.type)
  );
  const allElements = allPages.flatMap(page => page.elements);

  const actionedResult = onlyResults.map(resultBlock => {
    if (resultBlock.type === types.resultType) {
      const {
        value: { elementType, property, composerElement, composerElements, chapter, pictureType, picture },
      } = resultBlock;

      if (elementType === resultSubTypes.Flow) {
        if (property === resultTypeFlowProperties.goTo && composerElement) {
          return { goToFlow: { goTo: composerElement, condition, rule } };
        }
        if (property === resultTypeFlowProperties.show && composerElements) {
          return { showOrHideFlow: { operation: resultTypeFlowProperties.show, composerElements, condition, rule } };
        }
        if (property === resultTypeFlowProperties.hide && composerElements) {
          return { showOrHideFlow: { operation: resultTypeFlowProperties.hide, composerElements, condition, rule } };
        }
        if (property === resultTypeFlowProperties.imageSelection && chapter?.value && pictureType && picture?.imageUrl) {
          return {
            imageSelectionFlow: { pictureType, chapterElementId: chapter?.value, imageUrl: picture?.imageUrl, condition, rule },
          };
        }
      }

      if (elementType === resultSubTypes.Product) {
        const {
          value: { elements, action, priceType, product },
        } = resultBlock;

        if (property === resultTypeProductProperties.price && isLength(elements) && priceType) {
          const {
            value: { range, pricePerRequestText, rangeFrom, rangeTo },
          } = resultBlock;
          if (action === priceActions.selectPriceToShow) {
            return { priceProduct: { elements, action, priceType, condition, rule } };
          }
          if (action === priceActions.changePrice) {
            if ([Product.PRICE_PER_MONTH, Product.PRICE_PER_YEAR].includes(priceType)) {
              if (range === productRangeOptions.all) {
                return { priceProduct: { elements, action, priceType, range, condition, rule } };
              }
              if (range === productRangeOptions.fromTo && rangeFrom && rangeTo) {
                return { priceProduct: { elements, action, priceType, range, rangeFrom, rangeTo, condition, rule } };
              }
            }
            if (priceType === Product.PRICE_PRE_REQUEST) {
              return { priceProduct: { elements, action, priceType, pricePerRequestText, condition, rule } };
            }
          }
        }

        if (property === resultTypeProductProperties.counter && isLength(elements)) {
          return { counterProduct: { elements, condition, rule } };
        }

        if (property === resultTypeProductProperties.scoring && isLength(elements)) {
          return { scoreProduct: { elements, condition, rule } };
        }

        if (property === resultTypeProductProperties.savings && isLength(elements)) {
          return { savingsProduct: { elements, condition, rule } };
        }

        if (property === resultTypeProductProperties.productSelection && action === productResultTypeActions.addedToResult && isLength(elements)) {
          return { resultProduct: { elements, action, condition, rule } };
        }

        if (
          property === resultTypeProductProperties.productSelection &&
          [productResultTypeActions.preselected, productResultTypeActions.unselect].includes(action) &&
          isLength(elements)
        ) {
          return { selectProduct: { elements, action, condition, rule } };
        }

        if (property === resultTypeProductProperties.dependance && isLength(elements) && isLength(product)) {
          return { dependenceProduct: { elements, product, condition, rule } };
        }
      }

      if (elementType === resultSubTypes.Answer) {
        const {
          value: { elements, product },
        } = resultBlock;

        if (property === resultTypeAnswerProperties.score && isLength(elements) && product) {
          return { scoreAnswer: { elements, product, condition, rule } };
        }

        if (property === resultTypeAnswerProperties.savings && isLength(elements) && product) {
          return { savingsAnswer: { elements, product, condition, rule } };
        }

        if (property === resultTypeAnswerProperties.set && isLength(elements)) {
          return { setAnswer: { elements, condition, rule } };
        }

        if ([resultTypeAnswerProperties.select, resultTypeAnswerProperties.unselect].includes(property) && isLength(elements)) {
          return { selectAnswer: { elements, property, condition, rule } };
        }
      }

      if (elementType === resultSubTypes.SetVariable) {
        const {
          value: { element },
        } = resultBlock;
        return { resultVariables: { element, condition, rule } };
      }
    }

    if (resultBlock.type === types.arithmeticOperator) {
      return { arithmeticOperator: resultBlock.value };
    }

    if (resultBlock.type === types.value) {
      return resultBlock;
    }

    if (resultBlock.type === types.variable) {
      const variables = finder?.customVariables || [];
      const selectedVariable = variables.find(variable => variable.id === resultBlock.value);
      const selectedValue = selectedVariable?.value || 0;

      return {
        ...resultBlock,
        value: {
          amount: selectedValue,
          valueType: 'digit',
        },
      };
    }

    if (resultBlock.type === types.element && isLength(resultBlock.value.elements)) {
      if (resultBlock.value.elementType === elementSubTypes.Product) {
        const productCards = getProductCards(resultBlock.value.elements, allElements);
        const prodPropertyResult = getProdPropertyResult(productCards, resultBlock.value.property, pagesFlow, currentElements, false);
        // // eslint-disable-next-line no-console
        // console.log('prodPropertyResult then', prodPropertyResult);
        return prodPropertyResult ? { value: { amount: prodPropertyResult[0], valueType: 'digit' }, type: types.element } : null;
      }
      if (resultBlock.value.elementType === elementSubTypes.Answer) {
        const answers = getAnswers(resultBlock.value.elements, allElements);
        const answerPropertyResult = getAnswerPropertyResult(answers, resultBlock.value.property, resultBlock.value.composerItem, pagesFlow);
        // // eslint-disable-next-line no-console
        // console.log('answerPropertyResult then', answerPropertyResult);
        return answerPropertyResult ? { value: { amount: answerPropertyResult[0], valueType: 'digit' }, type: types.element } : null;
      }
    }

    return null;
  });

  const updatedResults = actionedResult.reduce((accumulator, resBlock) => {
    if (resBlock && !resBlock.arithmeticOperator && ![types.value, types.element, types.variable].includes(resBlock?.type)) {
      accumulator.push(resBlock);
    }
    if (accumulator.length > 0 && resBlock?.arithmeticOperator) {
      if ([arithmeticTypes.equal, arithmeticTypes.plusEquals, arithmeticTypes.minusEquals].includes(resBlock.arithmeticOperator)) {
        accumulator.at(-1).resultOperator = resBlock.arithmeticOperator;
      }

      if ([arithmeticTypes.plus, arithmeticTypes.minus, arithmeticTypes.multiply, arithmeticTypes.divide].includes(resBlock.arithmeticOperator)) {
        accumulator.at(-1).value = accumulator.at(-1).value ? accumulator.at(-1).value : [];
        accumulator.at(-1).value.push(resBlock.arithmeticOperator);
      }
    }

    if (accumulator.length > 0 && [types.value, types.element, types.variable].includes(resBlock?.type)) {
      accumulator.at(-1).value = accumulator.at(-1).value ? accumulator.at(-1).value : [];
      accumulator.at(-1).value.push(resBlock.value);
    }

    return accumulator;
  }, []);

  return updatedResults.map(resBlock => {
    return resBlock?.value ? { ...resBlock, value: getNumbersCalculation(resBlock.value) } : resBlock;
  });
};
