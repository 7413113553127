/* eslint-disable react-hooks/exhaustive-deps */
import { CardSection } from '../../../../../../../../ui/CardSection';
import { CounterSectionHeader } from './CounterSectionHeader';
import { CounterFields } from '../../../components/CounterFields';
import { DefaultCounter } from '../../../../../../../../../../features/DefaultCounter';
import { useEffect } from 'react';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';
import { useToggleDefaultCounter } from '../../../hooks/useToggleDefaultCounter';
import { useFirstMountState } from '../../../../../../../../../../../../../../lib/hooks/useFirstMountState';

export function CounterSection({ data, productIndex, structureItemIndex }) {
  const { updateStructure } = useUpdateStructure();
  const handleToggleDefaultCounter = useToggleDefaultCounter(structureItemIndex, productIndex);
  const isFirstMountState = useFirstMountState();

  const handleChangeDefaultCounterValue = value => {
    // eslint-disable-next-line init-declarations
    let result;
    if (value > data?.max) {
      result = data?.max;
    } else if (value < data?.min) {
      result = data?.min;
    } else {
      result = value;
    }
    updateStructure(draft => {
      const productItemCounterSection = draft[structureItemIndex].content.products[productIndex]?.counterSection;
      productItemCounterSection.value = result;
      productItemCounterSection.defaultValue = result;
    });
  };

  useEffect(() => {
    if (isFirstMountState) {
      return;
    }

    if (data?.showDefaultCounter) {
      handleChangeDefaultCounterValue(data?.value);
    }
  }, [data?.max, data?.min, data.showDefaultCounter]);

  return (
    <CardSection>
      <CounterSectionHeader data={data} productIndex={productIndex} structureItemIndex={structureItemIndex} />
      <CounterFields data={data} dataPath={`[${structureItemIndex}].content.products[${productIndex}].counterSection`} />
      <DefaultCounter
        checked={data?.showDefaultCounter}
        value={data.value}
        onToggleDefaultCounter={handleToggleDefaultCounter}
        onChangeDefaultCounterValue={handleChangeDefaultCounterValue}
        disabled={!data.showCounter}
      />
    </CardSection>
  );
}
