import { LayoutStatus } from '../../lib/Layout';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { Status, StatusType } from '../../../../../../components/Status';

export function LayoutStatusBodyCell({ status }) {
  const { status: statusText } = useLocalization().translations.shared;

  switch (status) {
    case LayoutStatus.ACTIVATED:
      return <Status textValue={statusText.activated} type={StatusType.GREEN} />;

    case LayoutStatus.DEACTIVATED:
      return <Status textValue={statusText.deactivated} type={StatusType.GRAY} />;

    default:
      throw new Error('Invalid status');
  }
}
