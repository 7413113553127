import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

const Root = styled(AppBox)({
  border: 'none',
  padding: 0,
  margin: 0,
  outline: 0,
  background: 'none',
  display: 'flex',
  cursor: 'pointer',
});

// TODO: replace this useless logic with proving "src" prop to render icon button or something like this
export function AppIconButton({ as, ...rest }) {
  return <Root as='button' {...rest} />;
}
