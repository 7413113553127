import { TreeSelect } from 'antd';

import styles from './AppTreeSelect.module.scss';

const { SHOW_ALL } = TreeSelect;

export function AppTreeSelect({ label, placeholder, options, onChange, value, isMultiSelect = true }) {
  return (
    <div className={styles.appTreeSelect}>
      <div className={styles.label}>{label}</div>
      <TreeSelect
        value={value}
        onChange={onChange}
        treeData={options}
        placeholder={placeholder}
        showCheckedStrategy={SHOW_ALL}
        maxTagCount={2}
        showSearch
        filterTreeNode={(search, item) => {
          return item.title.toLowerCase().includes(search.toLowerCase());
        }}
        multiple={isMultiSelect}
        treeDefaultExpandAll
        treeCheckable={isMultiSelect}
        treeCheckStrictly={isMultiSelect}
        className={styles.treeSelectComponent}
      />
    </div>
  );
}
