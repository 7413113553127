import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { useFinderArrangeRequests } from '../../lib/useFinderArrangeRequests';
import { ArrangeListPopup } from '../../../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../../../components/TableHeaderCell';
import { User } from '../../../../../../../UsersPage/components/UsersTable/lib/User';
import { getUsersByLoginFromApi } from '../../../../../../../../utils/api';

export function FinderSearchPersonHeaderCell({ arrangedId, title, filterFieldName, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const { filtering } = useStringArrange({
    form,
    request: getUsersByLoginFromApi,
    formatResponse: data => formatArrangedData(data, User.USERNAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
