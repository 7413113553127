import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { DroppableFieldWrapper } from '../../DroppableItems/DroppableFieldWrapper';
import { DroppableImageWrapper } from '../../DroppableItems/DroppableImageWrapper';
import styled from 'styled-components';

/**
 * @desc The intro is the starting page for the finder. The intro should be at the beginning of Finder, and other elements cannot be added to this page.
 * @param {Object} item
 * @param {Function} handleChange
 * @param {Function} onChangeFieldInfoIconValue
 * @param {Function} onDeleteInfoIcon
 * @returns {JSX.Element}
 */

export function IntroForm({ item, handleChange, onChangeFieldInfoIconValue, onDeleteInfoIcon }) {
  const { content = {}, id } = item;
  const { headline, subline, primaryButton, secondaryButton, selectBackgroundImage } = content;
  const { templateHeadline, templateSubline, templatePrimaryButton, templateSecondaryButton, selectImage, templateInfo } =
    useLocalization().translations.unique.formItems;

  const {
    finder: {
      finderSettings: { media },
    },
  } = useSelector(({ finders }) => finders);

  return (
    <Root>
      <DroppableFieldWrapper
        itemId={id}
        labelField={templateHeadline.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.headline.infoIcon && 'INFO_ICON'}
        valueObject={headline}
        onChangeField={handleChange}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
        fieldName='headline'
        infoIcon={item.content.headline.infoIcon}
      />
      <DroppableFieldWrapper
        itemId={id}
        labelField={templateSubline.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.subline.infoIcon && 'INFO_ICON'}
        valueObject={subline}
        onChangeField={handleChange}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
        fieldName='subline'
        infoIcon={item.content.subline.infoIcon}
      />
      <DroppableFieldWrapper
        itemId={id}
        labelField={templatePrimaryButton.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.primaryButton.infoIcon && 'INFO_ICON'}
        valueObject={primaryButton}
        onChangeField={handleChange}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
        fieldName='primaryButton'
        infoIcon={item.content.primaryButton.infoIcon}
      />
      <DroppableFieldWrapper
        itemId={id}
        labelField={templateSecondaryButton.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.secondaryButton.infoIcon && 'INFO_ICON'}
        valueObject={secondaryButton}
        onChangeField={handleChange}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
        fieldName='secondaryButton'
        infoIcon={item.content.secondaryButton.infoIcon}
      />
      <DroppableImageWrapper
        itemId={id}
        labelField={selectImage.label}
        labelTemplateInfo={templateInfo.label}
        type={!item.content.selectBackgroundImage.infoIcon && 'INFO_ICON'}
        valueObject={selectBackgroundImage}
        onChangeField={handleChange}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
        fieldName='selectBackgroundImage'
        infoIcon={item.content.selectBackgroundImage.infoIcon}
        media={media}
      />
    </Root>
  );
}

const Root = styled('div')({
  padding: '20px 25px',
});
