/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { LayoutRadioButton } from './LayoutRadioButton';
import { AppSpace } from '../../../../../../../../../ui/AppSpace';
import { IntroLayout } from './layouts/IntroLayouts/IntroLayout';
import { ContentLayoutContentBlocks } from './layouts/ContentLayouts/ContentLayoutContentBlocks';
import { QuestionLayout } from './layouts/QuestionLayouts/QuestionLayout';
import { ChapterLayout } from './layouts/ChapterLayouts/ChapterLayout';
import { TemplateTypes } from '../../../../../../../../../lib/models/TemplateTypes';
import { DefaultColors, DefaultFontStyles } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';
import { loadFonts } from '../../../../ThemePage/lib/util/loadFonts';
import { selectQuestionnaireElement, updateQuestionnaireStructure } from '../../../../../../../../../lib/store/slices/findersSlice';
import { ResultLayout } from './layouts/ResultLayouts/ResultLayout';
import { ProductLayout } from './layouts/ProductLayouts';
import { ComparisonTableLayout } from './layouts/ContentLayouts/ComparisonTableLayout';
import { ComparisonDetailTableLayout } from './layouts/ContentLayouts/ComparisonDetailTableLayout';
import { ResultDepotLayout } from './layouts/ResultLayouts/ResultDepotLayout';
import { ContactFormLayout } from './layouts/ContactFormLayouts/ContactFormLayout';

/**
 * @desc The templates from the template library contain a layout that will be displayed in the Layout tab.
 * @returns {JSX.Element}
 */

const checkIsNotCompletedChapters = element => !element.content?.completedChaptersButtons;

// eslint-disable-next-line max-lines-per-function
export function LayoutTemplates() {
  const {
    finder: {
      brand,
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
    selectedQuestionnaireElement,
    questionnaireStructure,
    isViewMode,
    templateLayouts,
  } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();
  const [selectedLayout, setSelectedLayout] = useState(null);
  const stylesConfiguration =
    brand.id && brand.activated
      ? JSON.parse(brand.configuration)
      : {
          fonts: DefaultFontStyles,
          colors: DefaultColors,
        };
  const { fontsForLoading } = stylesConfiguration;

  useEffect(() => {
    if (fontsForLoading) {
      loadFonts(fontsForLoading);
    }
  }, [fontsForLoading]);

  useEffect(() => {
    if (selectedQuestionnaireElement) {
      setSelectedLayout(`${selectedQuestionnaireElement.layoutId}_&&_${selectedQuestionnaireElement.layoutStyle}`);
    }
  }, [selectedQuestionnaireElement.id]);

  const onChange = ({ target }) => {
    setSelectedLayout(target.value);
    const layoutId = String(target.value.split('_&&_')[0]);
    const layoutStyle = target.value.split('_&&_')[1];

    const result =
      selectedQuestionnaireElement.templateType === TemplateTypes.CHAPTER && checkIsNotCompletedChapters(selectedQuestionnaireElement)
        ? questionnaireStructure.map((el, index) => {
            if (el.templateType === TemplateTypes.CHAPTER && checkIsNotCompletedChapters(el)) {
              const item = {
                ...selectedQuestionnaireElement,
                layoutId,
                layoutStyle,
              };
              dispatch(selectQuestionnaireElement({ item, index }));
              return { ...el, layoutId, layoutStyle };
            }
            return el;
          })
        : questionnaireStructure.map((el, index) => {
            if (el.id === selectedQuestionnaireElement.id) {
              const item = {
                ...selectedQuestionnaireElement,
                layoutId,
                layoutStyle,
              };
              dispatch(selectQuestionnaireElement({ item, index }));
              return { ...el, layoutId, layoutStyle };
            }
            return el;
          });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <Radio.Group style={styles.root} onChange={onChange} value={selectedLayout} disabled={isViewMode}>
      <StyledSpace direction='vertical' size='middle'>
        {selectedQuestionnaireElement &&
          questionnaireStructure.some(elem => elem.id === selectedQuestionnaireElement.id) &&
          templateLayouts
            .filter(layout => layout.templateId === Number(selectedQuestionnaireElement.templateId))
            .sort((cur, next) => next.usedInTotalFinders - cur.usedInTotalFinders)
            .map(layoutOption => {
              const { kind, layoutDesign, ...style } = layoutOption.content.style;

              const layoutProps = {
                selectedElement: selectedQuestionnaireElement,
                style: layoutOption.content.style,
                stylesConfiguration,
              };

              const layoutElements = {
                [TemplateTypes.INTRO]: <IntroLayout {...layoutProps} />,
                [TemplateTypes.CHAPTER]: <ChapterLayout {...layoutProps} />,
                [TemplateTypes.QUESTION]: <QuestionLayout {...layoutProps} />,
                [TemplateTypes.CONTENT]: (
                  <>
                    {selectedQuestionnaireElement?.content?.detailTablesDefault ? (
                      <ComparisonDetailTableLayout
                        {...stylesConfiguration}
                        dataObj={selectedQuestionnaireElement.content.contentBlocks[0]}
                        icons={[...customIcons, ...defaultIcons]}
                      />
                    ) : (
                      Array.isArray(selectedQuestionnaireElement?.content?.contentBlocks) && <ContentLayoutContentBlocks {...layoutProps} />
                    )}

                    {selectedQuestionnaireElement?.content?.tables && (
                      <ComparisonTableLayout {...stylesConfiguration} dataObj={selectedQuestionnaireElement} />
                    )}
                  </>
                ),
                [TemplateTypes.PRODUCT]: (
                  <ProductLayout customStyles={style} kind={kind} layoutDesign={layoutDesign} stylesConfiguration={stylesConfiguration} />
                ),
                [TemplateTypes.CONTACT_FORM]: <ContactFormLayout {...layoutProps} />,
                [TemplateTypes.RESULT]: selectedQuestionnaireElement?.content?.products ? (
                  <ResultDepotLayout {...layoutProps} />
                ) : (
                  <ResultLayout {...layoutProps} />
                ),
              };
              return (
                <LayoutRadioButton
                  key={layoutOption.id}
                  value={`${layoutOption.id}_&&_${JSON.stringify(layoutOption.content.style)}`}
                  layout={layoutElements[selectedQuestionnaireElement.templateType]}
                  templateType={selectedQuestionnaireElement.templateType}
                />
              );
            })}
      </StyledSpace>
    </Radio.Group>
  );
}

const styles = {
  root: {
    padding: 16,
    width: '100%',
    overflowY: 'auto',
    transform: 'scaleX(-1)',
    height: '100%',
  },
};

const StyledSpace = styled(AppSpace)(() => ({
  width: '100%',
  transform: 'scaleX(-1)',
  '& > .ant-space-item': {
    width: '100%',
  },
}));
