import { getSelectedExceptionalIdList } from '../../../../../utils';
import { isIndexFound } from '../../../../../../../../../../../lib/util/helpers';
import {
  checkIsHamburgerBusiness,
  checkIsHamburgerFkP1,
  getConditions,
  getSpecificProducts,
  ProductCardId,
  ProductId,
} from '../../../../../models/Hardcode';
import { checkIsUserChangedSelection } from '../../utils';
import { getDependenceRuleProducts } from '../../../../../hooks/useCardLogic/useNormalOnSelectCard/useNormalUpdateProductsSelection';
import { priceScoringSort } from '../../../../../../../utils/end-user-flow-helpers';
import { getRulesDependenceProductProperties } from '../../../../../../../utils/rules/product_dependence_rules_helper';

// eslint-disable-next-line max-lines-per-function
export function getUpdatedCards({
  products,
  allPages,
  recommendedIndexList,
  setSelectionModalOpen,
  structureItemId,
  isFirstRender,
  pagesFlow,
  currentElements,
  scoringResult,
}) {
  const ids = getSelectedExceptionalIdList(products);
  const { P1, P2, P3 } = getSpecificProducts(allPages);
  const conditions = getConditions({ P1, P2, P3 });
  const isHamburgerBusiness = checkIsHamburgerBusiness(products);

  const updatedDependenceProducts = products.map(prod => {
    const { ruleParentFor, ruleChildFor } = getRulesDependenceProductProperties(prod, pagesFlow, currentElements);
    return { ...prod, ruleParentFor, ruleChildFor };
  });
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const normalRecommendedCards = updatedDependenceProducts?.map((card, cardIndex) => {
    let selected = card?.selected;
    let counterValue = 0;
    const cardId = card?.id;
    const productId = card?.productNameSection?.product?.value;
    const cardIndexForUnSelection = ids.indexOf();
    const isCurrentCardShouldBeUnselected = isIndexFound(cardIndexForUnSelection);
    const isCurrentCardRecommended = recommendedIndexList.includes(cardIndex);
    const isHamburgerPkP41 = cardId === ProductCardId.HAMBURGER_PK_P4_1;
    const isHamburgerPkP42 = cardId === ProductCardId.HAMBURGER_PK_P4_2;
    const hamburgerPkP4P1Conditions = P2?.content?.products?.[1]?.selected || conditions.P4_1 || conditions.P4_2;
    const isFkP1 = checkIsHamburgerFkP1(structureItemId);
    const isUserChangedSelection = checkIsUserChangedSelection(selected);

    const counterValueViaRecommendation =
      isCurrentCardRecommended && card?.counterSection?.showCounter && card?.counterSection?.value === 0
        ? card?.counterSection?.min || 1
        : card?.counterSection?.value || 1;

    // Note: order of the cases is matter
    switch (true) {
      case isCurrentCardShouldBeUnselected:
        selected = false;
        break;
      case isUserChangedSelection && isFirstRender:
        // Does nothing for saving user's choice
        break;
      case isHamburgerBusiness && isFkP1 && isCurrentCardRecommended:
        selected = Boolean(isFkP1 || productId === ProductId.HAMBURGER_BUSINESS);
        break;
      case isHamburgerPkP42:
        if (P2?.content?.products?.[1]?.selected || conditions.P4_1 || conditions.P4_2) {
          if (selected) return card;
          selected = true;
          break;
        }
        counterValue = counterValueViaRecommendation;
        selected = isCurrentCardRecommended;
        break;
      case isHamburgerPkP41:
        if (!card.selected && hamburgerPkP4P1Conditions) {
          setSelectionModalOpen(true);
          break;
        }

        counterValue = counterValueViaRecommendation;
        selected = isCurrentCardRecommended;
        break;
      default:
        counterValue = counterValueViaRecommendation;
        selected = isCurrentCardRecommended;
        break;
    }
    return isCurrentCardRecommended ? { ...card, counterSection: { ...card.counterSection, value: counterValue }, selected } : { ...card, selected };
  });

  const dependenceRuleCards = getDependenceRuleProducts(normalRecommendedCards);

  return normalRecommendedCards.map(card => {
    const ruleProdCard = dependenceRuleCards.find(prod => prod.id === card.id);
    const { isParent } = ruleProdCard;
    const productNameSection = card?.productNameSection;
    const iteratedProductId = productNameSection?.product?.value;

    const parents = dependenceRuleCards.filter(prod => prod.isParent);
    const children = dependenceRuleCards.filter(prod => prod.isChild);
    const parentsValueIds = new Set(parents.map(prod => prod?.productNameSection?.product?.value));
    const parentsScore = scoringResult.filter(scoreObj => parentsValueIds.has(scoreObj.id));
    const sortedParentsScore = parentsScore[0]?.price
      ? parentsScore.sort((cur, next) => priceScoringSort(cur, next))
      : parentsScore.sort((cur, next) => next.score - cur.score);

    const isChildSelected = children.some(prod => prod.selected);
    const isParentSelected = parents.some(prod => prod.selected);
    const isBestScoreParent = sortedParentsScore[0]?.id === iteratedProductId;

    const dependenceSelected = getProductParentDependenceSelected({
      card,
      isParentSelected,
      isChildSelected,
      isParent,
      isBestScoreParent,
    });
    const selected = isParent ? dependenceSelected : card?.selected;
    return { ...card, selected };
  });
}

function getProductParentDependenceSelected({ card, isParentSelected, isChildSelected, isParent, isBestScoreParent }) {
  if (isParent && isChildSelected && !card?.selected && !isParentSelected && isBestScoreParent) {
    return true;
  }

  return card?.selected;
}
