import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Space } from 'antd';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { AppButton } from '../../../../../../../../ui/buttons/AppButton';
import { PlayIcon } from '../../../../../../../../ui/icons/PlayIcon';
import { DeleteIcon } from '../../../../../../../../ui/icons/DeleteIcon';
import { HeadphoneIcon } from '../../../../../../../../ui/icons/HeadphoneIcon';
import { AudioPlayer } from './AudioPlayer';
import { updateQuestionnaireStructure } from '../../../../../../../../lib/store/slices/findersSlice';
import './style.scss';
import { usePermissions } from 'src/lib/hooks/usePermissions';

export function AudioPlayerDropdown({ audioObject, item, questionnaireStructure, itemIndex }) {
  const { isRestricted } = usePermissions();
  const [visible, setVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const audioPlayer = useRef();
  const dispatch = useDispatch();
  const { isViewMode } = useSelector(({ finders }) => finders);

  const stopPlayingAndClose = () => {
    setVisible(false);
    audioPlayer.current.pause();
    setIsPlaying(false);
  };

  const handleVisibleChange = flag => {
    setVisible(flag);
    if (!flag && audioPlayer?.current) {
      stopPlayingAndClose();
    }
  };

  const deleteAudioFile = () => {
    const updatedItem = { ...item, audio: null };
    const newQuestionnaireStructure = [...questionnaireStructure];
    newQuestionnaireStructure[itemIndex] = updatedItem;
    dispatch(updateQuestionnaireStructure({ structure: newQuestionnaireStructure }));
  };

  return (
    <Space className='audio_player_panel'>
      <div className='audio_player_file_box align_items_center'>
        <HeadphoneIcon className='audio_player_icon' />
        <AppText ellipsis className='audio_player_file_name'>
          {audioObject.originalFileName}
        </AppText>
      </div>
      <Space className='audio_player_btn_wrap'>
        <Dropdown
          open={visible}
          placement='bottomRight'
          trigger={['click']}
          onOpenChange={handleVisibleChange}
          dropdownRender={() => (
            <AudioPlayer
              audioPlayer={audioPlayer}
              audioObject={audioObject}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              stopPlayingAndClose={stopPlayingAndClose}
            />
          )}
        >
          <AppButton className={visible ? 'audio_player_btn audio_player_btn__visible audio_player_btn__play' : 'audio_player_btn'}>
            <PlayIcon />
          </AppButton>
        </Dropdown>

        <AppButton
          className={visible ? 'audio_player_btn audio_player_btn__visible' : 'audio_player_btn'}
          onClick={deleteAudioFile}
          disabled={isRestricted() || isViewMode}
          style={{ cursor: isRestricted() ? 'not-allowed' : 'pointer' }}
        >
          <DeleteIcon />
        </AppButton>
      </Space>
    </Space>
  );
}
