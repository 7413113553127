import { AppButton } from '../../../../../../../../../../../../ui/buttons/AppButton';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';

export function ProductFlowButtons(props) {
  const { templates } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout;

  return (
    <Root {...props}>
      <Btn textValue={templates.button} />
      <Btn type='primary' textValue={templates.button} style={{ color: '#fff' }} />
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

const Btn = styled(AppButton)({
  minWidth: '155px',
  height: 48,
  borderRadius: 24,
});
