import { AppModal } from '../../../../../../../../../ui/AppModal';
import { Typography } from 'antd';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';

export function HamburgerPKP4P1Modal({
  confirmSelection,
  setSelectionModalOpen,
  isOpen,
  headline,
  subLine,
  primaryButton,
  secondaryButton,
  fonts,
  colors,
}) {
  const close = () => setSelectionModalOpen(false);

  const confirmAction = () => {
    confirmSelection();
    close();
  };

  return (
    <AppModal
      open={isOpen}
      cancelButtonProps={{
        onClick: close,
        textValue: secondaryButton,
        style: {
          fontWeight: 700,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
        },
      }}
      actionButtonProps={{
        onClick: confirmAction,
        textValue: primaryButton,
        style: {
          backgroundColor: colors[ColorVariants.PRIMARY_COLOR],
          fontWeight: 700,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
        },
      }}
      closable={false}
      maskClosable={false}
    >
      <Typography.Title
        style={{
          textAlign: 'center',
          fontSize: fonts.headlineFirst.fontSize,
          fontFamily: fonts.headlineFirst.font,
          fontWeight: fonts.headlineFirst.fontWeight,
          color: colors[fonts.headlineFirst.colorVariant],
        }}
      >
        {headline}
      </Typography.Title>
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          fontSize: fonts.body.fontSize,
          fontFamily: fonts.body.font,
          fontWeight: fonts.body.fontWeight,
          color: colors[fonts.body.colorVariant],
        }}
      >
        {subLine}
      </Typography.Paragraph>
    </AppModal>
  );
}
