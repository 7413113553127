export function LinearFlowIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.21697 0C7.94083 0 7.71697 0.223858 7.71697 0.5L7.71697 13H8.71697L8.71697 0.5C8.71697 0.223858 8.49311 0 8.21697 0ZM10.1819 13.0039H6.24991C6.2024 13.0039 6.15589 13.0175 6.11581 13.043C6.07573 13.0685 6.04374 13.1049 6.0236 13.1479C6.00345 13.1909 5.99598 13.2388 6.00205 13.2859C6.00813 13.333 6.0275 13.3774 6.05791 13.4139L8.02391 15.7739C8.12391 15.8939 8.30791 15.8939 8.40791 15.7739L10.3739 13.4139C10.4043 13.3774 10.4237 13.333 10.4298 13.2859C10.4358 13.2388 10.4284 13.1909 10.4082 13.1479C10.3881 13.1049 10.3561 13.0685 10.316 13.043C10.2759 13.0175 10.2294 13.0039 10.1819 13.0039Z'
        fill='white'
      />
    </svg>
  );
}
