import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../../../lib/store/slices/titleSlice';
import { FindersTable } from '../../../management/pages/FindersAndProductsPage/modules/finders/components/FindersTable';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { TenantCard } from '../../../../features/TenantCard';
import { cleanTenant, getTenantRequest } from '../../../../../../lib/store/slices/findersSlice';
import { AppPreloader } from '../../../../../../ui/AppPreloader';
import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';
import { handleTranslation } from '../../../../../../lib/util/helpers';
import styled from 'styled-components';
import { TopPanel } from 'src/processes/CMS/processes/management/pages/FindersAndProductsPage/components/TopPanel';
import { ProductsAndCategory } from 'src/processes/CMS/processes/management/pages/FindersAndProductsPage/components/ProductsAndCategory';

export function YourFindersPage() {
  const dispatch = useDispatch();
  const { translations } = useLocalization();
  const { yourFinders } = translations.unique.pages;
  const navigate = useNavigate();
  const { tenantId } = useSelector(({ auth }) => auth);
  const { tenantLoading, tenantError } = useSelector(({ finders }) => finders);

  const Tab = {
    FINDERS: 'FINDERS',
    PRODUCTS: 'PRODUCTS',
  };

  const [tab, setTab] = useState(Tab.FINDERS);

  const tables = {
    FINDERS: <FindersTable />,
    PRODUCTS: <ProductsAndCategory />,
  };

  const tabOptions = [
    { tabName: 'Finders', value: Tab.FINDERS },
    { tabName: 'Products', value: Tab.PRODUCTS },
  ];

  useEffect(() => {
    if (tenantId) {
      dispatch(getTenantRequest(tenantId));
      dispatch(setTitle({ title: handleTranslation(yourFinders.title), subTitle: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  useEffect(() => {
    if (tenantError) {
      navigate(ROUTER_PATHS.notFound);
      dispatch(cleanTenant());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantError]);

  if (tenantLoading) {
    return <AppPreloader />;
  }

  return (
    <StyledWrapper>
      <TenantCard />
      <TopPanel tabOptions={tabOptions} addButtons={[]} activeTab={tab} setActiveTab={setTab} />
      {tables[tab]}
    </StyledWrapper>
  );
}

const StyledWrapper = styled('div')({
  padding: '24px 32px',
  width: 'calc(100vw - 5px)',
});
