import { useSelector } from 'react-redux';

import { QuestionnaireSelect } from 'src/processes/CMS/processes/management/pages/QuestionnairePage/features/QuestionnaireSelect';
import { AppText } from 'src/ui/text/AppText';
import { AppSpace } from 'src/ui/AppSpace';

import { useLocalization } from 'src/lib/hooks/useLocalization';
import { ProductsIcon } from 'src/ui/icons/ProductsIcon';

export function ProductBlock({ productRow, handleChangeField }) {
  const { label } = useLocalization().translations.unique.formItems.templateSelectProduct;
  const { product } = useLocalization().translations.shared.generic;

  const { tenantProducts } = useSelector(({ finders }) => finders);

  const productOptions = tenantProducts.map(tenantProduct => ({
    title: tenantProduct.name,
    value: tenantProduct.id,
  }));

  const onSelectChange = (field, value) => {
    handleChangeField({
      ...productRow.value,
      [field]: value,
    });
  };

  const { productOne, productTwo } = productRow.value;

  return (
    <>
      <AppSpace style={{ marginBottom: 24 }}>
        <ProductsIcon fill='#191919' />
        <AppText style={{ fontWeight: 700 }} textValue={product} />
      </AppSpace>

      <QuestionnaireSelect
        selectProps={{
          value: productOne,
          options: productOptions,
          label: `${label()} 1`,
          onChange: val => onSelectChange('productOne', val),
        }}
      />
      <QuestionnaireSelect
        selectProps={{
          value: productTwo,
          options: productOptions,
          label: `${label()} 2`,
          onChange: val => onSelectChange('productTwo', val),
        }}
      />
      <div style={{ backgroundColor: '#ECECEE', height: '1px', marginBottom: '16px' }} />
    </>
  );
}
