export const iconList = {
  arrowLeft: [
    'M7.99992 4.00063C7.99992 4.13324 7.94724 4.26042 7.85347 4.35419C7.7597 4.44795 7.63253 4.50063 7.49992 4.50063H1.70692L3.85392 6.64663C3.90041 6.69312 3.93728 6.74831 3.96244 6.80905C3.9876 6.86979 4.00055 6.93489 4.00055 7.00063C4.00055 7.06637 3.9876 7.13148 3.96244 7.19221C3.93728 7.25295 3.90041 7.30814 3.85392 7.35463C3.80743 7.40112 3.75224 7.438 3.6915 7.46315C3.63076 7.48831 3.56566 7.50126 3.49992 7.50126C3.43417 7.50126 3.36907 7.48831 3.30833 7.46315C3.24759 7.438 3.19241 7.40112 3.14592 7.35463L0.145917 4.35463C0.0993539 4.30819 0.062411 4.25301 0.0372045 4.19226C0.0119981 4.13152 -0.000976563 4.0664 -0.000976562 4.00063C-0.000976563 3.93486 0.0119981 3.86974 0.0372045 3.809C0.062411 3.74825 0.0993539 3.69308 0.145917 3.64663L3.14592 0.646632C3.2398 0.552745 3.36714 0.5 3.49992 0.5C3.63269 0.5 3.76003 0.552745 3.85392 0.646632C3.9478 0.740518 4.00055 0.867856 4.00055 1.00063C4.00055 1.13341 3.9478 1.26075 3.85392 1.35463L1.70692 3.50063H7.49992C7.63253 3.50063 7.7597 3.55331 7.85347 3.64708C7.94724 3.74085 7.99992 3.86802 7.99992 4.00063Z',
  ],
  arrowRight: [
    'M8.26636e-05 3.99937C8.2652e-05 3.86676 0.0527607 3.73958 0.146529 3.64582C0.240297 3.55205 0.367474 3.49937 0.500083 3.49937L6.29308 3.49937L4.14608 1.35337C4.09959 1.30688 4.06272 1.25169 4.03756 1.19095C4.0124 1.13021 3.99945 1.06511 3.99945 0.999369C3.99945 0.933626 4.0124 0.868525 4.03756 0.807786C4.06272 0.747046 4.09959 0.691857 4.14608 0.645369C4.19257 0.598881 4.24776 0.562005 4.3085 0.536846C4.36924 0.511687 4.43434 0.498738 4.50008 0.498738C4.56583 0.498738 4.63093 0.511687 4.69167 0.536846C4.75241 0.562005 4.80759 0.598881 4.85408 0.645369L7.85408 3.64537C7.90065 3.69181 7.93759 3.74699 7.9628 3.80774C7.988 3.86848 8.00098 3.9336 8.00098 3.99937C8.00098 4.06514 7.988 4.13026 7.9628 4.191C7.93759 4.25175 7.90065 4.30692 7.85408 4.35337L4.85408 7.35337C4.7602 7.44726 4.63286 7.5 4.50008 7.5C4.36731 7.5 4.23997 7.44726 4.14608 7.35337C4.0522 7.25948 3.99945 7.13214 3.99945 6.99937C3.99945 6.86659 4.0522 6.73926 4.14608 6.64537L6.29308 4.49937L0.500083 4.49937C0.367474 4.49937 0.240297 4.44669 0.146529 4.35292C0.0527608 4.25915 8.26752e-05 4.13198 8.26636e-05 3.99937Z',
  ],
  checked: [
    'M7.47016 0.969304C7.61011 0.830575 7.79903 0.7525 7.99609 0.751956C8.19315 0.751412 8.3825 0.828444 8.52321 0.966398C8.66393 1.10435 8.74469 1.29214 8.74805 1.48917C8.75141 1.6862 8.67709 1.87663 8.54116 2.0193L4.54916 7.0093C4.48055 7.0832 4.39774 7.14251 4.30569 7.18367C4.21364 7.22483 4.11423 7.24701 4.01341 7.24888C3.91259 7.25075 3.81243 7.23226 3.71891 7.19454C3.6254 7.15681 3.54046 7.10061 3.46916 7.0293L0.824158 4.3833C0.750471 4.31464 0.691369 4.23184 0.650377 4.13984C0.609385 4.04784 0.587343 3.94853 0.585566 3.84783C0.583789 3.74712 0.602314 3.64709 0.640035 3.55371C0.677756 3.46032 0.733901 3.37548 0.805119 3.30427C0.876338 3.23305 0.961172 3.1769 1.05456 3.13918C1.14795 3.10146 1.24798 3.08294 1.34868 3.08471C1.44938 3.08649 1.5487 3.10853 1.6407 3.14952C1.7327 3.19051 1.8155 3.24962 1.88416 3.3233L3.97816 5.4163L7.45116 0.991304C7.45735 0.983556 7.46403 0.976207 7.47116 0.969304H7.47016Z',
  ],
  hamburger: [
    'M0 14.75C0 14.5511 0.0790178 14.3603 0.21967 14.2197C0.360322 14.079 0.551088 14 0.75 14H17.25C17.4489 14 17.6397 14.079 17.7803 14.2197C17.921 14.3603 18 14.5511 18 14.75C18 14.9489 17.921 15.1397 17.7803 15.2803C17.6397 15.421 17.4489 15.5 17.25 15.5H0.75C0.551088 15.5 0.360322 15.421 0.21967 15.2803C0.0790178 15.1397 0 14.9489 0 14.75ZM0 10.25C0 10.0511 0.0790178 9.86032 0.21967 9.71967C0.360322 9.57902 0.551088 9.5 0.75 9.5H17.25C17.4489 9.5 17.6397 9.57902 17.7803 9.71967C17.921 9.86032 18 10.0511 18 10.25C18 10.4489 17.921 10.6397 17.7803 10.7803C17.6397 10.921 17.4489 11 17.25 11H0.75C0.551088 11 0.360322 10.921 0.21967 10.7803C0.0790178 10.6397 0 10.4489 0 10.25ZM0 5.75C0 5.55109 0.0790178 5.36032 0.21967 5.21967C0.360322 5.07902 0.551088 5 0.75 5H17.25C17.4489 5 17.6397 5.07902 17.7803 5.21967C17.921 5.36032 18 5.55109 18 5.75C18 5.94891 17.921 6.13968 17.7803 6.28033C17.6397 6.42098 17.4489 6.5 17.25 6.5H0.75C0.551088 6.5 0.360322 6.42098 0.21967 6.28033C0.0790178 6.13968 0 5.94891 0 5.75ZM0 1.25C0 1.05109 0.0790178 0.860322 0.21967 0.71967C0.360322 0.579018 0.551088 0.5 0.75 0.5H17.25C17.4489 0.5 17.6397 0.579018 17.7803 0.71967C17.921 0.860322 18 1.05109 18 1.25C18 1.44891 17.921 1.63968 17.7803 1.78033C17.6397 1.92098 17.4489 2 17.25 2H0.75C0.551088 2 0.360322 1.92098 0.21967 1.78033C0.0790178 1.63968 0 1.44891 0 1.25Z',
  ],
};
