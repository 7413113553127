import styled from 'styled-components';
import { AppLayout } from '../../ui/AppLayout';
import { AppBox } from '../../ui/boxes/AppBox';

export function MainLayout(props) {
  return <AppBox as={Styled} {...props} />;
}

const Styled = styled(AppLayout)({
  // minWidth: '1300px',
  margin: '0 auto',
  background: 'linear-gradient(107.56deg, #f8f5ff 0%, #ffffff 51.56%, #f6f4ff 100%)',
});
