import React, { useState } from 'react';
import styled from 'styled-components';
import { ComparisonTableHead } from './ComparisonTableHead';
import { ComparisonTableBody } from './ComparisonTableBody';

export function ComparisonTable() {
  const [focusedId, setFocusedId] = useState(null);

  // useOutsideClick2(handleBlur);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <StyledTable>
        <ComparisonTableHead />
        <ComparisonTableBody setFocusedId={setFocusedId} focusedId={focusedId} />
      </StyledTable>
    </div>
  );
}

const StyledTable = styled('table')({
  borderSpacing: 0,
  borderRadius: '8px',
  overflow: 'hidden',
  tableLayout: 'fixed',
  width: 'max-content',
  // minWidth: '100%',

  tr: { width: '100%' },
  td: { height: '50px' },
  'th, td': { padding: 0, minWidth: 'unset' },
});

/* IMPROVE: focusing and blurring "value cells" (ValueCell.jsx). Take code below as a basis and finish it. */
// const CN = 'DataCell';
//
// const useOutsideClick2 = callback => {
//   // eslint-disable-next-line unicorn/consistent-function-scoping
//   const handleClick = event => {
//     const contains = event.target.classList.contains('DataCell');
//     const childNode = event.target.querySelector('.DataCell');
//     const parentNode = event.target.closest('.DataCell');
//     const hasClassName = contains || parentNode !== null;
//
//     if (!hasClassName) {
//       // callback();
//     }
//   };
//
//   useEffect(() => {
//     document.addEventListener('click', handleClick);
//
//     return () => {
//       document.removeEventListener('click', handleClick);
//     };
//   });
// };
