import { ConditionalItemSelect } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/ConditionItem/ConditionalItemSelect';

import { AppNewSelect } from 'src/ui/AppNewSelect';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';

import {
  mapSelectOptions,
  resultTypeFlowProperties,
} from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import { FlowImageSelection } from './FlowImageSelection';

import styles from '../ConditionItem.module.scss';

const mappedFlowOptions = mapSelectOptions(Object.values(resultTypeFlowProperties));

/**
 * @desc Flow
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
export function Flow({ value, onChange }) {
  const { handlePropertyChange, handleValueChange } = useConditionResult({
    value,
    onChange,
  });

  const { property, composerElements, composerElement } = value;

  return (
    <>
      <AppNewSelect
        label='Flow action'
        titleSearch
        className={`${styles.largeSelect} ${styles.selectItem}`}
        value={property}
        isSearchable
        onChange={({ value }) => {
          handlePropertyChange(value);
        }}
        options={mappedFlowOptions}
      />
      {property && property !== resultTypeFlowProperties.imageSelection && (
        <ConditionalItemSelect
          label={property}
          value={property !== resultTypeFlowProperties.goTo ? composerElements : composerElement}
          isMultiSelect={property !== resultTypeFlowProperties.goTo}
          onChange={selection => {
            handleValueChange(property !== resultTypeFlowProperties.goTo ? 'composerElements' : 'composerElement', selection);
          }}
        />
      )}

      {property === resultTypeFlowProperties.imageSelection && <FlowImageSelection value={value} onChange={onChange} />}
    </>
  );
}
