import { Finder } from '../../lib/Finder';
import { useFinderDateColumn } from '../../components/useFinderDateColumn';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function useLastEditedDateColumn() {
  const { lastEdit: title } = useLocalization().translations.shared.columns;

  return useFinderDateColumn({
    arrangedId: Finder.LAST_EDITED_DATE,
    title,
    filterFromName: 'lastModifiedDateFrom',
    filterToName: 'lastModifiedDateTo',
  });
}
