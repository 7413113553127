export const Layout = {
  NAME: 'name',
  ID: 'id',
  CREATOR: 'createdBy',
  IS_ACTIVE: 'activated',
  CREATION_DATE: 'createdDate',
  USED_IN_PUBLISHED_FINDERS: 'usedInPublishedFinders',
  USED_IN_TOTAL_FINDERS: 'usedInTotalFinders',
  USED_IN_EDITING_FINDERS: 'usedInEditingFinders',
  PREVIEW_IMAGE: 'previewImage',
};

export const LayoutStatus = {
  ACTIVATED: true,
  DEACTIVATED: false,
};
