import { safeExecute, safeStr } from '../../../../../../../../lib/util/helpers';

export function getResultProductName(productInformation, product) {
  return productInformation?.name && productInformation?.isShortProductName
    ? product?.productNameSection?.shortProduct?.title
    : product?.productNameSection?.product?.title;
}

// eslint-disable-next-line i18n-text/no-en
const NO_CARD = 'Keine girocard';
const MIN_DISPLAYED_VALUE_OF_MULTIPLE_PRODUCT_AMOUNT = 2;

export function getMultipleProductAmount(product) {
  if (product?.counterSection?.hardcodeAmount) {
    return product?.counterSection?.hardcodeAmount;
  }
  if (product?.counterSection?.ruleAmount) {
    return product?.counterSection?.ruleAmount;
  }

  return product?.productNameSection?.product?.title !== NO_CARD && product?.counterSection?.value;
}

export function checkCanShowProductAmount(multipleProductAmount) {
  return multipleProductAmount >= MIN_DISPLAYED_VALUE_OF_MULTIPLE_PRODUCT_AMOUNT;
}

function formatAddressAndContacts(address, contacts) {
  const formatLine = line => {
    if (Array.isArray(line)) {
      return line.filter(Boolean).join(' ').replaceAll(' ,', ',').trim();
    }
    return line;
  };

  const formattedAddress = address.map(formatLine).filter(Boolean).join('\n');
  const formattedContacts = contacts.filter(Boolean).join('\n');

  if (!formattedAddress && !formattedContacts) return '';

  if (!formattedContacts) return formattedAddress;

  if (!formattedAddress) return formattedContacts;

  return `${formattedAddress}\n\n${formattedContacts}`;
}

export function getAddressAndCommunication(tenant = {}) {
  const maybeFirstLineAddress = safeStr(tenant.bankAddress);
  const maybeAddress2ndLine = safeStr(tenant.addressLine2);
  const maybePlz = safeStr(tenant.plz);
  const maybeBankCity = safeStr(tenant.bankCity);
  const maybePhone = tenant.bankPhone ? `Tel.: ${tenant.bankPhone}` : '';
  const maybeEmail = tenant.bankEmail ? `Email: ${tenant.bankEmail}` : '';

  const address = [maybeFirstLineAddress, maybeAddress2ndLine, [maybePlz, maybeBankCity]];
  const contacts = [maybePhone, maybeEmail];

  return formatAddressAndContacts(address, contacts);
}

export function checkIsPdfChanged(stringifiedPrintedResultProps, printedResultProps) {
  const prev = stringifiedPrintedResultProps;
  const curr = safeExecute(() => JSON.stringify(printedResultProps));
  return Boolean(prev && curr && prev !== curr);
}
