import './AppSpinner.sass';
import classNames from 'classnames';

export function AppSpinner({ show, size = 12, circleWidth = 1, className, style }) {
  return (
    <div
      className={classNames('AppSpinner', { show }, className)}
      style={{
        width: size,
        height: size,
        borderWidth: circleWidth,
        ...style,
      }}
    />
  );
}
