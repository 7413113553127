import { AppList } from '../../../../../../../../ui/AppList';
import styled from 'styled-components';
import { forwardRef } from 'react';

export const ListWrapper = forwardRef(({ children, ...rest }, ref) => {
  return (
    <Root {...rest} ref={ref}>
      {children}
    </Root>
  );
});

const getBorder = (borderColor = '#5C5C6E') => `1px solid ${borderColor}`;

const Root = styled(AppList)(({ borderColor }) => ({
  borderTop: ({ withBorderTop = true }) => (withBorderTop ? getBorder(borderColor) : 'unset'),

  '& > li': {
    borderBottom: getBorder(borderColor),
  },
}));
