import { AppModal } from '../../../../../../../../../ui/AppModal';
import { Typography } from 'antd';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';

export function PreviewAudioGuideModal({
  setAudioGuideModal,
  close,
  isOpen,
  isModalLoading = false,
  headline,
  subLine,
  primaryButton,
  secondaryButton,
  fonts,
  colors,
  setIsAudioGuidePlaying,
}) {
  const submit = () => {
    setAudioGuideModal(true);
    setIsAudioGuidePlaying(true);
    close();
  };

  const onClose = () => {
    setAudioGuideModal(false);
    close();
  };

  return (
    <AppModal
      open={isOpen}
      cancelButtonProps={{
        onClick: onClose,
        textValue: secondaryButton,
        hovercolor: colors[ColorVariants.PRIMARY_COLOR],
        style: {
          fontWeight: 700,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
        },
      }}
      actionButtonProps={{
        onClick: submit,
        loading: isModalLoading,
        textValue: primaryButton,
        style: {
          backgroundColor: colors[ColorVariants.PRIMARY_COLOR],
          fontWeight: 700,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
        },
      }}
    >
      <Typography.Title
        style={{
          textAlign: 'center',
          fontSize: fonts.headlineFirst.fontSize,
          fontFamily: fonts.headlineFirst.font,
          fontWeight: fonts.headlineFirst.fontWeight,
          color: colors[fonts.headlineFirst.colorVariant],
        }}
      >
        {headline}
      </Typography.Title>
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          fontSize: fonts.body.fontSize,
          fontFamily: fonts.body.font,
          fontWeight: fonts.body.fontWeight,
          color: colors[fonts.body.colorVariant],
        }}
      >
        {subLine}
      </Typography.Paragraph>
    </AppModal>
  );
}
