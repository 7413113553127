import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { checkCanShowProductAmount, getMultipleProductAmount, getResultProductName } from '../helpers';
import styled from 'styled-components';

export function ResultProductTitle({ productInformation = {}, colors = {}, product = {}, fonts = {}, disableAdaptive = false, ...rest }) {
  const { style, ...restProps } = rest;
  const { windowWidth } = useWindowResize();
  const multipleProductAmount = getMultipleProductAmount(product);
  const canShowProductAmount = checkCanShowProductAmount(multipleProductAmount);
  const productName = getResultProductName(productInformation, product);

  const allStyle = {
    overflow: 'hidden',
    marginBottom: '0px',
    fontSize: windowWidth > AppBreakpoint.M || disableAdaptive ? fonts?.body?.fontSize : fonts?.subText?.fontSize,
    fontFamily: fonts?.body?.font,
    fontWeight: 700,
    color: colors?.[fonts?.body?.colorVariant],
    textWrap: 'wrap',
    ...style,
  };

  return (
    <AppText style={allStyle} {...restProps}>
      {canShowProductAmount && (
        <>
          <span style={styles.multipleProductAmount}>{`${multipleProductAmount}x`}</span>&nbsp;
        </>
      )}

      <StyledProductName>{productName}</StyledProductName>
    </AppText>
  );
}

const styles = {
  multipleProductAmount: {
    fontStyle: 'italic',
    textDecoration: 'underline',
  },
};

const StyledProductName = styled('span')({
  wordBreak: 'break-all !important',
});
