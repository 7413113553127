import styled from 'styled-components';
import { DetailTableBulletPoints } from './DetailTableBulletPoints';

export function CollapseInner({ detailTable, colors, fonts, applyVariable }) {
  const { bulletPointsSection } = detailTable;
  return (
    <Root>
      <DetailTableBulletPoints bulletPointsSection={bulletPointsSection} colors={colors} fonts={fonts} applyVariable={applyVariable} />
    </Root>
  );
}

const Root = styled('div')({
  textAlign: 'center',
});
