import { useResultProps } from '../../hooks/useResultProps';
import styles from '../PreviewResultLayout/styles.module.scss';
import { InfoIconComponent } from '../InfoIconComponent';
import { ResultButtons } from '../PreviewResultLayout/components/ResultButtons';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../lib/util/Adaptive';
import { DepotProducts } from './DepotProducts';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

// eslint-disable-next-line max-lines-per-function
export function PreviewResultDepotLayout({
  resultObject,
  colors,
  fonts,
  style,
  allPages,
  pagesFlow,
  setPagesFlow,
  fontsForLoading,
  setPageHistory,
  pageHistory,
  currentElements,
}) {
  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, buttonsStyle = {} } = style;
  const { applyVariable } = useVariables(pagesFlow);

  const { windowWidth } = useWindowResize();

  const {
    content: { buttons, products },
  } = resultObject;

  const { headline, printedResultProps = {} } = useResultProps({
    allPages,
    resultObject,
    fonts,
    colors,
    headlineStyle,
    sublineStyle,
    pagesFlow,
    fontsForLoading,
    pageHistory,
  });

  return (
    <section
      className={styles.section}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#F2F2F3',
        padding: windowWidth < AppBreakpoint.M && '0 16px',
      }}
    >
      <div className={styles.container} style={rootStyle}>
        {headline?.value && (
          <h2
            style={{
              ...fonts.headlineSecond,
              marginBottom: 15,
              overflow: 'hidden',
              wordBreak: 'break-all',
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.headlineSecond.fontSize,
              fontFamily: fonts.headlineSecond.font,
              fontWeight: fonts.headlineSecond.fontWeight,
              color: colors[fonts.headlineSecond.colorVariant],
              ...headlineStyle,
            }}
          >
            {applyVariable(headline.value)}
            <InfoIconComponent applyVariable={applyVariable} type={headline} />
          </h2>
        )}
        <DepotProducts
          products={products}
          fonts={fonts}
          colors={colors}
          setPagesFlow={setPagesFlow}
          pagesFlow={pagesFlow}
          setPageHistory={setPageHistory}
          currentElements={currentElements}
        />
        <ResultButtons
          list={buttons.filter(button => pagesFlow.allShownButtonsIds.includes(button.id))}
          buttonsStyle={{ ...buttonsStyle, height: 43, marginBottom: windowWidth > AppBreakpoint.PHONE && '16px' }}
          setPagesFlow={setPagesFlow}
          pagesFlow={pagesFlow}
          setPageHistory={setPageHistory}
          printedResultProps={printedResultProps}
          colors={colors}
          fonts={fonts}
          resultObject={resultObject}
          currentElements={currentElements}
          style={{
            // marginBottom: windowWidth < AppBreakpoint.TABLET_LANDSCAPE && 0,
            marginTop: windowWidth < AppBreakpoint.TABLET_LANDSCAPE && '16px',
            gridTemplateColumns: windowWidth < AppBreakpoint.M && 'repeat(auto-fill, minmax(178px, 380px))',
            padding: windowWidth < AppBreakpoint.M && 0,
          }}
        />
      </div>
    </section>
  );
}
