import { Form } from 'antd';
import { ArrangeSortAndFilterPopupWrapper } from '../ArrangeSortAndFilterPopupWrapper';
import { useResetArrange } from '../../utils/hooks/useResetArrange';
import { AppNewDatePicker } from '../../../../../../ui/AppNewDatePicker';
import { ArrangeFormItem } from '../../utils/model/Arrange';
import styled from 'styled-components';
import { appDayJs } from '../../../../../../lib/models/Time';
import { AppFormItem } from '../../../../../../ui/AppFormItem';

const NAME = ArrangeFormItem.DATES;
const SIZE = 'small';

function getEndOfTheUTCDayByDate(date) {
  // eslint-disable-next-line no-magic-numbers
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
}

function geStartOfTheUTCDayByDate(date) {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

function formatDates([startDate, endDate]) {
  return [
    startDate ? appDayJs(geStartOfTheUTCDayByDate(startDate)).toISOString() : undefined,
    endDate ? appDayJs(getEndOfTheUTCDayByDate(endDate)).toISOString() : undefined,
  ];
}

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }

  const instance = new Date(date);
  return new Date(instance.getUTCFullYear(), instance.getUTCMonth(), instance.getUTCDate());
}

export function ArrangeDatePopup({ onFinish, form, initialValues, isActiveArrange }) {
  const datesValue = Form.useWatch(NAME, form);
  const fromDate = datesValue?.[0];
  const toDate = datesValue?.[1];
  const startDate = convertUTCToLocalDate(fromDate);

  const onChange = dates => {
    form.setFieldValue(NAME, formatDates(dates));
  };

  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeSortAndFilterPopupWrapper>
        <Styled size={SIZE} name={NAME}>
          <AppNewDatePicker
            isClearable
            hasFeedback={false}
            selectsRange
            size={SIZE}
            selected={startDate}
            startDate={startDate}
            endDate={convertUTCToLocalDate(toDate)}
            onChange={onChange}
            style={{ overflow: 'visible', height: '100%' }}
            placeholderText='Dates' // REFACTOR: move it to translations
          />
        </Styled>
      </ArrangeSortAndFilterPopupWrapper>
    </Form>
  );
}

const Styled = styled(AppFormItem)({
  '.react-datepicker-wrapper': {
    width: '100%',
  },

  '.react-datepicker__close-icon::after': {
    background: '#9696A2',
    minWidth: '12px',
    minHeight: '12px',
  },

  '.AppInputInner': {
    height: 'unset',

    '& > input': {
      padding: '5px 30px 5px 16px',
    },
  },
});
