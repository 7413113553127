import { RuleSearchDataHeaderCell } from './RuleSearchDataHeaderCell';

/**
 * @desc Custom hook for managing rule search data columns.
 * @param {Object} props - An object containing properties for the rule search data column.
 * @param {string} props.arrangedId - The ID of the arranged item.
 * @param {Object} props.columnProps - Additional properties for the column.
 * @returns {Object} - An object containing properties for managing rule search data columns.
 */
export function useRuleSearchDataColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    dataIndex: props.arrangedId,
    title: <RuleSearchDataHeaderCell {...rest} />,
    ...columnProps,
  };
}
