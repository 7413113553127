export const ROUTER_IDS = {
  home: 'home',
  logIn: 'logIn',
  setUpPassword: 'setUpPassword',
  templates: 'templates',
  template: 'template',
  tenants: 'tenants',
  tenant: 'tenant',
  finders: 'finders',
  finder: 'finder',
  yourFinders: 'yourFinders',
  themes: 'themes',
  theme: 'theme',
  users: 'users',
  profile: 'profile',
  notFound: 'notFound',
  questionnaire: 'questionnaire',
  settings: 'settings',
  products: 'products',
  preview: 'preview',
  rules: 'rules',
  builder: 'builder',
};
