export function DesertIllustration() {
  return (
    <svg width='216' height='216' viewBox='0 0 216 216' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='108.037' cy='108' rx='107.962' ry='108' fill='#B2E6EC' />
      <path
        d='M215.999 108.004C215.999 118.052 214.627 127.78 212.061 137.01C209.34 146.793 205.277 156.018 200.083 164.472C181.091 195.387 146.97 216.004 108.036 216.004C69.1021 216.004 34.9816 195.387 15.9888 164.472C7.07892 149.97 -0.851707 128.061 0.0736876 106.153C14.88 107.387 27.527 109.101 38.6317 102.45C83.0505 75.8431 94.4637 58.0156 107.111 58.0156C125.236 58.0156 167.261 70.2149 170.037 71.2842C172.814 72.3534 215.999 96.3704 215.999 108.004Z'
        fill='#CA7444'
      />
      <path
        d='M37.7065 168.792C48.5027 160.46 70.7121 146.883 91.3792 140.403C110.504 136.7 85.8192 213.485 72.2544 209.832C56.2143 205.512 34.0049 188.54 27.2187 179.283C26.5761 178.407 32.6773 172.673 37.7065 168.792Z'
        fill='#975733'
      />
      <path
        d='M216 108.004C216 118.052 214.628 127.78 212.062 137.01C209.342 146.793 205.279 156.018 200.085 164.472C181.092 195.387 146.972 216.004 108.038 216.004C95.7259 216.004 83.8955 213.942 72.8727 210.145C60.6212 205.925 54.6733 193.169 52.8225 179.284C51.1363 166.632 71.9473 146.884 93.8482 139.787C119.416 131.501 131.789 127.135 128.396 108.004C124.707 94.7355 101.435 98.0211 98.7836 89.7984C95.699 80.2327 94.4652 58.0156 107.112 58.0156C125.238 58.0156 167.263 70.2149 170.039 71.2842C172.815 72.3534 216 96.3704 216 108.004Z'
        fill='#FC9155'
      />
    </svg>
  );
}
