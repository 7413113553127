import { Typography } from 'antd';
import styled from 'styled-components';
import { TranslatableBox } from '../boxes/TranslatableBox';
import { useCombinedComponents } from '../../lib/hooks/useCombinedComponents';
import classNames from 'classnames';

const StyledText = styled(Typography.Text)({});

export function AppText({ as, ...props }) {
  const { className, ...rest } = props;
  return useCombinedComponents({
    components: [TranslatableBox, StyledText, as],
    props: { className: classNames('AppText', className), ...rest },
  });
}
