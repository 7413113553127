import { TextFieldWithInfoIcon } from 'src/processes/CMS/processes/management/pages/QuestionnairePage/features/TextFieldWithInfoIcon';
import { AppRadioGroup } from 'src/ui/AppRadioGroup';
import { AppRadio } from 'src/ui/AppRadio';

export function ComparisonRadioGroup({ tableIndex, topicIndex, structureItemIndex, product, fieldName, onTopicChange }) {
  return (
    <>
      <AppRadioGroup
        value={product.value}
        className='detailTableRadioGroup'
        onChange={event =>
          onTopicChange(fieldName, {
            value: event.target.value,
            customText: product.customText,
            infoIcon: product.infoIcon,
          })
        }
      >
        <AppRadio value='Yes' textValue='Yes' />
        <AppRadio value='No' textValue='No' />
        <AppRadio value='Custom text' textValue='Custom text' />
      </AppRadioGroup>
      {product.value === 'Custom text' && (
        <TextFieldWithInfoIcon
          data={product.customText}
          dataPath={`[${structureItemIndex}].content.contentBlocks[0].detailTables[${tableIndex}].topics[${topicIndex}][${fieldName}].customText`}
        />
      )}
    </>
  );
}
