import { InfoIcon } from '../../../../../../../../ui/icons/InfoIcon';
import { AppTooltip } from '../../../../../../../../ui/AppTooltip';
import { AppTitle } from '../../../../../../../../ui/text/AppTitle';
import { AppBox } from '../../../../../../../../ui/boxes/AppBox';

export function ContentTop({ title, tooltipText }) {
  return (
    <AppBox $flexY style={styles.root}>
      <AppTitle textValue={title} level={3} style={styles.title} />

      <AppTooltip text={tooltipText}>
        <span>
          <InfoIcon style={styles.infoIcon} />
        </span>
      </AppTooltip>
    </AppBox>
  );
}

const styles = {
  root: {
    borderBottom: '1.5px solid #CFCFD4',
    padding: '13px 16px ',
  },
  title: { margin: '0 8px 0 0' },
  infoIcon: { display: 'flex', color: '#5C5C6E' },
};
