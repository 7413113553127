import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { getTenantsRequest } from '../../../../../../../../lib/store/slices/tenantsSlice';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../lib/consts';

export function useTenantNames() {
  const dispatch = useDispatch();
  const { tenants: tenantList } = useSelector(({ tenants }) => tenants);
  const tenantNames = isLength(tenantList) ? tenantList.map(item => ({ value: item.id, title: item.name })) : [];

  useEffect(() => {
    dispatch(getTenantsRequest({ params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE, page: 0 } })); // TODO: replace requesting huge amount of data with pagination and infinite scroll
  }, [dispatch]);

  return {
    tenantNames,
  };
}
