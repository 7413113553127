import { ProductLayoutCounter } from './ProductLayoutCounter';
import { ProductLayoutCard } from '../../components/ProductLayoutCard';
import styled from 'styled-components';

export function ProductLayoutCardWithCounter(props) {
  const { data } = props;
  const canShowCounter = data.counter?.showCounter;

  return (
    <Root>
      <ProductLayoutCard {...props} />
      <StyledProductLayoutCounter>{canShowCounter && <ProductLayoutCounter {...data.counter} />}</StyledProductLayoutCounter>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 100%',
});

const StyledProductLayoutCounter = styled('div')({
  height: 80,
  marginTop: 24,
});
