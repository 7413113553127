import { useSelector } from 'react-redux';
import { AppText } from '../../../../ui/text/AppText';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import './styles.scss';
import { dateFormatter } from '../../lib/helpers';

export function TenantCard() {
  const {
    tenant: { contractStart, contractEnd, contactSkp, comment, users },
  } = useSelector(({ finders }) => finders);
  const { translations } = useLocalization();
  const { columns: sharedColumns } = translations.shared;
  const { columns: tenantsColumns } = translations.unique.pages.tenants;

  return (
    <div className='tenant_card'>
      <div className='tenant_card_column'>
        <AppText className='tenant_card_title white_color' textValue={tenantsColumns.contractStart} />
        <AppText className='white_color'>{dateFormatter(contractStart)}</AppText>
      </div>
      <div className='tenant_card_column'>
        <AppText className='tenant_card_title white_color' textValue={tenantsColumns.contractEnd} />
        <AppText className='white_color'>{dateFormatter(contractEnd)}</AppText>
      </div>
      <div className='tenant_card_column'>
        <AppText className='tenant_card_title white_color' textValue={tenantsColumns.contactSKP} />
        <AppText className='white_color'>{contactSkp}</AppText>
      </div>
      <div className='tenant_card_column'>
        <AppText className='tenant_card_title white_color' textValue={sharedColumns.comments} />
        <AppText className='white_color' textValue={comment} />
      </div>
      <div className='tenant_card_column'>
        <AppText className='tenant_card_title white_color' textValue={tenantsColumns.users} />
        <AppText className='white_color'>{Boolean(users?.length) && users.map(user => user.login).join(', ')}</AppText>
      </div>
    </div>
  );
}
