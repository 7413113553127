import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { useLayoutArrangeRequests } from '../lib/useLayoutArrangeRequests';
import { useDateColumn } from '../../../../../components/columns/date';
import { clearDateFilters } from '../../../../../utils/arranges-helpers';
import { useDateArrange } from '../../../../../utils/hooks/arranges-by-type/useDateArrange';

export function useLayoutDateColumn({ title, arrangedId, filterFromName, filterToName, headerCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ layouts }) => layouts);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useLayoutArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearDateFilters(form),
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
}
