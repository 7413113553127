import { AppText } from '../../../../../../ui/text/AppText';
import { AppBox } from '../../../../../../ui/boxes/AppBox';
import { ImagesIcon } from '../../../../../../ui/icons/ImagesIcon';
import { UploadedMediaRow } from '../UploadedMediaRow';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import './styles.scss';

export function UploadImageLabel({ mediaObject, deleteOnClick, uploading, imgHeight }) {
  const { chooseImage } = useLocalization().translations.unique.formItems;

  return mediaObject ? (
    <AppBox className='upload_image_box upload_image_box__uploaded'>
      <UploadedMediaRow media={mediaObject} deleteOnClick={deleteOnClick} uploading={uploading} imgHeight={imgHeight} />
    </AppBox>
  ) : (
    <AppBox $flexCenterXY className='upload_image_box upload_image_box__not_uploaded'>
      <div className='upload_image_inner'>
        <ImagesIcon />
        <AppText type='secondary' textValue={chooseImage.text} />
      </div>
    </AppBox>
  );
}
