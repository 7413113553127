import { PreviewContentBlocks } from './PreviewContentBlocks';
import { ButtonOrLinkComponent } from '../ButtonOrLinkComponent';

export function PreviewContentLayout({
  media,
  contentObject,
  colors,
  fonts,
  style,
  bulletIcons,
  setPagesFlow,
  pagesFlow,
  setPageHistory,
  currentElements,
}) {
  const { content } = contentObject;

  return (
    <>
      {Array.isArray(content?.contentBlocks) && (
        <PreviewContentBlocks
          pagesFlow={pagesFlow}
          contentElement={contentObject}
          media={media}
          bulletIcons={bulletIcons}
          colors={colors}
          fonts={fonts}
          style={style}
        />
      )}
      {content?.composerButton && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
          <ButtonOrLinkComponent
            contentObject={content?.composerButton}
            style={{
              backgroundColor: colors.primaryColor,
              height: 48,
              minWidth: 200,
              borderRadius: 24,
              padding: '5px 8px',
              color: '#FFFFFF',
            }}
            setPagesFlow={setPagesFlow}
            pagesFlow={pagesFlow}
            textStyle={{
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: fonts.body.fontWeight,
            }}
            setPageHistory={setPageHistory}
            currentElements={currentElements}
          />
        </div>
      )}
    </>
  );
}
