import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { useRuleArrangeRequests } from '../../lib/useRuleArrangeRequests';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Rule } from '../../lib/Rule';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { ArrangeListPopup } from '../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../components/TableHeaderCell';
import { User } from '../../../../UsersPage/components/UsersTable/lib/User';
import { getUsersByLoginFromApi } from '../../../../../utils/api';

export function RuleCreatorHeaderCell() {
  const { creator: title } = useLocalization().translations.shared.columns;
  const { activeArrangedId, sorting } = useSelector(({ rules }) => rules);
  const isActiveArrange = activeArrangedId === Rule.CREATOR;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const { filtering } = useStringArrange({
    form,
    request: getUsersByLoginFromApi,
    formatResponse: data => formatArrangedData(data, User.USERNAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useRuleArrangeRequests({
    arrangedId: Rule.CREATOR,
    sortFieldName: Rule.CREATOR,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ includeCreatedBy: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
