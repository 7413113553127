import { publicAxiosInstance } from '../../../../../../../../../lib/util/http/axios/axios-instance';

export async function verifyReCaptchaToken(token, finderUrl) {
  try {
    const response = await publicAxiosInstance.post('global/public/verify-captcha', {
      token,
      finderUrl: encodeURIComponent(finderUrl),
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
