import { HamburgerPKP4P1ProductCard } from '../../PreviewProductCard/PreviewProductCardContainer/hardcoded/HamburgerPKP4P1ProductCard';
import { ProductCardId } from '../../../models/Hardcode';
import { PreviewProductCardPresentational } from '../../PreviewProductCard/PreviewProductCardPresentational';

export function DisplayedProductCard(props) {
  const cardId = props.card.id;

  if (cardId === ProductCardId.HAMBURGER_PK_P4_1) {
    return <HamburgerPKP4P1ProductCard {...props} />;
  }

  return <PreviewProductCardPresentational {...props} />;
}
