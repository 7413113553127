import { InfoIconComponent } from '../../../InfoIconComponent';
import styled from 'styled-components';
import { useWindowResize } from '../../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../../lib/util/Adaptive';
import { Score } from './CollapseInner/Score';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../../../../../../../../../ui/icons/ChevronDownIcon';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function DetailTableHeadline({ colors, fonts, applyVariable, detailTable, activeKey }) {
  const { details } = useLocalization().translations.unique.pages.finders.detailTable;
  const { windowWidth } = useWindowResize();

  const { headline, subline, score, bulletPointsSection } = detailTable;

  return (
    <>
      <StyledHeader
        backgroundColor={colors.primaryColor}
        activeKey={activeKey}
        sublineValue={subline.value}
        showScore={score.showScore}
        windowWidth={windowWidth}
        bulletPoints={bulletPointsSection.bulletPoints}
      >
        <StyledHeaderText windowWidth={windowWidth}>
          <p
            style={{
              overflow: 'hidden',
              marginBottom: 0,
              fontSize: fonts.headlineFourth.fontSize,
              fontFamily: fonts.headlineFourth.font,
              fontWeight: fonts.headlineFourth.fontWeight,
              color: colors[fonts.headlineFourth.colorVariant],
              marginLeft: windowWidth > AppBreakpoint.DESKTOP && '130px',
            }}
          >
            {applyVariable(headline.value)}
          </p>
          {headline.value && <InfoIconComponent applyVariable={applyVariable} type={headline} style={{ marginLeft: 10 }} />}
        </StyledHeaderText>
        <StyledHeaderIcon windowWidth={windowWidth}>
          <ExpandIconWrapper>
            {windowWidth > AppBreakpoint.M && details()}
            {activeKey.length > 0 ? (
              <ChevronUpIcon fill='#fff' style={{ marginLeft: 8 }} />
            ) : (
              <ChevronDownIcon fill='#fff' style={{ marginLeft: 8 }} />
            )}
          </ExpandIconWrapper>
        </StyledHeaderIcon>
      </StyledHeader>
      <StyledBottom activeKey={activeKey} onClick={event => event.stopPropagation()}>
        {score.showScore && <Score score={score} colors={colors} />}
        {subline.value && (
          <p
            style={{
              overflow: 'hidden',
              marginTop: 8,
              marginBottom: 16,
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: fonts.body.fontWeight,
              color: colors[fonts.body.colorVariant],
              textAlign: 'center',
            }}
          >
            {applyVariable(subline.value)}
            {subline.value && <InfoIconComponent applyVariable={applyVariable} type={subline} />}
          </p>
        )}
      </StyledBottom>
    </>
  );
}

const StyledHeader = styled('div')(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  backgroundColor: props.backgroundColor,
  borderRadius:
    props.sublineValue || props.showScore || (props.bulletPoints.length > 0 && props.activeKey.length > 0)
      ? '8px 8px 0px 0px !important'
      : '8px 8px 8px 8px !important',
}));

const StyledHeaderText = styled('div')(props => ({
  display: 'flex',
  justifyContent: props.windowWidth > AppBreakpoint.DESKTOP ? 'center' : 'left',
  flex: props.windowWidth > AppBreakpoint.DESKTOP && '1 1 100%',
}));

const StyledHeaderIcon = styled('div')(props => ({
  flex: props.windowWidth > AppBreakpoint.DESKTOP && '1 1 15%',
  minWidth: props.windowWidth > AppBreakpoint.DESKTOP && '125px',
}));

const ExpandIconWrapper = styled('span')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 50,
  fontSize: '16px',
});

const StyledBottom = styled('span')(props => ({
  background: '#fff',
  padding: '16px 0',
  borderRadius: props.activeKey.length === 0 && '0 0 8px 8px',
  overflow: 'hidden',
}));
