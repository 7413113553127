import { useEffect, useState } from 'react';
import { useUpdateProductsStructure } from '../../../../../hooks/useCardLogic/generic/useUpdateProductsStructure';
import { useScrollToCard } from '../useScrollToCard';
import { getUpdatedCards } from './getUpdatedCards';
import { usePrevious } from '../../../../../../../../../../../lib/hooks/usePrevious';
import { useOnUpdateCountersAllChapters } from '../useOnUpdateCounter';
import { isLength } from '../../../../../../../../../../../lib/util/helpers';

const getUnselectedIdsArr = products => {
  return products.map(card => card?.id).filter(Boolean);
};

// eslint-disable-next-line max-lines-per-function
export function useHandleSelectionOnMount({
  selectable,
  recommendedIndexList,
  preSelectedCardIndex,
  products,
  setDragging,
  carouselRef,
  windowWidth,
  setPagesFlow,
  structureItemId,
  allPages,
  setSelectionModalOpen,
  pagesFlow,
  currentElements,
  scoringResult,
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const updateProductsStructure = useUpdateProductsStructure(setPagesFlow, structureItemId);
  const updateCountersAllChapters = useOnUpdateCountersAllChapters(setPagesFlow);
  const previousRecommendedIndexList = usePrevious(recommendedIndexList);
  const prevStr = JSON.stringify(previousRecommendedIndexList);
  const currStr = JSON.stringify(recommendedIndexList);

  const scrollToPreSelectedCard = useScrollToCard({
    setDragging,
    carouselRef,
    windowWidth,
    cardIndex: preSelectedCardIndex,
  });

  useEffect(() => {
    const defaultCounterProducts = products.filter(prod => prod?.counterSection?.showDefaultCounter && prod?.counterSection?.value > 0);
    if (isFirstRender && isLength(defaultCounterProducts)) {
      if (selectable) {
        const selectedDefaultCounterProducts = defaultCounterProducts.filter(product => product.selected);
        updateCountersAllChapters(selectedDefaultCounterProducts);
      } else {
        updateCountersAllChapters(defaultCounterProducts);
      }
    }

    const areRecommendationsSame = prevStr === currStr;

    const productsWithoutSelection = products.map(prod => prod?.selected).filter(selected => selected === undefined);

    if (isFirstRender && productsWithoutSelection.length !== products.length) {
      setIsFirstRender(false);
      return;
    }

    if (areRecommendationsSame || !selectable) {
      setIsFirstRender(false);
      return;
    }

    scrollToPreSelectedCard();

    const updatedCards = getUpdatedCards({
      products,
      recommendedIndexList,
      allPages,
      setSelectionModalOpen,
      structureItemId,
      isFirstRender,
      pagesFlow,
      currentElements,
      scoringResult,
    });
    const selectedCards = updatedCards.filter(item => item.selected);

    updateProductsStructure(updatedCards, selectedCards, false, null, getUnselectedIdsArr(products));

    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFirstRender,
    selectable,
    updateProductsStructure,
    scrollToPreSelectedCard,
    allPages,
    recommendedIndexList,
    setSelectionModalOpen,
    structureItemId,
    previousRecommendedIndexList,
    prevStr,
    currStr,
    updateCountersAllChapters,
    products,
    scoringResult,
  ]);
}
