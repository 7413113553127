import { TableHeaderCell } from '../../../../components/TableHeaderCell';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { CardImageCell } from '../../../../features/CardImageCell';
import { AppText } from '../../../../../../../../ui/text/AppText';

export function useProductImageLink() {
  const { image: title } = useLocalization().translations.unique.pages.products.table.columns;

  return {
    dataIndex: Product.IMAGE_LINK,
    width: 150,
    render: link => (link ? <CardImageCell imageUrl={link} /> : <AppText>-</AppText>),
    title: <TableHeaderCell title={title} withArrange={false} />,
  };
}
