import { formatPriceForUi, transformCurrencyCodeToSymbol } from '../../../../../../../lib/models/Finance';
import { useProductPriceTypeText } from './useProductPriceTypeText';
import { Product } from '../../FindersAndProductsPage/lib/Product';
import { ResultPriceType } from '../../../utils/consts';

// TODO hardcode: ids for KONTOFINDER (P9.1 and P10.1)
const KONTOFINDER_PK_PRODUCT_9_1_ID = 'e344a746-d246-451e-872b-8aea09aeb317';
const KONTOFINDER_PK_PRODUCT_10_1_ID = '252469e3-3cde-4fb2-ba90-d464a444b4ca';

// TODO ids for P7.1 and P7.2
const KONTOFINDER_PK_PRODUCT_7_1_ID = 'cae08004-665e-4d1a-99c2-cf9a4465faaf';
const KONTOFINDER_PK_PRODUCT_7_2_ID = '15b32346-df0f-4036-ac9e-95bfa3031305';

export function useProductRowPrice(product, productInformation, showPrice, rulePrice, rulePriceType) {
  const priceTypeText = useProductPriceTypeText(product?.priceSection?.priceType, rulePriceType, true);
  if (productInformation?.priceType === ResultPriceType.NO_PRICE) {
    return '';
  }

  return getPrice(product, productInformation, showPrice, rulePrice, rulePriceType, priceTypeText);
}

const getBasePriceInfo = (value, currency, priceTypeText) =>
  `${formatPriceForUi(value)} ${transformCurrencyCodeToSymbol(currency)} ${priceTypeText} `;

const getPricePerPiece = (product, productInformation) => {
  const priceSection = product?.priceSection;
  // Todo: for hardcode products

  if (
    productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE &&
    product?.counterSection?.value && // Todo: for hardcode products
    [KONTOFINDER_PK_PRODUCT_9_1_ID, KONTOFINDER_PK_PRODUCT_10_1_ID, KONTOFINDER_PK_PRODUCT_7_1_ID, KONTOFINDER_PK_PRODUCT_7_2_ID].includes(
      product.id.split('_')[0]
    )
  ) {
    return product.priceSection.priceValue / product.counterSection.hardcodeAmount;
  }

  if (productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE && product?.counterSection) {
    if (product.counterSection?.ruleAmount) {
      return priceSection.priceValue / product.counterSection.ruleAmount;
    }
    if (product.counterSection.value) {
      return priceSection.priceValue / product.counterSection.value;
    }
  }

  return priceSection.priceValue;
};

const getPrice = (product, productInformation, showPrice, rulePrice, rulePriceType, priceTypeText = '') => {
  const priceSection = product?.priceSection;

  if (rulePriceType && rulePriceType === Product.PRICE_PRE_REQUEST && priceSection?.priceType === Product.PRICE_PRE_REQUEST) {
    return priceSection?.priceValue;
  }

  if (rulePriceType && rulePriceType === Product.PRICE_PRE_REQUEST) {
    return rulePriceType;
  }

  if (!rulePriceType && priceSection?.priceType === Product.PRICE_PRE_REQUEST) {
    return priceSection?.priceValue;
  }

  if (showPrice || rulePriceType === priceSection?.priceType) {
    return getBasePriceInfo(getPricePerPiece(product, productInformation), priceSection?.currency, priceTypeText);
  }

  return getBasePriceInfo(getPricePerPiece(product, productInformation), priceSection?.currency, priceTypeText);
};
