import React from 'react';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { QuestionnaireSelect } from '../../../../../features/QuestionnaireSelect';
import { ContactFormAction } from '../../../../../../../../../../../lib/models/Questionnaire';
import { getNextIndex, translateOptionsMapper } from '../../../../../../../../../../../lib/util/helpers';
import { generateContactFormRowStructure } from './utils';
import { OptionType } from './consts';

const formSectionStyle = {
  marginBottom: 0,
};

const formItemProps = {
  style: { minHeight: 'unset', width: '100%' },
};

export function ContactFormRowFieldOptionAction(props) {
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const { rowIndex, fieldIndex, optionIndex, structureItemIndex, field } = props;
  const option = field?.optionList?.[optionIndex];
  const { updateStructure } = useUpdateStructure();

  const createNewDependedRow = draft => {
    const nextIndex = getNextIndex(rowIndex);
    const item = generateContactFormRowStructure({
      condition: {
        fromRowId: draft[structureItemIndex].content.formRowList[rowIndex].id,
        fromOptionId: draft[structureItemIndex].content.formRowList[rowIndex].fieldList[fieldIndex].optionList[optionIndex].id,
        optionType: OptionType.GROUP,
      },
    });

    draft[structureItemIndex].content.formRowList.splice(nextIndex, 0, item);
  };

  const onChange = ({ value }) => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].optionList[${optionIndex}].action`, value);

      if (value === ContactFormAction.CREATE_NEW_ROWS) {
        createNewDependedRow(draft);
      }
    });
  };

  const { actionOptions: actionOptionsText } = useLocalization().translations.unique.pages.questionnaire.content;

  const options = [
    { value: ContactFormAction.CREATE_NEW_ROWS, title: actionOptionsText.createNewRows },
    { value: ContactFormAction.NONE, title: actionOptionsText.none },
  ].map(translateOptionsMapper);

  return (
    <QuestionnaireSelect
      style={formSectionStyle}
      formItemProps={formItemProps}
      selectProps={{
        value: { value: option?.action },
        onChange,
        options,
        label: contentText.action,
      }}
    />
  );
}
