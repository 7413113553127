import { ToggleExpandButton } from './ToggleExpandButton';
import { toggleValue } from '../../../../../../../lib/util/helpers';
import tableStyles from '../../../utils/styles/table.module.sass';
import { TableColumn } from '../../../../../models/Table';

export function useExpandColumn({ areColumnsExpanded, setAreColumnsExpanded }) {
  const onClick = () => setAreColumnsExpanded(toggleValue);

  return {
    dataIndex: TableColumn.EXPAND_BUTTON,
    width: 24,
    className: tableStyles.expandBodyCell,
    title: () => <ToggleExpandButton areColumnsExpanded={areColumnsExpanded} onClick={onClick} />,
  };
}
