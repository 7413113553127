import classNames from 'classnames';
import classes from '../index.module.scss';
import { ROW_KEY, RowType, TABLE_TITLE_COLUMN_INDEX } from './utils';
import { v4 as uuidv4 } from 'uuid';

export function getTableTitleRow(table, additionalRowType) {
  return {
    className: classNames(classes.tableNameRow, {
      [classes.tableNameNotFirst]: additionalRowType === RowType.TABLE_NAME_NOT_FIRST,
    }),
    rowTypes: [RowType.TABLE_NAME],
    [ROW_KEY]: uuidv4(),
    [TABLE_TITLE_COLUMN_INDEX]: {
      type: RowType.TABLE_NAME,
      value: table?.tableName?.value,
    },
  };
}
