import { useCallback } from 'react';

export function useOnSelectHamburgerPkP4P2({ card, updateProductsStructure, updateProductSelect, productObject }) {
  return useCallback(
    customValue => {
      const updatedSelectedProducts = updateProductSelect(customValue);
      const selectedProducts = updatedSelectedProducts.filter(prod => prod.selected);
      const unselectedProductIds = card?.selected ? [card?.id] : [];
      updateProductsStructure(updatedSelectedProducts, selectedProducts, true, productObject, unselectedProductIds);
    },
    [updateProductSelect, card?.selected, card?.id, updateProductsStructure, productObject]
  );
}
