import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  products: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  isModalLoading: false,
  error: null,
  selectedProduct: {},
  modalType: '',
  isModalOpen: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsRequest: state => {
      state.error = null;
      state.loading = true;
      state.products = [];
    },
    getProductsSuccess: (state, action) => {
      state.products = action.payload.products;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getProductsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openProductModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedProduct = action.payload.selectedProduct || {};
    },
    closeProductModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedProduct = {};
    },
    addProductRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    addProductSuccess: state => {
      state.isModalLoading = false;
    },
    addProductError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editProductRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    editProductSuccess: state => {
      state.isModalLoading = false;
    },
    editProductError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    deleteProductRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteProductSuccess: state => {
      state.isModalLoading = false;
    },
    deleteProductError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    /* arranges */
    // apply arranges
    applyProductArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyProductArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeProductFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeProductFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeProductArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getProductsRequest,
  getProductsSuccess,
  getProductsError,
  openProductModal,
  closeProductModal,
  addProductRequest,
  addProductSuccess,
  addProductError,
  editProductRequest,
  editProductSuccess,
  editProductError,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductError,
  applyProductArrangesRequest,
  applyProductArrangesError,
  removeProductFiltersRequest,
  removeProductFiltersError,
  removeProductArranges,
} = productsSlice.actions;
export const products = productsSlice.reducer;
