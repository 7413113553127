export const multiValueChipStyles = {
  background: '#ECECEE',
  padding: '9px 0 9px 8px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  margin: '0px',
  '&:last-child': {
    marginRight: '0px',
  },
};

export const multiValueChipWithoutRemoveStyles = {
  padding: '9px 8px 9px 8px',
};

export const multiValueChipLabelStyles = {
  fontWeight: 'bold',
  fontSize: '12px',
  color: '#191919',
  lineHeight: 1,
  wordBreak: 'break-all',
};
