import { logicalTypes, types } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import { useCondition } from '../hooks/useCondition';
import { useConditionItem } from '../hooks/useConditionItem';

import { CrossIcon } from 'src/ui/icons/CrossIcon';
import { ArrowBarUp } from 'src/ui/icons/ArrowBarUp';
import { ArrowBarDown } from 'src/ui/icons/ArrowBarDown';
import { ConditionItem } from './ConditionItem';
import { Actions } from './Actions';

import styles from '../BuilderPage.module.scss';

/**
 * @desc Condition Body
 * @param {Object} condition
 * @param {Number} index
 * @param {Boolean} isLastItem
 * @returns {JSX.Element}
 */
export function ConditionBody({ condition, index, isLastItem }) {
  const { id, body } = condition;

  const { moveCondition } = useCondition();
  const { updateConditionItem, deleteConditionItem } = useConditionItem(id);

  const isThenOperatorPlaced = body.some(conditionItem => conditionItem.value === logicalTypes.Then);

  const availableTypes = [types.element, types.resultType, types.value, types.variable];
  const lastItem = -1;
  const isLastItemValid = availableTypes.includes(body.at(lastItem).type);

  return (
    <div className={`${styles.conditionBody} ${!isLastItemValid ? styles.hasError : ''}`}>
      <div className={styles.actionRow}>
        <Actions conditionId={id} conditionBody={body} />
        <div className={styles.conditionControls}>
          <button type='button' className={`${styles.actionIconButton} ${index === 0 && styles.disabled}`} onClick={() => moveCondition(index, 'up')}>
            <ArrowBarUp />
          </button>
          <button
            type='button'
            className={`${styles.actionIconButton} ${isLastItem && styles.disabled}`}
            onClick={() => moveCondition(index, 'down')}
          >
            <ArrowBarDown />
          </button>
        </div>
      </div>
      <div className={styles.conditionalSeparator} />
      <div className={styles.conditionalRow}>
        {body.map(({ id: criteriaId, type, value }, bodyIdx) => (
          <div className={styles.conditionalBlock} key={criteriaId}>
            <ConditionItem
              type={type}
              index={bodyIdx}
              body={body}
              value={value}
              onChange={val => updateConditionItem(criteriaId, val)}
              isThenOperatorPlaced={isThenOperatorPlaced}
            />

            {bodyIdx + 1 === body.length && body.length > 1 && (
              <button type='button' className={styles.deleteIcon} onClick={deleteConditionItem}>
                <CrossIcon fill='#5C5C6E' />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
