import { humanizeIndex, isFixedOdd, isLastIndex } from '../../../../../../../lib/util/helpers';
import { RowType } from './utils';
import { getTableTitleRow } from './getTableTitleRow';
import { getTableGenericRow } from './getTableGenericRow';

const isFirstProductRow = rows => rows?.[0]?.cells?.some(item => item?.type === 'product');
const getPlainRowTypes = (tableIndex, array, rowIndex) => [rowIndex === 0 && RowType.FIRST_PLAIN, isLastIndex(array, rowIndex) && RowType.LAST_PLAIN];

const getMaybeNotFirstTableNameRowType = tableIndex => tableIndex !== 0 && RowType.TABLE_NAME_NOT_FIRST;

// Note: order (of pushing rows) is important
export function getDataSource(tableList, applyVariable = () => {}) {
  return tableList?.map((table, tableIndex) => {
    let rows = table?.tableRows;
    const result = [];

    if (isFirstProductRow(rows)) {
      const [productsRow, ...restRows] = rows;
      rows = restRows;
      result.push(getTableGenericRow({ row: productsRow, rowTypes: [RowType.PRODUCTS] }));
    }

    if (table?.tableName?.value !== '') {
      result.push(getTableTitleRow(table, getMaybeNotFirstTableNameRowType(tableIndex)));
    }

    // eslint-disable-next-line unicorn/no-array-for-each
    rows?.forEach((row, rowIndex, array) => {
      result.push(
        getTableGenericRow({
          row,
          rowTypes: getPlainRowTypes(tableIndex, array, rowIndex),
          subTableRowIndex: rowIndex,
          isLastOdd: isLastIndex(array, rowIndex) && isFixedOdd(humanizeIndex(rowIndex)),
        })
      );
    });

    return result.map(resultItem => {
      const updatedResultItem = { ...resultItem };
      for (const key of Object.keys(updatedResultItem)) {
        if (updatedResultItem[key] && !Array.isArray(updatedResultItem[key]) && typeof updatedResultItem[key] === 'object') {
          updatedResultItem[key] = {
            ...updatedResultItem[key],
            value: applyVariable(updatedResultItem[key].value),
          };
        }
      }

      return updatedResultItem;
    });
  });
}
