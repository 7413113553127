export function CenterAlignIcon() {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 7C4 6.86739 4.05268 6.74021 4.14645 6.64645C4.24021 6.55268 4.36739 6.5 4.5 6.5H7.5C7.63261 6.5 7.75979 6.55268 7.85355 6.64645C7.94732 6.74021 8 6.86739 8 7C8 7.13261 7.94732 7.25979 7.85355 7.35355C7.75979 7.44732 7.63261 7.5 7.5 7.5H4.5C4.36739 7.5 4.24021 7.44732 4.14645 7.35355C4.05268 7.25979 4 7.13261 4 7ZM2 4C2 3.86739 2.05268 3.74021 2.14645 3.64645C2.24021 3.55268 2.36739 3.5 2.5 3.5H9.5C9.63261 3.5 9.75979 3.55268 9.85355 3.64645C9.94732 3.74021 10 3.86739 10 4C10 4.13261 9.94732 4.25979 9.85355 4.35355C9.75979 4.44732 9.63261 4.5 9.5 4.5H2.5C2.36739 4.5 2.24021 4.44732 2.14645 4.35355C2.05268 4.25979 2 4.13261 2 4ZM0 1C0 0.867392 0.0526785 0.740215 0.146447 0.646447C0.240215 0.552679 0.367392 0.5 0.5 0.5H11.5C11.6326 0.5 11.7598 0.552679 11.8536 0.646447C11.9473 0.740215 12 0.867392 12 1C12 1.13261 11.9473 1.25979 11.8536 1.35355C11.7598 1.44732 11.6326 1.5 11.5 1.5H0.5C0.367392 1.5 0.240215 1.44732 0.146447 1.35355C0.0526785 1.25979 0 1.13261 0 1Z'
        fill='white'
      />
    </svg>
  );
}
