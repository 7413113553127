/* eslint-disable id-denylist,no-magic-numbers,init-declarations,no-prototype-builtins,max-lines,no-unsafe-optional-chaining */
import { EMPTY_LENGTH, FIRST_INDEX, FIRST_REQUEST_PAGE, NO_FOUND_INDEX } from './constants';
import { formatSortQuery } from '../../processes/CMS/processes/management/utils/helpers';
import { defaultBackgroundImage } from '../../processes/CMS/lib/models/DefaultStylesFontsAndColors';
import axios from 'axios';

const UNKNOWN_TEXT = '--- unknown text ---';

export function handleTranslation(value) {
  let result;
  try {
    switch (true) {
      case typeof value === 'function':
        result = value();
        break;
      case typeof value === 'string':
        result = value;
        break;
      case typeof value === 'number':
        result = value.toString();
        break;
      case value === undefined:
      case value === null:
        result = '';
        break;
      default:
        result = UNKNOWN_TEXT;
        break;
    }
  } catch {
    result = UNKNOWN_TEXT;
  }

  return result;
}

export const isString = value => typeof value === 'string';

export const isObj = value => Object.prototype.toString.call(value) === '[object Object]';

export const isLength = value => value?.length > EMPTY_LENGTH;

export const isLink = value => isString(value) && value.startsWith('http');

export const trimForm = formValues =>
  Object.fromEntries(Object.entries(formValues).map(([key, value]) => [key, isString(value) ? value.trim() : value]));

export const toggleValue = value => !value;

export const sliceFromStart = (arrOrStr, to) => arrOrStr.slice(FIRST_INDEX, to);

export const getNextIndex = index => index + 1;

export const getNextIndexByArr = arr => {
  const lastIndex = getLastIndex(arr);
  return isIndexFound(lastIndex) ? getNextIndex(lastIndex) : FIRST_INDEX;
};

export const getFirstElement = arrOrStr => arrOrStr?.[FIRST_INDEX];

export const getFirstCapitalized = str => getFirstElement(str)?.toUpperCase();

export const capitalize = str => getFirstCapitalized(str) + str.slice(1).toLowerCase();

export const getLastIndex = arrOrStr => (arrOrStr?.length ? arrOrStr.length - 1 : -1);

// FIX and REFACTOR:
export const isOdd = num => num % 2;

const isEven = num => num % 2 === 0;

export const isFixedOdd = num => !isEven(num);

export const normalizePageForRequest = page => page - 1;

export const normalizePageForUI = page => page + 1;

export const getDomainAndSubDomain = link => {
  const linkArr = new URL(link).hostname.split('.');
  return [linkArr[0], linkArr.slice(1).join('.')];
};

export const bytesToMegabytes = bytes => bytes / 1024 ** 2;

export const insertAtIndex = (target, newElements, index) => target.slice(0, index).concat(newElements, target.slice(index));

export const secToMs = ({ sec }) => sec * 1000;

export const minsToMs = ({ mins }) => secToMs({ sec: mins * 60 });

export const formatTime = time => {
  if (time && !Number.isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes || '00'}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  return '00:00';
};

export const downloadFile = fileUrl => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', `kontofinder_${new Date().toLocaleString()}`);
  document.body.append(link);
  link.click();
  link.remove();
};

export function createFormData(data) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
}

export const checkObjectWithValue = checkedValue => {
  return checkedValue && typeof checkedValue === 'object' && checkedValue.hasOwnProperty('value');
};

const identity = value => value;

export const mapObject = ({ source, keyCb = identity, valueCb = identity }) =>
  Object.fromEntries(Object.entries(source).map(([key, value]) => [keyCb(key), valueCb(value)]));

export const isFalsy = value => [null, undefined, ''].includes(value);

export const isTruthy = value => !isFalsy(value);

export const isIndexFound = index => typeof index === 'number' && index !== NO_FOUND_INDEX;

export const voidFunc = () => {};

export const checkValue = (arr, value) => {
  if (arr.some(itemObj => itemObj.id === value)) {
    return value;
  }
  return null;
};

const isObjectFilled = obj => obj && isLength(Object.keys(obj));

export function getEnv(envName, { safe = false, parse = false } = {}) {
  const value = process.env[envName];
  if (!value && !safe) throw new Error(`"${envName}" env variable is not set!`);

  try {
    if (!parse) return value;
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}

export const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

const isUiPageInvalid = page =>
  [0, undefined, null].includes(page) || // "0" here is invalid because pages in UI starts not from "0", but from "1"
  page < 0;

function getPageForRequest(newUiPage, filtering, pagination) {
  const currentUiPage = pagination.current;
  const isNewPage = isTruthy(newUiPage);
  const isFilteringRequest = isObjectFilled(filtering); // Needs to be reset because if filter is applied not on 1st page, and the `totalElements` will be less than `pageSize`, - nothing will be displayed
  const isCurrentUiPageInvalid = isUiPageInvalid(currentUiPage);

  let tempResult;
  switch (true) {
    case isNewPage:
      tempResult = newUiPage;
      break;
    case isFilteringRequest:
    case isCurrentUiPageInvalid:
      tempResult = FIRST_REQUEST_PAGE;
      break;
    default:
      // For persisting current page after ALL actions, EXCEPT filtering and pagination: activating, deactivating, deleting, sorting, etc.
      tempResult = normalizePageForRequest(currentUiPage);
      break;
  }

  if (isUiPageInvalid(tempResult)) {
    return FIRST_REQUEST_PAGE;
  }

  return tempResult;
}

export function normalizeTableRequestConfig({ params, pagination, filtering, sorting, ...rest }) {
  const { page: newPage, ...restParams } = params;
  const sort = sorting.withSorting ? formatSortQuery(sorting.sortFieldName, sorting.sortOrder) : undefined;
  const page = getPageForRequest(newPage, filtering, pagination);

  return { ...rest, params: { sort, page, ...restParams } };
}

export const getIndexByTemplateType = (items, element) => {
  return items.filter(item => item.templateType === element.templateType).findIndex(el => el.id === element.id);
};

export function safeJsonParse(data) {
  try {
    return JSON.parse(data);
  } catch {
    return undefined;
  }
}

export const checkDisableColor = (icons, value) => {
  if (checkValue(icons, value)) {
    const icon = icons.find(iconObj => iconObj.id === value);
    return icon.svgContent ? icon.svgContent.includes('fill') : true;
  }
  return true;
};

export const humanizeIndex = index => index + 1;

export const safeStr = value => (isString(value) ? value : '');

export const getBackgroundUrl = (imageObj, mediaOptions) => {
  return imageObj?.value && mediaOptions.some(media => media.id === imageObj?.value)
    ? mediaOptions.find(media => media.id === imageObj?.value).s3Url
    : defaultBackgroundImage;
};

export const getIconObject = (iconObj, iconOptions) => iconOptions.find(icon => icon.id === iconObj?.value);

export const getBackgroundImageStyle = (flag, imageUrl) => {
  return flag ? { backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url('${imageUrl}')` } : {};
};

export const isPositiveAmount = value => value > 0;

export const isEmptyPage = responseData => responseData?.empty && isPositiveAmount(responseData?.totalElements);

export const normalizeParamsOnEmptyPage = params => ({ ...params, page: params.page - 1 });

export const getArrSum = arrOfNumbers => arrOfNumbers?.reduce((acc, curr) => acc + curr, 0);

export const getShallowArrCopy = arr => [...arr];

export function normalizeNumber(value, min, max) {
  let result = Math.round(Number(value));

  if (result < min) {
    result = min;
  } else if (result > max) {
    result = max;
  }

  return result;
}

export const translateOptionsMapper = (item = {}) => ({ ...item, title: handleTranslation(item.title) });

export const getUniqueArray = arr => [...new Set(arr)];

// export const removeDuplicates = array =>
//   array?.filter((v1, i, a) => a?.findLastIndex(v2 => v2?.productNameSection.product.value === v1?.productNameSection.product.value) === i);

export const removeProductsDuplicates = array => {
  const filteredSingleCategoryProds = array.filter((prod, index, arr) => {
    if (!prod?.productNameSection?.multiProduct) {
      const lastSingleCategoryProdIndex = arr.findLastIndex(
        prodItem => prodItem?.productNameSection.categoryName === prod?.productNameSection.categoryName
      );
      return index === lastSingleCategoryProdIndex;
    }
    return true;
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return filteredSingleCategoryProds.reduce((acc, nextProd) => {
    if (nextProd?.productNameSection?.multiProduct) {
      const samePrevProd = acc.find(prodItem => prodItem?.productNameSection.product.value === nextProd?.productNameSection.product.value);
      if (samePrevProd) {
        return acc.map(prod => {
          if (prod?.id === samePrevProd?.id) {
            if (prod?.counterSection?.showCounter === false) {
              return nextProd;
            }
            if (prod?.counterSection?.value > 0) {
              if (nextProd?.counterSection?.showCounter === false) {
                return prod;
              }
              if (nextProd?.counterSection?.value > 0) {
                return {
                  ...prod,
                  counterSection: {
                    ...prod?.counterSection,
                    value: nextProd?.counterSection?.value + prod?.counterSection?.value,
                    rules: [...prod.rules, ...nextProd.rules],
                  },
                };
              }
              return prod;
            }
          }
          return prod;
        });
      }
      if (!samePrevProd) {
        return [...acc, nextProd];
      }
    }
    return [...acc, nextProd];
  }, []);
};

export const updateRulesResultProducts = (selectedProducts, rulesResultProducts) => {
  return rulesResultProducts.reduce((acc, currProd) => {
    if (selectedProducts.some(prod => prod.id === currProd.id)) {
      const counterSection = currProd?.counterSection || { value: 1 };
      const counterDefaultValue = counterSection?.showDefaultCounter ? counterSection?.defaultValue : counterSection?.min;
      const selectedCounterSection = { ...counterSection, value: counterSection?.value ?? counterDefaultValue };
      return acc.map(accProd =>
        accProd.id === currProd.id
          ? {
              ...accProd,
              counterSection: { ...accProd.counterSection, showCounter: true, value: accProd.counterSection.value + selectedCounterSection.value },
            }
          : accProd
      );
    }
    return [...acc, currProd];
  }, selectedProducts);
};

export function findMostRepeatedItem(arr, preferredValue) {
  const elementCount = {};
  // eslint-disable-next-line no-bitwise
  for (const element of arr) elementCount[element] = -~elementCount[element];

  if (elementCount[preferredValue]) {
    return preferredValue;
  }

  return Object.keys(elementCount).reduce((acc, curr) => {
    if (elementCount[acc] > elementCount[curr]) {
      return acc;
    }
    if (elementCount[acc] < elementCount[curr]) {
      return curr;
    }

    return curr === preferredValue ? curr : acc;
  }, arr[0]);
}

export function execute(callback, safe = false) {
  try {
    return callback();
  } catch (error) {
    if (safe) return undefined;

    throw error;
  }
}

export const safeExecute = callback => execute(callback, true);

export const getFontStyles = fontObj => ({
  fontFamily: fontObj?.font,
  fontSize: fontObj?.fontSize,
  fontWeight: fontObj?.fontWeight,
});

export const getTextStyles = (fontObj, colors) => ({
  ...getFontStyles(fontObj),
  color: colors?.[fontObj?.colorVariant],
});

export const arrToIndexedObj = arr => Object.fromEntries(arr.map((item, index) => [index, item]));

export const isLastIndex = (arr, index) => arr?.length - 1 === index;

export const isLastElement = arr => arr?.length === 1;

export const appParseInt = value => Number.parseInt(value, 10);

export function findIndexesMatchingCondition(arr, condition) {
  const result = [];

  for (const [index, element] of arr.entries()) {
    if (condition(element)) {
      result.push(index);
    }
  }

  return result;
}

export function handleLocalStorageValue({ value, safe = true, parse = true }) {
  if (!parse) return value;

  if (!safe) return JSON.parse(value);

  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}

export const AppLocalStorage = {
  setItem: (key, value) => {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);

    const event = new StorageEvent('storage', {
      key,
      newValue: stringified,
    });
    window.dispatchEvent(event);
  },
  removeItem: key => {
    localStorage.removeItem(key);

    const event = new StorageEvent('storage', {
      key,
      newValue: undefined,
    });
    window.dispatchEvent(event);
  },
  getItem: ({ key, safe, parse }) => {
    const value = localStorage.getItem(key);

    return handleLocalStorageValue({ value, safe, parse });
  },
};

export function implement(message = 'Implement me!') {
  throw new Error(message);
}

export async function downloadAsBase64(link) {
  const { data } = await axios.get(link, { responseType: 'blob' });
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    reader.onerror = () => {
      reject(new Error('Error reading PDF file.'));
    };

    reader.readAsDataURL(data);
  });
}

export const maybe = (condition, obj) => (condition ? obj : {});

export const pressOnlyRegEx = (event, regex = /\d/) => {
  if (!regex.test(event.key)) {
    event.preventDefault();
  }
};

export const findCommon = arrays => {
  if (arrays.length === 0) {
    return [];
  }

  const referenceArray = arrays[0];
  const commonElements = referenceArray.filter(item => {
    return arrays.every(array => array.some(elem => elem.id === item.id));
  });

  return commonElements.length > 0 ? commonElements : [];
};

export function parseNumberType(number) {
  if (Number.isInteger(number)) {
    return Number.parseInt(number, 10);
  }
  if (Number.isFinite(Number.parseFloat(number))) {
    const stringValue = String(number);
    return Number.parseFloat(stringValue.replace(',', '.'));
  }
  return Number.NaN;
}

export function formatVariableText(text) {
  if (!text) return '';

  const formattedString = text.toString();

  // eslint-disable-next-line prettier/prettier, regexp/no-unused-capturing-group, unicorn/no-unsafe-regex
    if ((/^\d+(\.\d+)?$/).test(formattedString)) {
    return formattedString.replaceAll('.', ',');
  }
  // If the text doesn't match either pattern
  return formattedString.toString();
}
