export function CheckmarkIcon({ width = '9', height = '8', fill = '#5C5C6E', ...props }) {
  return (
    <svg width={width} height={height} viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.97065 0.96735C7.1106 0.828622 7.29952 0.750547 7.49658 0.750003C7.69363 0.749459 7.88299 0.826491 8.0237 0.964445C8.16441 1.1024 8.24518 1.29019 8.24854 1.48722C8.2519 1.68425 8.17758 1.87468 8.04165 2.01735L4.04965 7.00735C3.98104 7.08125 3.89823 7.14055 3.80618 7.18172C3.71413 7.22288 3.61472 7.24506 3.5139 7.24693C3.41308 7.24879 3.31292 7.23031 3.2194 7.19258C3.12589 7.15486 3.04094 7.09866 2.96965 7.02735L0.324646 4.38135C0.250959 4.31269 0.191857 4.22989 0.150865 4.13789C0.109873 4.04589 0.087831 3.94658 0.0860542 3.84587C0.0842774 3.74517 0.102802 3.64514 0.140523 3.55175C0.178244 3.45836 0.234389 3.37353 0.305608 3.30231C0.376826 3.23109 0.46166 3.17495 0.555048 3.13723C0.648437 3.09951 0.748466 3.08098 0.849169 3.08276C0.949872 3.08454 1.04918 3.10658 1.14118 3.14757C1.23318 3.18856 1.31598 3.24766 1.38465 3.32135L3.47865 5.41435L6.95165 0.989351C6.95784 0.981603 6.96452 0.974254 6.97165 0.96735H6.97065Z'
        fill={fill}
      />
    </svg>
  );
}
