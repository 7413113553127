/* eslint-disable no-magic-numbers */
import { arithmeticTypes } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { Product } from '../../pages/FindersAndProductsPage/lib/Product';
import { parseNumberType } from 'src/lib/util/helpers';

// eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
export const changeNumberValue = (valueObject, resultOperator, ruleObject, oldAmountSection = {}) => {
  const { priceType } = ruleObject;
  const { amount, valueType } = valueObject;
  const oldValue = oldAmountSection[priceType] || oldAmountSection.priceValue || oldAmountSection.value;
  if (priceType === Product.PRICE_PRE_REQUEST) {
    return oldValue;
  }

  if (resultOperator === arithmeticTypes.equal) {
    if (valueType === 'digit') {
      return parseNumberType(amount);
    }
    if (valueType === 'percent') {
      return (parseNumberType(oldValue) * parseNumberType(amount)) / 100;
    }
  }

  if (resultOperator === arithmeticTypes.plusEquals) {
    if (valueType === 'digit') {
      return parseNumberType(oldValue) + parseNumberType(amount);
    }
    if (valueType === 'percent') {
      return (parseNumberType(oldValue) * (100 + parseNumberType(amount))) / 100;
    }
  }

  if (resultOperator === arithmeticTypes.minusEquals) {
    if (valueType === 'digit') {
      return parseNumberType(oldValue) - parseNumberType(amount);
    }
    if (valueType === 'percent') {
      return (parseNumberType(oldValue) * (100 - parseNumberType(amount))) / 100;
    }
  }
};
