import { v4 as uuidv4 } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { GripVerticalIcon } from '../../../../../../ui/icons/GripVerticalIcon';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../ui/text/AppText';
import './styles.scss';
import { AppBox } from '../../../../../../ui/boxes/AppBox';
import { DraggableFormSectionQuestion } from '../DraggableFormSectionQuestion';
import { ResultType } from '../../../../../../lib/models/Questionnaire';

export function DraggableFormListQuestion({ valueArray, onChange, label, addButtonText, additionalFields, item }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const addValue = () => {
    const updatedArr = [
      ...valueArray,
      {
        id: uuidv4(),
        value: '',
        exception: { title: '1', value: '1' },
        ...additionalFields,
      },
    ];
    onChange(updatedArr);
  };

  return (
    <>
      <div className='draggable_zone_block'>
        <Droppable
          droppableId={JSON.stringify({
            itemId: item.id,
            fieldName: 'answers',
          })}
          type={ResultType.QUESTION_ANSWERS}
        >
          {provided => (
            <div ref={provided.innerRef}>
              {valueArray.map((valueObj, index) => (
                <Draggable key={valueObj.id} draggableId={valueObj.id} index={index} isDragDisabled={isViewMode}>
                  {prov => (
                    <div ref={prov.innerRef} {...prov.draggableProps}>
                      <div className='draggable_input_wrap'>
                        <DraggableFormSectionQuestion
                          index={index}
                          valueObject={valueObj}
                          onChange={onChange}
                          valueArray={valueArray}
                          label={label}
                          item={item}
                        />
                        <AppBox disabled={isViewMode} {...prov.dragHandleProps} className='draggable_input_drag_icon'>
                          <GripVerticalIcon />
                        </AppBox>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <AppBasicButton onClick={addValue} disabled={isViewMode}>
        <AppText className='primary_color bold' textValue={addButtonText} />
      </AppBasicButton>
    </>
  );
}
