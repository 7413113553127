import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import { SelectProductFormItem } from '../../../../../../../../features/form-items/SelectProductFormItem';
import { AppCounterInput } from '../../../../../../../../../../../../ui/AppCounterInput';
import { IconButton } from '../../../../../../../../features/IconButton';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import './style.scss';

/**
 * @desc Represents a row in the product range scoring section.
 * @param {Object} prodObj - The product object containing information about the product.
 * @param {Object} rangeObject - The range object containing information about the scoring range.
 * @param {Object} selectedQuestion - The selected question.
 * @param {Boolean} disabled - Indicates whether the row should be disabled.
 * @returns {JSX.Element} The rendered product range scoring row component.
 */
// eslint-disable-next-line max-lines-per-function
export function ProductRangeScoringRow({ prodObj, rangeObject, selectedQuestion, disabled }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const handleChangeProductField = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.map(scoreRangeObj => {
                return scoreRangeObj.id === rangeObject.id
                  ? {
                      ...scoreRangeObj,
                      products: scoreRangeObj.products.map(itemObj => {
                        return itemObj.id === prodObj.id ? { ...itemObj, [fieldName]: value } : itemObj;
                      }),
                    }
                  : scoreRangeObj;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const deleteProduct = deleteId => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: {
              ...el.content,
              scoringRanges: el.content.scoringRanges.map(scoreRangeObj => {
                return scoreRangeObj.id === rangeObject.id
                  ? { ...scoreRangeObj, products: scoreRangeObj.products.filter(productObj => productObj.id !== deleteId) }
                  : scoreRangeObj;
              }),
            },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeScore = value => {
    if (Number.isInteger(Number(value))) {
      const result = questionnaireStructure.map(el => {
        return el.id === selectedQuestion.elementId
          ? {
              ...el,
              content: {
                ...el.content,
                scoringRanges: el.content.scoringRanges.map(scoreRangeObj => {
                  return scoreRangeObj.id === rangeObject.id
                    ? {
                        ...scoreRangeObj,
                        products: scoreRangeObj.products.map(itemObj => (itemObj.id === prodObj.id ? { ...itemObj, score: Number(value) } : itemObj)),
                      }
                    : scoreRangeObj;
                }),
              },
            }
          : el;
      });
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  return (
    <Space size='middle' className='range_scoring_box'>
      <SelectProductFormItem
        value={prodObj.prodId}
        rangeProductsArr={rangeObject.products}
        onChange={handleChangeProductField('prodId')}
        width={213}
        disabled={disabled}
      />
      <AppCounterInput
        style={{ width: 72 }}
        value={prodObj.score}
        onChange={value => handleChangeScore(value)}
        disabled={isViewMode}
        regEx={/[\d-]/}
      />
      <IconButton onClick={() => deleteProduct(prodObj.id)} disabled={disabled} icon={<DeleteIcon className='secondary_color' />} />
    </Space>
  );
}
