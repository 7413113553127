import { QuestionnaireVerticalGripHandle } from '../../../../../features/QuestionnaireVerticalGripHandle';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { handleTranslation, humanizeIndex } from '../../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireBasicButton } from '../../../../../features/QuestionnaireBasicButton';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import { SINGLE_ITEM_DELETING_INDEXES_AMOUNT } from '../../../../../../../../../../../lib/util/constants';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { ContactFormRowCondition } from './ContactFormRowCondition';

const justifyContentSpaceBetween = { justifyContent: 'space-between' };
const gap8Px = { gap: '8px' };
const gap16Px = { gap: '16px' };

export function ContactFormRowHeader({ providedDraggable, rowIndex, structureItemIndex, templateContent }) {
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const { updateStructure } = useUpdateStructure();

  const deleteRow = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.formRowList.splice(rowIndex, SINGLE_ITEM_DELETING_INDEXES_AMOUNT);
    });
  };

  return (
    <div className='align_items_center' style={justifyContentSpaceBetween}>
      <div className='align_items_center' style={gap8Px}>
        <QuestionnaireVerticalGripHandle dragHandleProps={providedDraggable.dragHandleProps} />
        <AppText textValue={`${handleTranslation(contentText.row)} ${humanizeIndex(rowIndex)}`} />
      </div>

      <div className='flex flex-y-c' style={gap16Px}>
        <ContactFormRowCondition rowIndex={rowIndex} structureItemIndex={structureItemIndex} templateContent={templateContent} />
        <QuestionnaireBasicButton onClick={deleteRow}>
          <DeleteIcon />
        </QuestionnaireBasicButton>
      </div>
    </div>
  );
}
