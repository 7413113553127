import { createChartConfig, getBestProduct, filterProdsWithPriceAndName } from '../../../../../utils/question-products-chart-helpers';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { G2, Line } from '@ant-design/plots';
import styles from './styles.module.scss';

const lineColors = ['#1979C9', '#D62A0D', '#FAA219', '#48A357'];

// eslint-disable-next-line max-lines-per-function
export function Chart({ productValues, pagesFlow, chartAnswers, xAxis, yAxis, fonts, colors }) {
  const { applyVariable } = useVariables(pagesFlow);

  const bestProd = getBestProduct(productValues, chartAnswers);

  G2.registerShape('point', 'breath-point', {
    // eslint-disable-next-line max-lines-per-function
    draw(cfg, container) {
      const { data, x, y, color } = cfg;
      const point = {
        x,
        y,
      };
      const group = container.addGroup();

      if (data.amount === bestProd?.amount && data.productName === bestProd?.productName) {
        const decorator1 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 15,
            fill: color,
            opacity: 0.5,
          },
        });
        const decorator2 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 15,
            fill: color,
            opacity: 0.5,
          },
        });
        const decorator3 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 15,
            fill: color,
            opacity: 0.5,
          },
        });
        decorator1.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
          }
        );
        decorator2.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 600,
          }
        );
        decorator3.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 1200,
          }
        );
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 6,
            fill: color,
            opacity: 0.7,
          },
        });
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 1.5,
            fill: color,
          },
        });
      }

      return group;
    },
  });
  const sortedProducts = filterProdsWithPriceAndName(productValues)?.sort((cur, next) => cur.price - next.price);

  const config = createChartConfig({
    initialData: sortedProducts,
    xAxis: {
      ...xAxis,
      value: applyVariable(xAxis.value),
    },
    yAxis: {
      ...yAxis,
      value: applyVariable(yAxis.value),
    },
    subText: fonts.subText,
    color: colors[fonts.subText.colorVariant],
    lineColors,
  });

  return (
    <div>
      <Line {...config} />
      <div className={styles.chart_products}>
        {[...sortedProducts].map((prod, index) => {
          return (
            <div key={prod.id} className={styles.chart_products_item}>
              <span style={{ display: 'block', marginRight: 5, height: 3, width: 20, backgroundColor: lineColors[index] }} />
              <span
                style={{
                  fontSize: fonts?.subText?.fontSize,
                  fontFamily: fonts?.subText?.font,
                  fontWeight: 400,
                  color: colors[fonts?.subText?.colorVariant],
                }}
              >
                {prod.productName}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
