import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBox } from '../../../../../../../../ui/boxes/AppBox';
import { FormCard } from '../../../../features/FormCard';
import { ColorsCard } from '../../../ThemePage/components/ThemeForm/components/ColorsCard';
import { FontsCard } from '../../../ThemePage/components/ThemeForm/components/FontsCard';
import { MediaCard } from './MediaCard';
import { BulletPointsCard } from './BulletPointsCard';
import { HeaderCard } from './HeaderCard';
import { FooterCard } from './FooterCard';
import { AppTitle } from '../../../../../../../../ui/text/AppTitle';
import { ThemeSelectFormItem } from '../../../../features/form-items/ThemeSelectFormItem';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { loadFonts } from '../../../ThemePage/lib/util/loadFonts';
import { getFontsRequest } from '../../../../../../../../lib/store/slices/themesSlice';
import { FaviconTitleCard } from './FaviconTitleCard';

export function DesignSettingsForm({ form, designSettings, setDesignSettings }) {
  const dispatch = useDispatch();
  const { settings } = useLocalization().translations.unique.pages;
  const { fonts } = useSelector(({ themes }) => themes);

  useEffect(() => {
    loadFonts(fonts);
  }, [fonts]);

  useEffect(() => {
    dispatch(getFontsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBox className='space_between' style={{ marginBottom: 12 }}>
        <AppTitle level={3} textValue={settings.design.title} />
        <ThemeSelectFormItem form={form} designSettings={designSettings} setDesignSettings={setDesignSettings} />
      </AppBox>
      <ColorsCard disabled themeConfiguration={designSettings.themeConfig} />
      <FormCard>
        <FontsCard disabled themeConfiguration={designSettings.themeConfig} />
      </FormCard>
      <MediaCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <BulletPointsCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <HeaderCard form={form} designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <FooterCard form={form} designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <FaviconTitleCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
    </>
  );
}
