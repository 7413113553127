import { useLayoutDateColumn } from '../../components/useLayoutDateColumn';
import { Layout } from '../../lib/Layout';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

export function useLayoutCreationDateColumn() {
  const { creationDate: title } = useLocalization().translations.shared.columns;

  return useLayoutDateColumn({
    arrangedId: Layout.CREATION_DATE,
    title,
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
  });
}
