import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { CategoryNameFormItem } from '../../../../../../../../../../../features/CategoryNameFormItem';
import { trimForm } from '../../../../../../../../../../../lib/util/helpers';
import { editCategoryRequest } from '../../../../../../../../../../../lib/store/slices/categoriesSlice';
import { CategorySelectionFormSection } from '../../features/CategorySelectionFormSection';
import { Category, CategorySelection } from '../../../CategoriesTable/lib/Category';

export function EditCategoryForm({ form, categoryData, tenantId }) {
  const dispatch = useDispatch();

  const onFinish = values => {
    const trimmedForm = trimForm(values);
    dispatch(editCategoryRequest({ categoryForm: { ...categoryData, ...trimmedForm }, tenantId }));
  };

  const initialValues = {
    ...categoryData,
    // Enable multiple selection by default if it was unset
    [Category.IS_MULTI_PRODUCT]: categoryData[Category.IS_MULTI_PRODUCT] ?? CategorySelection.MULTIPLE_SELECTION,
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish} style={{ marginTop: 32 }}>
      <CategoryNameFormItem />
      <CategorySelectionFormSection />
    </Form>
  );
}
