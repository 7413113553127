import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function FontWeightFormItem({ value, onChange, fieldName, field, disabled, placeholder }) {
  const fontsOptions = [
    { value: 700, title: 'Bold' },
    { value: 400, title: 'Regular' },
  ];

  const fontsWhenDisabled = [{ value: undefined, title: 'No options' }];

  return (
    <AppFormSection
      formItemProps={{
        style: { width: 123 },
      }}
    >
      <AppNewSelect
        disabled={disabled}
        value={{ value }}
        smallWidth
        placeholder={placeholder}
        onChange={selected => onChange(selected?.value, fieldName, field)}
        options={disabled && value === undefined ? fontsWhenDisabled : fontsOptions}
      />
    </AppFormSection>
  );
}
