import { publicAxiosInstance } from '../../../../../../../../../lib/util/http/axios/axios-instance';
import { ContactFormField } from '../../../../../../../../../lib/models/Questionnaire';
import { appLogger } from '../../../../../../../../../lib/util/logger';
import dayjs from 'dayjs';
import { getDynamicFormLetter } from './getDynamicFormLetter';
import { isLength } from '../../../../../../../../../lib/util/helpers';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function onSubmitDynamicForm({
  emailBody,
  formValues,
  formRowList,
  subject,
  applyVariable,
  applyVariablesToObject,
  bankName,
  setPagesFlow,
  logo,
  finderUrl,
  pdfBlob,
  emailHeaderText,
}) {
  try {
    setPagesFlow(prev => ({ ...prev, isDynamicFormSubmitting: true }));
    const allFormFields = formRowList.flatMap(row => row.fieldList);
    const formValuesArr = Object.entries(formValues).map(formVal => {
      const fieldObject = allFormFields.find(fieldItem => fieldItem.id === formVal[0]);
      return { name: fieldObject.name, value: formVal[1], type: fieldObject.type };
    });
    const formattedValues = formValuesArr.map(fieldItem => {
      if ([ContactFormField.DROPDOWN, ContactFormField.DROPDOWN_WITH_ACTION].includes(fieldItem.type)) {
        return { ...fieldItem, value: fieldItem.value === undefined ? '-' : fieldItem.value.title };
      }
      if (ContactFormField.CHECKBOX === fieldItem.type) {
        return { ...fieldItem, value: isLength(fieldItem.value) ? fieldItem.value.join(', ') : '-' };
      }
      if (ContactFormField.DATE === fieldItem.type) {
        return { ...fieldItem, value: fieldItem.value ? dayjs(fieldItem?.value).format('DD.MM.YYYY') : '-' };
      }
      if (ContactFormField.TIME === fieldItem.type) {
        return { ...fieldItem, value: fieldItem.value ? dayjs(fieldItem?.value).format('HH:mm') : '-' };
      }
      return { ...fieldItem, value: fieldItem.value || '-' };
    });
    const formattedValuesToHtml = formattedValues
      .map(formValue => applyVariablesToObject(formValue))
      .map(fieldItem => `<p style="font-size:16px;font-weight:400;line-height:24px;letter-spacing:0.2px;">${fieldItem.name}: ${fieldItem.value}</p>`)
      .join('');

    const updatedLetterString = getDynamicFormLetter({ emailBody: applyVariable(emailBody), bankName, emailHeaderText, formattedValuesToHtml, logo });

    const formData = new FormData();
    if (pdfBlob) {
      formData.append('pdfBlob', pdfBlob);
    }
    formData.append('mailBody', updatedLetterString);
    formData.append('finderUrl', encodeURIComponent(finderUrl));
    formData.append('subject', applyVariable(subject));

    await publicAxiosInstance.post('global/public/save-dynamic-contact', formData);

    setPagesFlow(prev => {
      if (prev?.afterContactFormLink) {
        window.open(prev?.afterContactFormLink?.link, prev?.afterContactFormLink?.openInNewWindow ? '_blank' : '_self');
      }
      return { ...prev, currentFlowElement: { ...prev.currentFlowElement, submitted: true }, afterContactFormLink: null, showSuccessScreen: true };
    });
  } catch (error) {
    appLogger.error(error);
    // eslint-disable-next-line i18n-text/no-en,no-alert
    alert(error || 'Something went wrong. Please contact us.');
  } finally {
    setPagesFlow(prev => ({ ...prev, isDynamicFormSubmitting: false }));
  }
}
