import { AppImage } from '../../../../../../ui/AppImage';
import './styles.scss';

export function CardImageCell({ imageUrl = '' }) {
  return (
    <div className='card_cell'>
      <AppImage className='card_cell_image' src={imageUrl} alt='card product' />
    </div>
  );
}
