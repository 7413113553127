import { types, comparisonTypes, arithmeticTypes, logicalTypes, elementSubTypes, resultSubTypes } from './actionConstants';

export const elementTypes = {
  valueType: {
    type: types.value,
    value: {
      amount: 0,
      valueType: 'digit',
    },
  },
  variable: {
    type: types.variable,
    value: null,
  },
  logical: {
    type: types.logicalOperator,
  },
  arithmetic: {
    type: types.arithmeticOperator,
  },
  comparison: {
    type: types.comparisonOperator,
  },
  element: {
    type: types.element,
    value: {
      elementType: null,
      element: null,
      property: null,
    },
  },
  resultType: {
    type: types.resultType,
    value: {
      elementType: null,
      element: null,
      property: null,
      composerElement: null,
    },
  },
};

export const elementOptions = Object.values(elementSubTypes);
export const resultOptions = Object.values(resultSubTypes);

export const comparisonOptions = Object.values(comparisonTypes);
export const arithmeticOptions = Object.values(arithmeticTypes);
export const logicalOptions = Object.values(logicalTypes);

export const actionTypes = [
  {
    name: types.element,
    disabled: false,
    reason: '',
    element: elementTypes.element,
  },
  {
    name: types.value,
    disabled: false,
    reason: '',
    element: elementTypes.valueType,
  },
  {
    name: types.resultType,
    disabled: false,
    reason: '',
    element: elementTypes.resultType,
  },
  {
    name: types.variable,
    disabled: false,
    reason: '',
    element: elementTypes.variable,
  },
  {
    name: types.logicalOperator,
    disabled: false,
    reason: '',
    children: logicalOptions.map(option => ({
      name: option,
      disabled: false,
      reason: '',
      element: {
        type: types.logicalOperator,
        value: option,
      },
    })),
  },
  {
    name: types.comparisonOperator,
    disabled: false,
    reason: '',
    tooltip: 'comparison',
    children: comparisonOptions.map(option => ({
      name: option,
      disabled: false,
      reason: '',
      element: {
        type: types.comparisonOperator,
        value: option,
      },
    })),
  },
  {
    name: types.arithmeticOperator,
    disabled: false,
    reason: '',
    tooltip: 'arithmetic',
    children: arithmeticOptions.map(option => ({
      name: option,
      disabled: false,
      reason: '',
      element: {
        type: types.arithmeticOperator,
        value: option,
      },
    })),
  },
];
