import { AppBasicButton } from 'src/ui/buttons/AppBasicButton';
import { AppText } from 'src/ui/text/AppText';
import { DetailTable } from './DetailTable';

import { useLocalization } from 'src/lib/hooks/useLocalization';

import './detailTable.scss';

export function DetailTables({
  detailTables,
  structureItemIndex,
  addComparisonDetailTable,
  deleteComparisonDetailTable,
  updateComparisonDetailTable,
  addTopic,
  deleteTopic,
  updateTopic,
  changeTopicOrder,
}) {
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;

  return (
    <>
      {detailTables.map((detailTable, index) => (
        <DetailTable
          tableIndex={index}
          key={detailTable.id}
          structureItemIndex={structureItemIndex}
          detailTable={detailTable}
          deleteComparisonDetailTable={deleteComparisonDetailTable}
          updateComparisonDetailTable={updateComparisonDetailTable}
          addTopic={addTopic}
          deleteTopic={deleteTopic}
          updateTopic={updateTopic}
          changeTopicOrder={changeTopicOrder}
        />
      ))}

      <AppBasicButton onClick={addComparisonDetailTable} style={{ margin: '32px 0' }}>
        <AppText className='primary_color bold' textValue={comparisonDetailTable.addComparisonDetailTable} />
      </AppBasicButton>
    </>
  );
}
