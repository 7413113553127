import { productResultTypeActions } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { updateChapterResultProducts, updateChapterRulesResultProducts } from '../end-user-flow-helpers';

export const updateProductResult = (prodResultsBlocks, chapters, pages, unselectProdsWithChapters = []) => {
  const allProds = pages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT).map(el => el.content.products)).flat();

  const chaptersAfterProductHide = chapters.map(chapter => {
    const deleteProdsIds = [...unselectProdsWithChapters].filter(prod => prod.chapterId === chapter.id).map(prod => prod.id);
    return {
      ...chapter,
      resultSelectedProducts: updateChapterResultProducts(chapter.resultSelectedProducts, [], deleteProdsIds),
    };
  });

  return prodResultsBlocks.reduce(
    (accumulator, block) => {
      if (productResultTypeActions.addedToResult === block.resultProduct.action) {
        const rulesChapters = accumulator.rulesChapters.map(chapter => {
          const addProds = block.conditionValue
            ? findProductAndChapter(block.resultProduct.elements, chapters, allProds, true)
                .filter(prod => prod.chapterId === chapter.id)
                .map(prod => prod.prodObject)
            : [];

          const deleteProdsIds = findProductAndChapter(block.resultProduct.elements, chapters, allProds, false)
            .filter(prod => prod.chapterId === chapter.id)
            .map(prod => prod.prodObject.id);

          return {
            ...chapter,
            rulesResultSelectedProducts: updateChapterRulesResultProducts(chapter.rulesResultSelectedProducts, addProds, deleteProdsIds),
          };
        });

        return { rulesChapters };
      }

      return accumulator;
    },
    { rulesChapters: chaptersAfterProductHide }
  );
};

export const updateProductSelection = (prodSelectionBlocks, chapters, pages) => {
  const allProds = pages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT).map(el => el.content.products)).flat();

  return prodSelectionBlocks.reduce(
    (accumulator, block) => {
      if (productResultTypeActions.preselected === block.selectProduct.action) {
        const rulesChapters = accumulator.rulesChapters.map(chapter => {
          const addProds = findProductAndChapter(block.selectProduct.elements, chapters, allProds, true)
            .filter(prod => prod.chapterId === chapter.id)
            .map(prod => {
              if (prod?.prodObject?.counterSection) {
                return {
                  ...prod.prodObject,
                  counterSection: {
                    ...prod.prodObject.counterSection,
                    value: prod.prodObject.counterSection.value === 0 ? 1 : prod?.prodObject.counterSection.value,
                  },
                };
              }
              return prod.prodObject;
            });

          const deleteProdsIds = [];

          return {
            ...chapter,
            resultSelectedProducts: updateChapterResultProducts(chapter.resultSelectedProducts, addProds, deleteProdsIds),
          };
        });

        const rulesSelectedProducts = getUpdatedSelectedProducts(
          accumulator.rulesSelectedProducts,
          block.selectProduct.elements,
          pages,
          allProds,
          true
        );
        return { rulesChapters, rulesSelectedProducts };
      }

      if (productResultTypeActions.unselect === block.selectProduct.action) {
        const rulesChapters = accumulator.rulesChapters.map(chapter => {
          const addProds = [];
          const deleteProdsIds = findProductAndChapter(block.selectProduct.elements, chapters, allProds, false)
            .filter(prod => prod.chapterId === chapter.id)
            .map(prod => prod.prodObject.id);

          return {
            ...chapter,
            resultSelectedProducts: updateChapterResultProducts(chapter.resultSelectedProducts, addProds, deleteProdsIds),
          };
        });

        const rulesSelectedProducts = getUpdatedSelectedProducts(
          accumulator.rulesSelectedProducts,
          block.selectProduct.elements,
          pages,
          allProds,
          false
        );
        return { rulesChapters, rulesSelectedProducts };
      }

      return accumulator;
    },
    { rulesChapters: chapters, rulesSelectedProducts: [] }
  );
};

const getUpdatedSelectedProducts = (rulesSelectedProducts, blockProds, pages, allProds, selected) => {
  const newUpdatedProducts = blockProds.map(prod => {
    const prodObject = allProds.find(prodItem => prod.productId === prodItem.id);
    const pageId = pages.find(page => page.elements.find(element => element.id === prod.productTemplateId))?.id;
    const counterSection = prodObject?.counterSection || { value: 1 };
    const counterDefaultValue = counterSection?.showDefaultCounter ? counterSection?.defaultValue : counterSection?.min;
    const selectedCounterSection = { ...counterSection, value: counterDefaultValue ?? 1 };
    const unselectedCounterSection = { ...counterSection, value: 0 };

    return {
      ...prodObject,
      selected,
      productTemplateId: prod.productTemplateId,
      pageId,
      counterSection: selected ? selectedCounterSection : unselectedCounterSection,
    };
  });
  const newUProductsIds = new Set(newUpdatedProducts.map(prod => prod.id));
  const oldProducts = rulesSelectedProducts.filter(prod => !newUProductsIds.has(prod.id));

  return [...oldProducts, ...newUpdatedProducts];
};

export const findProductAndChapter = (products, chapters, allProds, selected) => {
  const chaptersProds = chapters.map(chap => {
    return {
      ...chap,
      prodElementsIds: chap.chapterPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT).map(el => el.id)),
    };
  });

  return products.map(prod => {
    const chapter = chaptersProds.find(chap => chap.prodElementsIds.includes(prod.productTemplateId));
    const prodObject = allProds.find(prodItem => prod.productId === prodItem.id);
    return {
      id: prod.id,
      chapterId: chapter?.id,
      title: prod.title,
      prodObject: { ...prodObject, selected, ruleSelected: true, productTemplateId: prod.productTemplateId },
    };
  });
};
