import { createSlice } from '@reduxjs/toolkit';
import { AppLocalStorage } from '../../util/helpers';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../util/constants';

const initialState = {
  user: {
    fullName: '',
    email: '',
  },
  role: '',
  username: '',
  tenantId: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: state => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }) => {
      AppLocalStorage.setItem(ACCESS_TOKEN_NAME, payload.accessToken);
      AppLocalStorage.setItem(REFRESH_TOKEN_NAME, payload.refreshToken);
      state.role = payload.role;
      state.username = payload.username;
      state.tenantId = payload.tenantId;
      state.loading = false;
    },
    loginError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    logoutSuccess: () => {
      return initialState;
    },
    getUserDataRequest: state => {
      state.loading = true;
    },
    getUserDataSuccess: (state, { payload }) => {
      state.user = payload;
      state.loading = false;

      if (payload.authority === 'ROLE_POWER_USER' || payload.authority === 'ROLE_USER') {
        state.tenantId = payload.tenants[0].id;
      }
    },
    getUserDataError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { loginRequest, loginSuccess, logoutSuccess, loginError, getUserDataRequest, getUserDataSuccess, getUserDataError } = authSlice.actions;
export const auth = authSlice.reducer;
