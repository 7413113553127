import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { ProgressBarType } from '../../utils/consts';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../lib/util/helpers';

export function ProgressBarVariantFormItem({ value, onChange }) {
  const { design } = useLocalization().translations.unique.pages.settings;

  const options = [
    { value: ProgressBarType.ORIGINAL, title: handleTranslation(design.progressBar.variantOriginal) },
    { value: ProgressBarType.NEW, title: handleTranslation(design.progressBar.variantNew) },
  ];

  return (
    <AppFormSection
      formItemProps={{
        style: { minHeight: 40, minWidth: 226 },
      }}
    >
      <AppNewSelect onChange={selected => onChange(selected?.value)} options={options} value={{ value }} />
    </AppFormSection>
  );
}
