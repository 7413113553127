import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getDomainsError,
  getDomainsRequest,
  getDomainsSuccess,
  getEndUserFinderError,
  getEndUserFinderRequest,
  getEndUserFinderSuccess,
} from '../slices/endUserFinderSlice';
import { publicAxiosInstance } from '../../util/http/axios/axios-instance';

function* getEndUserFinder({ payload: { params } }) {
  try {
    const { data } = yield call(publicAxiosInstance.get, '/global/public', { params });
    yield data?.name
      ? put(getEndUserFinderSuccess({ finder: data, questionnaireStructure: JSON.parse(data.questionnaire) }))
      : put(getEndUserFinderError(true));
  } catch (error) {
    yield put(getEndUserFinderError(error));
  }
}

function* getDomains() {
  try {
    const res = yield call(publicAxiosInstance.get, '/global/public/domains');
    yield put(getDomainsSuccess(res.data));
  } catch (error) {
    yield put(getDomainsError({ error }));
  }
}

export function* watchEndUserFinderManagement() {
  yield all([takeLatest(getEndUserFinderRequest.type, getEndUserFinder)]);
  yield all([takeLatest(getDomainsRequest.type, getDomains)]);
}
