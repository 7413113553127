import { AppText } from '../../../../../../ui/text/AppText';
import { BulletIconComponent } from '../BulletIconComponent';
import './styles.scss';
import classNames from 'classnames';

export function BulletIconTag({ icon, color, size }) {
  return (
    <div className='bullet_select_icon_wrap'>
      <div className={classNames('bullet_select_icon_square', { small: size === 'small' })}>
        <BulletIconComponent icon={icon} color={color} />
      </div>
      <AppText ellipsis className='bullet_select_icon_name'>
        {icon.originalFileName}
      </AppText>
    </div>
  );
}
