import { useDispatch, useSelector } from 'react-redux';
import { DeleteRuleModal } from './DeleteRuleModal';
import { DuplicateRuleModal } from './DuplicateRuleModal';
import { RuleModal } from '../../../../../lib/models/Modal';
import { closeRuleModal } from '../../../../../../../lib/store/slices/rulesSlice';

/**
 * @desc Component for managing rule modals such as delete and duplicate modals.
 * @returns {JSX.Element} The JSX for the RuleModals component.
 */
export function RuleModals() {
  const { isModalOpen, modalType: currentModalType, selectedRule, isModalLoading } = useSelector(({ rules }) => rules);
  const dispatch = useDispatch();

  const close = () => dispatch(closeRuleModal());
  const checkIsOpen = modalType => isModalOpen && modalType === currentModalType;

  const genericProps = { ruleData: selectedRule, close, isModalLoading };

  return (
    <>
      <DeleteRuleModal {...genericProps} isOpen={checkIsOpen(RuleModal.DELETE)} />
      <DuplicateRuleModal {...genericProps} isOpen={checkIsOpen(RuleModal.DUPLICATE)} />
    </>
  );
}
