import styles from './styles.module.scss';
import { FlowButton } from '../FlowButton/FlowButton';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../lib/util/helpers';

const ResultText = 'Ergebnis';

export function ResultButton({ setPagesFlow, pagesFlow, colors, fonts }) {
  const { allPages, currentPageId } = pagesFlow;

  const resultPage = allPages.find(page => page.elements.find(el => el.templateType === TemplateTypes.RESULT));

  const nextOnClick = () => {
    setPagesFlow(prev => ({
      ...prev,
      currentChapterId: null,
      currentPageId: resultPage?.id,
      prevPageIds: [currentPageId],
      currentFlowElement: isLength(resultPage?.elements) ? resultPage.elements[0] : null,
      currentElementsIds: isLength(resultPage?.elements) ? [resultPage.elements[0].id] : [],
      completed: true,
    }));
  };

  return (
    <section className={`${styles.wrap} ${styles.chapters_button_wrap}`}>
      <FlowButton
        style={{
          backgroundColor: colors.primaryColor,
          marginLeft: 'auto',
          fontWeight: 700,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
        }}
        onClick={nextOnClick}
        analyticsClass='analytics-button'
      >
        {ResultText}
      </FlowButton>
    </section>
  );
}
