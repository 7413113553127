import { QuestionnaireSelect } from '../../../../../features/QuestionnaireSelect';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { FlowOperation } from '../../../../../../../../../../../lib/models/Questionnaire';
import { translateOptionsMapper } from '../../../../../../../../../../../lib/util/helpers';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { useSelector } from 'react-redux';

/**
 * @desc Flow Button Operation Select
 * @param {Object} selectProps
 * @param {String} structureItemId
 * @param {Number} buttonIndex
 * @param {String} buttonsField
 * @param {String} buttonName
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowButtonOperationSelect({ selectProps = {}, structureItemId, buttonIndex, buttonsField, buttonName, ...rest }) {
  const { options, ...restSelectProps } = selectProps;
  const { formItemProps: { style: formItemStyle, ...restFormItemProps } = {}, style = {}, ...restProps } = rest;
  const { updateStructure } = useUpdateStructure();
  const { link, goTo } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  const {
    finder: {
      generalSettings: { hvmIntegration },
    },
  } = useSelector(({ finders }) => finders);

  const { enabled } = hvmIntegration || {};

  const baseOptions = [
    { value: FlowOperation.LINK, title: link },
    { value: FlowOperation.GO_TO_ELEMENT, title: goTo },
  ];

  if (enabled) {
    baseOptions.push({ value: FlowOperation.HVM, title: 'Transfer list to HVM' });
  }

  const finalOptions = baseOptions.concat(options).map(translateOptionsMapper);

  const onChange = value => {
    updateStructure(draft => {
      const structureItem = draft?.find(iterated => iterated.id === structureItemId);
      if (structureItem && buttonsField && !buttonName) {
        structureItem.content[buttonsField][buttonIndex].flow.operation = value;
      }
      if (structureItem && buttonName) {
        structureItem.content[buttonName].flow.operation = value;
      }
    });
  };

  return (
    <QuestionnaireSelect
      style={{ ...style, marginBottom: '12px' }}
      formItemProps={{
        style: { ...formItemStyle, minHeight: 'unset' },
        ...restFormItemProps,
      }}
      selectProps={{
        options: finalOptions,
        onChange: selected => onChange(selected?.value),
        ...restSelectProps,
      }}
      {...restProps}
    />
  );
}
