import { Checkbox } from 'antd';
import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

const Root = styled(AppBox)({
  '.ant-checkbox-group': {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      marginInlineStart: '0px',
    },

    '.ant-checkbox+span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

export function AppCheckboxGroup({ rootProps, groupProps }) {
  return (
    <Root {...rootProps}>
      <Checkbox.Group {...groupProps} />
    </Root>
  );
}
