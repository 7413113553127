import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ResultType, withOptionsContactFormFields } from '../../../../../../../../../../../lib/models/Questionnaire';
import classNames from 'classnames';
import { ContactFormRowFieldTop } from './ContactFormRowFieldTop';
import { ContactFormRowFieldOptionList } from './ContactFormRowFieldOptionList';
import { AddContactFormOptionButton } from './AddContactFormOptionButton';
import { isLength } from '../../../../../../../../../../../lib/util/helpers';

export function ContactFormRowField(props) {
  const { rowIndex, fieldIndex, structureItemIndex, templateContent, id } = props;
  const field = templateContent?.formRowList[rowIndex]?.fieldList?.[fieldIndex];

  return (
    <Draggable
      draggableId={JSON.stringify({
        type: ResultType.CONTACT_FORM_ROW_FIELD,
        rowIndex,
        fieldIndex,
        structureItemIndex,
        id,
      })}
      index={fieldIndex}
    >
      {providedDraggable => (
        <li
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          style={{ ...providedDraggable.draggableProps.style, alignItems: 'flex-start' }}
          className={classNames(providedDraggable.draggableProps.className, 'flex flex-col')}
        >
          <ContactFormRowFieldTop {...props} providedDraggable={providedDraggable} />

          {withOptionsContactFormFields.includes(field?.type) && (
            <>
              {isLength(field.optionList) && <ContactFormRowFieldOptionList {...props} field={field} />}
              <AddContactFormOptionButton
                fieldOptions={field?.optionList}
                structureItemIndex={structureItemIndex}
                rowIndex={rowIndex}
                fieldIndex={fieldIndex}
                fieldType={field?.type}
              />
            </>
          )}
        </li>
      )}
    </Draggable>
  );
}
