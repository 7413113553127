import { ColorSelect } from './ColorSelect';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { DefaultTextColor } from '../comparison-table-utils';
import { useContentTable } from '../hooks/useContentTable';

export function TextColorSelect({ textColorList, rowIndex, columnIndex }) {
  const { contentTable } = useLocalization().translations.unique.pages.questionnaire;
  const { contentTableState } = useContentTable();
  const currentSelected = contentTableState.tableRows[rowIndex].cells[columnIndex];
  const value = {
    label: currentSelected.label || handleTranslation(contentTable.colorLabels.black),
    value: currentSelected.color || DefaultTextColor.BLACK,
  };

  const onChange = newSelected => {
    contentTableState.updateTable(({ tableRows }) => {
      const column = tableRows[rowIndex].cells[columnIndex];

      column.color = newSelected.value;
      column.label = newSelected.label;
    });
  };

  return <ColorSelect options={textColorList} value={value} label={contentTable.textColor} onChange={onChange} />;
}
