/**
 * Custom hook for managing condition result state and handling changes.
 * @param {Object} value - The current value of the condition result.
 * @param {Function} onChange - Function to be called when the condition result changes.
 * @returns {Object} - An object containing functions to handle different types of changes.
 */

export const useConditionResult = ({ value, onChange }) => {
  const handleElementTypeChange = val => {
    onChange({
      elementType: val,
      elements: null,
      property: null,
    });
  };

  const handleElementsChange = val => {
    onChange({
      ...value,
      elements: val,
      property: null,
    });
  };

  const handlePropertyChange = val => {
    onChange({
      elementType: value.elementType,
      elements: value.elements,
      property: val,
    });
  };

  const handleValueChange = (prop, val) => {
    onChange({
      ...value,
      [prop]: val,
    });
  };

  const handleComposerItemChange = val => {
    onChange({
      ...value,
      product: val,
    });
  };

  return { handleElementTypeChange, handleElementsChange, handlePropertyChange, handleValueChange, handleComposerItemChange };
};
