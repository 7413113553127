import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT, generateEmptyRow, getCellsAmount, getRowsAmount } from '../comparison-table-utils';
import { QuestionnaireTextButton } from '../../../../features/QuestionnaireTextButton';
import { useContentTable } from '../hooks/useContentTable';

export function AddRow() {
  const { contentTableState } = useContentTable();
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;
  const disabled = getRowsAmount(contentTableState.tableRows) >= COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT;

  const addRow = () => {
    contentTableState.updateTable(({ tableRows }) => {
      const cellsAmount = getCellsAmount(tableRows);
      tableRows.push(generateEmptyRow(cellsAmount || 1));
    });
  };

  return <QuestionnaireTextButton onClick={addRow} disabled={disabled} textValue={contentTableText.addRow} />;
}
