import classes from '../index.module.scss';
import { AppText } from '../../../../../../../ui/text/AppText';
import { getTextStyles } from '../../../../../../../lib/util/helpers';
import { AppImage } from '../../../../../../../ui/AppImage';
import { InfoIconComponent } from '../../../pages/PreviewPage/components/InfoIconComponent';

// Only in this row index product name can be displayed
const ALLOWED_ROW_INDEX = 0;

export function Product({ cell, body, colors, rowIndex }) {
  return (
    <div className={classes.product}>
      {rowIndex === ALLOWED_ROW_INDEX && cell?.productName && <AppText strong textValue={cell?.productName} style={getTextStyles(body, colors)} />}

      <div style={{ display: 'flex' }}>
        <div className={classes.productImageWrapper}>
          <AppImage src={cell?.image} alt='Product' />
        </div>

        {cell?.infoIcon && <InfoIconComponent type={cell} />}
      </div>
    </div>
  );
}
