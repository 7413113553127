import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { AppCheckbox } from '../../../../../../../../../../../../../ui/AppCheckbox';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { AppSegmented } from '../../../../../../../../../../../../../ui/AppSegmented';
import { handleTranslation } from '../../../../../../../../../../../../../lib/util/helpers';
import { ResultPricePeriod, ResultPriceType } from '../../../../../../../../../utils/consts';
import { TotalPrice } from './TotalPrice';

const ShortProductName = {
  DEFAULT: false,
  CUSTOM: true,
};

const DISABLED_OPACITY = 0.5;

const appCheckBoxStyle = { marginBottom: 10 };
const appFirstCheckBoxStyle = { minWidth: 115 };

// eslint-disable-next-line max-lines-per-function
export function ProductInformation({ item, isViewMode, onChange }) {
  const { productInfo } = useLocalization().translations.unique.pages.result;
  const {
    fullName,
    shortName,
    resultPriceType: { pricePerProduct, pricePerPiece, noPrice, pricePerYearAndMonth, pricePerYear, pricePerMonth },
  } = useLocalization().translations.unique.pages.questionnaire.content;

  const {
    content: { productInformation },
    id,
  } = item;

  const handleChangeProductInformation = (event, fieldName) => {
    onChange({ ...productInformation, [fieldName]: event.target.checked });
  };

  const nameOptions = [
    { value: ShortProductName.DEFAULT, label: fullName },
    { value: ShortProductName.CUSTOM, label: shortName },
  ].map(el => ({ ...el, label: handleTranslation(el.label) }));

  const priceOptions = [
    { value: ResultPriceType.PRICE_PER_PRODUCT, label: pricePerProduct },
    { value: ResultPriceType.PRICE_PER_PIECE, label: pricePerPiece },
    { value: ResultPriceType.NO_PRICE, label: noPrice },
  ].map(el => ({ ...el, label: handleTranslation(el.label) }));

  const pricePeriodOptions = [
    { value: ResultPricePeriod.PRICE_PER_YEAR_AND_MONTH, label: pricePerYearAndMonth },
    { value: ResultPricePeriod.PRICE_PER_YEAR, label: pricePerYear },
    { value: ResultPricePeriod.PRICE_PER_MONTH, label: pricePerMonth },
  ].map(el => ({ ...el, label: handleTranslation(el.label) }));

  const handleChangeTypeName = (value, fieldName) => {
    onChange({ ...productInformation, [fieldName]: !value });
  };

  const handleChangeTypePrice = (value, fieldName) => {
    onChange({ ...productInformation, [fieldName]: value });
  };

  const handleChangePeriodPrice = (value, fieldName) => {
    onChange({ ...productInformation, [fieldName]: value });
  };

  return (
    <div className='template_card_content_block'>
      <AppText className='template_card_headline' textValue={productInfo.title} />
      <StyledFirstBlock>
        <AppCheckbox
          style={{ ...appCheckBoxStyle, ...appFirstCheckBoxStyle }}
          onChange={event => handleChangeProductInformation(event, 'picture')}
          checked={productInformation?.picture}
          textValue={productInfo.picture}
          disabled={isViewMode}
        />
        <AppCheckbox
          style={appCheckBoxStyle}
          onChange={event => handleChangeProductInformation(event, 'externalProductPageLink')}
          checked={productInformation?.externalProductPageLink}
          textValue={productInfo.externalProductPageLink}
          disabled={isViewMode}
        />
        <AppCheckbox
          style={{ ...appCheckBoxStyle, marginLeft: 30 }}
          onChange={event => handleChangeProductInformation(event, 'splitPrices')}
          checked={productInformation?.splitPrices}
          textValue={productInfo.splitPrices}
          disabled={isViewMode}
        />
      </StyledFirstBlock>
      <StyledSecondBlock>
        <AppCheckbox
          onChange={event => handleChangeProductInformation(event, 'name')}
          checked={productInformation?.name}
          textValue={productInfo.name}
          disabled={isViewMode}
          style={appFirstCheckBoxStyle}
        />
        <AppSegmented
          disabled={!productInformation?.name || isViewMode}
          style={{ opacity: (!productInformation?.name || isViewMode) && DISABLED_OPACITY }}
          options={nameOptions}
          onChange={() => handleChangeTypeName(productInformation.isShortProductName, 'isShortProductName')}
          value={productInformation.isShortProductName}
        />
      </StyledSecondBlock>
      <StyledThirdBlock>
        <AppCheckbox
          onChange={event => handleChangeProductInformation(event, 'showTotalPrice')}
          checked={productInformation?.showTotalPrice}
          textValue={productInfo.totalPrice}
          disabled={isViewMode}
          style={{ ...appFirstCheckBoxStyle, marginTop: '5px' }}
        />
        <StyledThirdBlockRight>
          <AppSegmented
            options={priceOptions}
            onChange={value => handleChangeTypePrice(value, 'priceType')}
            value={productInformation.priceType || ResultPriceType.PRICE_PER_PRODUCT}
          />
          <AppSegmented
            options={pricePeriodOptions}
            onChange={value => handleChangePeriodPrice(value, 'pricePeriod')}
            value={productInformation.pricePeriod || ResultPricePeriod.PRICE_PER_YEAR_AND_MONTH}
          />
        </StyledThirdBlockRight>
      </StyledThirdBlock>
      <TotalPrice productInformation={productInformation} id={id} onChange={onChange} />
    </div>
  );
}

const StyledFirstBlock = styled('div')({
  display: 'flex',
  marginBottom: '19px',
});

const StyledSecondBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
});

const StyledThirdBlock = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
});

const StyledThirdBlockRight = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});
