import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { DatePicker, TimePicker } from 'antd';
import { ContactFormField } from '../../../../../../../../../lib/models/Questionnaire';
import styles from './styles.module.scss';

export function ContactFormDatePicker({ fieldObject, disabled, applyVariable }) {
  return (
    <AppFormSection
      className={fieldObject.isFullWidth ? styles.input_full_width : styles.input_half_width}
      formItemProps={{
        name: fieldObject?.id,
        rules: [{ required: fieldObject?.required }],
      }}
      textFieldProps={{ label: applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name) }}
    >
      {fieldObject.type === ContactFormField.TIME && (
        <TimePicker
          style={{ width: '100%' }}
          placeholder={applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name)}
          disabled={disabled}
          format='HH:mm'
        />
      )}
      {fieldObject.type === ContactFormField.DATE && (
        <DatePicker
          style={{ width: '100%' }}
          placeholder={applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name)}
          format='DD.MM.YYYY'
          disabled={disabled}
        />
      )}
    </AppFormSection>
  );
}
