import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { TemplateTypes } from '../../models/TemplateTypes';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  templates: [],
  activeArrangedId: null,
  category: TemplateTypes.INTRO,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  isModalLoading: false,
  error: null,
  selectedTemplate: {},
  modalType: '',
  isModalOpen: false,
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    getTemplatesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.templates = [];

      if (payload?.category) {
        state.category = payload.category;
      }
    },
    getTemplatesSuccess: (state, action) => {
      state.templates = action.payload.templates;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getTemplatesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setTemplateStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setTemplateStatusSuccess: state => {
      state.loading = false;
    },
    setTemplateStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    addTemplateRequest: state => {
      state.error = null;
      state.loading = true;
    },
    addTemplateSuccess: state => {
      state.loading = false;
    },
    addTemplateError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    reUploadTemplateRequest: state => {
      state.error = null;
      state.loading = true;
    },
    reUploadTemplateSuccess: state => {
      state.loading = false;
    },
    reUploadTemplateError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openTemplateModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedTemplate = action.payload.selectedTemplate || {};
    },
    closeTemplateModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedTemplate = {};
    },
    deleteTemplateRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteTemplateSuccess: state => {
      state.isModalLoading = false;
    },
    deleteTemplateError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    /* arranges */
    // apply arranges
    applyTemplatesArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyTemplatesArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeTemplatesFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeTemplatesFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeTemplatesArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesError,
  setTemplateStatusRequest,
  setTemplateStatusSuccess,
  setTemplateStatusError,
  addTemplateRequest,
  addTemplateSuccess,
  addTemplateError,
  reUploadTemplateRequest,
  reUploadTemplateSuccess,
  reUploadTemplateError,
  openTemplateModal,
  closeTemplateModal,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  deleteTemplateError,
  applyTemplatesArrangesRequest,
  applyTemplatesArrangesError,
  removeTemplatesFiltersRequest,
  removeTemplatesFiltersError,
  removeTemplatesArranges,
} = templatesSlice.actions;
export const templates = templatesSlice.reducer;
