import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useLayoutSearchDataColumn } from '../../components/search-data';
import { Layout } from '../../lib/Layout';

export function useLayoutNameColumn() {
  const { name: title } = useLocalization().translations.unique.pages.layouts.table.columns;

  return useLayoutSearchDataColumn({
    title,
    arrangedId: Layout.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 195,
    },
  });
}
