/* eslint-disable max-lines-per-function */
import { Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TenantsIcon } from './assets/tenants.svg';
import { ReactComponent as UsersIcon } from './assets/users.svg';
import { ReactComponent as TemplatesIcon } from './assets/templates.svg';
import { ReactComponent as ThemesIcon } from './assets/themes.svg';
import { setTitle } from '../../../../../../lib/store/slices/titleSlice';

import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../lib/util/helpers';
import { AppLeftSidebar } from '../../../../../../ui/AppLeftSidebar';
import { getTemplatesRequest } from '../../../../../../lib/store/slices/templatesSlice';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { usePermissions } from 'src/lib/hooks/usePermissions';

const menuStyles = {
  height: '100%',
  borderRight: 0,
  background: 'transparent',
};

export function MainLeftSideBar() {
  const { isRestricted } = usePermissions();
  const location = useLocation();
  const { sidebar } = useLocalization().translations.unique.components;
  const { category } = useSelector(({ templates }) => templates);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templatesOptions = [
    { textValue: sidebar.templates.children.intro, key: TemplateTypes.INTRO },
    { textValue: sidebar.templates.children.chapter, key: TemplateTypes.CHAPTER },
    { textValue: sidebar.templates.children.content, key: TemplateTypes.CONTENT },
    { textValue: sidebar.templates.children.question, key: TemplateTypes.QUESTION },
    { textValue: sidebar.templates.children.product, key: TemplateTypes.PRODUCT },
    { textValue: sidebar.templates.children.result, key: TemplateTypes.RESULT },
    { textValue: sidebar.templates.children.contactForm, key: TemplateTypes.CONTACT_FORM },
  ];

  const onClickTemplate = ({ key, keyPath }) => {
    if (keyPath.includes(ROUTER_PATHS.templates)) {
      navigate(ROUTER_PATHS.templates);
      dispatch(getTemplatesRequest({ category: key }));
      dispatch(setTitle({ subTitle: handleTranslation(templatesOptions.find(option => option.key === key).textValue) }));
    }
  };

  const items = [
    {
      label: <Link to={ROUTER_PATHS.tenants}>{handleTranslation(sidebar.tenants)}</Link>,
      icon: <TenantsIcon />,
      key: ROUTER_PATHS.tenants,
    },
    {
      label: (
        <Link to={ROUTER_PATHS.users} style={{ pointerEvents: isRestricted() ? 'none' : 'all' }}>
          {handleTranslation(sidebar.users)}
        </Link>
      ),
      icon: <UsersIcon />,
      key: ROUTER_PATHS.users,
      disabled: isRestricted(),
    },
    {
      label: handleTranslation(sidebar.templates.name),
      icon: <TemplatesIcon />,
      key: ROUTER_PATHS.templates,
      children: templatesOptions.map(({ textValue, key }) => {
        return { label: handleTranslation(textValue), key };
      }),
      disabled: isRestricted(),
    },
    {
      label: (
        <Link to={ROUTER_PATHS.themes} style={{ pointerEvents: isRestricted() ? 'none' : 'all' }}>
          {handleTranslation(sidebar.themes)}
        </Link>
      ),
      icon: <ThemesIcon />,
      key: ROUTER_PATHS.themes,
      disabled: isRestricted(),
    },
  ];

  const keyExtractor = array => {
    return array.filter(item => item.key === category).map(menuItem => menuItem.key);
  };

  const linkPathExtractor = array => {
    return array.filter(item => location.pathname.includes(item.key)).map(linkObject => linkObject.key);
  };

  const selectedItems = location.pathname.includes(ROUTER_PATHS.templates)
    ? keyExtractor(items.find(menuItem => menuItem.key === ROUTER_PATHS.templates).children)
    : linkPathExtractor(items);

  return (
    <AppLeftSidebar>
      <Menu mode='inline' theme='dark' selectedKeys={selectedItems} style={menuStyles} items={items} onClick={onClickTemplate} />
    </AppLeftSidebar>
  );
}
