import { useState } from 'react';
import { Space, Switch } from 'antd';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormCard } from '../../../../../features/FormCard';
import { FormCardButton } from '../../../../../features/FormCardButton';
import { FontColorFormItem } from '../../../../../features/form-items/FontColorFormItem';
import { UploadImageLabel } from '../../../../../features/UploadImageLabel';
import { FinderFileLoader } from '../../../../../features/FinderFileLoader';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';
import '../styles.scss';
import { useSelector } from 'react-redux';
import { usePermissions } from 'src/lib/hooks/usePermissions';

const MAX_FILE_SIZE = 0.5;

// eslint-disable-next-line max-lines-per-function
export function HeaderCard({ form, designSettings, setDesignSettings }) {
  const { isRestricted } = usePermissions();
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const { design } = useLocalization().translations.unique.pages.settings;
  const { settingsHead, settingsSub, primaryButton, secondaryButton } = useLocalization().translations.unique.formItems;
  const {
    themeConfig: { colors },
    headerConfig,
  } = designSettings;

  const {
    finder: {
      generalSettings: { hvmIntegration },
    },
  } = useSelector(({ finders }) => finders);

  const { enabled } = hvmIntegration || {};

  // const fieldHandleChange = (fieldName, value) => setDesignSettings({ ...designSettings, headerConfig: { ...headerConfig, [fieldName]: value } });
  const fieldHandleChange = (fieldName, value) => {
    setDesignSettings({ ...designSettings, headerConfig: { ...headerConfig, [fieldName]: value } });
  };

  const selectHeaderBackgroundColor = value => {
    if (value) {
      fieldHandleChange('backgroundColor', value);
    }
  };

  const resetFields = () => {
    setDesignSettings({
      ...designSettings,
      headerConfig: {
        autoGuide: null,
        backgroundColor: ColorVariants.SECONDARY_SECOND,
        isAutoGuide: true,
        isBackgroundShadow: true,
        logo: null,
        showHeader: null,
      },
    });
    form.setFieldValue(FormItemName.HEADER_HEAD, '');
    form.setFieldValue(FormItemName.HEADER_SUB, '');
    form.setFieldValue(FormItemName.PRIMARY_BUTTON, 'Aktivieren');
    form.setFieldValue(FormItemName.SECONDARY_BUTTON, 'Deaktivieren');
  };

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppTitle level={4} textValue={design.header} />
        <FormCardButton onClick={resetFields} />
      </AppBox>
      {enabled && (
        <Space size='large' className='align_items_center' style={{ marginBottom: 32 }}>
          <div className='align_items_center'>
            <Switch checked={headerConfig.showHeader !== false} onChange={checked => fieldHandleChange('showHeader', checked)} size='small' />
            <AppText className='settings_card_switch' textValue='Show header' />
          </div>
        </Space>
      )}
      <AppText className='settings_card_label' textValue={design.background} />
      <Space size='large' className='align_items_center' style={{ marginBottom: 32 }}>
        <FontColorFormItem value={headerConfig.backgroundColor} onChange={selectHeaderBackgroundColor} colors={colors} />
        <div className='align_items_center'>
          <Switch checked={headerConfig.isBackgroundShadow} onChange={checked => fieldHandleChange('isBackgroundShadow', checked)} size='small' />
          <AppText className='settings_card_switch' textValue={design.shadow} />
        </div>
      </Space>
      <AppText className='settings_card_label' textValue={design.logo} />
      <FinderFileLoader
        afterUploadAction={file => fieldHandleChange('logo', file)}
        errorText={design.beforeUploadFileError()}
        maxFileSize={MAX_FILE_SIZE}
        disabled={uploadingLogo}
        setUploading={setUploadingLogo}
        uploadLabel={
          <UploadImageLabel
            mediaObject={uploadingLogo ? {} : headerConfig.logo}
            uploading={uploadingLogo}
            deleteOnClick={() => fieldHandleChange('logo', null)}
            imgHeight={52}
          />
        }
      />
      <AppText className='settings_card_label' textValue={design.audioGuide} />
      <div className='align_items_center' style={{ marginBottom: 32 }}>
        <Switch checked={headerConfig.isAutoGuide} onChange={checked => fieldHandleChange('isAutoGuide', checked)} disabled={isRestricted()} />
        <AppText className='settings_card_switch' textValue={design.audioGuidance} />
      </div>
      <FinderFileLoader
        afterUploadAction={file => fieldHandleChange('autoGuide', file)}
        errorText={design.beforeUploadFileError()}
        maxFileSize={MAX_FILE_SIZE}
        disabled={isRestricted() || uploadingAvatar}
        setUploading={setUploadingAvatar}
        uploadLabel={
          <UploadImageLabel
            mediaObject={uploadingAvatar ? {} : headerConfig.autoGuide}
            uploading={uploadingAvatar}
            deleteOnClick={() => {
              if (!isRestricted()) {
                fieldHandleChange('autoGuide', null);
              }
            }}
            imgHeight={68}
          />
        }
      />
      <AppFormSection
        formItemProps={{ name: FormItemName.HEADER_HEAD, rules: [{ required: true, message: settingsHead.errors.required() }] }}
        textFieldProps={{ label: settingsHead.label, disabled: isRestricted() }}
      />
      <AppFormSection formItemProps={{ name: FormItemName.HEADER_SUB }} textFieldProps={{ label: settingsSub.label, disabled: isRestricted() }} />
      <Space size='middle' className='equal_space'>
        <AppFormSection
          formItemProps={{ name: FormItemName.PRIMARY_BUTTON }}
          textFieldProps={{ label: primaryButton.label, disabled: isRestricted() }}
        />
        <AppFormSection
          formItemProps={{ name: FormItemName.SECONDARY_BUTTON }}
          textFieldProps={{ label: secondaryButton.label, disabled: isRestricted() }}
        />
      </Space>
    </FormCard>
  );
}
