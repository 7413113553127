import { useFinderSearchDataColumn } from '../../components/search-data';
import { Finder } from '../../lib/Finder';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function useFinderUrlColumn() {
  const { url: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderSearchDataColumn({
    title,
    arrangedId: Finder.URL,
    searchFieldName: 'searchByUrl',
    filterFieldName: 'includeUrls',
    columnsProps: {
      width: 287,
    },
  });
}
