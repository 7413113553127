import React from 'react';
import styled from 'styled-components';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';

export function Page({ providedItem, snapshotItem, isFirstPage, element, onOpenContextMenu, questionnaireStructure, isOpen, index, children }) {
  const onlyPages = questionnaireStructure?.every(el => el.templateType === TemplateTypes.PAGE);

  return (
    <PageItem
      ref={providedItem.innerRef}
      {...providedItem.draggableProps}
      {...providedItem.dragHandleProps}
      isDragging={snapshotItem.isDragging}
      onContextMenu={event => onOpenContextMenu(event, element?.id)}
      isFirstPage={isFirstPage}
      onlyPages={onlyPages}
      isOpen={isOpen}
      questionnaireStructure={questionnaireStructure}
      index={index}
    >
      {children}
    </PageItem>
  );
}

const getDisplayStyle = ({ isOpen, questionnaireStructure, isFirstPage, onlyPages, index }) => {
  if (!isOpen && questionnaireStructure[index + 1] && questionnaireStructure[index + 1].templateType !== TemplateTypes.CHAPTER) {
    return 'none';
  }
  if (isFirstPage && !onlyPages) {
    return 'none';
  }
  return isOpen || questionnaireStructure[index + 1]?.templateType === TemplateTypes.CHAPTER ? 'block' : 'none';
};

const PageItem = styled('div')(props => ({
  position: 'relative',
  borderBottom: props.isDragging ? '1px solid #da1b5e' : '2px dashed #2c2c35',
  margin: '5px 0',
  display: getDisplayStyle(props),
}));
