import styled from 'styled-components';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import classes from '../ComparisonTable.module.sass';
import { DefaultRowColor } from '../comparison-table-utils';

export function ColorBlock({ color, label, cx, ...rest }) {
  return (
    <AppBox $flexCenterXY {...rest}>
      <div className={classes.circle}>
        <CircleInner className={classes.circleInner} color={color} />
      </div>
      <AppText textValue={label} ellipsis style={{ color: '#fff' }} />
    </AppBox>
  );
}

const checkerboardPatternStyles = {
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff)',
  backgroundSize: '14px 14px',
  backgroundPosition: '0px 0px, 7px 7px',
};

const CircleInner = styled('span')({
  background: ({ color }) => (color === DefaultRowColor.NONE ? checkerboardPatternStyles : color),
});
