import { AppContent } from '../../../../../../ui/AppContent';
import { ProductsTable } from '../../modules/ProductsTable';
import { TableColumn } from '../../../../models/Table';
import { AppText } from '../../../../../../ui/text/AppText';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { useProductUsedInThisFinderColumn } from './columns/used-in-this-finder';

export function ProductsPage() {
  const { goToProductOverview } = useLocalization().translations.unique.pages.products;
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));
  const usedInThisFinderColumn = useProductUsedInThisFinderColumn();

  const modifyColumns = columns => columns.filter(item => item.dataIndex !== TableColumn.ACTIONS).concat(usedInThisFinderColumn);

  return (
    <AppContent>
      <Link to={ROUTER_PATHS.tenant(tenantId)} state={{ tab: 'PRODUCTS' }} style={{ marginBottom: '16px', display: 'flex' }}>
        <AppText strong className='primary_color' textValue={goToProductOverview} />
      </Link>

      <ProductsTable modifyColumns={modifyColumns} />
    </AppContent>
  );
}
