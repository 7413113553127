import styled from 'styled-components';
import { AppNewCheckbox } from '../../AppNewCheckbox';

export function MultiSelectOption({ isSelected, isDisabled, label, ...rest }) {
  return (
    <Root>
      <AppNewCheckbox checked={isSelected} isDisabled={isDisabled} label={label} onChange={rest.innerProps.onClick} />
    </Root>
  );
}

const Root = styled('div')({
  padding: '8px 0',
  '&:first-child': { paddingTop: 0 },
  '&:last-child': { paddingBottom: 0 },
});
