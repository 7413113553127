import { AppTitle } from '../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import './styles.scss';
import { AppImage } from '../../../../../../../ui/AppImage';

export function NotFound() {
  const { title, description, imageAlt } = useLocalization().translations.unique.pages.notFound;

  return (
    <div className='not_found_wrap'>
      <AppImage className='not_found_image' src='/images/not_found.svg' alt={imageAlt} />
      <AppTitle className='not_found_title' textValue={title} level={1} />
      <AppText className='not_found_descr' textValue={description} />
    </div>
  );
}
