import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import classes from '../Flow.module.sass';
import { FlowButtonOperationSelect } from './FlowButtonOperationSelect';
import classNames from 'classnames';

/**
 * @desc Flow Button Operation Wrapper
 * @param {JSX.Element} children
 * @param {String} structureItemId
 * @param {String} buttonsField
 * @param {Number} buttonIndex
 * @param {String} buttonName
 * @param {Object} selectProps
 * @param {Array} className
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowButtonOperationWrapper({
  title,
  children,
  structureItemId,
  buttonsField,
  buttonIndex,
  buttonName,
  selectProps = {},
  className = [],
  ...rest
}) {
  return (
    <div className={classNames(className, classes.operationSection)} {...rest}>
      <AppText textValue={title} className={classes.operationTitle} />
      <FlowButtonOperationSelect
        structureItemId={structureItemId}
        buttonIndex={buttonIndex}
        buttonsField={buttonsField}
        buttonName={buttonName}
        selectProps={selectProps}
      />
      {children}
    </div>
  );
}
