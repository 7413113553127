import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addTemplateError,
  addTemplateRequest,
  addTemplateSuccess,
  applyTemplatesArrangesError,
  applyTemplatesArrangesRequest,
  closeTemplateModal,
  deleteTemplateError,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  getTemplatesError,
  getTemplatesRequest,
  getTemplatesSuccess,
  removeTemplatesFiltersError,
  removeTemplatesFiltersRequest,
  reUploadTemplateError,
  reUploadTemplateRequest,
  reUploadTemplateSuccess,
  setTemplateStatusError,
  setTemplateStatusRequest,
  setTemplateStatusSuccess,
} from '../slices/templatesSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getLibraryTemplatesApiRequest } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    templates: { sorting, filtering, category, pagination },
  } = yield select();

  const data = { ...filtering, includeCategory: [category] };
  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data });
  const response = yield call(getLibraryTemplatesApiRequest, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getTemplatesSuccess({ templates: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchTemplates({ payload: { category, ...rest } = {} }) {
  try {
    yield requestAndSave(rest);
  } catch (error) {
    yield put(getTemplatesError({ error }));
  }
}

function* deleteTemplate({ payload: templateId }) {
  try {
    yield call(axiosInstance.delete, `template/${templateId}/delete`);
    yield put(deleteTemplateSuccess({ success: { messageType: deleteTemplateSuccess.type } }));
    yield put(closeTemplateModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteTemplateError({ error }));
  }
}

function* setTemplateStatus({ payload: { templateId, status } }) {
  try {
    yield call(axiosInstance.patch, `template/${templateId}/set-active?active=${status}`);
    yield put(setTemplateStatusSuccess({ success: { messageType: setTemplateStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setTemplateStatusError({ error }));
  }
}

function* reUploadTemplate({ payload: templateForm }) {
  try {
    yield call(axiosInstance.put, 'template/', templateForm, { headers: { 'Content-Type': 'multipart/form-data; ' } });
    yield put(reUploadTemplateSuccess({ success: { messageType: reUploadTemplateSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(reUploadTemplateError({ error }));
  }
}

function* addTemplate({ payload: templateForm }) {
  try {
    yield call(axiosInstance.post, 'template/', templateForm, { headers: { 'Content-Type': 'multipart/form-data; ' } });
    yield put(addTemplateSuccess({ success: { messageType: addTemplateSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addTemplateError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyTemplatesArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeTemplatesFiltersError({ error }));
  }
}

export function* watchTemplatesManagement() {
  yield all([
    takeLatest(getTemplatesRequest.type, fetchTemplates),
    takeLatest(setTemplateStatusRequest.type, setTemplateStatus),
    takeLatest(deleteTemplateRequest.type, deleteTemplate),
    takeLatest(reUploadTemplateRequest.type, reUploadTemplate),
    takeLatest(addTemplateRequest.type, addTemplate),
    takeLatest(applyTemplatesArrangesRequest.type, applyArranges),
    takeLatest(removeTemplatesFiltersRequest.type, removeFilters),
  ]);
}
