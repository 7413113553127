import { getCategoriesFromApi, getProductsFromApi, getUsersFromApi } from '../../../../../lib/api';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../lib/consts';

export const getFilteredProductsFromApi = (tenantId, filters) =>
  getProductsFromApi(tenantId, {
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: filters,
  });

export const getFilteredCategoriesFromApi = (tenantId, filters) =>
  getCategoriesFromApi(tenantId, {
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: filters,
  });

export const getUsersByLoginFromApi = value =>
  getUsersFromApi({ params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE }, data: { searchByLogin: value } });
