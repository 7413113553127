import { QuestionnaireTextButton } from '../../../../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { generateDetailTable } from './generateDetailTable';
import styled from 'styled-components';

const MAX_NUMBER_DETAIL_TABLES = 5;

export function AddDetailTableButton({ detailTableSection, structureItemIndex, productIndex }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { updateStructure } = useUpdateStructure();
  const { productsDepot } = useLocalization().translations.unique.pages.result;

  const addDetailTable = () => {
    updateStructure(draft => {
      draft[structureItemIndex]?.content.products[productIndex].detailTableSection.detailTables.push(generateDetailTable());
    });
  };

  return (
    <Root>
      <QuestionnaireTextButton
        textValue={productsDepot.detailTable.addButton}
        disabled={isViewMode || !detailTableSection.showDetailTable || detailTableSection.detailTables.length >= MAX_NUMBER_DETAIL_TABLES}
        onClick={addDetailTable}
      />
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 20px 32px 20px',
});
