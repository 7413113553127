import { CardSection } from '../../../../../../ui/CardSection';
import { Header } from './Header';
import { SettingsFields } from './SettingsFields';

export function PrintPdfSettings({ data, structureItemIndex }) {
  return (
    <CardSection withPaddingBottom>
      <Header />
      <SettingsFields data={data} structureItemIndex={structureItemIndex} />
    </CardSection>
  );
}
