import { useState } from 'react';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { ActionsIcon } from '../../../../../ui/icons/ActionsIcon';
import { IconButton } from './IconButton';
import { DropdownMenuButton } from '../../../ui/DropdownMenuButton';
import { FileLoader } from './FileLoader';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { openUserModal, setUserStatusRequest } from '../../../../../lib/store/slices/usersSlice';
import { openTenantModal, setTenantStatusRequest } from '../../../../../lib/store/slices/tenantsSlice';
import { openFinderModal, setFinderStatusRequest } from '../../../../../lib/store/slices/findersSlice';
import { openTemplateModal, reUploadTemplateRequest, setTemplateStatusRequest } from '../../../../../lib/store/slices/templatesSlice';
import { openThemeModal, setThemeStatusRequest } from '../../../../../lib/store/slices/themesSlice';
import { FinderModal, LayoutModal, TemplateModal, TenantModal, ThemeModal, UserModal, RuleModal } from '../../../lib/models/Modal';
import { downloadFile } from '../../../../../lib/util/helpers';
import { openLayoutModal, reUploadLayoutRequest, setLayoutStatusRequest } from '../../../../../lib/store/slices/layoutsSlice';
import { openRuleModal, setRuleStatusRequest } from '../../../../../lib/store/slices/rulesSlice';

// REFACTOR: whole file

// eslint-disable-next-line max-lines-per-function
export function ActionsDropdown({ table, row }) {
  const { actions } = useLocalization().translations.unique.components;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = flag => setVisible(flag);

  const deleteAction = () => {
    setVisible(false);
    if (table === 'tenants') {
      dispatch(openTenantModal({ modalType: TenantModal.DELETE, selectedTenant: row }));
    }
    if (table === 'users') {
      dispatch(openUserModal({ modalType: UserModal.DELETE, selectedUser: row }));
    }
    if (table === 'finders') {
      dispatch(openFinderModal({ modalType: FinderModal.DELETE, selectedFinder: row }));
    }
    if (table === 'templates') {
      dispatch(openTemplateModal({ modalType: TemplateModal.DELETE, selectedTemplate: row }));
    }
    if (table === 'layouts') {
      dispatch(openLayoutModal({ modalType: LayoutModal.DELETE, currentItem: row }));
    }
    if (table === 'themes') {
      dispatch(openThemeModal({ modalType: ThemeModal.DELETE, selectedTheme: row }));
    }
    if (table === 'rules') {
      dispatch(openRuleModal({ modalType: RuleModal.DELETE, selectedRule: row }));
    }
  };

  const toggleStatus = () => {
    setVisible(false);
    if (table === 'users') {
      dispatch(setUserStatusRequest({ status: row.status === 'DEACTIVATED', userId: row.id }));
    }
    if (table === 'tenants') {
      dispatch(setTenantStatusRequest({ status: !row.active, tenantId: row.id }));
    }
    if (table === 'templates') {
      dispatch(setTemplateStatusRequest({ status: !row.activated, templateId: row.id }));
    }
    if (table === 'layouts') {
      dispatch(setLayoutStatusRequest({ status: !row.activated, id: row.id }));
    }
    if (table === 'themes') {
      dispatch(setThemeStatusRequest({ status: !row.activated, themeId: row.id }));
    }
    if (table === 'finders') {
      dispatch(setFinderStatusRequest({ status: !row.activated, finderId: row.id }));
    }
    if (table === 'rules') {
      dispatch(setRuleStatusRequest({ status: !row.activated, ruleId: row.id }));
    }
  };

  const onClickDuplicate = () => {
    setVisible(false);
    if (table === 'themes') {
      dispatch(openThemeModal({ modalType: ThemeModal.DUPLICATE, selectedTheme: row }));
    }
    if (table === 'finders') {
      dispatch(openFinderModal({ modalType: FinderModal.DUPLICATE, selectedFinder: row }));
    }
    if (table === 'rules') {
      dispatch(openRuleModal({ modalType: RuleModal.DUPLICATE, selectedRule: row }));
    }
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: 141,
    padding: '8px 0',
    background: '#FFFFFF',
    border: 'border: 1px solid #9696A2',
    borderRadius: 8,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  };

  const disabledActive = {
    tenants: row.active,
    templates: row.activated,
    layouts: row.activated,
    themes: row.activated,
    users: ['ACTIVE', 'PENDING'].includes(row.status),
    finders: row.activated,
    rules: row.activated,
  };

  const disabledDeactivate = {
    tenants: !row.active,
    templates: Boolean(row.usedInPublishedFinders) || !row.activated,
    themes:
      (row.findersByTenant && row.findersByTenant.flatMap(tenant => tenant.finders.map(finder => finder.status)).includes('ACTIVATED')) ||
      !row.activated,
    users: ['DEACTIVATED', 'PENDING'].includes(row.status),
    finders: !row.activated,
    layouts: !row.activated,
    rules: !row.activated,
  };

  const onClickDownload = () => {
    setVisible(false);
    if (row.s3Url) {
      downloadFile(row.s3Url);
    }
  };

  const onClickUpload = () => {
    setVisible(false);
  };

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement='topRight'
      onOpenChange={handleVisibleChange}
      dropdownRender={() => (
        <div style={contentStyle}>
          <DropdownMenuButton textValue={actions.activate} disabled={disabledActive[table]} onClick={toggleStatus} />
          <DropdownMenuButton textValue={actions.deactivate} disabled={disabledDeactivate[table]} onClick={toggleStatus} />
          {['finders', 'themes'].includes(table) && <DropdownMenuButton textValue={actions.duplicate} onClick={onClickDuplicate} />}
          {table === 'rules' && <DropdownMenuButton textValue={actions.duplicateTo} onClick={onClickDuplicate} />}
          {['templates', 'layouts'].includes(table) && <DropdownMenuButton textValue={actions.download} onClick={onClickDownload} />}
          {['templates', 'layouts'].includes(table) && (
            <FileLoader
              item={row}
              uploadAction={table === 'templates' ? reUploadTemplateRequest : reUploadLayoutRequest}
              uploadLabel={<DropdownMenuButton textValue={actions.upload} onClick={onClickUpload} />}
            />
          )}
          <DropdownMenuButton textValue={actions.delete} danger onClick={deleteAction} />
        </div>
      )}
    >
      <IconButton icon={<ActionsIcon isOpen={visible} />} />
    </Dropdown>
  );
}
