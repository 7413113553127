import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { CategoryNameFormItem } from '../../../../../../../../../../../features/CategoryNameFormItem';
import { trimForm } from '../../../../../../../../../../../lib/util/helpers';
import { addCategoryRequest } from '../../../../../../../../../../../lib/store/slices/categoriesSlice';
import { CategorySelectionFormSection } from '../../features/CategorySelectionFormSection';
import { Category } from '../../../CategoriesTable/lib/Category';

export function AddCategoryForm({ form, tenantId }) {
  const dispatch = useDispatch();

  const onFinish = values => {
    const trimmedForm = trimForm(values);
    dispatch(addCategoryRequest({ categoryForm: trimmedForm, tenantId }));
  };

  return (
    <Form form={form} onFinish={onFinish} style={{ marginTop: 32 }} initialValues={{ [Category.IS_MULTI_PRODUCT]: true }}>
      <CategoryNameFormItem />
      <CategorySelectionFormSection />
    </Form>
  );
}
