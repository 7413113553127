import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addTenantError,
  addTenantRequest,
  addTenantSuccess,
  applyTenantArrangesError,
  applyTenantArrangesRequest,
  closeTenantModal,
  deleteTenantError,
  deleteTenantRequest,
  deleteTenantSuccess,
  editTenantError,
  editTenantRequest,
  editTenantSuccess,
  getTenantDomainsError,
  getTenantDomainsRequest,
  getTenantDomainsSuccess,
  getTenantsError,
  getTenantsRequest,
  getTenantsSuccess,
  removeTenantFiltersError,
  removeTenantFiltersRequest,
  setTenantStatusError,
  setTenantStatusRequest,
  setTenantStatusSuccess,
} from '../slices/tenantsSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getTenantsApiRequest, withXTenantHeader } from '../../api';

function* requestAndSaveTenants(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    tenants: { sorting, filtering, pagination },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });
  const response = yield call(getTenantsApiRequest, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSaveTenants({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getTenantsSuccess({ tenants: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchTenants({ payload = {} }) {
  try {
    yield requestAndSaveTenants(payload);
  } catch (error) {
    yield put(getTenantsError({ error }));
  }
}

function* addTenant({ payload }) {
  try {
    yield call(axiosInstance.post, 'tenant/database-config/', payload);
    yield put(addTenantSuccess({ success: { messageType: addTenantSuccess.type } }));
    yield put(closeTenantModal());
    yield fork(requestAndSaveTenants);
  } catch (error) {
    yield put(addTenantError({ error }));
  }
}

function* editTenant({ payload }) {
  try {
    yield call(axiosInstance.put, 'tenant/database-config/update', payload);
    yield put(editTenantSuccess({ success: { messageType: editTenantSuccess.type } }));
    yield put(closeTenantModal());
    yield fork(requestAndSaveTenants);
  } catch (error) {
    yield put(editTenantError({ error }));
  }
}

function* deleteTenant({ payload: tenantId }) {
  try {
    yield call(axiosInstance.delete, `tenant/database-config/${tenantId}/delete`, withXTenantHeader(tenantId));
    yield put(deleteTenantSuccess({ success: { messageType: deleteTenantSuccess.type } }));
    yield put(closeTenantModal());
    yield fork(requestAndSaveTenants);
  } catch (error) {
    yield put(deleteTenantError({ error }));
  }
}

function* setTenantStatus({ payload: { tenantId, status } }) {
  try {
    yield call(axiosInstance.patch, `tenant/database-config/${tenantId}/set-active?active=${status}`, {}, withXTenantHeader(tenantId));
    yield put(setTenantStatusSuccess({ success: { messageType: setTenantStatusSuccess.type } }));
    yield fork(requestAndSaveTenants);
  } catch (error) {
    yield put(setTenantStatusError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSaveTenants();
  } catch (error) {
    yield put(applyTenantArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSaveTenants();
  } catch (error) {
    yield put(removeTenantFiltersError({ error }));
  }
}

function* getTenantDomains({ payload: tenantId }) {
  try {
    const res = yield call(axiosInstance.get, 'domain/domains', withXTenantHeader(tenantId));
    yield put(getTenantDomainsSuccess(res.data));
  } catch (error) {
    yield put(getTenantDomainsError({ error }));
  }
}

/**
 * @desc function generator - observer for all tenant's sagas functions
 */
export function* watchTenantsManagement() {
  yield all([
    takeLatest(getTenantsRequest.type, fetchTenants),
    takeLatest(setTenantStatusRequest.type, setTenantStatus),
    takeLatest(addTenantRequest.type, addTenant),
    takeLatest(editTenantRequest.type, editTenant),
    takeLatest(deleteTenantRequest.type, deleteTenant),
    takeLatest(applyTenantArrangesRequest.type, applyArranges),
    takeLatest(removeTenantFiltersRequest.type, removeFilters),
    takeLatest(getTenantDomainsRequest.type, getTenantDomains),
  ]);
}
