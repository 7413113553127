import { ProductCategoryHeaderCell } from './ProductCategoryHeaderCell';
import { PRODUCT_CATEGORY_ID } from './product-category-utils';
import { AppText } from '../../../../../../../../ui/text/AppText';

export const productCategoryColumn = {
  dataIndex: PRODUCT_CATEGORY_ID,
  title: <ProductCategoryHeaderCell />,
  width: 150,
  render: data => <AppText>{data?.name}</AppText>,
};
