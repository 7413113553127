export const UserModal = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const TenantModal = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const TemplateModal = {
  DELETE: 'delete',
};

export const LayoutModal = {
  DELETE: 'delete',
};

export const ThemeModal = {
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
};

export const FinderModal = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
  CHANGE_QUESTIONNAIRE: 'changeQuestionnaire',
  SESSION_EXPIRED: 'sessionExpired',
};

export const ProductModal = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const CategoryModal = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const RuleModal = {
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
};
