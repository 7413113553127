import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { useFinderSearchPersonColumn } from '../../components/search-person';
import { Finder } from '../../lib/Finder';
import styles from '../../../../../../../../utils/styles/table.module.sass';

export function useFinderCreatorColumn() {
  const { creator: title } = useLocalization().translations.shared.columns;

  return useFinderSearchPersonColumn({
    title,
    arrangedId: Finder.CREATOR,
    filterFieldName: 'includeCreatedBy',
    width: 200,
    headerCellProps: {
      rootProps: { className: styles.hiddenHeaderCell },
    },
  });
}
