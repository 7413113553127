export function PlayCircleIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg className={className} {...rest} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM10.185 7.6395C10.0729 7.55965 9.9409 7.51221 9.80358 7.50238C9.66627 7.49254 9.52889 7.52069 9.40651 7.58374C9.28413 7.64679 9.18147 7.74231 9.10976 7.85983C9.03806 7.97735 9.00008 8.11233 9 8.25V15.75C9.00008 15.8877 9.03806 16.0227 9.10976 16.1402C9.18147 16.2577 9.28413 16.3532 9.40651 16.4163C9.52889 16.4793 9.66627 16.5075 9.80358 16.4976C9.9409 16.4878 10.0729 16.4403 10.185 16.3605L15.435 12.6105C15.5322 12.5411 15.6115 12.4495 15.6661 12.3433C15.7208 12.2372 15.7494 12.1194 15.7494 12C15.7494 11.8806 15.7208 11.7628 15.6661 11.6567C15.6115 11.5505 15.5322 11.4589 15.435 11.3895L10.185 7.6395Z'
        fill='#00ACC0'
      />
    </svg>
  );
}
