import { changeNumberValue } from './change_number_value';

export const getProductSavingsValue = (cardSavingsBlocks, originalSavings) => {
  return cardSavingsBlocks.reduce(
    (accumulator, cardSavingsBlock) => {
      const { savingsProduct, resultOperator, value } = cardSavingsBlock;

      if (savingsProduct) {
        const ruleSavingsValue = changeNumberValue(value, resultOperator, savingsProduct, { value: originalSavings });
        return { ruleSavings: ruleSavingsValue < 0 ? 0 : ruleSavingsValue, originalSavings };
      }

      return accumulator;
    },
    { ruleSavings: undefined, originalSavings }
  );
};
