import React from 'react';
import styled from 'styled-components';
import { DragIcon } from '../../../../../../../../../ui/icons/DragIcon';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';

export function EmptyCard({ isDraggingOver }) {
  const { content } = useLocalization().translations.unique.pages.questionnaire;
  return (
    <Container>
      <Card isDraggingOver={isDraggingOver}>
        <DragIcon />
        <AppText style={{ color: '#5C5C6E', marginLeft: 8 }} textValue={content.dragText} />
      </Card>
    </Container>
  );
}

const Card = styled('div')(props => ({
  height: '96px',
  margin: '18px auto 50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#f2f2f3',
  borderRadius: '8px',
  padding: '24px',
  border: `1px ${props.isDraggingOver ? 'solid #00ACC0' : 'solid #ddd'}`,
}));

const Container = styled('div')({
  margin: '18px auto 0',
  width: '75%',
});
