import { QuestionnaireTextButton } from '../../../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';

export function AddProductButton({ structureItemIndex, allProducts, generateProductItem }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const areNoAvailableProducts = questionnaireStructure[structureItemIndex].content.products?.length === allProducts?.length;
  const { updateStructure } = useUpdateStructure();
  const { productsDepot } = useLocalization().translations.unique.pages.result;

  const addProduct = () => {
    updateStructure(draft => {
      const showCounter = !draft[structureItemIndex].content.productSelection;
      draft[structureItemIndex].content.products.push(generateProductItem(showCounter));
    });
  };

  return (
    <Root>
      <QuestionnaireTextButton textValue={productsDepot.addProductButton} disabled={areNoAvailableProducts || isViewMode} onClick={addProduct} />
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 20px',
  borderBottom: '1px solid #ECECEE',
});
