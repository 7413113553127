import { AppText } from '../../../../../../ui/text/AppText';
import { IconButton } from '../IconButton';
import { CircleXIcon } from '../../../../../../ui/icons/CircleXIcon';
import { PreloaderIcon } from '../../../../../../ui/icons/PreloaderIcon';
import './styles.scss';

export function UploadedIconBox({ icon, deleteOnClick }) {
  return (
    <div className='bullet_icon_box'>
      <div className='bullet_icon_wrap'>
        {icon.s3Url ? (
          <img className='bullet_icon_image' src={icon.s3Url} alt='bullet icon' />
        ) : (
          <PreloaderIcon className='file_uploader' style={{ width: 24, height: 24 }} />
        )}
      </div>
      {icon.s3Url && (
        <>
          <AppText ellipsis className='bullet_icon_name'>
            {icon.originalFileName}
          </AppText>
          <IconButton additionalStyles='bullet_icon_hidden_box' onClick={deleteOnClick} icon={<CircleXIcon className='bullet_icon_hidden_btn' />} />
        </>
      )}
    </div>
  );
}
