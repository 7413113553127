export function generateTable() {
  return {
    tableName: {
      value: '',
      infoIcon: null,
    },
    tableRows: [
      {
        bgColor: null,
        cells: [
          { value: '', withPlaceholder: false, id: '1' },
          { value: '', type: 'product', id: '2' },
          { value: '', type: 'product', id: '3' },
          { value: '', type: 'product', id: '4' },
        ],
      },
      {
        bgColor: null,
        cells: [
          { value: '', withPlaceholder: false, id: '5' },
          { value: '', id: '6' },
          { value: '', id: '7' },
          { value: '', id: '8' },
        ],
      },
      {
        bgColor: null,
        cells: [
          { value: '', withPlaceholder: false, id: '9' },
          { value: '', id: '10' },
          { value: '', id: '11' },
          { value: '', id: '12' },
        ],
      },
    ],
  };
}
