import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { ArrangeListPopup } from '../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../components/TableHeaderCell';
import { useLayoutArrangeRequests } from '../../lib/useLayoutArrangeRequests';
import { getLayoutsApiRequest } from '../../../../../../../../../../lib/api';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../../../lib/consts';

export function LayoutSearchDataHeaderCell({ arrangedId, title, searchFieldName, filterFieldName, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ layouts }) => layouts);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredLayoutList({ [searchFieldName]: value }),
    formatResponse: data => formatArrangedData(data, arrangedId),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useLayoutArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}

const getFilteredLayoutList = filters =>
  getLayoutsApiRequest({
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: filters,
  });
