import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppNewSelect } from '../../../../../../../../../../../../ui/AppNewSelect';
import { IconButton } from '../../../../../../../../features/IconButton';
import { EditIcon } from '../../../../../../../../../../../../ui/icons/EditIcon';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import { GripVerticalIcon } from '../../../../../../../../../../../../ui/icons/GripVerticalIcon';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { selectRule } from '../../../../../../../../../../../../lib/store/slices/rulesSlice';
import { ROUTER_PATHS } from '../../../../../../../../../../../../lib/util/router/router-paths';
import './style.scss';

export function RuleRow({ ruleObj, elementRules, elementId, disabled, ...rest }) {
  const {
    questionnaireStructure,
    finder: { id: finderId },
    finderRules,
    tenant,
  } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectRule: selectRuleText } = useLocalization().translations.unique.formItems;

  const elementRulesRuleIds = new Set(elementRules.filter(rule => rule.ruleId !== ruleObj.ruleId).map(rule => rule.ruleId));
  const ruleOptions = finderRules?.filter(rule => !elementRulesRuleIds.has(rule.ruleId)).map(rule => ({ value: rule.ruleId, title: rule.name }));

  const handleChangeRule = value => {
    const result = questionnaireStructure.map(el => {
      return el.id === elementId ? { ...el, rules: el.rules.map(rule => (rule.rowId === ruleObj.rowId ? { ...rule, ruleId: value } : rule)) } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const editRule = () => {
    const ruleFromFinder = finderRules.find(ruleItem => ruleItem.ruleId === ruleObj.ruleId);
    if (ruleFromFinder) {
      const formattedRule = { ...ruleFromFinder, conditions: JSON.parse(ruleFromFinder.conditions) };
      dispatch(selectRule(formattedRule));
      navigate(`${ROUTER_PATHS.builder(finderId)}?X-TENANT=${tenant.id}`);
    }
  };

  const deleteRule = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === elementId ? { ...el, rules: el.rules.filter(rule => rule.rowId !== ruleObj.rowId) } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <div className='rule_row_wrap'>
      <div {...rest} className='rule_row_drag_icon'>
        <GripVerticalIcon />
      </div>

      <div style={{ width: 264 }}>
        <AppNewSelect
          label={selectRuleText.label}
          options={ruleOptions}
          value={ruleObj.ruleId}
          onChange={selected => handleChangeRule(selected.value)}
          disabled={disabled}
        />
      </div>
      <IconButton onClick={editRule} disabled={disabled || !ruleObj.ruleId} icon={<EditIcon className='secondary_color' />} />
      <IconButton onClick={deleteRule} disabled={disabled} icon={<DeleteIcon className='secondary_color' />} />
    </div>
  );
}
