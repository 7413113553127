import styled from 'styled-components';
import { AppText } from '../../../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSwitch } from '../../../../../../../../features/QuestionnaireSwitch';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';
import { AddDetailTableButton } from './AddDetailTableButton';
import { DetailTableList } from './DetailTableList';

export function DetailTableSection({ data, productIndex, structureItemIndex }) {
  const { productsDepot } = useLocalization().translations.unique.pages.result;

  const { updateStructure } = useUpdateStructure();

  const toggleShowDetailTable = () => {
    updateStructure(draft => {
      const { detailTableSection } = draft[structureItemIndex].content.products[productIndex];
      detailTableSection.showDetailTable = !detailTableSection.showDetailTable;
    });
  };

  return (
    <Root>
      <StyledHeader>
        <AppText strong textValue={productsDepot.detailTable.title} />
        <QuestionnaireSwitch onChange={toggleShowDetailTable} isOn={data.detailTableSection.showDetailTable} style={{ marginLeft: 8 }} />
      </StyledHeader>
      {data.detailTableSection.showDetailTable && (
        <DetailTableList data={data.detailTableSection.detailTables} structureItemIndex={structureItemIndex} productIndex={productIndex} />
      )}
      <AddDetailTableButton detailTableSection={data.detailTableSection} structureItemIndex={structureItemIndex} productIndex={productIndex} />
    </Root>
  );
}

const Root = styled('div')({
  borderBottom: '1px solid #ECECEE',
});

const StyledHeader = styled('div')({
  display: 'flex',
  padding: '32px 24px 16px 24px',
});
