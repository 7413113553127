import { AppNewSelect } from 'src/ui/AppNewSelect';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import './valueType.scss';

export function ValueTypeInput({ value = {}, onChange = () => {}, allowString }) {
  const { valueTypeInput } = useLocalization().translations.unique.components;
  const { selectValue, inputValue } = value;

  const options = [
    {
      title: valueTypeInput.percent(),
      value: 'percent',
    },
    {
      title: valueTypeInput.digit(),
      value: 'digit',
    },
  ];

  if (allowString) {
    options.push({
      title: valueTypeInput.stringType(),
      value: 'string',
    });
  }

  const onValueChange = (type, val) => {
    if (type === 'selectValue') {
      onChange({
        ...value,
        inputValue: '',
        [type]: val,
      });
      return;
    }

    if (type === 'inputValue' && selectValue !== 'string') {
      const formattedValue = val.replace(/[^\d,]/g, '');
      const firstCommaIndex = val.indexOf(',');
      const notFoundComaIndex = -1;
      const valueWithoutDuplicateComas =
        firstCommaIndex !== notFoundComaIndex
          ? formattedValue.slice(0, firstCommaIndex + 1) + formattedValue.slice(firstCommaIndex + 1).replaceAll(',', '')
          : formattedValue;

      onChange({
        ...value,
        [type]: valueWithoutDuplicateComas,
      });

      return;
    }

    onChange({
      ...value,
      inputValue: inputValue || 0,
      [type]: val,
    });
  };

  return (
    <div className='valueTypeInput'>
      <AppNewSelect
        className='valueTypeSelect'
        value={selectValue}
        onChange={selection => onValueChange('selectValue', selection.value)}
        options={options}
        label={valueTypeInput.valueType}
      />
      <div className='valueContainer'>
        <span className='valueContainerLabel'>{valueTypeInput.value()}</span>
        <input value={inputValue} onChange={event => onValueChange('inputValue', event.target.value)} />
      </div>
    </div>
  );
}
