import { useFinderSearchPersonColumn } from '../../components/search-person';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { Finder } from '../../lib/Finder';

export function useFinderLastEditedBy() {
  const { lastEditFrom: title } = useLocalization().translations.unique.pages.finders.table.columns;

  return useFinderSearchPersonColumn({
    title,
    arrangedId: Finder.LAST_EDITED_BY,
    filterFieldName: 'includeLastModifiedBy',
    width: 207,
  });
}
