import { Layout } from '../../lib/Layout';
import { PreviewCell } from '../../../../../../features/PreviewCell';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { TableHeaderCell } from '../../../../../../components/TableHeaderCell';

export function useLayoutPreviewColumn() {
  const { preview: title } = useLocalization().translations.shared.columns;

  return {
    title: <TableHeaderCell withArrange={false} title={title} />,
    dataIndex: Layout.PREVIEW_IMAGE,
    width: 183,
    render: (url, row) => <PreviewCell previewImage={row.previewImage} image={row.image} />,
  };
}
