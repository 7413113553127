import { Modal } from 'antd';
import styled from 'styled-components';
import { AppBox, STYLES } from './boxes/AppBox';
import { AppTitle } from './text/AppTitle';
import { CrossIcon } from './icons/CrossIcon';
import { useLocalization } from '../lib/hooks/useLocalization';
import { AppButton } from './buttons/AppButton';

const APP_MODAL_DEFAULT_WIDTH = 612; // px

export function AppModal(props) {
  const { generic } = useLocalization().translations.shared;
  const {
    title,
    titleProps = {},
    footerProps: { style: footerStyle = {}, ...footerRestProps } = {},
    cancelButtonProps = {},
    actionButtonProps = {},
    ...rest
  } = props;
  const closeIcon = <CrossIcon styles='secondary_color' />;

  const footer = (
    <div style={{ ...styles.footer, ...footerStyle }} {...footerRestProps}>
      <AppButton textValue={generic.cancel} $isFullWidth {...cancelButtonProps} />
      <AppButton textValue={generic.save} $isFullWidth type='primary' {...actionButtonProps} />
    </div>
  );

  return (
    <AppBox
      centered
      destroyOnClose
      title={titleProps?.textValue ? <AppTitle level={3} {...titleProps} /> : title}
      footer={footer}
      closeIcon={closeIcon}
      width={APP_MODAL_DEFAULT_WIDTH}
      as={StyledModal}
      onCancel={cancelButtonProps.onClick}
      {...rest}
    />
  );
}

const StyledModal = styled(Modal)(() => ({
  '& .ant-modal-close span': STYLES.$flexCenterXY,
  '.ant-modal-content': {
    padding: '34px 24px 20px 24px',
  },
  '.ant-modal-footer': {
    marginTop: 8,
  },
}));

const styles = {
  footer: {
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
};
