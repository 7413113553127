import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const appDayJs = dayjs;

const MINS_IN_1_HOUR = 60;

export function getTimezoneOffsetInHours() {
  const timezoneOffsetInMinutes = appDayJs().utcOffset();
  return timezoneOffsetInMinutes / MINS_IN_1_HOUR;
}
