import { execute, isPositiveAmount, isTruthy } from '../util/helpers';
import fx from 'money';

export const CurrencyCode = {
  EUR: 'eur',
  USD: 'usd',
  CAD: 'cad',
  CHF: 'chf',
  SGD: 'sgd',
};

const CurrencySymbol = {
  EUR: '€',
  USD: '$',
  CAD: 'CA$',
  CHF: '₣',
  SGD: 'S$',
};

export const CurrencySymbolAndCode = {
  EUR: '€',
  USD: '$',
  CAD: 'CAD',
  CHF: 'CHF',
  SGD: 'SGD',
};

export const DEFAULT_CURRENCY_SYMBOL = CurrencySymbol.EUR;

export const DEFAULT_CURRENCY_CODE = CurrencyCode.EUR;

export const normalizeCurrencyCode = currencyCode => currencyCode?.toUpperCase?.()?.trim?.() || '';

export function transformCurrencyCodeToSymbol(currencyCode) {
  if (!currencyCode) return DEFAULT_CURRENCY_SYMBOL;

  return CurrencySymbol[normalizeCurrencyCode(currencyCode)] || currencyCode;
}

const DEFAULT_FRACTION_DIGITS = 2;

export function formatPriceForUi(value) {
  const numbered = Number(value);
  const normalized = isPositiveAmount(numbered) ? numbered : 0;
  return normalized?.toFixed?.(DEFAULT_FRACTION_DIGITS)?.replace?.('.', ',') || '';
}

function getBaseRate(baseCurrencyCode) {
  return {
    [baseCurrencyCode]: 1,
  };
}

const FX_DEFAULT_CURRENCY_CODE = DEFAULT_CURRENCY_CODE.toUpperCase(); // Must be uppercased 🙄

fx.base = FX_DEFAULT_CURRENCY_CODE;
fx.rates = {
  ...getBaseRate(FX_DEFAULT_CURRENCY_CODE),
  USD: 1.09,
  CAD: 1.45,
  CHF: 0.98,
  SGD: 1.47,
};

const DEFAULT_MONEY_AMOUNT = 0;

export function convertMoney(value, from, to, safe = true) {
  return (
    execute(() => {
      if (!isTruthy(value)) throw new Error('"value" must be provided');
      if (!from) throw new Error('"from" currency must be provided');
      if (!to) throw new Error('"to" currency must be provided');

      return fx(value).from(normalizeCurrencyCode(from)).to(normalizeCurrencyCode(to));
    }, safe) || DEFAULT_MONEY_AMOUNT
  );
}
