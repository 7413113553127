/* eslint-disable no-param-reassign */
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFinderColors } from '../../../../../../../../../../hooks/useFinderColors';
import { useUpdateStructure } from '../../../../../../../../../../hooks/useUpdateStructure';
import { BulletPointIconFormItem } from '../../../../../../../../../../../../features/form-items/BulletPointIconFormItem';
import { FontColorFormItem } from '../../../../../../../../../../../../features/form-items/FontColorFormItem';
import { checkDisableColor } from '../../../../../../../../../../../../../../../../lib/util/helpers';

export function BulletPointSelects({ structureItemIndex, bulletPointIcon, bulletPointIconColor, productIndex, detailTableIndex, rightColumnType }) {
  const {
    isViewMode,
    finder: {
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);
  const icons = [...defaultIcons, ...customIcons];
  const colors = useFinderColors();

  const { updateStructure } = useUpdateStructure();

  const disabled = rightColumnType === 'text';

  const changeBulletPointIcon = data => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].detailTableSection.detailTables[detailTableIndex].bulletPointsSection.bulletPointIcon =
        data;
    });
  };

  const changeBulletPointIconColor = data => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].detailTableSection.detailTables[
        detailTableIndex
      ].bulletPointsSection.bulletPointIconColor = data;
    });
  };

  return (
    <>
      <Styled
        as={BulletPointIconFormItem}
        size='small'
        color={colors[bulletPointIconColor]}
        icons={icons}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '160px', minHeight: 24 },
        }}
        selectProps={{
          size: 'small',
          value: bulletPointIcon,
          onChange: (selected, _action) => changeBulletPointIcon({ value: selected.value, svgContent: selected.svgContent }),
          disabled,
        }}
      />
      <Styled
        as={FontColorFormItem}
        size='small'
        value={bulletPointIconColor}
        colors={colors}
        onChange={changeBulletPointIconColor}
        disabled={checkDisableColor(icons, bulletPointIcon.value) || isViewMode}
        style={{ width: 'unset' }}
        formItemProps={{
          style: { width: '160px', minHeight: 24 },
        }}
        selectProps={{
          size: 'small',
          disabled,
        }}
      />
    </>
  );
}

const Styled = styled('div')({
  '.react-select__value-container': {
    padding: '4px 5px 4px',
    overflowX: 'hidden !important',
  },
});
