import React from 'react';
import { InfoIconComponent } from '../../InfoIconComponent';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { ExternalLinkIcon } from '../../../../../../../../../ui/icons/ExternalLinkIcon';
import styled from 'styled-components';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';

export function ProductHeader({ product, colors, fonts, applyVariable }) {
  const { productPageLink } = useLocalization().translations.unique.pages.questionnaire.content;

  const { windowWidth } = useWindowResize();

  return (
    <Root windowWidth={windowWidth}>
      <StyledHeaderLeft windowWidth={windowWidth}>
        <p
          style={{
            overflow: 'hidden',
            marginBottom: 8,
            fontSize: fonts.headlineSecond.fontSize,
            fontFamily: fonts.headlineSecond.font,
            fontWeight: fonts.headlineSecond.fontWeight,
            color: colors[fonts.headlineSecond.colorVariant],
          }}
        >
          {product.productNameSection.product.title}
        </p>
        <p
          style={{
            maxWidth: 500,
            overflow: 'hidden',
            marginBottom: 0,
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: fonts.body.fontWeight,
            color: colors[fonts.body.colorVariant],
          }}
        >
          {applyVariable(product.productNameSection.subline.value)}
          <InfoIconComponent applyVariable={applyVariable} type={product.productNameSection.subline} />
        </p>
      </StyledHeaderLeft>
      {product.productPageLink && (
        <div>
          <a
            href={product.productPageLink}
            target='_blank'
            style={{
              overflow: 'hidden',
              marginBottom: 0,
              fontSize: fonts.headlineThird.fontSize,
              fontFamily: fonts.headlineThird.font,
              fontWeight: fonts.headlineThird.fontWeight,
              color: colors[fonts.headlineThird.colorVariant],
              display: 'flex',
              alignItems: 'center',
            }}
            rel='noreferrer'
          >
            <AppText textValue={productPageLink} style={{ color: 'inherit' }} />
            <ExternalLinkIcon style={{ marginLeft: 10 }} fill={colors[fonts.headlineThird.colorVariant]} />
          </a>
        </div>
      )}
    </Root>
  );
}

const Root = styled('div')(props => ({
  marginBottom: 25,
  display: props.windowWidth > AppBreakpoint.M ? 'flex' : 'block',
  justifyContent: 'space-between',
}));

const StyledHeaderLeft = styled('div')(props => ({
  marginBottom: props.windowWidth > AppBreakpoint.M ? '0px' : '25px',
}));
