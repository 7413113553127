/* eslint-disable no-magic-numbers,prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { TemplateTypes } from '../../models/TemplateTypes';
import { isTruthy } from '../../util/helpers';
import { QuestionnaireVersion, UpdateStructureType, ViewMode } from '../../models/Questionnaire';
import { DEFAULT_PAGINATION } from '../../util/constants';
import { FinderModal } from '../../../processes/CMS/lib/models/Modal';
import { checkIsTryingEditingPublishedWhenDraftIsDifferent } from '../selectors/findersSelectors';

const MAX_DEPTH_HISTORY = 10;

const initialState = {
  tenant: {},
  tenantLoading: true,
  tenantError: null,
  finder: {},
  finderLoading: true,
  finderError: null,
  finders: [],
  questionnaireStructure: [],
  pastQuestionnaireStructure: [],
  futureQuestionnaireStructure: [],
  pastQSSS: {},
  futureQSSS: {},
  tempQuestionnaireStructure: [],
  selectedQuestionnaireElement: null,
  selectedQuestionnaireIndex: null,
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  isModalLoading: false,
  error: null,
  selectedFinder: {},
  modalType: '',
  isModalOpen: false,
  tenantThemes: [],
  checkSMTPLoading: false,
  templates: [],
  isTemplatesLoading: false,
  isPublishingLoading: false,
  isUploading: false,
  isProductsLoading: true,
  tenantProducts: [],
  currentUserId: null,
  isViewMode: ViewMode.NOT_DEFINED,
  isTemplateLayoutsLoading: false,
  templateLayouts: [],
  selectedQuestionnaireVersion: null,
  updateQuestionnaireStructureLoading: false,
  availableDomains: [],
  finderRules: [],
};

const findersSlice = createSlice({
  name: 'finders',
  initialState,
  reducers: {
    getTenantRequest: state => {
      state.tenant = {};
      state.tenantError = null;
      state.tenantLoading = true;
    },
    getTenantSuccess: (state, action) => {
      state.tenant = action.payload;
      state.tenantLoading = false;
    },
    getTenantError: (state, action) => {
      state.tenantError = action.payload;
    },
    cleanTenant: state => {
      state.tenant = {};
      state.tenantError = null;
    },
    getFinderRequest: state => {
      state.finderError = null;
      state.finderLoading = true;
    },
    getFinderSuccess: (state, action) => {
      state.finder = action.payload.finder;
      state.questionnaireStructure = action.payload.questionnaireStructure;
      state.tenant = { ...state.tenant, ...action.payload.tenant };
      state.finderLoading = false;

      const { blockedByUser } = action.payload.finder;
      const isBlocked = isTruthy(blockedByUser);

      state.isViewMode = isBlocked && state.currentUserId !== blockedByUser?.id;
    },
    getFinderError: (state, action) => {
      state.finderError = action.payload;
    },
    cleanFinder: state => {
      state.finder = {};
      state.questionnaireStructure = [];
      state.selectedQuestionnaireElement = null;
      state.selectedQuestionnaireIndex = null;
      state.finderError = null;
    },
    updateQuestionnaireStructure: (state, { payload }) => {
      // window.location.
      const { structure, updateType } = payload;
      const isTryingEditingPublishedWhenDraftIsDifferent = checkIsTryingEditingPublishedWhenDraftIsDifferent(state);

      if (isTryingEditingPublishedWhenDraftIsDifferent) {
        state.isModalOpen = true;
        state.modalType = FinderModal.CHANGE_QUESTIONNAIRE;
      }

      if (updateType !== UpdateStructureType.NAVIGATOR_TOGGLE) {
        if (state.pastQuestionnaireStructure.length === MAX_DEPTH_HISTORY) {
          state.pastQuestionnaireStructure.shift();
        }
        state.pastQuestionnaireStructure = [...state.pastQuestionnaireStructure, state.questionnaireStructure];
        state.pastQSSS = { ...state.pastQSSS, [state.finder.id]: state.pastQuestionnaireStructure };
      }

      if (state.isViewMode !== ViewMode.DISABLED && updateType !== UpdateStructureType.NAVIGATOR_TOGGLE) {
        return;
      }

      const updatedStructure = structure?.reduce((acc, curr, index, array) => {
        if (
          curr.templateType === TemplateTypes.CHAPTER &&
          acc.every(item => item.templateType !== TemplateTypes.CHAPTER) &&
          !array.some(element => element.templateType === TemplateTypes.INTRO)
        ) {
          acc.splice(1, 0, curr);
        } else if (
          curr.templateType === TemplateTypes.CHAPTER &&
          acc.every(item => item.templateType !== TemplateTypes.CHAPTER) &&
          array.some(element => element.templateType === TemplateTypes.INTRO)
        ) {
          acc.splice(3, 0, curr);
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);

      if (state.finder.questionnaireDraft) {
        state.finder.questionnaireDraft = JSON.stringify(updatedStructure);
      }

      if (isTryingEditingPublishedWhenDraftIsDifferent) {
        state.tempQuestionnaireStructure = updatedStructure;

        return;
      }

      state.questionnaireStructure = updatedStructure;
      state.updateQuestionnaireStructureLoading = true;
    },
    undoHistory: state => {
      const tempPastQuestionnaireStructure = [...state.pastQuestionnaireStructure];
      const tempQuestionnaireStructure = [...state.questionnaireStructure];
      state.pastQuestionnaireStructure = state.pastQuestionnaireStructure.slice(0, -1);
      state.questionnaireStructure = tempPastQuestionnaireStructure.at(-1);
      state.futureQuestionnaireStructure = [tempQuestionnaireStructure, ...state.futureQuestionnaireStructure];

      state.pastQSSS = { ...state.pastQSSS, [state.finder.id]: state.pastQuestionnaireStructure };
      state.futureQSSS = { ...state.futureQSSS, [state.finder.id]: state.futureQuestionnaireStructure };
    },
    redoHistory: state => {
      state.pastQuestionnaireStructure = [...state.pastQuestionnaireStructure, state.questionnaireStructure];
      state.questionnaireStructure = state.futureQuestionnaireStructure[0];
      state.futureQuestionnaireStructure = state.futureQuestionnaireStructure.slice(1);

      state.pastQSSS = { ...state.pastQSSS, [state.finder.id]: state.pastQuestionnaireStructure };
      state.futureQSSS = { ...state.futureQSSS, [state.finder.id]: state.futureQuestionnaireStructure };
    },
    updateQuestionnaireStructureSuccess: (state, { payload }) => {
      state.finder = payload;
      state.isViewMode = ViewMode.DISABLED;
      state.updateQuestionnaireStructureLoading = false;
    },
    updateQuestionnaireStructureError: state => {
      state.updateQuestionnaireStructureLoading = false;
    },
    // load finder list
    loadFinderListRequest: state => {
      state.error = null;
      state.loading = true;
      state.finders = [];
    },
    loadFinderListSuccess: (state, action) => {
      state.finders = action.payload.finders;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    loadFinderListError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setFinderVariable: (state, action) => {
      state.finder = {
        ...state.finder,
        customVariables: action.payload,
      };
    },
    //
    setFinderStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setFinderStatusSuccess: state => {
      state.loading = false;
    },
    setFinderStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openFinderModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedFinder = action.payload.selectedFinder || {};
    },
    closeFinderModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedFinder = {};
    },
    addFinderRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    addFinderSuccess: state => {
      state.isModalLoading = false;
    },
    addFinderError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editFinderRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    editFinderSuccess: state => {
      state.isModalLoading = false;
    },
    editFinderError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editFinderGeneralSettingsRequest: state => {
      state.error = null;
      state.loading = true;
    },
    editFinderGeneralSettingsSuccess: (state, action) => {
      state.loading = false;
      state.finder = action.payload.finder;
    },
    editFinderGeneralSettingsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    editFinderDesignSettingsRequest: state => {
      state.error = null;
      state.loading = true;
    },
    editFinderDesignSettingsSuccess: (state, action) => {
      state.loading = false;
      state.finder = action.payload.finder;
    },
    editFinderDesignSettingsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteFinderRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteFinderSuccess: state => {
      state.isModalLoading = false;
    },
    deleteFinderError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    duplicateFinderRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    duplicateFinderSuccess: state => {
      state.isModalLoading = false;
    },
    duplicateFinderError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    getTenantThemesRequest: state => {
      state.tenantThemes = [];
      state.error = null;
    },
    getTenantThemesSuccess: (state, action) => {
      state.tenantThemes = action.payload;
    },
    getTenantThemesError: (state, action) => {
      state.error = action.payload;
    },
    uploadMediaRequest: state => {
      state.error = null;
      state.isUploading = true;
    },
    uploadMediaSuccess: state => {
      state.isUploading = false;
    },
    uploadMediaError: (state, action) => {
      state.error = action.payload;
      state.isUploading = false;
    },
    checkSMTPRequest: state => {
      state.error = null;
      state.checkSMTPLoading = true;
    },
    checkSMTPSuccess: state => {
      state.checkSMTPLoading = false;
    },
    checkSMTPError: (state, action) => {
      state.error = action.payload;
      state.checkSMTPLoading = false;
    },
    publishFinderRequest: state => {
      state.error = null;
      state.isPublishingLoading = true;
    },
    publishFinderSuccess: (state, { payload }) => {
      state.isPublishingLoading = false;
      state.finder = payload.finder;
      state.questionnaireStructure = payload.questionnaireStructure;
    },
    publishFinderError: (state, action) => {
      state.error = action.payload;
      state.isPublishingLoading = false;
    },

    /* arranges */
    // apply arranges
    applyFinderArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyFinderArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeFinderFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeFinderFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeFinderArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
    getFinderTemplatesRequest: state => {
      state.templates = [];
      state.isTemplatesLoading = true;
      state.error = null;
    },
    getFinderTemplatesSuccess: (state, action) => {
      state.templates = action.payload;
      state.isTemplatesLoading = false;
    },
    getFinderTemplatesError: (state, action) => {
      state.error = action.payload;
      state.isTemplatesLoading = false;
    },
    saveQuestionnaireStructureError: (state, { payload }) => {
      state.error = payload;
      state.updateQuestionnaireStructureLoading = false;

      if (payload?.error?.exceptionCode === 'ERROR_028') {
        state.isViewMode = ViewMode.ENABLED;
      }
    },
    selectQuestionnaireElement: (state, { payload }) => {
      state.selectedQuestionnaireElement = payload.item;
      state.selectedQuestionnaireElementIndex = payload.index;
    },
    getTenantProductsRequest: state => {
      state.tenantProducts = [];
      state.isProductsLoading = true;
      state.error = null;
    },
    getTenantProductsSuccess: (state, action) => {
      state.tenantProducts = action.payload;
      state.isProductsLoading = false;
    },
    getTenantProductsError: (state, action) => {
      state.error = action.payload;
      state.isProductsLoading = false;
    },
    setCurrentUserId: (state, { payload }) => {
      state.currentUserId = payload;
    },
    getTemplateLayoutsRequest: state => {
      state.templateLayouts = [];
      state.isTemplateLayoutsLoading = true;
      state.error = null;
    },
    getTemplateLayoutsSuccess: (state, action) => {
      state.templateLayouts = action.payload;
      state.isTemplateLayoutsLoading = false;
    },
    getTemplateLayoutsError: (state, action) => {
      state.error = action.payload;
      state.isTemplateLayoutsLoading = false;
    },
    submitCanChangePublished: state => {
      state.questionnaireStructure = state.tempQuestionnaireStructure;
      state.selectedQuestionnaireVersion = QuestionnaireVersion.DRAFT;
      state.tempQuestionnaireStructure = [];
    },
    setSelectedQuestionnaireVersion: (state, { payload }) => {
      state.selectedQuestionnaireVersion = payload;
    },
    setQuestionnaireBasedOnVersion: (state, { payload }) => {
      state.selectedQuestionnaireVersion = payload;
      const structureVersion = payload === QuestionnaireVersion.DRAFT ? state.finder.questionnaireDraft : state.finder.questionnaire;
      state.questionnaireStructure = JSON.parse(structureVersion);
      state.selectedQuestionnaireElement = null;
      state.selectedQuestionnaireIndex = null;
    },
    setPastQuestionnaireStructure: (state, action) => {
      state.pastQuestionnaireStructure = action.payload || [];
    },
    setFutureQuestionnaireStructure: (state, action) => {
      state.futureQuestionnaireStructure = action.payload || [];
    },
    setQuestionnaireActivityRequest: () => {},
    //
    getTenantsDomainsRequest: () => {
      //
    },
    getTenantDomainsSuccess: (state, action) => {
      state.availableDomains = action.payload;
    },
    getTenantDomainsError: (state, action) => {
      state.error = action.payload;
    },
    getFinderRulesRequest: state => {
      state.finderRules = [];
      state.error = null;
    },
    getFinderRulesSuccess: (state, action) => {
      state.finderRules = action.payload;
    },
    getFinderRulesError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getFinderRequest,
  getFinderSuccess,
  getFinderError,
  cleanFinder,
  getTenantRequest,
  getTenantSuccess,
  getTenantError,
  cleanTenant,
  loadFinderListRequest,
  loadFinderListSuccess,
  loadFinderListError,
  setFinderVariable,
  setFinderStatusRequest,
  setFinderStatusSuccess,
  setFinderStatusError,
  openFinderModal,
  closeFinderModal,
  addFinderRequest,
  addFinderSuccess,
  addFinderError,
  editFinderRequest,
  editFinderSuccess,
  editFinderDesignSettingsError,
  editFinderDesignSettingsRequest,
  editFinderDesignSettingsSuccess,
  editFinderGeneralSettingsError,
  editFinderGeneralSettingsRequest,
  editFinderGeneralSettingsSuccess,
  editFinderError,
  deleteFinderRequest,
  deleteFinderSuccess,
  deleteFinderError,
  duplicateFinderRequest,
  duplicateFinderSuccess,
  duplicateFinderError,
  getTenantThemesRequest,
  getTenantThemesSuccess,
  getTenantThemesError,
  uploadMediaError,
  uploadMediaRequest,
  uploadMediaSuccess,
  checkSMTPRequest,
  checkSMTPSuccess,
  checkSMTPError,
  updateQuestionnaireStructure,
  updateQuestionnaireStructureSuccess,
  publishFinderError,
  publishFinderRequest,
  publishFinderSuccess,
  applyFinderArrangesRequest,
  applyFinderArrangesError,
  removeFinderFiltersRequest,
  removeFinderFiltersError,
  removeFinderArranges,
  getFinderTemplatesRequest,
  getFinderTemplatesSuccess,
  getFinderTemplatesError,
  saveQuestionnaireStructureError,
  selectQuestionnaireElement,
  getTenantProductsRequest,
  getTenantProductsSuccess,
  getTenantProductsError,
  setCurrentUserId,
  getTemplateLayoutsRequest,
  getTemplateLayoutsSuccess,
  getTemplateLayoutsError,
  undoHistory,
  redoHistory,
  setPastQuestionnaireStructure,
  setFutureQuestionnaireStructure,
  setQuestionnaireBasedOnVersion,
  submitCanChangePublished,
  setSelectedQuestionnaireVersion,
  setQuestionnaireActivityRequest,
  getTenantsDomainsRequest,
  getTenantDomainsSuccess,
  getFinderRulesRequest,
  getFinderRulesSuccess,
  getFinderRulesError,
} = findersSlice.actions;
export const finders = findersSlice.reducer;
