import { SortProductsBy } from './SortProductsBy';
import { ProductInformation } from './ProductInformation';

export function ResultTable({ item, isViewMode, structureItemIndex, onChange }) {
  return (
    item?.content?.productInformation && (
      <>
        <ProductInformation item={item} isViewMode={isViewMode} onChange={onChange('productInformation')} />
        <SortProductsBy sortProductsBy={item.content?.sortProductsBy} structureItemIndex={structureItemIndex} onChange={onChange('sortProductsBy')} />
      </>
    )
  );
}
