import { useNavigate, useParams } from 'react-router-dom';
import { AppContent } from '../../../../../../ui/AppContent';
import { AppTitle } from '../../../../../../ui/text/AppTitle';
import { AddTableItemButton } from '../../features/AddTableItemButton';
import { RulesTable } from './RulesTable';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { RuleModals } from './RuleModals';
import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';
import { selectRule } from '../../../../../../lib/store/slices/rulesSlice';

/**
 * @desc Component for displaying the rules page, including the rules table and modals for deleting and duplicating rules.
 * @returns {JSX.Element} The JSX for the RulesPage component.
 */
export function RulesPage() {
  const { rules } = useLocalization().translations.unique.pages;
  const navigate = useNavigate();
  const { tenant } = useSelector(({ finders }) => finders);
  const { id } = useParams();
  const dispatch = useDispatch();

  const addNewRule = () => {
    navigate(`${ROUTER_PATHS.builder(id)}?X-TENANT=${tenant.id}`);
    dispatch(selectRule({}));
  };

  return (
    <AppContent>
      <RuleModals />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24 }}>
        <AppTitle level={3} style={{ marginBottom: 0 }} textValue={rules.title} />
        <AddTableItemButton text={rules.addButton} styles='table_btn__lg table_btn__tab' onClick={addNewRule} />
      </div>

      <RulesTable />
    </AppContent>
  );
}
