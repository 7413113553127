import { useLocalization } from '../lib/hooks/useLocalization';
import { LinkFormItem } from './LinkFormItem/LinkFormItem';
import { Product } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/lib/Product';
import { useIsExceptional } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/modules/products/ProductModals/lib/useIsExceptional';

export function ProductLinkFormItem(props) {
  const { formItemProps = {}, textFieldProps } = props;
  const { rules = [], ...restFormItemProps } = formItemProps;
  const { label } = useLocalization().translations.unique.formItems.productLink;
  const isExceptional = useIsExceptional();

  return (
    <LinkFormItem
      formItemProps={{
        name: Product.PRODUCT_PAGE_LINK,
        rules: [{ required: !isExceptional }].concat(rules),
        ...restFormItemProps,
      }}
      textFieldProps={{ label, disabled: isExceptional, ...textFieldProps }}
    />
  );
}
