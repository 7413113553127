import styled from 'styled-components';
import { ChapterState, TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { getBackgroundForChapterLine } from './utils';

/**
 * @des Component for rendering the chapter line.
 * @param {Array} newAllChapters - Array of all chapters.
 * @param {number} progressPercentage - The progress percentage.
 * @param {number} allDistance - Total distance for progress.
 * @param {number} numberOfChapters - Total number of chapters.
 * @param {string} progressBarColor - Color of the progress bar.
 * @param {string} oppositeColorForProgressBar - Opposite color for the progress bar.
 * @param {Object} currentChapter - The current chapter.
 * @param {Object} currentFlowElement - The current flow element.
 * @returns {JSX.Element} - The rendered chapter line component.
 */
export function ChapterLine({
  newAllChapters,
  progressPercentage,
  allDistance,
  numberOfChapters,
  progressBarColor,
  oppositeColorForProgressBar,
  currentChapter,
  currentFlowElement,
}) {
  return (
    <Root>
      {newAllChapters.map((chapter, idx) => {
        const position = (idx + 1) * (allDistance / numberOfChapters);
        return (
          <StyledChapterLine
            key={chapter.id}
            isChapterMissed={progressPercentage >= position && (chapter.state === ChapterState.OPENED || chapter.state === ChapterState.NOT_OPENED)}
            isChapterCompleted={
              chapter.state === ChapterState.COMPLETED &&
              (currentFlowElement?.templateType !== TemplateTypes.CHAPTER ? currentChapter?.id !== chapter?.id : true)
            }
            progressBarColor={progressBarColor}
            oppositeColorForProgressBar={oppositeColorForProgressBar}
          />
        );
      })}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledChapterLine = styled('div')(props => ({
  position: 'relative',
  marginTop: '-5px',
  height: '5px',
  width: '100%',
  marginLeft: 9.5,
  marginRight: 9.5,
  backgroundColor: getBackgroundForChapterLine(props),
}));
