import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';
import { useProductPriceColumn } from '../../components/price';

export function useProductPricePerYearColumn() {
  const { yearPrice: title } = useLocalization().translations.unique.pages.products.table.columns;

  return useProductPriceColumn({
    title,
    arrangedId: Product.PRICE_PER_YEAR,
    columnsProps: {
      width: 140,
    },
  });
}
