import { TemplateTypes } from '../../../../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../../../../lib/util/helpers';
import { ElementNameWithLabel } from '../../../../../../../features/ElementNameWithLabel';
import { useSelector } from 'react-redux';
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';

export function QuestionSelect({ question, elementId, additionalInformation, onChange }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const questionOptions = questionnaireStructure
    ?.filter(el => el.templateType === TemplateTypes.QUESTION)
    ?.map(element => {
      const elementIdByType = getIndexByTemplateType(questionnaireStructure, element);

      return {
        content: element.content,
        value: element.id,
        title: (
          <ElementNameWithLabel
            templateType={element.templateType}
            elementIdByType={elementIdByType}
            label={element.content?.question?.value || element.label}
            minWidthElementLabel={25}
            minWidthAppText={35}
          />
        ),
      };
    });

  const handleChangeQuestion = (value, content) => {
    const result = additionalInformation.map(item =>
      item.id === elementId ? { ...item, question: { value }, answer: { value: '' }, content } : item
    );
    onChange(result);
  };

  const getQuestionValue = questionObject => {
    if (questionOptions.length === 1 && !questionObject.value) {
      handleChangeQuestion(questionOptions[0]?.value, questionOptions[0].content);
    }
    if (questionOptions.length > 1 && !questionObject.value) {
      return {};
    }
    return questionObject;
  };

  return (
    <QuestionnaireSelect
      selectProps={{
        value: getQuestionValue(question),
        onChange: selected => handleChangeQuestion(selected.value, selected.content),
        options: questionOptions,
        isOverflowHidden: true,
      }}
    />
  );
}
