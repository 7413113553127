import { FilterTitle } from './FilterTitle';

export function FilterBlockWrapper({ children }) {
  return (
    <div>
      <FilterTitle />
      {children}
    </div>
  );
}
