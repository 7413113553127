/* eslint-disable no-param-reassign */
import styled from 'styled-components';
import { AppBasicButton } from '../../../../../../../../../../ui/buttons/AppBasicButton';
import { useContentTable } from '../hooks/useContentTable';

function getBoldValue(tableRows, rowIndex, columnIndex) {
  return tableRows[rowIndex].cells[columnIndex].isBold;
}

export function BoldButton({ columnIndex, rowIndex }) {
  const { contentTableState } = useContentTable();
  const isBold = Boolean(getBoldValue(contentTableState.tableRows, rowIndex, columnIndex));

  const toggleBold = () => {
    contentTableState.updateTable(({ tableRows }) => {
      const prevBoldValue = getBoldValue(tableRows, rowIndex, columnIndex);
      tableRows[rowIndex].cells[columnIndex].isBold = !prevBoldValue;
    });
  };

  return (
    <Root onClick={toggleBold} isBold={isBold}>
      <div>B</div>
    </Root>
  );
}

const Root = styled(AppBasicButton)(({ isBold }) => ({
  width: '43px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > *': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    ...(isBold && {
      background: '#5C5C6E',
    }),
  },
}));
