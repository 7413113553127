import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import {
  clearListFilters,
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../../../../utils/arranges-helpers';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation, isLength } from '../../../../../../../../../../../../lib/util/helpers';
import { useEnumArrange } from '../../../../../../../../utils/hooks/arranges-by-type/useEnumArrange';
import { useStatusColumn } from '../../../../../../../../components/columns/status';
import { Finder, finderPublishingStatuses, FinderStatus, FinderTableColumn } from '../../lib/Finder';
import { useFinderArrangeRequests } from '../../lib/useFinderArrangeRequests';
import { FinderStatusBodyCell } from './FinderStatusBodyCell';

const arrangedId = FinderTableColumn.STATUS;
const pseudoAllStatuses = [];
const pseudoAllFilters = {};

export function useFinderStatusColumn() {
  const { translations } = useLocalization();
  const { status: sharedStatusText } = translations.shared;
  const { statuses: finderStatusesText } = translations.unique.pages.finders.table;
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const [form] = Form.useForm();
  const isActiveArrange = activeArrangedId === arrangedId;
  const getItemsValues = () => getSelectedItemsValues(form);
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const publishedLabel = handleTranslation(finderStatusesText.published);
  const notPublishedLabel = handleTranslation(finderStatusesText.notPublished);
  const deactivatedLabel = handleTranslation(sharedStatusText.deactivated);

  const allOptions = [
    createOptionItem(publishedLabel, FinderStatus.PUBLISHED),
    createOptionItem(notPublishedLabel, FinderStatus.NOT_PUBLISHED),
    createOptionItem(deactivatedLabel, FinderStatus.DEACTIVATED),
  ];

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const activated = isLength(itemsValues) ? !itemsValues.includes(FinderStatus.DEACTIVATED) : undefined;
    const selectedStatuses = itemsValues.filter(item => finderPublishingStatuses.includes(item));
    const allStatusesAreSelected = selectedStatuses.length === finderPublishingStatuses.length;
    const status = allStatusesAreSelected ? pseudoAllStatuses : selectedStatuses[0];
    return activated === false && allStatusesAreSelected ? pseudoAllFilters : { status, activated };
  };

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: [Finder.ACTIVATED, Finder.STATUS],
    clearFilters: () => clearListFilters(form),
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      dataIndex: Finder.ACTIVATED,
      render: (value, row) => {
        const status = value ? row.status : value;
        return <FinderStatusBodyCell status={status} />;
      },
    },
  });
}
