import { checkIsMobile } from '../../../../../../../../../lib/util/Adaptive';
import { HtmlNodeId } from '../../../../../../../../../lib/util/HtmlNodeId';
import { ProductCardId } from '../../../models/Hardcode';
import { checkMustBeUnselected } from '../../../utils';
import { isTruthy } from '../../../../../../../../../lib/util/helpers';

const LEF_PADDING = 16;
const DEFAULT_CARD_WIDTH = 460;
const NON_DESKTOP_CARD_WIDTH = 366;

export function getCenterPadding(products, windowWidth) {
  const defaultValue = 0;

  if (products.length === 1) {
    let cardWidth = DEFAULT_CARD_WIDTH;

    // eslint-disable-next-line no-magic-numbers
    if (windowWidth <= 1200) {
      cardWidth = NON_DESKTOP_CARD_WIDTH;
    }

    // eslint-disable-next-line no-magic-numbers
    return windowWidth / 2 - cardWidth / 2 - LEF_PADDING;
  }

  return defaultValue;
}

export const CAROUSEL_MAIN_SETTINGS = {
  dots: false,
  infinite: false,
  centerMode: true,
  speed: 500,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
};

export function maybeScrollToCardOnMobile(windowWidth, cardIndex) {
  if (!checkIsMobile(windowWidth)) return;

  const all = document.querySelector(`#${HtmlNodeId.PRODUCTS_CAROUSEL}`);
  const node = all?.children?.[cardIndex];

  if (node) {
    node?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
  }
}

const rewriteSelectProducts = (products, isSelect) =>
  products.map(card => {
    if (checkMustBeUnselected(card)) {
      return { ...card, selected: false };
    }

    if (ProductCardId.HAMBURGER_PK_P4_1 === card?.id) {
      return { ...card, selected: isSelect };
    }

    return { ...card, selected: !isSelect };
  });

export const makeConfirmSelection =
  ({ products, scrollToCard, updateHardcodeFlowStructure, cardId }) =>
  () => {
    const itemIndex = products.findIndex(item => item.id === cardId);
    scrollToCard(itemIndex);

    const updatedSelectedProducts = rewriteSelectProducts(products, true);
    const selectedProducts = updatedSelectedProducts.filter(item => item.selected);
    updateHardcodeFlowStructure(updatedSelectedProducts, selectedProducts);
  };

// By default, `selected` is `undefined` (or other falsy value). So here is checking whether the value is set.
export const checkIsUserChangedSelection = selected => isTruthy(selected);
