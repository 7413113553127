import { LayoutNumberHeaderCell } from './LayoutNumberHeaderCell';
import tableStyles from '../../../../../../utils/styles/table.module.sass';
import { AmountBodyCell } from '../../../../../../components/AmountBodyCell';
import classNames from 'classnames';

export function useLayoutNumberColumn(props) {
  const { columnProps, bodyCellProps = {}, ...rest } = props;
  const { className, ...restColumnProps } = columnProps;

  return {
    ...restColumnProps,
    dataIndex: props.arrangedId,
    className: classNames(tableStyles.numberBodyColumn, className),
    title: <LayoutNumberHeaderCell {...rest} />,
    render: amount => <AmountBodyCell amount={amount} {...bodyCellProps} />,
  };
}
