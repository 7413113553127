/* eslint-disable no-param-reassign */
import { useUpdateStructure } from './useUpdateStructure';

export function useChangeCardContentField(structureItemIndex) {
  const { updateStructure } = useUpdateStructure();

  const changeField = fieldName => data => {
    updateStructure(draft => {
      draft[structureItemIndex].content[fieldName] = data;
    });
  };

  return { changeField };
}
