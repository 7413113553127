import styled from 'styled-components';

export function CardSectionHeader(props) {
  return <Root {...props} />;
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px',
});
