import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { TemplateTypes } from '../../models/TemplateTypes';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  list: [],
  activeArrangedId: null,
  category: TemplateTypes.INTRO,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  isModalLoading: false,
  error: null,
  currentItem: {},
  modalType: '',
  isModalOpen: false,
  parentItem: {},
};

const slice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    setCurrentLayout: (state, action) => {
      state.currentItem = action.payload;
    },
    getLayoutParentRequest: state => {
      state.parentItem = {};
      state.error = null;
      state.loading = true;
    },
    getLayoutParentSuccess: (state, action) => {
      state.parentItem = action.payload;
      state.loading = false;
    },
    getLayoutParentError: (state, action) => {
      state.error = action.payload;
    },
    getLayoutListRequest: state => {
      state.error = null;
      state.loading = true;
      state.list = [];
    },
    getLayoutListSuccess: (state, action) => {
      state.list = action.payload.list;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getLayoutListError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setLayoutStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setLayoutStatusSuccess: state => {
      state.loading = false;
    },
    setLayoutStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    addLayoutRequest: state => {
      state.error = null;
      state.loading = true;
    },
    addLayoutSuccess: state => {
      state.loading = false;
    },
    addLayoutError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    reUploadLayoutRequest: state => {
      state.error = null;
      state.loading = true;
    },
    reUploadLayoutSuccess: state => {
      state.loading = false;
    },
    reUploadLayoutError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openLayoutModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.currentItem = action.payload.currentItem || {};
    },
    closeLayoutModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.currentItem = {};
    },
    deleteLayoutRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteLayoutSuccess: state => {
      state.isModalLoading = false;
    },
    deleteLayoutError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    /* arranges */
    // apply arranges
    applyLayoutsArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyLayoutsArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeLayoutsFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeLayoutsFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeLayoutsArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getLayoutListRequest,
  getLayoutListSuccess,
  getLayoutListError,
  setLayoutStatusRequest,
  setLayoutStatusSuccess,
  setLayoutStatusError,
  addLayoutRequest,
  addLayoutSuccess,
  addLayoutError,
  reUploadLayoutRequest,
  reUploadLayoutSuccess,
  reUploadLayoutError,
  openLayoutModal,
  closeLayoutModal,
  deleteLayoutRequest,
  deleteLayoutSuccess,
  deleteLayoutError,
  applyLayoutsArrangesRequest,
  applyLayoutsArrangesError,
  removeLayoutsFiltersRequest,
  removeLayoutsFiltersError,
  removeLayoutsArranges,
  getLayoutParentRequest,
  getLayoutParentSuccess,
  getLayoutParentError,
  setCurrentLayout,
} = slice.actions;
export const layouts = slice.reducer;
