import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronUpIcon } from '../../../../../../../../../ui/icons/ChevronUpIcon';
import { ComposerChildren } from './ComposerChildren/ComposerChildren';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';

const getDroppableIdAndType = value => {
  if (value === 'page') {
    return 'COMPOSER';
  }
  if (value === 'infoIcon') {
    return 'INFO_ICON';
  }
  if (value === 'composerButton') {
    return 'COMPOSER_BUTTON';
  }
  return '-';
};

/**
 * @desc Composer content: all the elements for building a questionnaire (Page, Info icon, Button, Intro, Chapter, Content, Question, Product, Result, Contact form)
 * @param {Array} composerItems
 * @returns {JSX.Element}
 */

export function ComposerContent({ composerItems }) {
  const [modifiedComposerItems, setModifiedComposerItems] = useState(composerItems);
  const [droppableState, setDroppableState] = useState(null);
  const { isViewMode } = useSelector(({ finders }) => finders);

  /**
   * @desc Function opens/closes menu items
   * @param {String} menuItemId
   */
  const handleToggleVisible = menuItemId => {
    setModifiedComposerItems(
      modifiedComposerItems?.map(item => {
        if (item.id === menuItemId) {
          return { ...item, isOpen: !item.isOpen };
        }
        return item;
      })
    );
  };

  /**
   * @desc Function set active droppableId and type for Droppable component
   * @param {String} composerItemKey
   */
  const handleMouseEnter = composerItemKey => {
    setDroppableState(composerItemKey);
  };

  return (
    <Droppable droppableId={getDroppableIdAndType(droppableState)} isDropDisabled type={getDroppableIdAndType(droppableState)}>
      {(provided, snapshot) => (
        <Items ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
          {modifiedComposerItems?.map((composerItem, composerIndex) => (
            <Draggable
              key={composerItem.id}
              draggableId={composerItem.id}
              index={composerIndex}
              isDragDisabled={(composerItem.key !== 'page' && composerItem.key !== 'infoIcon' && composerItem.key !== 'composerButton') || isViewMode}
            >
              {(providedItem, snapshotItem) => (
                <>
                  <AppBox
                    as={Item}
                    disabled={composerItem.children ? false : isViewMode}
                    ref={providedItem.innerRef}
                    {...providedItem.draggableProps}
                    {...providedItem.dragHandleProps}
                    isDragging={snapshotItem.isDragging}
                    onClick={() => handleToggleVisible(composerItem.id)}
                    onMouseEnter={() =>
                      composerItem.key === 'page' || composerItem.key === 'infoIcon' || composerItem.key === 'composerButton'
                        ? handleMouseEnter(composerItem.key)
                        : null
                    }
                    style={{
                      ...providedItem.draggableProps.style,
                      transform: snapshotItem.isDragging ? providedItem.draggableProps.style?.transform : 'translate(0px, 0px)',
                      marginBottom: 5,
                    }}
                  >
                    {composerItem.children && (
                      <ChevronUpIcon style={{ marginRight: 10, transform: !composerItem.isOpen && 'scaleY(-1)', transition: '0.2s transform' }} />
                    )}
                    {composerItem.label}
                  </AppBox>
                  {snapshotItem.isDragging && <Item>{composerItem.label}</Item>}
                  {composerItem.isOpen && <ComposerChildren composerItem={composerItem} composerIndex={composerIndex} />}
                </>
              )}
            </Draggable>
          ))}
        </Items>
      )}
    </Droppable>
  );
}

const Items = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  height: 'calc(100vh - 185px)',
  overflowY: 'auto',
});

const Item = styled('li')({
  display: 'flex',
  alignItems: 'center',
  background: '#404055',
  color: '#fff',
  fontSize: 16,
  lineHeight: '18px',
  fontWeight: 700,
  padding: '11px 20px',
  marginBottom: 5,
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(25, 25, 25, 0.06);',
  },
});
