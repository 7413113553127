import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppCheckbox } from '../../../../../../ui/AppCheckbox';
import { AppFormSection } from '../../../../../../ui/AppFormSection';

export function DefaultCounter({ checked, value, disabled, onToggleDefaultCounter, onChangeDefaultCounterValue }) {
  const { addDefaultStateText, enterStateText } = useLocalization().translations.unique.formItems;

  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <StyledWrapper>
      <AppCheckbox
        onChange={() => onToggleDefaultCounter(checked)}
        checked={checked}
        textValue={addDefaultStateText}
        disabled={isViewMode || disabled}
      />
      <AppFormSection
        style={{ width: 176 }}
        formItemProps={{ style: { minHeight: 50 }, hasFeedback: false }}
        textFieldProps={{
          value,
          onChange: value => onChangeDefaultCounterValue(Number(value)),
          label: enterStateText,
          disabled: isViewMode || !checked || disabled,
          isNumber: true,
        }}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
});
