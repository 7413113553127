/* eslint-disable no-param-reassign */

import { addColumn, ComparisonTableCellKind, getCellsAmount } from '../../comparison-table-utils';
import { isFalsy, isIndexFound } from '../../../../../../../../../../../lib/util/helpers';
import { DEFAULT_NAME_CELL_INDEX, findCustomNameIndexFromRow } from './ProductFieldsSelect-helpers';

const DELETE_EMPTY_ROWS_AMOUNT = 1;

function updateNameCell(cell, field) {
  cell.value = field?.label;
  cell.productFieldName = field?.productFieldName;
  cell.kind = ComparisonTableCellKind.NAME;
}

function updateValueCell(cell, field, selectedProduct) {
  cell.value = field?.value;
  cell.productFieldName = field?.productFieldName;
  cell.productId = selectedProduct.id;
  cell.kind = ComparisonTableCellKind.VALUE;
}

function clearValueCell(cell) {
  cell.value = undefined;
  cell.productFieldName = undefined;
  cell.kind = undefined;
  cell.productId = undefined;
}

const checkShouldDeleteRow = row => row?.cells.filter(item => item.kind !== ComparisonTableCellKind.NAME).every(item => isFalsy(item.value));
const getValueCell = (row, columnIndex) => row.cells[columnIndex];

function getNameCellIndex(row, columnIndex) {
  const customNameIndex = findCustomNameIndexFromRow(row);
  const tempIndex = isIndexFound(customNameIndex) ? customNameIndex : DEFAULT_NAME_CELL_INDEX;
  const nextIndex = tempIndex + 1;

  return tempIndex === columnIndex ? nextIndex : tempIndex;
}

function getNameCell(row, columnIndex) {
  const nameCellIndex = getNameCellIndex(row, columnIndex);

  return row.cells[nameCellIndex];
}

function removeCompletedFieldFromIterations(selectedFields, startIndex) {
  selectedFields.splice(startIndex, 1);
}

function fillNameCellAndValueCell(tableRows, row, nameCell, valueCell, selectedFields, selectedProduct) {
  const firstFieldIndex = 0;
  const firstField = selectedFields[firstFieldIndex];

  // If no "value" column
  if (getCellsAmount(tableRows) === 1) {
    addColumn(tableRows, 'left');
    nameCell = getNameCell(row, 0);
    valueCell = getValueCell(row, 1);
  }

  updateValueCell(valueCell, firstField, selectedProduct);
  updateNameCell(nameCell, firstField);

  removeCompletedFieldFromIterations(selectedFields, firstFieldIndex);
}

function unselectField(tableRows, row, valueCell, iteratedRowIndex) {
  clearValueCell(valueCell);

  if (checkShouldDeleteRow(row)) {
    tableRows.splice(iteratedRowIndex, DELETE_EMPTY_ROWS_AMOUNT);
  }
}

function fillValueCellBasedOnNameCell(selectedFields, matchedSelectedFieldIndex, valueCell, selectedProduct) {
  const field = selectedFields[matchedSelectedFieldIndex];
  updateValueCell(valueCell, field, selectedProduct);

  removeCompletedFieldFromIterations(selectedFields, matchedSelectedFieldIndex);
}

export function fillSelectedFieldsInExistedRows(tableRows, selectedFields, rowIndex, columnIndex, selectedProduct) {
  for (const [iteratedRowIndex, row] of tableRows.entries()) {
    if (iteratedRowIndex === rowIndex) {
      continue;
    }

    const nameCell = getNameCell(row, columnIndex);
    const valueCell = getValueCell(row, columnIndex);
    const matchedSelectedFieldIndex = selectedFields.findIndex(item => item.productFieldName === nameCell?.productFieldName);
    const canFillValueCellBasedOnNameCell =
      nameCell?.value && (isFalsy(valueCell.productId) || valueCell.productId === selectedProduct.id) && isIndexFound(matchedSelectedFieldIndex);
    const canFillNameCellAndValueCell = isFalsy(nameCell?.value) && isFalsy(valueCell.value);
    const shouldUnselectField =
      valueCell.productId === selectedProduct.id && !selectedFields.some(item => item.productFieldName === valueCell.productFieldName);

    switch (true) {
      case canFillValueCellBasedOnNameCell: {
        fillValueCellBasedOnNameCell(selectedFields, matchedSelectedFieldIndex, valueCell, selectedProduct);
        break;
      }
      case canFillNameCellAndValueCell: {
        fillNameCellAndValueCell(tableRows, row, nameCell, valueCell, selectedFields, selectedProduct);
        break;
      }
      case shouldUnselectField: {
        unselectField(tableRows, row, valueCell, iteratedRowIndex);
        break;
      }
      default:
        break;
    }
  }
}
