import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsRequest, removeProductArranges } from '../../../../../../lib/store/slices/productsSlice';
import { normalizePageForRequest } from '../../../../../../lib/util/helpers';
import { AppTable } from '../../../../../../ui/AppTable';
import { useProductActionsColumn } from './columns/actions';
import { useProductNameColumn } from './columns/name';
import { useProductHeadlineColumn } from './columns/headline';
import { useProductSublineColumn } from './columns/subline';
import { useProductLinkColumn } from './columns/link';
import { useProductImageLink } from './columns/image-link';
import { useProductPricePerYearColumn } from './columns/price-per-year';
import { useProductPricePerMonthColumn } from './columns/price-per-month';
import { productCategoryColumn } from './columns/category';
import { useSearchParams } from 'react-router-dom';
import { useProductShortNameColumn } from './columns/short-name';

export function ProductsTable(props) {
  const dispatch = useDispatch();
  const { products: dataSource, pagination, loading } = useSelector(({ products }) => products);
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));
  const { modifyColumns, ...rest } = props;

  useEffect(() => {
    dispatch(removeProductArranges());
    dispatch(getProductsRequest({ tenantId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    productCategoryColumn,
    useProductNameColumn(),
    useProductShortNameColumn(),
    useProductHeadlineColumn(),
    useProductSublineColumn(),
    useProductLinkColumn(),
    useProductPricePerYearColumn(),
    useProductPricePerMonthColumn(),
    useProductImageLink(),
    useProductActionsColumn(),
  ];

  return (
    <AppTable
      columns={modifyColumns ? modifyColumns(columns) : columns}
      dataSource={dataSource}
      pagination={pagination}
      loading={loading}
      onChange={tablePagination => dispatch(getProductsRequest({ params: { page: normalizePageForRequest(tablePagination.current) } }))}
      {...rest}
    />
  );
}
