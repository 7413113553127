import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LayoutsTable } from './components/LayoutsTable';
import { AddTableItemButton } from '../../features/AddTableItemButton';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { LayoutModals } from './components/LayoutModals';
import { AppContent } from '../../../../../../ui/AppContent';
import { FileLoader } from '../../features/FileLoader';
import { addLayoutRequest, getLayoutParentRequest } from '../../../../../../lib/store/slices/layoutsSlice';
import { useParams } from 'react-router-dom';
import { usePermissions } from 'src/lib/hooks/usePermissions';

/**
 * @desc Layouts page
 * @returns {JSX.Element}
 */
export function LayoutsPage() {
  const { isRestricted } = usePermissions();
  const dispatch = useDispatch();
  const { addButton } = useLocalization().translations.unique.pages.layouts;
  const { id } = useParams();

  useEffect(() => {
    dispatch(getLayoutParentRequest(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContent>
      <LayoutModals />

      <FileLoader
        uploadAction={addLayoutRequest}
        uploadLabel={<AddTableItemButton styles='table_btn__lg' text={addButton} disabled={isRestricted()} />}
      />

      <LayoutsTable />
    </AppContent>
  );
}
