export const Role = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  POWER_USER: 'ROLE_POWER_USER',
};

export const managerRoles = [Role.SUPER_ADMIN, Role.ADMIN, Role.POWER_USER];

export const checkIsManager = role => managerRoles.includes(role);

export const checkIsUser = role => role === Role.USER;

export const checkIsSuperAdmin = role => role === Role.SUPER_ADMIN;
