export function LinkIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.0734 10.0786L5.0154 12.1366C4.17141 12.9806 3.69727 14.1253 3.69727 15.3189C3.69727 16.5124 4.17141 17.6571 5.0154 18.5011C5.85938 19.3451 7.00407 19.8193 8.19765 19.8193C9.39122 19.8193 10.5359 19.3451 11.3799 18.5011L14.1219 15.7576C14.6308 15.2486 15.0103 14.6249 15.2283 13.9389C15.4463 13.253 15.4966 12.5247 15.3749 11.8152C15.2533 11.1058 14.9632 10.4359 14.529 9.86173C14.0949 9.28759 13.5294 8.82597 12.8799 8.51562L12.0009 9.39462C11.9117 9.48402 11.834 9.58431 11.7699 9.69312C12.2717 9.83738 12.7271 10.1101 13.0912 10.4843C13.4553 10.8585 13.7155 11.3213 13.8459 11.8268C13.9763 12.3324 13.9726 12.8633 13.835 13.3669C13.6974 13.8706 13.4308 14.3296 13.0614 14.6986L10.3209 17.4406C9.75798 18.0035 8.99449 18.3198 8.1984 18.3198C7.4023 18.3198 6.63882 18.0035 6.0759 17.4406C5.51297 16.8777 5.19673 16.1142 5.19673 15.3181C5.19673 14.522 5.51297 13.7585 6.0759 13.1956L7.2654 12.0076C7.09757 11.3787 7.03273 10.7268 7.0734 10.0771V10.0786Z'
        fill='#9696A2'
      />
      <path
        d='M9.87834 7.27107C9.3694 7.78008 8.98999 8.40376 8.77195 9.08975C8.55392 9.77574 8.50363 10.504 8.6253 11.2135C8.74697 11.9229 9.03705 12.5928 9.4712 13.167C9.90534 13.7411 10.4709 14.2027 11.1203 14.5131L12.2828 13.3491C11.7743 13.2127 11.3105 12.9448 10.9383 12.5724C10.566 12.2 10.2984 11.7361 10.1622 11.2275C10.026 10.7189 10.026 10.1833 10.1624 9.67474C10.2987 9.16614 10.5665 8.70238 10.9388 8.33007L13.6793 5.58807C14.2423 5.02515 15.0057 4.7089 15.8018 4.7089C16.5979 4.7089 17.3614 5.02515 17.9243 5.58807C18.4873 6.15099 18.8035 6.91448 18.8035 7.71057C18.8035 8.50666 18.4873 9.27015 17.9243 9.83307L16.7348 11.0211C16.9028 11.6511 16.9673 12.3036 16.9268 12.9516L18.9848 10.8936C19.8288 10.0496 20.303 8.90489 20.303 7.71132C20.303 6.51774 19.8288 5.37305 18.9848 4.52907C18.1409 3.68508 16.9962 3.21094 15.8026 3.21094C14.609 3.21094 13.4643 3.68508 12.6203 4.52907L9.87834 7.27107Z'
        fill='#9696A2'
      />
    </svg>
  );
}
