export function BulletCheckIcon(props) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.2694 2.60576C20.6841 2.21293 21.236 1.99575 21.8086 2.00006C22.3812 2.00437 22.9298 2.22983 23.3385 2.62886C23.7472 3.02789 23.9841 3.56929 23.9992 4.1388C24.0144 4.70832 23.8065 5.26141 23.4196 5.68137L11.6726 20.2979C11.4706 20.5143 11.2268 20.688 10.9558 20.8086C10.6848 20.9292 10.3921 20.9942 10.0953 20.9996C9.79843 21.0051 9.50354 20.9509 9.22823 20.8404C8.95292 20.7299 8.70283 20.5653 8.49292 20.3564L0.702785 12.6059C0.485843 12.4048 0.311839 12.1622 0.191154 11.8928C0.0704689 11.6233 0.0055747 11.3324 0.000343637 11.0374C-0.00488742 10.7424 0.049652 10.4494 0.160707 10.1759C0.271762 9.90233 0.437058 9.65384 0.646735 9.44523C0.856411 9.23662 1.10617 9.07216 1.38112 8.96167C1.65606 8.85118 1.95056 8.79692 2.24704 8.80212C2.54352 8.80732 2.83591 8.87189 3.10677 8.99196C3.37763 9.11203 3.6214 9.28515 3.82355 9.50099L9.98853 15.6317L20.2134 2.6702C20.2319 2.64765 20.2515 2.62613 20.2723 2.60576H20.2694Z'
        fill='currentColor'
      />
    </svg>
  );
}
