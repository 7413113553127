import { TemplateTypes } from '../../../../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../../../../lib/util/helpers';
import { useSelector } from 'react-redux';
import { ElementNameWithLabel } from './ElementNameWithLabel';
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';

export function AnswerSelect({ answer, question, elementId, additionalInformation, onChange }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const answerOptions = questionnaireStructure
    ?.filter(el => el.templateType === TemplateTypes.QUESTION && el.id === question.value)
    ?.flatMap(element => {
      const elementIdByType = getIndexByTemplateType(questionnaireStructure, element);
      const { counterAnswers = [], answers = [], chartAnswers = [] } = element.content;
      const getAnswers = () => {
        if (counterAnswers.length > 0) {
          return counterAnswers;
        }
        if (answers.length > 0) {
          return answers;
        }
        if (chartAnswers.length > 0) {
          return chartAnswers;
        }
        return [];
      };
      return getAnswers()?.map((el, index) => {
        return {
          value: el.id,
          title: <ElementNameWithLabel questionIndex={elementIdByType} answerIndex={index} label={el.value} />,
        };
      });
    });

  const handleChangeAnswer = value => {
    const result = additionalInformation.map(item => (item.id === elementId ? { ...item, answer: { value } } : item));
    onChange(result);
  };

  const getAnswerValue = answerObject => {
    if (answerOptions.length === 1 && !answerObject.value) {
      handleChangeAnswer(answerOptions[0]?.value);
    }
    if (answerOptions.length > 1 && !answerObject.value) {
      return {};
    }
    return answerObject;
  };

  return (
    <QuestionnaireSelect
      selectProps={{
        value: getAnswerValue(answer),
        onChange: selected => handleChangeAnswer(selected.value),
        options: answerOptions,
        isOverflowHidden: true,
      }}
    />
  );
}
