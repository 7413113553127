import styled from 'styled-components';
import { forwardRef } from 'react';

export const STYLES = {
  $isFullWidth: {
    width: '100%',
  },
  get $isFullSize() {
    return {
      ...this.$isFullWidth,
      height: '100%',
    };
  },
  $flexCenterXY: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  $flexY: {
    display: 'flex',
    alignItems: 'center',
  },
  get $absoluteFullSize() {
    return {
      ...this.$isFullSize,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  },
  $flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const Wrapper = styled.div(({ $isFullSize, $isFullWidth, $flexCenterXY, $absoluteFullSize, $flexColumn, $flexY, disabled }) => ({
  ...($isFullSize ? STYLES.$isFullSize : {}),
  ...($isFullWidth ? STYLES.$isFullWidth : {}),
  ...($flexCenterXY ? STYLES.$flexCenterXY : {}),
  ...($flexY ? STYLES.$flexY : {}),
  ...($absoluteFullSize ? STYLES.$absoluteFullSize : {}),
  ...($flexColumn ? STYLES.$flexColumn : {}),
  ...(disabled
    ? {
        '&, & *': {
          color: '#9696A2 !important',
          fill: '#9696A2 !important',
          cursor: 'not-allowed !important',
          '&:hover': {
            borderColor: 'currentColor !important',
          },
        },
      }
    : {}),
}));

export const AppBox = forwardRef((props, ref) => {
  return <Wrapper {...props} ref={ref} />;
});
