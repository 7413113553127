export function ExternalLinkIcon(props) {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.5976 6.9564H11.6088C11.404 6.9564 11.238 7.12242 11.238 7.32719V11.8353C11.238 12.0746 11.0433 12.2693 10.804 12.2693H2.16419C1.92495 12.2693 1.73036 12.0746 1.73036 11.8353V3.19568C1.73036 2.95635 1.92495 2.76161 2.16419 2.76161H6.90315C7.10792 2.76161 7.27394 2.59559 7.27394 2.39082V1.40204C7.27394 1.19727 7.10792 1.03125 6.90315 1.03125H2.16419C0.970831 1.03125 0 2.00223 0 3.19568V11.8354C0 13.0288 0.970881 13.9997 2.16419 13.9997H10.8039C11.9974 13.9997 12.9683 13.0288 12.9683 11.8354V7.32724C12.9684 7.12242 12.8023 6.9564 12.5976 6.9564Z'
        fill={props.fill || '#DA1B5E'}
      />
      <path
        d='M13.6288 0H9.69574C9.49097 0 9.32495 0.166016 9.32495 0.370791V1.35957C9.32495 1.56434 9.49097 1.73036 9.69574 1.73036H11.0456L5.96603 6.80986C5.82122 6.95466 5.82122 7.1894 5.96603 7.33426L6.66519 8.03347C6.73475 8.10303 6.82903 8.14209 6.92741 8.14209C7.02575 8.14209 7.12008 8.10303 7.18959 8.03347L12.2692 2.95387V4.3037C12.2692 4.50848 12.4352 4.67449 12.64 4.67449H13.6288C13.8335 4.67449 13.9995 4.50848 13.9995 4.3037V0.370791C13.9995 0.166016 13.8335 0 13.6288 0Z'
        fill={props.fill || '#DA1B5E'}
      />
    </svg>
  );
}
