import { QuestionnaireTextButton } from '../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import lodashSet from 'lodash.set';
import { getNextIndexByArr } from '../../../../../../../../../../../lib/util/helpers';
import { generateContactFormOptionStructure } from './utils';
import { ContactFormField } from '../../../../../../../../../../../lib/models/Questionnaire';

const DROPDOWN_MAX_ITEMS = 100;
const CHECKBOX_MAX_ITEMS = 50;
const RADIO_MAX_ITEMS = 50;

function getLimitValue(fieldType) {
  switch (fieldType) {
    case ContactFormField.CHECKBOX:
      return CHECKBOX_MAX_ITEMS;
    case ContactFormField.RADIO:
    case ContactFormField.RADIO_WITH_ACTION:
      return RADIO_MAX_ITEMS;
    case ContactFormField.DROPDOWN:
    case ContactFormField.DROPDOWN_WITH_ACTION:
      return DROPDOWN_MAX_ITEMS;
    default:
      throw new Error(`${fieldType} is invalid "fieldType"`);
  }
}

function isLimit(fieldType, optionsAmount) {
  const limitValue = getLimitValue(fieldType);
  return optionsAmount >= limitValue;
}

export function AddContactFormOptionButton({ structureItemIndex, rowIndex, fieldIndex, fieldOptions, fieldType }) {
  const { updateStructure } = useUpdateStructure();
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;

  const onClick = () => {
    updateStructure(draft => {
      const nextIndex = getNextIndexByArr(fieldOptions);

      lodashSet(
        draft,
        `[${structureItemIndex}].content.formRowList[${rowIndex}].fieldList[${fieldIndex}].optionList[${nextIndex}]`,
        generateContactFormOptionStructure()
      );
    });
  };

  return (
    <QuestionnaireTextButton
      textValue={contentText.addOption}
      onClick={onClick}
      style={{ marginTop: '16px', paddingLeft: '24px' }}
      disabled={isLimit(fieldType, fieldOptions?.length)}
    />
  );
}
