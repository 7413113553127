import classes from '../Flow.module.sass';
import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';
import { FlowButtonSection } from '../FlowButtonSection';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { FlowOperation } from '../../../../../../../../../../../lib/models/Questionnaire';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';

//  FlowOperation.FORM_THEN_LINK REMOVED
const UNIQUE_OPERATION_LIST = new Set([FlowOperation.GO_TO_FORM, FlowOperation.PRINT]);

const isAlreadySelected = (flowButton, operation) => flowButton?.flow?.operation === operation;

const isNotSelected = (buttonList, operation) => !buttonList.some(button => isAlreadySelected(button, operation));

const canAddOption = (buttonList, iteratedOperation, currentOperation) =>
  isNotSelected(buttonList, iteratedOperation) || currentOperation === iteratedOperation;

function filterOptions(optionsList, buttonList, currentOperation) {
  return optionsList.filter(option => {
    if (UNIQUE_OPERATION_LIST.has(option?.value)) {
      return canAddOption(buttonList, option?.value, currentOperation);
    }

    return true;
  });
}

/**
 * @desc Completed Chapters Flow
 * @param {Object} structureItem
 * @param {Boolean} disabled
 * @param {Array} questionnaireStructure
 * @returns {JSX.Element}
 */
export function CompletedChaptersFlow({ structureItem, disabled, questionnaireStructure }) {
  const {
    id: structureItemId,
    content: { completedChaptersButtons },
    label,
    templateType,
    rules,
  } = structureItem;

  const { flowButtonAction } = useLocalization().translations.unique.formItems;
  const { buttonIndex: buttonIndexText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const elementIdByType = getIndexByTemplateType(questionnaireStructure, structureItem);

  const optionsList = [{ value: FlowOperation.PRINT, title: flowButtonAction.options.print() }];

  return (
    <div className={classes.root}>
      <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ marginBottom: 16 }} />
      <FlowRulesList elementRules={rules} />

      {completedChaptersButtons.map((button, buttonIndex) => {
        const currentOperation = button?.flow?.operation;
        const expandedOptions = filterOptions(optionsList, completedChaptersButtons, currentOperation);

        return (
          <FlowButtonSection
            key={button.id}
            buttonIndex={buttonIndex}
            structureItemId={structureItemId}
            buttonObject={button}
            buttonTitle={buttonIndexText({ index: buttonIndex + 1 })}
            buttonText={button.buttonText.value}
            options={expandedOptions}
            elements={questionnaireStructure}
            disabled={disabled}
            buttonsField='completedChaptersButtons'
          />
        );
      })}
    </div>
  );
}
