import { Droppable } from 'react-beautiful-dnd';
import { DragColumnsRow } from './DragColumnsRow';
import { DeleteColumnsRow } from './DeleteColumnsRow';
import { ComparisonTableDroppable } from '../comparison-table-utils';
import { useContentTable } from '../hooks/useContentTable';

export function ComparisonTableHead() {
  const { contentTableState } = useContentTable();

  return (
    <Droppable
      droppableId={JSON.stringify({
        tableIndex: contentTableState.tableIndex,
        structureItemIndex: contentTableState.structureItemIndex,
        type: 'columns',
      })}
      direction='horizontal'
      type={ComparisonTableDroppable.COLUMN}
    >
      {provided => (
        <thead>
          <DragColumnsRow provided={provided} />
          <DeleteColumnsRow />
        </thead>
      )}
    </Droppable>
  );
}
