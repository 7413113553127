import styled from 'styled-components';
import { CounterMinMax } from './CounterMinMax';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { TextFieldWithInfoIcon } from '../../../../../../features/TextFieldWithInfoIcon';

export function CounterFields({ data, dataPath, ...rest }) {
  const { templateHeadline } = useLocalization().translations.unique.formItems;
  const disabled = data.showCounter === false;

  return (
    <Root {...rest}>
      <TextFieldWithInfoIcon disabled={disabled} label={templateHeadline.label} data={data.headline} dataPath={`${dataPath}.headline`} />
      <CounterMinMax min={data.min} max={data.max} dataPath={dataPath} disabled={disabled} />
    </Root>
  );
}

const Root = styled('div')({
  gap: '16px',
  display: 'flex',
  alignItems: 'center',

  '& > *:first-child': { flex: 1 },
});
