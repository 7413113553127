import { useDispatch, useSelector } from 'react-redux';
import { closeFinderModal } from '../../../../../../../lib/store/slices/findersSlice';
import { FinderModal } from '../../../../../lib/models/Modal';
import { AreYouSureChangeQuestionnaireModal } from './AreYouSureChangeQuestionnaireModal';
import { SessionExpiredModal } from './SessionExpiredModal';

export function QuestionnaireModals() {
  const { isModalOpen, modalType: currentModalType, isModalLoading } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const close = () => dispatch(closeFinderModal());
  const checkIsOpen = modalType => isModalOpen && modalType === currentModalType;

  const genericProps = { close, isModalLoading };

  return (
    <>
      <SessionExpiredModal {...genericProps} isOpen={checkIsOpen(FinderModal.SESSION_EXPIRED)} />
      <AreYouSureChangeQuestionnaireModal {...genericProps} isOpen={checkIsOpen(FinderModal.CHANGE_QUESTIONNAIRE)} />
    </>
  );
}
