import { Popover } from 'antd';
import { AppIconButton } from '../../../../../ui/AppIconButton';
import { SortIcon } from './SortIcon';

export function ArrangeButtonWrapper({ isActiveArrange, sortOrder, children }) {
  return (
    <Popover
      trigger='click'
      content={children}
      placement='bottomRight'
      overlayStyle={styles.card}
      overlayInnerStyle={styles.inner}
      destroyTooltipOnHide
      destroyPopupOnHide
    >
      <AppIconButton style={{ margin: '2px 0 0 16px' }}>
        <SortIcon isActiveArrange={isActiveArrange} sortOrder={sortOrder} />
      </AppIconButton>
    </Popover>
  );
}

const styles = {
  card: {
    width: '240px',
  },
  inner: {
    padding: 16,
  },
};
