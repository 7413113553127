export function PreloaderIcon(props) {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8Z'
        fill='#CFCFD4'
      />
      <path
        d='M8 0C9.58225 1.88681e-08 11.129 0.469192 12.4446 1.34824C13.7602 2.22729 14.7855 3.47672 15.391 4.93853C15.9965 6.40034 16.155 8.00887 15.8463 9.56072C15.5376 11.1126 14.7757 12.538 13.6569 13.6569L12.5255 12.5255C13.4205 11.6304 14.0301 10.4901 14.277 9.24858C14.524 8.0071 14.3972 6.72027 13.9128 5.55083C13.4284 4.38138 12.6081 3.38184 11.5556 2.67859C10.5032 1.97535 9.2658 1.6 8 1.6V0Z'
        fill='#DA1B5E'
      />
    </svg>
  );
}
