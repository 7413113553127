import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../lib/models/Form';
import { handleTranslation } from '../../../../../../lib/util/helpers';

// eslint-disable-next-line unicorn/no-unsafe-regex
const EMAIL_PATTERN = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3})|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export function EmailFormItem(props) {
  const { label, errors } = useLocalization().translations.unique.formItems.email;
  const { textFieldProps, formItemName, required = true } = props;

  return (
    <AppFormSection
      formItemProps={{
        name: formItemName || FormItemName.EMAIL,
        rules: [{ required }, { pattern: EMAIL_PATTERN, message: handleTranslation(errors.invalid) }],
      }}
      textFieldProps={{
        autoComplete: 'email',
        label,
        ...textFieldProps,
      }}
    />
  );
}
