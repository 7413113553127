import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isOdd, normalizePageForRequest } from '../../../../../../../../lib/util/helpers';
import { useLayoutActionsColumn } from './columns/actions';
import { useLayoutNameColumn } from './columns/name';
import { useLayoutIdColumn } from './columns/id';
import { layoutCreatorColumn } from './columns/creator';
import { useLayoutStatusColumn } from './columns/status';
import { useLayoutCreationDateColumn } from './columns/creation-date';
import { useLayoutPreviewColumn } from './columns/preview';
import { AppTable } from '../../../../../../../../ui/AppTable';
import { getLayoutListRequest, removeLayoutsArranges } from '../../../../../../../../lib/store/slices/layoutsSlice';
import { useParams } from 'react-router-dom';
import { useLayoutUsedInEditingFindersColumn } from './columns/used-in-editing-finders';
import { useLayoutUsedInPublishedFindersColumn } from './columns/used-in-published-finders';
import { useLayoutUsedInTotalFindersColumn } from './columns/used-in-total-finders';

/**
 * @desc Layouts Table
 * @returns {JSX.Element}
 */
export function LayoutsTable() {
  const dispatch = useDispatch();
  const { list, loading, pagination } = useSelector(({ layouts }) => layouts);
  const { id: templateId } = useParams();

  useEffect(() => {
    dispatch(removeLayoutsArranges());
    dispatch(getLayoutListRequest({ templateId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    useLayoutPreviewColumn(),
    useLayoutIdColumn(),
    useLayoutNameColumn(),
    layoutCreatorColumn,
    useLayoutCreationDateColumn(),
    useLayoutStatusColumn(),
    useLayoutUsedInEditingFindersColumn(),
    useLayoutUsedInPublishedFindersColumn(),
    useLayoutUsedInTotalFindersColumn(),
    useLayoutActionsColumn(),
  ];

  return (
    <AppTable
      columns={columns}
      rowKey='id'
      dataSource={list}
      pagination={pagination}
      loading={loading}
      scroll={{ x: 'max-content' }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      onChange={tablePagination => dispatch(getLayoutListRequest({ params: { page: normalizePageForRequest(tablePagination.current) } }))}
    />
  );
}
