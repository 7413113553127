import { InfoIcon } from 'src/ui/icons/InfoIcon';
import { AppTooltip } from 'src/ui/AppTooltip';

export function Topic({ applyVariable, topic, colors, fonts, comparisonOptions, icons }) {
  function productText(product) {
    if (!product.value) return null;

    const option = comparisonOptions.find(optionItem => optionItem.value === product.value);
    const colorOptions = { ...colors, green: '#00A887', red: '#FF4949' };

    if (product.value === 'Custom text') {
      return (
        <span style={{ color: colorOptions[option.color], display: 'flex', alignItems: 'center' }}>
          {applyVariable(product.customText.value)}
          {product.customText.infoIcon?.value && (
            <AppTooltip text={applyVariable(product.customText.infoIcon?.value)}>
              <InfoIcon style={{ marginLeft: '10px' }} />
            </AppTooltip>
          )}
        </span>
      );
    }

    const icon = icons.find(iconItem => iconItem.id === option.icon);

    // eslint-disable-next-line react/no-danger
    return <div style={{ fill: colorOptions[option.color] }} dangerouslySetInnerHTML={{ __html: icon.svgContent }} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        backgroundColor: 'white',
        color: colors[fonts.body.colorVariant],
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <div style={{ flex: 4 }}>
        <div style={{ padding: '16px 24px' }}>{applyVariable(topic.topicName)}</div>
      </div>

      <div style={{ flex: 3, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ padding: '0 12px' }}>{productText(topic.productOne)}</div>
      </div>
      <div style={{ flex: 3, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ padding: '0 12px' }}>{productText(topic.productTwo)}</div>
      </div>
    </div>
  );
}
