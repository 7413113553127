/* eslint-disable max-lines */
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { CollapseHeader } from './CollapseHeader';
import { ChevronDownIcon } from '../../../../../../../../../../ui/icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { IntroForm } from './IntroForm';
import { ContentForm } from './ContentForm';
import { QuestionForm } from './QuestionForm';
import { updateQuestionnaireStructure } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { ProductForm } from './ProductForm';
import { ResultForm } from './ResultForm';
import { Droppable } from 'react-beautiful-dnd';
import { ContactForm } from './ContactForm';
import styled from 'styled-components';
import { ActionsWrapper } from '../ActionsWrapper';

const { Panel } = Collapse;

const headerTexts = {
  [TemplateTypes.INTRO]: 'Intropage - I.',
  [TemplateTypes.CONTENT]: 'Content - C.',
  [TemplateTypes.PRODUCT]: 'Products - P.',
  [TemplateTypes.QUESTION]: 'Question - Q.',
  [TemplateTypes.RESULT]: 'Result - R.',
  [TemplateTypes.CONTACT_FORM]: 'Contact form - CF.',
};

const getActiveKey = isCollapsed => (isCollapsed ? [] : ['1']);

// eslint-disable-next-line max-lines-per-function
export function CardCollapse({ item, providedItem, itemIndex }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, selectedQuestionnaireElement } = useSelector(({ finders }) => finders);

  const [activeKey, setActiveKey] = useState(getActiveKey(item?.isCollapsed));

  const handleChangeFieldValue = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, [fieldName]: { ...el.content[fieldName], value } } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeField = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, [fieldName]: value } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeFieldTargetValue = fieldName => event => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...el.content, [fieldName]: event.target.value } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeFieldInfoIconValue = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: { ...el.content, [fieldName]: { ...el.content[fieldName], infoIcon: { ...el.content[fieldName].infoIcon, value } } },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleDeleteInfoIcon = fieldName => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: { ...el.content, [fieldName]: { ...el.content[fieldName], infoIcon: '' } },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleDeleteInfoIconContentBlocks = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id
        ? {
            ...el,
            content: { ...el.content, [fieldName]: value },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeContentFields = updatedFields => {
    const result = questionnaireStructure.map(el => {
      return el.id === item.id ? { ...el, content: { ...item.content, ...updatedFields } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleCollapseChange = keys => {
    setActiveKey(keys);
  };

  useEffect(() => {
    setActiveKey(getActiveKey(item?.isCollapsed));
  }, [item?.isCollapsed]);

  const handleDeleteComposerButton = itemId => {
    const result = questionnaireStructure.map(el =>
      el.id === itemId
        ? {
            ...el,
            content: {
              ...el.content,
              composerButton: null,
            },
          }
        : el
    );
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <Root>
      <Collapse
        activeKey={activeKey}
        expandIcon={({ isActive }) => (isActive ? <ChevronUpIcon fill='#5C5C6E' /> : <ChevronDownIcon fill='#5C5C6E' />)}
        expandIconPosition='end'
        onChange={handleCollapseChange}
        style={{
          boxShadow:
            item.id === selectedQuestionnaireElement?.id
              ? '2px 2px #0092A3, -2px -2px #0092A3, -2px 2px #0092A3, 2px -2px #0092A3'
              : '0 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Panel
          header={
            <CollapseHeader
              templateType={item.templateType}
              headerText={`${item.label} ${headerTexts[item.templateType]}${item.templateTypeIndex}`}
              {...providedItem.dragHandleProps}
            />
          }
          key='1'
        >
          <Droppable
            droppableId={`${item.id}_composerButton`}
            type={
              (item.templateType === TemplateTypes.CONTENT ||
                item.templateType === TemplateTypes.QUESTION ||
                item.templateType === TemplateTypes.PRODUCT) &&
              !item.content?.composerButton?.name &&
              'COMPOSER_BUTTON'
            }
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {item.templateType === TemplateTypes.INTRO && (
                  <IntroForm
                    item={item}
                    handleChange={handleChangeFieldValue}
                    onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
                    onDeleteInfoIcon={handleDeleteInfoIcon}
                  />
                )}
                {item.templateType === TemplateTypes.CONTENT && (
                  <ContentForm
                    item={item}
                    itemIndex={itemIndex}
                    handleChangeFieldValue={handleChangeFieldValue}
                    handleChangeField={handleChangeField}
                    onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
                    onDeleteInfoIcon={handleDeleteInfoIcon}
                    onDeleteInfoIconContentBlocks={handleDeleteInfoIconContentBlocks}
                    onDeleteComposerButton={handleDeleteComposerButton}
                    isDraggingOver={snapshot.isDraggingOver}
                  />
                )}
                {item.templateType === TemplateTypes.QUESTION && (
                  <QuestionForm
                    item={item}
                    handleChangeFieldValue={handleChangeFieldValue}
                    handleChangeField={handleChangeField}
                    handleChangeFieldTargetValue={handleChangeFieldTargetValue}
                    onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
                    onDeleteInfoIcon={handleDeleteInfoIcon}
                    handleChangeContentFields={handleChangeContentFields}
                    onDeleteComposerButton={handleDeleteComposerButton}
                    isDraggingOver={snapshot.isDraggingOver}
                  />
                )}
                {item.templateType === TemplateTypes.RESULT && (
                  <ResultForm
                    item={item}
                    onChangeFieldValue={handleChangeFieldValue}
                    onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
                    onDeleteInfoIcon={handleDeleteInfoIcon}
                    onChange={handleChangeField}
                    structureItemIndex={itemIndex}
                  />
                )}
                {item.templateType === TemplateTypes.PRODUCT && (
                  <ProductForm
                    structureItem={item}
                    structureItemIndex={itemIndex}
                    onDeleteComposerButton={handleDeleteComposerButton}
                    isDraggingOver={snapshot.isDraggingOver}
                    onChangeFieldValue={handleChangeFieldValue}
                  />
                )}
                {item.templateType === TemplateTypes.CONTACT_FORM && (
                  <ContactForm structureItem={item} structureItemIndex={itemIndex} onChangeFieldValue={handleChangeFieldValue} />
                )}
              </div>
            )}
          </Droppable>
        </Panel>
      </Collapse>
      <div className='actions_wrapper_hide actions_wrapper_show'>
        <ActionsWrapper item={item} itemIndex={itemIndex} />
      </div>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  .actions_wrapper_hide {
    display: none;
  }
  &:hover .actions_wrapper_show {
    display: block;
  }
`;
