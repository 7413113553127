import { Form } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';
import { AppFormSection } from '../ui/AppFormSection';
import { AppInputNumber } from '../ui/AppInputNumber';

function formatPriceToStr(price = '') {
  let formattedValue = price.replace(/[^\d.]/g, ''); // Remove non-numeric characters except dot
  const dotIndex = formattedValue.indexOf('.');
  // eslint-disable-next-line no-magic-numbers
  if (dotIndex !== -1) {
    // If the input contains a dot, format the integer and decimal parts separately
    // eslint-disable-next-line unicorn/no-unsafe-regex
    const integerPart = formattedValue.slice(0, dotIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // eslint-disable-next-line no-magic-numbers
    const decimalPart = formattedValue.slice(dotIndex + 1, dotIndex + 3);
    formattedValue = `${integerPart}.${decimalPart}`;
  } else {
    // If the input doesn't contain a dot, format the integer part only
    // eslint-disable-next-line unicorn/no-unsafe-regex
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return formattedValue;
}

function isPriceFormatted(price) {
  // eslint-disable-next-line prettier/prettier
  return !price || (/,/).test(price);
}

export function PriceFormItem(props) {
  const { formItemProps, textFieldProps } = props;
  const form = Form.useFormInstance();
  const price = Form.useWatch(formItemProps.name, form);

  const onChange = event => {
    const value = event.target.value?.toString() || '';
    const result = formatPriceToStr(value);

    form.setFields([{ name: formItemProps.name, touched: Boolean(result), value: result }]);
  };

  useEffect(() => {
    if (!isPriceFormatted(price)) {
      form.setFieldValue(formItemProps.name, formatPriceToStr(price));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItemProps.name, price]);

  return (
    <Root
      formItemProps={formItemProps}
      textFieldProps={{
        ...textFieldProps,
        onChange,
        as: AppInputNumber,
        type: 'text',
      }}
    />
  );
}

const Root = styled(AppFormSection)({
  '.ant-form-item-has-error label, .ant-form-item-has-success label': {
    left: '40px',
  },
});
