export function ProductsIcon({ fill = 'white', ...rest }) {
  return (
    <svg {...rest} width='16' height='16' viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3003_42090)'>
        <path d='M8.186 1.11438C8.06662 1.06653 7.93338 1.06653 7.814 1.11438L1.846 3.50138L8 5.96238L14.154 3.50138L8.186 1.11438ZM15 4.24038L8.5 6.84038V14.7624L15 12.1624V4.24138V4.24038ZM7.5 14.7634V6.83938L1 4.24038V12.1634L7.5 14.7634V14.7634ZM7.443 0.185376C7.80057 0.0423748 8.19943 0.0423748 8.557 0.185376L15.686 3.03738C15.7787 3.07452 15.8581 3.13857 15.9141 3.22127C15.9701 3.30396 16 3.40152 16 3.50138V12.1634C15.9999 12.3632 15.9399 12.5584 15.8278 12.7238C15.7157 12.8892 15.5566 13.0172 15.371 13.0914L8.186 15.9654C8.06662 16.0132 7.93338 16.0132 7.814 15.9654L0.63 13.0914C0.444251 13.0174 0.284942 12.8894 0.172642 12.724C0.0603417 12.5586 0.000206329 12.3633 0 12.1634L0 3.50138C2.32399e-05 3.40152 0.0299437 3.30396 0.085907 3.22127C0.14187 3.13857 0.221313 3.07452 0.314 3.03738L7.443 0.185376Z' />
      </g>
      <defs>
        <clipPath id='clip0_3003_42090'>
          <rect width='16' height='16' />
        </clipPath>
      </defs>
    </svg>
  );
}
