import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BodyCell } from './BodyCell';
import { CellPopup } from './CellPopup';
import { isImageLikeType } from '../comparison-table-utils';
import { ValueCellInner } from './ValueCellInner';

export function ValueCell({
  bgColor,
  rowColorList,
  iconList,
  fieldLabels,
  productFieldList,
  pictureList,
  productList,
  textColorList,
  item,
  rowIndex,
  columnIndex,
  focusedId,
  setFocusedId,
  ...rest
}) {
  const bgColorValue = bgColor?.value;
  const isFocused = focusedId === item.id;

  const isImage = isImageLikeType(item?.type);
  const { isViewMode } = useSelector(({ finders }) => finders);

  const focusCell = () => {
    if (isViewMode) {
      return;
    }

    setFocusedId(item.id);
  };

  const genericProps = {
    rowIndex,
    columnIndex,
  };

  const cellPopupProps = {
    ...genericProps,
    rowColorList,
    productList,
    textColorList,
    iconList,
    pictureList,
    fieldLabels,
    productFieldList,
  };

  const innerProps = {
    ...genericProps,
    item,
    isFocused,
    bgColorValue,
    isImage,
  };

  const inner = <ValueCellInner {...innerProps} />;

  return (
    <Root
      {...rest}
      disabled={isViewMode}
      $bgColor={bgColorValue}
      $isImage={isImage}
      className={classNames(rest.className, 'DataCell')}
      onClick={focusCell}
    >
      {isViewMode ? inner : <CellPopup {...cellPopupProps}>{inner}</CellPopup>}
    </Root>
  );
}

const Root = styled(BodyCell)({
  background: ({ $bgColor }) => $bgColor || 'unset',
  cursor: ({ $isImage, disabled }) => (disabled ? 'not-allowed' : $isImage ? 'pointer' : 'text'),
  minWidth: '169px',
});
