import { Divider } from 'antd';
import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

export function AppDivider({ type = 'horizontal', innerProps, ...rest }) {
  return (
    <Root {...rest} type={type}>
      <Divider {...innerProps} />
    </Root>
  );
}

const Root = styled(AppBox)(({ type }) => ({
  overflow: 'hidden',
  '& > *': {
    display: 'flex',
    background: '#B3B3BB',
    border: 'none',
    margin: '0',
    ...(type === 'horizontal' ? { height: '1px', width: '100%' } : { height: '100%', width: '1px' }),
  },
}));
