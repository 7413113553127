export function ArrowBarDown({ width = '17', height = '17', fill = '#5C5C6E' }) {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11567_5544)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.02734 3.5C1.02734 3.36739 1.08002 3.24021 1.17379 3.14645C1.26756 3.05268 1.39474 3 1.52734 3H14.5273C14.66 3 14.7871 3.05268 14.8809 3.14645C14.9747 3.24021 15.0273 3.36739 15.0273 3.5C15.0273 3.63261 14.9747 3.75979 14.8809 3.85355C14.7871 3.94732 14.66 4 14.5273 4H1.52734C1.39474 4 1.26756 3.94732 1.17379 3.85355C1.08002 3.75979 1.02734 3.63261 1.02734 3.5ZM8.02734 6C8.15995 6 8.28713 6.05268 8.3809 6.14645C8.47467 6.24021 8.52734 6.36739 8.52734 6.5V12.293L10.6733 10.146C10.7672 10.0521 10.8946 9.99937 11.0273 9.99937C11.1601 9.99937 11.2875 10.0521 11.3813 10.146C11.4752 10.2399 11.528 10.3672 11.528 10.5C11.528 10.6328 11.4752 10.7601 11.3813 10.854L8.38134 13.854C8.3349 13.9006 8.27972 13.9375 8.21898 13.9627C8.15823 13.9879 8.09311 14.0009 8.02734 14.0009C7.96158 14.0009 7.89646 13.9879 7.83571 13.9627C7.77496 13.9375 7.71979 13.9006 7.67334 13.854L4.67334 10.854C4.57946 10.7601 4.52671 10.6328 4.52671 10.5C4.52671 10.3672 4.57946 10.2399 4.67334 10.146C4.76723 10.0521 4.89457 9.99937 5.02734 9.99937C5.16012 9.99937 5.28746 10.0521 5.38134 10.146L7.52734 12.293V6.5C7.52734 6.36739 7.58002 6.24021 7.67379 6.14645C7.76756 6.05268 7.89474 6 8.02734 6Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_11567_5544'>
          <rect width={width} height={height} fill='white' transform='translate(0.0273438)' />
        </clipPath>
      </defs>
    </svg>
  );
}
