import axios from 'axios';
import { isLocal } from '../../env';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const PRIVATE_LOCAL_API_BASE_URL = `${BASE_URL}api/`;
const PRIVATE_MAIN_API_BASE_URL = '/api/';
const customApiBaseURL = process.env.REACT_APP_HOST;
const PUBLIC_MAIN_API_BASE_URL = '/';

const axiosInstanceBaseUrl = isLocal ? PRIVATE_LOCAL_API_BASE_URL : customApiBaseURL || PRIVATE_MAIN_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: axiosInstanceBaseUrl,
  validateStatus: () => true,
});

// REFACTOR: axios folder structure. Split code related to public API from private API.

export const publicAxiosInstance = axios.create({
  baseURL: isLocal ? BASE_URL : customApiBaseURL || PUBLIC_MAIN_API_BASE_URL,
});
