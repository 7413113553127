import { QuestionnaireTextButton } from '../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { generateContactFormRowStructure } from './utils';
import lodashSet from 'lodash.set';
import { getNextIndexByArr } from '../../../../../../../../../../../lib/util/helpers';

export function AddContactFormRowButton({ structureItemIndex, formRowList }) {
  const { updateStructure } = useUpdateStructure();
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;

  const onClick = () => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${getNextIndexByArr(formRowList)}]`, generateContactFormRowStructure());
    });
  };

  return <QuestionnaireTextButton textValue={contentText.addRow} onClick={onClick} style={{ marginTop: '24px' }} />;
}
