import styled from 'styled-components';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppAvatar } from '../../../../../../ui/AppAvatar';
import { QuestionnaireVersion } from '../../../../../../lib/models/Questionnaire';
import dayjs from 'dayjs';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';

export function Version({ title, personName, modifyingTime, value, reactSelectProps, index, ...rest }) {
  const { questionnaireVersions } = useLocalization().translations.unique.components;
  const subText = value === QuestionnaireVersion.DRAFT ? questionnaireVersions.lastEditedBy : questionnaireVersions.publishedBy;
  const formattedTime = dayjs(modifyingTime).format('DD MMM, HH:mm');
  const selected = reactSelectProps?.selectProps?.value;
  const isSelected = selected?.value === value;

  const handleClick = () => {
    reactSelectProps.selectOption(reactSelectProps.options[index]);
  };

  return (
    <Root onClick={handleClick} role='button' {...rest}>
      <FirstLine $isSelected={isSelected} className='firstLine'>
        <Title textValue={title} $isSelected={isSelected} className='firstLineTitle' />
        <Time textValue={formattedTime} />
      </FirstLine>

      <SecondLine>
        <AppAvatar stringStyle={{ fontWeight: 'bold' }}>{personName}</AppAvatar>
        <Person>
          <AppText textValue={subText} />
          &nbsp;
          <AppText textValue={personName} />
        </Person>
      </SecondLine>
    </Root>
  );
}

const Root = styled('div')(() => ({
  cursor: 'pointer',

  '&:hover': {
    '.firstLine': {
      background: '#fbe8ef',
    },

    '.firstLineTitle': {
      color: '#BA1750',
    },
  },
}));

const FirstLine = styled.div(({ $isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '4px',
  borderRadius: '4px 0 0 4px',
  padding: '8px',
  transition: 'all .2s ease-out',

  ...($isSelected && { background: '#FBE8EF' }),
}));

const Title = styled(AppText)(({ $isSelected }) => ({
  fontSize: '14px !important',
  paddingRight: '40px',

  ...($isSelected && { color: '#BA1750' }),
}));

const Time = styled(AppText)({
  fontSize: '12px',
  color: '#5C5C6E',
});

const SecondLine = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
  paddingLeft: '14px',
});

const Person = styled.div({
  '> *': {
    fontSize: '12px',
    color: '#5C5C6E',
  },

  '> *:last-child': {
    fontWeight: 'bold',
  },
});
