export function RightPointerIcon(props) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_2712_176649)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6 11.9991C6 11.8001 6.07902 11.6094 6.21967 11.4687C6.36032 11.3281 6.55109 11.2491 6.75 11.2491L15.4395 11.2491L12.219 8.03005C12.1493 7.96032 12.094 7.87754 12.0562 7.78643C12.0185 7.69532 11.9991 7.59767 11.9991 7.49905C11.9991 7.40044 12.0185 7.30279 12.0562 7.21168C12.094 7.12057 12.1493 7.03779 12.219 6.96805C12.2887 6.89832 12.3715 6.84301 12.4626 6.80527C12.5537 6.76753 12.6514 6.74811 12.75 6.74811C12.8486 6.74811 12.9463 6.76753 13.0374 6.80527C13.1285 6.84301 13.2113 6.89832 13.281 6.96805L17.781 11.4681C17.8508 11.5377 17.9063 11.6205 17.9441 11.7116C17.9819 11.8027 18.0013 11.9004 18.0013 11.9991C18.0013 12.0977 17.9819 12.1954 17.9441 12.2865C17.9063 12.3776 17.8508 12.4604 17.781 12.5301L13.281 17.0301C13.2113 17.0998 13.1285 17.1551 13.0374 17.1928C12.9463 17.2306 12.8486 17.25 12.75 17.25C12.6514 17.25 12.5537 17.2306 12.4626 17.1928C12.3715 17.1551 12.2887 17.0998 12.219 17.0301C12.0782 16.8892 11.9991 16.6982 11.9991 16.4991C11.9991 16.4004 12.0185 16.3028 12.0562 16.2117C12.094 16.1206 12.1493 16.0378 12.219 15.9681L15.4395 12.7491L6.75 12.7491C6.55109 12.7491 6.36032 12.67 6.21967 12.5294C6.07902 12.3887 6 12.198 6 11.9991Z'
          fill='white'
          stroke='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2712_176649'>
          <rect width='24' height='24' fill='white' transform='translate(0 24) rotate(-90)' />
        </clipPath>
      </defs>
    </svg>
  );
}
