import { CardSectionHeader } from '../../../../../ui/CardSectionHeader';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { CardSection } from '../../../../../ui/CardSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import ReactQuill from 'react-quill';
import { TextFieldWithInfoIcon } from '../../../../../features/TextFieldWithInfoIcon';
import lodashSet from 'lodash.set';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';

export function ContactFormAdditionalText({ structureItemIndex, templateContent }) {
  const { unique } = useLocalization().translations;
  const { content: contentText } = unique.pages.questionnaire;
  const { templateHeadline } = unique.formItems;
  const { updateStructure } = useUpdateStructure();

  const changeSideText = value => {
    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.additionalText.sideText`, value);
    });
  };

  return (
    <CardSection withPaddingBottom withBorderBottom={false}>
      <CardSectionHeader>
        <AppText strong textValue={contentText.additionalText} />
      </CardSectionHeader>
      <TextFieldWithInfoIcon
        label={templateHeadline.label}
        data={templateContent?.additionalText?.headline}
        dataPath={`[${structureItemIndex}].content.additionalText.headline`}
      />
      <ReactQuill theme='snow' value={templateContent?.additionalText?.sideText} onChange={changeSideText} />
    </CardSection>
  );
}
