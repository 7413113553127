import { QuestionnaireTextButton } from '../../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../../hooks/useUpdateStructure';
import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function AddProductButton({ structureItemIndex, allProducts, generateProductItem }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const areNoAvailableProducts = questionnaireStructure[structureItemIndex].content.products?.length === allProducts?.length;
  const { updateStructure } = useUpdateStructure();
  const { generic } = useLocalization().translations.shared;

  const addProduct = () => {
    updateStructure(draft => {
      const showCounter = !draft[structureItemIndex].content.productSelection;
      draft[structureItemIndex].content.products.push(generateProductItem(showCounter));
    });
  };

  return (
    <div style={{ padding: '16px 20px' }}>
      <QuestionnaireTextButton textValue={generic.addProduct} disabled={areNoAvailableProducts} onClick={addProduct} />
    </div>
  );
}
