import styled from 'styled-components';
import { AppText } from '../../../../../ui/text/AppText';
import { AppBox } from '../../../../../ui/boxes/AppBox';

export const StatusType = {
  GRAY: 'gray',
  ORANGE: 'orange',
  GREEN: 'green',
  NAVY: 'navy',
};

const Root = styled(AppBox)({
  width: 'fit-content',
  background: '#858899',
  borderRadius: '8px',
  padding: '5px 8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: ({ type }) =>
    type === StatusType.GREEN
      ? '#008F73'
      : type === StatusType.ORANGE
      ? '#FC9155'
      : type === StatusType.GRAY
      ? '#858899'
      : type === StatusType.NAVY
      ? '#D9E9EF'
      : 'unset',
});

const Text = styled(AppText)({
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '14px',
  color: ({ type }) => (type === StatusType.NAVY ? '#4C97B2' : '#fff'),
});

export function Status({ textValue, type }) {
  return (
    <Root type={type}>
      <Text textValue={textValue} type={type} />
    </Root>
  );
}
