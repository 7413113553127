import { AppImage } from '../../../../../../../../../../../../../ui/AppImage';
import { AppTitle } from '../../../../../../../../../../../../../ui/text/AppTitle';

export function ProductListMultipleCarouselCenterMode(props) {
  const { data, dataIndex } = props;
  const { activeSlide } = data[dataIndex]?.extraData || {};

  return (
    <div>
      <div>
        <div>
          <div style={{ width: '100%' }}>
            {data[dataIndex]?.image && (
              <div
                style={{
                  width: '100%',
                  height: 300,
                  userSelect: 'none',
                  borderRadius: 15,
                }}
              >
                <AppImage
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    borderRadius: 15,
                  }}
                  src={data[dataIndex]?.image}
                  alt='image'
                />
              </div>
            )}

            {data[dataIndex]?.headline?.title && dataIndex === activeSlide && <AppTitle level={3} textValue={data[dataIndex]?.headline?.title} />}
          </div>
        </div>
      </div>
    </div>
  );
}
