import { useSelector } from 'react-redux';
import { ContentTop } from '../ContentTop';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Explanation } from '../Explanation';
import { AdvancedSettingsProcess } from './AdvancedSettignsProcess';

export function AdvancedSettingsContent() {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { title, tooltipText, explanation } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.advanceSettings;

  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <ContentTop title={title} tooltipText={tooltipText} />
      {questionnaireStructure ? <AdvancedSettingsProcess /> : <Explanation descriptionText={explanation.description} />}
    </div>
  );
}
