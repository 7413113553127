import { components } from 'react-select';
import { ProductBlock } from './ProductBlock';

export function ProductOption(props) {
  const { data } = props;

  return (
    <components.Option {...props}>
      <ProductBlock {...props} image={data.image} label={data.label} />
    </components.Option>
  );
}
