import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { ElementLabel } from '../../../../features/ElementLabel';
import { LabelTexts } from '../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../lib/util/helpers';
import './style.scss';

/**
 * @desc Element Box
 * @param {Object} element
 * @param {Array} elements
 * @param {Object} selectedElement
 * @param {Function} selectElement
 * @returns {JSX.Element}
 */
export function ElementBox({ element, elements, selectedElement, selectElement }) {
  const selectElementItem = () => {
    selectElement({
      elementId: element.id,
      templateType: element.templateType,
    });
  };
  const elementIdByType = getIndexByTemplateType(elements, element);

  return (
    <div
      role='presentation'
      className={selectedElement.elementId === element.id ? 'element_flow_box element_flow_box__selected' : 'element_flow_box'}
      onClick={() => selectElementItem()}
    >
      <ElementLabel templateType={element.templateType} />
      <AppText>{`${LabelTexts[element.templateType]}.${elementIdByType + 1}`}</AppText>
    </div>
  );
}
