import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';
import { uploadMediaRequest } from '../../../../../lib/store/slices/findersSlice';
import { useBeforeUploadError } from '../utils/hooks/useBeforeUploadError';
import { createFormData } from '../../../../../lib/util/helpers';
import { appLogger } from '../../../../../lib/util/logger';

export function FinderFileLoader({
  uploadLabel,
  afterUploadAction,
  accept = 'image/*',
  maxFileSize,
  errorText,
  targetType = 'FINDER',
  disabled,
  setUploading,
}) {
  const dispatch = useDispatch();
  const { finder, tenant, isViewMode } = useSelector(({ finders }) => finders);
  const { beforeUpload, contextHolder } = useBeforeUploadError(maxFileSize, errorText);

  const customUploadRequest = async ({ file }) => {
    let fileForUpload = file;
    setUploading(true);

    try {
      if (accept.includes('image') && !file.type.includes('svg')) {
        fileForUpload = await imageCompression(file, { maxSizeMB: 10 });
      }
      const formData = createFormData({
        file: fileForUpload,
        targetType,
        targetId: finder.id,
        originalFileName: file.name,
      });

      dispatch(uploadMediaRequest({ formData, afterUploadAction, tenantId: tenant.id, setUploading }));
      if (setUploading) {
        setUploading(true);
      }
    } catch (error) {
      appLogger.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      <Upload
        accept={accept}
        beforeUpload={beforeUpload}
        customRequest={options => customUploadRequest(options)}
        showUploadList={false}
        disabled={disabled || isViewMode}
      >
        {uploadLabel}
      </Upload>
    </>
  );
}
