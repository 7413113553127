import styled from 'styled-components';
import { Button } from '../../../../../../../../../components/Button';
import { Typography } from 'antd';

/**
 * @desc Buttons
 * @param {Array} buttons
 * @param {Object} buttonsStyle
 * @param {Object} colors
 * @returns {JSX.Element}
 */
export function Buttons({ buttons, buttonsStyle, colors }) {
  return (
    <StyledButtons>
      {buttons?.map(button => (
        <Button
          key={button.id}
          style={{
            ...buttonsStyle,
            backgroundColor: colors.primaryColor,
          }}
        >
          <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0, color: '#fff' }}>
            {button.buttonText.value}
          </Typography.Paragraph>
        </Button>
      ))}
    </StyledButtons>
  );
}

const StyledButtons = styled('div')({
  marginTop: 16,
  display: 'grid',
  gridTemplateColumns: 'repeat( auto-fill, minmax(178px, 1fr) )',
  rowGap: 5,
  columnGap: 16,
  marginBottom: 20,
});
