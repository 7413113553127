export function ProgressBarLabelIcon() {
  return (
    <svg width='82' height='67' viewBox='0 0 82 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_1712_7157)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M62 17C62 16.4477 61.5523 16 61 16H21C20.4477 16 20 16.4477 20 17V37C20 37.5523 20.4477 38 21 38H37.816C38.2187 38 38.5821 38.2416 38.738 38.6129L40.078 41.8041C40.4211 42.6213 41.5789 42.6213 41.922 41.8041L43.262 38.6129C43.4179 38.2416 43.7813 38 44.184 38H61C61.5523 38 62 37.5523 62 37V17Z'
          fill='white'
        />
      </g>
      <defs>
        <filter id='filter0_d_1712_7157' x='0' y='0' width='82' height='66.418' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1712_7157' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1712_7157' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
