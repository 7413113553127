import { generateGenericProductItemStructure } from '../utils';
import { DEFAULT_MAX_COUNTER_VALUE } from '../../../../../../../../../../../../lib/models/Questionnaire';

export function generateProductWithCounterItem(showCounter) {
  return {
    ...generateGenericProductItemStructure(),
    counterSection: {
      showCounter,
      value: 0,
      headline: {
        value: '',
        infoIcon: null,
      },
      min: 1,
      max: DEFAULT_MAX_COUNTER_VALUE,
    },
  };
}
