import { createPortal } from 'react-dom';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { addVariableRequest, selectVariable, editVariableRequest, updateSelectedVariable } from 'src/lib/store/slices/variablesSlice';
import { showErrorNotification } from 'src/lib/store/slices/notificationsSlice';
import { AppInput } from 'src/ui/AppInput';
import { AppNewSelect } from 'src/ui/AppNewSelect';
import { AppButton } from 'src/ui/buttons/AppButton';
import { AppIconButton } from 'src/ui/AppIconButton';
import { CrossIcon } from 'src/ui/icons/CrossIcon';

import styles from './VariablePopup.module.scss';
import { useDispatch, useSelector } from 'react-redux';

const dataTypeOptions = [
  {
    title: 'String',
    value: 'String',
  },
  {
    title: 'Number',
    value: 'Number',
  },
  {
    title: 'Boolean',
    value: 'Boolean',
  },
];

const defaultBooleanOptions = [
  {
    title: 'true',
    value: 'true',
  },
  {
    title: 'false',
    value: 'false',
  },
];

/**
 * @desc Variable Popup
 * @param {Boolean} isOpen
 * @param {Function} onClose
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
export function VariablePopup({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { translations } = useLocalization();
  const { selectedVariable, variables: existingVariables } = useSelector(({ variables }) => variables);
  const variablesLocalization = translations.unique.pages.variables;
  const genericLocalization = translations.shared.generic;

  const handleVariableChange = (property, value) => {
    const updatedObj = {
      ...selectedVariable,
    };

    if (property === 'type') {
      updatedObj.defaultValue = '';
    }

    if (property === 'defaultValue' && selectedVariable.type === 'Number') {
      const formattedValue = value.replace(/[^\d,]/g, '');
      const firstCommaIndex = value.indexOf(',');
      const notFoundComaIndex = -1;
      const valueWithoutDuplicateComas =
        firstCommaIndex !== notFoundComaIndex
          ? formattedValue.slice(0, firstCommaIndex + 1) + formattedValue.slice(firstCommaIndex + 1).replaceAll(',', '')
          : formattedValue;

      updatedObj.defaultValue = valueWithoutDuplicateComas;
    } else {
      updatedObj[property] = value;
    }

    dispatch(updateSelectedVariable(updatedObj));
  };

  const isSaveDisabled =
    !selectedVariable.name || !selectedVariable.type || (selectedVariable.type === 'Boolean' ? selectedVariable.defaultValue === '' : false);

  const save = () => {
    if (selectedVariable.id) {
      dispatch(editVariableRequest({ ...selectedVariable, closeModal }));
    } else if (existingVariables.some(existingVariable => existingVariable.name === selectedVariable.name)) {
      dispatch(showErrorNotification({ error: { message: 'The variable name is already used' } }));
    } else {
      dispatch(addVariableRequest({ ...selectedVariable, closeModal }));
    }
  };

  const closeModal = () => {
    dispatch(selectVariable({}));
    onClose();
  };

  return isOpen
    ? createPortal(
        <div className={styles.variablePopup}>
          <div className={styles.body}>
            <div className={styles.header}>
              {selectedVariable.id ? variablesLocalization.editVariable() : variablesLocalization.addVariable()}
              <AppIconButton onClick={closeModal}>
                <CrossIcon fill='#5C5C6E' />
              </AppIconButton>
            </div>

            <div className={styles.inputContainer}>
              <AppInput
                label='Variable name'
                value={selectedVariable.name}
                onChange={event => handleVariableChange('name', event.target.value.replace(/\s/g, ''))}
              />
              <AppNewSelect
                value={selectedVariable.type}
                label='Data type'
                options={dataTypeOptions}
                onChange={selection => handleVariableChange('type', selection.value)}
              />

              {selectedVariable.type === 'Boolean' ? (
                <AppNewSelect
                  label='Default state'
                  value={selectedVariable.defaultValue?.toString()}
                  options={defaultBooleanOptions}
                  onChange={selection => {
                    handleVariableChange('defaultValue', selection.value === 'true');
                  }}
                />
              ) : (
                <AppInput
                  label='Default state'
                  value={selectedVariable.defaultValue}
                  onChange={event => handleVariableChange('defaultValue', event.target.value)}
                />
              )}
            </div>
            <div className={styles.actions}>
              <AppButton onClick={closeModal}>{genericLocalization.cancel()}</AppButton>
              <AppButton type='primary' disabled={isSaveDisabled} onClick={save}>
                {genericLocalization.save()}
              </AppButton>
            </div>
          </div>
        </div>,
        document.getElementById('root')
      )
    : '';
}
