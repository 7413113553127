export function TabletIcon() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_8106_90710)'>
        <path
          d='M12.3359 0.5H3.0026C2.56058 0.5 2.13665 0.675595 1.82409 0.988155C1.51153 1.30072 1.33594 1.72464 1.33594 2.16667V14.8333C1.33594 15.0522 1.37905 15.2689 1.4628 15.4711C1.54656 15.6733 1.66933 15.8571 1.82409 16.0118C1.97886 16.1666 2.16259 16.2894 2.3648 16.3731C2.56701 16.4569 2.78373 16.5 3.0026 16.5H12.3359C12.5548 16.5 12.7715 16.4569 12.9737 16.3731C13.176 16.2894 13.3597 16.1666 13.5144 16.0118C13.6692 15.8571 13.792 15.6733 13.8757 15.4711C13.9595 15.2689 14.0026 15.0522 14.0026 14.8333V2.16667C14.0026 1.72464 13.827 1.30072 13.5144 0.988155C13.2019 0.675595 12.778 0.5 12.3359 0.5ZM7.66927 15.8333C7.11594 15.8333 6.66927 15.3867 6.66927 14.8333C6.66927 14.28 7.11594 13.8333 7.66927 13.8333C8.2226 13.8333 8.66927 14.28 8.66927 14.8333C8.66927 15.3867 8.2226 15.8333 7.66927 15.8333ZM12.6693 13.1667H2.66927V2.5H12.6693V13.1667Z'
          fill='url(#paint0_linear_8106_90710)'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_8106_90710' x1='1.33594' y1='0.5' x2='17.026' y2='4.43017' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F8F5FF' />
          <stop offset='0.515625' stopColor='white' />
          <stop offset='1' stopColor='#F6F4FF' />
        </linearGradient>
        <clipPath id='clip0_8106_90710'>
          <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
