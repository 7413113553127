import { ROW_KEY } from './utils';
import { v4 as uuidv4 } from 'uuid';
import { arrToIndexedObj } from '../../../../../../../lib/util/helpers';

export function getTableGenericRow({ row, ...rest }) {
  return {
    [ROW_KEY]: uuidv4(),
    bgColor: {
      ...row?.bgColor,
      value: row?.bgColor?.value === 'none' ? 'white' : row?.bgColor?.value,
    },
    ...arrToIndexedObj(row?.cells),
    ...rest,
  };
}
