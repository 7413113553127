/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { ImageOption } from '../ImageOption';
import { ImagesIcon } from '../../../../../../ui/icons/ImagesIcon';
import { ChevronDownIcon } from '../../../../../../ui/icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../../../ui/icons/ChevronUpIcon';
import { checkObjectWithValue } from '../../../../../../lib/util/helpers';
import { SelectImages } from '../../../../../../lib/models/SelectImages';
import './styles.scss';

// eslint-disable-next-line max-lines-per-function
export function ImageSelect({ valueObject, onChange, label, mediaOptions, selectBackgroundImageActive = {} }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const [visible, setVisible] = useState(false);
  const [selectWidth, setSelectWidth] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState(mediaOptions);
  const selectRef = useRef();

  const handleSearch = event => {
    if (event.target.value) {
      const filteredOptions = mediaOptions.filter(mediaObj => mediaObj.originalFileName && mediaObj.originalFileName.includes(event.target.value));
      setOptions(filteredOptions);
    } else {
      setOptions(mediaOptions);
    }
    setSearchValue(event.target.value);
  };

  const { inputPlaceholdersByTypes } = useLocalization().translations.shared.arranges.filter;

  useEffect(() => {
    if (selectRef?.current) {
      const { width } = selectRef.current.getBoundingClientRect();
      setSelectWidth(width);
    }
  }, [visible]);

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: selectWidth,
    maxHeight: 404,
    minHeight: 246,
    padding: '12px 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
  };
  const handleVisibleChange = flag => setVisible(flag);

  if (!checkObjectWithValue(valueObject)) {
    return null;
  }
  const checkMedia = value => value && mediaOptions.some(media => media.id === value);
  const checkLowSaturationBackgroundUrl = () => checkMedia(selectBackgroundImageActive.value) && valueObject.value === SelectImages.LOW_SATURATION;
  const usualBackgroundUrl = checkMedia(valueObject.value) ? mediaOptions.find(media => media.id === valueObject.value).s3Url : null;

  const lowSaturationBackgroundUrl = checkLowSaturationBackgroundUrl()
    ? mediaOptions.find(media => media.id === selectBackgroundImageActive.value).s3Url
    : null;

  const isMedia = checkMedia(valueObject.value);

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement='bottom'
      getPopupContainer={trigger => trigger.parentNode}
      onOpenChange={handleVisibleChange}
      disabled={isViewMode}
      dropdownRender={() => (
        <div style={contentStyle}>
          <AppFormSection
            size='small'
            rootProps={{ style: { margin: 0 } }}
            formItemProps={{ hasFeedback: false, style: { minHeight: 'unset' } }}
            textFieldProps={{ label: inputPlaceholdersByTypes.list, onChange: handleSearch, value: searchValue }}
          />
          <div className='image_select_options_wrap'>
            {options.map(imageObj => (
              <ImageOption
                key={imageObj.id}
                s3Url={
                  checkMedia(selectBackgroundImageActive.value) && imageObj.id === SelectImages.LOW_SATURATION
                    ? mediaOptions.find(media => media.id === selectBackgroundImageActive.value).s3Url
                    : imageObj.s3Url
                }
                id={imageObj.id}
                fileName={imageObj.originalFileName}
                value={valueObject.value}
                onSelect={onChange}
                isOpacity={imageObj.id === SelectImages.LOW_SATURATION}
              />
            ))}
          </div>
        </div>
      )}
    >
      <div
        ref={selectRef}
        className={classNames('image_select_wrap', 'align_items_center', {
          disabled: isViewMode,
          image_select_wrap__open: visible,
          image_select_wrap__closed: !visible,
        })}
      >
        <div
          className={classNames('image_select_picture', { image_select_picture__not_selected: !isMedia, disabled: isViewMode })}
          style={
            isMedia
              ? {
                  backgroundImage: `url('${checkLowSaturationBackgroundUrl() ? lowSaturationBackgroundUrl : usualBackgroundUrl}')`,
                  opacity: valueObject.value === SelectImages.LOW_SATURATION ? '0.6' : '1',
                }
              : {}
          }
        >
          {!isMedia && <ImagesIcon />}
        </div>

        {isMedia && (
          <AppText ellipsis className='image_select_text' disabled={isViewMode}>
            {mediaOptions.find(mediaObj => mediaObj.id === valueObject.value).originalFileName}
          </AppText>
        )}

        <AppText ellipsis={!isMedia} className={isMedia ? 'image_select_text_label__selected' : 'image_select_text_label'} textValue={label} />

        {visible ? <ChevronUpIcon fill='#5C5C6E' /> : <ChevronDownIcon fill='#5C5C6E' />}
      </div>
    </Dropdown>
  );
}
