import { useSelector } from 'react-redux';
import { ContentTop } from '../ContentTop';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { LayoutTemplates } from './LayoutTemplates';
import { Explanation } from '../Explanation';

/**
 * @desc The layout is responsible for the positioning and styles of the elements in the template
 * @returns {JSX.Element}
 */
export function LayoutContent() {
  const { selectedQuestionnaireElement } = useSelector(({ finders }) => finders);
  const { title, tooltipText, explanation } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout;

  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <ContentTop title={title} tooltipText={tooltipText} />
      {selectedQuestionnaireElement ? <LayoutTemplates /> : <Explanation descriptionText={explanation.description} />}
    </div>
  );
}
