import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { useState } from 'react';
import { useArrangeSubmit } from '../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { useRuleArrangeRequests } from '../../lib/useRuleArrangeRequests';
import { ArrangeListPopup } from '../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../components/TableHeaderCell';
import { getRuleData } from './rule-search-data-utils';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../utils/arranges-helpers';

/**
 * @desc Rule Search Data Header Cell
 * @param {String} arrangedId
 * @param {String} title
 * @param {String} searchFieldName
 * @param {String} filterFieldName
 * @param {Object} headerCellProps
 * @returns {JSX.Element}
 */
export function RuleSearchDataHeaderCell({ arrangedId, title, searchFieldName, filterFieldName, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ rules }) => rules);
  const {
    finder: { id: finderId },
  } = useSelector(({ finders }) => finders);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));
  const { filtering } = useStringArrange({
    form,
    request: value => getRuleData(tenantId, finderId, searchFieldName, value),
    formatResponse: response => formatArrangedData(response, arrangedId),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useRuleArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
