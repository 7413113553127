import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { BulletPointsBlock } from './BulletPointsBlock';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import '../../style.scss';
import { DroppableFieldWrapper } from '../../../DroppableItems/DroppableFieldWrapper';
import { DroppableImageWrapper } from '../../../DroppableItems/DroppableImageWrapper';
import { ComparisonDetailTable } from './ComparisonDetailTable';

// eslint-disable-next-line max-lines-per-function
export function ContentBlock({ index, structureItemIndex, contentBlock, contentBlocks, handleChangeField, item, onDeleteInfoIcon }) {
  const {
    contentHeadline,
    contentSubline,
    selectBackgroundImage,
    bulletPointIcon,
    bulletPoints,
    isComparisonDetailTable,
    productRow,
    productOptions,
    comparisonOptions,
    detailTables,
  } = contentBlock;
  const { templateHeadline, templateSubline, selectImage, templateInfo } = useLocalization().translations.unique.formItems;
  const { contentBlock: contentBlockText } = useLocalization().translations.unique.pages.questionnaire.content;
  const {
    finder: {
      brand: { configuration },
      finderSettings: { media, bulletIcons },
    },
  } = useSelector(({ finders }) => finders);

  const handleChangeFieldValue = fieldName => value => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[index] = { ...contentBlock, [fieldName]: { ...contentBlock[fieldName], value } };
    handleChangeField(updatedContentBlocks);
  };

  const handleChangeFieldInfoIconValue = fieldName => value => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[index] = {
      ...contentBlock,
      [fieldName]: { ...contentBlock[fieldName], infoIcon: { ...contentBlock[fieldName].infoIcon, value } },
    };
    handleChangeField(updatedContentBlocks);
  };

  const handleDeleteInfoIconContentBlocks = fieldName => {
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[index] = {
      ...contentBlock,
      [fieldName]: { ...contentBlock[fieldName], infoIcon: null },
    };
    onDeleteInfoIcon(updatedContentBlocks);
  };

  return (
    <div className='template_card_content_block'>
      {!isComparisonDetailTable && <AppText className='template_card_headline' textValue={contentBlockText} />}

      {contentHeadline && !contentHeadline.isHidden && (
        <DroppableFieldWrapper
          itemId={item.id}
          labelField={templateHeadline.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.contentBlocks[index].contentHeadline?.infoIcon && 'INFO_ICON'}
          valueObject={contentHeadline}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
          onDeleteInfoIcon={handleDeleteInfoIconContentBlocks}
          fieldName='contentHeadline'
          infoIcon={item.content.contentBlocks[index].contentHeadline?.infoIcon}
          contentBlocks='contentBlocks'
          contentBlockIndex={index}
        />
      )}
      {contentSubline && !contentSubline.isHidden && (
        <DroppableFieldWrapper
          itemId={item.id}
          labelField={templateSubline.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.contentBlocks[index].contentSubline?.infoIcon && 'INFO_ICON'}
          valueObject={contentSubline}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
          onDeleteInfoIcon={handleDeleteInfoIconContentBlocks}
          fieldName='contentSubline'
          infoIcon={item.content.contentBlocks[index].contentSubline?.infoIcon}
          contentBlocks='contentBlocks'
          contentBlockIndex={index}
        />
      )}
      {selectBackgroundImage && (
        <DroppableImageWrapper
          itemId={item.id}
          labelField={selectImage.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.contentBlocks[index].selectBackgroundImage.infoIcon && 'INFO_ICON'}
          valueObject={selectBackgroundImage}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={handleChangeFieldInfoIconValue}
          onDeleteInfoIcon={handleDeleteInfoIconContentBlocks}
          fieldName='selectBackgroundImage'
          infoIcon={item.content.contentBlocks[index].selectBackgroundImage.infoIcon}
          media={media}
          contentBlocks='contentBlocks'
          contentBlockIndex={index}
        />
      )}
      {bulletPoints && (
        <BulletPointsBlock
          contentBlockIndex={index}
          contentBlock={contentBlock}
          contentBlocks={contentBlocks}
          bulletPoints={bulletPoints}
          bulletPointIcon={bulletPointIcon}
          handleChangeField={handleChangeField}
          item={item}
        />
      )}
      {isComparisonDetailTable && (
        <ComparisonDetailTable
          productRow={productRow}
          productOptions={productOptions}
          comparisonOptions={comparisonOptions}
          detailTables={detailTables}
          contentBlockIndex={index}
          contentBlock={contentBlock}
          contentBlocks={contentBlocks}
          handleChangeField={handleChangeField}
          bulletIcons={bulletIcons}
          configuration={configuration}
          item={item}
          structureItemIndex={structureItemIndex}
          handleChangeFieldValue={handleChangeFieldValue}
        />
      )}
    </div>
  );
}
