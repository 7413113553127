import { FinderNumberHeaderCell } from './FinderNumberHeaderCell';
import { AmountBodyCell } from '../../../../../../../../components/AmountBodyCell';
import styles from '../../../../../../../../utils/styles/table.module.sass';

export function useFinderNumberColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    className: styles.numberBodyColumn,
    title: <FinderNumberHeaderCell {...rest} />,
    render: amount => <AmountBodyCell amount={amount} />,
  };
}
