import { useSelector } from 'react-redux';
import { ContentTop } from '../ContentTop';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { Explanation } from '../Explanation';
import { AppPreloader } from '../../../../../../../../../ui/AppPreloader';
import { QuestionScoring } from './QuestionScoring';
import { TemplateTypes } from '../../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../../lib/util/helpers';
import styled from 'styled-components';

/**
 * @desc Checks if any item in the array has a specific template type.
 * @param {Array} items - An array of items to check.
 * @returns {boolean} True if any item has the template type; otherwise, false.
 */
const checkQuestionElement = items => items.some(item => item.templateType === TemplateTypes.QUESTION);

/**
 * @desc Renders the score content based on questionnaire structure and product loading status.
 * @returns {JSX.Element} The rendered score content component.
 */
export function ScoreContent() {
  const { questionnaireStructure, tenantProducts, isProductsLoading } = useSelector(({ finders }) => finders);
  const { title, tooltipText, explanation } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  return (
    <Root>
      <ContentTop title={title} tooltipText={tooltipText} />
      <StyledMain>
        {isProductsLoading ? (
          <AppPreloader />
        ) : isLength(tenantProducts) && checkQuestionElement(questionnaireStructure) ? (
          <QuestionScoring />
        ) : (
          <Explanation descriptionText={explanation.description} />
        )}
      </StyledMain>
    </Root>
  );
}

const Root = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const StyledMain = styled('div')({
  display: 'flex',
  minHeight: 'calc(100vh - 220px)',
});
