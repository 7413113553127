import { AppTitle } from '../../../../../../../../ui/text/AppTitle';
import { MailServerCard } from './MailServerCard';
import { DataProtectionCard } from './DataProtectionCard';
import { BasicDataCard } from './BasicDataCard';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { TrackingCard } from './TrackingCard';

export function GeneralSettingsForm({ form }) {
  const { settings } = useLocalization().translations.unique.pages;

  return (
    <>
      <AppTitle level={3} textValue={settings.general.title} />
      <BasicDataCard form={form} />
      <MailServerCard form={form} />
      <TrackingCard form={form} />
      <DataProtectionCard form={form} />
    </>
  );
}
