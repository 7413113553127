import { useCallback } from 'react';
import { useScrollToCardOnNonMobile } from './useScrollToCardOnNonMobile';
import { maybeScrollToCardOnMobile } from '../utils';

export function useScrollToCard({ setDragging, carouselRef, cardIndex, windowWidth }) {
  const scrollToCardOnNonMobile = useScrollToCardOnNonMobile(setDragging, carouselRef);

  return useCallback(() => {
    scrollToCardOnNonMobile(cardIndex);

    maybeScrollToCardOnMobile(windowWidth, cardIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardIndex, scrollToCardOnNonMobile]);
}
