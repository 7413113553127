import { axiosInstance, publicAxiosInstance } from './util/http/axios/axios-instance';
import { PAGINATION_ELEMENTS_AMOUNT } from '../processes/CMS/lib/consts';
import { isTruthy } from './util/helpers';

export const getTenantsApiRequest = ({ params, ...config }) => {
  return axiosInstance('tenant/database-config/get-tenants', {
    ...config,
    method: 'POST',
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export const getTenantByIdApiRequest = tenantId => axiosInstance.get(`/tenant/database-config/${tenantId}`, withXTenantHeader(tenantId));

export const getFindersFromApi = (tenantId, { params, ...restConfig }) => {
  return axiosInstance('/finder/get-finders', {
    ...restConfig,
    method: 'POST',
    ...withXTenantHeader(tenantId),
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export function generatePdfApiRequest(data, pdfType) {
  return publicAxiosInstance.post('global/public/generate-pdf', data, {
    params: { pdfType },
  });
}

export async function generatePdfRequest(payload) {
  const res = await fetch('/pdf-generator', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

  let pdfblob = '';

  try {
    pdfblob = await res.blob();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  // eslint-disable-next-line no-console
  console.log('pdf blob', pdfblob);

  return pdfblob;
}

export function getPdfLinkApiRequest(id) {
  return publicAxiosInstance.get(`global/public/get-pdf-link/${id}`);
}

export const getCategoriesFromApi = (tenantId, { params, ...restConfig } = {}) => {
  return axiosInstance('/category/get-categories', {
    ...restConfig,
    method: 'POST',
    ...withXTenantHeader(tenantId),
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export const getProductsFromApi = (tenantId, { params, ...restConfig } = {}) => {
  return axiosInstance('/product/get-products', {
    ...restConfig,
    method: 'POST',
    ...withXTenantHeader(tenantId),
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export const getThemesApiRequest = ({ params, data, ...restConfig }) => {
  return axiosInstance.post('/brand/get-brands', data, {
    ...restConfig,
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export const getRulesApiRequest = (tenantId, { params, ...restConfig }) => {
  return axiosInstance('/rule/get-rules', {
    ...restConfig,
    method: 'POST',
    ...withXTenantHeader(tenantId),
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
};

export const AuthURI = {
  LOG_IN: 'authv2/authenticateV2',
  UPDATE_ACCESS_TOKEN: 'authv2/authenticateV2/refreshtoken',
  SET_PASSWORD: 'account/set-password',
};

export const withXTenantHeader = id => {
  return isTruthy(id) ? { headers: { 'X-TENANT': id } } : {};
};

export const getUsersFromApi = ({ params, ...config }) => {
  return axiosInstance(
    'admin/get-users',
    Object.assign(config, {
      method: 'POST',
      params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
    })
  );
};

export function updateAccessTokenApiRequest(refreshToken) {
  return axiosInstance.post(AuthURI.UPDATE_ACCESS_TOKEN, { refreshToken });
}

export function uploadMediaApiRequest(formData, tenantId) {
  return axiosInstance.post('media', formData, { 'content-type': 'multipart/form-data; ', ...withXTenantHeader(tenantId) });
}

export function getLibraryTemplatesApiRequest({ params, data, ...restConfig }) {
  return axiosInstance.post('template/get-templates', data, {
    ...restConfig,
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
}

export function getLayoutsApiRequest({ params, data, ...restConfig }) {
  return axiosInstance.post('layout/get-layouts', data, {
    ...restConfig,
    params: { size: PAGINATION_ELEMENTS_AMOUNT, ...params },
  });
}

export function getVariablesApiRequest(tenantId, finderId) {
  return axiosInstance.post('custom-variable/get-custom-variables', { finderId }, { ...withXTenantHeader(tenantId), params: { size: 10000000 } });
}

export function addVariablesApiRequest(tenantId, payload) {
  return axiosInstance.post('custom-variable', payload, { ...withXTenantHeader(tenantId) });
}

export function updateVariableApiRequest(tenantId, { payload }) {
  return axiosInstance.put(`custom-variable/${payload.id}`, { ...payload, value: payload.defaultValue }, { ...withXTenantHeader(tenantId) });
}

export function deleteVariablesApiRequest(tenantId, { payload }) {
  return axiosInstance.delete(`custom-variable/${payload}/delete`, { ...withXTenantHeader(tenantId) });
}
