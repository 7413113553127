/* eslint-disable no-magic-numbers,max-lines */
import { v4 as uuidv4 } from 'uuid';
import { ChapterState, DestinationVariants, TemplateTypes } from '../../../../../lib/models/TemplateTypes';
import { ComparisonTableDraggableId } from '../pages/QuestionnairePage/components/QuestionnaireContent/ContentTableSection/comparison-table-utils';
import { checkHaveAllTablesSameColumnsAmount } from './table-helpers';
import { updateInfoIconsIndexes } from '../pages/QuestionnairePage/utils/helpers';
import { FlowOperation } from '../../../../../lib/models/Questionnaire';
import { safeJsonParse } from '../../../../../lib/util/helpers';

/**
 * @desc The function will change the position of templates in the search engine.
 * If you drag a section, then all elements to the next section that are under the section will be dragged together with the section
 * @param {Array} list
 * @param {Number} startIndex
 * @param {Number} endIndex
 * @returns {Array}
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const chapterObject = list.find((item, index) => index === startIndex && item.templateType === TemplateTypes.CHAPTER);
  // If it is a chapter, then all elements that are under it and are not a chapters are moved together with the chapter that is dragged
  if (chapterObject) {
    let lastIndex = null;
    for (let i = startIndex + 1; i <= list.length; i += 1) {
      lastIndex += 1;
      if (list[i]?.templateType === TemplateTypes.CHAPTER || !list[i]) {
        break;
      }
    }
    const [...removed] = result.splice(startIndex, lastIndex);
    result.splice(endIndex, 0, ...removed);
  } else {
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  }
  return result;
};

/**
 * @desc The function will change the position
 * @param {Array} list
 * @param {Object} source
 * @param {Object} destination
 * @returns {Array}
 */
export const reorderFn = (list, source, destination) => {
  const result = [...list];
  const [removed] = result.splice(source.index, 1);
  result.splice(destination.index, 0, removed);
  return result;
};

/**
 * @desc The function will change the position bullet points
 * @param {Array} questionnaireStructure
 * @param {Object} source
 * @param {Object} destination
 * @returns {Array}
 */
export const reorderBulletPoints = (questionnaireStructure, source, destination) => {
  const itemId = source.droppableId.split('_')[0];
  const contentBlockIndex = source.droppableId.split('_')[1];

  return questionnaireStructure.map(item => {
    if (item.id === itemId) {
      return {
        ...item,
        content: {
          ...item.content,
          contentBlocks: item.content.contentBlocks.map((element, index) => {
            return index === Number(contentBlockIndex)
              ? {
                  ...element,
                  bulletPoints: reorderFn(element.bulletPoints, source, destination),
                }
              : element;
          }),
        },
      };
    }
    return item;
  });
};

/**
 * @desc The function will change the position question answers
 * @param {Array} questionnaireStructure
 * @param {Object} source
 * @param {Object} destination
 * @param {String} itemId
 * @param {fieldName} itemId
 * @returns {Array}
 */
export const reorderQuestionAnswers = (questionnaireStructure, source, destination, itemId, fieldName) => {
  return questionnaireStructure.map(item => {
    if (item.id === itemId) {
      if (Array.isArray(item.content.productValues)) {
        return {
          ...item,
          content: {
            ...item.content,
            [fieldName]: reorderFn(item.content[fieldName], source, destination),
            productValues: item.content.productValues.map(prodVal => ({
              ...prodVal,
              prices: reorder(prodVal.prices, source.index, destination.index),
            })),
          },
        };
      }
      return {
        ...item,
        content: { ...item.content, [fieldName]: reorderFn(item.content[fieldName], source, destination) },
      };
    }
    return item;
  });
};

/**
 * @desc The function will change the position rules
 * @param {Array} questionnaireStructure
 * @param {Object} source
 * @param {Object} destination
 * @returns {Array}
 */
export const reorderRules = (questionnaireStructure, source, destination) => {
  const itemId = source.droppableId;

  return questionnaireStructure.map(item => {
    if (item.id === itemId) {
      return { ...item, rules: reorderFn(item.rules, source, destination) };
    }
    return item;
  });
};

/**
 * @desc The function will change the position topics
 * @param {Array} questionnaireStructure
 * @param {Object} data
 * @param {Object} source
 * @param {Object} destination
 * @returns {Array}
 */
export const reorderTopics = (questionnaireStructure, data, source, destination) => {
  const currentDetailTables = [...questionnaireStructure[data.structureItemIndex].content.contentBlocks[0].detailTables];

  const updatedTopics = reorderFn(
    [...questionnaireStructure[data.structureItemIndex].content.contentBlocks[0].detailTables[data.tableIndex].topics],
    source,
    destination
  );

  const updatedDetailTables = currentDetailTables.map((table, index) => {
    if (index === data.tableIndex) {
      return {
        ...table,
        topics: updatedTopics,
      };
    }

    return table;
  });

  return questionnaireStructure.map((structureItem, index) => {
    if (index === data.structureItemIndex) {
      return {
        ...structureItem,
        content: {
          ...structureItem.content,
          contentBlocks: [
            {
              ...structureItem.content.contentBlocks[0],
              detailTables: updatedDetailTables,
            },
          ],
        },
      };
    }

    return structureItem;
  });
};

/**
 * @desc The function add page in finder
 * @param {Array} destination
 * @param {Object} droppableSource
 * @param {Object} droppableDestination
 * @returns {Array}
 */
export const copyPage = (destination, droppableSource, droppableDestination) => {
  const destClone = [...destination];
  destClone.splice(droppableDestination.index, 0, { id: uuidv4(), isOpen: true, templateType: TemplateTypes.PAGE, audio: null });
  return destClone;
};
export const getDefaultFlowObject = element => {
  if (element.templateType === TemplateTypes.CHAPTER) {
    return {
      ownStates: [ChapterState.OPENED],
      conditions: [{ condition: null, states: [], chapters: [] }],
      goTo: DestinationVariants.NEXT,
      showElements: [],
    };
  }
  if (element.templateType === TemplateTypes.QUESTION && element.content.answers) {
    return {
      conditions: [{ condition: null, ifAnswers: [], ifGoTo: DestinationVariants.NEXT, elseIfAnswers: [], elseIfGoTo: DestinationVariants.NEXT }],
      elseGoTo: DestinationVariants.NEXT,
      elseShowElements: [],
    };
  }
  if (element.templateType === TemplateTypes.QUESTION && element.content.counterAnswers && element?.flow?.goTo) {
    return { goTo: DestinationVariants.NEXT, showElements: [] };
  }
  if (element.templateType === TemplateTypes.QUESTION && element.content.counterAnswers) {
    return { counterAnswersFlowArr: [], counterAnswersElseGoTo: DestinationVariants.NEXT, counterAnswersElseShowElements: [] };
  }
  return { goTo: DestinationVariants.NEXT, showElements: [] };
};

/**
 * @desc The function add template in finder
 * @param {Array} destination
 * @param {Object} droppableSource
 * @param {Object} droppableDestination
 * @param {Array} composerItems
 * @returns {Array}
 */
export const copyTemplate = (destination, droppableSource, droppableDestination, composerItems) => {
  const parentIndex = Number(droppableSource.index.toString().split('')[0]);
  const childrenIndex = Number(droppableSource.index.toString().split('')[1]);
  const composerObj = composerItems.find((el, index) => index === parentIndex);
  const isIntroObject = destination.some(el => el.templateType === TemplateTypes.INTRO);
  const isChapterObject = destination.some(el => el.templateType === TemplateTypes.CHAPTER);
  const destClone = [...destination];
  const templateObj = composerObj.children[childrenIndex];
  const flowObject = getDefaultFlowObject(templateObj);
  const chapterFirstObj = destination.find(el => el.templateType === TemplateTypes.CHAPTER);
  const ifChapterLayout =
    isChapterObject && templateObj?.templateType === TemplateTypes.CHAPTER
      ? { layoutId: chapterFirstObj.layoutId, layoutStyle: chapterFirstObj.layoutStyle }
      : {};
  const ifChapterTemplate = templateObj.content?.chapterButton
    ? {
        ...templateObj,
        content: {
          ...templateObj.content,
          chapterButton: { ...templateObj.content.chapterButton, id: uuidv4(), rules: [] },
        },
      }
    : {};
  if (!isIntroObject && templateObj?.templateType === TemplateTypes.INTRO) {
    const introTemplateObj = {
      ...templateObj,
      content: {
        ...templateObj.content,
        primaryButton: { ...templateObj.content.primaryButton, id: uuidv4(), rules: [] },
        secondaryButton: { ...templateObj.content.secondaryButton, id: uuidv4(), rules: [] },
      },
    };
    destClone.splice(
      1,
      0,
      { ...introTemplateObj, id: uuidv4(), isOpen: true, flow: flowObject },
      { id: uuidv4(), audio: null, templateType: TemplateTypes.PAGE }
    );
  } else {
    destClone.splice(droppableDestination.index, 0, {
      ...templateObj,
      id: uuidv4(),
      isOpen: true,
      flow: flowObject,
      ...ifChapterLayout,
      ...ifChapterTemplate,
    });
  }

  return destClone;
};

/**
 * @desc The function add info icon to template in finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const copyInfoIcon = (destination, questionnaireStructure) => {
  const { droppableId } = destination;

  const fieldName = droppableId.split('_')[0];
  const elementId = droppableId.split('_')[1];
  const contentBlocks = droppableId.split('_')[2];
  const contentBlockIndex = droppableId.split('_')[3];
  const bulletPointIndex = droppableId.split('_')[4];

  if (contentBlocks === 'contentBlocks') {
    return questionnaireStructure.map(el => {
      if (el.id === elementId) {
        if (fieldName === 'bulletPoints') {
          return {
            ...el,
            content: {
              ...el.content,
              contentBlocks: el.content.contentBlocks.map((element, index) => {
                return index === Number(contentBlockIndex)
                  ? {
                      ...element,
                      bulletPoints: element.bulletPoints.map((item, idx) => {
                        if (idx === Number(bulletPointIndex)) {
                          return { ...item, infoIcon: { infoIconName: fieldName, value: '' } };
                        }
                        return item;
                      }),
                    }
                  : element;
              }),
            },
          };
        }
        return {
          ...el,
          content: {
            ...el.content,
            contentBlocks: el.content.contentBlocks.map((element, index) => {
              return index === Number(contentBlockIndex)
                ? { ...element, [fieldName]: { ...element[fieldName], infoIcon: { infoIconName: fieldName, value: '' } } }
                : element;
            }),
          },
        };
      }
      return el;
    });
  }

  return questionnaireStructure.map(el => {
    if (el.id === elementId) {
      return {
        ...el,
        content: {
          ...el.content,
          [fieldName]: { ...el.content[fieldName], infoIcon: { infoIconName: fieldName, value: '' } },
        },
      };
    }
    return el;
  });
};

/**
 * @desc The function add info icon to question (answer field) template in finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
export const copyInfoIconQuestionAnswer = (destination, questionnaireStructure) => {
  const { droppableId } = destination;

  const fieldName = droppableId.split('_')[0];
  const itemId = droppableId.split('_')[1];
  const answerId = droppableId.split('_')[2];

  return questionnaireStructure.map(item => {
    if (item.id === itemId) {
      return {
        ...item,
        content: {
          ...item.content,
          answers: item.content.answers.map(el => {
            if (answerId === el.id) {
              return { ...el, infoIcon: { infoIconName: fieldName, value: '' } };
            }
            return el;
          }),
        },
      };
    }
    return item;
  });
};

/**
 * @desc The function add info icon to question (chart answer field) template in finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
export const copyInfoIconQuestionChartAnswers = (destination, questionnaireStructure) => {
  const parsedDroppableId = safeJsonParse(destination.droppableId);
  const { fieldName, answerId, itemId } = parsedDroppableId;

  return questionnaireStructure.map(item => {
    if (item.id === itemId) {
      return {
        ...item,
        content: {
          ...item.content,
          chartAnswers: item.content.chartAnswers.map(el => {
            if (answerId === el.id) {
              return { ...el, infoIcon: { infoIconName: fieldName, value: '' } };
            }
            return el;
          }),
        },
      };
    }
    return item;
  });
};

/**
 * @desc The function add info icon to product template (total price field) in finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
export const copyInfoIconProductTotalPrice = (destination, questionnaireStructure) => {
  const { droppableId } = destination;
  const fieldName = droppableId.split('_')[0];
  const elementId = droppableId.split('_')[1];
  const productIndex = droppableId.split('_')[2];

  return questionnaireStructure.map(item => {
    if (item.id === elementId) {
      return {
        ...item,
        content: {
          ...item.content,
          products: item.content.products.map((element, index) => {
            if (index === Number(productIndex)) {
              return {
                ...element,
                priceSection: {
                  ...element.priceSection,
                  infoIcon: { infoIconName: fieldName, value: '' },
                },
              };
            }
            return element;
          }),
        },
      };
    }
    return item;
  });
};

/**
 * @desc The function add info icon to result template (price name field) in finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
export const copyInfoIconResultPriceNameField = (destination, questionnaireStructure) => {
  const { droppableId } = destination;
  const fieldName = droppableId.split('_')[0];
  const elementId = droppableId.split('_')[1];

  return questionnaireStructure.map(item => {
    if (item.id === elementId) {
      return {
        ...item,
        content: {
          ...item.content,
          productInformation: {
            ...item.content.productInformation,
            priceName: {
              ...item.content.productInformation.priceName,
              infoIcon: {
                infoIconName: fieldName,
                value: '',
              },
            },
          },
        },
      };
    }
    return item;
  });
};

/**
 * @desc The function add composer button to finder
 * @param {Array} destination
 * @param {Array} questionnaireStructure
 * @returns {Array}
 */
export const copyComposerButton = (destination, questionnaireStructure) => {
  const { droppableId } = destination;
  const itemId = droppableId.split('_')[0];
  return questionnaireStructure.map(item =>
    item.id === itemId
      ? {
          ...item,
          content: {
            ...item.content,
            composerButton: {
              id: uuidv4(),
              rules: [],
              name: 'composerButton',
              value: '',
              flow: { targetBlank: true, href: null, goTo: null, operation: FlowOperation.GO_TO_ELEMENT, showElements: [] },
            },
          },
        }
      : item
  );
};

/**
 * @desc Drag-n-drop table columns
 * @param {Function} updateStructure
 * @param {Object} result
 * @returns {Array}
 */
export function dndTableColumns(updateStructure, result) {
  const { destination, source, draggableId } = result;
  const parsed = JSON.parse(draggableId);
  const { [ComparisonTableDraggableId.STRUCTURE_INDEX]: structureIndex, [ComparisonTableDraggableId.TABLE_INDEX]: tableIndex } = parsed;

  updateStructure(draft => {
    const { tables: tableList } = draft[structureIndex].content;
    const currentTable = tableList[tableIndex];

    if (checkHaveAllTablesSameColumnsAmount(tableList)) {
      for (const table of tableList) {
        const { tableRows } = table;
        for (const row of tableRows) {
          const [removedItem] = row.cells.splice(source.index, 1);
          row.cells.splice(destination.index, 0, removedItem);
        }
      }
    } else {
      const { tableRows } = currentTable;
      for (const row of tableRows) {
        const [removedItem] = row.cells.splice(source.index, 1);
        row.cells.splice(destination.index, 0, removedItem);
      }
    }

    updateInfoIconsIndexes(currentTable.tableRows);
  });
}

/**
 * @desc Drag-n-drop table row
 * @param {Function} updateStructure
 * @param {Object} result
 * @returns {Array}
 */
export function dndTableRow(updateStructure, result) {
  const { destination, source, draggableId } = result;
  const parsedSourceDroppableId = JSON.parse(source.droppableId);
  const parsedDestinationDroppableId = JSON.parse(destination.droppableId);
  const disallowDndARowFromOneTableToAnother =
    parsedSourceDroppableId[ComparisonTableDraggableId.TABLE_INDEX] !== parsedDestinationDroppableId[ComparisonTableDraggableId.TABLE_INDEX];

  if (disallowDndARowFromOneTableToAnother) return;

  const parsedDraggableId = JSON.parse(draggableId);
  const { [ComparisonTableDraggableId.STRUCTURE_INDEX]: structureIndex, [ComparisonTableDraggableId.TABLE_INDEX]: tableIndex } = parsedDraggableId;

  updateStructure(draft => {
    const { tableRows } = draft[structureIndex].content.tables[tableIndex];
    const [removed] = tableRows.splice(source.index, 1);
    tableRows.splice(destination.index, 0, removed);
    updateInfoIconsIndexes(tableRows);
  });
}
