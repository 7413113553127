import { Form } from 'antd';
import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { EditCategoryForm } from './EditCategoryForm';
import { useResetFormOnCloseModal } from '../../../../../../../../../lib/hooks/useResetFormOnCloseModal';
import { useEffect } from 'react';
import { CategoryFormItem } from '../../lib/Category';

function getFieldsData(userData) {
  return [
    { name: CategoryFormItem.CATEGORY_NAME, value: userData.name },
    { name: CategoryFormItem.MULTI_PRODUCT, value: userData.multiProduct },
  ];
}

export function EditCategoryModal({ close, isOpen, isModalLoading, categoryData, tenantId }) {
  const { title } = useLocalization().translations.unique.pages.category.editModal;
  const [form] = Form.useForm();

  useResetFormOnCloseModal({ form, isOpen });

  useEffect(() => {
    form.setFields(getFieldsData(categoryData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, categoryData]);

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title({ categoryName: categoryData.name }) }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: form.submit, loading: isModalLoading }}
    >
      {isOpen && <EditCategoryForm form={form} categoryData={categoryData} tenantId={tenantId} />}
    </AppModal>
  );
}
