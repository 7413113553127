import { Radio } from 'antd';
import styled from 'styled-components';
import { forwardRef } from 'react';
import { AppBox } from './boxes/AppBox';

export const AppRadioGroup = forwardRef(({ as, ...rest }, ref) => {
  return <Root {...rest} ref={ref} forwardedAs={Radio.Group} />;
});

const Root = styled(AppBox)({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    marginBottom: '13px',
    ':last-of-type': {
      marginBottom: '0',
    },
  },
});
