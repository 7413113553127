import { Form } from 'antd';
import { FormItemName } from '../../../../../../../../../lib/models/Form';

export function useFinderSubdomain(form) {
  const domainValue = Form.useWatch(FormItemName.DOMAIN, form);
  const subdomainValue = Form.useWatch(FormItemName.SUBDOMAIN, form);
  const maybeDomain = domainValue ? domainValue.value : '';
  const maybeSubdomain = subdomainValue ? `${subdomainValue}.` : '';
  const finderURL = `https://${maybeSubdomain}${maybeDomain}`;

  return {
    finderURL,
  };
}
