/* eslint-disable max-lines-per-function,sonarjs/cognitive-complexity,max-lines,no-unsafe-optional-chaining,no-param-reassign */
import { Product } from '../FindersAndProductsPage/lib/Product';
import { ProductId, QuestionElementId, StructureItemId } from './models/Hardcode';
import { findIndexesMatchingCondition, isLength, removeProductsDuplicates, updateRulesResultProducts } from '../../../../../../lib/util/helpers';
import { priceScoringSort } from '../../utils/end-user-flow-helpers';
import { ResultPriceType, ResultSortProductsByType } from '../../utils/consts';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';
import { rulesEngine } from '../../utils/rules/rules_helper';
import { getPriceWithCounterValue, getRulesProductPrice } from '../../utils/rules/product_price_rules_helper';
import { v4 as uuidv4 } from 'uuid';
import { getRulesProductCounter } from '../../utils/rules/product_counter_rules_helper';

export const isProductMultipleAmount = product => product?.counterSection?.value > 1;

export const canShowProductInResult = product => product?.counterSection?.value > 0 || product?.selected;

// TODO hardcode: ids for KONTOFINDER (P9.1 and P10.1)
const KONTOFINDER_PK_PRODUCT_9_1_ID = 'e344a746-d246-451e-872b-8aea09aeb317';
const KONTOFINDER_PK_PRODUCT_10_1_ID = '252469e3-3cde-4fb2-ba90-d464a444b4ca';
// TODO hardcode: value for P9 and P10
const MULTIPLY_VALUE_P9 = 12;
const MULTIPLY_VALUE_P10 = 24;

// TODO hardcode: for P7.1 and P7.2
//  TODO price for P7.1
const PRICE_PRODUCT_7_1_COUNTER_1 = 50;
const PRICE_PRODUCT_7_1_COUNTER_MORE_1 = 89;
//  TODO price for P7.2
const PRICE_PRODUCT_7_2_COUNTER_1 = 0;
const PRICE_PRODUCT_7_2_COUNTER_MORE_1 = 29;

// TODO ids for P7.1 and P7.2
const KONTOFINDER_PK_PRODUCT_7_1_ID = 'cae08004-665e-4d1a-99c2-cf9a4465faaf';
const KONTOFINDER_PK_PRODUCT_7_2_ID = '15b32346-df0f-4036-ac9e-95bfa3031305';

function getResultProductsByPriority(products, priorityIds) {
  const productMap = new Map(products.map(product => [product.productNameSection.product.value, product]));
  const priorityProducts = priorityIds.map(id => productMap.get(id)).filter(product => product !== undefined);
  const result = [...priorityProducts, ...products];
  const uniqueResult = [];
  for (const el of result) {
    if (!uniqueResult.some(item => el.id === item.id)) {
      uniqueResult.push(el);
    }
  }
  return uniqueResult;
}

function groupBySameValueAndSumThem(array) {
  return Object.values(
    array.reduce((acc, curr) => {
      if (!acc[curr.rulePrice]) {
        acc[curr.rulePrice] = [];
      }
      acc[curr.rulePrice].push(curr);
      return acc;
    }, {})
  ).map(el => {
    return el.reduce(
      (acc, curr) => {
        acc.rulePrice += Number(curr.rulePrice);
        return acc;
      },
      { id: uuidv4(), rulePrice: 0, ruleAmount: el.length }
    );
  });
}

export function getRulePriceArray(curr, pagesFlow, priceType) {
  const rulePriceArray = [];
  const priceArray = [];
  for (let i = 1; i <= curr.counterSection?.value; i += 1) {
    const { rules, id, priceSection } = curr;
    const { priceProduct } = rulesEngine({ rules, pagesFlow, currentElements: [], rulesFilter: ['priceProduct'], logger: false });
    const cardPriceBlocks = priceProduct.filter(block => block?.priceProduct?.elements?.map(el => el.productId)?.includes(id));
    const { rulePrice, rulePriceType } = getPriceWithCounterValue(cardPriceBlocks, i, priceSection);
    if ((rulePrice === 0 || rulePrice) && rulePriceType === priceType) {
      rulePriceArray.push({ id, ...getPriceWithCounterValue(cardPriceBlocks, i, priceSection) });
    } else {
      priceArray.push({ id, rulePriceType: 'pricePerYear', rulePrice: Number(priceSection.priceValue) });
    }
  }
  return { rulePriceArray, priceArray };
}

const getPriceValue = (el, elementRulePrice = '') => {
  if (elementRulePrice || elementRulePrice === 0) {
    return elementRulePrice;
  }
  if (el?.priceSection?.priceType === Product.PRICE_PRE_REQUEST) {
    return el?.priceSection.priceValue;
  }
  if (Number(el?.counterSection?.value) > 0 && Number(el?.priceSection.priceValue) > 0) {
    return Number(el?.counterSection?.value) * Number(el?.priceSection.priceValue);
  }
  return Number(el?.priceSection?.priceValue);
};

// TODO remove hardcode when add rule builder
export const getResultProductsWithSplitPrices = (newResultProducts, Q6, productInformation, pagesFlow) => {
  return newResultProducts.reduce((acc, curr, idx, array) => {
    const {
      ruleCounter,
      // originalCounter
    } = getRulesProductCounter(curr, pagesFlow, [], false);
    const amountCardsWithFreePrice = Q6?.content?.counterAnswers?.[0]?.amount;
    const amountCardsWithPrice = curr?.counterSection?.value - amountCardsWithFreePrice;

    const { showPrice, rulePriceType } = getRulesProductPrice(curr || {}, pagesFlow, [], false);
    const priceType = showPrice ? rulePriceType : curr?.priceSection?.priceType;

    // const cardWithPriceWhenCounterOne = {
    //   ...curr,
    //   counterSection: { ...curr.counterSection, hardcodeAmount: 1 },
    // };
    // const cardWithPriceWhenCounterMoreOne = {
    //   ...curr,
    //   id: `${curr.id}_${idx}`,
    //   priceSection: {
    //     ...curr?.priceSection,
    //     priceValue:
    //       productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE
    //         ? (ruleProductsPrice?.rulePrice * curr.counterSection.value - ruleProductsPrice?.rulePrice) / (curr.counterSection.value - 1)
    //         : ruleProductsPrice?.rulePrice * curr.counterSection.value - ruleProductsPrice?.rulePrice,
    //   },
    //   counterSection: { ...curr.counterSection, hardcodeAmount: curr.counterSection.value - 1 },
    // };
    // return curr.counterSection.value > 1
    //   ? [...acc, cardWithPriceWhenCounterOne, cardWithPriceWhenCounterMoreOne]
    //   : [...acc, cardWithPriceWhenCounterOne];

    const getCardsFor91And92 = (cardWithFreePrice, cardWithPrice) => {
      if (amountCardsWithPrice && amountCardsWithFreePrice) {
        return [...acc, cardWithFreePrice, cardWithPrice];
      }
      if (amountCardsWithPrice) {
        return [...acc, cardWithPrice];
      }
      return [...acc, cardWithFreePrice];
    };
    if ([KONTOFINDER_PK_PRODUCT_9_1_ID].includes(curr.id)) {
      const cardWithFreePrice = {
        ...curr,
        priceSection: { ...curr?.priceSection, priceValue: 0 },
        counterSection: { ...curr.counterSection, hardcodeAmount: amountCardsWithFreePrice },
      };
      const cardWithPrice = {
        ...curr,
        id: `${curr.id}_${idx}`,
        priceSection: {
          ...curr?.priceSection,
          priceValue:
            // productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE ? MULTIPLY_VALUE_P9 : MULTIPLY_VALUE_P9 * amountCardsWithPrice,
            MULTIPLY_VALUE_P9 * amountCardsWithPrice,
        },
        counterSection: { ...curr.counterSection, hardcodeAmount: amountCardsWithPrice },
      };
      acc = getCardsFor91And92(cardWithFreePrice, cardWithPrice);
      return acc;
    }
    if ([KONTOFINDER_PK_PRODUCT_10_1_ID].includes(curr.id)) {
      const cardWithFreePrice = {
        ...curr,
        priceSection: { ...curr?.priceSection, priceValue: 0 },
        counterSection: { ...curr.counterSection, hardcodeAmount: amountCardsWithFreePrice },
      };
      const cardWithPrice = {
        ...curr,
        id: `${curr.id}_${idx}`,
        priceSection: {
          ...curr?.priceSection,
          priceValue: MULTIPLY_VALUE_P10 * amountCardsWithPrice,
          // productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE ? MULTIPLY_VALUE_P10 : MULTIPLY_VALUE_P10 * amountCardsWithPrice,
        },
        counterSection: { ...curr.counterSection, hardcodeAmount: amountCardsWithPrice },
      };

      acc = getCardsFor91And92(cardWithFreePrice, cardWithPrice);
      return acc;
    }
    if (
      (curr.id === KONTOFINDER_PK_PRODUCT_7_1_ID && !array.some(item => item.id === KONTOFINDER_PK_PRODUCT_7_2_ID)) ||
      (array
        .filter(elem => elem.id === KONTOFINDER_PK_PRODUCT_7_1_ID || elem.id === KONTOFINDER_PK_PRODUCT_7_2_ID)
        .some(item => item.counterSection.value > 0) &&
        curr.id === KONTOFINDER_PK_PRODUCT_7_1_ID)
    ) {
      const cardWithPriceWhenCounterOne =
        curr.counterSection.value > 0
          ? {
              ...curr,
              priceSection: { ...curr?.priceSection, priceValue: PRICE_PRODUCT_7_1_COUNTER_1 },
              counterSection: { ...curr.counterSection, hardcodeAmount: 1 },
            }
          : { ...curr, priceSection: { ...curr?.priceSection, priceValue: 0 } };
      const cardWithPriceWhenCounterMoreOne = {
        ...curr,
        id: `${curr.id}_${idx}`,
        priceSection: {
          ...curr?.priceSection,
          priceValue: PRICE_PRODUCT_7_1_COUNTER_MORE_1 * curr.counterSection.value - PRICE_PRODUCT_7_1_COUNTER_MORE_1,
          // productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE
          //   ? (PRICE_PRODUCT_7_1_COUNTER_MORE_1 * curr.counterSection.value - PRICE_PRODUCT_7_1_COUNTER_MORE_1) / (curr.counterSection.value - 1)
          //   : PRICE_PRODUCT_7_1_COUNTER_MORE_1 * curr.counterSection.value - PRICE_PRODUCT_7_1_COUNTER_MORE_1,
        },
        counterSection: { ...curr.counterSection, hardcodeAmount: curr.counterSection.value - 1 },
      };
      acc = isProductMultipleAmount(curr)
        ? [...acc, cardWithPriceWhenCounterOne, cardWithPriceWhenCounterMoreOne]
        : [...acc, cardWithPriceWhenCounterOne];
      return acc;
    }
    if (curr.id === KONTOFINDER_PK_PRODUCT_7_2_ID && !array.some(item => item.id === KONTOFINDER_PK_PRODUCT_7_1_ID)) {
      const cardWithFreePrice = {
        ...curr,
        priceSection: { ...curr?.priceSection, priceValue: PRICE_PRODUCT_7_2_COUNTER_1 },
        counterSection: { ...curr.counterSection, hardcodeAmount: 1 },
      };
      const cardWithPrice = {
        ...curr,
        id: `${curr.id}_${idx}`,
        priceSection: {
          ...curr?.priceSection,
          priceValue: PRICE_PRODUCT_7_2_COUNTER_MORE_1 * (curr?.counterSection?.value - 1),
        },
        counterSection: { ...curr.counterSection, hardcodeAmount: curr?.counterSection?.value - 1 },
      };
      acc = curr.counterSection.value > 1 ? [...acc, cardWithFreePrice, cardWithPrice] : [...acc, cardWithPrice];
      return acc;
    }

    if (
      array
        .filter(elem => elem.id === KONTOFINDER_PK_PRODUCT_7_1_ID || elem.id === KONTOFINDER_PK_PRODUCT_7_2_ID)
        .some(item => item.counterSection.value > 0) &&
      curr.id === KONTOFINDER_PK_PRODUCT_7_2_ID
    ) {
      acc = [
        ...acc,
        {
          ...curr,
          priceSection: {
            ...curr?.priceSection,
            priceValue: PRICE_PRODUCT_7_2_COUNTER_MORE_1 * curr.counterSection.value,
          },
          counterSection: { ...curr.counterSection, hardcodeAmount: curr?.counterSection?.value },
        },
      ];
      return acc;
    }

    // const getPriceValue = () => {
    //   if (curr?.priceSection?.priceType === Product.PRICE_PRE_REQUEST) {
    //     return curr?.priceSection.priceValue;
    //   }
    //   if (Number(curr?.counterSection?.value) > 0 && Number(curr?.priceSection.priceValue) > 0) {
    //     return Number(curr?.counterSection?.value) * Number(curr?.priceSection.priceValue);
    //   }
    //   return Number(curr?.priceSection.priceValue);
    // };
    // acc = [...acc, { ...curr, priceSection: { ...curr?.priceSection, priceValue: getPriceValue() } }];
    // return acc;

    if (ruleCounter) {
      // const freeCards = {
      //   ...curr,
      //   priceSection: {
      //     ...curr?.priceSection,
      //     priceValue: 0,
      //   },
      //   counterSection: { ...curr.counterSection, ruleAmount: ruleCounter - originalCounter },
      // };
      const cardsWithPrice = {
        ...curr,
        // id: curr.id + idx,
        priceSection: {
          ...curr?.priceSection,
          // priceValue: curr.priceSection.priceValue * originalCounter,
          priceValue: curr.priceSection.priceValue * ruleCounter,
        },
        // counterSection: { ...curr.counterSection, ruleAmount: originalCounter },
        counterSection: { ...curr.counterSection, ruleAmount: ruleCounter },
      };
      // return originalCounter ? [...acc, freeCards, cardsWithPrice] : [...acc, freeCards];
      return [...acc, cardsWithPrice];
    }
    const { rulePriceArray, priceArray } = getRulePriceArray(curr, pagesFlow, priceType);

    const result = [...groupBySameValueAndSumThem(rulePriceArray), ...groupBySameValueAndSumThem(priceArray)].map((el, index) => {
      return {
        ...curr,
        id: index > 0 ? curr.id + index : curr.id,
        priceSection: {
          ...curr?.priceSection,
          priceValue: getPriceValue(curr, el?.rulePrice),
        },
        counterSection: { ...curr.counterSection, ruleAmount: el.ruleAmount },
      };
    });

    if (result.some(el => el.id === curr.id)) {
      return [...acc, ...result];
    }
    return [...acc, ...result, { ...curr, priceSection: { ...curr?.priceSection, priceValue: getPriceValue(curr) } }];
  }, []);
};

export const getResultProducts = (pagesFlow, Q6, productInformation, sortProductsBy, showChapterIds) => {
  const resultProducts = pagesFlow.allChapters
    .filter(chapter => showChapterIds?.includes(chapter.id))
    .flatMap(item => {
      const filteredSelectedProds = removeProductsDuplicates(item.resultSelectedProducts);
      return updateRulesResultProducts(filteredSelectedProds, item.rulesResultSelectedProducts);
    });

  const newResultProducts =
    sortProductsBy?.selected === ResultSortProductsByType.PRIORITY
      ? getResultProductsByPriority(resultProducts, sortProductsBy?.productsPriority)
      : resultProducts;

  // Split prices (only hardcode products)
  if (productInformation?.splitPrices) {
    return getResultProductsWithSplitPrices(newResultProducts, Q6, productInformation, pagesFlow);
  }
  // Split prices when splitPrices - off, pricePerPiece - on (only hardcode products)
  if (!productInformation?.splitPrices && productInformation?.priceType === ResultPriceType.PRICE_PER_PIECE) {
    return getResultProductsWithSplitPrices(newResultProducts, Q6, productInformation, pagesFlow);
  }

  return newResultProducts.map((el, idx, array) => {
    const { showPrice, rulePriceType } = getRulesProductPrice(el || {}, pagesFlow, [], false);
    const priceType = showPrice ? rulePriceType : el?.priceSection?.priceType;

    if ([KONTOFINDER_PK_PRODUCT_9_1_ID].includes(el.id)) {
      return {
        ...el,
        priceSection: {
          ...el?.priceSection,

          priceValue: (el?.counterSection?.value - Q6?.content?.counterAnswers?.[0]?.amount) * MULTIPLY_VALUE_P9,
        },
      };
    }
    if ([KONTOFINDER_PK_PRODUCT_10_1_ID].includes(el.id)) {
      return {
        ...el,
        priceSection: {
          ...el?.priceSection,

          priceValue: (el?.counterSection?.value - Q6?.content?.counterAnswers?.[0]?.amount) * MULTIPLY_VALUE_P10,
        },
      };
    }
    if (
      (el.id === KONTOFINDER_PK_PRODUCT_7_1_ID && !array.some(item => item.id === KONTOFINDER_PK_PRODUCT_7_2_ID)) ||
      (array
        .filter(elem => elem.id === KONTOFINDER_PK_PRODUCT_7_1_ID || elem.id === KONTOFINDER_PK_PRODUCT_7_2_ID)
        .some(item => item.counterSection.value > 0) &&
        el.id === KONTOFINDER_PK_PRODUCT_7_1_ID)
    ) {
      return {
        ...el,
        priceSection: {
          ...el.priceSection,
          // eslint-disable-next-line no-nested-ternary
          priceValue: isProductMultipleAmount(el)
            ? PRICE_PRODUCT_7_1_COUNTER_MORE_1 * el.counterSection.value - PRICE_PRODUCT_7_1_COUNTER_MORE_1 + PRICE_PRODUCT_7_1_COUNTER_1
            : el.counterSection.value > 0
            ? PRICE_PRODUCT_7_1_COUNTER_1
            : 0,
        },
      };
    }
    if (el.id === KONTOFINDER_PK_PRODUCT_7_2_ID && !array.some(item => item.id === KONTOFINDER_PK_PRODUCT_7_1_ID)) {
      return {
        ...el,
        priceSection: {
          ...el.priceSection,
          priceValue: isProductMultipleAmount(el)
            ? PRICE_PRODUCT_7_2_COUNTER_MORE_1 * el.counterSection.value - PRICE_PRODUCT_7_2_COUNTER_MORE_1
            : PRICE_PRODUCT_7_2_COUNTER_1,
        },
      };
    }
    if (
      array
        .filter(elem => elem.id === KONTOFINDER_PK_PRODUCT_7_1_ID || elem.id === KONTOFINDER_PK_PRODUCT_7_2_ID)
        .some(item => item.counterSection.value > 0) &&
      el.id === KONTOFINDER_PK_PRODUCT_7_2_ID
    ) {
      return {
        ...el,
        priceSection: {
          ...el.priceSection,
          priceValue: PRICE_PRODUCT_7_2_COUNTER_MORE_1 * el.counterSection.value,
        },
      };
    }

    const { ruleCounter } = getRulesProductCounter(el, pagesFlow, [], false);
    if (ruleCounter) {
      return {
        ...el,
        priceSection: {
          ...el?.priceSection,
          // priceValue: el.priceSection.priceValue * el.counterSection.value,
          priceValue: el.priceSection.priceValue * ruleCounter,
        },
        counterSection: { ...el.counterSection, ruleAmount: ruleCounter },
      };
    }
    const { rulePriceArray, priceArray } = getRulePriceArray(el, pagesFlow, priceType);
    const resultObject = [...rulePriceArray, ...priceArray].reduce(
      (acc, curr) => {
        acc.rulePrice += Number(curr.rulePrice);
        acc.id = curr.id;
        return acc;
      },
      { rulePrice: 0, id: null }
    );
    if (resultObject.id === el.id && (resultObject.rulePrice || resultObject.rulePrice === 0)) {
      return {
        ...el,
        priceSection: {
          ...el?.priceSection,
          priceValue: resultObject.rulePrice,
        },
      };
    }
    return {
      ...el,
      priceSection: {
        ...el?.priceSection,
        priceValue: getPriceValue(el),
      },
    };
  });
};

export const checkMustBeUnselected = item => item?.isExceptional;

export const getSelectedExceptionalIdList = products => products?.filter(item => item?.selected && checkMustBeUnselected(item)).map(item => item?.id);

function getRecommendedHardcodedIndexList(products, structureItemId) {
  return findIndexesMatchingCondition(products, card => {
    const productId = card?.productNameSection?.product?.value;

    return ProductId.HAMBURGER_BUSINESS === productId && structureItemId === StructureItemId.HAMBURGER_FK_P1;
  });
}

function getRecommendedNormalIndexList(products, scoringResult, highestScore, isChart) {
  const prodsWithIndex = products?.map((card, index) => ({
    id: card?.productNameSection?.product?.value,
    index,
    price: Number(card?.priceSection?.priceValue),
    category: card?.productNameSection?.categoryName,
    multiProduct: card?.productNameSection?.multiProduct,
  }));
  const scoreResultWithPrice = scoringResult.map(scoreItem => {
    const scoreProd = prodsWithIndex.find(prod => prod.id === scoreItem.id);
    return { ...scoreItem, price: scoreItem.price || scoreProd?.price, category: scoreProd.category, multiProduct: scoreProd?.multiProduct };
  });

  const sortedScoreResult = scoreResultWithPrice.sort((cur, next) => priceScoringSort(cur, next));

  const filteredProds = isChart
    ? prodsWithIndex?.filter(item => scoringResult.some((scoreObj, index) => scoreObj?.id === item?.id && index === 0))
    : prodsWithIndex?.filter(item => {
        if (!item.multiProduct) {
          const scoreInCategory = sortedScoreResult.filter(scoreOb => scoreOb.category === item.category);
          return scoreInCategory.some(
            (scoreObj, index) => scoreObj?.id === item?.id && scoreInCategory[0].score === sortedScoreResult[0].score && index === 0
          );
        }
        return sortedScoreResult.some(scoreObj => scoreObj?.id === item?.id && scoreObj?.score === highestScore);
      });

  return filteredProds?.map(item => item?.index);
}

export function getRecommendedIndexList(products, scoringResult, structureItemId, isChart) {
  const hardcodedIndexList = getRecommendedHardcodedIndexList(products, structureItemId);
  const highestScore = scoringResult?.[0]?.score;
  const isScorePositive = highestScore > 0;

  if (isScorePositive) {
    return [...hardcodedIndexList, ...getRecommendedNormalIndexList(products, scoringResult, highestScore, isChart)];
  }

  return hardcodedIndexList;
}

export const getNotExceptionalIndex = products => products?.findIndex(item => !item?.isExceptional);

export const getCounterQ6 = (selectedElement, P10, P9, Q6, counterValue) =>
  [QuestionElementId.HAMBURGER_VB_KONTOFINDER_PK_QUESTION_6, QuestionElementId.KONFOFINDER_PK_QUESTION_6].includes(Q6?.id) &&
  (P9.id === selectedElement?.id || P10?.id === selectedElement?.id)
    ? Q6?.content?.counterAnswers?.[0].amount
    : counterValue;

export const getAdditionalInformationData = (allQuestions, additionalInformation, pageHistory) => {
  const additionalInformationWithOrder = additionalInformation.map((item, idx) => ({ ...item, order: idx }));

  const questionFlowElementIds = pageHistory
    ?.flatMap?.(page => page?.elements?.filter(el => el?.templateType === TemplateTypes.QUESTION))
    .map(el => el.id);
  const flowQuestions = allQuestions.filter(question => questionFlowElementIds.includes(question.id));

  const counterAnswersArray = flowQuestions
    .flatMap(item => {
      return item.content?.counterAnswers?.map(elem => {
        if (item.content?.currencySymbol) {
          return {
            ...elem,
            currencySymbol: item.content?.currencySymbol,
          };
        }
        return elem;
      });
    })
    .filter(Boolean);

  const answersArray = flowQuestions
    .filter(item => item.content?.answers && isLength(item?.selectedAnswers))
    .flatMap(el => {
      return {
        questionId: el.id,
        answers: el.content?.answers.filter(answer => el?.selectedAnswers.includes(answer.id)),
      };
    });

  const chartAnswersArray = flowQuestions.filter(item => item.content?.chartAnswers).flatMap(el => el.content?.chartAnswers);

  const additionalInformationData = [];

  for (const answer of counterAnswersArray) {
    for (const item of additionalInformationWithOrder) {
      if (answer?.id === item?.answer?.value) {
        additionalInformationData.push({
          order: item.order,
          id: item.id,
          left: item?.fieldText?.value,
          right: `${answer?.amount}${answer?.currencySymbol ? answer?.currencySymbol : ''}`,
        });
      }
    }
  }

  for (const answer of answersArray) {
    for (const item of additionalInformationWithOrder) {
      if (answer.questionId === item.question.value) {
        const answerValues = answer.answers.map(answ => answ.value).join(', ');
        additionalInformationData.push({
          order: item.order,
          id: item.id,
          left: item?.fieldText?.value,
          right: answerValues,
        });
      }
    }
  }

  for (const answer of chartAnswersArray) {
    for (const item of additionalInformationWithOrder) {
      if (answer?.id === item?.answer?.value) {
        additionalInformationData.push({
          order: item.order,
          id: item.id,
          left: item?.fieldText?.value,
          right: answer.amount,
        });
      }
    }
  }

  return additionalInformationData.sort((current, next) => current.order - next.order);
};
