import styled from 'styled-components';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { AddAdditionalInfoButton } from './AddAdditionalInfoButton';
import { AdditionalInformationList } from './AdditionalInformationList';

const MAX_ADDITIONAL_INFO = 6;

export function AdditionalInformation({ additionalInformation, structureItemIndex, onChange }) {
  const { title } = useLocalization().translations.unique.pages.result.productsDepot.additionalInformation;

  return (
    <Root>
      <AppText strong textValue={title} />
      <AdditionalInformationList additionalInformation={additionalInformation} structureItemIndex={structureItemIndex} onChange={onChange} />
      <AddAdditionalInfoButton structureItemIndex={structureItemIndex} disabled={additionalInformation?.length >= MAX_ADDITIONAL_INFO} />
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 20px',
});
