import { Typography } from 'antd';
import { BulletCheckIcon } from '../../../../../../../../../../ui/icons/BulletCheckIcon';
import { BulletIconComponent } from '../../../../../../features/BulletIconComponent';
import { getBackgroundUrl, getIconObject, getBackgroundImageStyle } from '../../../../../../../../../../lib/util/helpers';
import { InfoIconComponent } from '../../../InfoIconComponent';
import { ComparisonDetailTable } from 'src/processes/CMS/processes/management/modules/ComparisonDetailTable';
import { BulletPointsBlock } from './BulletPointsBlock';

import styles from '../styles.module.scss';

// eslint-disable-next-line max-lines-per-function
export function PreviewContentBlockItem({
  isVerticalContentBlock,
  contentBlock,
  applyVariable,
  media,
  blocksLength,
  bulletIcons,
  style,
  fonts,
  colors,
}) {
  const { customIcons, defaultIcons } = bulletIcons;

  const {
    contentBlockStyle = {},
    contentHeadlineStyle = {},
    backgroundImageBlockStyle = {},
    contentSublineStyle = {},
    headlineAndSublineWrapStyle = {},
    bulletPointsWrapStyle = {},
  } = style;

  const { contentHeadline, contentSubline, selectBackgroundImage, bulletPointIcon, bulletPoints, isComparisonDetailTable } = contentBlock;

  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImage, media);

  const iconObj = getIconObject(bulletPointIcon, [...customIcons, ...defaultIcons]);

  const iconComponent = () => {
    if (iconObj) {
      return <BulletIconComponent icon={iconObj} color={colors[bulletPointIcon.color]} />;
    }
    return <BulletCheckIcon style={{ color: colors.primaryColor }} />;
  };

  const verticalHeight = 180;
  const imageBottomMargin = backgroundImageBlockStyle?.height === verticalHeight ? '14px' : '0';

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        className={contentBlockStyle.horizontal ? styles.horizontal_content_block : ''}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...getBackgroundImageStyle(contentBlockStyle.backGround, backgroundImageUrl),
          ...contentBlockStyle,
        }}
      >
        <div
          className={contentBlockStyle.horizontal ? styles.horizontal_background_block : ''}
          style={{
            width: '100%',
            ...getBackgroundImageStyle(backgroundImageBlockStyle.backGround, backgroundImageUrl),
            ...backgroundImageBlockStyle,
            position: 'relative',
            marginBottom: imageBottomMargin,
          }}
        >
          {backgroundImageBlockStyle.backGround && <InfoIconComponent applyVariable={applyVariable} type={selectBackgroundImage} />}
        </div>

        {!contentBlockStyle.horizontal && contentHeadline && contentSubline && (
          <div style={{ backgroundColor: '#FFFFFF', ...headlineAndSublineWrapStyle }}>
            <div style={{ position: 'relative' }}>
              <Typography.Paragraph
                ellipsis={blocksLength !== 1}
                style={{
                  textAlign: 'center',
                  padding: '0 10px',
                  fontSize: fonts.headlineThird.fontSize,
                  fontFamily: fonts.headlineThird.font,
                  fontWeight: fonts.headlineThird.fontWeight,
                  color: colors[fonts.headlineThird.colorVariant],
                  ...contentHeadlineStyle,
                  margin: '24px 0 10px 0',
                }}
              >
                {applyVariable(contentHeadline.value)}
                <InfoIconComponent applyVariable={applyVariable} type={contentHeadline} styles={{ top: 2 }} />
              </Typography.Paragraph>
            </div>
            <div style={{ position: 'relative' }}>
              <Typography.Paragraph
                ellipsis={blocksLength !== 1}
                style={{
                  textAlign: 'center',
                  padding: '0 10px',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  ...contentSublineStyle,
                  margin: '0px 0 20px 0',
                }}
              >
                {applyVariable(contentSubline.value)}
                <InfoIconComponent applyVariable={applyVariable} type={contentSubline} styles={{ top: 2 }} />
              </Typography.Paragraph>
            </div>
          </div>
        )}

        <div
          className={bulletPointsWrapStyle.horizontal ? styles.horizontal_bullet_points : styles.bullet_points}
          style={{
            display: 'flex',
            width: '100%',
            padding: '20px 16px 10px 16px',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            ...bulletPointsWrapStyle,
            paddingTop: 0,
          }}
        >
          {contentBlockStyle.horizontal && contentHeadline && contentSubline && (
            <div style={{ backgroundColor: '#FFFFFF', ...headlineAndSublineWrapStyle }}>
              <div style={{ position: 'relative' }}>
                <Typography.Paragraph
                  ellipsis={blocksLength !== 1}
                  style={{
                    textAlign: 'center',
                    margin: '0 0 10px 0',
                    padding: '0 10px',
                    fontSize: fonts.headlineThird.fontSize,
                    fontFamily: fonts.headlineThird.font,
                    fontWeight: fonts.headlineThird.fontWeight,
                    color: colors[fonts.headlineThird.colorVariant],
                    ...contentHeadlineStyle,
                    marginTop: 24,
                    marginBottom: 24,
                  }}
                >
                  {applyVariable(contentHeadline.value)}
                  <InfoIconComponent applyVariable={applyVariable} type={contentHeadline} styles={{ top: 2 }} />
                </Typography.Paragraph>
              </div>
              <div style={{ position: 'relative' }}>
                <Typography.Paragraph
                  ellipsis={blocksLength !== 1}
                  style={{
                    textAlign: 'center',
                    margin: '0px 0 20px 0',
                    padding: '0 10px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                    ...contentSublineStyle,
                  }}
                >
                  {applyVariable(contentSubline.value)}
                  <InfoIconComponent applyVariable={applyVariable} type={contentSubline} styles={{ top: 2 }} />
                </Typography.Paragraph>
              </div>
            </div>
          )}

          {bulletPoints && (
            <BulletPointsBlock
              isVerticalContentBlock={isVerticalContentBlock}
              isHorizontal={contentBlockStyle.horizontal}
              bulletPoints={bulletPoints}
              iconComponent={iconComponent}
              applyVariable={applyVariable}
              colors={colors}
              fonts={fonts}
            />
          )}
        </div>
        {isComparisonDetailTable && (
          <ComparisonDetailTable
            applyVariable={applyVariable}
            data={contentBlock}
            icons={[...customIcons, ...defaultIcons]}
            colors={colors}
            fonts={fonts}
          />
        )}
      </div>
      {contentBlockStyle.backGround && <InfoIconComponent applyVariable={applyVariable} type={selectBackgroundImage} />}
    </div>
  );
}
