export function ArrowBarUp({ width = '17', height = '17', fill = '#CFCFD4' }) {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11567_5541)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.02734 10C8.15995 10 8.28713 9.94736 8.3809 9.85359C8.47467 9.75982 8.52734 9.63265 8.52734 9.50004V3.70704L10.6733 5.85404C10.7198 5.90053 10.775 5.9374 10.8358 5.96256C10.8965 5.98772 10.9616 6.00067 11.0273 6.00067C11.0931 6.00067 11.1582 5.98772 11.2189 5.96256C11.2797 5.9374 11.3349 5.90053 11.3813 5.85404C11.4278 5.80755 11.4647 5.75236 11.4899 5.69162C11.515 5.63088 11.528 5.56578 11.528 5.50004C11.528 5.4343 11.515 5.3692 11.4899 5.30846C11.4647 5.24772 11.4278 5.19253 11.3813 5.14604L8.38134 2.14604C8.3349 2.09948 8.27972 2.06253 8.21898 2.03733C8.15823 2.01212 8.09311 1.99915 8.02734 1.99915C7.96158 1.99915 7.89646 2.01212 7.83571 2.03733C7.77496 2.06253 7.71979 2.09948 7.67334 2.14604L4.67334 5.14604C4.57946 5.23993 4.52671 5.36726 4.52671 5.50004C4.52671 5.63282 4.57946 5.76015 4.67334 5.85404C4.76723 5.94793 4.89457 6.00067 5.02734 6.00067C5.16012 6.00067 5.28746 5.94793 5.38134 5.85404L7.52734 3.70704V9.50004C7.52734 9.63265 7.58002 9.75982 7.67379 9.85359C7.76756 9.94736 7.89474 10 8.02734 10ZM1.02734 12.5C1.02734 12.3674 1.08002 12.2403 1.17379 12.1465C1.26756 12.0527 1.39474 12 1.52734 12H14.5273C14.66 12 14.7871 12.0527 14.8809 12.1465C14.9747 12.2403 15.0273 12.3674 15.0273 12.5C15.0273 12.6326 14.9747 12.7598 14.8809 12.8536C14.7871 12.9474 14.66 13 14.5273 13H1.52734C1.39474 13 1.26756 12.9474 1.17379 12.8536C1.08002 12.7598 1.02734 12.6326 1.02734 12.5Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_11567_5541'>
          <rect width={width} height={height} fill='white' transform='translate(0.0273438)' />
        </clipPath>
      </defs>
    </svg>
  );
}
