export function FastForwardIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg className={className} {...rest} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 4C12.5 3.86739 12.4473 3.74021 12.3536 3.64645C12.2598 3.55268 12.1326 3.5 12 3.5C11.8674 3.5 11.7402 3.55268 11.6464 3.64645C11.5527 3.74021 11.5 3.86739 11.5 4V7.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308V11.692C4 12.322 4.692 12.702 5.233 12.389L11.5 8.753V12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12V4Z'
        fill='white'
      />
    </svg>
  );
}
