import { isLength } from '../../../../../../lib/util/helpers';
import { rulesEngine } from './rules_helper';

export const getDependenceValue = (parentDependenceBlocks, childDependenceBlocks) => {
  const ruleParentFor = parentDependenceBlocks.reduce((accumulator, dependenceBlock) => {
    const {
      dependenceProduct: { product },
    } = dependenceBlock;

    if (isLength(product)) {
      const newChildProdsIds = product.map(prod => prod.id);
      const oldChildProdsIds = accumulator.filter(id => newChildProdsIds.includes(id));
      return [...oldChildProdsIds, ...newChildProdsIds];
    }

    return accumulator;
  }, []);

  const ruleChildFor = childDependenceBlocks.reduce((accumulator, dependenceBlock) => {
    const {
      dependenceProduct: { elements },
    } = dependenceBlock;

    if (isLength(elements)) {
      const newParentProdsIds = elements.map(prod => prod.id);
      const oldParentProdsIds = accumulator.filter(id => newParentProdsIds.includes(id));
      return [...oldParentProdsIds, ...newParentProdsIds];
    }

    return accumulator;
  }, []);

  return { ruleParentFor, ruleChildFor };
};

export const getRulesDependenceProductProperties = (productCard, pagesFlow, currentElements) => {
  const { rules, id: cardId } = productCard;
  const { dependenceProduct } = rulesEngine({
    rules,
    pagesFlow,
    currentElements,
    rulesFilter: ['dependenceProduct'],
    logger: false,
  });

  const dependenceProductParentBlocks = dependenceProduct.filter(block =>
    block?.dependenceProduct?.elements?.map(el => el.productId)?.includes(cardId)
  );
  const dependenceProductChildBlocks = dependenceProduct.filter(block => block?.dependenceProduct?.product?.map(el => el.id)?.includes(cardId));
  const dependenceRulesObject = getDependenceValue(dependenceProductParentBlocks, dependenceProductChildBlocks);

  return { ...dependenceRulesObject };
};
