import { Radio, Checkbox } from 'antd';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { CircleIcon } from '../../../../../../../../../ui/icons/CircleIcon';
import { SquareIcon } from '../../../../../../../../../ui/icons/SquareIcon';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { AppRadio } from '../../../../../../../../../ui/AppRadio';
import { AppCheckbox } from '../../../../../../../../../ui/AppCheckbox';
import { ContactFormField } from '../../../../../../../../../lib/models/Questionnaire';
import styles from './styles.module.scss';

export function ContactFormRadioOrCheckboxGroup({ fieldObject, fonts, colors, isEndUserForm, applyVariable }) {
  const radioGroupHeader = applyVariable(fieldObject?.required ? `${fieldObject?.name}*` : fieldObject?.name);

  return (
    <div className={styles.input_full_width}>
      <AppTitle
        level={4}
        textValue={radioGroupHeader}
        style={{
          lineHeight: 1.25,
          marginBottom: 16,
          fontSize: fonts.headlineFourth.fontSize,
          fontFamily: fonts.headlineFourth.font,
          fontWeight: fonts.headlineFourth.fontWeight,
          color: colors[fonts.headlineFourth.colorVariant],
        }}
      />

      <AppFormSection style={{ minHeight: 78 }} formItemProps={{ name: fieldObject?.id, rules: [{ required: fieldObject?.required }] }}>
        {isEndUserForm ? (
          <>
            {[ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION].includes(fieldObject.type) && (
              <Radio.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                {fieldObject.optionList.map(optionObj => (
                  <AppRadio
                    key={optionObj.id}
                    style={{ margin: '0 8px 13px 0' }}
                    value={optionObj.title}
                    textValue={applyVariable(optionObj.title)}
                    color={colors.primaryColor}
                  />
                ))}
              </Radio.Group>
            )}
            {fieldObject.type === ContactFormField.CHECKBOX && (
              <Checkbox.Group>
                {fieldObject.optionList.map(optionObj => (
                  <AppCheckbox key={optionObj.id} style={{ margin: '0 8px 13px 0' }} value={optionObj.title}>
                    {applyVariable(optionObj.title)}
                  </AppCheckbox>
                ))}
              </Checkbox.Group>
            )}
          </>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {fieldObject.optionList.map(optionObj => (
              <div key={optionObj.id} style={{ display: 'flex', alignItems: 'center', margin: '5px 40px 5px 0' }}>
                {fieldObject.type === ContactFormField.CHECKBOX && <SquareIcon style={{ display: 'flex', marginRight: 8 }} />}
                {[ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION].includes(fieldObject.type) && (
                  <CircleIcon style={{ display: 'flex', marginRight: 8 }} />
                )}
                <AppText
                  textValue={applyVariable(optionObj.title)}
                  style={{
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </AppFormSection>
    </div>
  );
}
