import React from 'react';
import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';

export function BodyCell({ children, rootProps, innerProps, ...rest }) {
  return (
    <td {...rootProps} {...rest}>
      <AppBox {...innerProps}>{children}</AppBox>
    </td>
  );
}
