import { getSpecificProducts } from '../../models/Hardcode';
import { useOnSelectCard } from '../../hooks/useOnSelectCard';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { getNotExceptionalIndex, getSelectedExceptionalIdList } from '../../utils';

export function useHandleCounterForNoCard({
  products,
  pagesFlow,
  recommendedIndexList,
  selectable,
  setPagesFlow,
  structureItemId,
  setDragging,
  carouselRef,
  windowWidth,
  isSelectionModalOpen,
  setSelectionModalOpen,
  productObject,
  scoringResult,
}) {
  const selectedExceptionalList = getSelectedExceptionalIdList(products);

  const toSelectCardIndex = isLength(recommendedIndexList) ? recommendedIndexList[0] : getNotExceptionalIndex(products);
  const card = products?.[toSelectCardIndex];
  const cardId = card?.id;
  const { P1, P2, P3 } = getSpecificProducts(pagesFlow.allPages);
  const conditions = {
    P4_1: P1?.content?.products?.[0]?.selected && P2?.content?.products?.[1]?.selected,
    P4_2: P1?.content?.products?.[1]?.selected && P3?.content?.products?.[1]?.selected,
  };

  const { onSelectCard } = useOnSelectCard({
    conditions,
    P2,
    cardId,
    products,
    card,
    selectable,
    setPagesFlow,
    structureItemId,
    customUnselectedProductIds: selectedExceptionalList,
    selectedCardIndex: toSelectCardIndex,
    setDragging,
    carouselRef,
    windowWidth,
    isSelectionModalOpen,
    setSelectionModalOpen,
    productObject,
    pagesFlow,
    scoringResult,
  });

  return () => {
    if (!isLength(selectedExceptionalList)) return;

    onSelectCard(true);
  };
}
