import styles from '../../PreviewProduct/styles.module.scss';
import { AppIconButton } from '../../../../../../../../../ui/AppIconButton';
import { LeftPointerIcon } from '../../../../../../../../../ui/icons/LeftPointerIcon';
import { RightPointerIcon } from '../../../../../../../../../ui/icons/RightPointerIcon';

export function CarouselButtons({ onLeftClick, onRightClick, colors }) {
  return (
    <div className={styles.carouselButtons}>
      <AppIconButton
        onClick={onLeftClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          background: colors.primaryColor,
          width: '32px',
          height: '32px',
        }}
      >
        <LeftPointerIcon />
      </AppIconButton>
      <AppIconButton
        onClick={onRightClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          background: colors.primaryColor,
          width: '32px',
          height: '32px',
        }}
      >
        <RightPointerIcon />
      </AppIconButton>
    </div>
  );
}
