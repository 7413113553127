import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import { AppCheckbox } from './AppCheckbox';
import { AppCheckboxGroup } from './AppCheckboxGroup';
import { isLength } from '../lib/util/helpers';
import { useLocalization } from '../lib/hooks/useLocalization';
import { AppBox } from './boxes/AppBox';
import { AppDivider } from './AppDivider';
import styles from './AppCheckboxAll.module.scss';
import { AppFormSection } from './AppFormSection';
import { ArrangeFormItem } from '../processes/CMS/processes/management/utils/model/Arrange';
import { AppText } from './text/AppText';

function convertOptionsToValues(options) {
  return options.map(item => item.value);
}

export function CheckboxAllFormSection(props) {
  const {
    rootProps,
    minSearchLength,
    formSectionProps,
    checkboxGroupProps: { groupProps: { options, ...restGroupProps }, rootProps: { className, ...restRootProps } = {} } = {},
    isLoading,
  } = props;

  const { name: fieldName } = formSectionProps.formItemProps;

  const [indeterminate, setIndeterminate] = useState(true);
  const { generic, arranges } = useLocalization().translations.shared;
  const form = Form.useFormInstance();
  const selected = Form.useWatch(fieldName, form);
  const searchValue = Form.useWatch(ArrangeFormItem.SEARCH, form);
  const [areAllChecked, setAreAllChecked] = useState(false);
  const areOptions = isLength(options);
  const areNoResults = !isLoading && searchValue?.length >= minSearchLength && searchValue?.length >= 1 && !areOptions;

  const setCheckbox = selectedOptions => {
    form.setFieldValue(fieldName, selectedOptions);
    setIndeterminate(isLength(selectedOptions) && selectedOptions.length < options.length);
    setAreAllChecked(selectedOptions.length === options.length);
  };

  const toggleAll = event => {
    const value = event.target.checked;
    const toggledOn = convertOptionsToValues(options);
    form.setFieldValue(fieldName, value ? toggledOn : []);
    setIndeterminate(false);
    setAreAllChecked(value);
  };

  useEffect(() => {
    if (options?.length === selected?.length) {
      setAreAllChecked(true);
    } else {
      setAreAllChecked(false);
    }
  }, [selected, options]);

  if (areNoResults) {
    return (
      <div style={{ padding: '6px 0 10px', display: 'flex', justifyContent: 'center' }}>
        <AppText textValue={arranges.filter.noResultsFound} />
      </div>
    );
  }
  if (!areOptions) {
    return null;
  }

  return (
    <Root $flexColumn {...rootProps}>
      <AppCheckbox indeterminate={indeterminate} onChange={toggleAll} checked={areAllChecked} textValue={generic.selectAll} />

      <AppDivider style={{ margin: '8px 0' }} />

      <AppFormSection {...formSectionProps}>
        <AppCheckboxGroup
          rootProps={{
            className: `${styles.root} ${className}`,
            ...restRootProps,
          }}
          groupProps={{
            onChange: setCheckbox,
            options,
            value: selected,
            ...restGroupProps,
          }}
        />
      </AppFormSection>
    </Root>
  );
}

const Root = styled(AppBox)({
  marginTop: '14px',
});
