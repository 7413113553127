import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CategoriesTable } from '../modules/categories/CategoriesTable';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import { AddTableItemButton } from '../../../features/AddTableItemButton';
import { CategoryModals } from '../modules/categories/CategoryModals';
import { openProductModal } from '../../../../../../../lib/store/slices/productsSlice';
import { openCategoryModal } from '../../../../../../../lib/store/slices/categoriesSlice';
import { TopPanel } from './TopPanel';
import { CategoryModal, ProductModal } from '../../../../../lib/models/Modal';
import { ProductModals } from '../modules/products/ProductModals';
import { ProductsTable } from '../../../modules/ProductsTable';

const Tab = {
  PRODUCTS: 'PRODUCTS',
  CATEGORY: 'CATEGORY',
};

export function ProductsAndCategory() {
  const dispatch = useDispatch();
  const { translations } = useLocalization();
  const { category: categoryPage, products: productsPage } = translations.unique.pages;
  const [activeTab, setActiveTab] = useState(Tab.PRODUCTS);

  const tabOptions = [
    { tabName: productsPage.title, value: Tab.PRODUCTS },
    { tabName: categoryPage.title, value: Tab.CATEGORY },
  ];
  const tables = {
    PRODUCTS: <ProductsTable />,
    CATEGORY: <CategoriesTable />,
  };
  const modals = {
    PRODUCTS: <ProductModals />,
    CATEGORY: <CategoryModals />,
  };
  const addButtons = {
    PRODUCTS: (
      <AddTableItemButton
        styles='table_btn__tab'
        text={productsPage.addButton}
        onClick={() => dispatch(openProductModal({ modalType: ProductModal.ADD }))}
      />
    ),
    CATEGORY: (
      <AddTableItemButton
        styles='table_btn__tab'
        text={categoryPage.addButton}
        onClick={() => dispatch(openCategoryModal({ modalType: CategoryModal.ADD }))}
      />
    ),
  };

  const topPanelProps = { addButtons, tabOptions, activeTab, setActiveTab, tabButtonStyle: false };

  return (
    <>
      <TopPanel {...topPanelProps} />

      {modals[activeTab]}
      {tables[activeTab]}
    </>
  );
}
