import React from 'react';
import styled from 'styled-components';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';

/**
 * @desc Context menu
 * @param {HTMLInputElement} contextMenuRef
 * @param {Function} onDelete
 * @param {Object} providedItem
 * @return {JSX.Element}
 */
export function ContextMenu({ contextMenuRef, onDelete, providedItem }) {
  const { navigatorTab } = useLocalization().translations.unique.pages.questionnaire.leftSideBar;

  return (
    <Wrapper ref={contextMenuRef}>
      <AppText
        onClick={onDelete}
        {...providedItem.dragHandleProps}
        textValue={navigatorTab.delete}
        type='secondary'
        style={{
          color: '#000',
          fontSize: 16,
          lineHeight: '19px',
          cursor: 'pointer',
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  color: '#000',
  zIndex: 1,
  position: 'absolute',
  minWidth: 150,
  backgroundColor: '#fff',
  borderRadius: 8,
  top: '50%',
  right: '10%',
  transform: 'translateY(-50%)',
  padding: '8px 16px',
});
