// const Env = {
// local: 'local',
// DEV: 'dev',
// PRE_PROD: 'pre-prod',
// PROD: 'production',
// };

// TODO and REFACTOR:
//  * ask DevOps set env variables to CI/CD;
//  * update logic of handling envs

export const isLocal = window.location.origin.startsWith('http://localhost');
// export const isProd = !isLocal && !window.location.origin.includes('kontofinder.impltech.online');
