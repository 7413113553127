import { HeaderBlock } from './HeaderBlock';
import { Buttons } from './Buttons';
import { useSelector } from 'react-redux';
import { PrintPdfSettings } from './PrintPdfSettings';
import { ResultDepot } from './ResultDepot';
import { ResultTable } from './ResultTable';

/**
 * @desc Result form
 * @param {Object} item
 * @param {Function} onChangeFieldValue
 * @param {Function} onChangeFieldInfoIconValue
 * @param {Function} onDeleteInfoIcon
 * @param {Function} onChange
 * @param {Number} structureItemIndex
 * @returns {JSX.Element}
 */

export function ResultForm({ item, onChangeFieldValue, onChangeFieldInfoIconValue, onDeleteInfoIcon, onChange, structureItemIndex }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <>
      <HeaderBlock
        item={item}
        onChangeFieldValue={onChangeFieldValue}
        onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
        onDeleteInfoIcon={onDeleteInfoIcon}
      />
      <PrintPdfSettings data={item?.content?.pdfSettings} structureItemIndex={structureItemIndex} />
      <ResultTable item={item} isViewMode={isViewMode} structureItemIndex={structureItemIndex} onChange={onChange} />
      <Buttons item={item} isViewMode={isViewMode} onChange={onChange('buttons')} />
      <ResultDepot item={item} structureItemIndex={structureItemIndex} onChange={onChange} />
    </>
  );
}
