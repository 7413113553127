/* eslint-disable no-magic-numbers */
import { ChapterState } from '../../../../../../lib/models/TemplateTypes';
import { SelectImages } from '../../../../../../lib/models/SelectImages';
import { pictureTypes } from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { getBackgroundUrl, isLength } from '../../../../../../lib/util/helpers';
import { rulesEngine } from './rules_helper';
import { hideOrShowElements } from './element_rules_engine_helper';
import { updateProductResult } from './product_selection_rules_helper';

export const chaptersRulesEngine = (rules, pagesFlow, showChapterIds, setShowChapters, currentElements, setImageSelectionFlow, setPagesFlow) => {
  const { showOrHideFlow, imageSelectionFlow, resultProduct } = rulesEngine({
    rules,
    pagesFlow,
    currentElements,
    rulesFilter: ['showOrHideFlow', 'imageSelectionFlow', 'resultProduct', 'resultVariables'],
  });
  const { elementsIds: updatedCurrentElementsIds } = hideOrShowElements(showChapterIds, showOrHideFlow);
  setShowChapters(updatedCurrentElementsIds);
  setImageSelectionFlow(imageSelectionFlow);

  setPagesFlow(prev => {
    const { rulesChapters } = updateProductResult(resultProduct, prev.allChapters, prev.allPages);
    const { elementsIds: allShownButtonsIds } = hideOrShowElements(prev.allShownButtonsIds, showOrHideFlow);
    return {
      ...prev,
      allShownButtonsIds,
      allChapters: isLength(resultProduct) ? rulesChapters : prev.allChapters,
    };
  });
};

export const getChapterImageUrl = (
  chapterId,
  chapterState,
  imageSelectionFlow,
  selectBackgroundImageActive,
  selectBackgroundImageInactive,
  media,
  additionalMediaOptions = []
) => {
  const pictureType = chapterState === ChapterState.NOT_OPENED ? pictureTypes.inactive : pictureTypes.active;
  const filteredImages =
    chapterState === ChapterState.NOT_OPENED && selectBackgroundImageInactive.value === SelectImages.LOW_SATURATION
      ? imageSelectionFlow.filter(imageObj => imageObj.chapterElementId === chapterId)
      : imageSelectionFlow.filter(imageObj => imageObj.chapterElementId === chapterId && imageObj.pictureType === pictureType);

  if (filteredImages?.at(-1)) {
    return filteredImages?.at(-1)?.imageUrl;
  }

  return chapterState === ChapterState.NOT_OPENED
    ? getBackgroundUrl(
        selectBackgroundImageInactive.value === SelectImages.LOW_SATURATION ? selectBackgroundImageActive : selectBackgroundImageInactive,
        selectBackgroundImageInactive.value === SelectImages.LOW_SATURATION ? media : additionalMediaOptions
      )
    : getBackgroundUrl(selectBackgroundImageActive, media);
};
