/* eslint-disable no-param-reassign */
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';
import { Product } from '../../../../../../../../FindersAndProductsPage/lib/Product';
import { handleTranslation } from '../../../../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';

export function PriceTypes({ structureItemIndex, productIndex, data, allProducts, disabled }) {
  const { priceTypes: priceTypesText, priceRequestDefaultValue } = useLocalization().translations.unique.pages.questionnaire.content;
  const { updateStructure } = useUpdateStructure();
  const priceTypeOptions = [
    { value: Product.PRICE_PER_MONTH, title: handleTranslation(priceTypesText.pricePerMonth) },
    { value: Product.PRICE_PER_YEAR, title: handleTranslation(priceTypesText.pricePerYear) },
    { value: Product.PRICE_PRE_REQUEST, title: handleTranslation(priceTypesText.pricePerRequest) },
  ];

  const onChange = ({ value: priceType }) => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].priceSection.priceType = priceType;

      const selectedProduct = allProducts.find(
        item => item.id === draft?.[structureItemIndex]?.content?.products?.[productIndex]?.productNameSection?.product?.value
      );

      if (priceType === Product.PRICE_PRE_REQUEST) {
        draft[structureItemIndex].content.products[productIndex].priceSection.priceValue =
          selectedProduct?.[Product.PRICE_PRE_REQUEST] || handleTranslation(priceRequestDefaultValue);
        return;
      }

      if (selectedProduct) {
        draft[structureItemIndex].content.products[productIndex].priceSection.priceValue = selectedProduct?.[priceType];
      }
    });
  };

  return (
    <QuestionnaireSelect
      selectProps={{
        options: priceTypeOptions,
        value: { value: data.priceType },
        onChange,
        disabled,
      }}
    />
  );
}
