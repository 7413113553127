import { Space } from 'antd';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppCounterInput } from '../../../../../../ui/AppCounterInput';
import { IconButton } from '../IconButton';
import { DeleteIcon } from '../../../../../../ui/icons/DeleteIcon';
import { SeparatorIcon } from '../../../../../../ui/icons/SeparatorIcon';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { MAX_COUNTER_VALUE } from '../../../../../../lib/models/Questionnaire';
import './style.scss';

/**
 * @desc Range Min Max Row
 * @param {Number} rangeIndex
 * @param {Object} rangeObject
 * @param {Function} onChange
 * @param {Function} deleteRange
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function RangeMinMaxRow({ rangeIndex, rangeObject, onChange, deleteRange, disabled }) {
  const { range } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  return (
    <Space size='middle' className='range_scoring_box'>
      <AppText ellipsis className='range_scoring_name'>
        {range({ index: rangeIndex + 1 })}
      </AppText>
      <div className='range_scoring_inputs'>
        <AppCounterInput
          style={{ width: 72 }}
          value={rangeObject.minValue}
          disabled={disabled}
          max={rangeObject.maxValue}
          onChange={value => onChange(value, 'minValue', 0, rangeObject.maxValue)}
        />
        <SeparatorIcon />
        <AppCounterInput
          style={{ width: 72 }}
          value={rangeObject.maxValue}
          disabled={disabled}
          min={rangeObject.minValue}
          onChange={value => onChange(value, 'maxValue', rangeObject.minValue, MAX_COUNTER_VALUE)}
        />
      </div>
      <IconButton onClick={() => deleteRange(rangeObject.id)} disabled={disabled} icon={<DeleteIcon className='secondary_color' />} />
    </Space>
  );
}
