export function RightArrow({ fill = '#191919' }) {
  return (
    <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.646894 0.646894C0.693339 0.600331 0.748515 0.563388 0.80926 0.538181C0.870005 0.512975 0.935126 0.5 1.00089 0.5C1.06666 0.5 1.13178 0.512975 1.19253 0.538181C1.25327 0.563388 1.30845 0.600331 1.35489 0.646894L7.35489 6.64689C7.40146 6.69334 7.4384 6.74851 7.46361 6.80926C7.48881 6.87001 7.50179 6.93513 7.50179 7.00089C7.50179 7.06666 7.48881 7.13178 7.46361 7.19253C7.4384 7.25327 7.40146 7.30845 7.35489 7.35489L1.35489 13.3549C1.26101 13.4488 1.13367 13.5015 1.00089 13.5015C0.868118 13.5015 0.74078 13.4488 0.646894 13.3549C0.553007 13.261 0.500262 13.1337 0.500262 13.0009C0.500262 12.8681 0.553007 12.7408 0.646894 12.6469L6.29389 7.00089L0.646894 1.35489C0.60033 1.30845 0.563387 1.25327 0.538181 1.19253C0.512974 1.13178 0.5 1.06666 0.5 1.00089C0.5 0.935127 0.512974 0.870005 0.538181 0.80926C0.563387 0.748515 0.60033 0.69334 0.646894 0.646894V0.646894Z'
        fill={fill}
      />
    </svg>
  );
}
