import styled from 'styled-components';
import { ContactFormRowContentBottom } from './ContactFormRowContentBottom';
import { ContactFormRowFieldList } from './ContactFormRowFieldList';
import { isLength } from '../../../../../../../../../../../lib/util/helpers';

export function ContactFormRowContent(props) {
  return (
    <Root className='flex flex-col'>
      {isLength(props?.templateContent?.formRowList?.[props?.rowIndex]?.fieldList) && <ContactFormRowFieldList {...props} />}
      <ContactFormRowContentBottom {...props} />
    </Root>
  );
}

const Root = styled('div')({
  paddingLeft: '24px',
});
