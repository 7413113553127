/* eslint-disable no-param-reassign */
import { AppInputNumber } from '../../../../../../../../../../../../../ui/AppInputNumber';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { QuestionnaireFormSection } from '../../../../../../../features/QuestionnaireFormSection';
import { Product } from '../../../../../../../../FindersAndProductsPage/lib/Product';

export function PriceValue({ structureItemIndex, productIndex, data, allProducts, productId, disabled }) {
  const { updateStructure } = useUpdateStructure();
  const selectedProduct = allProducts.find(item => item.id === productId);
  const isPricePerRequest = data?.priceType === Product.PRICE_PRE_REQUEST;

  const onChange = ({ target }) => {
    updateStructure(draft => {
      draft[structureItemIndex].content.products[productIndex].priceSection.priceValue = target.value;
    });
  };

  const currency = <span style={{ color: '#9696A2' }}>&nbsp;{selectedProduct?.currency?.toUpperCase?.()}</span>;

  return (
    <QuestionnaireFormSection
      textFieldProps={{
        onChange,
        as: isPricePerRequest ? undefined : AppInputNumber,
        prefix: isPricePerRequest ? undefined : currency,
        value: data.priceValue,
        disabled,
      }}
    />
  );
}
