import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { QuestionnaireFormSection } from '../../../../../features/QuestionnaireFormSection';
import { LinkIcon } from '../../../../../../../../../../../ui/icons/LinkIcon';
import { QuestionnaireSwitch } from '../../../../../features/QuestionnaireSwitch';

/**
 * @desc Flow Button Link
 * @param {String} href
 * @param {Boolean} targetBlank
 * @param {String} structureItemId
 * @param {Number} buttonIndex
 * @param {String} buttonName
 * @param {String} buttonsField
 * @returns {JSX.Element}
 */
export function FlowButtonLink({ href, targetBlank, structureItemId, buttonIndex, buttonName, buttonsField }) {
  const { unique } = useLocalization().translations;
  const { openNewTab } = unique.pages.questionnaire.rightSideBar.flow;
  const { linkUrl } = unique.formItems;
  const { updateStructure } = useUpdateStructure();

  const onChange = (fieldName, value) => {
    updateStructure(draft => {
      const structureItem = draft?.find(iterated => iterated.id === structureItemId);
      if (structureItem && buttonsField && !buttonName) {
        structureItem.content[buttonsField][buttonIndex].flow[fieldName] = value;
      }
      if (structureItem && buttonName) {
        structureItem.content[buttonName].flow[fieldName] = value;
      }
    });
  };

  return (
    <>
      <QuestionnaireFormSection
        labelLeft='40px'
        formItemProps={{ style: { minHeight: 'unset' } }}
        textFieldProps={{
          value: href,
          prefix: <LinkIcon />,
          label: linkUrl.alternativeLabel,
          onChange: event => onChange('href', event?.target?.value),
        }}
      />

      <div className='align_items_center'>
        <QuestionnaireSwitch
          isOn={targetBlank}
          labelProps={{
            textValue: openNewTab,
          }}
          onChange={checked => onChange('targetBlank', checked)}
        />
      </div>
    </>
  );
}
