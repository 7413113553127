/* eslint-disable max-lines */
import { useSelector } from 'react-redux';
import { Space, Typography } from 'antd';
import { LayoutNumberInputSection } from '../features/LayoutNumberInputSection';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { ButtonOrLinkComponent } from '../../../../../../PreviewPage/components/ButtonOrLinkComponent';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';

/**
 * @desc Layout for Question template
 * @param {Object} selectedElement
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function QuestionLayout({ selectedElement, style, stylesConfiguration }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { rootStyle = {}, answersBlockStyle = {}, questionStyle = {}, questionSublineStyle = {} } = style;

  const element = selectedElement ? questionnaireStructure.find(elem => elem.id === selectedElement.id) : {};

  const {
    content: { answers, chartAnswers, counterAnswers, productValues = [], question, questionSubline, xAxis, yAxis, composerButton },
  } = element;

  const { fonts, colors } = stylesConfiguration;

  const { body, heading2 } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  return (
    <section
      style={{
        width: 1920,
        height: 813,
        backgroundColor: '#F2F2F3',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 452px',
          ...rootStyle,
        }}
      >
        {question && (
          <Typography.Paragraph
            style={{
              marginBottom: 15,
              marginTop: 32,
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.headlineSecond.fontSize,
              fontFamily: fonts.headlineSecond.font,
              fontWeight: fonts.headlineSecond.fontWeight,
              color: colors[fonts.headlineSecond.colorVariant],
              ...questionStyle,
            }}
          >
            {question.value || heading2()}
            {question?.infoIcon?.value && (
              <span style={{ marginLeft: 5 }}>
                <InfoIcon />
              </span>
            )}
          </Typography.Paragraph>
        )}
        {questionSubline && (
          <Typography.Paragraph
            style={{
              marginBottom: 35,
              width: '100%',
              textAlign: 'center',
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: 700,
              color: colors[fonts.body.colorVariant],
              ...questionSublineStyle,
            }}
          >
            {questionSubline.value || body()}
            {questionSubline?.infoIcon?.value && (
              <span style={{ position: 'relative' }}>
                <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 1 }} />
              </span>
            )}
          </Typography.Paragraph>
        )}
        <div style={{ width: '100%', background: '#FFFFFF', ...answersBlockStyle }}>
          {Array.isArray(chartAnswers) && Array.isArray(productValues) && xAxis && yAxis && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '32px 70px' }}>
              <div style={{ width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {chartAnswers.map(answer => (
                  <LayoutNumberInputSection
                    key={answer.id}
                    answers={chartAnswers}
                    answerFieldName='chartAnswers'
                    primaryColor={colors.primaryColor}
                    textStyle={{
                      fontSize: fonts.body.fontSize,
                      fontFamily: fonts.body.font,
                      fontWeight: 400,
                      color: colors[fonts.body.colorVariant],
                    }}
                    answer={answer}
                    element={element}
                    disabled
                  />
                ))}
              </div>
              <div style={{ width: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ maxWidth: 700 }} src='/images/graph.png' alt='img' />
              </div>
            </div>
          )}
          {Array.isArray(answers) && (
            <Space size='middle' wrap style={{ width: '100%', padding: '20px 24px', display: 'flex', justifyContent: 'center' }}>
              {answers.map(answer => (
                <div
                  key={answer.id}
                  style={{
                    width: 230,
                    border: '2px solid #5C5C6E',
                    borderRadius: 4,
                    height: 58,
                    padding: '10px 32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{
                      fontSize: fonts.body.fontSize,
                      fontFamily: fonts.body.font,
                      fontWeight: 400,
                      color: colors[fonts.body.colorVariant],
                      textAlign: 'center',
                      marginBottom: 0,
                    }}
                  >
                    {answer.value}
                  </Typography.Paragraph>
                  {answer?.infoIcon?.value && (
                    <span style={{ position: 'absolute', right: 5, top: 0 }}>
                      <InfoIcon />
                    </span>
                  )}
                </div>
              ))}
            </Space>
          )}
          {Array.isArray(counterAnswers) && (
            <div style={{ width: rootStyle.slider ? '100%' : '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {counterAnswers.map(answer => (
                <LayoutNumberInputSection
                  key={answer.id}
                  answers={counterAnswers}
                  answerFieldName='counterAnswers'
                  primaryColor={colors.primaryColor}
                  textStyle={{
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: 400,
                    color: colors[fonts.body.colorVariant],
                  }}
                  answer={answer}
                  element={element}
                  disabled
                  slider={rootStyle.slider}
                />
              ))}
            </div>
          )}
        </div>
        {composerButton && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
            <ButtonOrLinkComponent
              contentObject={composerButton}
              style={{
                backgroundColor: colors.primaryColor,
                height: 48,
                minWidth: 200,
                borderRadius: 24,
                padding: '5px 8px',
                color: '#FFFFFF',
              }}
              textStyle={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
              }}
              disabled
            />
          </div>
        )}
      </div>
    </section>
  );
}
