import { ROUTER_IDS } from './router-ids';

const HOME_PATH = '/';

function getPath(subPath = '') {
  return HOME_PATH + subPath;
}

const PREFIXES = {
  tenants: getPath('tenants'),
  templates: getPath('templates'),
  finders: getPath('finders'),
  themes: getPath('themes'),
};

export const ROUTER_PATHS = {
  // home
  [ROUTER_IDS.home]: HOME_PATH,

  // templates
  [ROUTER_IDS.templates]: PREFIXES.templates,
  [ROUTER_IDS.template](id) {
    return `${this.templates}/${id}`;
  },

  // tenants
  [ROUTER_IDS.tenants]: PREFIXES.tenants,
  [ROUTER_IDS.tenant](id) {
    return `${this.tenants}/${id}`;
  },

  // finders
  [ROUTER_IDS.questionnaire](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.questionnaire}`;
  },
  [ROUTER_IDS.settings](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.settings}`;
  },
  [ROUTER_IDS.products](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.products}`;
  },
  [ROUTER_IDS.preview](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.preview}`;
  },
  [ROUTER_IDS.rules](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.rules}`;
  },
  [ROUTER_IDS.builder](id) {
    return `${PREFIXES.finders}/${id}/${ROUTER_IDS.rules}/${ROUTER_IDS.builder}`;
  },

  // themes
  [ROUTER_IDS.themes]: PREFIXES.themes,
  get [ROUTER_IDS.theme]() {
    return `${this.themes}/${ROUTER_IDS.theme}`;
  },

  // auth
  [ROUTER_IDS.logIn]: getPath('log-in'),
  [ROUTER_IDS.setUpPassword]: getPath('set-up-password'),

  // other
  [ROUTER_IDS.profile]: getPath('profile'),
  [ROUTER_IDS.users]: getPath('users'),
  [ROUTER_IDS.yourFinders]: getPath('your-finders'),
  [ROUTER_IDS.notFound]: getPath('not-found'),

  // client
  [ROUTER_IDS.finder]: getPath('finder'),
};
