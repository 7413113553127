import { useProductSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';
import { AppText } from '../../../../../../../../ui/text/AppText';

export function useProductLinkColumn() {
  const { link: title } = useLocalization().translations.unique.pages.products.table.columns;

  return useProductSearchDataColumn({
    title,
    arrangedId: Product.PRODUCT_PAGE_LINK,
    searchFieldName: 'searchByProductPageLink',
    filterFieldName: 'equalByProductPageLink',
    columnsProps: {
      width: 280,
      render: data => <AppText>{data || '-'}</AppText>,
    },
  });
}
