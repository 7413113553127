import { FormCard } from '../../../../../features/FormCard';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { FinderFileLoader } from '../../../../../features/FinderFileLoader';
import { UploadImageLabel } from '../../../../../features/UploadImageLabel';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { useState } from 'react';
import { InfoIcon } from '../../../../../../../../../ui/icons/InfoIcon';
import { Tooltip } from 'antd';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';

const MAX_FILE_SIZE = 0.3;

export function FaviconTitleCard({ designSettings, setDesignSettings }) {
  const { design } = useLocalization().translations.unique.pages.settings;
  const { faviconTitle } = useLocalization().translations.unique.formItems;

  const [uploadingFavicon, setUploadingFavicon] = useState(false);

  const { faviconTitleConfig } = designSettings;

  const fieldHandleChange = (fieldName, value) => {
    setDesignSettings({ ...designSettings, faviconTitleConfig: { ...faviconTitleConfig, [fieldName]: value } });
  };

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppTitle level={4} textValue={design.faviconTitle} />
      </AppBox>
      <AppBox className='align_items_center' style={{ marginBottom: 24 }}>
        <AppText className='settings_card_label' textValue={design.favicon} />
        <Tooltip title={design.faviconInfo}>
          <InfoIcon className='primary_color' style={{ marginTop: -15, marginLeft: 10, cursor: 'pointer' }} />
        </Tooltip>
      </AppBox>
      <FinderFileLoader
        accept='image/x-icon'
        afterUploadAction={file => fieldHandleChange('favicon', file)}
        errorText={design.beforeUploadFaviconError()}
        maxFileSize={MAX_FILE_SIZE}
        disabled={uploadingFavicon}
        setUploading={setUploadingFavicon}
        uploadLabel={
          <UploadImageLabel
            mediaObject={uploadingFavicon ? {} : faviconTitleConfig?.favicon}
            uploading={uploadingFavicon}
            deleteOnClick={() => fieldHandleChange('favicon', null)}
            imgHeight={52}
          />
        }
      />
      <AppText className='settings_card_label' textValue={faviconTitle.label} />
      <AppFormSection
        formItemProps={{ name: FormItemName.FAVICON_TITLE_TITLE, rules: [{ required: true, message: faviconTitle.errors.required() }] }}
        textFieldProps={{ label: faviconTitle.label }}
      />
    </FormCard>
  );
}
