import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { IconButton } from '../../../../../../../../features/IconButton';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import { AnswerSelectFormItem } from '../../../../../../../../features/form-items/AnswerSelectFormItem';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { AppBasicButton } from '../../../../../../../../../../../../ui/buttons/AppBasicButton';
import { DestinationVariants } from '../../../../../../../../../../../../lib/models/TemplateTypes';
import { CounterFlowRangeItem } from './CounterFlowRangeItem';
import { newAnswerRange } from '../../../../../../utils/helpers';
import '../../../../style.scss';

/**
 * @desc Counter Answers Range Block
 * @param {Number} index
 * @param {String} elementId
 * @param {Object} counterAnswersFlowItem
 * @param {Array} counterAnswersFlowArr
 * @param {Array} counterAnswers
 * @param {Function} handleChangeFieldValue
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function CounterAnswersRangeBlock({
  index,
  elementId,
  counterAnswersFlowItem,
  counterAnswersFlowArr,
  counterAnswers,
  handleChangeFieldValue,
  disabled,
}) {
  const { if: ifText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { addRange } = useLocalization().translations.shared.generic;

  const handleChangeCounterAnswersFlowField = fieldName => value => {
    const updatedArr = counterAnswersFlowArr.map((counterFlowItem, condIndex) => {
      return condIndex === index ? { ...counterFlowItem, [fieldName]: value } : counterFlowItem;
    });
    handleChangeFieldValue(updatedArr);
  };

  const deleteCounterFlowItem = () => {
    const updatedStatesArr = counterAnswersFlowArr.filter((counterFlowItem, condIndex) => condIndex !== index);
    handleChangeFieldValue(updatedStatesArr);
  };

  const addCounterAnswerRange = () => {
    const updatedArray = counterAnswersFlowArr.map((counterFlowItem, condIndex) => {
      return condIndex === index
        ? {
            ...counterFlowItem,
            counterAnswersRanges: [...counterFlowItem.counterAnswersRanges, newAnswerRange(DestinationVariants.NEXT)],
          }
        : counterFlowItem;
    });

    handleChangeFieldValue(updatedArray);
  };

  const deleteRange = deleteId => {
    const updatedArr = counterAnswersFlowArr.map((counterFlowItem, condIndex) => {
      return condIndex === index
        ? {
            ...counterFlowItem,
            counterAnswersRanges: counterFlowItem.counterAnswersRanges.filter(rangeItem => rangeItem.id !== deleteId),
          }
        : counterFlowItem;
    });
    handleChangeFieldValue(updatedArr);
  };

  const updateRangeObject = (objectId, fieldName, value) => {
    const updatedArr = counterAnswersFlowArr.map((counterFlowItem, condIndex) => {
      return condIndex === index
        ? {
            ...counterFlowItem,
            counterAnswersRanges: counterFlowItem.counterAnswersRanges.map(rangeItem =>
              rangeItem.id === objectId ? { ...rangeItem, [fieldName]: value } : rangeItem
            ),
          }
        : counterFlowItem;
    });
    handleChangeFieldValue(updatedArr);
  };

  const updateRangeObjectFields = (objectId, values) => {
    const updatedArr = counterAnswersFlowArr.map((counterFlowItem, condIndex) => {
      return condIndex === index
        ? {
            ...counterFlowItem,
            counterAnswersRanges: counterFlowItem.counterAnswersRanges.map(rangeItem =>
              rangeItem.id === objectId ? { ...rangeItem, ...values } : rangeItem
            ),
          }
        : counterFlowItem;
    });
    handleChangeFieldValue(updatedArr);
  };

  const answerOptions = counterAnswers.filter(answer => {
    return !counterAnswersFlowArr.some(flowItem => flowItem.counterAnswerId === answer.id) || counterAnswersFlowItem.counterAnswerId === answer.id;
  });

  return (
    <div className='flow_process_counter_ranges'>
      <div style={{ padding: '15px 15px 0 15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AppText textValue={ifText} className='flow_process_box_select_title' />
          <IconButton onClick={deleteCounterFlowItem} disabled={disabled} icon={<DeleteIcon className='flow_process_box_delete_icon' />} />
        </div>

        <AnswerSelectFormItem
          value={counterAnswersFlowItem.counterAnswerId}
          onChange={handleChangeCounterAnswersFlowField('counterAnswerId')}
          disabled={disabled}
          options={answerOptions}
          isMultiSelect={false}
          allAnswers={counterAnswers}
        />
      </div>
      {counterAnswersFlowItem?.counterAnswersRanges.map((rangeObj, rangeIndex) => (
        <CounterFlowRangeItem
          key={rangeObj.id}
          elementId={elementId}
          rangeObject={rangeObj}
          rangeIndex={rangeIndex}
          updateRangeObject={updateRangeObject}
          updateRangeObjectFields={updateRangeObjectFields}
          deleteRange={deleteRange}
          disabled={disabled}
          counterAnswersRanges={counterAnswersFlowItem?.counterAnswersRanges}
        />
      ))}

      <AppBasicButton onClick={addCounterAnswerRange} style={{ display: 'inline-flex', padding: '10px 15px' }} disabled={disabled}>
        <AppText className='primary_color bold' textValue={addRange} />
      </AppBasicButton>
    </div>
  );
}
