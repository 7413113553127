/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { ColorVariants } from '../../../../../../../lib/models/DefaultStylesFontsAndColors';
import { handleTranslation } from '../../../../../../../../../lib/util/helpers';
import { EMPTY_LENGTH } from '../../../../../../../../../lib/util/constants';

export const Align = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export function formatBrandThemeColorList(configuration, colorVariants) {
  if (!configuration) {
    return [];
  }

  const parsedConfiguration = JSON.parse(configuration);
  const entries = Object.entries(parsedConfiguration.colors);

  const labels = {
    [ColorVariants.PRIMARY_COLOR]: handleTranslation(colorVariants.primary),
    [ColorVariants.SECONDARY_FIRST]: handleTranslation(colorVariants.secondary1),
    [ColorVariants.SECONDARY_SECOND]: handleTranslation(colorVariants.secondary2),
    [ColorVariants.BACKGROUND_COLOR]: handleTranslation(colorVariants.background),
  };

  return entries.map(([key, value]) => ({
    value,
    label: handleTranslation(labels[key]),
  }));
}

const AddColumnPlacement = {
  LEFT: 'left',
  RIGHT: 'right',
};

export function addColumn(tableRows, placement = AddColumnPlacement.RIGHT) {
  for (const item of tableRows) {
    const cell = generateCell();

    if (placement === AddColumnPlacement.LEFT) {
      item.cells.unshift(cell);
    } else if (placement === AddColumnPlacement.RIGHT) {
      item.cells.push(cell);
    }
  }
}

export const ComparisonTableDroppable = {
  COLUMN: 'comparisonTableColumn',
  ROW: 'comparisonTableRow',
};

export const DefaultTextColor = {
  BLACK: 'black',
};

export const DefaultRowColor = {
  NONE: 'none',
};

export const ComparisonTableCellType = {
  TEXT: 'text',
  PRODUCT: 'product',
  PICTURE: 'picture',
  ICON: 'icon',
};

export const ComparisonTableCellKind = {
  NAME: 'name',
  VALUE: 'value',
};

export const ComparisonTableDraggableId = {
  STRUCTURE_INDEX: 'structureIndex',
  TABLE_INDEX: 'tableIndex',
};

const DEFAULT_COMPARISON_TABLE_CELL_TYPE = ComparisonTableCellType.TEXT;

const IMAGE_LIKE_TYPES = new Set([ComparisonTableCellType.PRODUCT, ComparisonTableCellType.PICTURE, ComparisonTableCellType.ICON]);

export const isImageLikeType = value => IMAGE_LIKE_TYPES.has(value);

export function generateCell() {
  return { value: '', type: DEFAULT_COMPARISON_TABLE_CELL_TYPE, id: uuidv4() };
}

export function generateEmptyRow(cellsAmount) {
  return {
    cells: Array.from({ length: cellsAmount }, () => generateCell()),
  };
}

export function fillImage(tableRows, newSelected, rowIndex, columnIndex) {
  tableRows[rowIndex].cells[columnIndex].value = newSelected?.value;
  tableRows[rowIndex].cells[columnIndex].image = newSelected?.image;
}

export function fillProduct(tableRows, newSelected, rowIndex, columnIndex) {
  fillImage(tableRows, newSelected, rowIndex, columnIndex);
  tableRows[rowIndex].cells[columnIndex].productName = newSelected?.productName;
}

// REFACTOR: name of the func
export function getSimpleValue(tableRows, options, rowIndex, columnIndex) {
  const currentProductId = tableRows[rowIndex].cells[columnIndex]?.value;
  return options?.find(item => item.value === currentProductId);
}

export const getCellsAmount = tableRows => {
  const biggest = Math.max(...tableRows.map(row => row?.cells?.length || EMPTY_LENGTH));
  return Number.isSafeInteger(biggest) ? biggest : EMPTY_LENGTH;
};

export const COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT = 100;

export function normalizeField(value) {
  let result = Number(value);

  if (value > COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT) {
    result = COMPARISON_TABLE_ROWS_AND_COLUMN_LIMIT;
  }

  return result;
}

export const getRowsAmount = tableRows => tableRows?.length || EMPTY_LENGTH;
