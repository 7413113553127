import React, { useState } from 'react';
import Slider from 'react-slick';
import { useGetCarouselSettings } from '../lib/hooks/useGetCarouselSettings';
import { DisplayedProductCardWithWrapper } from './DisplayedProductCardWithWrapper';
import { CarouselButtons } from './CarouselButtons';
import { LayoutDesign } from '../../../../../../../../../lib/models/Questionnaire';
import { CenterModeCarousel } from './centerModeCarousel'; // <-- Eslint: 'Dependency cycle detected.eslint(import/no-cycle)'

const MIN_LENGTH = 1;

// REFACTOR: use Context API instead of props drilling
function NonMobileCarouser({ genericProps, carouselProps, cardProps, layoutDesign }) {
  const { products, windowWidth, colors, setDragging, carouselRef } = genericProps;
  const { onLeftScrollButtonClick, onRightScrollButtonClick, dragging, outletContext, preSelectedCardIndex } = carouselProps;

  const [sliderDraggable, setSliderDraggable] = useState(true);

  const handleSliderDraggable = value => {
    setSliderDraggable(value);
  };

  const disabledSlider = false;
  const settings = useGetCarouselSettings({ products, preSelectedCardIndex, windowWidth, layoutDesign, sliderDraggable });
  const rootStyle = { width: outletContext?.currentResolution?.width || '100vw' };
  const handleBeforeChange = () => setDragging(true);
  const handleAfterChange = () => setDragging(false);

  const onClickCapture = event => {
    if (dragging) {
      event.stopPropagation();
    }
  };

  const hasShowCounter = Boolean(products.some(product => product.counterSection && product.counterSection.showCounter === true));

  return (
    <div style={rootStyle}>
      {!layoutDesign ||
        (layoutDesign?.title !== LayoutDesign.CENTER_MODE_CAROUSEL && (
          <>
            <Slider beforeChange={handleBeforeChange} afterChange={handleAfterChange} ref={carouselRef} {...settings}>
              {products.map((item, index) => (
                <div onClickCapture={onClickCapture} key={item.id}>
                  <DisplayedProductCardWithWrapper
                    hasShowCounter={hasShowCounter}
                    {...layoutDesign}
                    key={item.id}
                    {...genericProps}
                    card={item}
                    cardIndex={index}
                    onSliderDraggable={handleSliderDraggable}
                    {...cardProps}
                  />
                </div>
              ))}
            </Slider>

            {products.length > MIN_LENGTH && (
              <CarouselButtons onLeftClick={onLeftScrollButtonClick} onRightClick={onRightScrollButtonClick} colors={colors} />
            )}
          </>
        ))}

      {layoutDesign?.title === LayoutDesign.CENTER_MODE_CAROUSEL && (
        <CenterModeCarousel disabledSlider={disabledSlider} genericProps={genericProps} cardProps={cardProps} layoutDesign={layoutDesign} />
      )}
    </div>
  );
}

export { NonMobileCarouser };
