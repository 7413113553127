import styles from './styles.module.scss';
import { EmailSuccess } from '../../../../../../../../../ui/icons/EmailSuccess';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { StructureItemId } from '../../../Hardcode';
import { formatHref } from 'src/processes/CMS/processes/management/utils/helpers';

export function SuccessScreen({
  bankName,
  resultObject = {},
  pagesFlow,
  successCustomText,
  successButton,
  colors,
  fonts,
  applyVariable = varText => {
    return varText;
  },
}) {
  const { text, textFK } = useLocalization().translations.unique.components.clientForm.successScreen;
  const { id: resultObjectId } = resultObject;
  const successText = pagesFlow?.isFormHardcoded && resultObjectId === StructureItemId.HAMBURGER_FK_RESULT ? textFK : text;
  const bankText = pagesFlow?.isFormHardcoded && resultObjectId === StructureItemId.HAMBURGER_FK_RESULT ? `Ihre ${bankName}` : bankName;

  const isCustomSuccessText = successCustomText && successCustomText.replace(/<(.|\n)*?>/g, '').trim().length > 0;

  const { name: buttonName, url, isNewTab } = successButton || { name: '', url: '', isNewTab: false };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <EmailSuccess />
        {isCustomSuccessText ? (
          // eslint-disable-next-line react/no-danger
          <div className={styles.text} style={{ color: '#002D67' }} dangerouslySetInnerHTML={{ __html: successCustomText }} />
        ) : (
          <AppText textValue={successText} className={styles.text} style={{ color: '#002D67' }} />
        )}
        <p className={styles.subtext} style={{ color: '#002D67' }}>
          {bankText}
        </p>
        {buttonName && (
          <a
            href={formatHref(applyVariable(url))}
            target={isNewTab ? '_blank' : ''}
            style={{
              width: 300,
              minHeight: 48,
              background: colors.primaryColor,
              marginBottom: 10,
              border: 'none',
              borderRadius: 24,
              color: '#FFFFFF',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
              textDecoration: 'none',
              fontFamily: fonts?.body?.font,
            }}
            rel='noreferrer'
          >
            {applyVariable(buttonName)}
          </a>
        )}
        <div style={{ height: '70px' }} />
      </div>
    </div>
  );
}
