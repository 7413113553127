import styled from 'styled-components';
import { AppText } from './text/AppText';

const Styled = styled(AppText)(({ $size, labelLeft }) => ({
  display: 'flex',
  alignSelf: 'center',
  fontWeight: 'normal',
  fontFamily: 'inherit',
  position: 'absolute',
  pointerEvents: 'none',
  left: labelLeft || '16px',
  top: String($size === 'small' ? '5px' : '11px'),
  fontSize: `${$size === 'small' ? '12px' : '16px'} !important`,
  transition: '0.2s ease all',
  zIndex: 2,
  color: '#9696A2 !important',
  border: 'none !important',
  width: 'auto !important',

  '&.asLabel': {
    top: '-10px',
    left: '16px !important',
    fontSize: '12px !important',
    background: 'white !important',
    padding: '0 2px !important',
    zIndex: 3,
  },
}));

export function AppInputLabel({ as, ...rest }) {
  return <Styled {...rest} forwardedAs='label' />;
}
