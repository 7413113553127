/* eslint-disable max-lines */
import { Radio, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { DraggableFormListQuestion } from '../../../../../../../features/DraggableFormListQuestion';
import { AppRadio } from '../../../../../../../../../../../ui/AppRadio';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { DraggableCounterAnswerList } from '../../../../../../../features/DraggableCounterAnswerList';
import { DroppableFieldWrapper } from '../../DroppableItems/DroppableFieldWrapper';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';
import { AppTooltip } from '../../../../../../../../../../../ui/AppTooltip';
import { ProductValuesBlock } from './ProductValuesBlock';
import { ComposerButton } from '../ComposerButton';
import { AppBox } from '../../../../../../../../../../../ui/boxes/AppBox';
import '../style.scss';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { AppCheckbox } from '../../../../../../../../../../../ui/AppCheckbox';
import styled from 'styled-components';
import { CurrencyFormItem } from '../../../../../../../features/form-items/CurrencyFormItem';
import { CurrencySymbolAndCode } from '../../../../../../../../../../../lib/models/Finance';
import { AppInput } from '../../../../../../../../../../../ui/AppInput';

const MIN_STEP = 1;
const MAX_NUMBER_OF_EXCEPTIONS = 100;
const DEFAULT_NUMBER_OF_EXCEPTIONS = 1;

/**
 * @desc Question form
 * @param {Number} itemIndex
 * @param {Function} handleChangeFieldValue
 * @param {Function} handleChangeField
 * @param {Function} handleChangeFieldTargetValue
 * @param {Function} onChangeFieldInfoIconValue
 * @param {Function} onDeleteInfoIcon
 * @param {Function} handleChangeContentFields
 * @param {Function} onDeleteComposerButton
 * @param {Boolean} isDraggingOver
 * @returns {JSX.Element}
 */

// eslint-disable-next-line max-lines-per-function
export function QuestionForm({
  item,
  handleChangeFieldValue,
  handleChangeField,
  handleChangeFieldTargetValue,
  onChangeFieldInfoIconValue,
  onDeleteInfoIcon,
  handleChangeContentFields,
  onDeleteComposerButton,
  isDraggingOver,
}) {
  const { content = {}, id, label } = item;
  const { question, questionSubline, mandatory, multipleChoice, answers, chartAnswers, xAxis, yAxis, productValues, scoring, counterAnswers } =
    content;

  const dispatch = useDispatch();

  const {
    templateSubline,
    templateQuestion,
    templateAnswer,
    templateInfo,
    templateXAxis,
    templateYAxis,
    enterStep,
    showCurrencySymbol: showCurrencySymbolText,
  } = useLocalization().translations.unique.formItems;

  const {
    question: questionText,
    answer: answerText,
    mandatory: mandatoryText,
    multipleChoice: multipleChoiceText,
    singleChoice: singleChoiceText,
    graphAxis,
    productValues: productValuesText,
    productValuesTooltip,
  } = useLocalization().translations.unique.pages.questionnaire.content;
  const { addAnswer } = useLocalization().translations.shared.generic;
  const { isViewMode, questionnaireStructure } = useSelector(({ finders }) => finders);

  const handleChangeStep = value => {
    let step = 1;
    if (Number(value) === 0) {
      step = item.content.step;
      return;
    }
    if (value >= MIN_STEP) {
      step = value;
    }
    if (!value) {
      step = MIN_STEP;
    }
    const result = questionnaireStructure.map(el =>
      el.id === id
        ? {
            ...el,
            content: {
              ...el.content,
              step: Number(step),
            },
          }
        : el
    );
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleShowCurrencySymbol = value => {
    const result = questionnaireStructure.map(el => {
      if (el.id === id) {
        const { showCurrencySymbol, ...rest } = el.content;
        return { ...el, content: !value ? { ...el.content, showCurrencySymbol: true } : { ...rest } };
      }
      return el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeCurrencySymbol = value => {
    const result = questionnaireStructure.map(el => {
      if (el.id === id) {
        return { ...el, content: { ...el.content, currencySymbol: value.value } };
      }
      return el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleKeyPress = event => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeNumberOfExceptions = targetValue => {
    const exceptionOptions = [];
    if (targetValue <= MAX_NUMBER_OF_EXCEPTIONS && targetValue >= 1) {
      for (let i = 1; i <= targetValue; i += 1) {
        exceptionOptions.push({ title: String(i), value: String(i) });
      }
      const result = questionnaireStructure.map(el => {
        if (el.id === id) {
          return {
            ...el,
            content: {
              ...el.content,
              numberOfExceptions: targetValue,
              answers: answers.map(elem => {
                let maxException = 0;
                if (elem?.exception?.value > maxException) {
                  maxException = elem?.exception?.value;
                }
                return {
                  ...elem,
                  exception: targetValue >= maxException ? elem.exception : { title: String(targetValue), value: String(targetValue) },
                  exceptionOptions,
                };
              }),
            },
          };
        }
        return el;
      });
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  return (
    <>
      <div className='template_card_header_block'>
        <div className='align_items_center space_between' style={{ marginBottom: 24 }}>
          <AppText className='bold' textValue={questionText} />
          {Object.prototype.hasOwnProperty.call(content, 'mandatory') && (
            <AppBox className='align_items_center' disabled={isViewMode}>
              <Switch checked={mandatory} onChange={handleChangeField('mandatory')} size='small' disabled={isViewMode} />
              <AppText className='settings_card_switch' textValue={mandatoryText} />
            </AppBox>
          )}
        </div>
        <DroppableFieldWrapper
          itemId={id}
          labelField={templateQuestion.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.question.infoIcon && 'INFO_ICON'}
          valueObject={question}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
          onDeleteInfoIcon={onDeleteInfoIcon}
          fieldName='question'
          infoIcon={item.content.question.infoIcon}
        />
        <DroppableFieldWrapper
          itemId={id}
          labelField={templateSubline.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.questionSubline.infoIcon && 'INFO_ICON'}
          valueObject={questionSubline}
          onChangeField={handleChangeFieldValue}
          onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
          onDeleteInfoIcon={onDeleteInfoIcon}
          fieldName='questionSubline'
          infoIcon={item.content.questionSubline.infoIcon}
        />
      </div>
      <div className='template_card_content_block'>
        <div className='space_between' style={{ flexWrap: 'wrap' }}>
          <AppText className='bold' style={{ marginBottom: 12 }} textValue={answerText} />
          <div className='space_between'>
            {label !== 'Simple Input' && (
              <div className='question_answer_currency_symbol_block'>
                <StyledWrapperCheckbox>
                  <AppCheckbox
                    onChange={() => handleShowCurrencySymbol(item.content?.showCurrencySymbol)}
                    checked={item.content?.showCurrencySymbol}
                    textValue={showCurrencySymbolText}
                    disabled={isViewMode}
                  />
                </StyledWrapperCheckbox>
                <Styled
                  as={CurrencyFormItem}
                  size='small'
                  currencies={CurrencySymbolAndCode}
                  style={{ width: 'unset', position: 'static' }}
                  formItemProps={{
                    style: { width: '130px' },
                  }}
                  selectProps={{
                    isSearchable: false,
                    disabled: !item.content?.showCurrencySymbol,
                    size: 'small',
                    value: item.content.currencySymbol,
                    onChange: selected => handleChangeCurrencySymbol(selected),
                  }}
                />
              </div>
            )}
            {item.content.step && (
              <div className='question_answer_step_block'>
                <div className='question_answer_step_block_info_icon'>
                  <AppTooltip text={enterStep}>
                    <InfoIcon />
                  </AppTooltip>
                </div>
                <AppInput isNumber size='small' label={enterStep} value={item.content.step} onChange={handleChangeStep} disabled={isViewMode} />
              </div>
            )}
          </div>
          {Object.prototype.hasOwnProperty.call(content, 'multipleChoice') && (
            <Radio.Group
              value={multipleChoice}
              onChange={handleChangeFieldTargetValue('multipleChoice')}
              disabled={isViewMode}
              style={{ marginBottom: 5 }}
            >
              <AppRadio value textValue={multipleChoiceText} />
              <AppRadio value={false} textValue={singleChoiceText} />
            </Radio.Group>
          )}
          {item.content?.multipleChoice && (
            <AppInput
              type='number'
              style={{ width: 72, padding: '8px' }}
              value={item.content?.numberOfExceptions || DEFAULT_NUMBER_OF_EXCEPTIONS}
              onChange={event => handleChangeNumberOfExceptions(Number(event.target.value))}
              onKeyPress={handleKeyPress}
              size='small'
            />
          )}
        </div>
        {Array.isArray(answers) && (
          <DraggableFormListQuestion
            valueArray={answers}
            additionalFields={{
              products: [],
              savings: [],
              exceptionOptions: answers?.[0]?.exceptionOptions || [{ title: '1', value: '1' }],
            }}
            onChange={handleChangeField('answers')}
            label={templateAnswer.label}
            addButtonText={addAnswer}
            item={item}
          />
        )}
        {Array.isArray(chartAnswers) && (
          <DraggableCounterAnswerList
            answers={chartAnswers}
            answersFieldName='chartAnswers'
            productValues={productValues}
            onChange={handleChangeField('chartAnswers')}
            handleChangeContentFields={handleChangeContentFields}
            label={templateAnswer.label}
            addButtonText={addAnswer}
            item={item}
          />
        )}
        {Array.isArray(counterAnswers) && (
          <DraggableCounterAnswerList
            answers={counterAnswers}
            answersFieldName='counterAnswers'
            onChange={handleChangeField('counterAnswers')}
            handleChangeContentFields={handleChangeContentFields}
            label={templateAnswer.label}
            addButtonText={addAnswer}
            item={item}
          />
        )}
      </div>
      {xAxis && yAxis && (
        <div className='template_card_content_block'>
          <div className='align_items_center' style={{ marginBottom: 24 }}>
            <AppText className='bold' textValue={graphAxis} />
          </div>
          <DroppableFieldWrapper
            itemId={id}
            labelField={templateXAxis.label}
            labelTemplateInfo={templateInfo.label}
            type={!item.content.xAxis.infoIcon && 'INFO_ICON'}
            valueObject={xAxis}
            onChangeField={handleChangeFieldValue}
            onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
            onDeleteInfoIcon={onDeleteInfoIcon}
            fieldName='xAxis'
            infoIcon={item.content.xAxis.infoIcon}
          />
          <DroppableFieldWrapper
            itemId={id}
            labelField={templateYAxis.label}
            labelTemplateInfo={templateInfo.label}
            type={!item.content.yAxis.infoIcon && 'INFO_ICON'}
            valueObject={yAxis}
            onChangeField={handleChangeFieldValue}
            onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
            onDeleteInfoIcon={onDeleteInfoIcon}
            fieldName='yAxis'
            infoIcon={item.content.yAxis.infoIcon}
          />
        </div>
      )}
      {Array.isArray(productValues) && (
        <div className='template_card_content_block'>
          <div className='align_items_center' style={{ marginBottom: 24 }}>
            <AppText className='bold' style={{ marginRight: 8 }} textValue={productValuesText} />
            <AppTooltip text={productValuesTooltip}>
              <span>
                <InfoIcon style={{ display: 'flex', color: '#5C5C6E' }} />
              </span>
            </AppTooltip>
          </div>
          <ProductValuesBlock
            productValues={productValues}
            scoring={scoring}
            handleChangeField={handleChangeField('productValues')}
            handleChangeContentFields={handleChangeContentFields}
            chartAnswers={chartAnswers}
          />
        </div>
      )}
      {(item.content?.composerButton?.name || isDraggingOver) && (
        <div className='template_card_bottom_block'>
          <ComposerButton item={item} isDraggingOver={isDraggingOver} onChangeFieldValue={handleChangeFieldValue} onDelete={onDeleteComposerButton} />
        </div>
      )}
    </>
  );
}

const StyledWrapperCheckbox = styled('div')({
  marginRight: 16,
  minWidth: 155,
});

const Styled = styled('div')({
  '.react-select__value-container': {
    padding: '4px 5px 4px',
    overflowX: 'hidden !important',
  },
});
