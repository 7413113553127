/* eslint-disable no-magic-numbers */
import { Typography } from 'antd';
import { ProductLayoutCounter } from '../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ProductLayouts/multiple-counters/components/ProductLayoutCounter';
import styles from './styles.module.scss';
import { useProductPriceTypeText } from '../../hooks/useProductPriceTypeText';
import { safeStr } from '../../../../../../../../lib/util/helpers';
import { formatPriceForUi } from '../../../../../../../../lib/models/Finance';
import { useTotalPriceStringCounter } from './useTotalPriceStringCounter';
import { getCounterQ6 } from '../../utils';
import { useHandleCounterForNoCard } from './useHandleCounterForNoCard';
import { getRulesProductPrice } from '../../../../utils/rules/product_price_rules_helper';
import { getRulesProductCounter } from '../../../../utils/rules/product_counter_rules_helper';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { useUpdateHardcodedSingleCounter } from '../../hooks/useUpdateHardcodedSingleCounter';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

// eslint-disable-next-line max-lines-per-function
export function PreviewSingleCounter({
  fonts,
  colors,
  onUpdateCounter,
  counter,
  allPages,
  productObject,
  products,
  scrollToCard,
  selectable,
  setPagesFlow,
  structureItemId,
  pagesFlow,
  preSelectedCardIndex,
  setDragging,
  carouselRef,
  windowWidth,
  isSelectionModalOpen,
  setSelectionModalOpen,
  recommendedIndexList,
  currentElements,
  scoringResult,
}) {
  const { applyVariable } = useVariables(pagesFlow);

  const selectedElement = products.find(item => item.selected);

  const { showPrice, rulePrice, rulePriceType } = getRulesProductPrice(selectedElement || {}, pagesFlow, currentElements, false);
  const priceType = showPrice ? rulePriceType : selectedElement?.priceSection?.priceType;
  const price = showPrice || rulePriceType === selectedElement?.priceSection?.priceType ? rulePrice : selectedElement?.priceSection?.priceValue;
  const productPriceType = useProductPriceTypeText(selectedElement?.priceSection?.priceType, priceType);
  const { ruleCounter } = getRulesProductCounter(selectedElement, pagesFlow, currentElements, false);
  const allQuestions = allPages?.flatMap?.(page => page?.elements?.filter(el => el.templateType === TemplateTypes.QUESTION));
  const Q6 = allQuestions?.[5];
  const allProducts = allPages.flatMap(page => page?.elements?.filter(el => el.templateType === TemplateTypes.PRODUCT));
  const P9 = allProducts?.[8]?.content?.products?.[0];
  const P10 = allProducts?.[9]?.content?.products?.[0];

  const counterValue = selectedElement?.counterSection?.value || 0;
  const counterQ6 = getCounterQ6(selectedElement, P10, P9, Q6, counterValue);

  const handleCounterForNoCard = useHandleCounterForNoCard({
    products,
    pagesFlow,
    preSelectedCardIndex,
    recommendedIndexList,
    scrollToCard,
    selectable,
    setPagesFlow,
    structureItemId,
    setDragging,
    carouselRef,
    windowWidth,
    isSelectionModalOpen,
    setSelectionModalOpen,
    productObject,
    scoringResult,
  });

  const totalPriceForSingleCounter = useTotalPriceStringCounter({
    Q6,
    P9,
    P10,
    selectedElement,
    counterQ6,
    counterValue,
    ruleCounter,
    pagesFlow,
    price,
    priceType,
  });

  // Todo hardcode Q6 P9 P10
  useUpdateHardcodedSingleCounter({
    onUpdateCounter,
    selectedElement,
    counterQ6,
    Q6,
    P9,
    P10,
  });

  return (
    <>
      {counter.headline.value && (
        <Typography.Title
          ellipsis={{ rows: 1 }}
          style={{
            margin: '0 auto',
            width: '100%',
            textAlign: 'center',
            marginBottom: 16,
            fontSize: fonts.body.fontSize,
            fontFamily: fonts.body.font,
            fontWeight: fonts.body.fontWeight,
            color: colors[fonts.body.colorVariant],
          }}
        >
          {applyVariable(counter.headline.value)}
        </Typography.Title>
      )}

      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <ProductLayoutCounter
              style={{ alignSelf: 'center' }}
              disabled={!selectedElement}
              onChangeCounter={handleCounterForNoCard}
              onUpdateCounter={value => {
                onUpdateCounter(value, selectedElement);
              }}
              showCounter={!selectedElement?.counterSection?.showCounter}
              value={counterValue}
              fonts={fonts}
              colors={colors}
              maxValue={counter.max}
              minValue={counter.min}
              productObject={productObject}
              currentElements={currentElements}
              pagesFlow={pagesFlow}
              card={selectedElement}
              setPagesFlow={setPagesFlow}
            />
          </div>

          {selectedElement?.priceSection?.showTotalPrice && (
            <div className={styles.price}>
              <Typography.Paragraph
                style={{
                  fontSize: 24,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  marginBottom: 10,
                }}
              >
                {`${formatPriceForUi(totalPriceForSingleCounter)} ${safeStr(selectedElement.priceSection.currency)?.toUpperCase()}`}
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  marginBottom: 0,
                }}
              >
                {productPriceType}
              </Typography.Paragraph>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
