import { RuleActionsBodyCell } from './RuleActionsBodyCell';
import { useActionsColumn } from '../../../../../components/columns/actions';

/**
 * @desc Custom hook for managing rule actions columns.
 * @returns {Object} - An object containing properties for managing rule actions columns.
 */
export function useRuleActionsColumn() {
  return useActionsColumn({
    render: (text, row) => <RuleActionsBodyCell row={row} />,
  });
}
