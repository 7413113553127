import { Rule } from '../../lib/Rule';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { useRuleDateColumn } from '../../components/useRuleDateColumn';

/**
 * @desc Custom hook for managing the rule creation date column.
 * @returns {Object} - An object containing properties for managing the rule creation date column.
 */
export function useRuleCreationDateColumn() {
  const { creationDate: title } = useLocalization().translations.shared.columns;

  return useRuleDateColumn({
    arrangedId: Rule.CREATION_DATE,
    title,
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
  });
}
