import { Droppable } from 'react-beautiful-dnd';
import { BulletPointRow } from './BulletPointRow';
import styled from 'styled-components';
import { DroppableIdField, DroppableIdKind, ResultType } from '../../../../../../../../../../../../../../../../lib/models/Questionnaire';
import { QuestionnaireDraggable } from '../../../../../../../../../../features/QuestionnaireDraggable';
import { AppList } from '../../../../../../../../../../../../../../../../ui/AppList';

const getDroppableId = dataPath =>
  JSON.stringify({
    [DroppableIdField.PATH]: dataPath,
    [DroppableIdField.KIND]: DroppableIdKind.WITH_PATH,
  });

export function BulletPoints({
  bulletPointsSection,
  productIndex,
  structureItemIndex,
  bulletPointIcon,
  bulletPointIconColor,
  dataPath,
  disabled,
  ...rest
}) {
  return (
    <div {...rest}>
      <Droppable droppableId={getDroppableId(dataPath)} type={ResultType.BULLET_POINT}>
        {providedDroppable => (
          <List ref={providedDroppable.innerRef}>
            {bulletPointsSection.bulletPoints.map((item, index) => (
              <QuestionnaireDraggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
                {providedDraggable => (
                  <li ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
                    <BulletPointRow
                      providedDraggable={providedDraggable}
                      item={item}
                      rightColumnType={bulletPointsSection.rightColumnType}
                      index={index}
                      productIndex={productIndex}
                      structureItemIndex={structureItemIndex}
                      bulletPointIcon={bulletPointIcon}
                      bulletPointIconColor={bulletPointIconColor}
                      dataPath={dataPath}
                      disabled={disabled}
                    />
                  </li>
                )}
              </QuestionnaireDraggable>
            ))}

            {providedDroppable.placeholder}
          </List>
        )}
      </Droppable>
    </div>
  );
}

const List = styled(AppList)({
  display: 'flex',
  flexDirection: 'column',
});
