export function EmailSuccess() {
  return (
    <svg width='201' height='139' viewBox='0 0 201 139' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_8161_231753)'>
        <path
          d='M21.9167 114.053H159.917C160.99 114.053 161.833 113.223 161.833 112.167V57.3707C172.567 56.4279 181 47.5277 181 36.7419C181 25.315 171.532 16 159.917 16C148.953 16 139.907 24.2968 138.948 34.8563H21.9167C21.84 34.8563 21.8017 34.894 21.725 34.894C21.61 34.894 21.495 34.9317 21.3417 34.9694C21.2267 35.0071 21.1117 35.0448 20.9967 35.1203C20.8817 35.158 20.805 35.2334 20.7283 35.3088C20.6133 35.3843 20.5367 35.4974 20.46 35.5728C20.4217 35.6105 20.3833 35.6482 20.345 35.6859C20.3067 35.7237 20.3067 35.7991 20.2683 35.8368C20.1917 35.9499 20.1533 36.0631 20.115 36.1762C20.0767 36.2894 20.0383 36.4025 20.0383 36.5533C20.0383 36.6288 20 36.6665 20 36.7419V112.167C20 113.223 20.8433 114.053 21.9167 114.053ZM159.917 19.7713C169.423 19.7713 177.167 27.3892 177.167 36.7419C177.167 46.0946 169.423 53.7126 159.917 53.7126C154.167 53.7126 148.8 50.8841 145.58 46.1323C143.663 43.3416 142.667 40.0983 142.667 36.7419C142.667 27.3892 150.41 19.7713 159.917 19.7713ZM138.987 38.6275C139.255 41.4183 140.06 44.1336 141.44 46.5849L90.9167 79.7342L28.2417 38.6275H138.987ZM23.8333 40.2492L89.8433 83.5432C90.1883 83.7695 90.5333 83.8826 90.9167 83.8826C91.3 83.8826 91.645 83.7695 91.99 83.5809L143.548 49.7527C147.152 54.1274 152.327 56.8804 158 57.3707V110.281H23.8333V40.2492Z'
          fill='#FF6600'
        />
        <path
          d='M156.053 45.6096C156.42 45.9867 156.897 46.1752 157.338 46.1752C157.778 46.1752 158.292 45.9867 158.622 45.6096L173.3 30.5245C174.034 29.7703 174.034 28.6012 173.3 27.8469C172.566 27.0927 171.428 27.0927 170.694 27.8469L157.301 41.612L151.247 35.3894C150.513 34.6352 149.375 34.6352 148.641 35.3894C147.907 36.1437 147.907 37.3128 148.641 38.067L156.053 45.6096Z'
          fill='#FF6600'
        />
      </g>
      <defs>
        <filter id='filter0_d_8161_231753' x='0' y='0' width='201' height='138.055' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8161_231753' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_8161_231753' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
