import { useSelector } from 'react-redux';

export function useIconList() {
  const { bulletIcons } = useSelector(({ finders }) => finders.finder.finderSettings);
  return [...bulletIcons.customIcons, ...bulletIcons.defaultIcons].map(item => ({
    label: item.originalFileName,
    value: item.id,
    image: item.s3Url,
  }));
}
