export function CloseIcon() {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.209802 0.209802C0.276139 0.143298 0.354944 0.0905335 0.441704 0.0545322C0.528463 0.0185308 0.621474 0 0.715406 0C0.809339 0 0.902349 0.0185308 0.989109 0.0545322C1.07587 0.0905335 1.15467 0.143298 1.22101 0.209802L5.00019 3.99041L8.77936 0.209802C8.84576 0.143405 8.92459 0.0907363 9.01134 0.0548025C9.09809 0.0188688 9.19107 0.000373895 9.28497 0.000373895C9.37887 0.000373895 9.47185 0.0188688 9.5586 0.0548025C9.64535 0.0907363 9.72418 0.143405 9.79057 0.209802C9.85697 0.276199 9.90964 0.355023 9.94557 0.441775C9.98151 0.528527 10 0.621507 10 0.715406C10 0.809306 9.98151 0.902286 9.94557 0.989038C9.90964 1.07579 9.85697 1.15461 9.79057 1.22101L6.00997 5.00019L9.79057 8.77936C9.85697 8.84576 9.90964 8.92459 9.94557 9.01134C9.98151 9.09809 10 9.19107 10 9.28497C10 9.37887 9.98151 9.47185 9.94557 9.5586C9.90964 9.64535 9.85697 9.72418 9.79057 9.79057C9.72418 9.85697 9.64535 9.90964 9.5586 9.94557C9.47185 9.98151 9.37887 10 9.28497 10C9.19107 10 9.09809 9.98151 9.01134 9.94557C8.92459 9.90964 8.84576 9.85697 8.77936 9.79057L5.00019 6.00997L1.22101 9.79057C1.15461 9.85697 1.07579 9.90964 0.989038 9.94557C0.902286 9.98151 0.809306 10 0.715406 10C0.621507 10 0.528527 9.98151 0.441775 9.94557C0.355023 9.90964 0.276199 9.85697 0.209802 9.79057C0.143405 9.72418 0.0907363 9.64535 0.0548025 9.5586C0.0188688 9.47185 0.000373895 9.37887 0.000373895 9.28497C0.000373895 9.19107 0.0188688 9.09809 0.0548025 9.01134C0.0907363 8.92459 0.143405 8.84576 0.209802 8.77936L3.99041 5.00019L0.209802 1.22101C0.143298 1.15467 0.0905335 1.07587 0.0545322 0.989109C0.0185308 0.902349 0 0.809339 0 0.715406C0 0.621474 0.0185308 0.528463 0.0545322 0.441704C0.0905335 0.354944 0.143298 0.276139 0.209802 0.209802Z'
        fill='white'
      />
    </svg>
  );
}
