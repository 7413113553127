/* eslint-disable no-magic-numbers,no-plusplus,consistent-return,init-declarations,no-unsafe-optional-chaining,sonarjs/cognitive-complexity,max-lines */

const createProductData = (prodObj, index) => {
  const dataArray = [];

  let costPerTransaction;
  for (let i = 1; i < 501; i++) {
    // data for first line from 0 to 500
    if (index === 0) {
      costPerTransaction = 50000000 / (i + 30) + 2100000;
      dataArray.push({ productName: prodObj.productName, amount: i - 1, cost: costPerTransaction });
    }
    // data for second line from 80 to 500
    if (index === 1 && i < 421) {
      costPerTransaction = 50000000 / (i + 21) + 1400000;
      dataArray.push({ productName: prodObj.productName, amount: i + 79, cost: costPerTransaction });
    }
    // data for third line from 160 to 500
    if (index === 2 && i < 341) {
      costPerTransaction = 50000000 / (i + 16) + 700000;
      dataArray.push({ productName: prodObj.productName, amount: i + 159, cost: costPerTransaction });
    }
    // data for fourth line from 240 to 500
    if (index === 3 && i < 261) {
      costPerTransaction = 50000000 / (i + 13) + 100000;
      dataArray.push({ productName: prodObj.productName, amount: i + 239, cost: costPerTransaction });
    }
  }
  return dataArray;
};

export const filterProdsWithPriceAndName = prods => prods?.filter(prod => prod.value && prod.price && prod.productName);

const getChartData = products => filterProdsWithPriceAndName(products)?.flatMap((prod, index) => createProductData(prod, index));

export const createChartConfig = ({ initialData, xAxis, yAxis, subText, color, lineColors }) => {
  const formattedData = getChartData(initialData);

  return {
    data: formattedData,
    height: 350,
    width: 400,
    renderer: 'svg',
    xField: 'amount',
    yField: 'cost',
    seriesField: 'productName',
    legend: false,
    point: {
      shape: 'breath-point',
    },
    smooth: true,
    tooltip: {
      customContent: () => null,
    },
    xAxis: {
      tickInterval: 10,
      nice: true,
      grid: null,
      title: {
        text: xAxis ? xAxis.value : null,
        style: {
          fontSize: subText.fontSize,
          fontFamily: subText.font,
          fontWeight: 400,
          color,
        },
      },
      line: {
        style: {
          stroke: '#5C5C6E',
        },
      },
      label: null,
    },
    yAxis: {
      nice: true,
      grid: null,
      title: {
        text: yAxis ? yAxis.value : null,
        style: {
          fontSize: subText.fontSize,
          fontFamily: subText.font,
          fontWeight: 400,
          color,
        },
      },
      line: {
        style: {
          stroke: '#5C5C6E',
        },
      },
      label: null,
    },
    color: lineColors,
  };
};

const getServiceCost = (prods, chartAnswers, index) => {
  return chartAnswers.reduce((cur, next) => {
    const servicePrice = prods[index]?.prices.find(price => price.priceId === next.id).value;
    return cur + next.amount * servicePrice;
  }, 0);
};

const getTotalCost = (prods, chartAnswers, index) => {
  const totalServiceCost = getServiceCost(prods, chartAnswers, index);
  return prods[index]?.price + totalServiceCost;
};

const getFirstProdAmount = (prods, TOTAL_COST_PROD1, TOTAL_COST_PROD2) => {
  const pricesPercentage = prods[0]?.price / prods[1]?.price;
  const supportVariable = (TOTAL_COST_PROD1 / TOTAL_COST_PROD2 - pricesPercentage) / (1 - pricesPercentage);
  return Math.ceil(105 * supportVariable);
};

const getSecondProdAmount = (prods, TOTAL_COST_PROD1, TOTAL_COST_PROD2, TOTAL_COST_PROD3) => {
  const supportVariable = -(TOTAL_COST_PROD2 - TOTAL_COST_PROD1) / (TOTAL_COST_PROD3 - TOTAL_COST_PROD2 - (TOTAL_COST_PROD2 - TOTAL_COST_PROD1));
  return Math.ceil((189 - 105) * supportVariable) + 105;
};

const getLastProdAmount = (prods, TOTAL_COST_PROD1, TOTAL_COST_PROD2, serviceCostLastProd, serviceCostPrevProd, startPoint, endPoint) => {
  const strangeVariable = serviceCostLastProd - (TOTAL_COST_PROD2 - TOTAL_COST_PROD1);
  const supportVariable =
    (-strangeVariable / (1.2 * strangeVariable + 8) + (strangeVariable / (1.2 * strangeVariable + 8)) * (TOTAL_COST_PROD1 / TOTAL_COST_PROD2)) *
    (serviceCostLastProd / (serviceCostPrevProd - serviceCostLastProd));
  return Math.ceil((endPoint - startPoint) * supportVariable) + startPoint;
};

const getDotPoint = (prodArr, amount, bestProd, chartAnswers) => {
  const TOTAL_COST_PROD1 = getTotalCost(prodArr, chartAnswers, 0);
  const TOTAL_COST_PROD2 = getTotalCost(prodArr, chartAnswers, 1);
  const TOTAL_COST_PROD3 = getTotalCost(prodArr, chartAnswers, 2);
  const TOTAL_COST_PROD4 = getTotalCost(prodArr, chartAnswers, 3);
  const serviceCostProd1 = getServiceCost(prodArr, chartAnswers, 0);
  const serviceCostProd2 = getServiceCost(prodArr, chartAnswers, 1);
  const serviceCostProd3 = getServiceCost(prodArr, chartAnswers, 2);
  const serviceCostProd4 = getServiceCost(prodArr, chartAnswers, 3);

  if (prodArr.length === 0) {
    return { productName: '', amount: null };
  }

  if (prodArr.length === 1) {
    return { productName: prodArr[0]?.productName, amount: Math.ceil(amount / 2) };
  }

  if (prodArr.length === 2) {
    if (bestProd?.id === prodArr[0]?.id) {
      return { productName: prodArr[0]?.productName, amount: getFirstProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2) };
    }

    if (bestProd?.id === prodArr[1]?.id) {
      return {
        productName: prodArr[1]?.productName,
        amount: getLastProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2, serviceCostProd2, serviceCostProd1, 105, 563),
      };
    }
  }

  if (prodArr.length === 3) {
    if (bestProd?.id === prodArr[0]?.id) {
      return { productName: prodArr[0]?.productName, amount: getFirstProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2) };
    }

    if (bestProd?.id === prodArr[1]?.id) {
      return { productName: prodArr[1]?.productName, amount: getSecondProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2, TOTAL_COST_PROD3) };
    }

    if (bestProd?.id === prodArr[2]?.id) {
      return {
        productName: prodArr[2]?.productName,
        amount: getLastProdAmount(prodArr, TOTAL_COST_PROD2, TOTAL_COST_PROD3, serviceCostProd3, serviceCostProd2, 189, 546),
      };
    }
  }

  if (prodArr.length === 4) {
    if (bestProd?.id === prodArr[0]?.id) {
      return { productName: prodArr[0]?.productName, amount: getFirstProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2) };
    }

    if (bestProd?.id === prodArr[1]?.id) {
      return { productName: prodArr[1]?.productName, amount: getSecondProdAmount(prodArr, TOTAL_COST_PROD1, TOTAL_COST_PROD2, TOTAL_COST_PROD3) };
    }

    if (bestProd?.id === prodArr[2]?.id) {
      const supportVariable = -(TOTAL_COST_PROD3 - TOTAL_COST_PROD2) / (TOTAL_COST_PROD4 - TOTAL_COST_PROD3 - (TOTAL_COST_PROD3 - TOTAL_COST_PROD2));
      return { productName: prodArr[2]?.productName, amount: Math.ceil((278 - 189) * supportVariable) + 189 };
    }

    if (bestProd?.id === prodArr[3]?.id) {
      return {
        productName: prodArr[3]?.productName,
        amount: getLastProdAmount(prodArr, TOTAL_COST_PROD3, TOTAL_COST_PROD4, serviceCostProd4, serviceCostProd3, 278, 536),
      };
    }
  }
};

export const getBestProduct = (prods, chartAnswers) => {
  const filteredProds = filterProdsWithPriceAndName(prods);
  const initialSortProducts = filteredProds.filter(prod => prod.value && prod.price && prod.productName).sort((cur, next) => cur.price - next.price);
  const sortBestProducts = filteredProds
    .map(prod => {
      const servicesCost = chartAnswers.reduce((cur, next) => {
        const serviceCost = next.amount * prod.prices.find(price => price.priceId === next.id).value;
        return cur + serviceCost;
      }, 0);
      return { ...prod, cost: Math.round((prod.price + servicesCost) * 100) / 100 };
    })
    .sort((cur, next) => cur.cost - next.cost);
  const pointsSum = chartAnswers.reduce((cur, next) => cur + next.amount, 0);
  const bestProd =
    sortBestProducts[0]?.cost === sortBestProducts[1]?.cost && sortBestProducts[1]?.price > sortBestProducts[0]?.price
      ? sortBestProducts[1]
      : sortBestProducts[0];

  return getDotPoint(initialSortProducts, pointsSum, bestProd, chartAnswers);
};
