import { useSelector } from 'react-redux';
import { AppBox } from '../../../../../../../ui/boxes/AppBox';
import { GripVerticalIcon } from '../../../../../../../ui/icons/GripVerticalIcon';
import { forwardRef } from 'react';
import classNames from 'classnames';

export const QuestionnaireVerticalGripHandle = forwardRef(({ children, disabled, dragHandleProps, className, ...rest }, ref) => {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <AppBox disabled={isViewMode || disabled} {...dragHandleProps} className={classNames('align_items_center', className)} {...rest} ref={ref}>
      <GripVerticalIcon />
    </AppBox>
  );
});
