import { getAnswerScoreValue } from './answer_score_rules_helper';
import { getAnswerSavingsValue } from './answer_savings_rules_helper';

export const getRulesAnswerProperties = (prevRulesAnswersScore, prevRulesAnswersSavings, allQuestion, scoreAnswer, savingsAnswer) => {
  const allSimpleAnswers = allQuestion.filter(el => Array.isArray(el.content?.answers)).flatMap(el => el.content?.answers);
  const scoreRulesArray = getAnswerScoreValue(scoreAnswer, allSimpleAnswers);
  const savingsRulesArray = getAnswerSavingsValue(savingsAnswer, allSimpleAnswers);

  const newAnswersScore = updateAnswerProperties(scoreRulesArray, prevRulesAnswersScore);
  const newAnswersSavings = updateAnswerProperties(savingsRulesArray, prevRulesAnswersSavings);

  return { rulesAnswersScore: newAnswersScore, rulesAnswersSavings: newAnswersSavings };
};

export const updateAnswerProperties = (newProperties, oldProperties) => {
  const newAnswersIds = new Set(newProperties.map(answer => answer.id));
  const otherOldAnswers = oldProperties.filter(answer => !newAnswersIds.has(answer.id));

  return [...newProperties, ...otherOldAnswers];
};
