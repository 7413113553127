import { v4 as uuidv4 } from 'uuid';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../../../../lib/models/Questionnaire';

export function generateDetailTable() {
  return {
    id: uuidv4(),
    headline: {
      value: '',
      infoIcon: '',
    },
    subline: {
      value: '',
      infoIcon: '',
    },
    score: {
      showScore: false,
      scoreList: [
        { id: uuidv4(), value: '0/3' },
        { id: uuidv4(), value: '1/3' },
        { id: uuidv4(), value: '2/3' },
        { id: uuidv4(), value: '3/3' },
      ],
      selectedScore: '0/3',
    },
    bulletPointsSection: {
      rightColumnType: ProductRightBulletPointColumnType.TEXT,
      bulletPoints: [],
      bulletPointIcon: { value: '', svgContent: '' },
      bulletPointIconColor: null,
    },
  };
}
