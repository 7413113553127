/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppInputNumber } from '../../../../../../../../../../ui/AppInputNumber';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { generateCell, generateEmptyRow, getCellsAmount, getRowsAmount, normalizeField } from '../comparison-table-utils';
import { useContentTable } from '../hooks/useContentTable';

export function ColumnsField() {
  const { fieldLabels } = useLocalization().translations.unique.pages.questionnaire.contentTable;
  const [columnsAmount, setColumnsAmount] = useState('');
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { contentTableState } = useContentTable();

  const onChangeColumnsField = ({ target }) => {
    contentTableState.updateTable(table => {
      let { value } = target;
      value = normalizeField(value);

      const isMore = !columnsAmount || value > columnsAmount;
      const isLess = value < columnsAmount;
      const isEmpty = value < 1;

      if (isEmpty) {
        table.tableRows = [];
      } else if (isMore) {
        const columnsToAddAmount = value - columnsAmount;
        const newCells = Array.from({ length: columnsToAddAmount }, () => generateCell());

        if (getRowsAmount(table.tableRows) < 1) {
          table.tableRows.push(generateEmptyRow(0));
        }

        for (const row of table.tableRows) {
          row.cells = row?.cells?.concat(newCells);
        }
      } else if (isLess) {
        const columnsToDeleteAmount = columnsAmount - value;

        for (const row of table.tableRows) {
          row.cells.splice(value, columnsToDeleteAmount);
        }
      }

      setColumnsAmount(value);
    });
  };

  useEffect(() => {
    setColumnsAmount(getCellsAmount(contentTableState.tableRows));
  }, [contentTableState.tableRows]);

  return <AppInputNumber size='small' label={fieldLabels.columns} value={columnsAmount} onChange={onChangeColumnsField} disabled={isViewMode} />;
}
