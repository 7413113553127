import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { isLength, safeJsonParse } from '../../../../../../../../../../lib/util/helpers';
import { types, resultSubTypes } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';
import './style.scss';

export function FlowRulesList({ elementRules = [] }) {
  const { finderRules } = useSelector(({ finders }) => finders);

  const allElementRules = elementRules
    .filter(rule => rule.ruleId)
    .map(ruleObj => finderRules.find(rule => rule.ruleId === ruleObj.ruleId))
    .filter(Boolean);
  const flowElementRules = allElementRules.filter(rule => {
    const conditionsBodies = safeJsonParse(rule?.conditions)?.map(condition => condition.body);
    const resultTypes = conditionsBodies?.flatMap(body => body.filter(bodyItem => bodyItem.type === types.resultType));
    return resultTypes?.some(resType => resType?.value?.elementType === resultSubTypes.Flow);
  });
  const flowRulesListText = flowElementRules?.map(rule => rule?.name).join(', ');

  return isLength(allElementRules) && <AppText className='flow_rules_list'>{flowRulesListText}</AppText>;
}
