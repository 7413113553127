import { AppText } from '../../../../../../ui/text/AppText';
import { ArrangeButtonWrapper } from '../ArrangeButtonWrapper';
import tableStyles from '../../utils/styles/table.module.sass';
import styled from 'styled-components';

export function TitleAndButton({ withArrange, children, title, form, isActiveArrange, sortOrder }) {
  return (
    <div style={styles.root}>
      <Title textValue={title} className={tableStyles.headerCellTitle} />

      {withArrange && (
        <ArrangeButtonWrapper form={form} isActiveArrange={isActiveArrange} sortOrder={sortOrder}>
          {children}
        </ArrangeButtonWrapper>
      )}
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
};

const Title = styled(AppText)({
  whiteSpace: 'pre',
});
