import { useState } from 'react';
import { Space } from 'antd';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormCard } from '../../../../../features/FormCard';
import { FormCardButton } from '../../../../../features/FormCardButton';
import { FinderFileLoader } from '../../../../../features/FinderFileLoader';
import { UploadLabel } from '../../../../../features/UploadLabel';
import { UploadedIconBox } from '../../../../../features/UploadedIconBox';
import { PreloaderIcon } from '../../../../../../../../../ui/icons/PreloaderIcon';
import '../styles.scss';

const GAP_SIZE = 16;
const MAX_ICON_FILE_SIZE = 0.3;

export function BulletPointsCard({ designSettings, setDesignSettings }) {
  const [uploading, setUploading] = useState(false);
  const { design } = useLocalization().translations.unique.pages.settings;
  const { items } = useLocalization().translations.shared.generic;
  const { bulletIcons } = designSettings;

  const updateDefaultIcons = file => {
    setDesignSettings({ ...designSettings, bulletIcons: { ...bulletIcons, customIcons: [...bulletIcons.customIcons, file] } });
  };

  const deleteIcon = (fieldName, id) => {
    const filteredIcons = bulletIcons[fieldName].filter(file => file.id !== id);
    setDesignSettings({ ...designSettings, bulletIcons: { ...bulletIcons, [fieldName]: filteredIcons } });
  };

  const resetCustomIcons = () => {
    setDesignSettings({ ...designSettings, bulletIcons: { ...bulletIcons, customIcons: [] } });
  };

  const customIconsOptions = uploading ? [...bulletIcons.customIcons, { id: 'uploading' }] : bulletIcons.customIcons;

  return (
    <FormCard
      title={
        <>
          <AppBox style={{ marginBottom: 24 }}>
            <AppTitle className='align_items_center' level={4} textValue={design.bulletPoints}>
              {design.bulletPoints()} {uploading && <PreloaderIcon className='file_uploader' style={{ marginLeft: 8, width: 18, height: 18 }} />}
            </AppTitle>
          </AppBox>
          <AppText className='settings_card_label' textValue={design.default} />
          <AppBox style={{ marginBottom: 32, minHeight: 32 }}>
            <Space size={[GAP_SIZE, GAP_SIZE]} wrap>
              {bulletIcons?.defaultIcons?.map(icon => (
                <UploadedIconBox key={icon.id} icon={icon} deleteOnClick={() => deleteIcon('defaultIcons', icon.id)} />
              ))}
            </Space>
          </AppBox>

          <AppBox className='space_between' style={{ marginBottom: 24 }}>
            <AppText className='settings_card_label settings_card_label__no_margin_bottom'>
              {design.customIcons()}
              <AppText className='settings_card_label__regular' textValue={items({ amount: bulletIcons?.customIcons?.length })} />
            </AppText>
            <FormCardButton onClick={resetCustomIcons} />
          </AppBox>

          <AppBox style={{ marginBottom: 32, minHeight: 32 }}>
            <Space size={[GAP_SIZE, GAP_SIZE]} wrap>
              {customIconsOptions?.map(icon => (
                <UploadedIconBox key={icon.id} icon={icon} deleteOnClick={() => deleteIcon('customIcons', icon.id)} />
              ))}
            </Space>
          </AppBox>
        </>
      }
    >
      <AppBox>
        <FinderFileLoader
          afterUploadAction={updateDefaultIcons}
          errorText={design.beforeUploadIconError()}
          maxFileSize={MAX_ICON_FILE_SIZE}
          targetType='FINDER_ICONS'
          disabled={uploading}
          setUploading={setUploading}
          uploadLabel={<UploadLabel uploading={uploading} text={design.newIconUpload} infoIcon infoText={design.uploadIconTooltip()} />}
        />
      </AppBox>
    </FormCard>
  );
}
