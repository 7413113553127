import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';
import styled from 'styled-components';
import { handleTranslation } from '../../../../../../../../../../../../../lib/util/helpers';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { ProductRightBulletPointColumnType } from '../../../../../../../../../../../../../lib/models/Questionnaire';
import lodashGet from 'lodash.get';

export function ProductBulletPointsSubHeaderRow({ value, structureItemIndex, dataPath, disabled, ...rest }) {
  const { updateStructure } = useUpdateStructure();
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;

  const options = [
    { title: contentText.bulletPointTypes.textType, value: ProductRightBulletPointColumnType.TEXT },
    { title: contentText.bulletPointTypes.iconType, value: ProductRightBulletPointColumnType.ICON },
  ].map(item => ({ ...item, title: handleTranslation(item.title) }));

  const onChange = selected => {
    updateStructure(draft => {
      const bulletPointsSection = lodashGet(draft, dataPath);
      bulletPointsSection.rightColumnType = selected.value;
    });
  };

  return (
    <Root {...rest}>
      <div>
        <AppText strong style={{ fontSize: '12px' }} textValue={contentText.columnLeft} />
      </div>

      <Right>
        <AppText strong style={{ fontSize: '12px', paddingTop: '5px' }} textValue={contentText.columnRight} />
        <QuestionnaireSelect
          size='small'
          style={{ width: 'unset', paddingRight: '32px' }}
          formItemProps={{
            style: { width: '91px' },
          }}
          selectProps={{ options, value: { value }, onChange, disabled }}
        />
      </Right>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '5px',
  justifyContent: 'space-between',
  columnGap: '18px',

  '& > *:first-child': { flex: 6.5, display: 'flex' },
  '& > *:last-child': { flex: 3.5, display: 'flex' },
});

const Right = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
