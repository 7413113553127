/* eslint-disable sonarjs/cognitive-complexity */
import { comparisonTypes } from '../../pages/BuilderPage/Condition/Actions/actionConstants';

export const logicalComparisons = (accumulator, propertyResult) => {
  if (accumulator.logicalOperator === 'AND') {
    return { value: accumulator.value && propertyResult, logicalOperator: null };
  }
  if (accumulator.logicalOperator === 'OR') {
    return { value: accumulator.value || propertyResult, logicalOperator: null };
  }
  return { ...accumulator, value: propertyResult };
};

export const getArrayComparisons = (accumulator, propertyResult) => {
  if (accumulator.logicalOperator) {
    return logicalComparisons(accumulator, propertyResult);
  }

  if (accumulator.comparisonOperator === comparisonTypes.equals) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem === item))
        : propertyResult.every(item => Number(accumulator.value) === item),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.notEquals) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem !== item))
        : propertyResult.every(item => Number(accumulator.value) !== item),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.greater) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem > item))
        : propertyResult.every(item => Number(accumulator.value) > item),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.greaterOrEqual) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem >= item))
        : propertyResult.every(item => Number(accumulator.value) >= item),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.less) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem < item))
        : propertyResult.every(item => Number(accumulator.value) < item),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.lessOrEqual) {
    return {
      value: Array.isArray(accumulator.value)
        ? propertyResult.every(item => accumulator.value.every(acItem => acItem <= item))
        : propertyResult.every(item => Number(accumulator.value) <= item),
      comparisonOperator: null,
    };
  }

  return { ...accumulator, value: propertyResult };
};

export const getDigitalComparisons = (accumulator, digitalValue) => {
  if (accumulator.comparisonOperator === comparisonTypes.equals) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item === Number(digitalValue))
        : Number(accumulator.value) === Number(digitalValue),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.notEquals) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item !== Number(digitalValue))
        : Number(accumulator.value) !== Number(digitalValue),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.greater) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item > Number(digitalValue))
        : Number(accumulator.value) > Number(digitalValue),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.greaterOrEqual) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item >= Number(digitalValue))
        : Number(accumulator.value) >= Number(digitalValue),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.less) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item < Number(digitalValue))
        : Number(accumulator.value) < Number(digitalValue),
      comparisonOperator: null,
    };
  }
  if (accumulator.comparisonOperator === comparisonTypes.lessOrEqual) {
    return {
      value: Array.isArray(accumulator.value)
        ? accumulator.value.every(item => item <= Number(digitalValue))
        : Number(accumulator.value) <= Number(digitalValue),
      comparisonOperator: null,
    };
  }

  return { ...accumulator, value: digitalValue };
};
