import { DeleteButton } from './DeleteButton';
import { BodyCell } from './BodyCell';
import classes from '../ComparisonTable.module.sass';
import { useContentTable } from '../hooks/useContentTable';
import { updateInfoIconsIndexes } from '../../../../utils/helpers';

export function BodyDeleteRowCell({ rowIndex }) {
  const { contentTableState } = useContentTable();

  const deleteRow = index => {
    contentTableState.updateTable(({ tableRows }) => {
      tableRows.splice(index, 1);
      updateInfoIconsIndexes(tableRows);
    });
  };

  return (
    <BodyCell rootProps={{ className: classes.bodyDeleteRowCell }}>
      <DeleteButton onClick={() => deleteRow(rowIndex)} />
    </BodyCell>
  );
}
