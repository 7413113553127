import { HeadCell } from './HeadCell';
import { HeaderDragColumnsCells } from './HeaderDragColumnsCells';
import classes from '../ComparisonTable.module.sass';

export function DragColumnsRow({ provided }) {
  return (
    <tr ref={provided.innerRef} {...provided.droppableProps}>
      <HeadCell className={classes.dragRowHandleCellWidth} />
      <HeadCell className={classes.deleteRowCellWidth} />
      <HeaderDragColumnsCells />
      {provided.placeholder}
    </tr>
  );
}
