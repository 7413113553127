/* eslint-disable no-param-reassign */
import styled from 'styled-components';
import { AppSwitch } from 'src/ui/AppSwitch';
import { useContentTable } from 'src/processes/CMS/processes/management/pages/QuestionnairePage/components/QuestionnaireContent/ContentTableSection/hooks/useContentTable';

function CellPopupFootnotes({ rowIndex, columnIndex }) {
  const { contentTableState } = useContentTable();
  const currentCell = contentTableState.tableRows[rowIndex].cells[columnIndex];
  const { enabled, note } = currentCell.footnote || {
    enabled: false,
    note: '',
  };

  const changeEnabled = () => {
    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].cells[columnIndex].footnote = {
        enabled: !enabled,
        note,
      };
    });
  };

  const changeNote = event => {
    const formattedNote = event.target.value.replace(/\D/g, '');
    contentTableState.updateTable(({ tableRows }) => {
      tableRows[rowIndex].cells[columnIndex].footnote = {
        note: formattedNote,
        enabled,
      };
    });
  };

  return (
    <Container>
      <AppSwitch switchProps={{ onChange: changeEnabled, checked: enabled }} labelProps={{ textValue: 'Footnote', style: { color: 'white' } }} />
      <Input placeholder='Enter #' value={note} onChange={changeNote} disabled={!enabled} />
    </Container>
  );
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const Input = styled('input')({
  borderRadius: '8px',
  backgroundColor: '#5C5C6E',
  color: 'white',
  boxShadow: 'none',
  outline: 'none',
  border: '1px solid transparent',
  marginLeft: '8px',
  marginRight: '8px',
  paddingLeft: '8px',
  width: '106px',
});

export { CellPopupFootnotes };
