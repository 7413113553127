import { iconList } from './iconList';

export function Icon({ icon, width = 8, height = 8, fill = '#5C5C6E' }) {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      {iconList[icon].map(path => (
        <path key={path} fillRule='evenodd' clipRule='evenodd' d={path} fill={fill} />
      ))}
    </svg>
  );
}
