/* eslint-disable max-lines */
import { AppNewSelect } from 'src/ui/AppNewSelect';
import { AppInput } from 'src/ui/AppInput';

import { useElements } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useElements';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';
import { Product as ProductConstants } from '../../../../FindersAndProductsPage/lib/Product';

import { findCommon } from 'src/lib/util/helpers';

import {
  resultTypeProductProperties,
  productResultTypeActions,
  productRangeOptions,
  priceActions,
  priceTypes,
  mapSelectOptions,
} from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import styles from '../ConditionItem.module.scss';
import { ProductRange } from './ProductRange';

const mappedProductOptions = mapSelectOptions(Object.values(resultTypeProductProperties));

const productActions = mapSelectOptions(Object.values(productResultTypeActions));

const rangeOptions = mapSelectOptions(Object.values(productRangeOptions));

const priceActionOptions = mapSelectOptions(Object.values(priceActions));
const priceTypesOptions = [
  {
    title: priceTypes.perMonth,
    value: ProductConstants.PRICE_PER_MONTH,
  },
  {
    title: priceTypes.perYear,
    value: ProductConstants.PRICE_PER_YEAR,
  },
  {
    title: priceTypes.perRequest,
    value: ProductConstants.PRICE_PRE_REQUEST,
  },
];

/**
 * @desc Product
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function Product({ value, onChange }) {
  const { handleElementsChange, handlePropertyChange } = useConditionResult({
    value,
    onChange,
  });

  const { elementType, elements, property, action, product, range, pricePerRequestText, priceType } = value;

  const elementsWithoutTitle = (elements || []).map(elem => ({
    ...elem,
    title: '',
  }));

  const productsWithoutTitle = (product || []).map(prod => ({
    ...prod,
    title: '',
  }));

  const { products } = useElements();

  let selectedCardProducts = [];

  if (property === resultTypeProductProperties.dependance) {
    const updatedElementsArray = products.filter(productItem => {
      const existingElement = elements.find(elem => productItem.id === elem.id);
      return Boolean(existingElement);
    });

    const productsArrays = updatedElementsArray.map(elementItem => elementItem.products);

    selectedCardProducts = findCommon(productsArrays);

    for (const elementItem of elements) {
      selectedCardProducts = selectedCardProducts.filter(cardItem => cardItem.id !== elementItem.id);
    }
  }

  const handleRangeOptionChange = value => {
    const updatedObj = {
      elementType,
      elements,
      property,
      action,
      priceType,
      range: value,
      pricePerRequestText: null,
    };

    if (value === productRangeOptions.fromTo) {
      onChange({
        ...updatedObj,
        rangeFrom: '0',
        rangeTo: '0',
      });
    } else {
      onChange(updatedObj);
    }
  };

  return (
    <>
      <AppNewSelect
        label='Select Product'
        className={`${styles.largeSelect} ${styles.selectItem}`}
        value={elementsWithoutTitle}
        titleSearch
        isMultiSelect
        onChange={handleElementsChange}
        options={products}
      />
      {elements && (
        <AppNewSelect
          label='Property'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={property}
          fullWidth
          onChange={({ value }) => {
            handlePropertyChange(value);
          }}
          options={mappedProductOptions}
        />
      )}

      {property === resultTypeProductProperties.productSelection && (
        <AppNewSelect
          label='Action'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={action}
          fullWidth
          onChange={({ value }) => {
            onChange({
              elementType,
              elements,
              property,
              action: value,
            });
          }}
          options={productActions}
        />
      )}

      {property === resultTypeProductProperties.dependance && (
        <AppNewSelect
          label='Child'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          fullWidth
          value={productsWithoutTitle}
          isMultiSelect
          onChange={selected => {
            onChange({
              elementType,
              elements,
              property,
              action,
              product: selected,
            });
          }}
          options={selectedCardProducts}
        />
      )}

      {property === resultTypeProductProperties.price && (
        <AppNewSelect
          label='Action with price'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={action}
          fullWidth
          onChange={({ value }) => {
            onChange({
              elementType,
              elements,
              property,
              action: value,
              priceType: null,
              range: null,
              pricePerRequestText: null,
            });
          }}
          options={priceActionOptions}
        />
      )}

      {property === resultTypeProductProperties.price && action && (
        <AppNewSelect
          label='Select price type'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={priceType}
          onChange={({ value }) => {
            onChange({
              elementType,
              elements,
              property,
              action,
              priceType: value,
              range: null,
              pricePerRequestText: null,
            });
          }}
          options={priceTypesOptions}
        />
      )}

      {property === resultTypeProductProperties.price && priceType === priceTypes.perRequest && action === priceActions.changePrice && (
        <div className={styles.inputItem}>
          <AppInput
            label={priceTypes.perRequest}
            className={styles.largeSelect}
            value={pricePerRequestText}
            onChange={event => {
              onChange({
                elementType,
                elements,
                property,
                action,
                priceType,
                range: null,
                pricePerRequestText: event.target.value,
              });
            }}
          />
        </div>
      )}

      {property === resultTypeProductProperties.price && priceType && priceType !== priceTypes.perRequest && action === priceActions.changePrice && (
        <AppNewSelect
          label='Range'
          titleSearch
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={range}
          onChange={({ value }) => {
            handleRangeOptionChange(value);
          }}
          options={rangeOptions}
        />
      )}

      {property === 'Price' && range === productRangeOptions.fromTo && priceType !== priceTypes.perRequest && (
        <ProductRange value={value} onChange={onChange} />
      )}
    </>
  );
}
