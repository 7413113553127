import classNames from 'classnames';
import classes from '../index.module.scss';
import { FIXED_COLUMN_INDEX, getAlign, getBgColor, isTransparentRow } from '../lib/utils';
import { CellContent } from './CellContent';
import { isOdd } from '../../../../../../../lib/util/helpers';
import { DefaultRowColor } from '../../../pages/QuestionnairePage/components/QuestionnaireContent/ContentTableSection/comparison-table-utils';

export function Cell({ item, row, columnIndex, rowIndex, colors, fonts, footnoteRef, idx }) {
  const background = getCellBg(row, rowIndex, colors);

  return (
    <div
      style={{ background, justifyContent: getAlign(item?.align), textAlign: item?.align }}
      className={classNames(classes.cell, { [classes.fixedCell]: columnIndex === FIXED_COLUMN_INDEX })}
    >
      <CellContent item={item} fonts={fonts} colors={colors} rowIndex={rowIndex} footnoteRef={footnoteRef} idx={idx} />
    </div>
  );
}

const EVEN_BG = '#FFFFFF';

function getCellBg(row, rowIndex, colors) {
  const customBg = row?.bgColor?.value;
  if (customBg && customBg !== DefaultRowColor.NONE) return customBg;

  const bgColor = getBgColor(colors);

  if (isTransparentRow(row?.rowTypes, rowIndex)) {
    return bgColor;
  }

  return isOdd(row?.subTableRowIndex) ? bgColor : EVEN_BG;
}
