import { axiosLogger } from '../axios-logger';
import { AuthURI } from '../../../../api';
import { AppLocalStorage } from '../../../helpers';
import { ACCESS_TOKEN_NAME } from '../../../constants';

function fixPostRequest(request) {
  if (request.method.toLowerCase() === 'post' && !request.data) {
    request.data = {};
  }
}

function passToken(request) {
  const isOurApiCall = !request?.url?.startsWith?.('http');
  const isUpdateAccessTokenRequest = request?.url?.includes?.(AuthURI.UPDATE_ACCESS_TOKEN);

  if (isOurApiCall && !isUpdateAccessTokenRequest) {
    const accessToken = AppLocalStorage.getItem({ key: ACCESS_TOKEN_NAME });
    // eslint-disable-next-line i18n-text/no-en
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
}

export function handleFulfilledRequest(request) {
  axiosLogger(request);

  fixPostRequest(request);
  passToken(request);

  return request;
}
