import { useState } from 'react';
import { AppText } from '../../../../../../ui/text/AppText';
import { AppModal } from '../../../../../../ui/AppModal';
import { SearchIcon } from '../../../../../../ui/icons/SearchIcon';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppImage } from '../../../../../../ui/AppImage';
import './styles.scss';

export function PreviewCell({ previewImage = '', image = '' }) {
  const { caption, imageAlt } = useLocalization().translations.unique.components.preview;
  const [isModalOpen, setModalOpen] = useState(false);

  const closePreview = () => setModalOpen(false);
  return (
    <div className='preview_cell'>
      <AppImage className='preview_cell_image' src={previewImage} alt={imageAlt} />
      <button className='preview_cell_hover' type='button' onClick={() => setModalOpen(true)}>
        <SearchIcon styles='preview_cell_hover_icon' />
        <AppText className='white_color' textValue={caption} />
      </button>
      <AppModal width={1344} open={isModalOpen} cancelButtonProps={{ onClick: closePreview }} footer={null}>
        <AppImage className='preview_modal_image' src={image} alt={imageAlt} />
      </AppModal>
    </div>
  );
}
