import { AppBox } from '../../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { FormCardButton } from '../../../../../../features/FormCardButton';
import { FormCard } from '../../../../../../features/FormCard';
import { ColorPickerFormItem } from './ColorPickerFormItem';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { ColorVariants } from '../../../../../../../../lib/models/DefaultStylesFontsAndColors';

export function ColorsCard({ themeConfiguration, setThemeConfiguration, defaultColors, disabled = false }) {
  const { translations } = useLocalization();
  const { theme } = translations.unique.pages;
  const { colorVariants } = translations.shared;
  const { colors } = themeConfiguration;

  const onChangeField = (fieldName, value) => {
    setThemeConfiguration({ ...themeConfiguration, colors: { ...colors, [fieldName]: value } });
  };
  const resetFields = () => {
    setThemeConfiguration({ ...themeConfiguration, colors: defaultColors });
  };

  const colorPickers = [
    { label: colorVariants.primary(), fieldName: ColorVariants.PRIMARY_COLOR, color: colors.primaryColor },
    { label: colorVariants.secondary1(), fieldName: ColorVariants.SECONDARY_FIRST, color: colors.secondaryFirst },
    { label: colorVariants.secondary2(), fieldName: ColorVariants.SECONDARY_SECOND, color: colors.secondarySecond },
    { label: colorVariants.background(), fieldName: ColorVariants.BACKGROUND_COLOR, color: colors.backgroundColor },
  ];

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className={`bold ${disabled ? 'primary_text_color_disabled' : ''}`} textValue={theme.colors} />
        {!disabled && <FormCardButton onClick={resetFields} />}
      </AppBox>
      <AppBox style={{ display: 'flex' }}>
        {colorPickers.map(({ label, fieldName, color }) => (
          <ColorPickerFormItem key={fieldName} label={label} fieldName={fieldName} color={color} setColor={onChangeField} disabled={disabled} />
        ))}
      </AppBox>
    </FormCard>
  );
}
