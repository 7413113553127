import styled from 'styled-components';

import { AppSwitch } from 'src/ui/AppSwitch';
import { AppInput } from 'src/ui/AppInput';

import { useLocalization } from 'src/lib/hooks/useLocalization';
import { useUpdateStructure } from 'src/processes/CMS/processes/management/pages/QuestionnairePage/hooks/useUpdateStructure';

export function CustomSign({ value, structureItemIndex, productIndex }) {
  const { customSign } = useLocalization().translations.unique.pages.questionnaire.content;

  const { updateStructure } = useUpdateStructure();

  const handleChangeCustomSign = signValue => {
    updateStructure(draft => {
      const { products } = draft[structureItemIndex].content;

      products[productIndex] = {
        ...products[productIndex],
        productNameSection: {
          ...products[productIndex].productNameSection,
          customSign: signValue,
        },
      };
    });
  };

  return (
    <Root>
      <AppSwitch
        switchProps={{
          onChange: isEnabled => {
            handleChangeCustomSign({
              isEnabled,
              sign: value?.sign,
            });
          },
          checked: value?.isEnabled || false,
        }}
        labelProps={{ textValue: customSign }}
      />
      {value?.isEnabled && (
        <InputContainer style={{ flex: 1 }}>
          <AppInput
            value={value?.sign || ''}
            style={{ flex: 1 }}
            onChange={event => {
              handleChangeCustomSign({
                isEnabled: value?.isEnabled,
                sign: event.target.value,
              });
            }}
          />
        </InputContainer>
      )}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  marginBottom: '28px',
});

const InputContainer = styled('div')({
  flex: 1,
});
