/* eslint-disable max-lines */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppParagraph } from '../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { ElementBox } from '../../components/ElementBox';
import { ElementFlow } from './ElementFlow';
import { QuestionAnswersFlow } from './QuestionAnswersFlow';
import { QuestionCounterAnswersFlow } from './QuestionCounterAnswersFlow';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { getDefaultFlowObject } from '../../../../../../utils/dnd-helpers';
import { isLength } from '../../../../../../../../../../lib/util/helpers';
import { ResultFlow } from './ResultFlow';
import { QuestionnaireTextButton } from '../../../../features/QuestionnaireTextButton';
import { ResultDepotFlow } from './ResultDepotFlow';
import { ResultFlowLabel } from './ResultFlowLabel';
import { CompletedChaptersFlow } from './CompletedChaptersFlow';
import { ChapterFlow } from './ChapterFlow';
import { filterElements, checkElement, getElementObject } from '../../utils/helpers';
import '../../style.scss';

const clearFlow = { targetBlank: true, href: null, goTo: null, operation: null, showElements: [] };

/**
 * @desc Gets the default flow object for a button.
 * @param {Object|null} buttonObject - The button object.
 * @param {string} objectName - The name of the button object.
 * @returns {Object} The default flow object for the button.
 */
const getDefaultButtonFlow = (buttonObject, objectName) => {
  return buttonObject ? { [objectName]: { ...buttonObject, flow: clearFlow } } : {};
};

/**
 * @desc Gets the default flow objects for an array of buttons.
 * @param {Array|null} buttonsArr - The array of button objects.
 * @param {string} objectName - The name of the button objects.
 * @returns {Object} The default flow objects for the buttons.
 */
const getDefaultButtonsFlow = (buttonsArr, objectName) => {
  return Array.isArray(buttonsArr) ? { [objectName]: buttonsArr.map(butObj => ({ ...butObj, flow: clearFlow })) } : {};
};

/**
 * @desc Gets the default flow objects for an array of products with buttons.
 * @param {Array|null} productsArr - The array of product objects.
 * @param {string} objectName - The name of the product objects.
 * @returns {Object} The default flow objects for the products' buttons.
 */
const getDefaultButtonsFlowResultDepotProducts = (productsArr, objectName) => {
  return Array.isArray(productsArr)
    ? {
        [objectName]: productsArr.map(product => {
          return { ...product, buttonsSection: { buttons: product.buttonsSection.buttons.map(button => ({ ...button, flow: clearFlow })) } };
        }),
      }
    : {};
};

/**
 * @desc Represents the process for managing flow elements in the questionnaire structure.
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function FlowProcess() {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();
  const [selectedElement, selectElement] = useState({
    elementId: isLength(filterElements(questionnaireStructure)) ? filterElements(questionnaireStructure)[0].id : null,
    templateType: isLength(filterElements(questionnaireStructure)) ? filterElements(questionnaireStructure)[0].templateType : null,
  });
  const { clearAll } = useLocalization().translations.shared.generic;
  const { noSelectElement } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  const elementObject = getElementObject(questionnaireStructure, selectedElement.elementId);

  const clearAllFlow = () => {
    if (checkElement(questionnaireStructure, selectedElement.elementId)) {
      const { content } = elementObject;
      const { primaryButton, secondaryButton, chapterButton, composerButton, buttons, products, completedChaptersButtons } = content;
      const flowObj = getDefaultFlowObject(elementObject);
      const result = questionnaireStructure.map(el => {
        return el.id === selectedElement.elementId
          ? {
              ...el,
              flow: flowObj,
              content: {
                ...el.content,
                ...getDefaultButtonFlow(primaryButton, 'primaryButton'),
                ...getDefaultButtonFlow(secondaryButton, 'secondaryButton'),
                ...getDefaultButtonFlow(chapterButton, 'chapterButton'),
                ...getDefaultButtonFlow(composerButton, 'composerButton'),
                ...getDefaultButtonsFlow(buttons, 'buttons'),
                ...getDefaultButtonsFlow(completedChaptersButtons, 'completedChaptersButtons'),
                ...getDefaultButtonsFlowResultDepotProducts(products, 'products'),
              },
            }
          : el;
      });
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  const handleChangeFieldValue = parentFieldName => childFieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedElement.elementId ? { ...el, [parentFieldName]: { ...el[parentFieldName], [childFieldName]: value } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const handleChangeFlowFieldsValue = updatedFlowFields => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedElement.elementId ? { ...el, flow: { ...el.flow, ...updatedFlowFields } } : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const isCompletedChapters = elementObject?.content?.completedChaptersButtons;

  return (
    <div className='flow_process'>
      <div className='flow_drops_wrap'>
        {filterElements(questionnaireStructure).map(element => (
          <ElementBox
            key={element.id}
            element={element}
            elements={filterElements(questionnaireStructure)}
            selectedElement={selectedElement}
            selectElement={selectElement}
          />
        ))}
      </div>
      <div className='flow_selects_wrap'>
        {checkElement(questionnaireStructure, selectedElement.elementId) ? (
          <>
            <div className='flow_selects_clear_btn'>
              <QuestionnaireTextButton onClick={clearAllFlow} style={{ display: 'inline-flex' }} textValue={clearAll} />
            </div>
            <div className='flow_selects_logic_wrap'>
              {selectedElement.templateType === TemplateTypes.CHAPTER &&
                (isCompletedChapters ? (
                  <CompletedChaptersFlow structureItem={elementObject} disabled={isViewMode} questionnaireStructure={questionnaireStructure} />
                ) : (
                  <ChapterFlow
                    element={elementObject}
                    elements={questionnaireStructure}
                    handleChangeFlowFieldValue={handleChangeFieldValue('flow')}
                    handleChangeFlowFieldsValue={handleChangeFlowFieldsValue}
                    disabled={isViewMode}
                  />
                ))}
              {[TemplateTypes.CONTENT, TemplateTypes.INTRO, TemplateTypes.PRODUCT, TemplateTypes.CONTACT_FORM].includes(
                selectedElement.templateType
              ) && (
                <ElementFlow
                  element={elementObject}
                  elements={questionnaireStructure}
                  handleChangeFlowFieldsValue={handleChangeFlowFieldsValue}
                  disabled={isViewMode}
                />
              )}
              {selectedElement.templateType === TemplateTypes.RESULT && (
                <>
                  <ResultFlowLabel structureItem={elementObject} elements={questionnaireStructure} />
                  <ResultDepotFlow structureItem={elementObject} disabled={isViewMode} />
                  <ResultFlow structureItem={elementObject} elements={questionnaireStructure} disabled={isViewMode} />
                </>
              )}
              {selectedElement.templateType === TemplateTypes.QUESTION && elementObject.content.answers && (
                <QuestionAnswersFlow
                  element={elementObject}
                  elements={questionnaireStructure}
                  handleChangeFlowFieldValue={handleChangeFieldValue('flow')}
                  handleChangeFlowFieldsValue={handleChangeFlowFieldsValue}
                  disabled={isViewMode}
                />
              )}
              {selectedElement.templateType === TemplateTypes.QUESTION &&
                elementObject.content.counterAnswers &&
                elementObject.flow.counterAnswersElseGoTo && (
                  <QuestionCounterAnswersFlow
                    element={elementObject}
                    elements={questionnaireStructure}
                    handleChangeFlowFieldValue={handleChangeFieldValue('flow')}
                    handleChangeFlowFieldsValue={handleChangeFlowFieldsValue}
                    disabled={isViewMode}
                  />
                )}
              {selectedElement.templateType === TemplateTypes.QUESTION &&
                (elementObject.content.chartAnswers || (elementObject.content.counterAnswers && elementObject.flow.goTo)) && (
                  <ElementFlow
                    element={elementObject}
                    elements={questionnaireStructure}
                    handleChangeFlowFieldsValue={handleChangeFlowFieldsValue}
                    disabled={isViewMode}
                  />
                )}
            </div>
          </>
        ) : (
          <AppParagraph className='flow_selects_no_select' textValue={noSelectElement} />
        )}
      </div>
    </div>
  );
}
