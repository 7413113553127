import styled from 'styled-components';
import { ButtonOrLinkComponent } from '../../ButtonOrLinkComponent';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../../lib/util/Adaptive';

export function DepotProductButtons({ buttons, colors, setPagesFlow, pagesFlow, fonts, setPageHistory, currentElements, disabled }) {
  const { windowWidth } = useWindowResize();

  return (
    <StyledButtons>
      {buttons.map((button, index) => {
        const LAST_INDEX = buttons.length - 1;
        const isLastIndex = LAST_INDEX === index;
        return (
          <ButtonOrLinkComponent
            key={button.id}
            contentObject={{ flow: button.flow, value: button.buttonText.value, id: button.id }}
            style={{
              backgroundColor: colors.primaryColor,
              height: 43,
              minWidth: 180,
              borderRadius: 24,
              padding: '5px 8px',
              color: '#FFFFFF',
              marginBottom: windowWidth > AppBreakpoint.M || isLastIndex ? '0px' : '16px',
            }}
            setPagesFlow={setPagesFlow}
            pagesFlow={pagesFlow}
            textStyle={{
              fontSize: fonts?.body.fontSize,
              fontFamily: fonts?.body.font,
              fontWeight: fonts?.body.fontWeight,
            }}
            setPageHistory={setPageHistory}
            currentElements={currentElements}
            disabled={disabled}
          />
        );
      })}
    </StyledButtons>
  );
}

const StyledButtons = styled('div')({
  marginTop: 16,
  display: 'grid',
  gridTemplateColumns: 'repeat( auto-fill, minmax(178px, 1fr) )',
  rowGap: 5,
  columnGap: 16,
});
