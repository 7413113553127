import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShow: false,
  error: null,
  success: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showSuccessNotification: (state, action) => {
      state.isShow = true;
      state.success = action.payload;
    },
    showErrorNotification: (state, action) => {
      state.isShow = true;
      state.error = action.payload;
    },
    hideNotification: state => {
      state.isShow = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const { showSuccessNotification, showErrorNotification, hideNotification } = notificationsSlice.actions;
export const notifications = notificationsSlice.reducer;
