import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';
import { isLength } from '../../../../../../lib/util/helpers';

export function ProductSelectFormItem({ products, value, onChange }) {
  const { label } = useLocalization().translations.unique.formItems.templateSelectProduct;
  const productOptions = isLength(products)
    ? products.map(product => ({
        value: product.id,
        title: product.name,
        price: product.pricePerMonth ? Number(product.pricePerMonth) : 0,
        shortName: product.shortName,
      }))
    : [];
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <AppFormSection>
      <AppNewSelect isDisabled={isViewMode} value={{ value }} label={label} onChange={selected => onChange(selected)} options={productOptions} />
    </AppFormSection>
  );
}
