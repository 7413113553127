import { all } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import { watchUserAuth } from './authSaga';
import { watchUsersManagement } from './usersSaga';
import { watchTenantsManagement } from './tenantsSaga';
import { watchTemplatesManagement } from './templatesSaga';
import { watchThemesManagement } from './themesSaga';
import { watchFindersManagement } from './findersSaga';
import { watchProductsManagement } from './productsSaga';
import { watchCategoriesManagement } from './categoriesSaga';
import { watchRulesManagement } from './rulesSaga';
import { watchVariablesManagement } from './variablesSaga';
import { watchErrorHanding } from './notificationsSaga';
import { watchAndLog } from './loggerSaga';
import { watchLayoutsManagement } from './layoutsSaga';
import { watchEndUserFinderManagement } from './endUserFinderSaga';

export function* rootSaga() {
  yield all([
    watchUserAuth(),
    watchUsersManagement(),
    watchTenantsManagement(),
    watchTemplatesManagement(),
    watchLayoutsManagement(),
    watchThemesManagement(),
    watchFindersManagement(),
    watchProductsManagement(),
    watchCategoriesManagement(),
    watchRulesManagement(),
    watchVariablesManagement(),
    watchAndLog(),
    watchErrorHanding(),
    watchEndUserFinderManagement(),
  ]);
}
