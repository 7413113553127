import { TextFieldWithInfoIcon } from '../../../../../../../features/TextFieldWithInfoIcon';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';
import { DeleteIcon } from '../../../../../../../../../../../../../ui/icons/DeleteIcon';
import { QuestionSelect } from './QuestionSelect';
import { AnswerSelect } from './AnswerSelect';
import { QuestionnaireBasicButton } from '../../../../../../../features/QuestionnaireBasicButton';

export function AdditionalInformationList({ additionalInformation, structureItemIndex, onChange }) {
  const { fieldText } = useLocalization().translations.unique.pages.result.productsDepot.additionalInformation;

  const handleDelete = elementId => {
    const result = additionalInformation.filter(el => el.id !== elementId);
    onChange(result);
  };

  return (
    <Root>
      {additionalInformation?.map((el, index) => {
        return (
          <StyledItem key={el.id}>
            <StyledMain>
              <StyledMainLeft>
                <TextFieldWithInfoIcon
                  label={fieldText.label}
                  data={el.fieldText}
                  dataPath={`[${structureItemIndex}].content.additionalInformation[${index}].fieldText`}
                />
              </StyledMainLeft>
              <StyledMainRight>
                <QuestionSelect question={el.question} elementId={el.id} additionalInformation={additionalInformation} onChange={onChange} />
                {!el.content?.answers && (
                  <AnswerSelect
                    answer={el.answer}
                    question={el.question}
                    elementId={el.id}
                    additionalInformation={additionalInformation}
                    onChange={onChange}
                  />
                )}
              </StyledMainRight>
            </StyledMain>
            <QuestionnaireBasicButton onClick={() => handleDelete(el.id)}>
              <DeleteIcon />
            </QuestionnaireBasicButton>
          </StyledItem>
        );
      })}
    </Root>
  );
}

const Root = styled('div')({
  marginTop: 24,
});

const StyledItem = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
});

const StyledMain = styled('div')({
  display: 'flex',
  gap: 24,
  width: '95%',
  marginRight: 15,
});

const StyledMainLeft = styled('div')({
  width: '45%',
});

const StyledMainRight = styled('div')({
  width: '50%',
});
