import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../ui/text/AppText';
import { updateQuestionnaireStructure } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';

import styles from './Chapter.module.scss';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../../../../../../../../../ui/icons/ChevronDownIcon';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { toggleChapterElements } from '../../../../utils/helpers';
import { CompletedChapters } from './CompletedChapters';
import { StandardChapter } from './StandardChapter';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { ActionsWrapper } from '../ActionsWrapper';

const TWO = 2;
const PANEL_KEY = '1';

const abbreviationStyle = { fontWeight: 500, color: '#fff', fontSize: 12, lineHeight: '14px' };
const abbreviationTextStyle = { fontWeight: 700, color: '#fff', fontSize: 14 };

/**
 * @desc Chapter - composer element
 * @param {Object} item
 * @param {Object} providedItem
 * @param {Number} itemIndex
 * @param {Object} firstChapter
 * @returns {JSX.Element}
 */

export function Chapter({ item, providedItem, itemIndex, firstChapter }) {
  const dispatch = useDispatch();

  const { title: buttonsTitle } = useLocalization().translations.unique.pages.result.productsDepot.buttons;

  const { questionnaireStructure, selectedQuestionnaireElement } = useSelector(({ finders }) => finders);

  const getAbbreviation = elementCategory => (elementCategory === TemplateTypes.CHAPTER ? String(elementCategory.slice(0, TWO)) : elementCategory[0]);

  const onCollapse = keys => {
    const isOpen = keys?.includes(PANEL_KEY);
    const result = toggleChapterElements(questionnaireStructure, item.id, isOpen);

    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const isCompletedChapters = item.content?.completedChaptersButtons;

  return (
    <Root>
      <div {...providedItem.dragHandleProps} className={styles.handle}>
        <div className={styles.handle_type}>
          <AppText textValue={getAbbreviation(item.templateType)} style={abbreviationStyle} />
        </div>
        <AppText textValue={`${item.label} ${item.templateType} - ${getAbbreviation(item.templateType)}.`} style={abbreviationTextStyle} />
        <AppText textValue={String(item.templateTypeIndex)} style={abbreviationTextStyle} />
      </div>
      <StyledCollapse
        defaultActiveKey={PANEL_KEY}
        expandIcon={({ isActive }) => (isActive ? <ChevronUpIcon fill='#5C5C6E' /> : <ChevronDownIcon fill='#5C5C6E' />)}
        expandIconPosition='end'
        onChange={onCollapse}
        style={{
          boxShadow:
            item.id === selectedQuestionnaireElement?.id
              ? '2px 2px #0092A3, -2px -2px #0092A3, -2px 2px #0092A3, 2px -2px #0092A3'
              : '0 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Collapse.Panel header={isCompletedChapters ? buttonsTitle() : item.label} key={PANEL_KEY}>
          <div className={styles.wrapper}>
            {isCompletedChapters ? (
              <CompletedChapters buttons={item.content.completedChaptersButtons} itemIndex={itemIndex} />
            ) : (
              <StandardChapter item={item} itemIndex={itemIndex} firstChapter={firstChapter} />
            )}
          </div>
        </Collapse.Panel>
      </StyledCollapse>
      <div className='actions_wrapper_hide actions_wrapper_show'>
        <ActionsWrapper item={item} itemIndex={itemIndex} />
      </div>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  .actions_wrapper_hide {
    display: none;
  }
  &:hover .actions_wrapper_show {
    display: block;
  }
`;

const StyledCollapse = styled(Collapse)({
  borderTopLeftRadius: '0px',

  '.ant-collapse-header ': {
    padding: '20px 24px !important',
  },
  '.ant-collapse-header-text': {
    fontWeight: 'bold',
  },
});
