import { useLocalization } from 'src/lib/hooks/useLocalization';
import { Icon } from 'src/features/Icon';
import styles from './ImageMultiSelect.module.scss';

export function SliderControls({ currentSlide, maxSlides, onSlideArrowClick }) {
  const { selectImage } = useLocalization().translations.unique.formItems;

  return (
    <>
      <div className={styles.controls}>
        <button className={styles.control} onClick={event => onSlideArrowClick(event, 'prev')} type='button'>
          <Icon icon='arrowLeft' />
        </button>
        <div className={`${styles.control} ${styles.middle}`}>{`${currentSlide} / ${maxSlides}`}</div>
        <button className={styles.control} onClick={event => onSlideArrowClick(event, 'next')} type='button'>
          <Icon icon='arrowRight' />
        </button>
      </div>
      <div className={styles.active}>{selectImage.active()}</div>
    </>
  );
}
