export function DndInfoIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M8.25533 14.0036L8.25533 7.00356L9.66726 7.00356L9.74183 14.0036H8.25533ZM9.00604 5.18892C8.74751 5.18892 8.52545 5.10275 8.33984 4.9304C8.15755 4.75473 8.06641 4.54593 8.06641 4.30398C8.06641 4.05871 8.15755 3.84991 8.33984 3.67756C8.52545 3.50189 8.74751 3.41406 9.00604 3.41406C9.26456 3.41406 9.48497 3.50189 9.66726 3.67756C9.85286 3.84991 9.94567 4.05871 9.94567 4.30398C9.94567 4.54593 9.85286 4.75473 9.66726 4.9304C9.48497 5.10275 9.26456 5.18892 9.00604 5.18892Z'
        fill='#5C5C6E'
      />
      <rect x='0.5' y='0.5' width='17' height='17' rx='5.5' stroke='#5C5C6E' />
    </svg>
  );
}
