import { FormItemName } from '../../../../../../lib/models/Form';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppSegmented } from '../../../../../../ui/AppSegmented';
import { handleTranslation } from '../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { Form } from 'antd';

const MailServer = {
  DEFAULT: true,
  CUSTOM: false,
};

export function MailServerFormSection() {
  const { general } = useLocalization().translations.unique.pages.settings;
  const form = Form.useFormInstance();

  const onChange = value => {
    form.setFieldValue(FormItemName.MAIL_SERVER, value);
    if (value) {
      form.setFieldValue(FormItemName.MAIL_SERVER_SENDER, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_HOST, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_PORT, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_USER_NAME, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_PASSWORD, '');
    }
  };

  const options = [
    { value: MailServer.DEFAULT, label: general.useDefault },
    { value: MailServer.CUSTOM, label: general.customMailServer },
  ].map(item => ({ ...item, label: handleTranslation(item.label) }));

  return (
    <AppFormSection formItemProps={{ name: FormItemName.MAIL_SERVER, style: { minHeight: '46px' } }}>
      <AppSegmented options={options} onChange={onChange} />
    </AppFormSection>
  );
}
