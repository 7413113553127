import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { CardSectionHeader } from '../../../../../../../ui/CardSectionHeader';
import { CardSection } from '../../../../../../../ui/CardSection';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductBulletPoints } from './ProductBulletPoints';
import { ProductBulletPointsSubHeaderRow } from './ProductBulletPointsSubHeaderRow';
import { isLength } from '../../../../../../../../../../../../../lib/util/helpers';
import { ProductAddBulletPointButton } from './ProductAddBulletPointButton';

export function ProductBulletPointsForProductSection({ data, productIndex, structureItemIndex, bulletPointIcon, bulletPointIconColor, ...rest }) {
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].bulletPointsSection`;

  return (
    <CardSection withPaddingBottom {...rest}>
      <CardSectionHeader>
        <AppText strong textValue={contentText.bulletPointsForProduct} />
      </CardSectionHeader>

      <div>
        {isLength(data.bulletPoints) && (
          <>
            <ProductBulletPointsSubHeaderRow dataPath={dataPath} value={data.rightColumnType} structureItemIndex={structureItemIndex} />

            <ProductBulletPoints
              dataPath={`${dataPath}.bulletPoints`}
              bulletPointIcon={bulletPointIcon}
              bulletPointIconColor={bulletPointIconColor}
              bulletPointsSection={data}
              productIndex={productIndex}
              structureItemIndex={structureItemIndex}
            />
          </>
        )}

        <ProductAddBulletPointButton
          data={data}
          dataPath={`${dataPath}.bulletPoints`}
          productIndex={productIndex}
          structureItemIndex={structureItemIndex}
        />
      </div>
    </CardSection>
  );
}
