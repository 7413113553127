import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';

import { useRuleArrangeRequests } from '../../lib/useRuleArrangeRequests';
import { Rule } from '../../lib/Rule';
import {
  clearListFilters,
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../utils/arranges-helpers';
import { useStatusColumn } from '../../../../../components/columns/status';
import { useEnumArrange } from '../../../../../utils/hooks/arranges-by-type/useEnumArrange';
import { handleTranslation } from '../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { RuleStatusBodyCell } from './RuleStatusBodyCell';

const arrangedId = Rule.ACTIVATED;

const pseudoAllFilters = {};

export function useRuleStatusColumn() {
  const { status } = useLocalization().translations.shared;
  const { activeArrangedId, sorting } = useSelector(({ rules }) => rules);
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const [form] = Form.useForm();
  const isActiveArrange = activeArrangedId === arrangedId;
  const getItemsValues = () => getSelectedItemsValues(form);
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = handleTranslation(status.activated);
  const deactivatedLabel = handleTranslation(status.deactivated);

  const allOptions = [createOptionItem(activatedLabel, true), createOptionItem(deactivatedLabel, false)];

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const areAllFiltersSelected = allOptions.length === itemsValues.length;
    return areAllFiltersSelected ? pseudoAllFilters : { activated: itemsValues?.[0] };
  };

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useRuleArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearListFilters(form),
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      render: (status, row) => (
        <RuleStatusBodyCell
          status={status}
          usedInElements={row.usedInElements}
          elementsUsage={row.elementsUsage}
          questionnaireStructure={questionnaireStructure}
        />
      ),
    },
  });
}
