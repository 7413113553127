import { useProductSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';

export function useProductNameColumn() {
  const { name: title } = useLocalization().translations.shared.columns;

  return useProductSearchDataColumn({
    title,
    arrangedId: Product.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnsProps: {
      width: 200,
    },
  });
}
