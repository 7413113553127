import { hex2rgba } from '../../utils/helpers';

export function Header({ productRow, productOptions, color }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '-65px',
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        zIndex: -1,
        fontWeight: '600',
        gap: '8px',
        color,
      }}
    >
      <div style={{ flex: 4 }} />
      <div
        style={{
          flex: 3,
          backgroundColor: hex2rgba(productOptions?.productOne?.color, productOptions?.productOne?.transparency),
          padding: '24px 0',
          borderRadius: '8px',
        }}
      >
        {productRow.value?.productOne?.title}
      </div>
      <div
        style={{
          flex: 3,
          backgroundColor: hex2rgba(productOptions?.productTwo?.color, productOptions?.productTwo?.transparency),
          padding: '24px 0',
          borderRadius: '8px',
        }}
      >
        {productRow.value?.productTwo?.title}
      </div>
    </div>
  );
}
