/* eslint-disable max-lines-per-function,max-lines,github/no-dynamic-script-tag,no-underscore-dangle,no-multi-assign,id-length */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DefaultColors, DefaultFontStyles } from '../../CMS/lib/models/DefaultStylesFontsAndColors';
import { loadFonts } from '../../CMS/processes/management/pages/ThemePage/lib/util/loadFonts';
import { createChapterPage, getChapters, getPages } from '../../CMS/processes/management/utils/end-user-flow-helpers';
import { isLength } from '../../../lib/util/helpers';
import { AppPreloader } from '../../../ui/AppPreloader';
import { NotFound } from '../../CMS/processes/management/pages/NotFoundPage/components/NotFound';
import styles from '../../CMS/processes/management/pages/PreviewPage/styles.module.scss';
import { PreviewAudioGuideModal } from '../../CMS/processes/management/pages/PreviewPage/components/PreviewModals/PreviewAudioGuideModal';
import { PreviewHeader } from '../../CMS/processes/management/pages/PreviewPage/components/PreviewHeader';
import { PreviewLayoutTemplates } from '../../CMS/processes/management/pages/PreviewPage/components/PreviewLayoutTemplates';
import { PreviewAudioPlayer } from '../../CMS/processes/management/pages/PreviewPage/components/PreviewAudioPlayer';
import { PreviewFooter } from '../../CMS/processes/management/pages/PreviewPage/components/PreviewFooter';
import { cleanEndUserFinder, getEndUserFinderRequest } from '../../../lib/store/slices/endUserFinderSlice';
import styled from 'styled-components';
import { publicAxiosInstance } from '../../../lib/util/http/axios/axios-instance';
import { appLogger } from '../../../lib/util/logger';
import { useSearchParams } from 'react-router-dom';
import { useGetUrlParamsForMatomo } from '../../../lib/hooks/useGetUrlParamsForMatomo';
import { NewProgressBar } from '../../CMS/processes/management/pages/PreviewPage/components/NewProgressBar';
import { setAllElementsButtonsIds } from '../../CMS/processes/management/pages/PreviewPage/hooks/setNextFlow';

const ZERO = 0;

async function setTenant(setPagesFlow, linkOrigin) {
  const { data } = await publicAxiosInstance.get('global/public/get-bank-info', {
    params: {
      url: encodeURIComponent(linkOrigin),
    },
  });

  setPagesFlow(prevState => ({
    ...prevState,
    tenant: data,
  }));
}

export function EndUserFinder() {
  const dispatch = useDispatch();

  const url = window.location.href;
  const urlEncoded = encodeURIComponent(url);

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const { endUserFinder, endUserFinderError, endUserFinderLoading } = useSelector(state => state.endUserFinder);

  const isTrackingEnabled = endUserFinder.generalSettings?.tracking?.usedTracking;
  const trackerUrl = endUserFinder.generalSettings?.tracking?.trackerUrl;

  useEffect(() => {
    dispatch(getEndUserFinderRequest({ params: { url: urlEncoded } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pagesFlow, setPagesFlow] = useState({
    allChapters: [],
    allPages: [],
    currentChapterId: null,
    currentPageId: null,
    currentFlowElement: null,
    currentElementsIds: [],
    prevPageIds: [],
    completed: false,
    answersScoring: {},
    progressPercentage: 0,
    // finder: null,
    // tenant: null,
  });

  const [audioGuideModal, setAudioGuideModal] = useState(true);
  const [isAudioGuidePlaying, setIsAudioGuidePlaying] = useState(false);
  const [showChapterIds, setShowChapterIds] = useState([]);

  const audioGuideRef = useRef(null);

  const stylesConfiguration =
    endUserFinder.brand?.id && endUserFinder.brand.activated
      ? JSON.parse(endUserFinder.brand.configuration)
      : { fonts: DefaultFontStyles, colors: DefaultColors };
  const { fontsForLoading, fonts, colors } = stylesConfiguration;

  const onCloseAudioGuide = useCallback(() => {
    setAudioGuideModal(false);
  }, []);

  const playAudio = () => {
    setIsAudioGuidePlaying(true);
    audioGuideRef.current.play();
  };

  const pauseAudio = () => {
    setIsAudioGuidePlaying(false);
    audioGuideRef.current.pause();
    audioGuideRef.current.currentTime = ZERO;
  };

  useEffect(() => {
    if (fontsForLoading) {
      loadFonts(fontsForLoading);
    }
  }, [fontsForLoading]);

  useEffect(() => {
    if (endUserFinderError) {
      dispatch(cleanEndUserFinder());
    }
  }, [dispatch, endUserFinderError]);

  useEffect(() => {
    if (endUserFinder?.questionnaireDraft) {
      // split by pages
      const pages = getPages(endUserFinder?.questionnaireDraft, endUserFinder?.rules);
      // find all chapters and join them
      const chapters = getChapters(endUserFinder?.questionnaireDraft, pages, endUserFinder?.rules);
      // create special page for all chapters and update answers
      const updatedPages = createChapterPage(pages, chapters);
      // create list of all shown buttons
      const allShownButtonsIds = setAllElementsButtonsIds(updatedPages.flatMap(page => page.elements));

      setPagesFlow({
        allChapters: chapters,
        allPages: updatedPages,
        currentChapterId: chapters[0]?.id,
        currentPageId: updatedPages[0].id,
        currentFlowElement: isLength(updatedPages[0]?.elements) ? updatedPages[0]?.elements[0] : null,
        currentElementsIds: isLength(updatedPages[0]?.elements) ? [updatedPages[0]?.elements[0]?.id] : [],
        allShownButtonsIds,
        prevPageIds: [],
        completed: false,
        answersScoring: {},
        progressPercentage: 0,
        rulesProductsProperties: [],
        rulesAnswersScore: [],
        rulesAnswersSavings: [],
        hideProducts: [],
        clickedButtonsIds: [],
      });
      setShowChapterIds(chapters?.filter(item => !item.content.completedChaptersButtons).map(chap => chap.id));
      setLoading(false);
    }
  }, [endUserFinder]);

  // Set finder
  useEffect(() => {
    if (endUserFinder?.name && !pagesFlow?.finder) {
      setPagesFlow(prevState => ({ ...prevState, finder: endUserFinder }));
    }
  }, [endUserFinder, pagesFlow]);

  // Set tenant
  useEffect(() => {
    if (window?.location?.origin && !pagesFlow?.tenant) {
      setTenant(setPagesFlow, window.location.origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesFlow, window?.location?.origin]);

  // ========= Matomo analytics start ==============
  const { currentPageIndex, currentTemplates } = useGetUrlParamsForMatomo(pagesFlow);

  useEffect(() => {
    if (currentTemplates.length > 0) {
      setSearchParams({
        ...searchParams,
        page: currentPageIndex,
        templates: currentTemplates,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, currentTemplates.length]);

  // Track page view
  useLayoutEffect(() => {
    if (isTrackingEnabled && trackerUrl) {
      const _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ 'mtm.startTime': Date.now(), event: 'mtm.Start' });
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = trackerUrl;
      s.parentNode.insertBefore(g, s);
    }
  }, [trackerUrl, isTrackingEnabled]);
  // ========= Matomo analytics end ==============

  if ((endUserFinderLoading || loading) && !endUserFinderError) {
    return <AppPreloader style={{ height: '100vh' }} />;
  }

  if (Object.keys(endUserFinder).length === 0 || endUserFinderError) {
    return (
      <StyledNotFoundWrapper>
        <NotFound />
      </StyledNotFoundWrapper>
    );
  }

  const {
    header,
    footer,
    media,
    bulletIcons,
    header: {
      subLine: audioGuideSubLine,
      headline: audiGuideHeadline,
      primaryButton: audiGuidePrimaryButton,
      secondaryButton: audioGuideSecondaryButton,
    },
  } = endUserFinder.finderSettings;

  appLogger.info('EndUserFinder:', { pagesFlow });

  return (
    <Root className={styles.container} style={{ backgroundColor: colors.backgroundColor }}>
      <PreviewAudioGuideModal
        subLine={audioGuideSubLine}
        headline={audiGuideHeadline}
        primaryButton={audiGuidePrimaryButton}
        secondaryButton={audioGuideSecondaryButton}
        close={onCloseAudioGuide}
        isOpen={audioGuideModal && header.isAutoGuide}
        fonts={fonts}
        colors={colors}
        setAudioGuideModal={setAudioGuideModal}
        setIsAudioGuidePlaying={setIsAudioGuidePlaying}
      />
      <PreviewHeader
        isAudioGuidePlaying={isAudioGuidePlaying}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        setAudioGuideModal={setAudioGuideModal}
        backgroundColor={colors[header.backgroundColor]}
        header={header}
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        footer={footer}
        colors={colors}
        textLinkStyles={{ ...fonts.textLink, color: colors[fonts.textLink.colorVariant] }}
      />
      <NewProgressBar pagesFlow={pagesFlow} footer={footer} colors={colors} showChapterIds={showChapterIds} fonts={fonts} />
      <PreviewLayoutTemplates
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        colors={colors}
        fonts={fonts}
        media={media}
        bulletIcons={bulletIcons}
        fontsForLoading={fontsForLoading}
        showChapterIds={showChapterIds}
        setShowChapterIds={setShowChapterIds}
      />
      <PreviewAudioPlayer
        pauseAudio={pauseAudio}
        isAudioGuidePlaying={isAudioGuidePlaying}
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        audioPlayer={audioGuideRef}
        setIsAudioGuidePlaying={setIsAudioGuidePlaying}
      />
      <PreviewFooter
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        footer={footer}
        colors={colors}
        textLinkStyles={{ ...fonts.textLink, color: colors[fonts.textLink.colorVariant] }}
      />
    </Root>
  );
}

const StyledNotFoundWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const Root = styled('div')({
  '&, div, span, p, h1, h2, h3, h4, h5, h6': {
    wordBreak: 'break-word !important',
  },
});
