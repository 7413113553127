import { AppPopup } from '../../../../../../../ui/AppPopup';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';
import './FilterValuesPopup.sass';
import { CrossIcon } from '../../../../../../../ui/icons/CrossIcon';
import { AppBasicButton } from '../../../../../../../ui/buttons/AppBasicButton';
import { LIST_LABEL_PROP_NAME, LIST_VALUE_PROP_NAME } from '../../../utils/arranges-helpers';
import { MainLabel } from '../MainLabel';

const MIN_MULTIPLE_VALUES_AMOUNT = 2;

export function FilterValuesPopup({ removeFilter, removeFilterValue, getParsedItems, filterLabel }) {
  const { clearAppliedFilters: title } = useLocalization().translations.shared.arranges.filterValues;
  const { moreValues } = useLocalization().translations.shared.arranges.labels;
  const parsedItems = getParsedItems();
  const valuesAmount = parsedItems?.length;
  const mainLabelText = valuesAmount >= MIN_MULTIPLE_VALUES_AMOUNT ? moreValues({ amount: valuesAmount }) : filterLabel;

  const content = (
    <ul>
      {parsedItems.map(item => {
        const value = item[LIST_VALUE_PROP_NAME];
        const label = item[LIST_LABEL_PROP_NAME];

        return (
          <li key={value}>
            <span>{label}</span>

            <AppBasicButton onClick={() => removeFilterValue(value)}>
              <CrossIcon />
            </AppBasicButton>
          </li>
        );
      })}
    </ul>
  );

  return (
    <AppPopup title={title} content={content} placement='rightTop' overlayClassName='FilterValuesPopup'>
      <MainLabel removeFilter={removeFilter} textValue={mainLabelText} />
    </AppPopup>
  );
}
