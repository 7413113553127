import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addProductError,
  addProductRequest,
  addProductSuccess,
  applyProductArrangesError,
  applyProductArrangesRequest,
  closeProductModal,
  deleteProductError,
  deleteProductRequest,
  deleteProductSuccess,
  editProductError,
  editProductRequest,
  editProductSuccess,
  getProductsError,
  getProductsRequest,
  getProductsSuccess,
  removeProductFiltersError,
  removeProductFiltersRequest,
} from '../slices/productsSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getProductsFromApi, withXTenantHeader } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, tenantId: uriTenantId, recursionLimit = 1 } = args;

  const {
    products: { sorting, filtering, pagination },
    finders: {
      tenant: { id: tenantId },
    },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });
  const response = yield call(getProductsFromApi, uriTenantId || tenantId, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getProductsSuccess({ products: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchProducts({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getProductsError({ error }));
  }
}

function* deleteProduct({ payload: { productId, tenantId } }) {
  try {
    yield call(axiosInstance.delete, `product/${productId}/delete`, withXTenantHeader(tenantId));
    yield put(deleteProductSuccess({ success: { messageType: deleteProductSuccess.type } }));
    yield put(closeProductModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteProductError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyProductArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeProductFiltersError({ error }));
  }
}

function* addProduct({ payload: { product, tenantId } }) {
  try {
    yield call(axiosInstance.post, 'product/', product, withXTenantHeader(tenantId));
    yield put(addProductSuccess({ success: { messageType: addProductSuccess.type } }));
    yield put(closeProductModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addProductError({ error }));
  }
}

function* editProduct({ payload: { product, tenantId } }) {
  try {
    yield call(axiosInstance.put, 'product/update', product, withXTenantHeader(tenantId));
    yield put(editProductSuccess({ success: { messageType: editProductSuccess.type } }));
    yield put(closeProductModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(editProductError({ error }));
  }
}

export function* watchProductsManagement() {
  yield all([
    takeLatest(getProductsRequest.type, fetchProducts),
    takeLatest(deleteProductRequest.type, deleteProduct),
    takeLatest(applyProductArrangesRequest.type, applyArranges),
    takeLatest(removeProductFiltersRequest.type, removeFilters),
    takeLatest(addProductRequest.type, addProduct),
    takeLatest(editProductRequest.type, editProduct),
  ]);
}
