import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  applyRuleArrangesError,
  applyRuleArrangesRequest,
  closeRuleModal,
  deleteRuleError,
  deleteRuleRequest,
  deleteRuleSuccess,
  addRuleRequest,
  addRuleSuccess,
  addRuleError,
  duplicateRuleError,
  duplicateRuleRequest,
  duplicateRuleSuccess,
  editRuleError,
  editRuleRequest,
  editRuleSuccess,
  getRulesError,
  getRulesRequest,
  getRulesSuccess,
  removeRuleFiltersError,
  removeRuleFiltersRequest,
  setRuleStatusError,
  setRuleStatusRequest,
  setRuleStatusSuccess,
} from '../slices/rulesSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getRulesApiRequest, withXTenantHeader } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    rules: { sorting, filtering, pagination },
    finders: {
      finder: { id: finderId },
      tenant: { id: tenantId },
    },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: { finderId, ...filtering } });
  const response = yield call(getRulesApiRequest, tenantId, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getRulesSuccess({ rules: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchRules({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getRulesError({ error }));
  }
}

function* addRule({ payload }) {
  try {
    const {
      finders: {
        finder: { id: finderId },
        tenant: { id: tenantId },
      },
    } = yield select();
    const res = yield call(
      axiosInstance.post,
      '/rule',
      {
        ...payload,
        conditions: JSON.stringify(payload.conditions),
        finderId,
      },
      withXTenantHeader(tenantId)
    );
    if (res?.data) {
      yield put(addRuleSuccess({ success: { messageType: addRuleSuccess.type }, selectedRule: res.data }));
      yield call(fetchRules, {});
    }
  } catch (error) {
    yield put(addRuleError({ error }));
  }
}

function* editRule({ payload }) {
  try {
    const {
      finders: {
        finder: { id: finderId },
        tenant: { id: tenantId },
      },
    } = yield select();
    yield call(
      axiosInstance.put,
      `/rule/${payload.id}`,
      {
        ...payload,
        conditions: JSON.stringify(payload.conditions),
        finderId,
      },
      withXTenantHeader(tenantId)
    );
    yield put(editRuleSuccess({ success: { messageType: editRuleSuccess.type } }));
    yield call(fetchRules, {});
  } catch (error) {
    yield put(editRuleError({ error }));
  }
}

function* deleteRule({ payload: ruleId }) {
  const {
    finders: {
      tenant: { id: tenantId },
    },
  } = yield select();
  try {
    yield call(axiosInstance.delete, `/rule/${ruleId}/delete`, withXTenantHeader(tenantId));
    yield put(deleteRuleSuccess({ success: { messageType: deleteRuleSuccess.type } }));
    yield put(closeRuleModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteRuleError({ error }));
  }
}

function* duplicateRule({ payload: { rule, tenantId } }) {
  try {
    yield call(axiosInstance.post, '/rule/duplicate', rule, withXTenantHeader(tenantId));
    yield put(duplicateRuleSuccess({ success: { messageType: duplicateRuleSuccess.type } }));
    yield put(closeRuleModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(duplicateRuleError({ error }));
  }
}

function* setRuleStatus({ payload: { ruleId, status } }) {
  const {
    finders: {
      tenant: { id: tenantId },
    },
  } = yield select();
  try {
    yield call(axiosInstance.patch, `/rule/${ruleId}/set-active?status=${status}`, {}, withXTenantHeader(tenantId));
    yield put(setRuleStatusSuccess({ success: { messageType: setRuleStatusSuccess.type } }));
    yield fork(requestAndSave);
  } catch (error) {
    yield put(setRuleStatusError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyRuleArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeRuleFiltersError({ error }));
  }
}

/**
 * @desc function generator - observer for all rules sagas functions
 */
export function* watchRulesManagement() {
  yield all([
    takeLatest(getRulesRequest.type, fetchRules),
    takeLatest(setRuleStatusRequest.type, setRuleStatus),
    takeLatest(addRuleRequest.type, addRule),
    takeLatest(deleteRuleRequest.type, deleteRule),
    takeLatest(editRuleRequest.type, editRule),
    takeLatest(duplicateRuleRequest.type, duplicateRule),
    takeLatest(applyRuleArrangesRequest.type, applyArranges),
    takeLatest(removeRuleFiltersRequest.type, removeFilters),
  ]);
}
