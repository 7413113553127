import { hex2rgba } from '../../../../utils/helpers';
import { ChapterState } from '../../../../../../../../lib/models/TemplateTypes';

export const OPACITY = 0.2;

/**
 * @desc Function that returns the background color for the circle step.
 * @param {String} chapterState - The state of the chapter.
 * @param {String} progressBarColor - The color of the progress bar.
 * @param {Number} progressPercentage - The progress percentage.
 * @param {String} oppositeColorForProgressBar - The opposite color for the progress bar.
 * @param {Array} chapterResultSelectedProducts - The selected products in the chapter.
 * @param {Number} position - The position of the chapter.
 * @param {Boolean} noProductsInChapter - Flag indicating if there are no products in the chapter.
 * @returns {String} - The background color.
 */

export const getBackgroundColor = (
  chapterState,
  progressBarColor,
  progressPercentage,
  oppositeColorForProgressBar,
  chapterResultSelectedProducts,
  position,
  noProductsInChapter
) => {
  if (
    progressPercentage >= position &&
    (chapterState === ChapterState.OPENED ||
      chapterState === ChapterState.NOT_OPENED ||
      (chapterResultSelectedProducts?.length === 0 && !noProductsInChapter))
  ) {
    return oppositeColorForProgressBar;
  }
  if (chapterState === ChapterState.OPENED) {
    return '#fff';
  }
  if (chapterState === ChapterState.COMPLETED) {
    return progressBarColor;
  }
  return '#fff';
};

/**
 * @desc Function that returns the border color for the circle step.
 * @param {String} chapterState - The state of the chapter.
 * @param {String} progressBarColor - The color of the progress bar.
 * @param {Number} progressPercentage - The progress percentage.
 * @param {String} oppositeColorForProgressBar - The opposite color for the progress bar.
 * @param {Array} chapterResultSelectedProducts - The selected products in the chapter.
 * @param {Number} position - The position of the chapter.
 * @param {Boolean} noProductsInChapter - Flag indicating if there are no products in the chapter.
 * @returns {String} - The border color.
 */
export const getBorderColor = (
  chapterState,
  progressBarColor,
  progressPercentage,
  oppositeColorForProgressBar,
  chapterResultSelectedProducts,
  position,
  noProductsInChapter
) => {
  if (
    progressPercentage >= position &&
    (chapterState === ChapterState.OPENED ||
      chapterState === ChapterState.NOT_OPENED ||
      (chapterResultSelectedProducts?.length === 0 && !noProductsInChapter))
  ) {
    return oppositeColorForProgressBar;
  }
  if (chapterState === ChapterState.OPENED || chapterState === ChapterState.COMPLETED) {
    return progressBarColor;
  }
  return hex2rgba(progressBarColor, OPACITY);
};

/**
 * @desc Function that returns the text color for the step.
 * @param {String} chapterState - The state of the chapter.
 * @param {Number} position - The position of the chapter.
 * @param {Number} progressPercentage - The progress percentage.
 * @param {String} oppositeColorForProgressBar - The opposite color for the progress bar.
 * @param {Array} chapterResultSelectedProducts - The selected products in the chapter.
 * @param {Boolean} noProductsInChapter - Flag indicating if there are no products in the chapter.
 * @returns {String} - The text color.
 */
export const getColorText = (
  chapterState,
  position,
  progressPercentage,
  oppositeColorForProgressBar,
  chapterResultSelectedProducts,
  noProductsInChapter
) => {
  if (
    progressPercentage >= position &&
    (chapterState === ChapterState.OPENED ||
      chapterState === ChapterState.NOT_OPENED ||
      (chapterResultSelectedProducts?.length === 0 && !noProductsInChapter))
  ) {
    return oppositeColorForProgressBar;
  }
  if (chapterState === ChapterState.OPENED || chapterState === ChapterState.COMPLETED) {
    return '#333333';
  }
  return '#858899';
};

/**
 * @desc Function that returns the background color for the chapter line.
 * @param {Boolean} isChapterMissed - Flag indicating if the chapter is missed.
 * @param {String} oppositeColorForProgressBar - The opposite color for the progress bar.
 * @param {Boolean} isChapterCompleted - Flag indicating if the chapter is completed.
 * @param {String} progressBarColor - The color of the progress bar.
 * @returns {String} - The background color for the chapter line.
 */
export const getBackgroundForChapterLine = ({ isChapterMissed, oppositeColorForProgressBar, isChapterCompleted, progressBarColor }) => {
  if (isChapterMissed) {
    return oppositeColorForProgressBar;
  }
  if (isChapterCompleted) {
    return progressBarColor;
  }
  return 'transparent';
};
