import styled from 'styled-components';
import { forwardRef } from 'react';
import { FormSectionTitle } from '../processes/CMS/processes/management/features/FormSectionTitle';
import { AppFormItem } from './AppFormItem';
import { AppInput } from './AppInput';

export const AppFormSection = forwardRef((props, ref) => {
  const {
    title,
    size,
    labelLeft,
    borderColor,
    className = '',
    rootProps,
    children,
    formItemProps: { hasFeedback = true, ...restFormItemProps } = {},
    textFieldProps: { style, ...restTextFieldProps } = {},
    ...restProps
  } = props;

  return (
    <Root ref={ref} $title={title} {...restProps} {...rootProps} className={`AppFormSection ${className}`}>
      {title && <FormSectionTitle textValue={title} />}

      <AppFormItem {...restFormItemProps} hasFeedback={hasFeedback} size={size}>
        {children || (
          <AppInput
            {...restTextFieldProps}
            hasFeedback={hasFeedback}
            size={size}
            labelLeft={labelLeft}
            borderColor={borderColor}
            style={{ marginBottom: 0, ...style }}
          />
        )}
      </AppFormItem>
    </Root>
  );
});

const Root = styled('div')(({ $title }) => ({
  width: '100%',
  marginBottom: 6,
  ...($title && {
    marginBottom: '24px',
  }),
}));
