import { AppText } from '../../../../../../../../../../../../../../../ui/text/AppText';
import { QuestionnaireSwitch } from '../../../../../../../../../features/QuestionnaireSwitch';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { Radio } from 'antd';
import { AppRadio } from '../../../../../../../../../../../../../../../ui/AppRadio';
import { useSelector } from 'react-redux';
import { useUpdateStructure } from '../../../../../../../../../hooks/useUpdateStructure';

const questionnaireSwitchStyle = { marginLeft: 8 };
const radioGroupStyleStyle = { marginTop: 16 };

export function ScoreBlock({ detailTable, structureItemIndex, productIndex, detailTableIndex }) {
  const { score: scoreText } = useLocalization().translations.unique.pages.result.productsDepot.detailTable;

  const { isViewMode } = useSelector(({ finders }) => finders);

  const { updateStructure } = useUpdateStructure();

  const toggleShowScore = () => {
    updateStructure(draft => {
      const { score } = draft[structureItemIndex].content.products[productIndex].detailTableSection.detailTables[detailTableIndex];
      score.showScore = !score.showScore;
    });
  };

  const handleChangeSelectedScore = ({ target: { value } }) => {
    updateStructure(draft => {
      const { score } = draft[structureItemIndex].content.products[productIndex].detailTableSection.detailTables[detailTableIndex];
      score.selectedScore = value;
    });
  };

  return (
    <Root>
      <StyledHeader>
        <AppText strong textValue={scoreText.title} />
        <QuestionnaireSwitch onChange={toggleShowScore} isOn={detailTable.score.showScore} style={questionnaireSwitchStyle} />
      </StyledHeader>
      {detailTable.score.showScore && (
        <Radio.Group value={detailTable.score.selectedScore} onChange={handleChangeSelectedScore} disabled={isViewMode} style={radioGroupStyleStyle}>
          {detailTable.score.scoreList.map(el => (
            <AppRadio key={el.id} value={el.value} textValue={el.value} />
          ))}
        </Radio.Group>
      )}
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 24px',
  borderBottom: '1px solid #ECECEE',
});
const StyledHeader = styled('div')({
  display: 'flex',
});
