/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Slider } from 'antd';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { CrossIcon } from '../../../../../../../../ui/icons/CrossIcon';
import { ArrowClockwiseIcon } from '../../../../../../../../ui/icons/ArrowClockwiseIcon';
import { FastBackwardIcon } from '../../../../../../../../ui/icons/FastBackwardIcon';
import { FastForwardIcon } from '../../../../../../../../ui/icons/FastForwardIcon';
import { PlayCircleIcon } from '../../../../../../../../ui/icons/PlayCircleIcon';
import { PauseCircleIcon } from '../../../../../../../../ui/icons/PauseCircleIcon';
import { IconButton } from '../../../../features/IconButton';
import { formatTime } from '../../../../../../../../lib/util/helpers';
import './style.scss';

const ZERO = 0;
const TIME_INTERVAL = 100;
const TIME_STEP = 10;

const formatter = value => formatTime(value);

export function AudioPlayer({ audioPlayer, audioObject, isPlaying, setIsPlaying, stopPlayingAndClose }) {
  const [duration, setDuration] = useState(ZERO);
  const [elapsed, setElapsed] = useState(ZERO);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(Math.floor(audioPlayer?.current?.duration));
      setElapsed(Math.floor(audioPlayer?.current?.currentTime));
    }, TIME_INTERVAL);

    return () => clearInterval(interval);
  }, [audioPlayer]);

  const togglePlay = () => {
    if (!isPlaying) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
    }
    setIsPlaying(prev => !prev);
  };

  const toggleForward = () => {
    audioPlayer.current.currentTime += TIME_STEP;
  };
  const toggleBackward = () => {
    audioPlayer.current.currentTime -= TIME_STEP;
  };

  const backToStart = () => {
    audioPlayer.current.currentTime = ZERO;
  };

  const changeCurrentTime = value => {
    audioPlayer.current.currentTime = value;
  };

  return (
    <div className='audio_player_wrap'>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioPlayer} src={audioObject.cdnUrl} autoPlay loop />
      <IconButton
        additionalStyles='audio_player_close_btn'
        icon={<CrossIcon styles='white_color audio_player_close_btn_icon' />}
        onClick={stopPlayingAndClose}
      />
      <div className='audio_player_time_block align_items_center space_between'>
        <AppText className='audio_player_time_text'>{formatTime(elapsed)}</AppText>
        <Slider className='audio_player_time_slider' value={elapsed} max={duration} onChange={changeCurrentTime} tooltip={{ formatter }} />
        <AppText className='audio_player_time_text'>{formatTime(duration - elapsed)}</AppText>
      </div>
      <div className='align_items_center space_between'>
        <IconButton icon={<ArrowClockwiseIcon />} onClick={backToStart} />
        <div className='audio_player_controls_block align_items_center space_between'>
          <IconButton icon={<FastBackwardIcon />} onClick={toggleBackward} />
          <IconButton
            additionalStyles='audio_player_controls_play_btn'
            icon={isPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
            onClick={togglePlay}
          />
          <IconButton icon={<FastForwardIcon />} onClick={toggleForward} />
        </div>
        <AppText style={{ width: 24 }} />
      </div>
    </div>
  );
}
