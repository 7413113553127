import { AppNewSelect } from 'src/ui/AppNewSelect';

import { useElements } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useElements';
import { useConditionResult } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useConditionResult';

import {
  resultTypeAnswerProperties,
  resultTypeNoProductAnswerProperties,
  mapSelectOptions,
} from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

import styles from '../ConditionItem.module.scss';

/**
 * @desc Answer
 * @param {Object} value
 * @param {Function} onChange
 * @returns {JSX.Element}
 */
export function Answer({ value, onChange }) {
  const { handleElementsChange, handlePropertyChange, handleComposerItemChange } = useConditionResult({
    value,
    onChange,
  });

  const { elements, property, product } = value;

  const { answerOptions, tenantProducts } = useElements();

  const isSelectedAnswerWithoutProducts = elements?.some(elementItem => elementItem.answerType === 'without product');

  const elementsWithoutTitle = (elements || []).map(elem => ({
    ...elem,
    title: '',
  }));

  const mappedAnswerOptions = mapSelectOptions(
    Object.values(isSelectedAnswerWithoutProducts ? resultTypeNoProductAnswerProperties : resultTypeAnswerProperties)
  );

  return (
    <>
      <AppNewSelect
        label='Select Answer'
        titleSearch
        className={`${styles.largeSelect} ${styles.selectItem}`}
        value={elementsWithoutTitle}
        isMultiSelect
        onChange={handleElementsChange}
        options={answerOptions}
      />
      {elements && (
        <AppNewSelect
          label='Property'
          titleSearch
          fullWidth
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={property}
          onChange={({ value }) => {
            handlePropertyChange(value);
          }}
          options={mappedAnswerOptions}
        />
      )}

      {(property === 'Score' || property === 'Savings') && (
        <AppNewSelect
          label='Select product'
          titleSearch
          fullWidth
          className={`${styles.largeSelect} ${styles.selectItem}`}
          value={product}
          onChange={handleComposerItemChange}
          options={tenantProducts}
        />
      )}
    </>
  );
}
