import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppFormSection } from '../ui/AppFormSection';
import { useLocalization } from '../lib/hooks/useLocalization';
import { getCategoriesFromApi } from '../lib/api';
import { Product } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/lib/Product';
import { AppNewSelect } from '../ui/AppNewSelect';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../processes/CMS/lib/consts';
import { useIsExceptional } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/modules/products/ProductModals/lib/useIsExceptional';
import { useSelector } from 'react-redux';

const getCategories = axiosResponse => axiosResponse.data.content;
const formatCategories = content => content.map(item => ({ value: item.id, title: item.name }));

export function ProductCategoryFormItem(props) {
  const { label } = useLocalization().translations.unique.formItems.productCategory;
  const [options, setOptions] = useState([]);
  const { formItemProps, textFieldProps } = props;
  const { id: tenantId } = useParams();
  const isExceptional = useIsExceptional();
  const { tenantId: tenantIdForUser } = useSelector(({ auth }) => auth);

  const actualTenantId = tenantId || tenantIdForUser;

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    getCategoriesFromApi(actualTenantId, { params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE } })
      .then(getCategories)
      .then(formatCategories)
      .then(setOptions);
  }, [actualTenantId]);

  return (
    <AppFormSection
      formItemProps={{
        name: Product.CATEGORY,
        rules: [{ required: !isExceptional }],
        ...formItemProps,
      }}
    >
      <AppNewSelect isDisabled={isExceptional} label={label} options={options} {...textFieldProps} />
    </AppFormSection>
  );
}
