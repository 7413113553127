import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { useDateColumn } from '../../../../components/columns/date';
import { clearDateFilters } from '../../../../utils/arranges-helpers';
import { useDateArrange } from '../../../../utils/hooks/arranges-by-type/useDateArrange';
import { useRuleArrangeRequests } from '../lib/useRuleArrangeRequests';

/**
 * @desc Custom hook for managing rule date columns.
 * @param {string} title - The title of the date column.
 * @param {string} arrangedId - The ID of the arranged item.
 * @param {string} filterFromName - The name of the filter for the "from" date.
 * @param {string} filterToName - The name of the filter for the "to" date.
 * @param {Object} headerCellProps - Additional props for the header cell.
 * @returns {Object} - An object containing properties for managing rule date columns.
 */

export function useRuleDateColumn({ title, arrangedId, filterFromName, filterToName, headerCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ rules }) => rules);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useRuleArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters: () => clearDateFilters(form),
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
}
