export function CheckProduct() {
  return (
    <svg data-testid='prod_test_card_selected' width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5129 0.914466C13.7894 0.645686 14.1573 0.497094 14.5391 0.500043C14.9208 0.502992 15.2865 0.657253 15.559 0.930275C15.8315 1.2033 15.9894 1.57372 15.9995 1.96339C16.0096 2.35306 15.871 2.73149 15.6131 3.01883L7.78171 13.0196C7.64705 13.1677 7.48452 13.2866 7.30384 13.3691C7.12317 13.4515 6.92805 13.496 6.73017 13.4997C6.53229 13.5035 6.3357 13.4664 6.15215 13.3908C5.96861 13.3152 5.80188 13.2026 5.66194 13.0597L0.468524 7.75667C0.323895 7.61906 0.207893 7.45311 0.127436 7.26873C0.0469793 7.08435 0.00371646 6.88531 0.000229091 6.68349C-0.00325828 6.48166 0.0331013 6.28119 0.107138 6.09402C0.181175 5.90686 0.291372 5.73684 0.431156 5.5941C0.570941 5.45137 0.737448 5.33885 0.920745 5.26325C1.10404 5.18765 1.30037 5.15052 1.49803 5.15408C1.69568 5.15764 1.89061 5.20182 2.07118 5.28397C2.25175 5.36613 2.41427 5.48458 2.54903 5.63226L6.65902 9.82697L13.4756 0.958558C13.4879 0.943128 13.501 0.928407 13.5149 0.914466H13.5129Z'
        fill='#fff'
      />
    </svg>
  );
}
