function checkIsFunction(value) {
  return value && Object.prototype.toString.call(value) === '[object Function]';
}

export async function reportWebVitals(onPerfEntry) {
  if (onPerfEntry && checkIsFunction(onPerfEntry)) {
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
  }
}
