import styled from 'styled-components';
import { forwardRef, useState } from 'react';
import { Input } from 'antd';
import { AppInputLabel } from '../AppInputLabel';
import { handleTranslation, isLength } from '../../lib/util/helpers';
import { inputStyles } from '../../lib/util/common-styles';
import {
  baseBorderStyles,
  disabledFieldStyles,
  equalLeftBorderStyles,
  errorFieldBeforeStyles,
  focusedFieldBeforeStyles,
  hoverFieldBeforeStyles,
  initialFieldBeforeStyles,
  resetFieldStyles,
  secondFieldBeforeStyles,
  successFieldBeforeStyles,
} from '../../lib/styles/common';
import { AppCounterInput } from '../AppCounterInput';

const Root = styled('div')({
  height: '100%',
  position: 'relative',
});

const StyledInput = styled(Input)({
  background: 'transparent',
  border: 'none',
});

const fieldGenericStyles = ({ $size, $borderColor, leftborder }) => ({
  position: 'relative',
  width: '100%',

  height: $size === 'small' ? '24px' : '40px',
  display: 'flex',
  transition: 'all 0.1s',
  cursor: 'text',
  padding: '0px',
  alignItems: 'center',
  minHeight: 'unset',
  boxShadow: 'none !important',

  '&, input': {
    fontSize: `${$size === 'small' ? '12px' : 'initial'} !important`,
    background: 'transparent',
  },

  '& span[class*="suffix"]': {
    zIndex: 10,
  },

  ...resetFieldStyles,

  '&:before': $borderColor ? secondFieldBeforeStyles : { ...initialFieldBeforeStyles, ...(leftborder && equalLeftBorderStyles) },
  '&:hover:before': hoverFieldBeforeStyles,
  '&[class*="focused"]:before': focusedFieldBeforeStyles,
  '&[class*="status-error"]:before': errorFieldBeforeStyles,
  '&[class*="status-success"]:before': successFieldBeforeStyles,
  '&[class*="disabled"]': disabledFieldStyles,
});

const WithFeedback = styled(Input)(({ $size, $borderColor, disabled, leftborder }) => ({
  ...fieldGenericStyles({ $size, $borderColor, leftborder }),

  '&.ant-input-affix-wrapper': {
    ...inputStyles,
    background: disabled ? '#F2F2F3' : 'transparent',
  },
}));

const WithoutFeedback = styled('div')(({ $size, $disabled }) => ({
  ...fieldGenericStyles({ $size }),
  input: inputStyles,

  '.ant-input-affix-wrapper': {
    boxShadow: 'none !important',
  },

  ...($disabled && { cursor: 'not-allowed', '&:hover:before': baseBorderStyles }),
}));

export const AppInput = forwardRef(
  (
    { rootProps, value, label, hasFeedback, hasError, disabled, placeholder, size, labelLeft, borderColor, leftborder, isNumber, isActive, ...rest },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    const $asLabel = isActive || isFocused || isLength(value) || ![undefined, null, ''].includes(value);
    const inputProps = { onFocus, onBlur, disabled, value, placeholder: handleTranslation(placeholder), ...rest };

    return (
      <Root ref={ref}>
        {label && <AppInputLabel $size={size} textValue={label} labelLeft={labelLeft} className={$asLabel ? 'asLabel' : ''} type='secondary' />}
        {hasFeedback ? (
          <WithFeedback {...inputProps} $size={size} $borderColor={borderColor} leftborder={leftborder} />
        ) : (
          <WithoutFeedback $size={size} $disabled={disabled} className={`AppInputInner ${hasError ? 'status-error' : ''}`} $borderColor={borderColor}>
            {isNumber ? <AppCounterInput {...inputProps} size={size} /> : <StyledInput {...inputProps} />}
          </WithoutFeedback>
        )}
      </Root>
    );
  }
);
