import { AppText } from '../../../../../../../../../../../../../../ui/text/AppText';
import { CardSectionHeader } from '../../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';

export function ProductPageLinkSectionHeader() {
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;

  return (
    <CardSectionHeader>
      <AppText strong textValue={contentText.productPageLink} />
    </CardSectionHeader>
  );
}
