/* eslint-disable array-callback-return,consistent-return */
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { ContactFormRadioOrCheckboxGroup } from './ContactFormRadioOrCheckboxGroup';
import { DynamicContactFormInputOrDropdown } from './DynamicContactFormInputOrDropdown';
import { ContactFormDatePicker } from './ContactFormDatePicker';
import { ContactFormField } from '../../../../../../../../../lib/models/Questionnaire';
import { ALWAYS_SHOW, OptionType } from '../../../../QuestionnairePage/components/QuestionnaireContent/cards/CardCollapse/ContactForm/consts';
import styles from './styles.module.scss';

// eslint-disable-next-line max-lines-per-function
export function DynamicContactFormRow({
  rowObject,
  form,
  rowIndex,
  fonts,
  colors,
  formList,
  isEndUserForm,
  disabled,
  applyVariable = defaultValue => {
    return defaultValue;
  },
}) {
  const { condition, fieldList } = rowObject;
  const conditionField = formList.find(row => row.id === condition?.fromRowId)?.fieldList[0];
  const conditionFieldName = conditionField?.id;
  const formValue = Form.useWatch(conditionFieldName, form);
  const conditionFieldValue = conditionFieldName ? formValue : null;

  const [isShow, setIsShow] = useState(condition?.value === ALWAYS_SHOW);

  useEffect(() => {
    if (form && condition.optionType === OptionType.GROUP && conditionFieldValue) {
      const currentConditionFieldValue = form.getFieldValue(conditionFieldName);
      const currentValue = currentConditionFieldValue?.title || currentConditionFieldValue;
      const realConditionValue = conditionField?.optionList?.find(option => option.title === currentValue)?.id;
      setIsShow(realConditionValue === condition?.fromOptionId);
    }
    if (condition.value === ALWAYS_SHOW) {
      setIsShow(true);
    }
  }, [form, condition, formList, conditionFieldValue, conditionFieldName, conditionField?.optionList]);

  return (
    <div className={styles.row_content}>
      {isShow
        ? fieldList.map((fieldObj, fieldIndex) => {
            if (
              [
                ContactFormField.TEXT,
                ContactFormField.PHONE_NUMBER,
                ContactFormField.EMAIL,
                ContactFormField.DROPDOWN,
                ContactFormField.DROPDOWN_WITH_ACTION,
              ].includes(fieldObj.type)
            ) {
              return (
                <DynamicContactFormInputOrDropdown
                  key={fieldObj.id}
                  fieldObject={fieldObj}
                  fonts={fonts}
                  colors={colors}
                  disabled={disabled}
                  applyVariable={applyVariable}
                />
              );
            }
            if ([ContactFormField.RADIO, ContactFormField.RADIO_WITH_ACTION, ContactFormField.CHECKBOX].includes(fieldObj.type)) {
              return (
                <ContactFormRadioOrCheckboxGroup
                  key={fieldObj.id}
                  fieldObject={fieldObj}
                  fonts={fonts}
                  colors={colors}
                  isEndUserForm={isEndUserForm}
                  applyVariable={applyVariable}
                />
              );
            }
            if ([ContactFormField.TIME, ContactFormField.DATE].includes(fieldObj.type)) {
              return (
                <ContactFormDatePicker
                  key={fieldObj.id}
                  fieldObject={fieldObj}
                  rowIndex={rowIndex}
                  fieldIndex={fieldIndex}
                  disabled={disabled}
                  applyVariable={applyVariable}
                />
              );
            }
          })
        : null}
    </div>
  );
}
