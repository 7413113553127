import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { headerTitle } from './titleSlice';
import { tenants } from './tenantsSlice';
import { users } from './usersSlice';
import { finders } from './findersSlice';
import { products } from './productsSlice';
import { categories } from './categoriesSlice';
import { templates } from './templatesSlice';
import { themes } from './themesSlice';
import { rules } from './rulesSlice';
import { variables } from './variablesSlice';
import { localization } from './localizationSlice';
import { notifications } from './notificationsSlice';
import { auth } from './authSlice';
import { layouts } from './layoutsSlice';
import { endUserFinder } from './endUserFinderSlice';
import { preview } from './previewSlice';

export const rootReducer = combineReducers({
  auth: persistReducer({ key: 'auth', storage, blacklist: ['error', 'loading'] }, auth),
  headerTitle,
  tenants,
  users,
  finders: persistReducer({ key: 'finders', storage: storageSession, whitelist: ['pastQSSS', 'futureQSSS', 'finder'] }, finders),
  products,
  categories,
  templates,
  layouts,
  themes,
  rules,
  variables,
  localization,
  notifications,
  endUserFinder,
  preview: persistReducer(
    {
      key: 'preview',
      storage,
    },
    preview
  ),
});
