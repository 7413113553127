/* eslint-disable react/no-array-index-key */
import { AppList } from '../../../../../../../../../ui/AppList';
import { ContentTable } from './ContentTable';
import { AddTable } from './components/AddTable';
import styled from 'styled-components';

export function ContentTableList({ tables, structureItemIndex, structureItemId }) {
  return (
    <div style={{ paddingBottom: '30px' }}>
      <AppList>
        {tables?.map((item, index) => (
          <ListItem key={index}>
            <ContentTable tableIndex={index} tables={tables} structureItemIndex={structureItemIndex} structureItemId={structureItemId} />
          </ListItem>
        ))}
      </AppList>
      <AddTable structureItemIndex={structureItemIndex} />
    </div>
  );
}

const ListItem = styled('li')({
  borderBottom: '1px solid #E3E3E6',
  '&:last-child': {
    borderBottom: 0,
  },
});
