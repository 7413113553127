import { FinderSearchPersonHeaderCell } from './FinderSearchPersonHeaderCell';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';

export function useFinderSearchPersonColumn(props) {
  const { arrangedId, width } = props;

  return {
    dataIndex: arrangedId,
    width,
    render: url => <AppText>{url}</AppText>,
    title: <FinderSearchPersonHeaderCell {...props} />,
  };
}
