import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { getBackgroundUrl } from '../../../../../../../../../../../lib/util/helpers';
import { InfoIcon } from '../../../../../../../../../../../ui/icons/InfoIcon';

/**
 * @desc Layout for Intro template
 * @param {Object} selectedElement
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function IntroLayout({ selectedElement, style, stylesConfiguration }) {
  const {
    finder: {
      finderSettings: { media: mediaOptions },
    },
    questionnaireStructure,
  } = useSelector(({ finders }) => finders);

  const { rootStyle = {}, headlineStyle = {}, sublineStyle = {}, primaryButtonStyle = {}, secondaryButtonStyle = {} } = style;
  const { fonts, colors } = stylesConfiguration;
  const element = questionnaireStructure.find(elem => elem.id === selectedElement.id) || {};

  const { content: { headline, primaryButton, secondaryButton, selectBackgroundImage, subline } = {} } = element || {};

  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImage, mediaOptions);

  const {
    body,
    heading2,
    lines,
    primaryButton: primaryButtonDefaultText,
    secondaryButton: secondaryButtonDefaultText,
  } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  return (
    <section
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url('${backgroundImageUrl}')`,
        width: 1920,
        height: 813,
        display: 'flex',
        transform: 'scale(0.237) translate(-3096px, -1320px)',
        position: 'relative',
      }}
    >
      <div style={{ margin: '0 453px', width: '100%' }}>
        <div
          style={{
            width: 500,
            minHeight: 269,
            background: colors.backgroundColor,
            padding: 24,
            margin: '100px 0',
            ...rootStyle,
          }}
        >
          {subline && (
            <div style={{ display: 'flex' }}>
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                style={{
                  marginBottom: 16,
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  ...sublineStyle,
                  maxWidth: '90%',
                }}
              >
                {subline.value || body()}
              </Typography.Paragraph>
              {subline?.infoIcon?.value && (
                <span style={{ position: 'relative' }}>
                  <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 1 }} />
                </span>
              )}
            </div>
          )}
          {headline && (
            <div style={{ display: 'flex' }}>
              <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                style={{
                  ...fonts.headlineSecond,
                  marginBottom: 32,
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                  fontSize: fonts.headlineSecond.fontSize,
                  fontFamily: fonts.headlineSecond.font,
                  fontWeight: fonts.headlineSecond.fontWeight,
                  color: colors[fonts.headlineSecond.colorVariant],
                  ...headlineStyle,
                  maxWidth: '90%',
                }}
              >
                {headline.value || (heading2() && lines())}
              </Typography.Paragraph>
              {headline?.infoIcon?.value && (
                <span style={{ position: 'relative' }}>
                  <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 4 }} />
                </span>
              )}
            </div>
          )}
          {primaryButton && (
            <button
              type='button'
              style={{
                width: 300,
                height: 48,
                background: colors.primaryColor,
                marginBottom: 10,
                border: 'none',
                borderRadius: 24,
                color: '#FFFFFF',
                overflow: 'hidden',
                wordBreak: 'break-all',
                cursor: 'pointer',
                position: 'relative',
                ...primaryButtonStyle,
              }}
            >
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                style={{
                  margin: '0 auto',
                  maxWidth: '80%',
                  marginBottom: 0,
                }}
              >
                {primaryButton.value || primaryButtonDefaultText()}
              </Typography.Paragraph>
              {primaryButton?.infoIcon?.value && (
                <span style={{ position: 'absolute', right: 15, top: 8 }}>
                  <InfoIcon />
                </span>
              )}
            </button>
          )}
          {secondaryButton && (
            <button
              type='button'
              style={{
                ...fonts.body,
                width: 300,
                height: 48,
                background: '#FFFFFF',
                marginBottom: 10,
                border: '1px solid #FFFFFF',
                borderRadius: 24,
                color: '#191919',
                overflow: 'hidden',
                wordBreak: 'break-all',
                cursor: 'pointer',
                position: 'relative',
                ...secondaryButtonStyle,
              }}
            >
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                style={{
                  margin: '0 auto',
                  maxWidth: '80%',
                  marginBottom: 0,
                }}
              >
                {secondaryButton.value || secondaryButtonDefaultText()}
              </Typography.Paragraph>
              {secondaryButton?.infoIcon?.value && (
                <span style={{ position: 'absolute', right: 15, top: 8 }}>
                  <InfoIcon />
                </span>
              )}
            </button>
          )}
        </div>
      </div>
      {selectBackgroundImage?.infoIcon?.value && (
        <span style={{ position: 'absolute', right: 25, top: 20 }}>
          <InfoIcon />
        </span>
      )}
    </section>
  );
}
