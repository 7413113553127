/* eslint-disable no-param-reassign */
import { QuestionnaireSwitch } from '../../../../../../../../features/QuestionnaireSwitch';
import { CardSectionHeader } from '../../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../../../../hooks/useUpdateStructure';

export function CounterSectionHeader({ structureItemIndex, productIndex, data }) {
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;
  const { updateStructure } = useUpdateStructure();

  const toggle = () => {
    updateStructure(draft => {
      const prevValue = draft[structureItemIndex].content.products[productIndex].counterSection.showCounter;
      draft[structureItemIndex].content.products[productIndex].counterSection.showCounter = !prevValue;
    });
  };

  return (
    <CardSectionHeader>
      <QuestionnaireSwitch
        labelProps={{
          strong: true,
          textValue: contentText.showCounter,
        }}
        isOn={data.showCounter}
        onChange={toggle}
      />
    </CardSectionHeader>
  );
}
