/* eslint-disable no-magic-numbers */
import { useMemo } from 'react';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { isLength, isTruthy } from '../../../../../../../../lib/util/helpers';
import { Typography } from 'antd';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { ProductLayoutCardBulletPoints } from '../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ProductLayouts/components/ProductLayoutCardBulletPoints';
import { InfoIconComponent } from '../InfoIconComponent';
import { calculateProductSavings } from '../../../../utils/savings-helpers';
import { formatPriceForUi, transformCurrencyCodeToSymbol } from '../../../../../../../../lib/models/Finance';
import { useProductPriceTypeText } from '../../hooks/useProductPriceTypeText';
import { Product } from '../../../FindersAndProductsPage/lib/Product';
import { TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { getRulesProductPrice } from '../../../../utils/rules/product_price_rules_helper';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

const KONTOFINDER_PK_QUESTION_6_ID = '05597e4b-e93f-41d9-bdf4-7d2f1cfc2d7a';
const KOVTUN_KONTOFINDER_PK_QUESTION_6_ID = 'e58aee4d-a297-4910-aea6-d3ccd0da5f01';

// eslint-disable-next-line max-lines-per-function
export function ProductCardContent({
  pagesFlow,
  card,
  fonts,
  colors,
  bulletPointIconColor,
  bulletPointIcon,
  customIcons,
  defaultIcons,
  showSavings,
  currentElements,
}) {
  const { applyVariable } = useVariables(pagesFlow);

  const { translations } = useLocalization();
  const { templates } = translations.unique.pages.questionnaire.rightSideBar.layout;

  const { showPrice, rulePrice, rulePriceType } = getRulesProductPrice(card, pagesFlow, currentElements);
  const priceType = showPrice ? rulePriceType : card?.priceSection?.priceType;
  const price = showPrice || rulePriceType === card?.priceSection?.priceType ? rulePrice : card?.priceSection?.priceValue;
  const formattedPrice = formatPriceForUi(price);
  const currency = transformCurrencyCodeToSymbol(card?.priceSection?.currency);
  const productPriceType = useProductPriceTypeText(priceType);
  const maybeProductPriceType = productPriceType ? String(productPriceType) : '';
  const priceAndCurrency = priceType === Product.PRICE_PRE_REQUEST ? price : `${formattedPrice} ${currency} ${maybeProductPriceType}`;
  const productId = card?.productNameSection?.product?.value;
  const ruleProd = pagesFlow.rulesProductsProperties.find(prod => prod.id === card.id);
  const savingsValue = ruleProd?.ruleSavings ?? calculateProductSavings(pagesFlow?.allPages, pagesFlow?.rulesAnswersSavings, productId);
  const savings = `${savingsValue} ${currency}`;

  const { allPages } = pagesFlow;

  const allProducts = allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.PRODUCT));
  const P9 = allProducts[8];

  const allQuestions = allPages.flatMap(page => page.elements.filter(el => el.templateType === TemplateTypes.QUESTION));
  const Q6 = allQuestions[5];

  const Subline = (
    <Typography.Paragraph
      style={{
        whiteSpace: 'break-spaces',
        lineHeight: 1.2,
        fontSize: fonts.body.fontSize,
        fontFamily: fonts.body.font,
        fontWeight: fonts.body.fontWeight,
        color: colors[fonts.body.colorVariant],
      }}
    >
      {applyVariable(card?.productNameSection.subline.value)}
      <InfoIconComponent applyVariable={applyVariable} type={card?.productNameSection.subline} styles={{ top: 3 }} />
    </Typography.Paragraph>
  );

  const BulletPoints = useMemo(() => {
    const bulletIcon = [...customIcons, ...defaultIcons].find(item => item.id === bulletPointIcon?.value);

    return (
      <ProductLayoutCardBulletPoints
        applyVariable={applyVariable}
        bulletPointIconSvg={bulletPointIcon?.svgContent}
        bulletPointsSection={card?.bulletPointsSection}
        bulletPointIconColor={bulletPointIconColor}
        bulletPointIconUrl={bulletIcon ? bulletIcon.s3Url : defaultIcons[0].s3Url}
        fonts={fonts}
      />
    );
  }, [fonts, bulletPointIcon, bulletPointIconColor, card?.bulletPointsSection, customIcons, defaultIcons, applyVariable]);

  const { showTotalPrice } = card?.priceSection ?? {};

  const savingsStyle = {
    fontSize: fonts?.body?.fontSize,
    fontFamily: fonts?.body?.font,
    fontWeight: 700,
    color: colors?.[fonts?.body?.colorVariant],
  };

  const productPrice = isTruthy(
    [KOVTUN_KONTOFINDER_PK_QUESTION_6_ID, KONTOFINDER_PK_QUESTION_6_ID].includes(Q6?.id) && P9?.content.products[0].id === card.id ? '' : price
  )
    ? priceAndCurrency
    : '‎';

  return (
    <div style={{ width: '100%' }}>
      <div style={{ position: 'relative' }}>{card?.productNameSection.subline?.value && Subline}</div>
      {isLength(card?.bulletPointsSection.bulletPoints) && BulletPoints}
      {showTotalPrice && (
        <div style={{ textAlign: 'right', marginTop: 8 }}>
          <AppText
            style={{
              fontSize: fonts.body.fontSize,
              fontFamily: fonts.body.font,
              fontWeight: fonts.body.fontWeight,
              color: colors[fonts.body.colorVariant],
            }}
            textValue={applyVariable(productPrice)}
          />
          <InfoIconComponent applyVariable={applyVariable} type={card?.priceSection} styles={{ top: 3 }} />
        </div>
      )}
      {showSavings && (
        <div style={{ marginLeft: 'auto', marginTop: 8, display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
            <AppText data-testid='prod_test_savings' textValue={savings} style={savingsStyle} />
            <AppText textValue={templates.savings} style={{ ...savingsStyle, fontSize: savingsStyle?.fontSize || '12px' }} />
          </div>
        </div>
      )}
    </div>
  );
}
