import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../lib/Product';
import { AppFormSection } from '../../../../../../../../../../ui/AppFormSection';
import { useIsExceptional } from '../lib/useIsExceptional';

export function PricePerRequest(props) {
  const { label } = useLocalization().translations.unique.formItems.pricePerRequest;
  const { formItemProps, textFieldProps } = props;

  return (
    <AppFormSection
      formItemProps={{
        name: Product.PRICE_PRE_REQUEST,
        ...formItemProps,
      }}
      textFieldProps={{
        label,
        disabled: useIsExceptional(),
        ...textFieldProps,
      }}
    />
  );
}
