import { PreviewFormWrapper } from '../components/PreviewFormWrapper';
import { PreviewFormHeader } from '../components/PreviewFormHeader';

export function PrivateClientForm(props) {
  return (
    <div>
      <div>
        <PreviewFormHeader {...props} />
        <PreviewFormWrapper {...props} />
      </div>
    </div>
  );
}
