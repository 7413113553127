export function PauseCircleIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg className={className} {...rest} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12V12ZM9.375 7.5C8.34 7.5 7.5 8.34 7.5 9.375V14.625C7.5 15.1223 7.69754 15.5992 8.04917 15.9508C8.40081 16.3025 8.87772 16.5 9.375 16.5C9.87228 16.5 10.3492 16.3025 10.7008 15.9508C11.0525 15.5992 11.25 15.1223 11.25 14.625V9.375C11.25 8.34 10.41 7.5 9.375 7.5ZM14.625 7.5C13.59 7.5 12.75 8.34 12.75 9.375V14.625C12.75 15.1223 12.9475 15.5992 13.2992 15.9508C13.6508 16.3025 14.1277 16.5 14.625 16.5C15.1223 16.5 15.5992 16.3025 15.9508 15.9508C16.3025 15.5992 16.5 15.1223 16.5 14.625V9.375C16.5 8.34 15.66 7.5 14.625 7.5Z'
        fill='#00ACC0'
      />
    </svg>
  );
}
