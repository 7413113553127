import { types, logicalTypes } from 'src/processes/CMS/processes/management/pages/BuilderPage/Condition/Actions/actionConstants';

const elementItems = [types.element, types.value, types.variable];

/**
 * @desc Sets the availability status of actions and their children based on available options.
 * @param {Array} actions - The array of actions to set availability for.
 * @param {Array} availableOptions - The array of available options.
 * @returns {Array} - The updated array of actions with availability status.
 */
/* eslint max-lines: ["error", 300] */
const setAvailableActions = (actions, availableOptions) => {
  return actions.map(action => {
    if (availableOptions.includes(action.name)) {
      return {
        ...action,
        disabled: false,
      };
    }

    let noChildrenAvailable = true;

    const actionChildren = action.children?.map(child => {
      if (availableOptions.includes(child.name)) {
        noChildrenAvailable = false;
      }

      return {
        ...child,
        disabled: !availableOptions.includes(child.name),
      };
    });

    return {
      ...action,
      disabled: noChildrenAvailable,
      children: actionChildren,
    };
  });
};

/**
 * @desc Validates the logical operator and updates the availability of actions based on the operator value.
 * @param {Array} actions - The array of actions to update availability for.
 * @param {string} operatorValue - The value of the logical operator to validate.
 * @param {Array} body - The body of the condition.
 * @param {boolean} isThenOperatorPlaced - Indicates whether the THEN operator is already placed.
 * @returns {Array} - The updated array of actions with availability status.
 */
const validateLogicalOperator = (actions, operatorValue, body, isThenOperatorPlaced) => {
  const logicalOperators = body.filter(
    conditionItem => conditionItem.value === logicalTypes.openBracket || conditionItem.value === logicalTypes.closeBracket
  );
  const lastItem = -1;
  const isBracketOpen = logicalOperators.at(lastItem)?.value === logicalTypes.openBracket;

  switch (operatorValue) {
    case logicalTypes.and: {
      let availableOptions = [];

      availableOptions = isThenOperatorPlaced ? [types.resultType] : elementItems;

      if (!isBracketOpen && !isThenOperatorPlaced) {
        availableOptions.push(logicalTypes.openBracket);
      }

      return setAvailableActions(actions, availableOptions);
    }
    case logicalTypes.or:
    case 'IF': {
      const availableOptions = elementItems;

      if (!isBracketOpen) {
        availableOptions.push(logicalTypes.openBracket);
      }

      return setAvailableActions(actions, availableOptions);
    }
    case logicalTypes.openBracket: {
      const availableOptions = elementItems;
      return setAvailableActions(actions, availableOptions);
    }
    case logicalTypes.closeBracket: {
      const availableOptions = [logicalTypes.Then, logicalTypes.or, logicalTypes.and];
      return setAvailableActions(actions, availableOptions);
    }
    case logicalTypes.Then: {
      const availableOptions = [types.resultType];
      return setAvailableActions(actions, availableOptions);
    }
    default:
      return actions;
  }
};

/**
 * @desc Validates the comparison operator and updates the availability of actions based on the available options.
 * @param {Array} actions - The array of actions to update availability for.
 * @returns {Array} - The updated array of actions with availability status.
 */
const validateComparisonOperator = actions => {
  const availableOptions = elementItems;
  return setAvailableActions(actions, availableOptions);
};

/**
 * @desc Validates the arithmetic type and updates the availability of actions based on the available options.
 * @param {Array} actions - The array of actions to update availability for.
 * @returns {Array} - The updated array of actions with availability status.
 */
const validateArithmeticType = actions => {
  const availableOptions = elementItems;
  return setAvailableActions(actions, availableOptions);
};

/**
 * @desc Validates the result type and updates the availability of actions based on the available options.
 * @param {Array} actions - The array of actions to update availability for.
 * @returns {Array} - The updated array of actions with availability status.
 */
const validateResultType = actions => {
  const availableOptions = [logicalTypes.and, types.arithmeticOperator];
  return setAvailableActions(actions, availableOptions);
};
/**
 * @desc Validates the item operator and updates the availability of actions based on the available options.
 * @param {Array} actions - The array of actions to update availability for.
 * @param {Array} body - The body of the condition.
 * @param {boolean} isThenOperatorPlaced - Indicates whether the THEN operator is already placed.
 * @returns {Array} - The updated array of actions with availability status.
 */
const validateItemOperator = (actions, body, isThenOperatorPlaced) => {
  const logicalOperators = body.filter(
    conditionItem => conditionItem.value === logicalTypes.openBracket || conditionItem.value === logicalTypes.closeBracket
  );
  const lastItem = -1;
  const itemBeforeElementIndex = -2;
  const isBracketOpen = logicalOperators.at(lastItem)?.value === logicalTypes.openBracket;
  const itemBeforeElement = body.at(itemBeforeElementIndex);

  if (isThenOperatorPlaced) {
    return setAvailableActions(actions, [logicalTypes.and, types.arithmeticOperator]);
  }

  if (isBracketOpen && (itemBeforeElement.type === types.logicalOperator || itemBeforeElement.type === types.arithmeticOperator)) {
    return setAvailableActions(actions, [
      types.comparisonOperator,
      types.arithmeticOperator,
      logicalTypes.closeBracket,
      logicalTypes.or,
      logicalTypes.and,
    ]);
  }

  if (isBracketOpen && itemBeforeElement.type === types.comparisonOperator) {
    return setAvailableActions(actions, [types.arithmeticOperator, logicalTypes.closeBracket, logicalTypes.and, logicalTypes.or]);
  }

  if (isBracketOpen) {
    return setAvailableActions(actions, [types.arithmeticOperator, logicalTypes.closeBracket, logicalTypes.and, logicalTypes.or]);
  }

  if (itemBeforeElement.type === types.comparisonOperator) {
    return setAvailableActions(actions, [logicalTypes.Then]);
  }

  if (itemBeforeElement.type === types.arithmeticOperator) {
    return setAvailableActions(actions, [types.arithmeticOperator, types.comparisonOperator]);
  }

  const availableOptions = [types.arithmeticOperator, types.comparisonOperator, logicalTypes.Then, logicalTypes.or, logicalTypes.and];
  return setAvailableActions(actions, availableOptions);
};

/**
 * @desc Performs action validation based on the last condition item type and updates the availability of actions accordingly.
 * @param {Array} actions - The array of actions to validate.
 * @param {Array} body - The body of the condition.
 * @returns {Object} - An object containing the validated actions.
 */
export const useActionValidation = (actions, body) => {
  const lastIndex = -1;
  const lastConditionItem = body.at(lastIndex);

  let validatedActions = [];

  const isThenOperatorPlaced = body.some(conditionItem => conditionItem.value === logicalTypes.Then);

  switch (lastConditionItem.type) {
    case types.logicalOperator:
      validatedActions = validateLogicalOperator(actions, lastConditionItem.value, body, isThenOperatorPlaced);
      break;
    case types.element:
    case types.value:
    case types.variable:
      validatedActions = validateItemOperator(actions, body, isThenOperatorPlaced);
      break;
    case types.arithmeticOperator:
      validatedActions = validateArithmeticType(actions);
      break;
    case types.comparisonOperator:
      validatedActions = validateComparisonOperator(actions);
      break;
    case types.resultType:
      validatedActions = validateResultType(actions);
      break;
    default:
      validatedActions = actions;
  }

  validatedActions = validatedActions.map(action => {
    if (action.name === types.arithmeticOperator) {
      return {
        ...action,
        children: action.children.map(actionChild => {
          if (actionChild.name === '=') {
            return {
              ...actionChild,
              disabled: !isThenOperatorPlaced || action.disabled,
            };
          }

          return actionChild;
        }),
      };
    }

    return action;
  });

  return { validatedActions };
};
