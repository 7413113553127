export function FastBackwardIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg className={className} {...rest} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.5 12C3.5 12.1326 3.55268 12.2598 3.64645 12.3536C3.74021 12.4473 3.86739 12.5 4 12.5C4.13261 12.5 4.25979 12.4473 4.35355 12.3536C4.44732 12.2598 4.5 12.1326 4.5 12L4.5 8.752L10.767 12.388C11.307 12.7 12 12.322 12 11.692L12 4.308C12 3.678 11.308 3.298 10.767 3.611L4.5 7.247L4.5 4C4.5 3.86739 4.44732 3.74021 4.35355 3.64645C4.25979 3.55268 4.13261 3.5 4 3.5C3.86739 3.5 3.74022 3.55268 3.64645 3.64645C3.55268 3.74021 3.5 3.86739 3.5 4L3.5 12Z'
        fill='white'
      />
    </svg>
  );
}
