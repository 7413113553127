import { ConfigProvider } from 'antd';
import './styles.scss';
import styled from 'styled-components';
import { AppSider } from '../AppSider';

const theme = {
  token: {
    colorPrimary: '#5C5C6E',
    borderRadiusLG: 0,
  },
};

export function AppLeftSidebar({ children, ...rest }) {
  return (
    <Root width={240} {...rest}>
      <ConfigProvider theme={theme}>{children}</ConfigProvider>
    </Root>
  );
}

const Root = styled(AppSider)({
  '&.ant-layout-sider': {
    background: '#404055 !important',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
});
