import { useLayoutNumberColumn } from '../../components/sort';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { Layout } from '../../lib/Layout';

export function useLayoutUsedInEditingFindersColumn() {
  const { editUsed: title } = useLocalization().translations.shared.columns;

  return useLayoutNumberColumn({
    title,
    arrangedId: Layout.USED_IN_EDITING_FINDERS,
    columnProps: {
      width: 160,
    },
  });
}
