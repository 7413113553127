import { CheckmarkIcon } from '../icons/CheckmarkIcon';
import styles from './AppNewCheckbox.module.sass';
import { AppText } from '../text/AppText';

export function AppNewCheckbox({ label, checked = false, onChange = () => {}, style, isDisabled }) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={isDisabled ? styles.disabled : styles.root} style={style}>
      <input type='checkbox' checked={checked} onChange={onChange} className={styles.input} />

      <span className={styles.content}>
        <span className={styles.customCheckbox}>
          <CheckmarkIcon />
        </span>

        {label && <AppText textValue={label} className={styles.label} />}
      </span>
    </label>
  );
}
