/* eslint-disable consistent-return */
import { checkIsHttpError, checkIsHttpServerError } from '../http-helpers';
// import { getFirstArrStrOrStr } from '../../helpers';
import { HttpStatusCode } from '../http-status-code';
import { AuthURI } from '../../../api';
import { handleUpdateAccessTokenAndRetryRequests } from './axios-tokens-handler';
import { store } from '../../../store';
import { logoutSuccess } from '../../../store/slices/authSlice';

const ERROR_MESSAGES = {
  unknownMessage: 'Unknown error message',
  // eslint-disable-next-line unicorn/no-unused-properties
  unknownResponseStatusCode: 'Unknown response status code',
  unknownServer: 'Unknown server error',
  unknownHttp: 'Unknown HTTP error',
};

// function getErrorMessage(value) {
//   return (
//     getFirstArrStrOrStr(value?.data) ||
//     getFirstArrStrOrStr(value?.data?.detail) ||
//     getFirstArrStrOrStr(value?.data?.message) ||
//     value?.toJSON?.()?.message ||
//     ERROR_MESSAGES.unknownMessage
//   );
// }

function getErrorObject(value) {
  if (value?.status && checkIsHttpServerError(value.status)) {
    return { ...value, message: ERROR_MESSAGES.unknownServer };
  }

  if (typeof value === 'string') {
    return { ...value, message: ERROR_MESSAGES.unknownMessage };
  }

  return value?.data ? value.data : value;
}

function getStatusCode(axiosResponse) {
  // const isUnknown = !checkIsValidHttpCode(result);

  // if (isUnknown) {
  //   return Promise.reject(new Error(ERROR_MESSAGES.unknownResponseStatusCode));
  // }

  return axiosResponse?.status || axiosResponse?.data?.statusCode || axiosResponse?.data?.status || axiosResponse?.data?.httpCode;
}

function checkShouldUpdateAccessToken(response) {
  const skippedUrls = Object.values(AuthURI);

  if (response?.config?.url) {
    const isAllowedUrl = !skippedUrls.includes(response.config.url);

    return response?.status === HttpStatusCode.UNAUTHORIZED && isAllowedUrl;
  }
}

export function checkIsError(axiosResponse) {
  const statusCode = getStatusCode(axiosResponse);

  return checkIsHttpError(statusCode);
}

export function handleAxiosError(passedError) {
  const statusCode = getStatusCode(passedError);
  // const errorMessage = getErrorMessage(passedError);
  const errorObject = getErrorObject(passedError);

  const shouldLogOut = passedError?.data?.exceptionCode === 'ERROR_306';
  const isKnownHttpError = checkIsHttpError(statusCode);
  const isUnknownServerError = errorObject.message === 'Network Error';
  const shouldUpdateAccessToken = checkShouldUpdateAccessToken(passedError);

  if (shouldLogOut) {
    // eslint-disable-next-line i18n-text/no-en,no-alert
    alert('For security reasons, your session has expired. Please click OK and log in again.');
    store.dispatch(logoutSuccess());
    return;
  }

  if (shouldUpdateAccessToken) {
    return handleUpdateAccessTokenAndRetryRequests(passedError);
  }

  if (isKnownHttpError) {
    throw errorObject;
  }

  if (isUnknownServerError) {
    throw new Error(ERROR_MESSAGES.unknownServer);
  }

  throw new Error(ERROR_MESSAGES.unknownHttp);
}
