import { useEffect } from 'react';

export function useResetArrange({ isActiveArrange, form, clearOptions }) {
  useEffect(() => {
    if (!isActiveArrange) {
      form.resetFields();

      if (clearOptions) {
        clearOptions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveArrange, form]);
}
