import { Form } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';

export const AppFormItem = forwardRef(({ children, className = '', size, ...rest }, ref) => {
  return (
    <Root className={`AppFormItem ${className}`} $size={size} {...rest} ref={ref}>
      {children}
    </Root>
  );
});

const Root = styled(Form.Item)(({ theme, $size }) => ({
  minHeight: $size === 'small' ? '40px' : '68px',
  marginBottom: 0,

  '.ant-form-item-control-input': {
    minHeight: 'unset',
  },

  // bottom caption (error)
  '& .ant-form-item-explain > *': {
    margin: '4px 0 0',
    color: theme.token.colorTextSecondary,
    fontSize: '12px',
    letterSpacing: '-1.5%',
    paddingLeft: '16px',
  },

  // hide all errors except first one
  '.ant-form-item-explain .ant-form-item-explain-error:not(:first-child)': {
    display: 'none',
  },
}));
