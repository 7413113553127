import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useLayoutNumberColumn } from '../../components/sort';
import { Layout } from '../../lib/Layout';

export function useLayoutUsedInTotalFindersColumn() {
  const { totalUsed: title } = useLocalization().translations.shared.columns;

  return useLayoutNumberColumn({
    title,
    arrangedId: Layout.USED_IN_TOTAL_FINDERS,
    columnProps: {
      width: 144,
    },
    bodyCellProps: {
      className: 'primary_color',
    },
  });
}
