import { Input } from 'antd';
import styled from 'styled-components';
import { inputStyles } from '../lib/util/common-styles';

export function AppTextArea(props) {
  return <Root {...props} />;
}

const Root = styled(Input.TextArea)({
  ...inputStyles,
  overflow: 'auto',
  padding: '0 !important',

  textarea: {
    padding: '0 16px 0',
    height: '100%',
    paddingTop: '20px !important',
    paddingBottom: '20px !important',
  },
});
