import { LeftPointerIcon } from '../../../../../../../../../../../../ui/icons/LeftPointerIcon';
import { RightPointerIcon } from '../../../../../../../../../../../../ui/icons/RightPointerIcon';
import styled from 'styled-components';

export function ProductLayoutScrollButtons(props) {
  return (
    <Root {...props}>
      <Btn>
        <LeftPointerIcon />
      </Btn>

      <Btn>
        <RightPointerIcon />
      </Btn>
    </Root>
  );
}

const Root = styled('div')({
  gap: '16px',
  display: 'flex',
  justifyContent: 'center',
});

const Btn = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: '#DA1B5E',
  width: '32px',
  height: '32px',
});
