/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppText } from '../../../../../../ui/text/AppText';
import { IconButton } from '../IconButton';
import { CircleXIcon } from '../../../../../../ui/icons/CircleXIcon';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { DefaultCounter } from '../DefaultCounter';
import { CounterMaxValueFormItem } from '../form-items/CounterMaxValueFormItem';
import './styles.scss';
import { useFirstMountState } from '../../../../../../lib/hooks/useFirstMountState';

// eslint-disable-next-line max-lines-per-function
export function DraggableFormRow({ valueObject, valueArray, index, onChange, label, deleteValue }) {
  const { templateAnswerMin, templateAnswerMax } = useLocalization().translations.unique.formItems;
  const { to } = useLocalization().translations.shared.generic;
  const { isViewMode } = useSelector(({ finders }) => finders);

  const isFirstMountState = useFirstMountState();

  const handleValueChange = (fieldName, value) => {
    const newArr = [...valueArray];
    newArr[index] = { ...valueObject, [fieldName]: value };
    onChange(newArr);
  };

  const handleValueNumberChange = (fieldName, value) => {
    const numbered = Math.round(Number(value));
    handleValueChange(fieldName, numbered);
  };

  const handleChangeDefaultCounterValue = (fieldName, value) => {
    // eslint-disable-next-line init-declarations
    let result;
    if (Number(value) > Number(valueObject.maxValue)) {
      result = Number(valueObject.maxValue);
    } else if (Number(value) < Number(valueObject.minValue)) {
      result = Number(valueObject.minValue);
    } else {
      result = Number(value);
    }
    handleValueChange(fieldName, result);
  };

  useEffect(() => {
    if (isFirstMountState) {
      return;
    }

    if (valueObject?.showDefaultCounter) {
      handleChangeDefaultCounterValue('amount', valueObject.amount);
    }
    if (!valueObject?.showDefaultCounter) {
      handleChangeDefaultCounterValue('amount', valueObject.minValue);
    }
  }, [valueObject.maxValue, valueObject.minValue]);

  const handleToggleDefaultCounter = value => {
    const newArr = [...valueArray];
    const { showDefaultCounter, ...rest } = valueObject;
    if (!value) {
      newArr[index] = { ...valueObject, showDefaultCounter: true, amount: valueObject.minValue };
    } else {
      newArr[index] = { ...rest, amount: valueObject.minValue };
    }
    onChange(newArr);
  };

  return (
    <div className='draggable_form_row'>
      <Space size='middle' className='stretch_first_child' style={{ alignItems: 'flex-start', width: '100%' }}>
        <AppFormSection
          formItemProps={{ style: { width: '100%', minHeight: 45 } }}
          textFieldProps={{
            value: valueObject.value,
            onChange: event => handleValueChange('value', event.target.value),
            label: label({ index: index + 1 }),
            disabled: isViewMode,
          }}
        />
        <Space style={{ alignItems: 'flex-start' }}>
          <AppFormSection
            formItemProps={{ style: { width: 110, minHeight: 50 }, hasFeedback: false }}
            textFieldProps={{
              value: valueObject.minValue,
              onChange: value => handleValueNumberChange('minValue', value),
              max: valueObject.maxValue,
              label: templateAnswerMin.label,
              disabled: isViewMode,
              isNumber: true,
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
            <AppText className='bold' textValue={to} />
          </div>

          <Space.Compact>
            <CounterMaxValueFormItem value={valueObject.maxValueText} onChange={value => handleValueChange('maxValueText', value)} />
            <AppFormSection
              formItemProps={{ style: { width: 124, minHeight: 50 }, hasFeedback: false }}
              textFieldProps={{
                value: valueObject.maxValue,
                onChange: value => handleValueNumberChange('maxValue', value),
                min: valueObject.minValue,
                label: templateAnswerMax.label,
                disabled: isViewMode,
                leftborder: 'true',
                isNumber: true,
              }}
            />
          </Space.Compact>
        </Space>
        <IconButton
          additionalStyles='draggable_delete_button'
          onClick={() => deleteValue(valueObject.id)}
          disabled={isViewMode}
          icon={<CircleXIcon className='draggable_delete_button_icon' />}
        />
      </Space>
      <div className='draggable_form_row_bottom'>
        <DefaultCounter
          checked={valueObject?.showDefaultCounter}
          value={valueObject.amount}
          onToggleDefaultCounter={handleToggleDefaultCounter}
          onChangeDefaultCounterValue={value => handleChangeDefaultCounterValue('amount', value)}
        />
      </div>
    </div>
  );
}
