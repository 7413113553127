/* eslint-disable max-lines, max-lines-per-function */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultColors, DefaultFontStyles } from '../../../../lib/models/DefaultStylesFontsAndColors';
import { PreviewFooter } from './components/PreviewFooter';
import { PreviewHeader } from './components/PreviewHeader';
import { PreviewLayoutTemplates } from './components/PreviewLayoutTemplates';
import { setAllElementsButtonsIds } from './hooks/setNextFlow';
import styles from './styles.module.scss';
import { loadFonts } from '../ThemePage/lib/util/loadFonts';
import { PreviewAudioGuideModal } from './components/PreviewModals/PreviewAudioGuideModal';
import { PreviewAudioPlayer } from './components/PreviewAudioPlayer';
import { AppPreloader } from '../../../../../../ui/AppPreloader';
import {
  createChapterPage,
  createPrevPageIdsIfNeeded,
  getChapters,
  getCurrentChapterIdIfNeeded,
  getCurrentElementsIds,
  getCurrentFlowElement,
  getCurrentPageId,
  getPages,
  getQuestionAnswerIdsDependsOnFlow,
  updatePagesAndSelectAnswers,
  updateScoringForCurrentQuestion,
} from '../../utils/end-user-flow-helpers';
import styled from 'styled-components';
import { getTenantByIdApiRequest } from '../../../../../../lib/api';
import { useGetFinder } from '../../../../../../lib/hooks/useGetFinder';
import { appLogger } from '../../../../../../lib/util/logger';
import { cleanFinder } from '../../../../../../lib/store/slices/findersSlice';
import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';
import { useNavigate } from 'react-router-dom';
import { NewProgressBar } from './components/NewProgressBar';

const ZERO = 0;

async function setTenant(setPagesFlow, tenantId) {
  const { data } = await getTenantByIdApiRequest(tenantId);
  setPagesFlow(prev => ({
    ...prev,
    tenant: data,
  }));
}

export function PreviewPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { finderError } = useSelector(({ finders }) => finders);
  const { brand, finderSettings = {}, currentQuestionnaireStructure, selectedQuestionnaireElement, rules } = useSelector(({ preview }) => preview);

  const [loading, setLoading] = useState(true);
  const { finder } = useSelector(({ finders }) => finders);
  const { getFinder, tenantId } = useGetFinder();

  const [pagesFlow, setPagesFlow] = useState({
    allChapters: [],
    allPages: [],
    currentChapterId: null,
    currentPageId: null,
    currentFlowElement: null,
    currentElementsIds: [],
    allShownButtonsIds: [],
    prevPageIds: [],
    completed: false,
    answersScoring: {},
    // finder: null,
    // tenant: null,
  });

  const [audioGuideModal, setAudioGuideModal] = useState(true);
  const [isAudioGuidePlaying, setIsAudioGuidePlaying] = useState(false);
  const [showChapterIds, setShowChapterIds] = useState([]);

  const audioGuideRef = useRef(null);

  const stylesConfiguration = brand?.id && brand.activated ? JSON.parse(brand.configuration) : { fonts: DefaultFontStyles, colors: DefaultColors };

  const { fontsForLoading, fonts, colors } = stylesConfiguration;

  const onCloseAudioGuide = useCallback(() => {
    setAudioGuideModal(false);
  }, []);

  const playAudio = async () => {
    setIsAudioGuidePlaying(true);
    await audioGuideRef?.current?.play();
  };

  const pauseAudio = async () => {
    setIsAudioGuidePlaying(false);
    await audioGuideRef.current.pause();
    // eslint-disable-next-line require-atomic-updates
    audioGuideRef.current.currentTime = ZERO;
  };

  useEffect(() => {
    dispatch(cleanFinder());
    getFinder();
  }, [dispatch, getFinder]);

  useEffect(() => {
    if (finderError) {
      navigate(ROUTER_PATHS.notFound);
      dispatch(cleanFinder());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finderError]);

  useEffect(() => {
    if (fontsForLoading) {
      loadFonts(fontsForLoading);
    }
  }, [fontsForLoading]);

  // Set finder
  useEffect(() => {
    if (finder?.name && !pagesFlow?.finder) {
      setPagesFlow(prev => ({
        ...prev,
        finder,
      }));
    }
  }, [finder, pagesFlow]);

  // Set tenant
  useEffect(() => {
    if (tenantId && !pagesFlow?.tenant) {
      setTenant(setPagesFlow, tenantId);
    }
  }, [tenantId, pagesFlow]);

  useEffect(() => {
    if (currentQuestionnaireStructure) {
      // split by pages
      const pages = getPages(currentQuestionnaireStructure, rules);
      // find all chapters and join them
      const chapters = getChapters(currentQuestionnaireStructure, pages, rules);
      // create special page for all chapters and update answers
      const updatedPages = createChapterPage(pages, chapters);
      // find currentChapter based on selectedElement in Questionnaire
      const currentChapterId = getCurrentChapterIdIfNeeded(chapters, selectedQuestionnaireElement);
      // find currentPageId based on selectedElement in Questionnaire
      const currentPageId = getCurrentPageId(updatedPages, selectedQuestionnaireElement, rules);
      // create prevPageIds if we open preview with selected element
      const prevPageIds = createPrevPageIdsIfNeeded(chapters, updatedPages, selectedQuestionnaireElement, currentChapterId, currentPageId);

      const currentFlowElement = getCurrentFlowElement(updatedPages, selectedQuestionnaireElement, rules);

      const currentElementId = getCurrentElementsIds(updatedPages, selectedQuestionnaireElement, currentPageId);

      const selectedAnswersIds = getQuestionAnswerIdsDependsOnFlow(currentChapterId, currentPageId, chapters, selectedQuestionnaireElement);
      // create list of all shown buttons
      const allShownButtonsIds = setAllElementsButtonsIds(updatedPages.flatMap(page => page.elements));

      const updatedPagesWithAnswers = updatePagesAndSelectAnswers(
        updatedPages,
        currentPageId,
        selectedQuestionnaireElement,
        selectedAnswersIds,
        chapters,
        currentChapterId
      );

      const updatedAnswersScoring = updateScoringForCurrentQuestion(
        currentPageId,
        selectedAnswersIds,
        chapters,
        currentChapterId,
        {},
        selectedQuestionnaireElement
      );

      setPagesFlow({
        allChapters: chapters,
        allPages: updatedPagesWithAnswers,
        currentChapterId,
        currentPageId,
        currentFlowElement,
        currentElementsIds: selectedQuestionnaireElement ? [currentElementId, selectedQuestionnaireElement.id] : currentElementId,
        allShownButtonsIds,
        prevPageIds,
        completed: false,
        answersScoring: updatedAnswersScoring,
        progressPercentage: 0,
        rulesProductsProperties: [],
        rulesAnswersScore: [],
        rulesAnswersSavings: [],
        clickedButtonsIds: [],
        hideProducts: [],
      });
      setShowChapterIds(chapters?.filter(item => !item.content.completedChaptersButtons).map(chap => chap.id));
      setLoading(false);
    }
  }, [currentQuestionnaireStructure, rules, selectedQuestionnaireElement]);

  if (loading) {
    return <AppPreloader style={{ height: '100vh' }} />;
  }

  const {
    header,
    footer,
    media,
    bulletIcons,
    header: {
      subLine: audioGuideSubLine,
      headline: audiGuideHeadline,
      primaryButton: audiGuidePrimaryButton,
      secondaryButton: audioGuideSecondaryButton,
    } = {},
  } = finderSettings;

  appLogger.info('PreviewPage:', { pagesFlow });

  return (
    <Root className={styles.container} style={{ backgroundColor: colors.backgroundColor }}>
      <PreviewAudioGuideModal
        subLine={audioGuideSubLine}
        headline={audiGuideHeadline}
        primaryButton={audiGuidePrimaryButton}
        secondaryButton={audioGuideSecondaryButton}
        close={onCloseAudioGuide}
        isOpen={audioGuideModal && header?.isAutoGuide}
        fonts={fonts}
        colors={colors}
        setAudioGuideModal={setAudioGuideModal}
        setIsAudioGuidePlaying={setIsAudioGuidePlaying}
      />
      <PreviewHeader
        isAudioGuidePlaying={isAudioGuidePlaying}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        setAudioGuideModal={setAudioGuideModal}
        backgroundColor={colors[header?.backgroundColor]}
        header={header}
        pagesFlow={pagesFlow}
        footer={footer}
        colors={colors}
        textLinkStyles={{
          ...fonts.textLink,
          color: colors[fonts.textLink.colorVariant],
        }}
        setPagesFlow={setPagesFlow}
      />
      <NewProgressBar pagesFlow={pagesFlow} footer={footer} colors={colors} showChapterIds={showChapterIds} fonts={fonts} />
      <PreviewLayoutTemplates
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        colors={colors}
        fonts={fonts}
        media={media}
        bulletIcons={bulletIcons}
        fontsForLoading={fontsForLoading}
        showChapterIds={showChapterIds}
        setShowChapterIds={setShowChapterIds}
      />
      <PreviewAudioPlayer
        pauseAudio={pauseAudio}
        isAudioGuidePlaying={isAudioGuidePlaying}
        pagesFlow={pagesFlow}
        setPagesFlow={setPagesFlow}
        audioPlayer={audioGuideRef}
        setIsAudioGuidePlaying={setIsAudioGuidePlaying}
      />
      <PreviewFooter
        pagesFlow={pagesFlow}
        footer={footer}
        colors={colors}
        textLinkStyles={{
          ...fonts.textLink,
          color: colors[fonts.textLink.colorVariant],
        }}
        setPagesFlow={setPagesFlow}
      />
    </Root>
  );
}

const Root = styled('div')({
  '&, div, span, p, h1, h2, h3, h4, h5, h6': {
    wordBreak: 'break-word',
  },
});
