import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ResultType } from '../../../../../../../../../../../lib/models/Questionnaire';
import classNames from 'classnames';
import { AppList } from '../../../../../../../../../../../ui/AppList';
import { ContactFormRowFieldOption } from './ContactFormRowFieldOption';

const styles = { padding: '24px 0 0 24px', gap: '24px', width: '100%' };

export function ContactFormRowFieldOptionList(props) {
  const { rowIndex, fieldIndex, structureItemIndex, field } = props;

  return (
    <Droppable
      type={ResultType.CONTACT_FORM_ROW_FIELD_OPTION}
      droppableId={JSON.stringify({
        structureItemIndex,
        rowIndex,
        fieldIndex,
        type: ResultType.CONTACT_FORM_ROW_FIELD_OPTION,
      })}
    >
      {provided => (
        <AppList
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ ...provided.droppableProps.style, ...styles }}
          className={classNames('flex flex-col', provided.droppableProps.className)}
        >
          {field.optionList.map((item, optionIndex) => {
            return <ContactFormRowFieldOption key={item.id} {...props} optionIndex={optionIndex} field={field} id={item.id} />;
          })}
          {provided.placeholder}
        </AppList>
      )}
    </Droppable>
  );
}
