import { translateOptionsMapper } from '../../../../../../../../../../../lib/util/helpers';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { ContactFormField } from '../../../../../../../../../../../lib/models/Questionnaire';

export function useGetFieldOptions() {
  const { fieldOptions } = useLocalization().translations.unique.pages.questionnaire.content;

  return [
    { value: ContactFormField.TEXT, title: fieldOptions.text },
    { value: ContactFormField.EMAIL, title: fieldOptions.email },
    { value: ContactFormField.PHONE_NUMBER, title: fieldOptions.phoneNumber },
    { value: ContactFormField.DATE, title: fieldOptions.date },
    { value: ContactFormField.TIME, title: fieldOptions.time },
    { value: ContactFormField.RADIO, title: fieldOptions.radio },
    { value: ContactFormField.RADIO_WITH_ACTION, title: fieldOptions.radioWithAction },
    { value: ContactFormField.CHECKBOX, title: fieldOptions.checkbox },
    { value: ContactFormField.DROPDOWN, title: fieldOptions.dropdown },
    { value: ContactFormField.DROPDOWN_WITH_ACTION, title: fieldOptions.dropdownWithAction },
  ].map(translateOptionsMapper);
}
