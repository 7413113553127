import { v4 as uuidv4 } from 'uuid';
import { Typography } from 'antd';
import { getBackgroundImageStyle, getBackgroundUrl } from '../../../../../../../../../lib/util/helpers';
import { PreviewContentBlockItem } from './PreviewContentBlockItem';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import styles from './styles.module.scss';
import { InfoIconComponent } from '../../InfoIconComponent';

const isVerticalContentBlock = {
  'Content two block': false,
  Text: false,
  'One Content Block': true,
};

export function PreviewContentBlocks({ media, pagesFlow, contentElement, colors, fonts, style, bulletIcons }) {
  const { content: { headerHeadline, contentBlocks } = {}, label } = contentElement || {};

  const { applyVariable } = useVariables(pagesFlow);

  const { rootStyle = {}, headerHeadlineStyle = {} } = style;

  const backgroundImageUrl = getBackgroundUrl(contentBlocks[0].selectBackgroundImage, media);

  return (
    <div
      id={styles.previewContentBlock}
      className={styles.container}
      style={{
        ...getBackgroundImageStyle(rootStyle.backGround, backgroundImageUrl),
        ...rootStyle,
      }}
    >
      {headerHeadline?.value && (
        <Typography.Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            textAlign: 'center',
            fontSize: fonts.headlineSecond.fontSize,
            fontFamily: fonts.headlineSecond.font,
            fontWeight: fonts.headlineSecond.fontWeight,
            color: colors[fonts.headlineSecond.colorVariant],
            ...headerHeadlineStyle,
            margin: '0 0px 24px 0px',
          }}
        >
          {applyVariable(headerHeadline.value)}
          <InfoIconComponent applyVariable={applyVariable} type={headerHeadline} />
        </Typography.Paragraph>
      )}
      <div className={styles.content_blocks} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
        {contentBlocks.map(block => (
          <div
            key={uuidv4()}
            className={styles.content_block}
            style={{
              width: `calc((100% - 16px*(${contentBlocks?.length} - 1))/${contentBlocks?.length}`,
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 20,
            }}
          >
            <PreviewContentBlockItem
              isVerticalContentBlock={isVerticalContentBlock[label]}
              contentBlock={block}
              pagesFlow={pagesFlow}
              applyVariable={applyVariable}
              blocksLength={contentBlocks?.length}
              style={style}
              fonts={fonts}
              colors={colors}
              media={media}
              bulletIcons={bulletIcons}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
