import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { FontColorTag } from '../FontColorTag';
import { ColorVariants } from '../../../../lib/models/DefaultStylesFontsAndColors';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function FontColorFormItem({ colors, additionalColors = [], value, onChange, fieldName, field, disabled, width = 226, size, ...rest }) {
  const { colorVariants } = useLocalization().translations.shared;
  const { formItemProps = {}, selectProps, ...restRootProps } = rest;
  const { style, ...restFormItemProps } = formItemProps;

  const genericFontColorTagProps = { size, disabled };

  const additionalOptions = additionalColors.map(color => ({
    value: color.value,
    title: <FontColorTag text={color.title} color={color.color} {...genericFontColorTagProps} />,
  }));

  const fontColorOptions = [
    ...additionalOptions,
    {
      value: ColorVariants.PRIMARY_COLOR,
      title: <FontColorTag text={colorVariants.primary} color={colors.primaryColor} {...genericFontColorTagProps} />,
    },
    {
      value: ColorVariants.SECONDARY_FIRST,
      title: <FontColorTag text={colorVariants.secondary1} color={colors.secondaryFirst} {...genericFontColorTagProps} />,
    },
    {
      value: ColorVariants.SECONDARY_SECOND,
      title: <FontColorTag text={colorVariants.secondary2} color={colors.secondarySecond} {...genericFontColorTagProps} />,
    },
    {
      value: ColorVariants.BACKGROUND_COLOR,
      title: <FontColorTag text={colorVariants.background} color={colors.backgroundColor} {...genericFontColorTagProps} />,
    },
  ];

  return (
    <AppFormSection
      formItemProps={{
        style: { width, minHeight: 40, ...style },
        ...restFormItemProps,
      }}
      {...restRootProps}
    >
      <AppNewSelect
        disabled={disabled}
        onChange={selected => onChange(selected?.value, fieldName, field)}
        options={fontColorOptions}
        value={{ value }}
        {...selectProps}
      />
    </AppFormSection>
  );
}
