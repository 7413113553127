export function LeftPointerIcon(props) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_2712_176646)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 12.0009C18 12.1999 17.921 12.3906 17.7803 12.5313C17.6397 12.6719 17.4489 12.7509 17.25 12.7509L8.5605 12.7509L11.781 15.9699C11.8507 16.0397 11.906 16.1225 11.9438 16.2136C11.9815 16.3047 12.0009 16.4023 12.0009 16.5009C12.0009 16.5996 11.9815 16.6972 11.9438 16.7883C11.906 16.8794 11.8507 16.9622 11.781 17.0319C11.7113 17.1017 11.6285 17.157 11.5374 17.1947C11.4463 17.2325 11.3486 17.2519 11.25 17.2519C11.1514 17.2519 11.0537 17.2325 10.9626 17.1947C10.8715 17.157 10.7887 17.1017 10.719 17.0319L6.219 12.5319C6.14915 12.4623 6.09374 12.3795 6.05593 12.2884C6.01812 12.1973 5.99866 12.0996 5.99866 12.0009C5.99866 11.9023 6.01812 11.8046 6.05593 11.7135C6.09374 11.6224 6.14915 11.5396 6.219 11.4699L10.719 6.96995C10.7887 6.90022 10.8715 6.8449 10.9626 6.80716C11.0537 6.76942 11.1514 6.75 11.25 6.75C11.3486 6.75 11.4463 6.76942 11.5374 6.80716C11.6285 6.8449 11.7113 6.90022 11.781 6.96995C11.9218 7.11078 12.0009 7.30178 12.0009 7.50095C12.0009 7.59956 11.9815 7.69721 11.9438 7.78832C11.906 7.87943 11.8507 7.96222 11.781 8.03195L8.5605 11.2509L17.25 11.2509C17.4489 11.2509 17.6397 11.33 17.7803 11.4706C17.921 11.6113 18 11.802 18 12.0009Z'
          fill='white'
          stroke='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2712_176646'>
          <rect width='24' height='24' fill='white' transform='translate(24) rotate(90)' />
        </clipPath>
      </defs>
    </svg>
  );
}
