import { Switch } from 'antd';
import { AppText } from '../../../../../../../ui/text/AppText';
import { useSelector } from 'react-redux';
import { AppBox } from '../../../../../../../ui/boxes/AppBox';

const appTextStyle = { marginLeft: '8px' };

export function QuestionnaireSwitch({ onChange, isOn, labelProps = {}, disabled, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { style, ...restLabelProps } = labelProps;
  const isDisabled = isViewMode || disabled;

  return (
    <AppBox className='align_items_center' disabled={isDisabled} {...rest}>
      <Switch checked={isOn} onChange={onChange} size='small' disabled={isDisabled} />
      <AppText style={{ ...appTextStyle, ...style }} {...restLabelProps} />
    </AppBox>
  );
}
