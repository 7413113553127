/* eslint-disable no-magic-numbers,max-lines,unicorn/prefer-set-has */
import { v4 as uuidv4 } from 'uuid';
import { ChapterState, ConditionTypes, TemplateTypes } from '../../../../../lib/models/TemplateTypes';
import { isLength, removeProductsDuplicates } from '../../../../../lib/util/helpers';
import { ProductKind } from '../../../../../lib/models/Questionnaire';

const getElementRules = (element, finderRules) => {
  const activeFinderRules = finderRules.filter(rule => rule.activated);
  return isLength(element?.rules)
    ? element.rules.map(rule => activeFinderRules.find(finderRule => finderRule.ruleId === rule.ruleId)).filter(Boolean)
    : [];
};

const addRulesToContent = (element, finderRules, elementRules) => {
  const { content: elementContent, templateType } = element;
  const updatedContent = { ...elementContent };

  if (elementContent.primaryButton) {
    updatedContent.primaryButton = { ...elementContent.primaryButton, rules: getElementRules(elementContent.primaryButton, finderRules) };
  }
  if (elementContent.secondaryButton) {
    updatedContent.secondaryButton = { ...elementContent.secondaryButton, rules: getElementRules(elementContent.secondaryButton, finderRules) };
  }
  if (elementContent.composerButton) {
    updatedContent.composerButton = { ...elementContent.composerButton, rules: getElementRules(elementContent.composerButton, finderRules) };
  }
  if (Array.isArray(elementContent.buttons)) {
    updatedContent.buttons = elementContent.buttons.map(button => ({ ...button, rules: getElementRules(button, finderRules) }));
  }
  if (Array.isArray(elementContent.products)) {
    if (templateType === TemplateTypes.PRODUCT) {
      updatedContent.products = elementContent.products.map(product => ({ ...product, rules: elementRules }));
    }
    if (templateType === TemplateTypes.RESULT) {
      updatedContent.products = elementContent.products.map(product => ({
        ...product,
        buttonsSection: {
          ...product.buttonsSection,
          buttons: product.buttonsSection.buttons.map(button => ({ ...button, rules: getElementRules(button, finderRules) })),
        },
      }));
    }
  }

  return updatedContent;
};

export const getPages = (finderStructure, finderRules) => {
  return finderStructure
    ? JSON.parse(finderStructure).reduce((accumulator, item) => {
        if (item.templateType === TemplateTypes.PAGE) {
          accumulator.push({ ...item, elements: [] });
        } else if (accumulator.length > 0) {
          const elementRules = getElementRules(item, finderRules);
          accumulator.at(-1).elements.push({ ...item, rules: elementRules, content: addRulesToContent(item, finderRules, elementRules) });
        }
        return accumulator;
      }, [])
    : [];
};

const getChapterInnerPagesIds = slicedArr => slicedArr.map(page => page.id).filter((page, pageIndex) => pageIndex !== 0);

const getChapterPages = (chapterArr, pageArr, chapterId, ind) => {
  const start = pageArr.findIndex(page => page.elements.find(el => el.id === chapterId));
  const end = pageArr.findIndex(page => page.elements.find(el => el.id === chapterArr[ind + 1]?.id));
  return end > 0
    ? { chapterPages: pageArr.slice(start, end), chapterInnerPagesIds: getChapterInnerPagesIds(pageArr.slice(start, end)) }
    : { chapterPages: pageArr.slice(start), chapterInnerPagesIds: getChapterInnerPagesIds(pageArr.slice(start)) };
};

const getChapterConditionValue = (conditionObject, allChapters) => {
  if (!isLength(conditionObject.states) && !isLength(conditionObject.chapters)) {
    return { value: true, condition: conditionObject.condition };
  }
  if (!isLength(conditionObject.states) || !isLength(conditionObject.chapters)) {
    return { value: false, condition: conditionObject.condition };
  }
  const filteredChapters = allChapters.filter(chap => conditionObject.chapters.includes(chap.id));
  const checkedValue = filteredChapters.every(chapter => conditionObject.states.includes(chapter.state));
  return { value: checkedValue, condition: conditionObject.condition };
};

const getChapterConditionsLogic = conditions => {
  return conditions.reduce((accumulator, conditionObject) => {
    if (conditionObject.condition === ConditionTypes.AND) {
      return accumulator && conditionObject.value;
    }
    if (conditionObject.condition === ConditionTypes.OR) {
      return accumulator || conditionObject.value;
    }
    return conditionObject.value;
  }, null);
};

const getChapterState = (chapterFlow, chapterArray) => {
  const chapterConditions = chapterFlow.conditions.map(cond => getChapterConditionValue(cond, chapterArray));
  const checkOpenLogic = getChapterConditionsLogic(chapterConditions);
  return checkOpenLogic ? ChapterState.OPENED : ChapterState.NOT_OPENED;
};

export const updateChaptersState = (isNextChapter, chapters, currentChapterId) => {
  const updatedChapters = isNextChapter
    ? chapters.map(chapter => (chapter.id === currentChapterId ? { ...chapter, state: ChapterState.COMPLETED } : chapter))
    : chapters;
  return updatedChapters.map(chap => ({
    ...chap,
    state: chap.state === ChapterState.NOT_OPENED ? getChapterState(chap.flow, updatedChapters) : chap.state,
  }));
};

export const getChapters = (finderStructure, pages, finderRules) => {
  const pagesWithoutResult = pages.filter(page => !page.elements.some(el => el.templateType === TemplateTypes.RESULT));

  return JSON.parse(finderStructure)
    .filter(el => el.templateType === TemplateTypes.CHAPTER)
    .map((chapter, index, chapterArr) => ({
      ...chapter,
      ...getChapterPages(chapterArr, pagesWithoutResult, chapter.id, index),
      state: getChapterState(chapter.flow, chapterArr),
      resultSelectedProducts: [],
      rulesResultSelectedProducts: [],
      rules: getElementRules(chapter, finderRules),
      content: {
        ...chapter.content,
        chapterButton: { ...chapter.content.chapterButton, rules: getElementRules(chapter.content.chapterButton, finderRules) },
      },
    }));
};

export const updateChapterRulesResultProducts = (oldSelectedProducts, newSelectedProducts, unselectedProdIds) => {
  const filteredWithoutUnselectedProducts = oldSelectedProducts.filter(prod => !unselectedProdIds.includes(prod?.id));
  return [...filteredWithoutUnselectedProducts, ...newSelectedProducts];
};

export const updateChapterResultProducts = (oldSelectedProducts, newSelectedProducts, unselectedProdIds) => {
  const filteredWithoutUnselectedProducts = oldSelectedProducts.filter(prod => !unselectedProdIds.includes(prod?.id));
  const filteredOldProductsDuplicates = filteredWithoutUnselectedProducts.filter(
    prod => !newSelectedProducts.map(newProd => newProd?.id).includes(prod?.id)
  );
  return [...filteredOldProductsDuplicates, ...newSelectedProducts];
};

export const rewriteChapterSelectedProducts = (chapters, currentChapterId, newSelectedProducts, unselectedProdIds = []) => {
  if (!currentChapterId) {
    return chapters;
  }

  const modifiedNewSelectedProducts = newSelectedProducts.filter(prod => {
    if (prod?.productNameSection?.product?.title === 'Keine girocard') {
      return true;
    }
    return Boolean(
      (prod?.counterSection && prod?.counterSection?.value > 0) ||
        (prod?.counterSection?.ruleCounter && prod?.counterSection?.ruleCounter > 0) ||
        prod?.counterSection?.showCounter === false
    );
  });

  return chapters.map(chapter => {
    return chapter.id === currentChapterId
      ? {
          ...chapter,
          resultSelectedProducts: updateChapterResultProducts(chapter.resultSelectedProducts, modifiedNewSelectedProducts, unselectedProdIds),
        }
      : chapter;
  });
};

export const rewriteHamburgPKChapterSelectedProducts = ({
  P2,
  P3,
  allChapters,
  currentChapterId,
  newSelectedProducts,
  unselectedProductIds = [],
}) => {
  const updatedChapters = rewriteChapterSelectedProducts(allChapters, currentChapterId, newSelectedProducts, unselectedProductIds);
  const oldResultProds = allChapters[0].resultSelectedProducts;
  const getNewResultProds = () => {
    if (P2?.content?.products[1].selected) {
      return [{ ...P2?.content?.products[0], selected: true }];
    }
    if (P3?.content?.products[1].selected) {
      return [{ ...P3?.content?.products[0], selected: true }];
    }
    return oldResultProds;
  };

  return updatedChapters.map((chap, index) => (index === 0 ? { ...chap, resultSelectedProducts: getNewResultProds() } : chap));
};

export const rewriteHamburgPKAllPages = (allPages, P2, P3) => {
  const HARDCODE_PAGE_ID = allPages.find(page => page.elements.find(el => el.id === P2?.id))?.id;
  const updatedP2Prods = P2?.content?.products[1].selected
    ? [
        { ...P2?.content?.products[0], selected: true },
        { ...P2?.content?.products[1], selected: false },
      ]
    : P2?.content?.products;
  const updatedP3Prods = P3?.content?.products[1].selected
    ? [
        { ...P3?.content?.products[0], selected: true },
        { ...P3?.content?.products[1], selected: false },
      ]
    : P3?.content?.products;

  return allPages.map(page =>
    page.id === HARDCODE_PAGE_ID
      ? {
          ...page,
          elements: page.elements.map(el => {
            if (el.id === P2.id) {
              return { ...P2, content: { ...P2.content, products: updatedP2Prods } };
            }
            if (el.id === P3.id) {
              return { ...P3, content: { ...P3.content, products: updatedP3Prods } };
            }
            return el;
          }),
        }
      : page
  );
};

// cleaning holz products card counters
export const rewriteHamburgPKHolzPages = (allPages, allChapters, P10, P12) => {
  const P10_PAGE_ID = allPages.find(page => page.elements.find(el => el.id === P10?.id))?.id;
  const P12_PAGE_ID = allPages.find(page => page.elements.find(el => el.id === P12?.id))?.id;
  const updatedP10Prods = [{ ...P10?.content?.products[0], counterSection: { value: 0 } }, P10?.content?.products[1]];
  const updatedP12Prods = [{ ...P12?.content?.products[0], counterSection: { value: 0 } }, P12?.content?.products[1]];

  const updatedPages = allPages.map(page => {
    if (page.id === P10_PAGE_ID) {
      return {
        ...page,
        elements: page.elements.map(el => {
          if (el.id === P10.id) {
            return { ...P10, content: { ...P10.content, products: updatedP10Prods } };
          }
          return el;
        }),
      };
    }
    if (page.id === P12_PAGE_ID) {
      return {
        ...page,
        elements: page.elements.map(el => {
          if (el.id === P12.id) {
            return { ...P12, content: { ...P12.content, products: updatedP12Prods } };
          }
          return el;
        }),
      };
    }
    return page;
  });

  const updatedChapters = allChapters.map((chapter, index) =>
    index === 3
      ? {
          ...chapter,
          resultSelectedProducts: chapter.resultSelectedProducts.filter(
            prod => ![P10?.content?.products[0]?.id, P12?.content?.products[0]?.id].includes(prod.id)
          ),
        }
      : chapter
  );

  return { updatedChapters, updatedPages };
};

export const createChapterPage = (pagesArr, chaptersArr) => {
  if (isLength(chaptersArr)) {
    const firstChapterIndex = pagesArr.findIndex(page => page.elements.find(el => el.templateType === TemplateTypes.CHAPTER));
    const copyPagesArr = [...pagesArr];
    const pagesWithoutChapters = copyPagesArr
      .filter(page => page.elements.some(el => el.templateType !== TemplateTypes.CHAPTER))
      .map(page => ({
        ...page,
        elements: page.elements.map(el =>
          el.templateType === TemplateTypes.QUESTION && Array.isArray(el.content.answers) ? { ...el, selectedAnswers: [] } : el
        ),
      }));

    pagesWithoutChapters.splice(firstChapterIndex, 0, { id: uuidv4(), templateType: TemplateTypes.CHAPTER, elements: chaptersArr });
    return pagesWithoutChapters;
  }

  return pagesArr.map(page => ({
    ...page,
    elements: page.elements.map(el =>
      el.templateType === TemplateTypes.QUESTION && Array.isArray(el.content.answers) ? { ...el, selectedAnswers: [] } : el
    ),
  }));
};

const getSingleChoiceConditionValue = (conditionObject, selectedAnswers) => {
  if (!isLength(conditionObject.ifAnswers) && !isLength(conditionObject.elseIfAnswers)) {
    return { value: false, condition: conditionObject.condition ? ConditionTypes.OR : conditionObject.condition };
  }
  const ifAnswersValue = conditionObject.ifAnswers.some(answer => selectedAnswers.includes(answer))
    ? { goTo: conditionObject.ifGoTo, showElements: conditionObject?.ifShowElements }
    : false;
  const elseIfAnswersValue = conditionObject.elseIfAnswers.some(answer => selectedAnswers.includes(answer))
    ? { goTo: conditionObject.elseIfGoTo, showElements: conditionObject?.elseIfShowElements }
    : false;
  const conditionValue = ifAnswersValue || elseIfAnswersValue;
  return { value: conditionValue, condition: conditionObject.condition ? ConditionTypes.OR : conditionObject.condition };
};

const getMultiChoiceConditionValue = (conditionObject, selectedAnswers) => {
  if (!isLength(conditionObject.ifAnswers) && !isLength(conditionObject.elseIfAnswers)) {
    return { value: false, condition: conditionObject.condition };
  }
  const ifAnswersValue =
    conditionObject.ifAnswers.length === selectedAnswers.length && conditionObject.ifAnswers.every(answer => selectedAnswers.includes(answer))
      ? { goTo: conditionObject.ifGoTo, showElements: conditionObject?.ifShowElements }
      : false;
  const elseIfAnswersValue =
    conditionObject.elseIfAnswers.length === selectedAnswers.length && conditionObject.elseIfAnswers.every(answer => selectedAnswers.includes(answer))
      ? { goTo: conditionObject.elseIfGoTo, showElements: conditionObject?.elseIfShowElements }
      : false;
  const conditionValue = ifAnswersValue || elseIfAnswersValue;
  return { value: conditionValue, condition: conditionObject.condition };
};

const updateQuestionFlowConditions = (conditions, multipleChoice, selectedAnswers) =>
  conditions?.map(cond =>
    multipleChoice ? getMultiChoiceConditionValue(cond, selectedAnswers) : getSingleChoiceConditionValue(cond, selectedAnswers)
  );

export const getQuestionConditionsFlow = (questionFlow, multipleChoice, selectedAnswers) => {
  const { conditions, elseGoTo, elseShowElements } = questionFlow;
  const updatedConditions = updateQuestionFlowConditions(conditions, multipleChoice, selectedAnswers);

  const conditionFlow = updatedConditions?.reduce((accumulator, conditionObject) => {
    if (conditionObject.condition === ConditionTypes.AND) {
      return accumulator && conditionObject.value;
    }
    if (conditionObject.condition === ConditionTypes.OR) {
      return accumulator || conditionObject.value;
    }
    return conditionObject.value;
  }, null);

  return conditionFlow || { goTo: elseGoTo, showElements: elseShowElements };
};

const updateCounterAnswersFlowArr = (counterAnswersFlowArr, counterAnswers) => {
  return counterAnswersFlowArr?.map(countObj => {
    const countAnswerAmount = counterAnswers.find(answer => answer.id === countObj.counterAnswerId)?.amount;
    return { ...countObj, amount: countAnswerAmount };
  });
};

const getCurrentCounterAnswerFlow = flowObj => {
  if (!flowObj) {
    return null;
  }
  if (flowObj && !isLength(flowObj.counterAnswersRanges)) {
    return null;
  }
  if (flowObj && isLength(flowObj.counterAnswersRanges)) {
    const rangeObject = flowObj.counterAnswersRanges.find(range => flowObj?.amount >= range.minValue && flowObj?.amount <= range.maxValue);
    return { goTo: rangeObject?.thenGoTo, showElements: rangeObject?.thenGoToShowElements };
  }
  return { goTo: flowObj?.thenGoTo, showElements: flowObj?.thenGoToShowElements };
};

export const getQuestionCounterRangeFlow = (questionFlow, counterAnswers, actionCounterId) => {
  const { counterAnswersFlowArr, counterAnswersElseGoTo, counterAnswersElseShowElements } = questionFlow;
  const updatedCounterAnswersFlowArr = updateCounterAnswersFlowArr(counterAnswersFlowArr, counterAnswers);
  const defaultCounterId = counterAnswers.find(answer => answer.lastModified)?.id || updatedCounterAnswersFlowArr[0]?.counterAnswerId;
  const flowCounterId = actionCounterId || defaultCounterId;
  const currentCountAnswerFlowObject = updatedCounterAnswersFlowArr?.find(countObj => countObj.counterAnswerId === flowCounterId);
  const rangeFlow = getCurrentCounterAnswerFlow(currentCountAnswerFlowObject);
  return rangeFlow?.goTo ? rangeFlow : { goTo: counterAnswersElseGoTo, showElements: counterAnswersElseShowElements };
};

export const getCurrentPageNextElementId = (flowResult, currentPageElements) =>
  currentPageElements
    .filter(
      el => el.templateType === TemplateTypes.PRODUCT || el.templateType === TemplateTypes.QUESTION || el.templateType === TemplateTypes.CONTENT
    )
    .find(el => el.id === flowResult)?.id;

const getScoringArr = scoringArr => {
  return [...new Set(scoringArr.map(item => item.id))].map(id => {
    const idArr = scoringArr.filter(el => el.id === id);
    const scoreResult = idArr.reduce((accumulator, next) => accumulator + next.score, 0);
    const prodPrice = scoringArr.find(el => el.id === id && el.price)?.price;
    return { id, score: scoreResult, price: prodPrice };
  });
};

const getScoreRange = (ranges, amount) => {
  return ranges?.some(range => range.maxValue >= amount && amount >= range.minValue)
    ? ranges?.find(range => range.maxValue >= amount && amount >= range.minValue).products.map(item => ({ id: item.prodId, score: item.score }))
    : [];
};

const getChartProductResult = (chartProduct, chartAnswers) => {
  const totalCosts = chartProduct.prices.reduce((accumulator, price) => {
    return accumulator + price.value * chartAnswers.find(chartAnswer => chartAnswer.id === price.priceId).amount;
  }, chartProduct.price);
  return {
    cost: Math.round(totalCosts * 100) / 100,
    id: chartProduct.value,
    productName: chartProduct.productName,
    price: chartProduct.price,
  };
};

const getChartProductsScore = (chartProducts, chartAnswers, scoring) => {
  const filteredChartProds = chartProducts.filter(prod => prod.id && prod.productName);
  return filteredChartProds
    .map(chartProd => getChartProductResult(chartProd, chartAnswers))
    .sort((current, next) => current.cost - next.cost)
    .map((sortObj, index) => ({ ...sortObj, score: scoring[index] ? scoring[index].value : 0 }))
    .map((sortItem, index, sortArr) => ({
      ...sortItem,
      score:
        index !== 0 && sortItem.cost === sortArr[index - 1].cost ? sortArr.find(scoreObj => scoreObj.cost === sortItem.cost).score : sortItem.score,
    }));
};

export const priceScoringSort = (curr, next) => {
  const currScore = curr.score;
  const nextScore = next.score;
  const currPrice = curr.price;
  const nextPrice = next.price;

  if (currScore === nextScore) {
    // eslint-disable-next-line no-nested-ternary
    return currPrice > nextPrice ? -1 : currPrice < nextPrice ? 1 : 0;
  }
  return currScore > nextScore ? -1 : 1;
};

const getScoringResult = (scoringAnswers, pagesFlow, isChart) => {
  const { allPages, rulesAnswersScore } = pagesFlow;

  const scoringAnswersArray = Object.values(scoringAnswers)
    .map(item =>
      item.selectedAnswers.map(answer => {
        const scoreAnswer = rulesAnswersScore.find(answ => answ.id === answer.id) || answer;
        return scoreAnswer?.products;
      })
    )
    .flat(2);

  const allElementsContent = allPages.flatMap(page => page.elements.map(el => el.content));

  const allCounterAnswers = allElementsContent.filter(content => Array.isArray(content.counterAnswers));
  const scoringCounterAnswersArrays = allCounterAnswers.map(answ => answ.counterAnswers.map(item => getScoreRange(answ.scoringRanges, item.amount)));
  const scoringCounterAnswersArray = scoringCounterAnswersArrays.flat(2);

  const allChartAnswers = allElementsContent.filter(content => Array.isArray(content.chartAnswers));
  const chartProductsResults = allChartAnswers.map(el => getChartProductsScore(el.productValues, el.chartAnswers, el.scoring));
  const scoringChartAnswersArray = chartProductsResults.flat();

  const scoringArrays = isChart ? scoringChartAnswersArray : [...scoringAnswersArray, ...scoringCounterAnswersArray];
  const allScoringResult = getScoringArr(scoringArrays.filter(item => item?.id));

  return isChart ? allScoringResult.sort((cur, next) => priceScoringSort(cur, next)) : allScoringResult.sort((cur, next) => next.score - cur.score);
};

const filterScoringResult = (scoringResult, productList) =>
  scoringResult.filter(score => productList.some(card => card.productNameSection.product.value === score.id));

export function getFilteredScoringResult(answersScoring, pagesFlow, isChart, products) {
  const scoringResult = getScoringResult(answersScoring, pagesFlow, isChart);
  return filterScoringResult(scoringResult, products);
}

export function getRulesScoringResult(products, scoringResult, rulesProductsProperties, isChart) {
  const updatedScoreProducts = products
    .map(prod => {
      const scoreObject = scoringResult.find(score => prod.productNameSection.product.value === score.id);
      const originalScore = scoreObject?.score ?? 0;
      const ruleObject = rulesProductsProperties.find(ruleProd => ruleProd.id === prod.id);
      return {
        id: prod.productNameSection.product.value,
        score: ruleObject?.ruleScore ?? originalScore,
        ruleScore: ruleObject?.ruleScore,
        price: scoreObject?.price,
        hvmProductId: prod.hvmProductId,
      };
    })
    .sort((current, next) => next.score - current.score);

  return isChart
    ? updatedScoreProducts.sort((cur, next) => priceScoringSort(cur, next))
    : updatedScoreProducts.sort((cur, next) => next.score - cur.score);
}

export const getBestProducts = (resultSelectedProducts, pagesFlow, answersScoring) => {
  const scoringResult = getScoringResult(answersScoring, pagesFlow);

  const filteredProducts = removeProductsDuplicates(resultSelectedProducts);

  return filteredProducts
    .map(prod => ({
      ...prod,
      score: scoringResult?.find(scoreObj => scoreObj.id === prod.productNameSection.product.value)?.score,
    }))
    .sort((current, next) => next.score - current.score);
};

export const createPrevPageIdsIfNeeded = (chapters, pages, selectedElement, currentChapterId, currentPageId) => {
  if (!selectedElement || selectedElement.templateType === TemplateTypes.CHAPTER) {
    return [];
  }

  const chaptersPageId = pages.find(page => page.templateType === TemplateTypes.CHAPTER)?.id;
  const currentChapter = chapters.find(chapter => chapter.id === currentChapterId);
  const indexOfCurrentPage = currentChapter?.chapterInnerPagesIds.indexOf(currentPageId);

  const prevPages = currentChapter?.chapterInnerPagesIds.slice(0, indexOfCurrentPage);

  if (!prevPages) {
    return [];
  }

  return [chaptersPageId, ...prevPages];
};

export const getCurrentChapterIdIfNeeded = (chapters, selectedElement) => {
  if (!selectedElement) {
    return chapters[0]?.id;
  }

  const selectedElementIsChapter = selectedElement.templateType === TemplateTypes.CHAPTER;

  if (selectedElementIsChapter) {
    return selectedElement.id;
  }

  const currentChapter = chapters.find(chapter =>
    chapter.chapterPages.find(page => page.elements.find(element => element.id === selectedElement.id))
  );

  return currentChapter?.id || chapters[0]?.id;
};

export const getCurrentPageId = (pages, selectedElement) => {
  if (!selectedElement) {
    return pages[0].id;
  }

  return pages.find(page => page.elements.find(element => element.id === selectedElement.id))?.id;
};

export const getCurrentFlowElement = (pages, selectedElement, finderRules) => {
  if (isLength(pages[0]?.elements) && !selectedElement) {
    return pages[0]?.elements[0];
  }

  return { ...selectedElement, rules: getElementRules(selectedElement, finderRules) } || null;
};

export const getCurrentElementsIds = (pages, selectedElement, currentPageId) => {
  if (isLength(pages[0]?.elements) && !selectedElement) {
    return [pages[0]?.elements[0]?.id];
  }

  const currentPage = pages.find(page => page.id === currentPageId);
  const currentElementsExceptSelected = currentPage?.elements.filter(element => element.id !== selectedElement.id);

  const elementIdsToShowIfNeeded = currentElementsExceptSelected?.find(item => {
    return (
      item.flow.goTo === selectedElement.id ||
      item.flow.elseGoTo === selectedElement.id ||
      item.flow?.conditions?.find(condition => condition.ifGoTo === selectedElement.id || condition.elseIfGoTo === selectedElement.id)
    );
  });

  return selectedElement ? elementIdsToShowIfNeeded?.id : null;
};

export const getQuestionAnswerIdsDependsOnFlow = (currentChapterId, currentPageId, chapters, selectedQuestionnaireElement) => {
  if (!selectedQuestionnaireElement) {
    return [];
  }

  const currentChapter = chapters.find(chapter => chapter.id === currentChapterId);
  const currentPage = currentChapter?.chapterPages.find(page => page.id === currentPageId);
  const currentQuestion = currentPage?.elements.find(
    element => element.templateType === TemplateTypes.QUESTION && Array.isArray(element.content?.answers)
  );

  const currentCondition = currentQuestion?.flow?.conditions?.find(
    condition => condition.ifGoTo === selectedQuestionnaireElement.id || condition.elseIfGoTo === selectedQuestionnaireElement.id
  );

  return currentCondition?.ifGoTo === selectedQuestionnaireElement.id ? currentCondition.ifAnswers : currentCondition?.elseIfAnswers;
};

const getCurrentQuestion = (chapters, currentChapterId, currentPageId) => {
  const currentChapter = chapters.find(chapter => chapter.id === currentChapterId);
  const currentPage = currentChapter?.chapterPages.find(page => page.id === currentPageId);
  return currentPage?.elements.find(element => element.templateType === TemplateTypes.QUESTION && Array.isArray(element.content?.answers));
};

export const updatePagesAndSelectAnswers = (pages, currentPageId, selectedQuestionnaireElement, selectedAnswersIds, chapters, currentChapterId) => {
  if (!selectedQuestionnaireElement || selectedQuestionnaireElement.templateType === TemplateTypes.QUESTION || !selectedAnswersIds) {
    return pages;
  }

  const currentQuestion = getCurrentQuestion(chapters, currentChapterId, currentPageId);

  if (!currentQuestion) {
    return pages;
  }

  const updatedSelectedAnswers = currentQuestion?.content.multipleChoice ? selectedAnswersIds : [selectedAnswersIds[0]];

  const updatedElements = elements =>
    elements.map(element => (element.id === currentQuestion.id ? { ...element, selectedAnswers: updatedSelectedAnswers } : element));

  return pages.map(page => (page.id === currentPageId ? { ...page, elements: updatedElements(page.elements) } : page));
};

export const updateScoringForCurrentQuestion = (
  currentPageId,
  selectedAnswersIds,
  chapters,
  currentChapterId,
  answersScoring,
  selectedQuestionnaireElement
) => {
  if (!selectedQuestionnaireElement) {
    return answersScoring;
  }

  const currentQuestion = getCurrentQuestion(chapters, currentChapterId, currentPageId);

  if (currentQuestion && selectedAnswersIds) {
    const currentAnswers = currentQuestion.content.answers.filter(answer => selectedAnswersIds.includes(answer.id));
    const initialScoreArr = answersScoring[currentQuestion.id] ? answersScoring[currentQuestion.id].selectedAnswers : [];
    const selectedAnswersScoring = currentQuestion.content.multipleChoice ? [...initialScoreArr, currentAnswers] : [currentAnswers[0]];

    return {
      ...answersScoring,
      [currentQuestion.id]: { selectedAnswers: selectedAnswersScoring },
    };
  }

  return answersScoring;
};

export const updateElementsAfterAnswering = (elements, questionElementId, resultAnswers, nonFlowProductsElements) =>
  elements.map(el => {
    if (el.id === questionElementId) {
      return { ...el, selectedAnswers: resultAnswers };
    }
    if (nonFlowProductsElements.map(prodEl => prodEl.id).includes(el.id)) {
      return {
        ...el,
        content: {
          ...el.content,
          products: nonFlowProductsUpdate(
            el.content.products.map(prodItem => ({
              ...prodItem,
              productTemplateId: el.id,
              kind: el.content?.kind,
              productSelection: el.content?.productSelection,
            }))
          ),
        },
      };
    }
    return el;
  });

export const nonFlowProductsUpdate = products =>
  products.map(prod => {
    if (prod?.kind === ProductKind.ONE_COUNTER) {
      return {
        ...prod,
        selected: undefined,
        counterSection: { ...prod?.counterSection, value: prod?.counterSection?.showDefaultCounter ? prod?.counterSection?.value : 0 },
      };
    }
    if (prod?.kind === ProductKind.MULTIPLE_COUNTERS && !prod?.productSelection && !prod?.counterSection?.showCounter) {
      return prod;
    }
    if (prod?.kind === ProductKind.MULTIPLE_COUNTERS && prod?.productSelection) {
      return { ...prod, selected: undefined };
    }
    if (prod?.kind === ProductKind.MULTIPLE_COUNTERS && prod?.counterSection?.showCounter) {
      return {
        ...prod,
        counterSection: { ...prod?.counterSection, value: prod?.counterSection?.showDefaultCounter ? prod?.counterSection?.value : 0 },
      };
    }
    return prod;
  });

export const updatePagesRulesProducts = (allPages, products, hide) => {
  return allPages.map(page => {
    if (products.map(prod => prod.pageId).includes(page.id)) {
      return {
        ...page,
        elements: page.elements.map(el => {
          if (products.map(prod => prod.productTemplateId).includes(el.id)) {
            const filteredProducts = products.filter(prod => prod.productTemplateId === el.id);
            const updatedProducts = el.content.products.map(prod => {
              const isChangeProd = filteredProducts.map(prodItem => prodItem.id).includes(prod.id);
              const changedProd = filteredProducts.find(prodItem => prodItem.id === prod.id);
              return isChangeProd ? changedProd : prod;
            });
            return { ...el, content: { ...el.content, products: hide ? filteredProducts : updatedProducts } };
          }
          return el;
        }),
      };
    }
    return page;
  });
};

export const updatePagesRulesInputAnswers = (allPages, answers) => {
  return allPages.map(page => {
    if (answers.map(answ => answ.pageId).includes(page.id)) {
      return {
        ...page,
        elements: page.elements.map(el => {
          if (answers.map(answ => answ.questionId).includes(el.id)) {
            const filteredAnswers = answers.filter(answ => answ.questionId === el.id);
            const isChart = Array.isArray(el.content?.chartAnswers);
            const isCounter = Array.isArray(el.content?.counterAnswers);

            if (isChart) {
              const updatedAnswers = updateInputAnswers(el.content?.chartAnswers, filteredAnswers);
              return { ...el, content: { ...el.content, chartAnswers: updatedAnswers } };
            }
            if (isCounter) {
              const updatedAnswers = updateInputAnswers(el.content?.counterAnswers, filteredAnswers);
              return { ...el, content: { ...el.content, counterAnswers: updatedAnswers } };
            }
          }
          return el;
        }),
      };
    }
    return page;
  });
};

const updateInputAnswers = (answers, filteredAnswers) => {
  return answers.map(answerItem => {
    const isChangedAnswer = filteredAnswers.map(item => item.id).includes(answerItem.id);
    const changedAnswer = filteredAnswers.find(item => item.id === answerItem.id);
    return isChangedAnswer ? changedAnswer : answerItem;
  });
};

export const updatePagesRulesSimpleAnswers = (allPages, allUpdatedAnswers, selectedAnswers, unselectedAnswers) => {
  return allPages.map(page => {
    if (allUpdatedAnswers.map(answ => answ.pageId).includes(page.id)) {
      return {
        ...page,
        elements: page.elements.map(el => {
          if (allUpdatedAnswers.map(answ => answ.questionId).includes(el.id)) {
            const filteredSelectedAnswersIds = selectedAnswers.filter(answ => answ.questionId === el.id).map(answ => answ.id);
            const filteredUnselectedAnswersIds = unselectedAnswers.filter(answ => answ.questionId === el.id).map(answ => answ.id);
            const isSimpleAnswer = Array.isArray(el.content?.answers);

            if (isSimpleAnswer) {
              const updatedSelectedAnswers = el.selectedAnswers
                .filter(id => !filteredUnselectedAnswersIds.includes(id))
                .concat(filteredSelectedAnswersIds);

              return { ...el, selectedAnswers: updatedSelectedAnswers };
            }
          }

          return el;
        }),
      };
    }
    return page;
  });
};
