export function DesktopIcon() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.9974 1.83594H1.9974C1.26406 1.83594 0.664062 2.43594 0.664062 3.16927V11.1693C0.664062 11.9026 1.26406 12.5026 1.9974 12.5026H6.66406V13.8359H5.33073V15.1693H10.6641V13.8359H9.33073V12.5026H13.9974C14.7307 12.5026 15.3307 11.9026 15.3307 11.1693V3.16927C15.3307 2.43594 14.7307 1.83594 13.9974 1.83594ZM13.9974 11.1693H1.9974V3.16927H13.9974V11.1693Z'
        fill='url(#paint0_linear_8106_90698)'
      />
      <defs>
        <linearGradient id='paint0_linear_8106_90698' x1='0.664062' y1='1.83594' x2='17.8852' y2='7.82971' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F8F5FF' />
          <stop offset='0.515625' stopColor='white' />
          <stop offset='1' stopColor='#F6F4FF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
