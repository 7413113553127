import { CompanyFormFields } from './CompanyFormFields';
import { PreviewFormWrapper } from '../components/PreviewFormWrapper';
import { PreviewFormHeader } from '../components/PreviewFormHeader';

export function CompanyClientForm(props) {
  return (
    <div>
      <div>
        <PreviewFormHeader {...props} />

        <PreviewFormWrapper {...props}>
          <CompanyFormFields {...props} />
        </PreviewFormWrapper>
      </div>
    </div>
  );
}
