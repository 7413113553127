/* eslint-disable no-magic-numbers,react-hooks/exhaustive-deps,max-lines */
import { useEffect, useState } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { CheckmarkIcon } from '../../../../../../../../ui/icons/CheckmarkIcon';
import styled from 'styled-components';
import { AppText } from '../../../../../../../../ui/text/AppText';
import { CloseIcon } from '../../../../../../../../ui/icons/CloseIcon';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../lib/util/Adaptive';
import { ChapterLine } from './ChapterLine';
import { IndicatorLabel } from './IndicatorLabel';
import { getBackgroundColor, getBorderColor, getColorText, OPACITY } from './utils';
import { hex2rgba } from '../../../../utils/helpers';
import { ProgressBarType } from '../../../../utils/consts';
import { ChapterState, TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { usePrevious } from '../../../../../../../../lib/hooks/usePrevious';
import { useFirstMountState } from '../../../../../../../../lib/hooks/useFirstMountState';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';
import { Tooltip } from 'antd';

const ALL_DISTANCE = 100;
const StartText = 'Anfang';
const ChapterText = 'Chapter';
const appTextStyle = { marginTop: 8, wordBreak: 'normal', maxWidth: 150, fontSize: '12px' };
const MAX_WIDTH_CHAPTER_TEXT = 750;

/**
 * @desc A component for tracking the progress of passing the questionnaire.
 * @param {Object} pagesFlow - The flow of pages in the questionnaire.
 * @param {Object} footer - The footer configuration.
 * @param {Object} colors - The colors used for the progress bar.
 * @param {Array} showChapterIds - The array of chapter IDs to show.
 * @param {Object} fonts - The fonts configuration.
 * @returns {JSX.Element} The rendered progress bar component.
 */

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
export function NewProgressBar({ pagesFlow, footer, colors, showChapterIds, fonts }) {
  const { windowWidth } = useWindowResize();
  const { applyVariable } = useVariables(pagesFlow);

  const { oppositeColorForProgressBar = '', progressbarBackgroundColor, progressbarVariant } = footer;

  const { allChapters, currentPageId, currentChapterId, currentFlowElement, allPages } = pagesFlow;

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [indicatorValue, setIndicatorValue] = useState(0);
  const [currChIdxWithPossibleValNull, setCurrChIdxWithPossibleValNull] = useState(0);

  const newAllChapters = allChapters.filter(chapter => showChapterIds?.includes(chapter.id));

  const progressBarColor = colors[progressbarBackgroundColor] || '#005CA8';
  const numberOfChapters = newAllChapters.length;
  const isMobile = AppBreakpoint.M > windowWidth;

  const isIntro = allPages.flatMap(page => page.elements).find(el => el.templateType === TemplateTypes.INTRO);
  const currentChapterIndex = newAllChapters.findIndex(el => el.id === currentChapterId);
  const numberOfPagesInChapter = newAllChapters[currentChapterIndex]?.chapterPages?.flatMap(el => el).length;
  const newPagePercentage = isIntro
    ? ALL_DISTANCE / (numberOfPagesInChapter || 2) / numberOfChapters
    : ALL_DISTANCE / (numberOfPagesInChapter || 1) / numberOfChapters;
  const currentChapter = newAllChapters.find(chapter => chapter.id === currentChapterId);
  const prevChapter = newAllChapters[currentChapterIndex - 1];
  const pagesInChapter = newAllChapters[currentChapterIndex]?.chapterPages?.flatMap(el => el);
  const currentPageIndex = pagesInChapter?.findIndex(page => page.id === currentPageId) || -1;
  const prevPageIndex = usePrevious(currentPageIndex);
  const isFirstMountState = useFirstMountState();
  const numberOfCompletedChapters = newAllChapters.filter(ch => ch.state === ChapterState.COMPLETED).length;
  const lastCompletedChapterIndexPlusOne = newAllChapters.findLastIndex(ch => ch.state === ChapterState.COMPLETED) + 1;

  useEffect(() => {
    if (currentPageIndex > 0) {
      setCurrChIdxWithPossibleValNull(currentChapterIndex);
    } else {
      setCurrChIdxWithPossibleValNull(null);
    }
  }, [currentChapterIndex, currentPageIndex]);

  useEffect(() => {
    if (progressPercentage < 0) {
      setProgressPercentage(0);
      setIndicatorValue(0);
    }
  }, [progressPercentage]);

  useEffect(() => {
    if (progressPercentage < ALL_DISTANCE) {
      if (isIntro) {
        if (currentPageIndex && prevPageIndex) {
          if (currentPageIndex >= prevPageIndex) {
            setProgressPercentage(prevState => prevState + newPagePercentage);
            setIndicatorValue(prev => prev + newPagePercentage);
          } else {
            setProgressPercentage(prevState => prevState - newPagePercentage);
            setIndicatorValue(prev => prev - newPagePercentage);
          }
        } else {
          setProgressPercentage(prevState => prevState + newPagePercentage);
          setIndicatorValue(prev => prev + newPagePercentage);
        }
      }
      if (!isIntro) {
        if (currentPageIndex >= 0 && currentPageIndex >= prevPageIndex) {
          setProgressPercentage(prevState => prevState + newPagePercentage);
          setIndicatorValue(prev => prev + newPagePercentage);
        } else {
          setProgressPercentage(prevState => prevState - newPagePercentage);
          setIndicatorValue(prev => prev - newPagePercentage);
        }
      }
    }
  }, [currentPageIndex]);

  useEffect(() => {
    if (currChIdxWithPossibleValNull >= 0 && progressPercentage <= ALL_DISTANCE && currentFlowElement?.templateType !== TemplateTypes.RESULT) {
      if (prevChapter?.state === ChapterState.OPENED || currentPageIndex > 0) {
        setProgressPercentage(currChIdxWithPossibleValNull * (ALL_DISTANCE / numberOfChapters) + newPagePercentage);
      } else {
        setProgressPercentage(currChIdxWithPossibleValNull * (ALL_DISTANCE / numberOfChapters));
      }
    }
  }, [currChIdxWithPossibleValNull]);

  useEffect(() => {
    if (currentPageIndex < 0 && currentFlowElement?.templateType !== TemplateTypes.RESULT) {
      setIndicatorValue(numberOfCompletedChapters * (ALL_DISTANCE / numberOfChapters) || 0);
      setProgressPercentage(lastCompletedChapterIndexPlusOne * (ALL_DISTANCE / numberOfChapters) || 0);
    }
  }, [currentPageIndex, currChIdxWithPossibleValNull]);

  useEffect(() => {
    if (progressPercentage <= ALL_DISTANCE && currentPageIndex > 0) {
      if (currentChapter.state === ChapterState.COMPLETED) {
        setIndicatorValue((numberOfCompletedChapters - 1) * (ALL_DISTANCE / numberOfChapters) + newPagePercentage);
      }
      if (currentChapter.state !== ChapterState.COMPLETED) {
        setIndicatorValue(numberOfCompletedChapters * (ALL_DISTANCE / numberOfChapters) + newPagePercentage);
      }
    }
  }, [currChIdxWithPossibleValNull, currentChapter?.state]);

  useEffect(() => {
    if (currentFlowElement?.templateType === TemplateTypes.RESULT) {
      setProgressPercentage(ALL_DISTANCE);
      setIndicatorValue(ALL_DISTANCE);
    }
  }, [currentFlowElement?.templateType]);

  useEffect(() => {
    if (isFirstMountState && currChIdxWithPossibleValNull >= 0 && currentFlowElement?.templateType === TemplateTypes.CHAPTER) {
      setProgressPercentage(0);
      setIndicatorValue(0);
    }
  }, []);

  // For show/hide chapter rule
  useEffect(() => {
    if (currentFlowElement?.templateType !== TemplateTypes.CHAPTER) {
      const value = (ALL_DISTANCE / (numberOfPagesInChapter || 1) / numberOfChapters) * currentPageIndex;
      setIndicatorValue(currChIdxWithPossibleValNull * (ALL_DISTANCE / numberOfChapters) + value);
      setProgressPercentage(currChIdxWithPossibleValNull * (ALL_DISTANCE / numberOfChapters) + value);
    }
  }, [newAllChapters.length]);

  return (
    progressbarVariant === ProgressBarType.NEW && (
      <Root isMobile={isMobile}>
        <StyledProgressbar isMobile={isMobile}>
          <ProgressBar
            percent={progressPercentage}
            filledBackground={progressBarColor}
            unfilledBackground={hex2rgba(progressBarColor, OPACITY)}
            height={5}
          >
            <Step>
              {() => (
                <StyledStepInner isMobile={isMobile}>
                  <StyledCircle backgroundColor={progressBarColor} borderColor={progressBarColor} />
                  {!isMobile && (
                    <AppText ellipsis style={{ ...appTextStyle, fontFamily: fonts?.body?.font }}>
                      {applyVariable(StartText)}
                    </AppText>
                  )}
                </StyledStepInner>
              )}
            </Step>
            {newAllChapters.map(({ id, state, resultSelectedProducts, content, chapterPages }) => {
              const productsInChapter = chapterPages.flatMap(ch => {
                return ch.elements.filter(el => el.templateType === TemplateTypes.PRODUCT);
              });
              const noProductsInChapter = productsInChapter.length === 0;
              return (
                <Step key={id} transition={state === ChapterState.COMPLETED && resultSelectedProducts.length > 0 && 'scale'}>
                  {({ position }) => (
                    <StyledStepInner isMobile={isMobile}>
                      <StyledCircle
                        backgroundColor={getBackgroundColor(
                          state,
                          progressBarColor,
                          progressPercentage,
                          oppositeColorForProgressBar,
                          resultSelectedProducts,
                          position,
                          noProductsInChapter
                        )}
                        borderColor={getBorderColor(
                          state,
                          progressBarColor,
                          progressPercentage,
                          oppositeColorForProgressBar,
                          resultSelectedProducts,
                          position,
                          noProductsInChapter
                        )}
                      >
                        {(state === ChapterState.OPENED ||
                          state === ChapterState.NOT_OPENED ||
                          (resultSelectedProducts.length === 0 && !noProductsInChapter)) && <CloseIcon />}
                        {state === ChapterState.COMPLETED && (resultSelectedProducts.length > 0 || noProductsInChapter) && (
                          <CheckmarkIcon fill='#fff' width='12' height='12' />
                        )}
                      </StyledCircle>
                      {!isMobile && (
                        <AppText
                          ellipsis
                          style={{
                            fontSize: '12px',
                            marginTop: 8,
                            wordBreak: 'normal',
                            maxWidth: MAX_WIDTH_CHAPTER_TEXT / newAllChapters.length,
                            color: getColorText(
                              state,
                              position,
                              progressPercentage,
                              oppositeColorForProgressBar,
                              resultSelectedProducts,
                              noProductsInChapter
                            ),
                            fontFamily: fonts?.body?.font,
                          }}
                        >
                          <Tooltip placement='bottomLeft' title={applyVariable(content?.headline?.value || ChapterText)}>
                            {applyVariable(content?.headline?.value || ChapterText)}
                          </Tooltip>
                        </AppText>
                      )}
                    </StyledStepInner>
                  )}
                </Step>
              );
            })}
          </ProgressBar>
          <ChapterLine
            newAllChapters={newAllChapters}
            progressPercentage={progressPercentage}
            allDistance={ALL_DISTANCE}
            numberOfChapters={numberOfChapters}
            progressBarColor={progressBarColor}
            oppositeColorForProgressBar={oppositeColorForProgressBar}
            currentChapter={currentChapter}
            currentFlowElement={currentFlowElement}
          />
          <IndicatorLabel progressPercentage={progressPercentage} indicatorValue={indicatorValue} />
        </StyledProgressbar>
      </Root>
    )
  );
}

const Root = styled('div')(({ isMobile }) => ({
  padding: isMobile ? '0 50px' : '0 100px',
  maxWidth: '1020px',
  margin: '58px auto 0',
  width: '100%',
}));

const StyledProgressbar = styled('div')(({ isMobile }) => ({
  position: 'relative',
  marginBottom: isMobile ? '36px' : '50px',
}));

const StyledStepInner = styled('div')(({ isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: !isMobile && 25,
}));

const StyledCircle = styled('div')(({ backgroundColor, borderColor }) => ({
  width: '20px',
  height: '20px',
  backgroundColor,
  border: `1px solid ${borderColor}`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
