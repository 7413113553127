import { components } from 'react-select';
import { useLocalization } from '../../../lib/hooks/useLocalization';
import { AppNewCheckbox } from '../../AppNewCheckbox';
import { AppDivider } from '../../AppDivider';

export function AppNewSelectMenuList(props) {
  const { selectProps, children } = props;
  const { select } = useLocalization().translations.unique.components;
  const areAllSelected = Boolean(selectProps.value && selectProps.options && selectProps.value?.length === selectProps.options?.length);

  const options = children?.props?.children === 'No options' ? [] : children?.map(el => el.props.data);

  const selectPropsValue = selectProps.value ? selectProps.value : [];

  const newOptions = selectProps.inputValue && selectProps.isMulti ? [...selectPropsValue, ...options] : options;

  const uniqueOptions = [...new Map(newOptions.map(item => [item.value, item])).values()];

  const toggle = () => {
    selectProps.onChange(areAllSelected ? [] : uniqueOptions);
  };

  // REFACTOR: replace looping throw option with checking by element className
  const hasOptions = selectProps.options.some(option => option?.title?.toLowerCase?.().includes(selectProps?.inputValue?.toLowerCase?.()));

  return (
    <div>
      {selectProps.isMulti && hasOptions && (
        <>
          <AppNewCheckbox label={select.selectAll} onChange={toggle} checked={areAllSelected} style={{ marginTop: '16px' }} />
          <AppDivider style={{ margin: '16px 16px 0 0' }} />
        </>
      )}

      <components.MenuList {...props} />
    </div>
  );
}
