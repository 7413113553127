import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesRequest, removeCategoryArranges } from '../../../../../../../../../lib/store/slices/categoriesSlice';
import { isOdd, normalizePageForRequest } from '../../../../../../../../../lib/util/helpers';
import { AppTable } from '../../../../../../../../../ui/AppTable';
import { useCategoryActionsColumn } from './columns/actions';
import { useCategoryNameColumn } from './columns/name';
import { useIdNameColumn } from './columns/id';
import { categoryAssignedProductsColumn } from './columns/assigned-products';

export function CategoriesTable() {
  const dispatch = useDispatch();
  const { categories: dataSource, pagination, loading } = useSelector(({ categories }) => categories);

  useEffect(() => {
    dispatch(removeCategoryArranges());
    dispatch(getCategoriesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [useIdNameColumn(), useCategoryNameColumn(), categoryAssignedProductsColumn, useCategoryActionsColumn()];

  return (
    <AppTable
      columns={columns}
      rowKey='id'
      dataSource={dataSource}
      pagination={pagination}
      loading={loading}
      scroll={{ x: 'max-content' }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      onChange={tablePagination => dispatch(getCategoriesRequest({ params: { page: normalizePageForRequest(tablePagination.current) } }))}
    />
  );
}
