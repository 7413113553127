import { Status, StatusType } from '../../../../../components/Status';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { AppTooltip } from '../../../../../../../../../ui/AppTooltip';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { getIndexByTemplateType } from '../../../../../../../../../lib/util/helpers';
import { LabelTexts } from '../../../../../../../../../lib/models/TemplateTypes';
import styled from 'styled-components';
import { setAllElementsButtons } from '../../../../../utils/questionnaire-helpers';

/**
 * @desc Component for rendering the status of a rule in a table body cell.
 * @param {Boolean} status - The status of the rule (true for activated, false for deactivated).
 * @param {Number} usedInElements - The number of elements the rule is used in.
 * @param {Array} elementsUsage - The usage of the rule in elements.
 * @param {Array} questionnaireStructure - The structure of the questionnaire.
 * @returns {JSX.Element} - The rendered component.
 */
export function RuleStatusBodyCell({ status, usedInElements, elementsUsage, questionnaireStructure }) {
  const { status: statusText } = useLocalization().translations.shared;
  const { rules } = useLocalization().translations.unique.pages;

  if (!status) {
    return <Status textValue={statusText.deactivated} type={StatusType.GRAY} />;
  }

  const getButtonName = id => {
    const allButtons = setAllElementsButtons(questionnaireStructure);
    const button = allButtons.find(el => el.id === id);
    return button?.value || button?.buttonText?.value;
  };

  const renderTooltip = () => {
    return (
      <StyledTooltip>
        <AppText className='white_color' textValue={rules.tooltip.usedIn} />
        <StyledList>
          {elementsUsage.map(element => {
            if (element?.templateType) {
              const newElement = {
                id: element.id.replace(/^"|"$/g, ''),
                templateType: element.templateType.replace(/^"|"$/g, ''),
              };
              const indexByTemplateType = getIndexByTemplateType(questionnaireStructure, newElement) + 1;
              return (
                <StyledItem key={element.id}>
                  <AppText className='white_color'>{`${LabelTexts[newElement.templateType]}.${indexByTemplateType}`}</AppText>
                </StyledItem>
              );
            }
            return (
              <StyledItem key={element.id}>
                <AppText ellipsis style={{ width: 150 }} className='white_color' textValue={getButtonName(element.id.replace(/^"|"$/g, ''))} />
              </StyledItem>
            );
          })}
        </StyledList>
      </StyledTooltip>
    );
  };

  if (status && usedInElements > 0) {
    return (
      <AppTooltip placement='rightBottom' text={renderTooltip}>
        <StyledTooltipWrapper>
          <Status textValue={statusText.usedTimes({ amount: usedInElements })} type={StatusType.GREEN} />
        </StyledTooltipWrapper>
      </AppTooltip>
    );
  }

  if (status && !usedInElements) {
    return <Status textValue={statusText.notApplied} type={StatusType.NAVY} />;
  }
}

const StyledTooltipWrapper = styled('div')({
  maxWidth: 110,
  cursor: 'pointer',
});

const StyledTooltip = styled('div')({
  padding: 5,
  minWidth: 95,
});

const StyledList = styled('ul')({
  marginTop: 5,
  paddingLeft: 25,
  marginBottom: 0,
});

const StyledItem = styled('li')({
  fontSize: 16,
  lineHeight: '24px',
});
