import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Form, Dropdown } from 'antd';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import './style.scss';

export function ColorPickerFormItem({ label, fieldName, color, setColor, disabled }) {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = flag => setVisible(flag);

  const handleChange = colorObj => {
    setColor(fieldName, colorObj.hex);
  };

  return (
    <Form.Item className='color_picker_form_item' label={label}>
      {disabled ? (
        <div style={{ backgroundColor: color }} className='color_picker_wrap color_picker_wrap_disabled' />
      ) : (
        <Dropdown
          open={visible}
          trigger={['click']}
          placement='top'
          onOpenChange={handleVisibleChange}
          dropdownRender={() => (
            <div style={{ width: 220 }}>
              <SketchPicker color={color} onChange={handleChange} disableAlpha presetColors={[]} />
            </div>
          )}
        >
          <div style={{ backgroundColor: color }} className='color_picker_wrap' />
        </Dropdown>
      )}
      <AppText className='secondary_color'>{color.toUpperCase()}</AppText>
    </Form.Item>
  );
}
