import { handleTranslation, isLength } from '../../../../../../../../../../../lib/util/helpers';
import { QuestionnaireSelect } from '../../../../../features/QuestionnaireSelect';
import React, { useEffect } from 'react';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { ContactFormAction } from '../../../../../../../../../../../lib/models/Questionnaire';
import { ALWAYS_SHOW, alwaysShowBaseItem, OptionType } from './consts';
import lodashSet from 'lodash.set';
import { findConditionIndexes, getConditionOptions, getOptionLabel } from './utils';

export function ContactFormRowCondition({ rowIndex, structureItemIndex, templateContent }) {
  const isAnyRowWithAction = templateContent?.formRowList?.some(row =>
    row?.fieldList?.some(item => item?.optionList?.some(option => option?.action === ContactFormAction.CREATE_NEW_ROWS))
  );
  const { content: contentText } = useLocalization().translations.unique.pages.questionnaire;
  const { updateStructure } = useUpdateStructure();
  const alwaysShowText = handleTranslation(contentText.alwaysShow);

  const alwaysShowItem = {
    value: alwaysShowBaseItem,
    title: alwaysShowText,
  };

  const condition = templateContent?.formRowList?.[rowIndex]?.condition;

  const options = isLength(templateContent?.formRowList)
    ? [alwaysShowItem, ...Object.values(getConditionOptions(templateContent?.formRowList, contentText?.condition, rowIndex))]
    : [];

  const conditionIndexes = findConditionIndexes(options, condition?.fromRowId, condition?.fromOptionId);
  const value = conditionIndexes ? options?.[conditionIndexes?.optionIndex]?.options?.[conditionIndexes?.groupOptionIndex] : alwaysShowBaseItem;

  const onChange = selected => {
    // appLogger.info({ selected });
    if (selected?.value?.value === ALWAYS_SHOW) {
      updateStructure(draft => {
        lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].condition`, alwaysShowBaseItem);
      });
      return;
    }

    updateStructure(draft => {
      lodashSet(draft, `[${structureItemIndex}].content.formRowList[${rowIndex}].condition`, {
        fromRowId: selected?.value?.rowId,
        fromOptionId: selected?.value?.optionId,
        optionType: OptionType.GROUP,
      });
    });
  };

  useEffect(() => {
    if ((!isAnyRowWithAction || !conditionIndexes) && condition?.value !== ALWAYS_SHOW) {
      updateStructure(draft => {
        // eslint-disable-next-line no-param-reassign
        draft[structureItemIndex].content.formRowList[rowIndex].condition = alwaysShowBaseItem;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionIndexes, isAnyRowWithAction, rowIndex, structureItemIndex, condition]);

  return (
    <QuestionnaireSelect
      style={{ width: '100%', minWidth: '180px', marginBottom: 0 }}
      formItemProps={{ style: { minHeight: 'unset' } }}
      size='small'
      selectProps={{
        disabled: !isAnyRowWithAction,
        label: isAnyRowWithAction ? contentText.condition.label : undefined,
        options,
        isValueFromOptions: false,
        value,
        onChange,
        formatOptionLabel: option => {
          const isFlat = option?.optionType === OptionType.FLAT;
          const isGroup = option?.value?.optionType === OptionType.GROUP;

          if (isFlat) return alwaysShowText;
          if (isGroup) {
            return getOptionLabel(conditionIndexes?.rowIndex, conditionIndexes?.groupOptionIndex, contentText?.condition);
          }

          return '';
        },
      }}
    />
  );
}
