import { useSelector } from 'react-redux';
import { ManagerRoutes } from './processes/management';
import { UserRoutes } from './processes/user/UserRoutes';
import { checkIsManager, checkIsUser } from '../../lib/models/Role';

export function CMSRoutes() {
  const { role } = useSelector(({ auth }) => auth);
  const isManager = checkIsManager(role);
  const isUser = checkIsUser(role);

  return (
    <>
      {/* <Header /> */}
      {isManager ? <ManagerRoutes /> : isUser ? <UserRoutes /> : null}
    </>
  );
}
