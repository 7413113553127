import { ProductNameSection } from './ProductNameSection';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppList } from '../../../../../../../../../../../../../ui/AppList';
import { ProductPageLinkSection } from './ProductPageLinkSection';
import { ProductBulletPointsForProductSection } from './ProductBulletPointsForProductSection';
import { ProductButtons } from './ProductButtons';
import { DetailTableSection } from './DetailTableSection';

export function ProductList({ products, structureItemIndex }) {
  const { tenantProducts, questionnaireStructure } = useSelector(({ finders }) => finders);

  const addedProducts = questionnaireStructure[structureItemIndex].content.products;
  const availableProducts = tenantProducts.filter(existedProduct =>
    addedProducts.every(addedProduct => addedProduct?.productNameSection?.product?.value !== existedProduct.id)
  );

  return (
    <ProductListWrapper>
      {products?.map((product, index) => {
        return (
          <li key={product.id}>
            <ProductNameSection
              allProducts={tenantProducts}
              availableProducts={availableProducts}
              productIndex={index}
              structureItemIndex={structureItemIndex}
              data={product.productNameSection}
            />
            <ProductPageLinkSection productIndex={index} value={product.productPageLink} structureItemIndex={structureItemIndex} />
            <ProductBulletPointsForProductSection data={product.bulletPointsSection} productIndex={index} structureItemIndex={structureItemIndex} />
            <ProductButtons data={product.buttonsSection.buttons} productIndex={index} structureItemIndex={structureItemIndex} />
            <DetailTableSection data={product} productIndex={index} structureItemIndex={structureItemIndex} />
          </li>
        );
      })}
    </ProductListWrapper>
  );
}

const BORDER = '1px solid #5C5C6E';

const ProductListWrapper = styled(AppList)({
  '& > li': {
    borderBottom: BORDER,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});
