import { InfoIconComponent } from '../../InfoIconComponent';

export function ResultSubline({ fonts = {}, colors = {}, subline = {}, sublineStyle, showInfoIcon = false, applyVariable }) {
  return (
    <p
      style={{
        marginBottom: 35,
        overflow: 'hidden',
        width: '100%',
        textAlign: 'center',
        fontSize: fonts?.body?.fontSize,
        fontFamily: fonts?.body?.font,
        fontWeight: 700,
        color: colors?.[fonts?.body?.colorVariant],
        ...sublineStyle,
      }}
    >
      {applyVariable(subline?.value)}
      {showInfoIcon && <InfoIconComponent applyVariable={applyVariable} type={subline} />}
    </p>
  );
}
