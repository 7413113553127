/**
 * @desc Custom hook for managing condition elements and handling changes.
 * @param {Object} value - The current value of the condition element.
 * @param {Function} onChange - Function to be called when the condition element changes.
 * @returns {Object} - An object containing functions to handle different types of changes.
 */

export const useConditionElement = ({ value, onChange }) => {
  const handleElementTypeChange = val => {
    onChange({
      elementType: val,
      elements: null,
      property: null,
      composerItem: null,
    });
  };

  const handleElementsChange = val => {
    onChange({
      ...value,
      elements: val,
      property: null,
      composerItem: null,
    });
  };

  const handlePropertyChange = val => {
    onChange({
      ...value,
      property: val,
      composerItem: null,
    });
  };

  const handleComposerItemChange = val => {
    onChange({
      ...value,
      composerItem: val,
    });
  };
  return { handleElementTypeChange, handleElementsChange, handlePropertyChange, handleComposerItemChange };
};
