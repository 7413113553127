/* eslint-disable react/no-array-index-key */
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BodyDragRowCell } from './BodyDragRowCell';
import { BodyInputCells } from './BodyInputCells';
import { BodyDeleteRowCell } from './BodyDeleteRowCell';
import {
  ComparisonTableDraggableId,
  ComparisonTableDroppable,
  DefaultRowColor,
  DefaultTextColor,
  formatBrandThemeColorList,
} from '../comparison-table-utils';
import classes from '../ComparisonTable.module.sass';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { handleTranslation } from '../../../../../../../../../../lib/util/helpers';
import { useProductList } from '../useProductList';
import { useProductFieldList } from '../useProductFieldList';
import { useFieldLabels } from './ProductFieldsSelect/useFieldLabels';
import { usePictureList } from '../usePictureList';
import { useIconList } from '../useIconList';
import { useContentTable } from '../hooks/useContentTable';

export function ComparisonTableBody({ focusedId, setFocusedId }) {
  const { translations } = useLocalization();
  const { colorVariants } = translations.shared;
  const { colorLabels } = translations.unique.pages.questionnaire.contentTable;
  const { finder, isViewMode } = useSelector(({ finders }) => finders);
  const brandThemeColorList = finder.brand?.configuration ? formatBrandThemeColorList(finder.brand.configuration, colorVariants) : [];
  const textColorList = [...brandThemeColorList, { value: DefaultTextColor.BLACK, label: handleTranslation(colorLabels.black) }];
  const rowColorList = [...brandThemeColorList, { value: DefaultRowColor.NONE, label: handleTranslation(colorLabels.default) }];
  const productList = useProductList();
  const fieldLabels = useFieldLabels();
  const productFieldList = useProductFieldList(fieldLabels);
  const pictureList = usePictureList();
  const iconList = useIconList();
  const { contentTableState } = useContentTable();

  return (
    <Droppable
      droppableId={JSON.stringify({
        tableIndex: contentTableState.tableIndex,
        structureItemIndex: contentTableState.structureItemIndex,
        type: 'rows',
      })}
      direction='vertical'
      type={ComparisonTableDroppable.ROW}
    >
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {contentTableState?.tableRows?.map((row, rowIndex) => (
            <Draggable
              key={rowIndex}
              draggableId={JSON.stringify({
                type: 'row',
                [ComparisonTableDraggableId.STRUCTURE_INDEX]: contentTableState.structureItemIndex,
                [ComparisonTableDraggableId.TABLE_INDEX]: contentTableState.tableIndex,
                rowIndex,
              })}
              index={rowIndex}
              isDragDisabled={isViewMode}
            >
              {providedDraggable => (
                <tr
                  ref={providedDraggable.innerRef}
                  {...providedDraggable.draggableProps}
                  className={classNames(providedDraggable.draggableProps.className, classes.bodyRow)}
                >
                  <BodyDragRowCell providedDraggable={providedDraggable} />
                  <BodyDeleteRowCell rowIndex={rowIndex} />
                  <BodyInputCells
                    row={row}
                    rowIndex={rowIndex}
                    fieldLabels={fieldLabels}
                    productFieldList={productFieldList}
                    textColorList={textColorList}
                    iconList={iconList}
                    rowColorList={rowColorList}
                    productList={productList}
                    pictureList={pictureList}
                    setFocusedId={setFocusedId}
                    focusedId={focusedId}
                  />
                </tr>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
}
