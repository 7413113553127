export const types = {
  value: 'Value',
  variable: 'Variable',
  logicalOperator: 'Logical operator',
  arithmeticOperator: 'Arithmetic operator',
  comparisonOperator: 'Comparison operator',
  element: 'Element',
  resultType: 'Result Type',
  preCalculation: 'Pre calculation',
  calculation: 'Calculation',
};

export const elementSubTypes = {
  Product: 'Product',
  Question: 'Question',
  Answer: 'Answer',
  Buttons: 'Buttons',
};

export const resultSubTypes = {
  Product: 'Product',
  Answer: 'Answer',
  Flow: 'Flow',
  SetVariable: 'Set Variable',
};

export const productProperties = {
  price: 'Price',
  score: 'Score',
  savings: 'Savings',
  counter: 'Counter',
  selected: 'Selected',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  notSelected: 'Not selected',
};

export const answerProperties = {
  score: 'Score',
  savings: 'Savings',
  counter: 'Counter',
  selected: 'Selected',
  notSelected: 'Not selected',
};

export const noProductAnswerProperties = {
  selected: 'Selected',
  notSelected: 'Not selected',
  counter: 'Counter',
};

export const buttonProperties = {
  selected: 'Selected',
  notSelected: 'Not selected',
};

export const questionProperties = {
  selected: 'Selected',
  notSelected: 'Not selected',
};

export const resultTypeProductProperties = {
  productSelection: 'Product selection',
  scoring: 'Scoring',
  dependance: 'Dependence on other product(-s)',
  savings: 'Savings',
  price: 'Price',
  counter: 'Counter',
};

export const resultTypeNoProductAnswerProperties = {
  select: 'Select',
  unselect: 'Unselect',
  set: 'Set',
};

export const resultTypeAnswerProperties = {
  select: 'Select',
  unselect: 'Unselect',
  set: 'Set',
  score: 'Score',
  savings: 'Savings',
};

export const resultTypeFlowProperties = {
  goTo: 'Go to',
  show: 'Show',
  hide: 'Hide',
  imageSelection: 'Image selection',
};

export const pictureTypes = {
  active: 'Active picture',
  inactive: 'Inactive picture',
};

export const comparisonTypes = {
  greater: '>',
  less: '<',
  greaterOrEqual: '>=',
  lessOrEqual: '<=',
  equals: '==',
  notEquals: '!=',
};

export const arithmeticTypes = {
  plus: '+',
  minus: '-',
  multiply: '*',
  divide: '/',
  equal: '=',
  plusEquals: '+=',
  minusEquals: '-=',
};

export const logicalTypes = {
  Then: 'THEN', // from uppercase to avoid "then" function as in promise
  or: 'OR',
  and: 'AND',
  openBracket: '(',
  closeBracket: ')',
};

export const productResultTypeActions = {
  preselected: 'Select',
  addedToResult: 'Product card is added to the result',
  unselect: 'Unselect',
};

export const productRangeOptions = {
  all: 'ALL',
  fromTo: 'From - to',
};

export const priceActions = {
  selectPriceToShow: 'Select which price to show',
  changePrice: 'Change the price',
};

export const priceTypes = {
  perMonth: 'Price per month',
  perYear: 'Price per year',
  perRequest: 'Price per request',
};

export const mapSelectOptions = options => {
  return options.map(option => ({
    title: option,
    value: option,
  }));
};
