import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { ProfilePopup } from './features/ProfilePopup';
import './lib/styles/styles.scss';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../ui/text/AppText';
import { AppImage } from '../../../../ui/AppImage';
import { Outlet } from 'react-router-dom';

const { Header: AntHeader } = Layout;

const headerStyle = {
  height: 75,
  paddingInline: 24,
};

export function Header() {
  const { header } = useLocalization().translations.unique.components;
  const {
    headerTitle: { title, subTitle },
  } = useSelector(({ headerTitle }) => headerTitle);

  return (
    <>
      <AntHeader style={headerStyle} className='header'>
        <div className='header_wrap'>
          <div className='header_logo_wrap'>
            <AppImage className='header_logo_image' src='/icons/logo.svg' alt={header.logoAlt} />
            <AppText className='header_text white_color'>
              {header.logoText()} <AppText className='header_text header_text__thin white_color' textValue={header.logoPlatform} />
            </AppText>
            <svg className='header_decor' xmlns='http://www.w3.org/2000/svg' width='2' height='25' viewBox='0 0 2 25' fill='none'>
              <rect x='0.761719' y='0.796875' width='1' height='24' fill='#C4C4C4' />
            </svg>
            <AppText className='header_text header_text__title' textValue={title} />
            {subTitle && (
              <AppText ellipsis className='header_text header_text__title header_text__thin'>
                {subTitle}
              </AppText>
            )}
          </div>
          <ProfilePopup />
        </div>
      </AntHeader>
      <Outlet />
    </>
  );
}
