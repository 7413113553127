import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

export function QuestionnaireDraggable({ children, isDragDisabled, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  return (
    <Root isDragDisabled={isDragDisabled || isViewMode} {...rest}>
      {children}
    </Root>
  );
}

const Root = styled(Draggable)({});
