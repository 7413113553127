import { useDispatch, useSelector } from 'react-redux';
import { ProductSavingsRow } from '../../components/ProductSavingsRow';
import { AppCounterInput } from '../../../../../../../../../../../../ui/AppCounterInput';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../../lib/store/slices/findersSlice';
import './style.scss';

/**
 * /**
 *  * @desc Product Answer Savings component.
 *  * Renders the savings options for a selected product answer.
 *  * @param {Object} savingObj - The saving object containing information about the product savings.
 *  * @param {Object} selectedQuestion - The selected question object.
 *  * @param {Array} savings - The array containing all the savings options.
 *  * @returns {JSX.Element} - JSX element representing the Product Answer Savings component.
 *  */
export function ProductAnswerSavings({ savingObj, selectedQuestion, savings }) {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();

  const updateSavingsArr = (savingsArr, fieldName, value) => {
    return savingsArr.map(savingItem => (savingItem.id === savingObj.id ? { ...savingItem, [fieldName]: value } : savingItem));
  };

  const updateAnswers = (answerArr, updatedSavingsArr) => {
    return answerArr.map(answer => {
      return answer.id === selectedQuestion.answerId ? { ...answer, savings: updatedSavingsArr } : answer;
    });
  };

  const savingsHandleChange = value => {
    if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
      const result = questionnaireStructure.map(el => {
        return el.id === selectedQuestion.elementId
          ? {
              ...el,
              content: {
                ...el.content,
                answers: updateAnswers(el.content.answers, updateSavingsArr(savings, 'savingsValue', Number(value))),
              },
            }
          : el;
      });
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  const handleFieldChange = fieldName => value => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? { ...el, content: { ...el.content, answers: updateAnswers(el.content.answers, updateSavingsArr(savings, fieldName, value)) } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const deleteRow = deleteId => {
    const filteredSavings = savings.filter(savingItem => savingItem.id !== deleteId);
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? { ...el, content: { ...el.content, answers: updateAnswers(el.content.answers, filteredSavings) } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <div className='product_saving_wrap'>
      <ProductSavingsRow
        savingObj={savingObj}
        savingsArr={savings}
        onChange={handleFieldChange('productId')}
        deleteRow={deleteRow}
        disabled={isViewMode}
      />
      <div className='product_saving_input_wrap'>
        <AppCounterInput
          style={{ width: '100%', padding: '0 8px' }}
          value={savingObj.savingsValue}
          onChange={savingsHandleChange}
          disabled={isViewMode}
          regEx={/[\d,]/}
        />
      </div>
    </div>
  );
}
