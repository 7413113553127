export const MINIMAL_SEARCH_LENGTH = 2;

export const ArrangeListType = {
  ENUM: 'enum',
  STRING: 'string',
};

export const ResultPriceType = {
  PRICE_PER_PRODUCT: 'Price per product',
  PRICE_PER_PIECE: 'Price per piece',
  NO_PRICE: 'No price',
};

export const ResultPricePeriod = {
  PRICE_PER_YEAR_AND_MONTH: '0,00 € p. a. und 0,00 € p. M.',
  PRICE_PER_YEAR: '0,00 € p. a.',
  PRICE_PER_MONTH: '0,00 € p. M.',
};

export const ResultSortProductsByType = {
  DEFAULT: 'Default (User selection)',
  PRIORITY: 'Priority',
};

export const ProgressBarType = {
  ORIGINAL: 'original',
  NEW: 'new',
};
