import { Form, Switch, Space } from 'antd';
import { AppBox } from '../../../../../../../../../ui/boxes/AppBox';
import { FormCard } from '../../../../../features/FormCard';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { FormItemName } from '../../../../../../../../../lib/models/Form';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { TextEditor } from './TextEditor';

export function DataProtectionCard({ form }) {
  const { general } = useLocalization().translations.unique.pages.settings;
  const { settingsHead, settingsSub } = useLocalization().translations.unique.formItems;
  const toggleDataProtection = checked => {
    form.setFieldValue(FormItemName.DATA_PROTECTION, checked);
  };

  return (
    <FormCard>
      <AppBox className='space_between' style={{ marginBottom: 24 }}>
        <AppText className='bold' textValue={general.dataProtection} />
      </AppBox>
      <Form.Item name={FormItemName.DATA_PROTECTION} noStyle>
        <Space size='small' style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
          <Switch checked={Form.useWatch(FormItemName.DATA_PROTECTION, form)} onChange={toggleDataProtection} />
          <AppText textValue={general.dataProtectionDescr} />
        </Space>
      </Form.Item>

      <AppFormSection
        formItemProps={{ name: FormItemName.DATA_PROTECTION_HEAD, rules: [{ required: true, message: settingsHead.errors.required() }] }}
        textFieldProps={{ label: settingsHead.label }}
      />
      <AppFormSection formItemProps={{ name: FormItemName.DATA_PROTECTION_SUB }} textFieldProps={{ label: settingsSub.label }} />
      <Form.Item name={FormItemName.DATA_PROTECTION_CONTENT}>
        <TextEditor form={form} />
      </Form.Item>
    </FormCard>
  );
}
