import { createSlice } from '@reduxjs/toolkit';
import {
  detectLocale,
  documentCookieDetector,
  htmlLangAttributeDetector,
  localStorageDetector,
  navigatorDetector,
  queryStringDetector,
  sessionStorageDetector,
} from 'typesafe-i18n/detectors';
import { baseLocale, locales } from '../../localization/i18n-util';

const defaultDetectors = [
  htmlLangAttributeDetector,
  queryStringDetector,
  localStorageDetector,
  documentCookieDetector,
  sessionStorageDetector,
  navigatorDetector,
];

const initialState = {
  locale: baseLocale,
};

const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    initLocale: state => {
      state.locale = detectLocale(baseLocale, locales, ...defaultDetectors);
    },
  },
});

export const { initLocale } = localizationSlice.actions;
export const localization = localizationSlice.reducer;
