import { ComparisonDetailTable } from 'src/processes/CMS/processes/management/modules/ComparisonDetailTable';

/**
 * @desc Layout for Comparison Detail Table template
 * @param {Object} style
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function ComparisonDetailTableLayout({ style, ...rest }) {
  return (
    <div
      style={{
        pointerEvents: 'none',
        width: '1920px',
        height: '863px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        transform: 'scale(0.237) translate(-3096px, -1391px)',
        ...style,
      }}
    >
      <ComparisonDetailTable data={rest.dataObj} {...rest} />
    </div>
  );
}
