const areDifferentColumnsAmountInTables = (table, amount) => table?.tableRows?.some(row => row?.cells?.length !== amount);

export function checkHaveAllTablesSameColumnsAmount(tableList) {
  /*
   In fact in could be not exact the first row of first table, but any other.
   But it's more safe to rely on the first row of first table, because we are sure that it is existed.
   */
  const firstTableFirstRowColumnsAmount = tableList?.[0]?.tableRows?.[0]?.cells?.length;
  return !tableList?.some(table => areDifferentColumnsAmountInTables(table, firstTableFirstRowColumnsAmount));
}
