import { Radio } from 'antd';
import styled from 'styled-components';
import { TranslatableBox } from './boxes/TranslatableBox';

export function AppRadio({ as, color = '#DA1B5E', ...rest }) {
  return <Root {...rest} forwardedAs={Radio} color={color} />;
}

const Root = styled(TranslatableBox)(({ color }) => ({
  // generic
  '&.ant-radio-wrapper .ant-radio-inner': {
    background: 'none !important',
    '&:after': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 'unset',
      height: 'unset',
      margin: '3px !important',
      border: 'none !important',
      transition: 'background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      transform: 'unset !important',
    },
  },

  // default
  '&.ant-radio-wrapper': {
    color: '#191919',

    '.ant-radio-inner': {
      borderColor: '#191919',

      '&:after': {
        background: '#191919',
      },
    },
  },

  // hover
  '&.ant-radio-wrapper:hover': {
    color,

    '.ant-radio-inner': {
      borderColor: color,

      '&:after': {
        background: color,
      },
    },
  },

  // pressed
  '&.ant-radio-wrapper:active .ant-radio-inner': {
    borderColor: '#E1497E !important',

    '&:after': {
      background: '#E1497E !important',
    },
  },

  // disabled
  '&.ant-radio-wrapper-disabled': {
    color: '#9696A2 !important',

    '.ant-radio .ant-radio-inner': {
      borderColor: '#858899 !important',

      '&:after': {
        background: '#858899 !important',
      },
    },
  },
}));
