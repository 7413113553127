import { Button } from '../../../../../../../../components/Button';
import styled from 'styled-components';

/**
 * @desc Layout for CompletedChapters template
 * @param {Array} completedChapters
 * @param {Object} colors
 * @returns {JSX.Element}
 */
export function CompletedChapters({ completedChapters, colors }) {
  return (
    <Root>
      {completedChapters?.map(completedChapter => {
        return (
          <Item key={completedChapter.id}>
            {completedChapter.content.completedChaptersButtons.map(button => {
              return (
                <Button
                  key={button.id}
                  style={{
                    backgroundColor: colors.primaryColor,
                    color: '#FFFFFF',
                  }}
                >
                  {button.buttonText.value}
                </Button>
              );
            })}
          </Item>
        );
      })}
    </Root>
  );
}

const Root = styled('div')({
  maxWidth: 800,
  margin: '10px auto',
});

const Item = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 24,
});
