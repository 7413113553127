import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRulesRequest, removeRuleArranges } from '../../../../../../../lib/store/slices/rulesSlice';
import { getVariablesRequest } from '../../../../../../../lib/store/slices/variablesSlice';
import { isOdd, normalizePageForRequest, isLength } from '../../../../../../../lib/util/helpers';
import { AppTable } from '../../../../../../../ui/AppTable';
import { useRuleActionsColumn } from './columns/actions';
import { useRuleCreationDateColumn } from './columns/creation-date';
import { useRuleLastEditedDateColumn } from './columns/last-edited-date';
import { useRuleStatusColumn } from './columns/status';
import { useRulesNameColumn } from './columns/name';
import { ruleCreatorColumn } from './columns/creator';

/**
 * @desc Rules Table
 * @returns {JSX.Element}
 */
export function RulesTable() {
  const dispatch = useDispatch();
  const { rules: dataSource, pagination, loading } = useSelector(({ rules }) => rules);
  const {
    tenant: { id: tenantId },
    finder: { id: finderId },
  } = useSelector(({ finders }) => finders);

  useEffect(() => {
    if (tenantId && finderId) {
      dispatch(removeRuleArranges());
      dispatch(getRulesRequest());
      dispatch(getVariablesRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finderId, tenantId]);

  const columns = [
    useRulesNameColumn(),
    ruleCreatorColumn,
    useRuleCreationDateColumn(),
    useRuleLastEditedDateColumn(),
    useRuleStatusColumn(),
    useRuleActionsColumn(),
  ];

  return (
    <AppTable
      columns={columns}
      rowKey='id'
      dataSource={dataSource}
      pagination={pagination}
      loading={loading}
      scroll={{ x: isLength(dataSource) ? 'max-content' : null }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      onChange={tablePagination => dispatch(getRulesRequest({ params: { page: normalizePageForRequest(tablePagination.current) } }))}
    />
  );
}
