import { genericCommentColumnProps } from '../../../../../../../../components/columns/comment/useCommentColumn';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { useFinderSearchDataColumn } from '../../components/search-data';
import { Finder } from '../../lib/Finder';

export function useFinderCommentColumn() {
  const { comments: title } = useLocalization().translations.shared.columns;

  return useFinderSearchDataColumn({
    title,
    arrangedId: Finder.COMMENT,
    searchFieldName: 'searchByComment',
    filterFieldName: 'includeComments',
    columnProps: genericCommentColumnProps,
  });
}
