import { handleTranslation } from '../../../../../../../lib/util/helpers';
import { Product } from '../../FindersAndProductsPage/lib/Product';
import { useLocalization } from '../../../../../../../lib/hooks/useLocalization';

const getPricePerMonthText = (priceTypesText, short) => (short ? priceTypesText.pricePerMonthShort : priceTypesText.pricePerMonth);
const getPricePerYearText = (priceTypesText, short) => (short ? priceTypesText.pricePerYearShort : priceTypesText.pricePerYear);

export function useProductPriceTypeText(priceType, rulePriceType, short = false) {
  const { dePriceTypes: priceTypesText } = useLocalization().translations.unique.pages.questionnaire.content; // REFACTOR: place of the translation

  if (rulePriceType) {
    if (rulePriceType === Product.PRICE_PER_MONTH) {
      return handleTranslation(getPricePerMonthText(priceTypesText, short));
    }
    if (rulePriceType === Product.PRICE_PER_YEAR) {
      return handleTranslation(getPricePerYearText(priceTypesText, short));
    }
    return '';
  }

  return priceType
    ? handleTranslation(
        priceType === Product.PRICE_PER_MONTH
          ? getPricePerMonthText(priceTypesText, short)
          : priceType === Product.PRICE_PER_YEAR
          ? getPricePerYearText(priceTypesText, short)
          : ''
      )
    : '';
}
