import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppInput } from '../../../../../../../../../../../ui/AppInput';
import classes from '../Flow.module.sass';
import classNames from 'classnames';

/**
 * @desc Flow Button Info
 * @param {String} buttonTitle
 * @param {String} buttonText
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function FlowButtonInfo({ buttonTitle, buttonText, className, ...rest }) {
  return (
    <div className={classNames(className, classes.buttonInfo)} {...rest}>
      <AppText textValue={buttonTitle} style={styles.title} />
      <AppInput value={buttonText} disabled />
    </div>
  );
}

const styles = {
  title: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 700,
  },
};
