import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../ui/text/AppText';
import { DeleteIcon } from '../../../../../../ui/icons/DeleteIcon';
import { PreloaderIcon } from '../../../../../../ui/icons/PreloaderIcon';
import './styles.scss';

export function UploadedMediaRow({ media, deleteOnClick, uploading, imgHeight = 52 }) {
  const deleteClick = event => {
    event.stopPropagation();
    deleteOnClick();
  };

  return (
    <div className='align_items_center space_between'>
      <div className='align_items_center text_container'>
        <div className='media_row_image_wrap'>
          {uploading ? (
            <PreloaderIcon className='file_uploader' style={{ width: 24, height: 24 }} />
          ) : (
            <img className='media_row_image' src={media.s3Url} alt='avatar' style={{ height: imgHeight }} />
          )}
        </div>
        {media.originalFileName && !uploading && (
          <AppText ellipsis className='media_row_file_name'>
            {media.originalFileName}
          </AppText>
        )}
      </div>
      {media.s3Url && !uploading && (
        <AppBasicButton style={{ display: 'flex', alignItems: 'center', marginRight: 10 }} onClick={deleteClick}>
          <DeleteIcon className='secondary_color' />
        </AppBasicButton>
      )}
    </div>
  );
}
