import { MinMax } from '../../../../../../features/MinMax';
import { useUpdateStructure } from '../../../../../../hooks/useUpdateStructure';
import lodashSet from 'lodash.set';

export function CounterMinMax({ disabled, min, max, dataPath }) {
  const { updateStructure } = useUpdateStructure();

  const changeMin = value => {
    updateStructure(draft => {
      lodashSet(draft, `${dataPath}.min`, value);
    });
  };

  const changeMax = value => {
    updateStructure(draft => {
      lodashSet(draft, `${dataPath}.max`, value);
    });
  };

  return (
    <MinMax minTextFieldProps={{ value: min, onChange: changeMin, disabled }} maxTextFieldProps={{ value: max, onChange: changeMax, disabled }} />
  );
}
