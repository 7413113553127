import { QuestionnaireTextButton } from '../../../../../../../features/QuestionnaireTextButton';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { generateAdditionalInfo } from './generateAdditionalInfo';

export function AddAdditionalInfoButton({ structureItemIndex, disabled }) {
  const { additionalInformation } = useLocalization().translations.unique.pages.result.productsDepot;

  const { updateStructure } = useUpdateStructure();

  const addAdditionalInfo = () => {
    updateStructure(draft => {
      draft[structureItemIndex]?.content.additionalInformation.push(generateAdditionalInfo());
    });
  };

  return <QuestionnaireTextButton textValue={additionalInformation.addAdditionalInformationButton} disabled={disabled} onClick={addAdditionalInfo} />;
}
