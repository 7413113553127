import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { CounterFields } from '../../components/CounterFields';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import styled from 'styled-components';

export function ProductTopSectionCounter({ structureItem, structureItemIndex }) {
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;

  return (
    <Root>
      <QuestionnaireSwitch disabled style={{ paddingTop: '11px' }} labelProps={{ textValue: contentText.showCounter }} isOn />
      <CounterFields data={structureItem.content.counter} dataPath={`[${structureItemIndex}].content.counter`} style={{ flex: 1 }} />
    </Root>
  );
}

const Root = styled('div')({
  gap: '24px',
  display: 'flex',
  alignItems: 'flex-start',
});
