import { CategorySearchDataHeaderCell } from './CategorySearchDataHeaderCell';

export function useCategorySearchDataColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <CategorySearchDataHeaderCell {...rest} />,
  };
}
