import { ButtonOrLinkComponent } from '../../ButtonOrLinkComponent';
import styled from 'styled-components';
import { useResultProps } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useResultProps';

export function CompletedChapter({
  allPages,
  completedChapter,
  pagesFlow,
  setPagesFlow,
  colors,
  fonts,
  fontsForLoading,
  pageHistory,
  setPageHistory,
  currentElements,
}) {
  const { content: { completedChaptersButtons } = {}, id } = completedChapter;

  const { printedResultProps = {} } = useResultProps({
    allPages,
    resultObject: completedChapter,
    fonts,
    colors,
    pagesFlow,
    fontsForLoading,
    pageHistory,
  });

  return (
    <Item key={id}>
      {completedChaptersButtons.map(button => {
        return (
          <ButtonOrLinkComponent
            key={button.id}
            contentObject={{ flow: button.flow, value: button.buttonText.value, id: button.id }}
            style={{
              backgroundColor: colors.primaryColor,
              height: 43,
              minWidth: 180,
              borderRadius: 24,
              padding: '5px 8px',
              color: '#FFFFFF',
            }}
            setPagesFlow={setPagesFlow}
            pagesFlow={pagesFlow}
            textStyle={{
              fontSize: fonts?.body.fontSize,
              fontFamily: fonts?.body.font,
              fontWeight: fonts?.body.fontWeight,
            }}
            setPageHistory={setPageHistory}
            printedResultProps={printedResultProps}
            currentElements={currentElements}
          />
        );
      })}
    </Item>
  );
}

const Item = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 24,
});
