import { useSelector } from 'react-redux';
import { AppParagraph } from '../../../../../../../../../../../../ui/text/AppParagraph';
import { AppBox } from '../../../../../../../../../../../../ui/boxes/AppBox';
import { useProductOneCounterExample } from './lib/useProductOneCounterExample';
import { isLength } from '../../../../../../../../../../../../lib/util/helpers';
import { ProductLayoutScrollButtons } from '../components/ProductLayoutScrollButtons';
import { ProductFlowButtons } from '../components/ProductFlowButtons';
import styled from 'styled-components';
import { PRODUCT_ONE_COUNTER_TEMPLATE_DEFAULT_CONTENT } from '../../../../../../../../../../../../lib/models/Questionnaire';
import lodashIsEqual from 'lodash.isequal';
import { formatEditedProductOneCounter } from './lib/formatEditedProductOneCounter';
import { ProductListWithoutCounter } from './component/ProductListWithoutCounter';
import { ProductOneCounterSection } from './component/ProductOneCounterSection';
import { InfoIcon } from '../../../../../../../../../../../../ui/icons/InfoIcon';
import { ButtonOrLinkComponent } from '../../../../../../../PreviewPage/components/ButtonOrLinkComponent';

const SELECTED_PRODUCT_INDEX = 0;
const RECOMMENDED_PRODUCT_INDEX = 1;

/**
 * @desc Product One Counter Layout
 * @param {Object} props
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function ProductOneCounterLayout(props) {
  const {
    selectedQuestionnaireElementIndex,
    questionnaireStructure,
    finder: {
      finderSettings: {
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);
  const productTemplateContent = questionnaireStructure?.[selectedQuestionnaireElementIndex]?.content;
  const productExample = useProductOneCounterExample();
  const isTemplateEdited = !lodashIsEqual(productTemplateContent, PRODUCT_ONE_COUNTER_TEMPLATE_DEFAULT_CONTENT);

  const data = isTemplateEdited ? formatEditedProductOneCounter(productTemplateContent, customIcons, defaultIcons) : productExample;
  const { list = [], headline, headlineInfoIcon, bulletPointIconSvg, bulletPointIconUrl, bulletPointIconColor, counter, composerButton } = data;
  const { customStyles = {}, style, innerStyle, stylesConfiguration, ...rest } = props;
  const isList = isLength(list);

  const { fonts, colors } = stylesConfiguration;

  if (list?.[SELECTED_PRODUCT_INDEX]) {
    list[SELECTED_PRODUCT_INDEX].data.selected = true;
  }

  if (list?.[RECOMMENDED_PRODUCT_INDEX]) {
    list[RECOMMENDED_PRODUCT_INDEX].data.recommended = true;
  }

  counter.value = 3;

  return (
    <Root $flexY style={{ ...style, ...customStyles.root }} {...rest}>
      <Inner style={{ ...innerStyle, ...customStyles.inner }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AppParagraph as='p' textValue={headline} style={{ marginBottom: '27px', textAlign: 'center' }} />
          {headlineInfoIcon?.value && (
            <span style={{ marginLeft: 10, marginTop: -20 }}>
              <InfoIcon />
            </span>
          )}
        </div>
        {isList && (
          // REFACTOR: passed props
          <ProductListWithoutCounter
            allData={data}
            list={list}
            bulletPointIconUrl={bulletPointIconUrl}
            bulletPointIconSvg={bulletPointIconSvg}
            bulletPointIconColor={bulletPointIconColor}
          />
        )}

        <ProductLayoutScrollButtons style={{ margin: '22px 0 40px' }} />

        {isList && <ProductOneCounterSection counter={counter} />}

        {composerButton && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
            <ButtonOrLinkComponent
              contentObject={composerButton}
              disabled
              style={{
                backgroundColor: colors.primaryColor,
                height: 48,
                minWidth: 200,
                borderRadius: 24,
                padding: '5px 8px',
                color: '#FFFFFF',
              }}
              textStyle={{
                fontSize: fonts.body.fontSize,
                fontFamily: fonts.body.font,
                fontWeight: fonts.body.fontWeight,
              }}
            />
          </div>
        )}

        <ProductFlowButtons style={{ marginTop: '22px' }} />
      </Inner>
    </Root>
  );
}

const Root = styled(AppBox)({
  height: '100%',
  minHeight: '100%',
});

const Inner = styled(AppBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '30px 0',
  height: '100%',
});
