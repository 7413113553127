import { QuestionnaireVerticalGripHandle } from '../../../../../features/QuestionnaireVerticalGripHandle';
import React from 'react';
import { ContactFormRowFieldActions } from './ContactFormRowFieldActions';
import { ContactFormRowFieldParts } from './ContactFormRowFieldParts';

const gap8Px = { gap: '8px' };
const gap16Px = { gap: '16px' };

export function ContactFormRowFieldTop(props) {
  const { providedDraggable } = props;

  return (
    <div className='flex flex-y-c w-100 flex-sb' style={gap16Px}>
      <div className='flex flex-y-c w-100' style={gap8Px}>
        <QuestionnaireVerticalGripHandle dragHandleProps={providedDraggable.dragHandleProps} />
        <ContactFormRowFieldParts {...props} />
      </div>

      <ContactFormRowFieldActions {...props} />
    </div>
  );
}
