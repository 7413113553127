import React from 'react';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { DroppableFieldWrapper } from '../../DroppableItems/DroppableFieldWrapper';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';

export function HeaderBlock({ item, onChangeFieldValue, onChangeFieldInfoIconValue, onDeleteInfoIcon }) {
  const { title } = useLocalization().translations.unique.pages.result;
  const { templateHeadline, templateSubline, templateInfo } = useLocalization().translations.unique.formItems;
  return (
    <div className='template_card_header_block'>
      <AppText className='template_card_headline' textValue={title} />
      {item.content?.headline && (
        <DroppableFieldWrapper
          itemId={item.id}
          labelField={templateHeadline.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.headline?.infoIcon && 'INFO_ICON'}
          valueObject={item.content.headline}
          onChangeField={onChangeFieldValue}
          onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
          onDeleteInfoIcon={onDeleteInfoIcon}
          fieldName='headline'
          infoIcon={item.content.headline?.infoIcon}
        />
      )}
      {item.content?.subline && (
        <DroppableFieldWrapper
          itemId={item.id}
          labelField={templateSubline.label}
          labelTemplateInfo={templateInfo.label}
          type={!item.content.subline?.infoIcon && 'INFO_ICON'}
          valueObject={item.content.subline}
          onChangeField={onChangeFieldValue}
          onChangeFieldInfoIconValue={onChangeFieldInfoIconValue}
          onDeleteInfoIcon={onDeleteInfoIcon}
          fieldName='subline'
          infoIcon={item.content.subline?.infoIcon}
        />
      )}
    </div>
  );
}
