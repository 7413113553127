import { Space } from 'antd';
import styled from 'styled-components';
import { AppBox } from './boxes/AppBox';

const StyledSpace = styled(Space)(({ $itemStyles = {} }) => ({
  alignItems: 'flex-start',
  '& > .ant-space-item': $itemStyles,
}));

export function AppSpace(props) {
  return <AppBox {...props} as={StyledSpace} />;
}
