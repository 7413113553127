import { useLocalization } from 'src/lib/hooks/useLocalization';
import { useOutsideClick } from 'src/lib/hooks/useOutsideClick';

import { QuestionMark } from 'src/ui/icons/QuestionMark';
import { ChevronDownIcon } from 'src/ui/icons/ChevronDownIcon';
import { AppTooltip } from 'src/ui/AppTooltip';
import { Action } from './Action';

import styles from './Actions.module.scss';
import { useRef, useState } from 'react';

/**
 * @desc Action Dropdown
 * @param {Object} item
 * @param {Number | String} conditionId
 * @returns {JSX.Element}
 */
export function ActionDropdown({ item, conditionId }) {
  const { children, disabled, name: actionName, tooltip } = item;

  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(containerRef, () => {
    setIsOpen(false);
  });

  const { builder: builderTranslations } = useLocalization().translations.unique.pages;

  const comparisonTooltip = (
    <div className={styles.tooltipContainer}>
      <div>{builderTranslations.moreThen()}</div>
      <div>{builderTranslations.lessThen()}</div>
      <div>{builderTranslations.moreOrEqual()}</div>
      <div>{builderTranslations.lessOrEqual()}</div>
      <div>{builderTranslations.equal()}</div>
      <div>{builderTranslations.notEqual()}</div>
    </div>
  );

  const arithmeticTooltip = (
    <div className={styles.tooltipContainer}>
      <div>{builderTranslations.addition()}</div>
      <div>{builderTranslations.subtraction()}</div>
      <div>{builderTranslations.multiplication()}</div>
      <div>{builderTranslations.division()}</div>
      <div>{builderTranslations.assign()}</div>
      <div>{builderTranslations.additionAndAssign()}</div>
      <div>{builderTranslations.divisionAndAssign()}</div>
    </div>
  );

  const tooltipEnum = {
    arithmetic: arithmeticTooltip,
    comparison: comparisonTooltip,
  };

  return (
    <div
      className={`${styles.actionDropdown} ${styles.action} ${isOpen ? styles.open : ''} ${disabled ? styles.disabled : ''}`}
      onClick={() => setIsOpen(disabled ? isOpen : !isOpen)}
      aria-hidden='true'
      ref={containerRef}
    >
      <span>{actionName}</span>

      {tooltip && (
        <AppTooltip htmlText={tooltipEnum[tooltip]}>
          <div className={styles.iconHolder}>
            <QuestionMark fill={disabled ? '#CFCFD4' : '#DA1B5E'} />
          </div>
        </AppTooltip>
      )}

      <div className={`${styles.chevron} ${styles.iconHolder} `}>
        <ChevronDownIcon fill='#DA1B5E' />
      </div>
      <div className={styles.items}>
        {children.map(action => (
          <Action key={action.name} conditionId={conditionId} item={action} type='dropdown' />
        ))}
      </div>
    </div>
  );
}
