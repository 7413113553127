import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { getArrangesInitialValues } from '../../../../../../../../utils/arranges-helpers';
import { useFinderArrangeRequests } from '../../lib/useFinderArrangeRequests';
import { useArrangeSubmit } from '../../../../../../../../utils/hooks/useArrangeSubmit';
import { TableHeaderCell } from '../../../../../../../../components/TableHeaderCell';
import { SortPopup } from '../../../../../../../../components/popups/SortPopup';

export function FinderNumberHeaderCell({ title, arrangedId, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();

  const { applyArranges } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    form,
  });

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    withArrange: false, // TODO: enable me when BE add the sorting
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <SortPopup initialValues={getArrangesInitialValues(form)} form={form} onFinish={onFinish} isActiveArrange={isActiveArrange} />
    </TableHeaderCell>
  );
}
