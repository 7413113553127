import { v4 as uuidv4 } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { DraggableFormSectionContent } from '../DraggableFormSectionContent';
import { GripVerticalIcon } from '../../../../../../ui/icons/GripVerticalIcon';
import { AppBasicButton } from '../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../ui/text/AppText';
import './styles.scss';
import { AppBox } from '../../../../../../ui/boxes/AppBox';

export function DraggableFormListContent({ valueArray, onChange, label, headingLabel, addButtonText, item, contentBlockIndex }) {
  const { isViewMode } = useSelector(({ finders }) => finders);

  const addValue = () => {
    const updatedArr = [...valueArray, { id: uuidv4(), textValue: '', headline: '' }];
    onChange(updatedArr);
  };

  return (
    <>
      <div className='draggable_zone_block'>
        <Droppable droppableId={`${item.id}_${contentBlockIndex}`} type='BULLET_POINT'>
          {provided => (
            <div ref={provided.innerRef}>
              {valueArray.map((valueObj, index) => (
                <Draggable key={valueObj.id} draggableId={valueObj.id} index={index} isDragDisabled={isViewMode}>
                  {prov => (
                    <div ref={prov.innerRef} {...prov.draggableProps}>
                      <div className='draggable_input_wrap'>
                        <DraggableFormSectionContent
                          index={index}
                          valueObject={valueObj}
                          onChange={onChange}
                          valueArray={valueArray}
                          label={label}
                          headingLabel={headingLabel}
                          item={item}
                          contentBlockIndex={contentBlockIndex}
                        />
                        <AppBox disabled={isViewMode} {...prov.dragHandleProps} className='draggable_input_drag_icon'>
                          <GripVerticalIcon />
                        </AppBox>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <AppBasicButton onClick={addValue} disabled={isViewMode}>
        <AppText className='primary_color bold' textValue={addButtonText} />
      </AppBasicButton>
    </>
  );
}
