import { useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionDropBox } from '../../../../../../features/QuestionDropBox';
import { QuestionAnswerScoring } from './QuestionAnswerScoring';
import { QuestionChartScoring } from './QuestionChartScoring';
import { QuestionCounterScoring } from './QuestionCounterScoring';
import { TemplateTypes } from '../../../../../../../../../../lib/models/TemplateTypes';
import { isLength } from '../../../../../../../../../../lib/util/helpers';
import './style.scss';

/**
 * @desc Filters out questions from an array of items based on their template type.
 * @param {Array} items - An array of items to filter.
 * @returns {Array} An array containing only the elements with template type 'QUESTION'.
 */
const filterQuestions = items => items?.filter(item => item.templateType === TemplateTypes.QUESTION);

/**
 * @desc Renders the question scoring component.
 * @returns {JSX.Element} The rendered question scoring component.
 */
export function QuestionScoring() {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);

  const [selectedQuestion, selectQuestion] = useState({
    elementId: filterQuestions(questionnaireStructure)[0].id,
    answerId: isLength(filterQuestions(questionnaireStructure)[0].content.answers)
      ? filterQuestions(questionnaireStructure)[0].content.answers[0].id
      : null,
  });

  const questionElement = filterQuestions(questionnaireStructure).find(element => element.id === selectedQuestion.elementId);

  return (
    <div className='questions_scoring'>
      <div className='questions_drops_wrap'>
        {filterQuestions(questionnaireStructure).map((element, index) => (
          <QuestionDropBox key={element.id} question={element} index={index} selectedQuestion={selectedQuestion} selectQuestion={selectQuestion} />
        ))}
      </div>
      <div className='products_score_wrap'>
        {questionElement?.content?.answers && <QuestionAnswerScoring questionElement={questionElement} selectedQuestion={selectedQuestion} />}
        {questionElement?.content?.chartAnswers && <QuestionChartScoring questionElement={questionElement} selectedQuestion={selectedQuestion} />}
        {questionElement?.content?.counterAnswers && <QuestionCounterScoring questionElement={questionElement} selectedQuestion={selectedQuestion} />}
      </div>
    </div>
  );
}
