/* eslint-disable max-lines-per-function */
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { Form, message } from 'antd';
import { useState } from 'react';
import { AppText } from '../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { bytesToMegabytes, createFormData, handleTranslation } from '../../../../../lib/util/helpers';
import { AppUpload } from '../../../../../ui/AppUpload';
import { ImagesIcon } from '../../../../../ui/icons/ImagesIcon';
import { AppFormSection } from '../../../../../ui/AppFormSection';
import { Product } from '../pages/FindersAndProductsPage/lib/Product';
import { uploadMediaApiRequest } from '../../../../../lib/api';
import { errorFieldBeforeStyles, hoverFieldBeforeStyles, initialFieldBeforeStyles, resetFieldStyles } from '../../../../../lib/styles/common';
import { UploadedMediaBox } from './UploadedMediaBox';
import { useSelector } from 'react-redux';

const LIMIT_SIZE = 10; // MB

const INNER_CLASS_NAME = 'ChooseImageFormItemInner';

export function ChooseImageFormItem() {
  const [messageApi, contextHolder] = message.useMessage();
  const { chooseImage } = useLocalization().translations.unique.formItems;
  const { id: tenantId } = useParams();
  const { tenantId: tenantIdForUser } = useSelector(({ auth }) => auth);

  const actualTenantId = tenantId || tenantIdForUser;

  const form = Form.useFormInstance();
  const [fileList, setFileList] = useState([]);
  const imageLink = Form.useWatch(Product.IMAGE_LINK, form);
  const errorMessage = chooseImage.errors.maxSize({ amount: LIMIT_SIZE, unit: 'mb' });
  const { requiredImage, corrupted } = chooseImage.errors;

  const upload = async ({ file }) => {
    try {
      const fileForUpload = await imageCompression(file, { maxSizeMB: LIMIT_SIZE });

      const formData = createFormData({
        file: file.type === 'image/svg+xml' ? file : fileForUpload,
        targetType: 'PRODUCT',
        originalFileName: file?.name,
      });
      const { data } = await uploadMediaApiRequest(formData, actualTenantId);

      const { s3Url } = data;
      form.setFieldValue(Product.IMAGE_LINK, s3Url);
    } catch {
      messageApi.error(handleTranslation(corrupted));
      deleteImage();
    }
  };

  const deleteImage = () => {
    setFileList([]);
    form.setFieldValue(Product.IMAGE_LINK, null);
  };

  const onChange = info => {
    const sizeInMB = bytesToMegabytes(info.file?.size);
    const isSizeOk = sizeInMB <= LIMIT_SIZE;

    if (!info.file || isSizeOk) {
      form.setFields([{ name: Product.IMAGE_LINK, errors: [] }]);
      setFileList([...info.fileList]);
      return;
    }

    form.setFields([{ name: Product.IMAGE_LINK, errors: [handleTranslation(errorMessage)] }]);
  };

  return (
    <>
      {contextHolder}
      <Root
        formItemProps={{
          name: Product.IMAGE_LINK,
          rules: [{ required: true, message: handleTranslation(requiredImage) }],
        }}
      >
        <AppUpload
          accept='image/jpeg, image/png'
          listType='picture-card'
          showUploadList={false}
          customRequest={upload}
          onChange={onChange}
          fileList={fileList}
        >
          <div className={INNER_CLASS_NAME}>
            {imageLink ? (
              <UploadedMediaBox media={{ s3Url: typeof imageLink === 'string' ? imageLink : false }} deleteOnClick={deleteImage} />
            ) : (
              <>
                <ImagesIcon />
                <AppText type='secondary' textValue={chooseImage.text} style={styles.text} />
              </>
            )}
          </div>
        </AppUpload>
      </Root>
    </>
  );
}

const selectStyles = {
  '.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select': {
    margin: '0 !important',
    border: 'none !important',
    width: '100% !important',
    height: '121px !important',
  },
};

const innerStyles = {
  [`.${INNER_CLASS_NAME}`]: {
    ...resetFieldStyles,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&:before': {
      ...initialFieldBeforeStyles,
      borderStyle: 'dashed',
    },
  },
  [`.${INNER_CLASS_NAME}:hover:before`]: hoverFieldBeforeStyles,
  [`.ant-form-item-has-error .${INNER_CLASS_NAME}:before`]: errorFieldBeforeStyles,
};

const Root = styled(AppFormSection)({
  ...selectStyles,
  ...innerStyles,
});

const styles = {
  text: { fontSize: 12, marginTop: 7, color: '#5C5C6E' },
};
