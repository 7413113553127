import { Fragment } from 'react';

import { Action } from './Action';
import { ActionDropdown } from './ActionDropdown';
import { actionTypes } from './actionTypes';

import { useActionValidation } from 'src/processes/CMS/processes/management/pages/BuilderPage/hooks/useActionValidation';

import styles from './Actions.module.scss';

/**
 * @desc Actions
 * @param {String} conditionId
 * @param {Array} conditionBody
 * @returns {JSX.Element}
 */
export function Actions({ conditionId, conditionBody }) {
  const { validatedActions } = useActionValidation(actionTypes, conditionBody);

  return (
    <div className={styles.actions}>
      {validatedActions.map(action => (
        <Fragment key={action.name}>
          {action.children ? <ActionDropdown conditionId={conditionId} item={action} /> : <Action conditionId={conditionId} item={action} />}
          <div className={styles.separator} />
        </Fragment>
      ))}
    </div>
  );
}
