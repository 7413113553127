import { AppNewSelect } from '../../../../../../../ui/AppNewSelect';
import { AppFormSection } from '../../../../../../../ui/AppFormSection';
import { useSelector } from 'react-redux';
import './QuestionnaireSelect.sass';

export function QuestionnaireSelect({ selectProps = {}, size, ...rest }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { disabled, isOverflowHidden, ...restSelectProps } = selectProps;

  return (
    <AppFormSection size={size} {...rest}>
      <AppNewSelect
        disabled={isViewMode || disabled}
        size={size}
        {...restSelectProps}
        classNames={{
          valueContainer: () => (isOverflowHidden ? 'questionnaireSelectOverflowHidden' : 'questionnaireSelect'),
        }}
      />
    </AppFormSection>
  );
}
