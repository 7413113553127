/* eslint-disable no-magic-numbers */
import { getNextIndex, humanizeIndex, isFalsy, isLength } from '../../../../../../../lib/util/helpers';
import { FIRST_INDEX } from '../../../../../../../lib/util/constants';
import { v4 as uuidv4 } from 'uuid';
import { TemplateTypes } from '../../../../../../../lib/models/TemplateTypes';

export function formatOptions(options) {
  return isLength(options) ? options.map(product => ({ value: product.id, title: product.name })) : [];
}

export function updateInfoIconsIndexes(tableRows) {
  if (!Array.isArray(tableRows)) return [];

  let currentIndex = humanizeIndex(FIRST_INDEX);

  for (const row of tableRows) {
    const isCellsArr = row && Array.isArray(row?.cells);
    if (!isCellsArr) continue;

    for (const cell of row.cells) {
      const infoIconIndex = cell?.infoIcon?.infoIconIndex;
      if (isFalsy(infoIconIndex)) continue;

      cell.infoIcon.infoIconIndex = currentIndex;
      currentIndex = getNextIndex(currentIndex);
    }
  }

  return tableRows;
}

export function newAnswerRange(destination) {
  return { minValue: '', maxValue: '', id: uuidv4(), thenGoTo: destination };
}

export function fillUniqueRangesAndAddNew(array, uniqueFieldName, startValue, minStart = 1) {
  const uniqueRangesArrayResult = [];
  let newRangeAdded = false;

  if (array.length === 0) {
    uniqueRangesArrayResult.push({ minValue: minStart, maxValue: minStart, id: uuidv4(), [uniqueFieldName]: startValue });
    newRangeAdded = true;
  }

  for (let i = 0; i < array.length; i += 1) {
    const currentRange = array[i];
    const nextRange = array[i + 1];

    if (!newRangeAdded && i === 0 && currentRange.minValue > 1) {
      uniqueRangesArrayResult.push({ id: uuidv4(), [uniqueFieldName]: startValue, minValue: 1, maxValue: 1 });
      newRangeAdded = true;
    }

    uniqueRangesArrayResult.push({
      [uniqueFieldName]: currentRange[uniqueFieldName],
      id: currentRange.id,
      minValue: currentRange.minValue,
      maxValue: currentRange.maxValue,
    });

    if (!newRangeAdded && nextRange && nextRange.minValue - currentRange.maxValue > 1) {
      uniqueRangesArrayResult.push({
        id: uuidv4(),
        [uniqueFieldName]: startValue,
        minValue: currentRange.maxValue + 1,
        maxValue: currentRange.maxValue + 1,
      });
      newRangeAdded = true;
    }
  }

  if (!newRangeAdded && array.length > 0) {
    const lastRange = array.at(-1);
    uniqueRangesArrayResult.push({
      minValue: lastRange.maxValue + 1,
      maxValue: lastRange.maxValue + 1,
      id: uuidv4(),
      [uniqueFieldName]: startValue,
    });
  }
  return uniqueRangesArrayResult;
}

const isRangeOverlaps = (counterAnswersRanges, currentRange, fieldName, targetValue) => {
  return counterAnswersRanges.some(range => {
    if (range.id === currentRange.id) {
      return false;
    }

    const newRangeStart = fieldName === 'minValue' ? targetValue : currentRange.minValue;
    const newRangeEnd = fieldName === 'maxValue' ? targetValue : currentRange.maxValue;

    return newRangeStart <= range.maxValue && range.minValue <= newRangeEnd;
  });
};

export function updateRange(targetValue, fieldName, rangeId, counterAnswersRanges, updateRangeObject) {
  const currentRange = counterAnswersRanges.find(range => range.id === rangeId);

  if (fieldName === 'minValue' && currentRange.maxValue === '') {
    updateRangeObject(currentRange.id, fieldName, targetValue);
    return;
  }

  if (fieldName === 'maxValue' && currentRange.minValue === '') {
    updateRangeObject(currentRange.id, fieldName, targetValue);
    return;
  }

  if (isRangeOverlaps(counterAnswersRanges, currentRange, fieldName, targetValue)) {
    return;
  }

  updateRangeObject(currentRange.id, fieldName, targetValue);
}

export function updateRangeValue(targetValue, fieldName, rangeId, minNumber, maxValue, previousObject, nextObject, updateRangeObject) {
  if (Number.isInteger(targetValue) && targetValue >= minNumber && targetValue <= maxValue) {
    if (previousObject && nextObject && targetValue > previousObject.maxValue && targetValue < nextObject.minValue) {
      updateRangeObject(rangeId, fieldName, targetValue);
    }
    if (previousObject && !nextObject && targetValue > previousObject.maxValue) {
      updateRangeObject(rangeId, fieldName, targetValue);
    }
    if (nextObject && !previousObject && targetValue < nextObject.minValue) {
      updateRangeObject(rangeId, fieldName, targetValue);
    }
    if (!previousObject && !nextObject) {
      updateRangeObject(rangeId, fieldName, targetValue);
    }
  }
}

export function toggleChapterElements(structure, chapterId, isOpen) {
  let isChapterFound = false;
  const result = [];

  for (let element of structure) {
    const isChapter = element.templateType === TemplateTypes.CHAPTER;
    const isChildElement = ![TemplateTypes.PAGE, TemplateTypes.CHAPTER].includes(element.templateType);

    if (isChapter && element.id === chapterId) {
      isChapterFound = true;
    } else if (isChapterFound && isChildElement) {
      element = { ...element, isCollapsed: !isOpen };
    }

    result.push(element);

    const isNextChapter = isChapterFound && isChapter && element.id !== chapterId;
    if (isNextChapter) {
      result.push(...structure.slice(result.length));
      break;
    }
  }

  return result;
}
