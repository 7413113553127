import styled from 'styled-components';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import { AppText } from '../../../text/AppText';
import { multiValueChipLabelStyles, multiValueChipStyles } from '../../lib/AppNewSelect-styles';

export function MoreSelectedBadge({ items }) {
  const { moreSelected } = useLocalization().translations.unique.components.select;
  const amount = items.length;
  const title = items.join(', ');

  return (
    <Root title={title}>
      <AppText ellipsis textValue={moreSelected({ amount })} style={multiValueChipLabelStyles} />
    </Root>
  );
}

const Root = styled('div')({
  ...multiValueChipStyles,
  paddingRight: '10px',
});
