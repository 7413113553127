import { AppTitle } from '../../../../../ui/text/AppTitle';
import { AppRadioGroup } from '../../../../../ui/AppRadioGroup';
import { AppRadio } from '../../../../../ui/AppRadio';
import { TableSortOrder } from '../../../lib/consts';
import { AppBox } from '../../../../../ui/boxes/AppBox';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { AppFormSection } from '../../../../../ui/AppFormSection';
import { ArrangeFormItem } from '../utils/model/Arrange';

export function SortBlock() {
  const { arranges } = useLocalization().translations.shared;

  return (
    <AppBox>
      <AppTitle level={3} textValue={arranges.sort.title} type='secondary' style={{ fontSize: '12px', marginBottom: '16px' }} />

      <AppFormSection
        formItemProps={{
          name: ArrangeFormItem.SORT_ORDER,
          // rules: [{ required: true }], // TODO: enable
        }}
      >
        <AppRadioGroup $flexColumn>
          <AppRadio value={TableSortOrder.ASC} textValue={arranges.sort.radioButtons.acs} />
          <AppRadio value={TableSortOrder.DESC} textValue={arranges.sort.radioButtons.desc} />
          <AppRadio value={TableSortOrder.NONE} textValue={arranges.sort.radioButtons.none} />
        </AppRadioGroup>
      </AppFormSection>
    </AppBox>
  );
}
