import { forwardRef } from 'react';
import { AppBox } from './AppBox';
import { handleTranslation } from '../../lib/util/helpers';

export const TranslatableBox = forwardRef((props, ref) => {
  const { textValue, children = null, ...rest } = props;
  const rendered = (children || handleTranslation(textValue)) ?? null;

  return (
    <AppBox {...rest} ref={ref}>
      {rendered}
    </AppBox>
  );
});
