import { Form } from 'antd';
import { AppModal } from '../../../../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { EditProductForm } from './EditProductForm';
import { useResetFormOnCloseModal } from '../../../../../../../../../lib/hooks/useResetFormOnCloseModal';

export function EditProductModal({ close, isOpen, isModalLoading, productData }) {
  const { title } = useLocalization().translations.unique.pages.products.editModal;
  const [form] = Form.useForm();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <AppModal
      open={isOpen}
      width={936}
      titleProps={{ textValue: title }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: form.submit, loading: isModalLoading }}
    >
      {isOpen && <EditProductForm form={form} productData={productData} />}
    </AppModal>
  );
}
