import { AppFormSection } from '../ui/AppFormSection';
import { useLocalization } from '../lib/hooks/useLocalization';
import { Product } from '../processes/CMS/processes/management/pages/FindersAndProductsPage/lib/Product';

export function ProductShortNameFormItem(props) {
  const { label } = useLocalization().translations.unique.formItems.productShortName;
  const { formItemProps, textFieldProps } = props;

  return (
    <AppFormSection
      formItemProps={{
        name: Product.SHORT_NAME,
        rules: [{ required: true }, { whitespace: true }],
        ...formItemProps,
      }}
      textFieldProps={{
        label,
        ...textFieldProps,
      }}
    />
  );
}
