import { useCallback, useState } from 'react';
import lodashDebounce from 'lodash.debounce';
import { ArrangeFormItem } from '../../model/Arrange';
import { ArrangeListType, MINIMAL_SEARCH_LENGTH } from '../../consts';

const DEBOUNCED_DELAY = 600; // ms

// REFACTOR: rename "formatResponse"
export function useStringArrange({ form, request, formatResponse, minSearchLength = MINIMAL_SEARCH_LENGTH }) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(null);

  const clearOptions = () => setOptions([]);

  const handleRequest = async value => {
    if (value?.length >= minSearchLength) {
      form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, []); // on typing remove previous selected items
      const { data } = await request(value);
      const formattedResponse = formatResponse(data);
      setOptions(formattedResponse);
    } else {
      clearOptions();
    }

    setIsLoading(false);
  };

  const debounced = lodashDebounce(handleRequest, DEBOUNCED_DELAY);

  const onChangeSearchField = useCallback(async event => {
    try {
      const { value } = event.target;
      if (value?.length >= minSearchLength) {
        setIsLoading(true);
      }
      await debounced(value);
    } catch {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { filtering: { onChangeSearchField, options, setOptions, clearOptions, isLoading, type: ArrangeListType.STRING } };
}
