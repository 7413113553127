import { useDispatch, useSelector } from 'react-redux';
import { ProductModal } from '../../../../../../../lib/models/Modal';
import { AddProductModal } from './components/AddProductModal';
import { closeProductModal } from '../../../../../../../../../lib/store/slices/productsSlice';
import { EditProductModal } from './components/EditProductModal';
import { DeleteProductModal } from './components/DeleteProductModal';

export function ProductModals() {
  const { isModalOpen, modalType: currentModalType, selectedProduct, isModalLoading } = useSelector(({ products }) => products);
  const dispatch = useDispatch();

  const close = () => dispatch(closeProductModal());
  const checkIsOpen = modalType => isModalOpen && modalType === currentModalType;

  const genericProps = { productData: selectedProduct, close, isModalLoading };

  return (
    <>
      <AddProductModal {...genericProps} isOpen={checkIsOpen(ProductModal.ADD)} />
      <EditProductModal {...genericProps} isOpen={checkIsOpen(ProductModal.EDIT)} />
      <DeleteProductModal {...genericProps} isOpen={checkIsOpen(ProductModal.DELETE)} />
    </>
  );
}
