import { AppText } from 'src/ui/text/AppText';
import { DefaultColors } from 'src/processes/CMS/lib/models/DefaultStylesFontsAndColors';
import { OptionRow } from './OptionRow';

import { useLocalization } from 'src/lib/hooks/useLocalization';

export function ComparisonOptions({ comparisonOptions, bulletIcons, configuration, comparisonOptionsChange }) {
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;

  const answerOptions = comparisonOptions.map(option => ({ title: option.value, value: option.value }));

  const icons = [...bulletIcons.defaultIcons, ...bulletIcons.customIcons];
  const colors = configuration ? JSON.parse(configuration).colors : DefaultColors;

  const additionalColors = [
    {
      value: 'green',
      color: '#00A887',
      title: 'Green',
    },
    {
      value: 'red',
      color: '#FF4949',
      title: 'Red',
    },
  ];

  return (
    <>
      <AppText className='template_card_headline' textValue={comparisonDetailTable.configuringComparisonOptions} />

      {comparisonOptions.map((comparisonOption, index) => (
        <OptionRow
          key={comparisonOption.value}
          index={index}
          comparisonOption={comparisonOption}
          answerOptions={answerOptions}
          icons={icons}
          colors={colors}
          additionalColors={additionalColors}
          comparisonOptionsChange={comparisonOptionsChange}
        />
      ))}

      <div style={{ backgroundColor: '#5C5C6E', height: '1px', marginBottom: '16px' }} />
    </>
  );
}
