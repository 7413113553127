import { axiosLogger } from '../axios-logger';
import { checkIsError, handleAxiosError } from '../axios-error-handler';
import { handleFulfilledRequest } from './fulfiled-request';

function handleFulfilledResponse(response) {
  axiosLogger(response);

  if (checkIsError(response)) {
    return handleAxiosError(response);
  }

  return response;
}

function handleRejectedRequest(error) {
  axiosLogger(error);
  return Promise.reject(error);
}

function handleRejectedResponse(error) {
  axiosLogger(error);
  return handleAxiosError(error);
}

export function applyInterceptors(instance) {
  instance.interceptors.request.use(handleFulfilledRequest, handleRejectedRequest);
  instance.interceptors.response.use(handleFulfilledResponse, handleRejectedResponse);
}
