/* eslint-disable no-console */

export const appLogger = {
  info(...args) {
    // if (isProd) return;

    console.info.apply(null, args);
  },
  error(...args) {
    // if (isProd) return;

    console.error.apply(null, args);
  },
  groupCollapsed(...args) {
    // if (isProd) return;

    console.groupCollapsed.apply(null, args);
  },
  groupEnd(...args) {
    // if (isProd) return;

    console.groupEnd.apply(null, args);
  },
};
