import styled from 'styled-components';
import { AppSpace } from '../../../../../ui/AppSpace';
import { CurrencyFormItem } from '../../../../../features/CurrencyFormItem';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { PriceFormItem } from '../../../../../features/PriceFormItem';
import { Product } from '../pages/FindersAndProductsPage/lib/Product';
import { handleTranslation } from '../../../../../lib/util/helpers';
import { formatPriceStrToNum } from '../pages/FindersAndProductsPage/modules/products/ProductModals/lib/utils';
import { useIsExceptional } from '../pages/FindersAndProductsPage/modules/products/ProductModals/lib/useIsExceptional';

const currencyValidation = ({ getFieldValue }, errorMessage) => ({
  validator(_rule, value) {
    if (!value && (getFieldValue(Product.PRICE_PER_MONTH) || getFieldValue(Product.PRICE_PER_YEAR))) {
      return Promise.reject(handleTranslation(errorMessage));
    }
    return Promise.resolve();
  },
});

const pricePerMonthValidation = ({ getFieldValue }, errorMessage) => ({
  validator(_rule, value) {
    const pricePerYear = getFieldValue(Product.PRICE_PER_YEAR);
    if (!value || !pricePerYear || (pricePerYear && formatPriceStrToNum(value) < formatPriceStrToNum(pricePerYear))) {
      return Promise.resolve();
    }
    return Promise.reject(handleTranslation(errorMessage));
  },
});

const pricePerYearValidation = ({ getFieldValue }, errorMessage) => ({
  validator(_rule, value) {
    const pricePerMonth = getFieldValue(Product.PRICE_PER_MONTH);
    if (!value || !pricePerMonth || (pricePerMonth && formatPriceStrToNum(value) > formatPriceStrToNum(pricePerMonth))) {
      return Promise.resolve();
    }
    return Promise.reject(handleTranslation(errorMessage));
  },
});

export function ProductPrices() {
  const { pricePerMonth, pricePerYear, currency } = useLocalization().translations.unique.formItems;
  const disabled = useIsExceptional();

  return (
    <StyledSpace size='middle'>
      <CurrencyFormItem
        formItemProps={{
          name: Product.CURRENCY,
          rules: [formInstance => currencyValidation(formInstance, currency.errors.requiredWhenPriceSet)],
          dependencies: [Product.PRICE_PER_MONTH, Product.PRICE_PER_YEAR],
        }}
        textFieldProps={{
          label: currency.label,
          disabled,
        }}
      />
      <PriceFormItem
        formItemProps={{
          name: Product.PRICE_PER_MONTH,
          dependencies: [Product.PRICE_PER_YEAR],
          rules: [formInstance => pricePerMonthValidation(formInstance, pricePerMonth.errors.invalid)],
        }}
        textFieldProps={{
          label: pricePerMonth.label,
          disabled,
        }}
      />
      <PriceFormItem
        formItemProps={{
          name: Product.PRICE_PER_YEAR,
          dependencies: [Product.PRICE_PER_MONTH],
          rules: [formInstance => pricePerYearValidation(formInstance, pricePerYear.errors.invalid)],
        }}
        textFieldProps={{
          label: pricePerYear.label,
          disabled,
        }}
      />
    </StyledSpace>
  );
}

const StyledSpace = styled(AppSpace)(() => ({
  width: '100%',
  '& > *:nth-child(n+2)': {
    display: 'flex',
    flex: 1,
  },
}));
