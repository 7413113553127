import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, theme as antTheme, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from '../../../../../../lib/store/slices/authSlice';
import { ROUTER_PATHS } from '../../../../../../lib/util/router/router-paths';

import './styles.scss';
import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { sliceFromStart } from '../../../../../../lib/util/helpers';
import { DropdownMenuButton } from '../../../../ui/DropdownMenuButton';
import { AppAvatar } from '../../../../../../ui/AppAvatar';

const MAX_DISPLAYED_EMAIL_LENGTH = 20;
const CUT_EMAIL_LENGTH = 5;

const { useToken } = antTheme;
const { Text } = Typography;

export function ProfilePopup() {
  const { profilePopup } = useLocalization().translations.unique.components;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const {
    user: { fullName, email },
  } = useSelector(({ auth }) => auth);
  const { token } = useToken();
  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: 264,
    padding: '16px 0 10px',
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const handleVisibleChange = flag => setVisible(flag);

  const trimIndex = email.indexOf('@');
  const formattedEmail = email.length > MAX_DISPLAYED_EMAIL_LENGTH ? `${sliceFromStart(email, CUT_EMAIL_LENGTH)}***${email.slice(trimIndex)}` : email;

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement='bottomRight'
      onOpenChange={handleVisibleChange}
      overlayClassName='profile_dropdown'
      dropdownRender={() => (
        <div style={contentStyle}>
          <div className='profile_user_block'>
            <AppAvatar className='profile_user_avatar' size={40}>
              {fullName}
            </AppAvatar>
            <div className='profile_user_info'>
              <Text className='profile_user_text profile_user__bold' ellipsis>
                {fullName}
              </Text>
              <Tooltip placement='topLeft' title={email}>
                <Text className='profile_user_text profile_user__light'>{formattedEmail}</Text>
              </Tooltip>
            </div>
          </div>
          <DropdownMenuButton
            textValue={profilePopup.profileLink}
            onClick={() => {
              setVisible(false);
              navigate(ROUTER_PATHS.profile);
            }}
          />
          <hr className='profile_hr' />
          <DropdownMenuButton textValue={profilePopup.logout} danger onClick={() => dispatch(logoutSuccess())} />
        </div>
      )}
    >
      <AppAvatar as={Button} type='primary' size={40}>
        {fullName}
      </AppAvatar>
    </Dropdown>
  );
}
