import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppParagraph } from '../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../lib/store/slices/findersSlice';
import { ElementBox } from '../../components/ElementBox';
import { QuestionnaireTextButton } from '../../../../features/QuestionnaireTextButton';
import { RulesProcess } from './RulesProcess';
import { isLength } from '../../../../../../../../../../lib/util/helpers';
import { filterElements, checkElement, getElementObject } from '../../utils/helpers';
import '../../style.scss';

export function AdvancedSettingsProcess() {
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);
  const dispatch = useDispatch();
  const [selectedElement, selectElement] = useState({
    elementId: isLength(filterElements(questionnaireStructure)) ? filterElements(questionnaireStructure)[0].id : null,
  });
  const { clearAll } = useLocalization().translations.shared.generic;
  const { noSelectElement } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.advanceSettings;

  const elementObject = getElementObject(questionnaireStructure, selectedElement.elementId);

  const clearAllRules = () => {
    if (checkElement(questionnaireStructure, selectedElement.elementId)) {
      const result = questionnaireStructure.map(el => (el.id === selectedElement.elementId ? { ...el, rules: [] } : el));
      dispatch(updateQuestionnaireStructure({ structure: result }));
    }
  };

  return (
    <div className='flow_process'>
      <div className='flow_drops_wrap'>
        {filterElements(questionnaireStructure).map(element => (
          <ElementBox
            key={element.id}
            element={element}
            elements={filterElements(questionnaireStructure)}
            selectedElement={selectedElement}
            selectElement={selectElement}
          />
        ))}
      </div>
      <div className='flow_selects_wrap'>
        {checkElement(questionnaireStructure, selectedElement.elementId) ? (
          <>
            <div className='flow_selects_clear_btn'>
              <QuestionnaireTextButton onClick={clearAllRules} style={{ display: 'inline-flex' }} textValue={clearAll} disabled={isViewMode} />
            </div>
            <div className='flow_selects_logic_wrap'>
              <RulesProcess element={elementObject} disabled={isViewMode} />
            </div>
          </>
        ) : (
          <AppParagraph className='flow_selects_no_select' textValue={noSelectElement} />
        )}
      </div>
    </div>
  );
}
