import { useCategorySearchDataColumn } from '../../components/search-data';
import { Category } from '../../lib/Category';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';

export function useIdNameColumn() {
  const { id: title } = useLocalization().translations.shared.columns;

  return useCategorySearchDataColumn({
    title,
    arrangedId: Category.ID,
    searchFieldName: 'searchById',
    filterFieldName: 'includeIds',
    columnsProps: {
      width: 175,
    },
  });
}
