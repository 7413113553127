import { useSelector } from 'react-redux';
import { isString } from '../../../../../../../../lib/util/helpers';
import { TableHeaderCell } from '../../../../components/TableHeaderCell';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Status, StatusType } from '../../../../components/Status';

export function useProductUsedInThisFinderColumn() {
  const questionnaireDraft = useSelector(({ finders }) => finders?.finder?.questionnaireDraft);
  const { table } = useLocalization().translations.unique.pages.products;

  return {
    dataIndex: 'usedInThisFinder',
    title: <TableHeaderCell title={table.columns.usedInThisFinder} withArrange={false} />,
    render: (value, row) => {
      const { id: productId } = row;

      if (isString(questionnaireDraft) && questionnaireDraft.includes(productId)) {
        return <Status textValue={table.yes} type={StatusType.GREEN} />;
      }

      return <Status textValue={table.no} type={StatusType.GRAY} />;
    },
  };
}
