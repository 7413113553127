import { ProgressBarLabelIcon } from '../../../../../../../../ui/icons/ProgressBarLabelIcon';
import styled from 'styled-components';

/**
 * @desc Indicator label component.
 * @param {number} progressPercentage - The progress percentage.
 * @param {number} indicatorValue - The value of the indicator.
 * @returns {JSX.Element} - The rendered indicator label component.
 */
export function IndicatorLabel({ progressPercentage, indicatorValue }) {
  return (
    <Root indicatorValue={progressPercentage}>
      <ProgressBarLabelIcon />
      <StyledIndicatorText>{Math.floor(indicatorValue)}%</StyledIndicatorText>
    </Root>
  );
}

const Root = styled('div')(props => ({
  left: `${props.indicatorValue}%`,
  transform: 'translateX(-50%)',
  width: '82px',
  height: '46px',
  position: 'absolute',
  transition: '.3s all ease',
  top: '-50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledIndicatorText = styled('span')({
  position: 'absolute',
  top: '10px',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 600,
});
