import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { AppFormSection } from '../../../../../../../../../ui/AppFormSection';
import { AppRadioGroup } from '../../../../../../../../../ui/AppRadioGroup';
import { AppRadio } from '../../../../../../../../../ui/AppRadio';
import { ClientFormField } from '../ClientForm';
import styled from 'styled-components';
import { useWindowResize } from '../../../../../utils/hooks/useWindowResize';
import { handleTranslation } from '../../../../../../../../../lib/util/helpers';

const AreYouAClientOption = {
  YES: true,
  NO: false,
};

const TABLET_WIDTH = 900;

export function PreviewFormGenericBottomFields({ bankName, isHardcodeForHamburgerVbKontofinderFk }) {
  const { form } = useLocalization().translations.unique.components.clientForm;
  const { windowWidth } = useWindowResize();

  return (
    <div>
      <AppTitle level={4} textValue={form.fields.areYouAClient.title({ bankName })} style={{ lineHeight: 1.25, fontWeight: 700, fontSize: '16px' }} />
      <AppFormSection
        formItemProps={{
          name: ClientFormField.ARE_YOU_A_CLIENT,
          rules: isHardcodeForHamburgerVbKontofinderFk
            ? [{ required: true, message: handleTranslation(form.fields.requiredField) }]
            : [{ required: true }],
        }}
      >
        <StyledGroup>
          <AppRadio value={AreYouAClientOption.YES} textValue={form.fields.areYouAClient.options.yes} />
          <AppRadio value={AreYouAClientOption.NO} textValue={form.fields.areYouAClient.options.no} />
        </StyledGroup>
      </AppFormSection>
      <AppText
        textValue={form.requiredFieldsNotion}
        style={{ fontSize: '12px', margin: windowWidth > TABLET_WIDTH ? '27px 0 0 0' : '0px 0 27px 0', display: 'flex' }}
      />
    </div>
  );
}

const StyledGroup = styled(AppRadioGroup)({
  marginTop: '16px',
  flexDirection: 'row',
  alignItems: 'center',
  '& > *': {
    marginBottom: '0',
  },
});
