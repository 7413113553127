export const getProductCards = (elements, allElements) => {
  return elements?.map(prod => {
    const productElement = allElements.find(el => el.id === prod?.productTemplateId);
    return productElement?.content?.products?.find(prodCard => prodCard?.id === prod?.productId);
  });
};

export const getAnswers = (elements, allElements) => {
  return elements?.map(answerObj => {
    const questionElement = allElements.find(el => el.id === answerObj?.questionId);
    const answer = questionElement.content?.answers?.find(answ => answ.id === answerObj?.answerId);
    const counterAnswer = questionElement.content?.chartAnswers?.find(answ => answ.id === answerObj?.answerId);
    const chartAnswer = questionElement.content?.counterAnswers?.find(answ => answ.id === answerObj?.answerId);
    return { question: questionElement, answer: answer || chartAnswer || counterAnswer };
  });
};

export const getQuestions = (elements, allElements) => {
  return elements?.map(questionObj => {
    const questionElement = allElements.find(el => el.id === questionObj?.questionId);
    return { question: questionElement };
  });
};
