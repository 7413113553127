import { LayoutSearchDataHeaderCell } from './LayoutSearchDataHeaderCell';

export function useLayoutSearchDataColumn(props) {
  const { columnProps, ...rest } = props;

  return {
    dataIndex: props.arrangedId,
    title: <LayoutSearchDataHeaderCell {...rest} />,
    ...columnProps,
  };
}
