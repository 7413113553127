import { BulletCheckIcon } from '../../../../../../../../../../../../ui/icons/BulletCheckIcon';

/**
 * @desc Completed Chapter Element
 * @param {Object} colors
 * @param {Object} chapterCompletedCheckStyle
 * @returns {JSX.Element}
 */
export function CompletedChapterElement({ colors, chapterCompletedCheckStyle }) {
  return (
    <div
      style={{
        width: 40,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors?.primaryColor,
        position: 'absolute',
        zIndex: 1,
        ...chapterCompletedCheckStyle,
      }}
    >
      <BulletCheckIcon style={{ color: '#FFFFFF', width: 16, height: 16 }} />
    </div>
  );
}
