import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  rules: [],
  currentHistoryIndex: -1,
  history: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {},
  loading: false,
  error: null,
  selectedRule: {},
  isModalLoading: false,
  modalType: '',
  isModalOpen: false,
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    getRulesRequest: state => {
      state.error = null;
      state.loading = true;
      state.rules = [];
    },
    getRulesSuccess: (state, action) => {
      state.rules = action.payload.rules.map(rule => ({
        ...rule,
        isChanged: false,
        conditions: JSON.parse(rule.conditions),
      }));
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getRulesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setRuleStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setRuleStatusSuccess: state => {
      state.loading = false;
    },
    setRuleStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openRuleModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedRule = action.payload.selectedRule || {};
    },
    closeRuleModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedRule = {};
    },
    addRuleRequest: state => {
      state.error = null;
      state.loading = true;
    },
    addRuleSuccess: (state, action) => {
      const selectedRule = {
        ...action.payload.selectedRule,
        conditions: JSON.parse(action.payload.selectedRule.conditions),
      };

      state.history = state.history.map(item => ({
        ...item,
        id: selectedRule.id,
      }));

      state.selectedRule = selectedRule;
      state.loading = false;
    },
    addRuleError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    editRuleRequest: state => {
      state.error = null;
      state.loading = true;
    },
    editRuleSuccess: state => {
      state.loading = false;
      state.selectedRule.isChanged = false;
    },
    editRuleError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteRuleRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteRuleSuccess: state => {
      state.isModalLoading = false;
    },
    deleteRuleError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    duplicateRuleRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    duplicateRuleSuccess: state => {
      state.isModalLoading = false;
    },
    duplicateRuleError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    selectRule: (state, action) => {
      state.selectedRule = action.payload;
      state.currentHistoryIndex = 0;
      state.history = [action.payload];
    },
    updateSelectedRule: (state, action) => {
      const updatedRule = { ...action.payload, isChanged: true };
      state.selectedRule = updatedRule;
      let updatedHistory = null;

      updatedHistory =
        state.currentHistoryIndex !== state.history.length - 1
          ? [...state.history.slice(0, state.currentHistoryIndex + 1), updatedRule]
          : [...state.history, updatedRule];

      state.currentHistoryIndex += 1;
      state.history = updatedHistory;
    },
    cleanRule: state => {
      state.selectedRule = {};
    },
    undo: state => {
      const previousHistoryIndex = state.currentHistoryIndex - 1;
      state.selectedRule = state.history[previousHistoryIndex];
      state.currentHistoryIndex = previousHistoryIndex;
    },
    redo: state => {
      const nextHistoryIndex = state.currentHistoryIndex + 1;
      state.selectedRule = state.history[nextHistoryIndex];
      state.currentHistoryIndex = nextHistoryIndex;
    },
    /* arranges */
    // apply arranges
    applyRuleArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    applyRuleArrangesError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // remove filters
    removeRuleFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeRuleFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //
    removeRuleArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
  },
});

export const {
  getRulesRequest,
  getRulesSuccess,
  getRulesError,
  setRuleStatusRequest,
  setRuleStatusSuccess,
  setRuleStatusError,
  openRuleModal,
  closeRuleModal,
  addRuleRequest,
  addRuleSuccess,
  addRuleError,
  editRuleRequest,
  editRuleSuccess,
  editRuleError,
  deleteRuleRequest,
  deleteRuleSuccess,
  deleteRuleError,
  undo,
  redo,
  duplicateRuleRequest,
  duplicateRuleSuccess,
  duplicateRuleError,
  selectRule,
  updateSelectedRule,
  cleanRule,
  applyRuleArrangesRequest,
  applyRuleArrangesError,
  removeRuleFiltersRequest,
  removeRuleFiltersError,
  removeRuleArranges,
} = rulesSlice.actions;
export const rules = rulesSlice.reducer;
