import { useMemo } from 'react';
import { TemplateTypes } from '../../../../../../lib/models/TemplateTypes';

const LAST_INDEX = -1;

export function useModifiedStructure(questionnaireStructure) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    let pageIndex = 0;
    let introIndex = 0;
    let chapterIndex = 0;
    let contentIndex = 0;
    let questionIndex = 0;
    let productIndex = 0;
    let resultIndex = 0;
    let contactFormIndex = 0;
    const auxiliaryArray = [];
    return questionnaireStructure?.map((item, index, array) => {
      auxiliaryArray.push(item);
      if (item.templateType === TemplateTypes.CHAPTER) {
        chapterIndex += 1;
        return { ...item, templateTypeIndex: chapterIndex };
      }
      if (item.templateType === TemplateTypes.PAGE) {
        if (!array[index + 1] || questionnaireStructure[index + 1]?.templateType !== TemplateTypes.CHAPTER) {
          pageIndex += 1;
          const chapters = auxiliaryArray.filter(ele => ele.templateType === TemplateTypes.CHAPTER);
          const latsChapter = chapters.at(LAST_INDEX);
          return { ...item, pageName: latsChapter?.label, chapterIndex, templateTypeIndex: pageIndex };
        }
        if (questionnaireStructure[index + 1]?.templateType === TemplateTypes.CHAPTER) {
          const pageName = array.find((el, idx) => {
            return el.templateType === TemplateTypes.CHAPTER && idx > index;
          });
          pageIndex += 1;
          return { ...item, pageName: pageName?.label, chapterIndex: chapterIndex + 1, templateTypeIndex: pageIndex };
        }
      }
      if (item.templateType === TemplateTypes.INTRO) {
        introIndex += 1;
        return { ...item, templateTypeIndex: introIndex };
      }

      if (item.templateType === TemplateTypes.CONTENT) {
        contentIndex += 1;
        return { ...item, templateTypeIndex: contentIndex };
      }
      if (item.templateType === TemplateTypes.QUESTION) {
        questionIndex += 1;
        return { ...item, templateTypeIndex: questionIndex };
      }
      if (item.templateType === TemplateTypes.PRODUCT) {
        productIndex += 1;
        return { ...item, templateTypeIndex: productIndex };
      }
      if (item.templateType === TemplateTypes.RESULT) {
        resultIndex += 1;
        return { ...item, templateTypeIndex: resultIndex };
      }
      if (item.templateType === TemplateTypes.CONTACT_FORM) {
        contactFormIndex += 1;
        return { ...item, templateTypeIndex: contactFormIndex };
      }
      return item;
    });
  }, [questionnaireStructure]);
}
