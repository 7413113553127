import { AppBasicButton } from '../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../ui/text/AppText';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';

export function FormCardButton({ onClick, textValue, disabled = false }) {
  const { clearAll } = useLocalization().translations.shared.generic;

  return (
    <AppBasicButton onClick={onClick} disabled={disabled}>
      <AppText className={`bold ${disabled ? 'primary_color_disabled' : 'primary_color'}`} textValue={textValue || clearAll} />
    </AppBasicButton>
  );
}
