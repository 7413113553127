import { ProductFieldsValueContainer } from '../ProductFieldsValueContainer';
import { ComparisonTableCellKind } from '../../comparison-table-utils';
import { Product } from '../../../../../../FindersAndProductsPage/lib/Product';

export function getValue(optionList, tableRows, selectedProduct, columnIndex) {
  return optionList.filter(option =>
    tableRows.some(
      row =>
        selectedProduct && row.cells?.[columnIndex]?.productFieldName === option?.value && row.cells?.[columnIndex]?.productId === selectedProduct.id
    )
  );
}

export const components = {
  ValueContainer: ProductFieldsValueContainer,
  MultiValue: () => null,
};

export const DEFAULT_NAME_CELL_INDEX = 0;

export const findCustomNameIndexFromRow = row => row.cells.findIndex(cell => cell.kind === ComparisonTableCellKind.NAME);

export function getOptions(productKeys, fieldLabels) {
  return productKeys
    ?.map(key => ({ label: fieldLabels[key], value: key }))
    .filter(
      item =>
        Boolean(item.label) && //
        item.value !== Product.IMAGE_LINK // Because image is already showed when a product is selected
    );
}
