import { Tooltip } from 'antd';
import { AppBox } from '../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../ui/text/AppText';
import { UploadIcon } from '../../../../../../ui/icons/UploadIcon';
import { InfoIcon } from '../../../../../../ui/icons/InfoIcon';
import './styles.scss';

export function UploadLabel({ text, uploading, infoIcon, infoText }) {
  return (
    <AppBox className={uploading ? 'upload_box upload_box_disabled' : 'upload_box'}>
      <UploadIcon styles='primary_color upload_box_icon' />
      <AppText className='primary_color bold' textValue={text} />
      {infoIcon && (
        <Tooltip title={infoText}>
          <div className='upload_box_info_block align_items_center'>
            <InfoIcon className='primary_color' />
          </div>
        </Tooltip>
      )}
    </AppBox>
  );
}
