import { useSelector } from 'react-redux';
import styles from './ActionsComponent.module.scss';
import { PlusIcon } from '../../../../../../../../../../../ui/icons/PlusIcon';
import { CopyIcon } from '../../../../../../../../../../../ui/icons/CopyIcon';
import { DeleteIcon } from '../../../../../../../../../../../ui/icons/DeleteIcon';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';

export function ActionsComponent({ onAddTemplate, onCopyTemplate, onDeleteCard, templateType }) {
  const { isViewMode } = useSelector(({ finders }) => finders);
  const isTemplateTypeIntro = templateType === 'INTRO';

  return (
    <div className={styles.items}>
      <AppBasicButton onClick={!isTemplateTypeIntro ? onAddTemplate : null} disabled={isTemplateTypeIntro || isViewMode}>
        <PlusIcon fill='black' />
      </AppBasicButton>
      <AppBasicButton onClick={!isTemplateTypeIntro ? onCopyTemplate : null} disabled={isTemplateTypeIntro || isViewMode}>
        <CopyIcon fill='black' />
      </AppBasicButton>
      <AppBasicButton onClick={onDeleteCard} disabled={isViewMode}>
        <DeleteIcon fill='black' />
      </AppBasicButton>
    </div>
  );
}
