import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

export function AnswerSelectFormItem({ value, onChange, allAnswers, options, isMultiSelect = true, disabled }) {
  const answerOptions = options.map(answer => {
    const index = allAnswers.indexOf(answer);
    return {
      value: answer.id,
      title: `A.${index + 1}.${answer.value}`,
    };
  });

  const handleChange = selected => {
    const newSelectedValue = isMultiSelect ? selected.map(item => item.value) : selected.value;
    onChange(newSelectedValue);
  };

  return (
    <AppFormSection>
      <AppNewSelect
        options={answerOptions}
        value={value}
        onChange={selected => handleChange(selected)}
        withoutLimit
        isMultiSelect={isMultiSelect}
        disabled={disabled}
      />
    </AppFormSection>
  );
}
