import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FinderSubHeader } from '../../../modules/FinderSubHeader';
import { cleanFinder } from '../../../../../lib/store/slices/findersSlice';
import { useLocalization } from '../../../../../lib/hooks/useLocalization';
import { ROUTER_PATHS } from '../../../../../lib/util/router/router-paths';
import { useGetFinder } from '../../../../../lib/hooks/useGetFinder';
import { setTitle } from '../../../../../lib/store/slices/titleSlice';
import { handleTranslation } from '../../../../../lib/util/helpers';
import { AppPreloader } from '../../../../../ui/AppPreloader';

export function WithFinderSubHeader() {
  const dispatch = useDispatch();
  const { translations } = useLocalization();
  const { title } = translations.unique.pages.finder;
  const { getFinder } = useGetFinder();
  const navigate = useNavigate();
  const { finderError, finderLoading } = useSelector(({ finders }) => finders);

  useEffect(() => {
    dispatch(setTitle({ title: handleTranslation(title), subTitle: '' }));
    dispatch(cleanFinder());
    getFinder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFinder]);

  useEffect(() => {
    if (finderError) {
      navigate(ROUTER_PATHS.notFound);
      dispatch(cleanFinder());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finderError]);

  return (
    <>
      <FinderSubHeader />
      {finderLoading ? <AppPreloader style={{ minHeight: 200 }} /> : <Outlet />}
    </>
  );
}
