import { useCallback } from 'react';
import { isIndexFound, isTruthy } from '../../../../../../../../../lib/util/helpers';

export function useHardcodedUpdateProductSelect({ products, productId, categoryName, customUnselectedProductIds }) {
  return useCallback(
    customValue => {
      return products?.map(card => {
        const productNameSection = card?.productNameSection;
        const iteratedProductId = productNameSection?.product?.value;
        const isCurrentProduct = iteratedProductId === productId;
        const unselectedProductIndex = customUnselectedProductIds.indexOf(card?.id);
        const shouldBeUnselected = isIndexFound(unselectedProductIndex);

        let selected = card?.selected;
        switch (true) {
          case shouldBeUnselected:
            selected = false;
            break;
          case isCurrentProduct && isTruthy(customValue):
            selected = customValue;
            break;
          case productNameSection?.categoryName === categoryName: {
            selected = isCurrentProduct && card?.selected ? false : isCurrentProduct;
            break;
          }
          default:
            break;
        }

        return { ...card, selected };
      });
    },
    [products, productId, customUnselectedProductIds, categoryName]
  );
}
