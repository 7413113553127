import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addCategoryError,
  addCategoryRequest,
  addCategorySuccess,
  applyCategoryArrangesError,
  applyCategoryArrangesRequest,
  closeCategoryModal,
  deleteCategoryError,
  deleteCategoryRequest,
  deleteCategorySuccess,
  editCategoryError,
  editCategoryRequest,
  editCategorySuccess,
  getCategoriesError,
  getCategoriesRequest,
  getCategoriesSuccess,
  removeCategoryFiltersError,
  removeCategoryFiltersRequest,
} from '../slices/categoriesSlice';
import { axiosInstance } from '../../util/http/axios/axios-instance';
import { isEmptyPage, normalizePageForUI, normalizeParamsOnEmptyPage, normalizeTableRequestConfig } from '../../util/helpers';
import { getCategoriesFromApi, withXTenantHeader } from '../../api';

function* requestAndSave(args = {}) {
  // "recursionLimit" here for avoiding infinity recursion, if something goes wrong
  const { params = {}, recursionLimit = 1 } = args;

  const {
    categories: { sorting, filtering, pagination },
    finders: {
      tenant: { id: tenantId },
    },
  } = yield select();

  const config = normalizeTableRequestConfig({ params, pagination, filtering, sorting, data: filtering });
  const response = yield call(getCategoriesFromApi, tenantId, config);

  if (response?.data) {
    if (isEmptyPage(response?.data) && recursionLimit > 0) {
      yield requestAndSave({
        ...args,
        recursionLimit: recursionLimit - 1,
        params: normalizeParamsOnEmptyPage({ ...params, page: params?.page || config?.params?.page }),
      });
      return;
    }

    const { content, number: pageNumber, size, totalElements } = response.data;
    yield put(getCategoriesSuccess({ categories: content, page: normalizePageForUI(pageNumber), size, total: totalElements }));
  }
}

function* fetchCategories({ payload = {} }) {
  try {
    yield requestAndSave(payload);
  } catch (error) {
    yield put(getCategoriesError({ error }));
  }
}

function* addCategory({ payload: { categoryForm, tenantId } }) {
  try {
    yield call(axiosInstance.post, '/category/', categoryForm, withXTenantHeader(tenantId));
    yield put(addCategorySuccess({ success: { messageType: addCategorySuccess.type } }));
    yield put(closeCategoryModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(addCategoryError({ error }));
  }
}

function* editCategory({ payload: { categoryForm, tenantId } }) {
  try {
    yield call(axiosInstance.put, '/category/update', categoryForm, withXTenantHeader(tenantId));
    yield put(editCategorySuccess({ success: { messageType: editCategorySuccess.type } }));
    yield put(closeCategoryModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(editCategoryError({ error }));
  }
}

function* deleteCategory({ payload: { categoryId, tenantId } }) {
  try {
    yield call(axiosInstance.delete, `category/${categoryId}/delete`, withXTenantHeader(tenantId));
    yield put(deleteCategorySuccess({ success: { messageType: deleteCategorySuccess.type } }));
    yield put(closeCategoryModal());
    yield fork(requestAndSave);
  } catch (error) {
    yield put(deleteCategoryError({ error }));
  }
}

function* applyArranges() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(applyCategoryArrangesError({ error }));
  }
}

function* removeFilters() {
  try {
    yield requestAndSave();
  } catch (error) {
    yield put(removeCategoryFiltersError({ error }));
  }
}

export function* watchCategoriesManagement() {
  yield all([
    takeLatest(getCategoriesRequest.type, fetchCategories),
    takeLatest(addCategoryRequest.type, addCategory),
    takeLatest(editCategoryRequest.type, editCategory),
    takeLatest(deleteCategoryRequest.type, deleteCategory),
    takeLatest(applyCategoryArrangesRequest.type, applyArranges),
    takeLatest(removeCategoryFiltersRequest.type, removeFilters),
  ]);
}
