/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import styles from './styles.module.scss';
import { isLength } from '../../../../../../../../lib/util/helpers';
import { DestinationVariants, TemplateTypes } from '../../../../../../../../lib/models/TemplateTypes';
import { updateChaptersState } from '../../../../utils/end-user-flow-helpers';
import { usePreviewContext } from '../../hooks/usePreviewContext';
import { FlowButton } from '../FlowButton/FlowButton';
import { useWindowResize } from '../../../../utils/hooks/useWindowResize';
import { AppBreakpoint } from '../../../../../../../../lib/util/Adaptive';
import { setHistoryPages } from '../../hooks/getHistoryPage';
import { setCurrentElementsIds, getConditionsAndRulesCurrentFlow } from '../../hooks/setNextFlow';

const LAST_INDEX = -1;
const PrevText = 'Zurück';
const NextText = 'Weiter';

export function NextPrevButtons({ setPagesFlow, pagesFlow, colors, disabled, fonts, setPageHistory, pageHistory, currentElements }) {
  const { previewState } = usePreviewContext();
  const {
    allPages,
    afterContactFormLink,
    allChapters,
    prevPageIds,
    currentChapterId,
    currentPageId,
    currentFlowElement,
    showContactForm,
    showSuccessScreen,
    isDynamicFormSubmitting,
    currentElementsIds,
  } = pagesFlow;
  const currentChapterIndex = allChapters.findIndex(page => page.id === currentChapterId);
  const nextChapterId = allChapters[currentChapterIndex + 1] ? allChapters[currentChapterIndex + 1]?.id : allChapters[0]?.id;
  const currentPageIndex = allPages.findIndex(page => page.id === currentPageId);
  const chapterInnerPagesIds = allChapters?.find(chapter => chapter.id === currentChapterId)?.chapterInnerPagesIds;
  const isLastChapterPage = chapterInnerPagesIds?.at(LAST_INDEX) === currentPageId;
  const isLastPage = allPages?.at(LAST_INDEX)?.id === currentPageId;
  const allChaptersPage = allPages.find(page => page.templateType === TemplateTypes.CHAPTER);

  const { windowWidth } = useWindowResize();
  // eslint-disable-next-line consistent-return
  function getNextPage(nextFlow) {
    if (isLastChapterPage && isLength(allChapters) && nextFlow === DestinationVariants.NEXT) {
      return allPages.find(page => page.templateType === TemplateTypes.CHAPTER);
    }
    if (isLastPage && nextFlow === DestinationVariants.NEXT) {
      return allPages[0];
    }
    if (nextFlow === DestinationVariants.NEXT) {
      return allPages[currentPageIndex + 1];
    }
    if (nextFlow && nextFlow !== DestinationVariants.NEXT) {
      return allPages.find(page => page.elements.find(el => el.id === nextFlow));
    }
  }

  const prevOnClick = () => {
    if (!isLength(prevPageIds)) return;

    setPagesFlow(prev => ({
      ...prev,
      showSuccessScreen: false,
    }));

    if (afterContactFormLink) {
      setPagesFlow(prev => ({
        ...prev,
        afterContactFormLink: null,
        showSuccessScreen: false,
      }));
    }

    const newPrevPageIds = [...prevPageIds];
    newPrevPageIds.pop();
    const prevPageId = prevPageIds.at(LAST_INDEX);
    const prevPage = pageHistory.find(page => page.id === prevPageId);

    const otherFields = showContactForm
      ? { showContactForm: false } // This will return a user to "Result Table"
      : {
          currentChapterId,
          currentPageId: prevPageIds.at(LAST_INDEX),
          prevPageIds: newPrevPageIds,
          currentFlowElement: prevPage?.lastCurrentFlowElement,
          currentElementsIds: isLength(prevPage?.elements) ? prevPage.elements.map(el => el.id) : [],
        };

    setPagesFlow(prev => ({
      ...prev,
      ...otherFields,
      showSuccessScreen: false,
    }));

    setHistoryPages(allPages, currentPageId, currentElementsIds, setPageHistory, currentFlowElement);
  };

  const onSubmitWithRecaptcha = async () => {
    await previewState.form.submit();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const nextOnClick = async () => {
    if (showContactForm && !showSuccessScreen) {
      try {
        await onSubmitWithRecaptcha();
      } catch {
        //
      }
      return;
    }

    if (showContactForm && showSuccessScreen) {
      setPagesFlow(prev => ({
        ...prev,
        showContactForm: false,
        showSuccessScreen: false,
      }));
    }

    if (showSuccessScreen) {
      setPagesFlow(prev => ({
        ...prev,
        showSuccessScreen: false,
      }));
    }

    if (currentFlowElement?.templateType === TemplateTypes.CONTACT_FORM && !currentFlowElement.submitted) {
      try {
        await onSubmitWithRecaptcha();
      } catch {
        //
      }
      return;
    }

    const updatedFlow = getConditionsAndRulesCurrentFlow(pagesFlow, currentElements);
    const nextPage = getNextPage(updatedFlow?.goTo);
    const nextCurrentElementsIds = setCurrentElementsIds(nextPage, updatedFlow?.goTo, updatedFlow?.showElements);

    if (updatedFlow?.goTo === DestinationVariants.NEXT && nextPage) {
      const chaptersCheckedFlow = updateChaptersState(isLastChapterPage, allChapters, currentChapterId);

      setPagesFlow(prev => ({
        ...prev,
        allChapters: chaptersCheckedFlow,
        currentChapterId: isLastChapterPage ? nextChapterId : currentChapterId,
        currentPageId: isLastChapterPage ? allPages?.find(page => page.templateType === TemplateTypes.CHAPTER)?.id : nextPage?.id,
        prevPageIds: isLastChapterPage ? [] : [...prevPageIds, currentPageId],
        currentFlowElement: isLength(nextPage?.elements) ? nextPage.elements[0] : null,
        currentElementsIds: nextCurrentElementsIds,
      }));
    }

    if (updatedFlow?.goTo && updatedFlow?.goTo !== DestinationVariants.NEXT && nextPage) {
      const chapterElement = allChaptersPage?.elements?.find(el => el.id === updatedFlow?.goTo);
      const chaptersCheckedFlow = updateChaptersState(chapterElement, allChapters, currentChapterId);

      setPagesFlow(prev => ({
        ...prev,
        allChapters: chaptersCheckedFlow,
        currentChapterId: chapterElement ? chapterElement.id : currentChapterId,
        currentPageId: nextPage.id,
        prevPageIds: chapterElement ? [] : [...prevPageIds, currentPageId],
        currentFlowElement: nextPage?.elements?.find(el => el.id === updatedFlow?.goTo),
        currentElementsIds: nextCurrentElementsIds,
      }));
    }

    setHistoryPages(allPages, currentPageId, currentElementsIds, setPageHistory, currentFlowElement);
  };

  return (
    <section className={String(styles.nextPrevButtons)}>
      <div className={styles.wrap}>
        <FlowButton
          analyticsClass='analytics-button'
          style={{
            backgroundColor: colors.primaryColor,
            fontWeight: 700,
            fontSize: fonts.headlineFourth.fontSize,
            fontFamily: fonts.headlineFourth.font,
            width: windowWidth < AppBreakpoint.M && !(currentFlowElement?.templateType !== TemplateTypes.RESULT || showContactForm) && '100%',
          }}
          onClick={prevOnClick}
          disabled={!isLength(prevPageIds)}
        >
          {PrevText}
        </FlowButton>

        {(currentFlowElement?.templateType !== TemplateTypes.RESULT || showContactForm) && (
          <FlowButton
            analyticsClass='analytics-button'
            style={{
              backgroundColor: colors.primaryColor,
              marginLeft: 'auto',
              fontWeight: 700,
              fontSize: fonts.headlineFourth.fontSize,
              fontFamily: fonts.headlineFourth.font,
              width: windowWidth < AppBreakpoint.M && showSuccessScreen && '100%',
              visibility: showSuccessScreen ? 'hidden' : 'visible',
              pointerEvents: showSuccessScreen ? 'none' : 'all',
            }}
            onClick={nextOnClick}
            disabled={disabled || !currentFlowElement || showSuccessScreen}
            loading={previewState?.isFormSubmitting || isDynamicFormSubmitting}
          >
            {NextText}
          </FlowButton>
        )}
      </div>
    </section>
  );
}
