import { DepotProducts } from './DepotProducts';
import { AdditionalInformation } from './AdditionalInformation';

export function ResultDepot({ item, structureItemIndex, onChange }) {
  return (
    <>
      {item?.content?.products && <DepotProducts products={item.content.products} structureItemIndex={structureItemIndex} />}
      {item?.content?.additionalInformation && (
        <AdditionalInformation
          additionalInformation={item.content.additionalInformation}
          structureItemIndex={structureItemIndex}
          onChange={onChange('additionalInformation')}
        />
      )}
    </>
  );
}
