import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { AppInput } from '../AppInput';
import './AppNewDatePicker.sass';
import { DatePickerHeader } from './components/DatePickerHeader';

export function AppNewDatePicker({ style, suffix, popperClassName, value, size, hasFeedback, ...rest }) {
  return (
    <ReactDatePicker
      popperClassName={classNames('AppNewDatePicker', popperClassName)}
      showPopperArrow={false}
      customInput={<AppInput style={style} suffix={suffix} size={size} hasFeedback={hasFeedback} />}
      renderCustomHeader={DatePickerHeader}
      value={value}
      dateFormat='dd.MM.yyyy'
      {...rest}
    />
  );
}
