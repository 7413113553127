import { FinderStatus } from '../../lib/Finder';
import { Status, StatusType } from '../../../../../../../../components/Status';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';

export function FinderStatusBodyCell({ status }) {
  const { translations } = useLocalization();
  const { status: sharedStatusText } = translations.shared;
  const { statuses: finderStatusesText } = translations.unique.pages.finders.table;

  switch (status) {
    case FinderStatus.PUBLISHED:
      return <Status textValue={finderStatusesText.published} type={StatusType.GREEN} />;

    case FinderStatus.NOT_PUBLISHED:
      return <Status textValue={finderStatusesText.notPublished} type={StatusType.ORANGE} />;

    case FinderStatus.DEACTIVATED:
      return <Status textValue={sharedStatusText.deactivated} type={StatusType.GRAY} />;

    default:
      throw new Error('Invalid status');
  }
}
