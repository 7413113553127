import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export function Button({ onClick, analyticsClass, children, ...props }) {
  return (
    <StyledButton onClick={onClick} {...props} className={analyticsClass}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled(AntdButton)({
  backgroundColor: '#DA1B5E',
  border: 'none',
  borderRadius: 20,
  minWidth: 178,
  height: 40,
  transition: '.3s ease',
  color: ' #fff',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover ': {
    backgroundColor: '#ba1750',
    color: ' #fff !important',
  },
  '&:active': {
    backgroundColor: '#e9769e',
  },
});
