import styled from 'styled-components';
import { Form } from 'antd';
import { TABLET_WIDTH } from '../products-pdf/constants';

export const StyledFormWrapper = styled(Form)(props => ({
  maxWidth: props.windowWidth > TABLET_WIDTH ? '586px' : '100%',
  width: '100%',

  '.asLabel': {
    background: 'unset',
  },

  '.react-select__option': {
    '&:hover, &.react-select__option--is-focused': {
      background: `${props.colors.primaryColor} !important`,

      '*': {
        color: '#fff !important',
      },
    },
  },

  '.AppFormSection': {
    marginBottom: '10px',

    '.react-select__control, .ant-input-affix-wrapper': {
      background: '#fff',
      '&:before': {
        borderColor: '#5C5C6E',
        borderRadius: '4px',
      },

      '&:hover, &.ant-input-affix-wrapper-focused, &.react-select__control--is-focused': {
        '&:before': {
          borderColor: `${props.colors.secondaryColor} !important`,
        },
      },
    },
  },
}));
