const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SORT_ORDERS = Object.values(SortOrder);

// export const PAGINATION_FIRST_PAGE = 0;
export const PAGINATION_ELEMENTS_AMOUNT = 10;

export const TableSortOrder = {
  ...SortOrder,
  NONE: 'none',
};

export const WITHOUT_PAGINATION_ELEMENTS_SIZE = 10000;
