export function WarningIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_10885_100743)'>
        <path
          d='M11.907 3.02612C11.9364 3.01 11.9695 3.00174 12.003 3.00212C12.0361 3.00199 12.0686 3.01025 12.0975 3.02612C12.1318 3.04669 12.1599 3.07626 12.1785 3.11162L22.464 20.6121C22.518 20.7021 22.5165 20.7981 22.467 20.8866C22.448 20.9229 22.4202 20.9539 22.386 20.9766C22.3563 20.9948 22.3218 21.0037 22.287 21.0021H1.71904C1.68422 21.0038 1.64971 20.9949 1.62004 20.9766C1.58592 20.9539 1.55807 20.9229 1.53904 20.8866C1.51432 20.845 1.50153 20.7974 1.50206 20.749C1.50259 20.7005 1.51642 20.6532 1.54204 20.6121L11.826 3.11162C11.8448 3.07631 11.8728 3.04675 11.907 3.02612ZM13.473 2.35112C13.3246 2.09247 13.1105 1.87757 12.8524 1.72813C12.5942 1.57869 12.3013 1.5 12.003 1.5C11.7048 1.5 11.4118 1.57869 11.1537 1.72813C10.8956 1.87757 10.6815 2.09247 10.533 2.35112L0.247541 19.8516C-0.437959 21.0186 0.384041 22.5021 1.71754 22.5021H22.287C23.6205 22.5021 24.444 21.0171 23.757 19.8516L13.473 2.35112Z'
          fill='#C58926'
        />
        <path
          d='M10.5029 17.9997C10.5029 17.8027 10.5417 17.6077 10.6171 17.4257C10.6925 17.2437 10.803 17.0783 10.9423 16.939C11.0816 16.7997 11.2469 16.6892 11.4289 16.6139C11.6109 16.5385 11.8059 16.4997 12.0029 16.4997C12.1999 16.4997 12.395 16.5385 12.577 16.6139C12.7589 16.6892 12.9243 16.7997 13.0636 16.939C13.2029 17.0783 13.3134 17.2437 13.3887 17.4257C13.4641 17.6077 13.5029 17.8027 13.5029 17.9997C13.5029 18.3975 13.3449 18.779 13.0636 19.0603C12.7823 19.3417 12.4008 19.4997 12.0029 19.4997C11.6051 19.4997 11.2236 19.3417 10.9423 19.0603C10.661 18.779 10.5029 18.3975 10.5029 17.9997ZM10.6499 8.99219C10.63 8.80295 10.65 8.61163 10.7087 8.43063C10.7675 8.24963 10.8636 8.08301 10.9909 7.94157C11.1182 7.80013 11.2738 7.68703 11.4476 7.60961C11.6215 7.53219 11.8096 7.49219 11.9999 7.49219C12.1902 7.49219 12.3784 7.53219 12.5522 7.60961C12.726 7.68703 12.8817 7.80013 13.009 7.94157C13.1362 8.08301 13.2324 8.24963 13.2911 8.43063C13.3499 8.61163 13.3699 8.80295 13.3499 8.99219L12.8249 14.2527C12.8073 14.4593 12.7127 14.6519 12.56 14.7921C12.4072 14.9324 12.2073 15.0103 11.9999 15.0103C11.7925 15.0103 11.5927 14.9324 11.4399 14.7921C11.2871 14.6519 11.1926 14.4593 11.1749 14.2527L10.6499 8.99219Z'
          fill='#C58926'
        />
      </g>
      <defs>
        <clipPath id='clip0_10885_100743'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
