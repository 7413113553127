/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppInputNumber } from '../../../../../../../../../../ui/AppInputNumber';
import { generateEmptyRow, getCellsAmount, getRowsAmount, normalizeField } from '../comparison-table-utils';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useContentTable } from '../hooks/useContentTable';

export function RowsField() {
  const { fieldLabels } = useLocalization().translations.unique.pages.questionnaire.contentTable;
  const [rowsAmount, setRowsAmount] = useState('');
  const { isViewMode } = useSelector(({ finders }) => finders);
  const { contentTableState } = useContentTable();
  const { tableRows } = contentTableState;

  const onChangeRowsField = ({ target }) => {
    contentTableState.updateTable(table => {
      let { value } = target;
      value = normalizeField(value);

      const isMore = value > rowsAmount;
      const isLess = value < rowsAmount;
      const isEmpty = value < 1;

      if (isEmpty) {
        table.tableRows = [];
      } else if (isMore) {
        const rowsToAddAmount = value - rowsAmount;
        const cellsAmount = getCellsAmount(table.tableRows);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < rowsToAddAmount; i++) {
          table.tableRows.push(generateEmptyRow(cellsAmount || 1));
        }
      } else if (isLess) {
        const rowsToDeleteAmount = rowsAmount - value;

        table.tableRows.splice(value, rowsToDeleteAmount);
      }

      setRowsAmount(value);
    });
  };

  useEffect(() => {
    setRowsAmount(getRowsAmount(tableRows));
  }, [tableRows]);

  return <AppInputNumber size='small' label={fieldLabels.rows} value={rowsAmount} onChange={onChangeRowsField} disabled={isViewMode} />;
}
