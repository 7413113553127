// import { StrictMode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { useChainProviders as createReactProviders } from 'react-flat-providers'; // alias here because of ESLint react hooks rule
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../../../lib/store';
import { LocalizationProvider } from './components/LocalizationProvider';
import { theme } from '../../lib/styles/theme';
import { AppAntdConfigProvider } from './components/AppAntdConfigProvider';

export const AppProvider = createReactProviders()
  // .add(StrictMode)
  .add(ReduxProvider, { store })
  .add(PersistGate, { loading: null, persistor })
  .add(LocalizationProvider)
  .add(AppAntdConfigProvider)
  .add(ThemeProvider, { theme }) // one more theme provider for accessing the theme in "styled-processes"

  .make();
