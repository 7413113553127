/* eslint-disable max-lines-per-function */
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { selectVariable, deleteVariableRequest } from 'src/lib/store/slices/variablesSlice';
import { useLocalization } from 'src/lib/hooks/useLocalization';
import { AppTooltip } from 'src/ui/AppTooltip';

import { DeleteConfirmationPopup } from './VariablePopup/DeleteConfirmationPopup';
import { EditIcon } from 'src/ui/icons/EditIcon';
import { DeleteIcon } from 'src/ui/icons/DeleteIcon';
import { AppIconButton } from 'src/ui/AppIconButton';
import { formatVariableText } from 'src/lib/util/helpers';

import styles from './VariableTable.module.scss';
import { useState } from 'react';

/**
 * @desc Table
 * @param {Function} setIsModalOpen
 * @returns {JSX.Element}
 */
export function Table({ setIsModalOpen }) {
  const { variables, loading } = useSelector(({ variables: variablesState }) => variablesState);
  const { rules } = useSelector(({ rules: allRules }) => allRules);
  const { variables: variablesLocalization } = useLocalization().translations.unique.pages;
  const dispatch = useDispatch();

  const [deletePopupInfo, setDeletePopupInfo] = useState({
    variable: {},
    isOpen: false,
    variableUsage: {},
  });

  const editVariable = variable => {
    if (!loading) {
      dispatch(selectVariable(variable));
      setIsModalOpen(true);
    }
  };

  const deleteVariable = variable => {
    const variableUsage = {
      conditions: new Set(),
      rules: new Set(),
    };

    for (const rule of rules) {
      for (const condition of rule.conditions) {
        for (const bodyItem of condition.body) {
          if (
            (bodyItem.type === 'Variable' && bodyItem.value === variable.id) ||
            (bodyItem.type === 'Result Type' && bodyItem.value.elementType === 'Set Variable' && bodyItem.value.element === variable.id)
          ) {
            variableUsage.rules.add(rule.name);
            variableUsage.conditions.add(condition.name);
          }
        }
      }
    }

    setDeletePopupInfo({
      isOpen: true,
      variable,
      variableUsage,
    });
  };

  return (
    <div className={classNames(styles.table, { [styles.disabled]: loading })}>
      <DeleteConfirmationPopup
        deletePopupInfo={deletePopupInfo}
        onClose={() => {
          setDeletePopupInfo({
            isOpen: false,
          });
        }}
        onDelete={variableId => {
          dispatch(deleteVariableRequest(variableId));
          setDeletePopupInfo({
            isOpen: false,
          });
        }}
      />
      <div className={classNames(styles.header, styles.row)}>
        <div className={styles.column}>{variablesLocalization.name()}</div>
        <div className={styles.column}>{variablesLocalization.dataType()}</div>
        <div className={styles.column}>{variablesLocalization.defaultState()}</div>
      </div>

      {variables.map(variable => (
        <div key={variable.id} className={classNames(styles.tableRow, styles.row)}>
          <div className={styles.column}>
            <AppTooltip text={variable.name}>{variable.name}</AppTooltip>
          </div>
          <div className={styles.column}>
            <AppTooltip text={variable.type}>{variable.type}</AppTooltip>
          </div>
          <div className={classNames(styles.column, styles.actionColumn)}>
            <div className={styles.defaultValue}>
              <AppTooltip text={formatVariableText(variable.defaultValue)}>{formatVariableText(variable.defaultValue)}</AppTooltip>
            </div>
            <div className={styles.actions}>
              <AppIconButton onClick={() => editVariable(variable)}>
                <EditIcon />
              </AppIconButton>
              <AppIconButton onClick={() => deleteVariable(variable)}>
                <DeleteIcon />
              </AppIconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
