import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';
import { PRODUCT_CATEGORY_ID } from './product-category-utils';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../utils/arranges-helpers';
import { useStringArrange } from '../../../../utils/hooks/arranges-by-type/useStringArrange';
import { getFilteredCategoriesFromApi } from '../../../../utils/api';
import { useArrangeSubmit } from '../../../../utils/hooks/useArrangeSubmit';
import { TableHeaderCell } from '../../../../components/TableHeaderCell';
import { Category } from '../../../../pages/FindersAndProductsPage/modules/categories/CategoriesTable/lib/Category';
import { useProductArrangeRequests } from '../../lib/useProductArrangeRequests';
import { ArrangeListPopup } from '../../../../components/popups/ArrangeListPopup';
import { useTenantIdFromUri } from '../../../../../../lib/hooks/useTenantIdFromUri';

export function ProductCategoryHeaderCell() {
  const { category: title } = useLocalization().translations.unique.pages.products.table.columns;
  const { activeArrangedId, sorting } = useSelector(({ products }) => products);
  const isActiveArrange = activeArrangedId === PRODUCT_CATEGORY_ID;
  const [form] = Form.useForm();
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);
  const [filterLabel, setFilterLabel] = useState('');
  const tenantId = useTenantIdFromUri();

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredCategoriesFromApi(tenantId, { searchByName: value }),
    formatResponse: response => formatArrangedData(response, Category.NAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useProductArrangeRequests({
    arrangedId: PRODUCT_CATEGORY_ID,
    clearFilters,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ equalByCategories: getItemsValues() }),
    form,
    setFilterLabel,
    sortFieldName: `${PRODUCT_CATEGORY_ID}.name`,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    sortOrder: sorting.sortOrder,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
