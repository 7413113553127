import { useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { IconButton } from '../../../../../../../../features/IconButton';
import { DeleteIcon } from '../../../../../../../../../../../../ui/icons/DeleteIcon';
import { AnswerSelectFormItem } from '../../../../../../../../features/form-items/AnswerSelectFormItem';
import { SelectMultiGoToFormItem } from '../../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { CustomSwitch } from '../../../../../../../../features/CustomSwitch';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { ConditionTypes } from '../../../../../../../../../../../../lib/models/TemplateTypes';
import '../../../../style.scss';

/**
 * @desc Answers Condition Block
 * @param {Number} index
 * @param {String} conditionItem
 * @param {Array} conditions
 * @param {Array} answers
 * @param {Boolean} multipleChoice
 * @param {Function} handleChangeFieldValue
 * @param {String} elementId
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
export function AnswersConditionBlock({ index, conditionItem, conditions, answers, multipleChoice, handleChangeFieldValue, elementId, disabled }) {
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { if: ifText, goTo: goToText, elseIf: elseIfText, and, or } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;

  const handleChangeConditionBlockField = fieldName => value => {
    const updatedConditionsArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, [fieldName]: value } : condItem;
    });
    handleChangeFieldValue(updatedConditionsArr);
  };

  const handleChangeConditionBlockFields = values => {
    const updatedConditionsArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, ...values } : condItem;
    });
    handleChangeFieldValue(updatedConditionsArr);
  };

  const toggleCondition = () => {
    const updateCondition = conditionItem.condition === ConditionTypes.AND ? ConditionTypes.OR : ConditionTypes.AND;
    const updatedConditionsArr = conditions.map((condItem, condIndex) => {
      return condIndex === index ? { ...condItem, condition: updateCondition } : condItem;
    });
    handleChangeFieldValue(updatedConditionsArr);
  };

  const deleteCondition = () => {
    const updatedStatesArr = conditions.filter((condItem, condIndex) => condIndex !== index);
    handleChangeFieldValue(updatedStatesArr);
  };

  return (
    <div>
      {index !== 0 && ( // first condition block don't need OR/AND operator and delete button
        <div className='align_items_center space_between' style={{ marginBottom: 16 }}>
          <div style={{ width: 108 }}>
            <CustomSwitch
              items={[and, or]}
              status={!multipleChoice ? false : conditionItem.condition === ConditionTypes.AND}
              onClick={() => toggleCondition()}
              itemWidth={50}
              disabled={disabled || !multipleChoice}
            />
          </div>
          <IconButton onClick={deleteCondition} disabled={disabled} icon={<DeleteIcon className='flow_process_box_delete_icon' />} />
        </div>
      )}
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={ifText} className='flow_process_box_select_title' />
        <AnswerSelectFormItem
          value={conditionItem.ifAnswers}
          onChange={handleChangeConditionBlockField('ifAnswers')}
          disabled={disabled}
          options={answers}
          allAnswers={answers}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={goToText} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={conditionItem}
          options={questionnaireStructure}
          onChange={handleChangeConditionBlockFields}
          disabled={disabled}
          goToFieldName='ifGoTo'
          showElementsFieldName='ifShowElements'
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={elseIfText} className='flow_process_box_select_title' />
        <AnswerSelectFormItem
          value={conditionItem.elseIfAnswers}
          onChange={handleChangeConditionBlockField('elseIfAnswers')}
          disabled={disabled}
          options={answers}
          allAnswers={answers}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <AppText textValue={goToText} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={conditionItem}
          options={questionnaireStructure}
          onChange={handleChangeConditionBlockFields}
          disabled={disabled}
          goToFieldName='elseIfGoTo'
          showElementsFieldName='elseIfShowElements'
        />
      </div>
    </div>
  );
}
