export function getDynamicFormLetter({ emailBody, bankName, emailHeaderText, formattedValuesToHtml, logo }) {
  const topPart = `<!DOCTYPE html>
  <html lang="en">
      <head>
      <meta charset="UTF-8" />
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap" rel="stylesheet">
      </head>
  <body>
  <div style="background-color: #F9F9F9 ;font-family: Montserrat, Arial, Helvetica, sans-serif; padding: 15px 30px 30px 15px; text-align: center; min-width:250px; margin: 0 auto;">
    <table style="margin:0 auto;box-sizing:border-box;background:#FFFFFF;border-collapse:collapse;border-spacing:0;color: #333333; font-family: Montserrat, Arial, Helvetica, sans-serif; font-size: 16px;height: 100%;padding:0; width: 100%;max-width: 500px;  text-align:left;box-shadow:0 4px 4px rgba(0, 0, 0, 0.07);"
    >
      <tbody>
      <tr>
        <td align="left" style="height: 50px;padding:24px 49px 0px 49px;" >
          <img src="${logo}" style="height: 45px;" height="45" alt="logo">
        </td>
      </tr>`;

  let middlePart = null;

  middlePart = emailBody
    ? `<tr style="box-sizing:border-box">
    <td style="padding:24px 49px;">
      ${emailBody.replaceAll('%CF%', formattedValuesToHtml)}
    </tr></td>`
    : `<tr style="box-sizing:border-box">
    <td style="padding:24px 49px;">
      <p style="font-size:16px;font-weight:400;line-height:24px;letter-spacing:0.2px;">Liebes Team,</p>
      ${emailHeaderText || ''}
      ${formattedValuesToHtml}
      <p style="font-size:16px;font-weight:400;line-height:24px;letter-spacing:0.2px;">
        Mit freundlichen Grüßen,
      </p>
      <p style="font-size:16px;font-weight:400;line-height:24px;letter-spacing:0.2px;">
      ${bankName}
      </p>
    </td>
  </tr>`;

  const bottomPart = `</tbody>
  </table>
</div>
</body>
</html>`;

  return `${topPart} ${middlePart} ${bottomPart}`;
}
