export function NonLinearFlowIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 -2.18557e-08C12.6326 -1.60592e-08 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5L13 1C13 3.202 11.76 4.827 10.582 5.874C10.03 6.364 9.48 6.739 9.05 7C9.48 7.26 10.03 7.636 10.582 8.126C11.76 9.173 13 10.798 13 13L12 13C12 11.202 10.99 9.827 9.918 8.874C9.33433 8.3587 8.69029 7.91613 8 7.556C7.30976 7.91582 6.66572 8.35805 6.082 8.873C5.01 9.828 4 11.204 4 13L3 13C3 10.798 4.24 9.173 5.418 8.126C5.89389 7.70551 6.40662 7.32866 6.95 7C6.52 6.74 5.97 6.364 5.418 5.874C4.24 4.827 3 3.202 3 1L3 0.5C3 0.367391 3.05268 0.240214 3.14645 0.146446C3.24021 0.052678 3.36739 -4.21055e-07 3.5 -4.15258e-07C3.63261 -4.09462e-07 3.75979 0.052678 3.85355 0.146446C3.94732 0.240214 4 0.367391 4 0.5L4 1C4 2.798 5.01 4.173 6.082 5.126C6.66567 5.6413 7.30971 6.08387 8 6.444C8.69024 6.08418 9.33428 5.64195 9.918 5.127C10.99 4.172 12 2.796 12 1L12 0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 -2.76522e-08 12.5 -2.18557e-08Z'
        fill='white'
      />
      <path
        d='M10.5339 13L14.4659 13C14.5134 13 14.5599 13.0136 14.6 13.0391C14.6401 13.0646 14.6721 13.101 14.6922 13.144C14.7124 13.187 14.7198 13.2349 14.7138 13.282C14.7077 13.3291 14.6883 13.3735 14.6579 13.41L12.6919 15.77C12.5919 15.89 12.4079 15.89 12.3079 15.77L10.3419 13.41C10.3115 13.3735 10.2921 13.3291 10.2861 13.282C10.28 13.2349 10.2875 13.187 10.3076 13.144C10.3277 13.101 10.3597 13.0646 10.3998 13.0391C10.4399 13.0136 10.4864 13 10.5339 13ZM1.53391 13L5.46591 13C5.51342 13 5.55993 13.0136 5.60001 13.0391C5.64009 13.0646 5.67208 13.101 5.69222 13.144C5.71237 13.187 5.71984 13.2349 5.71377 13.282C5.70769 13.3291 5.68832 13.3735 5.65791 13.41L3.69191 15.77C3.59191 15.89 3.40791 15.89 3.30791 15.77L1.34191 13.41C1.31151 13.3735 1.29214 13.3291 1.28606 13.282C1.27998 13.2349 1.28746 13.187 1.3076 13.144C1.32775 13.101 1.35974 13.0646 1.39982 13.0391C1.4399 13.0136 1.48641 13 1.53391 13Z'
        fill='white'
      />
    </svg>
  );
}
