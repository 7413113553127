import { Table } from 'antd';
import styled from 'styled-components';
import { PAGINATION_ELEMENTS_AMOUNT } from '../processes/CMS/lib/consts';
import { isLength, isOdd } from '../lib/util/helpers';

// TODO: add localization
export function AppTable(props) {
  const { pagination, ...restProps } = props;

  return (
    <Root
      rowKey='id'
      scroll={{ x: 'max-content' }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      pagination={{
        defaultPageSize: PAGINATION_ELEMENTS_AMOUNT,
        showSizeChanger: false,
        ...pagination,
      }}
      {...restProps}
    />
  );
}

const Root = styled(Table)(props => ({
  maxWidth: isLength(props?.dataSource) ? '100%' : 'calc(100vw - 66px)',
  '.ant-table-column-title': {
    flex: 'unset',
  },
  '.ant-table-thead': {
    '.ant-table-filter-column': {
      justifyContent: 'unset',
      alignItems: 'flex-start',
    },
  },
  '.ant-dropdown-trigger.ant-table-filter-trigger': {
    borderRadius: '0px',
    marginLeft: '8px',
    marginTop: '0px',

    svg: {
      marginBottom: '11px', // margin between the button and arrange popup
    },

    '&:hover': {
      background: 'none',
    },
  },
}));
