import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { QuestionnaireTextButton } from '../../../../../features/QuestionnaireTextButton';
import { useUpdateStructure } from '../../../../../hooks/useUpdateStructure';
import { generateTable } from './generateTable';

export function AddTable({ structureItemIndex }) {
  const { updateStructure } = useUpdateStructure();
  const { contentTable: contentTableText } = useLocalization().translations.unique.pages.questionnaire;

  const onClick = () => {
    updateStructure(draft => {
      draft[structureItemIndex].content.tables.push(generateTable());
    });
  };

  return <QuestionnaireTextButton onClick={onClick} textValue={contentTableText.addTable} style={{ padding: '0 24px' }} />;
}
