import React, { useState } from 'react';
import { Collapse } from 'antd';
import { AppBreakpoint } from '../../../../../../../../../../lib/util/Adaptive';
import { ChevronUpIcon } from '../../../../../../../../../../ui/icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../../../../../../../../../ui/icons/ChevronDownIcon';
import { DetailTableHeadline } from './DetailTableHeadline';
import { CollapseInner } from './CollapseInner';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { useWindowResize } from '../../../../../../utils/hooks/useWindowResize';

const FIRST_INDEX = 0;

export function CollapseItem({ detailTable, colors, fonts, applyVariable, index }) {
  const { details } = useLocalization().translations.unique.pages.finders.detailTable;
  const { windowWidth } = useWindowResize();
  const [activeKey, setActiveKey] = useState([]);

  const handleChange = keys => {
    setActiveKey(keys);
  };

  return (
    <Root key={detailTable.id} backgroundColor={colors.primaryColor} firstIndex={index === FIRST_INDEX} activeKey={activeKey}>
      <Collapse
        collapsible={detailTable?.bulletPointsSection?.bulletPoints?.length === 0 && 'disabled'}
        onChange={handleChange}
        expandIconPosition='end'
        expandIcon={() => (
          <ExpandIconWrapper>
            {windowWidth > AppBreakpoint.M && details()}
            {activeKey.length > 0 ? (
              <ChevronUpIcon fill='#fff' style={{ marginLeft: 8 }} />
            ) : (
              <ChevronDownIcon fill='#fff' style={{ marginLeft: 8 }} />
            )}
          </ExpandIconWrapper>
        )}
        items={[
          {
            key: '1',
            label: (
              <DetailTableHeadline colors={colors} fonts={fonts} applyVariable={applyVariable} detailTable={detailTable} activeKey={activeKey} />
            ),
            children: <CollapseInner detailTable={detailTable} colors={colors} fonts={fonts} applyVariable={applyVariable} />,
          },
        ]}
      />
    </Root>
  );
}

const Root = styled('div')(props => ({
  marginTop: props.firstIndex ? '32px' : '16px',
  '.ant-collapse-header': {
    padding: '0px !important',
    color: '#fff !important',
    display: 'block !important',
    '.ant-collapse-expand-icon': {
      display: 'none !important',
    },
  },
}));

const ExpandIconWrapper = styled('span')({
  marginLeft: 50,
  fontSize: '16px',
});
