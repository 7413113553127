import { PersonEditor, PersonEditorKind } from '../../../../../../../../../../features/PersonEditor';

const EMPTY = '-';

export function FinderEditModeBodyCell({ item }) {
  const isCurrentlyEditing = item?.blockedByUser;
  const isLastEdited = item?.lastBlockedByUser;
  const neverBeenEdited = !isCurrentlyEditing && !isLastEdited;

  if (neverBeenEdited) {
    return EMPTY;
  }

  if (isCurrentlyEditing) {
    return <PersonEditor kind={PersonEditorKind.EDITING} fullName={item?.blockedByUser?.login} />;
  }

  if (isLastEdited) {
    return <PersonEditor kind={PersonEditorKind.LAST_EDITED} fullName={item?.lastBlockedByUser?.login} />;
  }

  return null;
}
