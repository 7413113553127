/* eslint-disable no-param-reassign */
import { AppBox } from '../../../../../../../../../../../../../ui/boxes/AppBox';
import { AppText } from '../../../../../../../../../../../../../ui/text/AppText';
import { QuestionnaireSwitch } from '../../../../../../../features/QuestionnaireSwitch';
import { CardSectionHeader } from '../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductBulletPointSelects } from './ProductBulletPointSelects';
import styled from 'styled-components';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import { isFalsy } from '../../../../../../../../../../../../../lib/util/helpers';
import { PRODUCT_MULTIPLE_COUNTERS_TEMPLATE_DEFAULT_CONTENT } from '../../../../../../../../../../../../../lib/models/Questionnaire';
import { AppCheckbox } from '../../../../../../../../../../../../../ui/AppCheckbox';
import { CurrencyFormItem } from '../../../../../../../../../features/form-items/CurrencyFormItem';
import { CurrencySymbolAndCode } from '../../../../../../../../../../../../../lib/models/Finance';
import { useSelector } from 'react-redux';

// eslint-disable-next-line max-lines-per-function
export function ProductTopSectionBasicHeader({ structureItem, structureItemIndex }) {
  const contentText = useLocalization().translations.unique.pages.questionnaire.content;
  const { showCurrencySymbol: showCurrencySymbolText } = useLocalization().translations.unique.formItems;
  const { updateStructure } = useUpdateStructure();

  const { isViewMode } = useSelector(({ finders }) => finders);

  const toggle = value => {
    updateStructure(draft => {
      draft[structureItemIndex].content.productSelection = value;
    });
  };

  const toggleShowSavings = value => {
    updateStructure(draft => {
      draft[structureItemIndex].content.showSavings = value;
    });
  };

  const handleShowCurrencySymbol = value => {
    updateStructure(draft => {
      const { showCurrencySymbol, ...rest } = structureItem.content;
      if (!value) {
        draft[structureItemIndex].content.showCurrencySymbol = true;
      } else {
        draft[structureItemIndex].content = { ...rest };
      }
    });
  };

  const handleChangeCurrencySymbol = value => {
    updateStructure(draft => {
      draft[structureItemIndex].content.currencySymbol = value.value;
    });
  };

  return (
    <div>
      <CardSectionHeader
        style={{
          marginBottom: '5px',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <AppBox $flexY style={{ paddingTop: '2px' }}>
          <AppText strong textValue={contentText.products} />
        </AppBox>

        <Right>
          <QuestionnaireSwitch
            style={{ paddingTop: '2px' }}
            labelProps={{ textValue: contentText.productSelection }}
            isOn={structureItem.content.productSelection}
            onChange={toggle}
          />

          <QuestionnaireSwitch
            style={{ paddingTop: '2px' }}
            labelProps={{ textValue: contentText.showSavings }}
            isOn={
              isFalsy(structureItem.content.showSavings)
                ? PRODUCT_MULTIPLE_COUNTERS_TEMPLATE_DEFAULT_CONTENT.showSavings
                : structureItem.content.showSavings
            }
            onChange={toggleShowSavings}
          />

          <ProductBulletPointSelects
            structureItemIndex={structureItemIndex}
            bulletPointIcon={structureItem.content.bulletPointIcon}
            bulletPointIconColor={structureItem.content.bulletPointIconColor}
          />
        </Right>
      </CardSectionHeader>

      <StyledCurrencyWrapper>
        <StyledWrapperCheckbox>
          <AppCheckbox
            onChange={() => handleShowCurrencySymbol(structureItem.content?.showCurrencySymbol)}
            checked={structureItem.content?.showCurrencySymbol}
            textValue={showCurrencySymbolText}
            disabled={isViewMode}
          />
        </StyledWrapperCheckbox>
        <Styled
          as={CurrencyFormItem}
          size='small'
          currencies={CurrencySymbolAndCode}
          style={{ width: 'unset', position: 'static' }}
          formItemProps={{
            style: { width: '130px' },
          }}
          selectProps={{
            isSearchable: false,
            disabled: !structureItem.content?.showCurrencySymbol,
            size: 'small',
            value: structureItem.content.currencySymbol,
            onChange: selected => handleChangeCurrencySymbol(selected),
          }}
        />
      </StyledCurrencyWrapper>
    </div>
  );
}

const Right = styled('div')({
  columnGap: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
});

const StyledCurrencyWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'end',
});

const StyledWrapperCheckbox = styled('div')({
  marginRight: 10,
});

const Styled = styled('div')({
  '.react-select__value-container': {
    padding: '4px 5px 4px',
    overflowX: 'hidden !important',
  },
});
