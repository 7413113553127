import { createSlice } from '@reduxjs/toolkit';
import { TableSortOrder } from '../../../processes/CMS/lib/consts';
import { DEFAULT_PAGINATION } from '../../util/constants';

const initialState = {
  tenants: [],
  activeArrangedId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE, // this field in a store because pagination after sort is applied should be with the sort
    sortFieldName: null,
    withSorting: true,
  },
  pagination: DEFAULT_PAGINATION,
  filtering: {}, // this field in a store because pagination after filters are applied should be with the filters
  loading: false,
  isModalLoading: false,
  error: null,
  selectedTenant: {},
  modalType: '',
  isModalOpen: false,
  tenantDomains: [],
  tenantDomainsLoading: false,
};

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    removeTenantArranges: state => {
      state.filtering = {};
      state.sorting = {};
      state.activeArrangedId = null;
      state.pagination = DEFAULT_PAGINATION;
    },
    getTenantsRequest: state => {
      state.error = null;
      state.loading = true;
      state.tenants = [];
    },
    getTenantsSuccess: (state, action) => {
      state.tenants = action.payload.tenants;
      state.pagination = { ...state.pagination, current: action.payload.page, pageSize: action.payload.size, total: action.payload.total };
      state.loading = false;
    },
    getTenantsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setTenantStatusRequest: state => {
      state.error = null;
      state.loading = true;
    },
    setTenantStatusSuccess: state => {
      state.loading = false;
    },
    setTenantStatusError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    openTenantModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedTenant = action.payload.selectedTenant || {};
    },
    closeTenantModal: state => {
      state.isModalOpen = false;
      state.modalType = '';
      state.selectedTenant = {};
    },
    addTenantRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    addTenantSuccess: state => {
      state.isModalLoading = false;
    },
    addTenantError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    editTenantRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    editTenantSuccess: state => {
      state.isModalLoading = false;
    },
    editTenantError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    deleteTenantRequest: state => {
      state.error = null;
      state.isModalLoading = true;
    },
    deleteTenantSuccess: state => {
      state.isModalLoading = false;
    },
    deleteTenantError: (state, action) => {
      state.error = action.payload;
      state.isModalLoading = false;
    },
    applyTenantArrangesRequest: (state, { payload }) => {
      state.error = null;
      state.loading = true;
      state.sorting = payload.sorting;
      state.filtering = payload.filtering;
      state.activeArrangedId = payload.activeArrangedId;
    },
    removeTenantFiltersRequest: state => {
      state.error = null;
      state.loading = true;
      state.filtering = {};
    },
    removeTenantFiltersError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    applyTenantArrangesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getTenantDomainsRequest: state => {
      state.error = null;
      state.tenantDomainsLoading = true;
      state.tenantDomains = [];
    },
    getTenantDomainsSuccess: (state, action) => {
      state.tenantDomainsLoading = false;
      state.tenantDomains = action.payload;
    },
    getTenantDomainsError: (state, action) => {
      state.error = action.payload;
      state.tenantDomainsLoading = false;
    },
  },
});

export const {
  getTenantsRequest,
  getTenantsSuccess,
  getTenantsError,
  setTenantStatusRequest,
  setTenantStatusSuccess,
  setTenantStatusError,
  openTenantModal,
  closeTenantModal,
  addTenantRequest,
  addTenantSuccess,
  editTenantRequest,
  editTenantSuccess,
  editTenantError,
  addTenantError,
  deleteTenantRequest,
  deleteTenantSuccess,
  deleteTenantError,
  removeTenantArranges,
  applyTenantArrangesRequest,
  applyTenantArrangesError,
  removeTenantFiltersRequest,
  removeTenantFiltersError,
  getTenantDomainsRequest,
  getTenantDomainsSuccess,
  getTenantDomainsError,
} = tenantsSlice.actions;
export const tenants = tenantsSlice.reducer;
