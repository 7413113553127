import { useCallback } from 'react';
import { rewriteChapterSelectedProducts } from '../../../../../utils/end-user-flow-helpers';

export function useUpdateProductsStructure(setPagesFlow, structureItemId) {
  return useCallback(
    (updatedProducts, selectedProducts, isClicked, productElement, unselectedIdsArr = []) => {
      setPagesFlow(prev => ({
        ...prev,
        allChapters: rewriteChapterSelectedProducts(prev?.allChapters, prev?.currentChapterId, selectedProducts, unselectedIdsArr),
        allPages: prev?.allPages?.map(page =>
          page.id === prev?.currentPageId
            ? {
                ...page,
                elements: page.elements.map(el =>
                  el.id === structureItemId
                    ? {
                        ...el,
                        content: {
                          ...el.content,
                          products: updatedProducts,
                        },
                      }
                    : el
                ),
              }
            : page
        ),
        currentFlowElement: isClicked ? productElement : prev?.currentFlowElement,
      }));
    },
    [setPagesFlow, structureItemId]
  );
}
