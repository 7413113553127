import { useProductSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { Product } from '../../../../pages/FindersAndProductsPage/lib/Product';

export function useProductShortNameColumn() {
  const { shortName: title } = useLocalization().translations.shared.columns;

  return useProductSearchDataColumn({
    title,
    arrangedId: Product.SHORT_NAME,
    searchFieldName: 'searchByShortName',
    filterFieldName: 'equalsByShortName',
    columnsProps: {
      width: 200,
    },
  });
}
