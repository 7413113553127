/* eslint-disable max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  elementSubTypes,
  productProperties,
  answerProperties,
  types,
  buttonProperties,
  questionProperties,
} from '../../pages/BuilderPage/Condition/Actions/actionConstants';
import { isLength } from '../../../../../../lib/util/helpers';
import { getArrayComparisons, getDigitalComparisons, logicalComparisons } from './logical_and_comparions_helper';
import { getProdPropertyResult, getAnswerPropertyResult, getButtonsPropertyResult, getQuestionsPropertyResult } from './property_result_helper';
import { getAnswers, getProductCards, getQuestions } from './get_elements_helper';
import { getIfArithmetic } from './if_arithmetic_helper';
import { getNumbersCalculation } from './get_numbers_calcaulation';

export const getIfConditions = (ifBlocks, pagesFlow, currentElements) => {
  const { allPages, finder } = pagesFlow;
  const allElements = allPages.flatMap(page => page.elements);
  const updatedForArithmeticBlocks = getIfArithmetic(ifBlocks, pagesFlow, allElements, currentElements);

  return updatedForArithmeticBlocks?.reduce(
    (accumulator, block) => {
      if (Array.isArray(block)) {
        const arrayResult = getIfConditions(block, pagesFlow, currentElements);
        if (accumulator.logicalOperator === 'AND') {
          return { value: accumulator.value && arrayResult.value, logicalOperator: null };
        }
        if (accumulator.logicalOperator === 'OR') {
          return { value: accumulator.value || arrayResult.value, logicalOperator: null };
        }
        return arrayResult;
      }

      if (block.type === types.element) {
        if (!isLength(block.value.elements)) {
          return { ...accumulator, value: null };
        }

        if (block.value.elementType === elementSubTypes.Product) {
          const productCards = getProductCards(block.value.elements, allElements);
          const prodPropertyResult = getProdPropertyResult(productCards, block.value.property, pagesFlow, currentElements);
          // // eslint-disable-next-line no-console
          // console.log('prodPropertyResult', prodPropertyResult);
          if ([productProperties.selected, productProperties.notSelected].includes(block.value.property)) {
            return logicalComparisons(accumulator, prodPropertyResult);
          }
          if ([productProperties.counter, productProperties.savings, productProperties.score].includes(block.value.property)) {
            return getArrayComparisons(accumulator, prodPropertyResult);
          }
        }

        if (block.value.elementType === elementSubTypes.Answer) {
          const answers = getAnswers(block.value.elements, allElements);
          const answerPropertyResult = getAnswerPropertyResult(answers, block.value.property, block.value.composerItem, pagesFlow);
          // // eslint-disable-next-line no-console
          // console.log('answerPropertyResult', answerPropertyResult);
          if ([answerProperties.selected, answerProperties.notSelected].includes(block.value.property)) {
            return logicalComparisons(accumulator, answerPropertyResult);
          }
          if ([productProperties.counter, productProperties.savings, productProperties.score].includes(block.value.property)) {
            return getArrayComparisons(accumulator, answerPropertyResult);
          }
        }

        if (
          block.value.elementType === elementSubTypes.Question &&
          [questionProperties.selected, questionProperties.notSelected].includes(block.value.property)
        ) {
          const questions = getQuestions(block.value.elements, allElements);
          const questionsPropertyResult = getQuestionsPropertyResult(questions, block.value.property);
          // // eslint-disable-next-line no-console
          // console.log(questionsPropertyResult, 'questionsPropertyResult');
          return logicalComparisons(accumulator, questionsPropertyResult);
        }

        if (
          block.value.elementType === elementSubTypes.Buttons &&
          [buttonProperties.selected, buttonProperties.notSelected].includes(block.value.property)
        ) {
          const buttonsIds = block.value.elements.map(elObject => elObject.value);
          const buttonsPropertyResult = getButtonsPropertyResult(buttonsIds, block.value.property, pagesFlow);
          // // eslint-disable-next-line no-console
          // console.log('buttonsPropertyResult', buttonsPropertyResult);
          return logicalComparisons(accumulator, buttonsPropertyResult);
        }
      }

      if (block.type === types.value && block.value.valueType === 'digit') {
        return getDigitalComparisons(accumulator, block.value.amount);
      }

      if (block.type === types.calculation) {
        const calculation = getNumbersCalculation(block.value);
        return getDigitalComparisons(accumulator, calculation.amount);
      }

      if (block.type === types.logicalOperator) {
        return { ...accumulator, logicalOperator: block.value };
      }

      if (block.type === types.comparisonOperator) {
        return { ...accumulator, comparisonOperator: block.value };
      }

      if (block.type === types.variable) {
        const variables = finder?.customVariables || [];
        const selectedVariable = variables.find(variable => variable.id === block.value);
        const selectedValue = selectedVariable?.value || 0;

        return { ...accumulator, value: selectedValue };
      }

      return accumulator;
    },
    { value: null, logicalOperator: null }
  );
};
