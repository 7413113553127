import styled from 'styled-components';
import { forwardRef } from 'react';
import { AppInput } from './AppInput';

export const AppInputNumber = forwardRef((props, ref) => {
  const { type = 'number', ...restProps } = props;

  return <Root type={type} controls={false} {...restProps} ref={ref} />;
});

const Root = styled(AppInput)(({ suffix, controls }) => ({
  height: '100%',
  '&.ant-input-affix-wrapper': {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '16px',
  },

  input: {
    textAlign: 'right',
    height: '100%',

    ...(!controls && {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0px',
      },

      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    }),
  },

  'span[class*="suffix"]': suffix ? { position: 'static', margin: '0 10px 0 0' } : { display: 'none' },
}));
