import { useDispatch, useSelector } from 'react-redux';
import { ScoringBox } from './ScoringBox';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppParagraph } from '../../../../../../../../../../../ui/text/AppParagraph';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import '../style.scss';

/**
 * @desc Renders the question chart scoring component.
 * @param {Object} questionElement - The question element containing scoring information.
 * @param {Object} selectedQuestion - The selected question.
 * @returns {JSX.Element} The rendered question chart scoring component.
 */
export function QuestionChartScoring({ questionElement, selectedQuestion }) {
  const dispatch = useDispatch();
  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);

  const { clearAll } = useLocalization().translations.shared.generic;
  const { addProductWithPrice } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.score;

  const clearChartQuestionScore = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === selectedQuestion.elementId
        ? {
            ...el,
            content: { ...el.content, scoring: el.content.scoring.map(scoreObject => ({ ...scoreObject, value: 0 })) },
          }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return questionElement?.content?.productValues?.some(prodObj => prodObj.value) ? (
    <>
      <div className='products_score_clear_btn'>
        <AppBasicButton style={{ display: 'inline-flex' }} onClick={clearChartQuestionScore} disabled={isViewMode}>
          <AppText className='primary_color bold' textValue={clearAll} />
        </AppBasicButton>
      </div>
      <div className='products_score_block'>
        {questionElement?.content?.scoring
          .filter(scoringObj => questionElement.content.productValues.find(prodObj => prodObj.id === scoringObj.id).value)
          .map((scoreObj, index) => (
            <ScoringBox key={scoreObj.id} index={index} scoreObj={scoreObj} selectedQuestion={selectedQuestion} />
          ))}
      </div>
    </>
  ) : (
    <AppParagraph className='products_score_description' textValue={addProductWithPrice} />
  );
}
