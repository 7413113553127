import { CircleXIcon } from '../../../../../../../../../../../../../ui/icons/CircleXIcon';
import { QuestionnaireBasicButton } from '../../../../../../../features/QuestionnaireBasicButton';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';
import lodashGet from 'lodash.get';

const DELETE_AMOUNT = 1;

export function DeleteProductBulletPointButton({ bulletPointIndex, dataPath, disabled }) {
  const { updateStructure } = useUpdateStructure();

  const deleteBulletPoint = () => {
    updateStructure(draft => {
      const bulletPoints = lodashGet(draft, dataPath);
      bulletPoints.splice(bulletPointIndex, DELETE_AMOUNT);
    });
  };

  return (
    <QuestionnaireBasicButton disabled={disabled} onClick={deleteBulletPoint} style={{ marginBottom: '24px' }}>
      <CircleXIcon />
    </QuestionnaireBasicButton>
  );
}
