import { CardSection } from '../../../../../../../ui/CardSection';
import { PriceSectionHeader } from './PriceSectionHeader';
import { PriceFields } from './PriceFields';

export function PriceSection({ data, productIndex, structureItemIndex, allProducts, product, structureItem }) {
  return (
    <CardSection>
      <PriceSectionHeader data={data} productIndex={productIndex} structureItemIndex={structureItemIndex} />
      <PriceFields
        structureItemIndex={structureItemIndex}
        productIndex={productIndex}
        data={data}
        allProducts={allProducts}
        productId={product?.value}
        structureItem={structureItem}
      />
    </CardSection>
  );
}
