/* eslint-disable no-param-reassign */
/* eslint-disable i18next/no-literal-string */
import { isLength } from '../../../../../../../../../lib/util/helpers';
import { ComparisonTable } from './components/ComparisonTable';
import { InfoIconInputs } from './components/InfoIconInputs';
import { ContentTableContextProvider } from './context/ContentTableContext';
import { useUpdateStructure } from '../../../hooks/useUpdateStructure';
import { TopTableSection } from './components/TopTableSection';
import React from 'react';
import { checkHaveAllTablesSameColumnsAmount } from '../../../../../utils/table-helpers';
import { AppTextArea } from 'src/ui/AppTextArea';
import styled from 'styled-components';

function resizeSingleColumn(draftTable, columnIndex, newWidth) {
  draftTable.columnsWidth = { ...draftTable.columnsWidth, [columnIndex]: newWidth };
}

function resizeTablesColumn(event, structureItemIndex, updateStructure, tableList, columnIndex, tableIndex) {
  event.stopPropagation();
  const startX = event.clientX;
  const startWidth = event.target.offsetParent.offsetWidth;

  const doResize = event => {
    const newWidth = startWidth + event.clientX - startX;

    updateStructure(draft => {
      const draftTableList = draft[structureItemIndex].content.tables;

      if (checkHaveAllTablesSameColumnsAmount(tableList)) {
        for (const draftTable of draftTableList) {
          resizeSingleColumn(draftTable, columnIndex, newWidth);
        }
      } else {
        const draftTable = draftTableList[tableIndex];
        resizeSingleColumn(draftTable, columnIndex, newWidth);
      }
    });
  };

  const stopResize = () => {
    window.removeEventListener('mousemove', doResize);
    window.removeEventListener('mouseup', stopResize);
  };

  window.addEventListener('mousemove', doResize);
  window.addEventListener('mouseup', stopResize);
}

export function ContentTable({ tableIndex, tables, structureItemIndex, structureItemId }) {
  const table = tables[tableIndex];
  const { tableRows, tableName, footnotes } = table;
  const { updateStructure } = useUpdateStructure();

  const contentTableState = {
    tableIndex,
    structureItemIndex,
    structureItemId,
    tableRows,
    tableName,
    table,
    tableNamePath: `[${structureItemIndex}].content.tables[${tableIndex}].tableName`,
    tablePath: `[${structureItemIndex}].content.tables[${tableIndex}]`,
    updateTable: callback => {
      updateStructure(draft => {
        const draftTable = draft[structureItemIndex].content.tables[tableIndex];
        callback(draftTable);
      });
    },
    updateTableList: callback => {
      updateStructure(draft => {
        const tableList = draft[structureItemIndex].content.tables;
        callback(tableList);
      });
    },
    resizeColumn: (columnIndex, event) => resizeTablesColumn(event, structureItemIndex, updateStructure, tables, columnIndex, tableIndex),
  };

  const updateFootnotes = event => {
    updateStructure(draft => {
      draft[structureItemIndex].content.tables[tableIndex] = {
        ...draft[structureItemIndex].content.tables[tableIndex],
        footnotes: event.target.value,
      };
    });
  };

  return (
    <ContentTableContextProvider value={{ contentTableState }}>
      <div style={styles.root}>
        <TopTableSection />

        {isLength(tableRows) && <ComparisonTable />}

        <InfoIconInputs />

        <div className='footnoteContainer'>
          <TextArea onChange={updateFootnotes} value={footnotes || ''} />
          <Label className={footnotes && 'active'}>Enter footnote text</Label>
        </div>
      </div>
    </ContentTableContextProvider>
  );
}

const styles = {
  root: {
    padding: '34px 24px 16px 0',
  },
};

const Label = styled('label')({
  position: 'absolute',
  fontSize: '12px',
  color: '#9696a2',
  left: '38px',
  top: '-9px',
  padding: '2px',
  backgroundColor: 'white',
});

const TextArea = styled(AppTextArea)({
  marginLeft: '24px',
  padding: '8px !important',
  paddingLeft: '14px !important',
  fontFamily: 'SimonKucher',
  resize: 'none',
  border: '1px solid #5C5C6E !important',
});
