import styled from 'styled-components';
import { AppAvatar } from '../../../ui/AppAvatar';
import { useLocalization } from '../../../lib/hooks/useLocalization';
import { AppText } from '../../../ui/text/AppText';
import { AppSpinner } from '../../../ui/AppSpinner';

const UNKNOWN_PERSON_NAME = '?';

export const PersonEditorKind = {
  EDITING: 'editing',
  LAST_EDITED: 'lastEdited',
};

export function PersonEditor({ fullName, avatarSize = 24, isLoading, kind, rootStyles, textStyles, nameStyles }) {
  const { personEditor } = useLocalization().translations.unique.components;
  const personEditorName = fullName || UNKNOWN_PERSON_NAME;

  const text =
    kind === PersonEditorKind.EDITING ? personEditor.isCurrentlyEditing : kind === PersonEditorKind.LAST_EDITED ? personEditor.lastEdited : null;

  return (
    <Root style={rootStyles}>
      <AppAvatar bgColor='#00ACC0' size={avatarSize} stringStyle={{ top: '-1.5px', fontWeight: 'bold' }}>
        {personEditorName}
      </AppAvatar>
      <TextBlock>
        <AppText textValue={personEditorName} style={{ ...nameStyles, ...textStyles }} />
        &nbsp;
        {text && <AppText textValue={text} style={textStyles} />}
      </TextBlock>
      {isLoading && <AppSpinner show />}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const TextBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '*': {
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
});
