/* eslint-disable unicorn/no-unsafe-regex */
import { useState, useEffect } from 'react';
import { AppText } from '../../../../../../../../../../../../ui/text/AppText';
import { Slider } from 'antd';
import { getAmount, getDotValue } from '../../../../../../utils/slider_input_helpers';
import styled from 'styled-components';
import './style.scss';

/**
 * @desc Input Slider
 * @param {Object} answer
 * @param {Function} onChange
 * @param {Object} textStyle
 * @param {Function} getFormattedValue
 * @param {String} primaryColor
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function InputSlider({ answer, onChange, textStyle, getFormattedValue, primaryColor, disabled }) {
  const [dot, setDot] = useState(0);

  const handleDotChange = value => {
    onChange(getAmount(value, answer));
  };

  useEffect(() => {
    setDot(getDotValue(answer.amount, answer));
  }, [answer, answer.amount]);

  return (
    <div className='layout_number_wrap'>
      <StyledSlider
        className='slider_number_wrap'
        value={dot}
        min={0}
        max={616}
        onChange={handleDotChange}
        primaryColor={primaryColor}
        disabled={disabled}
        tooltip={{ open: false }}
      />
      <div className='slider_number_text_wrap'>
        <AppText style={textStyle} className='layout_number_answer'>
          {String(getFormattedValue(answer.minValue)).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.')}
        </AppText>
        <AppText style={textStyle} className='layout_number_answer'>
          {String(getFormattedValue(answer.maxValue, answer.maxValueText)).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.')}
        </AppText>
      </div>
    </div>
  );
}

const StyledSlider = styled(Slider)`
  .ant-slider .ant-slider-horizontal:hover {
    background: ${props => props.primaryColor} !important;
  }
  .ant-slider-track,
  .ant-slider-track:hover,
  .ant-slider .ant-slider-track,
  .ant-slider:hover .ant-slider-track {
    height: 12px;
    border-radius: 6px;
    background: ${props => props.primaryColor} !important;
  }

  .ant-slider-rail {
    height: 12px;
    background: lightgray !important;
    border-radius: 6px;
  }

  .ant-slider-handle {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid;
    border-color: ${props => props.primaryColor};
    border-radius: 50%;
    margin-top: -8px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);
  }
  .ant-slider-handle:after {
    content: none;
  }
`;
