/* eslint-disable max-lines-per-function, sonarjs/cognitive-complexity, max-lines */
import { AppTooltip } from 'src/ui/AppTooltip';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import { AppTitle } from '../../../../../../../../../ui/text/AppTitle';
import { AppImage } from '../../../../../../../../../ui/AppImage';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';
import { ProductLayoutCounter } from '../../../../QuestionnairePage/components/QuestionnaireRightSideBar/LayoutContent/layouts/ProductLayouts/multiple-counters/components/ProductLayoutCounter';
import { CheckProduct } from '../../../../../../../../../ui/icons/CheckProduct';
import { ProductCardContent } from '../ProductCardContent';
import styles from './styles.module.scss';
import { sendDataToAnalytics } from '../../../../../utils/sendDataToAnalytics';
import { LayoutDesign } from '../../../../../../../../../lib/models/Questionnaire';
import { useVariables } from 'src/processes/CMS/processes/management/pages/PreviewPage/hooks/useVariables';

export function PreviewProductCardPresentational({
  isRecommended,
  pagesFlow,
  setPagesFlow,
  card,
  fonts,
  colors,
  bulletPointIconColor,
  bulletPointIcon,
  customIcons,
  defaultIcons,
  selectable,
  onUpdateCounter,
  hideCounter,
  showSavings,
  productObject,
  onSelectCard,
  layoutDesign,
  currentElements,
  onSliderDraggable,
  hasShowCounter,
}) {
  const { applyVariable } = useVariables(pagesFlow);

  if (isRecommended && card.hvmProductId) {
    const hvmRecommended = JSON.parse(localStorage.getItem('hvmRecommended'));

    const recommendedList = [];

    if (hvmRecommended) {
      recommendedList.push(...hvmRecommended);
    }

    recommendedList.push(card.hvmProductId);

    localStorage.setItem('hvmRecommended', JSON.stringify([...new Set(recommendedList)]));
  }

  const { translations } = useLocalization();
  const { templates } = translations.unique.pages.questionnaire.rightSideBar.layout;
  // const canShowCounter = !selectable && card.counterSection?.showCounter && !hideCounter;
  const canShowCounter = card.counterSection?.showCounter && !hideCounter;

  // IMPORTANT: it should be exactly like this. Do not pass `onSelectCard` directly to the `onClick` (because `onSelectCard` accepts `customValue` argument. Therefore, it should not accept `event` from the `onClick`.)
  const handleSelectCard = () => {
    onSelectCard();
    const { categoryName, product } = card.productNameSection;
    const { title } = product;
    sendDataToAnalytics('Product', `Category: ${categoryName || 'no category'}, Title: ${title}, Selected: ${card.selected ? 'no' : 'yes'}`);
  };

  const counterValue = selectable ? (card.selected ? card.counterSection?.value : 0) : card.counterSection?.value;

  const onUpdateCounterCB = (value, ruleValue) => {
    if (selectable && !card.selected) {
      onSelectCard();
    }
    const checkNullValue = ruleValue === undefined || Number.isNaN(ruleValue) ? value : ruleValue;

    if (selectable && card.selected && checkNullValue === 0) {
      onSelectCard();
    }
    if (selectable && !card.selected) {
      return;
    }

    onUpdateCounter(value, card);
  };
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card} onMouseEnter={() => onSliderDraggable(true)} onMouseLeave={() => onSliderDraggable(false)}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={handleSelectCard}
          className={styles.cardInner}
          style={{
            border: isRecommended || card.selected ? '2px solid #3EAF00' : '2px solid #5C5C6E',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          {!isRecommended && card.productNameSection?.customSign?.isEnabled && (
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '34px',
                padding: '8px 16px',
                borderRadius: '0 0 8px 8px',
                background: colors.primaryColor,
                maxWidth: '40%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxHeight: '40px',
                color: '#fff',
              }}
            >
              <AppTooltip text={applyVariable(card.productNameSection?.customSign?.sign)}>
                <AppText
                  textValue={applyVariable(card.productNameSection?.customSign?.sign)}
                  style={{ color: '#fff', fontWeight: 700, fontSize: fonts.headlineFourth.fontSize, fontFamily: fonts.headlineFourth.font }}
                />
              </AppTooltip>
            </div>
          )}
          {selectable && (
            <div
              style={{
                border: card.selected ? '#3EAF00' : '2px solid #5C5C6E',
                width: '35px',
                height: '35px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '-17.5px',
                left: '-17.5px',
                background: card.selected ? '#3EAF00' : '#fff',
                borderRadius: '50%',
              }}
            >
              {card.selected && <CheckProduct />}
            </div>
          )}

          {isRecommended && (
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '34px',
                padding: '8px 16px',
                borderRadius: '0 0 8px 8px',
                background: '#3EAF00',
              }}
            >
              <AppText
                data-testid='prod_test_card_recommended'
                textValue={templates.recommended}
                style={{ color: '#fff', fontWeight: 700, fontSize: fonts.headlineFourth.fontSize, fontFamily: fonts.headlineFourth.font }}
              />
            </div>
          )}
          <div style={{ width: '100%' }}>
            {card.productNameSection.product.title && (
              <AppTitle
                style={{
                  fontSize: fonts.headlineThird.fontSize,
                  fontFamily: fonts.headlineThird.font,
                  fontWeight: fonts.headlineThird.fontWeight,
                  color:
                    layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL
                      ? layoutDesign?.appTitle?.color
                      : colors[fonts.headlineThird.colorVariant],
                  textAlign: layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL ? layoutDesign?.appTitle?.textAlign : 'initial',
                }}
                level={3}
                textValue={
                  card.productNameSection.isShortProductName ? card.productNameSection.shortProduct?.title : card.productNameSection.product.title
                }
              />
            )}

            {card.image && card.productNameSection.showPicture ? (
              <div style={{ gap: '16px', display: 'flex' }}>
                <div
                  style={{
                    width: layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL ? layoutDesign?.appImage?.width : '70px',
                    height: layoutDesign?.title === LayoutDesign.NORMAL_MODE_CAROUSEL ? layoutDesign?.appImage?.height : '44px',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                >
                  <AppImage style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={card.image} alt='image' />
                </div>
                {layoutDesign.title !== LayoutDesign.NORMAL_MODE_CAROUSEL && (
                  <ProductCardContent
                    pagesFlow={pagesFlow}
                    card={card}
                    fonts={fonts}
                    colors={colors}
                    bulletPointIconColor={bulletPointIconColor}
                    bulletPointIcon={bulletPointIcon}
                    customIcons={customIcons}
                    defaultIcons={defaultIcons}
                    showSavings={showSavings}
                    currentElements={currentElements}
                  />
                )}
              </div>
            ) : (
              <ProductCardContent
                pagesFlow={pagesFlow}
                card={card}
                fonts={fonts}
                colors={colors}
                bulletPointIconColor={bulletPointIconColor}
                bulletPointIcon={bulletPointIcon}
                customIcons={customIcons}
                defaultIcons={defaultIcons}
                showSavings={showSavings}
                currentElements={currentElements}
              />
            )}
          </div>
        </div>
      </div>
      {hasShowCounter && (
        <div className={styles.counterWrapper} style={{ minHeight: '84px' }}>
          {canShowCounter && !card?.isExceptional && (
            <ProductLayoutCounter
              onUpdateCounter={onUpdateCounterCB}
              headline={card.counterSection.headline.value}
              showCounter={!card.counterSection.showCounter}
              value={counterValue}
              minValue={card.counterSection.min}
              maxValue={card.counterSection.max}
              fonts={fonts}
              colors={colors}
              productObject={productObject}
              card={card}
              currentElements={currentElements}
              pagesFlow={pagesFlow}
              setPagesFlow={setPagesFlow}
            />
          )}
        </div>
      )}
    </div>
  );
}
