import { useSelector } from 'react-redux';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { CompletedChapterElement } from './CompletedChapterElement';
import { getBackgroundUrl } from '../../../../../../../../../../../../lib/util/helpers';

/**
 * @desc Layout for NotFirstChapter template
 * @param {Object} chapter
 * @param {Number} chaptersLength
 * @param {Object} stylesConfiguration
 * @param {Object} style
 * @returns {JSX.Element}
 */
export function NotFirstChapter({ chapter, chaptersLength = 1, completed, stylesConfiguration, style = {} }) {
  const {
    finder: {
      finderSettings: { media: mediaOptions },
    },
  } = useSelector(({ finders }) => finders);

  const { otherChaptersStyle = {}, chapterCompletedCheckStyle = {} } = style;
  const { fonts, colors } = stylesConfiguration;
  const { content: { headline, selectBackgroundImageActive } = {} } = chapter;

  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImageActive, mediaOptions);

  const { heading3 } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  return (
    <div
      style={{
        width: `calc((100% - 16px*${chaptersLength - 1}) / ${chaptersLength}`,
        height: 234,
        position: 'relative',
        border: `3px solid ${completed ? colors.primaryColor : 'transparent'}`,
        overflow: 'hidden',
        ...otherChaptersStyle,
      }}
    >
      {completed && <CompletedChapterElement colors={colors} chapterCompletedCheckStyle={chapterCompletedCheckStyle} />}
      <div style={{ height: 64, background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {headline && (
          <h3
            style={{
              margin: '6px 0 6px 0',
              overflow: 'hidden',
              wordBreak: 'break-all',
              textAlign: 'center',
              fontSize: fonts.headlineThird.fontSize,
              fontFamily: fonts.headlineThird.font,
              fontWeight: fonts.headlineThird.fontWeight,
              color: colors[fonts.headlineThird.colorVariant],
              lineHeight: '22px',
            }}
          >
            {headline.value || heading3()}
          </h3>
        )}
      </div>
      <div
        style={{
          width: '100%',
          minHeight: 167,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('${backgroundImageUrl}')`,
        }}
      />
    </div>
  );
}
