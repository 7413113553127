import { useLocalization } from '../../../../../../lib/hooks/useLocalization';
import { QuestionnaireSelect } from '../../pages/QuestionnairePage/features/QuestionnaireSelect';

export function CounterMaxValueFormItem({ value, onChange, ...rest }) {
  const { selectMaxValueText } = useLocalization().translations.unique.formItems;
  const textOptions = [
    { value: '', title: 'Exactly' },
    { value: '>', title: '>' },
    { value: 'Mehr als', title: 'Mehr als' },
  ];
  const { formItemProps = {}, selectProps, ...restRootProps } = rest;
  const { style, ...restFormItemProps } = formItemProps;

  return (
    <QuestionnaireSelect
      formItemProps={{
        style: { width: 124, minHeight: 40, ...style },
        ...restFormItemProps,
      }}
      {...restRootProps}
      selectProps={{
        value: { value },
        onChange: selected => onChange(selected?.value),
        options: textOptions,
        label: selectMaxValueText,
        equalRightBorder: true,
        ...selectProps,
      }}
    />
  );
}
