import { useLocalization } from '../../../../../../../../../../lib/hooks/useLocalization';
import { ComparisonTableCellType } from '../comparison-table-utils';
import { PopupSelect } from './PopupSelect';
import '../ComparisonTable.sass';
import { useContentTable } from '../hooks/useContentTable';

export function CellTypeSelect({ rowIndex, columnIndex }) {
  const { cellTypes } = useLocalization().translations.unique.pages.questionnaire.contentTable;
  const { contentTableState } = useContentTable();
  const currentType = contentTableState.tableRows[rowIndex].cells[columnIndex]?.type;

  const defaultOption = { value: ComparisonTableCellType.TEXT, label: cellTypes.text() };

  const options = [
    { value: ComparisonTableCellType.PRODUCT, label: cellTypes.product() },
    defaultOption,
    { value: ComparisonTableCellType.PICTURE, label: cellTypes.picture() },
    { value: ComparisonTableCellType.ICON, label: cellTypes.icon() },
  ];

  const value = currentType ? options.find(item => item?.value === currentType) : defaultOption;

  const onChange = newSelected => {
    contentTableState.updateTable(({ tableRows }) => {
      const cell = tableRows[rowIndex].cells[columnIndex];

      // Clear data on change type of cell
      if (cell.type !== newSelected?.value) {
        cell.value = undefined;
        cell.image = undefined;
      }

      cell.type = newSelected?.value;
    });
  };

  return (
    <PopupSelect
      options={options}
      value={value}
      onChange={onChange}
      classNames={{
        container: props => props.selectProps.menuIsOpen && 'cellTypeActive',
      }}
    />
  );
}
