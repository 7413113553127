import { TemplateTypes } from '../../../../../lib/models/TemplateTypes';
import lodashFlow from 'lodash.flow';
import { getArrSum, getShallowArrCopy } from '../../../../../lib/util/helpers';

// Reduce helpers
const DEFAULT_SAVING_VALUE = 0;
const savingReduce = (array, callback) => array?.reduce(callback, DEFAULT_SAVING_VALUE);
const getValidCurrValue = (currValueCb, curr) => currValueCb(curr) || DEFAULT_SAVING_VALUE;
const calculate = (array, currValueCb) => savingReduce(array, (acc, curr) => acc + getValidCurrValue(currValueCb, curr));

// Counter funcs
const findProductRanges = (productsRanges, productId) => productsRanges?.find(item => item?.productId === productId)?.savingsRanges;
const getCounterProductRanges = (...args) => findProductRanges(...args) || [];
const calculateCounterSaving = ([coefficient, enteredValue]) => coefficient * enteredValue;
const checkIsValueInRange = (range, value) => value >= range?.minValue && value <= range?.maxValue;
const findFirstMatchedRange = (ranges, enteredValue) => ranges?.find(item => checkIsValueInRange(item, enteredValue)) || {};
const getCoefficient = range => range?.savingsValue;
const findCoefficient = lodashFlow(findFirstMatchedRange, getCoefficient);
const getEnteredValue = answer => answer?.amount;
const getProductsRanges = question => question?.content?.savingsProductsRanges;
const getCounterAnswers = question => question?.content?.counterAnswers;

const calculateCounterAnswerSavingsSum = lodashFlow([
  (answer, ranges) => [ranges, getEnteredValue(answer)],
  ([ranges, enteredValue]) => [findCoefficient(ranges, enteredValue), enteredValue],
  calculateCounterSaving,
]);

const calculateCounterAnswersListSavingsSum = (counterAnswers, savingsProductsRanges, productId) =>
  lodashFlow([
    getCounterProductRanges,
    getShallowArrCopy,
    ranges => calculate(counterAnswers, answer => calculateCounterAnswerSavingsSum(answer, ranges)),
  ])(savingsProductsRanges, productId);

const calculateAllCountersSum = (questionElements, productId) =>
  calculate(questionElements, question => calculateCounterAnswersListSavingsSum(getCounterAnswers(question), getProductsRanges(question), productId));

// Input funcs
const getInputAnswers = question => question?.content?.answers?.filter?.(item => question?.selectedAnswers?.includes?.(item?.id)) || [];
const getSavingsValueSum = (savings, productId) => calculate(savings, curr => curr?.productId === productId && curr?.savingsValue);
const getInputAnswersSavingsSum = (answers, rulesAnswersSavings, productId) =>
  calculate(answers, curr => {
    const ruleAnswer = rulesAnswersSavings.find(answer => answer.id === curr.id);
    const savings = ruleAnswer ? ruleAnswer.savings : curr?.savings;
    return getSavingsValueSum(savings, productId);
  });
const calculateAllInputsSum = (questionElements, rulesAnswersSavings, productId) =>
  calculate(questionElements, question => getInputAnswersSavingsSum(getInputAnswers(question), rulesAnswersSavings, productId));

const getQuestionElementsFromPage = page => page?.elements?.filter(item => item?.templateType === TemplateTypes.QUESTION);
const getQuestionElementsFromAllPages = allPages => allPages?.reduce((acc, curr) => [...acc, ...getQuestionElementsFromPage(curr)], []);

export const calculateProductSavings = (allPages, rulesAnswersSavings, productId) =>
  lodashFlow([
    getQuestionElementsFromAllPages,
    questionElements => [
      calculateAllInputsSum(questionElements, rulesAnswersSavings, productId),
      calculateAllCountersSum(questionElements, productId),
    ],
    getArrSum,
  ])(allPages);
