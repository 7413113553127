import { Form } from 'antd';
import { AppModal } from '../../../../../../../../ui/AppModal';
import { useLocalization } from '../../../../../../../../lib/hooks/useLocalization';
import { DuplicateRuleForm } from './DuplicateRuleForm';
import { useResetFormOnCloseModal } from '../../../../../../lib/hooks/useResetFormOnCloseModal';

/**
 * @desc Duplicate Rule Modal
 * @param {Function} close
 * @param {Boolean} isOpen
 * @param {Object} ruleData
 * @param {Boolean} isModalLoading
 * @returns {JSX.Element}
 */
export function DuplicateRuleModal({ close, isOpen, ruleData, isModalLoading }) {
  const { title } = useLocalization().translations.unique.pages.rules.duplicateModal;
  const [form] = Form.useForm();
  const { generic } = useLocalization().translations.shared;

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <AppModal
      open={isOpen}
      titleProps={{ textValue: title({ name: ruleData.name }) }}
      cancelButtonProps={{ onClick: close }}
      actionButtonProps={{ onClick: form.submit, loading: isModalLoading, textValue: generic.duplicate }}
    >
      {isOpen && <DuplicateRuleForm form={form} ruleData={ruleData} />}
    </AppModal>
  );
}
