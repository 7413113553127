import { HeadCell } from './HeadCell';
import { HeaderDeleteColumnsCells } from './HeaderDeleteColumnsCells';
import classes from '../ComparisonTable.module.sass';

export function DeleteColumnsRow() {
  return (
    <tr>
      <HeadCell className={classes.dragRowHandleCellWidth} />
      <HeadCell className={classes.deleteRowCellWidth} />
      <HeaderDeleteColumnsCells />
    </tr>
  );
}
