export function PlusIcon({ fill, ...rest }) {
  return (
    <svg {...rest} width='16' height='16' viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1373_9511)'>
        <g clipPath='url(#clip1_1373_9511)'>
          <path d='M8 4C8.13261 4 8.25979 4.05268 8.35355 4.14645C8.44732 4.24021 8.5 4.36739 8.5 4.5V7.5H11.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5H8.5V11.5C8.5 11.6326 8.44732 11.7598 8.35355 11.8536C8.25979 11.9473 8.13261 12 8 12C7.86739 12 7.74021 11.9473 7.64645 11.8536C7.55268 11.7598 7.5 11.6326 7.5 11.5V8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H7.5V4.5C7.5 4.36739 7.55268 4.24021 7.64645 4.14645C7.74021 4.05268 7.86739 4 8 4V4Z' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1373_9511'>
          <rect width='16' height='16' />
        </clipPath>
        <clipPath id='clip1_1373_9511'>
          <rect width='16' height='16' />
        </clipPath>
      </defs>
    </svg>
  );
}
