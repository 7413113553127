import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { FlowSingleButtonSection } from '../FlowSingleButtonSection';
import { ChapterStateFormItem } from '../../../../../../../features/form-items/ChapterStateFormItem';
import { SelectMultiGoToFormItem } from '../../../../../../../features/form-items/SelectMultiGoToFormItem';
import { ChapterConditionBlock } from './ChapterConditionBlock';
import { ElementNameWithLabel } from '../../../../../features/ElementNameWithLabel';
import { FlowRulesList } from '../../../components/FlowRulesList';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { ConditionTypes } from '../../../../../../../../../../../lib/models/TemplateTypes';
import { getIndexByTemplateType } from '../../../../../../../../../../../lib/util/helpers';
import '../../../style.scss';

/**
 * @desc Chapter Flow
 * @param {Array} elements
 * @param {Object} element
 * @param {Function} handleChangeFlowFieldValue
 * @param {Function} handleChangeFlowFieldsValue
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 */
export function ChapterFlow({ elements, element, handleChangeFlowFieldValue, handleChangeFlowFieldsValue, disabled }) {
  const dispatch = useDispatch();
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { is, thenGoTo } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.flow;
  const { addCondition } = useLocalization().translations.shared.generic;
  const { primaryButton: primaryButtonText } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const elementIdByType = getIndexByTemplateType(elements, element);

  const { flow, content, templateType, label, id: elementId, rules } = element;
  const { chapterButton } = content;

  const addConditionOnClick = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === elementId
        ? { ...el, flow: { ...el.flow, conditions: [...flow.conditions, { condition: ConditionTypes.AND, chapters: [], states: [] }] } }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  return (
    <>
      <div className='flow_process_box'>
        <ElementNameWithLabel label={label} elementIdByType={elementIdByType} templateType={templateType} style={{ marginBottom: 16 }} />
        <FlowRulesList elementRules={rules} />

        <div style={{ marginBottom: 8 }}>
          <AppText textValue={is} className='flow_process_box_select_title' />
          <ChapterStateFormItem value={flow.ownStates} disabled />
        </div>
        {flow.conditions.map((conditionItem, index) => (
          <ChapterConditionBlock
            key={uuidv4()}
            index={index}
            conditionItem={conditionItem}
            conditions={flow.conditions}
            handleChangeFieldValue={handleChangeFlowFieldValue('conditions')}
            disabled={disabled}
          />
        ))}

        <AppBasicButton onClick={addConditionOnClick} style={{ display: 'inline-flex', margin: '10px 0 16px 0' }} disabled={disabled}>
          <AppText className='primary_color bold' textValue={addCondition} />
        </AppBasicButton>

        <AppText textValue={thenGoTo} className='flow_process_box_select_title' />
        <SelectMultiGoToFormItem
          elementId={elementId}
          flow={flow}
          options={questionnaireStructure}
          onChange={handleChangeFlowFieldsValue}
          disabled={disabled}
        />
      </div>
      <div className='flow_process_box'>
        {chapterButton && elementIdByType === 0 && (
          <FlowSingleButtonSection
            structureItemId={elementId}
            elements={questionnaireStructure}
            buttonObject={chapterButton}
            buttonTextValue={chapterButton.value}
            buttonLabelText={primaryButtonText}
            buttonName='chapterButton'
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}
