import { useSelector } from 'react-redux';
import { AppFormSection } from '../../../../../../ui/AppFormSection';
import { AppNewSelect } from '../../../../../../ui/AppNewSelect';

const defaultFontOptions = [
  {
    title: 'Arial',
    value: 'Arial, sans-serif',
  },
  {
    title: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    title: 'Tahoma',
    value: 'Tahoma, sans-serif',
  },
  {
    title: 'Trebuchet MS',
    value: '"Trebuchet MS", sans-serif',
  },
  {
    title: 'Times New Roman',
    value: '"Times New Roman", serif',
  },
  {
    title: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    title: 'Garamond',
    value: 'Garamond, serif',
  },
  {
    title: 'Courier New',
    value: '"Courier New", monospace',
  },
  {
    title: 'Brush Script MT',
    value: '"Brush Script MT", cursive',
  },
  {
    title: 'SimonKucher',
    value: 'SimonKucher, sans-serif',
  },
];

export function FontFamilyFormItem({ value, onChange, fieldName, field, disabled }) {
  const { fonts } = useSelector(({ themes }) => themes);

  const fontsOptions = [...defaultFontOptions, ...fonts].map(font => {
    if (font.id) {
      return { value: font.fontName, title: <span style={{ fontFamily: font.fontName }}>{font.fontName}</span> };
    }
    return { value: font.value, title: <span style={{ fontFamily: font.value }}>{font.title}</span> };
  });

  return (
    <AppFormSection
      formItemProps={{
        style: { width: '100%', maxWidth: 333 },
      }}
    >
      <AppNewSelect disabled={disabled} onChange={selected => onChange(selected?.value, fieldName, field)} options={fontsOptions} value={{ value }} />
    </AppFormSection>
  );
}
