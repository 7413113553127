import { v4 as uuidv4 } from 'uuid';
import { isLength } from '../../../../../lib/util/helpers';
import { updateQuestionnaireStructure } from '../../../../../lib/store/slices/findersSlice';
import { TemplateTypes } from '../../../../../lib/models/TemplateTypes';

const FLAT_DEPTH = 3;

export const modifiedTemplates = (templates, templateLayouts) => {
  return templates?.map(item => {
    const filteredLayouts = templateLayouts
      ?.filter(layout => layout.templateId === item.id)
      ?.sort((cur, next) => next.usedInTotalFinders - cur.usedInTotalFinders);

    const bestLayout = isLength(filteredLayouts)
      ? { layoutId: String(filteredLayouts[0].id), layoutStyle: JSON.stringify(filteredLayouts[0].content.style) }
      : { layoutId: null, layoutStyle: null };

    return {
      templateId: String(item.id),
      label: item.name,
      id: uuidv4(),
      templateType: item.templateType,
      content: item.content.content,
      ...bestLayout,
    };
  });
};

export const hasValueField = contentObject => {
  const result = Object.values(contentObject).filter(item => {
    return item && typeof item === 'object';
  });
  const newResult = result.map(flatItem => {
    if (Array.isArray(flatItem)) {
      return flatItem?.map(item => item && Object?.values(item));
    }
    return flatItem;
  });
  return newResult.flat(FLAT_DEPTH).some(ele => ele?.value && ele?.value !== 'default');
};

export const deleteItem = (event, elementId, questionnaireStructure, setIsOpenContextMenu, dispatch) => {
  event.stopPropagation();
  event.preventDefault();
  const result = questionnaireStructure.filter(element => element.id !== elementId);
  dispatch(updateQuestionnaireStructure({ structure: result }));
  setIsOpenContextMenu({ isOpen: false, id: null });
};

export function setAllElementsButtons(elements) {
  const allButtons = [];
  // eslint-disable-next-line unicorn/no-array-for-each
  elements.forEach((el, index) => {
    if (el.templateType === TemplateTypes.INTRO) {
      allButtons.push(el.content.primaryButton, el.content.secondaryButton);
    }
    if (el.templateType === TemplateTypes.CHAPTER && index === 0 && el?.content?.chapterButton) {
      allButtons.push(el.content.chapterButton);
    }
    if (el.templateType === TemplateTypes.CHAPTER && el?.content?.completedChaptersButtons) {
      allButtons.push(...el.content.completedChaptersButtons);
    }
    if (el.content?.composerButton) {
      allButtons.push(el.content?.composerButton);
    }
    if (el?.content?.buttons) {
      allButtons.push(...el.content.buttons);
    }
    if (el.templateType === TemplateTypes.RESULT && el?.content?.products) {
      const productsButtons = el.content.products.flatMap(prod => prod.buttonsSection.buttons);
      allButtons.push(...productsButtons);
    }
  });
  return allButtons;
}
