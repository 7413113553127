import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Space } from 'antd';
import { useLocalization } from '../../../../lib/hooks/useLocalization';
import { AppText } from '../../../../ui/text/AppText';
import { AppButton } from '../../../../ui/buttons/AppButton';
import { LeftArrow } from '../../../../ui/icons/LeftArrow';
import { LeftReturnArrow } from '../../../../ui/icons/LeftReturnArrow';
import { RightReturnArrow } from '../../../../ui/icons/RightReturnArrow';
import { SettingsIcon } from '../../../../ui/icons/SettingsIcon';
import { QuestionnaireIcon } from '../../../../ui/icons/QuestionnaireIcon';
import { ProductsIcon } from '../../../../ui/icons/ProductsIcon';
import { RuleBuilderIcon } from '../../../../ui/icons/RuleBuilderIcon';
import { EyeIcon } from '../../../../ui/icons/EyeIcon';
import { publishFinderRequest, redoHistory, undoHistory } from '../../../../lib/store/slices/findersSlice';
import { undo, redo } from '../../../../lib/store/slices/rulesSlice';
import { ROUTER_PATHS } from '../../../../lib/util/router/router-paths';
import { Role } from '../../../../lib/models/Role';
import './styles.scss';
import { PersonEditor, PersonEditorKind } from '../../features/PersonEditor';
import { QuestionnaireVersions } from './QuestionnaireVersions';
import { useCallback } from 'react';

/* eslint max-lines: ["error", 300] */
// eslint-disable-next-line max-lines-per-function
export function FinderSubHeader() {
  const { finderSubHeader } = useLocalization().translations.unique.components;
  const { role } = useSelector(({ auth }) => auth);
  const {
    finder,
    isPublishingLoading,
    updateQuestionnaireStructureLoading,
    tenant,
    isViewMode,
    finderLoading,
    pastQuestionnaireStructure,
    futureQuestionnaireStructure,
  } = useSelector(({ finders }) => finders);

  const { currentHistoryIndex, history: ruleHistory } = useSelector(({ rules }) => rules);

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tenantId = Number(searchParams.get('X-TENANT'));
  const location = useLocation();
  const dispatch = useDispatch();
  const personEditorName = finder?.blockedByUser?.login;

  const finderLinks = [
    {
      key: 1,
      path(idx) {
        return ROUTER_PATHS.settings(idx);
      },
      icon: <SettingsIcon />,
      text: finderSubHeader.settings,
    },
    {
      key: 2,
      path(idx) {
        return ROUTER_PATHS.questionnaire(idx);
      },
      icon: <QuestionnaireIcon />,
      text: finderSubHeader.questionnaire,
    },
    {
      key: 3,
      path(idx) {
        return ROUTER_PATHS.products(idx);
      },
      icon: <ProductsIcon />,
      text: finderSubHeader.products,
    },
  ];

  if (role !== Role.USER) {
    finderLinks.push({
      key: 4,
      path(idx) {
        return ROUTER_PATHS.rules(idx);
      },
      icon: <RuleBuilderIcon />,
      text: finderSubHeader.ruleBuilder,
    });
  }

  const publishFinder = () => {
    dispatch(publishFinderRequest({ finderId: finder.id, tenantId: tenant.id }));
  };

  const handleUndo = useCallback(() => {
    if (pastQuestionnaireStructure?.length > 0) {
      dispatch(undoHistory());
    }
  }, [dispatch, pastQuestionnaireStructure?.length]);

  const handleRedo = useCallback(() => {
    if (futureQuestionnaireStructure.length > 0) {
      dispatch(redoHistory());
    }
  }, [dispatch, futureQuestionnaireStructure]);

  const onOpenPreview = useCallback(() => {
    const url = `${ROUTER_PATHS.preview(id)}?X-TENANT=${tenantId}`;

    window.open(url, '_blank');
  }, [id, tenantId]);

  return (
    <header className='finder_header'>
      <div className='finder_header_wrap'>
        <div className='finder_header_actions_wrap'>
          <Link className='finder_header_back_link' to={role === Role.USER ? ROUTER_PATHS.yourFinders : ROUTER_PATHS.tenant(tenantId)}>
            <LeftArrow styles='finder_header_back_link_icon' />
            <AppText className='white_color' textValue={finderSubHeader.backLink} />
          </Link>
          {location.pathname.includes([ROUTER_PATHS.questionnaire(id)]) && (
            <Space className='finder_header_actions'>
              <button
                type='button'
                className='finder_header_action finder_header_action_btn'
                disabled={isViewMode || pastQuestionnaireStructure?.length === 0}
                onClick={handleUndo}
              >
                <LeftReturnArrow />
              </button>
              <button
                type='button'
                className='finder_header_action finder_header_action_btn'
                disabled={isViewMode || futureQuestionnaireStructure.length === 0}
                onClick={handleRedo}
              >
                <RightReturnArrow />
              </button>
            </Space>
          )}

          {location.pathname.includes([ROUTER_PATHS.builder(id)]) && (
            <Space className='finder_header_actions'>
              <button
                type='button'
                className='finder_header_action finder_header_action_btn'
                disabled={isViewMode || currentHistoryIndex < 1}
                onClick={() => dispatch(undo())}
              >
                <LeftReturnArrow />
              </button>
              <button
                type='button'
                className='finder_header_action finder_header_action_btn'
                disabled={isViewMode || currentHistoryIndex === ruleHistory.length - 1}
                onClick={() => dispatch(redo())}
              >
                <RightReturnArrow />
              </button>
            </Space>
          )}
        </div>

        <div className='finder_header_links'>
          <Space className='finder_header_finder_links_container'>
            {finderLinks.map(link => {
              return (
                <Link
                  key={link.key}
                  className={`finder_header_link ${location.pathname.includes([link.path(id)]) ? 'finder_header_link_active' : ''}`}
                  to={`${link.path(id)}?X-TENANT=${tenantId}`}
                >
                  {link.icon}
                  <AppText
                    className={`white_color finder_header_link_text ${location.pathname.includes([link.path(id)]) ? 'bold' : ''}`}
                    textValue={link.text}
                  />
                </Link>
              );
            })}
          </Space>
          <Space size='middle' align='center'>
            <AppButton
              onClick={onOpenPreview}
              className='finder_header_action finder_header_action_special_btn'
              type='button'
              disabled={finderLoading}
            >
              <EyeIcon />
              <AppText className='white_color finder_header_action_special_btn_text preview_text' textValue={finderSubHeader.preview} />
            </AppButton>
            <QuestionnaireVersions />
            <AppButton
              disabled={updateQuestionnaireStructureLoading || finderLoading}
              type='primary'
              className='finder_header_publish_btn'
              textValue={finderSubHeader.publish}
              loading={isPublishingLoading}
              onClick={publishFinder}
            />
          </Space>
          {isViewMode && (
            <PersonEditor
              fullName={personEditorName}
              isLoading={finderLoading}
              kind={PersonEditorKind.EDITING}
              avatarSize={16}
              rootStyles={{ paddingLeft: '150px' }}
              textStyles={{ color: '#fff', fontSize: '12px' }}
              nameStyles={{ fontWeight: 'bold' }}
            />
          )}
        </div>
      </div>
    </header>
  );
}
