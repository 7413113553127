import { ContactFormRow } from './ContactFormRow';
import { ResultType } from '../../../../../../../../../../../lib/models/Questionnaire';
import { Droppable } from 'react-beautiful-dnd';
import { ListWrapper } from '../../../ListWrapper';
import styled from 'styled-components';

export function ContactFormRowList({ templateContent, structureItemIndex }) {
  return (
    <Droppable
      type={ResultType.CONTACT_FORM_ROW}
      droppableId={JSON.stringify({
        structureItemIndex,
        type: ResultType.CONTACT_FORM_ROW,
      })}
    >
      {provided => (
        <StyledList ref={provided.innerRef} {...provided.droppableProps} withBorderTop={false} borderColor='#E3E3E6'>
          {templateContent?.formRowList?.filter(Boolean)?.map((item, rowIndex) => {
            return (
              <ContactFormRow
                key={item?.id}
                rowIndex={rowIndex}
                structureItemIndex={structureItemIndex}
                templateContent={templateContent}
                id={item?.id}
              />
            );
          })}
          {provided.placeholder}
        </StyledList>
      )}
    </Droppable>
  );
}

const StyledList = styled(ListWrapper)({
  '& > li': {
    padding: '24px 0',
    '&:first-child': {
      paddingTop: 0,
    },
  },
});
