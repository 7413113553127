/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef, useState } from 'react';
import { updateQuestionnaireStructure } from '../../../../../../../../../lib/store/slices/findersSlice';
import { useOutsideClick } from '../../../../../../../../../lib/hooks/useOutsideClick';
import { TemplateTypes } from '../../../../../../../../../lib/models/TemplateTypes';
import { Page } from './Page/Page';
import { Chapter } from './Chapter/Chapter';
import { ContextMenu } from './ContextMenu/ContextMenu';
import { OtherItem } from './OtherItem/OtherItem';
import { UpdateStructureType } from '../../../../../../../../../lib/models/Questionnaire';
import { deleteItem } from '../../../../../utils/questionnaire-helpers';

const handleClickScroll = itemId => {
  const element = document.querySelector(`#${CSS.escape(itemId)}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

/**
 * @desc Navigator content: the elements in the Navigator are named in the next way:
 * Composer element type (Product, Question, Content, etc.), index of element (counter made by composer element type), and Headline name of the template.
 * For example: CH.1 Welcome chapter
 * The user should be able to make a Drag-and-drop (manual sorting) in the navigator to change a sequence.
 * The elements in the chapter can be expanded/hidden by clicking on the arrow in the chapter.
 * The arrows indicate the flow. If there is a straight row, then the flow is Sequential. If the flow has non-sequential logic, e.g. skips some element, then the arrows will intersect.
 * @returns {JSX.Element}
 */

// eslint-disable-next-line max-lines-per-function
export function NavigatorContent() {
  const dispatch = useDispatch();

  const contextMenuRef = useRef(null);

  const [isOpenContextMenu, setIsOpenContextMenu] = useState({ isOpen: false, id: null });

  const { questionnaireStructure, isViewMode } = useSelector(({ finders }) => finders);

  const isIntro = questionnaireStructure?.some(item => item.templateType === TemplateTypes.INTRO);

  /**
   * @desc Function set active droppableId and type for Droppable component
   * @param {String} elementId
   * @param {Boolean} isChapterOpen
   */
  const handleToggleVisible = (elementId, isChapterOpen) => {
    let chapterWasFound = false;
    const chapterIndex = questionnaireStructure.findIndex(ele => ele.id === elementId);
    const result = questionnaireStructure?.map((element, idx) => {
      if (element.id === elementId) {
        return { ...element, isOpen: !isChapterOpen };
      }
      if (element.templateType === TemplateTypes.CHAPTER && idx > chapterIndex) {
        chapterWasFound = true;
      }
      return chapterWasFound ? element : idx > chapterIndex ? { ...element, isOpen: !isChapterOpen } : element;
    });
    dispatch(updateQuestionnaireStructure({ structure: result, updateType: UpdateStructureType.NAVIGATOR_TOGGLE }));
  };

  /**
   * @desc Open menu right mouse click
   * @param {Event} event
   * @param {String} pageId
   */
  const handleOpenContextMenu = (event, pageId) => {
    if (questionnaireStructure.length > 1) {
      event.preventDefault();
      setIsOpenContextMenu({ isOpen: !isOpenContextMenu.isOpen, id: pageId });
    }
  };

  useOutsideClick(contextMenuRef, () => {
    setIsOpenContextMenu({ isOpen: false, id: null });
  });

  const showContextMenu = item => isOpenContextMenu.isOpen && isOpenContextMenu.id === item.id && !isViewMode;

  const navigatorItems = useMemo(() => {
    return questionnaireStructure?.reduce((acc, curr, index, array) => {
      if (curr.templateType === TemplateTypes.CHAPTER && array[index + 1] && array[index + 1].templateType !== TemplateTypes.CHAPTER) {
        acc.push({ ...curr, isCollapsed: true });
      } else {
        acc.push({ ...curr, isCollapsed: false });
      }
      return acc;
    }, []);
  }, [questionnaireStructure]);

  return (
    <Droppable droppableId='NAVIGATOR_ITEMS' type='NAVIGATOR'>
      {provided => (
        <Items ref={provided.innerRef}>
          {navigatorItems?.map((item, index) => {
            return (
              <Draggable
                key={item?.id}
                draggableId={`navigator_item${item?.id}`}
                index={index}
                isDragDisabled={isViewMode || (isIntro && (index === 0 || index === 1 || index === 2))}
              >
                {(providedItem, snapshotItem) =>
                  item.templateType === TemplateTypes.PAGE ? (
                    <Page
                      providedItem={providedItem}
                      snapshotItem={snapshotItem}
                      isFirstPage={index === 0}
                      element={item}
                      onOpenContextMenu={handleOpenContextMenu}
                      questionnaireStructure={questionnaireStructure}
                      isOpen={item.isOpen}
                      index={index}
                    >
                      {showContextMenu(item) && (
                        <ContextMenu
                          contextMenuRef={contextMenuRef}
                          onDelete={event => deleteItem(event, item.id, questionnaireStructure, setIsOpenContextMenu, dispatch)}
                          item={item}
                          providedItem={providedItem}
                        />
                      )}
                    </Page>
                  ) : item.templateType === TemplateTypes.CHAPTER ? (
                    <Chapter
                      providedItem={providedItem}
                      item={item}
                      onToggleVisible={handleToggleVisible}
                      snapshotItem={snapshotItem}
                      isOpenContextMenu={isOpenContextMenu}
                      onOpenContextMenu={handleOpenContextMenu}
                      onClickScroll={handleClickScroll}
                      items={questionnaireStructure}
                    >
                      {showContextMenu(item) && (
                        <ContextMenu
                          contextMenuRef={contextMenuRef}
                          onDelete={event => deleteItem(event, item.id, questionnaireStructure, setIsOpenContextMenu, dispatch)}
                          item={item}
                          providedItem={providedItem}
                        />
                      )}
                    </Chapter>
                  ) : (
                    <OtherItem
                      providedItem={providedItem}
                      snapshotItem={snapshotItem}
                      item={item}
                      onOpenContextMenu={handleOpenContextMenu}
                      isOpenContextMenu={isOpenContextMenu}
                      contextMenuRef={contextMenuRef}
                      onClickScroll={handleClickScroll}
                      items={questionnaireStructure}
                    >
                      {showContextMenu(item) && (
                        <ContextMenu
                          contextMenuRef={contextMenuRef}
                          onDelete={event => deleteItem(event, item.id, questionnaireStructure, setIsOpenContextMenu, dispatch)}
                          item={item}
                          providedItem={providedItem}
                        />
                      )}
                    </OtherItem>
                  )
                }
              </Draggable>
            );
          })}
        </Items>
      )}
    </Droppable>
  );
}

const Items = styled('div')({
  listStyle: 'none',
  padding: 0,
  height: 'calc(100vh - 185px)',
  overflowY: 'auto',
});
