import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ResultType } from '../../../../../../../../../../../lib/models/Questionnaire';
import { AppList } from '../../../../../../../../../../../ui/AppList';
import { ContactFormRowField } from './ContactFormRowField';
import classNames from 'classnames';

export function ContactFormRowFieldList(props) {
  const { templateContent, rowIndex, structureItemIndex } = props;

  return (
    <Droppable
      type={ResultType.CONTACT_FORM_ROW_FIELD}
      droppableId={JSON.stringify({
        structureItemIndex,
        rowIndex,
        type: ResultType.CONTACT_FORM_ROW_FIELD,
      })}
    >
      {provided => (
        <AppList
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ ...provided.droppableProps.style, paddingTop: '24px', gap: '24px' }}
          className={classNames('flex flex-col', provided.droppableProps.className)}
        >
          {templateContent.formRowList[rowIndex].fieldList?.map((item, fieldIndex) => {
            return <ContactFormRowField key={item.id} {...props} fieldIndex={fieldIndex} id={item.id} />;
          })}
          {provided.placeholder}
        </AppList>
      )}
    </Droppable>
  );
}
