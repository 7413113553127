import { useCallback, useEffect, useRef } from 'react';

export function useInterval({ callback, isEnabled = true, intervalInMs }) {
  const timeoutId = useRef(null);
  const isInterval = useRef(null);

  const handleCallback = useCallback(() => {
    if (isInterval.current) {
      callback();
      setTimeout(handleCallback, intervalInMs);
    }
  }, [intervalInMs, callback]);

  useEffect(() => {
    isInterval.current = true;
    if (isEnabled && isInterval.current) {
      timeoutId.current = setTimeout(handleCallback, intervalInMs);
    } else {
      clearTimeout(timeoutId.current);
    }

    return () => {
      isInterval.current = false;
      clearTimeout(timeoutId.current);
    };
  }, [handleCallback, intervalInMs, isEnabled]);
}
