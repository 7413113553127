import { QuestionElementId } from '../models/Hardcode';
import { useEffect } from 'react';

export function useUpdateHardcodedSingleCounter({ onUpdateCounter, selectedElement, counterQ6, Q6, P9, P10 }) {
  useEffect(() => {
    if (
      selectedElement?.id &&
      [QuestionElementId.HAMBURGER_VB_KONTOFINDER_PK_QUESTION_6, QuestionElementId.KONFOFINDER_PK_QUESTION_6].includes(Q6?.id) &&
      [P9?.id, P10?.id].includes(selectedElement?.id)
    ) {
      onUpdateCounter(counterQ6, selectedElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElement?.id, Q6?.content?.counterAnswers?.[0]?.amount]);
}
