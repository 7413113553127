import { SortDefaultIcon } from '../../../../../ui/icons/SortDefaultIcon';
import { SortAscendIcon } from '../../../../../ui/icons/SortAscendIcon';
import { SortDescendIcon } from '../../../../../ui/icons/SortDescendIcon';
import { TableSortOrder } from '../../../lib/consts';

export function SortIcon({ isActiveArrange, sortOrder }) {
  switch (true) {
    case isActiveArrange && sortOrder === TableSortOrder.ASC:
      return <SortAscendIcon active={isActiveArrange} />;
    case isActiveArrange && sortOrder === TableSortOrder.DESC:
      return <SortDescendIcon active={isActiveArrange} />;
    default:
      return <SortDefaultIcon />;
  }
}
