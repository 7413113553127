/* eslint-disable no-param-reassign */
import React from 'react';
import { QuestionnaireSelect } from '../../../../../../../features/QuestionnaireSelect';
import { formatOptions } from '../../../../../../../utils/helpers';
import styled from 'styled-components';
import { useLocalization } from '../../../../../../../../../../../../../lib/hooks/useLocalization';
import { useSelector } from 'react-redux';
import { useUpdateStructure } from '../../../../../../../hooks/useUpdateStructure';

export function ProductsPriority({ data, structureItemIndex, disabled }) {
  const { productsPriorityText } = useLocalization().translations.unique.pages.result.sortProductsBy;
  const { tenantProducts } = useSelector(({ finders }) => finders);

  const { updateStructure } = useUpdateStructure();

  const onChange = selected => {
    updateStructure(draft => {
      const productsPriority = draft?.[structureItemIndex]?.content?.sortProductsBy.productsPriority;

      const newIds = selected?.map(item => item.value);
      draft[structureItemIndex].content.sortProductsBy.productsPriority = Array.isArray(productsPriority) ? newIds : [];
    });
  };

  return (
    <Root>
      <QuestionnaireSelect
        formItemProps={{ style: { minHeight: 'unset', marginBottom: '34px' } }}
        selectProps={{
          isMultiSelect: true,
          withoutLimit: true,
          value: data || [],
          label: productsPriorityText,
          onChange,
          options: Array.isArray(tenantProducts) ? formatOptions(tenantProducts) : [],
          disabled,
        }}
      />
    </Root>
  );
}

const Root = styled('div')({
  marginTop: 13,
  marginBottom: 10,
});
