import { ComparisonTablePreview } from '../../../../../../../modules/ComparisonTableLayout';

/**
 * @desc Layout for Comparison Table template
 * @param {Object} style
 * @param {Object} rest
 * @returns {JSX.Element}
 */
export function ComparisonTableLayout({ style, ...rest }) {
  return (
    <ComparisonTablePreview
      {...rest}
      style={{
        pointerEvents: 'none',
        width: '1920px',
        height: '863px',
        display: 'flex',
        transform: 'scale(0.237) translate(-3096px, -1391px)',
        ...style,
      }}
    />
  );
}
