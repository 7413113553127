import { HeadlineSublineBlock } from './HeadlineSublineBlock';
import { ScoreBlock } from './ScoreBlock';
import { BulletPointsBlock } from './BulletPoints';
import { DeleteDetailTableButton } from './DeleteDetailTableButton';
import { Fragment } from 'react';

export function DetailTableList({ data, structureItemIndex, productIndex }) {
  return data.map((detailTable, index) => {
    return (
      <Fragment key={detailTable.id}>
        <DeleteDetailTableButton structureItemIndex={structureItemIndex} productIndex={productIndex} detailTableIndex={index} />
        <HeadlineSublineBlock
          detailTable={detailTable}
          structureItemIndex={structureItemIndex}
          productIndex={productIndex}
          detailTableIndex={index}
        />
        <ScoreBlock detailTable={detailTable} structureItemIndex={structureItemIndex} productIndex={productIndex} detailTableIndex={index} />
        <BulletPointsBlock
          data={detailTable.bulletPointsSection}
          productIndex={productIndex}
          structureItemIndex={structureItemIndex}
          detailTableIndex={index}
        />
      </Fragment>
    );
  });
}
