import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  endUserFinder: {},
  endUserFinderLoading: true,
  endUserFinderError: null,
  domains: [],
  domainsError: null,
  domainsLoading: true,
};

const endUserFinderSlice = createSlice({
  name: 'endUserFinder',
  initialState,
  reducers: {
    getEndUserFinderRequest: state => {
      state.endUserFinderError = null;
      state.endUserFinderLoading = true;
    },
    getEndUserFinderSuccess: (state, action) => {
      state.endUserFinder = action.payload.finder;
      state.endUserFinderLoading = false;
    },
    getEndUserFinderError: (state, action) => {
      state.endUserFinderError = action.payload;
      state.endUserFinderLoading = false;
    },
    cleanEndUserFinder: state => {
      state.endUserfinder = {};
    },
    getDomainsRequest: state => {
      state.domainsError = null;
      state.domainsLoading = true;
    },
    getDomainsSuccess: (state, action) => {
      state.domains = action.payload;
      state.domainsLoading = false;
    },
    getDomainsError: (state, action) => {
      state.domainsError = action.payload;
      state.domainsLoading = false;
    },
  },
});

export const {
  getEndUserFinderRequest,
  getEndUserFinderSuccess,
  getEndUserFinderError,
  cleanEndUserFinder,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
} = endUserFinderSlice.actions;
export const endUserFinder = endUserFinderSlice.reducer;
