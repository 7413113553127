import { components } from 'react-select';
import { AppInputLabel } from '../../AppInputLabel';
import { AppText } from '../../text/AppText';

export function Control({ children, ...rest }) {
  const { label, size } = rest.selectProps;
  const asLabel = rest.isFocused || rest.hasValue;
  const fontSize = size === 'small' ? '12px' : '16px';

  return (
    <div>
      {label && <AppInputLabel $size={size} textValue={label} className={asLabel ? 'asLabel' : ''} type='secondary' />}
      <components.Control {...rest}>
        {!rest.hasValue && label && (
          <AppText textValue={label} style={{ lineHeight: '1', whiteSpace: 'nowrap', fontSize, visibility: 'hidden', display: 'none' }} />
        )}
        {children}
      </components.Control>
    </div>
  );
}
