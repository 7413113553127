export const Product = {
  NAME: 'name',
  HVM_PRODUCT_ID: 'hvmProductId',
  SHORT_NAME: 'shortName',
  HEADLINE: 'headline',
  SUBLINE: 'subLine',
  PRODUCT_PAGE_LINK: 'productPageUrl', // REFACTOR: rename
  IMAGE_LINK: 'imageUrl',
  PRICE_PER_YEAR: 'pricePerYear',
  PRICE_PER_MONTH: 'pricePerMonth',
  PRICE_PRE_REQUEST: 'pricePerRequest',
  CATEGORY: 'category',
  CURRENCY: 'currency',
  IS_EXCEPTIONAL: 'isExceptional',
};
