import { AppText } from '../../../../../../../../../../../../../../ui/text/AppText';
import { CardSectionHeader } from '../../../../../../../../ui/CardSectionHeader';
import { useLocalization } from '../../../../../../../../../../../../../../lib/hooks/useLocalization';
import { ProductBulletPoints } from './ProductBulletPoints';
import { isLength } from '../../../../../../../../../../../../../../lib/util/helpers';
import { ProductAddBulletPointButton } from './ProductAddBulletPointButton';
import styled from 'styled-components';
import { ProductBulletPointSelects } from './ProductBulletPointSelects';

export function ProductBulletPointsForProductSection({ data, productIndex, structureItemIndex }) {
  const dataPath = `[${structureItemIndex}].content.products[${productIndex}].bulletPointsSection`;

  const { design } = useLocalization().translations.unique.pages.settings;

  return (
    <Root>
      <CardSectionHeader>
        <AppText strong textValue={design.bulletPoints} />
        <ProductBulletPointSelectsWrapper>
          <ProductBulletPointSelects
            structureItemIndex={structureItemIndex}
            bulletPointIcon={data.bulletPointIcon}
            bulletPointIconColor={data.bulletPointIconColor}
            productIndex={productIndex}
          />
        </ProductBulletPointSelectsWrapper>
      </CardSectionHeader>
      <div>
        {isLength(data.bulletPoints) && (
          <ProductBulletPoints
            dataPath={`${dataPath}.bulletPoints`}
            bulletPointIcon={data.bulletPointIcon}
            bulletPointIconColor={data.bulletPointIconColor}
            bulletPointsSection={data}
            productIndex={productIndex}
            structureItemIndex={structureItemIndex}
          />
        )}
        <ProductAddBulletPointButton
          data={data}
          dataPath={`${dataPath}.bulletPoints`}
          productIndex={productIndex}
          structureItemIndex={structureItemIndex}
        />
      </div>
    </Root>
  );
}

const Root = styled('div')({
  padding: '32px 24px',
  borderBottom: '1px solid #ECECEE',
});

const ProductBulletPointSelectsWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  marginRight: 34,
});
