import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AppText } from '../../../../../../../../../../../ui/text/AppText';
import { AppBasicButton } from '../../../../../../../../../../../ui/buttons/AppBasicButton';
import { RuleRow } from './RuleRow';
import { useLocalization } from '../../../../../../../../../../../lib/hooks/useLocalization';
import { updateQuestionnaireStructure } from '../../../../../../../../../../../lib/store/slices/findersSlice';
import { reorderRules } from '../../../../../../../utils/dnd-helpers';
import { ButtonRules } from './ButtonRules';
import '../../../style.scss';

export function RulesProcess({ element, disabled }) {
  const dispatch = useDispatch();
  const { questionnaireStructure } = useSelector(({ finders }) => finders);
  const { addRule } = useLocalization().translations.shared.generic;

  const { rules, id: elementId } = element;

  const addRuleOnClick = () => {
    const result = questionnaireStructure.map(el => {
      return el.id === elementId
        ? { ...el, rules: el?.rules ? [...el.rules, { rowId: uuidv4(), ruleId: null }] : [{ rowId: uuidv4(), ruleId: null }] }
        : el;
    });
    dispatch(updateQuestionnaireStructure({ structure: result }));
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source?.index === destination?.index && source?.droppableId === destination?.droppableId) {
      return;
    }

    dispatch(updateQuestionnaireStructure({ structure: reorderRules(questionnaireStructure, source, destination) }));
  };

  const currentQuestionnaireElement = questionnaireStructure.find(({ id }) => id === elementId);

  return (
    <div className='flow_process_box'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={elementId} type='ADVANCED_SETTINGS_RULES'>
          {provided => (
            <div ref={provided.innerRef}>
              {rules?.map((ruleObj, index) => (
                <Draggable key={ruleObj.rowId} draggableId={ruleObj.rowId} index={index} isDragDisabled={disabled}>
                  {prov => (
                    <div ref={prov.innerRef} {...prov.draggableProps} className='draggable_rule_wrap'>
                      <RuleRow ruleObj={ruleObj} elementRules={rules} elementId={elementId} disabled={disabled} {...prov.dragHandleProps} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AppBasicButton onClick={addRuleOnClick} style={{ display: 'inline-flex', margin: '10px 0 16px 0' }} disabled={disabled}>
        <AppText className='primary_color bold' textValue={addRule} />
      </AppBasicButton>

      <ButtonRules questionnaireElement={currentQuestionnaireElement} rules={rules} />
    </div>
  );
}
