import { Droppable, Draggable } from 'react-beautiful-dnd';

import { ResultType } from 'src/lib/models/Questionnaire';
import { AppBasicButton } from 'src/ui/buttons/AppBasicButton';
import { AppText } from 'src/ui/text/AppText';
import { ComparisonTopic } from './ComparisonTopic';

import { useLocalization } from 'src/lib/hooks/useLocalization';

export function ComparisonTopics({ topics, tableIndex, structureItemIndex, addTopic, deleteTopic, tableId, updateTopic, changeTopicOrder }) {
  const { comparisonDetailTable } = useLocalization().translations.unique.pages.questionnaire.content;

  return (
    <>
      <Droppable
        droppableId={`{
          "structureItemIndex": ${structureItemIndex},
          "tableIndex": ${tableIndex}
        }`}
        type={ResultType.COMPARISON_DETAIL_TABLE_TOPIC}
      >
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {topics.map((topic, index) => (
              <Draggable key={topic.id} draggableId={topic.id} index={index}>
                {provided1 => (
                  <div {...provided1.draggableProps}>
                    <div ref={provided1.innerRef}>
                      <ComparisonTopic
                        tableIndex={tableIndex}
                        structureItemIndex={structureItemIndex}
                        topicIndex={index}
                        topic={topic}
                        deleteTopic={deleteTopic}
                        tableId={tableId}
                        updateTopic={updateTopic}
                        changeTopicOrder={changeTopicOrder}
                        dragHandleProps={provided1.dragHandleProps}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AppBasicButton onClick={() => addTopic(tableId)} style={{ margin: '32px 0' }}>
        <AppText className='primary_color bold' textValue={comparisonDetailTable.addTopic} />
      </AppBasicButton>
    </>
  );
}
