import { Rule } from '../../lib/Rule';
import { ROUTER_PATHS } from '../../../../../../../../../lib/util/router/router-paths';
import { AppText } from '../../../../../../../../../ui/text/AppText';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectRule } from '../../../../../../../../../lib/store/slices/rulesSlice';
import { useNavigate } from 'react-router-dom';
import { useRuleSearchDataColumn } from '../../components/search-data';
import { useLocalization } from '../../../../../../../../../lib/hooks/useLocalization';

export function useRulesNameColumn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tenant, finder } = useSelector(({ finders }) => finders);
  const { ruleName: title } = useLocalization().translations.unique.pages.rules.table.columns;

  const editRule = row => {
    dispatch(selectRule(row));
    navigate(`${ROUTER_PATHS.builder(finder.id)}?X-TENANT=${tenant.id}`);
  };

  return useRuleSearchDataColumn({
    title,
    arrangedId: Rule.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 300,
      render: (text, row) => {
        return (
          <RuleName onClick={() => editRule(row)}>
            <AppText className='primary_color'>{text}</AppText>
          </RuleName>
        );
      },
    },
  });
}

const RuleName = styled('div')({
  cursor: 'pointer',
});
