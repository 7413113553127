import { getIndexByTemplateType } from '../util/helpers';
import { TemplateTypes } from '../models/TemplateTypes';

export function useGetUrlParamsForMatomo(pagesFlow) {
  const currentPageIndex = pagesFlow.allPages.findIndex(page => page.id === pagesFlow.currentPageId) + 1;

  const allElements = pagesFlow.allPages.flatMap(item => item.elements);

  const currentTemplates = allElements
    .filter(element => pagesFlow.currentElementsIds.includes(element.id))
    .map(elem => {
      if (elem.templateType === TemplateTypes.CHAPTER) {
        return 'CHAPTERS';
      }
      return `${elem.templateType}${getIndexByTemplateType(allElements, elem) + 1}`;
    })
    .join('_');

  return { currentPageIndex, currentTemplates };
}
