/* eslint-disable max-lines-per-function,max-lines */
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { BulletCheckIcon } from '../../../../../../../../../../../../ui/icons/BulletCheckIcon';
import { BulletIconComponent } from '../../../../../../../../features/BulletIconComponent';
import { useLocalization } from '../../../../../../../../../../../../lib/hooks/useLocalization';
import { getBackgroundUrl, getIconObject, getBackgroundImageStyle } from '../../../../../../../../../../../../lib/util/helpers';
import { InfoIcon } from '../../../../../../../../../../../../ui/icons/InfoIcon';

/**
 * @desc Content Block Item
 * @param {Object} contentBlock
 * @param {Number} blocksLength
 * @param {Object} style
 * @param {Object} stylesConfiguration
 * @returns {JSX.Element}
 */
export function ContentBlockItem({ contentBlock, blocksLength, style, stylesConfiguration }) {
  const {
    finder: {
      finderSettings: {
        media: mediaOptions,
        bulletIcons: { customIcons, defaultIcons },
      },
    },
  } = useSelector(({ finders }) => finders);

  const {
    contentBlockStyle = {},
    contentHeadlineStyle = {},
    backgroundImageBlockStyle = {},
    contentSublineStyle = {},
    headlineAndSublineWrapStyle = {},
    bulletPointsWrapStyle = {},
    bulletPointStyle = {},
    bulletPointTextWrapStyle = {},
    bulletPointTextStyle = {},
  } = style;

  const { contentHeadline, contentSubline, selectBackgroundImage, bulletPointIcon, bulletPoints } = contentBlock;

  const { fonts, colors } = stylesConfiguration;

  const { body, heading3, longLines } = useLocalization().translations.unique.pages.questionnaire.rightSideBar.layout.templates;

  const backgroundImageUrl = getBackgroundUrl(selectBackgroundImage, mediaOptions);

  const iconObj = getIconObject(bulletPointIcon, [...customIcons, ...defaultIcons]);

  const iconComponent = () => {
    if (iconObj) {
      return <BulletIconComponent icon={iconObj} color={colors[bulletPointIcon.color]} />;
    }
    return <BulletCheckIcon style={{ color: colors.primaryColor }} />;
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...getBackgroundImageStyle(contentBlockStyle.backGround, backgroundImageUrl),
          ...contentBlockStyle,
        }}
      >
        <div
          style={{
            width: '100%',
            ...getBackgroundImageStyle(backgroundImageBlockStyle.backGround, backgroundImageUrl),
            ...backgroundImageBlockStyle,
            position: 'relative',
          }}
        >
          {backgroundImageBlockStyle.backGround && selectBackgroundImage?.infoIcon?.value && (
            <span>
              <InfoIcon />
            </span>
          )}
        </div>
        {!contentBlockStyle.horizontal && (
          <div style={{ backgroundColor: '#FFFFFF', ...headlineAndSublineWrapStyle }}>
            <div style={{ position: 'relative' }}>
              <Typography.Paragraph
                ellipsis={blocksLength !== 1}
                style={{
                  textAlign: 'center',
                  margin: '32px 0 10px 0',
                  padding: '0 10px',
                  fontSize: fonts.headlineThird.fontSize,
                  fontFamily: fonts.headlineThird.font,
                  fontWeight: fonts.headlineThird.fontWeight,
                  color: colors.primaryColor,
                  ...contentHeadlineStyle,
                }}
              >
                {contentHeadline.value || heading3()}
                {contentHeadline?.infoIcon?.value && (
                  <span style={{ position: 'relative' }}>
                    <InfoIcon style={{ position: 'absolute', marginLeft: 5 }} />
                  </span>
                )}
              </Typography.Paragraph>
            </div>
            <div style={{ position: 'relative' }}>
              <Typography.Paragraph
                ellipsis={blocksLength !== 1}
                style={{
                  textAlign: 'center',
                  margin: '0px 0 20px 0',
                  padding: '0 10px',
                  fontSize: fonts.body.fontSize,
                  fontFamily: fonts.body.font,
                  fontWeight: fonts.body.fontWeight,
                  color: colors[fonts.body.colorVariant],
                  ...contentSublineStyle,
                }}
              >
                {contentSubline.value || body()}
                {contentSubline?.infoIcon?.value && (
                  <span style={{ position: 'relative' }}>
                    <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 3 }} />
                  </span>
                )}
              </Typography.Paragraph>
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '20px 16px 10px 16px',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            ...bulletPointsWrapStyle,
          }}
        >
          {contentBlockStyle.horizontal && (
            <div style={{ backgroundColor: '#FFFFFF', ...headlineAndSublineWrapStyle }}>
              <div style={{ position: 'relative' }}>
                <Typography.Paragraph
                  ellipsis={blocksLength !== 1}
                  style={{
                    textAlign: 'center',
                    margin: '32px 0 10px 0',
                    padding: '0 10px',
                    fontSize: fonts.headlineThird.fontSize,
                    fontFamily: fonts.headlineThird.font,
                    fontWeight: fonts.headlineThird.fontWeight,
                    color: colors.primaryColor,
                    ...contentHeadlineStyle,
                  }}
                >
                  {contentHeadline.value || heading3()}
                  {contentHeadline?.infoIcon?.value && (
                    <span style={{ position: 'relative' }}>
                      <InfoIcon style={{ position: 'absolute', marginLeft: 5 }} />
                    </span>
                  )}
                </Typography.Paragraph>
              </div>
              <div style={{ position: 'relative' }}>
                <Typography.Paragraph
                  ellipsis={blocksLength !== 1}
                  style={{
                    textAlign: 'center',
                    margin: '0px 0 20px 0',
                    padding: '0 10px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                    ...contentSublineStyle,
                  }}
                >
                  {contentSubline.value || body()}
                  {contentSubline?.infoIcon?.value && (
                    <span style={{ position: 'relative' }}>
                      <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 3 }} />
                    </span>
                  )}
                </Typography.Paragraph>
              </div>
            </div>
          )}

          {bulletPoints?.map(bulletPoint => (
            <div
              key={bulletPoint.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 10px',
                width: bulletPointStyle.equalWidth ? `calc(100% / ${bulletPoints.length}` : 'auto',
                ...bulletPointStyle,
              }}
            >
              <div style={{ margin: 10 }}>{iconComponent()}</div>
              <div style={bulletPointTextWrapStyle}>
                <Typography.Paragraph
                  style={{
                    marginBottom: 6,
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: 700,
                    color: colors[fonts.body.colorVariant],
                    ...bulletPointTextStyle,
                  }}
                >
                  {bulletPoint.headline || longLines()}
                </Typography.Paragraph>
                <Typography.Paragraph
                  style={{
                    marginBottom: 6,
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontSize: fonts.body.fontSize,
                    fontFamily: fonts.body.font,
                    fontWeight: fonts.body.fontWeight,
                    color: colors[fonts.body.colorVariant],
                    ...bulletPointTextStyle,
                  }}
                >
                  {bulletPoint.textValue || longLines()}
                  {bulletPoint?.infoIcon?.value && (
                    <span style={{ position: 'relative' }}>
                      <InfoIcon style={{ position: 'absolute', marginLeft: 5, top: 2 }} />
                    </span>
                  )}
                </Typography.Paragraph>
              </div>
            </div>
          ))}
        </div>
      </div>
      {contentBlockStyle.backGround && selectBackgroundImage?.infoIcon?.value && (
        <span>
          <InfoIcon />
        </span>
      )}
    </div>
  );
}
