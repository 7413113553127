import { components } from 'react-select';
import { MoreSelectedBadge } from './MoreSelectedBadge';

const MAX_TO_SHOW = 2;

export function MultiValue({ index, getValue, ...props }) {
  const overflow = getValue()
    .slice(MAX_TO_SHOW)
    .map(x => x.title);

  return index < MAX_TO_SHOW ? <components.MultiValue {...props} /> : index === MAX_TO_SHOW ? <MoreSelectedBadge items={overflow} /> : null;
}
