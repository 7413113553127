import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../../../../../utils/arranges-helpers';
import { useArrangeSubmit } from '../../../../../../../../utils/hooks/useArrangeSubmit';
import { useStringArrange } from '../../../../../../../../utils/hooks/arranges-by-type/useStringArrange';
import { useFinderArrangeRequests } from '../../lib/useFinderArrangeRequests';
import { ArrangeListPopup } from '../../../../../../../../components/popups/ArrangeListPopup';
import { TableHeaderCell } from '../../../../../../../../components/TableHeaderCell';
import { getFindersFromApi } from '../../../../../../../../../../../../lib/api';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../../../../../../../../../lib/consts';

const getFilteredFindersFromApi = (tenantId, filters) =>
  getFindersFromApi(tenantId, {
    params: { size: WITHOUT_PAGINATION_ELEMENTS_SIZE },
    data: filters,
  });

export function FinderSearchDataHeaderCell({ arrangedId, title, searchFieldName, filterFieldName, headerCellProps: additionalHeaderCellProps }) {
  const { activeArrangedId, sorting } = useSelector(({ finders }) => finders);
  const isActiveArrange = activeArrangedId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');
  const { id: tenantId } = useParams();

  const { filtering } = useStringArrange({
    form,
    request: value => getFilteredFindersFromApi(tenantId, { [searchFieldName]: value }),
    formatResponse: response => formatArrangedData(response, arrangedId),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useFinderArrangeRequests({
    arrangedId,
    sortFieldName: arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    setFilterLabel,
  });

  const headerCellProps = {
    title,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
}
